import type { ILimit } from "./ILimit";
import type { ContainerModel } from "../container";
import type { BaseElementModel } from "../element";

export abstract class BaseElementsTogetherLimit implements ILimit {
    private readonly maxCount: number;

    public constructor(maxCount: number) {
        this.maxCount = maxCount;
    }

    filterInsertElements(
        elements: BaseElementModel[],
        containerToInsert: ContainerModel,
        indexToInsert: number
    ): BaseElementModel[] {
        if (!this.isElementMatch(elements[0])) {
            return elements;
        }

        let count = 1;
        for (let i = indexToInsert - 1; i >= 0; i--) {
            const element = containerToInsert.elements[i];

            if (this.isElementMatch(element)) {
                count++;
                if (count > this.maxCount) {
                    return [];
                }
            } else {
                return elements;
            }
        }
        return elements;
    }

    protected abstract isElementMatch(element: BaseElementModel): boolean;
}
