import type { IntervalsTaskInstrumentSettings } from "../../../entities/task-instruments-settings/BaseTaskInstrumentSettings";
import type {
    TIntervalMethodInstrumentSettings,
    TRawIntervalSign,
} from "@viuch/feature-instrument-settings/service-types/intervals";
import type { TIntervalSign } from "@viuch/instrument-intervals/statement";

export function serializeIntervalsTaskSettings(
    model: IntervalsTaskInstrumentSettings
): TIntervalMethodInstrumentSettings {
    return {
        instrument_type: "interval",
        axes: model.instrument.intervals.lines.map((line) => ({
            points: line.dots.map((dot) => ({
                position: dot.position,
                is_included: dot.form === "filled",
                value: dot.value,
            })),
            signs: line.intervalsSings.map((sign) => serializeIntervalSignMap[sign]),
            label: line.label,
        })),
        settings_uuid: model.settingsUuid,
    };
}

const serializeIntervalSignMap: Record<TIntervalSign, TRawIntervalSign> = {
    positive: "+",
    negative: "-",
    none: null,
    zero: "0",
};
