import type { BaseFormNode } from "../nodes/BaseFormNode";
import type { TAnyControl } from "../types/controls";

import { instantiateCheckboxControl } from "./instantiateCheckboxControl";
import { instantiateFormControl } from "./instantiateFormControl";
import { instantiateLabelControl } from "./instantiateLabelControl";
import { instantiateMathArrayControl } from "./instantiateMathArrayControl";
import { instantiateMathControl } from "./instantiateMathControl";
import { instantiateNumberControl } from "./instantiateNumberControl";
import { instantiateSelectControl } from "./instantiateSelectControl";
import { instantiateTextControl } from "./instantiateTextControl";

export function instantiateControl(parent: BaseFormNode | null, data: TAnyControl): BaseFormNode {
    switch (data.control) {
        case "Checkbox":
            return instantiateCheckboxControl(data, parent);
        case "Form":
            return instantiateFormControl(data, parent);
        case "Math":
            return instantiateMathControl(data, parent);
        case "Text":
            return instantiateTextControl(data, parent);
        case "Number":
            return instantiateNumberControl(data, parent);
        case "Label":
            return instantiateLabelControl(data, parent);
        case "Select":
            return instantiateSelectControl(data, parent);
        case "MathArray":
            return instantiateMathArrayControl(data, parent);
    }
}
