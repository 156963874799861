import type { TFragment, TLine, TPoint } from "./types";

import { getLineAngle } from "./angles";
import { isZeroLengthVector } from "./check-geometry";
import { rotatePoint } from "./solvers";

export function mergeOneLineFragments(fragments: TLine[]): TLine[] {
    if (fragments.length === 0) throw new Error("At least 1 fragment required");

    const [first] = fragments;
    const angle = getLineAngle(first);
    const zero = { x: first.x1, y: first.y1 };

    type Point = { x: number; y: number; value: number };
    type Fragment = { left: Point; right: Point };

    return fragments
        .map<Fragment>((fragment) => {
            if (isZeroLengthVector(fragment)) {
                throw new Error("Unexpected zero-length fragment");
            }

            let { x1, x2, y1, y2 } = fragment;

            let left = rotatePoint({ x: x1, y: y1 }, angle, zero).x;
            let right = rotatePoint({ x: x2, y: y2 }, angle, zero).x;

            if (left > right) {
                [left, right] = [right, left];
                [x1, x2] = [x2, x1];
                [y1, y2] = [y2, y1];
            }

            return {
                left: { x: x1, y: y1, value: left },
                right: { x: x2, y: y2, value: right },
            };
        })
        .sort((a, b) => a.left.value - b.left.value)
        .reduce<Fragment[]>((all, current) => {
            if (!all.length) {
                all.push(current);
                return all;
            }

            const [last] = all.slice(-1);
            const left = current.left.value;
            const right = current.right.value;

            if (last.left.value <= left && left <= last.right.value) {
                if (right > last.right.value) {
                    last.right = current.right;
                    return all;
                }
            }

            all.push(current);
            return all;
        }, [])
        .map<TLine>(({ left, right }) => ({
            x1: left.x,
            y1: left.y,
            x2: right.x,
            y2: right.y,
        }));
}

export function createFragmentsFromPoints(points: TPoint[], loop: boolean): TFragment[] {
    const fragments: TFragment[] = [];
    for (let i = 0; i < points.length - 1; i++) {
        const a = points[i];
        const b = points[i + 1];
        fragments.push({ a, b });
    }
    if (loop) {
        fragments.push({ a: points.at(-1)!, b: points[0] });
    }

    return fragments;
}
