import type { SolutionExampleStepImpl } from "../entities/SolutionExampleImpl";
import type { TRawTaskExample } from "../service-types/taskExample";
import type { OmitId } from "@viuch/shared/types";

import { SolutionExampleImpl } from "../entities/SolutionExampleImpl";

import { mapSolutionExampleStepData } from "./mapSolutionExampleStepData";

export function mapTaskExample(data: OmitId<TRawTaskExample>): SolutionExampleImpl {
    const { steps: stepsData, instrument_settings, task_text, view_configuration, formula } = data;

    const steps: SolutionExampleStepImpl[] = stepsData.map(mapSolutionExampleStepData);

    return new SolutionExampleImpl(task_text ?? null, formula, steps, instrument_settings);
}
