import { assert } from "@viuch/utils/debug";

export function roundToStep(n: number, steps: number): number {
    assert(!Number.isNaN(n));
    assert(n >= 0);
    assert(n <= 1);
    assert(Number.isInteger(steps));
    assert(steps >= 2);

    const stepN = Math.round(n * (steps - 1));
    return stepN / (steps - 1);
}
