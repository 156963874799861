import type { TAnyAction } from "../types";

export type TNaturalLogAction = { type: "natural-log" };

export function createNaturalLogAction(): TNaturalLogAction {
    return { type: "natural-log" };
}

export function isNaturalLogAction(action: TAnyAction): action is TNaturalLogAction {
    return action.type === "natural-log";
}
