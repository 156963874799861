import type { TRawStudent } from "./types";
import type { Student } from "../../entities/students/Student";

import { agent } from "../../agent";
import { throwServiceError } from "../base/RequestError";

import { mapStudent } from "./utils";

export class StudentsService {
    async fetchAll(
        limit: number = 10,
        offset: number = 0,
        query?: string,
        signal?: AbortSignal
    ): Promise<TPagedStudents> {
        try {
            const { data } = await agent.get<TRawPagedStudents>(`/students/`, {
                params: { limit, offset, query },
                signal,
            });

            return {
                count: data.count,
                results: data.results.map(mapStudent),
            };
        } catch (e) {
            throwServiceError(e);
        }
    }

    async byId(studentId: string | number): Promise<Student> {
        try {
            const { data } = await agent.get<TRawStudent>(`/students/${studentId}/`);

            return mapStudent(data);
        } catch (e) {
            throwServiceError(e);
        }
    }
}

export type TRawPagedStudents = {
    count: number;
    results: TRawStudent[];
};

export type TPagedStudents = {
    count: number;
    results: Student[];
};
