import type { TAccess } from "../../store/AccessProvider";

type TMenuBlock = {
    title: string;
    style?: "transparent";
    items: readonly TMenuItem[];
};

type TMenuItem = {
    label: string;
    path: string;
    external?: boolean;
    hasAccess(p: TAccess): boolean;
};

export const getMenuBlocks = (): TMenuBlock[] => {
    return [
        {
            title: "Обучение",
            items: [
                {
                    label: "Предметы / Разделы / Подразделы / Темы",
                    path: "/subjects",
                    hasAccess: (p) => p.content.hierarchy.viewTree,
                },
                { label: "Классы", path: "/grades", hasAccess: (p) => p.content.grades.viewList },
                { label: "Экзамены", path: "/exams", hasAccess: (p) => p.content.exams.viewList },
                {
                    label: "Планы обучения",
                    path: "/global-study-plans",
                    hasAccess: (p) => p.content.studyPlans.viewList,
                },
            ],
        },
        {
            title: "Контент",
            items: [
                { label: "Конспекты", path: "/synopses", hasAccess: (p) => p.content.synopses.viewList },
                {
                    label: "Конспекты (черновики)",
                    path: "/draft-synopses",
                    hasAccess: (p) => p.content.draftSynopses.viewList,
                },
                { label: "Преамбулы", path: "/preambles", hasAccess: (p) => p.content.synopses.viewList },
                { label: "Полезные материалы", path: "/materials", hasAccess: (p) => p.content.synopses.viewList },
                { label: "Видеоролики", path: "/videos", hasAccess: (p) => p.content.videos.viewList },
                { label: "Задачи", path: "/problems", hasAccess: (p) => p.content.problems.viewList },
            ],
        },
        {
            title: "Коммуникация",
            items: [
                { label: "Блог", path: "/blog", hasAccess: (p) => p.content.blogs.viewList },
                {
                    label: "Вопросы и ответы",
                    path: "/questions",
                    hasAccess: (p) => p.communication.questionsAnswers.view,
                },
                { label: "Отзывы", path: "/reviews", hasAccess: (p) => p.communication.reviewsComments.view },
                { label: "Комментарии", path: "/comments", hasAccess: (p) => p.communication.reviewsComments.view },
            ],
        },
        {
            title: "Участники и статистика",
            items: [
                { label: "Список администраторов", path: "/admins", hasAccess: (p) => p.admin.admins.viewList },
                { label: "Список студентов", path: "/students", hasAccess: (p) => p.admin.students.viewList },
                { label: "Статистика по темам", path: "/stats/by-themes", hasAccess: (p) => p.admin.stats.byThemes },
                { label: "Нераспознанные фразы", path: "/unrecognized-words", hasAccess: (p) => p.admin.configs.view },
                { label: "Использование решателей", path: "/stats/solvers", hasAccess: (p) => p.admin.stats.solvers },
            ],
        },
        {
            title: "Оплата",
            items: [
                //
                { label: "Настройка тарифов", path: "/payments/tariffs", hasAccess: (p) => p.admin.tariffs.edit },
                { label: "Промокоды", path: "/payments/promocodes", hasAccess: (p) => p.admin.tariffs.edit },
                { label: "Статистика продаж", path: "/payments/stats", hasAccess: (p) => p.admin.tariffs.edit },
            ],
        },
        {
            title: "Настройки",
            items: [
                { label: "Часто задаваемые вопросы", path: "/faq", hasAccess: (p) => p.admin.faq.view },
                { label: "Шаблоны для решателя", path: "/step-descriptions", hasAccess: (p) => p.admin.configs.view },
                { label: "Величины и размерности", path: "/measures", hasAccess: (p) => p.admin.configs.view },
                { label: "Связь теорем и тем", path: "/theme-knowledge-links", hasAccess: (p) => p.admin.configs.view },
                { label: "Синонимы в геометрии", path: "/geometry-synonyms", hasAccess: (p) => p.admin.configs.view },
                {
                    label: "Перегенерация вариантов",
                    path: "/regenerate-variants",
                    hasAccess: (p) => p.admin.regenerateVariants,
                },
            ],
        },
        {
            title: "Обратите внимание",
            items: [
                { label: "Все", path: "/attention", hasAccess: (p) => p.content.hierarchy.viewWarnings },
                { label: "Разделы", path: "/attention/sections", hasAccess: (p) => p.content.hierarchy.viewWarnings },
                {
                    label: "Подразделы",
                    path: "/attention/subsections",
                    hasAccess: (p) => p.content.hierarchy.viewWarnings,
                },
                { label: "Темы", path: "/attention/themes", hasAccess: (p) => p.content.hierarchy.viewWarnings },
                { label: "Конспекты", path: "/attention/synopses", hasAccess: (p) => p.content.synopses.viewWarnings },
                {
                    label: "Метатеги и индексация",
                    path: "/attention/indexing",
                    hasAccess: (p) => p.admin.indexing.viewWarnings,
                },
            ],
        },
        {
            title: "Архивные страницы",
            style: "transparent",
            items: [
                { label: "Игнорируемые слова", path: "/ignored-words", hasAccess: (p) => p.admin.configs.view },
                { label: "Текстовые замены в формулах", path: "/replacements", hasAccess: (p) => p.admin.configs.view },
            ],
        },
    ];
};
