import type { ISerializedModule } from "./types";
import type { TSerializedElementPrototype } from "../../core/element";
import type { IElementVisitor } from "../../core/element/IElementVisitor";
import type { IStrategy } from "../../core/strategies";
import type { IMultiContainerElement } from "../../core/strategies/types";
import type { InputService } from "../../services";
import type { TContainerElements, TElementDeserializerFunction } from "../../types";

import { ContainerModel } from "../../core/container";
import { BaseElementModel } from "../../core/element";
import { MultiContainerElementStrategy } from "../../core/strategies/MultiContainerElementStrategy";

export class ModuleModel extends BaseElementModel<ISerializedModule> implements IMultiContainerElement {
    public readonly content: ContainerModel;

    public constructor(inputService: InputService, content: TContainerElements, uuid?: string) {
        super(inputService, uuid);

        this.content = new ContainerModel(content, this, ["content"], inputService, {
            showPlaceholderIfEmpty: true,
        });
    }

    public serialize(): ISerializedModule {
        return {
            type: "module",
            content: this.content.serialize(),
            uuid: this.uuid,
        };
    }

    public static deserialize: TElementDeserializerFunction<ISerializedModule> = (
        { inputService },
        { content, uuid }
    ) => new ModuleModel(inputService, inputService.deserializeContainer(content), uuid);

    getContainersToMoveCursorBetween(): ContainerModel[] {
        return [this.content];
    }

    protected override initBehaviour(): IStrategy {
        return new MultiContainerElementStrategy(this);
    }

    public accept<R>(visitor: IElementVisitor<R>): R {
        return visitor.doWithModule(this);
    }

    serializeAsClone(): TSerializedElementPrototype<ISerializedModule> {
        const { uuid, ...clone } = this.serialize();
        return {
            ...clone,
            content: this.content.serializeAsClone(),
        };
    }

    override getWrapperContainer(): ContainerModel | null {
        return this.content;
    }
}
