import { makeObservable, observable } from "mobx";

import type { TToolbarIcons } from "./icons";
import type { ToolbarMenu } from "./ToolbarMenu";

import { ButtonStates } from "./ButtonStates";

export interface IToolbarButtonParams {
    icon: TToolbarIcons;
    state?: ButtonStates;
    title?: string;
    nestedMenu?: ToolbarMenu;
    key?: string;
    overrideNestedMenuHandler?: boolean;
}

export class ToolbarButton {
    icon: TToolbarIcons;
    state: ButtonStates;
    title?: string;
    nestedMenu?: ToolbarMenu;
    key: string;
    disableNestedMenuHandler: boolean;

    constructor(params: IToolbarButtonParams) {
        this.icon = params.icon;
        this.state = params.state ?? ButtonStates.default;
        this.title = params?.title;
        this.nestedMenu = params?.nestedMenu;
        this.key = params.key ?? params.icon;
        this.disableNestedMenuHandler = params.overrideNestedMenuHandler ?? false;

        makeObservable(this, {
            icon: observable,
            state: observable,
            title: observable,
            nestedMenu: observable,
        });
    }
}
