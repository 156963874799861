import { action, computed, makeObservable, observable } from "mobx";

import type { BaseFormNode, IFormNodeVisitor } from "./BaseFormNode";

import { BaseValueFormNode } from "./BaseValueFormNode";

export class SelectGroupedFormNode extends BaseValueFormNode {
    @observable currentValue: string | null = null;
    @observable.shallow itemsGrouped: TSelectGroupedFormNodeItem[] = [];

    constructor(
        initialValue: string | null,
        label: string,
        itemsGrouped: TSelectGroupedFormNodeItem[],
        parent: BaseFormNode | null,
        path: string[],
        extensions: string[]
    ) {
        super(label, parent, path, extensions);

        this.itemsGrouped = itemsGrouped;
        this.currentValue = initialValue;

        makeObservable(this);
    }

    @action.bound
    setItems(itemsGrouped: TSelectGroupedFormNodeItem[]) {
        this.itemsGrouped = itemsGrouped;
    }

    @action.bound
    setValue(value: string | null) {
        this.currentValue = value;
    }

    @computed get currentItem(): TSelectFormNodeItem | null {
        const currentValue = this.currentValue;
        return this.itemsGrouped.flatMap((group) => group.items).find((item) => item.value === currentValue) ?? null;
    }

    accept<R>(visitor: IFormNodeVisitor<R>): R {
        return visitor.withSelectGrouped(this);
    }

    getValue(): unknown {
        return this.currentValue;
    }

    trySetValue(value: unknown): boolean {
        const item = this.itemsGrouped.flatMap((group) => group.items).find((item) => item.value === value);
        if (item) {
            this.currentValue = item.value;
            return true;
        }
        return false;
    }

    valueEqualsTo(value: unknown): boolean {
        return this.currentValue === value;
    }
}

export type TSelectGroupedFormNodeItem = {
    label: string;
    items: TSelectFormNodeItem[];
};

export type TSelectFormNodeItem = {
    label: string;
    value: string;
};
