import type { TAnyAction } from "../types";

export type TClipboardActionType = "copy" | "paste" | "cut";

export type TClipboardAction = {
    type: "clipboard";
    action: TClipboardActionType;
};

export function createClipboardAction(action: TClipboardActionType): TClipboardAction {
    return { type: "clipboard", action };
}

export function isClipboardAction(action: TAnyAction): action is TClipboardAction {
    return action.type === "clipboard";
}
