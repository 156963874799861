import cn from "classnames";
import { observer } from "mobx-react-lite";
import React from "react";

import type { PropsWithChildren } from "react";

import styles from "./GoalsFrame.module.scss";

type Props = PropsWithChildren<{
    className?: string;
    everyClassName?: string;
    disableShadows?: boolean;
}>;

export const GoalsFrame = observer(function GoalsFrame({ className, children, disableShadows, everyClassName }: Props) {
    return (
        <div className={cn(className, styles.wrapper, disableShadows && styles.disableShadows, everyClassName)}>
            <div className={cn(styles.outer, everyClassName)}>
                <div className={cn(styles.inner, everyClassName)}>{children}</div>
            </div>
        </div>
    );
});
