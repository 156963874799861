import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";

import { tryParseFloat } from "@viuch/shared/serialization/numbers";
import { isDefined } from "@viuch/shared/utils/data/isDefined";

import { BaseInput } from "./BaseInput";

import styles from "./NumberInput.module.scss";

type Props = {
    title?: string;
    value: number;
    onChange: (value: number) => void;
    className?: string;
    min?: number;
    max?: number;
    step?: number;
    error?: string;
    inline?: boolean;
    disabled?: boolean;
};

export const NumberInput: React.VFC<Props> = observer(function NumberInput({
    title,
    value,
    onChange,
    className,
    min,
    max,
    error,
    inline,
    disabled,
}) {
    const [textValue, setTextValue] = useState(value.toString());

    useEffect(() => {
        setTextValue(value.toString());
    }, [value]);

    const formatNumber = () => {
        let newValue: number | null = tryParseFloat(textValue);

        if (Number.isNaN(newValue) || newValue === null) {
            setTextValue(value.toString());
            return;
        }

        if (isDefined(min) && isDefined(max)) {
            newValue = Math.max(min, Math.min(newValue, max));
        } else if (isDefined(min)) {
            newValue = Math.max(min, newValue);
        } else if (isDefined(max)) {
            newValue = Math.min(newValue, max);
        }

        onChange(newValue);
        setTextValue(newValue.toString());
    };

    return (
        <BaseInput
            title={title}
            className={className}
            error={error}
            inline={inline}
        >
            <input
                className={styles.input}
                // Used as solution:
                // https://stackoverflow.com/questions/43687964/only-numbers-input-number-in-react
                type="tel"
                pattern="^-?[0-9]\d*\.?\d*$"
                autoComplete="one-time-code"
                value={textValue}
                onChange={(e) => setTextValue(e.target.value)}
                onBlur={formatNumber}
                disabled={disabled}
            />
        </BaseInput>
    );
});
