export class Vec {
    x: number;
    y: number;

    constructor(x: number, y: number) {
        this.x = x;
        this.y = y;
    }

    add(other: Vec): Vec {
        return new Vec(this.x + other.x, this.y + other.y);
    }

    sub(other: Vec): Vec {
        return new Vec(this.x - other.x, this.y - other.y);
    }

    mul(x: number, y: number): Vec {
        return new Vec(this.x * x, this.y * y);
    }
}

export class Ellipse {
    center: Vec;
    a: number;
    b: number;

    constructor(center: Vec, a: number, b: number) {
        this.center = center;
        this.a = a;
        this.b = b;
    }
}

export function ellipseIntersection(one: Ellipse, other: Ellipse): Array<Vec> {
    // Transform coordinates in such way, that `one` is circle with radius 1 and center in (0; 0)
    const ellipse = new Ellipse(
        other.center.sub(one.center).mul(1 / one.a, 1 / one.b),
        other.a / one.a,
        other.b / one.b
    );

    // in this coordinates intersection point (sin(t), cos(t))
    // (cos(f) - x_0)**2 / a**2 + (sin(f) - y_0)**2 / b**2 = 1
    // Replacing `sin(f) = 2t/(1 + t**2)`; `cos(f)=(1 - t**2)/(1 + t**2)`
    // We get following equation:
    // (-a**2*b**2 + a**2*y_0**2 + b**2*x_0**2 + 2*b**2*x_0 + b**2)*t**4
    // + (-4*a**2*y_0)*t**3
    // + (-2*a**2*b**2 + 2*a**2*y_0**2 + 4*a**2 + 2*b**2*x_0**2 - 2*b**2)*t**2
    // + (-4*a**2*y_0)*t
    // + (- a**2*b**2 + a**2*y_0**2 + b**2*x_0**2 - 2*b**2*x_0 + b**2)

    const a = ellipse.a;
    const b = ellipse.b;
    const x_0 = ellipse.center.x;
    const y_0 = ellipse.center.y;
    const coefficients = [
        -(a ** 2 * b ** 2) + a ** 2 * y_0 ** 2 + b ** 2 * x_0 ** 2 + 2 * b ** 2 * x_0 + b ** 2,
        -4 * a ** 2 * y_0,
        -2 * a ** 2 * b ** 2 + 2 * a ** 2 * y_0 ** 2 + 4 * a ** 2 + 2 * b ** 2 * x_0 ** 2 - 2 * b ** 2,
        -4 * a ** 2 * y_0,
        -(a ** 2 * b ** 2) + a ** 2 * y_0 ** 2 + b ** 2 * x_0 ** 2 - 2 * b ** 2 * x_0 + b ** 2,
    ];
    const t_variants = solvePoly4OrLowerReal(coefficients);

    const result = t_variants.map((t) => {
        const x = (1 - t ** 2) / (1 + t ** 2);
        const y = (2 * t) / (1 + t ** 2);

        return new Vec(x, y).mul(one.a, one.b).add(one.center);
    });
    // Weierstrass_substitution is not valid for point (-1, 0)
    if (Math.abs((ellipse.center.x + 1) ** 2 / ellipse.a ** 2 + ellipse.center.y ** 2 / ellipse.b ** 2 - 1) <= 1e-5) {
        result.push(new Vec(-1, 0).mul(a, b).add(one.center));
    }
    return result;
}

class Complex {
    re: number;
    im: number;

    constructor(re = 0, im = 0) {
        this.re = re;
        this.im = im;
    }

    add(other: Complex): Complex {
        return new Complex(this.re + other.re, this.im + other.im);
    }

    mul(other: Complex): Complex {
        return new Complex(this.re * other.re - this.im * other.im, this.re * other.im + this.im * other.re);
    }

    div(other: Complex): Complex {
        return this.mul(other.inverse());
    }

    inverse(): Complex {
        return new Complex(this.re / this.abs(), -this.im / this.abs());
    }

    abs(): number {
        return this.re ** 2 + this.im ** 2;
    }

    sub(other: Complex): Complex {
        return new Complex(this.re - other.re, this.im - other.im);
    }

    power(power: number): Complex {
        const mag2 = this.abs();
        const phi = Math.atan2(this.im, this.re);
        const new_mag = Math.pow(mag2, power / 2);
        return new Complex(new_mag * Math.cos(phi * power), new_mag * Math.sin(phi * power));
    }

    nth_roots(power: number): Array<Complex> {
        if (this.abs() <= 1e-16) {
            return [new Complex()];
        }

        const mag2 = this.abs();
        const phi = Math.atan2(this.im, this.re) / power;
        const new_mag = Math.pow(mag2, 1 / (power * 2));

        const result = [];
        for (let i = 0; i < power; ++i) {
            result.push(
                new Complex(
                    new_mag * Math.cos(phi + (2 * Math.PI * i) / power),
                    new_mag * Math.sin(phi + (2 * Math.PI * i) / power)
                )
            );
        }
        return result;
    }
}

function solvePoly4OrLowerReal(coefficients: Array<number>): Array<number> {
    if (coefficients[0] === 0 && coefficients[1] === 0 && coefficients[2] === 0) {
        return [-coefficients[4] / coefficients[3]];
    }
    let result: Complex[];
    if (coefficients[0] === 0 && coefficients[1] === 0) {
        result = solvePoly2(coefficients.slice(3).map((x) => new Complex(x / coefficients[2])));
    } else if (coefficients[0] === 0) {
        result = solvePoly3(coefficients.slice(2).map((x) => new Complex(x / coefficients[1])));
    } else {
        result = solvePoly4(coefficients.slice(1).map((x) => new Complex(x / coefficients[0])));
    }

    return result.filter((x) => Math.abs(x.im) <= 1e-5).map((x) => x.re);
}

function solvePoly4(coefficients: Array<Complex>): Array<Complex> {
    // Ferrari method
    // https://encyclopediaofmath.org/wiki/Ferrari_method
    const a = coefficients[0];
    const b = coefficients[1];
    const c = coefficients[2];
    const d = coefficients[3];

    const p = complex_mul([new Complex(-3 / 8), a, a]) // -3*a**2/8 + b
        .add(b);
    const q = complex_mul([a, a, a, new Complex(1 / 8)]) // a**3/8 - a*b/2 + c
        .sub(complex_mul([a, b, new Complex(1 / 2)]))
        .add(c);
    const r = complex_mul([new Complex(-3 / 256), a, a, a, a]) // -3*a**4/256 + a**2*b/16 - a*c/4 + d
        .add(complex_mul([new Complex(1 / 16), a, a, b]))
        .sub(complex_mul([new Complex(1 / 4), a, c]))
        .add(d);

    const alphas = solvePoly3([
        p,
        complex_mul([new Complex(1 / 4), p, p]).sub(r),
        complex_mul([new Complex(-1 / 8), q, q]),
    ]).filter((alpha) => alpha.abs() >= 1e-5);
    if (alphas.length === 0) {
        return [];
    }
    const alpha = alphas[0];

    const x_0 = q.div(alpha).mul(new Complex(1 / 4));

    const sqrts = alpha.mul(new Complex(2)).nth_roots(2);
    return sqrts
        .map((alpha_r) =>
            solvePoly2([
                alpha_r.mul(new Complex(-1)),
                alpha_r
                    .mul(x_0)
                    .add(p.mul(new Complex(1 / 2)))
                    .add(alpha),
            ])
        )
        .reduce((a, b) => a.concat(b), [])
        .map((x) => x.sub(a.mul(new Complex(1 / 4)))); // To initial system
}

function solvePoly2(coefficients: Array<Complex>): Array<Complex> {
    const b = coefficients[0].mul(new Complex(1 / 2));
    const c = coefficients[1];

    const d = b.mul(b).sub(c);
    return d.nth_roots(2).map((d_sqrt) => d_sqrt.sub(b));
}

function solvePoly3(coefficients: Array<Complex>): Array<Complex> {
    // Cardano method
    // https://encyclopediaofmath.org/wiki/Cubic_equation
    // https://encyclopediaofmath.org/wiki/Cardano_formula
    const b = coefficients[0];
    const c = coefficients[1];
    const d = coefficients[2];

    const p = complex_mul([new Complex(-1 / 3), b, b])
        .add(c)
        .mul(new Complex(1 / 3));
    const q = complex_mul([new Complex(2 / 27), b, b, b])
        .sub(complex_mul([new Complex(1 / 3), b, c]))
        .add(d)
        .mul(new Complex(1 / 2));
    const alpha_beta = complex_mul([p, p, p])
        .add(complex_mul([q, q]))
        .nth_roots(2)
        .map((r) => r.sub(q));

    const alpha = alpha_beta[0];
    const beta = alpha_beta[alpha_beta.length - 1];

    const roots = [];
    for (const alpha_root of alpha.nth_roots(3)) {
        for (const beta_root of beta.nth_roots(3)) {
            if (alpha_root.mul(beta_root).add(p).abs() <= 1e-5) {
                roots.push(alpha_root.add(beta_root));
            }
        }
    }
    return roots.map((y) => y.sub(b.mul(new Complex(1 / 3))));
}

function complex_mul(coefficients: Array<Complex>): Complex {
    return coefficients.reduce((one, other) => one.mul(other), new Complex(1));
}
