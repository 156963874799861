import { computed, makeObservable, observable } from "mobx";
import { createTransformer } from "mobx-utils";

import { areSamePoints } from "@viuch/geometry-lib/check-geometry";
import { getLineFromFragment } from "@viuch/geometry-lib/solvers";

import type { TEllipseWithTangent, TEllipseWithTangents } from "./types";
import type { BaseElement } from "../../elements";
import type { Figure2DController } from "../../Figure2DController";
import type { ToolbarMenu } from "../../toolbar";
import type { IUserPointer } from "../../viewport/types";
import type { TEllipse, TPoint } from "@viuch/geometry-lib/types";
import type React from "react";

import { DotElement } from "../../elements/dot";
import { ElementColor } from "../../elements/ElementColor";
import { EllipseElement } from "../../elements/ellipse";
import { LineElement } from "../../elements/line";
import { EllipseModel } from "../../models/circle";
import { LabelPointModel } from "../../models/label-point";
import { PointModel } from "../../models/point";
import { TangentLineToCircleModel } from "../../models/tangent";
import { getTangentPoints } from "../../models/tangent/tangent-utils";
import { ButtonStates, ToolbarButton } from "../../toolbar";
import { TooltipMenu } from "../../toolbar/tooltip";
import { createDefaultToolbarMenuBuilder } from "../../utils/toolbar";
import { BaseFlow } from "../BaseFlow";
import { createMapModelToElements, opacifyColor, z } from "../utils";

import { getAllCircles } from "./tangent-utils";

export class TangentToCircleInteractiveFlow extends BaseFlow {
    flow:
        | {
              stage: 1;
              point?: TPoint;
              drawing: boolean;
          }
        | {
              stage: 2;
              readonly point: TPoint;
              tangentPoint?: TPoint;
              drawing: boolean;
          };

    constructor(data: Figure2DController) {
        super(data);

        this.flow = { stage: 1, drawing: false };

        makeObservable(this, {
            flow: observable,
            modelElements: computed,
            availableTangents: computed,
            selectedTangent: computed,
        });
    }

    attach(): void {
        this.viewport.disable();
        this.snap.addViewportGrid().addFigureModelsPoints();
    }

    dispose(): void {
        this.snap.clean();
    }

    override getTooltipMenu(): TooltipMenu | null {
        let message: string | undefined;

        if (this.flow.stage === 1) {
            if (this.flow.point) {
                if (this.availableTangents && this.availableTangents.length > 0) {
                    message = "Выберите точку, через которую проходит касательная";
                } else {
                    message = "Невозможно построить касательную через эту точку";
                }
            } else {
                message = "Выберите точку за пределами окружности";
            }
        } else if (this.flow.tangentPoint) {
            message = "Готово! Нажмите, чтобы завершить построение";
        } else {
            message = "Выберите точку на окружности или эллипсе, через которую проходит касательная";
        }

        const cancelButton = new ToolbarButton({ icon: "remove", key: "cancel" });
        return new TooltipMenu(message, [cancelButton]);
    }

    override handleViewportPointerEvent(e: React.PointerEvent, pointer: IUserPointer): void {
        if (e.type === "pointercancel") return this.nextFlow();
        if (!e.isPrimary) return;

        const point = this.snap.snap(pointer.canvas, void 0);

        this.updatePointer(e, point);
        this.startDrawing(e);
        this.endDrawing(e);
    }

    private updatePointer(e: React.PointerEvent, point?: TPoint) {
        if (e.type === "pointerdown" || e.type === "pointermove") {
            switch (this.flow.stage) {
                case 1:
                    this.flow.point = point || void 0;
                    break;
                case 2:
                    this.flow.tangentPoint = point || void 0;
                    break;
            }
        }
    }

    private startDrawing(e: React.PointerEvent) {
        if (e.type === "pointerdown") {
            this.flow.drawing = true;
        }
    }

    private endDrawing(e: React.PointerEvent) {
        if (e.type === "pointerup") {
            this.flow.drawing = false;
            switch (this.flow.stage) {
                case 1:
                    if (this.flow.point) {
                        this.flow = {
                            stage: 2,
                            drawing: false,
                            point: this.flow.point,
                        };

                        const available = this.availableTangents;

                        if (!available?.length) {
                            this.nextFlow();
                            return;
                        }

                        this.snap.clean();
                        for (const {
                            tangents: [t1, t2],
                        } of available) {
                            this.snap.addPoint(t1).addPoint(t2);
                        }
                    }
                    break;
                case 2:
                    if (this.selectedTangent) {
                        const { point } = this.flow;
                        const { ellipse, forwardDirection, tangent } = this.selectedTangent;
                        this.rawSave(point, ellipse, forwardDirection, tangent);
                    }

                    this.nextFlow();
                    break;
            }
        }
    }

    private rawSave(vertexPoint: TPoint, circle: TEllipse, forwardDirection: boolean, pointOnCircle: TPoint) {
        this.figure.insertModels(function* () {
            yield TangentLineToCircleModel.create({
                point: vertexPoint,
                circle,
                forwardDirection,
            });
            yield EllipseModel.create({ ...circle, style: null });
            yield PointModel.create({ ...circle.center, style: null });
            yield PointModel.create({ ...vertexPoint, style: null });

            for (const point of [vertexPoint, circle.center, pointOnCircle]) {
                yield LabelPointModel.createNext(point, this.figure);
            }
        }, this);
    }

    get modelElements(): BaseElement[] {
        return this.figure.models.flatMap(this.mapModel);
    }

    protected renderElements(): BaseElement[] {
        return [...this.modelElements, ...this.getTempElements()];
    }

    private mapModel = createTransformer(createMapModelToElements(opacifyColor));

    get availableTangents(): TEllipseWithTangents[] | null {
        const { point } = this.flow;
        if (!point) return null;

        const ellipses = getAllCircles(this.figure);

        return ellipses
            .map<TEllipseWithTangents | null>((ellipse) => {
                try {
                    return {
                        ellipse,
                        tangents: getTangentPoints(point, ellipse),
                    };
                } catch {
                    return null;
                }
            })
            .filter<TEllipseWithTangents>(Boolean);
    }

    get selectedTangent(): TEllipseWithTangent | null {
        if (this.flow.stage !== 2 || !this.flow.tangentPoint || !this.availableTangents) return null;

        const { tangentPoint } = this.flow;

        for (const { ellipse, tangents } of this.availableTangents) {
            if (areSamePoints(tangentPoint, tangents[0])) {
                return { ellipse, tangent: tangents[0], forwardDirection: true };
            }
            if (areSamePoints(tangentPoint, tangents[1])) {
                return { ellipse, tangent: tangents[1], forwardDirection: false };
            }
        }

        return null;
    }

    private *getTempElements(): Iterable<BaseElement> {
        const { stage } = this.flow;

        if (this.flow.point && this.availableTangents) {
            const { point } = this.flow;
            for (let i = 0; i < this.availableTangents.length; i++) {
                const { tangents } = this.availableTangents[i];

                yield new DotElement({
                    id: `temp_${i}_p1`,
                    x: tangents[0].x,
                    y: tangents[0].y,
                    color: ElementColor.Building,
                    overrideRenderOrder: z.points.priority + 1,
                });

                yield new DotElement({
                    id: `temp_${i}_p2`,
                    x: tangents[1].x,
                    y: tangents[1].y,
                    color: ElementColor.Building,
                    overrideRenderOrder: z.points.priority + 1,
                });

                const line1 = getLineFromFragment(point, tangents[0]);
                const line2 = getLineFromFragment(point, tangents[1]);

                yield new LineElement({
                    id: `temp_${i}_line1`,
                    x1: line1.a.x,
                    y1: line1.a.y,
                    x2: line1.b.x,
                    y2: line1.b.y,
                    color: ElementColor.Util,
                    overrideRenderOrder: z.fragments.priority + 1,
                });

                yield new LineElement({
                    id: `temp_${i}_line2`,
                    x1: line2.a.x,
                    y1: line2.a.y,
                    x2: line2.b.x,
                    y2: line2.b.y,
                    color: ElementColor.Util,
                    overrideRenderOrder: z.fragments.priority + 1,
                });
            }
        }

        if (stage === 1) {
            const { point } = this.flow;
            if (!point) return;

            yield new DotElement({
                id: `temp_vertex`,
                x: point.x,
                y: point.y,
                color: ElementColor.Building,
                overrideRenderOrder: z.points.priority,
            });

            return;
        }

        if (stage === 2) {
            const { point } = this.flow;
            yield new DotElement({
                id: `temp_vertex`,
                x: point.x,
                y: point.y,
                color: ElementColor.Building,
                overrideRenderOrder: z.points.priority,
            });

            if (!this.selectedTangent) return;

            const { ellipse, tangent } = this.selectedTangent;

            yield new DotElement({
                id: `temp_current_point`,
                x: tangent.x,
                y: tangent.y,
                color: ElementColor.Selected,
                overrideRenderOrder: z.points.priority,
            });

            yield new LineElement({
                id: `temp_current_lint`,
                x1: point.x,
                y1: point.y,
                x2: tangent.x,
                y2: tangent.y,
                color: ElementColor.Selected,
                overrideRenderOrder: z.fragments.priority,
            });

            const { center, rx, ry } = ellipse;

            yield new EllipseElement({
                id: `temp_current_ellipse`,
                center,
                rx,
                ry,
                color: ElementColor.Building,
                overrideRenderOrder: z.circles.priority,
            });
        }
    }

    static create(data: Figure2DController) {
        return new TangentToCircleInteractiveFlow(data);
    }

    override getToolbarMenu(): ToolbarMenu {
        return createDefaultToolbarMenuBuilder()
            .setButtonState(ButtonStates.active, ["fragments"])
            .setButtonIcon("tangent", ["fragments"])
            .build();
    }
}
