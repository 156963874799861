import { copyPoint } from "@viuch/geometry-lib/factories";

import type { IElementVisitor } from "../BaseElement";
import type { ElementColor } from "../ElementColor";
import type { TPoint } from "@viuch/geometry-lib/types";

import { BaseElement } from "../BaseElement";

export interface IEllipseElement {
    id: string;
    center: TPoint;
    rx: number;
    ry: number;
    color?: ElementColor;
    thin?: boolean;
    overrideRenderOrder: number;
}

export class EllipseElement extends BaseElement implements IEllipseElement {
    center: TPoint;
    rx: number;
    ry: number;
    thin: boolean;

    constructor(data: IEllipseElement) {
        super(data);

        this.center = copyPoint(data.center);
        this.rx = data.rx;
        this.ry = data.ry;
        this.thin = !!data.thin;
    }

    accept<R>(visitor: IElementVisitor<R>): R {
        return visitor.withEllipse(this);
    }
}
