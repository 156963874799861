import cn from "classnames";
import { observer } from "mobx-react-lite";
import React from "react";

import type { Graph2DToolbar } from "../../core/Graph2DToolbar";
import type { IToolbarElement } from "../toolbarTypes";
import type { RefObject } from "react";

import { AnyToolbarElement } from "../AnyToolbarElement";

import styles from "./RootToolbarElement.module.scss";

type Props = {
    toolbar: Graph2DToolbar;
    elements: IToolbarElement[];
    topElements: IToolbarElement[];
    rootRef: RefObject<HTMLDivElement>;
    className?: string;
};

export const RootToolbarElement = observer(function RootToolbarElement({
    toolbar,
    elements,
    topElements,
    rootRef,
    className,
}: Props) {
    return (
        <>
            <div className={cn(styles.topContainer, className)}>
                {topElements.map((element, i) => (
                    <AnyToolbarElement
                        key={element.key ?? i}
                        toolbar={toolbar}
                        element={element}
                        rootRef={rootRef}
                        className={styles.toopItem}
                    />
                ))}
            </div>
            <div className={cn(styles.leftContainer, className)}>
                <div className={styles.rect} />
                {elements.map((element, i) => (
                    <AnyToolbarElement
                        key={element.key ?? i}
                        toolbar={toolbar}
                        element={element}
                        rootRef={rootRef}
                        className={styles.item}
                    />
                ))}
            </div>
        </>
    );
});

export interface IRootToolbarElement extends IToolbarElement {
    type: "root";
    elements: IToolbarElement[];
    topElements: IToolbarElement[];
}
