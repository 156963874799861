import type { TSerializedState } from "@viuch/math-editor";

export class StudentSolutionVariant {
    readonly id: number;
    readonly formula: TSerializedState;
    readonly themeId: number;
    readonly themeName: string;

    constructor(id: number, formula: TSerializedState, themeId: number, themeName: string) {
        this.id = id;
        this.formula = formula;
        this.themeId = themeId;
        this.themeName = themeName;
    }
}
