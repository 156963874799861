import { normalizeNumber } from "@viuch/shared/utils/math/normalize";
import { assert } from "@viuch/utils/debug";

import type { RefObject } from "react";

export function convertClientXToDotPosition(clientX: number, lineRef: RefObject<HTMLElement>): number {
    const node = lineRef.current;
    assert(node);

    const { left, width } = node.getBoundingClientRect();
    assert(width > 0);

    const position = (clientX - left) / width;
    return normalizeNumber(position, { min: 0, max: 1 });
}
