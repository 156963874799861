import type { TAngle, TLine, TPoint } from "./types";

import { getLineAngle, getMiddleAngle, getUnitVectorFromAngle } from "./angles";
import { createLine } from "./factories";
import { intersectionLines } from "./intersection";
import { addVectors } from "./vectors";

export function getBisectionPoint(angle: TAngle): TPoint | null {
    const bisectionLine = getBisectionLine(angle);
    return intersectionLines(bisectionLine, createLine(angle.start, angle.end))!;
}

export function getBisectionLine(angle: TAngle): TLine {
    const line1 = createLine(angle.vertex, angle.start);
    const line2 = createLine(angle.vertex, angle.end);

    const angle2 = getLineAngle(line2, true);
    const angle1 = getLineAngle(line1, true);
    const angleMiddle = getMiddleAngle(angle1, angle2);
    const vector = getUnitVectorFromAngle(angleMiddle);

    const bisectionPoint = addVectors(angle.vertex, vector);
    return createLine(angle.vertex, bisectionPoint);
}
