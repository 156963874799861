import { charActionCreator } from "../utils";

import { makeMathCharsRowFromAlphabet } from "./utils";

export const [ruCharsRow1, ruCharsRow2, ruCharsRow3] = ["йцукенгшщзх", "фывапролджэ", "ячсмитьбю"].map((chars) =>
    makeMathCharsRowFromAlphabet(chars, ["alphabet", "alphabet-ru"])
);
ruCharsRow1[4].children = [
    {
        actionCreator: charActionCreator("е"),
        text: "е",
        tags: ["alphabet", "alphabet-ru"],
    },
    {
        actionCreator: charActionCreator("ё"),
        text: "ё",
        tags: ["alphabet", "alphabet-ru"],
    },
];
ruCharsRow3[6].children = [
    {
        actionCreator: charActionCreator("ъ"),
        text: "ъ",
        tags: ["alphabet", "alphabet-ru"],
    },
    {
        actionCreator: charActionCreator("ь"),
        text: "ь",
        tags: ["alphabet", "alphabet-ru"],
    },
];
