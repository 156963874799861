import { useEffect, useRef } from "react";

import { assert } from "@viuch/utils/debug";

import type { RefObject } from "react";

import styles from "./InstrumentGrid.module.scss";

type Props = {
    startRef?: RefObject<HTMLElement>;
    steps: number;
};

export const InstrumentGrid = ({ startRef, steps }: Props) => {
    const gridRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const grid = gridRef.current!;
        const start = startRef?.current ?? grid;

        assert(start, "Start element should exists");

        const observer = new ResizeObserver(() => {
            const { width, left, top } = start.getBoundingClientRect();
            const { x, y } = grid.getBoundingClientRect();

            const stepSize = width / (steps - 1);
            const offsetX = Math.abs(x - left);
            const offsetY = Math.abs(y - top);

            grid.style.setProperty("visibility", "visible");
            grid.style.setProperty("--gridSize", `${stepSize}px`);
            grid.style.setProperty("--gridOffsetLeft", `${offsetX}px`);
            grid.style.setProperty("--gridOffsetTop", `${offsetY}px`);
        });

        observer.observe(start);

        return () => {
            observer.disconnect();
        };
    }, [startRef, steps]);

    return (
        <div
            ref={gridRef}
            className={styles.grid}
        />
    );
};
