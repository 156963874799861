import { action, flow, makeObservable, observable } from "mobx";

import type { IUploadImageService } from "./types";
import type { PictureInstrumentSettingsImpl } from "../settings/PictureInstrumentSettingsImpl";
import type { TPictureWatermarkPosition } from "@viuch/instrument-picture/statement";

import { MAX_WATERMARKS_COUNT } from "../settings/constants";
import { PictureInstrumentSettingsWatermark } from "../settings/PictureInstrumentSettingsWatermark";

export class PictureInstrumentSettingsEditorVm {
    private readonly picture: PictureInstrumentSettingsImpl;
    private readonly uploadService: IUploadImageService;

    @observable isLoading = false;

    constructor(picture: PictureInstrumentSettingsImpl, uploadService: IUploadImageService) {
        this.picture = picture;
        this.uploadService = uploadService;

        makeObservable(this);
    }

    @flow.bound
    async *downloadImage(file: File) {
        try {
            this.isLoading = true;
            const { uuid, url } = await this.uploadService.uploadImage(file);
            yield;

            this.picture.setImage({ url, uuid });
        } catch (e) {
            yield;
            console.error(e);
            alert("Произошла ошибка");
        } finally {
            this.isLoading = false;
        }
    }

    @action.bound
    createWatermark(position: TPictureWatermarkPosition) {
        if (this.picture.watermarks.length >= MAX_WATERMARKS_COUNT) {
            return;
        }

        const newWatermark = PictureInstrumentSettingsWatermark.createEmpty({
            position,
        });

        this.picture.watermarks.push(newWatermark);
    }

    @action.bound
    removeWatermark(watermark: PictureInstrumentSettingsWatermark) {
        this.picture.removeWatermark(watermark);
    }
}
