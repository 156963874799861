import { action, untracked } from "mobx";

import { InputService } from "@viuch/math-editor";

import type { PictureInstrumentSettingsWatermark } from "../../settings/PictureInstrumentSettingsWatermark";

export class PictureWatermarkListItemVm {
    private readonly watermark: PictureInstrumentSettingsWatermark;

    constructor(watermark: PictureInstrumentSettingsWatermark) {
        this.watermark = watermark;
    }

    get valueInput(): InputService {
        const initialState = untracked(() => this.watermark.templateValue);
        return new InputService({ multiline: false, initialState });
    }

    changeColor = action((color: string) => {
        this.watermark.color = color;
    });

    changeValue = action((input: InputService) => {
        this.watermark.templateValue = input.getSerializedState();
    });
}
