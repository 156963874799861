import type { TRawPreambleRequest, TRawPreamble } from "./PreamblesService.types";
import type { Preamble } from "../../entities/preambles/Preamble";

import { BaseCRUDService } from "../base";

import { mapPreambleResponse, serializePreambleRequest, validatePreambleRequest } from "./PreamblesService.utils";

export class PreamblesService extends BaseCRUDService<Preamble, TRawPreambleRequest, TRawPreamble> {
    constructor() {
        super(`preambles`, mapPreambleResponse, serializePreambleRequest, validatePreambleRequest);
    }
}
