export const zIndexValues = {
    point: {
        default: 3000,
        high: 3100,
    },
    label: {
        default: 4000,
        high: 4100,
    },
    path: {
        default: 2000,
        high: 2100,
    },
    util: {
        default: 1000,
        high: 1100,
    },
    interval: {
        default: 2000,
    },
};
