import { formatDuration, intervalToDuration } from "date-fns";
import { ru } from "date-fns/locale";

import type { Interval } from "date-fns";

const MINUTE_LENGTH = 60;
const HOUR_LENGTH = MINUTE_LENGTH * 60;
const DAY_LENGTH = HOUR_LENGTH * 24;

export function expandDuration(durationSeconds: number) {
    const days = Math.floor(durationSeconds / DAY_LENGTH);
    const _hoursSeconds = durationSeconds - days * DAY_LENGTH;
    const hours = Math.floor(_hoursSeconds / HOUR_LENGTH);
    const _minutesSeconds = _hoursSeconds - hours * HOUR_LENGTH;
    const minutes = Math.floor(_minutesSeconds / MINUTE_LENGTH);
    const seconds = Math.floor(_minutesSeconds - minutes * MINUTE_LENGTH);

    return { days, hours, minutes, seconds };
}

export function computeDurationSeconds(days: number, hours: number, minutes: number, seconds: number): number {
    return seconds + minutes * MINUTE_LENGTH + hours * HOUR_LENGTH + days * DAY_LENGTH;
}

export function serializeDuration(durationSeconds: number): string {
    const { days, hours, minutes, seconds } = expandDuration(durationSeconds);

    const h = hours.toString().padStart(2, "0");
    const m = minutes.toString().padStart(2, "0");
    const s = seconds.toString().padStart(2, "0");

    return `${days} ${h}:${m}:${s}`;
}

export function mapDurationToSeconds(durationStr: string): number {
    const regexp1 = /^(\d+) (\d{2}):(\d{2}):(\d{2})/g;
    const result1 = regexp1.exec(durationStr);
    if (result1) {
        const [, d, h, m, s] = result1;

        return computeDurationSeconds(+d, +h, +m, +s);
    }

    const regexp2 = /^(\d{2}):(\d{2}):(\d{2})/g;
    const result2 = regexp2.exec(durationStr);
    if (result2) {
        const [, h, m, s] = result2;

        return computeDurationSeconds(0, +h, +m, +s);
    }

    console.error("Wrong duration format", durationStr);
    return 0;
}

export function formatDurationBetweenDates(leftDate: Date, rightDate: Date): string {
    const interval: Interval = { start: leftDate, end: rightDate };
    const duration = intervalToDuration(interval);

    return formatDuration(duration, {
        locale: ru,
    });
}

export function formatDurationBySeconds(seconds: number): string {
    const interval: Interval = { start: 0, end: seconds * 1000 };
    const duration = intervalToDuration(interval);

    return formatDuration(duration, {
        locale: ru,
    });
}

export function formatShortDurationBySeconds(seconds: number): string {
    const interval: Interval = { start: 0, end: seconds * 1000 };
    const duration = intervalToDuration(interval);

    const timeDuration: Duration = {
        hours: duration.hours,
        minutes: duration.minutes,
        seconds: duration.seconds,
    };

    const dateDuration: Duration = {
        years: duration.years,
        months: duration.months,
        days: duration.days,
    };

    const hasDateDuration = dateDuration.days !== 0 || dateDuration.months !== 0 || dateDuration.years !== 0;

    const dateFormatedDuration = hasDateDuration
        ? formatDuration(dateDuration, {
              format: ["years", "months", "days"],
              zero: false,
              locale: ru,
          }) + " "
        : "";

    const timeFormat = ["hours", "minutes", "seconds"];
    if (timeDuration.hours === 0 && dateFormatedDuration === "") timeFormat.splice(0, 1);

    const timeFormatedDuration = formatDuration(timeDuration, {
        format: timeFormat,
        zero: true,
        delimiter: ":",
        locale: {
            formatDistance: (_, count) => String(count).padStart(2, "0"),
        },
    });

    return dateFormatedDuration + timeFormatedDuration;
}
