import type { TTabButton } from "./TabsBlock.types";

export const tabButtons: TTabButton[] = [
    {
        text: "αβγ",
        tab: "greek",
        tags: ["lang", "lang-greek"],
    },
    {
        text: "abc",
        tab: "en",
        tags: ["lang", "lang-en"],
    },
    {
        text: "абв",
        tab: "ru",
        tags: ["lang", "lang-ru"],
    },
];
