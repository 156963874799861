import type { TLowPriorityOperatorsActionSign } from "../elements/operator-low/types";
import type { TAnyAction } from "../types";

export type TLowPriorityOperatorAction = {
    type: "low-priority-operator";
    sign: TLowPriorityOperatorsActionSign;
};

export function createLowPriorityOperatorAction(sign: TLowPriorityOperatorsActionSign): TLowPriorityOperatorAction {
    return { type: "low-priority-operator", sign };
}

export function isLowPriorityAction(action: TAnyAction): action is TLowPriorityOperatorAction {
    return action.type === "low-priority-operator";
}
