import cn from "classnames";

import type { TMathEditorIconProps } from "./MathEditorIcon.types";
import type { VFC } from "react";

import { mathEditorIconsMap } from "../../constants";

import styles from "./MathEditorIcon.module.scss";

export const MathEditorIcon: VFC<TMathEditorIconProps> = ({ iconName, className }) => {
    const Icon = mathEditorIconsMap[iconName];

    if (Icon) {
        return <Icon className={cn(styles.icon, className)} />;
    }

    return null;
};
