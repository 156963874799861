import { generateUuid } from "@viuch/shared/utils/data";

import type { ISerializedDegree } from "./types";
import type { TAnySerializedElement } from "../../types";

import { createSerializedContainer } from "../../utils/serialization";

export function createSerializedDegree(power: TAnySerializedElement[]): ISerializedDegree {
    return {
        type: "degree",
        uuid: generateUuid(),
        content: createSerializedContainer(power),
    };
}
