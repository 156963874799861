window.addEventListener("error", function (e) {
    console.error(e);
});

import { createRoot } from "react-dom/client";

import "./i18n";
import "./polyfills";
import "./fixes";
import "./configure";
import "./styles/index.scss";

import Root from "./core/Root";

const rootElement = document.getElementById("root")!;
const root = createRoot(rootElement);

root.render(<Root />);
