import { computed, makeObservable, observable } from "mobx";
import { createTransformer } from "mobx-utils";

import type { BaseElement } from "../../elements";
import type { Figure2DController } from "../../Figure2DController";
import type { ToolbarButton, ToolbarMenu } from "../../toolbar";
import type { IUserPointer } from "../../viewport/types";
import type { TPoint } from "@viuch/geometry-lib/types";
import type React from "react";

import { DotElement } from "../../elements/dot";
import { ElementColor } from "../../elements/ElementColor";
import { ButtonStates } from "../../toolbar";
import { TooltipMenu } from "../../toolbar/tooltip";
import { createDefaultToolbarMenuBuilder, handleToolbarButtons } from "../../utils/toolbar";
import { BaseFlow } from "../BaseFlow";
import { createMapModelToElements } from "../utils";

import { CreateLabelDotFlow } from "./CreateLabelDotFlow";

export class CreateDotLabelInteractiveFlow extends BaseFlow {
    flow: {
        point: TPoint | null;
    };

    constructor(data: Figure2DController) {
        super(data);

        this.flow = { point: null };

        makeObservable(this, {
            flow: observable,
            tempElements: computed,
        });
    }

    attach(): void {
        this.snap.addFigureModelsPoints();
    }

    dispose(): void {
        this.snap.clean();
    }

    override handleToolbarButtonClick(menu: ToolbarMenu, button: ToolbarButton): void {
        switch (button.key) {
            case "labels":
                return this.nextFlow();
            default:
                handleToolbarButtons(this, button);
        }
    }

    override handleViewportPointerEvent(e: React.PointerEvent, pointer: IUserPointer): void {
        if (e.type === "pointercancel") return this.nextFlow();
        if (!e.isPrimary) return;

        const point = this.snap.snap(pointer.canvas);

        if (e.type === "pointerdown") {
            e.preventDefault();
            if (point) {
                this.nextFlow((data) => new CreateLabelDotFlow(data, point));
            } else {
                this.nextFlow();
            }
        }

        if (e.type === "pointermove") {
            this.flow.point = point;
        }
    }

    mapModels = createTransformer(createMapModelToElements());

    protected renderElements(): BaseElement[] {
        return [...this.figure.models.flatMap(this.mapModels), ...this.tempElements];
    }

    get tempElements(): BaseElement[] {
        if (this.flow.point) {
            const { x, y } = this.flow.point;
            return [
                new DotElement({
                    id: `temp_dotLabelInteractive`,
                    x,
                    y,
                    color: ElementColor.Selected,
                    overrideRenderOrder: 0,
                }),
            ];
        }
        return [];
    }

    static create(data: Figure2DController) {
        return new CreateDotLabelInteractiveFlow(data);
    }

    override getToolbarMenu(): ToolbarMenu {
        return createDefaultToolbarMenuBuilder()
            .setButtonState(ButtonStates.active, ["labels"])
            .setButtonIcon("label-dot", ["labels"])
            .build();
    }

    override getTooltipMenu(): TooltipMenu | null {
        return new TooltipMenu("Выберите точку");
    }
}
