import { areSamePoints, isRightAngle } from "@viuch/geometry-lib/check-geometry";
import { subtractVectors, vectorLength } from "@viuch/geometry-lib/vectors";
import {
    CircumscribedCircleModel,
    EllipseModel,
    InscribedCircleModel,
} from "@viuch/instrument-figure2d/statement-editor/models/circle";
import { EqualAnglesModel, EqualSegmentsModel } from "@viuch/instrument-figure2d/statement-editor/models/constraints";
import {
    ConstraintLineModel,
    FragmentDashedModel,
    FragmentModel,
    LineModel,
    VectorDashedModel,
    VectorModel,
} from "@viuch/instrument-figure2d/statement-editor/models/fragment";
import {
    AltitudeModel,
    BisectionModel,
    MedianModel,
} from "@viuch/instrument-figure2d/statement-editor/models/geometry";
import { LabelAngleModel } from "@viuch/instrument-figure2d/statement-editor/models/label-angle";
import { LabelFragmentModel } from "@viuch/instrument-figure2d/statement-editor/models/label-fragment";
import { LabelPointModel } from "@viuch/instrument-figure2d/statement-editor/models/label-point";
import { PointModel } from "@viuch/instrument-figure2d/statement-editor/models/point";
import {
    TangentLineOnCircleModel,
    TangentLineToCircleModel,
} from "@viuch/instrument-figure2d/statement-editor/models/tangent";
import { getTangentPoints } from "@viuch/instrument-figure2d/statement-editor/models/tangent/tangent-utils";
import { Polygon, PolygonKinds } from "@viuch/instrument-figure2d-settings/entities/Polygon";
import { assert } from "@viuch/utils/debug";

import type {
    TFigure2DHighlightTag,
    TFigure2DHighlights,
    TSerializedFigure2D,
    TSerializedFigure2DSettings,
} from "../service-types/figure2d";
import type { TBasis, TEllipse, TFragment } from "@viuch/geometry-lib/types";
import type { BaseModel } from "@viuch/instrument-figure2d/statement-editor/models";
import type { TModelStyle } from "@viuch/instrument-figure2d/statement-editor/models/modelStyle";
import type { TPoint } from "@viuch/math-editor/types";

import { SegmentLikeKinds } from "../service-types/figure2d";

export function mapFigurePolygon(polygon: TSerializedFigure2DSettings.Polygon): Polygon {
    const { kind, area, perimeter, points } = polygon;
    return new Polygon({
        kind: mapFigurePolygonKind(kind),
        area,
        value: points,
        perimeter,
    });
}

export function mapFigurePolygonKind(kind: TSerializedFigure2DSettings.PolygonKind | null): PolygonKinds {
    if (!kind) return PolygonKinds.NoType;

    switch (kind) {
        case "rectangle":
            return PolygonKinds.Rectangle;
        case "square":
            return PolygonKinds.Square;
        case "parallelogram":
            return PolygonKinds.Parallelogram;
        case "rhombus":
            return PolygonKinds.Rhomb;
    }
}

export function* createFigureModels(data: TSerializedFigure2D): Iterable<BaseModel> {
    const {
        points,
        segment_likes,
        bisections,
        medians,
        heights,
        ellipses,
        inscribed_circles,
        circumcircles,
        segment_labels,
        angle_labels,
        segment_equalities,
        angle_equalities,
        tangent_lines,
        __highlights,
    } = data;

    const highlights: Partial<TFigure2DHighlights> = __highlights ?? {};

    const pointsMap = new Map<string, TPoint>(points.map((point) => [point.id, point]));

    function* findAllCircles(): Iterable<[string, TEllipse]> {
        for (const ellipse of ellipses) {
            yield [ellipse.id, { center: ellipse.center, rx: ellipse.x_radius, ry: ellipse.y_radius }];
        }

        for (const circle of circumcircles) {
            const center = getPoint(circle.center);
            const radius = vectorLength(subtractVectors(center, getPoint(circle.polygon[0])));

            yield [circle.id, { center, rx: radius, ry: radius }];
        }
    }

    const circlesMap = new Map<string, TEllipse>(findAllCircles());

    function getPoint(pointId: string): TPoint {
        const point = pointsMap.get(pointId);
        assert(point);
        return point;
    }

    function getCircle(circleId: string): TEllipse {
        const circle = circlesMap.get(circleId);
        assert(circle);
        return circle;
    }

    function getFragment(fragment: TFragment<string>): TFragment {
        return { a: getPoint(fragment.a), b: getPoint(fragment.b) };
    }

    const getSegments = (() => {
        let segments = 1;
        return () => segments++;
    })();

    const getAngleSegments = (() => {
        let segments = 1;
        return () => segments++;
    })();

    const getBasis = (segment: TSerializedFigure2DSettings.SegmentLike): TBasis => {
        switch (segment.kind) {
            case SegmentLikeKinds.segment:
            case SegmentLikeKinds.line:
                return { fragment: getFragment(segment) };
            case SegmentLikeKinds.parallel:
                return {
                    offset: getPoint(segment.point),
                    fragment: getFragment(segment.base),
                };
            case SegmentLikeKinds.orthogonal:
                return {
                    offset: getPoint(segment.point),
                    fragment: getFragment(segment.base),
                    angle: Math.PI / 2,
                };
            case SegmentLikeKinds.parallelFragment:
                return {
                    // TODO сериализация для бэка
                    fragment: getFragment(segment.base),
                    offset: getPoint(segment.a),
                };
            case SegmentLikeKinds.vector:
                return {
                    fragment: getFragment(segment),
                };
        }
    };

    const pointsHls = highlights.points ?? [];
    const linesHls = highlights.segments?.filter((segment) => segment.is_line) ?? [];
    const fragmentsHls = highlights.segments?.filter((segment) => !segment.is_line) ?? [];
    const ellipsesHls = highlights.ellipses ?? [];
    const inCirclesHls = highlights.inscribed_circles ?? [];
    const outCirclesHls = highlights.circumcircles ?? [];
    const anglesHls = highlights.angles ?? [];

    function getStyle(tag: TFigure2DHighlightTag | undefined): TModelStyle | null {
        switch (tag) {
            case "attention":
                return tag;
        }

        return null;
    }

    for (const { x, y, label, id } of points) {
        const style = getStyle(pointsHls.find(({ point }) => point === id)?.highlight_tag);

        yield PointModel.create({ x, y, style });
        yield LabelPointModel.create({ x, y, label, style });
    }

    const angle90 = Math.PI / 2;

    for (const segmentLike of segment_likes) {
        const hl = (() => {
            switch (segmentLike.kind) {
                case SegmentLikeKinds.segment:
                case SegmentLikeKinds.vector:
                    return fragmentsHls.find(({ a, b }) => segmentLike.a === a && segmentLike.b === b);
                case SegmentLikeKinds.line:
                    return linesHls.find(({ a, b }) => segmentLike.a === a && segmentLike.b === b);
            }
        })();
        const style = getStyle(hl?.highlight_tag);

        switch (segmentLike.kind) {
            case SegmentLikeKinds.segment: {
                const a = getPoint(segmentLike.a);
                const b = getPoint(segmentLike.b);

                if (segmentLike.stroke_style === "solid") {
                    yield FragmentModel.create({ a, b, style });
                } else if (segmentLike.stroke_style === "dash") {
                    yield FragmentDashedModel.create({ a, b, style });
                }
                break;
            }
            case SegmentLikeKinds.line: {
                const a = getPoint(segmentLike.a);
                const b = getPoint(segmentLike.b);
                yield LineModel.create({ a, b, style });
                break;
            }
            case SegmentLikeKinds.parallel:
            case SegmentLikeKinds.orthogonal: {
                const constraintA = getPoint(segmentLike.base.a);
                const constraintB = getPoint(segmentLike.base.b);
                const vertex = getPoint(segmentLike.point);

                yield ConstraintLineModel.create({
                    constraintA,
                    constraintB,
                    vertex,
                    offsetAngle: segmentLike.kind === SegmentLikeKinds.parallel ? 0 : angle90,
                });
                break;
            }
            case SegmentLikeKinds.vector: {
                const from = getPoint(segmentLike.a);
                const to = getPoint(segmentLike.b);

                if (segmentLike.stroke_style === "solid") {
                    yield VectorModel.create({ from, to, style });
                } else if (segmentLike.stroke_style === "dash") {
                    yield VectorDashedModel.create({ from, to, style });
                }
            }
        }
    }

    for (const { angle, on_segment, point } of bisections) {
        const vertex = getPoint(angle.center);
        const start = getPoint(angle.left);
        const end = getPoint(angle.right);

        const style = getStyle(
            fragmentsHls.find(({ a, b }) => {
                return (angle.center === a && point === b) || (angle.center === b && point === a);
            })?.highlight_tag
        );

        yield BisectionModel.create({
            angle: { start, vertex, end },
            basis: getBasis(on_segment),
            segmentsCount: getSegments(),
            style,
        });
    }

    for (const { from_point, on_segment, point } of medians) {
        const style = getStyle(
            fragmentsHls.find(({ a, b }) => {
                return (from_point === a && point === b) || (from_point === b && point === a);
            })?.highlight_tag
        );

        yield MedianModel.create({
            vertex: getPoint(from_point),
            fragment: getFragment(on_segment),
            segmentsCount: getSegments(),
            style,
        });
    }

    for (const { from_point, on_segment, point } of heights) {
        const style = getStyle(
            fragmentsHls.find(({ a, b }) => {
                return (from_point === a && point === b) || (from_point === b && point === a);
            })?.highlight_tag
        );

        yield AltitudeModel.create({
            vertex: getPoint(from_point),
            basis: getBasis(on_segment),
            style,
        });
    }

    for (const ellipse of ellipses) {
        const style = getStyle(ellipsesHls.find(({ id }) => id === ellipse.id)?.highlight_tag);

        const center = getPoint(ellipse.center.id);
        const rx = ellipse.x_radius;
        const ry = ellipse.y_radius;

        yield EllipseModel.create({ center, rx, ry, style });
    }

    for (const inCircle of inscribed_circles) {
        const style = getStyle(inCirclesHls.find(({ id }) => id === inCircle.id)?.highlight_tag);

        const fragmentsPoints = inCircle.polygon.map(getPoint);

        yield InscribedCircleModel.create({ fragmentsPoints, style });
    }

    for (const outCircle of circumcircles) {
        const style = getStyle(outCirclesHls.find(({ id }) => id === outCircle.id)?.highlight_tag);

        const points = outCircle.polygon.map(getPoint);

        yield CircumscribedCircleModel.create({ points, style });
    }

    for (const { label, segment, alt_origin } of segment_labels) {
        const a = getPoint(segment.a);
        const b = getPoint(segment.b);
        const altOrigin = !!alt_origin;

        const style = getStyle(
            fragmentsHls.find(({ a, b }) => {
                return (segment.a === a && segment.b === b) || (segment.a === b && segment.b === a);
            })?.highlight_tag
        );

        yield LabelFragmentModel.create({ a, b, value: label, altOrigin, style });
    }

    for (const { angle, label } of angle_labels) {
        const style = getStyle(
            anglesHls.find(({ center, left, right }) => {
                return angle.center === center && angle.left === left && angle.right === right;
            })?.highlight_tag
        );

        const start = getPoint(angle.left);
        const vertex = getPoint(angle.center);
        const end = getPoint(angle.right);

        const preferRightAngle = isRightAngle({ vertex, start, end });

        yield LabelAngleModel.create({ start, vertex, end, value: label, preferRightAngle, style });
    }

    for (const segmentsEquality of segment_equalities) {
        yield EqualSegmentsModel.create({
            segments: segmentsEquality.map(({ a, b }) => ({ a: getPoint(a), b: getPoint(b) })),
            segmentsCount: getSegments(),
            style: null,
        });
    }

    for (const anglesEquality of angle_equalities) {
        yield EqualAnglesModel.create({
            angles: anglesEquality.map(({ left, center, right }) => ({
                start: getPoint(left),
                vertex: getPoint(center),
                end: getPoint(right),
            })),
            segmentsCount: getAngleSegments(),
            style: null,
        });
    }

    for (const tangentLine of tangent_lines) {
        const circle = getCircle(tangentLine.circle);
        if (tangentLine.outside_point) {
            const point = getPoint(tangentLine.outside_point);
            const [tp1] = getTangentPoints(point, circle);
            const forwardDirection = areSamePoints(tp1, tangentLine.touch_point);

            yield TangentLineToCircleModel.create({ point, circle, forwardDirection });
        } else {
            const touchPoint = getPoint(tangentLine.touch_point.id);

            yield TangentLineOnCircleModel.create({ ellipse: circle, touchPoint });
        }
    }
}
