import type { TDirectionTrace, TPoint, TRect, TSerializedState } from "../types";

export function checkCharIsDigit(char: string): boolean {
    return /^[0-9]$/.test(char);
}

export function checkStringIsChar(str: string): boolean {
    return /^[a-zа-яё]$/i.test(str);
}

export function isPointInsideRect({ x, y }: TPoint, rect: TRect): boolean {
    return x >= rect.x && x <= rect.x + rect.w && y >= rect.y && y <= rect.y + rect.h;
}

export function traceWhenPointOutsideRect({ x, y }: TPoint, rect: TRect): TDirectionTrace | undefined {
    const directions: TDirectionTrace = [];
    if (x < rect.x) directions.push("L");
    if (x > rect.x + rect.w) directions.push("R");
    if (y < rect.y) directions.push("T");
    if (y > rect.y + rect.h) directions.push("B");

    if (directions.length) {
        return directions;
    }
}

export function computeRectArea(rect: TRect): number {
    return rect.w * rect.h;
}

export function assert<T>(message: string, condition: T, metadata?: unknown): asserts condition {
    if (!condition) {
        // eslint-disable-next-line no-console
        console.error(metadata);
        throw new Error(`Assertion failed: ${message}`);
    }
}

export function hasFormulaExtensions(formula: TSerializedState): boolean {
    if (!formula.highlights) return false;
    if (formula.highlights.length === 0) return false;

    return true;
}

export function isMathExpression(value: unknown): value is TSerializedState {
    return !!value && typeof value === "object" && "elements" in value && Array.isArray(value.elements);
}
