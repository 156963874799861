import { action, makeObservable, observable } from "mobx";

import { assert } from "@viuch/utils/debug";

import type { BaseGraphItem } from "./BaseGraphItem";
import type { Graph2DModel } from "../../core/Graph2DModel";

export class GraphDataItems {
    private readonly model: Graph2DModel;

    @observable.shallow private readonly itemsArray: Array<BaseGraphItem>;
    private readonly itemsMap: Map<string, BaseGraphItem>;

    constructor(model: Graph2DModel) {
        this.model = model;
        this.itemsArray = [];
        this.itemsMap = observable.map();

        makeObservable(this);
    }

    getAll(): ReadonlyArray<BaseGraphItem> {
        return this.itemsArray;
    }

    @action.bound
    clear() {
        this.itemsArray.clear();
        this.itemsMap.clear();
    }

    @action.bound
    add(item: BaseGraphItem) {
        if (this.itemsMap.has(item.$uuid)) return;

        item.beforeAdd?.(this.model);

        this.itemsArray.push(item);
        this.itemsMap.set(item.$uuid, item);
    }

    @action.bound
    addSome(items: readonly BaseGraphItem[]) {
        for (const item of items) {
            this.add(item);
        }
    }

    @action.bound
    remove(item: BaseGraphItem) {
        this.itemsArray.remove(item);
        this.itemsMap.delete(item.$uuid);

        item.onRemoved?.(this.model);
    }

    getById<T extends BaseGraphItem>(id: string): T {
        const item = this.itemsMap.get<T>(id);
        assert(item);
        return item;
    }

    getByIndex<T extends BaseGraphItem>(index: number): T {
        assert(index >= 0);
        const item = this.itemsArray.at<T>(index);
        assert(item);
        return item;
    }

    clone(): GraphDataItems {
        const { model, itemsArray } = this;
        const dataStore = new GraphDataItems(model);

        dataStore.addSome(itemsArray.map((item) => item.clone()));

        return dataStore;
    }
}
