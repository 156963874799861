import type { ISerializedAngle } from "./types";
import type { TSerializedElementPrototype } from "../../core/element";
import type { IElementVisitor } from "../../core/element/IElementVisitor";
import type { InputService } from "../../services";
import type { TElementDeserializerFunction } from "../../types";

import { BaseElementModel } from "../../core/element";

export class AngleModel extends BaseElementModel<ISerializedAngle> {
    public constructor(inputService: InputService, uuid?: string) {
        super(inputService, uuid);
    }

    public serialize(): ISerializedAngle {
        return { type: "angle", uuid: this.uuid };
    }

    public static deserialize: TElementDeserializerFunction<ISerializedAngle> = ({ inputService }, { uuid }) =>
        new AngleModel(inputService, uuid);

    public accept<R>(visitor: IElementVisitor<R>): R {
        return visitor.doWithAngle(this);
    }

    serializeAsClone(): TSerializedElementPrototype<ISerializedAngle> {
        const { uuid, ...clone } = this.serialize();
        return clone;
    }
}
