import { makeObservable, observable } from "mobx";

import { normalizeAngle } from "@viuch/geometry-lib/angles";

import type { IElementVisitor } from "../BaseElement";
import type { ElementColor } from "../ElementColor";

import { BaseElement } from "../BaseElement";

export interface IAngleElement {
    id: string;
    x: number;
    y: number;
    angleStart: number;
    angleEnd: number;
    isRight?: boolean;
    segments?: number;
    offset?: number;
    color?: ElementColor;
    overrideRenderOrder: number;
}

export class AngleElement extends BaseElement implements IAngleElement {
    x: number;
    y: number;
    angleStart: number;
    angleEnd: number;
    isRight: boolean;
    segments?: number;
    offset?: number;

    constructor(data: IAngleElement) {
        super(data);

        this.x = data.x;
        this.y = data.y;
        this.angleStart = normalizeAngle(data.angleStart);
        this.angleEnd = normalizeAngle(data.angleEnd);
        if (this.angleStart > this.angleEnd) {
            this.angleEnd += Math.PI * 2;
        }
        this.isRight = data.isRight ?? false;
        this.segments = data.segments;
        this.offset = data.offset;

        makeObservable(this, {
            x: observable,
            y: observable,
            isRight: observable,
            angleStart: observable,
            angleEnd: observable,
            segments: observable,
            offset: observable,
        });
    }

    accept<R>(visitor: IElementVisitor<R>): R {
        return visitor.withAngle(this);
    }

    static create(data: IAngleElement) {
        return new AngleElement(data);
    }
}
