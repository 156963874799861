import { observer } from "mobx-react-lite";
import React from "react";

import { ReactComponent as DeleteIcon } from "@viuch/assets/icons/delete.svg";
import { MathInput2 } from "@viuch/ui-common";

import type { PictureInstrumentSettingsWatermark } from "../../settings/PictureInstrumentSettingsWatermark";
import type { KeyboardService } from "@viuch/math-editor";

import { Alignments } from "../alignments";

import { COLORS } from "./constants";
import { PictureWatermarkListItemVm } from "./PictureWatermarkListItem.vm";

import styles from "./PictureWatermarkListItem.module.scss";

type Props = {
    watermark: PictureInstrumentSettingsWatermark;
    keyboard: KeyboardService;
    onRemove: (watermark: PictureInstrumentSettingsWatermark) => void;
};

export const PictureWatermarkListItem: React.VFC<Props> = observer(function PictureWatermarkListItem(props) {
    const { watermark, keyboard, onRemove } = props;

    const vm = React.useMemo(() => new PictureWatermarkListItemVm(watermark), [watermark]);

    const xLabel = watermark.position.left.toFixed(2);
    const yLabel = watermark.position.top.toFixed(2);

    return (
        <li>
            <div className={styles.item}>
                <div className={styles.body}>
                    <div className={styles.positions}>
                        <div className={styles.position}>x = {xLabel}</div>
                        <div className={styles.position}>y = {yLabel}</div>
                    </div>
                    <div className={styles.colorsWrapper}>
                        <div className={styles.colors}>
                            {COLORS.map((color) => (
                                <button
                                    style={{ backgroundColor: color }}
                                    className={styles.colorButton}
                                    key={color}
                                    onClick={() => vm.changeColor(color)}
                                />
                            ))}
                        </div>
                        <input
                            type="color"
                            value={watermark.color}
                            onChange={(e) => vm.changeColor(e.target.value)}
                        />
                    </div>
                    <Alignments
                        selectedAlignment={watermark.alignment}
                        onChange={watermark.setAlignment}
                    />
                    <MathInput2
                        value={watermark.templateValue}
                        onChange={watermark?.setTemplateValue}
                        keyboardService={keyboard}
                        className={styles.input}
                    />
                </div>
                <button onClick={() => onRemove(watermark)}>
                    <DeleteIcon />
                </button>
            </div>
        </li>
    );
});
