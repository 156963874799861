import type { TFormControl } from "@viuch/form-builder/types/controls";

import { answerDimensionMathControl, evaluationDimensionsMathArrayControl } from "../controls/dimensions";
import { createLabel } from "../controls/label";
import { leftRightMathControl, valueMathControl, variableMathControl } from "../controls/value";

export const evaluateDims: TFormControl = {
    control: "Form",
    layout: "column",
    condition: [{ "./solver_type": "evaluate_dims" }],
    controls: [
        createLabel("Вычисляет выражение с размерностями"), //
        valueMathControl,
        evaluationDimensionsMathArrayControl,
        answerDimensionMathControl,
    ],
};

export const equationDims: TFormControl = {
    control: "Form",
    layout: "column",
    condition: [{ "./solver_type": "equation_dims" }],
    controls: [
        createLabel("Решает уравнение с размерностями"), //
        valueMathControl,
        variableMathControl,
        evaluationDimensionsMathArrayControl,
        answerDimensionMathControl,
    ],
};

export const compareDims: TFormControl = {
    control: "Form",
    layout: "column",
    condition: [{ "./solver_type": "compare_dims" }],
    controls: [
        createLabel("Сравнение с размерностями"), //
        leftRightMathControl,
        evaluationDimensionsMathArrayControl,
        answerDimensionMathControl,
    ],
};
