/**
 * Add item to array at the given index.
 * Returns new array.
 * */
export function addAtPosition<TItem>(array: TItem[], items: TItem | TItem[], index: number): TItem[] {
    const left = array.slice(0, index + 1);
    const right = array.slice(index + 1);

    return [...left, ...(Array.isArray(items) ? items : [items]), ...right];
}
