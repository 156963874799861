import { makeObservable } from "mobx";

import { deepClone } from "@viuch/shared/utils/data/copy";

import type { ReviewComment } from "./ReviewComment";

import { BaseReview } from "./BaseReview";

export class ThemeReview extends BaseReview {
    constructor(
        id: number,
        theme: ThemeReview["theme"],
        updatedAt: Date,
        createdAt: Date,
        isVerified: boolean,
        student: BaseReview["student"],
        text = "",
        comments: ReviewComment[] = []
    ) {
        super(id, updatedAt, createdAt, isVerified, student, text, comments);

        this.theme = theme;

        makeObservable(this);
    }

    readonly theme: {
        id: number;
        name: string;
        subjects: {
            id: number;
            name: string;
        }[];
    };

    clone() {
        return new ThemeReview(
            this.id,
            deepClone(this.theme),
            this.updatedAt,
            this.createdAt,
            this.isVerified,
            deepClone(this.student),
            this.text,
            this.comments
        );
    }
}
