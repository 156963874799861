import type { TFormControl } from "@viuch/form-builder/types/controls";

import { createLabel } from "../controls/label";
import { leftRightMathControl } from "../controls/value";

export const compareNumbers: TFormControl = {
    control: "Form",
    layout: "column",
    condition: {
        "./solver_type": ["compare_numbers"],
    },
    controls: [
        createLabel("В качестве ответа выступает один из символов: <, > или ="), //
        leftRightMathControl,
    ],
};
