import { makeObservable, observable } from "mobx";

import type { BaseVariableContent } from "./variables";

import { VariableGenerationTypes } from "./constants";
import { Variable } from "./Variable";
import { SolverVariableContent } from "./variables";

export class Answer<T extends BaseVariableContent = BaseVariableContent> extends Variable<T> {
    @observable.ref description: string;

    constructor(description: string, name: string, generation: VariableGenerationTypes, content: T) {
        super(name, generation, content);
        this.description = description;

        makeObservable(this);
    }

    static override createEmpty(): Answer {
        return new Answer("Ответ", "", VariableGenerationTypes.solver, SolverVariableContent.createEmpty());
    }

    override clone(): Answer {
        return new Answer(this.description, this.name, this.generation, this.content.clone());
    }
}
