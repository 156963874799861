import { action, makeObservable, observable } from "mobx";

import { createSerializedContainer } from "@viuch/math-editor";

import type { ITableCellVisitor } from "../ITableCellVisitor";
import type { TSerializedState } from "@viuch/math-editor/types";

import { BaseTableInstrumentSettingsCell } from "../BaseTableInstrumentSettingsCell";

export class TextTableInstrumentSettingsCell extends BaseTableInstrumentSettingsCell {
    @observable.ref formula: TSerializedState;

    constructor(isHidden: boolean, inputState: TSerializedState) {
        super(isHidden);

        this.formula = inputState ?? createSerializedContainer();
        makeObservable(this);
    }

    @action.bound
    setFormula(formula: TSerializedState) {
        this.formula = formula;
    }

    static createEmpty(): TextTableInstrumentSettingsCell {
        return new TextTableInstrumentSettingsCell(false, createSerializedContainer());
    }

    clone(): TextTableInstrumentSettingsCell {
        return new TextTableInstrumentSettingsCell(this.isHidden, this.formula);
    }

    accept<R>(visitor: ITableCellVisitor<R>): R {
        return visitor.withTextCell(this);
    }
}
