import { action, comparer, computed, makeObservable } from "mobx";

import type { TVirtualTreeNode } from "./types";
import type { BaseFormNode } from "../nodes/BaseFormNode";
import type { FormNode } from "../nodes/FormNode";

import { serializeVirtualTree, setValuesInVirtualTree } from "../serialization/serializeVirtualTreeNode";

export class VirtualFormTree {
    readonly rootNode: FormNode;

    constructor(rootForm: FormNode) {
        this.rootNode = rootForm;
        makeObservable(this);
    }

    @computed({ equals: comparer.shallow })
    private get _virtualTreeData() {
        return this._buildVirtualTree(false);
    }

    getRoot(): TVirtualTreeNode {
        return this._virtualTreeData.tree;
    }

    createFullVirtualTree() {
        return this._buildVirtualTree(true);
    }

    isNodePresent(formNode: BaseFormNode): boolean {
        return this._virtualTreeData.record.has(formNode);
    }

    tryGetTreeNode(formNode: BaseFormNode): TVirtualTreeNode | null {
        return this._virtualTreeData.record.get(formNode) ?? null;
    }

    private _buildVirtualTree(fullTree: boolean): {
        tree: TVirtualTreeNode;
        record: ReadonlyMap<BaseFormNode, TVirtualTreeNode>;
    } {
        const root: TVirtualTreeNode = {
            parent: null,
            children: new Map(),
            controls: new Set(),
        };

        const record = new Map<BaseFormNode, TVirtualTreeNode>();

        this._placeInTree(root, this.rootNode, (formNode, treeNode) => record.set(formNode, treeNode), fullTree);

        return { tree: root, record };
    }

    private _placeInTree(
        treeNode: TVirtualTreeNode,
        element: BaseFormNode,
        onPlaceCallback: (formNode: BaseFormNode, treeNode: TVirtualTreeNode) => void,
        forcePresent: boolean
    ): void {
        const { pathSegments } = element;

        // Создать путь

        let currentTreeNode: TVirtualTreeNode = treeNode;

        for (const pathSegment of pathSegments) {
            switch (pathSegment) {
                case "":
                case ".":
                    // ничего не делать. Путь не изменился.
                    break;
                case "..":
                    // переместиться на уровень выше
                    if (!currentTreeNode.parent) {
                        throw new Error("Try to jump outside of root");
                    }

                    currentTreeNode = currentTreeNode.parent;
                    break;
                default:
                    let nextNode = currentTreeNode.children.get(pathSegment);
                    if (!nextNode) {
                        nextNode = {
                            parent: currentTreeNode,
                            children: new Map(),
                            controls: new Set(),
                        };

                        currentTreeNode.children.set(pathSegment, nextNode);
                    }
                    currentTreeNode = nextNode;
            }
        }

        // Разместить элемент по найденному пути

        if (forcePresent || element.canPresentInTree(currentTreeNode)) {
            currentTreeNode.controls.add(element);
            onPlaceCallback(element, currentTreeNode);

            // Обойти дочерние элементы

            element.getChildren().forEach((child) => {
                this._placeInTree(currentTreeNode, child, onPlaceCallback, forcePresent);
            });
        }
    }

    getSerializedState(): object {
        return serializeVirtualTree(this.getRoot());
    }

    @action.bound
    setSerializedState(data: object): void {
        setValuesInVirtualTree(this.createFullVirtualTree().tree, data);
    }
}
