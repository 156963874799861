import type { MathEditorPluginWebComponent } from "./MathEditorPluginWebComponent";
import type { API, InlineTool, InlineToolConstructorOptions, SanitizerConfig } from "@editorjs/editorjs";

/* eslint-disable -- Расширение библиотеки @editorjs */

export class MathEditorTool implements InlineTool {
    private _state: boolean;

    api: API;
    button: HTMLButtonElement | null;

    static sanitize: SanitizerConfig = {
        "math-editor-web-component": { state: true, contenteditable: true, "math-expression": true },
    };

    static get isInline() {
        return true;
    }

    get state() {
        return this._state;
    }

    set state(state) {
        this._state = state;

        this.button?.classList.toggle(this.api.styles.inlineToolButtonActive, state);
    }

    constructor({ api }: InlineToolConstructorOptions) {
        this.api = api;
        this.button = null;
        this._state = false;
    }

    render() {
        this.button = document.createElement("button");
        this.button.type = "button";
        this.button.innerHTML =
            '<svg width="20" height="18"><path d="M10.458 12.04l2.919 1.686-.781 1.417-.984-.03-.974 1.687H8.674l1.49-2.583-.508-.775.802-1.401zm.546-.952l3.624-6.327a1.597 1.597 0 0 1 2.182-.59 1.632 1.632 0 0 1 .615 2.201l-3.519 6.391-2.902-1.675zm-7.73 3.467h3.465a1.123 1.123 0 1 1 0 2.247H3.273a1.123 1.123 0 1 1 0-2.247z"/></svg>';
        this.button.classList.add(this.api.styles.inlineToolButton);

        return this.button;
    }

    surround(range: Range) {
        if (this.state) {
            this.unwrap(range);
            return;
        }

        this.wrap(range);
    }

    private wrap(range: Range) {
        const selectedText = range.extractContents();
        const mathEditorElement: MathEditorPluginWebComponent = document.createElement("math-editor-web-component");

        mathEditorElement.contentEditable = "false";

        mathEditorElement.appendChild(selectedText);

        range.insertNode(mathEditorElement);

        range.collapse();
        mathEditorElement.innerHTML = "_";
    }

    private unwrap(range: Range) {
        this.api.selection.findParentTag("math-editor-web-component")?.remove();
    }

    checkState(selection: Selection): boolean {
        const mark = this.api.selection.findParentTag("math-editor-web-component");

        this.state = Boolean(mark);

        return this.state;
    }
}
