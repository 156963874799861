export function stripPath(path: string) {
    return stripLeadingSlash(stripTrailingSlash(path));
}

export function stripLeadingSlash(path: string) {
    return path.charAt(0) === "/" ? path.slice(1) : path;
}

export function stripTrailingSlash(path: string) {
    return path.charAt(path.length - 1) === "/" ? path.slice(0, -1) : path;
}
