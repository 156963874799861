import { action, makeObservable, observable } from "mobx";

import { InputService } from "@viuch/math-editor";
import { LazyResult } from "@viuch/shared/features/lazy-result";

import type {
    IIntervalsMethodLineDot,
    IntervalsDot,
    IntervalsInstrumentStatement,
    IntervalsLine,
    TDotForm,
} from "@viuch/instrument-intervals/statement";

export type TAddDotRequest = {
    intervals: IntervalsInstrumentStatement;
    line: IntervalsLine;
    position: number;
    valueInputService: InputService;
};

export type TEditDotRequest = {
    intervals: IntervalsInstrumentStatement;
    line: IntervalsLine;
    dot: IntervalsDot;
    valueInputService: InputService;
};

export class IntervalsInstrumentSettingsEditorViewModel {
    public readonly intervals: IntervalsInstrumentStatement;

    @observable
    lazyAddDotFields?: LazyResult<IIntervalsMethodLineDot | null, TAddDotRequest>;

    @observable
    lazyEditDotFields?: LazyResult<IIntervalsMethodLineDot | null, TEditDotRequest>;

    constructor(intervals: IntervalsInstrumentStatement) {
        this.intervals = intervals;

        makeObservable(this);
    }

    handleClearRequest = async () => true;

    @action.bound
    handleAddDotRequest(
        intervals: IntervalsInstrumentStatement,
        line: IntervalsLine,
        position: number
    ): Promise<IIntervalsMethodLineDot | null> {
        this.lazyAddDotFields = LazyResult.create({
            intervals,
            line,
            position,
            valueInputService: new InputService({}),
        });
        return this.lazyAddDotFields.promise;
    }

    @action.bound
    handleAddDot(form: TDotForm) {
        if (!this.lazyAddDotFields) return;

        const { valueInputService, position } = this.lazyAddDotFields.payload;
        const value = valueInputService.getSerializedState();

        this.lazyAddDotFields.resolveWith({ value, form, position });
        this.lazyAddDotFields = void 0;
    }

    @action.bound
    handleAddDotCancel() {
        this.lazyAddDotFields?.resolveWith(null);
        this.lazyAddDotFields = void 0;
    }

    @action.bound
    handleEditDotRequest(
        intervals: IntervalsInstrumentStatement,
        line: IntervalsLine,
        dot: IntervalsDot
    ): Promise<IIntervalsMethodLineDot | null> {
        const initialState = dot.value;
        this.lazyEditDotFields = LazyResult.create({
            intervals,
            line,
            dot,
            valueInputService: new InputService({ initialState }),
        });
        return this.lazyEditDotFields.promise;
    }

    @action.bound
    handleEditDot(form: TDotForm) {
        if (!this.lazyEditDotFields) return;

        const { valueInputService, dot } = this.lazyEditDotFields.payload;
        const value = valueInputService.getSerializedState();
        const { position } = dot;

        this.lazyEditDotFields.resolveWith({ form, value, position });
        this.lazyEditDotFields = void 0;
    }

    @action.bound
    handleEditDotCancel() {
        this.lazyEditDotFields?.resolveWith(null);
        this.lazyEditDotFields = void 0;
    }

    @action.bound
    handleRemoveEditableDot() {
        if (!this.lazyEditDotFields) return;

        const { line, dot } = this.lazyEditDotFields.payload;

        this.lazyEditDotFields.resolveWith(null);
        this.lazyEditDotFields = void 0;

        line.removeDot(dot);
    }
}
