import { instantiateForm } from "@viuch/form-builder/serialization/instantiateForm";

import type { VirtualFormTree } from "@viuch/form-builder/tree/VirtualFormTree";

import { solverRequestFormSchema } from "../../services/tasks/utils/schema/solverRequestFormSchema";

export class SolverRequest {
    readonly form: VirtualFormTree;

    constructor(data: object) {
        this.form = instantiateForm(solverRequestFormSchema);

        this.form.setSerializedState(data);
    }

    clone(): SolverRequest {
        return new SolverRequest(this.serialize());
    }

    serialize(): object {
        return this.form.getSerializedState();
    }

    static createEmpty() {
        return new SolverRequest({ solver_type: "evaluate" });
    }
}
