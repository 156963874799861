import { useEffect, useState } from "react";

import type { TContext } from "./ModalsProvider.types";
import type { PropsWithChildren } from "react";

import { ModalsContext } from "./ModalsProvider.constants";

export const ModalsProvider = ({ children }: PropsWithChildren) => {
    const [openedModals, setOpenedModals] = useState(0);
    const providerValue: TContext = {
        increaseOpenedModals() {
            setOpenedModals((prev) => prev + 1);
        },
        decreaseOpenedModals() {
            setOpenedModals((prev) => prev - 1);
        },
    };

    useEffect(() => {
        if (openedModals) {
            document.body.style.overflow = "hidden";
            return;
        }

        document.body.style.overflow = "auto";

        return () => {
            document.body.style.overflow = "auto";
        };
    }, [openedModals]);

    return <ModalsContext.Provider value={providerValue}>{children}</ModalsContext.Provider>;
};
