import cn from "classnames";
import { observer } from "mobx-react-lite";

import styles from "./FillSpinner.module.scss";

type Props = {
    backdrop?: boolean;
    allowEvents?: boolean;
    className?: string;
};

export const FillSpinner = observer(function FillSpinner({ className, backdrop, allowEvents }: Props) {
    return (
        <div
            className={cn(styles.spinner, backdrop && styles.backdrop, allowEvents && styles.allowEvents, className)}
        />
    );
});
