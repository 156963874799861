import type { ICondition } from "./ICondition";
import type { BaseFormNode } from "../nodes/BaseFormNode";
import type { TVirtualTreeNode } from "../tree/types";

import { BaseValueFormNode } from "../nodes/BaseValueFormNode";

export class ConditionPathValueEquals implements ICondition {
    private readonly path: string[];
    private readonly expectedValue: unknown;

    constructor(path: string[], expectedValue: unknown) {
        this.path = path;
        this.expectedValue = expectedValue;
    }

    getResult(control: BaseFormNode, node: TVirtualTreeNode): boolean {
        const refNode = tryFollowVirtualNodePath(node, this.path);

        if (!refNode) {
            return false;
        }

        const refControls = [...refNode.controls];

        if (refControls.length === 0) {
            return false;
        }

        for (const refControl of refControls) {
            if (refControl instanceof BaseValueFormNode) {
                if (!refControl.valueEqualsTo(this.expectedValue)) {
                    return false;
                }
            }
        }
        return true;
    }
}

function tryFollowVirtualNodePath(node: TVirtualTreeNode, path: string[]): TVirtualTreeNode | null {
    let currentNode = node;
    for (const pathSegment of path) {
        switch (pathSegment) {
            case "":
            case ".":
                break;
            case "..":
                if (!currentNode.parent) {
                    return null;
                }
                currentNode = currentNode.parent;
                break;
            default:
                const nextNode = currentNode.children.get(pathSegment);
                if (!nextNode) {
                    return null;
                }
                currentNode = nextNode;
                break;
        }
    }
    return currentNode;
}
