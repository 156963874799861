import cn from "classnames";
import { forwardRef, useEffect, useRef } from "react";
import { Scrollbar } from "react-scrollbars-custom";

import { mergeRefs } from "@viuch/shared/utils/react";

import type { CSSProperties, PropsWithChildren, ComponentPropsWithRef } from "react";

import styles from "./CustomScrollbar.module.scss";

export type TCustomScrollbarProps = PropsWithChildren<{
    scrollbarContentClassName?: string;
    scrollerClassName?: string;
    contentStyle?: CSSProperties;
    isFocused?: boolean;
    scrollerProps?: ComponentPropsWithRef<"div">;
    trackYClassName?: string;
    trackXClassName?: string;
    noScrollX?: boolean;
    noScrollY?: boolean;
}>;

export const CustomScrollbar = forwardRef<HTMLDivElement, TCustomScrollbarProps>(function CustomScrollbar(props, ref) {
    const {
        children,
        contentStyle,
        scrollerProps,
        isFocused,
        scrollbarContentClassName,
        trackYClassName,
        trackXClassName,
        noScrollX,
        noScrollY,
        scrollerClassName,
    } = props;
    const scrollerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (isFocused) {
            scrollerRef.current!.focus();
        }
    }, [isFocused]);

    return (
        <Scrollbar
            noScrollX={noScrollX}
            noScrollY={noScrollY}
            noDefaultStyles
            translateContentSizesToHolder
            className={styles.scrollbar}
            style={{ position: "static" }}
            disableTracksWidthCompensation
            wrapperProps={{
                renderer: ({ elementRef, ...restProps }) => (
                    <div
                        {...restProps}
                        ref={elementRef}
                        className={styles.wrapper}
                    />
                ),
            }}
            scrollerProps={{
                renderer: ({ elementRef, ...restProps }) => (
                    <div
                        {...restProps}
                        {...scrollerProps}
                        ref={mergeRefs(elementRef, ref, scrollerRef, scrollerProps?.ref)}
                        className={cn(styles.scroller, scrollerClassName)}
                        style={{
                            ...restProps.style,
                            ...scrollerProps?.style,
                        }}
                        data-scrollbar-element-tag="scroller"
                    />
                ),
            }}
            contentProps={{
                renderer: ({ elementRef, ...restProps }) => (
                    <div
                        {...restProps}
                        style={{ ...restProps.style, ...contentStyle }}
                        ref={elementRef}
                        className={scrollbarContentClassName}
                        data-scrollbar-element-tag="content"
                    />
                ),
            }}
            thumbXProps={{
                renderer: ({ elementRef, ...restProps }) => (
                    <div
                        {...restProps}
                        ref={elementRef}
                        className={styles.thumbX}
                    />
                ),
            }}
            trackXProps={{
                renderer: ({ elementRef, ...restProps }) => (
                    <div
                        {...restProps}
                        ref={elementRef}
                        className={cn(styles.trackX, trackXClassName)}
                        data-scrollbar-element-tag="track-x"
                    />
                ),
            }}
            thumbYProps={{
                renderer: ({ elementRef, ...restProps }) => (
                    <div
                        {...restProps}
                        ref={elementRef}
                        className={styles.thumbY}
                    />
                ),
            }}
            trackYProps={{
                renderer: ({ elementRef, ...restProps }) => {
                    return (
                        <div
                            {...restProps}
                            ref={elementRef}
                            className={cn(styles.trackY, trackYClassName)}
                            data-scrollbar-element-tag="track-y"
                        />
                    );
                },
            }}
        >
            {children}
        </Scrollbar>
    );
});
