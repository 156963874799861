import cn from "classnames";
import { observer } from "mobx-react-lite";
import React from "react";

import styles from "./LoadingSpinner.module.scss";

type Props = {
    absolute?: boolean;
    center?: boolean;
    className?: string;
};

export const LoadingSpinner = observer(function LoadingSpinner({ className, absolute, center }: Props) {
    return (
        <svg
            className={cn(styles.svg, styles.rotate, className, absolute && styles.absolute, center && styles.center)}
            viewBox="0 0 56 56"
        >
            <svg
                x={28}
                y={28}
                className={cn(styles.innerSvg)}
            >
                <circle
                    cx={0}
                    cy={0}
                    r={25}
                    strokeWidth={6}
                    className={styles.circle1}
                    pathLength={1}
                    strokeDasharray="0.85 0.15"
                    strokeDashoffset="0.3"
                >
                    <animateTransform
                        attributeName="transform"
                        attributeType="XML"
                        type="rotate"
                        from="0 0 0"
                        to="360 0 0"
                        dur="0.87s"
                        repeatCount="indefinite"
                    />
                </circle>
                <circle
                    cx={0}
                    cy={0}
                    r={18}
                    strokeWidth={2}
                    className={styles.circle2}
                >
                    <animate
                        attributeName="r"
                        values="18;15;18"
                        dur="3s"
                        repeatCount="indefinite"
                    />
                </circle>
            </svg>
        </svg>
    );
});
