import { Graph1DInstrumentStatement } from "@viuch/instrument-graph1d/statement";

export class Graph1DInstrumentSettings {
    readonly graph1d: Graph1DInstrumentStatement;

    constructor(graph1d: Graph1DInstrumentStatement) {
        this.graph1d = graph1d;
    }

    clone() {
        return new Graph1DInstrumentSettings(this.graph1d.clone());
    }

    static CreateEmpty() {
        return new Graph1DInstrumentSettings(Graph1DInstrumentStatement.CreateEmpty());
    }
}
