import cn from "classnames";
import { observer } from "mobx-react-lite";
import React, { useRef } from "react";

import { MathEditorInput } from "@viuch/math-editor";

import type { PictureInstrumentSettingsWatermark } from "../../settings/PictureInstrumentSettingsWatermark";

import { convertCoordinatesToFloatPosition } from "../utils";

import { PictureWatermarkDotViewModel } from "./PictureWatermarkDotViewModel";

import styles from "./PictureWatermarkDot.module.scss";

type Props = {
    watermark: PictureInstrumentSettingsWatermark;
    watermarksBlockRef: React.RefObject<HTMLElement>;
};

export const PictureWatermarkDot: React.VFC<Props> = observer(function PictureWatermarkDot(props) {
    const { watermark, watermarksBlockRef } = props;

    const vm = React.useMemo(() => new PictureWatermarkDotViewModel(watermark), [watermark]);

    const canMove = useRef(false);

    const handlePointerDown = (e: React.PointerEvent<HTMLDivElement>) => {
        if (!e.isPrimary) return;
        canMove.current = true;
        e.currentTarget.setPointerCapture(e.pointerId);
    };

    const handlePointerCancel = (e: React.PointerEvent<HTMLDivElement>) => {
        if (!e.isPrimary) return;
        canMove.current = false;
    };

    const handlePointerMove = (e: React.PointerEvent<HTMLDivElement>) => {
        if (!canMove.current || !e.isPrimary) {
            return;
        }

        e.currentTarget.setPointerCapture(e.pointerId);

        const position = convertCoordinatesToFloatPosition(e.clientX, e.clientY, watermarksBlockRef);
        watermark.setPosition(position);
    };

    return (
        <div
            onPointerDown={handlePointerDown}
            onPointerMove={handlePointerMove}
            onPointerCancel={handlePointerCancel}
            onPointerUp={handlePointerCancel}
            onClick={(e) => e.stopPropagation()}
            className={styles.watermarkWrapper}
            style={{
                left: `${watermark.position.left * 100}%`,
                top: `${watermark.position.top * 100}%`,
                "--color": watermark?.color,
            }}
        >
            <div className={styles.watermark} />

            <div
                className={cn(
                    styles.valueWrapper,
                    styles[watermark.alignment.vertical],
                    styles[watermark.alignment.horizontal]
                )}
            >
                <MathEditorInput
                    inputWrapperClassName={cn(styles.value, styles[watermark.alignment.horizontal])}
                    inputModel={vm.valueInput.model}
                    inputContentStyles={{ color: watermark.color }}
                    withoutScrollbar
                />
            </div>
        </div>
    );
});
