import Header from "@editorjs/header";
import Image from "./plugins/CustomImage";
import Link from "@editorjs/link";
import List from "@editorjs/list";
import Table from "@editorjs/table";
import Underline from "@editorjs/underline";
import FontFamilyPlugin2 from "./plugins/FontFamilyPlugin2";
import CustomColor from "./plugins/CustomColorInlinePlugin";

export const PLUGINS = {
    Table,
    List,
    Image,
    Link,
    Underline,
    Header,
    FontFamilyPlugin2,
    CustomColor,
};
