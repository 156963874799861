import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect, useRef } from "react";

import { ReactComponent as MinusIcon } from "@viuch/assets/icons/minus-red.svg";
import { InputService, MathEditorInput } from "@viuch/math-editor";
import { roundToStep } from "@viuch/shared/utils/math/round";
import { useConst } from "@viuch/utils/hooks";

import type { IntervalsDot, IntervalsLine } from "../../statement";
import type { KeyboardService } from "@viuch/math-editor";

import { STEPS } from "../constants";
import { convertClientXToDotPosition } from "../utils";

import { IntervalsInstrumentStatementDotEditor } from "./Dot";
import { LineViewModel } from "./Line.vm";
import { LineInterval } from "./LineInterval";

import styles from "./Line.module.scss";

type Props = {
    line: IntervalsLine;
    onRemoveLine: (line: IntervalsLine) => void;
    onCreateDot: (line: IntervalsLine, position: number) => void;
    onEditDot: (line: IntervalsLine, dot: IntervalsDot) => void;
    mathKeyboard?: KeyboardService;
    readonly?: boolean;
};

export const Line = observer(function Line({
    line,
    onRemoveLine,
    onCreateDot,
    onEditDot,
    readonly,
    mathKeyboard,
}: Props) {
    const vm = React.useMemo(() => new LineViewModel(line), [line]);

    const lineRef = useRef<HTMLDivElement>(null);

    const handleLineClick = (e: React.MouseEvent<HTMLDivElement>) => {
        if (readonly) return;

        const position = roundToStep(convertClientXToDotPosition(e.clientX, lineRef), STEPS);

        if (vm.canCreateDotAt(position)) {
            onCreateDot(line, position);
        }
    };

    const handleRemoveClick = () => {
        onRemoveLine(line);
    };

    const handleEditDot = (dot: IntervalsDot) => {
        onEditDot(line, dot);
    };

    const inputService = useConst(
        () => new InputService({ initialState: line.label, isReadOnly: !!readonly, disablePlaceholders: !!readonly })
    );

    useEffect(() => {
        inputService.setSerializedState(line.label);
    }, [line.label, inputService]);

    const updateLabel = useCallback(
        (s: InputService) => {
            line.setLabel(s.getSerializedState());
        },
        [line]
    );

    return (
        <div className={styles.line}>
            {!readonly && (
                <button
                    className={styles.lineRemoveButton}
                    onClick={handleRemoveClick}
                >
                    <MinusIcon />
                </button>
            )}
            <div className={styles.lineWithDotsWrapper}>
                <div className={styles.intervals}>
                    {vm.intervals.map(({ leftDot, rightDot, sign }) => (
                        <LineInterval
                            key={leftDot?.id ?? "zero"}
                            leftDot={leftDot}
                            rightDot={rightDot}
                            sign={sign}
                            onSignChange={vm.handleSignChange}
                            readonly={readonly}
                        />
                    ))}
                </div>
                <div
                    className={styles.lineWithDots}
                    onClick={handleLineClick}
                    ref={lineRef}
                />
                <div className={styles.dots}>
                    {line.dots.map((dot) => (
                        <IntervalsInstrumentStatementDotEditor
                            dot={dot}
                            lineRef={lineRef}
                            key={dot.id}
                            onEditDot={handleEditDot}
                            onMoveDot={vm.handleMoveDot}
                            readonly={readonly}
                        />
                    ))}
                </div>
            </div>
            <div className={styles.lineEnding} />

            <div className={styles.labelContainer}>
                <MathEditorInput
                    inputModel={inputService.model}
                    onBlur={updateLabel}
                    inputWrapperClassName={styles.labelMath}
                    className={styles.labelMath__inner}
                    keyboardService={mathKeyboard}
                />
            </div>
        </div>
    );
});
