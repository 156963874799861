import type { THighlightLocation, TSerializedState } from "../types";

export function sliceMathExpr(formula: TSerializedState, from: number, length: number): TSerializedState {
    const elements = formula.elements.slice(from, from + length);

    const highlights: THighlightLocation[] = [];

    for (const hl of formula.highlights ?? []) {
        const highlight = shiftHighlightRootPosition(hl, from, 0, length);

        if (highlight) {
            highlights.push(highlight);
        }
    }

    return { elements, highlights };
}

function shiftHighlightRootPosition(
    highlight: THighlightLocation,
    offset: number,
    boundaryStart: number,
    boundaryEnd: number
): THighlightLocation | null {
    if (highlight.source === "$") {
        const start = highlight.start - offset;
        const end = highlight.end - offset;

        if (start >= boundaryEnd || end <= boundaryStart) {
            return null;
        }

        return {
            ...highlight,
            start: Math.max(boundaryStart, start),
            end: Math.min(boundaryEnd, end),
        };
    }

    let fail = false;

    const replace = (value: number): number => {
        const start = value - offset;
        const end = start + 1;

        if (start >= boundaryEnd || end <= boundaryStart) {
            fail = true;
        }
        return value - offset;
    };

    const source = highlight.source.replace(/[0-9]+/, (value) => String(replace(+value)));

    if (fail) {
        return null;
    }

    return { ...highlight, source };
}
