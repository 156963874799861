import cn from "classnames";
import { observer } from "mobx-react-lite";
import React, { useRef, type VFC } from "react";

import { Portal } from "@viuch/shared/features/portal-lib";

import type { THandlersObject } from "../../FigureViewModel";
import type { ToolbarButton as ToolbarButtonType } from "../ToolbarButton";
import type { ToolbarController } from "../ToolbarController";
import type { ToolbarMenu } from "../ToolbarMenu";

import { ButtonStates } from "../ButtonStates";

import { ToolbarButton } from "./ToolbarButton";

import styles from "./Toolbar.module.scss";

type Props = {
    toolbar: ToolbarController;
    handlers: THandlersObject;
};

export const Toolbar: VFC<Props> = observer(function Toolbar({ toolbar, handlers }) {
    const menu = toolbar.menu;
    const nestedMenu = toolbar.nestedMenu;

    const lastButtonEventRef = useRef<Element>();

    const handleMainToolbarClick = (e: React.MouseEvent, menu: ToolbarMenu, button: ToolbarButtonType) => {
        lastButtonEventRef.current = e.currentTarget;
        handleClick(e, menu, button);
    };

    const handleClick = (e: React.MouseEvent, menu: ToolbarMenu, button: ToolbarButtonType) => {
        e.preventDefault();
        toolbar.dispatchButtonClick(menu, button);
    };

    const { top, right: left } = lastButtonEventRef.current?.getBoundingClientRect() || {};

    return (
        <div className={styles.wrapper}>
            <div className={styles.stub} />
            <div className={styles.menu}>
                {menu.buttons.map((button) => (
                    <ToolbarButton
                        key={button.key}
                        icon={button.icon}
                        active={button.state === ButtonStates.active}
                        disabled={button.state === ButtonStates.disabled}
                        child={Boolean(button.nestedMenu)}
                        onClick={(e) => handleMainToolbarClick(e, menu, button)}
                        onBlur={handlers.returnFocus}
                    />
                ))}
            </div>
            {nestedMenu && (
                <Portal>
                    <div
                        className={cn(styles.menu, styles.nested)}
                        style={{ position: "fixed", top, left }}
                    >
                        {nestedMenu.buttons.map((button) => (
                            <ToolbarButton
                                key={button.key}
                                icon={button.icon}
                                active={button.state === ButtonStates.active}
                                disabled={button.state === ButtonStates.disabled}
                                label={button.title ?? void 0}
                                onClick={(e) => handleClick(e, nestedMenu, button)}
                                onBlur={handlers.returnFocus}
                            />
                        ))}
                    </div>
                </Portal>
            )}
        </div>
    );
});
