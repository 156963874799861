import cn from "classnames";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";

import { transformPoint } from "@viuch/geometry-lib/transformations/functions";
import { useIsSafari } from "@viuch/utils/hooks";

import type { Graph2DEvents } from "../editor/core/Graph2DEvents";
import type { Graph2DViewportController } from "../editor/core/Graph2DViewportController";
import type { Graph2DViewSettings } from "../editor/core/Graph2DViewSettings";
import type { PointViewItem } from "../view-models/PointViewItem";

import styles from "./PointView.module.scss";

type Props = {
    item: PointViewItem;
    viewport: Graph2DViewportController;
    settings: Graph2DViewSettings;
    events: Graph2DEvents;
};

export const PointView = observer(function PointView({ item, viewport, settings, events }: Props) {
    const isSafari = useIsSafari();

    const { x, y, size, color, isFilled, blur } = item;
    const point = useMemo(
        () => transformPoint({ x, y }, [...settings.coordinateSystemTransform, ...viewport.viewportTransformations]),
        [x, y, viewport, settings]
    );

    const [isHover, setIsHover] = useState(false);

    const radius = isHover ? 5 : size / 2;

    return (
        <g className={cn(styles.g)}>
            {blur && !isSafari && (
                <circle
                    cx={`${point.x}%`}
                    cy={`${point.y}%`}
                    r={radius}
                    fill={color}
                    filter="blur(4px)"
                    transform="translate(-1 2)"
                    className={styles.viewPoint}
                />
            )}
            <circle
                cx={`${point.x}%`}
                cy={`${point.y}%`}
                r={isFilled ? radius : radius + 1}
                fill={color}
                className={styles.viewPoint}
            />
            {!isFilled && (
                <circle
                    cx={`${point.x}%`}
                    cy={`${point.y}%`}
                    r={radius}
                    fill="var(--darkBlueOpal100)"
                    className={styles.viewPoint}
                />
            )}
            <circle
                cx={`${point.x}%`}
                cy={`${point.y}%`}
                r={16}
                fill="transparent"
                className={styles.eventTarget}
                onClick={(e) => events.dispatchElementClick(e, item)}
                onPointerDown={(e) => events.dispatchElementPointerEvent(e, item)}
                onPointerMove={(e) => events.dispatchElementPointerEvent(e, item)}
                onPointerUp={(e) => events.dispatchElementPointerEvent(e, item)}
                onPointerCancel={(e) => events.dispatchElementPointerEvent(e, item)}
                onPointerEnter={() => setIsHover(true)}
                onPointerLeave={() => setIsHover(false)}
            />
        </g>
    );
});
