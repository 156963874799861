import { copyPoint } from "@viuch/geometry-lib/factories";

import type { ISelectionElementVisitor } from "../BaseSelection";
import type { TFragment, TLine, TPoint } from "@viuch/geometry-lib/types";

import { BaseSelection } from "../BaseSelection";

export class VectorSelection extends BaseSelection {
    readonly from: Readonly<TPoint>;
    readonly to: Readonly<TPoint>;

    constructor(from: TPoint, to: TPoint) {
        super();

        this.from = copyPoint(from);
        this.to = copyPoint(to);
    }

    toLine(): TLine {
        const { from: a, to: b } = this;
        return { x1: a.x, y1: a.y, x2: b.x, y2: b.y };
    }

    toFragment(): TFragment {
        const { from: a, to: b } = this;
        return { a: copyPoint(a), b: copyPoint(b) };
    }

    accept<R>(visitor: ISelectionElementVisitor<R>): R {
        return visitor.withVector(this);
    }
}
