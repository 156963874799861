import { observer } from "mobx-react-lite";
import React from "react";

import type { Ref } from "react";

import { BaseInput } from "./BaseInput";

import styles from "./TextInput.module.scss";

type Props = {
    title?: string;
    value: string;
    onChange: (value: string) => void;
    className?: string;
    multiline?: boolean;
    error?: string;
    disabled?: boolean;
    inline?: boolean;
    placeholder?: string;
    inputRef?: Ref<HTMLInputElement>;
    textAreaRef?: Ref<HTMLTextAreaElement>;
};

export const TextInput = observer(function TextInput(props: Props) {
    const {
        title,
        value,
        onChange,
        className,
        multiline,
        error,
        disabled,
        inline,
        placeholder,
        inputRef,
        textAreaRef,
    } = props;
    return (
        <BaseInput
            title={title}
            className={className}
            error={error}
            inline={inline}
        >
            {multiline ? (
                <textarea
                    className={styles.input}
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    rows={4}
                    disabled={disabled}
                    ref={textAreaRef}
                />
            ) : (
                <input
                    className={styles.input}
                    type="text"
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    disabled={disabled}
                    placeholder={placeholder}
                    ref={inputRef}
                />
            )}
        </BaseInput>
    );
});
