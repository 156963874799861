import type { TAnyAction } from "../types";

export type TCursorActionDirection = "left" | "right" | "up" | "down";

export type TCursorAction = {
    type: "cursor-move";
    direction: TCursorActionDirection;
};

export function createCursorMoveAction(direction: TCursorActionDirection): TCursorAction {
    return { type: "cursor-move", direction };
}

export function isCursorMoveAction(action: TAnyAction): action is TCursorAction {
    return action.type === "cursor-move";
}
