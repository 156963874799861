import { generateUuid } from "@viuch/shared/utils/data";

import type { ISerializedDigit } from "./types";
import type { BaseElementModel } from "../../core/element";
import type { TAnySerializedElement } from "../../types";

import { DigitModel } from "./DigitModel";

export function createSerializedDigit(digit: number): ISerializedDigit {
    return { type: "digit", uuid: generateUuid(), digit };
}

export function isDigit(element: BaseElementModel): element is DigitModel {
    return element instanceof DigitModel;
}

export function isSerializedDigit(serializedElement: TAnySerializedElement): serializedElement is ISerializedDigit {
    return serializedElement.type === "digit";
}
