import type { TRawSynopsisDraft, TRawSynopsisDraftShort, TRawSynopsisDraftResponse } from "./types";

import { agent } from "../../agent";
import { throwServiceError } from "../base/RequestError";

export class SynopsesDraftsService {
    async all(): Promise<TRawSynopsisDraftShort[]> {
        try {
            const { data } = await agent.get<TRawSynopsisDraftShort[]>("/synopses_drafts/");

            return data;
        } catch (e) {
            throwServiceError(e);
        }
    }

    async getSynopsisDraft(id: number): Promise<TRawSynopsisDraft> {
        try {
            const { data } = await agent.get<TRawSynopsisDraft>(`/synopses_drafts/${id}/`);

            return data;
        } catch (e) {
            throwServiceError(e);
        }
    }

    async createSynopsisDraft(body: TRawSynopsisDraft): Promise<TRawSynopsisDraftResponse> {
        try {
            const { data } = await agent.post<TRawSynopsisDraftResponse>(`/synopses_drafts/`, body);

            return data;
        } catch (e) {
            throwServiceError(e);
        }
    }

    async updateSynopsisDraft(id: number, body: TRawSynopsisDraft): Promise<TRawSynopsisDraftResponse> {
        try {
            const { data } = await agent.put<TRawSynopsisDraftResponse>(`/synopses_drafts/${id}/`, body);

            return data;
        } catch (e) {
            throwServiceError(e);
        }
    }

    async deleteSynopsisDraft(id: number): Promise<TRawSynopsisDraft> {
        try {
            const { data } = await agent.delete<TRawSynopsisDraft>(`/synopses_drafts/${id}/`);

            return data;
        } catch (e) {
            throwServiceError(e);
        }
    }
}
