import { observer } from "mobx-react-lite";
import React from "react";

import type { HighPriorityOperatorModel } from "./HighPriorityOperatorModel";
import type { TElementProps } from "../../types/element";

import { useMathEditorElementRef } from "../../hooks";
import { BaseSymbolElement } from "../base/base-symbol";

import { highPriorityOperators } from "./HighPriorityOperatorElement.constants";

export const HighPriorityOperatorElement: React.VFC<TElementProps<HighPriorityOperatorModel>> = observer(
    function HighPriorityOperatorElement({ elementModel }) {
        const { char, className } = highPriorityOperators[elementModel.symbolName];
        const ref = useMathEditorElementRef(elementModel);

        return (
            <BaseSymbolElement
                ref={ref}
                className={className}
            >
                {char}
            </BaseSymbolElement>
        );
    }
);
