import type { EditorModel } from "./EditorModel";
import type { TLinebreakAction } from "../../actions/linebreak";
import type { ContainerModel } from "../../core/container";
import type { BaseElementModel } from "../../core/element";
import type { IElementFilter } from "../../core/strategies/IElementFilter";

import { ClipboardPasteFilter } from "../../core/clipboard";
import { DefaultStrategy } from "../../core/strategies/DefaultStrategy";

export class EditorStrategy extends DefaultStrategy<EditorModel> {
    private readonly pasteFilter: IElementFilter;

    public constructor(model: EditorModel) {
        super(model);
        this.pasteFilter = ClipboardPasteFilter.Create({
            allowMultiline: this.inputService.isMultiline,
            allowEquationsSet: true,
        });
    }

    checkCanBeInserted(element: BaseElementModel, container: ContainerModel<EditorModel>): boolean {
        return this.pasteFilter.canInsert(element);
    }

    protected override handleLinebreakAction(action: TLinebreakAction) {
        if (this.inputService.isMultiline) {
            super.handleLinebreakAction(action);
        }
    }
}
