import { makeObservable, observable } from "mobx";

import { createSerializedContainer } from "@viuch/math-editor";

import type { IVariableContentVisitor } from "./IVariableContentVisitor";
import type { TSerializedState } from "@viuch/math-editor";

import { BaseVariableContent } from "./BaseVariableContent";

export interface ITextMappingVariableContent {
    key: TSerializedState;
    mapping: { key: TSerializedState; value: TSerializedState }[];
}

export class TextMappingVariableContent extends BaseVariableContent implements ITextMappingVariableContent {
    @observable key: TSerializedState;
    @observable.ref mapping: { key: TSerializedState; value: TSerializedState }[];

    constructor(data: ITextMappingVariableContent) {
        super();

        this.key = data.key;
        this.mapping = data.mapping;

        makeObservable(this);
    }

    accept<R>(visitor: IVariableContentVisitor<R>): R {
        return visitor.doWithTextMapping(this);
    }

    clone(): BaseVariableContent {
        return new TextMappingVariableContent({
            key: this.key,
            mapping: this.mapping,
        });
    }

    static createEmpty(): TextMappingVariableContent {
        return new TextMappingVariableContent({
            key: createSerializedContainer(),
            mapping: [{ key: createSerializedContainer(), value: createSerializedContainer() }],
        });
    }
}
