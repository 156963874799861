import cn from "classnames";

import type { IntegralModel } from "./IntegralModel";
import type { TElementProps } from "../../types/element";
import type { FC } from "react";

import { ContainerElement } from "../../core/container";
import { RoundBrackets } from "../../core/dynamic-brackets";

import styles from "./IntegralElement.module.scss";

export const IntegralElement: FC<TElementProps<IntegralModel>> = ({ elementModel }) => {
    return (
        <span className={cn(styles.inline, styles.wrapper)}>
            <span className={cn(styles.inline, styles.integralChar)}>∫</span>

            <span className={cn(styles.contentsWrapper)}>
                <span className={styles.inline}>
                    <ContainerElement
                        containerModel={elementModel.integralContent}
                        inline
                    />
                </span>
            </span>

            <span className={cn(styles.inline, styles.argContent)}>
                <span className={cn(styles.inline, styles.char)}>d</span>
                <RoundBrackets>
                    <ContainerElement
                        containerModel={elementModel.argContent}
                        inline
                    />
                </RoundBrackets>
            </span>
        </span>
    );
};
