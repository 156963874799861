import { action, computed, makeObservable, observable } from "mobx";

import { copyPoint } from "@viuch/geometry-lib/factories";
import { createOffsetXY } from "@viuch/geometry-lib/transformations/offset";
import { createScaleXY, createScaleY } from "@viuch/geometry-lib/transformations/scale";
import { roundLog } from "@viuch/shared/utils/math/roundLogExp";

import type { Graph2DViewSettings } from "./Graph2DViewSettings";
import type { TTransformerObject } from "@viuch/geometry-lib/transformations/transformer";
import type { TPoint } from "@viuch/geometry-lib/types";
import type { TSize } from "@viuch/math-editor/types";

export class Graph2DViewportController {
    private readonly settings: Graph2DViewSettings;
    readonly viewportTransformations: TTransformerObject[];

    @observable.ref scale: number;
    @observable.shallow position: TPoint;
    @observable.ref enabled: boolean;

    @observable.ref rootElement: HTMLElement | null = null;
    @observable.ref rootSize: TSize | null = null;

    constructor(settings: Graph2DViewSettings) {
        this.settings = settings;
        const { initialScale, initialOffsetX, initialOffsetY } = settings;

        this.scale = initialScale;
        this.position = {
            x: initialOffsetX,
            y: initialOffsetY,
        };
        this.enabled = true;

        this.viewportTransformations = [
            // инвертировать Y
            createScaleY(-1),
            // к реальной системе координат
            createScaleXY(100, 100),
            // к началу координат
            createOffsetXY(50, 50),
        ];

        makeObservable(this);
    }

    @action.bound
    setRootElement(element: HTMLElement) {
        this.rootElement = element;
    }

    @action.bound
    setRootSize(w: number, h: number) {
        this.rootSize = { w, h };
    }

    private getMinPosition(): TPoint {
        const value = 1 / this.scale;

        return {
            x: value / 2,
            y: value / 2,
        };
    }

    private followToConstraints() {
        if (this.scale < 1) {
            this.scale = 1;
        }
        const maxScale = this.settings.maxScale;
        if (this.scale > maxScale) {
            this.scale = maxScale;
        }

        const { x, y } = this.getMinPosition();

        if (this.position.x < x) {
            this.position.x = x;
        } else if (this.position.x > 1 - x) {
            this.position.x = 1 - x;
        }

        if (this.position.y < y) {
            this.position.y = y;
        } else if (this.position.y > 1 - y) {
            this.position.y = 1 - y;
        }
    }

    @action.bound
    setPosition(position: TPoint): void {
        this.position = copyPoint(position);
        this.followToConstraints();
    }

    @action.bound
    offsetPosition(offset: TPoint): void {
        this.position.x += offset.x;
        this.position.y += offset.y;
        this.followToConstraints();
    }

    @action.bound
    setScale(scale: number): void {
        this.scale = scale;
        this.followToConstraints();
    }

    @action.bound
    multiplyScale(scaleMultiplier: number): void {
        this.scale *= scaleMultiplier;
        this.followToConstraints();
    }

    @action.bound
    offsetScale(offset: number): void {
        this.scale += offset;
        this.followToConstraints();
    }

    @action.bound
    offsetScalePercent(offsetPercent: number): void {
        this.scale += this.scale * offsetPercent;
        this.followToConstraints();
    }

    @action.bound
    enable() {
        this.enabled = true;
    }

    @action.bound
    disable() {
        this.enabled = false;
    }

    @computed get bounds() {
        const {
            scale,
            position: { x: viewportPositionX, y: viewportPositionY },
        } = this;

        const viewportSize = 1 / scale;

        const left = viewportPositionX - 1 / (2 * scale);
        const right = left + viewportSize;

        const top = 1 - viewportPositionY - 1 / (2 * scale);
        const bottom = top + viewportSize;

        return { left, right, top, bottom };
    }

    @computed get gridRoundedScale(): number {
        const {
            scale,
            settings: { secondaryGridScaleFactor, secondaryGridScaleSteps },
        } = this;

        let last = 1;
        // итеративный поиск числа

        for (let power = 0; ; power++) {
            for (const m of [1, ...secondaryGridScaleSteps]) {
                const value = Math.pow(secondaryGridScaleFactor, power) * m;

                if (value > scale) {
                    return last;
                }
                last = value;
            }
        }
    }

    @computed get roundedScaleLog(): number {
        return roundLog(this.scale, this.settings.secondaryGridScaleFactor);
    }
}
