import { makeObservable, observable } from "mobx";

import { BaseDrawingGenerationSettings } from "./BaseDrawingGenerationSettings";

export class ShapeArea extends BaseDrawingGenerationSettings {
    @observable width: number;
    @observable height: number;
    @observable minArea: number;
    @observable maxArea: number;

    constructor(init: ShapeArea.Init) {
        super();

        this.width = init.width;
        this.height = init.height;
        this.minArea = init.minArea;
        this.maxArea = init.maxArea;

        makeObservable(this);
    }

    accept<R>(visitor: BaseDrawingGenerationSettings.Visitor<R>): R {
        return visitor.withShapeArea(this);
    }

    static createEmpty() {
        return new ShapeArea({
            minArea: 15,
            maxArea: 25,
            width: 8,
            height: 8,
        });
    }

    clone(): ShapeArea {
        return new ShapeArea(this);
    }
}

export declare namespace ShapeArea {
    export type Init = {
        width: number;
        height: number;
        minArea: number;
        maxArea: number;
    };
}
