import type { Figure2D } from "../../entities/Figure2D";
import type { Figure2DController, IFigure2DController, LazyFactory } from "../Figure2DController";
import type { THandlersObject } from "../FigureViewModel";

import { EventsController } from "../events";
import { FlowManager } from "../flows";
import { HistoryManager } from "../history";
import { SelectionController } from "../selection";
import { DeviceSettings, SnapToGridService } from "../services";
import { ActionsManager } from "../services/actions";
import { MoveDotService } from "../services/MoveDotService";
import { ModelNormalizer } from "../services/normalizer";
import { ToolbarController } from "../toolbar";
import { ViewportController } from "../viewport";

/**
 * Функция инициализации была вынесена из класса с инициализаций из-за циклической ссылки
 */
export function getInitControllerData(
    figure: Figure2D,
    handlers: THandlersObject
): LazyFactory<IFigure2DController, [data: Figure2DController]> {
    return {
        getToolbar: (data) => new ToolbarController(data),
        getEvents: (data) => new EventsController(data),
        getFlows: (data) => new FlowManager(data),
        getViewport: (data) => new ViewportController(data),
        getSelection: (data) => new SelectionController(data),
        getActions: (data) => new ActionsManager(data),
        getNormalizer: (data) => new ModelNormalizer(data),
        getHistory: (data) => new HistoryManager(data),
        getSnap: (data) => new SnapToGridService(data),
        getMoveDot: (data) => new MoveDotService(data),
        getDevice: () => new DeviceSettings(),
        getHandlers: () => handlers,
    };
}
