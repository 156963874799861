import { action, makeObservable, observable } from "mobx";

import { createSerializedContainer } from "@viuch/math-editor";
import { assert } from "@viuch/utils/debug";

import type { TSerializedState } from "@viuch/math-editor";

export class PrimaryHints {
    @observable.shallow hints: TSerializedState[];

    constructor(hints: readonly TSerializedState[]) {
        this.hints = hints.slice();

        makeObservable(this);
    }

    @action.bound
    addHint(toIndex: number, hint: TSerializedState) {
        assert(toIndex >= 0);
        assert(toIndex <= this.hints.length);

        this.hints.splice(toIndex, 0, hint);
    }
    @action.bound
    addEmptyHint(toIndex: number) {
        this.addHint(toIndex, createSerializedContainer());
    }

    @action.bound
    removeHint(index: number) {
        assert(index >= 0);
        assert(index < this.hints.length);

        this.hints.splice(index, 1);
    }

    @action.bound
    updateHint(index: number, hint: TSerializedState) {
        this.hints[index] = hint;
    }

    @action.bound
    setHints(hints: TSerializedState[]) {
        this.hints = hints;
    }

    @action.bound
    reorderHint(index: number, toIndex: number) {
        const [hint] = this.hints.splice(index, 1);
        this.hints.splice(toIndex, 0, hint);
    }

    clone(): PrimaryHints {
        return new PrimaryHints(this.hints);
    }
}
