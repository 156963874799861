import { computed, makeObservable } from "mobx";

import type { TPairItem, TStackItem } from "./PairBracketsContainerPlugin.types";
import type { ContainerModel } from "../container";
import type { BaseElementModel } from "../element";

import { isBracket } from "../../elements/bracket/utils";
import { isLinebreak } from "../../elements/linebreak/utils";
import { lastInArray } from "../../utils/data";

export class PairBracketsContainerPlugin {
    private container: ContainerModel;

    constructor(container: ContainerModel) {
        this.container = container;

        makeObservable(this, {
            modelsBetweenBrackets: computed,
        });
    }

    get modelsBetweenBrackets() {
        const pairs: TPairItem[] = [];

        let openBracketsStack: TStackItem[] = [];

        this.container.elements.forEach((element) => {
            function appendToLastStackItem() {
                const prevStackItem = lastInArray(openBracketsStack);
                if (prevStackItem) {
                    prevStackItem.elements.push(element);
                }
            }

            if (isLinebreak(element)) {
                openBracketsStack = [];
                return;
            }

            if (isBracket(element)) {
                const memoElements: BaseElementModel[] = [];

                if (!element.closing) {
                    appendToLastStackItem();

                    openBracketsStack.push({
                        openBracket: element,
                        elements: [],
                    });
                    return;
                }

                let stackItem: TStackItem | undefined;
                while ((stackItem = openBracketsStack.pop())) {
                    if (stackItem.openBracket.type === element.type) {
                        const openBracket = stackItem.openBracket;
                        const elementsBetween = [...stackItem.elements, ...memoElements];
                        const closeBracket = element;

                        appendToLastStackItem();

                        pairs.push({
                            openBracket,
                            closeBracket,
                            elementsBetween,
                        });
                        return;
                    }
                    memoElements.unshift(...stackItem.elements);
                }
                openBracketsStack = [];
                return;
            }

            appendToLastStackItem();
        });

        return pairs;
    }
}
