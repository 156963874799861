import cn from "classnames";
import { observer } from "mobx-react-lite";
import React from "react";

import { IntervalsMethodEditor } from "@viuch/instrument-intervals/statement-editor";
import { Portal } from "@viuch/shared/features/portal-lib";
import { MathInput, SelectInput } from "@viuch/ui-common";
import { InstrumentFrame, ModalFrame } from "@viuch/ui-kit/frames";
import { ModalBackdrop, ModalLayout } from "@viuch/ui-kit/surfaces";
import { useConst } from "@viuch/utils/hooks";

import type { IntervalsInstrumentStatement, TDotForm } from "@viuch/instrument-intervals/statement";
import type { KeyboardService } from "@viuch/math-editor";

import { dotTypesData } from "./constants";
import { IntervalsInstrumentSettingsEditorViewModel } from "./IntervalsInstrumentSettingsEditor.vm";

import styles from "./IntervalsInstrumentSettingsEditor.module.scss";

type Props = {
    intervals: IntervalsInstrumentStatement;
    keyboardService: KeyboardService;
    className?: string;
};

export const IntervalsInstrumentSettingsEditor = observer(function IntervalsInstrumentSettingsEditor({
    className,
    intervals,
    keyboardService,
}: Props) {
    const vm = useConst(() => new IntervalsInstrumentSettingsEditorViewModel(intervals));

    const [dotType, setDotType] = React.useState<TDotForm>("filled");

    const handleAddDot = () => {
        vm.handleAddDot(dotType);
    };

    const handleEditDot = () => {
        vm.handleEditDot(dotType);
    };

    return (
        <div className={cn(className, styles.wrapper)}>
            <InstrumentFrame className={styles.instrument}>
                <IntervalsMethodEditor
                    intervals={intervals}
                    onAddDotRequested={vm.handleAddDotRequest}
                    onEditDotRequested={vm.handleEditDotRequest}
                    onClearRequested={vm.handleClearRequest}
                    mathKeyboard={keyboardService}
                />
            </InstrumentFrame>
            {vm.lazyAddDotFields && (
                <Portal>
                    <ModalBackdrop
                        onClick={vm.handleAddDotCancel}
                        className={styles.backdrop}
                    >
                        <ModalFrame className={styles.frame}>
                            <ModalLayout
                                title="Добавить точку"
                                onClose={vm.handleAddDotCancel}
                            >
                                <div className={styles.popup}>
                                    <MathInput
                                        inputService={vm.lazyAddDotFields.payload.valueInputService}
                                        keyboardService={keyboardService}
                                        title="Значение"
                                    />
                                    <SelectInput
                                        data={dotTypesData}
                                        value={dotType}
                                        onChange={setDotType}
                                    />
                                    <button onClick={handleAddDot}>Добавить</button>
                                </div>
                            </ModalLayout>
                        </ModalFrame>
                    </ModalBackdrop>
                </Portal>
            )}
            {vm.lazyEditDotFields && (
                <Portal>
                    <ModalBackdrop
                        onClick={vm.handleEditDotCancel}
                        className={styles.backdrop}
                    >
                        <ModalFrame className={styles.frame}>
                            <ModalLayout
                                onClose={vm.handleEditDotCancel}
                                title="Редактировать точку"
                            >
                                <div className={styles.popup}>
                                    <MathInput
                                        inputService={vm.lazyEditDotFields.payload.valueInputService}
                                        keyboardService={keyboardService}
                                        title="Значение"
                                    />
                                    <SelectInput
                                        data={dotTypesData}
                                        value={dotType}
                                        onChange={setDotType}
                                    />
                                    <button onClick={handleEditDot}>Добавить</button>
                                    <button onClick={vm.handleRemoveEditableDot}>Удалить</button>
                                </div>
                            </ModalLayout>
                        </ModalFrame>
                    </ModalBackdrop>
                </Portal>
            )}
        </div>
    );
});
