import { action, makeObservable, observable } from "mobx";

import { createSerializedContainer } from "@viuch/math-editor";

import type { IMultichoiceInstrumentSettingsFieldVisitor } from "../BaseMultichoiceInstrumentSettingsField";
import type { TSerializedState } from "@viuch/math-editor/types";

import { BaseMultichoiceInstrumentSettingsField } from "../BaseMultichoiceInstrumentSettingsField";

export class FormulaMultichoiceInstrumentSettingsField extends BaseMultichoiceInstrumentSettingsField {
    @observable.ref formula: TSerializedState;

    constructor(inputState: TSerializedState, checked: boolean, mistakeHint: TSerializedState | null) {
        super(checked, mistakeHint);

        this.formula = inputState ?? createSerializedContainer();

        makeObservable(this);
    }

    static createEmpty(): FormulaMultichoiceInstrumentSettingsField {
        return new FormulaMultichoiceInstrumentSettingsField(createSerializedContainer(), false, null);
    }

    @action.bound
    setFormula(newState: TSerializedState): void {
        this.formula = newState;
    }

    clone(): FormulaMultichoiceInstrumentSettingsField {
        return new FormulaMultichoiceInstrumentSettingsField(this.formula, this.checked, this.mistakeHint);
    }

    accept<R>(visitor: IMultichoiceInstrumentSettingsFieldVisitor<R>): R {
        return visitor.withFormula(this);
    }
}
