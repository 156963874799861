import { observer } from "mobx-react-lite";
import React from "react";

import type { LowPriorityOperatorModel } from "./LowPriorityOperatorModel";
import type { TElementProps } from "../../types/element";

import { useMathEditorElementRef } from "../../hooks";
import { BaseSymbolElement } from "../base/base-symbol";

import { lowPriorityChars } from "./LowPriorityOperatorElement.constants";

export const LowPriorityOperatorElement: React.VFC<TElementProps<LowPriorityOperatorModel>> = observer(
    function LowPriorityOperatorElement({ elementModel }) {
        const char = lowPriorityChars[elementModel.symbolName];
        const ref = useMathEditorElementRef(elementModel);

        return <BaseSymbolElement ref={ref}>{char}</BaseSymbolElement>;
    }
);
