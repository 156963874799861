import cn from "classnames";
import { observer } from "mobx-react-lite";
import { useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import { Rnd } from "react-rnd";

import { ModalFrame } from "@viuch/ui-kit/frames";
import { ModalBackdrop, ModalLayout } from "@viuch/ui-kit/surfaces";
import { useConst } from "@viuch/utils/hooks";

import type { PropsWithChildren } from "react";

import { ModalWindowCloseReasonEnum } from "./constants";

import styles from "./ModalWindow.module.scss";

type Props = PropsWithChildren<{
    title: string;
    className?: string;
    onClose?(reason: ModalWindowCloseReasonEnum): void;
}>;

export const ModalWindow = observer(function ModalWindow(props: Props) {
    const { title, onClose, className, children } = props;

    const handleClose = onClose
        ? () => {
              onClose(ModalWindowCloseReasonEnum.closeCrossClick);
          }
        : void 0;

    const rndRef = useRef<Rnd>(null);

    useEffect(() => {
        rndRef.current!.updatePosition({ x: 0, y: 0 });
    }, []);

    const container = useConst(() => document.getElementById("modal-root")!);

    return createPortal(
        <ModalBackdrop
            className={styles.modalBackdrop}
            onClick={() => onClose?.(ModalWindowCloseReasonEnum.backdropClick)}
        >
            <Rnd
                className={cn(styles.rnd, className)}
                bounds="parent"
                dragHandleClassName={styles.draggable}
                style={{ top: "unset", left: "unset" }}
                ref={rndRef}
            >
                <ModalFrame className={styles.modalFrame}>
                    <ModalLayout
                        title={title}
                        onClose={handleClose}
                        className={styles.modalLayout}
                    >
                        <div className={styles.modalChildren}>{children}</div>
                    </ModalLayout>
                </ModalFrame>
            </Rnd>
        </ModalBackdrop>,
        container
    );
});
