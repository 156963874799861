import type { TMathKeyboardButton, TTouchKeyboardLanguageVM } from "./types";

import { createRemoveAction } from "../../../actions/remove";
import { createResultAction } from "../../../actions/result";
import { __miscTag } from "../../../core/keyboard-button-tags/constants";

export const languagesViewModel: TTouchKeyboardLanguageVM[] = [
    { lang: "ru", label: "Русский" },
    { lang: "en", label: "Английский" },
    { lang: "gr", label: "Греческий" },
];

export const backspaceButton: TMathKeyboardButton = {
    actionCreator: () => createRemoveAction("backward"),
    icon: "backspace",
    tags: [__miscTag],
};

export const resultButton: TMathKeyboardButton = {
    actionCreator: () => createResultAction(),
    icon: "result",
    tags: [__miscTag],
};
