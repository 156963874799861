import { action, makeObservable, observable } from "mobx";

import { deepClone } from "@viuch/shared/utils/data/copy";

import type { IndexingEntityMetadataOnly } from "../indexing/IndexingEntityMetadataOnly";
import type { SynopsisEntityChecks } from "../indexing/SynopsisEntityChecks";
import type { TComplexEditorUnion } from "@viuch/complex-editor";
import type { ISynopsis } from "@viuch/feature-synopsis";

export class Synopsis implements Synopsis.Init, ISynopsis {
    @observable id;
    @observable name;
    @observable themes;
    @observable introduction;
    @observable theory;
    @observable conclusion;
    @observable interestingFacts;
    @observable mainThemeId;
    readonly indexing: IndexingEntityMetadataOnly;
    readonly checks: SynopsisEntityChecks;

    constructor(init: Synopsis.Init) {
        this.id = init.id;
        this.name = init.name;
        this.themes = deepClone(init.themes);
        this.introduction = deepClone(init.introduction);
        this.theory = deepClone(init.theory);
        this.conclusion = deepClone(init.conclusion);
        this.interestingFacts = deepClone(init.interestingFacts);
        this.indexing = init.indexing;
        this.mainThemeId = init.mainThemeId;
        this.checks = init.checks;

        makeObservable(this);
    }

    clone() {
        const { id, name, themes, introduction, theory, conclusion, interestingFacts, indexing, checks, mainThemeId } =
            this;

        return new Synopsis({
            id,
            name,
            themes,
            introduction,
            theory,
            conclusion,
            interestingFacts,
            mainThemeId,
            checks: checks.clone(),
            indexing: indexing.clone(),
        });
    }

    @action.bound
    setName(name: string) {
        this.name = name;
    }

    @action.bound
    removeTheme(themeId: number) {
        this.themes.remove(themeId);

        if (!this.themes.includes(this.mainThemeId)) {
            this.mainThemeId = null;
        }
    }

    @action.bound
    addTheme(themeId: number) {
        if (this.themes.includes(themeId)) return;

        this.themes.push(themeId);

        if (this.themes.length === 1) {
            this.mainThemeId = themeId;
        }
    }

    @action.bound
    setMainThemeId(themeId: number) {
        if (this.themes.includes(themeId)) {
            this.mainThemeId = themeId;
        }
    }

    attachedViewModel?: Synopsis.ViewModel;

    attachViewModel(viewModel: Synopsis.ViewModel): void {
        this.attachedViewModel = viewModel;
    }

    detachViewModel(viewModel: Synopsis.ViewModel) {
        if (viewModel === this.attachedViewModel) {
            this.attachedViewModel = void 0;
        }
    }
}

export namespace Synopsis {
    export type Init = {
        id: number;
        name: string;
        themes: number[];
        introduction: TComplexEditorUnion;
        theory: TComplexEditorUnion;
        conclusion: TComplexEditorUnion;
        interestingFacts: TComplexEditorUnion | null;
        indexing: IndexingEntityMetadataOnly;
        checks: SynopsisEntityChecks;
        mainThemeId: number | null;
    };

    export type ViewModel = {
        saveAsync(): Promise<void>;
    };
}
