import { action, makeObservable, observable } from "mobx";

import { copyPoint } from "@viuch/geometry-lib/factories";

import type { Figure2DController } from "../Figure2DController";
import type { TPoint } from "@viuch/geometry-lib/types";

export class ViewportController {
    private readonly data: Figure2DController;

    @observable
    scale: number;

    @observable
    position: TPoint;

    @observable
    aspectRatio: number;

    @observable
    enabled: boolean;

    constructor(data: Figure2DController) {
        this.data = data;

        const { initialScale, initialOffsetX, initialOffsetY } = data.figure.settings;

        this.scale = initialScale;
        this.position = { x: initialOffsetX, y: initialOffsetY };
        this.aspectRatio = 1;
        this.enabled = true;

        makeObservable(this);
    }

    protected subscriptions: VoidFunction[] = [];

    dispose() {
        this.subscriptions.forEach((fn) => fn());
    }

    private getMinPosition(): TPoint {
        const ar = this.getAspectRatioComponents(this.aspectRatio);
        const zoom = this.scale;

        const zoomX = zoom * ar.y;
        const zoomY = zoom * ar.x;
        const viewAreaX = 1 / zoomX;
        const viewAreaY = 1 / zoomY;

        return { x: viewAreaX / 2, y: viewAreaY / 2 };
    }

    private getAspectRatioComponents(aspectRatio: number): TPoint {
        if (aspectRatio >= 1) {
            return { x: aspectRatio, y: 1 };
        }
        return { x: 1, y: 1 / aspectRatio };
    }

    private followToConstraints() {
        if (this.scale < 1) {
            this.scale = 1;
        }
        const maxScale = this.data.figure.settings.maxScale;
        if (this.scale > maxScale) {
            this.scale = maxScale;
        }

        const { x, y } = this.getMinPosition();

        if (this.position.x < x) {
            this.position.x = x;
        } else if (this.position.x > 1 - x) {
            this.position.x = 1 - x;
        }

        if (this.position.y < y) {
            this.position.y = y;
        } else if (this.position.y > 1 - y) {
            this.position.y = 1 - y;
        }
    }

    @action.bound
    setAspectRatio(aspectRatio: number) {
        this.aspectRatio = aspectRatio;
        this.followToConstraints();
    }

    @action.bound
    setPosition(position: TPoint): void {
        this.position = copyPoint(position);
        this.followToConstraints();
    }

    @action.bound
    offsetPosition(offset: TPoint): void {
        this.position.x += offset.x;
        this.position.y += offset.y;
        this.followToConstraints();
    }

    @action.bound
    setScale(scale: number): void {
        this.scale = scale;
        this.followToConstraints();
    }

    @action.bound
    multiplyScale(scaleMultiplier: number): void {
        this.scale *= scaleMultiplier;
        this.followToConstraints();
    }

    @action.bound
    offsetScale(offset: number): void {
        this.scale += offset;
        this.followToConstraints();
    }

    @action.bound
    offsetScalePercent(offsetPercent: number): void {
        this.scale += this.scale * offsetPercent;
        this.followToConstraints();
    }

    @action.bound
    enable() {
        this.enabled = true;
    }

    @action.bound
    disable() {
        this.enabled = false;
    }
}
