import { action, makeObservable, observable } from "mobx";

import type { Figure2DSettingsCompletion } from "./Figure2DSettingsCompletion";
import type { TPoint } from "@viuch/geometry-lib/types";

import { Figure2DSettings } from "./Figure2DSettings";

export type TFigure2DWithCompletionsSettings = {
    initial: Figure2DSettings;
    completions: Figure2DSettingsCompletion[];
    viewSettings: {
        initialPosition: TPoint;
        initialScale: number;
    };
};

export class Figure2DWithCompletionsSettings {
    @observable.ref initial: Figure2DSettings;
    @observable.shallow completions: Figure2DSettingsCompletion[];

    @observable.ref initialPosition: TPoint;
    @observable.ref initialScale: number;

    constructor(data: TFigure2DWithCompletionsSettings) {
        this.initial = data.initial;
        this.completions = data.completions.slice();
        this.initialPosition = data.viewSettings.initialPosition;
        this.initialScale = data.viewSettings.initialScale;

        makeObservable(this);
    }

    @action.bound
    setInitialViewSettings(position: TPoint, scale: number) {
        this.initialPosition = position;
        this.initialScale = scale;
    }

    clone(): Figure2DWithCompletionsSettings {
        return new Figure2DWithCompletionsSettings({
            initial: this.initial.clone(),
            completions: this.completions.map((completion) => completion.clone()),
            viewSettings: {
                initialPosition: this.initialPosition,
                initialScale: this.initialScale,
            },
        });
    }

    static createEmpty() {
        return new Figure2DWithCompletionsSettings({
            initial: Figure2DSettings.createEmpty(),
            completions: [],
            viewSettings: {
                initialPosition: { x: 0.5, y: 0.5 },
                initialScale: 1,
            },
        });
    }
}
