import { observer } from "mobx-react-lite";

import type { SlashModel } from "./SlashModel";
import type { TElementProps } from "../../types/element";
import type { VFC } from "react";

import { useMathEditorElementRef } from "../../hooks";

import styles from "./SlashElement.module.scss";

export const SlashElement: VFC<TElementProps<SlashModel>> = observer(function SlashElement({ elementModel }) {
    const ref = useMathEditorElementRef(elementModel);

    return (
        <span
            ref={ref}
            className={styles.inlineWrapper}
        >
            <span>/</span>
        </span>
    );
});
