export const scrollMargin = 15;

export function computeNewScrollAxis(
    scrollPos: number,
    cursorPos: number,
    inputPos: number,
    cursorSize: number,
    inputSize: number,
    scrollMargin: number
): number {
    const offset = cursorPos - inputPos;

    if (offset < scrollMargin) {
        return scrollPos + offset - scrollMargin;
    }
    const bottom = inputPos + inputSize - cursorPos - cursorSize;

    if (bottom < scrollMargin) {
        return scrollPos - bottom + scrollMargin;
    }

    return scrollPos;
}
