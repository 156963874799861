import type { BaseDrawingGenerationSettings } from "../../../entities/tasks/variables/generated-drawing/BaseDrawingGenerationSettings";
import type { TDrawingGenerationSettings, TDrawingGenerationViewport } from "../types";

import { DistanceToCenter } from "../../../entities/tasks/variables/generated-drawing/DistanceToCenter";
import { DistanceToLine } from "../../../entities/tasks/variables/generated-drawing/DistanceToLine";
import { DrawingGenerationViewport } from "../../../entities/tasks/variables/generated-drawing/DrawingGenerationViewport";
import { ShapeArea } from "../../../entities/tasks/variables/generated-drawing/ShapeArea";

export function mapDrawingSettings(drawing_settings: TDrawingGenerationSettings): BaseDrawingGenerationSettings {
    switch (drawing_settings.kind) {
        case "shape_area": {
            const { width, height, min_area: minArea, max_area: maxArea } = drawing_settings;
            return new ShapeArea({ width, height, minArea, maxArea });
        }
        case "distance_to_line": {
            const { draw_lines: drawLines, viewport } = drawing_settings;
            return new DistanceToLine({
                drawLines,
                viewport: mapDrawingGenerationViewport(viewport),
            });
        }
        case "distance_to_center": {
            const { viewport, draw_lines: drawLines } = drawing_settings;
            return new DistanceToCenter({
                drawLines,
                viewport: mapDrawingGenerationViewport(viewport),
            });
        }
    }
}

const serializeDrawingSettingsVisitor: BaseDrawingGenerationSettings.Visitor<TDrawingGenerationSettings> = {
    withShapeArea: ({ width, height, minArea: min_area, maxArea: max_area }) => ({
        kind: "shape_area",
        width,
        height,
        min_area,
        max_area,
    }),
    withDistanceToLine: ({ drawLines: draw_lines, viewport }) => ({
        kind: "distance_to_line",
        draw_lines,
        viewport: serializeDrawingGenerationViewport(viewport),
    }),
    withDistanceToCenter: ({ drawLines: draw_lines, viewport }) => ({
        kind: "distance_to_line",
        draw_lines,
        viewport: serializeDrawingGenerationViewport(viewport),
    }),
};

export function serializeDrawingSettings(settings: BaseDrawingGenerationSettings): TDrawingGenerationSettings {
    return settings.accept(serializeDrawingSettingsVisitor);
}

export function serializeDrawingGenerationViewport(viewport: DrawingGenerationViewport): TDrawingGenerationViewport {
    const { min_x, min_y, max_x, max_y } = viewport;
    return { min_x, min_y, max_x, max_y };
}

export function mapDrawingGenerationViewport(viewport: TDrawingGenerationViewport): DrawingGenerationViewport {
    const { min_x, min_y, max_x, max_y } = viewport;
    return new DrawingGenerationViewport({ min_x, min_y, max_x, max_y });
}
