import type { IBaseEditor } from "../editors/base";
import type { TComplexEditorDataUnion, TComplexEditorUnion } from "../editors/types";

import { EditorTypes } from "../editors/base";
import { FormatEditor } from "../editors/format";
import { Figure2DEditor } from "../editors/instruments/Figure2DEditor";
import { Graph1DEditor } from "../editors/instruments/Graph1DEditor";
import { Graph2DEditor } from "../editors/instruments/Graph2DEditor";
import { IntervalsEditor } from "../editors/instruments/IntervalsEditor";
import { PictureEditor } from "../editors/instruments/PictureEditor";
import { TableInstrumentEditor } from "../editors/instruments/TableInstrumentEditor";
import { MathEditor } from "../editors/math";

export function createEmptyComplexEditorData(): TComplexEditorUnion {
    return {
        data: [{ type: EditorTypes.format }],
    };
}

export function createEditorByData(data: TComplexEditorDataUnion): IBaseEditor {
    switch (data.type) {
        case EditorTypes.format:
            return new FormatEditor(data.state);
        case EditorTypes.math:
            return new MathEditor(data.state, data.textRepresentation);
        case EditorTypes.picture:
            return new PictureEditor(data.settings);
        case EditorTypes.intervals:
            return new IntervalsEditor(data);
        case EditorTypes.table:
        case EditorTypes.tableSelect:
            return new TableInstrumentEditor(data.type, data.settings);
        case EditorTypes.graph1d:
            return new Graph1DEditor(data);
        case EditorTypes.figure2d:
            return new Figure2DEditor(data);
        case EditorTypes.graph2d:
            return new Graph2DEditor(data);
    }
}

export function isEmptyEditorData(editorData: TComplexEditorUnion): boolean {
    if (editorData.data.length === 0) return true;

    for (const data of editorData.data) {
        switch (data.type) {
            case EditorTypes.format:
                if (data.state?.blocks.length) return false;
                break;
            case EditorTypes.math:
                if (data.state?.elements.length) return false;
                break;
            case EditorTypes.picture:
            case EditorTypes.intervals:
            case EditorTypes.table:
            case EditorTypes.tableSelect:
            case EditorTypes.graph1d:
            case EditorTypes.figure2d:
                return false;
        }
    }

    return true;
}
