import cn from "classnames";
import { observer } from "mobx-react-lite";

import type { PropsWithChildren } from "react";

import styles from "./HintFrame.module.scss";

type Props = PropsWithChildren<{
    variant: "desktop" | "tablet" | "mobile";
    className?: string;
    outerClassName?: string;
    innerClassName?: string;
    disableShadows?: boolean;
    everyClassName?: string;
}>;

export const HintFrame = observer(function HintFrame({
    className,
    children,
    innerClassName,
    outerClassName,
    disableShadows,
    everyClassName,
    variant,
}: Props) {
    return (
        <div
            className={cn(
                styles.wrapper,
                everyClassName,
                className,
                disableShadows && styles.disableShadows,
                styles[variant]
            )}
        >
            <div className={cn(styles.outer, everyClassName, outerClassName)}>
                <div className={cn(styles.inner, everyClassName, innerClassName)}>{children}</div>
            </div>
        </div>
    );
});
