import type { SubsectionsFilters } from "../../entities/attention-filters/SubsectionFilters";
import type { GetError } from "../base/RequestError";

import { agent } from "../../agent";
import { Subsection } from "../../entities/hierarchy";
import { IndexingEntityMetadata } from "../../entities/indexing/IndexingEntityMetadata";
import { BaseCRUDService } from "../base";

export class SubsectionsService extends BaseCRUDService<Subsection, ISubsectionRequest, ISubsectionResponse> {
    constructor() {
        super("/subsections/", mapSubsection, serializeSubsection);
    }

    async getBySectionId(...sectionIds: number[]): Promise<Subsection[]> {
        const { data } = await agent.get<ISubsectionResponse[]>("/subsections/", {
            params: { sections_in: sectionIds.join() },
        });

        return data.map(mapSubsection);
    }

    async getBySubjectId(...subjectIds: number[]): Promise<Subsection[]> {
        const { data } = await agent.get<ISubsectionResponse[]>("/subsections/", {
            params: { subjects_in: subjectIds.join() },
        });

        return data.map(mapSubsection);
    }

    async search(filters: SubsectionsFilters, signal: AbortSignal): Promise<Subsection[]> {
        const params = serializeSubsectionFilters(filters);
        const { data } = await agent.get<ISubsectionResponse[]>(`/subsections/`, { signal, params });

        return data.map(mapSubsection);
    }
}

export interface ISubsectionRequest {
    id: number;
    slug: string;
    name: string;
    sections: number[];
    tags: string[];
    themes: number[];
    meta_title: string | null;
    meta_description: string | null;
}

export interface ISubsectionResponse extends ISubsectionRequest {
    video_count: number;
    synopsis_count: number;
    task_count: number;
    problem_count: number;
    unverified_task_count: number;
}

export type TSubsectionError = GetError<ISubsectionRequest>;

export const mapSubsection = (data: ISubsectionResponse): Subsection => {
    const {
        id,
        name,
        slug,
        sections,
        tags,
        themes,
        meta_title,
        meta_description,
        video_count,
        synopsis_count,
        task_count,
        unverified_task_count,
        problem_count,
    } = data;

    return new Subsection({
        id,
        name,
        sections,
        tags,
        themes,
        video_count,
        synopsis_count,
        task_count,
        problem_count,
        unverified_task_count,
        indexing: new IndexingEntityMetadata(meta_title, meta_description, slug),
    });
};

export const serializeSubsection = ({
    id,
    name,
    sections,
    tags,
    themes,
    indexing,
}: Subsection): ISubsectionRequest => ({
    id,
    name,
    sections,
    tags,
    themes,
    slug: indexing.slug,
    meta_title: indexing.title,
    meta_description: indexing.description,
});

export const serializeSubsectionFilters = (filters: SubsectionsFilters) => {
    const data: Record<string, string> = {};

    filters.is_empty && (data.is_empty = "true");
    filters.is_detached && (data.is_detached = "true");
    filters.search && (data.search = filters.search);
    filters.is_meta_title_null && (data.is_meta_title_null = "true");
    filters.is_meta_description_null && (data.is_meta_description_null = "true");

    return new URLSearchParams(data);
};
