import { observer } from "mobx-react-lite";
import React from "react";

import type { IToolbarElement } from "./toolbarTypes";
import type { Graph2DToolbar } from "../core/Graph2DToolbar";
import type { RefObject } from "react";

type Props = {
    toolbar: Graph2DToolbar;
    element: IToolbarElement;
    rootRef: RefObject<HTMLDivElement>;
    className?: string;
};

export const AnyToolbarElement = observer(function AnyToolbarElement({ element, toolbar, rootRef, className }: Props) {
    element = toolbar.getElementOverride(element);

    const renderToolbarElement = toolbar.getElementRenderer(element);

    return <>{renderToolbarElement({ className, toolbar, rootRef })}</>;
});
