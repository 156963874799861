import { generateUuid } from "@viuch/shared/utils/data";

import type { ISerializedTrigonometricOperator, TTrigonometricOperator } from "./types";
import type { TSerializedContainer } from "../../types";

import { createSerializedContainer } from "../../utils/serialization";

export function createSerializedTrigonometricOperator(
    operator: TTrigonometricOperator,
    content: TSerializedContainer = createSerializedContainer(),
    degreeContent: TSerializedContainer = createSerializedContainer()
): ISerializedTrigonometricOperator {
    return {
        type: "trigonometric-operator",
        uuid: generateUuid(),
        symbolName: operator,
        degreeContent,
        content,
    };
}
