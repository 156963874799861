import type { RootModel } from "./RootModel";
import type { ContainerModel } from "../../core/container";
import type { BaseElementModel } from "../../core/element";
import type { IElementFilter } from "../../core/strategies/IElementFilter";

import { KeepCommonFilter } from "../../core/strategies/filters/KeepCommonFilter";
import { MultiContainerElementStrategy } from "../../core/strategies/MultiContainerElementStrategy";

export class RootElementStrategy extends MultiContainerElementStrategy<RootModel> {
    private indexFilter: IElementFilter;

    constructor(model: RootModel) {
        super(model);

        // disable 3,5,7,8,9,10
        this.indexFilter = new KeepCommonFilter();
    }

    override checkCanBeInserted(element: BaseElementModel, container: ContainerModel<RootModel>): boolean {
        if (container === this.model.indicator) {
            return this.indexFilter.canInsert(element);
        }
        return super.checkCanBeInserted(element, container);
    }
}
