import type { TDispatchKeyParams } from "../services/keyboard";
import type { TAnyAction } from "../types";

import { createBracketAction } from "../actions/bracket";
import { createCharAction } from "../actions/char";
import { createClipboardAction } from "../actions/clipboard";
import { createCursorMoveAction } from "../actions/cursor";
import { createDigitAction } from "../actions/digit";
import { createEqualityAction } from "../actions/equality";
import { createHistoryAction } from "../actions/history";
import { createLinebreakAction } from "../actions/linebreak";
import { createHighPriorityAction } from "../actions/operator-high";
import { createLowPriorityOperatorAction } from "../actions/operator-low";
import { createRemoveAction } from "../actions/remove";
import { createSlashAction } from "../actions/slash";
import { createSpaceAction } from "../actions/space";
import { createSymbolAction } from "../actions/symbol";

import { checkCharIsDigit, checkStringIsChar } from "./validation";

export const mapCharToAction: Record<string, TAnyAction> = {
    ",": createSymbolAction("comma"),
    "+": createLowPriorityOperatorAction("plus"),
    "-": createLowPriorityOperatorAction("minus"),
    "*": createHighPriorityAction("multiply"),
    ">": createEqualityAction("greater"),
    "<": createEqualityAction("less"),
    "=": createEqualityAction("equals"),
    ".": createSymbolAction("dot"),
    ":": createSymbolAction("colon"),
    ";": createSymbolAction("semicolon"),
    "%": createSymbolAction("percent"),
    "!": createSymbolAction("factorial"),
    "/": createSlashAction(),
    "(": createBracketAction("round"),
    ")": createBracketAction("round", { closing: true }),
    "[": createBracketAction("square"),
    "]": createBracketAction("square", { closing: true }),
    "{": createBracketAction("curly"),
    "}": createBracketAction("curly", { closing: true }),
    $: createSymbolAction("dollar"),
    "?": createSymbolAction("question"),
};

// noinspection NonAsciiCharacters
export const ctrlCharToAction: Record<string, TAnyAction | undefined> = {
    z: createHistoryAction("undo"),
    я: createHistoryAction("undo"),
    y /*EN*/: createHistoryAction("redo"),
    н: createHistoryAction("redo"),
    c /*EN*/: createClipboardAction("copy"),
    с /*RU*/: createClipboardAction("copy"),
    v: createClipboardAction("paste"),
    м: createClipboardAction("paste"),
    x /*EN*/: createClipboardAction("cut"),
    ч: createClipboardAction("cut"),
};

// noinspection NonAsciiCharacters
export const ctrlShiftCharToAction: Record<string, TAnyAction> = {
    z: createHistoryAction("redo"),
    я: createHistoryAction("redo"),
};

export function createActionFromChar(char: string): TAnyAction | undefined {
    const actionFromMap = mapCharToAction[char];

    if (actionFromMap) {
        return actionFromMap;
    }

    if (checkCharIsDigit(char)) {
        return createDigitAction(Number(char));
    }

    if (checkStringIsChar(char)) {
        return createCharAction(char);
    }
}

export const mapKeyCodeToAction: Partial<Record<string, TAnyAction>> = {
    Enter: createLinebreakAction("down"),
    NumpadEnter: createLinebreakAction("down"),
    ArrowLeft: createCursorMoveAction("left"),
    ArrowRight: createCursorMoveAction("right"),
    ArrowUp: createCursorMoveAction("up"),
    ArrowDown: createCursorMoveAction("down"),
    Backspace: createRemoveAction("backward"),
    Delete: createRemoveAction("forward"),
    Space: createSpaceAction(),
    NumpadDivide: createHighPriorityAction("divide"),
};

export function getActionFromKeyParams({
    key,
    code,
    ctrlKey,
    shiftKey,
    cmdKey,
}: TDispatchKeyParams): TAnyAction | undefined {
    if ((ctrlKey || cmdKey) && !shiftKey) {
        return ctrlCharToAction[key];
    }

    if ((ctrlKey || cmdKey) && shiftKey) {
        return ctrlShiftCharToAction[key.toLowerCase()];
    }

    return mapKeyCodeToAction[code] ?? createActionFromChar(key);
}
