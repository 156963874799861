import cn from "classnames";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

import { Figure2DEditor } from "@viuch/instrument-figure2d";
import { CheckboxInput } from "@viuch/ui-common";
import { InstrumentFrame } from "@viuch/ui-kit/frames";

import type { Figure2DEditor as Figure2DEditorModel } from "../../../editors/instruments/Figure2DEditor";
import type { KeyboardService } from "@viuch/math-editor";

import styles from "./Figure2DInstrumentEditor.module.scss";

type Props = {
    editor: Figure2DEditorModel;
    keyboardService: KeyboardService;
    className?: string;
};

export const Figure2DInstrumentEditor = observer(function Figure2DInstrumentEditor({
    className,
    keyboardService,
    editor,
}: Props) {
    const { figureController: controller, interactiveView, setInteractiveView } = editor;
    const [large, setLarge] = useState(true);

    return (
        <div className={cn(styles.root, className)}>
            <InstrumentFrame className={cn(styles.frame, large && styles.large)}>
                <Figure2DEditor
                    controller={controller}
                    keyboardService={keyboardService}
                />
            </InstrumentFrame>
            <CheckboxInput
                type="checkbox"
                checked={large}
                onChange={setLarge}
                labelAfter="Увеличить"
                noSpace
            />
            <CheckboxInput
                type="checkbox"
                checked={interactiveView}
                onChange={setInteractiveView}
                labelAfter="Зум и перемещение"
                noSpace
            />
        </div>
    );
});
