import cn from "classnames";
import React from "react";

import styles from "./ZoomButtons.module.scss";

type Props = {
    onPlus(): void;
    onMinus(): void;
    extra?: TZoomButtonsExtraButton[];
};

export const ZoomButtons = ({ onPlus, onMinus, extra }: Props) => (
    <div className={styles.buttons}>
        <>
            {extra
                ?.filter((b) => b.position === "before")
                .map(({ iconUrl, iconClassName, onClick }, i) => (
                    <button
                        key={i}
                        className={styles.button}
                        onClick={() => onClick()}
                    >
                        <div
                            className={cn(styles.button__icon, iconClassName)}
                            style={{ maskImage: `url(${iconUrl})`, WebkitMaskImage: `url(${iconUrl})` }}
                        />
                    </button>
                ))}
        </>
        <button
            className={styles.button}
            onClick={() => onPlus()}
        >
            <div className={cn(styles.button__icon, styles._plus)} />
        </button>
        <button
            className={styles.button}
            onClick={() => onMinus()}
        >
            <div className={cn(styles.button__icon, styles._minus)} />
        </button>
        <>
            {extra
                ?.filter((b) => b.position === "after")
                .map(({ iconUrl, iconClassName, onClick }, i) => (
                    <button
                        key={i}
                        className={styles.button}
                        onClick={() => onClick()}
                    >
                        <div
                            className={cn(styles.button__icon, iconClassName)}
                            style={{ maskImage: `url(${iconUrl})`, WebkitMaskImage: `url(${iconUrl})` }}
                        />
                    </button>
                ))}
        </>
    </div>
);

export type TZoomButtonsExtraButton = {
    position: "before" | "after";
    iconUrl: string;
    iconClassName?: string;
    onClick(): void;
};
