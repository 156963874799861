import React from "react";

import type { ModuleModel } from "./ModuleModel";
import type { TElementProps } from "../../types/element";

import { ContainerElement } from "../../core/container";
import { Baseline } from "../../core/element";
import { useMathEditorElementRef } from "../../hooks";

import styles from "./ModuleElement.module.scss";

export const ModuleElement: React.VFC<TElementProps<ModuleModel>> = ({ elementModel }) => {
    const ref = useMathEditorElementRef(elementModel);
    const contentRef = React.useRef<HTMLSpanElement>(null);
    const containerRef = React.useRef<HTMLSpanElement>(null);
    const baselineRef = React.useRef<HTMLSpanElement>(null);

    React.useEffect(() => {
        const content = contentRef.current!;
        const container = containerRef.current!;
        const baseline = baselineRef.current!;

        function update(): void {
            const containerRect = container.getBoundingClientRect();
            const baselineRect = baseline.getBoundingClientRect();

            const hTop = baselineRect.y - containerRect.y;
            const hBot = containerRect.y + containerRect.height - (baselineRect.y + baselineRect.height);

            baseline.style.marginTop = `${hTop}px`;
            baseline.style.marginBottom = `${hBot}px`;
        }

        const observer = new ResizeObserver(() => {
            update();
        });

        observer.observe(container);
        observer.observe(content);
        update();

        return () => {
            observer.disconnect();
        };
    }, []);

    return (
        <span
            ref={ref}
            className={styles.wrapper}
        >
            <Baseline className={styles.baseline} />
            <span className={styles.verticalLine} />
            <span
                ref={contentRef}
                className={styles.content}
            >
                <Baseline ref={baselineRef} />
                <span
                    ref={containerRef}
                    className={styles.container}
                >
                    <ContainerElement
                        inline
                        containerModel={elementModel.content}
                    />
                </span>
            </span>
            <span className={styles.verticalLine} />
        </span>
    );
};
