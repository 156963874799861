import type { TAnyAction } from "../types";

export type TFractionAction = {
    type: "fraction";
};

export function createFractionAction(): TFractionAction {
    return { type: "fraction" };
}

export function isFractionAction(action: TAnyAction): action is TFractionAction {
    return action.type === "fraction";
}
