import type { BaseElement } from "../../elements";
import type { Figure2DController } from "../../Figure2DController";
import type { ToolbarButton, ToolbarMenu } from "../../toolbar";
import type { TFragment } from "@viuch/geometry-lib/types";

import { EqualSegmentsModel } from "../../models/constraints";
import { PointModel } from "../../models/point";
import { getNewEqualSegmentsStrokesCount } from "../../utils/strokes-count";
import { BaseFlow } from "../BaseFlow";

export class EqualSegmentsSelectedFlow extends BaseFlow {
    private readonly fragments: TFragment[];

    constructor(data: Figure2DController, fragments: TFragment[]) {
        super(data);

        this.fragments = fragments;
    }

    attach(): void {
        const segments = this.fragments;
        const segmentsCount = getNewEqualSegmentsStrokesCount(this.figure);

        this.figure.insertModels(function* () {
            yield EqualSegmentsModel.create({ segments, segmentsCount, style: null });

            for (const fragment of segments) {
                yield PointModel.create({ ...fragment.a, style: null });
                yield PointModel.create({ ...fragment.b, style: null });
            }
        }, this);

        this.nextFlow();
    }

    dispose(): void {}

    override handleToolbarButtonClick(menu: ToolbarMenu, button: ToolbarButton): void;
    override handleToolbarButtonClick(): void {}

    protected renderElements(): BaseElement[] {
        return [];
    }
}
