import { createAllFeatures } from "@viuch/instrument-graph2d-plugins/shared/features";
import { s2m } from "@viuch/math-editor";
import { createSerializedChar } from "@viuch/math-editor/elements/char/utils";
import { createSerializedState } from "@viuch/math-editor/utils/serialization";

import { Graph2DInstrumentAxisSettings } from "../settings-entity/Graph2DInstrumentAxisSettings";
import { Graph2DInstrumentConfigSettings } from "../settings-entity/Graph2DInstrumentConfigSettings";
import { Graph2DInstrumentCoordinateSystemSettings } from "../settings-entity/Graph2DInstrumentCoordinateSystemSettings";
import { Graph2DInstrumentGridSettings } from "../settings-entity/Graph2DInstrumentGridSettings";
import { Graph2DInstrumentSettings } from "../settings-entity/Graph2DInstrumentSettings";
import { Graph2DInstrumentStateSettings } from "../settings-entity/Graph2DInstrumentStateSettings";
import { Graph2DInstrumentViewportSettings } from "../settings-entity/Graph2DInstrumentViewportSettings";
import { EvalGraphSettings } from "../settings-entity/graphs/EvalGraphSettings.ts/EvalGraphSettings";

export function createDefaultGraph2D(): EvalGraphSettings {
    return new EvalGraphSettings("#ffffff", createSerializedState([createSerializedChar("x")]), "equals");
}

export function createNewGraph2DSettings(): Graph2DInstrumentSettings {
    const { add, transformations, behaviour, modifiers } = createAllFeatures();

    return new Graph2DInstrumentSettings(
        new Graph2DInstrumentViewportSettings(s2m`100`, s2m`1`, s2m`0.5`, s2m`0.5`),
        new Graph2DInstrumentGridSettings(s2m`20`, s2m`20`, s2m`10`, [s2m`2`, s2m`5`]),
        new Graph2DInstrumentCoordinateSystemSettings(
            s2m`1`,
            s2m`1`,
            s2m`0`,
            s2m`0`,
            new Graph2DInstrumentAxisSettings(s2m`x`),
            new Graph2DInstrumentAxisSettings(s2m`y`)
        ),
        new Graph2DInstrumentStateSettings([createDefaultGraph2D()]),
        new Graph2DInstrumentConfigSettings(false, false, behaviour, add, transformations, modifiers)
    );
}
