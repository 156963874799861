import cn from "classnames";
import { observer } from "mobx-react-lite";
import React from "react";

import type { TDesktopKeyboardButtonProps } from "./DesktopKeyboardButton.types";

import styles from "./DesktopKeyboardButton.module.scss";

const DesktopKeyboardButton = observer(function DesktopKeyboardButton({
    children,
    action,
    className,
    keyboardService,
    tags,
    onClick,
    onShiftDoubleClick,
}: TDesktopKeyboardButtonProps) {
    const handleClick = (e: React.MouseEvent) => {
        e.preventDefault();
        onClick(action);
    };

    return (
        <button
            className={cn(styles.button, className, {
                [styles.shift]: onShiftDoubleClick,
                [styles.blurred]: !keyboardService.checkButtonEnabled(tags),
            })}
            onPointerDown={handleClick}
            onDoubleClick={onShiftDoubleClick}
        >
            {children}
        </button>
    );
});

export default DesktopKeyboardButton;
