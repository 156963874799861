import type { ICommand } from "./ICommand";

export class CommandsStack {
    private readonly executed: ICommand[];
    private unperformed: ICommand[];

    public constructor() {
        this.executed = [];
        this.unperformed = [];
    }

    perform(command: ICommand): void {
        command.apply();
        this.executed.push(command);
        this.unperformed = [];
    }

    undo(): void {
        const command = this.executed.pop();
        if (!command) {
            return;
        }

        command.rollback();
        this.unperformed.push(command);
    }

    redo(): void {
        const command = this.unperformed.pop();
        if (!command) {
            return;
        }
        command.apply();
        this.executed.push(command);
    }
}
