import type { TMathCharsRow } from "./types";

import { createAffiliationAction } from "../../../actions/affiliation";
import { createCharAction } from "../../../actions/char";
import { createCoordinatesVectorAction } from "../../../actions/coordinates-vector";
import { createDegreeAction } from "../../../actions/degree";
import { createDerivativeAction } from "../../../actions/derivative";
import { createDownIndexAction } from "../../../actions/down-index";
import { createFractionAction } from "../../../actions/fraction";
import { createGeometryCharAction } from "../../../actions/geometry";
import { createGradeAction } from "../../../actions/grade";
import { createRootAction } from "../../../actions/root";
import { createSlashAction } from "../../../actions/slash";
import { createSymbolAction } from "../../../actions/symbol";
import { createTopAngleAction } from "../../../actions/top-angle";
import { createTrigonometricOperatorAction } from "../../../actions/trigonometric-operator";
import { __miscTag } from "../../../core/keyboard-button-tags/constants";
import { createAngleAction } from "../../../elements/angle/utils";

export const geometryButtonsRow1: TMathCharsRow = [
    {
        actionCreator: () => createSymbolAction("tilda"),
        text: "~",
        tags: [__miscTag],
    },
    {
        actionCreator: () => createGradeAction(),
        icon: "num-degree",
        tags: [__miscTag],
    },
    {
        actionCreator: () => createDerivativeAction(),
        icon: "derivative",
        tags: ["derivative"],
    },
    {
        actionCreator: () => createGeometryCharAction("parallel"),
        icon: "parallel",
        tags: ["geometry"],
    },
    {
        actionCreator: () => createGeometryCharAction("perpendicular"),
        icon: "perpendicular",
        tags: ["geometry"],
    },
    {
        actionCreator: () => createAngleAction(),
        icon: "angle",
        tags: ["geometry"],
        children: [
            {
                actionCreator: () => createTopAngleAction(),
                icon: "top-angle",
                tags: ["geometry"],
            },
            {
                actionCreator: () => createAngleAction(),
                icon: "angle",
                tags: ["geometry"],
            },
        ],
    },
    {
        actionCreator: () => createSymbolAction("delta"),
        icon: "delta",
        tags: [__miscTag],
    },
];

export const geometryButtonsRow2: TMathCharsRow = [
    {
        actionCreator: () => createTrigonometricOperatorAction("sin"),
        icon: "sin",
        tags: ["trigonometric", "sin"],
        children: [
            {
                actionCreator: () => createTrigonometricOperatorAction("sin"),
                icon: "sin",
                tags: ["trigonometric", "sin"],
            },
            {
                actionCreator: () => createTrigonometricOperatorAction("arcsin"),
                icon: "arcsin",
                tags: ["trigonometric", "arcsin"],
            },
        ],
    },
    {
        actionCreator: () => createTrigonometricOperatorAction("cos"),
        icon: "cos",
        tags: ["trigonometric", "cos"],
        children: [
            {
                actionCreator: () => createTrigonometricOperatorAction("cos"),
                icon: "cos",
                tags: ["trigonometric", "cos"],
            },
            {
                actionCreator: () => createTrigonometricOperatorAction("arccos"),
                icon: "arccos",
                tags: ["trigonometric", "arccos"],
            },
        ],
    },
    {
        actionCreator: () => createFractionAction(),
        icon: "frac",
        tags: [__miscTag],
        children: [
            {
                actionCreator: () => createFractionAction(),
                icon: "frac",
                tags: [__miscTag],
            },
            {
                actionCreator: () => createSlashAction(),
                icon: "divide-nums",
                tags: [__miscTag],
            },
        ],
    },
    {
        actionCreator: () => createRootAction({ squared: true }),
        icon: "sqrt",
        tags: ["power"],
        children: [
            {
                actionCreator: () => createRootAction(),
                icon: "sqrt-index",
                tags: ["power"],
            },
            {
                actionCreator: () => createRootAction({ squared: true }),
                icon: "sqrt",
                tags: ["power"],
            },
            {
                actionCreator: () => createRootAction({ index: 3 }),
                icon: "sqrt3",
                tags: ["power"],
            },
        ],
    },
    {
        actionCreator: () => createDegreeAction(2),
        icon: "degree2",
        tags: ["power"],
        children: [
            {
                actionCreator: () => createDegreeAction(),
                icon: "degree",
                tags: ["power"],
            },
            {
                actionCreator: () => createDegreeAction(2),
                icon: "degree2",
                tags: ["power"],
            },
            {
                actionCreator: () => createDegreeAction(3),
                icon: "degree3",
                tags: ["power"],
            },
        ],
    },
    {
        actionCreator: () => createCoordinatesVectorAction(),
        icon: "vector-coordinates",
        tags: [__miscTag],
    },
    {
        actionCreator: () => createAffiliationAction("affiliation"),
        icon: "belong",
        tags: [__miscTag],
        children: [
            {
                actionCreator: () => createAffiliationAction("not-affiliation"),
                icon: "notBelong",
                tags: [__miscTag],
            },
            {
                actionCreator: () => createAffiliationAction("affiliation"),
                icon: "belong",
                tags: [__miscTag],
            },
        ],
    },
];

export const geometryButtonsRow3: TMathCharsRow = [
    {
        actionCreator: () => createTrigonometricOperatorAction("tg"),
        icon: "tg",
        tags: ["trigonometric", "tg"],
        children: [
            {
                actionCreator: () => createTrigonometricOperatorAction("tg"),
                icon: "tg",
                tags: ["trigonometric", "tg"],
            },
            {
                actionCreator: () => createTrigonometricOperatorAction("arctg"),
                icon: "arctg",
                tags: ["trigonometric", "arctg"],
            },
        ],
    },
    {
        actionCreator: () => createTrigonometricOperatorAction("ctg"),
        icon: "ctg",
        tags: ["trigonometric", "ctg"],
        children: [
            {
                actionCreator: () => createTrigonometricOperatorAction("ctg"),
                icon: "ctg",
                tags: ["trigonometric", "ctg"],
            },
            {
                actionCreator: () => createTrigonometricOperatorAction("arcctg"),
                icon: "arcctg",
                tags: ["trigonometric", "arcctg"],
            },
        ],
    },
    {
        actionCreator: () => createDownIndexAction(),
        icon: "num-index",
        tags: [__miscTag],
    },
    {
        actionCreator: () => createCharAction("α"),
        symbols: "α",
        tags: ["alphabet", "alphabet-greek"],
        children: "αβγωτλ".split("").map((char) => ({
            actionCreator: () => createCharAction(char),
            tags: ["alphabet", "alphabet-greek"],
            symbols: char,
        })),
    },
    {
        actionCreator: () => createCharAction("A"),
        symbols: "A",
        tags: ["alphabet", "alphabet-en"],
        children: "ABCD".split("").map((char) => ({
            actionCreator: () => createCharAction(char),
            symbols: char,
            tags: ["alphabet", "alphabet-en"],
        })),
    },
    {
        actionCreator: () => createCharAction("x"),
        symbols: "x",
        tags: ["alphabet", "alphabet-en"],
        children: "xyzabc".split("").map((char) => ({
            actionCreator: () => createCharAction(char),
            symbols: char,
            tags: ["alphabet", "alphabet-en"],
        })),
    },
];
