import type { ISerializedMultitude, TMultitudeActionChar } from "./types";
import type { TSerializedElementPrototype } from "../../core/element";
import type { IElementVisitor } from "../../core/element/IElementVisitor";
import type { InputService } from "../../services";
import type { TElementDeserializerFunction } from "../../types";

import { BaseElementModel } from "../../core/element";

export class MultitudeModel extends BaseElementModel<ISerializedMultitude> {
    public readonly symbolName: TMultitudeActionChar;

    public constructor(inputService: InputService, symbolName: TMultitudeActionChar, uuid?: string) {
        super(inputService, uuid);
        this.symbolName = symbolName;
    }

    override isEquals(otherElement: BaseElementModel): boolean {
        return otherElement instanceof MultitudeModel && this.symbolName === otherElement.symbolName;
    }

    public serialize(): ISerializedMultitude {
        return { type: "multitude", uuid: this.uuid, symbol: this.symbolName };
    }

    public static deserialize: TElementDeserializerFunction<ISerializedMultitude> = (
        { inputService },
        { uuid, symbol }
    ) => new MultitudeModel(inputService, symbol, uuid);

    public accept<R>(visitor: IElementVisitor<R>): R {
        return visitor.doWithMultitude(this);
    }

    serializeAsClone(): TSerializedElementPrototype<ISerializedMultitude> {
        const { uuid, ...clone } = this.serialize();
        return clone;
    }
}
