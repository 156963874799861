import { ReactComponent as AlignCenterIcon } from "@viuch/assets/icons/align-center.svg";
import { ReactComponent as AlignLeftIcon } from "@viuch/assets/icons/align-left.svg";
import { ReactComponent as AlignRightIcon } from "@viuch/assets/icons/align-right.svg";

import type { TPictureWatermarkAlignment } from "@viuch/instrument-picture/statement";
import type { VFC } from "react";

export const alignments: TPictureWatermarkAlignment[] = [
    { vertical: "top", horizontal: "left" },
    { vertical: "top", horizontal: "middle" },
    { vertical: "top", horizontal: "right" },

    { vertical: "middle", horizontal: "left" },
    { vertical: "middle", horizontal: "middle" },
    { vertical: "middle", horizontal: "right" },

    { vertical: "bottom", horizontal: "left" },
    { vertical: "bottom", horizontal: "middle" },
    { vertical: "bottom", horizontal: "right" },
];

export const alignmentToIconMap: Record<TPictureWatermarkAlignment["horizontal"], VFC> = {
    left: AlignLeftIcon,
    middle: AlignCenterIcon,
    right: AlignRightIcon,
};
