import type { TMultitudeCharClassNames } from "./MultitudeElement.types";

import styles from "./MultitudeElement.module.scss";

export const multitudeCharClassNames: TMultitudeCharClassNames = {
    apert: styles.apert,
    crossing: styles.crossing,
    subset: styles.subset,
    union: styles.union,
};
