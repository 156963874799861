import { action, makeObservable, observable } from "mobx";

import { Figure2D } from "@viuch/instrument-figure2d";
import { Figure2DController } from "@viuch/instrument-figure2d/statement-editor/Figure2DController";

import type { IEditorVisitor } from "../IEditorVisitor";
import type { TFigure2DViewConfig } from "@viuch/instrument-figure2d/entities/types";
import type { IHistorySnapshot } from "@viuch/instrument-figure2d/statement-editor/history";

import { EditorTypes, BaseEditor } from "../base";

export type TFigure2DInstrumentEditorData = {
    type: EditorTypes.figure2d;
    snapshot?: IHistorySnapshot;
    settings?: Partial<TFigure2DViewConfig>;
    isStatic?: boolean | undefined;
};

export class Figure2DEditor extends BaseEditor<TFigure2DInstrumentEditorData> {
    readonly figure: Figure2D;
    readonly figureController: Figure2DController;

    constructor(data: TFigure2DInstrumentEditorData) {
        super();

        this.figure = data.snapshot
            ? Figure2D.createFromSnapshot(data.snapshot, data.settings)
            : Figure2D.createEmpty();
        this.figureController = new Figure2DController(this.figure);
        this.interactiveView = !(data?.isStatic ?? false);

        makeObservable(this);
    }

    @observable.ref interactiveView: boolean;

    @action setInteractiveView = (v: boolean) => {
        this.interactiveView = v;
    };

    accept<R>(visitor: IEditorVisitor<R>): R {
        return visitor.visitFigure2D(this);
    }

    checkIsEmpty(): boolean {
        return false;
    }

    serialize(): Promise<TFigure2DInstrumentEditorData> {
        const { scale, position } = this.figureController.viewport;
        const { interactiveView } = this;

        return Promise.resolve({
            type: EditorTypes.figure2d,
            snapshot: this.figure.makeSnapshot().serialize(),
            settings: {
                initialScale: scale,
                initialOffsetX: position.x,
                initialOffsetY: position.y,
            },
            isStatic: !interactiveView,
        });
    }
}
