import type { DegreeModel } from "./DegreeModel";
import type { BaseElementModel } from "../../core/element";
import type { BracketModel } from "../bracket/BracketModel";

import { BaseDefaultElementVisitor } from "../../core/element/BaseDefaultElementVisitor";

export class DegreePrevElementAllowFinder extends BaseDefaultElementVisitor<boolean> {
    public canElementBePrev(element: BaseElementModel): boolean {
        return element.accept(this);
    }

    protected defaultBehaviour(element: BaseElementModel): boolean {
        return true;
    }

    override doWithBracket(bracket: BracketModel): boolean {
        if (!bracket.closing) {
            return false;
        }
        return super.doWithBracket(bracket);
    }

    override doWithDegree(element: DegreeModel): boolean {
        return false;
    }
}
