import type { BaseElementModel } from "../element";

import { BaseDefaultElementVisitor } from "../element/BaseDefaultElementVisitor";

export interface ILinebreakFinder {
    isLinebreak(element: BaseElementModel): boolean;
    isSpace(element: BaseElementModel): boolean;
    isEditor(element: BaseElementModel): boolean;
    isFraction(element: BaseElementModel): boolean;
    isAnyRoot(element: BaseElementModel): boolean;
    isModule(element: BaseElementModel): boolean;
    isAnyIntegral(element: BaseElementModel): boolean;
    isDegree(element: BaseElementModel): boolean;
    isAnyLog(element: BaseElementModel): boolean;
    isDownIndex(element: BaseElementModel): boolean;
}

export type TElementTypeNames = keyof ILinebreakFinder;
export type TElementTypesMap = { [key in TElementTypeNames]?: boolean };

export class LinebreakFinder extends BaseDefaultElementVisitor<TElementTypesMap> implements ILinebreakFinder {
    private constructor() {
        super();
    }

    static Create(): ILinebreakFinder {
        return new LinebreakFinder();
    }

    protected defaultBehaviour(): TElementTypesMap {
        return {};
    }

    override doWithLinebreak(): TElementTypesMap {
        return { isLinebreak: true };
    }

    override doWithModule(): TElementTypesMap {
        return { isModule: true };
    }

    override doWithSpace(): TElementTypesMap {
        return { isSpace: true };
    }

    override doWithEditor(): TElementTypesMap {
        return { isEditor: true };
    }

    override doWithFraction(): TElementTypesMap {
        return { isFraction: true };
    }

    override doWithRoot(): TElementTypesMap {
        return { isAnyRoot: true };
    }

    override doWithIntegral(): TElementTypesMap {
        return { isAnyIntegral: true };
    }

    override doWithComplexIntegral(): TElementTypesMap {
        return { isAnyIntegral: true };
    }

    override doWithDegree(): TElementTypesMap {
        return { isDegree: true };
    }

    override doWithLog(): TElementTypesMap {
        return { isAnyLog: true };
    }

    override doWithNaturalLog(): TElementTypesMap {
        return { isAnyLog: true };
    }

    override doWithDownIndex(): TElementTypesMap {
        return { isDownIndex: true };
    }

    public isLinebreak(element: BaseElementModel): boolean {
        return Boolean(element.accept(this).isLinebreak);
    }

    public isSpace(element: BaseElementModel): boolean {
        return Boolean(element.accept(this).isSpace);
    }

    public isEditor(element: BaseElementModel): boolean {
        return Boolean(element.accept(this).isEditor);
    }

    public isFraction(element: BaseElementModel): boolean {
        return Boolean(element.accept(this).isFraction);
    }

    public isAnyRoot(element: BaseElementModel): boolean {
        return Boolean(element.accept(this).isAnyRoot);
    }

    public isModule(element: BaseElementModel): boolean {
        return Boolean(element.accept(this).isModule);
    }

    public isAnyIntegral(element: BaseElementModel): boolean {
        return Boolean(element.accept(this).isAnyIntegral);
    }

    public isDegree(element: BaseElementModel): boolean {
        return Boolean(element.accept(this).isDegree);
    }

    public isAnyLog(element: BaseElementModel): boolean {
        return Boolean(element.accept(this).isAnyLog);
    }

    public isDownIndex(element: BaseElementModel): boolean {
        return Boolean(element.accept(this).isDownIndex);
    }
}
