import type { IFormNodeVisitor } from "./BaseFormNode";
import type { ICondition } from "../conditions/ICondition";
import type { TVirtualTreeNode } from "../tree/types";

import { BaseFormNode } from "./BaseFormNode";

export class LabelFormNode extends BaseFormNode {
    readonly text: string;
    readonly variant: TTextStyleVariant;

    private readonly condition: ICondition;

    constructor(
        text: string,
        variant: TTextStyleVariant,
        condition: ICondition,
        parent: BaseFormNode | null,
        path: string[]
    ) {
        super(parent, path);

        this.text = text;
        this.variant = variant;
        this.condition = condition;
    }

    accept = <R>(visitor: IFormNodeVisitor<R>): R => visitor.withLabel(this);

    override canPresentInTree(currentNode: TVirtualTreeNode): boolean {
        return this.condition.getResult(this, currentNode);
    }

    getChildren(): readonly BaseFormNode[] {
        return [];
    }
}

export type TTextStyleVariant = "header" | "paragraph" | "caption";
