import type { ISerializedHighPriorityOperator, THighPriorityOperatorsActionSign } from "./types";
import type { TSerializedElementPrototype } from "../../core/element";
import type { IElementVisitor } from "../../core/element/IElementVisitor";
import type { InputService } from "../../services";
import type { TElementDeserializerFunction } from "../../types";

import { BaseElementModel } from "../../core/element";

export class HighPriorityOperatorModel extends BaseElementModel<ISerializedHighPriorityOperator> {
    public readonly symbolName: THighPriorityOperatorsActionSign;

    public constructor(inputService: InputService, symbolName: THighPriorityOperatorsActionSign, uuid?: string) {
        super(inputService, uuid);

        this.symbolName = symbolName;
    }

    override isEquals(otherElement: BaseElementModel): boolean {
        return otherElement instanceof HighPriorityOperatorModel && this.symbolName === otherElement.symbolName;
    }

    public serialize(): ISerializedHighPriorityOperator {
        return {
            type: "operator-high",
            uuid: this.uuid,
            symbol: this.symbolName,
        };
    }

    public static deserialize: TElementDeserializerFunction<ISerializedHighPriorityOperator> = (
        { inputService },
        { uuid, symbol }
    ) => new HighPriorityOperatorModel(inputService, symbol, uuid);

    public accept<R>(visitor: IElementVisitor<R>): R {
        return visitor.doWithOperatorHigh(this);
    }

    serializeAsClone(): TSerializedElementPrototype<ISerializedHighPriorityOperator> {
        const { uuid, ...clone } = this.serialize();
        return clone;
    }
}
