import * as mobx from "mobx";

import { assert } from "@viuch/utils/debug";

import type { ILinebreakFinder } from "./LinebreakFinder";
import type { InputService } from "../../services";
import type { ContainerModel } from "../container";
import type { BaseElementModel } from "../element";

import { computeRectCenter, getRectFromDomRect } from "../../utils/positions";

import { LinebreakFinder } from "./LinebreakFinder";

export class CursorState {
    public readonly inputService: InputService;
    private domElement?: HTMLElement;
    private linebreakFinder: ILinebreakFinder;

    public container: ContainerModel;
    public index: number;

    constructor(container: ContainerModel) {
        this.inputService = container.inputService;
        this.container = container;
        this.index = 0;
        this.linebreakFinder = LinebreakFinder.Create();

        mobx.makeObservable(this, {
            container: mobx.observable,
            index: mobx.observable,
            moveToStart: mobx.action,
            moveToEnd: mobx.action,
            moveToPosition: mobx.action,
            setAfterElement: mobx.action,
            setBeforeElement: mobx.action,
            moveLeft: mobx.action,
            moveRight: mobx.action,
            moveUp: mobx.action,
            moveDown: mobx.action,
            reset: mobx.action,
            isShown: mobx.computed,
        });
    }

    setDomElement(domElement: HTMLElement) {
        this.domElement = domElement;
    }

    get isShown(): boolean {
        const isReadOnly = this.inputService.isReadOnly;
        const isEditorFocused = this.inputService.model.isFocused;
        const isSelectedSomething = this.inputService.selectionController.isSelectedSomething;

        return !isSelectedSomething && !isReadOnly && isEditorFocused;
    }

    get isInStart(): boolean {
        return this.index === 0;
    }

    get isInEnd(): boolean {
        return this.index >= this.container.getElementsCount();
    }

    getElementBefore(): BaseElementModel | null {
        if (this.isInStart) {
            return null;
        }
        return this.container.getElementByIndex(this.index - 1);
    }

    getElementAfter(): BaseElementModel | null {
        if (this.isInEnd) {
            return null;
        }
        return this.container.getElementByIndex(this.index);
    }

    moveToStart(container: ContainerModel): void {
        this.container = container;
        this.index = 0;
        this.checkPosition();
    }

    reset(container: ContainerModel | null): void {
        const _container = container ?? this.inputService.model.rootContainer;

        this.container = _container;
        this.index = _container.getElementsCount();
    }

    moveToEnd(container: ContainerModel): void {
        this.container = container;
        this.index = container.getElementsCount();
        this.checkPosition();
    }

    moveToPosition(container: ContainerModel, position: number): void {
        this.container = container;
        this.index = position;
        this.checkPosition();
    }

    setAfterElement(element: BaseElementModel): void {
        this.container = element.parentContainer;
        this.index = element.computeIndex() + 1;
        this.checkPosition();
    }

    setBeforeElement(element: BaseElementModel): void {
        this.container = element.parentContainer;
        this.index = element.computeIndex();
        this.checkPosition();
    }

    moveLeft(length?: number): void {
        this.index -= length ?? 1;
        this.checkPosition();
    }

    moveRight(length?: number): void {
        this.index += length ?? 1;
        this.checkPosition();
    }

    moveUp(): void {
        this.moveVertical(true);
        this.checkPosition();
    }

    moveDown(): void {
        this.moveVertical(false);
        this.checkPosition();
    }

    moveVertical(up: boolean): void {
        let startIndex = this.index;
        const length = this.container.getElementsCount();

        if (up) {
            startIndex--;
        }

        while (up ? startIndex >= 0 : startIndex < length) {
            const element = this.container.getElementByIndex(startIndex);
            this.linebreakFinder.isLinebreak(element);
            if (this.linebreakFinder.isLinebreak(element)) {
                break;
            }
            up ? startIndex-- : startIndex++;
        }

        if (up ? startIndex === -1 : startIndex === length) {
            this.index = up ? 0 : length;
            return;
        }

        const cursorCenter = computeRectCenter(getRectFromDomRect(this.domElement!.getBoundingClientRect()));

        let minDistance = 1e9;
        let isRight = false;
        let minIndex = up ? startIndex - 1 : startIndex + 1;

        for (let i = up ? startIndex - 1 : startIndex + 1; up ? i >= 0 : i < length; up ? i-- : i++) {
            const element = this.container.getElementByIndex(i);
            if (this.linebreakFinder.isLinebreak(element)) {
                break;
            }
            const rect = element.getRect();
            if (rect) {
                const center = computeRectCenter(rect);
                const distance = Math.abs(center.x - cursorCenter.x);
                if (minDistance > distance) {
                    minDistance = distance;
                    isRight = cursorCenter.x > center.x;
                    minIndex = i;
                }
            }
        }

        if (minIndex < 0) {
            minIndex = 0;
        } else if (minIndex > length) {
            minIndex = length;
        }

        this.index = minIndex + (isRight ? 1 : 0);
    }

    private checkPosition(): void {
        const isOutOfBounds = this.index < 0 || this.index > this.container.getElementsCount();
        assert(!isOutOfBounds, {
            index: this.index,
            container: this.container,
            count: this.container.getElementsCount(),
        });
        this.inputService.focus();
    }
}
