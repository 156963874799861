import { compareNumbers } from "@viuch/shared/utils/data";

import type { TRawStepDescription, TStepDescriptionsGroup, TRawStepDescriptionTable } from "./types";
import type { BaseTemplate } from "../../entities/solver-step-templates";
import type { StepDescriptionTable } from "../../entities/solver-step-templates/StepDescriptionTable";

import { agent } from "../../agent";

import { mapSolverStepTemplate, mapStepDescriptionTable, serializeStepDescription } from "./utils";

export class StepDescriptionsService {
    async rawLoadDescriptionByName(name: string): Promise<TRawStepDescription[]> {
        const params = { name };
        const { data } = await agent.get<TRawStepDescription[]>("/step_descriptions/", { params });

        return data;
    }

    async loadAllDescriptions(tableId: number | string | null): Promise<BaseTemplate[]> {
        const params = { table: tableId ?? void 0 };
        const { data } = await agent.get<TRawStepDescription[]>("/step_descriptions/", { params });

        return data.sort((a, b) => compareNumbers(a.id, b.id)).map(mapSolverStepTemplate);
    }

    async createStepDescription(template: BaseTemplate, tableId: number | string | null): Promise<BaseTemplate> {
        const request = serializeStepDescription(template);
        return mapSolverStepTemplate(await this.createStepDescriptionRaw(request, tableId));
    }

    async createStepDescriptionRaw(
        request: TRawStepDescription,
        tableId: number | string | null
    ): Promise<TRawStepDescription> {
        const params = { table: tableId ?? void 0 };
        const { data } = await agent.post<TRawStepDescription>("/step_descriptions/", request, { params });

        return data;
    }

    async updateStepDescription(template: BaseTemplate, tableId: number | string | null): Promise<BaseTemplate> {
        const request = serializeStepDescription(template);

        return mapSolverStepTemplate(await this.updateStepDescriptionRaw(request, tableId));
    }

    async updateStepDescriptionRaw(
        request: TRawStepDescription,
        tableId: number | string | null
    ): Promise<TRawStepDescription> {
        const params = { table: tableId ?? void 0 };
        const { data } = await agent.patch<TRawStepDescription>(`/step_descriptions/${request.id}`, request, {
            params,
        });

        return data;
    }

    async removeStepDescription(template: BaseTemplate, tableId: number | string | null): Promise<void> {
        const params = { table: tableId ?? void 0 };
        await agent.delete(`/step_descriptions/${template.id}`, { params });
    }

    async getListGroups(): Promise<TStepDescriptionsGroup[]> {
        const { data } = await agent.get<TStepDescriptionsGroup[]>("/step_descriptions/list_groups/");

        return data;
    }

    async getTables(): Promise<StepDescriptionTable[]> {
        const { data } = await agent.get<TRawStepDescriptionTable[]>(`/step_description_tables/`);

        return data.map(mapStepDescriptionTable);
    }

    async copyTable(newTableName: string, tableId: number | string | null): Promise<StepDescriptionTable> {
        const request = { name: newTableName };

        if (tableId === null) {
            const { data } = await agent.post<TRawStepDescriptionTable>(
                `/step_description_tables/copy_default/`,
                request
            );

            return mapStepDescriptionTable(data);
        }

        const { data } = await agent.post<TRawStepDescriptionTable>(
            `/step_description_tables/${tableId}/copy/`,
            request
        );

        return mapStepDescriptionTable(data);
    }

    async deleteTable(tableId: number | string): Promise<void> {
        await agent.delete(`/step_description_tables/${tableId}/`);
    }
}
