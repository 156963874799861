import { action, makeObservable, observable } from "mobx";

import type { TSerializedState } from "@viuch/math-editor";

import {
    BaseGraph2DInstrumentSettingsGraph,
    type IGraph2DInstrumentSettingsGraphVisitor,
} from "./BaseGraph2DInstrumentSettingsGraph";

export class PointGraphSettings extends BaseGraph2DInstrumentSettingsGraph {
    @observable.ref x: TSerializedState;
    @observable.ref y: TSerializedState;
    @observable.ref isIncluded: boolean;
    @observable.ref label: TSerializedState | null;
    @observable.ref xLabel: TSerializedState | null;
    @observable.ref yLabel: TSerializedState | null;

    constructor(
        x: TSerializedState,
        y: TSerializedState,
        is_included: boolean,
        color: string,
        label: TSerializedState | null,
        x_label: TSerializedState | null,
        y_label: TSerializedState | null
    ) {
        super(color);

        this.x = x;
        this.y = y;
        this.isIncluded = is_included;
        this.color = color;
        this.label = label;
        this.xLabel = x_label;
        this.yLabel = y_label;

        makeObservable(this);
    }

    @action.bound
    setX(x: TSerializedState) {
        this.x = x;
    }

    @action.bound
    setY(y: TSerializedState) {
        this.y = y;
    }

    @action.bound
    setIsIncluded(isIncluded: boolean) {
        this.isIncluded = isIncluded;
    }

    @action.bound
    setLabel(label: TSerializedState | null) {
        this.label = label;
    }

    @action.bound
    setXLabel(xLabel: TSerializedState | null) {
        this.xLabel = xLabel;
    }

    @action.bound
    setYLabel(yLabel: TSerializedState | null) {
        this.yLabel = yLabel;
    }

    override clone(): BaseGraph2DInstrumentSettingsGraph {
        const { x, y, isIncluded, color, label, xLabel, yLabel } = this;
        return new PointGraphSettings(x, y, isIncluded, color, label, xLabel, yLabel);
    }

    override accept = <T, A extends any[]>(visitor: IGraph2DInstrumentSettingsGraphVisitor<T, A>, ...args: A): T =>
        visitor.withPoint(this, ...args);
}
