import { v4 } from "uuid";

export function generateUuid(): string {
    return v4();
}

export function getRandomSource(): Crypto {
    return window.crypto;
}

function getRandomInt(): number {
    return Math.floor(Math.random() * Number.MAX_SAFE_INTEGER);
}

export function generateId(): number {
    if (typeof window === "undefined") {
        return getRandomInt();
    }

    const array = new Uint32Array(1);
    getRandomSource().getRandomValues(array);

    return array[0];
}
