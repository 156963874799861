import type { TMathCharsRow } from "./types";

import { createAffiliationAction } from "../../../actions/affiliation";
import { createCharAction } from "../../../actions/char";
import { createComplexIntegralAction } from "../../../actions/complex-integral";
import { createDifferentialAction } from "../../../actions/differential";
import { createEquationsSetAction } from "../../../actions/equations-set";
import { createFunctionAction } from "../../../actions/function";
import { createGradeAction } from "../../../actions/grade";
import { createIntegralAction } from "../../../actions/integral";
import { createLimAction } from "../../../actions/lim";
import { createLogAction } from "../../../actions/log";
import { createMultitudeAction } from "../../../actions/multitude";
import { createNaturalLogAction } from "../../../actions/natural-log";
import { createSlashAction } from "../../../actions/slash";
import { createSymbolAction } from "../../../actions/symbol";
import { createTrigonometricOperatorAction } from "../../../actions/trigonometric-operator";
import { createVectorAction } from "../../../actions/vector";
import { __miscTag } from "../../../core/keyboard-button-tags/constants";

export const algebraButtonsRow1: TMathCharsRow = [
    {
        actionCreator: () => createEquationsSetAction("and"),
        icon: "input-curly-bracket",
        tags: [__miscTag],
        children: [
            {
                actionCreator: () => createEquationsSetAction("and"),
                icon: "input-curly-bracket",
                tags: [__miscTag],
            },
            {
                actionCreator: () => createEquationsSetAction("or"),
                icon: "input-round-bracket",
                tags: [__miscTag],
            },
        ],
    },
    {
        actionCreator: () => createGradeAction(),
        icon: "num-degree",
        tags: [__miscTag],
    },
    {
        actionCreator: () => createSlashAction(),
        icon: "divide-nums",
        tags: [__miscTag],
    },
    {
        actionCreator: () => createDifferentialAction("x"),
        icon: "differential-x",
        tags: ["derivative"],
        children: [
            {
                actionCreator: () => createDifferentialAction("x"),
                icon: "differential-x",
                tags: ["derivative"],
            },
            {
                actionCreator: () => createDifferentialAction(),
                icon: "differential",
                tags: ["derivative"],
            },
        ],
    },
    {
        actionCreator: () => createLogAction(2),
        icon: "log2",
        tags: ["logarithms"],
        children: [
            {
                actionCreator: () => createLogAction(),
                icon: "log",
                tags: ["logarithms"],
            },
            {
                actionCreator: () => createLogAction(2),
                icon: "log2",
                tags: ["logarithms"],
            },
            {
                actionCreator: () => createNaturalLogAction(),
                icon: "ln",
                tags: ["logarithms"],
            },
        ],
    },
    {
        actionCreator: () => createSymbolAction("exhibitor"),
        icon: "exponent",
        tags: [__miscTag],
    },
    {
        actionCreator: () => createVectorAction(),
        icon: "vector",
        tags: ["geometry"],
    },
];

export const algebraButtonsRow2: TMathCharsRow = [
    {
        actionCreator: () => createTrigonometricOperatorAction("sin"),
        icon: "sin",
        tags: ["trigonometric", "sin"],
        children: [
            {
                actionCreator: () => createTrigonometricOperatorAction("sin"),
                icon: "sin",
                tags: ["trigonometric", "sin"],
            },
            {
                actionCreator: () => createTrigonometricOperatorAction("arcsin"),
                icon: "arcsin",
                tags: ["trigonometric", "arcsin"],
            },
        ],
    },
    {
        actionCreator: () => createTrigonometricOperatorAction("cos"),
        icon: "cos",
        tags: ["trigonometric", "cos"],
        children: [
            {
                actionCreator: () => createTrigonometricOperatorAction("cos"),
                icon: "cos",
                tags: ["trigonometric", "cos"],
            },
            {
                actionCreator: () => createTrigonometricOperatorAction("arccos"),
                icon: "arccos",
                tags: ["trigonometric", "arccos"],
            },
        ],
    },
    {
        actionCreator: () => createSymbolAction("infinity"),
        icon: "infinity",
        tags: [__miscTag],
        children: [
            {
                actionCreator: () => createSymbolAction("infinity"),
                icon: "infinity",
                tags: [__miscTag],
            },
            {
                actionCreator: () => createSymbolAction("empty-set"),
                icon: "empty",
                tags: [__miscTag],
            },
        ],
    },
    {
        actionCreator: () => createFunctionAction({ filled: true }),
        icon: "funcX",
        tags: ["functions"],
        children: [
            {
                actionCreator: () => createFunctionAction({ filled: true }),
                icon: "funcX",
                tags: ["functions"],
            },
            {
                actionCreator: () => createFunctionAction(),
                icon: "func",
                tags: ["functions"],
            },
        ],
    },
    {
        actionCreator: () => createLimAction(),
        icon: "lim",
        tags: ["limits"],
    },
    {
        actionCreator: () => createIntegralAction("x"),
        icon: "integral-x",
        tags: ["integrals"],
        children: [
            {
                actionCreator: () => createIntegralAction("x"),
                icon: "integral-x",
                tags: ["integrals"],
            },
            {
                actionCreator: () => createIntegralAction(),
                icon: "integral",
                tags: ["integrals"],
            },
            {
                actionCreator: () => createComplexIntegralAction(),
                icon: "complex-integral",
                tags: ["integrals"],
            },
        ],
    },
    {
        actionCreator: () => createAffiliationAction("affiliation"),
        icon: "belong",
        tags: [__miscTag],
        children: [
            {
                actionCreator: () => createAffiliationAction("not-affiliation"),
                icon: "notBelong",
                tags: [__miscTag],
            },
            {
                actionCreator: () => createAffiliationAction("affiliation"),
                icon: "belong",
                tags: [__miscTag],
            },
        ],
    },
];

export const algebraButtonsRow3: TMathCharsRow = [
    {
        actionCreator: () => createTrigonometricOperatorAction("tg"),
        icon: "tg",
        tags: ["trigonometric", "tg"],
        children: [
            {
                actionCreator: () => createTrigonometricOperatorAction("tg"),
                icon: "tg",
                tags: ["trigonometric", "tg"],
            },
            {
                actionCreator: () => createTrigonometricOperatorAction("arctg"),
                icon: "arctg",
                tags: ["trigonometric", "arctg"],
            },
        ],
    },
    {
        actionCreator: () => createTrigonometricOperatorAction("ctg"),
        icon: "ctg",
        tags: ["trigonometric", "ctg"],
        children: [
            {
                actionCreator: () => createTrigonometricOperatorAction("ctg"),
                icon: "ctg",
                tags: ["trigonometric", "ctg"],
            },
            {
                actionCreator: () => createTrigonometricOperatorAction("arcctg"),
                icon: "arcctg",
                tags: ["trigonometric", "arcctg"],
            },
        ],
    },
    {
        actionCreator: () => createSymbolAction("empty-set"),
        icon: "empty",
        tags: [__miscTag],
    },
    {
        actionCreator: () => createMultitudeAction("crossing"),
        icon: "intersection",
        tags: ["sets"],
        children: [
            {
                actionCreator: () => createMultitudeAction("crossing"),
                icon: "intersection",
                tags: ["sets"],
            },
            {
                actionCreator: () => createMultitudeAction("union"),
                icon: "union-set",
                tags: ["sets"],
            },
        ],
    },
    {
        actionCreator: () => createSymbolAction("pi"),
        icon: "pi",
        tags: [__miscTag],
    },
    {
        actionCreator: () => createCharAction("x"),
        symbols: "x",
        tags: ["alphabet", "alphabet-en"],
        children: "xyzabc".split("").map((char) => ({
            actionCreator: () => createCharAction(char),
            symbols: char,
            tags: ["alphabet", "alphabet-en"],
        })),
    },
];
