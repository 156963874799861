import { generateUuid } from "@viuch/shared/utils/data";

import type { EditorTypes } from "./EditorTypes";
import type { IEditorVisitor } from "../IEditorVisitor";

export type TBaseEditorData = {
    type: EditorTypes;
};

export abstract class BaseEditor<out D extends TBaseEditorData> implements IBaseEditor {
    readonly $$instance_id = generateUuid();
    readonly uuid: string;

    protected constructor() {
        this.uuid = generateUuid();
    }

    public abstract serialize(): Promise<D>;

    public abstract checkIsEmpty(): boolean;

    public abstract accept<R>(visitor: IEditorVisitor<R>): R;
}

export interface IBaseEditor {
    readonly uuid: string;

    accept<R>(visitor: IEditorVisitor<R>): R;
    serialize(): Promise<TBaseEditorData>;
    checkIsEmpty(): boolean;
}
