import cn from "classnames";
import { observer } from "mobx-react-lite";
import React from "react";

import type { PropsWithChildren } from "react";

import styles from "./ListFrame.module.scss";

type Props = PropsWithChildren<{
    className?: string;
    classes?: {
        wrapper?: string;
        frameOuter?: string;
        frameInner?: string;
    };
    everyClassName?: string;
    disableShadows?: boolean;
    variant?: number;
}>;

export const ListFrame = observer(function ListFrame(props: Props) {
    const { className, children, classes, everyClassName, disableShadows, variant } = props;
    return (
        <div
            className={cn(
                className,
                styles.wrapper,
                everyClassName,
                classes?.wrapper,
                disableShadows && styles.disableShadows,
                variant && styles[`variant-${variant}`]
            )}
        >
            <div className={cn(styles.frameOuter, everyClassName, classes?.frameOuter)}>
                <div className={cn(styles.frameInner, everyClassName, classes?.frameInner)}>{children}</div>
            </div>
        </div>
    );
});
