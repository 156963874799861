import type { TVirtualTreeNode } from "../tree/types";

import { BaseValueFormNode } from "../nodes/BaseValueFormNode";
import { ObjectBuilder } from "../utils/ObjectBuilder";

export function serializeVirtualTree(node: TVirtualTreeNode): object {
    const builder = new ObjectBuilder({});

    handleTreeNode(node, builder, []);

    return builder.build() ?? {};
}

function handleTreeNode(node: TVirtualTreeNode, builder: ObjectBuilder, path: (string | number)[]): void {
    const controlWithValue = tryGetValueFromNodeControls(node);

    if (controlWithValue) {
        builder.addValueOnPath(path, controlWithValue.getValue());
    }

    node.children.forEach((childNode, pathComponent) => {
        handleTreeNode(childNode, builder, [...path, pathComponent]);
    });
}

export function tryGetValueFromNodeControls(node: TVirtualTreeNode): BaseValueFormNode | null {
    for (const control of node.controls) {
        if (control instanceof BaseValueFormNode) {
            if (!control.isVirtual()) {
                return control;
            }
        }
    }
    return null;
}

export function setValuesInVirtualTree(fullTree: TVirtualTreeNode, data: object) {
    return setValuesInVirtualTreeNode(fullTree, data);
}

function setValuesInVirtualTreeNode(node: TVirtualTreeNode, value: unknown): void {
    for (const control of node.controls) {
        if (control instanceof BaseValueFormNode) {
            control.trySetValue(value);
        }
    }

    node.children.forEach((childNode, pathKey) => {
        const childValue = followPath([pathKey], value);

        setValuesInVirtualTreeNode(childNode, childValue);
    });
}

// TODO: унести в shared утилиты
export function followPath(path: (string | number)[], value: any): unknown {
    let current = value;

    for (const key of path) {
        current = current?.[key];
    }

    return current;
}
