export enum EditorTypes {
    math = "math-editor",
    format = "format-editor",
    picture = "picture-instrument",
    intervals = "intervals-instrument",
    table = "table-instrument",
    tableSelect = "table-select-instrument",
    graph1d = "graph1d-instrument",
    figure2d = "figure2d-instrument",
    graph2d = "graph2d-instrument",
}
