export enum AdminStatsItemKindEnum {
    created = "created",
    updated = "updated",
    cancelled = "cancelled",
    viewed = "viewed",
    unknown = "unknown",
}

export type TRawContentAdminStatsItem = {
    id: number;
    user: {
        content_admin_id: number;
        username: string;
        first_name: string;
        last_name: string;
        is_active: boolean;
    };
    theme: {
        id: number;
        name: string;
    } | null;
    created_at: string;
    finished_at: string;
    kind: "create" | "update";
};

export type TContentAdminStatsItem = {
    id: number;
    kind: AdminStatsItemKindEnum;
    createdAt: Date | null;
    finishedAt: Date | null;
    theme: {
        id: number;
        name: string;
    } | null;
};

export type TRawContentAdminStatsByThemesItem = {
    theme: null | {
        id: number;
        name: string;
    };
    total_time: string;
    records: TRawContentAdminStatsByThemesRecord[];
    tasks: {
        created_tasks: number;
        updated_tasks: number;
    };
};

export type TContentAdminStatsByThemesItem = {
    theme: null | {
        id: number;
        name: string;
    };
    totalTime: number;
    records: TContentAdminStatsByThemesRecord[];
    tasks: {
        created_tasks: number;
        updated_tasks: number;
    };
};

export type TRawContentAdminStatsByThemesRecord = {
    id: number;
    created_at: string;
    finished_at: string;
    kind: string;
    is_completed: boolean;
    theme_id: number;
};

export type TContentAdminStatsByThemesRecord = {
    id: number;
    createdAt: Date | null;
    finishedAt: Date | null;
    kind: AdminStatsItemKindEnum;
    isCompleted: boolean;
};

// Stats by week

export type TRawStatsAdminHistoryTimeByWeekRecord = {
    week_start: string; // datetime
    total_time: string; // duration
};

export type TRawStatsAdminHistoryTimeByTheme = {
    average_time: string; // duration
    results: TRawStatsAdminHistoryTimeByThemeItemResult[];
};

export type TRawStatsAdminHistoryTimeByThemeItemResult = {
    theme: {
        id: number;
        name: string;
        slug: string;
    };
    total_time: string; //duration
};

// Stats by month

export type TRawStatsAdminHistoryTimeByMonthRecord = {
    start: string; // datetime
    total_time: string; // duration
};
