import { action, computed, makeObservable, observable } from "mobx";

import { generateUuid } from "@viuch/shared/utils/data";
import { addAtPosition } from "@viuch/shared/utils/data/addAtPosition";

import type { Graph2DInstrumentConfigSettings } from "./Graph2DInstrumentConfigSettings";
import type { Graph2DInstrumentCoordinateSystemSettings } from "./Graph2DInstrumentCoordinateSystemSettings";
import type { Graph2DInstrumentGridSettings } from "./Graph2DInstrumentGridSettings";
import type { Graph2DInstrumentViewportSettings } from "./Graph2DInstrumentViewportSettings";

import { Graph2DInstrumentStateSettings } from "./Graph2DInstrumentStateSettings";

export class Graph2DInstrumentSettings {
    readonly $uuid = generateUuid();

    readonly viewport: Graph2DInstrumentViewportSettings;
    readonly grid: Graph2DInstrumentGridSettings;
    readonly config: Graph2DInstrumentConfigSettings;
    readonly coordinateSystem: Graph2DInstrumentCoordinateSystemSettings;
    readonly state: Graph2DInstrumentStateSettings;

    @observable.ref completions: Graph2DInstrumentStateSettings[];
    @observable selectedLayerId: string;

    constructor(
        viewport: Graph2DInstrumentViewportSettings,
        grid: Graph2DInstrumentGridSettings,
        coordinateSystem: Graph2DInstrumentCoordinateSystemSettings,
        graphs: Graph2DInstrumentStateSettings,
        config: Graph2DInstrumentConfigSettings,
        completions: Graph2DInstrumentStateSettings[] = []
    ) {
        this.config = config;
        this.viewport = viewport;
        this.grid = grid;
        this.coordinateSystem = coordinateSystem;
        this.state = graphs;
        this.completions = completions;

        this.selectedLayerId = this.state.$uuid;

        makeObservable(this);
    }

    @computed
    get selectedLayer(): Graph2DInstrumentStateSettings | null {
        if (this.selectedLayerId === this.state.$uuid) {
            return this.state;
        }

        return this.completions.find((c) => c.$uuid === this.selectedLayerId) ?? null;
    }

    get selectedCompletionIndex(): number | null {
        if (this.selectedLayerId === this.state.$uuid) {
            return null;
        }

        return this.completions.findIndex((c) => c.$uuid === this.selectedLayerId) ?? null;
    }

    @action.bound
    selectCompletion(id: string): void {
        this.selectedLayerId = id;
    }

    @action.bound
    addCompletion(afterId: string): void {
        const newCompletionSettings = new Graph2DInstrumentStateSettings([]);

        const afterIndex = this.completions.findIndex((c) => c.$uuid === afterId);

        this.completions = addAtPosition(this.completions, newCompletionSettings, afterIndex);
        this.selectedLayerId = newCompletionSettings.$uuid;
    }

    @action.bound
    deleteCompletion(id: string): void {
        if (this.state.$uuid === id) {
            return;
        }

        const deletingCompletionIndex = this.completions.findIndex((c) => c.$uuid === id);
        const leftCompletions = this.completions.slice(0, deletingCompletionIndex);

        this.completions = this.completions.toSpliced(deletingCompletionIndex, 1);

        if (this.selectedLayerId === id) {
            const nextSelectedLayer = this.completions.at(deletingCompletionIndex) ?? leftCompletions.findLast(Boolean);

            this.selectedLayerId = nextSelectedLayer?.$uuid ?? this.state.$uuid;
        }
    }

    clone() {
        const { viewport, grid, coordinateSystem, state, config, completions } = this;

        return new Graph2DInstrumentSettings(
            viewport.clone(),
            grid.clone(),
            coordinateSystem.clone(),
            state.clone(),
            config.clone(),
            completions.map((c) => c.clone())
        );
    }
}
