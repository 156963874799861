import { action, makeObservable, observable } from "mobx";
import { createTransformer } from "mobx-utils";

import { areSamePoints } from "@viuch/geometry-lib/check-geometry";
import { createFragmentsFromPoints } from "@viuch/geometry-lib/transform";

import type { BaseElement } from "../../elements";
import type { Figure2DController } from "../../Figure2DController";
import type { ToolbarMenu } from "../../toolbar";
import type { IUserPointer } from "../../viewport/types";
import type { TPoint } from "@viuch/geometry-lib/types";
import type React from "react";

import { DotElement } from "../../elements/dot";
import { ElementColor } from "../../elements/ElementColor";
import { EllipseElement } from "../../elements/ellipse";
import { LineElement } from "../../elements/line";
import { InscribedCircleModel } from "../../models/circle";
import { FragmentModel } from "../../models/fragment";
import { LabelPointModel } from "../../models/label-point";
import { ButtonStates } from "../../toolbar";
import { TooltipMenu } from "../../toolbar/tooltip";
import { createDefaultToolbarMenuBuilder } from "../../utils/toolbar";
import { BaseFlow } from "../BaseFlow";
import { createMapModelToElements, opacifyColor, z } from "../utils";

export class InscribedCircleFlow extends BaseFlow {
    flow: {
        points: TPoint[];
        current: { point?: TPoint; drawing: boolean };
    };

    constructor(data: Figure2DController, polygon?: TPoint[]) {
        super(data);

        this.flow = { points: polygon || [], current: { drawing: false } };

        makeObservable(this, {
            flow: observable,
        });
    }

    attach(): void {
        this.snap.addFigureModelsPoints();
        this.viewport.disable();

        if (this.flow.points.length > 0) {
            Promise.resolve().then(() => {
                this.finishCreating();
                this.nextFlow();
            });
        }
    }

    dispose(): void {
        this.snap.clean();
    }

    override handleViewportPointerEvent(e: React.PointerEvent, pointer: IUserPointer): void {
        if (!e.isPrimary) return;
        if (e.type === "pointercancel") return;

        const point = this.snap.snap(pointer.canvas);

        if (point) {
            this.moveCurrentPoint(e, point);
            this.startDrawing(e, point);
            this.endDrawing(e, point);
        } else {
            this.handleNoPoint(e);
        }
    }

    private handleNoPoint(e: React.PointerEvent) {
        if (e.type === "pointermove") {
            this.flow.current.point = void 0;
        }
        if (e.type === "pointerup") {
            this.nextFlow();
        }
    }

    private moveCurrentPoint(e: React.PointerEvent, point: TPoint) {
        if (e.type === "pointermove") {
            this.flow.current.point = point;
        }
    }

    private startDrawing(e: React.PointerEvent, point: TPoint) {
        if (e.type === "pointerdown") {
            this.flow.current = { drawing: true, point };
        }
    }

    private endDrawing(e: React.PointerEvent, point: TPoint) {
        if (e.type === "pointerup") {
            if (this.flow.points.find((p) => areSamePoints(p, point))) {
                const firstPoint = this.flow.points.at(0);
                if (firstPoint && areSamePoints(point, firstPoint)) {
                    this.finishCreating();
                    this.nextFlow();
                    return;
                } else {
                    this.nextFlow();
                    return;
                }
            }
            this.flow.points.push(point);
            this.flow.current = { drawing: false };
        }
    }

    private finishCreating = action(() => {
        this.finishCreatingInternal();
    });

    protected finishCreatingInternal() {
        if (this.flow.points.length >= 3) {
            this.figure.insertModels(function* () {
                const model = InscribedCircleModel.create({
                    fragmentsPoints: this.flow.points,
                    style: null,
                });
                yield model;

                for (const { a, b } of createFragmentsFromPoints(this.flow.points, true)) {
                    yield FragmentModel.create({ a, b, style: null });
                }
                for (const intersectionPoint of model.intersectionPoints) {
                    yield LabelPointModel.createNext(intersectionPoint, this.figure);
                }
            }, this);
        }
    }

    private render = createTransformer(createMapModelToElements(opacifyColor));

    protected renderElements(): BaseElement[] {
        return [...this.figure.models.flatMap(this.render), ...this.tempCurrentElements, ...this.tempReadyElements];
    }

    protected renderTempReadyElements(): BaseElement[] {
        const elements: BaseElement[] = [];
        const color = ElementColor.Building;

        for (let i = 0; i < this.flow.points.length - 1; i++) {
            const a = this.flow.points[i];
            const b = this.flow.points[i + 1];
            elements.push(
                new LineElement({
                    id: `temp_ready_line_${i}`,
                    x1: a.x,
                    y1: a.y,
                    x2: b.x,
                    y2: b.y,
                    thin: true,
                    color,
                    overrideRenderOrder: z.fragments.priority + 1,
                }),
                new DotElement({
                    id: `temp_ready_dot_${i}`,
                    x: a.x,
                    y: a.y,
                    color: i === 0 && this.flow.points.length >= 3 ? ElementColor.Selected : color,
                    overrideRenderOrder: z.points.priority + 1,
                })
            );
        }

        return elements;
    }

    protected get tempCircle() {
        const points = [...this.flow.points];
        if (points.length >= 3) {
            const exampleModel = new InscribedCircleModel({ fragmentsPoints: points, style: null }, -1);

            const { center, radius } = exampleModel;
            if (center !== null && radius !== null) {
                return new EllipseElement({
                    id: `temp_inscribed_circle`,
                    center,
                    rx: radius,
                    ry: radius,
                    color: ElementColor.Computed,
                    overrideRenderOrder: z.circles.priority,
                    thin: false,
                });
            }
        }
    }

    get tempReadyElements(): BaseElement[] {
        const els = [...this.renderTempReadyElements()];
        const circle = this.tempCircle;
        if (circle) {
            els.push(circle);
        }
        return els;
    }

    get tempCurrentElements(): BaseElement[] {
        const { drawing, point } = this.flow.current;
        if (!point) return [];

        const color = drawing ? ElementColor.Selected : ElementColor.Building;

        const lastPoint = this.flow.points.at(-1);
        if (!lastPoint) {
            return [
                new DotElement({
                    id: `temp_current_dot`,
                    x: point.x,
                    y: point.y,
                    color,
                    overrideRenderOrder: z.points.priority,
                }),
            ];
        }

        return [
            new LineElement({
                id: `temp_current_line`,
                x1: lastPoint.x,
                y1: lastPoint.y,
                x2: point.x,
                y2: point.y,
                thin: true,
                color,
                overrideRenderOrder: z.fragments.priority,
            }),
            new DotElement({
                id: `temp_current_dot`,
                x: point.x,
                y: point.y,
                color,
                overrideRenderOrder: z.points.priority,
            }),
        ];
    }

    static create(data: Figure2DController) {
        return new InscribedCircleFlow(data);
    }

    override getToolbarMenu(): ToolbarMenu {
        return createDefaultToolbarMenuBuilder()
            .setButtonState(ButtonStates.active, ["circles"])
            .setButtonIcon("circle-inscribed", ["circles"])
            .build();
    }

    override getTooltipMenu(): TooltipMenu | null {
        let text: string;
        if (this.flow.points.length >= 3) {
            text = "Выберите все вершины фигуры. Чтобы вписать окружность, замкните контур";
        } else {
            text = "Выберите все вершины фигуры";
        }

        return new TooltipMenu(text);
    }
}
