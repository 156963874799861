import type { BaseTableInstrumentSettingsCell } from "./cells";

import { SelectTableInstrumentSettingsCell, TextTableInstrumentSettingsCell } from "./cells";

export function generateSelectTableCells(cols: number, rows: number): BaseTableInstrumentSettingsCell[][] {
    const rowsArray = Array.from({ length: rows });
    const colsArray = Array.from({ length: cols });

    return rowsArray.map((_, rowIndex) =>
        colsArray.map((_, colIndex) => {
            if (rowIndex === 0 || colIndex === 0) {
                return TextTableInstrumentSettingsCell.createEmpty();
            }

            return SelectTableInstrumentSettingsCell.createEmpty();
        })
    );
}

export function generateTextTableCells(cols: number, rows: number): TextTableInstrumentSettingsCell[][] {
    const rowsArray = Array.from({ length: rows });
    const colsArray = Array.from({ length: cols });

    return rowsArray.map(() => colsArray.map(() => TextTableInstrumentSettingsCell.createEmpty()));
}
