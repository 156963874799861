import type { FunctionModel } from "./FunctionModel";
import type { TElementProps } from "../../types/element";
import type { VFC } from "react";

import { ContainerElement } from "../../core/container";
import { RoundBrackets } from "../../core/dynamic-brackets";
import { useMathEditorElementRef } from "../../hooks";

import styles from "./FunctionElement.module.scss";

export const FunctionElement: VFC<TElementProps<FunctionModel>> = ({ elementModel }) => {
    const ref = useMathEditorElementRef(elementModel);

    return (
        <span
            ref={ref}
            className={styles.wrapper}
        >
            <ContainerElement
                containerModel={elementModel.funcNameContent}
                inline
            />
            <RoundBrackets>
                <ContainerElement
                    containerModel={elementModel.funcArgContent}
                    inline
                />
            </RoundBrackets>
        </span>
    );
};
