import { generateUuid } from "@viuch/shared/utils/data";

import type { ISerializedChar } from "./types";
import type { BaseElementModel } from "../../core/element";
import type { TAnySerializedElement } from "../../types";

import { CharModel } from "./CharModel";

export function createSerializedChar(char: string): ISerializedChar {
    return { type: "char", uuid: generateUuid(), char };
}

export function isChar(element: BaseElementModel): element is CharModel {
    return element instanceof CharModel;
}

export function isSerializedChar(serializedElement: TAnySerializedElement): serializedElement is ISerializedChar {
    return serializedElement.type === "char";
}
