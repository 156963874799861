import { PictureInstrumentSettingsImpl, PictureInstrumentSettingsWatermark } from "@viuch/instrument-picture-settings";
import { createSerializedContainer } from "@viuch/math-editor";

import type { IEditorVisitor } from "../IEditorVisitor";
import type { TSerializedState } from "@viuch/math-editor";

import { BaseEditor, EditorTypes } from "../base";

export type TPictureEditorData = {
    type: EditorTypes.picture;
    settings?: TPictureSettings;
};

export type TPictureSettings = {
    picture: {
        uuid: string;
        url: string;
    } | null;
    watermarks: Array<TWatermarkData>;
    altText: string;
    title: TSerializedState;
};

export type TWatermarkData = {
    value: TSerializedState;
    alignment: {
        vertical: "top" | "middle" | "bottom";
        horizontal: "left" | "middle" | "right";
    };
    color: string;
    position: { x: number; y: number };
};

export class PictureEditor extends BaseEditor<TPictureEditorData> {
    public readonly picture: PictureInstrumentSettingsImpl;

    constructor(state?: TPictureSettings) {
        super();

        this.picture = state
            ? this.deserializePicture(state)
            : new PictureInstrumentSettingsImpl([], null, createSerializedContainer(), "");
    }

    private deserializePicture = (data: TPictureSettings): PictureInstrumentSettingsImpl =>
        new PictureInstrumentSettingsImpl(
            data.watermarks.map(this.deserializeWatermark),
            data.picture,
            data.title,
            data.altText
        );

    private deserializeWatermark = (data: TWatermarkData): PictureInstrumentSettingsWatermark =>
        new PictureInstrumentSettingsWatermark({
            templateValue: data.value,
            position: {
                left: data.position.x,
                top: data.position.y,
            },
            alignment: {
                vertical: data.alignment.vertical,
                horizontal: data.alignment.horizontal,
            },
            color: data.color,
        });

    accept<R>(visitor: IEditorVisitor<R>): R {
        return visitor.visitPicture(this);
    }

    checkIsEmpty(): boolean {
        return false;
    }

    async serialize(): Promise<TPictureEditorData> {
        return {
            type: EditorTypes.picture,
            settings: {
                picture: this.picture.image,
                watermarks: this.picture.watermarks.map((watermark) => ({
                    value: watermark.templateValue,
                    alignment: {
                        vertical: watermark.alignment.vertical,
                        horizontal: watermark.alignment.horizontal,
                    },
                    color: watermark.color,
                    position: {
                        x: watermark.position.left,
                        y: watermark.position.top,
                    },
                })),
                altText: this.picture.altText,
                title: this.picture.title,
            },
        };
    }
}
