import { action, makeObservable } from "mobx";

import { copyLine, copyPoint, lineToFragment } from "@viuch/geometry-lib/factories";

import type { BaseElement } from "../../elements";
import type { Figure2DController } from "../../Figure2DController";
import type { TLine, TPoint } from "@viuch/geometry-lib/types";

import { AltitudeModel } from "../../models/geometry";
import { LabelPointModel } from "../../models/label-point";
import { PointModel } from "../../models/point";
import { BaseFlow } from "../BaseFlow";

export class AltitudeFlow extends BaseFlow {
    private vertex: TPoint;
    private line: TLine;

    constructor(data: Figure2DController, vertex: TPoint, line: TLine) {
        super(data);
        this.vertex = copyPoint(vertex);
        this.line = copyLine(line);
        makeObservable(this, {
            createAltitude: action.bound,
        });
    }

    attach(): void {
        Promise.resolve().then(this.createAltitude);
    }

    dispose(): void {}

    createAltitude() {
        const { vertex, line } = this;

        const { a, b } = lineToFragment(line);

        this.figure.insertModels(function* () {
            const model = AltitudeModel.create({
                vertex,
                basis: { fragment: { a, b } },
                style: null,
            });
            yield model;
            yield LabelPointModel.createNext(model.projectionPoint, this.figure);

            for (const point of [a, b, vertex]) {
                yield PointModel.create({ ...point, style: null });
                yield LabelPointModel.createNext(point, this.figure);
            }
        }, this);

        this.nextFlow();
    }

    protected renderElements(): BaseElement[] {
        return [];
    }
}
