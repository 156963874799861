import type { IStrategy, TCursorMoveDirection, TRemoveMethod } from "./IStrategy";
import type { InputService } from "../../services";
import type { TAnyAction } from "../../types";
import type { CommandsStack } from "../commands";
import type { ContainerModel } from "../container";
import type { CursorState } from "../cursor";
import type { BaseElementModel } from "../element";
import type { SelectionController } from "../selection";

export abstract class BaseStrategy<M extends BaseElementModel> implements IStrategy {
    protected readonly model: M;

    protected constructor(model: M) {
        this.model = model;
    }

    protected get inputService(): InputService {
        return this.model.inputService;
    }

    protected get cursor(): CursorState {
        return this.inputService.cursorState;
    }

    protected get commands(): CommandsStack {
        return this.inputService.commands;
    }

    protected get selection(): SelectionController {
        return this.inputService.selectionController;
    }

    abstract handleAction(action: TAnyAction): void;

    abstract handleCursorMoveOver(direction: TCursorMoveDirection): void;

    abstract handleRemoveThis(method: TRemoveMethod): void;

    abstract handleInserted(): void;

    abstract checkCanBeInserted(element: BaseElementModel, container: ContainerModel<M>): boolean;
}
