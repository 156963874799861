import type { TMultitudeActionChar } from "../elements/multitude/types";
import type { TAnyAction } from "../types";

export type TMultitudeAction = {
    type: "multitudes";
    char: TMultitudeActionChar;
};

export function createMultitudeAction(char: TMultitudeActionChar): TMultitudeAction {
    return { type: "multitudes", char };
}

export function isMultitudeAction(action: TAnyAction): action is TMultitudeAction {
    return action.type === "multitudes";
}
