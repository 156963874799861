import { computed, makeObservable, observable } from "mobx";
import { createTransformer } from "mobx-utils";

import { hasMatchInPointSet } from "@viuch/geometry-lib/check-geometry";
import { filterEqualPoints } from "@viuch/geometry-lib/filters";

import type { BaseElement } from "../../elements";
import type { Figure2DController } from "../../Figure2DController";
import type { ToolbarMenu } from "../../toolbar";
import type { IUserPointer } from "../../viewport/types";
import type { TEllipse, TPoint } from "@viuch/geometry-lib/types";
import type React from "react";

import { DotElement } from "../../elements/dot";
import { ElementColor } from "../../elements/ElementColor";
import { EllipseModel } from "../../models/circle";
import { LabelPointModel } from "../../models/label-point";
import { PointModel } from "../../models/point";
import { TangentLineOnCircleModel } from "../../models/tangent";
import { findEllipsesVisitor, isPointOnEllipse } from "../../services/actions/utils";
import { ButtonStates } from "../../toolbar";
import { TooltipMenu } from "../../toolbar/tooltip";
import { createDefaultToolbarMenuBuilder } from "../../utils/toolbar";
import { getAllModelPointsVisitor } from "../../utils/visible-points";
import { BaseFlow } from "../BaseFlow";
import { createMapModelToElements, opacifyColor, z } from "../utils";

export class TangentEllipsePointFlow extends BaseFlow {
    private ellipsesWithPoints?: { ellipse: TEllipse; points: TPoint[] }[];
    private readonly pointOnEllipse?: { ellipse: TEllipse; point: TPoint };

    flow: { point?: TPoint; drawing: boolean };

    constructor(data: Figure2DController, pointOnEllipse?: { ellipse: TEllipse; point: TPoint }) {
        super(data);
        this.pointOnEllipse = pointOnEllipse;
        this.flow = { drawing: false };

        makeObservable(this, {
            flow: observable,
            modelsElements: computed,
        });
    }

    attach(): void {
        if (this.pointOnEllipse) {
            const { ellipse, point } = this.pointOnEllipse;
            this.saveRaw(ellipse, point);
            this.nextFlow();
        } else {
            const ellipses = this.figure.models.flatMap((model) => model.accept(findEllipsesVisitor));
            const allPoints = filterEqualPoints(
                this.figure.models.flatMap((model) => model.accept(getAllModelPointsVisitor))
            );

            this.ellipsesWithPoints = ellipses.map((ellipse) => {
                const points = allPoints.filter((point) => isPointOnEllipse(ellipse, point));
                for (const point of points) {
                    this.snap.addPoint(point);
                }
                return { ellipse, points };
            });
        }
    }

    dispose(): void {
        this.snap.clean();
    }

    private mapModelToRender = createTransformer(createMapModelToElements(opacifyColor));

    get modelsElements(): BaseElement[] {
        return this.figure.models.flatMap(this.mapModelToRender);
    }

    protected renderElements(): BaseElement[] {
        return [...this.modelsElements, ...this.renderTempElements()];
    }

    private *renderTempElements(): Iterable<BaseElement> {
        if (this.ellipsesWithPoints) {
            for (let i = 0; i < this.ellipsesWithPoints.length; i++) {
                const color = ElementColor.Building;
                const overrideRenderOrder = z.points.priority + 1;
                const { points } = this.ellipsesWithPoints[i];

                for (const j in points) {
                    if (Object.prototype.hasOwnProperty.call(points, i)) {
                        const { x, y } = points[j];

                        yield new DotElement({
                            id: `temp_candidatePoint_${i}_${j}`,
                            x,
                            y,
                            color,
                            overrideRenderOrder,
                        });
                    }
                }
            }
        }

        if (this.flow.point) {
            const { x, y } = this.flow.point;
            yield new DotElement({
                id: `temp_point`,
                x,
                y,
                color: ElementColor.Selected,
                overrideRenderOrder: z.points.priority,
            });
        }
        return [];
    }

    protected saveRaw(ellipse: TEllipse, touchPoint: TPoint) {
        this.figure.insertModels(function* () {
            const model = TangentLineOnCircleModel.create({ ellipse, touchPoint });
            yield model;

            yield PointModel.create({ ...touchPoint, style: null });
            yield PointModel.create({ ...ellipse.center, style: null });
            yield EllipseModel.create({ ...ellipse, style: null });

            yield LabelPointModel.createNext(touchPoint, this.figure);
        }, this);
    }

    override handleViewportPointerEvent(e: React.PointerEvent, pointer: IUserPointer) {
        if (e.type === "pointercancel") return this.nextFlow();
        if (!e.isPrimary) return;

        const point = this.snap.snap(pointer.canvas) || void 0;

        this.movePointer(e, point);
        this.startDrawing(e);
        this.endDrawing(e);
    }

    private movePointer(e: React.PointerEvent, point?: TPoint) {
        if (e.type === "pointermove" || e.type === "pointerdown") {
            this.flow.point = point;
        }
    }

    private startDrawing(e: React.PointerEvent) {
        if (e.type === "pointerdown") {
            this.flow.drawing = true;
        }
    }

    private endDrawing(e: React.PointerEvent) {
        if (e.type === "pointerup") {
            this.flow.drawing = false;
            if (this.flow.point && this.ellipsesWithPoints) {
                const point = this.flow.point;

                const ellipseWithPoint = this.ellipsesWithPoints.find(({ points }) =>
                    hasMatchInPointSet(points, point)
                );

                if (ellipseWithPoint) {
                    this.saveRaw(ellipseWithPoint.ellipse, point);
                }
            }
            this.nextFlow();
        }
    }

    static create(data: Figure2DController) {
        return new TangentEllipsePointFlow(data);
    }

    override getToolbarMenu(): ToolbarMenu {
        return createDefaultToolbarMenuBuilder()
            .setButtonState(ButtonStates.active, ["fragments"])
            .setButtonIcon("tangent-throw-point", ["fragments"])
            .build();
    }

    override getTooltipMenu(): TooltipMenu | null {
        const text = "Выберите существующую точку на окружности или эллипсе, через которую проходит касательная";

        return new TooltipMenu(text, []);
    }
}
