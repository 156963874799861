import { action, makeObservable, observable } from "mobx";

import type { ReviewComment } from "./ReviewComment";

export class BaseReview {
    constructor(
        id: number,
        updatedAt: Date,
        createdAt: Date,
        isVerified: boolean,
        student: BaseReview["student"],
        text = "",
        comments: ReviewComment[] = []
    ) {
        this.id = id;
        this.updatedAt = updatedAt;
        this.createdAt = createdAt;
        this.isVerified = isVerified;
        this.student = student;

        this.text = text;
        this.comments = comments;

        makeObservable(this);
    }

    readonly id: number;
    readonly updatedAt: Date;
    readonly createdAt: Date;
    readonly student: {
        id: number;
        username: string;
    };

    @observable text: string;
    @observable comments: ReviewComment[];
    @observable isVerified: boolean;

    @action.bound
    setText(text: string) {
        this.text = text;
    }
}
