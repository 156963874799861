import { IntervalsDot, IntervalsInstrumentStatement, IntervalsLine } from "@viuch/instrument-intervals/statement";

import type { TIntervalsEditorData } from "./IntervalsEditor";

export function mapIntervalsInstrument(data: TIntervalsEditorData): IntervalsInstrumentStatement {
    if (!data.lines) {
        return IntervalsInstrumentStatement.createEmpty();
    }
    const lines = data.lines.map((line) => {
        const dots = line.dots.map((dot) => {
            return new IntervalsDot(dot);
        });
        return new IntervalsLine(dots, line.intervalSigns, line.label);
    });
    return new IntervalsInstrumentStatement(lines);
}
