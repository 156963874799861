import { action, makeObservable, observable } from "mobx";
import { computedFn } from "mobx-utils";

import { safeCheck } from "@viuch/utils/debug";

import type { Graph2DModel } from "../../../core/Graph2DModel";
import type { Graph2DPreRenderingService } from "../../core/Graph2DPreRenderingService";
import type { Graph2DViewportController } from "../../core/Graph2DViewportController";
import type { BaseGraphItem } from "../../model/BaseGraphItem";
import type { GraphFunctionInterval } from "../../model/GraphFunctionInterval";
import type { BaseGraphViewItem } from "../../rendering/BaseGraphViewItem";
import type { IButtonToolbarElement } from "../../toolbar/elements/ToolbarButton";
import type { IToolbarElement } from "../../toolbar/toolbarTypes";
import type { TPoint } from "@viuch/geometry-lib/types";
import type React from "react";

import { FormulaEvalFunctionGraphItem } from "../../../models/FormulaEvalFunctionGraphItem";
import { FunctionIntervalViewItem } from "../../../view-models/FunctionIntervalViewItem";
import { PointViewItem } from "../../../view-models/PointViewItem";
import { zIndexValues } from "../../rendering/utils";
import { BaseGraphFlow } from "../BaseGraphFlow";

export class EditorFlow extends BaseGraphFlow {
    constructor(
        model: Graph2DModel,
        viewport: Graph2DViewportController,
        renderTransformer: Graph2DPreRenderingService
    ) {
        super(model, viewport, renderTransformer);
        makeObservable(this);
    }

    private readonly _toolbarHandlers: Map<string, TToolbarHandler<IToolbarElement>[]> = new Map();

    addToolbarButtonHandler<T extends IToolbarElement>(toolbarItemKey: string, getUpdates: (item: T) => T): this {
        let handlers = this._toolbarHandlers.get(toolbarItemKey);

        if (!handlers) {
            handlers = [];
            this._toolbarHandlers.set(toolbarItemKey, handlers);
        }

        handlers.push({ getUpdates });
        return this;
    }

    getToolbarElementUpdates(element: IToolbarElement): IButtonToolbarElement | IToolbarElement {
        if (!element.key) {
            return element;
        }

        for (const { getUpdates } of this._toolbarHandlers.get(element.key) ?? []) {
            element = getUpdates(element);
        }

        if (safeCheck<IButtonToolbarElement>(element, (e) => e.type === "button")) {
            switch (element.key) {
                case "cursor":
                    return {
                        ...element,
                        enabled: true,
                        active: true,
                    };
            }
        }

        return element;
    }

    getToolbarPreset(): string | null {
        return "default";
    }

    getViewItems(): readonly BaseGraphViewItem[] {
        const items = this.model.dataItems //
            .getAll()
            .flatMap(this._getModelViewItems);

        return this.renderTransformer.filter(items);
    }

    private _getModelViewItems = computedFn((model: BaseGraphItem): BaseGraphViewItem[] => {
        const { renderTransformer } = this;

        const SELECT_COLOR = "var(--primary-green)";

        return renderTransformer.transform(model).map((viewItem) => {
            if (viewItem instanceof FunctionIntervalViewItem) {
                const isSelected = this.selectedElements.some(
                    (s) => s.type === "graph-interval" && s.interval === viewItem.interval
                );

                if (isSelected) {
                    if (viewItem.color === "#ffffff") {
                        viewItem.color = SELECT_COLOR;
                    }
                    viewItem.width = 4;
                    viewItem.zIndex = zIndexValues.path.high;
                }
            }

            if (viewItem instanceof PointViewItem) {
                const { x, y } = viewItem;
                const isSelected = this.selectedElements.some((s) => s.type === "point" && s.x === x && s.y === y);

                if (isSelected) {
                    if (viewItem.color === "#ffffff") {
                        viewItem.color = SELECT_COLOR;
                    }
                    viewItem.size = 12;
                    viewItem.zIndex = zIndexValues.point.high;
                }
            }

            return viewItem;
        });
    });

    handleElementPointerEvent(e: React.PointerEvent, canvasPoint: TPoint, element: BaseGraphViewItem): void {
        if (element instanceof FunctionIntervalViewItem) {
            e.stopPropagation();
        }
        if (element instanceof PointViewItem) {
            e.stopPropagation();
        }
    }

    handleKeyEvent = void 0;

    handleViewportPointerEvent = void 0;

    override handleElementClickEvent(e: React.PointerEvent, viewItem: BaseGraphViewItem) {
        if (viewItem instanceof FunctionIntervalViewItem) {
            e.stopPropagation();
            const { model, interval } = viewItem;
            if (model instanceof FormulaEvalFunctionGraphItem) {
                const selectedElement = this.selectedElements.find(
                    (item) => item.type === "graph-interval" && item.model === model && item.interval === interval
                );

                if (selectedElement) {
                    this.selectedElements.remove(selectedElement);
                } else {
                    this.addSelectedElement({ type: "graph-interval", model, interval });
                }
            }
        }

        if (viewItem instanceof PointViewItem) {
            e.stopPropagation();
            const { model, x, y } = viewItem;

            const selectedPoint = this.selectedElements.find((s) => s.type === "point" && s.x === x && s.y === y);

            if (selectedPoint) {
                this.removeSelectedElement(selectedPoint);
            } else {
                this.addSelectedElement({ type: "point", x, y });
            }
        }
    }

    @observable.shallow selectedElements: TAnySelectedGraphElement[] = [];

    @action.bound
    removeSelectedElement(selectedElement: TAnySelectedGraphElement) {
        this.selectedElements.remove(selectedElement);
    }

    @action.bound
    addSelectedElement(selectedElement: TAnySelectedGraphElement) {
        this.selectedElements.push(selectedElement);
    }

    @action.bound
    clearSelectedElements() {
        this.selectedElements.clear();
    }
}

export type TToolbarHandler<T extends IToolbarElement> = {
    getUpdates(item: T): IToolbarElement;
};

export type TAnySelectedGraphElement = TSelectedGraphInterval | TSelectedGraphPoint;

export type TSelectedGraphInterval = {
    type: "graph-interval";
    model: FormulaEvalFunctionGraphItem;
    interval: GraphFunctionInterval;
};

export type TSelectedGraphPoint = {
    type: "point";
    x: number;
    y: number;
};
