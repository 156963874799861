import { action, makeObservable, observable } from "mobx";

import { IntervalsInstrumentStatement } from "@viuch/instrument-intervals/statement";

import type { TEditorBlockVerticalAlign } from "../../view/editor-view/useRenderBlock";
import type { IEditorVisitor } from "../IEditorVisitor";
import type { IIntervalsMethodLineDot, TIntervalSign, IntervalsLine } from "@viuch/instrument-intervals/statement";
import type { TSerializedState } from "@viuch/math-editor";

import { BaseEditor, EditorTypes } from "../base";

import { mapIntervalsInstrument } from "./utils";

export type TIntervalsEditorLineData = {
    dots: IIntervalsMethodLineDot[];
    intervalSigns: TIntervalSign[];
    label: TSerializedState | undefined;
};

export type TIntervalsEditorData = {
    type: EditorTypes.intervals;
    lines?: TIntervalsEditorLineData[];
    autoHeight?: boolean | undefined;
    verticalAlign?: TEditorBlockVerticalAlign | undefined;
};

export class IntervalsEditor extends BaseEditor<TIntervalsEditorData> {
    readonly intervals: IntervalsInstrumentStatement;

    @observable autoHeight: boolean;
    @observable verticalAlign: TEditorBlockVerticalAlign;

    constructor(state?: TIntervalsEditorData) {
        super();

        this.intervals = state ? mapIntervalsInstrument(state) : IntervalsInstrumentStatement.createEmpty();
        this.autoHeight = !!state?.autoHeight;
        this.verticalAlign = state?.verticalAlign ?? null;

        makeObservable(this);
    }

    async serialize(): Promise<TIntervalsEditorData> {
        return {
            type: EditorTypes.intervals,
            lines: this.intervals.lines.map((line: IntervalsLine) => ({
                dots: line.dots.map((dot) => ({
                    form: dot.form,
                    position: dot.position,
                    value: dot.value,
                })),
                intervalSigns: line.intervalsSings,
                label: line.label,
            })),
            autoHeight: this.autoHeight,
            verticalAlign: this.verticalAlign,
        };
    }

    checkIsEmpty(): boolean {
        return false;
    }

    accept<R>(visitor: IEditorVisitor<R>): R {
        return visitor.visitIntervalsInstrument(this);
    }

    @action.bound
    setAutoHeight(value: boolean) {
        this.autoHeight = value;
    }

    @action setVerticalAlign = (v: TEditorBlockVerticalAlign) => void (this.verticalAlign = v);
}
