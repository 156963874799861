import cn from "classnames";
import React from "react";

import type { TToolbarIcons } from "./types";

import styles from "./ToolbarIcon.module.scss";

type Props = {
    icon: TToolbarIcons | string;
    className?: string;
};

export function ToolbarIcon({ icon, className }: Props) {
    return <div className={cn(className, styles.icon, icon)} />;
}
