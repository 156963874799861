import type { ComplexIntegralModel } from "./ComplexIntegralModel";
import type { ContainerModel } from "../../core/container";
import type { BaseElementModel } from "../../core/element";
import type { IElementFilter } from "../../core/strategies/IElementFilter";

import { KeepCharsNumbersFilter } from "../../core/strategies/filters/KeepCharsNumbersFilter";
import { MultiContainerElementStrategy } from "../../core/strategies/MultiContainerElementStrategy";

export class ComplexIntegralStrategy extends MultiContainerElementStrategy<ComplexIntegralModel> {
    private argFilter: IElementFilter;

    constructor(model: ComplexIntegralModel) {
        super(model);

        this.argFilter = new KeepCharsNumbersFilter();
    }

    override checkCanBeInserted(element: BaseElementModel, container: ContainerModel<ComplexIntegralModel>): boolean {
        if (container === this.model.argContent) {
            return this.argFilter.canInsert(element);
        }
        return super.checkCanBeInserted(element, container);
    }
}
