import type { TAnyAction } from "../types";

export type TModuleAction = {
    type: "module";
};

export function createModuleAction(): TModuleAction {
    return { type: "module" };
}

export function isModuleAction(action: TAnyAction): action is TModuleAction {
    return action.type === "module";
}
