import type { BaseFormNode } from "../nodes/BaseFormNode";
import type { TTextControl } from "../types/controls";

import { TextFormNode } from "../nodes/TextFormNode";
import { pathFromRaw } from "../utils/paths";

export function instantiateTextControl(data: TTextControl, parent: BaseFormNode | null): TextFormNode {
    const { label, multiline, path } = data;

    return new TextFormNode("", label, multiline, parent, pathFromRaw(path), []);
}
