import type { BaseGraphViewItem } from "../editor/rendering/BaseGraphViewItem";

import { BaseGraphItem } from "../editor/model/BaseGraphItem";

export class GenericViewGraphItem extends BaseGraphItem {
    readonly viewItems: BaseGraphViewItem[];

    /** @deprecated Use only for develop reasons */
    constructor(viewItems: BaseGraphViewItem[]) {
        super();

        this.viewItems = viewItems;
    }

    override clone(): GenericViewGraphItem {
        return this;
    }
}
