import type { TMathArrayControl, TMathControl } from "@viuch/form-builder/types/controls";

export const evaluationDimensionsMathArrayControl: TMathArrayControl = {
    path: "./evaluation_dimensions",
    control: "MathArray",
    label: "Величины при вычислении:",
    extensions: ["select-dimension"],
};

export const answerDimensionMathControl: TMathControl = {
    path: "./answer_dimension",
    control: "Math",
    label: "Величина в ответе:",
    extensions: ["select-dimension"],
};
