import { action, makeObservable, observable } from "mobx";

import type { IVariableContentVisitor } from "./IVariableContentVisitor";
import type { TSerializedState } from "@viuch/math-editor";

import { BaseVariableContent } from "./BaseVariableContent";

export class TextVariableContent extends BaseVariableContent {
    @observable formula: TSerializedState;

    constructor(formula: TSerializedState) {
        super();
        this.formula = formula;

        makeObservable(this);
    }

    accept<R>(visitor: IVariableContentVisitor<R>): R {
        return visitor.withText(this);
    }

    @action.bound
    setFormula(formula: TSerializedState) {
        this.formula = formula;
    }

    clone(): TextVariableContent {
        return new TextVariableContent(this.formula);
    }
}
