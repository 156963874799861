import cn from "classnames";
import { observer } from "mobx-react-lite";
import React, { useCallback, useMemo } from "react";

import { InputService, MathEditorInput } from "@viuch/math-editor";

import type { TextTableInstrumentSettingsCell } from "../../settings/cells";
import type { KeyboardService } from "@viuch/math-editor";

import styles from "./TableInstrumentSettingsTextCellEditor.module.scss";

type Props = {
    textCell: TextTableInstrumentSettingsCell;
    keyboardService: KeyboardService;
    className?: string;
};

export const TableInstrumentSettingsTextCellEditor = observer(function TableInstrumentSettingsTextCellEditor(
    props: Props
) {
    const { className, textCell, keyboardService } = props;

    const inputService = useMemo(
        () => new InputService({ initialState: textCell.formula, multiline: true }),
        [textCell]
    );

    const onBlur = useCallback(
        (inputService: InputService) => {
            textCell.setFormula(inputService.getSerializedState());
        },
        [textCell]
    );

    return (
        <div className={cn(className, styles.wrapper)}>
            <MathEditorInput
                inputModel={inputService.model}
                className={styles.input}
                inputWrapperClassName={styles.inputWrapper}
                onBlur={onBlur}
                keyboardService={keyboardService}
            />
        </div>
    );
});
