import { computed, makeObservable, observable } from "mobx";

import { generateId } from "@viuch/shared/utils/data";

import type { IModelVisitor } from "../BaseModel";
import type { TModelStyle } from "../modelStyle";
import type { TPoint } from "@viuch/geometry-lib/types";
import type { TSerializedState } from "@viuch/math-editor";

import { BaseModel } from "../BaseModel";

export interface ILabelFragmentModel {
    a: TPoint;
    b: TPoint;
    value: TSerializedState;
    altOrigin: boolean;
    rotationAngle?: number;
    directionAngle?: number;
    style: TModelStyle | null;
}

export class LabelFragmentModel extends BaseModel implements ILabelFragmentModel {
    a: TPoint;
    b: TPoint;
    value: TSerializedState;
    rotationAngle: number;
    directionAngle: number;
    altOrigin: boolean;
    style: TModelStyle | null;

    constructor(data: ILabelFragmentModel, id: number) {
        super(id);

        this.a = { x: data.a.x, y: data.a.y };
        this.b = { x: data.b.x, y: data.b.y };
        this.value = data.value;
        this.rotationAngle = data.rotationAngle || 0;
        this.directionAngle = data.directionAngle || 0;
        this.altOrigin = data.altOrigin;
        this.style = data.style;

        makeObservable(this, {
            a: observable,
            b: observable,
            value: observable.ref,
            x: computed,
            y: computed,
            rotationAngle: observable,
            altOrigin: observable.ref,
            style: observable,
        });
    }

    accept<R>(visitor: IModelVisitor<R>): R {
        return visitor.withLabelFragment(this);
    }

    get x(): number {
        return (this.a.x + this.b.x) / 2;
    }

    get y(): number {
        return (this.a.y + this.b.y) / 2;
    }

    static create(data: ILabelFragmentModel) {
        return new LabelFragmentModel(data, generateId());
    }
}
