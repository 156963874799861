import { getClassOf } from "@viuch/shared/utils/runtime/classOf";
import { assert } from "@viuch/utils/debug";

import type { BaseGraphViewItem } from "./BaseGraphViewItem";
import type { ReactElement } from "react";

export class GraphElementsRendererService {
    private readonly map: Map<object, TRenderFunction> & {
        set<T extends BaseGraphViewItem>(classType: ClassOf<T>, renderFunction: TRenderFunction<T>): void;
    };

    constructor() {
        this.map = new Map();
    }

    registerRenderer<T extends BaseGraphViewItem>(type: ClassOf<T>, renderFunction: TRenderFunction<T>): this {
        assert(!this.map.has(type));

        this.map.set<T>(type, renderFunction);
        return this;
    }

    render(view: BaseGraphViewItem): ReactElement {
        const classType = getClassOf(view);
        assert(classType);

        const renderFunction = this.map.get(classType);
        assert(renderFunction);

        return renderFunction(view);
    }
}

export type TRenderFunction<T extends BaseGraphViewItem = BaseGraphViewItem> = (view: T) => ReactElement;
