import type { TAnyAction } from "../types";

export type TRemoveDirection = "forward" | "backward";

export type TRemoveAction = {
    type: "remove";
    direction: TRemoveDirection;
};

export function createRemoveAction(method: TRemoveDirection): TRemoveAction {
    return { type: "remove", direction: method };
}

export function isRemoveAction(action: TAnyAction): action is TRemoveAction {
    return action.type === "remove";
}
