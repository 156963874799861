import { makeObservable, observable } from "mobx";

import { generateId } from "@viuch/shared/utils/data";

import type { IModelVisitor } from "../BaseModel";
import type { TModelStyle } from "../modelStyle";
import type { TPoint } from "@viuch/geometry-lib/types";

import { BaseModel } from "../BaseModel";

export interface IPointModel {
    x: number;
    y: number;
    style: TModelStyle | null;
}

export class PointModel extends BaseModel implements IPointModel {
    x: number;
    y: number;
    style: TModelStyle | null;

    constructor(data: IPointModel, id: number) {
        super(id);

        this.x = data.x;
        this.y = data.y;
        this.style = data.style;

        makeObservable(this, {
            x: observable,
            y: observable,
            style: observable,
        });
    }

    static create(data: IPointModel) {
        return new PointModel(data, generateId());
    }

    accept<R>(visitor: IModelVisitor<R>): R {
        return visitor.withPoint(this);
    }

    toPoint(): TPoint {
        const { x, y } = this;
        return { x, y };
    }
}
