import type { TPoint, TRect } from "../types";

export function pointAddition<T extends TPoint>(from: T, what: TPoint): T {
    return {
        ...from,
        x: from.x + what.x,
        y: from.y + what.y,
    };
}

export function pointSubtraction<T extends TPoint>(from: T, what: TPoint): T {
    return {
        ...from,
        x: from.x - what.x,
        y: from.y - what.y,
    };
}

export function rectAddition({ x, y, w, h }: TRect, add: TRect): TRect {
    return {
        x: x + add.x,
        y: y + add.y,
        w: w + add.w,
        h: h + add.h,
    };
}

export function rectMultiplication({ x, y, w, h }: TRect, arg: TRect): TRect {
    return {
        x: x * arg.x,
        y: y * arg.y,
        w: w * arg.w,
        h: h * arg.h,
    };
}

export function getRectFromDomRect({ x, y, width: w, height: h }: DOMRectReadOnly): TRect {
    return { x, y, w, h };
}

export function computeRectCenter({ x, y, w, h }: TRect): TPoint {
    return {
        x: x + w / 2,
        y: y + h / 2,
    };
}

export function computeSquaredDistance(left: TPoint, right: TPoint): number {
    return Math.pow(left.x - right.x, 2) + Math.pow(left.y - right.y, 2);
}
