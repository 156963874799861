import type { ResultModel } from "./ResultModel";
import type { TElementProps } from "../../types/element";
import type { VFC } from "react";

import { MathEditorIcon } from "../../components/icon";
import { useMathEditorElementRef } from "../../hooks";

import styles from "./ResultElement.module.scss";

export const ResultElement: VFC<TElementProps<ResultModel>> = ({ elementModel }) => {
    const ref = useMathEditorElementRef(elementModel);

    return (
        <span
            ref={ref}
            className={styles.result}
        >
            <MathEditorIcon iconName="result" />
        </span>
    );
};
