import { observer } from "mobx-react-lite";

import type { ChangeEvent, RefObject } from "react";

type Props = {
    onChange: (file: File | null) => void;
    isDisabled?: boolean;
    accept?: string;
    className?: string;
    inputRef?: RefObject<HTMLInputElement>;
};

export const FileInput = observer(function FileInput({ className, onChange, isDisabled, accept, inputRef }: Props) {
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        onChange(e.target.files?.item(0) ?? null);
    };

    return (
        <input
            ref={inputRef}
            className={className}
            accept={accept}
            disabled={isDisabled}
            type="file"
            onChange={handleChange}
        />
    );
});
