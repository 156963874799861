export function scheduleTask(callback: VoidFunction) {
    queueMicrotask(() => {
        setTimeout(() => {
            callback();
        });
    });
}

export function detectIsMobile(): boolean {
    if (typeof window === "undefined") {
        return false;
    }
    return (
        window.matchMedia("(hover: none) and (pointer: coarse), (hover: none) and (pointer: fine)").matches ||
        "ontouchstart" in document
    );
}
