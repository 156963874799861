import type { TLineCharacteristic, TPoint, TFragment } from "./types";

import { getLineAngle, normalizeAngle } from "./angles";
import { areLineCharacteristicsEqual, areSamePoints, findPerpendicularPoint } from "./check-geometry";
import { squaredVectorLength } from "./vectors";

/**
 * Определяет характеристику отрезка так, что:
 * - любые 2 отрезка, лежащие на одной прямой, будут иметь одну и ту же характеристику
 * - и наоборот: любые 2 отрезка, не лежащие на одной прямой, будут иметь разные характеристики.
 */
export function getLineCharacteristic(a: TPoint, b: TPoint): TLineCharacteristic {
    const line = { x1: a.x, y1: a.y, x2: b.x, y2: b.y };
    const angle = normalizeAngle(getLineAngle(line, true), true);
    const pp = findPerpendicularPoint(line, { x: 0, y: 0 });
    const normalVectorSquaredLength = squaredVectorLength(pp.x, pp.y);
    return { angle, normalVectorSquaredLength };
}

/**
 * Находит непрерывный отрезок, используя (съедая) данные точки и отрезки.
 */
export function tryExtractMergedFragment(mutableFragments: TFragment[], mutablePoints: TPoint[]): TFragment | null {
    if (mutableFragments.length === 0) {
        return null;
    }

    if (mutableFragments.length === 1 || mutablePoints.length === 0) {
        const fragment = mutableFragments.shift()!;
        extractPoint(fragment.a);
        extractPoint(fragment.b);
        return fragment;
    }

    const baseFragment = mutableFragments.shift()!;
    const baseCharacteristic = getLineCharacteristic(baseFragment.a, baseFragment.b);

    while (mutableFragments.length > 0 || mutablePoints.length > 0) {
        if (extractPoint(baseFragment.a)) {
            for (let i = 0; i < mutableFragments.length; i++) {
                const fragment = mutableFragments[i];
                const characteristic = getLineCharacteristic(fragment.a, fragment.b);

                if (areLineCharacteristicsEqual(baseCharacteristic, characteristic)) {
                    if (areSamePoints(baseFragment.a, fragment.a)) {
                        mutableFragments.splice(i, 1);
                        i--;
                        baseFragment.a = fragment.b;
                        break;
                    } else if (areSamePoints(baseFragment.a, fragment.b)) {
                        mutableFragments.splice(i, 1);
                        i--;
                        baseFragment.a = fragment.a;
                        break;
                    }
                }
            }
        } else if (extractPoint(baseFragment.b)) {
            for (let i = 0; i < mutableFragments.length; i++) {
                const fragment = mutableFragments[i];
                const characteristic = getLineCharacteristic(fragment.a, fragment.b);
                if (areLineCharacteristicsEqual(baseCharacteristic, characteristic)) {
                    if (areSamePoints(baseFragment.b, fragment.a)) {
                        mutableFragments.splice(i, 1);
                        i--;
                        baseFragment.b = fragment.b;
                        break;
                    } else if (areSamePoints(baseFragment.b, fragment.b)) {
                        mutableFragments.splice(i, 1);
                        i--;
                        baseFragment.b = fragment.a;
                        break;
                    }
                }
            }
        } else {
            return baseFragment;
        }
    }
    return baseFragment;

    function extractPoint(point: TPoint): boolean {
        const i = mutablePoints.findIndex((point2) => areSamePoints(point, point2));
        if (i === -1) return false;
        mutablePoints.splice(i, 1);
        return true;
    }
}
