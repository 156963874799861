import type { DistanceToCenter } from "./DistanceToCenter";
import type { DistanceToLine } from "./DistanceToLine";
import type { ShapeArea } from "./ShapeArea";

export abstract class BaseDrawingGenerationSettings {
    abstract accept<R>(visitor: BaseDrawingGenerationSettings.Visitor<R>): R;
    abstract clone(): BaseDrawingGenerationSettings;
}

export declare namespace BaseDrawingGenerationSettings {
    export interface Visitor<R> {
        withDistanceToCenter: (distanceToCenter: DistanceToCenter) => R;
        withDistanceToLine: (distanceToLine: DistanceToLine) => R;
        withShapeArea: (shapeArea: ShapeArea) => R;
    }
}

export enum DrawingGenerationSettingsTypes {
    DistanceToCenter = "DistanceToCenter",
    DistanceToLine = "DistanceToLine",
    ShapeArea = "ShapeArea",
}

export const drawingGenerationSettingsTypeVisitor: BaseDrawingGenerationSettings.Visitor<DrawingGenerationSettingsTypes> =
    {
        withDistanceToCenter: () => DrawingGenerationSettingsTypes.DistanceToCenter,
        withDistanceToLine: () => DrawingGenerationSettingsTypes.DistanceToLine,
        withShapeArea: () => DrawingGenerationSettingsTypes.ShapeArea,
    };
