import { equateNumbers } from "@viuch/geometry-lib/equate";
import { intersectionEllipseVertical } from "@viuch/geometry-lib/intersection";

import type { BaseModel } from "../../models";
import type { IModelVisitor } from "../../models/BaseModel";
import type { CircumscribedCircleModel, EllipseModel, InscribedCircleModel } from "../../models/circle";
import type { ComputedIntersectionPoints } from "../../models/computed";
import type { EqualAnglesModel, EqualSegmentsModel } from "../../models/constraints";
import type {
    ConstraintLineModel,
    FragmentDashedModel,
    FragmentModel,
    LineModel,
    ParallelFragmentModel,
    VectorDashedModel,
    VectorModel,
} from "../../models/fragment";
import type { AltitudeModel, BisectionModel, MedianModel } from "../../models/geometry";
import type { MiddleLineModel } from "../../models/geometry/MiddleLineModel";
import type { LabelAngleModel } from "../../models/label-angle";
import type { LabelFragmentModel } from "../../models/label-fragment";
import type { LabelPointModel } from "../../models/label-point";
import type { PointModel } from "../../models/point";
import type { TangentLineOnCircleModel, TangentLineToCircleModel } from "../../models/tangent";
import type { BaseSelection, ISelectionElementVisitor } from "../../selection/BaseSelection";
import type { EllipseSelection, FragmentSelection, PointSelection, VectorSelection } from "../../selection/items";
import type { TEllipse, TPoint } from "@viuch/geometry-lib/types";

export enum SelectionTypes {
    point,
    fragment,
    ellipse,
    vector,
}

export type GetSelectionTypes = {
    [SelectionTypes.point]: PointSelection;
    [SelectionTypes.fragment]: FragmentSelection;
    [SelectionTypes.ellipse]: EllipseSelection;
    [SelectionTypes.vector]: VectorSelection;
};

const getSelectionTypeVisitor: ISelectionElementVisitor<SelectionTypes> = {
    withPoint: () => SelectionTypes.point,
    withFragment: () => SelectionTypes.fragment,
    withEllipse: () => SelectionTypes.ellipse,
    withVector: () => SelectionTypes.vector,
};

export function checkSelectionType<T extends SelectionTypes>(
    type: T,
    selection: BaseSelection
): selection is GetSelectionTypes[T] {
    return selection.accept(getSelectionTypeVisitor) === type;
}

export function createCheckSelectionType<T extends SelectionTypes>(type: T) {
    return (selection: BaseSelection): selection is GetSelectionTypes[T] =>
        selection.accept(getSelectionTypeVisitor) === type;
}

export enum ModelTypes {
    point,
    fragment,
    labelPoint,
    labelFragment,
    labelAngle,
    line,
    constraintLine,
    computedIntersectionPoints,
    bisection,
    median,
    altitude,
    equalSegments,
    equalAngles,
    ellipse,
    inscribedCircle,
    circumscribedCircle,
    tangentToCircle,
    middleLine,
    tangentOnCircle,
    parallelFragment,
    vector,
    vectorDashed,
    fragmentDashed,
}

export type MapModelTypes = {
    [ModelTypes.point]: PointModel;
    [ModelTypes.fragment]: FragmentModel;
    [ModelTypes.parallelFragment]: ParallelFragmentModel;
    [ModelTypes.labelPoint]: LabelPointModel;
    [ModelTypes.labelFragment]: LabelFragmentModel;
    [ModelTypes.labelAngle]: LabelAngleModel;
    [ModelTypes.line]: LineModel;
    [ModelTypes.constraintLine]: ConstraintLineModel;
    [ModelTypes.computedIntersectionPoints]: ComputedIntersectionPoints;
    [ModelTypes.bisection]: BisectionModel;
    [ModelTypes.median]: MedianModel;
    [ModelTypes.altitude]: AltitudeModel;
    [ModelTypes.equalSegments]: EqualSegmentsModel;
    [ModelTypes.equalAngles]: EqualAnglesModel;
    [ModelTypes.ellipse]: EllipseModel;
    [ModelTypes.inscribedCircle]: InscribedCircleModel;
    [ModelTypes.circumscribedCircle]: CircumscribedCircleModel;
    [ModelTypes.tangentToCircle]: TangentLineToCircleModel;
    [ModelTypes.middleLine]: MiddleLineModel;
    [ModelTypes.tangentOnCircle]: TangentLineOnCircleModel;
    [ModelTypes.vector]: VectorModel;
    [ModelTypes.vectorDashed]: VectorDashedModel;
    [ModelTypes.fragmentDashed]: FragmentDashedModel;
};

const getModelTypeVisitor: IModelVisitor<ModelTypes> = {
    withPoint: () => ModelTypes.point,
    withFragment: () => ModelTypes.fragment,
    withParallelFragment: () => ModelTypes.parallelFragment,
    withLabelPoint: () => ModelTypes.labelPoint,
    withLabelFragment: () => ModelTypes.labelFragment,
    withLabelAngle: () => ModelTypes.labelAngle,
    withLine: () => ModelTypes.line,
    withConstraintLine: () => ModelTypes.constraintLine,
    withComputedIntersectionPoints: () => ModelTypes.computedIntersectionPoints,
    withBisection: () => ModelTypes.bisection,
    withMedian: () => ModelTypes.median,
    withAltitude: () => ModelTypes.altitude,
    withEqualSegments: () => ModelTypes.equalSegments,
    withEqualAngles: () => ModelTypes.equalAngles,
    withEllipse: () => ModelTypes.ellipse,
    withInscribedCircle: () => ModelTypes.inscribedCircle,
    withCircumscribedCircle: () => ModelTypes.circumscribedCircle,
    withTangentLine: () => ModelTypes.tangentToCircle,
    withMiddleLine: () => ModelTypes.middleLine,
    withTangentLineOnCircle: () => ModelTypes.tangentOnCircle,
    withVector: () => ModelTypes.vector,
    withVectorDashed: () => ModelTypes.vectorDashed,
    withFragmentDashed: () => ModelTypes.fragmentDashed,
};

type InferModelType<T extends BaseModel> = {
    [Type in keyof MapModelTypes]: MapModelTypes[Type] extends T ? Type : never;
}[keyof MapModelTypes];

export function getModelType<T extends BaseModel>(model: T): InferModelType<T> {
    return model.accept(getModelTypeVisitor as IModelVisitor<InferModelType<T>>);
}

export function checkModelType<T extends ModelTypes>(
    model: BaseModel,
    type: T & ModelTypes
): model is MapModelTypes[T] {
    return model.accept(getModelTypeVisitor) === type;
}

export function isPointOnEllipse(ellipse: TEllipse, point: TPoint): boolean {
    const coordinates = intersectionEllipseVertical(ellipse, point.x);
    if (!coordinates) return false;
    const [y1, y2] = coordinates;
    return equateNumbers(y1, "==", point.y) || equateNumbers(y2, "==", point.y);
}

export const findEllipsesVisitor: IModelVisitor<TEllipse[]> = {
    withEllipse: (ellipse) => [ellipse],
    withCircumscribedCircle: (circle) => (circle.isEllipse() ? [circle] : []),
    withInscribedCircle: (circle) => (circle.isEllipse() ? [circle] : []),
    withPoint: () => [],
    withFragment: () => [],
    withFragmentDashed: () => [],
    withVector: () => [],
    withVectorDashed: () => [],
    withLabelPoint: () => [],
    withLabelFragment: () => [],
    withLabelAngle: () => [],
    withLine: () => [],
    withConstraintLine: () => [],
    withComputedIntersectionPoints: () => [],
    withBisection: () => [],
    withMedian: () => [],
    withAltitude: () => [],
    withEqualAngles: () => [],
    withEqualSegments: () => [],
    withTangentLine: () => [],
    withMiddleLine: () => [],
    withTangentLineOnCircle: () => [],
    withParallelFragment: () => [],
};
