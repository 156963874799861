import { observer } from "mobx-react-lite";

import type { LogModel } from "./LogModel";
import type { TElementProps } from "../../types/element";
import type { VFC } from "react";

import { ContainerElement } from "../../core/container";
import { RoundBrackets } from "../../core/dynamic-brackets";
import { useMathEditorElementRef } from "../../hooks";

import styles from "./LogElement.module.scss";

export const LogElement: VFC<TElementProps<LogModel>> = observer(function LogElement({ elementModel }) {
    const ref = useMathEditorElementRef(elementModel);

    return (
        <span
            ref={ref}
            className={styles.wrapper}
        >
            <span className={styles.inline}>log</span>

            <span className={styles.indices}>
                <span>
                    <ContainerElement
                        containerModel={elementModel.degreeContent}
                        className={styles.degree}
                        inline
                    />
                </span>
                <span>
                    <ContainerElement
                        containerModel={elementModel.base}
                        className={styles.base}
                        inline
                    />
                </span>
            </span>

            <RoundBrackets>
                <ContainerElement
                    containerModel={elementModel.content}
                    inline
                />
            </RoundBrackets>
        </span>
    );
});
