import { observer } from "mobx-react-lite";
import React from "react";

import type { BaseElementModel } from "../element";

type Props = {
    elementModel: BaseElementModel;
};

export const ElementRenderer: React.VFC<Props> = observer(function ElementRenderer({ elementModel }) {
    const inputService = elementModel.inputService;
    const Component = inputService.getReactComponent(elementModel);

    return <Component elementModel={elementModel} />;
});
