import type { BaseElementModel } from "../core/element";
import type { TSymbolActionChar } from "../elements/symbol/types";
import type {
    TAnySerializedElement,
    TContainerElements,
    THighlightLocation,
    TSerializedContainer,
    TSerializedState,
} from "../types";

import { createSerializedBracket } from "../elements/bracket/utils";
import { createSerializedChar, isSerializedChar } from "../elements/char/utils";
import { createSerializedDigit, isSerializedDigit } from "../elements/digit/utils";
import { createSerializedEquality } from "../elements/equality/utils";
import { isSerializedLinebreak } from "../elements/linebreak/utils";
import { createSerializedOperatorHigh } from "../elements/operator-high/utils";
import { createSerializedOperatorLow, isSerializedLowPriority } from "../elements/operator-low/utils";
import { createSerializedSpace, isSerializedSpace } from "../elements/space/utils";
import { createSerializedSymbol, isSerializedSymbol } from "../elements/symbol/utils";

export function createSerializedState(
    elements: (TAnySerializedElement | { type: string; [field: string]: any })[] = [],
    highlights: THighlightLocation[] = []
): TSerializedState {
    return { elements, highlights };
}

export function createContainerData(elements: BaseElementModel[] = [], uuid?: string): TContainerElements {
    return { elements, uuid };
}

export function createSerializedContainer(elements: TAnySerializedElement[] = []): TSerializedContainer {
    return { elements };
}

export function tryConvertSimpleToString(state: TSerializedState): string | null {
    const chars: string[] = [];
    for (const element of state.elements as any[]) {
        if (isSerializedChar(element)) {
            chars.push(element.char);
        } else if (isSerializedLinebreak(element)) {
            chars.push("\n");
        } else if (isSerializedDigit(element)) {
            chars.push(String(element.digit));
        } else if (isSerializedSpace(element)) {
            chars.push(" ");
        } else if (isSerializedLowPriority(element)) {
            const symbol = {
                plus: "+",
                minus: "-",
                "plus-minus": "±",
            }[element.symbol];
            chars.push(symbol);
        } else if (isSerializedSymbol(element)) {
            const symbolChars: Partial<Record<TSymbolActionChar, string>> = {
                pi: "π",
                comma: ",",
                dot: ".",
                colon: ":",
                semicolon: ";",
                percent: "%",
                exhibitor: "e",
                factorial: "!",
                "empty-set": "∅",
                tilda: "~",
                dollar: "$",
                question: "?",
            };

            const char = symbolChars[element.symbol];

            if (typeof char === "undefined") {
                return null;
            }
            chars.push(char);
        } else {
            return null;
        }
    }
    return chars.join("");
}

export function tryCreateSerializedFromString(str: string): TSerializedState | null {
    const elements: TAnySerializedElement[] = [];
    for (const char of str) {
        const c = char.toUpperCase();
        if ((c >= "A" && c <= "Z") || (c >= "А" && c <= "Я") || c === "Ё") {
            elements.push(createSerializedChar(char));
        } else if (char >= "0" && char <= "9") {
            elements.push(createSerializedDigit(Number(char)));
        } else {
            switch (char) {
                case " ":
                    elements.push(createSerializedSpace());
                    break;
                case ".":
                    elements.push(createSerializedSymbol("dot"));
                    break;
                case ",":
                    elements.push(createSerializedSymbol("comma"));
                    break;
                case "=":
                    elements.push(createSerializedEquality("equals"));
                    break;
                case "(":
                    elements.push(createSerializedBracket("round", false));
                    break;
                case ")":
                    elements.push(createSerializedBracket("round", true));
                    break;
                case "[":
                    elements.push(createSerializedBracket("square", false));
                    break;
                case "]":
                    elements.push(createSerializedBracket("square", true));
                    break;
                case "+":
                    elements.push(createSerializedOperatorLow("plus"));
                    break;
                case "-":
                    elements.push(createSerializedOperatorLow("minus"));
                    break;
                case "*":
                    elements.push(createSerializedOperatorHigh("multiply"));
                    break;
                case "/":
                    elements.push(createSerializedOperatorHigh("divide"));
                    break;
                case "$":
                    elements.push(createSerializedSymbol("dollar"));
                    break;
                default:
                    return null;
            }
        }
    }
    return createSerializedContainer(elements);
}

/**
 * String to Math
 */
export function s2m([str]: TemplateStringsArray | [string]): TSerializedState {
    return tryCreateSerializedFromString(str) ?? createSerializedContainer();
}

export function keepState(data: TSerializedState): TSerializedState {
    return { elements: data.elements };
}

export function applyHighlights(formula: TSerializedState, newHighlights: THighlightLocation[]): TSerializedState {
    const { elements, highlights } = formula;
    return { elements, highlights: [...(highlights ?? []), ...newHighlights] };
}
