import { assert } from "@viuch/utils/debug";

import type { BaseElementModel } from "../core/element";
import type { IElementVisitor } from "../core/element/IElementVisitor";
import type { THighlightLocation, TSerializedState } from "../types";

import { sliceMathExpr } from "./edit";
import { tryConvertSimpleToString } from "./serialization";
import { hasFormulaExtensions } from "./validation";

export type TMathExpressionView = Array<
    | {
          type: "formula";
          formula: TSerializedState;
      }
    | {
          type: "text";
          text: string;
      }
    | {
          type: "linebreak";
      }
>;

export function splitToTextFragments(formula: TSerializedState): TMathExpressionView {
    const tokens = tokenize(formula);

    const terms = join(tokens);

    return build(terms);
}

/**
 * Пытается преобразовать токены к строкам, чтобы оптимизировать рендеринг.
 */
function build(tokens: TMathExpressionToken[]): TMathExpressionView {
    return tokens.map((t) => {
        if (t.tag === "linebreak") {
            return { type: "linebreak" };
        }

        if (hasFormulaExtensions(t.formula)) {
            return { type: "formula", formula: t.formula };
        }

        const text = tryConvertSimpleToString(t.formula);

        if (text !== null) {
            return { type: "text", text };
        }

        return { type: "formula", formula: t.formula };
    });
}

/**
 * Пытается объединить фрагменты, применяя паттерны и правила.
 */
function join(tokens: TMathExpressionToken[]): TMathExpressionToken[] {
    return tokens;
}

function* _getHighlightedIndexes(highlights?: THighlightLocation[]): Iterable<number> {
    if (!highlights) return;

    for (const { start, end, source } of highlights) {
        if (source === "$") {
            for (let i = start; i < end; i++) {
                yield i;
            }
        } else {
            const _resultStr = source.match(/[0-9]+/)?.at(0);
            assert(_resultStr);

            yield +_resultStr;
        }
    }
}

/**
 * Разбивает формулу на токены
 *
 * @param formula Математическое выражение
 */
function tokenize(formula: TSerializedState): TMathExpressionToken[] {
    const elements = formula.elements as TAnySerializedElement[];

    if (elements.length === 0) return [];

    const highlightedIndexes = new Set<number>(_getHighlightedIndexes(formula.highlights));

    function isHighlighted(i: number): boolean {
        return highlightedIndexes.has(i);
    }

    function getElementGroup(element: TAnySerializedElement, i: number): string {
        if (isHighlighted(i)) {
            return "highlighted";
        }

        switch (element.type) {
            case "space":
                return "space";

            case "linebreak":
                return "linebreak";

            case "char":
            case "symbol":
            case "affiliation":
            case "function":
            case "angle":
            case "degree":
            case "derivative":
            case "differential":
            case "digit":
            case "multitude":
            case "natural-log":
            case "result":
            case "slash":
            case "top-angle":
            case "trigonometric-operator":
            case "bracket":
            case "operator-high":
            case "operator-low":
            case "equality":
            case "geometry":
            case "grade":
            case "root":
            case "down-index":
            case "module":
            case "equations-set":
            case "fraction":
            case "complex-integral":
            case "coordinates-vector":
            case "vector":
            case "integral":
            case "lim":
            case "log":
                return "token";

            case "input":
                assert(false);
        }
    }

    type _TCurrentGroup = {
        tag: string;
        start: number;
        length: number;
    };

    let currentGroup: _TCurrentGroup = {
        tag: getElementGroup(elements[0], 0),
        start: 0,
        length: 1,
    };

    const list: TMathExpressionToken[] = [];

    for (let i = 1; i < elements.length; i++) {
        const element = elements[i];

        const tag = getElementGroup(element, i);

        if (tag === currentGroup.tag) {
            currentGroup.length++;
        } else {
            list.push({
                tag: currentGroup.tag,
                formula: sliceMathExpr(formula, currentGroup.start, currentGroup.length),
            });

            currentGroup = {
                tag,
                start: i,
                length: 1,
            };
        }
    }

    list.push({
        tag: currentGroup.tag,
        formula: sliceMathExpr(formula, currentGroup.start, currentGroup.length),
    });

    return list;
}

type TAnyMathModel = Parameters<IElementVisitor<any>[keyof IElementVisitor<any>]>[0];
type TAnySerializedElement = TAnyMathModel extends BaseElementModel<infer X> ? X : never;

type TMathExpressionToken<T extends string = string> = {
    formula: TSerializedState;
    tag: T;
};

type TTokenizerTags =
    | "space"
    | "break"
    | "formula"
    | "operator-left"
    | "operator-right"
    | "operator-both"
    | "symbol"
    | "word"
    | "number"
    | "text";
