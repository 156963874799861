import { equateNumbers } from "@viuch/geometry-lib/equate";

import type { TSerializedFigure2DSettings } from "../service-types/figure2d";
import type { TEllipse, TFragment, TPoint } from "@viuch/geometry-lib/types";

const FRAC_DIGITS = 5;

export function hashCoordinate(coordinate: number) {
    if (equateNumbers(coordinate, "==", 0)) {
        return (0).toFixed(FRAC_DIGITS);
    }
    return coordinate.toFixed(FRAC_DIGITS);
}

export function hashPoint(point: TPoint) {
    return `${hashCoordinate(point.x)}_${hashCoordinate(point.y)}`;
}

export function hashEllipse({ center, rx, ry }: TEllipse) {
    return `${hashPoint(center)}_${hashCoordinate(rx)}_${hashCoordinate(ry)}`;
}

export function hashFragment({ a, b }: TFragment): TSerializedFigure2DSettings.BaseSegment {
    return {
        a: hashPoint(a),
        b: hashPoint(b),
    };
}

export function hashVirtualFragment({ a, b }: TFragment): TSerializedFigure2DSettings.VirtualSegment {
    return {
        a: { x: a.x, y: a.y, id: hashPoint(a) },
        b: { x: b.x, y: b.y, id: hashPoint(b) },
    };
}
