import { createSerializedContainer } from "@viuch/math-editor";
import { createSerializedChar, isSerializedChar } from "@viuch/math-editor/elements/char/utils";
import { createSerializedDigit, isSerializedDigit } from "@viuch/math-editor/elements/digit/utils";
import { createSerializedDownIndex, isSerializedDownIndex } from "@viuch/math-editor/elements/down-index/utils";
import { assert } from "@viuch/utils/debug";

import type { Figure2D } from "../../entities/Figure2D";
import type { TSerializedState } from "@viuch/math-editor";
import type { TAnySerializedElement } from "@viuch/math-editor/types";

import { checkModelType, ModelTypes } from "../services/actions/utils";

export function getNextPointAutoLabel(figure: Figure2D): TSerializedState {
    const labels = new Set<string>();
    for (const model of figure.models) {
        if (checkModelType(model, ModelTypes.labelPoint)) {
            const label = trySerializePointLabel(model.label);
            if (label) {
                const value = label.toUpperCase();
                labels.add(value);
            }
        }
    }

    for (const i of generateNumbers()) {
        for (const char of generateChars()) {
            const label = i === 0 ? char : `${char}${i}`;

            if (!labels.has(label)) {
                return deserializePointLabel(char, i);
            }
        }
    }

    assert(false);
}

function trySerializePointLabel(value: TSerializedState): string | null {
    const elements: TAnySerializedElement[] = value.elements.slice();

    if (elements.length === 1 || elements.length === 2) {
        if (!isSerializedChar(elements[0])) return null;
        const char = elements[0].char.toUpperCase();

        if (elements.length !== 1) {
            if (!isSerializedDownIndex(elements[1])) return null;

            const chars = [char];
            const downIndexElements: TAnySerializedElement[] = elements[1].content.elements;

            for (const element of downIndexElements) {
                if (!isSerializedDigit(element)) return null;
                chars.push(`${element.digit}`);
            }
            return chars.join("");
        }
        return char;
    }
    return null;
}

function deserializePointLabel(char: string, index: number): TSerializedState {
    const elements: TAnySerializedElement[] = [createSerializedChar(char)];

    if (index > 0) {
        const downIndexElements: TAnySerializedElement[] = `${index}`.split("").map((n) => createSerializedDigit(+n));
        elements.push(createSerializedDownIndex(downIndexElements));
    }

    return createSerializedContainer(elements);
}

function* generateNumbers(): Iterable<number> {
    let i = 0;
    while (true) {
        yield i++;
    }
}

function* generateChars(): Iterable<string> {
    for (let charCode = "A".charCodeAt(0); charCode <= "Z".charCodeAt(0); charCode++) {
        yield String.fromCharCode(charCode);
    }
}
