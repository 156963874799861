import { action, autorun, computed, makeObservable } from "mobx";

import type { HighlightRange } from "./ranges/HighlightRange";
import type { InputService } from "../../services";
import type { THighlightLocation, THighlightStyle } from "../../types";
import type { BaseElementModel } from "../element";

import { HighlightsList } from "./HighlightsList";
import { HighlightRangesFactory } from "./ranges/HighlightRangesFactory";

export class HighlightingService {
    private readonly inputService: InputService;
    private readonly highlightsFactory: HighlightRangesFactory;

    readonly highlights: HighlightsList;

    constructor(inputService: InputService) {
        this.inputService = inputService;
        this.highlights = new HighlightsList();
        this.highlightsFactory = new HighlightRangesFactory(this.inputService);

        makeObservable(this);
    }

    @action.bound
    effect() {
        const disposeAutoRemove = autorun(() => {
            for (const highlight of [...this.highlights]) {
                if (!highlight.checkIsPresent()) {
                    this.highlights.remove(highlight);
                }
            }
        }, {});

        return () => {
            disposeAutoRemove();
        };
    }

    @action.bound
    setHighlights(highlights: THighlightLocation[]) {
        for (const data of highlights) {
            const highlight = this.highlightsFactory.createHighlightFromData(data);
            if (highlight) {
                this.highlights.add(highlight);
            } else {
                console.error("Highlight initialization failed, skipped", data);
            }
        }
    }

    tryGetHighlight(element: BaseElementModel): HighlightRange | null {
        for (const highlight of this.highlights) {
            if (highlight.elementUuids.includes(element.uuid)) {
                return highlight;
            }
        }
        return null;
    }

    @computed
    get highlightedElementUuidsWithStyle(): Record<string, THighlightStyle> {
        const highlightStyles: Record<string, THighlightStyle> = {};

        for (const highlight of this.highlights.all()) {
            for (const elementUuid of highlight.elementUuids) {
                highlightStyles[elementUuid] = highlight.style;
            }
        }

        return highlightStyles;
    }

    serializeHighlights(): THighlightLocation[] {
        const highlights: THighlightLocation[] = [];
        for (const highlight of this.highlights) {
            const data = this.highlightsFactory.createDataFromHighlight(highlight);
            if (data) {
                highlights.push(data);
            }
        }
        return highlights;
    }
}
