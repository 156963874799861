import type { ISerializedLowPriorityOperator, TLowPriorityOperatorsActionSign } from "./types";
import type { TSerializedElementPrototype } from "../../core/element";
import type { IElementVisitor } from "../../core/element/IElementVisitor";
import type { InputService } from "../../services";
import type { TElementDeserializerFunction } from "../../types";

import { BaseElementModel } from "../../core/element";

export class LowPriorityOperatorModel extends BaseElementModel<ISerializedLowPriorityOperator> {
    public readonly symbolName: TLowPriorityOperatorsActionSign;

    public constructor(inputService: InputService, symbolName: TLowPriorityOperatorsActionSign, uuid?: string) {
        super(inputService, uuid);
        this.symbolName = symbolName;
    }

    override isEquals(otherElement: BaseElementModel): boolean {
        return otherElement instanceof LowPriorityOperatorModel && this.symbolName === otherElement.symbolName;
    }

    public serialize(): ISerializedLowPriorityOperator {
        return {
            type: "operator-low",
            uuid: this.uuid,
            symbol: this.symbolName,
        };
    }

    public static deserialize: TElementDeserializerFunction<ISerializedLowPriorityOperator> = (
        { inputService },
        { uuid, symbol }
    ) => new LowPriorityOperatorModel(inputService, symbol, uuid);

    public accept<R>(visitor: IElementVisitor<R>): R {
        return visitor.doWithOperatorLow(this);
    }

    serializeAsClone(): TSerializedElementPrototype<ISerializedLowPriorityOperator> {
        const { uuid, ...clone } = this.serialize();
        return clone;
    }
}
