import type { ISelectionElementVisitor } from "../BaseSelection";
import type { TPoint } from "@viuch/geometry-lib/types";

import { BaseSelection } from "../BaseSelection";

export class PointSelection extends BaseSelection {
    readonly x: number;
    readonly y: number;

    constructor(x: number, y: number) {
        super();
        this.x = x;
        this.y = y;
    }

    accept<R>(visitor: ISelectionElementVisitor<R>): R {
        return visitor.withPoint(this);
    }

    toPoint(): TPoint {
        return { x: this.x, y: this.y };
    }
}
