import { action, makeObservable, observable } from "mobx";
import { createTransformer } from "mobx-utils";

import { areSamePoints } from "@viuch/geometry-lib/check-geometry";
import { getLineFromFragment } from "@viuch/geometry-lib/solvers";

import type { BaseElement } from "../../elements";
import type { Figure2DController } from "../../Figure2DController";
import type { ToolbarButton, ToolbarMenu } from "../../toolbar";
import type { IUserPointer } from "../../viewport/types";
import type { TPoint } from "@viuch/geometry-lib/types";
import type React from "react";

import { DotElement } from "../../elements/dot";
import { ElementColor } from "../../elements/ElementColor";
import { LineElement } from "../../elements/line";
import { LineModel } from "../../models/fragment";
import { LabelPointModel } from "../../models/label-point";
import { PointModel } from "../../models/point";
import { ButtonStates } from "../../toolbar";
import { TooltipMenu } from "../../toolbar/tooltip";
import { createDefaultToolbarMenuBuilder, handleToolbarButtons } from "../../utils/toolbar";
import { BaseFlow } from "../BaseFlow";
import { createMapModelToElements, opacifyColor, z } from "../utils";

export class CreateLineFlow extends BaseFlow {
    flow:
        | {
              stage: 1;
              a?: TPoint;
              drawing: boolean;
          }
        | {
              stage: 2;
              readonly a: TPoint;
              b?: TPoint;
              drawing: boolean;
          };

    private readonly isRepeated: boolean;

    private constructor(data: Figure2DController, isRepeated = false) {
        super(data);

        this.flow = { stage: 1, drawing: false };
        this.isRepeated = isRepeated;

        makeObservable(this, {
            flow: observable,
            saveRaw: action,
        });
    }

    static create(data: Figure2DController) {
        return new CreateLineFlow(data);
    }

    attach(): void {
        this.viewport.disable();
        this.snap.addViewportGrid().addFigureModelsPoints();
    }

    dispose(): void {
        this.snap.clean();
    }

    override getToolbarMenu(): ToolbarMenu {
        return createDefaultToolbarMenuBuilder()
            .setButtonIcon("line", ["fragments"])
            .setButtonState(ButtonStates.active, ["fragments"])
            .build();
    }

    override handleViewportPointerEvent(e: React.PointerEvent, pointer: IUserPointer): void {
        if (e.type === "pointercancel") return this.nextFlow();
        if (!e.isPrimary) return;

        const point = this.snap.snap(pointer.canvas, pointer.canvas);

        this.movePointer(e, point);
        this.startDrawing(e);
        this.endDrawing(e);
    }

    saveRaw(a: TPoint, b: TPoint) {
        this.figure.insertModels(function* () {
            yield LineModel.create({ a, b, style: null });

            yield PointModel.create({ ...a, style: null });
            yield PointModel.create({ ...b, style: null });

            yield LabelPointModel.createNext(a, this.figure);
            yield LabelPointModel.createNext(b, this.figure);
        }, this);
    }

    override handleToolbarButtonClick(menu: ToolbarMenu, button: ToolbarButton): void {
        switch (button.key) {
            case "fragments":
                return this.nextFlow();
            default:
                handleToolbarButtons(this, button);
        }
    }

    mapModels = createTransformer(createMapModelToElements(opacifyColor));

    protected renderElements(): BaseElement[] {
        return [...this.figure.models.flatMap(this.mapModels), ...this.getTempElements()];
    }

    *getTempElements(): Iterable<BaseElement> {
        const { stage, drawing } = this.flow;
        if (stage === 1) {
            const { a } = this.flow;
            if (a) {
                yield new DotElement({
                    overrideRenderOrder: 0,
                    id: "temp__a",
                    color: drawing ? ElementColor.Selected : ElementColor.Building,
                    x: a.x,
                    y: a.y,
                });
            }
            return;
        }

        const { a, b } = this.flow;

        yield new DotElement({
            id: "temp__a",
            color: ElementColor.Building,
            x: a.x,
            y: a.y,
            overrideRenderOrder: z.points.priority,
        });

        if (b && !areSamePoints(a, b)) {
            const {
                a: { x: x1, y: y1 },
                b: { x: x2, y: y2 },
            } = getLineFromFragment(a, b);

            yield new LineElement({
                id: "temp__line",
                x1,
                y1,
                x2,
                y2,
                color: ElementColor.Building,
                overrideRenderOrder: z.fragments.priority,
            });

            yield new DotElement({
                id: "temp__b",
                color: drawing ? ElementColor.Building : ElementColor.Selected,
                x: b.x,
                y: b.y,
                overrideRenderOrder: z.points.priority,
            });
        }
    }

    private movePointer(e: React.PointerEvent, point: TPoint) {
        if (e.type === "pointermove" || e.type === "pointerdown") {
            switch (this.flow.stage) {
                case 1:
                    this.flow.a = point;
                    break;
                case 2:
                    this.flow.b = point;
                    break;
            }
        }
    }

    private startDrawing(e: React.PointerEvent) {
        if (e.type === "pointerdown") {
            this.flow.drawing = true;
        }
    }

    private endDrawing(e: React.PointerEvent) {
        if (e.type === "pointerup") {
            this.flow.drawing = false;

            switch (this.flow.stage) {
                case 1:
                    if (this.flow.a) {
                        this.flow = {
                            stage: 2,
                            drawing: false,
                            a: this.flow.a,
                        };
                    }
                    break;
                case 2: {
                    const { a, b } = this.flow;
                    if (b && !areSamePoints(a, b)) {
                        this.saveRaw(a, b);
                        this.nextFlow((d) => new CreateLineFlow(d, true));
                    } else {
                        this.nextFlow();
                    }
                    break;
                }
            }
        }
    }

    override getTooltipMenu(): TooltipMenu | null {
        let text: string;

        if (this.isRepeated) {
            text = "Продолжайте построение или нажмите на кнопку курсора, чтобы закончить построение";
        } else {
            switch (this.flow.stage) {
                case 1:
                    text = "Поставьте первую точку прямой";
                    break;
                case 2:
                    text = "Поставьте вторую точку прямой";
                    break;
            }
        }

        return new TooltipMenu(text, []);
    }
}
