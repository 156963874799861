import cn from "classnames";

import type { CoordinatesVectorModel } from "./CoordinatesVectorModel";
import type { TElementProps } from "../../types/element";
import type { VFC } from "react";

import { ContainerElement } from "../../core/container";
import { useMathEditorElementRef } from "../../hooks";

import styles from "./CoordinatesVectorElement.module.scss";

export const CoordinatesVectorElement: VFC<TElementProps<CoordinatesVectorModel>> = ({ elementModel }) => {
    const ref = useMathEditorElementRef(elementModel);

    return (
        <span
            ref={ref}
            className={cn(styles.inline, styles.wrapper)}
        >
            <span className={styles.inline}>(</span>
            <ContainerElement
                containerModel={elementModel.firstCoordinate}
                inline
            />
            <span className={styles.inline}>;&nbsp;</span>
            <ContainerElement
                containerModel={elementModel.secondCoordinate}
                inline
            />
            <span className={styles.inline}>)</span>
        </span>
    );
};
