import { action, autorun, makeObservable, observable } from "mobx";

import type { InputService } from "../../../services";
import type { THighlightStyle } from "../../../types";
import type { IReactionDisposer } from "mobx";

import { HighlightRange } from "./HighlightRange";

export type TCrossOutHighlightRangeCoords = { top: number; bottom: number; left: number; right: number } | null;

export class CrossOutHighlightRange extends HighlightRange {
    @observable crossOutCoords: TCrossOutHighlightRangeCoords = null;

    constructor(
        payload: Record<string, unknown>,
        style: THighlightStyle,
        containerUuid: string,
        elementUuids: string[],
        inputService: InputService
    ) {
        super(payload, style, containerUuid, elementUuids, inputService);

        makeObservable(this);
    }

    override effect(): IReactionDisposer {
        const dispose = autorun(() => {
            const coords = this.calculateCrossOutCoords();
            this.setCrossOutCoords(coords);
        });

        return dispose;
    }

    @action.bound
    setCrossOutCoords(coords: TCrossOutHighlightRangeCoords) {
        if (!coords) return;

        this.crossOutCoords = coords;
    }

    private calculateCrossOutCoords(): TCrossOutHighlightRangeCoords {
        const container = this.containersService.tryGetById(this.containerUuid);
        if (!container || !container.domElement) return null;

        const crossOutContainerCoords = container.domElement.getBoundingClientRect();

        const calculatedCoords = {
            left: +Infinity,
            right: -Infinity,
            top: +Infinity,
            bottom: -Infinity,
        };

        for (const uuid of this.elementUuids) {
            const element = this.elementsService.tryGetById(uuid);
            if (!element || !element.domElement) return null;

            const { left, right, top, bottom } = element.domElement.getBoundingClientRect();

            const relativeCrossOutCoords = {
                left: left - crossOutContainerCoords.x,
                right: right - crossOutContainerCoords.x,
                top: top - crossOutContainerCoords.y,
                bottom: bottom - crossOutContainerCoords.y,
            };

            if (relativeCrossOutCoords.left < calculatedCoords.left) {
                calculatedCoords.left = relativeCrossOutCoords.left;
            }

            if (relativeCrossOutCoords.right > calculatedCoords.right) {
                calculatedCoords.right = relativeCrossOutCoords.right;
            }

            if (relativeCrossOutCoords.top < calculatedCoords.top) {
                calculatedCoords.top = relativeCrossOutCoords.top;
            }

            if (relativeCrossOutCoords.bottom > calculatedCoords.bottom) {
                calculatedCoords.bottom = relativeCrossOutCoords.bottom;
            }
        }

        return calculatedCoords;
    }
}
