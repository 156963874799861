import { ReactComponent as TrashBinIcon } from "@viuch/assets/icons/trash-bin.svg";

import type { VFC } from "react";

import styles from "./TrashBinButton.module.scss";

type TRemoveIconsProps = {
    onClick(): void;
};

export const TrashBinButton: VFC<TRemoveIconsProps> = ({ onClick }) => (
    <button
        className={styles.iconButton}
        onClick={onClick}
    >
        <TrashBinIcon />
    </button>
);
