import { observer } from "mobx-react-lite";
import React from "react";

import type { DegreeModel } from "./DegreeModel";
import type { TElementProps } from "../../types/element";

import { ContainerElement } from "../../core/container";
import { Baseline } from "../../core/element";
import { useMathEditorElementRef } from "../../hooks";

import styles from "./DegreeElement.module.scss";

export const DegreeElement: React.VFC<TElementProps<DegreeModel>> = observer(function DegreeElement({ elementModel }) {
    const ref = useMathEditorElementRef<HTMLSpanElement>(elementModel);
    const baselineRef = React.useRef<HTMLSpanElement>(null);
    const contentRef = React.useRef<HTMLSpanElement>(null);

    React.useEffect(() => {
        const baselineElement = baselineRef.current!;
        const contentElement = contentRef.current!;
        const elementBefore = elementModel.domElementBeforeDegree;

        if (!elementBefore) {
            return;
        }

        function applyMargin(element: Element): void {
            const baselineRect = baselineElement.getBoundingClientRect();
            const beforeRect = element.getBoundingClientRect();

            const height = baselineRect.height + baselineRect.y - beforeRect.y;
            contentElement.style.marginBottom = `calc(${height}px - 0.3em)`;
        }

        const observer = new ResizeObserver(() => {
            applyMargin(elementBefore);
        });

        observer.observe(elementBefore);
        applyMargin(elementBefore);

        return () => {
            observer.disconnect();
        };
    }, [elementModel.domElementBeforeDegree]);

    return (
        <span
            ref={ref}
            className={styles.wrapper}
        >
            <span className={styles.degree}>
                <Baseline
                    ref={baselineRef}
                    className={styles.baseline}
                />
                <span
                    ref={contentRef}
                    className={styles.content}
                >
                    <ContainerElement
                        containerModel={elementModel.content}
                        className={styles.container}
                    />
                </span>
            </span>
        </span>
    );
});
