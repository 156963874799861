import { action, makeObservable, observable } from "mobx";

import { compareSimple, generateId } from "@viuch/shared/utils/data";

export class Video implements Video.Init {
    readonly id: number;
    @observable url: string;
    @observable name: string;

    constructor({ id, name, url }: Video.Init = Video.createDefaultInit()) {
        this.id = id;
        this.url = url;
        this.name = name;

        makeObservable(this);
    }

    @action.bound
    setName(name: string): void {
        this.name = name;
    }

    @action.bound
    setUrl(url: string): void {
        this.url = url;
    }

    clone() {
        return new Video(this);
    }

    equalsTo(other: Video.Init): boolean {
        switch (false) {
            case compareSimple(this.url, other.url):
            case compareSimple(this.name, other.name):
                return false;
        }
        return true;
    }
}

export namespace Video {
    export type Init = {
        id: number;
        url: string;
        name: string;
    };

    export const createDefaultInit = (override?: Partial<Init>): Init => ({
        id: generateId(),
        name: "",
        url: "",
        ...override,
    });
}
