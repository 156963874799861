import { createSerializedContainer } from "@viuch/math-editor";
import { createSerializedState } from "@viuch/math-editor/utils/serialization";

import type { TTaskDraft } from "./types";
import type { TSerializedState } from "@viuch/math-editor";

import { Answer } from "./Answer";
import { VariableGenerationTypes } from "./constants";
import { PrimaryHints } from "./PrimaryHints";
import { SolverVariableContent } from "./variables";

export const createDefaultTaskFields = (themeId: number): TTaskDraft => ({
    variables: [],
    answers: [new Answer("Ответ", "o", VariableGenerationTypes.solver, SolverVariableContent.createEmpty())],
    formula: createSerializedContainer(),
    theme: themeId,
    instruments: [],
    problemIds: [],
    primaryHints: null,
    variantGenerationLimit: 300,
    overrideTaskText: createSerializedState(),
    isVerified: false,
});

export function mapPrimaryHints(data: TSerializedState[]): PrimaryHints {
    return new PrimaryHints(data);
}

export function serializePrimaryHints(hints: PrimaryHints): TSerializedState[] {
    return hints.hints.slice();
}
