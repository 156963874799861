import { action, makeObservable, observable } from "mobx";

import { compareArrays, compareSimple } from "@viuch/shared/utils/data";

export interface IImageMaterialsThumbnails {
    tiny: string;
    small: string;
    medium: string;
    large: string;
    tinyOriginal: string;
    smallOriginal: string;
    mediumOriginal: string;
    largeOriginal: string;
}

export class ImageMaterial {
    readonly id: number;
    @observable.ref name: string;
    readonly imageUrl: string | null;
    readonly thumbnails: IImageMaterialsThumbnails | null;
    @observable.ref imageFile: File | null;
    @observable.shallow themeIds: number[];

    constructor(
        id: number,
        name: string,
        imageUrl: string | null,
        thumbnails: IImageMaterialsThumbnails | null,
        themeIds: number[]
    ) {
        this.themeIds = themeIds;
        this.id = id;
        this.name = name;
        this.imageUrl = imageUrl;
        this.thumbnails = thumbnails;
        this.imageFile = null;

        makeObservable(this);
    }

    @action.bound
    setName(name: string) {
        this.name = name;
    }

    @action.bound
    setImageFile(file: File | null) {
        this.imageFile = file;
    }

    @action.bound
    addThemeId(themeId: number) {
        if (!this.themeIds.includes(themeId)) {
            this.themeIds.push(themeId);
        }
    }

    @action.bound
    removeThemeId(themeId: number) {
        this.themeIds.remove(themeId);
    }

    static createEmpty() {
        return new ImageMaterial(0, "", null, null, []);
    }

    clone(): ImageMaterial {
        const { id, name, imageUrl, thumbnails, themeIds } = this;
        return new ImageMaterial(id, name, imageUrl, thumbnails, themeIds);
    }

    equalsTo(other: ImageMaterial, compareId: boolean): boolean {
        switch (false) {
            case compareId ? compareSimple(this.id, other.id) : true:
            case compareSimple(this.name, other.name):
            case compareSimple(this.imageFile, other.imageFile):
            case compareSimple(this.imageUrl, other.imageUrl):
            case compareSimple(this.thumbnails, other.thumbnails):
            case compareArrays(this.themeIds, other.themeIds):
                return false;
        }

        return true;
    }
}
