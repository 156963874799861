import cn from "classnames";
import { observer } from "mobx-react-lite";
import React from "react";

import type { PropsWithChildren } from "react";

import styles from "./EditorFrame.module.scss";

type Props = PropsWithChildren<{
    className?: string;
    outerClassName?: string;
    innerClassName?: string;
    disableShadows?: boolean;
    everyClassName?: string;
}>;

export const EditorFrame = observer(function EditorFrame({
    className,
    children,
    innerClassName,
    outerClassName,
    disableShadows,
    everyClassName,
}: Props) {
    return (
        <div className={cn(styles.wrapper, everyClassName, className, disableShadows && styles.disableShadows)}>
            <div className={cn(styles.outer, everyClassName, outerClassName)}>
                <div className={cn(styles.inner, everyClassName, innerClassName)}>{children}</div>
            </div>
        </div>
    );
});
