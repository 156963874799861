import type { ThemeAttentionFilters } from "../../entities/attention-filters/ThemeAttentionFilters";
import type { GetError } from "../base/RequestError";
import type { TRawTaskGenerationProgress, TTaskGenerationProgress } from "../tasks";
import type { TSerializedState } from "@viuch/math-editor";

import { agent } from "../../agent";
import { Theme } from "../../entities/hierarchy";
import { IndexingEntityMetadataOnly } from "../../entities/indexing/IndexingEntityMetadataOnly";
import { mapPrimaryHints, serializePrimaryHints } from "../../entities/tasks/utils";
import { BaseCRUDService } from "../base";

import { mapThemeGenerationProgress, mapThemeMinimal } from "./themes.serializers";

export class ThemesService extends BaseCRUDService<Theme, TThemeRequest, TThemeResponse> {
    constructor() {
        super("/themes_new/", mapTheme, serializeTheme);
    }

    async getBySubsectionId(...subsectionIds: number[]): Promise<Theme[]> {
        const { data } = await agent.get<TThemeResponse[]>(`/themes_new/`, {
            params: { subsections_in: subsectionIds.join() },
        });

        return data.map(mapTheme);
    }

    async getBySubjectId(...subjectIds: number[]): Promise<Theme[]> {
        const { data } = await agent.get<TThemeResponse[]>(`/themes_new/`, {
            params: { subjects_in: subjectIds.join() },
        });

        return data.map(mapTheme);
    }

    async searchAttention(filters: ThemeAttentionFilters, signal?: AbortSignal): Promise<TThemeMinimal[]> {
        const params = serializeThemeFilters(filters);
        params.set("view", "id,name");

        const { data } = await agent.get<TThemeMinimal[]>("/themes_new/", { params, signal });

        return data;
    }

    async allThemesShort(signal?: AbortSignal): Promise<TThemeMinimal[]> {
        const params = { view: "short" };

        const { data } = await agent.get<TThemeMinimal[]>("/themes_new/", { params, signal });

        return data.map(mapThemeMinimal);
    }

    async tree(spans: ThemesService.TSpan[], signal?: AbortSignal): Promise<ThemesService.TThemeGraphNode[]> {
        const request = {
            spans: spans.map((span) => ({
                theme: span.themeId,
                max_distance: span.depth,
            })),
        };
        const { data } = await agent.post<ThemesService.TThemeGraphNode[]>("/themes/graph/", request, {
            signal,
        });

        return data;
    }

    async updatePartial(themeId: number, request: Partial<TThemeRequest>): Promise<void> {
        await agent.patch(`/themes_new/${themeId}/`, request);
    }

    async cloneTheme(themeId: number): Promise<number> {
        const { data } = await agent.post<TThemeResponse>(`/themes_new/${themeId}/copy/`);

        return data.id;
    }

    async getGenerationProgress(themeId: number): Promise<TThemeGenerationProgress> {
        const { data } = await agent.get<TRawThemeGenerationProgress>(`/themes_new/${themeId}/generation_progress/`);

        return mapThemeGenerationProgress(data);
    }
}

export type TThemeRequest = {
    id: number;
    name: string;
    related_themes: number[];
    dependent_themes: number[];
    task_quantity: number;
    is_verified: boolean;
    base_task_formula: TSerializedState;
    video: number | null;
    subsections: number[];
    is_hidden: boolean;
    synopsis: number | null;
    variant_examples: number[];
    grades: number[];
    slug: string;
    meta_title?: string | null;
    meta_description?: string | null;
    primary_hints: TSerializedState[] | null;
    renderer_config: object | null;
    geometry_theorems: number[];
    image_materials: number[];
    unverified_task_count?: number;
};

export type TThemeResponse = TThemeRequest & {
    video_count: number;
    synopsis_count: number;
    task_count: number;
    problem_count: number;
    inherited_geometry_theorems: [];
    unverified_task_count: number;
};

export type TThemeError = GetError<TThemeRequest>;

export const mapTheme = ({
    synopsis,
    video,
    meta_title,
    meta_description,
    primary_hints,
    renderer_config,
    image_materials,
    ...data
}: TThemeResponse): Theme => {
    return new Theme({
        ...data,
        synopsis: synopsis ?? void 0,
        video: video ?? void 0,
        indexing: new IndexingEntityMetadataOnly(meta_title ?? null, meta_description ?? null),
        primaryHints: primary_hints ? mapPrimaryHints(primary_hints) : null,
        renderer_config,
        imageMaterialIds: image_materials,
    });
};

export const serializeTheme = ({
    id,
    name,
    related_themes,
    dependent_themes,
    task_quantity,
    is_verified,
    base_task_formula,
    video,
    synopsis,
    subsections,
    is_hidden,
    variant_examples,
    grades,
    slug,
    indexing,
    primaryHints,
    rendererConfigForm,
    geometry_theorems,
    imageMaterialIds,
    unverified_task_count,
}: Theme): TThemeRequest => ({
    id,
    name,
    related_themes,
    dependent_themes,
    task_quantity,
    is_verified,
    base_task_formula,
    video: video ?? null,
    synopsis: synopsis ?? null,
    subsections,
    is_hidden,
    variant_examples,
    grades,
    slug,
    meta_description: indexing.description,
    meta_title: indexing.title,
    primary_hints: primaryHints ? serializePrimaryHints(primaryHints) : null,
    renderer_config: rendererConfigForm.getSerializedState(),
    geometry_theorems,
    image_materials: imageMaterialIds,
    unverified_task_count,
});

export const serializeThemeFilters = ({
    is_detached,
    is_hidden,
    is_verified,
    task_variant_count__lt,
    task_count__lt,
    no_related_themes,
    no_dependent_themes,
    no_synopsis,
    no_video,
    no_grade,
    search,
    grades_in,
}: ThemeAttentionFilters): URLSearchParams => {
    const data: Record<string, string> = {};

    search.length && (data.search = search);
    is_detached && (data.is_detached = "true");
    is_hidden && (data.is_hidden = "true");
    !is_verified && (data.is_verified = "false");
    no_related_themes && (data.no_related_themes = "true");
    no_dependent_themes && (data.no_dependent_themes = "true");
    task_count__lt && (data.task_count__lt = String(task_count__lt));
    task_variant_count__lt && (data.task_variant_count__lt = String(task_variant_count__lt));
    no_synopsis && (data.no_synopsis = "true");
    no_video && (data.no_video = "true");
    no_grade && (data.grade_is_null = "true");
    !no_grade && grades_in.length && (data.grades_in = grades_in.join(","));

    return new URLSearchParams(data);
};

export type TThemeMinimal = {
    id: number;
    name: string;
};

export namespace ThemesService {
    export type TSpan = { themeId: number; depth: number };

    export type ThemeId = number & { readonly _type?: unique symbol };

    export type TThemeGraphNode = {
        id: ThemeId;
        name: string;
        related_themes: ThemeId[];
        dependent_themes: ThemeId[];
        subsections: number[];
        geometry_theorems: number[];
        text_before_tasks: string;
        video: null | number;
        synopsis: null | number;
        is_hidden: boolean;
        is_verified: boolean;
        tags: string[];
        graph_tags: TThemeGraphTags[];
    };

    type TThemeGraphTags = "verified" | "broken" | "incomplete";
}

export type TRawThemeGenerationProgress = {
    is_in_progress: boolean;
    tasks: TRawTaskGenerationProgress[];
};

export type TThemeGenerationProgress = {
    isInProgress: boolean;
    generated: number;
    estimate: number;
    tasks: Map<number, TTaskGenerationProgress>;
};
