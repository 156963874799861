import type { TSelectControl } from "@viuch/form-builder/types/controls";

import { solverNames } from "./solverNames";

export const selectSolverTypeControl: TSelectControl = {
    path: "./solver_type",
    control: "Select",
    label: "Тип решателя",
    initialValue: "simplify",
    data: {
        type: "static-grouped-items",
        groups: [
            {
                label: "Упрощение",
                items: m(
                    "simplify",
                    "simplify_log",
                    "simplify_exponential",
                    "simplify_radical",
                    "simplify_rational",
                    "simplify_trigonometry",
                    "simplify_and_substitute",
                    "fraction_conversion",
                    "rewrite_with_conditions",
                    "trigonometry_with_conditions"
                ),
            },
            {
                label: "Преобразование",
                items: m(
                    "expand",
                    "factor",
                    "rationalize",
                    "simplify_root",
                    "reverse_simplify_root",
                    "add_common_terms",
                    "mul_common_terms",
                    "numeric_nested_root",
                    "polynomial_nested_root",
                    "beautify",
                    "to_polynomial",
                    "polynom_divide"
                ),
            },
            {
                label: "Другое",
                items: m(
                    "evaluate",
                    "smart_evaluate",
                    "evaluate_precision",
                    "derivative",
                    "find_primes",
                    "compare_numbers",
                    "number_to_words",
                    "divide_with_remainder",
                    "limit",
                    "inequality",
                    "hide_operators",
                    "prove-identity",
                    "prove_inequality",
                    "substitute_prove_identity",
                    "evaluate_interval",
                    "range_of_expr",
                    "common_denominator",
                    "estimate_signs"
                ),
            },
            {
                label: "Система уравнений",
                items: m(
                    "univariative_system",
                    "linear_integer_system",
                    "multivariative_system",
                    "multivariative_system_replacement"
                ),
            },
            {
                label: "Геометрия",
                items: m("geometry", "geometry-dims", "figure_completion", "geometry_proof"),
            },
            {
                label: "Интеграл",
                items: m("indefinite_integral", "definitive_integral"),
            },
            {
                label: "Размерности",
                items: m("evaluate_dims", "equation_dims", "compare_dims"),
            },
            {
                label: "Уравнения",
                items: m(
                    "equation_any",
                    "variable_separation_equation",
                    "equation_linear",
                    "equation_quadratic",
                    "equation_cubic",
                    "equation_viet",
                    "elementary_equation",
                    "parametric-equation-per-root",
                    "parametric-equation-per-count",
                    "parametric-equation-count"
                ),
            },
            {
                label: "Экстремумы",
                items: m("min_value", "max_value", "min_point", "max_point", "min_max_local", "min_max_special"),
            },
            {
                label: "НОД и НОК",
                items: m("gcd", "lcm", "factor_integer"),
            },
        ],
    },
};

function m(...items: string[]) {
    return items.map((value) => ({
        value,
        label: solverNames[value] ?? value,
    }));
}
