import { action, makeObservable, observable } from "mobx";

import type { TSerializedState } from "@viuch/math-editor";

import {
    BaseGraph2DInstrumentSettingsGraph,
    type IGraph2DInstrumentSettingsGraphVisitor,
} from "./BaseGraph2DInstrumentSettingsGraph";

export class EvalGraphSettings extends BaseGraph2DInstrumentSettingsGraph {
    @observable.ref func: TSerializedState;
    @observable.ref sign: TGraph2DSettingsIntervalSign;

    constructor(color: string, func: TSerializedState, sign: TGraph2DSettingsIntervalSign) {
        super(color);
        this.func = func;
        this.color = color;
        this.sign = sign;

        makeObservable(this);
    }

    @action.bound
    setFunc(value: TSerializedState) {
        this.func = value;
    }

    @action.bound
    setSign(sign: TGraph2DSettingsIntervalSign) {
        this.sign = sign;
    }

    clone() {
        const { color, func, sign } = this;
        return new EvalGraphSettings(color, func, sign);
    }
    override accept = <T, A extends any[]>(visitor: IGraph2DInstrumentSettingsGraphVisitor<T, A>, ...args: A): T =>
        visitor.withEvalGraph(this, ...args);
}

export type TGraph2DSettingsIntervalSign = "equals" | "less" | "greater" | "less-equals" | "greater-equals";
