import type { TRawImageMaterial, TRawImageMaterialRequest } from "./ImageMaterialsService.types";

import { ImageMaterial } from "../../entities/image-material/ImageMaterial";

export function mapImageMaterial(data: TRawImageMaterial): ImageMaterial {
    const { id, image, name, thumbnails, themes } = data;

    return new ImageMaterial(
        id,
        name,
        image,
        {
            large: thumbnails.large,
            largeOriginal: thumbnails.large_original,
            medium: thumbnails.medium,
            mediumOriginal: thumbnails.medium_original,
            small: thumbnails.small,
            smallOriginal: thumbnails.small_original,
            tiny: thumbnails.tiny,
            tinyOriginal: thumbnails.tiny_original,
        },
        themes
    );
}

export function serializeImageMaterialCreate(item: ImageMaterial): FormData {
    const { id, name, imageFile, themeIds } = item;

    const data = new FormData();

    data.append("id", `${id}`);
    data.append("name", name);
    if (imageFile) {
        data.append("image", imageFile);
    }
    for (const themeId of themeIds) {
        data.append("themes", `${themeId}`);
    }

    return data;
}

export function serializeImageMaterialUpdate(item: ImageMaterial):
    | { method: "patch"; data: TRawImageMaterialRequest } //
    | { method: "put"; data: FormData } {
    const { id, name, imageFile, themeIds: themes } = item;

    if (imageFile) {
        return { method: "put", data: serializeImageMaterialCreate(item) };
    }

    return {
        method: "patch",
        data: {
            id,
            name,
            themes,
        },
    };
}
