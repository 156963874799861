import { action, makeObservable, observable } from "mobx";

import type { Graph2DPreRenderingService } from "./Graph2DPreRenderingService";
import type { Graph2DViewportController } from "./Graph2DViewportController";
import type { Graph2DViewSettings } from "./Graph2DViewSettings";
import type { Graph2DModel } from "../../core/Graph2DModel";
import type { BaseGraphFlow } from "../flows/BaseGraphFlow";

import { EditorFlow } from "../flows/view/EditorFlow";
import { ViewElementsFlow } from "../flows/view/ViewElementsFlow";

export class Graph2DFlowsController {
    private readonly viewport: Graph2DViewportController;
    private readonly settings: Graph2DViewSettings;
    private readonly model: Graph2DModel;
    private readonly renderTransformer: Graph2DPreRenderingService;
    readonly isReadonly: boolean;

    @observable.ref flow: BaseGraphFlow;

    constructor(
        model: Graph2DModel,
        settings: Graph2DViewSettings,
        viewport: Graph2DViewportController,
        renderTransformer: Graph2DPreRenderingService,
        isReadonly: boolean
    ) {
        this.isReadonly = isReadonly;
        this.settings = settings;
        this.model = model;
        this.viewport = viewport;
        this.renderTransformer = renderTransformer;

        this.flow = this.configureFlow(this.getDefaultFlow());

        makeObservable(this);
    }

    next(): void;
    next(flow: BaseGraphFlow): void;
    next(callback: TCreateFlowCallback): void;

    @action.bound
    next(callbackOrFlow?: BaseGraphFlow | TCreateFlowCallback): void {
        const nextFlow: BaseGraphFlow =
            typeof callbackOrFlow === "undefined"
                ? this.getDefaultFlow()
                : typeof callbackOrFlow === "function"
                ? callbackOrFlow({ flows: this, viewport: this.viewport })
                : callbackOrFlow;

        this.flow = this.configureFlow(nextFlow);
    }

    private readonly _flowExtensions: Set<TFlowExtension> = new Set();

    registerFlowExtension(extension: TFlowExtension): this {
        this._flowExtensions.add(extension);
        return this;
    }

    private getDefaultFlow(): BaseGraphFlow {
        const { model, viewport, renderTransformer } = this;

        return this.isReadonly
            ? new ViewElementsFlow(model, viewport, renderTransformer)
            : new EditorFlow(model, viewport, renderTransformer);
    }

    private configureFlow(flow: BaseGraphFlow): typeof flow {
        for (const applyExtensions of this._flowExtensions) {
            applyExtensions(flow);
        }
        return flow;
    }
}

export type TCreateFlowCallback = (params: TCreateFlowParams) => BaseGraphFlow;

export type TFlowExtension = (flow: BaseGraphFlow) => void;

export type TCreateFlowParams = {
    viewport: Graph2DViewportController;
    flows: Graph2DFlowsController;
};
