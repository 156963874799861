import type { TAnyAction } from "../types";

export type TCharAction = {
    type: "char";
    char: string;
};

export function createCharAction(char: string): TCharAction {
    return { type: "char", char };
}

export function isCharAction(action: TAnyAction): action is TCharAction {
    return action.type === "char";
}
