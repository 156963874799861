import type { ISerializedSlash } from "./types";
import type { TSerializedElementPrototype } from "../../core/element";
import type { IElementVisitor } from "../../core/element/IElementVisitor";
import type { InputService } from "../../services";
import type { TElementDeserializerFunction } from "../../types";

import { BaseElementModel } from "../../core/element";

export class SlashModel extends BaseElementModel<ISerializedSlash> {
    public constructor(inputService: InputService, uuid?: string) {
        super(inputService, uuid);
    }

    public serialize(): ISerializedSlash {
        return {
            type: "slash",
            uuid: this.uuid,
        };
    }

    public static deserialize: TElementDeserializerFunction<ISerializedSlash> = ({ inputService }, { uuid }) =>
        new SlashModel(inputService, uuid);

    public accept<R>(visitor: IElementVisitor<R>): R {
        return visitor.doWithSlash(this);
    }

    serializeAsClone(): TSerializedElementPrototype<ISerializedSlash> {
        const { uuid, ...clone } = this.serialize();
        return clone;
    }
}
