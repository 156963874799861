import type { TTableViewSettings } from "../entities/types";

export const defaultTableViewSettings: TTableViewSettings = {
    tableCenter: false,
    equalColumns: false,
    shrinkFirstColumn: false,
    centerCells: false,
    centerFirstRow: false,
    centerFirstColumn: false,
    wrapTable: false,
    wrapFirstRow: false,
    wrapFirstColumn: false,
    highlightFirstRow: false,
    highlightFirstColumn: false,
    boldFirstRow: false,
    boldFirstColumn: false,
};
