import { makeObservable, observable } from "mobx";

export class DrawingGenerationViewport {
    @observable min_x: number;
    @observable max_x: number;
    @observable min_y: number;
    @observable max_y: number;

    constructor(init: DrawingGenerationViewport.Init) {
        this.min_x = init.min_x;
        this.max_x = init.max_x;
        this.min_y = init.min_y;
        this.max_y = init.max_y;

        makeObservable(this);
    }

    static createEmpty() {
        return new DrawingGenerationViewport(DrawingGenerationViewport.defaults);
    }

    clone(): DrawingGenerationViewport {
        return new DrawingGenerationViewport(this);
    }
}

export namespace DrawingGenerationViewport {
    export type Init = {
        min_x: number;
        max_x: number;
        min_y: number;
        max_y: number;
    };

    export const defaults: Init = {
        min_x: 0,
        max_x: 1,
        min_y: 0,
        max_y: 1,
    };
}
