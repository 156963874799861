import type { IRoundBracketConfig } from "../types";

export function createDefaultRoundBracketConfig(height: number): IRoundBracketConfig {
    if (height <= 32) {
        return {
            width: 5,
            height,
            offsetTop: 2,
            offsetBottom: 1,
            x: 4,
            xControl: 2.5 + height * 0.05,
            strokeWidth: 1.6,
        };
    }

    if (height <= 50) {
        return {
            width: 7.5,
            height,
            offsetTop: 2,
            offsetBottom: 1,
            x: 6,
            xControl: 5.5 + height * 0.06,
            strokeWidth: 1.7,
        };
    }

    return {
        width: 9 + height * 0.04,
        height,
        offsetBottom: 2,
        offsetTop: 3,
        x: 6 + height * 0.04,
        xControl: 9 + height * 0.05,
        strokeWidth: 2.5,
    };
}
