import { makeObservable, observable } from "mobx";
import { createTransformer } from "mobx-utils";

import { areSamePoints } from "@viuch/geometry-lib/check-geometry";

import type { TAvailableAltitude } from "./utils";
import type { BaseElement } from "../../elements";
import type { Figure2DController } from "../../Figure2DController";
import type { ToolbarMenu } from "../../toolbar";
import type { IUserPointer } from "../../viewport/types";
import type { TPoint } from "@viuch/geometry-lib/types";
import type React from "react";

import { AngleElement } from "../../elements/angle";
import { DotElement } from "../../elements/dot";
import { ElementColor } from "../../elements/ElementColor";
import { LineElement } from "../../elements/line";
import { FragmentModel } from "../../models/fragment";
import { AltitudeModel } from "../../models/geometry";
import { LabelPointModel } from "../../models/label-point";
import { PointModel } from "../../models/point";
import { ButtonStates } from "../../toolbar";
import { TooltipMenu } from "../../toolbar/tooltip";
import { createDefaultToolbarMenuBuilder } from "../../utils/toolbar";
import { BaseFlow } from "../BaseFlow";
import { createMapModelToElements, opacifyColor } from "../utils";

import { getAltitudeAngles, getAvailableAltitudes } from "./utils";

export class InteractiveAltitudeFlow extends BaseFlow {
    flow:
        | {
              stage: 1;
              vertex?: TPoint;
              drawing: boolean;
          }
        | {
              stage: 2;
              vertex: TPoint;
              readonly availableAltitudes: TAvailableAltitude[];
              target?: TPoint;
              drawing: boolean;
          };

    constructor(data: Figure2DController) {
        super(data);

        this.flow = { stage: 1, drawing: false };

        makeObservable(this, {
            flow: observable,
        });
    }

    attach(): void {
        this.snap.clean().addFigureModelsPoints();
        this.viewport.disable();
    }

    dispose(): void {
        this.snap.clean();
    }

    override handleViewportPointerEvent(e: React.PointerEvent, pointer: IUserPointer): void {
        if (e.type === "pointercancel") return this.nextFlow();
        if (!e.isPrimary) return;

        const point = this.snap.snap(pointer.canvas);

        this.movePointer(e, point);
        this.startDrawing(e);
        this.endDrawing(e);
    }

    private checkAndSave() {
        if (this.flow.stage !== 2 || !this.flow.target) return;
        const { availableAltitudes, target } = this.flow;
        const altitude = this.findAltitude(availableAltitudes, target);

        if (altitude) {
            this.internalSave(altitude);
        }
    }

    private internalSave(altitude: TAvailableAltitude) {
        const { vertex, basis } = altitude;

        this.figure.insertModels(function* () {
            const model = AltitudeModel.create({ ...altitude, style: null });
            yield model;

            yield LabelPointModel.createNext(model.projectionPoint, this.figure);

            yield FragmentModel.create({ ...basis.fragment, style: null });
            for (const point of [basis.fragment.a, basis.fragment.b, vertex]) {
                yield PointModel.create({ ...point, style: null });
                yield LabelPointModel.createNext(point, this.figure);
            }
        }, this);
    }

    private findAltitude(availableAltitudes: TAvailableAltitude[], target: TPoint) {
        return availableAltitudes.find(({ altitudePoint }) => areSamePoints(altitudePoint, target));
    }

    static create(data: Figure2DController) {
        return new InteractiveAltitudeFlow(data);
    }

    protected renderElements(): BaseElement[] {
        return [...this.modelElements, ...this.tempAvailableElements, ...this.tempCurrentElement];
    }

    private renderModel = createTransformer(createMapModelToElements(opacifyColor));

    get modelElements(): BaseElement[] {
        return this.figure.models.flatMap(this.renderModel);
    }

    get tempAvailableElements(): BaseElement[] {
        const elements: BaseElement[] = [];

        if (this.flow.vertex) {
            const { x, y } = this.flow.vertex;
            elements.push(
                new DotElement({
                    id: `temp_vertex`,
                    x,
                    y,
                    color: this.flow.stage === 1 ? ElementColor.Building : ElementColor.Selected,
                    overrideRenderOrder: 0,
                })
            );
        }

        if (this.flow.stage !== 2) {
            return elements;
        }

        const overrideRenderOrder = 0;
        const dotColor = ElementColor.Building;
        const lineColor = ElementColor.Util;

        this.flow.availableAltitudes.forEach((altitude, i) => {
            const { basis, vertex, altitudePoint } = altitude;
            const end = basis.offset || basis.fragment.a;
            const { startAngle, endAngle } = getAltitudeAngles(altitude);

            elements.push(
                new DotElement({
                    id: `temp_${i}_altitudePoint`,
                    overrideRenderOrder,
                    color: dotColor,
                    x: altitudePoint.x,
                    y: altitudePoint.y,
                }),
                new LineElement({
                    id: `temp_${i}_altitude`,
                    x1: vertex.x,
                    y1: vertex.y,
                    x2: altitudePoint.x,
                    y2: altitudePoint.y,
                    overrideRenderOrder,
                    color: lineColor,
                }),
                new AngleElement({
                    id: `temp_${i}_angle`,
                    x: altitudePoint.x,
                    y: altitudePoint.y,
                    angleStart: startAngle,
                    angleEnd: endAngle,
                    overrideRenderOrder,
                    color: lineColor,
                    isRight: true,
                }),
                new LineElement({
                    id: `temp_${i}_basisUtil`,
                    x1: end.x,
                    y1: end.y,
                    x2: altitudePoint.x,
                    y2: altitudePoint.y,
                    overrideRenderOrder: overrideRenderOrder + 1,
                    color: lineColor.withOpacity(0.2),
                    thin: true,
                })
            );
        });

        return elements;
    }

    get tempCurrentElement(): BaseElement[] {
        if (this.flow.stage !== 2 || !this.flow.target) return [];

        const { target, vertex, availableAltitudes } = this.flow;
        const altitude = this.findAltitude(availableAltitudes, target);

        if (!altitude) return [];
        const color = ElementColor.Selected;
        const overrideRenderOrder = -1;

        const { basis, altitudePoint } = altitude;
        const { startAngle, endAngle } = getAltitudeAngles(altitude);

        const elements: BaseElement[] = [
            new LineElement({
                id: `temp_current_altitude`,
                x1: vertex.x,
                y1: vertex.y,
                x2: altitudePoint.x,
                y2: altitudePoint.y,
                color,
                overrideRenderOrder,
            }),
            new DotElement({
                id: `temp_current_altitudePoint`,
                x: altitudePoint.x,
                y: altitudePoint.y,
                overrideRenderOrder,
                color,
            }),
            new AngleElement({
                id: `temp_current_angle`,
                x: altitudePoint.x,
                y: altitudePoint.y,
                angleStart: startAngle,
                angleEnd: endAngle,
                overrideRenderOrder,
                color,
                isRight: true,
            }),
            new LineElement({
                id: `temp_current_basis_fragment`,
                overrideRenderOrder,
                color,
                thin: true,
                x1: basis.fragment.a.x,
                y1: basis.fragment.a.y,
                x2: basis.fragment.b.x,
                y2: basis.fragment.b.y,
            }),
        ];

        if (basis.offset) {
            elements.push(
                new DotElement({
                    id: `temp_current_basis_offset`,
                    x: basis.offset.x,
                    y: basis.offset.y,
                    color,
                    overrideRenderOrder,
                })
            );
        }

        return elements;
    }

    private movePointer(e: React.PointerEvent, point: TPoint | null) {
        if (e.type === "pointermove" || e.type === "pointerdown") {
            switch (this.flow.stage) {
                case 1:
                    this.flow.vertex = point || void 0;
                    break;
                case 2:
                    this.flow.target = point || void 0;
                    break;
            }
        }
    }

    private startDrawing(e: React.PointerEvent) {
        if (e.type === "pointerdown") {
            switch (this.flow.stage) {
                case 1:
                    break;
                case 2:
                    break;
            }
        }
    }

    private endDrawing(e: React.PointerEvent) {
        if (e.type === "pointerup") {
            switch (this.flow.stage) {
                case 1: {
                    const point = this.flow.vertex;
                    if (point) {
                        const availableAltitudes = getAvailableAltitudes(point, this.figure.models);

                        this.flow = {
                            stage: 2,
                            vertex: point,
                            availableAltitudes,
                            drawing: false,
                        };

                        this.snap.clean();
                        for (const { altitudePoint } of availableAltitudes) {
                            this.snap.addPoint(altitudePoint);
                        }
                    } else {
                        this.nextFlow();
                    }
                    break;
                }
                case 2:
                    this.checkAndSave();
                    this.nextFlow();
                    break;
            }
        }
    }

    override getToolbarMenu(): ToolbarMenu {
        return createDefaultToolbarMenuBuilder()
            .setButtonState(ButtonStates.active, ["fragments"])
            .setButtonIcon("height", ["fragments"])
            .build();
    }

    override getTooltipMenu(): TooltipMenu | null {
        let text: string;

        switch (this.flow.stage) {
            case 1:
                text = "Выберите точку, откуда опускать высоту";
                break;
            case 2:
                text = "Выберите точку, на которую упадёт высота";
                break;
        }

        return new TooltipMenu(text, []);
    }
}
