import { action, makeObservable } from "mobx";

import { createSerializedContainer } from "@viuch/math-editor";

import type { BaseTableInstrumentSettingsCell } from "./cells";
import type { TSerializedState } from "@viuch/math-editor";

import { BaseTableInstrumentSettingsImpl } from "./BaseTableInstrumentSettingsImpl";
import { TextTableInstrumentSettingsCell } from "./cells";
import { TableViewSettings } from "./TableViewSettings";
import { generateTextTableCells } from "./utils";

export class TableTextInstrumentSettingsImpl extends BaseTableInstrumentSettingsImpl {
    public constructor(
        cells: BaseTableInstrumentSettingsCell[][],
        title: TSerializedState,
        renderInFrame: boolean,
        viewSettings: TableViewSettings
    ) {
        super(cells, title, renderInFrame, viewSettings);

        makeObservable(this);
    }

    clone(): TableTextInstrumentSettingsImpl {
        return new TableTextInstrumentSettingsImpl(
            this.cells.map((row) => row.map((col) => col.clone())),
            this.title,
            this.renderInFrame,
            this.viewSettings.clone()
        );
    }

    static createEmpty() {
        return new TableTextInstrumentSettingsImpl(
            generateTextTableCells(2, 2),
            createSerializedContainer(),
            false,
            new TableViewSettings({})
        );
    }

    @action.bound
    addCol(): void {
        this.cells[0].push(new TextTableInstrumentSettingsCell(false, createSerializedContainer()));

        this.cells.slice(1).forEach((row) => {
            row.push(new TextTableInstrumentSettingsCell(false, createSerializedContainer()));
        });
    }

    @action.bound
    addRow(): void {
        this.cells.push([
            new TextTableInstrumentSettingsCell(false, createSerializedContainer()),
            ...Array.from({ length: this.rowLength - 1 }).map(() => {
                return new TextTableInstrumentSettingsCell(false, createSerializedContainer());
            }),
        ]);
    }
}
