import type { TRawFrequentlyAskedQuestionsList } from "./FAQService.types";
import type { FrequentlyAskedQuestionsList } from "../../entities/faq/faq";

import { agent } from "../../agent";
import { throwServiceError } from "../base/RequestError";

import { mapFrequentlyAskedQuestionsList, serializeFrequentlyAskedQuestionsList } from "./FAQService.utils";

export class FAQService {
    async fetchAll(): Promise<FrequentlyAskedQuestionsList> {
        try {
            const { data } = await agent.get<TRawFrequentlyAskedQuestionsList>("/faq/");

            return mapFrequentlyAskedQuestionsList(data);
        } catch (e) {
            throwServiceError(e);
        }
    }

    async changeAll(list: FrequentlyAskedQuestionsList) {
        try {
            await agent.put("/faq/", serializeFrequentlyAskedQuestionsList(list));
        } catch (e) {
            throwServiceError(e);
        }
    }
}
