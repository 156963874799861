import { action, makeObservable, observable } from "mobx";

import { createSerializedContainer, type TSerializedState } from "@viuch/math-editor";
import { compareArrays, compareSimple } from "@viuch/shared/utils/data";
import { equalsDeep } from "@viuch/shared/utils/equalsDeep";

export class GeometrySynonym {
    @observable.ref id: number;
    @observable.ref target: TSerializedState;
    @observable.shallow sources: TSerializedState[];

    constructor(id: number, target: TSerializedState, sources: TSerializedState[]) {
        this.id = id;
        this.target = target;
        this.sources = sources;

        makeObservable(this);
    }

    @action setTarget = (v: TSerializedState) => void (this.target = v);

    @action setSource = (index: number, v: TSerializedState) => void (this.sources[index] = v);

    @action addSource = (index?: number) =>
        void this.sources.splice(index ?? this.sources.length, 0, createSerializedContainer());

    @action removeSource = (index: number) => void this.sources.splice(index, 1);
}

export function cloneGeometrySynonym(item: GeometrySynonym): GeometrySynonym {
    const { id, sources, target } = item;
    return new GeometrySynonym(id, target, sources.slice());
}

export function areEqualGeometrySynonyms(left: GeometrySynonym, right: GeometrySynonym, compareIds: boolean): boolean {
    if (compareIds) {
        if (left.id !== right.id) return false;
    }

    switch (false) {
        case compareSimple(left.id, right.id):
        case compareArrays<TSerializedState>(left.sources, right.sources, (left, right) =>
            equalsDeep(left.elements, right.elements)
        ):
        case equalsDeep(left.target.elements, right.target.elements):
            compareArrays<TSerializedState>(left.sources, right.sources, (left, right) =>
                equalsDeep(left.elements, right.elements)
            );
            return false;
    }
    return true;
}
