import { observer } from "mobx-react-lite";
import React from "react";

import { BaseInput } from "./BaseInput";

import styles from "./SelectInput.module.scss";

type Props<T> = {
    value: T;
    onChange: (value: T) => void;
    data: TSelectInputData<T>;
    title?: string;
    emptyLabel?: string;
    className?: string;
    disabled?: boolean;
    error?: string;
};

export type TSelectInputData<T> = Array<{
    value: T;
    label: string;
}>;

export const SelectInput = observer(function SelectInput<T>({
    value,
    data,
    onChange,
    emptyLabel,
    className,
    title,
    disabled,
    error,
}: Props<T>): React.ReactElement {
    const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const i = Number(e.target.value);
        if (i !== -1) {
            onChange(data[i].value);
        }
    };

    const currentKey = data.findIndex((item) => value === item.value);

    return (
        <BaseInput
            title={title}
            className={className}
            error={error}
        >
            <select
                value={currentKey}
                onChange={handleChange}
                className={styles.select}
                disabled={disabled}
            >
                <option
                    value={-1}
                    hidden
                    disabled
                >
                    {emptyLabel}
                </option>
                {data.map(({ label }, i) => (
                    <option
                        value={i}
                        key={i}
                    >
                        {label}
                    </option>
                ))}
            </select>
        </BaseInput>
    );
});
