import { action, makeObservable, observable } from "mobx";

import { createSerializedContainer, type TSerializedState } from "@viuch/math-editor";

export class Graph2DInstrumentGridSettings {
    @observable.ref primaryGridSize: TSerializedState;
    @observable.ref secondaryGridSize: TSerializedState;
    @observable.ref secondaryGridSplitOnZoomFactor: TSerializedState;

    @observable.shallow secondaryGridSteps: TSerializedState[];

    constructor(
        primaryGridSize: TSerializedState,
        secondaryGridSize: TSerializedState,
        secondaryGridSplitOnZoomFactor: TSerializedState,
        secondaryGridSteps: TSerializedState[]
    ) {
        this.primaryGridSize = primaryGridSize;
        this.secondaryGridSize = secondaryGridSize;
        this.secondaryGridSplitOnZoomFactor = secondaryGridSplitOnZoomFactor;
        this.secondaryGridSteps = secondaryGridSteps;

        makeObservable(this);
    }

    @action.bound
    setPrimaryGridSize(value: TSerializedState) {
        this.primaryGridSize = value;
    }

    @action.bound
    setSecondaryGridSize(value: TSerializedState) {
        this.secondaryGridSize = value;
    }

    @action.bound
    setSecondaryGridSplitOnZoomFactor(value: TSerializedState) {
        this.secondaryGridSplitOnZoomFactor = value;
    }

    @action.bound
    setSecondaryGridStep(index: number, value: TSerializedState) {
        this.secondaryGridSteps[index] = value;
    }

    @action.bound
    addSecondaryGridStep() {
        this.secondaryGridSteps.push(createSerializedContainer());
    }

    @action.bound
    removeSecondaryGridStep(index: number) {
        this.secondaryGridSteps.splice(index, 1);
    }

    clone() {
        const { primaryGridSize, secondaryGridSize, secondaryGridSplitOnZoomFactor, secondaryGridSteps } = this;
        return new Graph2DInstrumentGridSettings(
            primaryGridSize,
            secondaryGridSize,
            secondaryGridSplitOnZoomFactor,
            secondaryGridSteps.slice()
        );
    }
}
