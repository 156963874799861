import type { TSerializedState } from "@viuch/math-editor";

import { GeometrySynonym } from "../../entities/geometry-synonyms/GeometrySynonym";
import { BaseCRUDService } from "../base";

export class GeometrySynonymsService extends BaseCRUDService<GeometrySynonym, TRawGeometrySynonymRequest> {
    constructor() {
        super("/geometry_synonyms/", mapGeometrySynonym, serializeGeometrySynonym);
    }
}

export type TRawGeometrySynonymRequest = {
    id: number;
    target: TSerializedState;
    sources: TSerializedState[];
};

function mapGeometrySynonym(data: TRawGeometrySynonymRequest): GeometrySynonym {
    const { id, target, sources } = data;
    return new GeometrySynonym(id, target, sources);
}

function serializeGeometrySynonym(item: GeometrySynonym): TRawGeometrySynonymRequest {
    const { id, target, sources } = item;
    return { id, target, sources };
}
