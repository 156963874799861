import type { IElementFilter } from "./IElementFilter";
import type { TCursorMoveDirection, TRemoveMethod } from "./IStrategy";
import type { TMultiContainerElement } from "./types";
import type { TEquationsSetAction } from "../../actions/equations-set";
import type { TLinebreakAction } from "../../actions/linebreak";
import type { ContainerModel } from "../container";
import type { BaseElementModel } from "../element";

import { RemoveCurrentCommand } from "../../commands/RemoveCurrentCommand";
import { ClipboardPasteFilter } from "../clipboard";

import { DefaultStrategy } from "./DefaultStrategy";

export class MultiContainerElementStrategy<M extends TMultiContainerElement> extends DefaultStrategy<M> {
    private pasteFilter: IElementFilter;

    checkCanBeInserted(element: BaseElementModel, container: ContainerModel<M>): boolean {
        return true;
    }

    public constructor(model: M) {
        super(model);

        this.pasteFilter = ClipboardPasteFilter.Create({
            allowMultiline: false,
            allowEquationsSet: false,
        });
    }

    protected override handleCursorOutLeft(): void {
        const cursor = this.cursor;
        const containers = this.model.getContainersToMoveCursorBetween();

        if (cursor.container === containers[0]) {
            const parentElement = cursor.container.parentElement;
            const parentElementIndex = parentElement.computeIndex();

            cursor.moveToPosition(parentElement.parentContainer, parentElementIndex);
            return;
        }

        const containerIndex = containers.indexOf(cursor.container);
        this.cursor.moveToEnd(containers[containerIndex - 1]);
    }

    protected override handleCursorOutRight(): void {
        const cursor = this.cursor;
        const containers = this.model.getContainersToMoveCursorBetween();

        if (cursor.container === containers[containers.length - 1]) {
            const parentElement = cursor.container.parentElement;
            const parentElementIndex = parentElement.computeIndex();

            cursor.moveToPosition(parentElement.parentContainer, parentElementIndex + 1);
            return;
        }

        const containerIndex = containers.indexOf(cursor.container);
        cursor.moveToStart(containers[containerIndex + 1]);
    }

    override handleCursorMoveOver(direction: TCursorMoveDirection): void {
        const cursor = this.cursor;
        const containers = this.model.getContainersToMoveCursorBetween();

        if (direction === "ltr") {
            cursor.moveToStart(containers[0]);
            return;
        }

        if (direction === "rtl") {
            cursor.moveToEnd(containers[containers.length - 1]);
        }
    }

    private tryFindFirstNonEmptyContainer(containers: ContainerModel[], fromEnd?: boolean): ContainerModel | null {
        const length = containers.length;

        for (let i = fromEnd ? length - 1 : 0; fromEnd ? i >= 0 : i < length; fromEnd ? i-- : i++) {
            const container = containers[i];
            if (!container.checkIsEmpty()) {
                return container;
            }
        }
        return null;
    }

    protected override handleRemoveOutFirst(fromEnd?: boolean) {
        const cursor = this.cursor;
        const containers = this.model.getContainersToMoveCursorBetween();

        const nonEmptyContainer = this.tryFindFirstNonEmptyContainer(containers, true);

        if (nonEmptyContainer) {
            cursor.moveToEnd(nonEmptyContainer);
        } else {
            this.commands.perform(new RemoveCurrentCommand(this.model));
        }
    }

    protected override handleRemoveOutLast() {
        const cursor = this.cursor;
        const containers = this.model.getContainersToMoveCursorBetween();

        const nonEmptyContainer = this.tryFindFirstNonEmptyContainer(containers, false);

        if (nonEmptyContainer) {
            cursor.moveToStart(nonEmptyContainer);
        } else {
            this.commands.perform(new RemoveCurrentCommand(this.model));
        }
    }

    protected override handleLinebreakAction({ direction }: TLinebreakAction) {
        const cursor = this.cursor;

        const parentElement = cursor.container.parentElement;
        const parentContainer = parentElement.parentContainer;
        let index = parentElement.computeIndex();
        if (direction === "down") {
            index++;
        }

        cursor.moveToPosition(parentContainer, index);
    }

    private tryFindFirstEmptyContainer(containers: ContainerModel[]): ContainerModel | null {
        for (let i = 0; i < containers.length; i++) {
            if (containers[i].checkIsEmpty()) {
                return containers[i];
            }
        }
        return null;
    }

    override handleInserted() {
        const containers = this.model.getContainersToMoveCursorBetween();

        const containerToInsertCursor =
            this.model.overridePrimaryContainer?.() ?? this.tryFindFirstEmptyContainer(containers);

        if (containerToInsertCursor) {
            this.cursor.moveToEnd(containerToInsertCursor);
        } else {
            super.handleInserted();
        }
    }

    override handleRemoveThis(method: TRemoveMethod) {
        const containers = this.model.getContainersToMoveCursorBetween();

        const fromEnd = method === "backspace";
        const nonEmptyContainer = this.tryFindFirstNonEmptyContainer(containers, fromEnd);

        if (nonEmptyContainer) {
            fromEnd ? this.cursor.moveToEnd(nonEmptyContainer) : this.cursor.moveToStart(nonEmptyContainer);
        } else {
            super.handleRemoveThis(method);
        }
    }

    protected override handleEquationsSetAction({ variant }: TEquationsSetAction): void {}
}
