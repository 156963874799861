import type { DifferentialModel } from "./DifferentialModel";
import type { ContainerModel } from "../../core/container";
import type { BaseElementModel } from "../../core/element";
import type { IElementFilter } from "../../core/strategies/IElementFilter";

import { CharsOnlyFilter } from "../../core/strategies/filters/CharsOnlyFilter";
import { MultiContainerElementStrategy } from "../../core/strategies/MultiContainerElementStrategy";

import { DifferentialContentFilter } from "./DifferentialContentFilter";

export class DifferentialStrategy extends MultiContainerElementStrategy<DifferentialModel> {
    private denominatorFilter: IElementFilter;
    private contentFilter: IElementFilter;

    constructor(model: DifferentialModel) {
        super(model);

        this.denominatorFilter = new CharsOnlyFilter();
        this.contentFilter = new DifferentialContentFilter();
    }

    override checkCanBeInserted(element: BaseElementModel, container: ContainerModel<DifferentialModel>): boolean {
        if (container === this.model.denominator) {
            return this.denominatorFilter.canInsert(element);
        }
        if (container === this.model.content) {
            return this.contentFilter.canInsert(element);
        }
        return super.checkCanBeInserted(element, container);
    }
}
