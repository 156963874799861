import { format } from "date-fns";
import { ru } from "date-fns/locale";

export function mapDate(date: string): Date {
    return new Date(date);
}

export function serializeDate(date: Date): string {
    const d = date.getDate().toString().padStart(2, "0");
    const m = (date.getMonth() + 1).toString().padStart(2, "0");
    const y = date.getFullYear();

    return `${y}-${m}-${d}`;
}

export function tryParseDate(value: string): Date | null {
    const regex = /^\D*(\d{1,2})\D+(\d{1,2})\D+(\d{4})\D*$/g;

    const result = regex.exec(value);
    if (!result) return null;

    const [, day, month, year] = result;

    return new Date(+year, +month - 1, +day);
}

export function formatDate(date: Date): string {
    const d = date.getDate().toString().padStart(2, "0");
    const m = (date.getMonth() + 1).toString().padStart(2, "0");
    const y = date.getFullYear();

    return `${d}.${m}.${y}`;
}

export function prettifyDateTime(date: Date): string {
    return format(date, "dd.MM.yyyy, HH:mm:ss", { locale: ru });
}

export function formatHumanDate(date: Date): string {
    return format(date, "d MMMM yyyy", { locale: ru });
}

export function formatHumanRawDate(date: string): string {
    return format(new Date(date), "d MMMM yyyy", { locale: ru });
}

export const monthStringsShort = ["янв", "фев", "мар", "апр", "май", "июн", "июл", "авг", "сен", "окт", "ноя", "дек"];
export const monthStringsFull = [
    "январь",
    "февраль",
    "март",
    "апрель",
    "май",
    "июнь",
    "июль",
    "август",
    "сентябрь",
    "октябрь",
    "ноябрь",
    "декабрь",
];

export const monthStringsFull2 = [
    "января",
    "февраля",
    "марта",
    "апреля",
    "мая",
    "июня",
    "июля",
    "августа",
    "сентября",
    "октября",
    "ноября",
    "декабря",
];

export function getDateComponents(date: Date) {
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const monthString = monthStringsShort[monthIndex];
    const year = date.getFullYear();

    return { day, monthIndex, monthString, year };
}

export function mapDateStringToSeconds(date: string): number {
    return Math.round(+mapDate(date) / 1000);
}
