export function doNothing(...args: unknown[]): void;
export function doNothing() {}

export function delay(ms: number): Promise<void>;
export function delay<T>(ms: number, resolveWith: T, cancellationToken?: AbortSignal): Promise<T>;

export function delay<T>(ms: number, resolveWith?: T, cancellationToken?: AbortSignal): Promise<T> {
    return new Promise<T>((resolve, reject) => {
        function handleAbort(e: Event) {
            assert(e.target instanceof AbortSignal);
            reject(e.target.reason);
        }

        cancellationToken?.addEventListener("abort", handleAbort);

        setTimeout(() => {
            cancellationToken?.removeEventListener("abort", handleAbort);
            resolve(resolveWith!);
        }, ms);
    });
}

export function assert<T>(something: T, ...args: unknown[]): asserts something {
    if (!something) {
        // eslint-disable-next-line no-console
        console.error("Assertion failed", ...args);
        throw new Error("Assertion failed");
    }
}

export function assertNever(something: never): never {
    throw new Error("Assertion failed, expected never", something);
}

export function safeCheck<TResult extends {}>(
    value: {},
    check: (value: Partial<TResult>) => boolean
): value is TResult {
    return check(value);
}

export function thrw(): never {
    throw new Error();
}

export function safeCheckNever(value: never): void {
    console.error("Value expected to be never, got:", value);
}
