import { autorun } from "mobx";
import { observer } from "mobx-react-lite";
import { useLayoutEffect, useCallback, useRef } from "react";

import { middlePoint, subtractVectors, vectorLength } from "@viuch/geometry-lib/vectors";
import { range } from "@viuch/shared/utils/math/range";
import { mergeRefs } from "@viuch/shared/utils/react";

import type { Figure2D } from "../../../entities/Figure2D";
import type { EventsController } from "../../events";
import type { DeviceSettings } from "../../services";
import type { ViewportController } from "../ViewportController";
import type { TPoint } from "@viuch/geometry-lib/types";
import type { PropsWithChildren, RefObject, Touch, PointerEvent, KeyboardEvent, TouchEvent } from "react";

import styles from "./FigureViewport.module.scss";

type Props = PropsWithChildren<{
    figure: Figure2D;
    viewport: ViewportController;
    events: EventsController;
    device: DeviceSettings;
    isStatic?: boolean;
    focusRef?: RefObject<HTMLDivElement>;
}>;

export const FigureViewport = observer(function NewFigureViewport({
    figure,
    viewport,
    events,
    device,
    children,
    isStatic,
    focusRef,
}: Props) {
    const viewportRef = useRef<HTMLDivElement>(null);
    const canvasRef = useRef<HTMLDivElement>(null);
    const gridCanvasRef = useRef<HTMLCanvasElement>(null);

    useLayoutEffect(() => {
        const scheduler = (run: VoidFunction) => requestAnimationFrame(() => run());

        const viewportElement = viewportRef.current!;
        const canvasElement = canvasRef.current!;
        const gridCanvasElement = gridCanvasRef.current!;

        /**
         * Рисует сетку на канвасе.
         */
        const ctx = gridCanvasElement.getContext("2d")!;

        function renderGrid(width: number, height: number) {
            ctx.imageSmoothingEnabled = false;
            ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

            const { scale, position } = viewport;
            const scaleGridMultiplier = Math.pow(2, Math.floor(Math.log2(scale)));
            const secondaryGridSteps = figure.settings.secondaryGridSteps / device.gridDivider;
            const primaryGridMultiplier = figure.settings.primaryGridMultiplier / device.gridDivider;
            const gridSegments = secondaryGridSteps / primaryGridMultiplier;
            const gridSecondarySegments = secondaryGridSteps * scaleGridMultiplier;
            const size = Math.max(width, height);

            const offset = {
                x: width / 2 - size * scale * position.x,
                y: height / 2 - size * scale * (1 - position.y),
            };

            ctx.setTransform(scale, 0, 0, scale, offset.x, offset.y);

            renderGridLines(gridSecondarySegments, 1, "#314b67");
            renderGridLines(gridSegments, 2, "#2c516e");

            ctx.resetTransform();

            function renderGridLines(segmentsCount: number, lineWidth: number, color: string) {
                const step = size / segmentsCount;
                ctx.lineWidth = lineWidth / scale;
                ctx.strokeStyle = color;

                ctx.beginPath();
                for (let i = 0; i <= segmentsCount; i++) {
                    ctx.moveTo(i * step, 0);
                    ctx.lineTo(i * step, size);

                    ctx.moveTo(0, i * step);
                    ctx.lineTo(size, i * step);
                }
                ctx.stroke();
            }
        }

        /**
         * Устанавливает размеры области просмотра.
         * Сообщает об изменении aspect-ratio.
         * Рисует сетку при изменении размеров.
         */
        const observer = new ResizeObserver(() => {
            const width = viewportElement.clientWidth;
            const height = viewportElement.clientHeight;

            const max = Math.max(width, height);

            gridCanvasElement.width = width;
            gridCanvasElement.height = height;

            renderGrid(width, height);

            canvasElement.style.setProperty("--width", max.toFixed());
            canvasElement.style.setProperty("--height", max.toFixed());
            viewport.setAspectRatio(width / height);
        });

        observer.observe(viewportElement);

        /**
         * Устанавливает вьюпорту scale и position.
         * Рисует сетку.
         */
        const disposeRender = autorun(
            () => {
                const { scale, position } = viewport;

                const canvasElement = canvasRef.current;
                if (!canvasElement) return;

                renderGrid(viewportElement.clientWidth, viewportElement.clientHeight);

                canvasElement.style.setProperty("--scale", `${scale}`);
                canvasElement.style.setProperty("--position-x", `${position.x}`);
                canvasElement.style.setProperty("--position-y", `${position.y}`);
            },
            { scheduler }
        );

        /**
         * Обрабатывает события скролла
         */
        const handleWheel = (e: WheelEvent) => {
            if (isStatic) return;

            e.preventDefault();
            viewport.offsetScalePercent(-e.deltaY / 1000);
        };

        viewportElement.addEventListener("wheel", handleWheel, { passive: false });

        /**
         * Перемещает вьюпорт, если указатель удерживается на краю экрана
         */
        let lastTime: number | null = null;
        let handle = requestAnimationFrame(updateViewportHeadless);

        function updateViewportHeadless(time: number) {
            try {
                if (!lastTime) return;
                if (isStatic) return;

                const delta = time - lastTime;
                const { enabled, scale } = viewport;
                const prevPointerEvent = lastPointerEventRef.current;

                if (!enabled && prevPointerEvent) {
                    const { left, top, width, height } = viewportElement.getBoundingClientRect();

                    const _x = (prevPointerEvent.clientX - left) / width;
                    const _y = 1 - (prevPointerEvent.clientY - top) / height;

                    const distance = 0.15;
                    const velocity = 5;

                    const speed = (velocity * delta) / (scale * 10000);
                    const delta_x = speed * range(0, distance, 1, 0, Math.min(_x, 1 - _x));
                    const delta_y = speed * range(0, distance, 1, 0, Math.min(_y, 1 - _y));

                    const x = _x > 0.5 ? delta_x : -delta_x;
                    const y = _y > 0.5 ? delta_y : -delta_y;

                    viewport.offsetPosition({ x, y });
                }
            } finally {
                handle = requestAnimationFrame((time) => updateViewportHeadless(time));
                lastTime = time;
            }
        }

        return () => {
            disposeRender();
            observer.disconnect();
            viewportElement.removeEventListener("wheel", handleWheel);
            cancelAnimationFrame(handle);
        };
    }, [device, figure, isStatic, viewport]);

    const getPoint = useCallback((e: PointerEvent | Touch): TPoint => {
        const { x, y } = { x: e.clientX, y: e.clientY };

        const canvasElement = canvasRef.current!;
        const { width, height, left, top } = canvasElement.getBoundingClientRect();

        return {
            x: (x - left) / width,
            y: 1 - (y - top) / height,
        };
    }, []);

    const prevPointerEventRef = useRef<PointerEvent | Touch>();
    const lastPointerEventRef = useRef<PointerEvent | Touch>();

    const handlePointerDown = (e: PointerEvent) => {
        const canvasPoint = getPoint(e);
        e.currentTarget.setPointerCapture(e.pointerId);

        events.dispatchViewportPointerEvent(e, { id: e.pointerId, canvas: canvasPoint });

        if (e.pointerType !== "touch" && e.isPrimary) {
            prevPointerEventRef.current = e;
        }
    };

    const handlePointerMove = (e: PointerEvent) => {
        const canvasPoint = getPoint(e);
        events.dispatchViewportPointerEvent(e, { id: e.pointerId, canvas: canvasPoint });

        if (e.pointerType !== "touch" && e.isPrimary) {
            lastPointerEventRef.current = e.buttons ? e : void 0;

            if (prevPointerEventRef.current && viewport.enabled) {
                const prevCanvasPoint = getPoint(prevPointerEventRef.current);

                viewport.offsetPosition({
                    x: prevCanvasPoint.x - canvasPoint.x,
                    y: prevCanvasPoint.y - canvasPoint.y,
                });
                prevPointerEventRef.current = e;
            }
        }
    };

    const handlePointerUp = (e: PointerEvent) => {
        const canvasPoint = getPoint(e);
        events.dispatchViewportPointerEvent(e, { id: e.pointerId, canvas: canvasPoint });

        if (e.pointerType !== "touch" && e.isPrimary) {
            lastPointerEventRef.current = void 0;
            prevPointerEventRef.current = void 0;
        }
    };

    const handlePointerCancel = () => {
        prevPointerEventRef.current = void 0;
    };

    const handleKeyEvent = (e: KeyboardEvent) => {
        events.dispatchKeyEvent(e);
    };

    const multiTouchDataRef = useRef<{ prevTouch1: Touch; prevTouch2: Touch }>();

    const handleTouchStart = useCallback((e: TouchEvent) => {
        if (e.touches.length === 1) {
            lastPointerEventRef.current = e.touches[0];
            prevPointerEventRef.current = e.touches[0];
        } else {
            lastPointerEventRef.current = void 0;
            prevPointerEventRef.current = void 0;
        }

        if (e.touches.length === 2) {
            const [touch1, touch2] = e.touches;
            multiTouchDataRef.current = { prevTouch1: touch1, prevTouch2: touch2 };
        } else {
            multiTouchDataRef.current = void 0;
        }
    }, []);

    const handleTouchMove = useCallback(
        (e: TouchEvent) => {
            if (e.touches.length === 1) {
                lastPointerEventRef.current = e.touches[0];
            } else {
                lastPointerEventRef.current = void 0;
            }

            if (e.touches.length === 1) {
                if (viewport.enabled && prevPointerEventRef.current) {
                    const prevCanvasPoint = getPoint(prevPointerEventRef.current);
                    const canvasPoint = getPoint(e.touches[0]);

                    viewport.offsetPosition({
                        x: prevCanvasPoint.x - canvasPoint.x,
                        y: prevCanvasPoint.y - canvasPoint.y,
                    });
                }
                prevPointerEventRef.current = e.touches[0];
            } else if (e.touches.length === 2) {
                if (viewport.enabled && multiTouchDataRef.current) {
                    const { prevTouch1, prevTouch2 } = multiTouchDataRef.current;
                    const prevPoint1 = getPoint(prevTouch1);
                    const prevPoint2 = getPoint(prevTouch2);
                    const prevPoint = middlePoint(prevPoint1, prevPoint2);
                    const prevDistance = vectorLength(subtractVectors(prevPoint1, prevPoint2));

                    const touches_ = [...e.touches];
                    const [touch1, touch2] =
                        touches_[0].identifier === prevTouch1.identifier ? touches_ : touches_.reverse();

                    const point1 = getPoint(touch1);
                    const point2 = getPoint(touch2);
                    const currentPoint = middlePoint(point1, point2);
                    const currentDistance = vectorLength(subtractVectors(point1, point2));

                    const offset = {
                        x: prevPoint.x - currentPoint.x,
                        y: prevPoint.y - currentPoint.y,
                    };

                    const k = currentDistance / prevDistance;

                    viewport.offsetPosition(offset);
                    viewport.multiplyScale(k);

                    multiTouchDataRef.current = { prevTouch1: touch1, prevTouch2: touch2 };
                }
            }
        },
        [getPoint, viewport]
    );

    const handleTouchEnd = useCallback(
        (e: TouchEvent) => {
            handleTouchStart(e);
        },
        [handleTouchStart]
    );

    const handleTouchCancel = useCallback(() => {
        prevPointerEventRef.current = void 0;
        multiTouchDataRef.current = void 0;
    }, []);

    return (
        <div
            ref={focusRef ? mergeRefs(viewportRef, focusRef) : viewportRef}
            className={styles.viewport}
            tabIndex={0}
            onPointerDown={handlePointerDown}
            onPointerMove={handlePointerMove}
            onPointerUp={handlePointerUp}
            onPointerCancel={handlePointerCancel}
            onKeyDown={handleKeyEvent}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
            onTouchCancel={handleTouchCancel}
        >
            <canvas
                ref={gridCanvasRef}
                className={styles.canvasGrid}
            />
            <div
                ref={canvasRef}
                className={styles.canvas}
            >
                {children}
            </div>
        </div>
    );
});
