import cn from "classnames";
import { observer } from "mobx-react-lite";

import type { PropsWithChildren } from "react";

import styles from "./ModalLayout.module.scss";

type Props = PropsWithChildren<{
    title: string;
    onClose?: VoidFunction;
    className?: string;
    draggableClassName?: string;
}>;

/** @deprecated */
export const ModalLayout = observer(function ModalLayout(props: Props) {
    const { className, title, onClose, children, draggableClassName } = props;

    const handleClose = () => {
        onClose?.();
    };

    return (
        <div className={cn(styles.modalLayout, className)}>
            <div className={styles.modalHeader}>
                <h2 className={cn(styles.modalTitle, draggableClassName)}>{title}</h2>
                {onClose && (
                    <div className={styles.modalClose}>
                        <button
                            className={styles.modalCloseButton}
                            onClick={handleClose}
                        >
                            <div className={styles.modalCloseIcon} />
                        </button>
                    </div>
                )}
            </div>
            <div className={styles.modalContent}>{children}</div>
        </div>
    );
});
