import { makeObservable, observable } from "mobx";
import { createTransformer } from "mobx-utils";

import { getAngle } from "@viuch/geometry-lib/angles";
import { areSamePoints } from "@viuch/geometry-lib/check-geometry";
import { compareFloats } from "@viuch/geometry-lib/equate";
import { copyPoint } from "@viuch/geometry-lib/factories";
import { createFragmentsFromPoints } from "@viuch/geometry-lib/transform";
import { middlePoint } from "@viuch/geometry-lib/vectors";

import type { BaseElement } from "../../elements";
import type { Figure2DController } from "../../Figure2DController";
import type { ToolbarButton, ToolbarMenu } from "../../toolbar";
import type { IUserPointer } from "../../viewport/types";
import type { TPoint } from "@viuch/geometry-lib/types";
import type React from "react";

import { DotElement } from "../../elements/dot";
import { ElementColor } from "../../elements/ElementColor";
import { LineElement } from "../../elements/line";
import { FragmentModel } from "../../models/fragment";
import { LabelPointModel } from "../../models/label-point";
import { PointModel } from "../../models/point";
import { ButtonStates } from "../../toolbar";
import { TooltipMenu } from "../../toolbar/tooltip";
import { createDefaultToolbarMenuBuilder, handleToolbarButtons } from "../../utils/toolbar";
import { BaseFlow } from "../BaseFlow";
import { createMapModelToElements, opacifyColor } from "../utils";

export class TriangleFlow extends BaseFlow {
    flow: {
        points: TPoint[];
        drawing: boolean;
        current?: TPoint;
    };

    constructor(data: Figure2DController, initialPoints?: TPoint[]) {
        super(data);

        this.flow = {
            drawing: false,
            points: initialPoints?.slice(0, 3).map(copyPoint) || [],
        };

        makeObservable(this, {
            flow: observable,
        });
    }

    static create(data: Figure2DController) {
        return new TriangleFlow(data);
    }

    attach(): void {
        this.snap.addFigureModelsPoints().addViewportGrid();
        this.viewport.disable();

        Promise.resolve().then(() => this.checkAndFinish());
    }

    dispose(): void {
        this.snap.clean();
    }

    override getToolbarMenu(): ToolbarMenu {
        return createDefaultToolbarMenuBuilder().setButtonState(ButtonStates.active, ["triangle"]).build();
    }

    override handleToolbarButtonClick(menu: ToolbarMenu, button: ToolbarButton): void {
        switch (button.key) {
            case "triangle":
                this.nextFlow();
                break;
            default:
                handleToolbarButtons(this, button);
        }
    }

    override handleViewportPointerEvent(e: React.PointerEvent, pointer: IUserPointer): void {
        if (e.type === "pointercancel") return this.nextFlow();
        if (!e.isPrimary) return;

        const point = this.snap.snap(pointer.canvas, pointer.canvas);

        this.updateCurrentPointPosition(e, point);
        this.startDrawing(e, point);
        this.endDrawing(e, point);
        this.checkAndFinish();
    }

    private updateCurrentPointPosition(e: React.PointerEvent, point: TPoint) {
        if (e.type === "pointermove") {
            this.flow.current = point;
        }
    }

    private startDrawing(e: React.PointerEvent, point: TPoint) {
        if (e.type === "pointerdown") {
            this.flow.drawing = true;
            this.flow.current = point;
        }
    }

    private endDrawing(e: React.PointerEvent, point: TPoint) {
        if (e.type === "pointerup") {
            this.flow.drawing = false;
            this.flow.points.push(this.flow.current || point);
        }
    }

    private checkAndFinish() {
        if (this.flow.points.length < 3) return;

        const [a, b, c] = this.flow.points;
        if (areSamePoints(a, b) || areSamePoints(b, c) || areSamePoints(c, a)) {
            this.nextFlow();
            return;
        }

        const baseAngle = getAngle({ vertex: b, start: a, end: c });
        if (compareFloats(baseAngle, Math.PI) === 0 || compareFloats(baseAngle, 0) === 0) {
            this.nextFlow();
            return;
        }

        this.figure.insertModels(function* () {
            for (const point of [a, b, c]) {
                yield PointModel.create({ ...point, style: null });
                yield LabelPointModel.createNext(point, this.figure);
            }

            yield FragmentModel.create({ a, b, style: null });
            yield FragmentModel.create({ a: b, b: c, style: null });
            yield FragmentModel.create({ a: c, b: a, style: null });
        }, this);

        this.nextFlow();
    }

    private render = createTransformer(createMapModelToElements(opacifyColor));

    protected renderElements(): BaseElement[] {
        return [...this.figure.models.flatMap(this.render), ...this.tempElements];
    }

    get tempElements(): BaseElement[] {
        const { points, current, drawing } = this.flow;

        const color = ElementColor.Building;
        const currentColor = drawing ? ElementColor.Selected : ElementColor.Building;
        const shallowColor = ElementColor.Util;
        const overrideRenderOrder = 0;
        const { scale } = this.viewport;

        const elements: BaseElement[] = [];

        if (current) {
            elements.push(
                new DotElement({
                    id: `temp_current_dot`,
                    x: current.x,
                    y: current.y,
                    overrideRenderOrder,
                    color: currentColor,
                })
            );
            const lastPoint = points.at(-1);

            if (lastPoint) {
                elements.push(
                    new LineElement({
                        id: `temp_current_line`,
                        x1: current.x,
                        y1: current.y,
                        x2: lastPoint.x,
                        y2: lastPoint.y,
                        overrideRenderOrder,
                        color: currentColor,
                        thin: true,
                    })
                );
            }

            if (points.length === 2) {
                const firstPoint = points[0];
                elements.push(
                    new LineElement({
                        id: `temp_current_line2`,
                        x1: current.x,
                        y1: current.y,
                        x2: firstPoint.x,
                        y2: firstPoint.y,
                        thin: true,
                        color: currentColor,
                        overrideRenderOrder,
                    })
                );
            }
        }

        elements.push(
            ...points.map(
                (point, i) =>
                    new DotElement({
                        id: `temp_point_${i}`,
                        x: point.x,
                        y: point.y,
                        color,
                        overrideRenderOrder,
                    })
            ),
            ...createFragmentsFromPoints(points, false).map(
                (fragment, i) =>
                    new LineElement({
                        id: `temp_line_${i}`,
                        x1: fragment.a.x,
                        y1: fragment.a.y,
                        x2: fragment.b.x,
                        y2: fragment.b.y,
                        color,
                        overrideRenderOrder,
                        thin: false,
                    })
            )
        );

        if (current) {
            const render = (points: [TPoint, TPoint][]) => {
                points.forEach(([first, second], i) => {
                    elements.push(
                        new LineElement({
                            id: `placeholder_line_${i}`,
                            color: shallowColor,
                            x1: first.x,
                            y1: first.y,
                            x2: second.x,
                            y2: second.y,
                            thin: true,
                            overrideRenderOrder,
                        })
                    );
                });
            };

            if (points.length === 0 || (points.length === 1 && areSamePoints(current, points[0]))) {
                const a = current;
                const b = { x: a.x + 0.1 / scale, y: a.y };
                const c = { x: a.x + 0.05 / scale, y: a.y + 0.1 / scale };
                render([
                    [a, b],
                    [b, c],
                    [c, a],
                ]);
            } else if (points.length === 1) {
                const a = points[0];
                const b = current;
                let c: TPoint;

                const mid = middlePoint(a, b);

                if (Math.abs(a.x - b.x) > Math.abs(a.y - b.y)) {
                    c = {
                        x: mid.x,
                        y: mid.y + 0.1 / scale,
                    };
                } else {
                    c = {
                        x: mid.x + 0.1 / scale,
                        y: mid.y,
                    };
                }
                render([
                    [a, b],
                    [b, c],
                    [c, a],
                ]);
            }
        }

        return elements;
    }

    override getTooltipMenu(): TooltipMenu | null {
        let text: string;

        switch (this.flow.points.length) {
            case 0:
                text = "Выберите первую вершину треугольника";
                break;
            case 1:
                text = "Выберите вторую вершину треугольника";
                break;
            case 2:
                text = "Выберите третью вершину треугольника";
                break;
            default:
                return null;
        }

        return new TooltipMenu(text, []);
    }
}
