export const solverNames: Partial<Record<string, string>> = {
    simplify: "Упрощение выражения",
    simplify_log: "Упрощение выражения в логарифмах",
    simplify_exponential: "Упрощение экспоненциального выражения",
    simplify_rational: "Упрощение рациональной дроби",
    simplify_radical: "Упрощение выражения в радикалах",
    simplify_trigonometry: "Упрощение тригонометрического выражения",
    simplify_and_substitute: "Упростить и подставить",
    fraction_conversion: "Преобразование дробей",
    range_of_expr: "Область определения",
    rewrite_with_conditions: "Вычислить при условии",
    "prove-identity": "Докажите тождество",
    prove_inequality: "Докажите тождественность неравенства",
    linear_integer_system: "Найдите все целочисленные корни",
    multivariative_system: "Система уравнений",
    multivariative_system_replacement: "Система уравнений (Подстановка)",
    expand: "Раскрыть скобки",
    factor: "Разложение на множители",
    add_common_terms: "Общие слагаемые",
    mul_common_terms: "Общие множители",
    rationalize: "К рациональной дроби",
    simplify_root: "Упрощение численного корня",
    numeric_nested_root: "Упрощение вложенного числового корня",
    polynomial_nested_root: "Упрощение вложенного полиномиального корня",
    evaluate: "Вычисление",
    smart_evaluate: "Рациональное вычисление",
    evaluate_interval: "Объединение/Пересечение",
    univariative_system: "Система и совокупность",
    derivative: "Производная",
    indefinite_integral: "Неопределенный интеграл",
    definitive_integral: "Определенный интеграл",
    limit: "Предел",
    geometry: "Геометрия",
    "geometry-dims": "Геометрия с размерностями",
    figure_completion: "Достроение",
    evaluate_dims: "Вычисление с размерностями",
    equation_dims: "Уравнение с размерностями",
    equation_any: "Уравнение",
    equation_linear: "Линейное уравнение",
    variable_separation_equation: "Уравнение обратными действиями",
    equation_quadratic: "Квадратное уравнение",
    equation_cubic: "Кубическое уравнение",
    equation_viet: "Формула Виета",
    inequality: "Неравенство",
    min_value: "Минимальное значение",
    max_value: "Максимальное значение",
    min_point: "Точка минимума",
    max_point: "Точка максимума",
    min_max_local: "Локальные экстремумы",
    min_max_special: "Экстремум параболы",
    beautify: "Сделать красиво",
    to_polynomial: "Раскрыть скобки и привести подобные",
    compare_numbers: "Сравнить числа",
    find_primes: "Найти простые числа",
    gcd: "Наибольший общий делитель",
    lcm: "Наименьшее общее кратное",
    hide_operators: "Поставить пропущенные знаки в выражение",
    compare_dims: "Сравнение с размерностями",
    number_to_words: "Число в текст",
    elementary_equation: "Вставить пропущенные числа",
    divide_with_remainder: "Деление с остатком",
    substitute_prove_identity: "Подставить и доказать тождество",
    common_denominator: "Привестм к общему знаменателю",
    estimate_signs: "Найти знак выражения",
    polynom_divide: "Деление многочлена на многочлен с остатком",
    reverse_simplify_root: "Внести под корень",
    geometry_proof: "Доказательство геометрии",
    factor_integer: "Список множителей целого числа",
    to_mixed_number: "В смешанную дробь",
    from_mixed_number: "Из смешанной дроби",
    trigonometry_with_conditions: "Вычисление тригонометрии при условии",
    "parametric-equation-per-root": "Найти параметр, зная корень уравнения",
    "parametric-equation-per-count": "Число корней в уравнении с параметром (Конкретное)",
    "parametric-equation-count": "Число корней в уравнении с параметром (Все)",
    evaluate_precision: "Вычислить с точностью",
};
