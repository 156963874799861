import { action, makeObservable, observable } from "mobx";

import type { IFormNodeVisitor, BaseFormNode } from "./BaseFormNode";

import { BaseValueFormNode } from "./BaseValueFormNode";

export class CustomSelectFormNode extends BaseValueFormNode {
    readonly customProviderSelector: string;

    @observable value: string | number | null;

    constructor(
        initialValue: string | number | null,
        label: string,
        selector: string,
        parent: BaseFormNode | null,
        path: string[],
        extensions: string[]
    ) {
        super(label, parent, path, extensions);

        this.value = initialValue;
        this.customProviderSelector = selector;

        makeObservable(this);
    }

    @action.bound
    setValue(value: string | number | null) {
        this.value = value;
    }

    accept<R>(visitor: IFormNodeVisitor<R>): R {
        return visitor.withSelectCustom(this);
    }

    getValue(): unknown {
        return this.value;
    }

    trySetValue(value: unknown): boolean {
        if (typeof value === "string" || typeof value === "number" || value === null) {
            this.value = value;
            return true;
        }

        return false;
    }

    valueEqualsTo(value: unknown): boolean {
        return value === this.value;
    }
}
