import { action, makeObservable, observable } from "mobx";

import { Graph1DInstrumentStatement } from "@viuch/instrument-graph1d/statement";

import type { TEditorBlockVerticalAlign } from "../../view/editor-view/useRenderBlock";
import type { IEditorVisitor } from "../IEditorVisitor";
import type { IGraph1DInstrumentStatement } from "@viuch/instrument-graph1d/statement";

import { BaseEditor, EditorTypes } from "../base";

export type TGraph1DInstrumentEditorData = {
    type: EditorTypes.graph1d;
    graph?: IGraph1DInstrumentStatement;
    autoHeight?: boolean | undefined;
    verticalAlign?: TEditorBlockVerticalAlign | undefined;
};

export class Graph1DEditor extends BaseEditor<TGraph1DInstrumentEditorData> {
    readonly graph: Graph1DInstrumentStatement;

    constructor(data: TGraph1DInstrumentEditorData) {
        super();

        this.graph = this.deserializeGraph(data.graph);
        this.autoHeight = !!data.autoHeight;
        this.verticalAlign = data?.verticalAlign ?? null;

        makeObservable(this);
    }

    @observable autoHeight: boolean;
    @observable verticalAlign: TEditorBlockVerticalAlign;

    @action setAutoHeight = (v: boolean) => void (this.autoHeight = v);
    @action setVerticalAlign = (v: TEditorBlockVerticalAlign) => void (this.verticalAlign = v);

    private deserializeGraph(data?: IGraph1DInstrumentStatement): Graph1DInstrumentStatement {
        return data ? Graph1DInstrumentStatement.FromData(data) : Graph1DInstrumentStatement.CreateEmpty();
    }

    accept<R>(visitor: IEditorVisitor<R>): R {
        return visitor.visitGraph1D(this);
    }

    checkIsEmpty(): boolean {
        return false;
    }

    async serialize(): Promise<TGraph1DInstrumentEditorData> {
        const graphData: IGraph1DInstrumentStatement = {
            axis: this.graph.axis,
            dots: this.graph.dots.map((dot) => ({
                position: dot.position,
                name: dot.name,
                value: dot.value,
                form: dot.form,
                isProtected: dot.isProtected,
                isNameVisible: dot.isNameVisible,
                isValueVisible: dot.isValueVisible,
            })),
            intervals: [], // TODO: не реализовано
        };

        return {
            type: EditorTypes.graph1d,
            graph: graphData,
            autoHeight: this.autoHeight,
            verticalAlign: this.verticalAlign,
        };
    }
}
