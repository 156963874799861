import type { TAnyAction } from "../types";

export type TSlashAction = { type: "slash" };

export function createSlashAction(): TSlashAction {
    return { type: "slash" };
}

export function isSlashAction(action: TAnyAction): action is TSlashAction {
    return action.type === "slash";
}
