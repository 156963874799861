import React from "react";

import type { SpaceModel } from "./SpaceModel";
import type { TElementProps } from "../../types/element";

import { useMathEditorElementRef } from "../../hooks";
import { BaseSymbolElement } from "../base/base-symbol";

export const SpaceElement: React.VFC<TElementProps<SpaceModel>> = ({ elementModel }) => {
    const ref = useMathEditorElementRef(elementModel);

    return <BaseSymbolElement ref={ref}>&nbsp;</BaseSymbolElement>;
};
