import type { InputService } from "../../services";
import type { BaseElementModel, TSerializedElementPrototype } from "../element";
import type { SelectionController } from "../selection";

export class ClipboardService {
    private inputService: InputService;
    private static readonly LOCAL_STORAGE_CLIPBOARD_KEY = "MATH_EDITOR_CLIPBOARD";

    public constructor(inputService: InputService) {
        this.inputService = inputService;
    }

    private get selection(): SelectionController {
        return this.inputService.selectionController;
    }

    public copy(elements: BaseElementModel[]): TSerializedElementPrototype[] {
        const serializedClonedElements = elements.map((element) => element.serializeAsClone());

        this.save(serializedClonedElements);
        return serializedClonedElements;
    }

    public copySelected(): void {
        const elements = this.selection.selectedElements;
        elements && this.copy(elements);
    }

    public getBuffer(): TSerializedElementPrototype[] | null {
        return this.load();
    }

    public isEmpty(): boolean {
        return typeof this.storage.getItem(ClipboardService.LOCAL_STORAGE_CLIPBOARD_KEY) !== "string";
    }

    private get storage(): Storage {
        return window.localStorage;
    }

    private save(data: TSerializedElementPrototype[]): void {
        const raw = JSON.stringify(data);
        this.storage.setItem(ClipboardService.LOCAL_STORAGE_CLIPBOARD_KEY, raw);
    }

    private load(): TSerializedElementPrototype[] | null {
        const raw = this.storage.getItem(ClipboardService.LOCAL_STORAGE_CLIPBOARD_KEY);

        if (!raw) {
            return null;
        }

        try {
            const data: unknown = JSON.parse(raw);
            if (!this.simpleCheckRawData(data)) {
                this.clear();
                return null;
            }
            return data;
        } catch {
            this.clear();
            return null;
        }
    }

    private simpleCheckRawData(data: unknown): data is TSerializedElementPrototype[] {
        return typeof data === "object" && Array.isArray(data);
    }

    private clear(): void {
        this.storage.removeItem(ClipboardService.LOCAL_STORAGE_CLIPBOARD_KEY);
    }
}
