import type { TIntervalSign } from "../../statement/types";

import styles from "./IntervalContainer.module.scss";

type TClassNamesMap = Partial<Record<TIntervalSign, string>>;

export const signToPositiveClassNameMap: TClassNamesMap = {
    positive: styles.active,
    negative: styles.inactive,
};

export const signToNegativeClassNameMap: TClassNamesMap = {
    positive: styles.inactive,
    negative: styles.active,
};
