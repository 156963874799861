import { makeObservable, observable } from "mobx";

import { copyPoint, createLine } from "@viuch/geometry-lib/factories";
import { generateId } from "@viuch/shared/utils/data";

import type { TModelStyle } from "../modelStyle";
import type { TFragment, TLine, TPoint, TVector } from "@viuch/geometry-lib/types";

import { BaseModel } from "../BaseModel";

export interface IVectorDashedModel {
    from: TPoint;
    to: TPoint;
    style: TModelStyle | null;
}

export interface IVectorDashedModelVisitor<R> {
    withVectorDashed: (fragment: VectorDashedModel) => R;
}

export class VectorDashedModel extends BaseModel implements IVectorDashedModel {
    from: TPoint;
    to: TPoint;
    style: TModelStyle | null;

    protected constructor(data: IVectorDashedModel, id: number) {
        super(id);
        this.from = copyPoint(data.from);
        this.to = copyPoint(data.to);
        this.style = data.style;

        makeObservable(this, {
            from: observable,
            to: observable,
            style: observable,
        });
    }

    static create(data: IVectorDashedModel) {
        return new VectorDashedModel(data, generateId());
    }

    accept<R>(visitor: IVectorDashedModelVisitor<R>): R {
        return visitor.withVectorDashed(this);
    }

    toFragment(): TFragment {
        const { from: a, to: b } = this;
        return { a: copyPoint(a), b: copyPoint(b) };
    }

    toVector(): TVector {
        const { from, to } = this;
        return { from: copyPoint(from), to: copyPoint(to) };
    }

    toLine(): TLine {
        const { from, to } = this;
        return createLine(from, to);
    }
}
