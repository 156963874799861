import type { ICondition } from "./ICondition";
import type { BaseFormNode } from "../nodes/BaseFormNode";
import type { TVirtualTreeNode } from "../tree/types";

export class ConditionAll implements ICondition {
    private readonly conditions: ICondition[];

    constructor(conditions: ICondition[]) {
        this.conditions = conditions;
    }

    getResult(control: BaseFormNode, node: TVirtualTreeNode): boolean {
        for (const condition of this.conditions) {
            if (!condition.getResult(control, node)) return false;
        }
        return true;
    }
}
