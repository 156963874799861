import type { TFormControl } from "@viuch/form-builder/types/controls";

import { createLabel } from "../controls/label";

export const trigonometryWithConditions: TFormControl = {
    control: "Form",
    layout: "column",
    condition: {
        "./solver_type": ["trigonometry_with_conditions"],
    },
    controls: [
        createLabel("Находит значения выражения если известны значения"),
        {
            path: "./expr",
            control: "Math",
            label: "Выражение",
        },
        {
            path: "./condition",
            control: "Math",
            label: "Условие (В формате sin(2a) = 1/2)",
        },
        {
            path: "./on_set",
            control: "Math",
            label: "Интервал значений переменной",
        },
    ],
};
