import type { ICurlyBracketConfig } from "../types";

export function defaultCurlyBracketConfig(height: number): ICurlyBracketConfig {
    if (height <= 32) {
        return {
            totalHeight: height,
            totalWidth: 7.8 + height * 0.04,
            strokeWidth: 1.2,
            middleHeight: 4.8 + height * 0.03,
            middleWidth: 2.4 + height * 0.03,
            offsetTop: 2,
            offsetBottom: 1,
            offsetLeft: 1,
            asideWidth: 0.5 + height * 0.03,
            radiusX: 1.5,
            radiusY: 1.5,
        };
    }

    if (height <= 50) {
        return {
            totalHeight: height,
            totalWidth: 11,
            strokeWidth: 1.5,
            middleHeight: 8,
            middleWidth: 4,
            offsetTop: 3,
            offsetBottom: 2,
            offsetLeft: 1,
            asideWidth: 2,
            radiusX: 2,
            radiusY: 2,
        };
    }

    return {
        totalHeight: height,
        totalWidth: 20,
        strokeWidth: 2.5,
        middleHeight: 15,
        middleWidth: 7,
        offsetTop: 3,
        offsetBottom: 2,
        offsetLeft: 2,
        asideWidth: 2,
        radiusX: 4,
        radiusY: 4,
    };
}
