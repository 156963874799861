import type { TMathCharsRow, TMathKeyboardButton } from "./types";

import { createBracketAction } from "../../../actions/bracket";
import { createCharAction } from "../../../actions/char";
import { createCoordinatesVectorAction } from "../../../actions/coordinates-vector";
import { createDegreeAction } from "../../../actions/degree";
import { createDigitAction } from "../../../actions/digit";
import { createDownIndexAction } from "../../../actions/down-index";
import { createEqualityAction } from "../../../actions/equality";
import { createFractionAction } from "../../../actions/fraction";
import { createModuleAction } from "../../../actions/module";
import { createHighPriorityAction } from "../../../actions/operator-high";
import { createLowPriorityOperatorAction } from "../../../actions/operator-low";
import { createRootAction } from "../../../actions/root";
import { createSlashAction } from "../../../actions/slash";
import { createSymbolAction } from "../../../actions/symbol";
import { __miscTag } from "../../../core/keyboard-button-tags/constants";

export const numbers = Array.from(Array.from({ length: 10 }).keys());
numbers.push(numbers.shift()!); // удалить 0 (нуль) из начала и вставить в конец

export const mathButtonsRow1: TMathCharsRow = numbers.map((n) => ({
    actionCreator: () => createDigitAction(n),
    text: String(n),
    tags: ["basic", "numbers"],
}));

export const mathButtonsRow2: TMathCharsRow = [
    {
        actionCreator: () => createLowPriorityOperatorAction("plus"),
        text: "+",
        tags: ["basic", "operations", "addition"],
    },
    {
        actionCreator: () => createLowPriorityOperatorAction("minus"),
        text: "-",
        tags: ["basic", "operations", "subtraction"],
    },
    {
        actionCreator: () => createHighPriorityAction("multiply"),
        icon: "multiply",
        tags: ["basic", "operations", "multiply"],
    },
    {
        actionCreator: () => createHighPriorityAction("divide"),
        icon: "divide",
        tags: ["basic", "operations", "divide"],
    },
    {
        actionCreator: () => createSymbolAction("factorial"),
        icon: "factorial",
        tags: [__miscTag],
    },
    {
        actionCreator: () => createLowPriorityOperatorAction("plus-minus"),
        text: "±",
        tags: [__miscTag],
    },
    {
        actionCreator: () => createBracketAction("round"),
        text: "(",
        tags: ["brackets"],
        children: [
            {
                actionCreator: () => createBracketAction("square"),
                text: "[",
                tags: ["brackets"],
            },
            {
                actionCreator: () => createBracketAction("round"),
                text: "(",
                tags: ["brackets"],
            },
            {
                actionCreator: () => createBracketAction("curly"),
                text: "{",
                tags: ["brackets"],
            },
        ],
    },
    {
        actionCreator: () => createBracketAction("round", { closing: true }),
        text: ")",
        tags: ["brackets"],
        children: [
            {
                actionCreator: () => createBracketAction("square", { closing: true }),
                text: "]",
                tags: ["brackets"],
            },
            {
                actionCreator: () => createBracketAction("curly", { closing: true }),
                text: "}",
                tags: ["brackets"],
            },
            {
                actionCreator: () => createBracketAction("round", { closing: true }),
                text: ")",
                tags: ["brackets"],
            },
        ],
    },
    {
        actionCreator: () => createEqualityAction("less"),
        text: "<",
        tags: ["compare"],
        children: [
            {
                actionCreator: () => createEqualityAction("less-equals"),
                text: "≤",
                tags: ["compare"],
            },
            {
                actionCreator: () => createEqualityAction("less"),
                text: "<",
                tags: ["compare"],
            },
        ],
    },
    {
        actionCreator: () => createEqualityAction("greater"),
        text: ">",
        tags: ["compare"],
        children: [
            {
                actionCreator: () => createEqualityAction("greater-equals"),
                text: "≥",
                tags: ["compare"],
            },
            {
                actionCreator: () => createEqualityAction("greater"),
                text: ">",
                tags: ["compare"],
            },
        ],
    },
];

export const mathButtonsRow3: TMathCharsRow = [
    {
        actionCreator: () => createEqualityAction("equals"),
        text: "=",
        tags: ["basic", "equals"],
        children: [
            {
                actionCreator: () => createEqualityAction("equals"),
                text: "=",
                tags: ["basic", "equals"],
            },
            {
                actionCreator: () => createEqualityAction("approx-equals"),
                text: "≈",
                tags: ["compare"],
            },
            {
                actionCreator: () => createEqualityAction("not-equals"),
                icon: "not-equals",
                tags: ["compare"],
            },
        ],
    },
    {
        actionCreator: () => createFractionAction(),
        icon: "frac",
        tags: [__miscTag],
        children: [
            {
                actionCreator: () => createFractionAction(),
                icon: "frac",
                tags: [__miscTag],
            },
            {
                actionCreator: () => createSlashAction(),
                icon: "divide-nums",
                tags: [__miscTag],
            },
        ],
    },
    {
        actionCreator: () => createRootAction({ squared: true }),
        icon: "sqrt",
        tags: ["power"],
        children: [
            {
                actionCreator: () => createRootAction({ squared: true }),
                icon: "sqrt",
                tags: ["power"],
            },
            {
                actionCreator: () => createRootAction(),
                icon: "sqrt-index",
                tags: ["power"],
            },
            {
                actionCreator: () => createRootAction({ index: 3 }),
                icon: "sqrt3",
                tags: ["power"],
            },
        ],
    },
    {
        actionCreator: () => createDegreeAction(2),
        icon: "degree2",
        tags: ["power"],
        children: [
            {
                actionCreator: () => createDegreeAction(),
                icon: "degree",
                tags: ["power"],
            },
            {
                actionCreator: () => createDegreeAction(2),
                icon: "degree2",
                tags: ["power"],
            },
            {
                actionCreator: () => createDegreeAction(3),
                icon: "degree3",
                tags: ["power"],
            },
        ],
    },
    {
        actionCreator: () => createDownIndexAction(),
        icon: "num-index",
        tags: [__miscTag],
    },
    {
        actionCreator: () => createModuleAction(),
        icon: "module",
        tags: [__miscTag],
    },
    {
        actionCreator: () => createCoordinatesVectorAction(),
        icon: "vector-coordinates",
        tags: [__miscTag],
    },
    {
        actionCreator: () => createSymbolAction("percent"),
        icon: "percent",
        tags: [__miscTag],
    },
    {
        actionCreator: () => createCharAction("x"),
        symbols: "x",
        tags: ["alphabet", "alphabet-en"],
        children: "yzabc".split("").map((char) => ({
            actionCreator: () => createCharAction(char),
            symbols: char,
            tags: ["alphabet", "alphabet-en"],
        })),
    },
];

export const commaButton: TMathKeyboardButton = {
    actionCreator: () => createSymbolAction("comma"),
    text: ",",
    tags: ["basic", "symbols", "comma"],
    children: [
        {
            actionCreator: () => createSymbolAction("comma"),
            text: ",",
            tags: ["basic", "symbols", "comma"],
        },
        {
            actionCreator: () => createSymbolAction("semicolon"),
            text: ";",
            tags: ["symbols", "semicolon"],
        },
    ],
};
