import type { IVariableContentVisitor } from "./IVariableContentVisitor";

export abstract class BaseVariableContent {
    protected constructor() {}

    abstract accept<R>(visitor: IVariableContentVisitor<R>): R;
    abstract clone(): BaseVariableContent;
}

export class UnknownVariableContent extends BaseVariableContent {
    readonly data: any;

    constructor(data: any) {
        super();
        this.data = data;

        console.error("Unknown variable content", data);
    }

    accept<R>(visitor: IVariableContentVisitor<R>): R {
        return visitor.withUnknown(this);
    }

    clone(): UnknownVariableContent {
        return new UnknownVariableContent(this);
    }
}
