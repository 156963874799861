import type { TLabelControl, TRequire } from "@viuch/form-builder/types/controls";

export function createLabel(text: string, condition?: TRequire): TLabelControl {
    return {
        control: "Label",
        type: "caption",
        label: text,
        condition,
    };
}
