import cn from "classnames";
import { observer } from "mobx-react-lite";
import React from "react";

import { createSerializedContainer, type KeyboardService } from "@viuch/math-editor";
import { CheckboxInput, MathInput2 } from "@viuch/ui-common";
import { BaseInput } from "@viuch/ui-common/BaseInput";

import type { PointGraphSettings } from "../../settings-entity/graphs/EvalGraphSettings.ts/PointGraphSettings";

import styles from "./Graph2DInstrumentSettingsGraphEditor.module.scss";

type Props = {
    graph: PointGraphSettings;
    onDelete(graph: PointGraphSettings): void;
    keyboardService: KeyboardService;
    className?: string;
};

export const Graph2DInstrumentSettingsPointEditor = observer(function Graph2DInstrumentSettingsPointEditor({
    graph,
    keyboardService,
    onDelete,
    className,
}: Props) {
    const { x, setX, y, setY, color, setColor, isIncluded, setIsIncluded } = graph;
    const { label, setLabel, xLabel, setXLabel, yLabel, setYLabel } = graph;

    return (
        <div className={cn(styles.layout, className)}>
            <h5 className={styles.title}>Точка на плоскости</h5>
            <div className={styles.row}>
                <MathInput2
                    title="Координата X"
                    value={x}
                    onChange={setX}
                    keyboardService={keyboardService}
                />
                <MathInput2
                    title="Координата Y"
                    value={y}
                    onChange={setY}
                    keyboardService={keyboardService}
                />
            </div>
            <CheckboxInput
                type="checkbox"
                checked={isIncluded}
                onChange={setIsIncluded}
                labelAfter="Закрашенная"
                noSpace
            />
            <div>
                <CheckboxInput
                    type="checkbox"
                    checked={!!label}
                    onChange={(v) => setLabel(v ? createSerializedContainer() : null)}
                    labelAfter="Название точки"
                    noSpace
                />
                {label && (
                    <MathInput2
                        value={label}
                        onChange={setLabel}
                        keyboardService={keyboardService}
                    />
                )}
            </div>
            <div>
                <CheckboxInput
                    type="checkbox"
                    checked={!!xLabel && !!yLabel}
                    onChange={(v) => {
                        setXLabel(v ? createSerializedContainer() : null);
                        setYLabel(v ? createSerializedContainer() : null);
                    }}
                    labelAfter="Подписи координат"
                    noSpace
                />
                {!!xLabel && !!yLabel && (
                    <div className={styles.row}>
                        <MathInput2
                            title="Подпись X"
                            value={xLabel}
                            onChange={setXLabel}
                            keyboardService={keyboardService}
                        />
                        <MathInput2
                            title="Подпись Y"
                            value={yLabel}
                            onChange={setYLabel}
                            keyboardService={keyboardService}
                        />
                    </div>
                )}
            </div>
            <BaseInput title="Цвет точки">
                <div className={styles.colors}>
                    <input
                        type="color"
                        value={color}
                        onChange={(e) => setColor(e.target.value)}
                    />
                    {colors.map((color, i) => (
                        <button
                            key={i}
                            onClick={() => setColor(color)}
                            style={{ "--color": color }}
                            className={styles.colorButton}
                        >
                            {" "}
                        </button>
                    ))}
                </div>
            </BaseInput>
            <button
                onClick={() => onDelete(graph)}
                className={styles.deleteButton}
            >
                <i className={styles.deleteButton__icon} />
                Удалить точку
            </button>
        </div>
    );
});

const colors = ["#ffffff", "#21f0a5", "#f18921", "#ff603d", "#439bff"];
