import React from "react";

import type { ToolbarController } from "../ToolbarController";

import { ToolbarIcon } from "../icons";

import styles from "./ActionsBar.module.scss";

type Props = {
    toolbar: ToolbarController;
    className?: string;
};

export const ActionsBar = ({ toolbar }: Props) => (
    <div className={styles.buttons}>
        {toolbar.actionsMenu.buttons.map((button) => (
            <button
                className={styles.button}
                key={button.key}
                onClick={() => {
                    toolbar.dispatchButtonClick(toolbar.actionsMenu, button);
                }}
            >
                <ToolbarIcon
                    className={styles.button__icon}
                    icon={button.icon}
                />
            </button>
        ))}
    </div>
);
