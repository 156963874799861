import type { ISerializedCoordinatesVector } from "./types";
import type { TSerializedElementPrototype } from "../../core/element";
import type { IElementVisitor } from "../../core/element/IElementVisitor";
import type { IStrategy } from "../../core/strategies";
import type { IMultiContainerElement } from "../../core/strategies/types";
import type { InputService } from "../../services";
import type { TContainerElements, TElementDeserializerFunction } from "../../types";

import { ContainerModel } from "../../core/container";
import { BaseElementModel } from "../../core/element";
import { MultiContainerElementStrategy } from "../../core/strategies/MultiContainerElementStrategy";

export class CoordinatesVectorModel
    extends BaseElementModel<ISerializedCoordinatesVector>
    implements IMultiContainerElement
{
    public readonly firstCoordinate: ContainerModel;
    public readonly secondCoordinate: ContainerModel;

    public constructor(
        inputService: InputService,
        firstCoordinate: TContainerElements,
        secondCoordinate: TContainerElements,
        uuid?: string
    ) {
        super(inputService, uuid);

        this.firstCoordinate = new ContainerModel(firstCoordinate, this, ["firstCoordinate"], inputService, {
            showPlaceholderIfEmpty: true,
        });

        this.secondCoordinate = new ContainerModel(secondCoordinate, this, ["secondCoordinate"], inputService, {
            showPlaceholderIfEmpty: true,
        });
    }

    getContainersToMoveCursorBetween(): ContainerModel[] {
        return [this.firstCoordinate, this.secondCoordinate];
    }

    protected override initBehaviour(): IStrategy {
        return new MultiContainerElementStrategy(this);
    }

    public serialize(): ISerializedCoordinatesVector {
        return {
            type: "coordinates-vector",
            firstCoordinate: this.firstCoordinate.serialize(),
            secondCoordinate: this.secondCoordinate.serialize(),
            uuid: this.uuid,
        };
    }

    public static deserialize: TElementDeserializerFunction<ISerializedCoordinatesVector> = (
        { inputService },
        { firstCoordinate, secondCoordinate, uuid }
    ) =>
        new CoordinatesVectorModel(
            inputService,
            inputService.deserializeContainer(firstCoordinate),
            inputService.deserializeContainer(secondCoordinate),
            uuid
        );

    public accept<R>(visitor: IElementVisitor<R>): R {
        return visitor.doWithCoordinatesVector(this);
    }

    serializeAsClone(): TSerializedElementPrototype<ISerializedCoordinatesVector> {
        const { uuid, ...clone } = this.serialize();
        return {
            ...clone,
            firstCoordinate: this.firstCoordinate.serializeAsClone(),
            secondCoordinate: this.secondCoordinate.serializeAsClone(),
        };
    }
}
