import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { observer } from "mobx-react-lite";
import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import type { TRawLoginErrors, TRawLoginRequest } from "../../services/auth/types";
import type { FC } from "react";

import LoginForm from "../../components/LoginForm";
import { useRootStore } from "../../integration";
import { setAccessToken, setRefreshToken } from "../../services/auth/utils";

import { schema } from "./Login.constants";

import styles from "./Login.module.scss";

const Login: FC = observer(function Login() {
    const { t } = useTranslation("auth");
    const store = useRootStore();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<TRawLoginRequest>({
        resolver: yupResolver(schema),
    });
    const [serverErrors, setServerErrors] = useState<TRawLoginErrors>();
    const detailError = serverErrors?.detail;

    const onSubmit = useCallback(
        async (values: TRawLoginRequest) => {
            try {
                setServerErrors(void 0);

                const { access, refresh } = await store.api.auth.login(values);
                setAccessToken(access);
                setRefreshToken(refresh);

                const userInfo = await store.api.auth.me();

                store.auth.authorize(userInfo);
            } catch (e) {
                if (axios.isAxiosError<TRawLoginErrors>(e)) {
                    setServerErrors(e.response?.data);
                }
            }
        },
        [store]
    );

    return (
        <div className={styles.container}>
            <h2>{t("loginTitle")}</h2>
            {Boolean(detailError) && <p className={styles.error}>{detailError}</p>}
            <LoginForm
                register={register}
                errors={errors}
                serverErrors={serverErrors}
                handleSubmit={handleSubmit(onSubmit)}
            />
        </div>
    );
});

export default Login;
