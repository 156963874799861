import type { TElementData, TElementDataWithRect, TElementWithRect } from "./SelectionController.types";
import type { TPoint, TRect } from "../../types";
import type { ContainerModel } from "../container";
import type { BaseElementModel } from "../element";

import { computeRectCenter, computeSquaredDistance, pointSubtraction } from "../../utils/positions";

export function getClientRects(container: ContainerModel, offset: TPoint): TElementWithRect[] {
    return container.elements
        .map((element) => {
            const elementRect = element.getRect();
            if (!elementRect) return null!;

            const rect = pointSubtraction(elementRect, offset);
            return { rect, element };
        })
        .filter(Boolean);
}

export function computeClosestElement(
    clickPoint: TPoint,
    elementsWithRects: TElementWithRect[]
): TElementDataWithRect | undefined {
    let minDistance = Number.MAX_SAFE_INTEGER;
    let closestElement: BaseElementModel | null = null;
    let closestElementRect: TRect | null = null;
    let isRight = false;
    elementsWithRects.forEach(({ element, rect }) => {
        const elementCenter = computeRectCenter(rect);
        const distance = computeSquaredDistance(elementCenter, clickPoint);

        if (distance < minDistance) {
            minDistance = distance;
            closestElement = element;
            closestElementRect = rect;

            isRight = clickPoint.x > elementCenter.x;
        }
    });

    if (closestElement && closestElementRect) {
        return {
            element: closestElement,
            isRight,
            isLeft: !isRight,
            rect: closestElementRect,
        };
    }
}

export function computeCommonElement(commonContainer: ContainerModel, data: TElementData): TElementData | undefined {
    if (commonContainer === data.element.parentContainer) {
        return data;
    }
    let currentElement = data.element;

    while (currentElement) {
        if (currentElement.parentContainer === commonContainer) {
            return {
                element: currentElement,
                isLeft: false,
                isRight: false,
            };
        }
        currentElement = currentElement.parentContainer?.parentElement;
    }
}
