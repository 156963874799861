import { computed, makeObservable, observable } from "mobx";

import { copyFragment } from "@viuch/geometry-lib/factories";
import { middlePoint } from "@viuch/geometry-lib/vectors";
import { generateId } from "@viuch/shared/utils/data";

import type { IModelVisitor } from "../BaseModel";
import type { TFragment } from "@viuch/geometry-lib/types";

import { BaseModel } from "../BaseModel";

interface IMiddleLineModel {
    base1: TFragment;
    base2: TFragment;
}

export class MiddleLineModel extends BaseModel implements IMiddleLineModel {
    base1: TFragment;
    base2: TFragment;

    constructor(data: IMiddleLineModel, id: number) {
        super(id);

        this.base1 = copyFragment(data.base1);
        this.base2 = copyFragment(data.base2);

        makeObservable(this, {
            base1: observable,
            base2: observable,
            middleLineFragment: computed,
        });
    }

    get middleLineFragment(): TFragment {
        const a = middlePoint(this.base1.a, this.base2.a);
        const b = middlePoint(this.base1.b, this.base2.b);
        return { a, b };
    }

    accept<R>(visitor: IModelVisitor<R>): R {
        return visitor.withMiddleLine(this);
    }

    static create(data: IMiddleLineModel) {
        return new MiddleLineModel(data, generateId());
    }
}
