import type { LimModel } from "./LimModel";
import type { ContainerModel } from "../../core/container";
import type { BaseElementModel } from "../../core/element";
import type { IElementFilter } from "../../core/strategies/IElementFilter";

import { MultiContainerElementStrategy } from "../../core/strategies/MultiContainerElementStrategy";

import { LimIntervalContentFilter } from "./LimIntervalContentFilter";

export class LimStrategy extends MultiContainerElementStrategy<LimModel> {
    private readonly contentToFilter: IElementFilter;
    private readonly contentFromFilter: IElementFilter;

    constructor(model: LimModel) {
        super(model);

        this.contentFromFilter = new LimIntervalContentFilter();
        this.contentToFilter = new LimIntervalContentFilter();
    }

    override checkCanBeInserted(element: BaseElementModel, container: ContainerModel<LimModel>): boolean {
        if (container === this.model.contentFrom) {
            return this.contentFromFilter.canInsert(element);
        }
        if (container === this.model.contentTo) {
            return this.contentToFilter.canInsert(element);
        }
        return super.checkCanBeInserted(element, container);
    }
}
