import { action, computed, flow, makeObservable, observable } from "mobx";
import { computedFn } from "mobx-utils";

import { generateId } from "@viuch/shared/utils/data";
import { delay } from "@viuch/utils/debug";

import type { TOnFocusHandler } from "./SolutionViewController";
import type { ISolutionExample, ISolutionExampleInnerStep, ISolutionExampleStep } from "@viuch/solution-example";

export class HintsViewController<
    TInnerStep extends ISolutionExampleInnerStep = ISolutionExampleInnerStep,
    TStep extends ISolutionExampleStep<TInnerStep> = ISolutionExampleStep<TInnerStep>,
    TExample extends Pick<ISolutionExample<TInnerStep, TStep>, "steps"> = Pick<
        ISolutionExample<TInnerStep, TStep>,
        "steps"
    >,
> {
    readonly $$instanceId = generateId();
    readonly example: TExample;

    constructor(example: TExample) {
        this.example = example;
        this.lastActiveData = { activeStepIndex: null, activeInnerStepIndex: null };

        makeObservable(this);
    }

    @observable _activeStepIndex: number | null = null;
    @observable _activeInnerStepIndex: number | null = null;

    @computed get activeStep(): TStep | null {
        if (this.example.steps.length === 0) return null;

        const { activeStepIndex } = this.activeData;

        if (activeStepIndex === null) return null;

        return this.example.steps.at(activeStepIndex) ?? null;
    }

    @computed get activeInnerStep(): TInnerStep | null {
        const { activeInnerStepIndex } = this.activeData;

        if (activeInnerStepIndex === null || !this.activeStep || this.activeStep.innerSteps.length === 0) return null;

        return this.activeStep.innerSteps.at(activeInnerStepIndex) ?? null;
    }

    getIsStepActive: BivarianceHack<(step: TStep) => boolean> = computedFn((step) => {
        return step === this.activeStep;
    });

    hasInnerStepActive: BivarianceHack<(step: TStep) => boolean> = computedFn((step) => {
        return step === this.activeStep && !!this.activeInnerStep;
    });

    getIsInnerStepActive: BivarianceHack<(innerStep: TInnerStep) => boolean> = computedFn((innerStep) => {
        return innerStep == this.activeInnerStep;
    });

    @action.bound
    toggleActiveStep(step: TStep): void {
        if (step === this.activeStep) {
            this.setActive(null, null);
        } else {
            this.setActive(this._getStepIndex(step), null);
        }
    }

    private _getStepIndex(step: TStep): number {
        return this.example.steps.indexOf(step);
    }

    private _getStep(index: number): TStep {
        return this.example.steps[index];
    }

    private _getInnerStepIndex(step: TStep, innerStep: TInnerStep): number {
        return step.innerSteps.indexOf(innerStep);
    }

    private _getInnerStep(stepIndex: number, innerStepIndex: number): TInnerStep {
        return this.example.steps[stepIndex].innerSteps[innerStepIndex];
    }

    @action.bound
    nextOn(step: TStep) {
        if (this.example.steps.length === 0) return;

        const { activeStepIndex, activeInnerStepIndex } = this.activeData;

        if (this._getStepIndex(step) === activeStepIndex) {
            // открыт текущий шаг.

            if (step.innerSteps.length > 0) {
                // тут есть подшаги.

                if (activeInnerStepIndex !== null) {
                    // сейчас открыт один из подшагов.

                    if (activeInnerStepIndex === step.innerSteps.length - 1) {
                        // Открыт последний подшаг
                        // > Закрыть подшаги и оставить открытый шаг

                        this.setActive(null, null);
                    } else {
                        // > Переключить подшаг на следующий

                        this.setActive(activeStepIndex, activeInnerStepIndex + 1);
                    }
                } else {
                    // есть подшаги, но они ещё не открыты
                    // > Открыть первый подшаг

                    this.setActive(activeStepIndex, 0);
                }
            } else {
                // подшагов нет
                // > закрыть текущий подшаг

                this.setActive(null, null);
            }
        } else {
            // выбрали другой неактивный шаг
            // > раскрыть его

            if (step.innerSteps.length > 0) {
                // внутри есть подшаги
                // > открыть первый подшаг

                this.setActive(this._getStepIndex(step), 0);
            } else {
                // внутри нет подшагов
                // > раскрыть шаг

                this.setActive(this._getStepIndex(step), null);
            }
        }
    }

    @flow.bound
    async *next() {
        if (this.example.steps.length === 0) return;

        const { activeStepIndex, activeInnerStepIndex } = this.activeData;

        if (activeInnerStepIndex !== null && activeStepIndex !== null) {
            // сейчас открыт подшаг.

            const step = this._getStep(activeStepIndex);

            const isLastInnerStep = activeInnerStepIndex === step.innerSteps.length - 1;
            if (isLastInnerStep) {
                // открыт последний подшаг.

                const isLastStep = activeStepIndex === this.example.steps.length - 1;

                if (isLastStep) {
                    // открыт последний шаг + последний подшаг
                    // > сбросить фокусировку

                    this.setActive(null, null);
                } else {
                    // открыт какой-то шаг + последний подшаг
                    // > открыть следующий шаг

                    const nextStep = this._getStep(activeStepIndex + 1);

                    if (nextStep.innerSteps.length > 0) {
                        this.setActive(activeStepIndex + 1, 0);
                    } else {
                        this.setActive(activeStepIndex + 1, null);
                    }
                }
            } else {
                // открыт не последний подшаг
                // > переключить на следующий подшаг

                this.setActive(activeStepIndex, activeInnerStepIndex + 1);
            }
        } else if (activeStepIndex !== null) {
            // сейчас открыт шаг.

            const step = this._getStep(activeStepIndex);

            const containInnerSteps = step.innerSteps.length !== 0;
            if (containInnerSteps) {
                // есть подшаги.
                // > открыть первый подшаг

                this.setActive(activeStepIndex, 0);
            } else {
                // нет подшагов.

                const isLastStep = activeStepIndex === this.example.steps.length - 1;
                if (isLastStep) {
                    // открыт последний шаг + нет подшагов
                    // > сбросить фокусировку

                    this.setActive(null, null);
                } else {
                    // открыт какой-то подшаг + нет подшагов
                    // > открыть следующий шаг

                    const nextStep = this._getStep(activeStepIndex + 1);

                    if (nextStep.innerSteps.length > 0) {
                        this.setActive(activeStepIndex + 1, 0);
                    } else {
                        this.setActive(activeStepIndex + 1, null);
                    }
                }
            }
        } else {
            // ничего не открыто.
            // > TODO: использовать последний открытый шаг
            // > открыть с нуля

            const firstStep = this._getStep(0);

            if (firstStep.innerSteps.length > 0) {
                this.setActive(0, 0);
            } else {
                this.setActive(0, null);
            }
        }

        yield;
        await delay(0);
        yield;

        this.scroll();
    }

    @flow.bound
    async *back() {
        if (this.example.steps.length === 0) return;

        const { activeStepIndex, activeInnerStepIndex } = this.activeData;

        if (activeInnerStepIndex !== null && activeStepIndex !== null) {
            // открыт шаг + подшаг.

            const isFirstInnerStep = activeInnerStepIndex === 0;
            if (isFirstInnerStep) {
                // открыт первый подшаг в шаге
                // > закрыть подшаг, оставить шаг открытым

                const isFirstStep = activeStepIndex === 0;

                if (isFirstStep) {
                    // открыт первый шаг, первый подшаг
                    // > закрыть всё

                    this.setActive(null, null);
                } else {
                    // открыт шаг, первый подшаг
                    // > открыть предыдущий шаг, последний подшаг

                    const prevStep = this._getStep(activeStepIndex - 1);

                    if (prevStep.innerSteps.length > 0) {
                        this.setActive(activeStepIndex - 1, prevStep.innerSteps.length - 1);
                    } else {
                        this.setActive(activeStepIndex - 1, null);
                    }
                }
            } else {
                // открыт какой-то подшаг в шаге (не первый)
                // > перейти на предыдущий подшаг

                this.setActive(activeStepIndex, activeInnerStepIndex - 1);
            }
        } else if (activeStepIndex !== null) {
            // открыт только шаг

            // > получить предыдущий шаг
            const isFirstStep = activeStepIndex === 0;

            if (isFirstStep) {
                // открыт первый шаг
                // > закрыть фокусировку

                this.setActive(null, null);
            } else {
                // открыт какой-то шаг
                // > найти предыдущий шаг
                const prevStepIndex = activeStepIndex - 1;
                const prevStep = this._getStep(prevStepIndex);

                const containInnerSteps = prevStep.innerSteps.length > 0;
                if (containInnerSteps) {
                    // в предыдущем шаге есть подшаги
                    // > открыть предыдущий шаг + последний подшаг

                    this.setActive(prevStepIndex, prevStep.innerSteps.length - 1);
                } else {
                    // в предыдущем шаге нет подшагов
                    // > открыть только сам шаг

                    this.setActive(prevStepIndex, null);
                }
            }
        } else {
            // ничего не открыто
            // > открыть последний шаг + последний подшаг

            const lastStepIndex = this.example.steps.length - 1;
            const lastStep = this._getStep(lastStepIndex);

            const containInnerSteps = lastStep.innerSteps.length > 0;
            if (containInnerSteps) {
                // есть подшаги
                // > открыть последний подшаг

                this.setActive(lastStepIndex, lastStep.innerSteps.length - 1);
            } else {
                // нет подшагов
                // > открыть только шаг

                this.setActive(lastStepIndex, null);
            }
        }

        // Пропустить рендер
        yield;
        await delay(0);
        yield;

        this.scroll();
    }

    @observable private lastActiveData: { activeStepIndex: number | null; activeInnerStepIndex: number | null };

    @computed
    get activeData() {
        const activeStepIndex = this._activeStepIndex;
        const activeInnerStepIndex = this._activeInnerStepIndex;

        return { activeStepIndex, activeInnerStepIndex };
    }

    private setActive(activeStepIndex: number | null, activeInnerStepIndex: number | null) {
        this._activeStepIndex = activeStepIndex;
        this._activeInnerStepIndex = activeInnerStepIndex;

        if (activeStepIndex) {
            this.lastActiveData = { activeStepIndex, activeInnerStepIndex };
        }
    }

    private scroll() {
        const index = this.activeData.activeStepIndex;

        if (index === null) return;

        this.onFocusHandlers.forEach((handler) => handler(index));
    }

    private onFocusHandlers = new Set<TOnFocusHandler>();

    subscribeFocusEvent(onFocusHandler: TOnFocusHandler): VoidFunction {
        this.onFocusHandlers.add(onFocusHandler);

        return () => {
            this.onFocusHandlers.delete(onFocusHandler);
        };
    }

    @action.bound
    setInnerStepIndex(index: number) {
        const { activeStepIndex } = this.activeData;
        this.setActive(activeStepIndex, index);
    }

    @action.bound
    closeInnerSteps(): void {
        this.setActive(null, null);
    }

    @action.bound
    reset() {
        this.setActive(null, null);
    }
}
