import { action, computed, makeObservable, observable } from "mobx";

import type {
    IInputServiceOptions,
    TElementComponent,
    TElementTypeToDeserializerMap,
    TModelToComponentMap,
} from "./InputService.types";
import type { BaseElementModel } from "../../core/element";
import type {
    IElementDeserializeOptions,
    IElementDeserializer,
    TAnyAction,
    TAnySerializedElement,
    TContainerElements,
    TElementModelClass,
    TPoint,
    TSerializedState,
} from "../../types";
import type { KeyboardService, TDispatchKeyParams } from "../keyboard";

import { ClipboardService } from "../../core/clipboard";
import { CommandsStack } from "../../core/commands";
import { ContainersStore } from "../../core/container";
import { CursorState } from "../../core/cursor";
import { ElementsStore } from "../../core/element/ElementsStore";
import { HighlightingService } from "../../core/highlighting";
import { LimitsStore } from "../../core/limits/LimitsStore";
import { LinebreaksCountLimit } from "../../core/limits/LinebreaksCountLimit";
import { DegreeNestingLimit } from "../../core/limits/nesting/DegreeNestingLimit";
import { DownIndexNestingLimit } from "../../core/limits/nesting/DownIndexNestingLimit";
import { FractionsNestingLimit } from "../../core/limits/nesting/FractionsNestingLimit";
import { IntegralNestingLimit } from "../../core/limits/nesting/IntegralNestingLimit";
import { LogNestingLimit } from "../../core/limits/nesting/LogNestingLimit";
import { ModulesNestingLimit } from "../../core/limits/nesting/ModulesNestingLimit";
import { RootNestingLimit } from "../../core/limits/nesting/RootNestingLimit";
import { FactorialsBetweenBracketsLimit } from "../../core/limits/together/FactorialsBetweenBracketsLimit";
import { FactorialsTogetherLimit } from "../../core/limits/together/FactorialsTogetherLimit";
import { PlaceholdersService } from "../../core/placeholders";
import { SelectionController } from "../../core/selection";
import { EditorModel } from "../../elements/editor/EditorModel";
import { configureInput } from "../../utils/config";
import { getClassFromInstance } from "../../utils/data";
import { getActionFromKeyParams } from "../../utils/input";
import { createContainerData } from "../../utils/serialization";

export class InputService {
    public readonly model: EditorModel;
    public readonly selectionController: SelectionController;
    public readonly runtimePlaceholdersEnabled: boolean;
    public readonly isReadOnly: boolean;
    public readonly isMultiline: boolean;
    public readonly maxNestingLevel: number;
    public readonly containers: ContainersStore;
    public readonly elements: ElementsStore;
    public readonly commands: CommandsStack;
    public readonly cursorState: CursorState;
    public readonly clipboard: ClipboardService;
    public readonly placeholders: PlaceholdersService;
    public readonly limits: LimitsStore;
    public readonly highlightingService: HighlightingService;

    public isDisabled: boolean;
    public placeholdersVisible: boolean;
    public keyboard?: KeyboardService;
    private readonly modelToComponentMap: TModelToComponentMap;
    private readonly elementTypeToDeserializerMap: TElementTypeToDeserializerMap;
    private readonly notifyBeforeAction?: (inputService: InputService, action: TAnyAction) => void;
    private readonly notifyAction?: (inputService: InputService, action: TAnyAction) => void;

    public constructor(options: IInputServiceOptions) {
        this.keyboard = options.keyboardService;
        this.modelToComponentMap = new Map();
        this.elementTypeToDeserializerMap = new Map();
        this.runtimePlaceholdersEnabled = !options.disableRuntimePlaceholders;
        this.isReadOnly = Boolean(options.isReadOnly);
        this.isMultiline = Boolean(options.multiline);
        this.maxNestingLevel = options.maxNestingLevel ?? 20;
        this.placeholdersVisible = true;

        this.limits = new LimitsStore(this);
        this.clipboard = new ClipboardService(this);
        this.containers = new ContainersStore(this);
        this.elements = new ElementsStore(this);
        this.commands = new CommandsStack();
        this.placeholders = new PlaceholdersService();
        this.highlightingService = new HighlightingService(this);
        this.notifyBeforeAction = options.notifyBeforeAction;
        this.notifyAction = options.notifyAction;
        this.isDisabled = !!options.disabled;
        this.placeholdersVisible = !options.disablePlaceholders;

        if (options.linebreaksCountLimit) {
            this.limits.add(new LinebreaksCountLimit(options.linebreaksCountLimit));
        }

        this.limits.add(new DegreeNestingLimit(3));
        this.limits.add(new RootNestingLimit(3));
        this.limits.add(new FractionsNestingLimit(4));
        this.limits.add(new ModulesNestingLimit(3));
        this.limits.add(new FactorialsTogetherLimit(3));
        this.limits.add(new FactorialsBetweenBracketsLimit(3));
        this.limits.add(new IntegralNestingLimit(4));
        this.limits.add(new LogNestingLimit(2));
        this.limits.add(new DownIndexNestingLimit(1));

        this.model = new EditorModel(createContainerData(), this);
        this.cursorState = new CursorState(this.model.rootContainer);
        this.selectionController = new SelectionController(this);

        configureInput(this);

        makeObservable(this, {
            isInputFocused: computed,
            keyboard: observable,
            setKeyboard: action,
            dispatchAction: action,
            isDisabled: observable,
            setDisabled: action,
            placeholdersVisible: observable,
            setPlaceholdersVisible: action,
        });

        if (options.initialState) {
            this.setSerializedState(options.initialState);
        }

        if (options.focus) {
            this.focus();
        }
    }

    get isInputFocused(): boolean {
        return this.model.isFocused;
    }

    public registerElement<M extends BaseElementModel<S>, S extends TAnySerializedElement>(
        ModelClass: TElementModelClass<M> & IElementDeserializer<S>,
        ReactComponent: TElementComponent<M>,
        type: S["type"]
    ): this {
        this.modelToComponentMap.set(
            ModelClass,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ReactComponent as any // typescript: баг с React.FC['propTypes']
        );
        this.elementTypeToDeserializerMap.set<S>(type, ModelClass);
        return this;
    }

    public getReactComponent<M extends BaseElementModel>(modelInstance: M): TElementComponent<M> {
        return this.modelToComponentMap.get<M>(getClassFromInstance(modelInstance));
    }

    public dispatchKeyboardEvent = (keyParams: TDispatchKeyParams): void => {
        const action = getActionFromKeyParams(keyParams);

        if (action) {
            queueMicrotask(() => {
                this.dispatchAction(action);
            });
        }
    };

    public dispatchAction<A extends TAnyAction>(action: A): void {
        if (this.isReadOnly || this.isDisabled) {
            return;
        }

        this.notifyBeforeAction?.(this, action);

        if (this.selectionController.isSelectedSomething) {
            const container = this.selectionController.commonContainer;
            container?.handleAction(action);
        } else {
            this.cursorState.container.handleAction(action);
        }

        this.notifyAction?.(this, action);
    }

    public getSerializedState(): TSerializedState {
        const elements = this.model.serializeAsClone().content.elements;
        const highlights = this.highlightingService.serializeHighlights();

        return { elements, highlights };
    }

    public setSerializedState(persistedData: TSerializedState): void {
        this.model.setElements(this.deserializeContainer(persistedData).elements);
        this.cursorState.reset(this.model.rootContainer);
        if (persistedData.highlights) {
            this.highlightingService.setHighlights(persistedData.highlights);
        }
    }

    public deserializeElement<T extends TAnySerializedElement = TAnySerializedElement>(
        element: T
    ): BaseElementModel<T> {
        return this.deserializeElementInternal(element, this.getDeserializerOptions());
    }

    public deserializeContainer(content: TSerializedState): TContainerElements {
        const options = this.getDeserializerOptions();
        const elements = content.elements.reduce<BaseElementModel[]>((elements, json) => {
            const element = this.deserializeElementInternal(json, options);
            if (element) {
                elements.push(element);
            }
            return elements;
        }, []);
        return { uuid: content?.uuid, elements };
    }

    public getDomElementOffset(): TPoint {
        return this.model.getOffsetPoint();
    }

    public initTouchSelection(point: TPoint, offset: TPoint): void {
        this.selectionController.notifyTouchSelectionInit(point, offset);
    }

    public focus() {
        this.model.handleFocus();
    }

    public getElementsCount(): number {
        return this.model.rootContainer.elements.length;
    }

    setKeyboard(keyboard: KeyboardService) {
        this.keyboard = keyboard;
    }

    private getDeserializerOptions(): IElementDeserializeOptions {
        return { inputService: this };
    }

    private deserializeElementInternal<T extends TAnySerializedElement = TAnySerializedElement>(
        element: T,
        options: IElementDeserializeOptions
    ): BaseElementModel<T> {
        const type = element.type;
        const deserializer = this.elementTypeToDeserializerMap.get<T>(type);

        return deserializer.deserialize(options, element);
    }

    public setDisabled(disabled: boolean) {
        this.isDisabled = disabled;
    }

    public setPlaceholdersVisible(visible: boolean) {
        this.placeholdersVisible = visible;
    }
}
