import { copyEllipse, copyFragment, copyPoint } from "@viuch/geometry-lib/factories";
import {
    intersectionEllipseHorizontal,
    intersectionEllipseVertical,
    intersectionLineFragment,
} from "@viuch/geometry-lib/intersection";
import { subtractVectors, vectorLength } from "@viuch/geometry-lib/vectors";
import { normalizeNumber } from "@viuch/shared/utils/math/normalize";
import { roundToStep } from "@viuch/shared/utils/math/round";

import type { Figure2DController } from "../Figure2DController";
import type { TEllipse, TFragment, TPoint } from "@viuch/geometry-lib/types";

import { snapEllipsesVisitor, snapFragmentsVisitor, snapPointsVisitor } from "./utils";

export class SnapToGridService {
    private readonly data: Figure2DController;

    private snapGridData?: { weight: number };
    private modelPoints: Array<{ point: TPoint }>;
    private modelFragments: Array<{ fragment: TFragment }>;
    private modelEllipses: Array<{ ellipse: TEllipse }>;

    constructor(data: Figure2DController) {
        this.data = data;
        this.modelPoints = [];
        this.modelFragments = [];
        this.modelEllipses = [];
    }

    private get viewport() {
        return this.data.viewport;
    }

    private get settings() {
        return this.data.figure.settings;
    }

    /**
     * Включает учёт сетки в расчёты
     */
    addViewportGrid() {
        this.snapGridData = { weight: 1 };
        return this;
    }

    addFigureModelsPoints() {
        const figure = this.data.figure;
        figure.models.flatMap((model) => model.accept(snapPointsVisitor)).forEach((point) => this.addPoint(point));

        figure.models
            .flatMap((model) => model.accept(snapFragmentsVisitor))
            .forEach((fragment) => this.addFragment(fragment));

        figure.models
            .flatMap((model) => model.accept(snapEllipsesVisitor))
            .forEach((ellipse) => this.addEllipse(ellipse));

        return this;
    }

    addPoint(point: TPoint) {
        const p = copyPoint(point);
        this.modelPoints.push({ point: p });

        return this;
    }

    addFragment(fragment: TFragment) {
        this.modelFragments.push({ fragment: copyFragment(fragment) });
    }

    addEllipse(ellipse: TEllipse) {
        this.modelEllipses.push({ ellipse: copyEllipse(ellipse) });
    }

    clean() {
        this.modelPoints = [];
        this.modelFragments = [];
        this.modelEllipses = [];
        this.snapGridData = void 0;
        return this;
    }

    private getIntersectionLineWithGridPoints(
        fragment: TFragment,
        gridSnapPoints: { x: number; y: number; _x: number; _y: number }
    ): TPoint[] {
        const { x, y, _x, _y } = gridSnapPoints;

        return [
            intersectionLineFragment({ x1: x, x2: x, y1: 0, y2: 1 }, fragment),
            intersectionLineFragment({ x1: _x, x2: _x, y1: 0, y2: 1 }, fragment),
            intersectionLineFragment({ x1: 0, x2: 1, y1: y, y2: y }, fragment),
            intersectionLineFragment({ x1: 0, x2: 1, y1: _y, y2: _y }, fragment),
        ].filter<TPoint>(Boolean);
    }

    snap<D>(point: TPoint, defaultValue: D): TPoint | D;
    snap(point: TPoint): TPoint | null;
    snap(point: TPoint, defaultValue?: null): TPoint | null {
        const { scale } = this.viewport;

        /*

        Точки графика имеют радиус привязки.
        В первую очередь нужно обойти такие точки.

         */

        let minDistancePoint: { d: number; p: TPoint } | undefined;
        const hitDistance = 2 / 40; // 2 мелких квадратика сетки

        const candidate = (p: TPoint): void => {
            const distance = vectorLength(subtractVectors(point, p));

            if (distance * scale <= hitDistance) {
                if (!minDistancePoint || minDistancePoint.d > distance) {
                    minDistancePoint = { d: distance, p };
                }
            }
        };

        for (const { point: p } of this.modelPoints) {
            candidate(p);
        }

        if (minDistancePoint) {
            return minDistancePoint.p;
        }

        /*

        Кроме точек есть отрезки.
        Сделать привязку к отрезкам.
        Точнее, к пересечению отрезков и сетки

         */

        const gridSnapPoint = this.getGridSnapLines(point);

        for (const { fragment } of this.modelFragments) {
            const fragmentPoints = this.getIntersectionLineWithGridPoints(fragment, gridSnapPoint);

            for (const p of fragmentPoints) {
                candidate(p);
            }
        }

        if (minDistancePoint) {
            // @ts-expect-error TS не учёл, что let-переменная изменилась за это время
            return minDistancePoint.p;
        }

        /*

        Следующие по приоритету - окружности.
        Так же как и с отрезками, ищем пересечения всех окружностей с координатной сеткой

         */

        for (const { ellipse } of this.modelEllipses) {
            const { x: x1, y: y1, _x: _x1, _y: _y1, _x2, _y2, x2, y2 } = gridSnapPoint;

            for (const { x: _x, y: _y } of [
                { x: x1, y: y1 },
                { x: _x1, y: _y1 },
                { x: _x2, y: _y2 },
                { x: x2, y: y2 },
            ]) {
                let coordinates: [y1: number, y2: number] | null = null;

                if ((coordinates = intersectionEllipseVertical(ellipse, _x))) {
                    const [y1, y2] = coordinates;

                    candidate({ x: _x, y: y1 });
                    candidate({ x: _x, y: y2 });
                }
                if ((coordinates = intersectionEllipseHorizontal(ellipse, _y))) {
                    const [x1, x2] = coordinates;

                    candidate({ x: x1, y: _y });
                    candidate({ x: x2, y: _y });
                }
            }
        }

        if (minDistancePoint) {
            // @ts-expect-error TS не учёл, что let-переменная изменилась за это время
            return minDistancePoint.p;
        }

        /*
        К элементам не подходит. Остаётся только привязать к сетке.
         */

        if (this.snapGridData) {
            const { x, y } = gridSnapPoint;
            return { x, y };
        }

        // Либо default значение

        return defaultValue ?? null;
    }

    private getGridSnapLines(point: TPoint) {
        const viewport = this.viewport;
        const settings = this.settings;

        const scale = Math.pow(2, Math.floor(Math.log2(viewport.scale)));
        const gridSteps = (scale * settings.secondaryGridSteps) / this.data.device.gridDivider;

        const x =
            roundToStep(
                normalizeNumber(point.x, {
                    min: 0,
                    max: viewport.scale,
                }) / scale,
                gridSteps * scale + 1
            ) * scale;

        const y =
            roundToStep(
                normalizeNumber(point.y, {
                    min: 0,
                    max: viewport.scale,
                }) / scale,
                gridSteps * scale + 1
            ) * scale;

        const step = 1 / gridSteps;

        const stepX = point.x - x >= 0 ? +step : -step;
        const stepY = point.y - y >= 0 ? +step : -step;

        const _x = x + stepX;
        const _y = y + stepY;

        const _x2 = x + 2 * stepX;
        const _y2 = y + 2 * stepY;

        const x2 = x + 2 - stepX;
        const y2 = y + 2 - stepY;

        return { x, y, _x, _y, x2, y2, _x2, _y2 };
    }
}
