import { action, makeObservable, observable } from "mobx";

import { generateId } from "@viuch/shared/utils/data";

import type { TGraph1DDotForms } from "./constants";
import type { IGraph1DInstrumentStatementDot } from "./types";
import type { TSerializedState } from "@viuch/math-editor";

export class Graph1DInstrumentStatementDot implements IGraph1DInstrumentStatementDot {
    readonly $$instanceId = generateId();
    @observable.ref name: TSerializedState;
    @observable.ref value: TSerializedState;
    @observable.ref position: number;
    @observable.ref form: TGraph1DDotForms;
    readonly isProtected: boolean;

    @observable.ref isNameVisible: boolean;
    @observable.ref isValueVisible: boolean;

    constructor(data: IGraph1DInstrumentStatementDot) {
        this.name = data.name;
        this.value = data.value;
        this.position = data.position;
        this.form = data.form;
        this.isProtected = data.isProtected;
        this.isNameVisible = data.isNameVisible ?? true;
        this.isValueVisible = data.isValueVisible ?? true;

        makeObservable(this);
    }

    clone(): Graph1DInstrumentStatementDot {
        return new Graph1DInstrumentStatementDot(this);
    }

    @action.bound
    setName(name: TSerializedState) {
        this.name = name;
    }

    @action.bound
    setValue(value: TSerializedState) {
        this.value = value;
    }

    @action.bound
    setPosition(position: number) {
        this.position = position;
    }

    @action.bound
    setIsNameVisible(value: boolean) {
        this.isNameVisible = value;
    }

    @action.bound
    setIsValueVisible(value: boolean) {
        this.isValueVisible = value;
    }
}
