import { observer } from "mobx-react-lite";
import React from "react";

type Props = {
    x: number | string;
    y: number | string;
    direction: "v" | "h";
    color: string;
};

export const StrokeView = observer(function StrokeView({ x, y, direction, color }: Props) {
    const k = 3;
    const dx = direction === "h" ? k : 0;
    const dy = direction === "v" ? k : 0;

    return (
        <svg
            width={1}
            height={1}
            x={x}
            y={y}
        >
            <line
                x1={dx}
                x2={-dx}
                y1={dy}
                y2={-dy}
                strokeWidth={1}
                stroke={color}
            />
        </svg>
    );
});
