import { action, makeObservable, observable } from "mobx";

import { deepClone } from "@viuch/shared/utils/data/copy";

import type { TAnswerAdmin } from "../../services/feedback/AnswersService.types";

export class Answer {
    constructor(id: number, text: string, questionId: number, admin: TAnswerAdmin, createdAt?: Date, updatedAt?: Date) {
        this.id = id;
        this.text = text;
        this.questionId = questionId;
        this.admin = admin;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;

        makeObservable(this);
    }

    readonly id: number;
    @observable text: string;
    readonly questionId: number;
    readonly admin: TAnswerAdmin;
    readonly createdAt?: Date;
    readonly updatedAt?: Date;

    @action.bound
    setText(text: string) {
        this.text = text;
    }

    clone() {
        return new Answer(this.id, this.text, this.questionId, deepClone(this.admin), this.createdAt, this.updatedAt);
    }
}
