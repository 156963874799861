import type { BaseVariableContent } from "../../../entities/tasks/variables";
import type { TTaskVariableResponse, TTaskAnswerResponse } from "../types";

import { VariableGenerationTypes } from "../../../entities/tasks";
import { Answer } from "../../../entities/tasks/Answer";
import { Variable } from "../../../entities/tasks/Variable";
import {
    DrawingSettingsVariableContent,
    IntervalVariableContent,
    NumberVariableContent,
    RandomVariableContent,
    RandomVariableWithDimensionsContent,
    SolverVariableContent,
} from "../../../entities/tasks/variables";
import { UnknownVariableContent } from "../../../entities/tasks/variables/BaseVariableContent";
import { NumericSequenceVariableContent } from "../../../entities/tasks/variables/NumericSequenceVariableContent";
import { RandomChoiceVariableContent } from "../../../entities/tasks/variables/RandomChoiceVariableContent";
import { ShuffleVariableContent } from "../../../entities/tasks/variables/ShuffleVariableContent";
import { TextMappingVariableContent } from "../../../entities/tasks/variables/TextMappingVariableContent";
import { TextVariableContent } from "../../../entities/tasks/variables/TextVariableContent";

import { mapDrawingSettings } from "./generatedDrawing";
import { mapSolverRequest } from "./mapSolverRequest";

export function mapVariable(data: TTaskVariableResponse): Variable {
    return new Variable(data.name, data.generation_type, mapVariableContent(data));
}

export function mapAnswer(data: TTaskAnswerResponse): Answer {
    return new Answer<BaseVariableContent>(data.description, data.name, data.generation_type, mapVariableContent(data));
}

export function mapVariableContent(data: TTaskVariableResponse): BaseVariableContent {
    switch (data.generation_type) {
        case VariableGenerationTypes.random:
            return new RandomVariableContent({
                left_range: data.content.left_range,
                right_range: data.content.right_range,
                quantity_fractional_digits: data.content.quantity_fractional_digits || 0,
                step_by: data.content.step_by,
                shift_start: data.content.shift_start,
                conditions: data.content.conditions ?? [],
            });
        case VariableGenerationTypes.randomWithDimension:
            return new RandomVariableWithDimensionsContent({
                leftRange: data.content.left_range,
                rightRange: data.content.right_range,
                quantityFractionalDigits: data.content.quantity_fractional_digits,
                targetDimension: data.content.target,
                sourceDimension: data.content.dimension,
            });
        case VariableGenerationTypes.number:
            return new NumberVariableContent({
                formula: data.content.formula,
                quantity_fractional_digits: data.content.quantity_fractional_digits || 0,
                multiple_of: data.content.multiple_of ?? [],
                conditions: data.content.conditions ?? [],
            });
        case VariableGenerationTypes.interval:
            return new IntervalVariableContent({
                left_range: data.content.left_range,
                right_range: data.content.right_range,
            });
        case VariableGenerationTypes.solver:
            return new SolverVariableContent({
                request: mapSolverRequest(data.content.request),
            });
        case VariableGenerationTypes.generatedDrawing:
            return new DrawingSettingsVariableContent({
                settings: mapDrawingSettings(data.content.drawing_settings),
            });
        case VariableGenerationTypes.text:
            return new TextVariableContent(data.content.formula);
        case VariableGenerationTypes.randomChoice:
            return new RandomChoiceVariableContent(data.content.choices);
        case VariableGenerationTypes.numericSequence: {
            const { count, formula, index_symbol, separator } = data.content;
            return new NumericSequenceVariableContent(formula, count, separator, index_symbol);
        }
        case VariableGenerationTypes.shuffle: {
            const { formula, quantity_fractional_digits } = data.content;
            return new ShuffleVariableContent({ formula, quantity_fractional_digits });
        }
        case VariableGenerationTypes.textMapping: {
            return new TextMappingVariableContent({
                key: data.content.key,
                mapping: data.content.mapping,
            });
        }
    }

    return new UnknownVariableContent(data);
}
