import type { TRawComputeResponse, TRawComputeRequest, TRawComputedVariablesMap } from "./types";
import type { TTaskVariableRequest } from "../tasks";

import { agent } from "../../agent";
import { throwServiceError } from "../base/RequestError";

export class TaskUtilsService {
    async compute(request: TRawComputeRequest): Promise<TRawComputeResponse> {
        try {
            const { data } = await agent.post<TRawComputeResponse>(`/task/util/compute/`, request);

            return data;
        } catch (e) {
            throwServiceError(e);
        }
    }

    async generateVariables(variables: TTaskVariableRequest[]): Promise<TRawComputedVariablesMap> {
        try {
            const request = { variables };
            const { data } = await agent.post<TRawComputedVariablesMap>(`/task/util/generate_variables/`, request);

            return data;
        } catch (e) {
            throwServiceError(e);
        }
    }
}
