import { mapDate } from "@viuch/shared/serialization/dates";
import { mapTimeToSeconds } from "@viuch/shared/serialization/times";

import type {
    TRawImprovement,
    TRawVerification_SolutionField,
    TRawSolutionVariant,
    TRawStudent,
    TRawStudentSolution,
    TRawStudentSolutionAttempt,
    TRawThemeAdvancementLogEntry,
    TRawThemeAdvancementLogTheme,
    TRawVerificationResult,
} from "./types";

import { StudentSolution } from "../../entities/solutions/StudentSolution";
import {
    Improvement,
    StudentSolutionAttemptThemeAdvancement,
    StudentSolutionAttempt,
    StudentSolutionAttemptAnalysis,
    StudentSolutionAttemptGrade,
    ThemeAdvancement,
} from "../../entities/solutions/StudentSolutionAttempt";
import { StudentSolutionVariant } from "../../entities/solutions/StudentSolutionVariant";
import { Student } from "../../entities/students/Student";

export function mapStudent(data: TRawStudent): Student {
    const { id, first_name, email, subscription_status, phone } = data;
    return new Student(id, first_name, email === "" ? null : email, phone === "" ? null : phone, subscription_status);
}

export function mapStudentSolution(data: TRawStudentSolution): StudentSolution {
    const { id, student, variant, solution, answers, instruments, is_accounted_for_theme_score } = data;

    return new StudentSolution(
        id,
        mapSolutionVariant(variant),
        student,
        solution,
        answers,
        is_accounted_for_theme_score
    );
}

function mapSolutionVariant(variant: TRawSolutionVariant): StudentSolutionVariant {
    const { id, formula, theme_id, theme_name } = variant;

    return new StudentSolutionVariant(id, formula, theme_id, theme_name);
}

export function mapStudentSolutionAttempt(data: TRawStudentSolutionAttempt): StudentSolutionAttempt {
    const {
        number,
        solution,
        solution_text,
        answers,
        created_at,
        total_time,
        is_accounted_for_theme_score,
        is_hint_request,
        advancement_entries,
        verification_result,
    } = data;

    return new StudentSolutionAttempt(
        number,
        mapDate(created_at),
        solution,
        is_accounted_for_theme_score,
        is_hint_request,
        mapTimeToSeconds(total_time),
        solution_text,
        answers,
        mapStudentSolutionAttemptAnalysis(verification_result),
        advancement_entries.map(mapStudentSolutionAttemptAdvancement)
    );
}

function mapStudentSolutionAttemptAnalysis({
    solution,
    answers,
    theme_id,
    time_spent,
    improvement,
    hint,
}: TRawVerificationResult): StudentSolutionAttemptAnalysis {
    return new StudentSolutionAttemptAnalysis(
        theme_id,
        solution.map(mapStudentSolutionAttemptGrade),
        answers.map(mapStudentSolutionAttemptGrade),
        hint,
        time_spent ?? 0,
        improvement ? mapImprovement(improvement) : null
    );
}

function mapAdvancementTheme(data: TRawThemeAdvancementLogTheme): ThemeAdvancement {
    const { theme, importance_score, state, knowledge_level, average_time } = data;
    return new ThemeAdvancement(theme, importance_score, knowledge_level, average_time, state);
}

function mapStudentSolutionAttemptAdvancement({
    created_at,
    old_knowledge_level,
    new_knowledge_level,
    total_time_spent,
    theme,
}: TRawThemeAdvancementLogEntry): StudentSolutionAttemptThemeAdvancement {
    return new StudentSolutionAttemptThemeAdvancement(
        old_knowledge_level,
        new_knowledge_level,
        mapTimeToSeconds(total_time_spent),
        mapDate(created_at),
        mapAdvancementTheme(theme)
    );
}

export function mapStudentSolutionAttemptGrade(data: TRawVerification_SolutionField): StudentSolutionAttemptGrade {
    const { is_valid, source, expression, theme_id, theme_name } = data;
    return new StudentSolutionAttemptGrade(is_valid, expression, source, theme_id, theme_name);
}

export function mapImprovement(data: TRawImprovement): Improvement {
    const { old_score, new_score, max_score } = data;
    return new Improvement(old_score, new_score, max_score);
}
