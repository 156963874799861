import type { TAnyControl, TFormControl } from "@viuch/form-builder/types/controls";

export const rendererConfigFields: TAnyControl[] = [
    {
        path: "./division_representation",
        control: "Select",
        label: "Отображение выражений с делением",
        initialValue: "fraction",
        data: {
            type: "static-items",
            items: [
                { value: "fraction", label: "В виде дроби" },
                { value: "division", label: "С использованием знака разделить" },
            ],
        },
    },
    {
        path: "./max_decimal_digits",
        control: "Number",
        label: "Знаков после запятой",
        min: 0,
        step: 1,
    },
    {
        path: "./verbosity",
        control: "Select",
        label: "Уровень подробности решения",
        initialValue: "0",
        data: {
            type: "static-items",
            items: [
                { value: 0, label: "Стандартный" },
                { value: 1, label: "Подробный" },
            ],
        },
    },
];

export const rendererConfigFormControl: TFormControl = {
    control: "Form",
    layout: "column",
    controls: [
        {
            path: "./renderer_config",
            control: "Checkbox",
            label: "Форматирование",
            virtual: true,
        },
        {
            path: "./renderer_config",
            control: "Form",
            layout: "column",
            condition: { ".": true },
            controls: rendererConfigFields,
        },
    ],
};
