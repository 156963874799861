// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck

export class ObjectBuilder {
    root: unknown;

    constructor(initial?: unknown) {
        this.root = initial;
    }

    addValueOnPath(path: (string | number)[], value: unknown): this {
        if (path.length === 0) {
            this.root = value;
        }

        if (typeof this.root === "undefined") {
            if (typeof path[0] === "string") {
                this.root = {};
            } else {
                this.root = [];
            }
        }

        let current = this.root;

        for (let i = 0; i < path.length - 1; i++) {
            const currentPathSegment = path[i];
            const nextPathSegment = path[i + 1];

            if (typeof nextPathSegment === "string") {
                if (typeof current[currentPathSegment] !== "object" || !current[currentPathSegment]) {
                    current[currentPathSegment] = {};
                }
                current = current[currentPathSegment];
            }

            if (typeof nextPathSegment === "number") {
                if (!Array.isArray(current[currentPathSegment])) {
                    current[currentPathSegment] = [];
                }
                current = current[currentPathSegment];
            }
        }

        current[path[path.length - 1]] = value;

        return this;
    }

    build() {
        return this.root;
    }
}
