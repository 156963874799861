import { flow, makeObservable, observable } from "mobx";

import { Graph2DModel } from "@viuch/instrument-graph2d/core/Graph2DModel";
import { Graph2DView } from "@viuch/instrument-graph2d/core/Graph2DView";
import { Graph2DViewSettings } from "@viuch/instrument-graph2d/editor/core/Graph2DViewSettings";

import type { IGraph2DSettingsHost } from "../interfaces/IGraph2DSettingsHost";
import type { Graph2DInstrumentSettings } from "../settings-entity/Graph2DInstrumentSettings";

export class Graph2DInstrumentSettingsEditorStore {
    private readonly host: IGraph2DSettingsHost;

    readonly settings: Graph2DInstrumentSettings;
    @observable.ref model: Graph2DModel;
    @observable.ref view: Graph2DView;

    constructor(settings: Graph2DInstrumentSettings, host: IGraph2DSettingsHost) {
        this.host = host;

        this.settings = settings;
        this.model = new Graph2DModel(host.publicKey);
        this.view = new Graph2DView(this.model, new Graph2DViewSettings(), true);

        makeObservable(this);
    }

    @observable.ref loading = false;
    @observable.ref error = false;

    @flow.bound
    async *buildGraph() {
        if (this.loading) return;

        this.loading = true;
        this.error = false;

        try {
            const { model, settings } = await this.host.createGraph2DInstrumentFromSettingsAsync(this.settings);
            yield;

            this.model = model;
            this.view = new Graph2DView(this.model, settings, true);
        } catch (e) {
            yield;
            this.error = true;
            console.error(e);
            return;
        } finally {
            this.loading = false;
        }
    }
}
