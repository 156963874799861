import { useEffect, useState } from "react";

export function useDebouncedState<T>(value: T, ms: number): T {
    const [debouncedSearch, setDebouncedSearch] = useState(value);

    useEffect(() => {
        const t = setTimeout(() => {
            setDebouncedSearch(value);
        }, ms);

        return () => {
            clearTimeout(t);
        };
    }, [ms, value]);

    return debouncedSearch;
}
