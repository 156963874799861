import { selectTableCellSerializer } from "@viuch/feature-instrument-settings/serializers/serializeTable";
import { isZeroLengthMathExpr } from "@viuch/math-editor";

import type { TableSelectTaskInstrumentSettings } from "../../../entities/task-instruments-settings/BaseTaskInstrumentSettings";
import type {
    TSelectTableInstrumentSettings,
    TRawHiddenCellPosition,
} from "@viuch/feature-instrument-settings/service-types/tables";

export function serializeSelectTableTaskSettings(
    model: TableSelectTaskInstrumentSettings
): TSelectTableInstrumentSettings {
    const { cells, title, viewSettings, renderInFrame } = model.instrument;

    return {
        instrument_type: "table-select",
        view_settings: viewSettings.serialize(),
        render_in_frame: renderInFrame,
        cells: cells.map((row) => row.map((cell) => cell.accept(selectTableCellSerializer))),
        title: isZeroLengthMathExpr(title) ? void 0 : title,
        settings_uuid: model.settingsUuid,
        hidden_cells: cells.reduce<TRawHiddenCellPosition[]>((hiddenCells, row, i) => {
            row.forEach((cell, j) => {
                if (cell.isHidden) {
                    hiddenCells.push({ row: i, col: j });
                }
            });

            return hiddenCells;
        }, []),
    };
}
