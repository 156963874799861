import { action, makeObservable, observable } from "mobx";

import { deepClone } from "@viuch/shared/utils/data/copy";

import type { TPictureImage } from "../../services/image-field";
import type { IndexingEntityMetadata } from "../indexing/IndexingEntityMetadata";
import type { TComplexEditorUnion } from "@viuch/complex-editor";

export type TBlogPostViewModel = {
    saveAsync(): Promise<void>;
};

export class BlogPost {
    @observable id: number;
    @observable name: string;
    @observable description: string;
    @observable content: TComplexEditorUnion;
    @observable isArchived: boolean;
    @observable image: TPictureImage | null;
    @observable createdAt?: Date;
    readonly indexing: IndexingEntityMetadata;

    constructor(
        id: number,
        name: string,
        description: string,
        content: TComplexEditorUnion,
        image: TPictureImage | null,
        isArchived: boolean,
        indexing: IndexingEntityMetadata,
        createdAt?: Date
    ) {
        this.id = id;
        this.name = name;
        this.description = description;
        this.content = deepClone(content);
        this.isArchived = isArchived;
        this.image = image;

        this.createdAt = createdAt;
        this.indexing = indexing;

        makeObservable(this);
    }

    @action.bound
    setName(name: string) {
        this.name = name;
    }

    @action.bound
    setDescription(description: string) {
        this.description = description;
    }

    @action.bound
    setImage(image: TPictureImage) {
        this.image = image;
    }

    @action.bound
    setContent(content: TComplexEditorUnion) {
        this.content = content;
    }

    attachedViewModel?: TBlogPostViewModel;

    attachViewModel(viewModel: TBlogPostViewModel): void {
        this.attachedViewModel = viewModel;
    }

    detachViewModel(viewModel: TBlogPostViewModel) {
        if (viewModel === this.attachedViewModel) {
            this.attachedViewModel = void 0;
        }
    }
}
