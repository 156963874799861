import { computed, makeObservable, observable } from "mobx";
import { createTransformer } from "mobx-utils";

import { getAngles, normalizeAngle } from "@viuch/geometry-lib/angles";
import { areSameAngles } from "@viuch/geometry-lib/check-geometry";
import { createAngle } from "@viuch/geometry-lib/factories";
import { filterEqualAngles } from "@viuch/geometry-lib/filters";

import type { BaseElement } from "../../elements";
import type { Figure2DController } from "../../Figure2DController";
import type { BisectionModel } from "../../models/geometry";
import type { ToolbarMenu } from "../../toolbar";
import type { IUserPointer } from "../../viewport/types";
import type { TAngle, TPoint } from "@viuch/geometry-lib/types";
import type React from "react";

import { AngleElement } from "../../elements/angle";
import { DotElement } from "../../elements/dot";
import { ElementColor } from "../../elements/ElementColor";
import { LineElement } from "../../elements/line";
import { EqualAnglesModel } from "../../models/constraints";
import { LabelPointModel } from "../../models/label-point";
import { PointModel } from "../../models/point";
import { checkModelType, ModelTypes } from "../../services/actions/utils";
import { ButtonStates } from "../../toolbar";
import { TooltipMenu } from "../../toolbar/tooltip";
import { getNewEqualAnglesSegmentsCount } from "../../utils/strokes-count";
import { createDefaultToolbarMenuBuilder } from "../../utils/toolbar";
import { BaseFlow } from "../BaseFlow";
import { createMapModelToElements, opacifyColor } from "../utils";

type TEqualAnglesFlowStages =
    | {
          stage: 1;
          start?: TPoint;
      }
    | {
          stage: 2;
          start: TPoint;
          vertex?: TPoint;
      }
    | {
          stage: 3;
          start: TPoint;
          vertex: TPoint;
          end?: TPoint;
      };

export class EqualAnglesFlow extends BaseFlow {
    flow: {
        angles: TAngle[];
        current: TEqualAnglesFlowStages;
    };

    constructor(data: Figure2DController) {
        super(data);

        this.flow = {
            angles: [],
            current: { stage: 1 },
        };

        makeObservable(this, {
            flow: observable,
            tempElements: computed,
        });
    }

    static create(data: Figure2DController) {
        return new EqualAnglesFlow(data);
    }

    attach(): void {
        this.snap.addFigureModelsPoints();
    }

    dispose(): void {
        this.snap.clean();
    }

    override handleViewportPointerEvent(e: React.PointerEvent, pointer: IUserPointer): void {
        if (!e.isPrimary) {
            return;
        }

        if (e.type === "pointercancel") {
            return this.nextFlow();
        }

        const snapPoint = this.snap.snap(pointer.canvas);

        if (snapPoint) {
            this.updateCurrentPointPosition(e, snapPoint);
            this.updateStages(e, snapPoint);
        } else {
            this.handleNotPoint(e);
        }
    }

    private render = createTransformer(createMapModelToElements(opacifyColor));

    protected renderElements(): BaseElement[] {
        return [...this.figure.models.flatMap(this.render), ...this.tempElements];
    }

    private renderAngles(): BaseElement[] {
        const color = ElementColor.Building;

        return this.flow.angles.flatMap<BaseElement>((angle, i) => {
            const a = normalizeAngle(angle, true);
            const { start, vertex, end } = a;
            const { startAngle, endAngle } = getAngles(a);

            return [
                new DotElement({
                    id: `angle_${i}__vertex`,
                    x: vertex.x,
                    y: vertex.y,
                    color,
                    overrideRenderOrder: 0,
                }),
                new LineElement({
                    id: `angle_${i}__startLine`,
                    x1: vertex.x,
                    y1: vertex.y,
                    x2: start.x,
                    y2: start.y,
                    color,
                    overrideRenderOrder: 0,
                }),
                new LineElement({
                    id: `angle_${i}__endLine`,
                    x1: vertex.x,
                    y1: vertex.y,
                    x2: end.x,
                    y2: end.y,
                    color,
                    overrideRenderOrder: 0,
                }),
                new AngleElement({
                    id: `angle_${i}__angle`,
                    x: vertex.x,
                    y: vertex.y,
                    color,
                    angleStart: startAngle,
                    angleEnd: endAngle,
                    isRight: false,
                    overrideRenderOrder: 0,
                }),
            ];
        });
    }

    private renderNewAngle(): BaseElement[] {
        const { current } = this.flow;
        const color = ElementColor.Building;
        const overrideRenderOrder = 0;

        if (current.stage === 1) {
            if (!current.start) return [];
            return [
                new DotElement({
                    id: "temp__start",
                    x: current.start.x,
                    y: current.start.y,
                    color: ElementColor.Selected,
                    overrideRenderOrder,
                }),
            ];
        }

        if (current.stage === 2) {
            if (!current.vertex) {
                return [
                    new DotElement({
                        id: "temp__start",
                        x: current.start.x,
                        y: current.start.y,
                        color,
                        overrideRenderOrder,
                    }),
                ];
            }
            return [
                new DotElement({
                    id: "temp__vertex",
                    x: current.start.x,
                    y: current.start.y,
                    color,
                    overrideRenderOrder,
                }),
                new LineElement({
                    id: "temp__lineStart",
                    x1: current.vertex.x,
                    y1: current.vertex.y,
                    x2: current.start.x,
                    y2: current.start.y,
                    color: ElementColor.Selected,
                    overrideRenderOrder,
                }),
            ];
        }

        if (current.stage === 3) {
            if (!current.end) {
                return [
                    new DotElement({
                        id: "temp__vertex",
                        x: current.start.x,
                        y: current.start.y,
                        color,
                        overrideRenderOrder,
                    }),
                    new LineElement({
                        id: "temp__lineStart",
                        x1: current.vertex.x,
                        y1: current.vertex.y,
                        x2: current.start.x,
                        y2: current.start.y,
                        color,
                        overrideRenderOrder,
                    }),
                ];
            }

            const { start, vertex, end } = current;
            const angle: TAngle = normalizeAngle({ start, vertex, end }, true);
            const { startAngle: angleStart, endAngle: angleEnd } = getAngles(angle);

            return [
                new DotElement({
                    id: "temp__vertex",
                    x: current.start.x,
                    y: current.start.y,
                    color,
                    overrideRenderOrder,
                }),
                new LineElement({
                    id: "temp__lineStart",
                    x1: current.vertex.x,
                    y1: current.vertex.y,
                    x2: current.start.x,
                    y2: current.start.y,
                    color,
                    overrideRenderOrder,
                }),
                new LineElement({
                    id: "temp__lineEnd",
                    x1: current.vertex.x,
                    y1: current.vertex.y,
                    x2: current.end.x,
                    y2: current.end.y,
                    color: ElementColor.Selected,
                    overrideRenderOrder,
                }),
                new AngleElement({
                    id: "temp__angle",
                    segments: 2,
                    angleStart,
                    angleEnd,
                    color: ElementColor.Selected,
                    x: vertex.x,
                    y: vertex.y,
                    isRight: false,
                    overrideRenderOrder: 0,
                }),
            ];
        }
        return [];
    }

    get tempElements(): BaseElement[] {
        return [...this.renderNewAngle(), ...this.renderAngles()];
    }

    private updateCurrentPointPosition(e: React.PointerEvent, point: TPoint) {
        switch (e.type) {
            case "pointerdown":
            case "pointermove":
                break;
            default:
                return;
        }

        switch (this.flow.current.stage) {
            case 1:
                this.flow.current.start = point;
                break;
            case 2:
                this.flow.current.vertex = point;
                break;
            case 3:
                this.flow.current.end = point;
                break;
        }
    }

    private handleNotPoint(e: React.PointerEvent) {
        if (e.type === "pointerup") {
            const { angles } = this.flow;
            if (angles.length >= 2) {
                const { equalAngles, bisections } = this.findInvolvedEqualAnglesGroups(angles);

                const anglesFromOtherGroups = equalAngles.flatMap(({ angles }) => angles);

                this.figure.filterModels((model) => !equalAngles.includes(model));

                const segmentsCount = getNewEqualAnglesSegmentsCount(this.figure, bisections);

                const anotherBisections: BisectionModel[] = [];
                for (const model of this.figure.models) {
                    if (checkModelType(model, ModelTypes.bisection)) {
                        anotherBisections.push(model);
                    }
                }

                for (const bisection of bisections) {
                    for (const b2 of anotherBisections) {
                        if (b2.segmentsCount === bisection.segmentsCount) {
                            b2.segmentsCount = segmentsCount;
                        }
                    }

                    bisection.segmentsCount = segmentsCount;
                }

                this.figure.insertModels(function* () {
                    yield EqualAnglesModel.create({
                        angles: filterEqualAngles([...angles, ...anglesFromOtherGroups]),
                        segmentsCount,
                        style: null,
                    });

                    for (const { start, vertex, end } of angles) {
                        yield PointModel.create({ ...start, style: null });
                        yield PointModel.create({ ...vertex, style: null });
                        yield PointModel.create({ ...end, style: null });

                        yield LabelPointModel.createNext(start, this.figure);
                        yield LabelPointModel.createNext(vertex, this.figure);
                        yield LabelPointModel.createNext(end, this.figure);
                    }
                }, this);
            }
            this.nextFlow();
        }
    }

    private findInvolvedEqualAnglesGroups(angles: TAngle[]): {
        equalAngles: EqualAnglesModel[];
        bisections: BisectionModel[];
    } {
        const equalAngles: EqualAnglesModel[] = [];

        const bisections: BisectionModel[] = [];

        for (const model of this.figure.models) {
            if (checkModelType(model, ModelTypes.equalAngles)) {
                for (const angle1 of model.angles) {
                    for (const angle2 of angles) {
                        if (areSameAngles(angle1, angle2)) {
                            equalAngles.push(model);
                        }
                    }
                }
            }
            if (checkModelType(model, ModelTypes.bisection)) {
                const { vertex, start, end } = model.angle;
                const { midPoint } = model;

                if (midPoint) {
                    for (const angle1 of [createAngle(start, vertex, midPoint), createAngle(end, vertex, midPoint)]) {
                        for (const angle2 of angles) {
                            if (areSameAngles(angle1, angle2)) {
                                bisections.push(model);
                            }
                        }
                    }
                }
            }
        }

        return { equalAngles, bisections };
    }

    private updateStages(e: React.PointerEvent, snapPoint: TPoint) {
        if (e.type === "pointerup") {
            switch (this.flow.current.stage) {
                case 1:
                    this.flow.current = {
                        stage: 2,
                        start: snapPoint,
                    };
                    break;
                case 2:
                    this.flow.current = {
                        stage: 3,
                        start: this.flow.current.start,
                        vertex: snapPoint,
                    };
                    break;
                case 3: {
                    const { start, vertex, end = snapPoint } = this.flow.current;
                    this.flow.angles.push(normalizeAngle({ start, vertex, end }, true));
                    this.flow.current = { stage: 1 };
                    break;
                }
            }
        }
    }

    override getToolbarMenu(): ToolbarMenu {
        return createDefaultToolbarMenuBuilder()
            .setButtonState(ButtonStates.active, ["labels"])
            .setButtonIcon("equal-angles", ["labels"])
            .build();
    }

    override getTooltipMenu(): TooltipMenu | null {
        let text: string;
        const {
            angles: { length },
            current: { stage },
        } = this.flow;

        if (length > 1) {
            text = "Выберите точки еще одного угла или нажмите в пустую область, чтобы закончить действие";
        } else {
            switch (stage) {
                case 1:
                    text = `Выберите точку на стороне ${length === 0 ? "первого" : "второго"} угла`;
                    break;
                case 2:
                    text = `Выберите вершину ${length === 0 ? "первого" : "второго"} угла`;
                    break;
                case 3:
                    text = `Выберите точку на стороне ${length === 0 ? "первого" : "второго"} угла`;
                    break;
            }
        }

        return new TooltipMenu(text);
    }
}
