import { computed, makeObservable } from "mobx";

import { InputService } from "@viuch/math-editor";

import type { IntervalsDot } from "../../statement/IntervalsDot";

export class IntervalsInstrumentStatementDotEditorViewModel {
    public readonly dot: IntervalsDot;

    constructor(dot: IntervalsDot) {
        this.dot = dot;
        makeObservable(this, {
            inputService: computed,
        });
    }

    get inputService(): InputService {
        const initialState = this.dot.value;
        return new InputService({ isReadOnly: true, initialState });
    }
}
