import { lazy, Suspense } from "react";
import { type RouteProps, Switch } from "react-router";
import { Route } from "react-router-dom";

import type { TAccess } from "../../store/AccessProvider";

import { FillSpinner } from "../../components/Spinner";
import { AdminLayout } from "../../layouts";
import AdminMain from "../../views/AdminMain";
import { ForbiddenPage } from "../../views/Forbidden";
import { RoleRoute } from "../RoleRoute";

const pages: Array<{
    path: string;
    Component: RouteProps["component"];
    hasAccess(p: TAccess): boolean;
}> = [
    {
        path: "/",
        Component: AdminMain,
        hasAccess: (p) => p.authorized,
    },
    {
        path: "/exams",
        Component: lazy(() => import("../../views/exams/ExamsPage").then((m) => ({ default: m.ExamsPage }))),
        hasAccess: (p) => p.content.exams.viewWarnings,
    },
    {
        path: "/exams/new",
        Component: lazy(() => import("../../views/exams/NewExamPage").then((m) => ({ default: m.NewExamPage }))),
        hasAccess: (p) => p.content.exams.createNew,
    },
    {
        path: "/exams/:id",
        Component: lazy(() => import("../../views/exams/ExamPage").then((m) => ({ default: m.ExamPage }))),
        hasAccess: (p) => p.content.exams.viewPage,
    },
    {
        path: "/exams/:id/variants",
        Component: lazy(() =>
            import("../../views/exams/ExamVariantsPage").then((m) => ({ default: m.ExamVariantsPage }))
        ),
        hasAccess: (p) => p.content.exams.variants.viewList,
    },
    {
        path: "/exam-variants/:id",
        Component: lazy(() =>
            import("../../views/exams/ExamVariantPage").then((m) => ({ default: m.ExamVariantPage }))
        ),
        hasAccess: (p) => p.content.exams.variants.viewPage,
    },
    {
        path: "/global-study-plans",
        Component: lazy(() =>
            import("../../views/GlobalStudyPlan/GlobalStudyPlansPage").then((m) => ({
                default: m.GlobalStudyPlansPage,
            }))
        ),
        hasAccess: (p) => p.content.studyPlans.viewList,
    },
    {
        path: "/global-study-plans/:id",
        Component: lazy(() =>
            import("../../views/GlobalStudyPlan/GlobalStudyPlanPage").then((m) => ({
                default: m.GlobalStudyPlanPage,
            }))
        ),
        hasAccess: (p) => p.content.studyPlans.viewPage,
    },
    {
        path: "/ignored-words",
        Component: lazy(() => import("../../views/ignored-words").then((m) => ({ default: m.IgnoredWordsPage }))),
        hasAccess: (p) => p.admin.configs.view,
    },
    {
        path: "/unrecognized-words",
        Component: lazy(() => import("../../views/ignored-words").then((m) => ({ default: m.UnrecognizedWordsPage }))),
        hasAccess: (p) => p.admin.configs.view,
    },
    {
        path: "/admins",
        Component: lazy(() => import("../../views/ContentAdmins").then((m) => ({ default: m.ContentAdminsListPage }))),
        hasAccess: (p) => p.admin.admins.viewList,
    },
    {
        path: "/admins/:id",
        Component: lazy(() => import("../../views/ContentAdmin").then((m) => ({ default: m.AdminPage }))),
        hasAccess: (p) => p.admin.admins.viewPage,
    },
    {
        path: "/regenerate-variants",
        Component: lazy(() =>
            import("../../views/regenerate-variants/RegenerateVariantsPage").then((m) => ({
                default: m.RegenerateVariantsPage,
            }))
        ),
        hasAccess: (p) => p.admin.regenerateVariants,
    },
    {
        path: "/tasks/:id",
        Component: lazy(() => import("../../views/task/TaskPage").then((m) => ({ default: m.TaskPage }))),
        hasAccess: (p) => p.__unknown,
    },
    {
        path: "/themes/:themeId/tasks/:taskId/variants/:variantId/solutions",
        Component: lazy(() =>
            import("../../views/task-solutions/TaskSolutionsPage").then((m) => ({ default: m.TaskSolutionsPage }))
        ),
        hasAccess: (p) => p.__unknown,
    },
    {
        path: "/themes/:id/solution-examples",
        Component: lazy(() =>
            import("../../views/task/ThemeSolutionExamplesPage").then((m) => ({
                default: m.ThemeSolutionExamplesPage,
            }))
        ),
        hasAccess: (p) => p.__unknown,
    },
    {
        path: "/students",
        Component: lazy(() =>
            import("../../views/students/StudentsListPage").then((m) => ({
                default: m.StudentsListPage,
            }))
        ),
        hasAccess: (p) => p.admin.students.viewList,
    },
    {
        path: "/students/:id",
        Component: lazy(() =>
            import("../../views/students/StudentPage").then((m) => ({
                default: m.StudentPage,
            }))
        ),
        hasAccess: (p) => p.admin.students.viewPage,
    },
    {
        path: "/student-solutions/:id",
        Component: lazy(() =>
            import("../../views/students/StudentSolutionPage").then((m) => ({
                default: m.StudentSolutionPage,
            }))
        ),
        hasAccess: (p) => p.admin.students.solutions.viewPage,
    },
    {
        path: "/blog",
        Component: lazy(() =>
            import("../../views/Blog/BlogPage").then((m) => ({
                default: m.BlogPage,
            }))
        ),
        hasAccess: (p) => p.content.blogs.viewList,
    },
    {
        path: "/faq",
        Component: lazy(() =>
            import("../../views/faq/FAQPage").then((m) => ({
                default: m.FAQPage,
            }))
        ),
        hasAccess: (p) => p.admin.faq.view,
    },
    {
        path: "/blog/posts/new",
        Component: lazy(() =>
            import("../../views/Blog/NewBlogPostPage").then((m) => ({
                default: m.NewBlogPostPage,
            }))
        ),
        hasAccess: (p) => p.content.blogs.createNew,
    },
    {
        path: "/blog/posts/:id",
        Component: lazy(() => import("../../views/Blog").then((m) => ({ default: m.BlogPostPage }))),
        hasAccess: (p) => p.content.blogs.viewPage,
    },
    {
        path: "/questions",
        Component: lazy(() =>
            import("../../views/questions/QuestionsPage").then((m) => ({ default: m.QuestionsPage }))
        ),
        hasAccess: (p) => p.communication.questionsAnswers.view,
    },
    {
        path: "/reviews",
        Component: lazy(() => import("../../views/reviews/ReviewsPage").then((m) => ({ default: m.ReviewsPage }))),
        hasAccess: (p) => p.communication.reviewsComments.view,
    },
    {
        path: "/comments",
        Component: lazy(() => import("../../views/comments/CommentsPage").then((m) => ({ default: m.CommentsPage }))),
        hasAccess: (p) => p.communication.reviewsComments.view,
    },
    {
        path: "/attention/indexing",
        Component: lazy(() => import("../../views/indexing/IndexingPage").then((m) => ({ default: m.IndexingPage }))),
        hasAccess: (p) => p.admin.indexing.viewWarnings,
    },
    {
        path: "/attention/indexing/subjects",
        Component: lazy(() =>
            import("../../views/indexing/IndexingPage").then((m) => ({ default: m.IndexingSubjectsPage }))
        ),
        hasAccess: (p) => p.admin.indexing.viewWarnings,
    },
    {
        path: "/attention/indexing/sections",
        Component: lazy(() =>
            import("../../views/indexing/IndexingPage").then((m) => ({ default: m.IndexingSectionsPage }))
        ),
        hasAccess: (p) => p.admin.indexing.viewWarnings,
    },
    {
        path: "/attention/indexing/subsections",
        Component: lazy(() =>
            import("../../views/indexing/IndexingPage").then((m) => ({ default: m.IndexingSubsectionsPage }))
        ),
        hasAccess: (p) => p.admin.indexing.viewWarnings,
    },
    {
        path: "/attention/indexing/themes-only",
        Component: lazy(() =>
            import("../../views/indexing/IndexingPage").then((m) => ({ default: m.IndexingThemesPage }))
        ),
        hasAccess: (p) => p.admin.indexing.viewWarnings,
    },
    {
        path: "/attention/indexing/synopses-with-themes",
        Component: lazy(() =>
            import("../../views/indexing/IndexingPage").then((m) => ({ default: m.IndexingSynopsesPage }))
        ),
        hasAccess: (p) => p.admin.indexing.viewWarnings,
    },
    {
        path: "/attention/indexing/exams",
        Component: lazy(() =>
            import("../../views/indexing/IndexingPage").then((m) => ({ default: m.IndexingExamsPage }))
        ),
        hasAccess: (p) => p.admin.indexing.viewWarnings,
    },
    {
        path: "/attention/indexing/blogs",
        Component: lazy(() =>
            import("../../views/indexing/IndexingPage").then((m) => ({ default: m.IndexingBlogsPage }))
        ),
        hasAccess: (p) => p.admin.indexing.viewWarnings,
    },
    {
        path: "/draft-synopses",
        Component: lazy(() =>
            import("../../views/DraftSynopses/DraftSynopsesListPage").then((m) => ({
                default: m.DraftSynopsesListPage,
            }))
        ),
        hasAccess: (p) => p.content.draftSynopses.viewList,
    },
    {
        path: "/draft-synopses/new",
        Component: lazy(() =>
            import("../../views/DraftSynopses/DraftSynopsisPage").then((m) => ({ default: m.DraftSynopsisPage }))
        ),
        hasAccess: (p) => p.content.draftSynopses.createNew,
    },
    {
        path: "/draft-synopses/:id",
        Component: lazy(() =>
            import("../../views/DraftSynopses/DraftSynopsisPage").then((m) => ({ default: m.DraftSynopsisPage }))
        ),
        hasAccess: (p) => p.content.draftSynopses.viewPage,
    },
    {
        path: "/stats/by-themes",
        Component: lazy(() =>
            import("../../views/Stats/StatsByThemesPage").then((m) => ({ default: m.StatsByThemesPage }))
        ),
        hasAccess: (p) => p.admin.stats.byThemes,
    },
    {
        path: "/stats/solvers",
        Component: lazy(() =>
            import("../../views/Stats/solvers/StatsSolversPage").then((m) => ({ default: m.StatsSolversPage }))
        ),
        hasAccess: (p) => p.admin.stats.solvers,
    },
    {
        path: "/grades",
        Component: lazy(() => import("../../views/grades/GradesPage").then((m) => ({ default: m.GradesPage }))),
        hasAccess: (p) => p.content.grades.viewList,
    },

    {
        path: "/payments/tariffs",
        Component: lazy(() =>
            import("../../views/payments/EditTariffsPage").then((m) => ({ default: m.EditTariffsPage }))
        ),
        hasAccess: (p) => p.admin.tariffs.edit,
    },
    {
        path: "/payments/promocodes",
        Component: lazy(() =>
            import("../../views/payments/EditPromocodesPage").then((m) => ({ default: m.EditPromocodesPage }))
        ),
        hasAccess: (p) => p.admin.tariffs.edit,
    },
    {
        path: "/payments/stats",
        Component: lazy(() =>
            import("../../views/payments/PaymentsStatsPage").then((m) => ({ default: m.PaymentsStatsPage }))
        ),
        hasAccess: (p) => p.admin.tariffs.edit,
    },
    {
        path: "/themes/new",
        Component: lazy(() => import("../../views/Hierarchy/NewThemePage").then((m) => ({ default: m.NewThemePage }))),
        hasAccess: (p) => p.content.themes.createPage,
    },
    {
        path: "/themes/:id",
        Component: lazy(() => import("../../views/Hierarchy/ThemePage").then((m) => ({ default: m.ThemePage }))),
        hasAccess: (p) => p.content.themes.viewPage,
    },
    {
        path: "/themes/:id/tree",
        Component: lazy(() =>
            import("../../views/Hierarchy/ThemeTreePage").then((m) => ({ default: m.ThemeTreePage }))
        ),
        hasAccess: (p) => p.content.themes.viewRelationsTree,
    },
    {
        path: "/step-descriptions",
        Component: lazy(() =>
            import("../../views/SolverStepTemplates").then((m) => ({ default: m.StepDescriptionsPage }))
        ),
        hasAccess: (p) => p.admin.configs.view,
    },
    {
        path: "/subjects",
        Component: lazy(() => import("../../views/Hierarchy/SubjectsPage").then((m) => ({ default: m.SubjectsPage }))),
        hasAccess: (p) => p.content.hierarchy.viewTree,
    },
    {
        path: "/subjects/new",
        Component: lazy(() =>
            import("../../views/Hierarchy/NewSubjectPage").then((m) => ({ default: m.NewSubjectPage }))
        ),
        hasAccess: (p) => p.content.subjects.createNew,
    },
    {
        path: "/subjects/:id",
        Component: lazy(() => import("../../views/Hierarchy/SubjectPage").then((m) => ({ default: m.SubjectPage }))),
        hasAccess: (p) => p.content.subjects.viewPage,
    },
    {
        path: "/sections/new",
        Component: lazy(() =>
            import("../../views/Hierarchy/NewSectionPage").then((m) => ({ default: m.NewSectionPage }))
        ),
        hasAccess: (p) => p.content.sections.createNew,
    },
    {
        path: "/sections/:id",
        Component: lazy(() => import("../../views/Hierarchy/SectionPage").then((m) => ({ default: m.SectionPage }))),
        hasAccess: (p) => p.content.sections.viewPage,
    },
    {
        path: "/subsections/new",
        Component: lazy(() =>
            import("../../views/Hierarchy/NewSubsectionPage").then((m) => ({ default: m.NewSubsectionPage }))
        ),
        hasAccess: (p) => p.content.subsections.createNew,
    },
    {
        path: "/subsections/:id",
        Component: lazy(() =>
            import("../../views/Hierarchy/SubsectionPage").then((m) => ({ default: m.SubsectionPage }))
        ),
        hasAccess: (p) => p.content.subsections.viewPage,
    },
    {
        path: "/videos",
        Component: lazy(() => import("../../views/videos").then((m) => ({ default: m.VideosPage }))),
        hasAccess: (p) => p.content.videos.viewList,
    },
    {
        path: "/problems",
        Component: lazy(() => import("../../views/Problems/ProblemsPage").then((m) => ({ default: m.ProblemsPage }))),
        hasAccess: (p) => p.content.problems.viewList,
    },
    {
        path: "/problems/new",
        Component: lazy(() =>
            import("../../views/Problems/NewProblemPage").then((m) => ({ default: m.NewProblemPage }))
        ),
        hasAccess: (p) => p.content.problems.edit,
    },
    {
        path: "/problems/:id",
        Component: lazy(() => import("../../views/Problems/ProblemPage").then((m) => ({ default: m.ProblemPage }))),
        hasAccess: (p) => p.content.problems.edit,
    },
    {
        path: "/synopses",
        Component: lazy(() => import("../../views/Synopses/SynopsesPage").then((m) => ({ default: m.SynopsesPage }))),
        hasAccess: (p) => p.content.synopses.viewList,
    },
    {
        path: "/synopses/new",
        Component: lazy(() =>
            import("../../views/Synopses/NewSynopsisPage").then((m) => ({ default: m.NewSynopsisPage }))
        ),
        hasAccess: (p) => p.content.synopses.createNew,
    },
    {
        path: "/synopses/:id",
        Component: lazy(() => import("../../views/Synopses/SynopsisPage").then((m) => ({ default: m.SynopsisPage }))),
        hasAccess: (p) => p.content.synopses.viewPage,
    },
    {
        path: "/preambles",
        Component: lazy(() =>
            import("../../views/preambles/PreamblesListPage").then((m) => ({ default: m.PreamblesListPage }))
        ),
        hasAccess: (p) => p.content.synopses.viewList,
    },
    {
        path: "/materials",
        Component: lazy(() =>
            import("../../views/materials/MaterialsListPage").then((m) => ({ default: m.MaterialsListPage }))
        ),
        hasAccess: (p) => p.content.synopses.viewList,
    },
    {
        path: "/measures",
        Component: lazy(() => import("../../views/measures").then((m) => ({ default: m.MeasuresPage }))),
        hasAccess: (p) => p.admin.configs.view,
    },
    {
        path: "/replacements",
        Component: lazy(() =>
            import("../../views/SolverStepTemplates/ReplacementsPage").then((m) => ({ default: m.ReplacementsPage }))
        ),
        hasAccess: (p) => p.admin.configs.view,
    },
    {
        path: "/attention",
        Component: lazy(() =>
            import("../../views/Attention/AttentionPage").then((m) => ({ default: m.AttentionPage }))
        ),
        hasAccess: (p) => p.content.attentionsPageView,
    },
    {
        path: "/attention/sections",
        Component: lazy(() =>
            import("../../views/Attention/AttentionSectionsPage").then((m) => ({ default: m.AttentionSectionsPage }))
        ),
        hasAccess: (p) => p.content.sections.viewWarnings,
    },
    {
        path: "/attention/subsections",
        Component: lazy(() =>
            import("../../views/Attention/AttentionSubsectionsPage").then((m) => ({
                default: m.AttentionSubsectionsPage,
            }))
        ),
        hasAccess: (p) => p.content.subsections.viewWarnings,
    },
    {
        path: "/attention/themes",
        Component: lazy(() =>
            import("../../views/Attention/AttentionThemesPage").then((m) => ({ default: m.AttentionThemesPage }))
        ),
        hasAccess: (p) => p.content.themes.viewWarnings,
    },
    {
        path: "/attention/synopses",
        Component: lazy(() =>
            import("../../views/Attention/AttentionSynopsesPage").then((m) => ({ default: m.AttentionSynopsesPage }))
        ),
        hasAccess: (p) => p.content.synopses.viewWarnings,
    },
    {
        path: "/geometry-synonyms",
        Component: lazy(() =>
            import("../../views/geometry-synonyms/GeometrySynonymsListPage").then((m) => ({
                default: m.GeometrySynonymsListPage,
            }))
        ),
        hasAccess: (p) => p.admin.configs.view,
    },
    {
        path: "/theme-knowledge-links",
        Component: lazy(() =>
            import("../../views/theme-knowledge-links/ThemeKnowledgeLinksPage").then((m) => ({
                default: m.ThemeKnowledgeLinksPage,
            }))
        ),
        hasAccess: (p) => p.admin.configs.view,
    },
];

const Admin = () => (
    <AdminLayout>
        <Suspense fallback={<FillSpinner backdrop />}>
            <Switch>
                {pages.map(({ path, Component, hasAccess }, i) => (
                    <RoleRoute
                        key={i}
                        exact
                        path={path}
                        component={Component}
                        hasAccess={hasAccess}
                    />
                ))}
                <Route
                    exact
                    path="/forbidden"
                    component={ForbiddenPage}
                />
            </Switch>
        </Suspense>
    </AdminLayout>
);

export default Admin;
