import type { TTrigonometricOperator } from "../elements/trigonometric-operator/types";
import type { TAnyAction } from "../types";

export type TTrigonometricOperatorAction = {
    type: "trigonometric-operator";
    operator: TTrigonometricOperator;
};

export function createTrigonometricOperatorAction(operator: TTrigonometricOperator): TTrigonometricOperatorAction {
    return { type: "trigonometric-operator", operator };
}

export function isTrigonometricOperatorAction(action: TAnyAction): action is TTrigonometricOperatorAction {
    return action.type === "trigonometric-operator";
}
