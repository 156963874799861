import { makeObservable, observable } from "mobx";

import { createFragment, createPoint } from "@viuch/geometry-lib/factories";

import type { IElementVisitor } from "../BaseElement";
import type { ElementColor } from "../ElementColor";
import type { TFragment } from "@viuch/geometry-lib/types";

import { BaseElement } from "../BaseElement";

export interface IVectorElement {
    id: string;
    x1: number;
    y1: number;
    x2: number;
    y2: number;
    thin?: boolean;
    isDashed?: boolean;
    color?: ElementColor;
    overrideRenderOrder: number;
}

export class VectorElement extends BaseElement implements IVectorElement {
    x1: number;
    y1: number;
    x2: number;
    y2: number;
    thin?: boolean | undefined;
    isDashed?: boolean | undefined;

    constructor(data: IVectorElement) {
        super(data);

        this.x1 = data.x1;
        this.y1 = data.y1;
        this.x2 = data.x2;
        this.y2 = data.y2;
        this.thin = data.thin;
        this.isDashed = data.isDashed;

        makeObservable(this, {
            x1: observable,
            y1: observable,
            x2: observable,
            y2: observable,
            isDashed: observable,
        });
    }

    toFragment(): TFragment {
        const { x1, y1, x2, y2 } = this;
        return createFragment(createPoint(x1, y1), createPoint(x2, y2));
    }

    accept<R>(visitor: IElementVisitor<R>): R {
        return visitor.withVector(this);
    }
}
