import cn from "classnames";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";

import { buildFunc, simpleTransformInterval } from "@viuch/geometry-lib/transformations/functions";
import { useIsSafari } from "@viuch/utils/hooks";

import type { Graph2DEvents } from "../editor/core/Graph2DEvents";
import type { Graph2DViewportController } from "../editor/core/Graph2DViewportController";
import type { Graph2DViewSettings } from "../editor/core/Graph2DViewSettings";
import type { FunctionIntervalViewItem } from "../view-models/FunctionIntervalViewItem";

import { ScaledSvgContainer } from "../editor/rendering/containers/ScaledSvgContainer";

import styles from "./FunctionIntervalView.module.scss";

type Props = {
    item: FunctionIntervalViewItem;
    viewport: Graph2DViewportController;
    settings: Graph2DViewSettings;
    events: Graph2DEvents;
};

export const FunctionIntervalView = observer(function FunctionIntervalView({
    item,
    viewport,
    settings,
    events,
}: Props) {
    const isSafari = useIsSafari();

    const { color, width, puncture, fill, blur } = item;

    const { path, start, end } = useMemo(() => {
        const { func, interval } = item;
        const constraint = (x: number) => Math.min(110, Math.max(-10, x));

        const intervalTransformations = [
            ...settings.coordinateSystemTransform, //
            ...viewport.viewportTransformations,
        ];

        const f = buildFunc(func, ...intervalTransformations.map((t) => t.transformFunction));
        const { start, end } = simpleTransformInterval(interval, intervalTransformations);

        const stepX = 0.05;
        const startX = constraint(start.value + stepX);
        const endX = constraint(end.value - stepX);

        const points: [x: number, y: number][] = [];

        if (!Number.isNaN(start.limit)) {
            points.push([constraint(start.value), constraint(start.limit)]);
        }

        for (let currentX = startX; currentX <= endX; currentX += stepX) {
            const y = f(currentX);

            if (Number.isNaN(y)) {
                console.error("Point value was NaN", func, interval);
                continue;
            }

            const currentY = constraint(y);

            points.push([currentX, currentY]);
        }

        if (!Number.isNaN(end.limit)) {
            points.push([constraint(end.value), constraint(end.limit)]);
        }

        const path = points.map(([x, y]) => `${x},${y}`).join(" ");

        return {
            path,
            start: constraint(start.value),
            end: constraint(end.value),
        };
    }, [item, settings, viewport]);

    const [isHover, setIsHover] = useState(false);

    return (
        <ScaledSvgContainer>
            <polyline
                points={path}
                stroke="var(--darkBlueOpal100)"
                fill="none"
                strokeWidth={width + 1}
                vectorEffect="non-scaling-stroke"
                className={cn(styles.visibleLine, puncture && styles._puncture)}
            />
            {blur && !isSafari && (
                <polyline
                    points={path}
                    stroke={color}
                    filter="blur(2px)"
                    transform="translate(1 1)"
                    fill="none"
                    strokeWidth={width + 1}
                    vectorEffect="non-scaling-stroke"
                    className={cn(styles.visibleLine, puncture && styles._puncture)}
                />
            )}
            <polyline
                points={path}
                stroke={color}
                fill="none"
                strokeWidth={isHover ? 6 : width}
                vectorEffect="non-scaling-stroke"
                className={cn(styles.visibleLine, puncture && styles._puncture)}
            />
            {fill &&
                (() => {
                    const fillY = fill === "down" ? 100 : 0;
                    return (
                        <polyline
                            points={`${start},${fillY} ${path} ${end},${fillY}`}
                            stroke="none"
                            fill={color}
                            fillOpacity={0.2}
                            strokeWidth={isHover ? 6 : width}
                            vectorEffect="non-scaling-stroke"
                            className={cn(styles.visibleFill, puncture && styles._puncture)}
                        />
                    );
                })()}
            <polyline
                points={path}
                stroke="transparent"
                fill="none"
                strokeWidth={12}
                vectorEffect="non-scaling-stroke"
                className={styles.eventTriggerLine}
                onClick={(e) => events.dispatchElementClick(e, item)}
                onPointerDown={(e) => events.dispatchElementPointerEvent(e, item)}
                onPointerMove={(e) => events.dispatchElementPointerEvent(e, item)}
                onPointerUp={(e) => events.dispatchElementPointerEvent(e, item)}
                onPointerCancel={(e) => events.dispatchElementPointerEvent(e, item)}
                onPointerEnter={() => setIsHover(true)}
                onPointerLeave={() => setIsHover(false)}
            />
        </ScaledSvgContainer>
    );
});
