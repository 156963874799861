import type { TRawExamRequest, TRawExamResponse } from "./ExamsService.types";
import type { Exam } from "../../entities/exams";

import { agent } from "../../agent";
import { BaseCRUDService } from "../base";

import { mapExam, serializeExam } from "./ExamsService.utils";

export class ExamsService extends BaseCRUDService<Exam, TRawExamRequest, TRawExamResponse> {
    constructor() {
        super("/exams/", mapExam, serializeExam);
    }

    async clone(examId: number): Promise<TRawExamResponse> {
        const { data } = await agent.post<TRawExamResponse>(`/${this.baseUrl}/${examId}/clone/`);
        return data;
    }
}
