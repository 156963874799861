import type { TAngle, TBasis, TEllipse, TFragment, TLine, TPoint, TRhomb } from "./types";

export function copyPoint(point: TPoint): TPoint {
    return { x: point.x, y: point.y };
}

export function copyAngle(angle: TAngle): TAngle {
    return {
        vertex: copyPoint(angle.vertex),
        start: copyPoint(angle.start),
        end: copyPoint(angle.end),
    };
}

export function copyBasis({ fragment, offset, angle }: TBasis): TBasis {
    const basis: TBasis = {
        fragment: copyFragment(fragment),
    };
    if (offset) {
        basis.offset = copyPoint(offset);
    }
    if (typeof angle === "number") {
        basis.angle = angle;
    }
    return basis;
}

export function createEllipse(center: TPoint, rx: number, ry: number): TEllipse {
    return { center: copyPoint(center), rx, ry };
}

export function copyEllipse({ center, rx, ry }: TEllipse): TEllipse {
    return { center: copyPoint(center), rx, ry };
}

export function createPoint(x: number, y: number): TPoint {
    return { x, y };
}

export function copyFragment(fragment: TFragment): TFragment {
    return { a: copyPoint(fragment.a), b: copyPoint(fragment.b) };
}

export function createFragment(a: TPoint, b: TPoint) {
    return { a: copyPoint(a), b: copyPoint(b) };
}

export function createAngle(start: TPoint, vertex: TPoint, end: TPoint): TAngle {
    return {
        start: copyPoint(start),
        vertex: copyPoint(vertex),
        end: copyPoint(end),
    };
}

export function createBasis(fragment: TFragment, offset?: TPoint, angle?: number): TBasis {
    const basis: TBasis = { fragment };
    if (offset) basis.offset = offset;
    if (typeof angle === "number") basis.angle = angle;
    return basis;
}

export function createLine(a: TPoint, b: TPoint): TLine;
export function createLine(fragment: TFragment): TLine;
export function createLine(fragmentOrA: TPoint | TFragment, b?: TPoint): TLine {
    if ("a" in fragmentOrA) {
        const { a, b } = fragmentOrA;
        return { x1: a.x, y1: a.y, x2: b.x, y2: b.y };
    }
    return { x1: fragmentOrA.x, y1: fragmentOrA.y, x2: b!.x, y2: b!.y };
}

export function createRhomb(center: TPoint, radiusPoint: TPoint): TRhomb {
    const vector = {
        x: Math.abs(radiusPoint.x - center.x),
        y: Math.abs(radiusPoint.y - center.y),
    };

    return {
        left: { x: center.x - vector.x, y: center.y },
        top: { x: center.x, y: center.y + vector.y },
        right: { x: center.x + vector.x, y: center.y },
        bottom: { x: center.x, y: center.y - vector.y },
    };
}

export function fragmentToLine({ a, b }: TFragment): TLine {
    return { x1: a.x, y1: a.y, x2: b.x, y2: b.y };
}

export function lineToFragment(line: TLine): TFragment {
    const { x1, y1, x2, y2 } = line;
    return { a: { x: x1, y: y1 }, b: { x: x2, y: y2 } };
}

export function copyLine(line: TLine): TLine {
    const { x1, y1, x2, y2 } = line;
    return { x1, y1, x2, y2 };
}
