import { areSamePoints } from "@viuch/geometry-lib/check-geometry";
import { copyFragment, copyPoint } from "@viuch/geometry-lib/factories";

import type { IModelVisitor } from "../models/BaseModel";
import type { CircumscribedCircleModel, EllipseModel, InscribedCircleModel } from "../models/circle";
import type { EqualAnglesModel, EqualSegmentsModel } from "../models/constraints";
import type {
    ParallelFragmentModel,
    ConstraintLineModel,
    FragmentModel,
    LineModel,
    FragmentDashedModel,
    VectorModel,
    VectorDashedModel,
} from "../models/fragment";
import type { AltitudeModel, BisectionModel, MedianModel } from "../models/geometry";
import type { MiddleLineModel } from "../models/geometry/MiddleLineModel";
import type { LabelAngleModel } from "../models/label-angle";
import type { LabelFragmentModel } from "../models/label-fragment";
import type { LabelPointModel } from "../models/label-point";
import type { PointModel } from "../models/point";
import type { TangentLineToCircleModel, TangentLineOnCircleModel } from "../models/tangent";
import type { TEllipse, TFragment, TPoint } from "@viuch/geometry-lib/types";

export const snapPointsVisitor: IModelVisitor<TPoint[]> = {
    withPoint: (point) => [copyPoint(point)],
    withFragment: ({ a, b }) => [copyPoint(a), copyPoint(b)],
    withLine: ({ a, b }) => [copyPoint(a), copyPoint(b)],
    withConstraintLine: ({ vertex, intersectionPoint }) =>
        intersectionPoint ? [copyPoint(intersectionPoint), copyPoint(vertex)] : [copyPoint(vertex)],
    withComputedIntersectionPoints: (model) => model.points.map(copyPoint),
    withBisection: ({ midPoint }) => (midPoint ? [copyPoint(midPoint)] : []),
    withMedian: ({ midPoint }) => [copyPoint(midPoint)],
    withAltitude: ({ projectionPoint }) => [copyPoint(projectionPoint)],
    withEllipse: ({ center }) => [center],
    withInscribedCircle: (circle) => (circle.center ? [circle.center, ...circle.intersectionPoints] : []),
    withCircumscribedCircle: (circle) => (circle.center ? [circle.center] : []),
    withTangentLine: (tangent) =>
        tangent.checkIsTangent()
            ? [copyPoint(tangent.point), copyPoint(tangent.pointOnCircle)]
            : [copyPoint(tangent.point)],
    withMiddleLine: ({ middleLineFragment }) => [middleLineFragment.a, middleLineFragment.b],
    withParallelFragment: ({ a, b }) => [copyPoint(a), copyPoint(b)],
    withFragmentDashed: ({ a, b }) => [copyPoint(a), copyPoint(b)],
    withVector: (vector) => [copyPoint(vector.from), copyPoint(vector.to)],
    withVectorDashed: (vector) => [copyPoint(vector.from), copyPoint(vector.to)],
    withLabelPoint: () => [],
    withLabelFragment: () => [],
    withLabelAngle: () => [],
    withEqualAngles: () => [],
    withEqualSegments: () => [],
    withTangentLineOnCircle: () => [],
};

export const snapFragmentsVisitor: IModelVisitor<TFragment[]> = {
    withFragment: (fragment) => [copyFragment(fragment)],
    withLine: (line) => [copyFragment(line.virtualFragment)],
    withConstraintLine: (line) => [copyFragment(line.virtualFragment)],
    withBisection: (bisection) => (bisection.bisectionFragment ? [copyFragment(bisection.bisectionFragment)] : []),
    withMedian: (median) => [copyFragment(median.medianFragment)],
    withAltitude: (altitude) => [copyFragment(altitude.altitudeFragment)],
    withTangentLine: (tangent) => (tangent.checkIsTangent() ? [copyFragment(tangent.virtualTangentLine)] : []),
    withMiddleLine: (middleLine) => [copyFragment(middleLine.middleLineFragment)],
    withTangentLineOnCircle: (tangent) => (tangent.hasTangent() ? [copyFragment(tangent.virtualTangentFragment)] : []),
    withParallelFragment: (fragment) => [copyFragment(fragment)],
    withFragmentDashed: (fragment) => [copyFragment(fragment)],
    withVector: (vector) => [vector.toFragment()],
    withVectorDashed: (vector) => [vector.toFragment()],
    withPoint: () => [],
    withLabelPoint: () => [],
    withLabelFragment: () => [],
    withLabelAngle: () => [],
    withComputedIntersectionPoints: () => [],
    withEqualAngles: () => [],
    withEqualSegments: () => [],
    withEllipse: () => [],
    withInscribedCircle: () => [],
    withCircumscribedCircle: () => [],
};

export const snapEllipsesVisitor: IModelVisitor<TEllipse[]> = {
    withEllipse: (ellipse) => [ellipse],
    withCircumscribedCircle: (circle) => (circle.isEllipse() ? [circle] : []),
    withInscribedCircle: (circle) => (circle.isEllipse() ? [circle] : []),
    withPoint: () => [],
    withFragment: () => [],
    withFragmentDashed: () => [],
    withVector: () => [],
    withVectorDashed: () => [],
    withLabelPoint: () => [],
    withLabelFragment: () => [],
    withLabelAngle: () => [],
    withLine: () => [],
    withConstraintLine: () => [],
    withComputedIntersectionPoints: () => [],
    withBisection: () => [],
    withMedian: () => [],
    withAltitude: () => [],
    withEqualAngles: () => [],
    withEqualSegments: () => [],
    withTangentLine: () => [],
    withMiddleLine: () => [],
    withTangentLineOnCircle: () => [],
    withParallelFragment: () => [],
};

export const createMoveModelVisitor: (from: TPoint, to: TPoint) => IModelVisitor<void> = (from, to) => ({
    withPoint: (point) => movePoint(point, from, to),
    withFragment: (fragment) => moveFragment(fragment, from, to),
    withParallelFragment: (fragment) => moveParallelFragment(fragment, from, to),
    withLabelPoint: (label) => moveDotLabel(label, from, to),
    withLabelFragment: (label) => moveFragmentLabel(label, from, to),
    withLabelAngle: (label) => moveAngleLabel(label, from, to),
    withLine: (line) => moveLine(line, from, to),
    withConstraintLine: (line) => moveConstraintLine(line, from, to),
    withComputedIntersectionPoints: () => {},
    withBisection: (bisection) => moveBisection(bisection, from, to),
    withMedian: (median) => moveMedian(median, from, to),
    withAltitude: (altitude) => moveAltitude(altitude, from, to),
    withEqualAngles: (equalAngles) => moveEqualAngles(equalAngles, from, to),
    withEqualSegments: (equalSegments) => moveEqualSegments(equalSegments, from, to),
    withEllipse: (ellipse) => moveEllipse(ellipse, from, to),
    withInscribedCircle: (circle) => moveInscribedCircle(circle, from, to),
    withCircumscribedCircle: (circle) => moveCircumscribedCircle(circle, from, to),
    withTangentLine: (tangent) => moveTangentLine(tangent, from, to),
    withMiddleLine: (middleLine) => moveMiddleLine(middleLine, from, to),
    withTangentLineOnCircle: (tangent) => moveTangentOnCircle(tangent, from, to),
    withFragmentDashed: (fragment) => moveFragment(fragment, from, to),
    withVector: (vector) => moveVector(vector, from, to),
    withVectorDashed: (vector) => moveVector(vector, from, to),
});

function internalMovePoint(pointRef: TPoint, from: TPoint, to: TPoint) {
    if (areSamePoints(pointRef, from)) {
        pointRef.x = to.x;
        pointRef.y = to.y;
    }
}

function movePoint(point: PointModel, from: TPoint, to: TPoint) {
    internalMovePoint(point, from, to);
}

function moveFragment(fragment: FragmentModel | FragmentDashedModel, from: TPoint, to: TPoint) {
    internalMovePoint(fragment.a, from, to);
    internalMovePoint(fragment.b, from, to);
}

function moveVector(vector: VectorModel | VectorDashedModel, from: TPoint, to: TPoint) {
    internalMovePoint(vector.from, from, to);
    internalMovePoint(vector.to, from, to);
}

function moveDotLabel(label: LabelPointModel, from: TPoint, to: TPoint) {
    internalMovePoint(label, from, to);
}

function moveFragmentLabel(label: LabelFragmentModel, from: TPoint, to: TPoint) {
    internalMovePoint(label.a, from, to);
    internalMovePoint(label.b, from, to);
}

function moveAngleLabel(label: LabelAngleModel, from: TPoint, to: TPoint) {
    internalMovePoint(label.vertex, from, to);
    internalMovePoint(label.start, from, to);
    internalMovePoint(label.end, from, to);
}

function moveLine(line: LineModel, from: TPoint, to: TPoint) {
    internalMovePoint(line.a, from, to);
    internalMovePoint(line.b, from, to);
}

function moveConstraintLine(line: ConstraintLineModel, from: TPoint, to: TPoint) {
    internalMovePoint(line.constraintA, from, to);
    internalMovePoint(line.constraintB, from, to);
    internalMovePoint(line.vertex, from, to);
}

function moveBisection(bisection: BisectionModel, from: TPoint, to: TPoint) {
    internalMovePoint(bisection.basis.fragment.a, from, to);
    internalMovePoint(bisection.basis.fragment.b, from, to);
    bisection.basis.offset && internalMovePoint(bisection.basis.offset, from, to);
    internalMovePoint(bisection.angle.vertex, from, to);
    internalMovePoint(bisection.angle.start, from, to);
    internalMovePoint(bisection.angle.end, from, to);
}

function moveMedian(median: MedianModel, from: TPoint, to: TPoint) {
    internalMovePoint(median.vertex, from, to);
    internalMovePoint(median.fragment.a, from, to);
    internalMovePoint(median.fragment.b, from, to);
}

function moveAltitude(altitude: AltitudeModel, from: TPoint, to: TPoint) {
    internalMovePoint(altitude.vertex, from, to);
    internalMovePoint(altitude.basis.fragment.a, from, to);
    internalMovePoint(altitude.basis.fragment.b, from, to);
    altitude.basis.offset && internalMovePoint(altitude.basis.offset, from, to);
}

function moveEqualAngles(equalAngles: EqualAnglesModel, from: TPoint, to: TPoint) {
    for (const angle of equalAngles.angles) {
        internalMovePoint(angle.vertex, from, to);
        internalMovePoint(angle.start, from, to);
        internalMovePoint(angle.end, from, to);
    }
}

function moveEqualSegments(equalSegments: EqualSegmentsModel, from: TPoint, to: TPoint) {
    for (const segment of equalSegments.segments) {
        internalMovePoint(segment.a, from, to);
        internalMovePoint(segment.b, from, to);
    }
}

function moveEllipse(ellipse: EllipseModel, from: TPoint, to: TPoint) {
    internalMovePoint(ellipse.center, from, to);
}

function moveInscribedCircle(circle: InscribedCircleModel, from: TPoint, to: TPoint) {
    for (const point of circle.fragmentsPoints) {
        internalMovePoint(point, from, to);
    }
}

function moveCircumscribedCircle(circle: CircumscribedCircleModel, from: TPoint, to: TPoint) {
    for (const point of circle.points) {
        internalMovePoint(point, from, to);
    }
}

function moveTangentLine(tangent: TangentLineToCircleModel, from: TPoint, to: TPoint) {
    internalMovePoint(tangent.point, from, to);
    internalMovePoint(tangent.circle.center, from, to);
}

function moveMiddleLine(middleLine: MiddleLineModel, from: TPoint, to: TPoint) {
    internalMovePoint(middleLine.base1.a, from, to);
    internalMovePoint(middleLine.base1.b, from, to);
    internalMovePoint(middleLine.base2.a, from, to);
    internalMovePoint(middleLine.base2.b, from, to);
}

function moveTangentOnCircle(tangent: TangentLineOnCircleModel, from: TPoint, to: TPoint) {
    internalMovePoint(tangent.touchPoint, from, to);
    internalMovePoint(tangent.ellipse.center, from, to);
}

function moveParallelFragment(fragment: ParallelFragmentModel, from: TPoint, to: TPoint) {
    internalMovePoint(fragment.a, from, to);
    internalMovePoint(fragment.b, from, to);
    internalMovePoint(fragment.base.a, from, to);
    internalMovePoint(fragment.base.b, from, to);
}
