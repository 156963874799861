import { BaseFormNode } from "./BaseFormNode";

export abstract class BaseValueFormNode extends BaseFormNode {
    readonly label: string;
    readonly extensions: readonly string[];

    protected constructor(
        label: string,
        parent: BaseFormNode | null,
        pathSegments: readonly string[],
        extensions: string[]
    ) {
        super(parent, pathSegments);

        this.label = label;
        this.extensions = extensions;
    }

    abstract getValue(): unknown;

    abstract trySetValue(value: unknown): boolean;

    abstract valueEqualsTo(value: unknown): boolean;

    isVirtual(): boolean;
    isVirtual() {
        return false;
    }

    override canPresentInTree() {
        return true;
    }

    override getChildren() {
        return [];
    }
}
