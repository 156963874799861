import { generateUuid } from "@viuch/shared/utils/data";

import type { BaseGraphItem } from "../model/BaseGraphItem";

export abstract class BaseGraphViewItem {
    readonly $uuid: string = generateUuid();
    readonly model: BaseGraphItem;
    zIndex: number;

    protected constructor(model: BaseGraphItem, zIndex: number) {
        this.model = model;
        this.zIndex = zIndex;
    }
}
