import { makeObservable, observable } from "mobx";
import { createTransformer } from "mobx-utils";

import { getUnitVectorFromAngle } from "@viuch/geometry-lib/angles";
import { areSamePoints } from "@viuch/geometry-lib/check-geometry";
import { equateNumbers } from "@viuch/geometry-lib/equate";
import { createLine, lineToFragment } from "@viuch/geometry-lib/factories";
import { getFragmentAngle, getLineFromFragment } from "@viuch/geometry-lib/solvers";
import { addVectors } from "@viuch/geometry-lib/vectors";

import type { BaseElement } from "../../elements";
import type { Figure2DController } from "../../Figure2DController";
import type { ToolbarMenu } from "../../toolbar";
import type { IUserPointer } from "../../viewport/types";
import type { TFragment, TPoint } from "@viuch/geometry-lib/types";
import type React from "react";

import { DotElement } from "../../elements/dot";
import { ElementColor } from "../../elements/ElementColor";
import { LineElement } from "../../elements/line";
import { FragmentModel, ParallelFragmentModel } from "../../models/fragment";
import { PointModel } from "../../models/point";
import { ButtonStates } from "../../toolbar";
import { TooltipMenu } from "../../toolbar/tooltip";
import { createDefaultToolbarMenuBuilder } from "../../utils/toolbar";
import { BaseFlow } from "../BaseFlow";
import { createMapModelToElements, opacifyColor, z } from "../utils";

import { collectCandidateFragments } from "./utils";

export class ParallelFragmentFlow extends BaseFlow {
    flow:
        | {
              stage: 1;
              baseCandidates: { fragment: TFragment; middlePoint: TPoint }[];
              currentMiddlePoint?: TPoint;
              drawing: boolean;
          }
        | {
              stage: 2;
              baseFragment: TFragment;
              a?: TPoint;
              drawing: boolean;
          }
        | {
              stage: 3;
              baseFragment: TFragment;
              a: TPoint;
              b?: TPoint;
              drawing: boolean;
          };

    constructor(data: Figure2DController) {
        super(data);

        this.flow = {
            stage: 1,
            drawing: false,
            baseCandidates: [],
        };

        makeObservable(this, {
            flow: observable,
        });
    }

    attach(): void {
        const baseCandidates = collectCandidateFragments(this.figure);

        for (const { middlePoint } of baseCandidates) {
            this.snap.addPoint(middlePoint);
        }
        this.flow = {
            baseCandidates,
            stage: 1,
            drawing: false,
        };

        this.viewport.disable();
    }

    dispose(): void {
        this.snap.clean();
    }

    private mapModelsToElements = createTransformer(createMapModelToElements(opacifyColor));

    get modelsElements(): BaseElement[] {
        return this.figure.models.flatMap(this.mapModelsToElements);
    }

    protected renderElements(): BaseElement[] {
        return [...this.modelsElements, ...this.renderTempElements()];
    }

    private *renderTempElements(): Iterable<BaseElement> {
        const { stage } = this.flow;
        if (stage === 1) {
            const { baseCandidates, currentMiddlePoint } = this.flow;

            for (let i = 0; i < baseCandidates.length; i++) {
                const { middlePoint } = baseCandidates[i];
                yield new DotElement({
                    id: `temp_candidate_${i}_dot`,
                    x: middlePoint.x,
                    y: middlePoint.y,
                    overrideRenderOrder: z.points.priority,
                    color: ElementColor.Building,
                });
            }

            if (currentMiddlePoint) {
                const candidate = baseCandidates.find(({ middlePoint }) =>
                    areSamePoints(middlePoint, currentMiddlePoint)
                );

                if (candidate) {
                    const { a, b } = candidate.fragment;
                    yield new LineElement({
                        id: `temp_candidate_line`,
                        x1: a.x,
                        y1: a.y,
                        x2: b.x,
                        y2: b.y,
                        color: ElementColor.Selected,
                        overrideRenderOrder: z.fragments.priority,
                    });
                }
            }
            return;
        }

        if (stage === 2 || stage === 3) {
            const { baseFragment, a } = this.flow;

            yield new LineElement({
                id: `temp_base_line`,
                x1: baseFragment.a.x,
                y1: baseFragment.a.y,
                x2: baseFragment.b.x,
                y2: baseFragment.b.y,
                color: ElementColor.Building,
                overrideRenderOrder: z.fragments.priority,
            });

            if (a) {
                yield new DotElement({
                    id: `temp_a`,
                    color: stage === 2 ? ElementColor.Selected : ElementColor.Building,
                    x: a.x,
                    y: a.y,
                    overrideRenderOrder: z.points.priority,
                });

                const angle = getFragmentAngle(baseFragment, true);
                const unitVector = getUnitVectorFromAngle(angle);
                const line = getLineFromFragment(a, addVectors(a, unitVector));

                yield new LineElement({
                    id: `temp_example_line`,
                    color: ElementColor.Util,
                    thin: true,
                    x1: line.a.x,
                    y1: line.a.y,
                    x2: line.b.x,
                    y2: line.b.y,
                    overrideRenderOrder: z.lines.virtual,
                });
            }

            if (stage === 3 && this.flow.b) {
                const { a, b } = this.flow;

                yield new DotElement({
                    id: `temp_b`,
                    x: b.x,
                    y: b.y,
                    color: ElementColor.Selected,
                    overrideRenderOrder: z.points.priority,
                });

                yield new LineElement({
                    id: `temp_parallel_line`,
                    x1: a.x,
                    y1: a.y,
                    x2: b.x,
                    y2: b.y,
                    color: ElementColor.Building,
                    overrideRenderOrder: z.fragments.priority,
                });
            }
        }
    }

    override handleViewportPointerEvent(e: React.PointerEvent, pointer: IUserPointer) {
        if (e.type === "pointercancel") return this.nextFlow();
        if (!e.isPrimary) return;

        this.movePointer(e, pointer.canvas);
        this.startDrawing(e);
        this.endDrawing(e);
    }

    private movePointer(e: React.PointerEvent, pointer: TPoint) {
        if (e.type === "pointerdown" || e.type === "pointermove") {
            switch (this.flow.stage) {
                case 1: {
                    this.flow.currentMiddlePoint = this.snap.snap(pointer) || void 0;
                    break;
                }
                case 2: {
                    this.flow.a = this.snap.snap(pointer) || void 0;
                    break;
                }
                case 3: {
                    const { a } = this.flow;
                    const angle = getFragmentAngle(this.flow.baseFragment, true);
                    const unitVector = getUnitVectorFromAngle(angle);
                    const line = createLine(a, addVectors(a, unitVector));
                    this.snap.clean();
                    this.snap.addFragment(lineToFragment(line));
                    this.flow.b = this.snap.snap(pointer) || void 0;
                    break;
                }
            }
        }
    }

    private startDrawing(e: React.PointerEvent) {
        if (e.type === "pointerdown") {
            this.flow.drawing = true;
        }
    }

    private endDrawing(e: React.PointerEvent) {
        if (e.type === "pointerup") {
            this.flow.drawing = false;
            switch (this.flow.stage) {
                case 1: {
                    const { baseCandidates, currentMiddlePoint } = this.flow;
                    if (currentMiddlePoint) {
                        const candidate = baseCandidates.find(({ middlePoint }) =>
                            areSamePoints(middlePoint, currentMiddlePoint)
                        );
                        if (candidate) {
                            this.flow = {
                                stage: 2,
                                drawing: false,
                                baseFragment: candidate.fragment,
                            };
                            this.snap.clean().addViewportGrid().addFigureModelsPoints();
                        }
                    }
                    break;
                }
                case 2: {
                    const { a, baseFragment } = this.flow;
                    if (a) {
                        this.flow = {
                            stage: 3,
                            a,
                            drawing: false,
                            baseFragment,
                        };
                        this.snap.clean().addViewportGrid().addFigureModelsPoints();
                    }

                    break;
                }
                case 3: {
                    const { a, b, baseFragment } = this.flow;
                    if (b && !areSamePoints(a, b)) {
                        const baseAngle = getFragmentAngle(baseFragment, true);
                        const parallelAngle = getFragmentAngle({ a, b }, true);

                        const base = equateNumbers(baseAngle, "==", parallelAngle)
                            ? baseFragment
                            : { a: baseFragment.b, b: baseFragment.a };

                        this.saveRaw(base, a, b);
                    }
                    this.nextFlow();
                    break;
                }
            }
        }
    }

    private saveRaw(base: TFragment, a: TPoint, b: TPoint): void {
        this.figure.insertModels(function* () {
            yield ParallelFragmentModel.create({ a, b, base });

            yield PointModel.create({ ...base.a, style: null });
            yield PointModel.create({ ...base.b, style: null });
            yield FragmentModel.create({ ...base, style: null });
            yield PointModel.create({ ...a, style: null });
        }, this);
    }

    static create(data: Figure2DController) {
        return new ParallelFragmentFlow(data);
    }

    override getToolbarMenu(): ToolbarMenu {
        return createDefaultToolbarMenuBuilder()
            .setButtonState(ButtonStates.active, ["fragments"])
            .setButtonIcon("parallel-fragment", ["fragments"])
            .build();
    }

    override getTooltipMenu(): TooltipMenu | null {
        let text: string;

        switch (this.flow.stage) {
            case 1:
                text = "Выберите отрезок, относительно которого будет построение";
                break;
            case 2:
                text = "Выберите первую точку параллельного отрезка";
                break;
            case 3:
                text = "Выберите вторую точку параллельного отрезка";
                break;
        }

        return new TooltipMenu(text, []);
    }
}
