import type { TContentAdminWithGeneratedPassword, TRawContentAdmin, TRawCreateContentAdmin } from "./types";
import type { AdminUser } from "../../entities/content-admin/AdminUser";

import { agent } from "../../agent";
import { throwServiceError } from "../base/RequestError";

import { mapAdmin, mapCreatedAdmin, serializeAdmin } from "./utils";

export class ContentAdminsService {
    async fetchAll(): Promise<AdminUser[]> {
        try {
            const { data } = await agent.get<TRawContentAdmin[]>("/admins/all");

            return data.map(mapAdmin);
        } catch (e) {
            throwServiceError(e);
        }
    }

    async fetchById(id: number): Promise<AdminUser> {
        try {
            const { data } = await agent.get<TRawContentAdmin>(`/admins/${id}/`);

            return mapAdmin(data);
        } catch (e) {
            throwServiceError(e);
        }
    }

    async create(contentAdmin: AdminUser): Promise<TContentAdminWithGeneratedPassword> {
        try {
            const { data } = await agent.post<TRawCreateContentAdmin>("/admins/", serializeAdmin(contentAdmin));

            return mapCreatedAdmin(data);
        } catch (e) {
            throwServiceError(e);
        }
    }

    async update(contentAdmin: AdminUser): Promise<AdminUser> {
        try {
            const { data } = await agent.patch<TRawContentAdmin>(
                `/admins/${contentAdmin.id}/`,
                serializeAdmin(contentAdmin)
            );

            return mapAdmin(data);
        } catch (e) {
            throwServiceError(e);
        }
    }

    async changeActiveStatus(contentAdmin: AdminUser, isActive: boolean): Promise<AdminUser> {
        try {
            const { data } = await agent.patch<TRawContentAdmin>(`/admins/${contentAdmin.id}`, {
                is_active: isActive,
            });

            return mapAdmin(data);
        } catch (e) {
            throwServiceError(e);
        }
    }
}
