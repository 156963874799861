import cn from "classnames";
import React from "react";

import type { ICurlyBracketProps, ICurlyBracketState } from "./CurlyBracket.types";
import type { ICurlyBracketConfig, ICurlyBracketController } from "./types";

import styles from "./CurlyBracket.module.scss";

export class CurlyBracket
    extends React.Component<ICurlyBracketProps, ICurlyBracketState>
    implements ICurlyBracketController
{
    constructor(props: ICurlyBracketProps) {
        super(props);
        this.state = { fontBracket: true };
    }

    private strokeProps: React.SVGProps<SVGLineElement & SVGPathElement> = {
        strokeLinecap: "round",
        stroke: "currentColor",
        fill: "transparent",
    };

    private svgRef = React.createRef<SVGSVGElement>();
    private topDashRef = React.createRef<SVGLineElement>();
    private topRoundRef = React.createRef<SVGPathElement>();
    private topLineRef = React.createRef<SVGLineElement>();
    private topCenterRef = React.createRef<SVGPathElement>();
    private bottomCenterRef = React.createRef<SVGPathElement>();
    private bottomLineRef = React.createRef<SVGLineElement>();
    private bottomRoundRef = React.createRef<SVGPathElement>();
    private bottomDashRef = React.createRef<SVGLineElement>();

    private applyAttributes(element: SVGElement, attrs: Record<string, number>): void {
        Object.entries(attrs).forEach(([key, value]) => element.setAttribute(key, String(value)));
    }

    public updateBracketParams({
        totalHeight,
        totalWidth,
        offsetTop,
        offsetBottom,
        middleHeight,
        radiusX,
        radiusY,
        offsetLeft,
        middleWidth,
        asideWidth,
        strokeWidth,
    }: ICurlyBracketConfig): void {
        const svg = this.svgRef.current!;
        const topDash = this.topDashRef.current!;
        const botDash = this.bottomDashRef.current!;
        const topRound = this.topRoundRef.current!;
        const botRound = this.bottomRoundRef.current!;
        const topLine = this.topLineRef.current!;
        const botLine = this.bottomLineRef.current!;
        const topCenter = this.topCenterRef.current!;
        const botCenter = this.bottomCenterRef.current!;

        const { closing } = this.props;

        if (!svg) {
            return;
        }

        [topDash, botDash, topRound, botRound, topLine, botLine, topCenter, botCenter].forEach((element) => {
            element.setAttribute("stroke-width", String(strokeWidth));
        });

        const w1 = offsetLeft;
        const w2 = offsetLeft + middleWidth;
        const w3 = offsetLeft + middleWidth + radiusX;
        const w4 = offsetLeft + middleWidth + radiusX + asideWidth;

        const x1 = closing ? totalWidth - w1 : w1;
        const x2 = closing ? totalWidth - w2 : w2;
        const x3 = closing ? totalWidth - w3 : w3;
        const x4 = closing ? totalWidth - w4 : w4;

        const y1 = offsetTop;
        const y2 = offsetTop + radiusY;
        const y3 = (totalHeight - middleHeight) / 2;
        const y4 = totalHeight / 2;
        const y5 = (totalHeight + middleHeight) / 2;
        const y6 = totalHeight - offsetBottom - radiusY;
        const y7 = totalHeight - offsetBottom;

        svg.setAttribute("width", `${String(totalWidth)}px`);
        svg.setAttribute("height", `${String(totalHeight)}px`);
        svg.setAttribute("viewBox", `0 0 ${totalWidth} ${totalHeight}`);

        this.applyAttributes(topDash, { x1: x3, y1, x2: x4, y2: y1 });
        topRound.setAttribute("d", `M ${x3} ${y1} Q ${x2} ${y1} ${x2} ${y2}`);
        this.applyAttributes(topLine, { x1: x2, y1: y2, x2, y2: y3 });
        topCenter.setAttribute("d", `M ${x2} ${y3} Q ${x2} ${y4} ${x1} ${y4}`);

        botCenter.setAttribute("d", `M ${x1} ${y4} Q ${x2} ${y4} ${x2} ${y5}`);
        this.applyAttributes(botLine, { x1: x2, y1: y5, x2, y2: y6 });
        botRound.setAttribute("d", `M ${x2} ${y6} Q ${x2} ${y7} ${x3} ${y7}`);
        this.applyAttributes(botDash, { x1: x3, y1: y7, x2: x4, y2: y7 });

        this.setState({ fontBracket: false });
    }

    public useFontBracket() {
        this.setState({ fontBracket: true });
    }

    private getBracketChar(): string {
        const { bracketType, closing } = this.props;
        return {
            curly: closing ? "}" : "{",
            square: closing ? "]" : "[",
        }[bracketType];
    }

    override render(): React.ReactElement {
        const { className } = this.props;
        const { fontBracket } = this.state;

        return (
            <span className={cn(styles.bracket, className)}>
                {fontBracket && this.getBracketChar()}
                <svg
                    ref={this.svgRef}
                    className={cn(styles.bracketSvg, fontBracket && styles.hidden)}
                >
                    <line
                        {...this.strokeProps}
                        ref={this.topDashRef}
                    />
                    <path
                        {...this.strokeProps}
                        ref={this.topRoundRef}
                    />
                    <line
                        {...this.strokeProps}
                        ref={this.topLineRef}
                    />
                    <path
                        {...this.strokeProps}
                        ref={this.topCenterRef}
                    />
                    <path
                        {...this.strokeProps}
                        ref={this.bottomCenterRef}
                    />
                    <line
                        {...this.strokeProps}
                        ref={this.bottomLineRef}
                    />
                    <path
                        {...this.strokeProps}
                        ref={this.bottomRoundRef}
                    />
                    <line
                        {...this.strokeProps}
                        ref={this.bottomDashRef}
                    />
                </svg>
            </span>
        );
    }
}
