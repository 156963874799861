import type { EqualityModel } from "../../../elements/equality/EqualityModel";
import type { LowPriorityOperatorModel } from "../../../elements/operator-low/LowPriorityOperatorModel";
import type { SpecialSymbolModel } from "../../../elements/symbol/SpecialSymbolModel";
import type { BaseElementModel } from "../../element";
import type { IElementFilter } from "../IElementFilter";

import { BaseDefaultElementVisitor } from "../../element/BaseDefaultElementVisitor";

export class KeepCharsNumbersFilter extends BaseDefaultElementVisitor<boolean> implements IElementFilter {
    protected defaultBehaviour(element: BaseElementModel): boolean {
        return false;
    }

    canInsert(element: BaseElementModel): boolean {
        return element.accept(this);
    }

    override doWithChar(element: BaseElementModel): boolean {
        return true;
    }

    override doWithSpace(element: BaseElementModel): boolean {
        return true;
    }

    override doWithDigit(element: BaseElementModel): boolean {
        return true;
    }

    override doWithSymbol(element: SpecialSymbolModel): boolean {
        switch (element.symbolName) {
            case "comma":
            case "dot":
                return true;
        }
        return false;
    }

    override doWithOperatorLow(element: LowPriorityOperatorModel): boolean {
        switch (element.symbolName) {
            case "plus":
            case "minus":
                return true;
        }
        return false;
    }

    override doWithOperatorHigh(element: BaseElementModel): boolean {
        return true;
    }

    override doWithEquality(element: EqualityModel): boolean {
        switch (element.symbolName) {
            case "equals":
                return true;
        }
        return false;
    }
}
