import { action, makeObservable, observable } from "mobx";

import { createSerializedContainer, s2m } from "@viuch/math-editor";

import type { IVariableContentVisitor } from "./IVariableContentVisitor";
import type { TSerializedState } from "@viuch/math-editor/types";

import { BaseVariableContent } from "./BaseVariableContent";

export interface IRandomVariableWithDimensionsContent {
    leftRange: TSerializedState;
    rightRange: TSerializedState;
    quantityFractionalDigits: number;
    sourceDimension: TSerializedState;
    targetDimension: TSerializedState;
}

export class RandomVariableWithDimensionsContent
    extends BaseVariableContent
    implements IRandomVariableWithDimensionsContent
{
    leftRange;
    rightRange;
    quantityFractionalDigits;
    sourceDimension;
    targetDimension;

    constructor(data: IRandomVariableWithDimensionsContent) {
        super();

        this.leftRange = data.leftRange;
        this.rightRange = data.rightRange;
        this.quantityFractionalDigits = data.quantityFractionalDigits;
        this.sourceDimension = data.sourceDimension;
        this.targetDimension = data.targetDimension;

        makeObservable(this, {
            leftRange: observable.ref,
            rightRange: observable.ref,
            quantityFractionalDigits: observable,
            sourceDimension: observable.ref,
            targetDimension: observable.ref,
            setLeftRange: action.bound,
            setRightRange: action.bound,
            setQuantityFractionalDigits: action.bound,
            setTargetDimension: action.bound,
            setSourceDimension: action.bound,
        });
    }

    accept<R>(visitor: IVariableContentVisitor<R>): R {
        return visitor.doWithRandomWithDimension(this);
    }

    static createEmpty(): RandomVariableWithDimensionsContent {
        return new RandomVariableWithDimensionsContent({
            leftRange: s2m`1`,
            rightRange: s2m`9`,
            quantityFractionalDigits: 0,
            sourceDimension: createSerializedContainer(),
            targetDimension: createSerializedContainer(),
        });
    }

    setLeftRange(value: TSerializedState) {
        this.leftRange = value;
    }

    setRightRange(value: TSerializedState) {
        this.rightRange = value;
    }

    setQuantityFractionalDigits(value: number) {
        this.quantityFractionalDigits = value;
    }

    setSourceDimension(sourceDimension: TSerializedState) {
        this.sourceDimension = sourceDimension;
    }

    setTargetDimension(targetDimension: TSerializedState) {
        this.targetDimension = targetDimension;
    }

    clone(): RandomVariableWithDimensionsContent {
        return new RandomVariableWithDimensionsContent(this);
    }
}
