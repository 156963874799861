import cn from "classnames";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

import { transformPoint } from "@viuch/geometry-lib/transformations/functions";
import { createScaleXY } from "@viuch/geometry-lib/transformations/scale";
import { MathExpressionView } from "@viuch/math-editor";

import type { Graph2DViewportController } from "../../editor/core/Graph2DViewportController";
import type { Graph2DViewSettings } from "../../editor/core/Graph2DViewSettings";
import type { AxisGraphViewItem } from "../../view-models/AxisGraphViewItem";

import { ArrowHeadView } from "../util/ArrowHeadView";
import { LineView } from "../util/LineView";
import { StrokeView } from "../util/StrokeView";

import { getXData, getYData } from "./AxisGraphView.utils";

import styles from "./AxisGraphView.module.scss";

type Props = {
    axis: AxisGraphViewItem;
    viewport: Graph2DViewportController;
    settings: Graph2DViewSettings;
    color: string;
};

export const AxisGraphView = observer(function AxisGraphViewItem({ axis, viewport, settings, color }: Props) {
    const { rootSize } = viewport;

    const zero = useMemo(() => {
        return transformPoint({ x: 0, y: 0 }, [
            ...settings.coordinateSystemTransform,
            ...viewport.viewportTransformations,
            createScaleXY(0.01, 0.01),
        ]);
    }, [settings, viewport]);

    if (!rootSize) return null;

    const { left, right, top, bottom } = viewport.bounds;

    const yPositionOnScreen = (zero.y - top) / (bottom - top);
    const xAxisLabelsOnTop = yPositionOnScreen > 0.25;

    const xPositionOnScreen = (zero.x - left) / (right - left);
    const yAxisLabelsOnRight = xPositionOnScreen < 0.25;

    const isX = axis.type === "x";

    const {
        position,
        axis: { head, tail },
        labels: { min, max },
    } = (isX ? getXData : getYData)(rootSize, viewport, zero);

    const pointsInView = axis.points.filter(({ boundPosition, position }) => {
        if (axis.omitZero && position === 0) return false;

        return boundPosition >= min && boundPosition <= max;
    });

    return (
        <g className={cn(styles.root)}>
            <g data-info="axis-x">
                <LineView
                    ax={p(isX ? head : position)}
                    ay={p(isX ? position : head)}
                    bx={p(isX ? tail : position)}
                    by={p(isX ? position : tail)}
                    color={color}
                    width={1}
                />
                <ArrowHeadView
                    x={p(isX ? tail : position)}
                    y={p(isX ? position : head)}
                    color={color}
                    width={1}
                    length={5}
                    direction={isX ? "right" : "up"}
                />
                <g>
                    {pointsInView.map(({ label, boundPosition, position: key }) => {
                        const offset = isX ? boundPosition : 1 - boundPosition;

                        return (
                            <g
                                key={key}
                                data-position={key}
                            >
                                <StrokeView
                                    x={p(isX ? offset : position)}
                                    y={p(isX ? position : offset)}
                                    color={color}
                                    direction={isX ? "v" : "h"}
                                />
                                <foreignObject
                                    width={1}
                                    height={1}
                                    x={p(isX ? offset : position)}
                                    y={p(isX ? position : offset)}
                                >
                                    <div
                                        className={cn(
                                            styles.mathLabelContainer,
                                            isX && (xAxisLabelsOnTop ? styles._up : styles._down),
                                            !isX && (yAxisLabelsOnRight ? styles._right : styles._left)
                                        )}
                                        style={{ color }}
                                    >
                                        <MathExpressionView
                                            mathExpression={label}
                                            className={styles.mathLabel}
                                            formulaClassName={styles.mathLabel__formula}
                                        />
                                    </div>
                                </foreignObject>
                            </g>
                        );
                    })}
                </g>
            </g>
        </g>
    );
});

const f = (n: number) => +n.toFixed(4);
const p = (v: number) => `${f(v * 100)}%`;
