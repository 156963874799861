import type { TControlsSchema } from "../types/controls";

import { VirtualFormTree } from "../tree/VirtualFormTree";

import { instantiateFormControl } from "./instantiateFormControl";

export function instantiateForm(data: TControlsSchema): VirtualFormTree {
    const form = instantiateFormControl(data.rootControl, null);

    return new VirtualFormTree(form);
}
