import { assert } from "@viuch/utils/debug";

import type { BaseElementModel } from "../core/element";
import type { TAnySerializedElement } from "../types";

import { BaseCommand } from "../core/commands";

import { NestingLimitExceedError } from "./errors/NestingLimitExceedError";
import { SelectionRequiredError } from "./errors/SelectionRequiredError";
import { computeNestingDown, computeNestingUp } from "./utils";

export class InsertInsteadOfSelectedCommand<T extends BaseElementModel> extends BaseCommand<T> {
    protected readonly serializedElement: TAnySerializedElement;
    protected readonly newSelectedElementsData: TAnySerializedElement[];
    protected readonly wrapperContainerId?: string;

    public constructor(model: T, serializedElement: TAnySerializedElement) {
        super(model);

        if (!this.selection.selectedElements || !this.selectionSnapshot) {
            throw new SelectionRequiredError();
        }

        const nesting = computeNestingDown(this.selection.commonContainer);
        if (nesting >= model.inputService.maxNestingLevel) {
            throw new NestingLimitExceedError();
        }

        const temp = this.deserializeElement(serializedElement);
        const tempWrapperContainer = temp.getWrapperContainer();
        if (tempWrapperContainer) {
            if (computeNestingUp(this.selection.selectedElements, nesting) >= model.inputService.maxNestingLevel) {
                throw new NestingLimitExceedError();
            }
            this.wrapperContainerId = tempWrapperContainer.uuid;
            this.newSelectedElementsData = this.selection.selectedElements
                .filter((element) => temp.behaviour.checkCanBeInserted(element, tempWrapperContainer))
                .map((element) => {
                    const serializePrototype = element.serializeAsClone();
                    const instance = this.deserializeElement(serializePrototype);
                    return instance.serialize();
                });
        } else {
            this.newSelectedElementsData = [];
        }

        this.serializedElement = serializedElement;
    }

    apply(): void {
        assert(this.selectionSnapshot);

        const element = this.inputService.deserializeElement(this.serializedElement);
        const container = this.getSelectionContainer();

        this.removeSelectedElements();
        container.insertElement(element, this.selectionSnapshot.firstElementIndex);

        if (this.wrapperContainerId) {
            const wrapperContainer = this.containers.getById(this.wrapperContainerId);
            const childrenElements = this.newSelectedElementsData.map((data) => this.deserializeElement(data));
            wrapperContainer.insertElementsRange(childrenElements, 0);
        }

        this.cursor.moveToPosition(container, this.selectionSnapshot.firstElementIndex);
        element.behaviour.handleInserted();
    }

    rollback(): void {
        assert(this.selectionSnapshot);

        const container = this.getSelectionContainer();
        container.removeElementByIndex(this.selectionSnapshot.firstElementIndex);

        this.cursor.moveToPosition(container, this.selectionSnapshot.firstElementIndex);

        this.restoreRemovedSelectedElements();
    }
}
