import type { ICondition } from "./ICondition";
import type { TRequire } from "../types/controls";

import { pathFromRaw } from "../utils/paths";

import { ConditionAll } from "./ConditionAll";
import { ConditionAny } from "./ConditionAny";
import { ConditionConst } from "./ConditionConst";
import { ConditionPathValueEquals } from "./ConditionPathValueEquals";

export function createCondition(condition: TRequire | undefined): ICondition {
    if (!condition) {
        return new ConditionConst(true);
    }

    if (Array.isArray(condition)) {
        return new ConditionAny(condition.map((c) => createCondition(c)));
    }

    const conditions = Object.entries(condition).map(([path, value]) => {
        const _path = pathFromRaw(path);

        if (Array.isArray(value)) {
            const innerConditions = value.map((v) => {
                return new ConditionPathValueEquals(_path, v);
            });
            return new ConditionAny(innerConditions);
        }

        return new ConditionPathValueEquals(_path, value);
    });

    return new ConditionAll(conditions);
}
