import cn from "classnames";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";

import { KeyboardService } from "@viuch/math-editor";
import { DesktopKeyboard } from "@viuch/math-editor/components/math-keybord/desktop-blocks";
import { Portal } from "@viuch/shared/features/portal-lib";
import { useConst, useToggle } from "@viuch/utils/hooks";

import type { PropsWithChildren } from "react";

import { MathKeyboardProviderContext } from "./MathKeyboardProvider.context";

import styles from "./MathKeyboardProvider.module.scss";

export const MathKeyboardProvider = observer(function FloatingMathKeyboard({ children }: PropsWithChildren<{}>) {
    const keyboard = useConst(() => new KeyboardService());
    const initialState = useConst(getInitialKeyboardOptions);
    const [autoOpen, toggleAutoOpen, setAutoOpen] = useToggle(initialState.autoOpen);
    const [pinned, togglePinned, setPinned] = useToggle(initialState.pinned);
    const isOpen = autoOpen ? !!keyboard.currentInputService || pinned : pinned;

    const handleClose = () => {
        setPinned(false);
    };

    const handleOpen = () => {
        if (!isOpen && !pinned) {
            setPinned(true);
        }
    };

    useEffect(() => {
        saveInitialKeyboardOptions(autoOpen, pinned);
    }, [autoOpen, pinned]);

    return (
        <MathKeyboardProviderContext.Provider value={keyboard}>
            {children}
            <Portal className={styles.portal}>
                <div
                    className={cn(styles.container, !isOpen && styles.closed)}
                    onClick={handleOpen}
                >
                    <div className={styles.content}>
                        <DesktopKeyboard
                            keyboardService={keyboard}
                            className={cn(styles.keyboard)}
                        />
                        <div className={styles.controls}>
                            <button
                                className={cn(styles.control, autoOpen && styles.highlight)}
                                onClick={toggleAutoOpen}
                                title="Клавиатура открывается и закрывается автоматически"
                            >
                                Авто
                            </button>
                            <button
                                className={cn(styles.control, pinned && styles.highlight)}
                                onClick={handleClose}
                                title="Клавиатура не будет закрываться"
                            >
                                Закрепить
                            </button>
                        </div>
                    </div>
                </div>
            </Portal>
        </MathKeyboardProviderContext.Provider>
    );
});

const defaultInitialKeyboardOptions = { autoOpen: true, pinned: false };

function getInitialKeyboardOptions(): { autoOpen: boolean; pinned: boolean } {
    try {
        const data = localStorage.getItem("viuch-admin-overlay-keyboard-options");
        if (!data) {
            return defaultInitialKeyboardOptions;
        }
        const options = JSON.parse(data);
        const autoOpen = !!options.autoOpen;
        const pinned = !!options.pinned;
        return { pinned, autoOpen };
    } catch {
        return defaultInitialKeyboardOptions;
    }
}

function saveInitialKeyboardOptions(autoOpen: boolean, pinned: boolean) {
    localStorage.setItem("viuch-admin-overlay-keyboard-options", JSON.stringify({ autoOpen, pinned }));
}
