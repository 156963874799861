import React from "react";

type TUseDisableSelectionFix = {
    disableSelectAbility: VoidFunction;
    returnSelectAbility: VoidFunction;
};

export function useDisableSafariTouchSelectionFix(applyClearEffect = true): TUseDisableSelectionFix {
    const [ability] = React.useState(() => ({
        disableSelectAbility: () => {
            document.body.style.setProperty("-webkit-touch-callout", "none");
            document.body.style.setProperty("-webkit-user-select", "none");
        },
        returnSelectAbility: () => {
            document.body.style.removeProperty("-webkit-touch-callout");
            document.body.style.removeProperty("-webkit-user-select");
        },
    }));

    React.useEffect(() => {
        return () => {
            applyClearEffect && ability.returnSelectAbility();
        };
    }, [ability, applyClearEffect]);

    return ability;
}
