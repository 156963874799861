import type { TRawSolverUsageStatistics } from "./SolversUsageStatsService.types";

import { agent } from "../../agent";
import { throwServiceError } from "../base/RequestError";

export class SolversUsageStatsService {
    async getSolversUsage(): Promise<TRawSolverUsageStatistics> {
        try {
            const { data } = await agent.get<TRawSolverUsageStatistics>(`/statistics/solver_usage/`);

            return data;
        } catch (e) {
            throwServiceError(e);
        }
    }
}
