import type { TRawAnswer } from "./AnswersService.types";
import type { Answer } from "../../entities/questions/Answer";

import { agent } from "../../agent";
import { throwServiceError } from "../base/RequestError";

import { mapAnswer, serializeCreateAnswer, serializePatchAnswer } from "./AnswersService.utils";

export class AnswersService {
    async createAnswer(answer: Answer): Promise<Answer> {
        try {
            const { data } = await agent.post<TRawAnswer>(`/feedback/answers`, serializeCreateAnswer(answer));

            return mapAnswer(data);
        } catch (e) {
            throwServiceError(e);
        }
    }

    async patchAnswer(answer: Answer): Promise<Answer> {
        try {
            const { data } = await agent.patch<TRawAnswer>(
                `/feedback/answers/${answer.id}`,
                serializePatchAnswer(answer)
            );

            return mapAnswer(data);
        } catch (e) {
            throwServiceError(e);
        }
    }
}
