import { makeObservable, observable } from "mobx";

import { createSerializedContainer } from "@viuch/math-editor";
import { generateId } from "@viuch/shared/utils/data";

import type { TSerializedState } from "@viuch/math-editor";

export enum PolygonKinds {
    NoType = "no-type",
    Rectangle = "rectangle",
    Square = "square",
    Parallelogram = "parallelogram",
    Rhomb = "rhombus",
}

export type TPolygon = {
    kind: PolygonKinds;
    area: TSerializedState | null;
    perimeter: TSerializedState | null;
    value: TSerializedState;
};

export class Polygon implements TPolygon {
    readonly $$instance_id = generateId();

    area: TSerializedState | null;
    perimeter: TSerializedState | null;
    value: TSerializedState;
    kind: PolygonKinds;

    constructor(data: TPolygon) {
        this.kind = data.kind;
        this.area = data.area ? { ...data.area } : null;
        this.perimeter = data.perimeter;
        this.value = data.value;

        makeObservable(this, {
            $$instance_id: true,
            clone: true,
            value: observable.ref,
            kind: observable,
            area: observable.ref,
            perimeter: observable.ref,
        });
    }

    clone() {
        return new Polygon(this);
    }

    static createEmpty() {
        return new Polygon({
            value: createSerializedContainer(),
            perimeter: null,
            area: null,
            kind: PolygonKinds.NoType,
        });
    }
}
