import { observer } from "mobx-react-lite";
import React from "react";

import type { PropsWithChildren } from "react";

import styles from "./ScaledSvgContainer.module.scss";

type Props = PropsWithChildren<{}>;

export const ScaledSvgContainer = observer(function ScaledSvgContainer({ children }: Props) {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 100 100"
            className={styles.fullscreenSvg}
            vectorEffect="non-scaling-stroke"
        >
            <svg
                className={styles.innerSvg}
                vectorEffect="non-scaling-stroke"
            >
                {children}
            </svg>
        </svg>
    );
});
