import type { ICommand } from "./ICommand";
import type { InputService } from "../../services";
import type { TAnySerializedElement } from "../../types";
import type { ClipboardService } from "../clipboard";
import type { ContainerModel, ContainersStore } from "../container";
import type { CursorState } from "../cursor";
import type { BaseElementModel } from "../element";
import type { ElementsStore } from "../element/ElementsStore";
import type { LimitsStore } from "../limits/LimitsStore";
import type { SelectionController, TSelectionSnapshot, TSerializedElementWithIndex } from "../selection";

export abstract class BaseCommand<T extends BaseElementModel = BaseElementModel> implements ICommand {
    protected readonly inputService: InputService;
    protected readonly cursorContainerId: string;
    protected readonly cursorPosition: number;
    protected readonly parentElementId: string;

    protected readonly serializedSelectedElementsData: TSerializedElementWithIndex[];
    protected selectionSnapshot: TSelectionSnapshot | null;

    protected constructor(element: T) {
        this.inputService = element.inputService;
        this.cursorContainerId = this.cursor.container.uuid;
        this.cursorPosition = this.cursor.index;
        this.parentElementId = element.uuid;

        this.selectionSnapshot = this.selection.createSnapshot();
        this.serializedSelectedElementsData = this.selection.serializeSelectedElements();
    }

    protected get selection(): SelectionController {
        return this.inputService.selectionController;
    }

    protected get cursor(): CursorState {
        return this.inputService.cursorState;
    }

    protected get containers(): ContainersStore {
        return this.inputService.containers;
    }

    protected get elements(): ElementsStore {
        return this.inputService.elements;
    }

    protected get clipboard(): ClipboardService {
        return this.inputService.clipboard;
    }

    protected get limits(): LimitsStore {
        return this.inputService.limits;
    }

    protected getCursorContainer(): ContainerModel<T> {
        return this.containers.getById<T>(this.cursorContainerId);
    }

    protected getSelectionContainer(): ContainerModel {
        if (!this.selectionSnapshot) {
            throw new Error("Selection snapshot is not present");
        }
        return this.containers.getById(this.selectionSnapshot.containerUuid);
    }

    protected getCursorParentElement(): T {
        const element = this.elements.getById<T>(this.parentElementId);

        if (!element) {
            throw new Error("Element was not registered");
        }
        return element;
    }

    protected deserializeElement(serializedElement: TAnySerializedElement): BaseElementModel {
        return this.inputService.deserializeElement(serializedElement);
    }

    public abstract apply(): void;

    public abstract rollback(): void;

    protected restoreCursor(): void {
        this.cursor.moveToPosition(this.getCursorContainer(), this.cursorPosition);
    }

    protected removeSelectedElements(): void {
        this.selection.clearSelection();

        if (this.selectionSnapshot) {
            const container = this.getSelectionContainer();
            const indexes = this.serializedSelectedElementsData.map(({ index }) => index);
            container.removeElementsByIndexes(indexes);
        }
    }

    protected restoreRemovedSelectedElements(): void {
        if (this.selectionSnapshot) {
            const container = this.getSelectionContainer();
            this.serializedSelectedElementsData.forEach(({ serializedElement, index }) => {
                const element = this.deserializeElement(serializedElement);
                container.insertElement(element, index);
            });
            this.selection.restoreSnapshot(this.selectionSnapshot);
        }
    }
}
