import { action, makeObservable, observable } from "mobx";

import type { HighlightRange } from "./ranges/HighlightRange";

import { CrossOutHighlightRange } from "./ranges/CrossedOutHighlightRange";

type TDisposeFunction = VoidFunction;

export class HighlightsList {
    @observable private readonly items = new Map<HighlightRange, TDisposeFunction>();

    constructor() {
        makeObservable(this);
    }

    @action.bound
    clear() {
        for (const value of [...this.items.keys()]) {
            this.remove(value);
        }
    }

    @action.bound
    add(item: HighlightRange) {
        const dispose = item.effect();
        this.items.set(item, dispose);
    }

    @action.bound
    remove(item: HighlightRange) {
        this.items.delete(item);
    }

    *[Symbol.iterator](): Iterator<HighlightRange> {
        yield* this.items.keys();
    }

    all(): HighlightRange[] {
        return [...this.items.keys()];
    }

    allCrossOut(conteinerUUID: string): CrossOutHighlightRange[] {
        const crossedOutHighlightRanges: CrossOutHighlightRange[] = [];

        for (const highlightRange of this.items.keys()) {
            if (highlightRange instanceof CrossOutHighlightRange && highlightRange.containerUuid == conteinerUUID) {
                crossedOutHighlightRanges.push(highlightRange);
            }
        }

        return crossedOutHighlightRanges;
    }
}
