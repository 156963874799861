import cn from "classnames";
import { observer } from "mobx-react-lite";

import type { TIntervalSign } from "../../statement/types";

import { getPath } from "./Interval.utils";

import styles from "./Interval.module.scss";

type Props = {
    sign: TIntervalSign;
    first?: boolean;
    last?: boolean;
};

export const Interval = observer(function Interval({ sign, first, last }: Props) {
    const { path, area, hideLine } = getPath(sign, first, last);

    return (
        <svg
            data-type="signPath"
            width="100%"
            height="64"
            viewBox="0 0 10 10"
            preserveAspectRatio="none"
            xmlns="http://www.w3.org/2000/svg"
            className={cn(styles.svg, styles[sign])}
        >
            {area && (
                <path
                    className={styles.fill}
                    d={area}
                    vectorEffect="non-scaling-stroke"
                />
            )}
            {path && (
                <path
                    className={styles.path}
                    d={path}
                    vectorEffect="non-scaling-stroke"
                />
            )}
            {!hideLine && (
                <path
                    className={styles.line}
                    d="M 0 5 H 10"
                    vectorEffect="non-scaling-stroke"
                />
            )}
        </svg>
    );
});
