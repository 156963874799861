import React from "react";

import type { AngleModel } from "./AngleModel";
import type { TElementProps } from "../../types/element";

import { useMathEditorElementRef } from "../../hooks";
import { BaseSymbolElement } from "../base/base-symbol";

import { AngleSymbolIcon } from "./icon";

import styles from "./AngleElement.module.scss";

export const AngleElement: React.VFC<TElementProps<AngleModel>> = ({ elementModel }) => {
    const ref = useMathEditorElementRef(elementModel);

    return (
        <BaseSymbolElement ref={ref}>
            <AngleSymbolIcon className={styles.angle} />
        </BaseSymbolElement>
    );
};
