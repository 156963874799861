import cn from "classnames";
import { observer } from "mobx-react-lite";
import React from "react";

import styles from "./ToolbarButtonIcon.module.scss";

type Props = {
    iconSrc: string;
    className?: string;
};

export const ToolbarButtonIcon = observer(function ToolbarButtonIcon({ iconSrc, className }: Props) {
    return (
        <i
            className={cn(styles.icon, className)}
            style={{
                maskImage: `url(${iconSrc})`,
                WebkitMaskImage: `url(${iconSrc})`,
            }}
        />
    );
});
