import type { StudentSolutionVariant } from "./StudentSolutionVariant";
import type { TSerializedState } from "@viuch/math-editor";

export class StudentSolution {
    readonly id: number;
    readonly variant: StudentSolutionVariant;
    readonly studentId: number | null;
    readonly solution: TSerializedState;
    readonly answers: TSerializedState[];
    readonly isFinished: boolean;

    constructor(
        id: number,
        variant: StudentSolutionVariant,
        studentId: number | null,
        solution: TSerializedState,
        answers: TSerializedState[],
        isFinished: boolean
    ) {
        this.id = id;
        this.variant = variant;
        this.studentId = studentId;
        this.solution = solution;
        this.answers = answers;
        this.isFinished = isFinished;
    }
}
