import type { ISerializedLog } from "./types";
import type { TSerializedElementPrototype } from "../../core/element";
import type { IElementVisitor } from "../../core/element/IElementVisitor";
import type { IStrategy } from "../../core/strategies";
import type { IMultiContainerElement } from "../../core/strategies/types";
import type { InputService } from "../../services";
import type { TContainerElements, TElementDeserializerFunction } from "../../types";

import { ContainerModel } from "../../core/container";
import { BaseElementModel } from "../../core/element";
import { MultiContainerElementStrategy } from "../../core/strategies/MultiContainerElementStrategy";

export class LogModel extends BaseElementModel<ISerializedLog> implements IMultiContainerElement {
    public readonly base: ContainerModel;
    public readonly content: ContainerModel;
    public readonly degreeContent: ContainerModel;

    public constructor(
        inputService: InputService,
        base: TContainerElements,
        degreeContent: TContainerElements,
        content: TContainerElements,
        uuid?: string
    ) {
        super(inputService, uuid);

        this.content = new ContainerModel(content, this, ["content"], inputService, {
            showPlaceholderIfEmpty: true,
        });
        this.base = new ContainerModel(base, this, ["base"], inputService, {
            showPlaceholderIfEmpty: true,
        });
        this.degreeContent = new ContainerModel(degreeContent, this, ["degreeContent"], inputService);
    }

    public serialize(): ISerializedLog {
        return {
            type: "log",
            uuid: this.uuid,
            content: this.content.serialize(),
            base: this.base.serialize(),
            degreeContent: this.degreeContent.serialize(),
        };
    }

    public static deserialize: TElementDeserializerFunction<ISerializedLog> = (
        { inputService },
        { content, degreeContent, base, uuid }
    ) =>
        new LogModel(
            inputService,
            inputService.deserializeContainer(base),
            inputService.deserializeContainer(degreeContent),
            inputService.deserializeContainer(content),
            uuid
        );

    public getContainersToMoveCursorBetween(): ContainerModel[] {
        return [this.degreeContent, this.base, this.content];
    }

    public overridePrimaryContainer(): ContainerModel {
        return this.base.checkIsEmpty() ? this.base : this.content;
    }

    override getWrapperContainer(): ContainerModel {
        return this.content;
    }

    protected override initBehaviour(): IStrategy {
        return new MultiContainerElementStrategy(this);
    }

    public accept<R>(visitor: IElementVisitor<R>): R {
        return visitor.doWithLog(this);
    }

    public serializeAsClone(): TSerializedElementPrototype<ISerializedLog> {
        const { uuid, ...clone } = this.serialize();
        return {
            ...clone,
            content: this.content.serializeAsClone(),
            degreeContent: this.degreeContent.serializeAsClone(),
            base: this.base.serializeAsClone(),
        };
    }
}
