import type {
    TContentAdminStatsItem,
    TContentAdminStatsByThemesItem,
    TRawContentAdminStatsItem,
    TRawContentAdminStatsByThemesItem,
    TRawStatsAdminHistoryTimeByWeekRecord,
    TRawStatsAdminHistoryTimeByTheme,
    TRawStatsAdminHistoryTimeByMonthRecord,
} from "./StatsInfoService.types";

import { agent } from "../../agent";
import { throwServiceError } from "../base/RequestError";

import { mapContentAdminStatsItem, mapContentAdminStatsByThemes } from "./StatsInfoService.utils";

export class StatsInfoService {
    async fetchContentAdminStatsById(contentAdminId: number): Promise<TContentAdminStatsItem[]> {
        try {
            const { data } = await agent.get<TRawContentAdminStatsItem[]>(
                `/statistics/admin_history/?user_in=${contentAdminId}`
            );

            return data.map(mapContentAdminStatsItem);
        } catch (e) {
            throwServiceError(e);
        }
    }

    async fetchStatsThemes(contentAdminId: number): Promise<TContentAdminStatsByThemesItem[]> {
        try {
            const { data } = await agent.get<TRawContentAdminStatsByThemesItem[]>(
                `/statistics/admin_history/by_theme/?user_in=${contentAdminId}`
            );

            return data.map(mapContentAdminStatsByThemes);
        } catch (e) {
            throwServiceError(e);
        }
    }

    async adminStatsByWeek(adminId: number): Promise<TRawStatsAdminHistoryTimeByWeekRecord[]> {
        try {
            const { data } = await agent.get<TRawStatsAdminHistoryTimeByWeekRecord[]>(
                `/statistics/admin_history/by_week/?user_in=${adminId}`
            );

            return data;
        } catch (e) {
            throwServiceError(e);
        }
    }

    async adminStatsByMonth(adminId: number): Promise<TRawStatsAdminHistoryTimeByMonthRecord[]> {
        try {
            const { data } = await agent.get<TRawStatsAdminHistoryTimeByMonthRecord[]>(
                `/statistics/admin_history/by_month/?user_in=${adminId}`
            );

            return data;
        } catch (e) {
            throwServiceError(e);
        }
    }

    async adminStatsByThemesTotal(
        adminId?: number,
        gradeIds?: number[],
        subjectIds?: number[],
        signal?: AbortSignal
    ): Promise<TRawStatsAdminHistoryTimeByTheme> {
        try {
            const params = {
                user_in: adminId,
                grade_in: gradeIds?.join(",") ?? undefined,
                subject_in: subjectIds?.join(",") ?? undefined,
            };

            const { data } = await agent.get<TRawStatsAdminHistoryTimeByTheme>(
                `/statistics/admin_history/by_theme_total/`,
                { params, signal }
            );

            return data;
        } catch (e) {
            throwServiceError(e);
        }
    }
}
