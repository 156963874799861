import type { TSerializedContainer } from "../../../types";
import type { EquationsSetModel } from "../EquationsSetModel";

import { BaseCommand } from "../../../core/commands";

export class RemoveContainerCommand extends BaseCommand<EquationsSetModel> {
    private readonly removeContainerIndex: number;
    private readonly removedSerializedContainer: TSerializedContainer;

    public constructor(model: EquationsSetModel, removeContainerIndex: number) {
        super(model);
        this.removeContainerIndex = removeContainerIndex;
        this.removedSerializedContainer = this.cursor.container.serialize();
    }

    apply(): void {
        const element = this.getCursorParentElement();
        element.removeContainerAt(this.removeContainerIndex);
        const containers = element.getContainersToMoveCursorBetween();
        const prevContainer = containers[this.removeContainerIndex - 1];
        this.cursor.moveToEnd(prevContainer);
    }

    rollback(): void {
        const element = this.getCursorParentElement();
        const containerData = this.inputService.deserializeContainer(this.removedSerializedContainer);
        element.addNewContainerAt(containerData, this.removeContainerIndex);
        this.restoreCursor();
    }
}
