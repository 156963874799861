import { computedFn } from "mobx-utils";

import type { Graph2DView } from "../core/Graph2DView";
import type { BaseGraphViewItem } from "../editor/rendering/BaseGraphViewItem";
import type { AxisGraphViewItem } from "../view-models/AxisGraphViewItem";

import { BaseGraphItem } from "../editor/model/BaseGraphItem";

export class AxisGraphItem extends BaseGraphItem {
    constructor() {
        super();
    }

    getAxesView = computedFn((view: Graph2DView): Iterable<BaseGraphViewItem> => {
        const items: BaseGraphViewItem[] = [];

        const scale = view.viewport.gridRoundedScale;

        const xView = this.xViews.get(scale);
        if (xView) items.push(xView);

        const yView = this.yViews.get(scale);
        if (yView) items.push(yView);

        return items;
    });

    private readonly xViews: Map<number, AxisGraphViewItem> = new Map();
    private readonly yViews: Map<number, AxisGraphViewItem> = new Map();

    addXView(scale: number, view: AxisGraphViewItem) {
        this.xViews.set(scale, view);
    }

    addYView(scale: number, view: AxisGraphViewItem) {
        this.yViews.set(scale, view);
    }

    override clone(): AxisGraphItem {
        // Внимание: Т.к. оси являются моделью времени инициализации, она не изменяется.
        // В целях улучшения производительности, вместо клонирования модели возвращается та же самая модель.
        return this;
    }
}
