export type TGraph2DFeaturesConfiguration = {
    add: TGraph2DFeaturesConfiguration_Add[];
    transformations: TGraph2DFeaturesConfiguration_Transformation[];
    modifiers: TGraph2DFeaturesConfiguration_Modifier[];
    behaviour: TGraph2DFeaturesConfiguration_Behaviour[];
};

export type TGraph2DFeaturesConfiguration_Add =
    | "point"
    | "pointOnInterval"
    | "line"
    | "parabola"
    | "cubicParabola"
    | "hyperbola"
    | "curt"
    | "sqrt"
    | "custom";

export type TGraph2DFeaturesConfiguration_Transformation =
    | "absX" //
    | "absY"
    | "move"
    | "scale"
    | "cut";

export type TGraph2DFeaturesConfiguration_Modifier =
    | "color" //
    | "pointLabel"
    | "inequality";

export type TGraph2DFeaturesConfiguration_Behaviour = "history";

export function createAllFeatures(): TGraph2DFeaturesConfiguration {
    return {
        add: [
            //
            "point",
            "pointOnInterval",
            "line",
            "parabola",
            "cubicParabola",
            "hyperbola",
            "curt",
            "sqrt",
            "custom",
        ],
        behaviour: [
            //
            "history",
        ],
        modifiers: [
            //
            "color",
            "pointLabel",
            "inequality",
        ],
        transformations: [
            //
            "absX",
            "absY",
            "move",
            "scale",
            "cut",
        ],
    };
}
