import * as mobx from "mobx";

import type { ISerializedEditor } from "./types";
import type { TSerializedElementPrototype } from "../../core/element";
import type { IElementVisitor } from "../../core/element/IElementVisitor";
import type { IStrategy } from "../../core/strategies";
import type { IMultiContainerElement } from "../../core/strategies/types";
import type { InputService, KeyboardService } from "../../services";
import type { TContainerElements, TElementDeserializerFunction, TPoint, TRect } from "../../types";

import { ContainerModel } from "../../core/container";
import { BaseElementModel } from "../../core/element";
import { getRectFromDomRect, pointSubtraction } from "../../utils/positions";

import { EditorStrategy } from "./EditorStrategy";

export class EditorModel extends BaseElementModel<ISerializedEditor> implements IMultiContainerElement {
    public readonly rootContainer: ContainerModel;
    public isFocused: boolean;

    public constructor(elements: TContainerElements, inputService: InputService, uuid?: string) {
        super(inputService, uuid);

        this.isFocused = false;
        this.rootContainer = new ContainerModel(elements, this, ["$"], inputService);

        mobx.makeObservable(this, {
            isFocused: mobx.observable,
            handleFocus: mobx.action,
            onValueBlur: mobx.action,
            keyboardService: mobx.computed,
        });
    }

    get keyboardService(): KeyboardService | null {
        return this.inputService.keyboard ?? null;
    }

    public getContainersToMoveCursorBetween(): ContainerModel[] {
        return [this.rootContainer];
    }

    protected override initBehaviour(): IStrategy {
        return new EditorStrategy(this);
    }

    public handleFocus = (): void => {
        this.keyboardService?.setCurrentInputService(this.inputService);
        this.isFocused = true;
    };

    public onValueBlur = (): void => {
        this.keyboardService?.releaseCurrentInputService(this.inputService);
        this.isFocused = false;
    };

    public setElements(elements: BaseElementModel[]): void {
        this.rootContainer.setElements(elements);
    }

    public serialize(): ISerializedEditor {
        return {
            type: "input",
            uuid: this.uuid,
            content: this.rootContainer.serialize(),
        };
    }

    public static deserialize: TElementDeserializerFunction<ISerializedEditor> = (
        { inputService },
        { content, uuid }
    ) => new EditorModel(inputService.deserializeContainer(content), inputService, uuid);

    public getContainerRect(): TRect {
        const container = this.domElement!;
        return getRectFromDomRect(container.getBoundingClientRect());
    }

    public getContainerScrollAsPoint(): TPoint {
        const element = this.domElement!;
        return { x: element.scrollLeft, y: element.scrollTop };
    }

    public getOffsetPoint(containerRect?: TRect): TPoint {
        return pointSubtraction(containerRect ?? this.getContainerRect(), this.getContainerScrollAsPoint());
    }

    public getRelativePoint(clientPoint: TPoint, offset?: TPoint): TPoint {
        return pointSubtraction(clientPoint, offset ?? this.getOffsetPoint());
    }

    public accept<R>(visitor: IElementVisitor<R>): R {
        return visitor.doWithEditor(this);
    }

    public serializeAsClone(): TSerializedElementPrototype<ISerializedEditor> {
        const { uuid, ...clone } = this.serialize();
        return {
            ...clone,
            content: this.rootContainer.serializeAsClone(),
        };
    }
}
