import cn from "classnames";
import { observer } from "mobx-react-lite";
import React from "react";

import type { PropsWithChildren } from "react";

import styles from "./ModalFader.module.scss";

type Props = PropsWithChildren<{
    className?: string;
}>;

export const ModalFader = observer(function ModalFader({ className, children }: Props) {
    return <div className={cn(styles.fader, className)}>{children}</div>;
});
