import { makeObservable, observable } from "mobx";

import { generateId } from "@viuch/shared/utils/data";

import type { BaseVariableContent } from "./variables";

import { VariableGenerationTypes } from "./constants";
import { NumberVariableContent } from "./variables";

export interface IVariable {
    name: string;
    generation: string;
}

export class Variable<T extends BaseVariableContent = BaseVariableContent> implements IVariable {
    readonly id: number;
    @observable.ref generation: VariableGenerationTypes;
    @observable.ref name: string;
    @observable.ref content: T;

    constructor(name: string, generation: VariableGenerationTypes, content: T) {
        this.id = generateId();

        this.name = name;
        this.generation = generation;
        this.content = content;

        makeObservable(this);
    }

    static createEmpty(): Variable {
        return new Variable<NumberVariableContent>(
            "",
            VariableGenerationTypes.number,
            NumberVariableContent.createEmpty()
        );
    }

    clone(): Variable {
        return new Variable(this.name, this.generation, this.content.clone());
    }
}
