import type { BaseFormNode } from "../nodes/BaseFormNode";
import type { TFormControl } from "../types/controls";

import { createCondition } from "../conditions/createCondition";
import { FormNode } from "../nodes/FormNode";
import { pathFromRaw } from "../utils/paths";

import { instantiateControl } from "./instantiateControl";

export function instantiateFormControl(data: TFormControl, parent: BaseFormNode | null): FormNode {
    const { path, controls, condition, layout } = data;

    const formInstance = new FormNode(layout, createCondition(condition), parent, pathFromRaw(path ?? "."));

    for (const controlDescriptor of controls) {
        const controlInstance = instantiateControl(formInstance, controlDescriptor);

        formInstance.addControl(controlInstance);
    }

    return formInstance;
}
