import type { BaseElementModel } from "../core/element";
import type { TAnySerializedElement } from "../types";

import { BaseCommand } from "../core/commands";

import { CommandInitError } from "./errors/CommandInitError";

export class ClipboardPasteCommand extends BaseCommand {
    private readonly serializedFilteredElementsToPaste: TAnySerializedElement[];

    public constructor(model: BaseElementModel) {
        super(model);

        const serializedPrototypes = this.clipboard.getBuffer();
        if (!serializedPrototypes) {
            throw new CommandInitError(false, "Clipboard is empty");
        }

        const container = this.selectionSnapshot ? this.selection.commonContainer! : this.cursor.container;

        const indexToInsert = this.selectionSnapshot ? this.selectionSnapshot.firstElementIndex : this.cursorPosition;

        const tempInstances = this.limits.filterElementsCanBeInserted(
            serializedPrototypes
                .map((data) => this.deserializeElement(data))
                .filter((element) => model.behaviour.checkCanBeInserted(element, container)),
            this.cursor.container,
            indexToInsert
        );

        this.serializedFilteredElementsToPaste = tempInstances.map((instance) => instance.serialize());

        if (this.serializedFilteredElementsToPaste.length === 0) {
            throw new CommandInitError(false, "All elements was filtered");
        }
    }

    apply(): void {
        const elements = this.serializedFilteredElementsToPaste.map((data) => this.deserializeElement(data));

        const length = this.serializedFilteredElementsToPaste.length;

        this.restoreCursor();
        if (this.selectionSnapshot) {
            const container = this.getSelectionContainer();
            const startIndex = this.selectionSnapshot.firstElementIndex;
            this.removeSelectedElements();
            container.insertElementsRange(elements, startIndex);
            this.cursor.moveToPosition(container, startIndex + length);
        } else {
            const container = this.getCursorContainer();
            const startIndex = this.cursorPosition;
            container.insertElementsRange(elements, startIndex);
            this.cursor.moveToPosition(container, startIndex + length);
        }
    }

    rollback(): void {
        const length = this.serializedFilteredElementsToPaste.length;

        if (this.selectionSnapshot) {
            const container = this.getSelectionContainer();
            const startIndex = this.selectionSnapshot.firstElementIndex;

            container.removeElementsRange(startIndex, length);
            this.restoreRemovedSelectedElements();
        } else {
            const container = this.getCursorContainer();
            container.removeElementsRange(this.cursorPosition, length);
        }
        this.restoreCursor();
    }
}
