import cn from "classnames";
import { useEffect, useRef } from "react";

import type { TBaseFractionElementProps } from "./BaseFractionElement.types";
import type { VFC } from "react";

import { Baseline } from "../../../core/element";
import { useMathEditorElementRef } from "../../../hooks";

import { platformDependentFractionOffset } from "./BaseFractionElement.constants";

import styles from "./BaseFractionElement.module.scss";

export const BaseFractionElement: VFC<TBaseFractionElementProps> = ({ denominator, numerator, elementModel }) => {
    const wrapperRef = useMathEditorElementRef<HTMLSpanElement>(elementModel);
    const baselineRef = useRef<HTMLSpanElement>(null);
    const lineRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const observableElement = wrapperRef.current!;
        const baselineElement = baselineRef.current!;
        const middleElement = lineRef.current!;

        const observer = new ResizeObserver(() => {
            const baselineHeightHalf = baselineElement.scrollHeight / 2;
            const centerRect = middleElement.getBoundingClientRect();
            const centerHeightDoubled = centerRect.height * 2;
            const centerBottom = centerRect.bottom;
            const observableBottom = observableElement.getBoundingClientRect().bottom;

            const distance = observableBottom - centerBottom + centerHeightDoubled - baselineHeightHalf;

            baselineElement.style.marginBottom = `${distance + platformDependentFractionOffset}px`;
        });

        observer.observe(observableElement);

        return () => {
            observer.disconnect();
        };
    }, [wrapperRef]);

    return (
        <span
            ref={wrapperRef}
            className={styles.wrapper}
        >
            <Baseline ref={baselineRef} />

            <div className={styles.fraction}>
                <span className={cn(styles.container, styles.numerator)}>{numerator}</span>

                <div
                    ref={lineRef}
                    className={styles.line}
                />

                <span className={cn(styles.container, styles.denominator)}>{denominator}</span>
            </div>
        </span>
    );
};
