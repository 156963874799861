import { computed, makeObservable, observable } from "mobx";
import { createTransformer } from "mobx-utils";

import { degToRad, getUnitVectorFromAngle } from "@viuch/geometry-lib/angles";
import { areSamePoints } from "@viuch/geometry-lib/check-geometry";
import { getFragmentAngle } from "@viuch/geometry-lib/solvers";
import { multiplyVector } from "@viuch/geometry-lib/vectors";

import type { BaseElement } from "../../elements";
import type { Figure2DController } from "../../Figure2DController";
import type { ToolbarMenu } from "../../toolbar";
import type { IUserPointer } from "../../viewport/types";
import type { TPoint } from "@viuch/geometry-lib/types";
import type React from "react";

import { DotElement } from "../../elements/dot";
import { ElementColor } from "../../elements/ElementColor";
import { LineElement } from "../../elements/line";
import { FragmentModel } from "../../models/fragment";
import { LabelPointModel } from "../../models/label-point";
import { PointModel } from "../../models/point";
import { ButtonStates } from "../../toolbar";
import { TooltipMenu } from "../../toolbar/tooltip";
import { createDefaultToolbarMenuBuilder } from "../../utils/toolbar";
import { BaseFlow } from "../BaseFlow";
import { createMapModelToElements, opacifyColor, z } from "../utils";

export class ParallelogramInteractiveFlow extends BaseFlow {
    flow:
        | {
              readonly stage: 1;
              a?: TPoint;
          }
        | {
              readonly stage: 2;
              readonly a: TPoint;
              b: TPoint;
          }
        | {
              readonly stage: 3;
              readonly a: TPoint;
              readonly b: TPoint;
              c: TPoint;
          };

    constructor(data: Figure2DController) {
        super(data);

        this.flow = { stage: 1 };

        makeObservable(this, {
            flow: observable,
            modelsElements: computed,
        });
    }

    attach(): void {
        this.viewport.disable();
        this.snap.addViewportGrid().addFigureModelsPoints();
    }

    dispose(): void {
        this.snap.clean();
    }

    mapModelsToRender = createTransformer(createMapModelToElements(opacifyColor));

    get modelsElements(): BaseElement[] {
        return this.figure.models.flatMap(this.mapModelsToRender);
    }

    protected renderElements(): BaseElement[] {
        return [...this.modelsElements, ...this.renderTempElements()];
    }

    *renderTempElements(): Iterable<BaseElement> {
        const { stage } = this.flow;

        if (stage === 1 || stage === 2 || stage === 3) {
            const { a } = this.flow;
            if (a) {
                yield new DotElement({
                    id: `temp_a`,
                    x: a.x,
                    y: a.y,
                    color: stage === 1 ? ElementColor.Selected : ElementColor.Building,
                    overrideRenderOrder: z.points.priority,
                });
            }
        }

        if (stage === 2 || stage === 3) {
            const { a, b } = this.flow;
            yield new DotElement({
                id: `temp_b`,
                x: b.x,
                y: b.y,
                color: stage === 2 ? ElementColor.Selected : ElementColor.Building,
                overrideRenderOrder: z.points.priority,
            });
            yield new LineElement({
                id: "temp_ab",
                x1: a.x,
                y1: a.y,
                x2: b.x,
                y2: b.y,
                overrideRenderOrder: z.lines.priority,
                color: stage === 2 ? ElementColor.Selected : ElementColor.Building,
            });
        }

        if (stage === 3) {
            const { a, b, c } = this.flow;
            const d = { x: c.x - (b.x - a.x), y: c.y - (b.y - a.y) };

            yield new DotElement({
                id: `temp_c`,
                x: c.x,
                y: c.y,
                color: ElementColor.Selected,
                overrideRenderOrder: z.points.priority,
            });
            yield new DotElement({
                id: `temp_d`,
                x: d.x,
                y: d.y,
                color: ElementColor.Building,
                overrideRenderOrder: z.points.priority,
            });

            yield* [
                [b, c],
                [c, d],
                [d, a],
            ].map(([first, second], i) => {
                return new LineElement({
                    id: `temp_line_${i}`,
                    x1: first.x,
                    y1: first.y,
                    x2: second.x,
                    y2: second.y,
                    overrideRenderOrder: z.lines.priority,
                    color: ElementColor.Building,
                });
            });
        }

        const renderShallow = (id: string, first: TPoint, second: TPoint) => {
            return new LineElement({
                id,
                color: ElementColor.Util,
                thin: true,
                x1: first.x,
                y1: first.y,
                x2: second.x,
                y2: second.y,
                overrideRenderOrder: 0,
            });
        };

        const { scale } = this.viewport;

        if (
            (this.flow.stage === 1 && this.flow.a) ||
            (this.flow.stage === 2 && areSamePoints(this.flow.a, this.flow.b))
        ) {
            // есть точка, надо дорисовать параллелограмм
            const a = this.flow.a!;
            const b = { x: a.x + 0.2 / scale, y: a.y };
            const c = { x: b.x + 0.04 / scale, y: b.y + 0.03 };
            const d = { x: a.x + 0.04 / scale, y: c.y };

            yield* [
                [a, b],
                [b, c],
                [c, d],
                [d, a],
            ].map(([first, second], i) => renderShallow(`shallow_${i}`, first, second));
        } else if (this.flow.stage === 2 || (this.flow.stage === 3 && areSamePoints(this.flow.b, this.flow.c))) {
            // есть одно основание параллелограмма, надо дорисовать остальное
            const { a, b } = this.flow;
            let angle = getFragmentAngle({ a, b }, true);
            const rotate = !((angle >= 0 && angle < Math.PI / 2) || angle > (3 * Math.PI) / 2);

            if (rotate) {
                angle -= degToRad(120);
            } else {
                angle += degToRad(60);
            }

            const angleUnit = multiplyVector(getUnitVectorFromAngle(angle), 0.2);
            const d = { x: a.x + angleUnit.x / scale, y: a.y + angleUnit.y / scale };
            const c = { x: b.x + angleUnit.x / scale, y: b.y + angleUnit.y / scale };

            yield* [
                [b, c],
                [c, d],
                [d, a],
            ].map(([first, second], i) => renderShallow(`shallow_${i}`, first, second));
        }
    }

    override handleViewportPointerEvent(e: React.PointerEvent, pointer: IUserPointer) {
        if (e.type === "pointercancel") return this.nextFlow();
        if (!e.isPrimary) return;

        const point = this.snap.snap(pointer.canvas, pointer.canvas);

        this.movePointer(e, point);
        this.startDrawing(e, point);
        this.endDrawing(e, point);
    }

    private movePointer(e: React.PointerEvent, point: TPoint) {
        if (e.type === "pointerdown" || e.type === "pointermove") {
            switch (this.flow.stage) {
                case 1:
                    this.flow.a = point;
                    break;
                case 2:
                    this.flow.b = point;
                    break;
                case 3: {
                    this.flow.c = point;
                    break;
                }
            }
        }
    }

    private startDrawing(e: React.PointerEvent, point: TPoint) {
        if (e.type === "pointerdown") {
            //
        }
    }

    private endDrawing(e: React.PointerEvent, point: TPoint) {
        if (e.type === "pointerup") {
            switch (this.flow.stage) {
                case 1: {
                    const a = this.flow.a;
                    if (a) {
                        this.flow = { stage: 2, a, b: a };
                    }
                    break;
                }
                case 2: {
                    const { a, b } = this.flow;
                    if (b) {
                        this.flow = { stage: 3, a, b, c: b };
                    }
                    break;
                }
                case 3: {
                    const { a, b, c } = this.flow;
                    const d = { x: c.x - (b.x - a.x), y: c.y - (b.y - a.y) };
                    this.saveRaw(a, b, c, d);
                    this.nextFlow();
                    break;
                }
            }
        }
    }

    static create(data: Figure2DController) {
        return new ParallelogramInteractiveFlow(data);
    }

    private saveRaw(a: TPoint, b: TPoint, c: TPoint, d: TPoint) {
        this.figure.insertModels(function* () {
            yield FragmentModel.create({ a, b, style: null });
            yield FragmentModel.create({ a: b, b: c, style: null });
            yield FragmentModel.create({ a: c, b: d, style: null });
            yield FragmentModel.create({ a: d, b: a, style: null });

            for (const point of [a, b, c, d]) {
                yield PointModel.create({ ...point, style: null });
                yield LabelPointModel.createNext(point, this.figure);
            }
        }, this);
    }

    override getToolbarMenu(): ToolbarMenu {
        return createDefaultToolbarMenuBuilder()
            .setButtonIcon("parallelogram", ["squares"])
            .setButtonState(ButtonStates.active, ["squares"])
            .build();
    }

    override getTooltipMenu(): TooltipMenu | null {
        let text: string;
        switch (this.flow.stage) {
            case 1:
                text = "Выберите первую вершину параллелограмма";
                break;
            case 2:
                text = "Выберите вторую вершину параллелограмма";
                break;
            case 3:
                text = "Выберите третью вершину параллелограмма";
                break;
        }

        return new TooltipMenu(text);
    }
}
