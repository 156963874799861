import cn from "classnames";
import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect, useRef } from "react";

import { createAnimationFrame } from "@viuch/shared/utils/dom/animationFrame";

import type { ContainerModel } from "./ContainerModel";
import type { BaseElementModel } from "../element";
import type { IReactionDisposer } from "mobx";
import type { Ref } from "react";

import { CursorElement } from "../../components/cursor";
import { useMathEditorHandlers } from "../../components/editor/mathEditorHandlersContext";
import { Placeholder } from "../../components/placeholder";
import { Baseline } from "../element";

import { ElementRenderer } from "./ElementRenderer";

import styles from "./ContainerElement.module.scss";

type Props = {
    containerModel: ContainerModel;
    className?: string;
    inline?: boolean;
    baselineRef?: Ref<HTMLElement>;
};

export const ContainerElement = observer(function ContainerElement(props: Props) {
    const { containerModel, className, inline, baselineRef } = props;

    const containerRef = useRef<HTMLDivElement | null>(null);

    const { inputService } = containerModel;
    const { isDisabled } = inputService;
    const { selectionController, cursorState, placeholders, placeholdersVisible, highlightingService } = inputService;
    const handlers = useMathEditorHandlers();

    useEffect(() => {
        const element = containerRef.current!;

        containerModel.setDomElement(element);
        selectionController.registerContainer(containerModel);

        return () => {
            selectionController.freeContainer(containerModel);
            containerModel.setDomElement(undefined);
        };
    }, [containerModel, selectionController]);

    const applySelection = selectionController.isContainerSelection(containerModel);
    const highlightedElementUuidsWithStyle = { ...highlightingService.highlightedElementUuidsWithStyle };

    const handleElementClick = useCallback(
        (element: BaseElementModel) => {
            const highlight = highlightingService.tryGetHighlight(element);
            if (highlight) {
                handlers.onHighlightClick(highlight);
            }
        },
        [handlers, highlightingService]
    );

    const crossOutHighlightRanges = highlightingService.highlights.allCrossOut(containerModel.uuid);

    useEffect(() => {
        if (inputService.isReadOnly || crossOutHighlightRanges.length === 0) return;
        const disposers: Array<VoidFunction | IReactionDisposer> = [];

        const dispose = createAnimationFrame({
            onFrame: () => {
                crossOutHighlightRanges.forEach((crossOutHighlightRange) => {
                    disposers.push(crossOutHighlightRange.effect());
                });
            },
        });

        disposers.push(dispose);

        return () => disposers.forEach((dispose) => dispose());
    }, [crossOutHighlightRanges, inputService.isReadOnly]);

    return (
        <div
            ref={containerRef}
            className={cn(styles.container, inline && styles.inlineWrapper, className)}
        >
            <Baseline ref={baselineRef} />

            {containerModel.elementsWithThings.map((thing) =>
                thing.type === "cursor" ? (
                    <CursorElement
                        cursorState={cursorState}
                        key="cursor"
                    />
                ) : thing.type === "element" ? (
                    <span
                        key={thing.element.uuid}
                        style={{ display: thing.element.getRenderType() }}
                        className={cn(
                            styles.element,
                            applySelection && selectionController.isElementSelected(thing.element) && styles.selected,
                            styles[highlightedElementUuidsWithStyle[thing.element.uuid]]
                        )}
                        onClick={() => handleElementClick(thing.element)}
                    >
                        <ElementRenderer elementModel={thing.element} />
                    </span>
                ) : thing.type === "placeholder" && placeholdersVisible ? (
                    <Placeholder
                        key={thing.key}
                        placeholders={placeholders}
                    />
                ) : null
            )}

            {crossOutHighlightRanges.length > 0 && (
                <svg
                    className={styles.crossOut}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    {crossOutHighlightRanges.map((crossOutRange, crossOutRangeIndex) => {
                        if (!crossOutRange.crossOutCoords) return;

                        const { left, right, bottom, top } = crossOutRange.crossOutCoords;

                        return (
                            <line
                                key={crossOutRangeIndex}
                                className={styles.crossOut__line}
                                x1={left}
                                x2={right}
                                y1={bottom}
                                y2={top}
                            />
                        );
                    })}
                </svg>
            )}
        </div>
    );
});
