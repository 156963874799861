import type { InputService } from "../services";

import { AffiliationElement } from "../elements/affiliation/AffiliationElement";
import { AffiliationModel } from "../elements/affiliation/AffiliationModel";
import { AngleElement } from "../elements/angle/AngleElement";
import { AngleModel } from "../elements/angle/AngleModel";
import { BracketElement } from "../elements/bracket/BracketElement";
import { BracketModel } from "../elements/bracket/BracketModel";
import { CharElement } from "../elements/char/CharElement";
import { CharModel } from "../elements/char/CharModel";
import { ComplexIntegralElement } from "../elements/complex-integral/ComplexIntegralElement";
import { ComplexIntegralModel } from "../elements/complex-integral/ComplexIntegralModel";
import { CoordinatesVectorElement } from "../elements/coordinates-vector/CoordinatesVectorElement";
import { CoordinatesVectorModel } from "../elements/coordinates-vector/CoordinatesVectorModel";
import { DegreeElement } from "../elements/degree/DegreeElement";
import { DegreeModel } from "../elements/degree/DegreeModel";
import { DerivativeElement } from "../elements/derivative/DerivativeElement";
import { DerivativeModel } from "../elements/derivative/DerivativeModel";
import { DifferentialElement } from "../elements/differential/DifferentialElement";
import { DifferentialModel } from "../elements/differential/DifferentialModel";
import { DigitElement } from "../elements/digit/DigitElement";
import { DigitModel } from "../elements/digit/DigitModel";
import { DownIndexElement } from "../elements/down-index/DownIndexElement";
import { DownIndexModel } from "../elements/down-index/DownIndexModel";
import { EditorModel } from "../elements/editor/EditorModel";
import { EqualityElement } from "../elements/equality/EqualityElement";
import { EqualityModel } from "../elements/equality/EqualityModel";
import { EquationsSetElement } from "../elements/equations-set/EquationsSetElement";
import { EquationsSetModel } from "../elements/equations-set/EquationsSetModel";
import { FractionElement } from "../elements/fraction/FractionElement";
import { FractionModel } from "../elements/fraction/FractionModel";
import { FunctionElement } from "../elements/function/FunctionElement";
import { FunctionModel } from "../elements/function/FunctionModel";
import { GeometryElement } from "../elements/geometry/GeometryElement";
import { GeometryModel } from "../elements/geometry/GeometryModel";
import { GradeElement } from "../elements/grade/GradeElement";
import { GradeModel } from "../elements/grade/GradeModel";
import { IntegralElement } from "../elements/integral/IntegralElement";
import { IntegralModel } from "../elements/integral/IntegralModel";
import { LimElement } from "../elements/lim/LimElement";
import { LimModel } from "../elements/lim/LimModel";
import { LinebreakElement } from "../elements/linebreak/LinebreakElement";
import { LinebreakModel } from "../elements/linebreak/LinebreakModel";
import { LogElement } from "../elements/log/LogElement";
import { LogModel } from "../elements/log/LogModel";
import { ModuleElement } from "../elements/module/ModuleElement";
import { ModuleModel } from "../elements/module/ModuleModel";
import { MultitudeElement } from "../elements/multitude/MultitudeElement";
import { MultitudeModel } from "../elements/multitude/MultitudeModel";
import { NaturalLogElement } from "../elements/natural-log/NaturalLogElement";
import { NaturalLogModel } from "../elements/natural-log/NaturalLogModel";
import { HighPriorityOperatorElement } from "../elements/operator-high/HighPriorityOperatorElement";
import { HighPriorityOperatorModel } from "../elements/operator-high/HighPriorityOperatorModel";
import { LowPriorityOperatorElement } from "../elements/operator-low/LowPriorityOperatorElement";
import { LowPriorityOperatorModel } from "../elements/operator-low/LowPriorityOperatorModel";
import { ResultElement } from "../elements/result/ResultElement";
import { ResultModel } from "../elements/result/ResultModel";
import { RootElement } from "../elements/root/RootElement";
import { RootModel } from "../elements/root/RootModel";
import { SlashElement } from "../elements/slash/SlashElement";
import { SlashModel } from "../elements/slash/SlashModel";
import { SpaceElement } from "../elements/space/SpaceElement";
import { SpaceModel } from "../elements/space/SpaceModel";
import { SpecialSymbolElement } from "../elements/symbol/SpecialSymbolElement";
import { SpecialSymbolModel } from "../elements/symbol/SpecialSymbolModel";
import { TopAngleElement } from "../elements/top-angle/TopAngleElement";
import { TopAngleModel } from "../elements/top-angle/TopAngleModel";
import { TrigonometricOperatorElement } from "../elements/trigonometric-operator/TrigonometricOperatorElement";
import { TrigonometricOperatorModel } from "../elements/trigonometric-operator/TrigonometricOperatorModel";
import { VectorElement } from "../elements/vector/VectorElement";
import { VectorModel } from "../elements/vector/VectorModel";

export function configureInput(inputService: InputService): InputService {
    inputService
        .registerElement(EditorModel, null!, "input")
        .registerElement(CharModel, CharElement, "char")
        .registerElement(DigitModel, DigitElement, "digit")
        .registerElement(DifferentialModel, DifferentialElement, "differential")
        .registerElement(FunctionModel, FunctionElement, "function")
        .registerElement(LimModel, LimElement, "lim")
        .registerElement(NaturalLogModel, NaturalLogElement, "natural-log")
        .registerElement(TopAngleModel, TopAngleElement, "top-angle")
        .registerElement(IntegralModel, IntegralElement, "integral")
        .registerElement(ComplexIntegralModel, ComplexIntegralElement, "complex-integral")
        .registerElement(TrigonometricOperatorModel, TrigonometricOperatorElement, "trigonometric-operator")
        .registerElement(LogModel, LogElement, "log")
        .registerElement(DerivativeModel, DerivativeElement, "derivative")
        .registerElement(EquationsSetModel, EquationsSetElement, "equations-set")
        .registerElement(BracketModel, BracketElement, "bracket")
        .registerElement(AffiliationModel, AffiliationElement, "affiliation")
        .registerElement(AngleModel, AngleElement, "angle")
        .registerElement(EqualityModel, EqualityElement, "equality")
        .registerElement(GeometryModel, GeometryElement, "geometry")
        .registerElement(SpecialSymbolModel, SpecialSymbolElement, "symbol")
        .registerElement(HighPriorityOperatorModel, HighPriorityOperatorElement, "operator-high")
        .registerElement(SpaceModel, SpaceElement, "space")
        .registerElement(LowPriorityOperatorModel, LowPriorityOperatorElement, "operator-low")
        .registerElement(MultitudeModel, MultitudeElement, "multitude")
        .registerElement(LinebreakModel, LinebreakElement, "linebreak")
        .registerElement(FractionModel, FractionElement, "fraction")
        .registerElement(RootModel, RootElement, "root")
        .registerElement(DegreeModel, DegreeElement, "degree")
        .registerElement(ModuleModel, ModuleElement, "module")
        .registerElement(GradeModel, GradeElement, "grade")
        .registerElement(DownIndexModel, DownIndexElement, "down-index")
        .registerElement(ResultModel, ResultElement, "result")
        .registerElement(VectorModel, VectorElement, "vector")
        .registerElement(SlashModel, SlashElement, "slash")
        .registerElement(CoordinatesVectorModel, CoordinatesVectorElement, "coordinates-vector");

    return inputService;
}
