import React from "react";

import type { BaseElementModel } from "../core/element";

export function useMathEditorElementRef<E extends HTMLElement = HTMLElement>(
    elementModel: BaseElementModel
): React.RefObject<E> {
    const ref = React.useRef<E>(null);

    React.useEffect(() => {
        const node = ref.current!;

        const observer = new ResizeObserver(() => {
            const { x, y, width: w, height: h }: DOMRectReadOnly = node.getBoundingClientRect();

            const isZero = (x && y && w && h) === 0;

            if (!isZero) {
                elementModel.notifySizeChanged({ w, h });
            } else {
                elementModel.notifySizeChanged(undefined);
            }
        });

        observer.observe(node);
        elementModel.setDomElement(node);

        return () => {
            observer.disconnect();
        };
    }, [elementModel]);

    return ref;
}
