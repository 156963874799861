import cn from "classnames";
import { useCallback, useRef, useState } from "react";

import type { TDesktopKeyboardProps, TKeyboardTab } from "./DesktopKeyboard.types";
import type { TAnyAction } from "../../../types";
import type { VFC } from "react";

import { AlphabetBlock, BaseKeyboardBlock } from "./blocks";
import { TabsBlock } from "./blocks/TabsBlock";
import {
    bracketsMathChars,
    differentialsMathChars,
    digitsMathChars,
    equalsMathChars,
    fractionsMathChars,
    geometryMathChars,
    sinsMathChars,
    specialSymbolsMathChars,
} from "./DesktopKeyboard.constants";

import styles from "./DesktopKeyboard.module.scss";

const DesktopKeyboard: VFC<TDesktopKeyboardProps> = ({ keyboardService, className }) => {
    const [selectedTab, setSelectedTab] = useState<TKeyboardTab>("en");
    const [isShiftPressed, setIsShiftPressed] = useState(false);
    const [isCapsActive, setIsCapsActive] = useState(false);
    const keyboardRef = useRef<HTMLDivElement>(null);

    const handleShiftDoubleClick = useCallback(() => {
        setIsCapsActive(true);
        setIsShiftPressed(true);
    }, []);

    const handleClick = useCallback(
        (action: TAnyAction) => {
            keyboardService.dispatchAction(action);
        },
        [keyboardService]
    );

    const handleAlphabetClick = useCallback(
        (action: TAnyAction) => {
            handleClick(action);

            if (action.type === "char" && !isCapsActive) {
                setIsShiftPressed(false);
            }

            if (action.type === "shift") {
                setIsShiftPressed((prev) => (isCapsActive ? false : !prev));
                setIsCapsActive(false);
            }
        },
        [isCapsActive, handleClick]
    );

    const handleTabClick = useCallback((tab: TKeyboardTab) => {
        setSelectedTab(tab);
    }, []);

    return (
        <div className={cn(styles.wrapper, className)}>
            <div
                ref={keyboardRef}
                className={styles.keyboard}
                onPointerDown={(e) => e.preventDefault()}
            >
                <TabsBlock
                    selectedTab={selectedTab}
                    onClick={handleTabClick}
                    isShiftPressed={isShiftPressed}
                    keyboardService={keyboardService}
                />

                <AlphabetBlock
                    onShiftDoubleClick={handleShiftDoubleClick}
                    onClick={handleAlphabetClick}
                    selectedTab={selectedTab}
                    isShiftPressed={isShiftPressed}
                    isCapsPressed={isCapsActive}
                    keyboardService={keyboardService}
                />

                <BaseKeyboardBlock
                    columns={4}
                    mathChars={digitsMathChars}
                    onClick={handleClick}
                    keyboardService={keyboardService}
                />

                {/* <BaseKeyboardBlock
                    columns={1}
                    mathChars={columnCalculationsMathChars}
                    onClick={handleClick}
                    iconClassName={styles.columnCalculationsButtonIcon}
                    keyboardService={keyboardService}
                /> */}

                <BaseKeyboardBlock
                    columns={2}
                    mathChars={bracketsMathChars}
                    onClick={handleClick}
                    keyboardService={keyboardService}
                />

                <BaseKeyboardBlock
                    columns={1}
                    mathChars={equalsMathChars}
                    onClick={handleClick}
                    keyboardService={keyboardService}
                />

                <BaseKeyboardBlock
                    columns={2}
                    mathChars={fractionsMathChars}
                    onClick={handleClick}
                    keyboardService={keyboardService}
                />

                <BaseKeyboardBlock
                    columns={3}
                    mathChars={differentialsMathChars}
                    onClick={handleClick}
                    textIconSize="small"
                    keyboardService={keyboardService}
                />

                <BaseKeyboardBlock
                    columns={2}
                    mathChars={sinsMathChars}
                    onClick={handleClick}
                    textIconSize="small"
                    keyboardService={keyboardService}
                />

                <BaseKeyboardBlock
                    columns={3}
                    mathChars={geometryMathChars}
                    onClick={handleClick}
                    keyboardService={keyboardService}
                />

                {/*
                <BaseKeyboardBlock
                    columns={2}
                    mathChars={geometrySymbols}
                    onClick={handleClick}
                    keyboardService={keyboardService}
                />
                */}

                <BaseKeyboardBlock
                    columns={4}
                    mathChars={specialSymbolsMathChars}
                    onClick={handleClick}
                    keyboardService={keyboardService}
                />
            </div>
        </div>
    );
};

export default DesktopKeyboard;
