import { action, makeObservable, observable } from "mobx";

import type { TSerializedState } from "@viuch/math-editor";

export class Graph2DInstrumentViewportSettings {
    @observable.ref zoomMax: TSerializedState;
    @observable.ref zoomInitial: TSerializedState;

    @observable.ref initialPositionX: TSerializedState;
    @observable.ref initialPositionY: TSerializedState;

    constructor(
        zoomMax: TSerializedState,
        zoomInitial: TSerializedState,
        initialPositionX: TSerializedState,
        initialPositionY: TSerializedState
    ) {
        this.zoomMax = zoomMax;
        this.zoomInitial = zoomInitial;
        this.initialPositionX = initialPositionX;
        this.initialPositionY = initialPositionY;

        makeObservable(this);
    }

    @action.bound
    setZoomMax(value: TSerializedState) {
        this.zoomMax = value;
    }

    @action.bound
    setZoomInitial(value: TSerializedState) {
        this.zoomInitial = value;
    }

    @action.bound
    setInitialPositionX(value: TSerializedState) {
        this.initialPositionX = value;
    }

    @action.bound
    setInitialPositionY(value: TSerializedState) {
        this.initialPositionY = value;
    }

    clone() {
        const { zoomMax, zoomInitial, initialPositionX, initialPositionY } = this;
        return new Graph2DInstrumentViewportSettings(zoomMax, zoomInitial, initialPositionX, initialPositionY);
    }
}
