import { observer } from "mobx-react-lite";
import React from "react";

import type { TooltipMenu } from "./TooltipMenu";
import type { ToolbarButton } from "../ToolbarButton";
import type { ToolbarController } from "../ToolbarController";
import type { ToolbarMenu } from "../ToolbarMenu";

import { ToolbarIcon } from "../icons";

import styles from "./Tooltip.module.scss";

type Props = {
    toolbar: ToolbarController;
};

export const Tooltip = observer(function Tooltip({ toolbar }: Props) {
    const { tooltip } = toolbar;

    if (!tooltip) return null;

    return (
        <TooltipContainer
            tooltip={tooltip}
            onClick={toolbar.dispatchButtonClick}
        ></TooltipContainer>
    );
});

type ContainerProps = React.PropsWithChildren<{
    tooltip: TooltipMenu;
    onClick(menu: ToolbarMenu, button: ToolbarButton): void;
}>;

export const TooltipContainer = observer(function TooltipContainer({ tooltip: tooltipMenu, onClick }: ContainerProps) {
    const { tooltip, buttons } = tooltipMenu;

    function handleClick(button: ToolbarButton) {
        onClick(tooltipMenu, button);
    }

    return (
        <div className={styles.tooltipWrapper}>
            <div className={styles.tooltip}>
                {buttons.map((button) => (
                    <button
                        type="button"
                        key={button.key}
                        className={styles.tooltip__button}
                        onClick={() => handleClick(button)}
                    >
                        <ToolbarIcon
                            icon={button.icon}
                            className={styles.tooltip__button__icon}
                        />
                    </button>
                ))}
                {tooltip && (
                    <div
                        key="tooltip"
                        className={styles.tooltip__label}
                    >
                        {tooltip}
                    </div>
                )}
            </div>
        </div>
    );
});
