import cn from "classnames";
import { observer } from "mobx-react-lite";
import React from "react";

import { mergeRefs } from "@viuch/shared/utils/react";

import type { PropsWithChildren, Ref } from "react";

import styles from "./NavigationFrame.module.scss";

type Props = PropsWithChildren<{
    className?: string;
    wrapperRef?: Ref<HTMLElement>;
    tabIndex?: number;
}>;

export const NavigationFrame = observer(function NavigationFrame({ className, children, wrapperRef, tabIndex }: Props) {
    return (
        <div
            className={cn(styles.frame1, className)}
            ref={mergeRefs(wrapperRef)}
            tabIndex={tabIndex}
        >
            <div className={styles.frame2}>{children}</div>
        </div>
    );
});
