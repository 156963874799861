import type { ILimit } from "./ILimit";
import type { ContainerModel } from "../container";
import type { ILinebreakFinder } from "../cursor/LinebreakFinder";
import type { BaseElementModel } from "../element";

import { LinebreakFinder } from "../cursor/LinebreakFinder";

export class LinebreaksCountLimit implements ILimit {
    private readonly maxLinebreaksCount: number;
    private finder: ILinebreakFinder;

    constructor(count: number) {
        this.maxLinebreaksCount = count;
        this.finder = LinebreakFinder.Create();
    }

    filterInsertElements(
        elements: BaseElementModel[],
        containerToInsert: ContainerModel,
        indexToInsert: number
    ): BaseElementModel[] {
        const { inputService } = containerToInsert;
        if (inputService.model.rootContainer !== containerToInsert) {
            return elements.slice();
        }

        let linebreaksCount = 0;

        for (const element of containerToInsert.elements) {
            if (this.finder.isLinebreak(element)) {
                linebreaksCount++;

                if (linebreaksCount >= this.maxLinebreaksCount) {
                    break;
                }
            }
        }

        return elements.filter((element) => {
            if (this.finder.isLinebreak(element)) {
                linebreaksCount++;

                if (linebreaksCount >= this.maxLinebreaksCount) {
                    return false;
                }
            }

            return true;
        });
    }
}
