import cn from "classnames";
import { observer } from "mobx-react-lite";
import { createTransformer } from "mobx-utils";
import React, { useMemo } from "react";

import { generateUuid } from "@viuch/shared/utils/data";

import type { KeyboardService, TSerializedState } from "@viuch/math-editor";
import type { ReactNode } from "react";

import { BaseInput } from "./BaseInput";
import { MathInput2 } from "./MathInput2";

import styles from "./MathArrayInput.module.scss";

type Props = {
    values: TSerializedState[];
    onChange(index: number, value: TSerializedState): void;
    onAddValue(): void;
    onRemoveValue(index: number): void;
    keyboardService?: KeyboardService;
    title?: string;
    error?: string;
    inline?: boolean;
    className?: string;
    disabled?: boolean;
    renderExtra?(index: number): ReactNode;
};

export const MathArrayInput = observer(function MathArrayInput({
    className,
    title,
    inline,
    error,
    keyboardService,
    values,
    onChange,
    onAddValue,
    disabled,
    onRemoveValue,
    renderExtra,
}: Props) {
    const getKey = useMemo(() => createTransformer<TSerializedState, string>(() => generateUuid()), []);

    return (
        <BaseInput
            title={title}
            className={className}
            error={error}
            inline={inline}
        >
            <div className={styles.list}>
                {values.map((value, i) => (
                    <div
                        key={getKey(value)}
                        className={styles.listItem}
                    >
                        <button
                            className={cn(styles.button, styles.remove, styles.listItem__button)}
                            onClick={() => onRemoveValue(i)}
                            disabled={disabled}
                        />
                        <MathInput2
                            value={value}
                            keyboardService={keyboardService}
                            className={styles.listItem__math}
                            onChange={(newValue) => onChange(i, newValue)}
                            disabled={disabled}
                        />
                        {renderExtra?.(i)}
                    </div>
                ))}
                <button
                    className={cn(styles.button, styles.plus)}
                    onClick={() => onAddValue()}
                    disabled={disabled}
                />
            </div>
        </BaseInput>
    );
});
