import { makeObservable, observable } from "mobx";

import type { InputService } from "../../../services";
import type { THighlightStyle } from "../../../types";
import type { ContainersStore } from "../../container";
import type { ElementsStore } from "../../element/ElementsStore";

export class HighlightRange {
    @observable elementUuids: readonly string[];
    @observable containerUuid: string;
    @observable length: number;

    readonly payload: Record<string, unknown>;
    readonly style: THighlightStyle;

    private readonly inputService: InputService;
    protected readonly containersService: ContainersStore;
    protected readonly elementsService: ElementsStore;

    constructor(
        payload: Record<string, unknown>,
        style: THighlightStyle,
        containerUuid: string,
        elementUuids: string[],
        inputService: InputService
    ) {
        this.inputService = inputService;
        this.elementsService = inputService.elements;
        this.containersService = inputService.containers;
        this.containerUuid = containerUuid;
        this.elementUuids = elementUuids;
        this.length = elementUuids.length;
        this.payload = payload;
        this.style = style;

        makeObservable(this);
    }

    effect(): VoidFunction {
        return () => {
            //
        };
    }

    checkIsPresent(): boolean {
        const container = this.containersService.tryGetById(this.containerUuid);
        if (!container) return false;

        let lastIndex: number | undefined;
        for (const uuid of this.elementUuids) {
            const element = this.elementsService.tryGetById(uuid);

            if (!element) {
                return false;
            }

            if (element.parentContainer !== container) {
                return false;
            }

            const index = element.computeIndex();
            if (index === -1) {
                return false;
            }

            if (lastIndex !== undefined) {
                if (index !== lastIndex + 1) {
                    return false;
                }
            }
            lastIndex = index;
        }

        return true;
    }

    remove() {
        this.inputService.highlightingService.highlights.remove(this);
    }
}
