import { generateUuid } from "@viuch/shared/utils/data";

import type { ISerializedVector } from "./types";

import { createSerializedContainer } from "../../utils/serialization";

export function createSerializedVector(): ISerializedVector {
    return {
        type: "vector",
        uuid: generateUuid(),
        content: createSerializedContainer(),
    };
}
