import { action, makeObservable } from "mobx";

import type { ButtonStates } from "../ButtonStates";
import type { TToolbarIcons } from "../icons";
import type { IToolbarButtonParams, ToolbarButton } from "../ToolbarButton";
import type { ToolbarMenu } from "../ToolbarMenu";

import { instantiateButton, instantiateMenu } from "./utils";

export class ToolbarMenuBuilder {
    readonly menu: ToolbarMenu;

    constructor(key: string) {
        this.menu = instantiateMenu(key, []);

        makeObservable(this, {
            setButtonState: action.bound,
            addButtons: action.bound,
            setButtonStateAll: action.bound,
            setButtonIcon: action.bound,
        });
    }

    private findButton(keys: string[]): ToolbarButton | null {
        let button: ToolbarButton | undefined;
        let menu: ToolbarMenu | undefined = this.menu;
        for (const key of keys) {
            if (!menu) return null;

            button = menu.buttons.find((button) => button.key === key);
            if (!button) return null;

            menu = button.nestedMenu;
        }

        return button ?? null;
    }

    setButtonState(state: ButtonStates, keys: string[]) {
        const button = this.findButton(keys);

        if (button) {
            button.state = state;
        }
        return this;
    }

    build(): ToolbarMenu {
        return this.menu;
    }

    addButtons(buttons: Iterable<IToolbarButtonParams>) {
        this.menu.buttons.push(...[...buttons].map(instantiateButton));
        return this;
    }

    setButtonStateAll(status: ButtonStates) {
        this.menu.buttons.forEach((button) => {
            if (button.nestedMenu) {
                button.nestedMenu.buttons.forEach((button) => {
                    button.state = status;
                });
            }
            button.state = status;
        });
        return this;
    }

    setButtonIcon(icon: TToolbarIcons, keys: string[]) {
        const button = this.findButton(keys);
        if (button) {
            button.icon = icon;
        }
        return this;
    }

    use(middleware: (builder: this) => this): this {
        middleware(this);
        return this;
    }
}
