import { action, computed, makeObservable, observable } from "mobx";

import { copyFragment, copyPoint, createFragment } from "@viuch/geometry-lib/factories";
import { middlePoint } from "@viuch/geometry-lib/vectors";
import { generateId } from "@viuch/shared/utils/data";

import type { TModelStyle } from "../modelStyle";
import type { TFragment, TPoint } from "@viuch/geometry-lib/types";

import { BaseModel } from "../BaseModel";

export interface IMedianModel {
    fragment: TFragment;
    vertex: TPoint;
    segmentsCount: number;
    style: TModelStyle | null;
}

export class MedianModel extends BaseModel implements IMedianModel {
    fragment: TFragment;
    vertex: TPoint;
    segmentsCount: number;
    style: TModelStyle | null;

    constructor(data: IMedianModel, id: number) {
        super(id);
        this.fragment = copyFragment(data.fragment);
        this.vertex = copyPoint(data.vertex);
        this.segmentsCount = data.segmentsCount;
        this.style = data.style;

        makeObservable(this, {
            fragment: observable,
            vertex: observable,
            segmentsCount: observable,
            midPoint: computed,
            medianFragment: computed,
            setSegmentsCount: action,
            style: observable,
        });
    }

    get midPoint(): TPoint {
        return middlePoint(this.fragment.a, this.fragment.b);
    }

    get medianFragment(): TFragment {
        return createFragment(this.vertex, this.midPoint);
    }

    toMedianFragment(): TFragment {
        return createFragment(this.vertex, this.midPoint);
    }

    accept<R>(visitor: IMedianModelVisitor<R>): R {
        return visitor.withMedian(this);
    }

    static create(data: IMedianModel) {
        return new MedianModel(data, generateId());
    }

    setSegmentsCount(count: number) {
        this.segmentsCount = count;
    }
}

export interface IMedianModelVisitor<R> {
    withMedian: (median: MedianModel) => R;
}
