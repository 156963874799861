import { makeObservable, observable } from "mobx";

import { copyFragment, copyPoint } from "@viuch/geometry-lib/factories";
import { generateId } from "@viuch/shared/utils/data";

import type { TModelStyle } from "../modelStyle";
import type { TFragment, TPoint } from "@viuch/geometry-lib/types";

import { BaseModel } from "../BaseModel";

export interface IFragmentModel {
    a: TPoint;
    b: TPoint;
    style: TModelStyle | null;
}

export interface IFragmentModelVisitor<R> {
    withFragment: (fragment: FragmentModel) => R;
}

export class FragmentModel extends BaseModel implements IFragmentModel {
    a: TPoint;
    b: TPoint;
    style: TModelStyle | null;

    protected constructor(data: IFragmentModel, id: number) {
        super(id);
        this.a = copyPoint(data.a);
        this.b = copyPoint(data.b);
        this.style = data.style;

        makeObservable(this, {
            a: observable,
            b: observable,
            style: observable,
        });
    }

    static create(data: IFragmentModel) {
        return new FragmentModel(data, generateId());
    }

    accept<R>(visitor: IFragmentModelVisitor<R>): R {
        return visitor.withFragment(this);
    }

    toFragment(): TFragment {
        return copyFragment(this);
    }
}
