import type { ISerializedGeometry, TGeometryChar } from "./types";
import type { TSerializedElementPrototype } from "../../core/element";
import type { IElementVisitor } from "../../core/element/IElementVisitor";
import type { InputService } from "../../services";
import type { TElementDeserializerFunction } from "../../types";

import { BaseElementModel } from "../../core/element";

export class GeometryModel extends BaseElementModel<ISerializedGeometry> {
    public readonly symbolName: TGeometryChar;

    public constructor(inputService: InputService, symbolName: TGeometryChar, uuid?: string) {
        super(inputService, uuid);

        this.symbolName = symbolName;
    }

    override isEquals(otherElement: BaseElementModel): boolean {
        return otherElement instanceof GeometryModel && this.symbolName === otherElement.symbolName;
    }

    public serialize(): ISerializedGeometry {
        return { type: "geometry", uuid: this.uuid, symbol: this.symbolName };
    }

    public static deserialize: TElementDeserializerFunction<ISerializedGeometry> = (
        { inputService },
        { uuid, symbol }
    ) => new GeometryModel(inputService, symbol, uuid);

    public accept<R>(visitor: IElementVisitor<R>): R {
        return visitor.doWithGeometry(this);
    }

    serializeAsClone(): TSerializedElementPrototype<ISerializedGeometry> {
        const { uuid, ...clone } = this.serialize();
        return clone;
    }
}
