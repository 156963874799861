import type { BaseFormNode } from "../nodes/BaseFormNode";
import type { TNumericControl } from "../types/controls";

import { NumberFormNode } from "../nodes/NumberFormNode";
import { pathFromRaw } from "../utils/paths";

export function instantiateNumberControl(data: TNumericControl, parent: BaseFormNode | null) {
    const { label, max, min, step, decimalDigits, path } = data;

    return new NumberFormNode(label, min, max, step, decimalDigits, parent, pathFromRaw(path), []);
}
