import { action, makeObservable, observable } from "mobx";

export class EntityChecks {
    @observable theoryVerified: boolean;
    @observable SEOVerified: boolean;
    @observable allVerified: boolean;

    constructor(theoryVerified: boolean, SEOVerified: boolean, allVerified: boolean) {
        this.theoryVerified = theoryVerified;
        this.SEOVerified = SEOVerified;
        this.allVerified = allVerified;

        makeObservable(this);
    }

    @action.bound
    setTheoryVerified(theoryVerified: boolean) {
        this.theoryVerified = theoryVerified;
    }

    @action.bound
    setSEOVerified(SEOVerified: boolean) {
        this.SEOVerified = SEOVerified;
    }

    @action.bound
    setAllVerified(allVerified: boolean) {
        this.allVerified = allVerified;
    }
}
