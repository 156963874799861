import { action, makeObservable, observable } from "mobx";

import { createSerializedContainer } from "@viuch/math-editor";

import type { SolverTemplate } from "./SolverTemplate";
import type { StepTemplate } from "./StepTemplate";
import type { TSerializedState } from "@viuch/math-editor";

export interface IBaseTemplate {
    id: number;
    name: string;
    templates: Map<string, TemplatesList>;
}

export abstract class BaseTemplate {
    readonly id: number;
    @observable name;
    @observable templates;

    protected constructor(data: IBaseTemplate) {
        this.id = data.id;
        this.name = data.name;
        this.templates = data.templates;

        makeObservable(this);
    }

    abstract accept<R>(visitor: IDescriptionTemplateVisitor<R>): R;

    @action.bound
    setName(name: string) {
        this.name = name;
    }

    @action.bound
    addTemplate(group: string) {
        this.getOrCreateList(group).addTemplate();
    }

    @action.bound
    removeTemplate(group: string, i: number) {
        if (i !== -1) {
            this.getOrCreateList(group).removeTemplate(i);
        }
    }

    @action.bound
    setTemplate(group: string, i: number, value: TSerializedState) {
        this.getOrCreateList(group).setTemplate(i, value);
    }

    getOrCreateList(group: string): TemplatesList {
        let list = this.templates.get(group);
        if (!list) {
            list = new TemplatesList([]);
            this.templates.set(group, list);
        }
        return list;
    }

    cloneTemplate(): Map<string, TemplatesList> {
        return new Map([...this.templates.entries()].map(([key, value]) => [key, value.clone()]));
    }
}

export interface IDescriptionTemplateVisitor<R> {
    withSolver(template: SolverTemplate): R;
    withStep(template: StepTemplate): R;
}

export class TemplatesList {
    @observable.shallow templates: TSerializedState[];

    constructor(templates: TSerializedState[]) {
        this.templates = templates.slice();

        makeObservable(this);
    }

    @action.bound
    addTemplate() {
        this.templates.push(createSerializedContainer());
    }

    @action.bound
    removeTemplate(i: number) {
        if (i !== -1) {
            this.templates.splice(i, 1);
        }
    }

    @action.bound
    setTemplate(i: number, value: TSerializedState) {
        this.templates[i] = value;
    }

    clone(): TemplatesList {
        return new TemplatesList(this.templates);
    }
}
