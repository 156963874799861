import type { TTransformerObject } from "./transformer";

export function createScaleX(multiplierX: number): TTransformerObject {
    return {
        transformFunction: (f, x) => f(x / multiplierX),
        transformXInterval: ({ start, end }) => [
            {
                start: { ...start, value: start.value * multiplierX },
                end: { ...end, value: end.value * multiplierX },
            },
        ],
        transformXIntervalWithLimits: ({ start, end }) => [
            {
                start: { ...start, value: start.value * multiplierX },
                end: { ...end, value: end.value * multiplierX },
            },
        ],
        transformPoint: ({ x, y }) => [{ x: x * multiplierX, y }],
        transformXValue: (x) => x * multiplierX,
        transformYValue: (y) => y,
    };
}

export function createScaleY(multiplierY: number): TTransformerObject {
    return {
        transformFunction: (f, x) => f(x) * multiplierY,
        transformXInterval: (interval) => [interval],
        transformXIntervalWithLimits: ({ start, end }) => [
            {
                start: { ...start, limit: start.limit * multiplierY },
                end: { ...end, limit: end.limit * multiplierY },
            },
        ],
        transformPoint: ({ x, y }) => [{ x, y: y * multiplierY }],
        transformXValue: (x) => x,
        transformYValue: (y) => y * multiplierY,
    };
}

export function createScaleXY(multiplierX: number, multiplierY: number): TTransformerObject {
    return {
        transformFunction: (f, x) => f(x / multiplierX) * multiplierY,
        transformXInterval: ({ start, end }) => [
            {
                start: { ...start, value: start.value * multiplierX },
                end: { ...end, value: end.value * multiplierX },
            },
        ],
        transformXIntervalWithLimits: ({ start, end }) => [
            {
                start: { ...start, value: start.value * multiplierX, limit: start.limit * multiplierY },
                end: { ...end, value: end.value * multiplierX, limit: end.limit * multiplierY },
            },
        ],
        transformPoint: ({ x, y }) => [{ x: x * multiplierX, y: y * multiplierY }],
        transformXValue: (x) => x * multiplierX,
        transformYValue: (y) => y * multiplierY,
    };
}
