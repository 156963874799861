import type { TIntervalSign } from "../../statement/types";

export type TPathVariants = "default" | "first" | "last" | "alone";
export type TPathTypes = "path" | "area";
export type TPathData = {
    path?: string;
    area?: string;
    hideLine?: boolean;
};

export const paths: Record<TIntervalSign, Record<TPathVariants, TPathData>> = {
    positive: {
        default: {
            path: "M 0 5 Q 5 -3 10 5",
            area: "M 0 5 Q 5 -3 10 5 Z",
        },
        first: {
            path: "M 0 2 Q 5 -1 10 5",
            area: "M 0 2 Q 5 -1 10 5 H 0 Z",
        },
        last: {
            path: "M 0 5 Q 5 -1 10 2",
            area: "M 0 5 Q 5 -1 10 2 V 5 Z",
        },
        alone: {
            path: "M 0 1 H 10",
            area: "M 0 1 H 10 V 5 H 0 Z",
        },
    },
    negative: {
        default: {
            path: "M 0 5 Q 5 13 10 5",
            area: "M 0 5 Q 5 13 10 5 Z",
        },
        first: {
            path: "M 0 8 Q 5 11 10 5",
            area: "M 0 8 Q 5 11 10 5 H 0 Z",
        },
        last: {
            path: "M 0 5 Q 5 11 10 8",
            area: "M 0 5 Q 5 11 10 8 V 5 Z",
        },
        alone: {
            path: "M 0 9 H 10",
            area: "M 0 9 H 10 V 5 H 0 Z",
        },
    },
    zero: {
        default: {
            path: "M 0 5 H 10",
        },
        first: {
            path: "M 0 5 H 10",
        },
        last: {
            path: "M 0 5 H 10",
        },
        alone: {
            path: "M 0 5 H 10",
        },
    },
    none: {
        default: {},
        first: {},
        last: {},
        alone: {},
    },
};
