import { useCallback, useEffect, useRef } from "react";

import type { RefObject } from "react";
export type CloseFunction = (event?: MouseEvent) => void;

export const useClickOutside = <T extends HTMLElement>(onClose: CloseFunction): RefObject<T> => {
    const ref = useRef<T>(null);

    const mouseListener = useCallback(
        (e: MouseEvent) => {
            if (ref.current && e.target instanceof Node && !ref.current.contains(e.target)) {
                onClose(e);
            }
        },
        [onClose]
    );

    const keyboardListener = useCallback(
        (e: DocumentEventMap["keyup"]) => {
            if (e.key === "Escape") {
                onClose();
            }
        },
        [onClose]
    );

    useEffect(() => {
        let wasListenersRemoved = false;

        setTimeout(() => {
            if (wasListenersRemoved) return;

            document.addEventListener("click", mouseListener, false);
            document.addEventListener("keyup", keyboardListener, true);
        });

        return (): void => {
            wasListenersRemoved = true;

            document.removeEventListener("click", mouseListener, false);
            document.removeEventListener("keyup", keyboardListener, true);
        };
    }, [mouseListener, keyboardListener]);

    return ref;
};
