import type { BaseElementModel } from "../../element";

import { BaseElementsTogetherLimit } from "../BaseElementsTogetherLimit";
import { FactorialsTogetherElementsVisitor } from "../utils/FactorialsTogetherElementsVisitor";

export class FactorialsTogetherLimit extends BaseElementsTogetherLimit {
    private readonly finder: FactorialsTogetherElementsVisitor;

    constructor(maxCount: number) {
        super(maxCount);
        this.finder = new FactorialsTogetherElementsVisitor();
    }

    protected isElementMatch(element: BaseElementModel): boolean {
        return element.accept(this.finder) === "factorial";
    }
}
