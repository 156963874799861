import type { History } from "history";

export class RouterStore {
    public readonly history: History;

    public constructor(history: History) {
        this.history = history;
    }

    navigate(location: string, search?: string) {
        this.history.push({ pathname: location, search });
    }

    openNewTab(location: string) {
        window.open(location, "_blank");
    }
}
