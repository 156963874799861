import cn from "classnames";
import { autorun } from "mobx";
import { observer } from "mobx-react-lite";
import React from "react";

import { MathEditorInput } from "@viuch/math-editor";

import type { BaseLabelElement } from "./BaseLabelElement";
import type { KeyboardService } from "@viuch/math-editor";
import type { CSSProperties } from "react";

import styles from "./LabelView.module.scss";

type Props = {
    label: BaseLabelElement;
    onPointerEvent(e: React.PointerEvent, label: BaseLabelElement): void;
    keyboardService?: KeyboardService;
    bold?: boolean;
    small?: boolean;
    isAngle?: boolean;
    rotate?: boolean;
    allowAltOrigin?: boolean;
};

export const LabelView = observer(function LabelTextView({
    label,
    onPointerEvent,
    keyboardService,
    bold,
    isAngle,
    small,
    allowAltOrigin,
}: Props) {
    React.useEffect(() => {
        const dispose = autorun(() => {
            const value = label.value;
            label.inputService.setSerializedState(value);
        });

        return () => {
            dispose();
        };
    }, [label]);

    const handlePointerEvent = (e: React.PointerEvent) => {
        onPointerEvent(e, label);
    };

    React.useEffect(() => {
        if (label.isEditable) {
            label.inputService.focus();
        }
    }, [label, label.id]);

    const { x, y, rotationAngle, directionAngle, color, isEditable, inputService, altOrigin } = label;

    const style: CSSProperties = {
        "--x": x,
        "--y": 1 - y,
        "--offset-x": +(Math.sin(directionAngle - Math.PI / 2) / 2 - 0.5).toFixed(4),
        "--offset-y": +(Math.cos(directionAngle - Math.PI / 2) / 2 - 0.5 - (altOrigin ? 1 : 0)).toFixed(4),
        "--color": color.hex,
        "--rotateDeg": `${-rotationAngle}rad`,
    };

    return (
        <div
            className={cn(styles.label, isAngle && styles.isAngle, altOrigin && styles.altOrigin)}
            style={style}
            onPointerDown={handlePointerEvent}
            onPointerMove={handlePointerEvent}
            onPointerUp={handlePointerEvent}
            onPointerCancel={handlePointerEvent}
        >
            <div className={styles.inputWrapper}>
                <MathEditorInput
                    inputModel={inputService.model}
                    readonly={!isEditable}
                    withoutScrollbar
                    className={cn(styles.input, small && styles.small, bold && styles.bold)}
                    onBlur={label.notifyBlur}
                    contentClassName={cn(isEditable && styles.border)}
                    keyboardService={keyboardService}
                />
                {allowAltOrigin && isEditable && (
                    <button
                        onClick={() => label.toggleAltOrigin()}
                        className={cn(styles.button, !altOrigin && styles.up)}
                    ></button>
                )}
            </div>
        </div>
    );
});
