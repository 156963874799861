export function filterEqualArrayItems<T>(getHash: (item: T) => string | number, ...arrays: T[][]): T[] {
    const set = new Set<string | number>();
    const result: T[] = [];

    for (const array of arrays) {
        for (const item of array) {
            const hash = getHash(item);
            if (!set.has(hash)) {
                set.add(hash);
                result.push(item);
            }
        }
    }
    return result;
}

export const Bool: <T>(value: T | 0 | null | undefined | false) => value is T = Boolean;

/**
 * Move an array item to a different position. Returns a new array with the item moved to the new position.
 */
export function arrayMove<T>(array: T[], from: number, to: number): T[] {
    const newArray = array.slice();

    newArray.splice(to < 0 ? newArray.length + to : to, 0, newArray.splice(from, 1)[0]);

    return newArray;
}
