import cn from "classnames";
import { observer } from "mobx-react-lite";
import React from "react";

import { MathEditorInput } from "@viuch/math-editor";
import { roundToStep } from "@viuch/shared/utils/math/round";
import { useDisableSafariTouchSelectionFix, useTimeout } from "@viuch/utils/hooks";

import type { TInteractionData } from "./Dot.types";
import type { IntervalsDot } from "../../statement";
import type { MouseEvent, PointerEvent } from "react";

import { STEPS } from "../constants";
import { convertClientXToDotPosition } from "../utils";

import { IntervalsInstrumentStatementDotEditorViewModel } from "./Dot.vm";

import styles from "./Dot.module.scss";

type TProps = {
    dot: IntervalsDot;
    lineRef: React.RefObject<HTMLElement>;
    onEditDot: (dot: IntervalsDot) => void;
    onMoveDot: (dot: IntervalsDot, position: number) => void;
    readonly?: boolean;
};

const HOLD_TIME = 1000;
const HOLD_MOVE_DISTANCE = 15;

export const IntervalsInstrumentStatementDotEditor: React.VFC<TProps> = observer(
    function IntervalsInstrumentStatementDotEditor(props) {
        const { dot, lineRef, onEditDot, onMoveDot, readonly } = props;

        const vm = React.useMemo(() => new IntervalsInstrumentStatementDotEditorViewModel(dot), [dot]);

        const interactionRef = React.useRef<TInteractionData | null>(null);

        const { disableSelectAbility, returnSelectAbility } = useDisableSafariTouchSelectionFix();

        const { initTimeout, clearTimeout } = useTimeout();

        const handleContextMenu = (e: MouseEvent) => {
            if (readonly) {
                return;
            }
            e.preventDefault();
            interactionRef.current = null;
            onEditDot(dot);
        };

        const handlePointerDown = (e: PointerEvent) => {
            if (!e.isPrimary || readonly) {
                return;
            }
            disableSelectAbility();
            e.currentTarget.setPointerCapture(e.pointerId);

            clearTimeout();
            interactionRef.current = {
                initialClientX: e.clientX,
                isMoving: false,
            };

            initTimeout(() => {
                const info = interactionRef.current;
                if (info) {
                    onEditDot(dot);
                }
                interactionRef.current = null;
            }, HOLD_TIME);
        };

        const handlePointerMove = (e: PointerEvent) => {
            if (!e.isPrimary || readonly) {
                return;
            }
            e.currentTarget.setPointerCapture(e.pointerId);

            const info = interactionRef.current;
            if (!info) {
                return;
            }

            if (!info.isMoving) {
                const distance = Math.abs(info.initialClientX - e.clientX);
                if (distance > HOLD_MOVE_DISTANCE) {
                    clearTimeout();
                    info.isMoving = true;
                }
            }
            if (info.isMoving) {
                const newDotPosition = roundToStep(convertClientXToDotPosition(e.clientX, lineRef), STEPS);
                if (newDotPosition !== dot.position) {
                    onMoveDot(dot, newDotPosition);
                }
            }
        };

        function handlePointerCancel(e: PointerEvent) {
            if (!e.isPrimary || readonly) {
                return;
            }
            returnSelectAbility();
            clearTimeout();
            interactionRef.current = null;
        }

        function handlePointerUp(e: PointerEvent) {
            if (!e.isPrimary || readonly) {
                return;
            }
            returnSelectAbility();
            clearTimeout();
            interactionRef.current = null;
        }

        return (
            <div
                key={dot.id}
                tabIndex={0}
                className={styles.block}
                style={{ left: `${dot.position * 100}%` }}
            >
                <div className={styles.container}>
                    <div
                        className={cn(styles.dot, {
                            [styles.puncture]: dot.form === "puncture",
                        })}
                        onPointerDown={handlePointerDown}
                        onContextMenu={handleContextMenu}
                        onPointerUp={handlePointerUp}
                        onPointerMove={handlePointerMove}
                        onPointerCancel={handlePointerCancel}
                    />
                    <MathEditorInput
                        withoutScrollbar
                        key={vm.inputService.model.uuid}
                        inputModel={vm.inputService.model}
                        inputWrapperClassName={styles.dotInput}
                        className={styles.mathField}
                        contentClassName={styles.mathFieldContent}
                    />
                </div>
            </div>
        );
    }
);
