export class DeviceSettings {
    private readonly isTouch = isTouchDevice();

    get snapToGridAccuracy(): number {
        return this.isTouch ? 1 / 35 : 1 / 50;
    }

    get gridDivider(): number {
        return this.isTouch ? 2 : 1;
    }
}

export function isTouchDevice(): boolean {
    return typeof window === "undefined" ? false : "ontouchstart" in window || navigator.maxTouchPoints > 0;
}
