import type { TAnyAction } from "../types";

export type TDifferentialAction = { type: "differential"; content?: string };

export function createDifferentialAction(content?: string): TDifferentialAction {
    return { type: "differential", content };
}

export function isDifferentialAction(action: TAnyAction): action is TDifferentialAction {
    return action.type === "differential";
}
