import cn from "classnames";
import { forwardRef } from "react";

import styles from "./Baseline.module.scss";

type Props = {
    className?: string;
};

export const Baseline = forwardRef<HTMLElement, Props>(function Baseline({ className }, ref) {
    return (
        <span
            ref={ref}
            className={cn(styles.baseline, className)}
        />
    );
});
