import type { ISerializedFunction } from "./types";
import type { TSerializedElementPrototype } from "../../core/element";
import type { IElementVisitor } from "../../core/element/IElementVisitor";
import type { IStrategy } from "../../core/strategies";
import type { IMultiContainerElement } from "../../core/strategies/types";
import type { InputService } from "../../services";
import type { TContainerElements, TElementDeserializerFunction } from "../../types";

import { ContainerModel } from "../../core/container";
import { BaseElementModel } from "../../core/element";
import { MultiContainerElementStrategy } from "../../core/strategies/MultiContainerElementStrategy";

export class FunctionModel extends BaseElementModel<ISerializedFunction> implements IMultiContainerElement {
    public readonly funcNameContent: ContainerModel;
    public readonly funcArgContent: ContainerModel;

    public constructor(
        inputService: InputService,
        funcNameElements: TContainerElements,
        funcArgElements: TContainerElements,
        uuid?: string
    ) {
        super(inputService, uuid);

        const containerOptions = { showPlaceholderIfEmpty: true };

        this.funcNameContent = new ContainerModel(
            funcNameElements,
            this,
            ["funcNameContent"],
            inputService,
            containerOptions
        );
        this.funcArgContent = new ContainerModel(
            funcArgElements,
            this,
            ["funcArgContent"],
            inputService,
            containerOptions
        );
    }

    public serialize(): ISerializedFunction {
        return {
            type: "function",
            funcNameContent: this.funcNameContent.serialize(),
            funcArgContent: this.funcArgContent.serialize(),
            uuid: this.uuid,
        };
    }

    public static deserialize: TElementDeserializerFunction<ISerializedFunction> = (
        { inputService },
        { funcNameContent, funcArgContent, uuid }
    ) =>
        new FunctionModel(
            inputService,
            inputService.deserializeContainer(funcNameContent),
            inputService.deserializeContainer(funcArgContent),
            uuid
        );

    getContainersToMoveCursorBetween(): ContainerModel[] {
        return [this.funcNameContent, this.funcArgContent];
    }

    protected override initBehaviour(): IStrategy {
        return new MultiContainerElementStrategy(this);
    }

    public accept<R>(visitor: IElementVisitor<R>): R {
        return visitor.doWithFunction(this);
    }

    serializeAsClone(): TSerializedElementPrototype<ISerializedFunction> {
        const { uuid, ...clone } = this.serialize();
        return {
            ...clone,
            funcNameContent: this.funcNameContent.serializeAsClone(),
            funcArgContent: this.funcArgContent.serializeAsClone(),
        };
    }
}
