import type {
    BaseTaskInstrumentSettings,
    IInstrumentSettingsVisitor,
} from "../../entities/task-instruments-settings/BaseTaskInstrumentSettings";
import type { TTaskInstrumentSettings } from "@viuch/feature-instrument-settings/service-types/taskInstruments";

import { serializeFigureTaskSettings } from "./figure2d/serializeFigureTaskSettings";
import { serializeGraph1dTaskSettings } from "./graph1d/serializeGraph1dTaskSettings";
import { serializeGraph2DTaskSettings } from "./graph2d/serializeGraph2DTaskSettings";
import { serializeIntervalsTaskSettings } from "./intervals/serializeIntervalsTaskSettings";
import { serializeMultichoiceTaskSettings } from "./multichoice/serializeMultichoiceTaskSettings";
import { serializePictureTaskSettings } from "./picture/serializePictureTaskSettings";
import { serializeInformationTableTaskSettings } from "./table/serializeInformationTableTaskSettings";
import { serializeSelectTableTaskSettings } from "./table/serializeSelectTableTaskSettings";

export function serializeInstrumentSettings(instrument: BaseTaskInstrumentSettings): TTaskInstrumentSettings {
    return instrument.accept(serializeInstrumentSettingsVisitor);
}

const serializeInstrumentSettingsVisitor: IInstrumentSettingsVisitor<TTaskInstrumentSettings> = {
    withGraphic1d: serializeGraph1dTaskSettings,
    withInformationTable: serializeInformationTableTaskSettings,
    withIntervalMethod: serializeIntervalsTaskSettings,
    withSelectTable: serializeSelectTableTaskSettings,
    withPicture: serializePictureTaskSettings,
    withMultichoice: serializeMultichoiceTaskSettings,
    withFigure: serializeFigureTaskSettings,
    withGraph2d: serializeGraph2DTaskSettings,
};
