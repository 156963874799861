import { IntervalsDot, IntervalsInstrumentStatement, IntervalsLine } from "@viuch/instrument-intervals/statement";
import { IntervalsInstrumentSettings } from "@viuch/instrument-intervals-settings";

import type { TIntervalMethodInstrumentSettings, TRawIntervalSign } from "../../service-types/intervals";
import type { TIntervalSign } from "@viuch/instrument-intervals/statement";

export function mapIntervals(data: TIntervalMethodInstrumentSettings): IntervalsInstrumentSettings {
    return new IntervalsInstrumentSettings(mapIntervalsStatement(data));
}

export function mapIntervalsStatement(settings: TIntervalMethodInstrumentSettings): IntervalsInstrumentStatement {
    return new IntervalsInstrumentStatement(
        settings.axes.map((axis) => {
            return new IntervalsLine(
                axis.points.map((point) => {
                    return new IntervalsDot({
                        value: point.value,
                        position: point.position,
                        form: point.is_included ? "filled" : "puncture",
                    });
                }),
                axis.signs.map(mapIntervalsDotSign),
                axis.label
            );
        })
    );
}

function mapIntervalsDotSign(rawSign: TRawIntervalSign): TIntervalSign {
    switch (rawSign) {
        case "+":
            return "positive";
        case "-":
            return "negative";
        case "0":
            return "zero";
        case null:
        default:
            return "none";
    }
}
