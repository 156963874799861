import cn from "classnames";
import { observer } from "mobx-react-lite";
import React from "react";

import type { PropsWithChildren } from "react";

import styles from "./ScrollableFrame.module.scss";

type Props = PropsWithChildren<{
    className?: string;
    everyClassName?: string;
}>;

export const ScrollableFrame = observer(function ScrollableFrame({ className, everyClassName, children }: Props) {
    return (
        <div className={cn(styles.wrapper, className, everyClassName)}>
            <div className={cn(styles.inner, everyClassName)}>{children}</div>
        </div>
    );
});
