import auth from "./auth.json";
import common from "./common.json";
import dimensions from "./dimensions.json";
import routes from "./routes.json";
import sections from "./sections.json";
import task from "./task.json";
import themes from "./themes.json";
import validation from "./validation.json";

export default {
    auth,
    common,
    dimensions,
    routes,
    sections,
    task,
    themes,
    validation,
};
