import { action, makeObservable, observable } from "mobx";

import type { TSerializedState } from "@viuch/math-editor";

import { BaseGraphItem } from "../editor/model/BaseGraphItem";

export class PointGraphItem extends BaseGraphItem {
    readonly x: number;
    readonly y: number;
    readonly xFormula: TSerializedState;
    readonly included: boolean;
    readonly yFormula: TSerializedState;
    readonly color: string;
    @observable.ref label: TSerializedState | null;
    @observable.ref labelX: TSerializedState | null;
    @observable.ref labelY: TSerializedState | null;

    constructor(
        x: number,
        y: number,
        xFormula: TSerializedState,
        yFormula: TSerializedState,
        included: boolean,
        color: string,
        label: TSerializedState | null,
        labelX: TSerializedState | null,
        labelY: TSerializedState | null
    ) {
        super();

        this.x = x;
        this.y = y;
        this.xFormula = xFormula;
        this.yFormula = yFormula;
        this.included = included;
        this.color = color;
        this.label = label;
        this.labelX = labelX;
        this.labelY = labelY;

        makeObservable(this);
    }
    clone() {
        const { x, y, xFormula, yFormula, included, color, label, labelX, labelY } = this;
        return new PointGraphItem(x, y, xFormula, yFormula, included, color, label, labelX, labelY);
    }

    @action.bound
    setLabel(label: TSerializedState | null) {
        this.label = label;
    }

    @action.bound
    setLabelsXY(labelX: TSerializedState | null, labelY: TSerializedState | null) {
        this.labelX = labelX;
        this.labelY = labelY;
    }
}
