import type { BaseGraphItem } from "../editor/model/BaseGraphItem";

import { BaseGraphViewItem } from "../editor/rendering/BaseGraphViewItem";
import { zIndexValues } from "../editor/rendering/utils";

export class PointViewItem extends BaseGraphViewItem {
    x: number;
    y: number;
    isFilled: boolean;
    size: number;
    color: string;
    blur: boolean;

    constructor(
        model: BaseGraphItem,
        x: number,
        y: number,
        size: number,
        color: string,
        isFilled: boolean,
        blur = false
    ) {
        super(model, zIndexValues.point.default);

        this.x = x;
        this.y = y;
        this.size = size;
        this.color = color;
        this.isFilled = isFilled;
        this.blur = blur;
    }
}
