import { makeObservable, observable } from "mobx";

import { createSerializedContainer } from "@viuch/math-editor";

import type { IVariableContentVisitor } from "./IVariableContentVisitor";
import type { TSerializedState } from "@viuch/math-editor/types";

import { BaseVariableContent } from "./BaseVariableContent";

export interface INumberVariableContent {
    formula: TSerializedState;
    quantity_fractional_digits: number;
    multiple_of: TSerializedState[];
    conditions: TSerializedState[];
}

export class NumberVariableContent extends BaseVariableContent implements INumberVariableContent {
    public formula: TSerializedState;
    public quantity_fractional_digits: number;
    public multiple_of: TSerializedState[];
    public conditions: TSerializedState[];

    constructor(data: INumberVariableContent) {
        super();

        this.formula = data.formula;
        this.quantity_fractional_digits = data.quantity_fractional_digits;
        this.multiple_of = data.multiple_of.slice();
        this.conditions = data.conditions.slice();

        makeObservable(this, {
            formula: observable,
            quantity_fractional_digits: observable,
            multiple_of: observable.shallow,
            conditions: observable.shallow,
        });
    }

    accept<R>(visitor: IVariableContentVisitor<R>): R {
        return visitor.doWithNumber(this);
    }

    static createEmpty(): NumberVariableContent {
        return new NumberVariableContent({
            formula: createSerializedContainer(),
            quantity_fractional_digits: 0,
            multiple_of: [],
            conditions: [],
        });
    }

    clone(): NumberVariableContent {
        return new NumberVariableContent(this);
    }
}
