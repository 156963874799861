import type { TFormControl } from "@viuch/form-builder/types/controls";

import { createLabel } from "../controls/label";
import { exprMathControl } from "../controls/value";

export const numberToWords: TFormControl = {
    control: "Form",
    layout: "column",
    condition: { "./solver_type": "number_to_words" },
    controls: [
        createLabel("Преобразует число в текст"), //
        exprMathControl,
    ],
};
