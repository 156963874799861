import { action, makeObservable } from "mobx";

import type { TSnapshotModel } from "./types";
import type { Figure2D } from "../../entities/Figure2D";

import { mapSnapshotToModel, createModelSnapshot } from "./utils";

export interface IHistorySnapshot {
    readonly models: readonly TSnapshotModel[];
}

export class HistorySnapshot implements IHistorySnapshot {
    readonly models: readonly TSnapshotModel[];

    constructor(models: readonly TSnapshotModel[]) {
        this.models = [...models];

        makeObservable(this);
    }

    static areEqual(left: HistorySnapshot, right: HistorySnapshot): boolean {
        return JSON.stringify(left) === JSON.stringify(right);
    }

    static createFromFigure(figure: Figure2D) {
        const models = figure.models.map(createModelSnapshot).filter<TSnapshotModel>(Boolean);
        return new HistorySnapshot(models);
    }

    static createFromSerialized(data: IHistorySnapshot) {
        return new HistorySnapshot(data.models);
    }

    static createEmpty() {
        return new HistorySnapshot([]);
    }

    @action.bound
    apply(figure: Figure2D) {
        figure.models = this.models.map(mapSnapshotToModel);
    }

    @action.bound
    append(figure: Figure2D) {
        figure.models.push(...this.models.map(mapSnapshotToModel));
    }

    serialize(): IHistorySnapshot {
        return JSON.parse<IHistorySnapshot>(JSON.stringify(this));
    }
}
