import type { BaseFormNode } from "../nodes/BaseFormNode";
import type { TMathControl } from "../types/controls";

import { MathFormNode } from "../nodes/MathFormNode";
import { pathFromRaw } from "../utils/paths";

export function instantiateMathControl(data: TMathControl, parent: BaseFormNode | null) {
    const { path, label, multiline, extensions } = data;

    return new MathFormNode(label, !!multiline, parent, pathFromRaw(path), extensions ?? []);
}
