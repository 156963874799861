import { action, makeObservable, observable } from "mobx";

import { generateId } from "@viuch/shared/utils/data";

import type { ITableCellVisitor } from "./ITableCellVisitor";

export abstract class BaseTableInstrumentSettingsCell {
    readonly $$instanceId = generateId();

    @observable isHidden: boolean;

    protected constructor(isHidden: boolean) {
        this.isHidden = isHidden;

        makeObservable(this);
    }

    @action.bound
    setHidden(hidden: boolean) {
        this.isHidden = hidden;
    }

    abstract clone(): BaseTableInstrumentSettingsCell;

    abstract accept<R>(visitor: ITableCellVisitor<R>): R;
}
