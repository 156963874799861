import { action, makeObservable, observable } from "mobx";

import type { IFormNodeVisitor, BaseFormNode } from "./BaseFormNode";

import { BaseValueFormNode } from "./BaseValueFormNode";

export class TextFormNode extends BaseValueFormNode {
    @observable value: string;

    @action.bound
    setValue(value: string) {
        this.value = value;
    }

    readonly multiline: boolean;

    constructor(
        initialValue: string,
        label: string,
        multiline: boolean,
        parent: BaseFormNode | null,
        path: string[],
        extensions: string[]
    ) {
        super(label, parent, path, extensions);

        this.value = initialValue;
        this.multiline = multiline;

        makeObservable(this);
    }

    override valueEqualsTo(value: unknown): boolean {
        if (typeof value === "string") {
            return value === this.value;
        }

        return false;
    }

    override accept = <R>(visitor: IFormNodeVisitor<R>): R => visitor.withText(this);

    override getValue() {
        return this.value;
    }

    override trySetValue(value: unknown): boolean {
        if (typeof value === "string") {
            this.value = value;
            return true;
        }

        return false;
    }
}
