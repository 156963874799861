import cn from "classnames";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

import { IntervalsInstrumentSettingsEditor } from "@viuch/instrument-intervals-settings";
import { CheckboxInput, SelectInput } from "@viuch/ui-common";

import type { IntervalsEditor } from "../../../editors/instruments/IntervalsEditor";
import type { TEditorBlockVerticalAlign } from "../../../view/editor-view/useRenderBlock";
import type { KeyboardService } from "@viuch/math-editor";
import type { TSelectInputData } from "@viuch/ui-common";

import styles from "./IntervalsInstrumentEditor.module.scss";

type Props = {
    editor: IntervalsEditor;
    keyboardService: KeyboardService;
    className?: string;
};

export const IntervalsInstrumentEditor = observer(function IntervalsInstrumentEditor({
    className,
    editor,
    keyboardService,
}: Props) {
    const { verticalAlign, setVerticalAlign, autoHeight, setAutoHeight, intervals } = editor;
    const [large, setLarge] = useState(true);

    return (
        <div className={cn(styles.wrapper, className)}>
            <IntervalsInstrumentSettingsEditor
                keyboardService={keyboardService}
                intervals={intervals}
                className={cn(styles.instrument, large && styles.large)}
            />
            <div>
                <CheckboxInput
                    type="checkbox"
                    checked={autoHeight}
                    onChange={setAutoHeight}
                    labelAfter="Автоматическая высота по кол-ву строк"
                    noSpace
                />
                <CheckboxInput
                    type="checkbox"
                    checked={large}
                    onChange={setLarge}
                    labelAfter="Увеличить"
                    noSpace
                />
                <SelectInput<TEditorBlockVerticalAlign>
                    value={verticalAlign}
                    onChange={setVerticalAlign}
                    title="Вертикальное выравнивание"
                    data={verticalAlignData}
                    emptyLabel="По-умолчанию"
                />
            </div>
        </div>
    );
});

const verticalAlignData: TSelectInputData<TEditorBlockVerticalAlign> = [
    { label: "По-умолчанию", value: null },
    { label: "По верху", value: "top" },
    { label: "По центру", value: "center" },
    { label: "По низу", value: "bottom" },
];
