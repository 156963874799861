import React from "react";

import { createRootStore, RootStoreProvider } from "../integration";

import App from "./App";

const rootStore = createRootStore();

const Root = () => (
    <RootStoreProvider store={rootStore}>
        <App />
    </RootStoreProvider>
);

export default Root;
