import { action, makeObservable, observable } from "mobx";

import type { BaseElementModel } from "./BaseElementModel";
import type { InputService } from "../../services";

export class ElementsStore {
    private readonly inputService: InputService;
    private readonly elements: Map<string, BaseElementModel>;

    constructor(inputService: InputService) {
        this.inputService = inputService;
        this.elements = observable.map();

        makeObservable(this);
    }

    getAll(): Iterable<BaseElementModel> {
        return this.elements.values();
    }

    @action.bound
    register(element: BaseElementModel): void {
        this.elements.set(element.uuid, element);
    }

    @action.bound
    free(element: BaseElementModel): void {
        this.elements.delete(element.uuid);
    }

    getById<T extends BaseElementModel>(id: string): T {
        return this.elements.get<T>(id)!;
    }

    tryGetById(uuid: string): BaseElementModel | null {
        return this.getById(uuid);
    }
}
