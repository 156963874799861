import { observer } from "mobx-react-lite";
import { useEffect, useRef } from "react";

import type { LimModel } from "./LimModel";
import type { TElementProps } from "../../types/element";
import type { VFC } from "react";

import { MathEditorIcon } from "../../components/icon";
import { ContainerElement } from "../../core/container";
import { RoundBrackets } from "../../core/dynamic-brackets";
import { useMathEditorElementRef } from "../../hooks";

import styles from "./LimElement.module.scss";

export const LimElement: VFC<TElementProps<LimModel>> = observer(function LimElement({ elementModel }) {
    const ref = useMathEditorElementRef(elementModel);
    const limRef = useRef<HTMLSpanElement>(null);
    const contentFromRef = useRef<HTMLSpanElement>(null);

    useEffect(() => {
        const lim = limRef.current!;
        const contentFrom = contentFromRef.current!;

        const observer = new ResizeObserver(() => {
            lim.style.transform = `translateX(calc(${contentFrom.clientWidth}px - 0.3em))`;
        });

        observer.observe(contentFrom);

        return () => observer.disconnect();
    }, []);

    return (
        <span
            ref={ref}
            className={styles.inline}
        >
            <span className={styles.wrapper}>
                <span ref={limRef}>lim</span>
                <span className={styles.downWrapper}>
                    <span ref={contentFromRef}>
                        <ContainerElement containerModel={elementModel.contentFrom} />
                    </span>
                    <MathEditorIcon
                        iconName="lim"
                        className={styles.icon}
                    />
                    <ContainerElement containerModel={elementModel.contentTo} />
                </span>
            </span>

            <RoundBrackets>
                <ContainerElement
                    containerModel={elementModel.content}
                    inline
                />
            </RoundBrackets>
        </span>
    );
});
