import { action, makeObservable, observable } from "mobx";

import { MAX_LINES } from "./config";
import { IntervalsLine } from "./IntervalsLine";

export class IntervalsInstrumentStatement {
    @observable lines: IntervalsLine[];

    constructor(lines: IntervalsLine[]) {
        this.lines = lines;

        makeObservable(this);
    }

    static createEmpty(): IntervalsInstrumentStatement {
        return new IntervalsInstrumentStatement([IntervalsLine.createEmpty()]);
    }

    @action.bound
    addLine() {
        if (this.lines.length >= MAX_LINES) {
            return;
        }

        this.lines.push(IntervalsLine.createEmpty());
    }

    @action.bound
    removeLineByIndex(index: number) {
        this.lines.splice(index, 1);

        if (this.lines.length === 0) {
            this.addLine();
        }
    }

    @action.bound
    removeLine(line: IntervalsLine) {
        const i = this.lines.indexOf(line);
        if (i !== -1) {
            this.removeLineByIndex(i);
        }
    }

    @action.bound
    clear() {
        this.lines = [IntervalsLine.createEmpty()];
    }

    clone() {
        return new IntervalsInstrumentStatement(this.lines.map((line) => line.cloneWithCurrentId()));
    }
}
