import { action, makeObservable, observable } from "mobx";

import type { TKeyboardButtonTags } from "../../core/keyboard-button-tags";
import type { TAnyAction } from "../../types";
import type { InputService } from "../input";

import { KeyboardButtonTags } from "../../core/keyboard-button-tags";

class KeyboardService {
    private disableButtonRules: KeyboardButtonTags;
    currentInputService?: InputService;

    constructor() {
        this.disableButtonRules = new KeyboardButtonTags();

        makeObservable(this, {
            currentInputService: observable,
            setCurrentInputService: action,
            releaseCurrentInputService: action,
            dispatchAction: action,
        });
    }

    dispatchAction = <A extends TAnyAction>(action: A): void => {
        this.currentInputService?.focus();
        this.currentInputService?.dispatchAction(action);
    };

    setCurrentInputService(inputService: InputService): void {
        this.currentInputService = inputService;
    }

    releaseCurrentInputService(inputService: InputService): void {
        if (this.currentInputService === inputService) {
            this.currentInputService = void 0;
        }
    }

    setCurrentButtonTags(tags: TKeyboardButtonTags): void {
        this.disableButtonRules.setCurrentTags(tags);
    }

    checkButtonEnabled(tags: string[]): boolean {
        return this.disableButtonRules.checkEnabled(tags);
    }
}

export default KeyboardService;
