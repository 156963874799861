import { generateUuid } from "@viuch/shared/utils/data";

import type { ISerializedAngle } from "./types";
import type { IAngleAction } from "../../actions/angle";

export function createSerializedAngle(): ISerializedAngle {
    return { type: "angle", uuid: generateUuid() };
}

export function createAngleAction(): IAngleAction {
    return { type: "angle" };
}
