import React from "react";

import type { GeometryModel } from "./GeometryModel";
import type { TElementProps } from "../../types/element";

import { useMathEditorElementRef } from "../../hooks";
import { BaseSymbolElement } from "../base/base-symbol";

import { geometrySymbols } from "./GeometryElement.constants";

export const GeometryElement: React.VFC<TElementProps<GeometryModel>> = ({ elementModel }) => {
    const { className, renderChar } = geometrySymbols[elementModel.symbolName];
    const ref = useMathEditorElementRef(elementModel);

    return (
        <BaseSymbolElement
            ref={ref}
            className={className}
        >
            {renderChar()}
        </BaseSymbolElement>
    );
};
