export function resizeLines(
    leftLine: SVGPathElement,
    rightLine: SVGPathElement,
    wrapperWidth: number,
    svgHeight: number
): void {
    const halfWrapperWidth = wrapperWidth / 2;

    leftLine.setAttribute("d", `M 0 ${svgHeight - 1} l ${halfWrapperWidth} -6`);
    rightLine.setAttribute("d", `M ${wrapperWidth} ${svgHeight - 1} l ${-halfWrapperWidth} -6`);
}
