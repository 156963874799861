import type { SectionsFilters } from "../../entities/attention-filters/SectionsFilters";
import type { GetError } from "../base/RequestError";

import { agent } from "../../agent";
import { Section } from "../../entities/hierarchy";
import { IndexingEntityMetadata } from "../../entities/indexing/IndexingEntityMetadata";
import { BaseCRUDService } from "../base";

export class SectionsService extends BaseCRUDService<Section, ISectionRequest, ISectionResponse> {
    constructor() {
        super("/sections_new/", mapSection, serializeSection);
    }

    async getBySubjectId(subjectId: number): Promise<Section[]> {
        const { data } = await agent.get<ISectionResponse[]>(`/sections_new/`, {
            params: { subjects_in: subjectId },
        });

        return data.map(mapSection);
    }

    async search(filters: SectionsFilters, signal: AbortSignal): Promise<Section[]> {
        const params = serializeSectionFilters(filters);
        const { data } = await agent.get<ISectionResponse[]>(`/sections_new/`, { signal, params });

        return data.map(mapSection);
    }
}

export interface ISectionRequest {
    id: number;
    slug: string;
    name: string;
    subjects: number[];
    subsections: number[];
    description: string;
    meta_title: string | null;
    meta_description?: string | null;
}

export interface ISectionResponse extends ISectionRequest {
    theme_count: number;
    video_count: number;
    synopsis_count: number;
    task_count: number;
    problem_count: number;
    unverified_task_count: number;
}

export type TSectionError = GetError<ISectionRequest>;

export const mapSection = (data: ISectionResponse): Section => {
    const {
        id,
        name,
        slug,
        subjects,
        subsections,
        description,
        meta_title,
        meta_description,
        theme_count,
        video_count,
        synopsis_count,
        task_count,
        problem_count,
        unverified_task_count,
    } = data;

    return new Section({
        id,
        name,
        subjects,
        subsections,
        description,
        theme_count,
        video_count,
        synopsis_count,
        task_count,
        problem_count,
        unverified_task_count,
        indexing: new IndexingEntityMetadata(meta_title, meta_description ?? null, slug),
    });
};

export const serializeSection = ({
    id,
    name,
    subjects,
    subsections,
    description,
    indexing,
}: Section): ISectionRequest => ({
    id,
    name,
    subjects,
    subsections,
    description,
    slug: indexing.slug,
    meta_title: indexing.title,
    meta_description: indexing.description,
});

export const serializeSectionFilters = (filters: SectionsFilters) => {
    const data: Record<string, string> = {};

    filters.is_empty && (data.is_empty = "true");
    filters.is_detached && (data.is_detached = "true");
    filters.search && (data.search = filters.search);
    filters.is_meta_title_null && (data.is_meta_title_null = "true");
    filters.is_meta_description_null && (data.is_meta_description_null = "true");

    return new URLSearchParams(data);
};
