import { generateUuid } from "@viuch/shared/utils/data";

import type { ISerializedBracket, TBracketType } from "./types";
import type { BaseElementModel } from "../../core/element";

import { getClassFromInstance } from "../../utils/data";

import { BracketModel } from "./BracketModel";

export function isBracket(model: BaseElementModel): model is BracketModel {
    return getClassFromInstance(model) === BracketModel;
}

export function createSerializedBracket(bracket: TBracketType, closing: boolean): ISerializedBracket {
    return { type: "bracket", uuid: generateUuid(), closing, bracket };
}
