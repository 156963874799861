import type { TFormControl } from "@viuch/form-builder/types/controls";

import { createLabel } from "../controls/label";
import { variableMathControl } from "../controls/value";

export const inequality: TFormControl = {
    control: "Form",
    layout: "column",
    condition: { "./solver_type": ["inequality"] },
    controls: [
        createLabel("Решает неравенство"), //
        {
            control: "Math",
            path: "./equation",
            label: "Неравенство",
        },
        variableMathControl,
    ],
};
