import type { AuthStore } from "./AuthStore";

export type TAccess = ReturnType<typeof createAccess>;

export function createAccess(authStore: AuthStore) {
    const r = authStore.user?.role;

    /** @deprecated */
    const __defaultRead = !r || !!r.everything_else?.can_read;
    /** @deprecated */
    const __defaultCreate = !r || !!r.everything_else?.can_edit_owned;
    /** @deprecated */
    const __superAdmin = !r;

    return {
        __unknown: __defaultRead,
        authorized: authStore.isAuthorized,
        content: {
            attentionsPageView: __defaultRead,
            hierarchy: {
                viewTree: __defaultRead,
                viewWarnings: __defaultRead,
            },
            subjects: {
                viewPage: __defaultRead,
                viewWarnings: __defaultRead,
                createNew: __defaultCreate,
            },
            sections: {
                viewPage: __defaultRead,
                viewWarnings: __defaultRead,
                createNew: __defaultCreate,
            },
            subsections: {
                viewPage: __defaultRead,
                viewWarnings: __defaultRead,
                createNew: __defaultCreate,
            },
            themes: {
                viewList: !r || !!r.themes?.can_read,
                viewPage: !r || !!r.themes?.can_read,
                viewWarnings: !r || !!r.themes?.can_read,
                createPage: !r || !!r.themes?.can_edit_owned,
                solutionExamples: __defaultRead,
                viewRelationsTree: __defaultRead,
                verifyTheme: !r || !!r?.themes?.can_edit_verified,
                verifyTask: !r || !!r?.themes?.can_edit_verified,
            },
            grades: {
                viewList: __defaultRead,
            },
            synopses: {
                viewList: __defaultRead,
                viewWarnings: __defaultRead,
                createNew: __defaultCreate,
                viewPage: __defaultRead,
            },
            draftSynopses: {
                viewList: !r || !!r.synopsis_drafts?.can_read,
                viewPage: !r || !!r.synopsis_drafts?.can_read,
                createNew: !r || !!r.synopsis_drafts?.can_edit_owned,
            },
            blogs: {
                viewList: __defaultRead,
                createNew: __defaultCreate,
                viewPage: __defaultRead,
            },
            studyPlans: {
                viewList: __defaultRead,
                viewPage: __defaultRead,
            },
            exams: {
                viewList: __defaultRead,
                viewWarnings: __defaultRead,
                createNew: __defaultCreate,
                viewPage: __defaultRead,
                variants: {
                    viewList: __defaultRead,
                    viewPage: __defaultRead,
                },
            },
            problems: {
                viewList: __defaultRead,
                edit: __defaultRead,
            },
            videos: {
                viewList: __defaultRead,
            },
        },
        communication: {
            reviewsComments: { view: __defaultRead },
            questionsAnswers: { view: __defaultRead },
        },
        admin: {
            admins: {
                viewPage: __defaultRead,
                viewList: __defaultRead,
            },
            students: {
                viewPage: __defaultRead,
                viewList: __defaultRead,
                solutions: {
                    viewPage: __defaultRead,
                },
            },
            faq: { view: __defaultRead },
            configs: { view: __defaultRead },
            regenerateVariants: __defaultRead,
            indexing: { viewWarnings: __defaultRead },
            stats: {
                byThemes: __defaultRead,
                solvers: __defaultRead,
            },
            tariffs: { edit: __superAdmin },
        },
    } as const;
}
