import { makeObservable, observable } from "mobx";

import { generateId } from "@viuch/shared/utils/data";

import type { IModelVisitor } from "../BaseModel";
import type { TPoint } from "@viuch/geometry-lib/types";

import { BaseModel } from "../BaseModel";

export class ComputedIntersectionPoints extends BaseModel {
    points: TPoint[];

    constructor(id: number) {
        super(id);
        this.points = [];

        makeObservable(this, {
            points: observable,
        });
    }

    accept<R>(visitor: IModelVisitor<R>): R {
        return visitor.withComputedIntersectionPoints(this);
    }

    static create() {
        return new ComputedIntersectionPoints(generateId());
    }
}
