import cn from "classnames";
import { observer } from "mobx-react-lite";
import { useRef } from "react";

import type { Graph2DToolbar } from "../core/Graph2DToolbar";

import { AnyToolbarElement } from "./AnyToolbarElement";

import styles from "./GraphToolbar.module.scss";

type Props = {
    toolbar: Graph2DToolbar;
    className?: string;
};

export const GraphToolbar = observer(function GraphToolbar({ toolbar, className }: Props) {
    const presetName = toolbar.getCurrentPresetName();

    const ref = useRef<HTMLDivElement>(null);
    const contentRef = useRef<HTMLDivElement>(null);

    if (!presetName) return null;

    const element = toolbar.getPreset(presetName);

    return (
        <div className={styles.toolbarPosition}>
            <div
                data-info="toolbar"
                className={cn(styles.toolbar, className)}
                ref={contentRef}
            >
                <AnyToolbarElement
                    toolbar={toolbar}
                    rootRef={ref}
                    element={element}
                    className={styles.element}
                />
            </div>
        </div>
    );
});
