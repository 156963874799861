import type { TFormControl } from "@viuch/form-builder/types/controls";

import { createLabel } from "../controls/label";
import { variableMathControl } from "../controls/value";

export const univariativeSystem: TFormControl = {
    control: "Form",
    layout: "column",
    condition: { "./solver_type": "univariative_system" },
    controls: [
        createLabel("Решает систему уравнений и неравенств в одной переменной"),
        {
            path: "./conditions",
            control: "Math",
            label: "Система уравнений и неравенств",
        },
        variableMathControl,
    ],
};
