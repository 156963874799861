import type { THighPriorityOperatorsActionSign } from "../elements/operator-high/types";
import type { TAnyAction } from "../types";

export type THighPriorityOperatorAction = {
    type: "high-priority-operator";
    sign: THighPriorityOperatorsActionSign;
};

export function createHighPriorityAction(sign: THighPriorityOperatorsActionSign): THighPriorityOperatorAction {
    return { type: "high-priority-operator", sign };
}

export function isHighPriorityAction(action: TAnyAction): action is THighPriorityOperatorAction {
    return action.type === "high-priority-operator";
}
