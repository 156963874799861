import EditorJS from "@editorjs/editorjs";
import { action, makeObservable, observable } from "mobx";

import { assert } from "@viuch/utils/debug";

import type { TFormatEditorData } from "./types";
import type { IEditorVisitor } from "../IEditorVisitor";
import type { IMathConverterService } from "../types";
import type { OutputData } from "@editorjs/editorjs";
import type { EditorConfig } from "@editorjs/editorjs/types/configs";
import type { IUploadImageService } from "@viuch/instrument-picture-settings/settings-editor/types";
import type { KeyboardService } from "@viuch/math-editor";

import { BaseEditor, EditorTypes } from "../base";

import { colors } from "./colors";
import { MathEditorTool } from "./plugins/MathEditorWebComponentPlugin";

export class FormatEditor extends BaseEditor<TFormatEditorData> {
    public editor?: EditorJS;
    private readonly state?: OutputData;

    public constructor(state?: OutputData) {
        super();

        this.state = state;

        makeObservable(this, {
            editor: observable,
            createEditor: action,
        });
    }

    public createEditor(
        mathConverter: IMathConverterService,
        uploadService: IUploadImageService,
        keyboardService: KeyboardService,
        onReady?: VoidFunction
    ): void {
        // eslint-disable-next-line @typescript-eslint/no-var-requires

        // eslint-disable-next-line @typescript-eslint/no-var-requires
        const PLUGINS = require("./FormarEditor.constants").PLUGINS;

        this.editor = new EditorJS({
            data: this.state,
            holder: this.uuid,
            autofocus: false,
            onReady,
            logLevel: "ERROR",
            tools: {
                ...PLUGINS,
                MathEditorTool: {
                    class: MathEditorTool,
                    inlineToolbar: true,
                    shortcut: "CTRL+M",
                },
                List: {
                    class: PLUGINS.List,
                    inlineToolbar: true,
                },
                Table: {
                    class: PLUGINS.Table,
                    inlineToolbar: true,
                },
                Header: {
                    class: PLUGINS.Header,
                    inlineToolbar: true,
                },
                Image: {
                    class: PLUGINS.Image,
                    inlineToolbar: true,
                    config: {
                        uploader: {
                            uploadByFile: (file: File) =>
                                new Promise((resolve, reject) => {
                                    uploadService
                                        .uploadImage(file)
                                        .then((image) => {
                                            resolve({
                                                success: 1,
                                                file: { url: image.url },
                                            });
                                        })
                                        .catch(() => {
                                            reject();
                                        });
                                }),
                        },
                    },
                },
                CustomColor: {
                    class: PLUGINS.CustomColor,
                    inlineToolbar: true,
                    config: {
                        colors: colors.map((cssValue) => ({
                            cssValue,
                        })),
                    },
                },
            },
        });
    }

    public destroy(): void {
        this.editor?.destroy();
    }

    public async serialize(): Promise<TFormatEditorData> {
        assert(this.editor);

        const state = await this.editor.save();

        return { type: EditorTypes.format, state };
    }

    public accept<R>(visitor: IEditorVisitor<R>): R {
        return visitor.visitFormatEditor(this);
    }

    public checkIsEmpty(): boolean {
        assert(this.editor);

        const { blocks } = this.editor;
        const blocksLength = blocks.getBlocksCount();

        if (!blocksLength) return true;

        for (let i = 0; i < blocksLength; i++) {
            const block = blocks.getBlockByIndex(i);
            if (block && !block.isEmpty) {
                return false;
            }
        }

        return true;
    }
}

declare module "@editorjs/editorjs/types" {
    type FixedEditorConfig = Omit<EditorConfig, "logLevel"> & { logLevel?: "VERBOSE" | "INFO" | "WARN" | "ERROR" };

    export default class EditorJS {
        constructor(configuration?: FixedEditorConfig);
    }
}
