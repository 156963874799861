import type { ISerializedRoot, TRootModelOptions } from "./types";
import type { TSerializedElementPrototype } from "../../core/element";
import type { IElementVisitor } from "../../core/element/IElementVisitor";
import type { IStrategy } from "../../core/strategies";
import type { IMultiContainerElement } from "../../core/strategies/types";
import type { InputService } from "../../services";
import type { TContainerElements, TElementDeserializerFunction } from "../../types";

import { ContainerModel } from "../../core/container";
import { BaseElementModel } from "../../core/element";

import { RootElementStrategy } from "./RootElementStrategy";

export class RootModel extends BaseElementModel<ISerializedRoot> implements IMultiContainerElement {
    public readonly isSquared: boolean;
    public content: ContainerModel;
    public indicator: ContainerModel;

    public constructor(
        inputService: InputService,
        content: TContainerElements,
        indicator: TContainerElements,
        options: TRootModelOptions,
        uuid?: string
    ) {
        super(inputService, uuid);
        this.isSquared = Boolean(options.isSquared);

        this.indicator = new ContainerModel(indicator, this, ["indicator"], this.inputService, {
            showPlaceholderIfEmpty: true,
        });

        this.content = new ContainerModel(content, this, ["content"], this.inputService, {
            showPlaceholderIfEmpty: true,
        });
    }

    getContainersToMoveCursorBetween(): ContainerModel[] {
        return this.isSquared ? [this.content] : [this.indicator, this.content];
    }

    public serialize(): ISerializedRoot {
        return {
            type: "root",
            uuid: this.uuid,
            content: this.content.serialize(),
            indicator: this.indicator.serialize(),
            isSquared: this.isSquared,
        };
    }

    public static deserialize: TElementDeserializerFunction<ISerializedRoot> = (
        { inputService },
        { content, indicator, uuid, isSquared }
    ) => {
        return new RootModel(
            inputService,
            inputService.deserializeContainer(content),
            inputService.deserializeContainer(indicator),
            { isSquared },
            uuid
        );
    };

    protected override initBehaviour(): IStrategy {
        return new RootElementStrategy(this);
    }

    public accept<R>(visitor: IElementVisitor<R>): R {
        return visitor.doWithRoot(this);
    }

    serializeAsClone(): TSerializedElementPrototype<ISerializedRoot> {
        const { uuid, ...clone } = this.serialize();
        return {
            ...clone,
            content: this.content.serializeAsClone(),
            indicator: this.indicator.serializeAsClone(),
        };
    }

    override getWrapperContainer(): ContainerModel | null {
        return this.content;
    }
}
