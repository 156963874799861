import { action, makeObservable, observable } from "mobx";

import type { TSerializedState } from "@viuch/math-editor";

export class Graph2DInstrumentAxisSettings {
    @observable.ref axisName: TSerializedState;

    constructor(axisName: TSerializedState) {
        this.axisName = axisName;

        makeObservable(this);
    }

    @action.bound
    setAxisName(value: TSerializedState) {
        this.axisName = value;
    }

    clone() {
        return new Graph2DInstrumentAxisSettings(this.axisName);
    }
}
