import type { TAnyAction } from "../types";

export type TLinebreakDirection = "up" | "down";

export type TLinebreakAction = {
    type: "linebreak";
    direction: TLinebreakDirection;
};

export function createLinebreakAction(direction: TLinebreakDirection): TLinebreakAction {
    return { type: "linebreak", direction };
}

export function isLinebreakAction(action: TAnyAction): action is TLinebreakAction {
    return action.type === "linebreak";
}
