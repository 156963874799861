import { normalizeNumber } from "@viuch/shared/utils/math/normalize";
import { assert } from "@viuch/utils/debug";

import type { TPictureWatermarkPosition } from "@viuch/instrument-picture/statement";
import type { RefObject } from "react";

export function convertCoordinatesToFloatPosition(
    x: number,
    y: number,
    nodeRef: RefObject<HTMLElement>
): TPictureWatermarkPosition {
    const node = nodeRef.current;
    assert(node);

    const { left, top, width, height } = node.getBoundingClientRect();
    assert(width > 0);
    assert(height > 0);

    const leftPos = (x - left) / width;
    const topPos = (y - top) / height;

    return {
        left: normalizeNumber(leftPos, { min: 0, max: 1 }),
        top: normalizeNumber(topPos, { min: 0, max: 1 }),
    };
}
