import type { TRawTheorem } from "./types";

import { agent } from "../../agent";
import { throwServiceError } from "../base/RequestError";

export class TheoremsService {
    async allTheorems(): Promise<TRawTheorem[]> {
        try {
            const { data } = await agent.get<TRawTheorem[]>("/geometry_theorems/");

            return data;
        } catch (e) {
            throwServiceError(e);
        }
    }
}
