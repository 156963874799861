import { expandDuration, mapDurationToSeconds } from "./durations";

export const padTimeSegment = (n: number): string => {
    const value = n.toString();

    if (value.length === 1) return `0${n}`;

    return value;
};

export function formatTime(format: "hh:mm" | "hh:mm:ss" | "m:ss", hours: number, minutes: number, seconds = 0): string {
    const hh = padTimeSegment(hours);
    const mm = padTimeSegment(minutes);
    const ss = padTimeSegment(seconds);
    if (format === "hh:mm") {
        return `${hh}:${mm}`;
    }
    if (format === "hh:mm:ss") {
        return `${hh}:${mm}:${ss}`;
    }
    const m = hours * 60 + minutes;
    return `${m}:${ss}`;
}

export function formatTimeSeconds(s: number): string {
    const { days, hours, minutes, seconds } = expandDuration(s);

    return formatTime("hh:mm:ss", hours + days * 24, minutes, seconds);
}

export function formatDurationShort(s: number): string {
    const { days, hours, minutes, seconds } = expandDuration(s);

    return formatTime("m:ss", hours + days * 24, minutes, seconds);
}

export function parseTime(timeStr: string) {
    // hh:mm OR hh:mm:ss
    const result = /^(\d{2}):(\d{2})(?::(\d{2}))?$/.exec(timeStr);
    if (!result) {
        throw new Error(`Wrong time format: ${timeStr}`);
    }
    const [, hh, mm, ss] = result;

    const hours = +hh;
    const minutes = +mm;
    const seconds = ss ? +ss : 0;

    return { hours, minutes, seconds };
}

export function mapTimeToSeconds(timeStr: string): number {
    return mapDurationToSeconds(timeStr);
}
