import type { BaseElementModel } from "../core/element";
import type { TAnySerializedElement } from "../types";

import { BaseCommand } from "../core/commands";

import { LimitExceededError } from "./errors/LimitExceededError";
import { NestingLimitExceedError } from "./errors/NestingLimitExceedError";
import { computeNestingDown } from "./utils";

export class InsertIntoCursorCommand<T extends BaseElementModel> extends BaseCommand<T> {
    private readonly serializedElement: TAnySerializedElement;

    public constructor(model: T, serializedElement: TAnySerializedElement) {
        if (computeNestingDown(model.parentContainer) >= model.inputService.maxNestingLevel) {
            throw new NestingLimitExceedError();
        }

        super(model);

        const container = this.selectionSnapshot ? this.selection.commonContainer! : this.cursor.container;

        const element = this.deserializeElement(serializedElement);
        const canBeInserted =
            this.limits.checkElementCanBeInserted(element, this.cursor.container, this.cursorPosition) &&
            model.behaviour.checkCanBeInserted(element, container);

        if (!canBeInserted) {
            throw new LimitExceededError();
        }

        this.serializedElement = serializedElement;
    }

    apply(): void {
        const element = this.inputService.deserializeElement(this.serializedElement);

        const container = this.getCursorContainer();
        this.selection.clearSelection();

        container.insertElement(element, this.cursorPosition);
        this.cursor.moveToPosition(container, this.cursorPosition);

        element.behaviour.handleInserted();
    }

    rollback(): void {
        const container = this.getCursorContainer();
        this.selection.clearSelection();
        container.removeElementByIndex(this.cursorPosition);

        this.cursor.moveToPosition(container, this.cursorPosition);
    }
}
