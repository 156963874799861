import { action, comparer, computed, makeObservable, observable } from "mobx";

import { generateUuid } from "@viuch/shared/utils/data";
import { assert } from "@viuch/utils/debug";

import type { IContainerModelOptions, TElementsWithThings, TThing } from "./types";
import type { InputService } from "../../services";
import type { TAnyAction, TAnySerializedElement, TContainerElements, TRect, TSerializedContainer } from "../../types";
import type { CursorState } from "../cursor";
import type { BaseElementModel, TSerializedContainerPrototype, TSerializedElementPrototype } from "../element";

import { compare } from "../../utils/data";
import { LinebreakFinder } from "../cursor/LinebreakFinder";
import { PairBracketsContainerPlugin } from "../dynamic-brackets";

import { ElementPlaceholdersChecker } from "./ElementPlaceholdersChecker";

export class ContainerModel<T extends BaseElementModel = BaseElementModel> {
    public readonly inputService: InputService;
    public readonly parentElement: T;
    public readonly pairBrackets: PairBracketsContainerPlugin;
    public elements!: BaseElementModel[];
    public readonly uuid: string;
    public domElement?: HTMLElement;
    public typer = LinebreakFinder.Create();
    public parentPath: (string | number)[];

    private readonly showPlaceholderWhenEmpty: boolean;
    private readonly elementPlaceholderCategory = ElementPlaceholdersChecker.Singleton();

    get lastElement(): BaseElementModel | undefined {
        const length = this.elements.length;
        if (length === 0) {
            return undefined;
        }
        return this.elements[length - 1];
    }

    public constructor(
        data: TContainerElements,
        parentModel: T,
        parentPath: (string | number)[],
        inputService: InputService,
        options?: IContainerModelOptions
    ) {
        this.parentPath = parentPath;
        this.uuid = data.uuid ?? generateUuid();
        this.inputService = inputService;
        this.parentElement = parentModel;
        this.showPlaceholderWhenEmpty = Boolean(options?.showPlaceholderIfEmpty);
        this.setElements(data.elements);

        makeObservable(this, {
            elements: observable,
            insertElement: action,
            removeElementByIndex: action,
            setElements: action,
            elementsWithThings: computed({ equals: comparer.structural }),
            insertElementsRange: action,
            removeElementsRange: action,
            domElement: observable,
            setDomElement: action,
        });

        this.pairBrackets = new PairBracketsContainerPlugin(this);

        inputService.containers.register(this);

        parentModel.registerDisposeCallback(() => {
            this.elements.forEach((element) => {
                element.dispose();
            });

            inputService.containers.free(this);
        });
    }

    get cursor() {
        return this.inputService.cursorState;
    }

    private get cursorState(): CursorState {
        return this.inputService.cursorState;
    }

    public get elementsWithThings(): TElementsWithThings {
        const isContainerThis = this.cursorState.container === this;
        const cursorIndex = this.cursorState.index;

        const cat = this.elementPlaceholderCategory;
        const isReadonly = this.inputService.isReadOnly;

        const things: TElementsWithThings = this.elements.flatMap((element, i, elements) => {
            return Array.from(
                (function* (): Generator<TThing> {
                    let hasCursor = false;
                    if (!isReadonly && isContainerThis && cursorIndex === element.index) {
                        yield { type: "cursor" };
                        hasCursor = true;
                    }

                    const prev = i === 0 ? undefined : elements[i - 1];
                    if (hasCursor) {
                        yield {
                            type: "placeholder",
                            key: "placeholder_cursor",
                        };
                    } else if (cat.shouldShowPlaceholder(prev, element) && !isReadonly) {
                        yield {
                            type: "placeholder",
                            key: `placeholder_${element.uuid}`,
                        };
                    }

                    yield { type: "element", element };
                })()
            );
        });

        let hasCursor = false;
        if (isContainerThis && this.cursorState.isInEnd && !isReadonly) {
            things.push({ type: "cursor" });
            hasCursor = true;
        }

        const lastPlaceholder = cat.shouldShowPlaceholder(this.lastElement, undefined);

        if (hasCursor) {
            things.push({ type: "placeholder", key: "placeholder_cursor" });
        } else if ((this.showPlaceholderWhenEmpty && !this.elements.length) || lastPlaceholder) {
            things.push({ type: "placeholder", key: "placeholder_last" });
        }

        return things;
    }

    public checkIsEmpty(): boolean {
        return this.elements.length === 0;
    }

    public handleAction<A extends TAnyAction>(action: A): void {
        this.parentElement.handle(action);
    }

    public getElementIndex(element: BaseElementModel): number {
        return this.elements.indexOf(element);
    }

    public getElementsCount(): number {
        return this.elements.length;
    }

    public getElements(): BaseElementModel[] {
        return this.elements;
    }

    public swapByIndexes(left: number, right: number): void {
        [this.elements[left], this.elements[right]] = [this.elements[right], this.elements[left]];
    }

    public insertElement(newElement: BaseElementModel, positionToInsert: number): void {
        assert(positionToInsert <= this.elements.length);
        this.attachParentReferenceToElement(newElement);
        this.elements.splice(positionToInsert, 0, newElement);
    }

    public insertElementsRange(newElements: BaseElementModel[], startIndex: number): void {
        assert(startIndex <= this.elements.length);
        newElements.forEach((element) => {
            this.attachParentReferenceToElement(element);
        });
        this.elements.splice(startIndex, 0, ...newElements);
    }

    public tryGetElementByIndex(elementIndex?: number): BaseElementModel | undefined {
        if (elementIndex === undefined || elementIndex >= this.elements.length || elementIndex < 0) {
            return undefined;
        }
        return this.getElementByIndex(elementIndex);
    }

    public getElementByIndex(elementIndex: number): BaseElementModel {
        assert(elementIndex < this.elements.length);
        return this.elements[elementIndex];
    }

    public removeElementByIndex(elementIndex: number): void {
        assert(elementIndex >= 0 && elementIndex < this.elements.length, {
            elementIndex,
            length: this.getElementsCount(),
            container: this,
        });
        const removedElements = this.elements.splice(elementIndex, 1);
        removedElements[0]?.dispose();
    }

    public removeElementsByIndexes(indexes: number[]): void {
        const sortedDescIndexes = indexes.sort((a, b) => compare(b, a));
        sortedDescIndexes.forEach((index) => {
            this.removeElementByIndex(index);
        });
    }

    public removeElement(element: BaseElementModel): void {
        const elementIndex = this.elements.indexOf(element);
        this.removeElementByIndex(elementIndex);
    }

    private attachItsReferenceToElements(): void {
        this.elements.forEach((element) => {
            element.parentContainer = this;
        });
    }

    private attachParentReferenceToElement(element: BaseElementModel): void {
        element.parentContainer = this;
    }

    public serialize(): TSerializedContainer {
        const elements = this.elements.reduce<TAnySerializedElement[]>((data, element) => {
            const serializedElement = element.serialize();

            if (serializedElement) {
                data.push(serializedElement);
            }

            return data;
        }, []);
        return { uuid: this.uuid, elements };
    }

    public serializeAsClone(): TSerializedContainerPrototype {
        const elements = this.elements.reduce<TSerializedElementPrototype[]>((data, element) => {
            const serializedElement = element.serializeAsClone();

            if (serializedElement) {
                data.push(serializedElement);
            }

            return data;
        }, []);
        return { elements };
    }

    public setElements(newElements: BaseElementModel[]) {
        this.elements = newElements;
        this.attachItsReferenceToElements();
    }

    public setDomElement(domElement?: HTMLElement): void {
        this.domElement = domElement;
    }

    public getRect(): TRect | null {
        if (!this.domElement) {
            return null;
        }
        const { x, y, width: w, height: h } = this.domElement.getBoundingClientRect();

        return { x, y, w, h };
    }

    removeElementsRange(startIndex: number, length: number): void {
        assert(
            startIndex >= 0 &&
                startIndex < this.elements.length &&
                length >= 0 &&
                this.elements.length >= startIndex + length
        );
        const removedElements = this.elements.splice(startIndex, length);
        removedElements.forEach((element) => element.dispose());
    }

    updatePath(path: (string | number)[]) {
        this.parentPath = path.slice();
    }
}
