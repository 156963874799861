import type { TFormControl } from "@viuch/form-builder/types/controls";

import { createLabel } from "../controls/label";
import { valueMathControl } from "../controls/value";

export const limit: TFormControl = {
    control: "Form",
    layout: "column",
    condition: { "./solver_type": "limit" },
    controls: [
        createLabel("Находит предел функции"), //
        valueMathControl,
    ],
};
