import type { TFormControl } from "@viuch/form-builder/types/controls";

import { createLabel } from "../controls/label";

export const findPrimes: TFormControl = {
    control: "Form",
    layout: "column",
    condition: {
        "./solver_type": ["find_primes"],
    },
    controls: [
        createLabel("Находит среди списка чисел простые"), //
        {
            control: "Math",
            path: "./numbers",
            label: "Ряд чисел",
        },
    ],
};
