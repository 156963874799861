import cn from "classnames";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

import { PictureInstrumentSettingsEditor } from "@viuch/instrument-picture-settings";
import { CheckboxInput } from "@viuch/ui-common";

import type { PictureInstrumentSettingsImpl } from "@viuch/instrument-picture-settings";
import type { IUploadImageService } from "@viuch/instrument-picture-settings/settings-editor/types";
import type { KeyboardService } from "@viuch/math-editor";

import styles from "./PictureInstrumentEditor.module.scss";

type Props = {
    picture: PictureInstrumentSettingsImpl;
    uploadService: IUploadImageService;
    keyboardService: KeyboardService;
};

export const PictureInstrumentEditor = observer(function PictureInstrumentEditor({
    keyboardService,
    uploadService,
    picture,
}: Props) {
    const [large, setLarge] = useState(true);

    return (
        <div className={cn(styles.wrapper)}>
            <PictureInstrumentSettingsEditor
                picture={picture}
                keyboardService={keyboardService}
                uploadImageService={uploadService}
                frameClassName={cn(styles.instrument, large && styles.large)}
            />
            <CheckboxInput
                type="checkbox"
                checked={large}
                onChange={setLarge}
                labelAfter="Увеличить"
                noSpace
            />
        </div>
    );
});
