import type { ISerializedDifferential } from "./types";
import type { TSerializedElementPrototype } from "../../core/element";
import type { IElementVisitor } from "../../core/element/IElementVisitor";
import type { IStrategy } from "../../core/strategies";
import type { IMultiContainerElement } from "../../core/strategies/types";
import type { InputService } from "../../services";
import type { TContainerElements, TElementDeserializerFunction } from "../../types";

import { ContainerModel } from "../../core/container";
import { BaseElementModel } from "../../core/element";

import { DifferentialStrategy } from "./DifferentialStrategy";

export class DifferentialModel extends BaseElementModel<ISerializedDifferential> implements IMultiContainerElement {
    public readonly denominator: ContainerModel;
    public readonly content: ContainerModel;

    public constructor(
        inputService: InputService,
        denominator: TContainerElements,
        content: TContainerElements,
        uuid?: string
    ) {
        super(inputService, uuid);

        this.denominator = new ContainerModel(denominator, this, ["denominator"], inputService, {
            showPlaceholderIfEmpty: true,
        });
        this.content = new ContainerModel(content, this, ["content"], inputService, {
            showPlaceholderIfEmpty: true,
        });
    }

    public serialize(): ISerializedDifferential {
        return {
            type: "differential",
            uuid: this.uuid,
            denominator: this.denominator.serialize(),
            content: this.content.serialize(),
        };
    }

    public static deserialize: TElementDeserializerFunction<ISerializedDifferential> = (
        { inputService },
        { denominator, content, uuid }
    ) =>
        new DifferentialModel(
            inputService,
            inputService.deserializeContainer(denominator),
            inputService.deserializeContainer(content),
            uuid
        );

    protected override initBehaviour(): IStrategy {
        return new DifferentialStrategy(this);
    }

    getContainersToMoveCursorBetween(): ContainerModel[] {
        return [this.denominator, this.content];
    }

    public accept<R>(visitor: IElementVisitor<R>): R {
        return visitor.doWithDifferential(this);
    }

    serializeAsClone(): TSerializedElementPrototype<ISerializedDifferential> {
        const { uuid, ...clone } = this.serialize();
        return {
            ...clone,
            denominator: this.denominator.serializeAsClone(),
            content: this.content.serializeAsClone(),
        };
    }

    override getWrapperContainer(): ContainerModel {
        return this.content;
    }
}
