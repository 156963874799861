import cn from "classnames";
import React from "react";

import type { MultitudeModel } from "./MultitudeModel";
import type { TElementProps } from "../../types/element";

import { useMathEditorElementRef } from "../../hooks";
import { BaseSymbolElement } from "../base/base-symbol";

import { multitudeCharClassNames } from "./MultitudeElement.constants";

import styles from "./MultitudeElement.module.scss";

export const MultitudeElement: React.VFC<TElementProps<MultitudeModel>> = ({ elementModel }) => {
    const className = multitudeCharClassNames[elementModel.symbolName];
    const ref = useMathEditorElementRef(elementModel);

    return (
        <BaseSymbolElement
            ref={ref}
            className={cn(styles.multitude, className)}
        />
    );
};
