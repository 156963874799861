import type { ICondition } from "./ICondition";

export class ConditionConst implements ICondition {
    private readonly value;

    constructor(value: boolean) {
        this.value = value;
    }

    getResult(): boolean {
        return this.value;
    }
}
