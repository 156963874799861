import { observer } from "mobx-react-lite";
import React from "react";

import type { Figure2D } from "../../../entities/Figure2D";
import type { BaseElement } from "../../elements";
import type { EventsController } from "../../events";
import type { FlowManager } from "../../flows";
import type { TPoint } from "@viuch/geometry-lib/types";
import type { KeyboardService } from "@viuch/math-editor";

import { useRenderElement } from "../../elements/useRenderElement";

import styles from "./RenderElements.module.scss";

interface Props {
    events: EventsController;
    figure: Figure2D;
    flows: FlowManager;
    keyboardService?: KeyboardService;
}

export const RenderElements = observer(function RenderElements({ events, keyboardService, figure, flows }: Props) {
    const containerRef = React.useRef<HTMLDivElement>(null);

    const getPoint = React.useCallback((e: React.PointerEvent | React.Touch): TPoint => {
        const { x, y } = { x: e.clientX, y: e.clientY };

        const element = containerRef.current!;
        const { width, height, left, top } = element.getBoundingClientRect();

        return {
            x: (x - left) / width,
            y: 1 - (y - top) / height,
        };
    }, []);

    const handlePointerEvent = React.useCallback(
        (e: React.PointerEvent, element: BaseElement) => {
            const point = getPoint(e);
            events.dispatchElementPointerEvent(e, element, { id: e.pointerId, canvas: point });
        },
        [events, getPoint]
    );

    const { renderHtml, renderSvg } = useRenderElement({ events, handlePointerEvent, keyboardService, figure });

    return (
        <div
            ref={containerRef}
            className={styles.container}
        >
            <svg
                width="100%"
                height="100%"
            >
                {flows.currentFlow.elements.map(renderSvg)}
            </svg>
            {flows.currentFlow.elements.map(renderHtml)}
        </div>
    );
});
