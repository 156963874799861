export function createManagedPromise<T>(): {
    promise: Promise<T>;
    resolve: (result: T) => void;
    reject: (e: Error) => void;
} {
    let resolve: (result: T) => void = null!;
    let reject: (error: unknown) => void = null!;

    const promise = new Promise<T>((_resolve, _reject) => {
        resolve = _resolve;
        reject = _reject;
    });

    return { promise, resolve, reject };
}

export type TPromiseControls<T> = {
    resolve(result: T): void;
    reject(e: unknown): void;
};

type Payload<T> = { payload: T };

export type TPromiseController<TResult> = Promise<TResult> & TPromiseControls<TResult>;
export type TPromiseControllerWithPayload<TResult, TPayload> = //
    Promise<TResult> & TPromiseControls<TResult> & Payload<TPayload>;

export function createPromiseController<TResult>(): TPromiseController<TResult>;
export function createPromiseController<TResult>(): TPromiseController<TResult>;
export function createPromiseController<TResult, TPayload>(
    payload: TPayload
): TPromiseControllerWithPayload<TResult, TPayload>;
export function createPromiseController<TResult, TPayload>(
    payload?: TPayload
): TPromiseControllerWithPayload<TResult, TPayload> {
    const data = createManagedPromise<TResult>();

    const { resolve, reject } = data;
    const promise = data.promise as Promise<TResult> & TPromiseControls<TResult> & Payload<TPayload | undefined>;

    promise.resolve = resolve;
    promise.reject = reject;
    promise.payload = payload;

    return promise as Promise<TResult> & TPromiseControls<TResult> & Payload<TPayload>;
}
