import type { TAffiliationChar } from "../elements/affiliation/types";
import type { IAction, TAnyAction } from "../types";

export interface IAffiliationAction extends IAction<"affiliation"> {
    char: TAffiliationChar;
}

export function createAffiliationAction(char: TAffiliationChar): IAffiliationAction {
    return { type: "affiliation", char };
}

export function isAffiliationAction(action: TAnyAction): action is IAffiliationAction {
    return action.type === "affiliation";
}
