import cn from "classnames";
import { observer } from "mobx-react-lite";
import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { useDisableSafariTouchSelectionFix } from "@viuch/utils/hooks";

import type { KeyboardService } from "../../../../services";
import type { TAnyAction } from "../../../../types";
import type { THoldRegistration, THoldRegistrationEffect, TMathKeyboardButton } from "../types";
import type { CSSProperties } from "react";

import { KeyboardIcon, KeyboardTextIcon } from "../../../keyboard/elements";
import { useProvidedElement } from "../../element-provider";

import { Button } from "./Button";
import { HoldButton } from "./HoldButton";
import { holdMs } from "./MathButton.constants";
import { calculateOffset, isXCoordinateInsideRect } from "./MathButton.utils";

import styles from "./MathButton.module.scss";

type Props = {
    button: TMathKeyboardButton;
    className?: string;
    buttonClassName?: string;
    alternate?: boolean;
    isShiftPressed?: boolean;
    keyboardService: KeyboardService;
    onClick(action: TAnyAction): void;
};

export const MathButton = observer(function MathButton({
    button: { icon, actionCreator, text, children, tags, symbols },
    className,
    buttonClassName,
    alternate,
    isShiftPressed = false,
    keyboardService,
    onClick,
}: Props) {
    const caseTransformer = isShiftPressed ? "toUpperCase" : "toLowerCase";

    const ref = useRef<HTMLDivElement>(null);

    function handleClick(): void {
        onClick?.(actionCreator({ isShiftPressed }));
    }

    function handleButtonClick(): void {
        if (!isHold) {
            handleClick();
        }
    }

    const [isHold, setIsHold] = useState(false);

    const elementsRef = useRef<THoldRegistration[]>([]);

    const registerHold: THoldRegistrationEffect = useCallback((registration) => {
        elementsRef.current.push(registration);

        return () => {
            elementsRef.current = elementsRef.current.filter((r) => r !== registration);
        };
    }, []);

    const { disableSelectAbility, returnSelectAbility } = useDisableSafariTouchSelectionFix();

    useEffect(() => {
        if (!children?.length) {
            return;
        }

        const button = ref.current!;
        const options: AddEventListenerOptions = {
            passive: false,
        };

        button.addEventListener("pointerdown", handlePointerDown, options);
        button.addEventListener("pointermove", handlePointerMove, options);
        button.addEventListener("pointerup", handlePointerUp, options);

        let timer: number | undefined;

        function handlePointerDown(e: PointerEvent): void {
            disableSelectAbility();

            const { target, pointerId } = e;

            if (target instanceof HTMLElement) {
                target.setPointerCapture(pointerId);
            }

            if (!timer) {
                timer = window.setTimeout(() => {
                    if (timer) {
                        setIsHold(true);
                    }
                }, holdMs);
            }
        }

        function handlePointerMove(e: PointerEvent): void {
            const { pageX: x, target, pointerId } = e;

            if (target instanceof HTMLElement) {
                target.setPointerCapture(pointerId);
            }

            elementsRef.current.forEach(({ rect, onEnter, onExit }) => {
                if (isXCoordinateInsideRect(x, rect)) {
                    onEnter();
                } else {
                    onExit();
                }
            });
        }

        function handlePointerUp(e: PointerEvent): void {
            returnSelectAbility();

            const { pageX: x } = e;

            elementsRef.current.forEach(({ rect, onDrop }) => {
                if (isXCoordinateInsideRect(x, rect)) {
                    onDrop();
                    // Prevent call of click handler on inner button.
                    e.stopPropagation();
                }
            });

            if (timer) {
                clearTimeout(timer);
                timer = undefined;
                setIsHold(false);
            }
        }

        return () => {
            if (timer) {
                clearTimeout(timer);
            }

            button.removeEventListener("pointermove", handlePointerMove, options);
            button.removeEventListener("pointerdown", handlePointerDown, options);
            button.removeEventListener("pointerup", handlePointerUp, options);
        };
    }, [children?.length, disableSelectAbility, returnSelectAbility]);

    const parentElement = useProvidedElement();

    const percent: number = useMemo(() => {
        const element = ref.current;
        if (!children?.length || !isHold || !parentElement || !element) {
            return 0;
        }

        return calculateOffset(children, element, parentElement);
    }, [children, isHold, parentElement]);

    const holdContainerStyles = {
        "--offset": `-${percent * 100}%`,
    } as CSSProperties;

    return (
        <div className={cn(className, styles.wrapper, isHold && styles.hold)}>
            {children && isHold && (
                <div className={cn(styles.holdContainerWrapper, styles.hold)}>
                    <div
                        style={holdContainerStyles}
                        className={styles.holdContainer}
                    >
                        {children.map((button, i) => (
                            <Fragment key={i}>
                                <HoldButton
                                    registerHold={registerHold}
                                    button={button}
                                    onClick={onClick}
                                    isShiftPressed={isShiftPressed}
                                />
                                {i < children.length - 1 && <div className={styles.delimiter} />}
                            </Fragment>
                        ))}
                    </div>
                </div>
            )}
            <div
                className={cn(styles.buttonWrapper, {
                    [styles.hold]: isHold,
                    [styles.blurred]: !keyboardService.checkButtonEnabled(tags),
                })}
                ref={ref}
            >
                <Button
                    onClick={handleButtonClick}
                    className={cn(styles.button, buttonClassName)}
                    alternate={alternate}
                >
                    {icon && (
                        <KeyboardIcon
                            iconName={icon}
                            className={styles.icon}
                        />
                    )}
                    {text && <KeyboardTextIcon>{text[caseTransformer]()}</KeyboardTextIcon>}
                    {symbols && <KeyboardTextIcon>{symbols}</KeyboardTextIcon>}
                    {isHold && <div className={styles.buttonHoldFill} />}
                    {children && <div className={styles.childThumb} />}
                </Button>
            </div>
        </div>
    );
});
