import { action, computed, makeObservable, observable } from "mobx";

import { s2m } from "@viuch/math-editor";

import type { TPictureImage } from "../settings-editor/types";
import type { TPictureWatermarkPosition } from "@viuch/instrument-picture/statement";
import type { TSerializedState } from "@viuch/math-editor";

import { MAX_WATERMARKS_COUNT } from "./constants";
import { PictureInstrumentSettingsWatermark } from "./PictureInstrumentSettingsWatermark";

export class PictureInstrumentSettingsImpl {
    @observable image: TPictureImage | null;
    @observable watermarks: PictureInstrumentSettingsWatermark[];
    @observable altText: string;
    @observable.ref title: TSerializedState;

    constructor(
        watermarks: PictureInstrumentSettingsWatermark[],
        image: TPictureImage | null,
        title: TSerializedState,
        altText: string
    ) {
        this.image = image;
        this.watermarks = watermarks;
        this.altText = altText;
        this.title = title;

        makeObservable(this);
    }

    static createEmpty() {
        return new PictureInstrumentSettingsImpl([], null, s2m``, "");
    }

    @computed
    get url(): string {
        return this.image?.url ?? "";
    }

    @action.bound
    setImage(image: TPictureImage) {
        this.image = image;
    }

    clone(): PictureInstrumentSettingsImpl {
        return new PictureInstrumentSettingsImpl(
            this.watermarks.map((wk) => wk.clone()),
            this.image,
            this.title,
            this.altText
        );
    }

    @action.bound
    createWatermark(position: TPictureWatermarkPosition): void {
        if (this.watermarks.length >= MAX_WATERMARKS_COUNT) {
            return;
        }

        this.watermarks.push(PictureInstrumentSettingsWatermark.createEmpty({ position }));
    }

    @action.bound
    removeWatermark(watermark: PictureInstrumentSettingsWatermark): void {
        this.watermarks.remove(watermark);
    }

    @action.bound
    setTitle(title: TSerializedState) {
        this.title = title;
    }

    @action.bound
    setAltText(altText: string) {
        this.altText = altText;
    }
}
