import { action, makeObservable } from "mobx";

import { copyAngle, copyPoint, createAngle, createFragment } from "@viuch/geometry-lib/factories";

import type { BaseElement } from "../../elements";
import type { Figure2DController } from "../../Figure2DController";
import type { TAngle } from "@viuch/geometry-lib/types";

import { FragmentModel } from "../../models/fragment";
import { BisectionModel } from "../../models/geometry";
import { LabelPointModel } from "../../models/label-point";
import { PointModel } from "../../models/point";
import { getNewEqualAnglesSegmentsCount } from "../../utils/strokes-count";
import { BaseFlow } from "../BaseFlow";

export class BisectionFlow extends BaseFlow {
    private readonly angle: TAngle;

    constructor(data: Figure2DController, angle: TAngle) {
        super(data);
        this.angle = copyAngle(angle);

        makeObservable(this, { createBisection: action.bound });
    }

    attach(): void {
        this.createBisection();
        this.nextFlow();
        this.viewport.disable();
    }

    dispose(): void {}

    createBisection() {
        const baseFragment = this.getBaseFragment();
        const vertex = copyPoint(this.angle.vertex);
        const angle = createAngle(baseFragment.a, vertex, baseFragment.b);

        this.figure.insertModels(function* () {
            const model = BisectionModel.create({
                angle,
                segmentsCount: getNewEqualAnglesSegmentsCount(this.figure),
                style: null,
            });
            yield model;

            model.midPoint && (yield LabelPointModel.createNext(model.midPoint, this.figure));

            yield FragmentModel.create({ ...baseFragment, style: null });
            for (const point of [baseFragment.a, baseFragment.b, vertex]) {
                yield PointModel.create({ ...point, style: null });
                yield LabelPointModel.createNext(point, this.figure);
            }
        }, this);
    }

    private getBaseFragment = () => createFragment(this.angle.start, this.angle.end);

    protected renderElements(): BaseElement[] {
        return [];
    }

    static create(data: Figure2DController, angle: TAngle) {
        return new BisectionFlow(data, angle);
    }
}
