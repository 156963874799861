import type { BaseGraphItem } from "../editor/model/BaseGraphItem";
import type { GraphFunctionInterval } from "../editor/model/GraphFunctionInterval";
import type { TFunc } from "@viuch/geometry-lib/transformations/functions";

import { BaseGraphViewItem } from "../editor/rendering/BaseGraphViewItem";
import { zIndexValues } from "../editor/rendering/utils";

export class FunctionIntervalViewItem extends BaseGraphViewItem {
    readonly func: TFunc;
    readonly interval: GraphFunctionInterval;
    color: string;
    width: number;
    puncture: boolean;
    fill: "up" | "down" | null;
    blur = false;

    constructor(
        model: BaseGraphItem,
        func: TFunc,
        interval: GraphFunctionInterval,
        color: string,
        width: number,
        puncture: boolean,
        fill: "up" | "down" | null,
        blur = false
    ) {
        super(model, zIndexValues.path.default);
        this.puncture = puncture;

        this.func = func;
        this.interval = interval;
        this.color = color;
        this.width = width;
        this.fill = fill;
        this.blur = blur;
    }
}
