import type { TAngle, TEllipse, TFragment, TLine, TLineCharacteristic, TPoint } from "./types";

import { getAngle } from "./angles";
import { compareFloats, equateNumbers } from "./equate";
import { createLine } from "./factories";
import { getPointHash } from "./hashing";

export function isPointOnLine({ x1, y1, x2, y2 }: TLine, { x, y }: TPoint): boolean {
    return Math.abs((x - x1) * (y2 - y1) - (x2 - x1) * (y - y1)) < 1e-4;
}

export function isPointInBounds({ x1, y1, x2, y2 }: TLine, { x, y }: TPoint) {
    const i = (x - x1) * (x2 - x);
    const j = (y - y1) * (y2 - y);

    return (
        equateNumbers(0, "<=", i) &&
        equateNumbers(i, "<=", Math.pow(x1 - x2, 2)) &&
        equateNumbers(0, "<=", j) &&
        equateNumbers(j, "<=", Math.pow(y1 - y2, 2))
    );
}

export function isPointOnFragment({ a: { x: x1, y: y1 }, b: { x: x2, y: y2 } }: TFragment, point: TPoint): boolean {
    const line = { x1, y1, x2, y2 };
    return isPointOnLine(line, point) && isPointInBounds(line, point);
}

isPointOnFragment.getPointFilter =
    (point: TPoint, expect = true) =>
    (fragment: TFragment): boolean =>
        isPointOnFragment(fragment, point) === expect;
isPointOnFragment.getFragmentFilter =
    (fragment: TFragment, expect = true) =>
    (point: TPoint) =>
        isPointOnFragment(fragment, point) === expect;

export function isZeroLengthVector({ x1, y1, x2, y2 }: TLine): boolean {
    return compareFloats(x1, x2) === 0 && compareFloats(y1, y2) === 0;
}

export function areSamePoints(a: TPoint, b: TPoint): boolean {
    return compareFloats(a.x, b.x) === 0 && compareFloats(a.y, b.y) === 0;
}

export function areSameEllipses(left: TEllipse, right: TEllipse): boolean {
    return (
        equateNumbers(left.rx, "==", right.rx) &&
        equateNumbers(left.ry, "==", right.ry) &&
        areSamePoints(left.center, right.center)
    );
}

export function isZeroPoint(point: TPoint): boolean {
    return equateNumbers(point.x, "==", 0) && equateNumbers(point.y, "==", 0);
}

export function areSameFragments(left: TFragment, right: TFragment): boolean {
    return (
        (areSamePoints(left.a, right.a) && areSamePoints(left.b, right.b)) ||
        (areSamePoints(left.a, right.b) && areSamePoints(left.b, right.a))
    );
}

export function areSameVectors(left: TFragment, right: TFragment): boolean {
    return areSamePoints(left.a, right.a) && areSamePoints(left.b, right.b);
}

export function areEqualAngles(left: TAngle, right: TAngle): boolean {
    return (
        areSamePoints(left.vertex, right.vertex) &&
        areSamePoints(left.start, right.start) &&
        areSamePoints(left.end, right.end)
    );
}

export function areSameAngles(left: TAngle, right: TAngle): boolean {
    return (
        areSamePoints(left.vertex, right.vertex) &&
        ((areSamePoints(left.start, right.start) && areSamePoints(left.end, right.end)) ||
            (areSamePoints(left.start, right.end) && areSamePoints(left.end, right.start)))
    );
}

export function hasMatchInPointSet(set: TPoint[], point: TPoint) {
    for (const p of set) {
        if (areSamePoints(point, p)) {
            return true;
        }
    }
    return false;
}

export function areEqualPointsSets(...pointsPairs: Array<[TPoint, TPoint]>): boolean {
    const set = pointsPairs.map(([first]) => getPointHash(first));

    for (const [, second] of pointsPairs) {
        const i = set.indexOf(getPointHash(second));
        if (i === -1) return false;
        set.splice(i, 1);
    }
    return set.length === 0;
}

export function areLineCharacteristicsEqual(left: TLineCharacteristic, right: TLineCharacteristic): boolean {
    return (
        equateNumbers(left.angle, "==", right.angle) &&
        equateNumbers(left.normalVectorSquaredLength, "==", right.normalVectorSquaredLength)
    );
}

export function isLineVertical(line: TLine): boolean {
    return equateNumbers(line.x1, "==", line.x2);
}

export function findFragmentsOnLine(fragments: TLine[], line: TLine): { matched: TLine[]; notMatched: TLine[] } {
    const match: TLine[] = [];
    const notMatch: TLine[] = [];
    for (const frag of fragments) {
        const { x1, y1, x2, y2 } = frag;
        if (isPointOnLine(line, { x: x1, y: y1 }) && isPointOnLine(line, { x: x2, y: y2 })) {
            match.push(frag);
        } else {
            notMatch.push(frag);
        }
    }
    return { matched: match, notMatched: notMatch };
}

export function findPerpendicularPoint(line: TLine, point: TPoint): TPoint;
export function findPerpendicularPoint(fragment: TFragment, point: TPoint): TPoint;
export function findPerpendicularPoint(line: TLine | TFragment, point: TPoint): TPoint {
    const _line = "x1" in line && "x2" in line ? line : createLine(line);

    const { x1, y1, x2, y2 } = _line;
    const { x: x3, y: y3 } = point;

    const x = y2 - y1;
    const y = x1 - x2;
    const L = (x1 * y2 - x2 * y1 + y1 * x3 - y2 * x3 + x2 * y3 - x1 * y3) / (x * (y2 - y1) + y * (x1 - x2));

    return {
        x: x3 + x * L,
        y: y3 + y * L,
    };
}

export function getLinePointY(line: TLine, x: number): TPoint | null {
    const { x1, y1, x2, y2 } = line;
    if (compareFloats(y1, y2) === 0) return { x, y: y1 };
    if (compareFloats(x1, x2) === 0) return null;

    const k = (x2 * y1 - y2 * x1) / (y2 - y1);
    const y = ((y2 - y1) / (x2 - x1)) * (x + k);
    return { x, y };
}

export function getLinePointX(line: TLine, y: number): TPoint | null {
    const { x1, y1, x2, y2 } = line;
    if (compareFloats(x1, x2) === 0) return { x: x1, y };
    if (compareFloats(y1, y2) === 0) return null;

    const k = (y2 * x1 - x2 * y1) / (x2 - x1);
    const x = ((x2 - x1) / (y2 - y1)) * (y + k);
    return { x, y };
}

export function isRightAngle(angle: TAngle) {
    const angleRad = getAngle(angle);

    const isRightAngle = compareFloats(Math.abs(angleRad), Math.PI / 2) === 0;

    return isRightAngle;
}
