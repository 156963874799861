import type { TInterval, TIntervalPoint, TIntervalWithLimits, TTransformerObject } from "./transformer";
import type { TPoint } from "../types";

export type TFunc<A extends unknown[] = [x: number], R = number> = (...args: A) => R;
export type TFuncFunc<A extends unknown[] = [x: number], R = number> = (f: TFunc<A, R>, ...args: A) => R;

export function wrapFunc<A extends unknown[], R>(func: TFunc<A, R>, wrapper: TFuncFunc<A, R>): TFunc<A, R> {
    return (...args) => wrapper(func, ...args);
}

export function wrapFuncMulti<A extends unknown[], R>(func: TFunc<A, R>, wrappers: TFuncFunc<A, R>[]): TFunc<A, R> {
    return wrappers.reduce(wrapFunc, func);
}

export function buildFunc(func: TFunc, ...pipeline: TFuncFunc[]): TFunc {
    return wrapFuncMulti(func, pipeline);
}

export function transformInterval({ start, end }: TInterval, updateX: TFunc): TInterval {
    return {
        start: transformIntervalPoint(start, updateX),
        end: transformIntervalPoint(end, updateX),
    };
}

function transformIntervalPoint(interval: TIntervalPoint, updateX: TFunc): TIntervalPoint {
    return { include: interval.include, value: updateX(interval.value) };
}

export function transformPoint(point: TPoint, transformations: TTransformerObject[]): TPoint {
    return transformations.reduce(({ x, y }, t) => ({ x: t.transformXValue(x), y: t.transformYValue(y) }), point);
}

export function transformX(x: number, transformations: TTransformerObject[]): number {
    return transformations.reduce((x, t) => t.transformXValue(x), x);
}

export function transformY(y: number, transformations: TTransformerObject[]): number {
    return transformations.reduce((y, t) => t.transformYValue(y), y);
}

export function simpleTransformInterval(
    interval: TIntervalWithLimits,
    transformations: TTransformerObject[]
): TIntervalWithLimits {
    const { start, end } = interval;

    const { x: startX, y: startY } = transformPoint({ x: start.value, y: start.limit }, transformations);
    const { x: endX, y: endY } = transformPoint({ x: end.value, y: end.limit }, transformations);

    return {
        start: { ...start, value: startX, limit: startY },
        end: { ...end, value: endX, limit: endY },
    };
}
