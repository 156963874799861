import type { TAnyAction } from "../types";

export type TGradeAction = { type: "grade" };

export function createGradeAction(): TGradeAction {
    return { type: "grade" };
}

export function isGradeAction(action: TAnyAction): action is TGradeAction {
    return action.type === "grade";
}
