import type { TFormControl } from "@viuch/form-builder/types/controls";

import { createLabel } from "../controls/label";

export const parametricPerSolver: TFormControl = {
    control: "Form",
    layout: "column",
    condition: {
        "./solver_type": ["parametric-equation-per-root"],
    },
    controls: [
        createLabel("Находит значения параметра, при которых уравнение имеет указанный корень."),
        {
            path: "./equation",
            control: "Math",
            label: "Уравнение",
        },
        {
            path: "./variable",
            control: "Math",
            label: "Переменная",
        },
        {
            path: "./root",
            control: "Math",
            label: "Корень",
        },
        {
            path: "./parameter",
            control: "Math",
            label: "Параметр",
        },
    ],
};

export const parametricPerRootCount: TFormControl = {
    control: "Form",
    layout: "column",
    condition: {
        "./solver_type": ["parametric-equation-per-count"],
    },
    controls: [
        createLabel("Находит значения параметра, при которых уравнение имеет указанное количество корней"),
        {
            path: "./equation",
            control: "Math",
            label: "Уравнение",
        },
        {
            path: "./variable",
            control: "Math",
            label: "Переменная",
        },
        {
            path: "./parameter",
            control: "Math",
            label: "Параметр",
        },
        {
            path: "./root_count",
            control: "Select",
            label: "Количество корней",
            initialValue: "zero",
            data: {
                type: "static-items",
                items: [
                    { value: "zero", label: "Нет корней" },
                    { value: "one", label: "1 корень" },
                    { value: "two", label: "2 корня" },
                    { value: "infinity", label: "Бесконечное количество" },
                ],
            },
        },
    ],
};

export const parametricRootCount: TFormControl = {
    control: "Form",
    layout: "column",
    condition: {
        "./solver_type": ["parametric-equation-count"],
    },
    controls: [
        createLabel("Находит значения параметра, при которых уравнение имеет указанное количество корней"),
        {
            path: "./equation",
            control: "Math",
            label: "Уравнение",
        },
        {
            path: "./variable",
            control: "Math",
            label: "Переменная",
        },
        {
            path: "./parameter",
            control: "Math",
            label: "Параметр",
        },
    ],
};
