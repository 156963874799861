import type { TColorEntry, TCustomColorConfig } from "./types";
import type { InlineTool, API, ToolConfig } from "@editorjs/editorjs";

import styles from "./CustomColor.module.scss";

export class CustomColor implements InlineTool {
    shortcut = void 0;

    static get isInline() {
        return true;
    }

    private readonly api: API;
    private readonly tag = "SPAN";
    private readonly className = "cdx-plugin-custom-color";
    private readonly colors: TColorEntry[];
    private controls?: {
        wrapper: HTMLDivElement;
        buttons: HTMLButtonElement[];
    };
    private button?: HTMLButtonElement;
    private _state = false;

    constructor({ api, config }: { api: API; config?: ToolConfig<TCustomColorConfig> }) {
        if (!config || !config.colors) {
            throw new Error("Config with colors required");
        }
        this.api = api;
        this.colors = config.colors;
    }

    surround(range: Range): void {
        if (this.state) {
            this.unwrap(range);
        } else {
            this.wrap(range);
        }
    }

    private wrap(range: Range) {
        const selectedText = range.extractContents();
        const span = document.createElement(this.tag);

        span.classList.add(this.className);
        span.appendChild(selectedText);
        range.insertNode(span);

        this.api.selection.expandToTag(span);
    }

    private unwrap(range: Range) {
        const mark = this.api.selection.findParentTag(this.tag, this.className);
        const text = range.extractContents();

        mark?.remove();

        range.insertNode(text);
    }

    checkState(selection: Selection): boolean {
        const element = this.api.selection.findParentTag(this.tag, this.className);

        if (element) {
            this.state = true;
            this.showActions(element);
            return true;
        } else {
            this.state = false;
            this.hideActions();
            return false;
        }
    }

    private showActions(element: HTMLElement) {
        if (!this.controls) return;
        const { wrapper, buttons } = this.controls;

        const currentColor = this.getValue(element);

        buttons.forEach((button) => {
            button.onclick = () => {
                const newColor = button.getAttribute("data-color");
                this.setValue(element, newColor);
            };
        });

        wrapper.hidden = false;
    }

    private getValue(element: HTMLElement): string | null {
        const color = element.dataset.color;

        return color || null;
    }

    private setValue(element: HTMLElement, color: string | null) {
        if (color) {
            element.setAttribute("data-color", color);
            element.style.setProperty("color", color);
        } else {
            element.removeAttribute("data-color");
            element.style.removeProperty("color");
        }
    }

    private hideActions() {
        if (!this.controls) return;

        const { wrapper } = this.controls;
        wrapper.hidden = true;
        //
    }

    renderActions(): HTMLElement {
        const wrapper = document.createElement("div");
        wrapper.hidden = true;
        wrapper.style.position = "relative";
        wrapper.classList.add(styles.customColorTool);

        const colorButtonsHtml = this.colors.map((colorData) => {
            const { cssValue } = colorData;
            return `<button data-color="${cssValue}" style="--color: ${cssValue}"></button>`;
        });

        const buttonsListHtml = `
            <div>
                <button data-color=""></button>
                ${colorButtonsHtml.join("")}
            </div>
        `;

        wrapper.insertAdjacentHTML("beforeend", buttonsListHtml);
        const buttons = Array.from(wrapper.children.item(0)!.children) as HTMLButtonElement[];

        this.controls = { wrapper, buttons };
        return wrapper;
    }

    clear(): void {
        // do nothing
    }

    render(): HTMLElement {
        this.button = document.createElement("button");
        this.button.type = "button";
        this.button.innerHTML =
            '<svg width="20" height="18"><path d="M10.458 12.04l2.919 1.686-.781 1.417-.984-.03-.974 1.687H8.674l1.49-2.583-.508-.775.802-1.401zm.546-.952l3.624-6.327a1.597 1.597 0 0 1 2.182-.59 1.632 1.632 0 0 1 .615 2.201l-3.519 6.391-2.902-1.675zm-7.73 3.467h3.465a1.123 1.123 0 1 1 0 2.247H3.273a1.123 1.123 0 1 1 0-2.247z"/></svg>';
        this.button.classList.add(this.api.styles.inlineToolButton);

        return this.button;
    }

    get state(): boolean {
        return this._state;
    }

    set state(state: boolean) {
        this._state = state;

        if (state) {
            this.button?.classList.add(this.api.styles.inlineToolButtonActive);
        } else {
            this.button?.classList.remove(this.api.styles.inlineToolButtonActive);
        }
    }

    static get sanitize() {
        return {
            span: { "data-color": true },
        };
    }
}
