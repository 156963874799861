import classNames from "classnames";
import { observer } from "mobx-react-lite";

import type { ChangeEvent } from "react";

import styles from "./TextAreaInput.module.scss";

type Props = {
    value: string;
    onChange: (value: string) => void;
    className?: string;
    rows?: number;
};

export const TextAreaInput = observer(function TextAreaInput({ value, onChange, className, rows }: Props) {
    const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        onChange(e.target.value);
    };

    return (
        <textarea
            rows={rows}
            className={classNames(styles.textarea, className)}
            value={value}
            onChange={handleChange}
        />
    );
});
