import { action, makeObservable, observable } from "mobx";

import type { IGraph1DInstrumentStatement } from "./types";

import { Graph1DInstrumentStatementDot } from "./Graph1DInstrumentStatementDot";
import { Graph1DInstrumentStatementStrokesInterval } from "./Graph1DInstrumentStatementInterval";

export class Graph1DInstrumentStatement implements IGraph1DInstrumentStatement {
    @observable.ref axis: string;
    @observable.shallow dots: Graph1DInstrumentStatementDot[];
    @observable.shallow intervals: Graph1DInstrumentStatementStrokesInterval[];

    constructor(
        axis: string,
        dots: Graph1DInstrumentStatementDot[],
        intervals: Graph1DInstrumentStatementStrokesInterval[]
    ) {
        this.axis = axis;
        this.dots = dots.slice();
        this.intervals = intervals.slice();

        makeObservable(this);
    }

    static CreateEmpty(): Graph1DInstrumentStatement {
        return Graph1DInstrumentStatement.FromData({
            axis: "x",
            dots: [],
            intervals: [],
        });
    }

    static FromData(graph: IGraph1DInstrumentStatement) {
        return new Graph1DInstrumentStatement(
            graph.axis,
            graph.dots.map((data) => new Graph1DInstrumentStatementDot(data)),
            graph.intervals?.map(
                (d) => new Graph1DInstrumentStatementStrokesInterval(d.color, d.left, d.right, d.side, d.direction)
            ) ?? []
        );
    }

    clone(): Graph1DInstrumentStatement {
        return Graph1DInstrumentStatement.FromData(this);
    }

    hasDotOnPosition(position: number): boolean {
        return this.dots.some((dot) => dot.position === position);
    }

    @action.bound
    setAxis(axis: string) {
        this.axis = axis;
    }

    @action.bound
    removeDot(dot: Graph1DInstrumentStatementDot) {
        this.dots.remove(dot);
    }
}
