import { makeObservable, observable } from "mobx";
import { createTransformer } from "mobx-utils";

import { areSamePoints } from "@viuch/geometry-lib/check-geometry";
import { createRhomb } from "@viuch/geometry-lib/factories";
import { reflectPoint } from "@viuch/geometry-lib/solvers";
import { createFragmentsFromPoints } from "@viuch/geometry-lib/transform";

import type { BaseElement } from "../../elements";
import type { Figure2DController } from "../../Figure2DController";
import type { ToolbarMenu } from "../../toolbar";
import type { IUserPointer } from "../../viewport/types";
import type { TPoint } from "@viuch/geometry-lib/types";
import type React from "react";

import { DotElement } from "../../elements/dot";
import { ElementColor } from "../../elements/ElementColor";
import { LineElement } from "../../elements/line";
import { FragmentModel } from "../../models/fragment";
import { LabelPointModel } from "../../models/label-point";
import { PointModel } from "../../models/point";
import { ButtonStates } from "../../toolbar";
import { TooltipMenu } from "../../toolbar/tooltip";
import { createDefaultToolbarMenuBuilder } from "../../utils/toolbar";
import { BaseFlow } from "../BaseFlow";
import { createMapModelToElements, opacifyColor } from "../utils";

export class RhombFlow extends BaseFlow {
    flow:
        | { stage: 1; drawing: boolean; center?: TPoint }
        | { stage: 2; drawing: boolean; center: TPoint; radiusPoint?: TPoint };

    constructor(data: Figure2DController) {
        super(data);

        this.flow = { drawing: false, stage: 1 };

        makeObservable(this, {
            flow: observable,
        });
    }

    static create(data: Figure2DController) {
        return new RhombFlow(data);
    }

    attach() {
        this.snap.addViewportGrid().addFigureModelsPoints();
        this.viewport.disable();
    }

    dispose() {
        this.snap.clean();
    }

    protected renderElements(): BaseElement[] {
        return [...this.figure.models.flatMap(this.render), ...this.tempElements];
    }

    private render = createTransformer(createMapModelToElements(opacifyColor));

    get tempElements(): BaseElement[] {
        const { stage, drawing, center } = this.flow;
        const currentColor = drawing ? ElementColor.Selected : ElementColor.Building;
        const overrideRenderOrder = 0;

        if (stage === 1 || !this.flow.radiusPoint) {
            if (!center) return [];
            const { scale } = this.viewport;
            const size = { x: 0.04 / scale, y: 0.06 / scale };

            const a = { x: center.x - size.x, y: center.y };
            const b = { x: center.x, y: center.y + size.y };
            const c = { x: center.x + size.x, y: center.y };
            const d = { x: center.x, y: center.y - size.y };

            return [
                new DotElement({
                    id: `temp_center_point`,
                    x: center.x,
                    y: center.y,
                    overrideRenderOrder,
                    color: currentColor,
                }),
                ...[
                    [a, b],
                    [b, c],
                    [c, d],
                    [d, a],
                ].map(
                    ([a, b], i) =>
                        new LineElement({
                            id: `temp_center_line${i}`,
                            x1: a.x,
                            y1: a.y,
                            x2: b.x,
                            y2: b.y,
                            thin: true,
                            overrideRenderOrder,
                            color: ElementColor.Util,
                        })
                ),
            ];
        }

        const { radiusPoint } = this.flow;

        const { top, right, bottom, left } = createRhomb(center, radiusPoint);
        const points = [top, right, bottom, left];
        const reflectedRadiusPoint = reflectPoint(center, radiusPoint);

        return [
            ...points.flatMap<BaseElement>((point, i) => [
                new DotElement({
                    id: `temp_point_${i}`,
                    x: point.x,
                    y: point.y,
                    color: currentColor,
                    overrideRenderOrder,
                }),
            ]),
            ...createFragmentsFromPoints(points, true).map<BaseElement>(
                ({ a, b }, i) =>
                    new LineElement({
                        id: `temp_line_${i}`,
                        x1: a.x,
                        y1: a.y,
                        x2: b.x,
                        y2: b.y,
                        overrideRenderOrder,
                        color: currentColor,
                        thin: false,
                    })
            ),
            new DotElement({
                id: `temp_radiusPoint`,
                x: radiusPoint.x,
                y: radiusPoint.y,
                overrideRenderOrder,
                color: currentColor,
            }),
            new LineElement({
                id: `temp_line_construction_x`,
                x1: radiusPoint.x,
                y1: radiusPoint.y,
                x2: reflectedRadiusPoint.x,
                y2: radiusPoint.y,
                thin: true,
                color: ElementColor.Util,
                overrideRenderOrder,
            }),
            new LineElement({
                id: `temp_line_construction_y`,
                x1: radiusPoint.x,
                y1: radiusPoint.y,
                x2: radiusPoint.x,
                y2: reflectedRadiusPoint.y,
                thin: true,
                color: ElementColor.Util,
                overrideRenderOrder,
            }),
        ];
    }

    override handleViewportPointerEvent(e: React.PointerEvent, pointer: IUserPointer): void {
        if (e.type === "pointercancel") return this.nextFlow();
        if (!e.isPrimary) return;

        const point = this.snap.snap(pointer.canvas, pointer.canvas);

        this.moveCurrentPoint(e, point);
        this.startDrawing(e);
        this.endDrawing(e, point);
    }

    private moveCurrentPoint(e: React.PointerEvent, point: TPoint) {
        if (e.type === "pointermove" || e.type === "pointerdown") {
            switch (this.flow.stage) {
                case 1:
                    this.flow.center = point;
                    break;
                case 2:
                    this.flow.radiusPoint = areSamePoints(point, this.flow.center) ? void 0 : point;
                    break;
            }
        }
    }

    private startDrawing(e: React.PointerEvent) {
        if (e.type === "pointerdown") {
            this.flow.drawing = true;
        }
    }

    private endDrawing(e: React.PointerEvent, point: TPoint) {
        if (e.type === "pointerup") {
            switch (this.flow.stage) {
                case 1:
                    this.flow = {
                        stage: 2,
                        center: this.flow.center || point,
                        drawing: false,
                    };
                    break;
                case 2:
                    this.flow.drawing = false;
                    this.checkAndSave();
                    break;
            }
        }
    }

    private checkAndSave() {
        if (this.flow.stage !== 2 || !this.flow.radiusPoint) return;

        const { radiusPoint, center } = this.flow;
        const { left, top, right, bottom } = createRhomb(center, radiusPoint);

        const points = [left, top, right, bottom];
        const fragments = createFragmentsFromPoints(points, true);

        this.figure.insertModels(function* () {
            for (const point of points) {
                yield PointModel.create({ ...point, style: null });
                yield LabelPointModel.createNext(point, this.figure);
            }
            for (const fragment of fragments) {
                yield FragmentModel.create({ ...fragment, style: null });
            }
        }, this);

        this.nextFlow();
    }

    override getToolbarMenu(): ToolbarMenu {
        return createDefaultToolbarMenuBuilder()
            .setButtonState(ButtonStates.active, ["squares"])
            .setButtonIcon("rhombus", ["squares"])
            .build();
    }

    override getTooltipMenu(): TooltipMenu | null {
        let text: string;

        switch (this.flow.stage) {
            case 1:
                text = "Выберите центр ромба";
                break;
            case 2:
                text = "Выберите размер ромба";
                break;
        }

        return new TooltipMenu(text);
    }
}
