import type React from "react";

export function isRightMouseEvent(e: React.PointerEvent): boolean {
    return e.pointerType === "mouse" && e.button === 2;
}

export function isLeftMouseEvent(e: React.PointerEvent): boolean {
    return e.pointerType === "mouse" && e.button === 0;
}

export function isTouchPointerEvent(e: React.PointerEvent): boolean {
    return e.pointerType !== "mouse";
}
