import { action, computed, makeObservable, observable } from "mobx";
import { createTransformer } from "mobx-utils";

import { getLineAngle, getUnitVectorFromAngle } from "@viuch/geometry-lib/angles";
import { areSamePoints } from "@viuch/geometry-lib/check-geometry";
import { copyPoint, createLine } from "@viuch/geometry-lib/factories";
import { getLineFromFragment } from "@viuch/geometry-lib/solvers";
import { addVectors, middlePoint } from "@viuch/geometry-lib/vectors";

import type { BaseElement } from "../../elements";
import type { Figure2DController } from "../../Figure2DController";
import type { ToolbarMenu } from "../../toolbar";
import type { TToolbarIcons } from "../../toolbar/icons";
import type { IUserPointer } from "../../viewport/types";
import type { TFragment, TPoint } from "@viuch/geometry-lib/types";
import type React from "react";

import { DotElement } from "../../elements/dot";
import { ElementColor } from "../../elements/ElementColor";
import { LineElement } from "../../elements/line";
import { ConstraintLineModel } from "../../models/fragment";
import { LabelPointModel } from "../../models/label-point";
import { PointModel } from "../../models/point";
import { ButtonStates } from "../../toolbar";
import { TooltipMenu } from "../../toolbar/tooltip";
import { createDefaultToolbarMenuBuilder } from "../../utils/toolbar";
import { BaseFlow } from "../BaseFlow";
import { createMapModelToElements, opacifyColor } from "../utils";

export class ConstraintLineFlow extends BaseFlow {
    readonly offsetAngle: number;
    flow?:
        | {
              stage: 1;
              drawing: boolean;
              baseA: TPoint;
          }
        | {
              stage: 2;
              drawing: boolean;
              readonly baseA: TPoint;
              baseB: TPoint;
          }
        | {
              stage: 3;
              drawing: boolean;
              readonly baseA: TPoint;
              readonly baseB: TPoint;
              vertex: TPoint;
          };

    constructor(data: Figure2DController, angle: number, fragment?: TFragment, point?: TPoint) {
        super(data);
        this.offsetAngle = angle;

        if (fragment) {
            const vertex = copyPoint(point || middlePoint(fragment.a, fragment.b));
            this.flow = {
                stage: 3,
                vertex,
                drawing: !point,
                baseA: copyPoint(fragment.a),
                baseB: copyPoint(fragment.b),
            };
        }

        makeObservable(this, {
            flow: observable,
            tempElements: computed,
            saveResult: action,
        });
    }

    attach(): void {
        this.viewport.disable();
        this.snap.addViewportGrid().addFigureModelsPoints();

        if (this.flow?.stage === 3) {
            Promise.resolve().then(() => this.saveResult());
        }
    }

    dispose(): void {
        this.snap.clean();
    }

    override handleViewportPointerEvent(e: React.PointerEvent, pointer: IUserPointer): void {
        const point = this.snap.snap(pointer.canvas, pointer.canvas);

        this.updatePoint(e, point);
        this.startNewDrawings(e, point);
        this.switchToNextStages(e);
    }

    private switchToNextStages(e: React.PointerEvent) {
        if (!this.flow) return;

        if (e.type === "pointerup") {
            switch (this.flow.stage) {
                case 1: {
                    this.flow = {
                        ...this.flow,
                        stage: 2,
                        baseB: this.flow.baseA,
                        drawing: false,
                    };
                    break;
                }
                case 2: {
                    if (areSamePoints(this.flow.baseA, this.flow.baseB)) {
                        return this.nextFlow();
                    }
                    this.flow = {
                        ...this.flow,
                        stage: 3,
                        vertex: this.flow.baseB,
                        drawing: false,
                    };
                    break;
                }
                case 3: {
                    this.flow.drawing = false;
                    this.saveResult();
                }
            }
        }
    }

    saveResult() {
        if (this.flow?.stage !== 3 || this.flow.drawing) return;

        const { vertex, baseA, baseB } = this.flow;

        this.figure.insertModels(function* () {
            yield ConstraintLineModel.create({
                offsetAngle: this.offsetAngle,
                vertex,
                constraintA: baseA,
                constraintB: baseB,
            });
            yield PointModel.create({ ...vertex, style: null });
            yield PointModel.create({ ...baseA, style: null });
            yield PointModel.create({ ...baseB, style: null });

            yield LabelPointModel.createNext(vertex, this.figure);
            yield LabelPointModel.createNext(baseA, this.figure);
            yield LabelPointModel.createNext(baseB, this.figure);
        }, this);

        this.nextFlow();
    }

    private startNewDrawings(e: React.PointerEvent, point: TPoint) {
        if (e.type === "pointerdown") {
            if (!this.flow) {
                this.flow = {
                    stage: 1,
                    drawing: true,
                    baseA: point,
                };
            } else {
                this.flow.drawing = true;
            }
        }
    }

    private updatePoint(e: React.PointerEvent, point: TPoint) {
        if (e.type === "pointerdown" || e.type === "pointermove") {
            if (!this.flow) {
                this.flow = {
                    stage: 1,
                    baseA: point,
                    drawing: e.type === "pointerdown",
                };
            } else {
                switch (this.flow.stage) {
                    case 1:
                        this.flow.baseA = point;
                        break;
                    case 2:
                        this.flow.baseB = point;
                        break;
                    case 3:
                        this.flow.vertex = point;
                        break;
                }
            }
        }
    }

    private mapModels = createTransformer(createMapModelToElements(opacifyColor));

    protected renderElements(): BaseElement[] {
        return [...this.figure.models.flatMap(this.mapModels), ...this.tempElements];
    }

    get tempElements(): BaseElement[] {
        const { flow } = this;
        if (!flow) return [];

        const { baseA, drawing } = flow;
        const color = drawing ? ElementColor.Selected : ElementColor.Building;

        if (flow.stage === 1) {
            return [
                new DotElement({
                    id: "temp__baseA",
                    x: baseA.x,
                    y: baseA.y,
                    color,
                    overrideRenderOrder: 0,
                }),
            ];
        }

        if (flow.stage === 2) {
            const { baseB } = flow;
            const elements: BaseElement[] = [
                new DotElement({
                    id: "temp__baseA",
                    x: baseA.x,
                    y: baseA.y,
                    color: ElementColor.Building,
                    overrideRenderOrder: 0,
                }),
                new DotElement({
                    id: "temp__baseB",
                    x: baseB.x,
                    y: baseB.y,
                    color,
                    overrideRenderOrder: 0,
                }),
            ];
            if (!areSamePoints(baseA, baseB)) {
                const { a, b } = getLineFromFragment(baseA, baseB);
                elements.push(
                    new LineElement({
                        id: "temp__baseLine",
                        x1: a.x,
                        y1: a.y,
                        x2: b.x,
                        y2: b.y,
                        color: ElementColor.Building,
                        overrideRenderOrder: 0,
                    })
                );
            }
            return elements;
        }

        if (flow.stage === 3) {
            const { baseB, vertex } = flow;
            const { a, b } = getLineFromFragment(baseA, baseB);

            const baseAngle = getLineAngle(createLine(baseA, baseB), true);
            const vector = getUnitVectorFromAngle(this.offsetAngle + baseAngle);
            const { a: c, b: d } = getLineFromFragment(vertex, addVectors(vertex, vector));

            return [
                new DotElement({
                    id: "temp__baseA",
                    x: baseA.x,
                    y: baseA.y,
                    color: ElementColor.Building,
                    overrideRenderOrder: 0,
                }),
                new DotElement({
                    id: "temp__baseB",
                    x: baseB.x,
                    y: baseB.y,
                    color: ElementColor.Building.withOpacity(0.5),
                    overrideRenderOrder: 0,
                }),
                new LineElement({
                    id: "temp__baseLine",
                    x1: a.x,
                    y1: a.y,
                    x2: b.x,
                    y2: b.y,
                    color: ElementColor.Building,
                    overrideRenderOrder: 0,
                }),
                new DotElement({
                    id: "temp__vertex",
                    color,
                    x: vertex.x,
                    y: vertex.y,
                    overrideRenderOrder: 0,
                }),
                new LineElement({
                    id: "temp__line",
                    x1: c.x,
                    y1: c.y,
                    x2: d.x,
                    y2: d.y,
                    color,
                    overrideRenderOrder: 0,
                }),
            ];
        }
        return [];
    }

    override getTooltipMenu(): TooltipMenu | null {
        if (!this.flow) return null;

        const isParallel = this.offsetAngle === 0;
        let text: string;

        switch (this.flow.stage) {
            case 1:
                text = `Выберите первую точку прямой, относительно которой хотите построить ${
                    isParallel ? "параллельную" : "перпендикулярную"
                } прямую`;
                break;
            case 2:
                text = `Выберите вторую точку прямой, относительно которой хотите построить ${
                    isParallel ? "параллельную" : "перпендикулярную"
                } прямую`;
                break;
            case 3:
                text = `Выберите точку, через которую будет проходить ${
                    isParallel ? "параллельная" : "перпендикулярная"
                } прямая`;
                break;
        }

        return new TooltipMenu(text, []);
    }

    override getToolbarMenu(): ToolbarMenu {
        const icon: TToolbarIcons = this.offsetAngle === 0 ? "parallel" : "perpendicular";

        return createDefaultToolbarMenuBuilder()
            .setButtonState(ButtonStates.active, ["fragments"])
            .setButtonIcon(icon, ["fragments"])
            .build();
    }
}
