import { action, makeObservable, observable } from "mobx";

import type { IMultichoiceInstrumentSettingsFieldVisitor } from "../BaseMultichoiceInstrumentSettingsField";
import type { TPictureImage } from "../types";
import type { TSerializedState } from "@viuch/math-editor";

import { BaseMultichoiceInstrumentSettingsField } from "../BaseMultichoiceInstrumentSettingsField";

export class ImageMultichoiceInstrumentSettingsField extends BaseMultichoiceInstrumentSettingsField {
    @observable.ref image: TPictureImage | null;
    @observable text: string;

    constructor(image: TPictureImage | null, text: string, checked: boolean, mistakeHint: TSerializedState | null) {
        super(checked, mistakeHint);

        this.image = image;
        this.text = text;

        makeObservable(this);
    }

    static createEmpty(): ImageMultichoiceInstrumentSettingsField {
        return new ImageMultichoiceInstrumentSettingsField(null, "", false, null);
    }

    clone(): ImageMultichoiceInstrumentSettingsField {
        return new ImageMultichoiceInstrumentSettingsField(
            this.image ? { ...this.image } : null,
            this.text,
            this.checked,
            this.mistakeHint
        );
    }

    accept<R>(visitor: IMultichoiceInstrumentSettingsFieldVisitor<R>): R {
        return visitor.withImage(this);
    }

    @action.bound
    setText(text: string) {
        this.text = text;
    }

    @action.bound
    setPicture(image: TPictureImage) {
        this.image = image;
    }
}
