import { makeObservable, observable } from "mobx";

import { createSerializedContainer } from "@viuch/math-editor";

import type { IVariableContentVisitor } from "./IVariableContentVisitor";
import type { TSerializedState } from "@viuch/math-editor/types";

import { BaseVariableContent } from "./BaseVariableContent";

export interface IIntervalVariableContent {
    left_range: TSerializedState;
    right_range: TSerializedState;
}

export class IntervalVariableContent extends BaseVariableContent implements IIntervalVariableContent {
    left_range: TSerializedState;
    right_range: TSerializedState;

    constructor(data: IIntervalVariableContent) {
        super();

        this.left_range = data.left_range;
        this.right_range = data.right_range;

        makeObservable(this, {
            left_range: observable,
            right_range: observable,
        });
    }

    accept<R>(visitor: IVariableContentVisitor<R>): R {
        return visitor.doWithInterval(this);
    }

    static createEmpty() {
        return new IntervalVariableContent({
            right_range: createSerializedContainer(),
            left_range: createSerializedContainer(),
        });
    }

    clone(): IntervalVariableContent {
        return new IntervalVariableContent(this);
    }
}
