import { numberBetweenMinMax } from "@viuch/shared/utils/data/min-max";

import type { TRect } from "../../../../types";
import type { TMathKeyboardButton } from "../types";

export function calculateOffset(
    children: TMathKeyboardButton[],
    element: HTMLElement,
    parentElement: HTMLElement
): number {
    const length = children.length;

    const elementRect = element.getBoundingClientRect();
    const elementX = elementRect.x + elementRect.width / 2;

    const parentRect = parentElement.getBoundingClientRect();
    const floatPercent = (elementX - parentRect.x) / parentRect.width;
    const area = Math.floor(numberBetweenMinMax(floatPercent * length, 0, length - 1));

    let percent = area / length;
    percent += percent / length / 2;

    return percent;
}

export function isXCoordinateInsideRect(x: number, rect: TRect): boolean {
    return x >= rect.x && x <= rect.x + rect.w;
}
