import type { ILimit } from "./ILimit";
import type { InputService } from "../../services";
import type { ContainerModel } from "../container";
import type { BaseElementModel } from "../element";

export class LimitsStore {
    private readonly inputService: InputService;
    private readonly limits: ILimit[];

    public constructor(inputService: InputService) {
        this.inputService = inputService;
        this.limits = [];
    }

    public add(limit: ILimit): this {
        this.limits.push(limit);
        return this;
    }

    public checkElementCanBeInserted(
        element: BaseElementModel,
        container: ContainerModel,
        indexToInsert: number
    ): boolean {
        let resultElements = [element];
        for (const limit of this.limits) {
            resultElements = limit.filterInsertElements(resultElements, container, indexToInsert);
            if (resultElements.length === 0) {
                return false;
            }
        }

        return true;
    }

    public filterElementsCanBeInserted(
        elements: BaseElementModel[],
        container: ContainerModel,
        indexToInsert: number
    ): BaseElementModel[] {
        let resultElements = elements;
        for (const limit of this.limits) {
            resultElements = limit.filterInsertElements(resultElements, container, indexToInsert);
            if (resultElements.length === 0) {
                return [];
            }
        }
        return resultElements;
    }
}
