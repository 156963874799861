export enum VariableGenerationTypes {
    random = "RND",
    randomWithDimension = "RND_WITH_DIM",
    number = "NUM",
    interval = "INTER",
    solver = "SOLVER",
    generatedDrawing = "GENERATED_DRAWING",
    text = "TEXT",
    randomChoice = "RANDOM_CHOICE",
    numericSequence = "NUMERIC_SEQUENCE",
    shuffle = "SHUFFLE",
    textMapping = "TEXT_MAPPING",
}
