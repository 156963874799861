import cn from "classnames";

import type { TBaseKeyboardBlockProps } from "./BaseKeyboardBlock.types";
import type { CSSProperties, VFC } from "react";

import { KeyboardIcon, KeyboardTextIcon } from "../../../../keyboard/elements";
import { DesktopKeyboardButton } from "../../elements";

import styles from "./BaseKeyboardBlock.module.scss";

const BaseKeyboardBlock: VFC<TBaseKeyboardBlockProps> = ({
    columns = 4,
    mathChars,
    rows = 4,
    textIconSize = "large",
    blockClassName,
    iconClassName,
    isShiftPressed = false,
    keyboardService,
    onClick,
}) => {
    const caseTransformer = isShiftPressed ? "toUpperCase" : "toLowerCase";
    const blockStyle = {
        "--columns": columns,
        "--rows": rows,
    } as CSSProperties;

    return (
        <div
            className={cn(styles.block, blockClassName)}
            style={blockStyle}
        >
            {mathChars.map(({ icon, char, actionCreator, tags }, index) => (
                <DesktopKeyboardButton
                    action={actionCreator({ isShiftPressed })}
                    key={index}
                    onClick={onClick}
                    tags={tags}
                    keyboardService={keyboardService}
                >
                    {icon && (
                        <KeyboardIcon
                            iconName={icon}
                            className={cn(styles.icon, iconClassName)}
                        />
                    )}
                    {char && <KeyboardTextIcon size={textIconSize}>{char[caseTransformer]()}</KeyboardTextIcon>}
                </DesktopKeyboardButton>
            ))}
        </div>
    );
};

export default BaseKeyboardBlock;
