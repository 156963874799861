import type { BaseGraphItem } from "../editor/model/BaseGraphItem";
import type { TSerializedState } from "@viuch/math-editor";

import { BaseGraphViewItem } from "../editor/rendering/BaseGraphViewItem";
import { zIndexValues } from "../editor/rendering/utils";

export class LabelViewItem extends BaseGraphViewItem {
    readonly x: number;
    readonly y: number;
    readonly label: TSerializedState;
    readonly position: "top" | "bottom";
    color: string;

    constructor(
        x: number,
        y: number,
        label: TSerializedState,
        position: "top" | "bottom",
        color: string,
        model: BaseGraphItem
    ) {
        super(model, zIndexValues.label.default);
        this.position = position;

        this.x = x;
        this.y = y;
        this.label = label;
        this.color = color;
    }
}
