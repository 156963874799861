import { assert } from "@viuch/utils/debug";

import type { BaseElementModel } from "../../../core/element";
import type { TAnySerializedElement } from "../../../types";
import type { EquationsSetModel } from "../EquationsSetModel";
import type { ISerializedEquationsSet } from "../types";

import { BaseCommand } from "../../../core/commands";
import { LinebreakFinder } from "../../../core/cursor/LinebreakFinder";
import { createContainerData } from "../../../utils";

export class WrapSelectedIntoEquationsSetCommand extends BaseCommand {
    private readonly serializedEquationsSet: TAnySerializedElement;

    public constructor(element: BaseElementModel, serializedEquationsSet: ISerializedEquationsSet) {
        super(element);

        assert(this.selection.selectedElements);

        const finder = LinebreakFinder.Create();
        const equationsSet = this.deserializeElement(serializedEquationsSet) as EquationsSetModel;

        while (equationsSet.equations.length > 1) {
            equationsSet.removeContainerAt(1);
        }

        this.selection.selectedElements.forEach((element) => {
            const containers = equationsSet.equations;
            const lastContainer = containers[containers.length - 1];

            if (finder.isLinebreak(element)) {
                if (lastContainer.getElementsCount() !== 0) {
                    equationsSet.addNewContainerAt(createContainerData([]), containers.length);
                }
            } else if (equationsSet.behaviour.checkCanBeInserted(element, lastContainer)) {
                const serializedClone = element.serializeAsClone();
                const cloneElement = this.deserializeElement(serializedClone);

                lastContainer.insertElement(cloneElement, lastContainer.getElementsCount());
            }
        });
        this.serializedEquationsSet = equationsSet.serialize();
    }

    apply(): void {
        assert(this.selectionSnapshot);

        this.removeSelectedElements();

        const element = this.deserializeElement(this.serializedEquationsSet);
        const container = this.getSelectionContainer();
        container.insertElement(element, this.selectionSnapshot.firstElementIndex);

        this.cursor.moveToPosition(container, this.selectionSnapshot.firstElementIndex + 1);
    }

    rollback(): void {
        assert(this.selectionSnapshot);

        const container = this.getSelectionContainer();
        container.removeElementByIndex(this.selectionSnapshot.firstElementIndex);

        this.restoreRemovedSelectedElements();
        this.restoreCursor();
    }
}
