import type { TSerializedState } from "@viuch/math-editor";

export const enum SolverStepTypes {
    solver = "SOLVER",
    step = "STEP",
}

export type TRawStepDescription = {
    id: number;
    name: string;
    template: Record<string, TSerializedState[]>;
    step_type: SolverStepTypes;
    is_shallow?: boolean;
};

export type TStepDescriptionsGroup = {
    name: string;
    description: string;
};

export type TRawStepDescriptionTable = {
    id: number;
    name: string;
};
