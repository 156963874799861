import { action, computed, makeObservable } from "mobx";

import { assert } from "@viuch/utils/debug";

import type { IntervalsDot } from "../../statement/IntervalsDot";
import type { IntervalsLine } from "../../statement/IntervalsLine";
import type { TIntervalSign } from "../../statement/types";

export class LineViewModel {
    public readonly line: IntervalsLine;

    constructor(line: IntervalsLine) {
        this.line = line;

        makeObservable(this, {
            intervals: computed,
        });
    }

    get intervals(): ReadonlyArray<{
        sign: TIntervalSign;
        leftDot: IntervalsDot | null;
        rightDot: IntervalsDot | null;
    }> {
        const dotsCount = this.line.dots.length;
        const intervalsCount = this.line.intervalsSings.length;
        assert(intervalsCount === dotsCount + 1);

        return this.line.intervalsSings.map((sign, i) => {
            const leftDot = i === 0 ? null : this.line.dots[i - 1];
            const rightDot = i === intervalsCount ? null : this.line.dots.at(i) || null;
            return { sign, leftDot, rightDot };
        });
    }

    handleSignChange = action((rightDot: IntervalsDot | null, newSign: TIntervalSign) => {
        const dotsCount = this.line.dots.length;
        const intervalsCount = this.line.intervalsSings.length;
        assert(intervalsCount === dotsCount + 1);

        const i = rightDot ? this.line.dots.indexOf(rightDot) : intervalsCount - 1;
        assert(i !== -1);

        this.line.intervalsSings[i] = this.getNewSign(this.line.intervalsSings[i], newSign);
    });

    private getNewSign(oldSign: TIntervalSign, sign: TIntervalSign): TIntervalSign {
        if (oldSign === "zero") {
            return sign;
        }

        if ((oldSign === "positive" && sign === "negative") || (sign === "positive" && oldSign === "negative")) {
            return "zero";
        }

        if (oldSign === sign) {
            return "none";
        }
        return sign;
    }

    canCreateDotAt(position: number): boolean {
        return !this.line.dots.some((dot) => dot.position === position);
    }

    handleMoveDot = action((dot: IntervalsDot, position: number) => {
        if (!this.canCreateDotAt(position)) {
            return;
        }
        dot.position = position;

        this.line.dots.sort((left, right) => left.position - right.position);
    });
}
