import cn from "classnames";
import { observer } from "mobx-react-lite";
import React from "react";
import SimpleBar from "simplebar-react";

import { mergeRefs } from "@viuch/shared/utils/react";

import type { PropsWithChildren } from "react";

import styles from "./ModalLayout2.module.scss";

type Props = PropsWithChildren<{
    title?: string;
    containerRef?: React.Ref<HTMLElement>;
    subtitle?: string;
    onClose?(): void;
    className?: string;
    contentClassName?: string;
    isCloseDisabled?: boolean;
    contentInnerClassName?: string;
    titleClassName?: string;
    subtitleClassName?: string;
    closeClassName?: string;
}>;

export const ModalLayout2 = observer(function ModalLayout2({
    className,
    title,
    onClose,
    children,
    subtitle,
    containerRef,
    contentClassName,
    isCloseDisabled,
    contentInnerClassName,
    titleClassName,
    subtitleClassName,
    closeClassName,
}: Props) {
    return (
        <div
            className={cn(styles.layout, className)}
            ref={mergeRefs(containerRef)}
        >
            <div className={styles.header}>
                <div>
                    <div className={cn(styles.title, titleClassName)}>{title}</div>
                    {subtitle && <div className={cn(styles.subtitle, subtitleClassName)}>{subtitle}</div>}
                </div>
                {onClose && (
                    <button
                        className={cn(styles.closeButton, closeClassName)}
                        onClick={onClose}
                        disabled={isCloseDisabled}
                    />
                )}
            </div>
            <SimpleBar className={cn(styles.content, contentClassName)}>
                <div className={cn(styles.contentInner, contentInnerClassName)}>{children}</div>
            </SimpleBar>
        </div>
    );
});
