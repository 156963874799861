import { action, makeObservable, observable } from "mobx";

import { createSerializedContainer } from "@viuch/math-editor";
import { generateId } from "@viuch/shared/utils/data";

import type { TPictureWatermarkAlignment, TPictureWatermarkPosition } from "@viuch/instrument-picture/statement";
import type { TSerializedState } from "@viuch/math-editor";

export type TPictureSettingsWatermarkFields = {
    position: TPictureWatermarkPosition;
    templateValue: TSerializedState;
    alignment: TPictureWatermarkAlignment;
    color: string;
};

export class PictureInstrumentSettingsWatermark {
    readonly $$instanceId = generateId();

    @observable.ref position: TPictureWatermarkPosition;
    @observable.ref templateValue: TSerializedState;
    @observable.ref alignment: TPictureWatermarkAlignment;
    @observable color: string;

    constructor(fields: TPictureSettingsWatermarkFields) {
        this.position = fields.position;
        this.templateValue = fields.templateValue;
        this.alignment = fields.alignment;
        this.color = fields.color;

        makeObservable(this);
    }

    static createEmpty(data?: Partial<TPictureSettingsWatermarkFields>): PictureInstrumentSettingsWatermark {
        return new PictureInstrumentSettingsWatermark({
            alignment: { vertical: "top", horizontal: "left" },
            position: { left: 0, top: 0 },
            templateValue: createSerializedContainer(),
            color: "#ffffff",
            ...data,
        });
    }

    clone(): PictureInstrumentSettingsWatermark {
        return new PictureInstrumentSettingsWatermark(this);
    }

    @action.bound
    setPosition(position: TPictureWatermarkPosition) {
        this.position = position;
    }

    @action.bound
    setTemplateValue(value: TSerializedState) {
        this.templateValue = value;
    }

    @action.bound
    setAlignment(value: TPictureWatermarkAlignment) {
        this.alignment = value;
    }
}
