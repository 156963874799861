import { action, makeObservable } from "mobx";

import { LazyResult } from "./LazyResult";

export class LazyConfirmation implements PromiseLike<boolean> {
    private readonly lazyResult = new LazyResult<boolean>();

    constructor() {
        makeObservable(this);
    }

    @action.bound
    confirm() {
        this.lazyResult.resolveWith(true);
    }

    @action.bound
    discard() {
        this.lazyResult.resolveWith(false);
    }

    readonly then = this.lazyResult.promise.then.bind(this.lazyResult.promise);
}
