import { observer } from "mobx-react-lite";
import React, { lazy, useEffect, useState } from "react";
import { Route, Switch, Router as ReactRouter, Redirect } from "react-router-dom";

import { useRootStore } from "../integration";
import {
    getRefreshToken,
    hasAccessToken,
    removeAccessToken,
    removeRefreshToken,
    setAccessToken,
    setRefreshToken,
} from "../services/auth/utils";
import Login from "../views/Login";

import Admin from "./Admin";
import { appHistory } from "./appHistory";

const DevelopPage = lazy(() => import("../views/develop-pages/DevelopPage").then((m) => ({ default: m.DevelopPage })));

const Router = observer(function Router() {
    const store = useRootStore();
    const [readyState, setReadyState] = useState<"loading" | "ready" | "error">("loading");

    useEffect(() => {
        (async () => {
            try {
                if (hasAccessToken()) {
                    const user = await store.api.auth.me();
                    store.auth.authorize(user);
                }

                setReadyState("ready");
            } catch (e) {
                const refreshToken = getRefreshToken();
                removeAccessToken();
                removeRefreshToken();

                if (refreshToken) {
                    try {
                        const { access } = await store.api.auth.refresh(refreshToken);

                        setAccessToken(access);
                        setRefreshToken(refreshToken);

                        const user = await store.api.auth.me();
                        store.auth.authorize(user);

                        setReadyState("ready");
                        return;
                    } catch (e) {
                        console.error("Failed to refresh token", e);

                        removeAccessToken();
                        removeRefreshToken();
                        setReadyState("ready");
                        return;
                    }
                }

                console.error(e);
                setReadyState("error");
            }
        })();
    }, [store]);

    if (readyState === "loading") {
        return <i>Loading...</i>;
    }

    if (readyState === "error") {
        return <i>Error</i>;
    }

    const { isAuthorized } = store.auth;

    return (
        <ReactRouter history={appHistory}>
            <Switch>
                <Route
                    exact
                    path="/dev"
                    component={DevelopPage}
                />
                {!isAuthorized && (
                    <>
                        <Route
                            exact
                            path="/login"
                            component={Login}
                        />
                        <Route
                            path="*"
                            render={() => <Redirect to="/login" />}
                        />
                    </>
                )}
                {isAuthorized && (
                    <>
                        <Route
                            path="/"
                            component={Admin}
                        />
                        <Route
                            path="/login"
                            render={() => <Redirect to="/" />}
                        />
                    </>
                )}
            </Switch>
        </ReactRouter>
    );
});

export default Router;
