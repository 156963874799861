import type { ISerializedIntegral } from "./types";
import type { TSerializedElementPrototype } from "../../core/element";
import type { IElementVisitor } from "../../core/element/IElementVisitor";
import type { IStrategy } from "../../core/strategies";
import type { IMultiContainerElement } from "../../core/strategies/types";
import type { InputService } from "../../services";
import type { TContainerElements, TElementDeserializerFunction } from "../../types";

import { ContainerModel } from "../../core/container";
import { BaseElementModel } from "../../core/element";

import { IntegralStrategy } from "./IntegralStrategy";

export class IntegralModel extends BaseElementModel<ISerializedIntegral> implements IMultiContainerElement {
    public readonly integralContent: ContainerModel;
    public readonly argContent: ContainerModel;

    constructor(
        inputService: InputService,
        integralElements: TContainerElements,
        argElements: TContainerElements,
        uuid?: string
    ) {
        super(inputService, uuid);

        this.integralContent = new ContainerModel(integralElements, this, ["integralContent"], inputService, {
            showPlaceholderIfEmpty: true,
        });

        this.argContent = new ContainerModel(argElements, this, ["argContent"], inputService, {
            showPlaceholderIfEmpty: true,
        });
    }

    public serialize(): ISerializedIntegral {
        return {
            type: "integral",
            argContent: this.argContent.serialize(),
            integralContent: this.integralContent.serialize(),
            uuid: this.uuid,
        };
    }

    public static deserialize: TElementDeserializerFunction<ISerializedIntegral> = (
        { inputService },
        { argContent, integralContent, uuid }
    ) =>
        new IntegralModel(
            inputService,
            inputService.deserializeContainer(integralContent),
            inputService.deserializeContainer(argContent),
            uuid
        );

    getContainersToMoveCursorBetween(): ContainerModel[] {
        return [this.integralContent, this.argContent];
    }

    protected override initBehaviour(): IStrategy {
        return new IntegralStrategy(this);
    }

    public accept<R>(visitor: IElementVisitor<R>): R {
        return visitor.doWithIntegral(this);
    }

    serializeAsClone(): TSerializedElementPrototype<ISerializedIntegral> {
        const { uuid, ...clone } = this.serialize();
        return {
            ...clone,
            argContent: this.argContent.serializeAsClone(),
            integralContent: this.integralContent.serializeAsClone(),
        };
    }

    override getWrapperContainer(): ContainerModel | null {
        return this.integralContent;
    }
}
