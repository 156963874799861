import type { TEllipse, TFragment, TLine, TPoint } from "./types";

import { isLineVertical, isPointInBounds, isPointOnFragment } from "./check-geometry";
import { isEllipseType, isFragmentType, isLineType } from "./check-types";
import { equateNumbers } from "./equate";
import { copyPoint, createLine, createPoint } from "./factories";
import { filterEqualPoints } from "./filters";
import { Ellipse, ellipseIntersection, Vec } from "./internal/intersection-ellipses";
import { canonicalLineParams, solveQuadraticEquation } from "./solvers";

export function intersectionLines(a: TLine, b: TLine): TPoint | null {
    const { x1, y1, x2, y2 } = a;
    const { x1: x3, y1: y3, x2: x4, y2: y4 } = b;

    const a1 = y2 - y1;
    const a2 = y4 - y3;
    const b1 = x1 - x2;
    const b2 = x3 - x4;
    const c1 = (x1 * (y1 - y2) + y1 * (x2 - x1)) * -1;
    const c2 = (x3 * (y3 - y4) + y3 * (x4 - x3)) * -1;

    const d = a1 * b2 - b1 * a2;
    const dx = c1 * b2 - b1 * c2;
    const dy = a1 * c2 - c1 * a2;

    if (equateNumbers(d, "==", 0)) return null;

    const x = dx / d;
    const y = dy / d;

    return { x, y };
}

export function intersectionLineFragment(line: TLine, fragment: TFragment): TPoint | null {
    const fragmentLine = createLine(fragment.a, fragment.b);

    const point = intersectionLines(line, fragmentLine);
    if (!point) return null;

    return isPointInBounds(fragmentLine, point) ? point : null;
}

export function intersectionFragments(a: TFragment, b: TFragment): TPoint | null {
    const lineA = createLine(a.a, a.b);
    const lineB = createLine(b.a, b.b);

    const point = intersectionLines(lineA, lineB);
    if (!point) return null;

    return isPointInBounds(lineA, point) && isPointInBounds(lineB, point) ? point : null;
}

export function intersectionEllipses(first: TEllipse, second: TEllipse): TPoint[] {
    return intersectionTwoEllipses(first, second);
}

export function intersectionFigures(
    first: TLine | TFragment | TEllipse,
    second: TLine | TFragment | TEllipse
): TPoint[] {
    const arr = <T>(value: T | null) => (value ? [value] : []);

    if (isFragmentType(first)) {
        if (isFragmentType(second)) {
            return arr(intersectionFragments(first, second));
        } else if (isLineType(second)) {
            return arr(intersectionLineFragment(second, first));
        } else if (isEllipseType(second)) {
            return intersectionFragmentEllipse(first, second);
        }
    }
    if (isLineType(first)) {
        if (isFragmentType(second)) {
            return arr(intersectionLineFragment(first, second));
        } else if (isLineType(second)) {
            return arr(intersectionLines(first, second));
        } else if (isEllipseType(second)) {
            return intersectionLineEllipse(first, second);
        }
    }
    if (isEllipseType(first)) {
        if (isFragmentType(second)) {
            return intersectionFragmentEllipse(second, first);
        } else if (isLineType(second)) {
            return intersectionLineEllipse(second, first);
        } else if (isEllipseType(second)) {
            return intersectionEllipses(first, second);
        }
    }

    return [];
}

function intersectionFragmentEllipse(fragment: TFragment, ellipse: TEllipse): TPoint[] {
    return intersectionLineEllipse(createLine(fragment), ellipse).filter((point) => isPointOnFragment(fragment, point));
}

export function intersectionEllipseHorizontal(
    ellipse: TEllipse,
    horizontalLineY: number
): [x1: number, x2: number] | null {
    const rx = ellipse.rx;
    const ry = ellipse.ry;
    const x0 = ellipse.center.x;
    const y0 = ellipse.center.y;

    const y = horizontalLineY;

    const tSquared = rx * rx * (1 - ((y - y0) * (y - y0)) / (ry * ry));
    if (tSquared < 0) return null;

    const t = Math.sqrt(tSquared);
    const x1 = t + x0;
    const x2 = -t + x0;
    return [x1, x2];
}

export function intersectionEllipseVertical(
    ellipse: TEllipse,
    horizontalLineX: number
): [y1: number, y2: number] | null {
    return intersectionEllipseHorizontal(
        { center: { x: ellipse.center.y, y: ellipse.center.x }, rx: ellipse.ry, ry: ellipse.rx },
        horizontalLineX
    );
}

function intersectionLineEllipse(line: TLine, ellipse: TEllipse): TPoint[] {
    if (isLineVertical(line)) {
        const x = line.x1;
        const result = intersectionEllipseVertical(ellipse, x);
        if (!result) return [];

        const [y1, y2] = result;
        return [createPoint(x, y1), createPoint(x, y2)];
    }

    const {
        rx,
        ry,
        center: { x: x0, y: y0 },
    } = ellipse;
    const { a, b } = canonicalLineParams(line);

    const _a = a ** 2 * rx ** 2 + ry ** 2;
    const _b = 2 * a * b * rx ** 2 - 2 * a * rx ** 2 * y0 - 2 * ry ** 2 * x0;
    const _c = b ** 2 * rx ** 2 - 2 * b * rx ** 2 * y0 - rx ** 2 * ry ** 2 + rx ** 2 * y0 ** 2 + ry ** 2 * x0 ** 2;

    const xSolutions = solveQuadraticEquation(_a, _b, _c);

    return xSolutions.map((x) => createPoint(x, a * x + b));
}

export function intersectionTwoEllipses(left: TEllipse, right: TEllipse): TPoint[] {
    const e1 = new Ellipse(new Vec(left.center.x, left.center.y), left.rx, left.ry);
    const e2 = new Ellipse(new Vec(right.center.x, right.center.y), right.rx, right.ry);
    return filterEqualPoints(ellipseIntersection(e1, e2)).map(copyPoint);
}
