import { generateId } from "@viuch/shared/utils/data";

export class Graph1DInstrumentStatementStrokesInterval {
    readonly $$instanceId = generateId();

    constructor(
        readonly color: string,
        readonly left: number | null,
        readonly right: number | null,
        readonly side: "top" | "bottom",
        readonly direction: "left" | "right"
    ) {}
}
