import { action, computed, makeObservable } from "mobx";
import { createTransformer } from "mobx-utils";

import { getAngles } from "@viuch/geometry-lib/angles";
import { areSamePoints } from "@viuch/geometry-lib/check-geometry";
import { createSerializedContainer, isZeroLengthMathExpr } from "@viuch/math-editor";
import { isLinebreakAction } from "@viuch/math-editor/actions/linebreak";

import type { BaseElement } from "../../elements";
import type { Figure2DController } from "../../Figure2DController";
import type { BaseModel } from "../../models";
import type { ToolbarButton, ToolbarMenu } from "../../toolbar";
import type { TAngle } from "@viuch/geometry-lib/types";
import type { InputService, TSerializedState } from "@viuch/math-editor";
import type { TAnyAction } from "@viuch/math-editor/types";
import type React from "react";

import { AngleElement } from "../../elements/angle";
import { DotElement } from "../../elements/dot";
import { ElementColor } from "../../elements/ElementColor";
import { LabelAngleElement } from "../../elements/label-text";
import { LineElement } from "../../elements/line";
import { LabelAngleModel } from "../../models/label-angle";
import { PointModel } from "../../models/point";
import { checkModelType, ModelTypes } from "../../services/actions/utils";
import { ButtonStates } from "../../toolbar";
import { TooltipMenu } from "../../toolbar/tooltip";
import { createDefaultToolbarMenuBuilder, handleToolbarButtons } from "../../utils/toolbar";
import { BaseFlow } from "../BaseFlow";
import { createMapModelToElements, opacifyColor, removeModel } from "../utils";

export class CreateLabelAngleFlow extends BaseFlow {
    private mapModel = createTransformer(createMapModelToElements(opacifyColor));
    private readonly angle: TAngle;
    private initialValue?: TSerializedState;
    private readonly preferRightAngle: boolean;

    constructor(data: Figure2DController, angle: TAngle, preferRightAngle: boolean) {
        super(data);
        this.angle = angle;
        this.preferRightAngle = preferRightAngle;

        makeObservable(this, {
            existingLabel: computed,
            modelsToRender: computed,
            tempLabelElement: computed,
            tempElements: computed,
        });
    }

    get existingLabel(): LabelAngleModel | null {
        const { vertex, start, end } = this.angle;
        return (
            this.figure.models.find(
                (model): model is LabelAngleModel =>
                    checkModelType(model, ModelTypes.labelAngle) &&
                    areSamePoints(vertex, model.vertex) &&
                    areSamePoints(start, model.start) &&
                    areSamePoints(end, model.end)
            ) || null
        );
    }

    get modelsToRender(): BaseModel[] {
        const { existingLabel } = this;
        return this.figure.models.filter((m) => m !== existingLabel);
    }

    get tempElements(): BaseElement[] {
        const {
            vertex: { x, y },
            start,
            end,
        } = this.angle;
        const color = ElementColor.Building;
        return [
            new DotElement({
                id: "temp__vertexDot",
                color: ElementColor.Selected,
                x,
                y,
                overrideRenderOrder: 0,
            }),
            new LineElement({
                id: "temp__startLine",
                x1: x,
                y1: y,
                x2: start.x,
                y2: start.y,
                color,
                overrideRenderOrder: 1,
            }),
            new LineElement({
                id: "temp__endLine",
                x1: x,
                y1: y,
                x2: end.x,
                y2: end.y,
                color,
                overrideRenderOrder: 1,
            }),
            this.tempLabelElement,
            this.tempAngleElement,
        ];
    }

    get tempAngleElement() {
        const { angle } = this;

        const { startAngle, endAngle } = getAngles(angle);

        return new AngleElement({
            id: `temp__angle`,
            x: angle.vertex.x,
            y: angle.vertex.y,
            angleStart: startAngle,
            angleEnd: endAngle,
            color: ElementColor.Selected,
            overrideRenderOrder: 10,
        });
    }

    get tempLabelElement(): LabelAngleElement {
        return new LabelAngleElement({
            id: "temp__label",
            color: ElementColor.Selected,
            isEditable: true,
            value: this.initialValue ?? createSerializedContainer(),
            directionAngle: 0,
            rotationAngle: 0,
            x: this.angle.vertex.x,
            y: this.angle.vertex.y,
            overrideRenderOrder: 0,
            notifyAction: this.notifyAction,
        });
    }

    attach(): void {
        this.initialValue = this.existingLabel?.value;
        this.viewport.disable();
    }

    dispose(): void {}

    override handleViewportPointerEvent(e: React.PointerEvent): void {
        if (!e.isPrimary) return;

        switch (e.type) {
            case "pointerdown":
            case "pointermove":
            case "pointerup":
            case "pointercancel":
                break;
            default:
                return;
        }

        if (e.type === "pointerdown") {
            e.preventDefault();
            this.removeCurrentModel();
            this.saveChanges();
            this.nextFlow();
        }
    }

    override handleElementPointerEvent(e: React.PointerEvent, element: BaseElement): void {
        if (!e.isPrimary) return;

        switch (e.type) {
            case "pointerdown":
            case "pointermove":
            case "pointerup":
            case "pointercancel":
                break;
            default:
                return;
        }

        if (element === this.tempLabelElement) {
            e.stopPropagation();
        }
    }

    override handleToolbarButtonClick(menu: ToolbarMenu, button: ToolbarButton): void {
        switch (button.key) {
            case "remove":
            case "delete": {
                this.removeCurrentModel();
                this.nextFlow();
                break;
            }
            case "labels":
                return this.nextFlow();
            default:
                handleToolbarButtons(this, button);
        }
    }

    protected renderElements(): BaseElement[] {
        return [...this.modelsToRender.flatMap(this.mapModel), ...this.tempElements];
    }

    private removeCurrentModel() {
        if (this.existingLabel) {
            removeModel(this.figure, this.existingLabel);
        }
    }

    private saveChanges() {
        const value = this.tempLabelElement.inputService.getSerializedState();
        const { vertex, start, end } = this.angle;
        const { preferRightAngle } = this;

        this.removeCurrentModel();

        if (!isZeroLengthMathExpr(value) || preferRightAngle) {
            this.figure.insertModels(function* () {
                yield LabelAngleModel.create({ value, vertex, start, end, preferRightAngle, style: null });

                yield PointModel.create({ ...vertex, style: null });
                yield PointModel.create({ ...start, style: null });
                yield PointModel.create({ ...end, style: null });
            }, this);
        }
    }

    notifyAction = action((inputService: InputService, action: TAnyAction) => {
        if (isLinebreakAction(action)) {
            this.tempLabelElement.value = inputService.getSerializedState();
            this.saveChanges();
            this.nextFlow();
        }
    });

    override getToolbarMenu(): ToolbarMenu {
        return createDefaultToolbarMenuBuilder()
            .setButtonState(ButtonStates.active, ["labels"])
            .setButtonIcon("label-angle", ["labels"])
            .build();
    }

    override getTooltipMenu(): TooltipMenu | null {
        return new TooltipMenu("Подпишите угол, затем нажмите Enter или в пустую область");
    }
}
