import type { BaseElementModel } from "../core/element";
import type { TAnySerializedElement } from "../types";

import { BaseCommand } from "../core/commands";

export class RemoveCurrentCommand<T extends BaseElementModel = BaseElementModel> extends BaseCommand<T> {
    private readonly serializedElement: TAnySerializedElement;
    private readonly elementIndex: number;
    private readonly containerId: string;

    public constructor(element: T) {
        super(element);
        this.serializedElement = element.serialize();
        this.elementIndex = element.computeIndex();
        this.containerId = element.parentContainer.uuid;
    }

    apply(): void {
        const container = this.containers.getById(this.containerId);
        container.removeElementByIndex(this.elementIndex);
        this.cursor.moveToPosition(container, this.elementIndex);
    }

    rollback(): void {
        const container = this.containers.getById(this.containerId);
        const element = this.deserializeElement(this.serializedElement);
        container.insertElement(element, this.elementIndex);
        this.cursor.moveToPosition(this.getCursorContainer(), this.cursorPosition);
    }
}
