import type { TGlobalStudyPlanTemplate } from "./GlobalStudyPlanTemplatesService.types";
import type { GlobalStudyPlanTemplate } from "../../entities/global-study-plan/templates";

import { agent } from "../../agent";
import { throwServiceError } from "../base/RequestError";

import { mapGlobalStudyPlanTemplate, serializeGlobalStudyPlanTemplate } from "./GlobalStudyPlanTemplatesService.utils";

export class GlobalStudyPlanTemplatesService {
    async createTemplate(template: GlobalStudyPlanTemplate): Promise<GlobalStudyPlanTemplate> {
        try {
            const request = serializeGlobalStudyPlanTemplate(template);

            const { data } = await agent.post<TGlobalStudyPlanTemplate>("/global_study_plans/templates/", request);

            return mapGlobalStudyPlanTemplate(data);
        } catch (e) {
            throwServiceError(e);
        }
    }

    async getAllTemplates(): Promise<GlobalStudyPlanTemplate[]> {
        try {
            const { data } = await agent.get<TGlobalStudyPlanTemplate[]>("/global_study_plans/templates/");

            return data.map(mapGlobalStudyPlanTemplate);
        } catch (e) {
            throwServiceError(e);
        }
    }

    async getTemplateById(templateId: number | string): Promise<GlobalStudyPlanTemplate> {
        try {
            const { data } = await agent.get<TGlobalStudyPlanTemplate>(`/global_study_plans/templates/${templateId}/`);

            return mapGlobalStudyPlanTemplate(data);
        } catch (e) {
            throwServiceError(e);
        }
    }

    async updateTemplate(template: GlobalStudyPlanTemplate): Promise<GlobalStudyPlanTemplate> {
        try {
            const request = serializeGlobalStudyPlanTemplate(template);

            const { data } = await agent.patch<TGlobalStudyPlanTemplate>(
                `/global_study_plans/templates/${template.id}/`,
                request
            );

            return mapGlobalStudyPlanTemplate(data);
        } catch (e) {
            throwServiceError(e);
        }
    }

    async deleteTemplateById(templateId: number | string): Promise<void> {
        try {
            await agent.delete<TGlobalStudyPlanTemplate>(`/global_study_plans/templates/${templateId}/`);
        } catch (e) {
            throwServiceError(e);
        }
    }
}
