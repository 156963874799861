import { action, makeObservable, observable } from "mobx";
import { createTransformer } from "mobx-utils";

import {
    areSamePoints,
    findPerpendicularPoint,
    isPointOnFragment,
    isZeroPoint,
} from "@viuch/geometry-lib/check-geometry";
import { filterEqualPoints } from "@viuch/geometry-lib/filters";
import { subtractVectors } from "@viuch/geometry-lib/vectors";
import { assert } from "@viuch/utils/debug";

import type { BaseElement } from "../../elements";
import type { Figure2DController } from "../../Figure2DController";
import type { ToolbarMenu } from "../../toolbar";
import type { IUserPointer } from "../../viewport/types";
import type { TFragment, TPoint } from "@viuch/geometry-lib/types";
import type React from "react";

import { DotElement } from "../../elements/dot";
import { ElementColor } from "../../elements/ElementColor";
import { LineElement } from "../../elements/line";
import { FragmentModel } from "../../models/fragment";
import { MiddleLineModel } from "../../models/geometry/MiddleLineModel";
import { LabelPointModel } from "../../models/label-point";
import { PointModel } from "../../models/point";
import { ToolbarButton } from "../../toolbar";
import { TooltipMenu } from "../../toolbar/tooltip";
import { handleToolbarButtons } from "../../utils/toolbar";
import { findAllViewFragments, findVertexViewPointsOnFragments } from "../../utils/visible-fragments";
import { findAllViewPoints } from "../../utils/visible-points";
import { BaseFlow } from "../BaseFlow";
import { createMapModelToElements, opacifyColor, z } from "../utils";

import { hashPoint } from "./utils";

export class MiddleFragmentInteractiveFlow extends BaseFlow {
    flow:
        | {
              stage: 1;
              a?: TPoint;
              drawing: boolean;
          }
        | {
              stage: 2;
              readonly a: TPoint;
              b?: TPoint;
              readonly availablePointsB: TPoint[];
              drawing: boolean;
          }
        | {
              stage: 3;
              readonly a: TPoint;
              readonly b: TPoint;
              c?: TPoint;
              readonly availablePairs: TFragment[];
              drawing: boolean;
          }
        | {
              stage: 4;
              readonly a: TPoint;
              readonly b: TPoint;
              readonly c: TPoint;
              d?: TPoint;
              readonly availablePoints: { point: TPoint; isAC: boolean }[];
              drawing: boolean;
          };

    private readonly initialData?: Record<"a" | "b" | "c" | "d", TPoint>;

    constructor(data: Figure2DController, initialData?: Record<"a" | "b" | "c" | "d", TPoint>) {
        super(data);
        this.initialData = initialData;

        this.flow = { stage: 1, drawing: false };

        makeObservable(this, {
            flow: observable.deep,
        });
    }

    attach(): void {
        this.snap.addFigureModelsPoints();
        this.viewport.disable();

        if (this.initialData) {
            const { a, b, c, d } = this.initialData;
            this.saveRaw(a, b, c, d);
            this.nextFlow();
        }
    }

    dispose(): void {
        this.snap.clean();
    }

    override handleViewportPointerEvent(e: React.PointerEvent, pointer: IUserPointer): void {
        if (e.type === "pointercancel") return this.nextFlow();
        if (!e.isPrimary) return;

        const point = this.snap.snap(pointer.canvas, void 0);

        this.movePointer(e, point);
        this.onStartDrawing(e);
        this.onEndDrawing(e);
    }

    private movePointer(e: React.PointerEvent, point?: TPoint) {
        if (e.type === "pointermove" || e.type === "pointerdown") {
            switch (this.flow.stage) {
                case 1:
                    this.flow.a = point;
                    break;
                case 2:
                    if (point && areSamePoints(this.flow.a, point)) {
                        this.flow.b = void 0;
                    } else {
                        this.flow.b = point;
                    }
                    break;
                case 3:
                    this.flow.c = point;
                    break;
                case 4:
                    this.flow.d = point;
                    break;
            }
        }
    }

    private onStartDrawing(e: React.PointerEvent) {
        if (e.type === "pointerdown") {
            this.flow.drawing = true;
        }
    }

    private onEndDrawing(e: React.PointerEvent) {
        if (e.type !== "pointerup") {
            return;
        }

        this.flow.drawing = false;
        switch (this.flow.stage) {
            case 1: {
                if (!this.flow.a) break;

                const allViewFragments = findAllViewFragments(this.figure.models);
                const allViewPoints = findAllViewPoints(this.figure.models);

                const availablePointsB = this.findPointsOnViewFragment(this.flow.a, allViewFragments, allViewPoints);

                this.snap.clean();
                availablePointsB.forEach((point) => this.snap.addPoint(point));

                this.flow = {
                    stage: 2,
                    a: this.flow.a,
                    availablePointsB,
                    drawing: false,
                };
                break;
            }
            case 2: {
                if (!this.flow.b) break;

                const availablePairs = Array.from(this.getAvailablePairs(this.flow.a, this.flow.b));

                if (availablePairs.length === 0) {
                    this.nextFlow();
                    return;
                }

                if (availablePairs.length === 1) {
                    const { a, b } = this.flow;
                    const { a: c, b: d } = availablePairs[0];
                    this.saveRaw(a, b, c, d);
                    this.nextFlow();
                    return;
                }

                this.snap.clean();
                availablePairs.forEach(({ a, b }) => {
                    this.snap.addPoint(a);
                    this.snap.addPoint(b);
                });

                this.flow = {
                    stage: 3,
                    a: this.flow.a,
                    b: this.flow.b,
                    drawing: false,
                    availablePairs,
                };
                break;
            }
            case 3: {
                if (!this.flow.c) break;
                const { a, b, c, availablePairs } = this.flow;

                const availablePoints = Array.from(this.findAvailablePoints(availablePairs, c));

                if (availablePoints.length === 1) {
                    const [{ point: p, isAC }] = availablePoints;
                    const { a, b } = this.flow;
                    const [c, d] = isAC ? [this.flow.c, p] : [p, this.flow.c];

                    this.saveRaw(a, b, c, d);
                    this.nextFlow();
                    return;
                }

                this.snap.clean();
                availablePoints.forEach(({ point }) => {
                    this.snap.addPoint(point);
                });

                this.flow = {
                    stage: 4,
                    a,
                    b,
                    c,
                    drawing: false,
                    availablePoints,
                };
                break;
            }
            case 4: {
                if (!this.flow.d) break;
                const { a, b, c, d, availablePoints } = this.flow;
                const availablePoint = availablePoints.find(({ point }) => areSamePoints(point, d));
                assert(availablePoint);

                if (availablePoint.isAC) {
                    this.saveRaw(a, b, c, d);
                } else {
                    this.saveRaw(a, b, d, c);
                }

                this.nextFlow();
                break;
            }
        }
    }

    private mapModel = createTransformer(createMapModelToElements(opacifyColor));

    protected renderElements(): BaseElement[] {
        return [...this.figure.models.flatMap(this.mapModel), ...this.getTempElements()];
    }

    *getTempElements(): Iterable<BaseElement> {
        const { stage } = this.flow;

        if (!this.flow.a) return;

        switch (stage) {
            case 1:
            case 2:
            case 3:
            case 4: {
                const { a } = this.flow;
                if (!a) break;
                const color = stage === 1 ? ElementColor.Selected : ElementColor.Building;

                yield new DotElement({
                    id: `temp_a`,
                    x: a.x,
                    y: a.y,
                    color,
                    overrideRenderOrder: z.points.priority,
                });
            }
        }

        switch (stage) {
            case 2:
            case 3:
            case 4: {
                const { a, b } = this.flow;
                if (!b) break;
                const color = stage === 2 ? ElementColor.Selected : ElementColor.Building;

                yield new DotElement({
                    id: `temp_b`,
                    x: b.x,
                    y: b.y,
                    color,
                    overrideRenderOrder: z.points.priority,
                });
                yield new LineElement({
                    id: `temp_ab`,
                    x1: a.x,
                    y1: a.y,
                    x2: b.x,
                    y2: b.y,
                    color,
                    overrideRenderOrder: z.fragments.priority,
                });
            }
        }

        switch (stage) {
            case 3:
            case 4: {
                const { c } = this.flow;
                if (!c) break;
                const color = stage === 3 ? ElementColor.Selected : ElementColor.Building;

                yield new DotElement({
                    id: `temp_c`,
                    x: c.x,
                    y: c.y,
                    color,
                    overrideRenderOrder: z.points.priority,
                });
            }
        }

        switch (stage) {
            case 4: {
                const { c, d } = this.flow;
                if (!d) break;

                yield new DotElement({
                    id: `temp_d`,
                    x: d.x,
                    y: d.y,
                    color: ElementColor.Selected,
                    overrideRenderOrder: z.points.priority,
                });
                yield new LineElement({
                    id: `temp_cd`,
                    x1: c.x,
                    y1: c.y,
                    x2: d.x,
                    y2: d.y,
                    color: ElementColor.Selected,
                    overrideRenderOrder: z.fragments.priority,
                });
            }
        }

        if (stage === 1 || !this.flow.b) {
            const { a } = this.flow;

            const allViewFragments = findAllViewFragments(this.figure.models);
            const viewFragments = allViewFragments.filter(isPointOnFragment.getPointFilter(a));

            for (let i = 0; i < viewFragments.length; i++) {
                const { a, b } = viewFragments[i];

                yield new LineElement({
                    id: `temp_aLine_${i}`,
                    x1: a.x,
                    y1: a.y,
                    x2: b.x,
                    y2: b.y,
                    thin: true,
                    color: ElementColor.Building,
                    overrideRenderOrder: z.lines.virtual,
                });
            }
        }

        if (stage === 2) {
            const { availablePointsB } = this.flow;

            for (let i = 0; i < availablePointsB.length; i++) {
                const { x, y } = availablePointsB[i];

                yield new DotElement({
                    id: `temp_b_available_${i}`,
                    x,
                    y,
                    color: ElementColor.Building,
                    overrideRenderOrder: z.points.priority + 1,
                });
            }
        }

        if (stage === 3) {
            const { availablePairs } = this.flow;

            for (let i = 0; i < availablePairs.length; i++) {
                const pair = availablePairs[i];

                yield new DotElement({
                    id: `temp_pair_a_${i}`,
                    x: pair.a.x,
                    y: pair.a.y,
                    overrideRenderOrder: z.points.priority + 1,
                    color: ElementColor.Building,
                });

                if (!areSamePoints(pair.a, pair.b)) {
                    yield new DotElement({
                        id: `temp_pair_b_${i}`,
                        x: pair.b.x,
                        y: pair.b.y,
                        overrideRenderOrder: z.points.priority + 1,
                        color: ElementColor.Building,
                    });

                    yield new LineElement({
                        id: `temp_pair_line_${i}`,
                        x1: pair.a.x,
                        y1: pair.a.y,
                        x2: pair.b.x,
                        y2: pair.b.y,
                        thin: true,
                        color: ElementColor.Building,
                        overrideRenderOrder: z.points.virtual,
                    });
                }
            }
        }

        if (stage === 4) {
            const { availablePoints, c } = this.flow;

            for (let i = 0; i < availablePoints.length; i++) {
                const { point } = availablePoints[i];

                yield new DotElement({
                    id: `temp_d_${i}`,
                    x: point.x,
                    y: point.y,
                    color: ElementColor.Building,
                    overrideRenderOrder: z.points.priority + 1,
                });

                if (!areSamePoints(point, c)) {
                    yield new LineElement({
                        id: `temp_pair_line_${i}`,
                        x1: point.x,
                        y1: point.y,
                        x2: c.x,
                        y2: c.y,
                        color: ElementColor.Building,
                        overrideRenderOrder: z.points.virtual,
                    });
                }
            }
        }
    }

    override handleToolbarButtonClick(menu: ToolbarMenu, button: ToolbarButton): void {
        switch (button.key) {
            default:
                handleToolbarButtons(this, button);
        }
    }

    override getTooltipMenu(): TooltipMenu | null {
        const cancelButton = new ToolbarButton({ icon: "remove", key: "cancel" });

        const label = ((): string => {
            switch (this.flow.stage) {
                case 1:
                    return "Выберите основание";
                case 2:
                    return "Выберите основание";
                case 3:
                    return "Выберите второе основание";
                case 4:
                    return "Выберите второе основание (создать среднюю линию)";
            }
        })();

        return new TooltipMenu(label, [cancelButton]);
    }

    private findPointsOnViewFragment(a: TPoint, allViewFragments: TFragment[], allViewPoints: TPoint[]): TPoint[] {
        return filterEqualPoints(
            findVertexViewPointsOnFragments(a, allViewFragments, allViewPoints).flatMap(({ points }) => points)
        );
    }

    static create(data: Figure2DController) {
        return new MiddleFragmentInteractiveFlow(data);
    }

    private *getAvailablePairs(left: TPoint, right: TPoint): Iterable<TFragment> {
        const baseFragment = { a: left, b: right };

        const allViewPoints = findAllViewPoints(this.figure.models);

        const allViewFragments = findAllViewFragments(this.figure.models);
        const pointsA = this.findPointsOnViewFragment(left, allViewFragments, allViewPoints);
        const pointsB = this.findPointsOnViewFragment(right, allViewFragments, allViewPoints);

        const collection: Record<string, { fromA: TPoint[]; fromB: TPoint[] }> = {};

        for (const point of pointsA) {
            add(point, "fromA");
        }

        for (const point of pointsB) {
            add(point, "fromB");
        }

        for (const { fromA, fromB } of Object.values(collection)) {
            for (const a of fromA) {
                for (const b of fromB) {
                    yield { a, b };
                }
            }
        }

        function getHash(point: TPoint): string | null {
            const p = findPerpendicularPoint(baseFragment, point);
            const vector = subtractVectors(p, point);

            return isZeroPoint(vector) ? null : hashPoint(vector);
        }

        function add(point: TPoint, group: "fromA" | "fromB") {
            const hash = getHash(point);

            if (hash) {
                if (!collection[hash]) {
                    collection[hash] = { fromA: [], fromB: [] };
                }
                collection[hash][group].push(point);
            }
        }
    }

    private *findAvailablePoints(availablePairs: TFragment[], c: TPoint): Iterable<{ point: TPoint; isAC: boolean }> {
        for (const pair of availablePairs) {
            if (areSamePoints(c, pair.a)) {
                yield { point: pair.b, isAC: true };
            } else if (areSamePoints(c, pair.b)) {
                yield { point: pair.a, isAC: false };
            }
        }
    }

    private saveRaw = action((a: TPoint, b: TPoint, c: TPoint, d: TPoint) => {
        this.figure.insertModels(function* () {
            const model = MiddleLineModel.create({ base1: { a, b }, base2: { a: c, b: d } });
            yield model;

            yield PointModel.create({ ...a, style: null });
            yield PointModel.create({ ...b, style: null });
            yield PointModel.create({ ...c, style: null });
            yield PointModel.create({ ...d, style: null });
            yield FragmentModel.create({ a, b, style: null });
            yield FragmentModel.create({ a: c, b: d, style: null });
            yield FragmentModel.create({ a, b: c, style: null });
            yield FragmentModel.create({ a: b, b: d, style: null });

            yield LabelPointModel.createNext(model.middleLineFragment.a, this.figure);
            yield LabelPointModel.createNext(model.middleLineFragment.b, this.figure);
        }, this);
    });
}
