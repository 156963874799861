import { action, makeObservable, observable } from "mobx";

import type { IVariableContentVisitor } from "./IVariableContentVisitor";
import type { TSerializedState } from "@viuch/math-editor";

import { BaseVariableContent } from "./BaseVariableContent";

export class NumericSequenceVariableContent extends BaseVariableContent {
    @observable.ref formula: TSerializedState;
    @observable.ref count: TSerializedState;
    @observable.ref separator: TSerializedState;
    @observable.ref index_symbol: string;

    constructor(formula: TSerializedState, count: TSerializedState, separator: TSerializedState, index_symbol: string) {
        super();
        this.formula = formula;
        this.count = count;
        this.separator = separator;
        this.index_symbol = index_symbol;

        makeObservable(this);
    }

    accept<R>(visitor: IVariableContentVisitor<R>): R {
        return visitor.withNumericSequence(this);
    }

    @action.bound
    setFormula(formula: TSerializedState) {
        this.formula = formula;
    }

    @action.bound
    setCount(count: TSerializedState) {
        this.count = count;
    }

    @action.bound
    setSeparator(separator: TSerializedState) {
        this.separator = separator;
    }

    @action.bound
    setIndexSymbol(index_symbol: string) {
        this.index_symbol = index_symbol;
    }

    clone(): NumericSequenceVariableContent {
        return new NumericSequenceVariableContent(this.formula, this.count, this.separator, this.index_symbol);
    }
}
