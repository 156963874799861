import cn from "classnames";
import { observer } from "mobx-react-lite";
import React from "react";

import { radToDeg } from "@viuch/geometry-lib/angles";
import { getVectorAngle, normalizeVector } from "@viuch/geometry-lib/solvers";
import { subtractVectors } from "@viuch/geometry-lib/vectors";
import { range } from "@viuch/shared/utils/data";

import type { StrokeElement } from "./StrokeElement";

import styles from "./StrokeView.module.scss";

type Props = {
    stroke: StrokeElement;
    onPointerEvent(e: React.PointerEvent, stroke: StrokeElement): void;
};

const distance = 4;
const height = 8;

export const StrokeView = observer(function StrokeView({ stroke, onPointerEvent }: Props) {
    const { id, middle, segments, color, a, b, visible } = stroke;

    const handlePointerEvent = (e: React.PointerEvent) => onPointerEvent(e, stroke);

    const vector = normalizeVector(subtractVectors(a, b));
    const angle = radToDeg(getVectorAngle(vector));

    return (
        <g
            data-test-type="stroke"
            data-stroke-id={id}
            className={cn(!visible && styles.hidden)}
        >
            <circle
                cx={`${middle.x * 100}%`}
                cy={`${(1 - middle.y) * 100}%`}
                r={10}
                fill="transparent"
                onPointerDown={handlePointerEvent}
                onPointerMove={handlePointerEvent}
                onPointerUp={handlePointerEvent}
                onPointerCancel={handlePointerEvent}
            />
            <svg
                x={`${middle.x * 100}%`}
                y={`${(1 - middle.y) * 100}%`}
                className={styles.strokes}
            >
                <g transform={`rotate(${angle})`}>
                    {range(segments).map((i) => (
                        <line
                            key={i}
                            x1={i * distance - (segments - 1) * (distance / 2)}
                            y1={height / -2}
                            x2={i * distance - (segments - 1) * (distance / 2)}
                            y2={height / 2}
                            stroke={color.hex}
                            strokeLinecap="round"
                            strokeWidth={2}
                            opacity={color.opacity}
                        />
                    ))}
                </g>
            </svg>
        </g>
    );
});
