import { generateUuid } from "@viuch/shared/utils/data";

import type { ISerializedFunction } from "./types";
import type { TAnySerializedElement } from "../../types";

import { createSerializedContainer } from "../../utils/serialization";

export function createSerializedFunction(
    func: TAnySerializedElement[],
    arg: TAnySerializedElement[]
): ISerializedFunction {
    return {
        type: "function",
        uuid: generateUuid(),
        funcNameContent: createSerializedContainer(func),
        funcArgContent: createSerializedContainer(arg),
    };
}
