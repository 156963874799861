import type { TPathData, TPathVariants } from "./Interval.constants";
import type { TIntervalSign } from "../../statement/types";

import { paths } from "./Interval.constants";

export function getPath(pathsMap: TIntervalSign, first?: boolean, last?: boolean): TPathData {
    const variant: TPathVariants = first && last ? "alone" : first ? "first" : last ? "last" : "default";

    return paths[pathsMap][variant];
}
