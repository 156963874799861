import { numberBetweenMinMax as minmax } from "@viuch/shared/utils/data/min-max";

import type { Graph2DViewportController } from "../../editor/core/Graph2DViewportController";
import type { TPoint } from "@viuch/geometry-lib/types";
import type { TSize } from "@viuch/math-editor/types";

export type TAxisPositionsComputeResult = {
    axis: {
        head: number;
        tail: number;
    };
    position: number;
    labels: {
        min: number;
        max: number;
    };
};

export function getXData(
    rootSize: TSize,
    viewport: Graph2DViewportController,
    zero: TPoint
): TAxisPositionsComputeResult {
    const { left, top, right, bottom } = viewport.bounds;
    const { scale } = viewport;

    const pw = (v: number) => v / (rootSize.w * scale);
    const ph = (v: number) => v / (rootSize.h * scale);

    const yCenter = zero.y;

    // положение оси
    const axis_pos = minmax(yCenter, top + ph(30), bottom - ph(10));

    const axis_start = left;
    const axis_end = right - pw(10);

    const stroke_min: number = left + pw(10);
    const stroke_max: number = right - pw(30);

    return {
        position: axis_pos,
        axis: {
            head: axis_start,
            tail: axis_end,
        },
        labels: {
            min: stroke_min,
            max: stroke_max,
        },
    };
}

export function getYData(
    rootSize: TSize,
    viewport: Graph2DViewportController,
    zero: TPoint
): TAxisPositionsComputeResult {
    const { left, top, right, bottom } = viewport.bounds;
    const { scale } = viewport;

    const pw = (v: number) => v / (rootSize.w * scale);
    const ph = (v: number) => v / (rootSize.h * scale);

    const xCenter = zero.x;

    const axis_pos = minmax(xCenter, left + pw(50), right - pw(30));

    const axis_tail = bottom;
    const axis_head = top + ph(10);

    const stroke_min = 1 - (bottom - ph(10));
    const stroke_max = 1 - (top + ph(30));

    return {
        position: axis_pos,
        axis: {
            head: axis_head,
            tail: axis_tail,
        },
        labels: {
            min: stroke_min,
            max: stroke_max,
        },
    };
}
