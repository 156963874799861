import { generateUuid } from "@viuch/shared/utils/data";

import type { ISerializedSpace } from "./types";
import type { TAnySerializedElement } from "../../types";

export function createSerializedSpace(): ISerializedSpace {
    return { type: "space", uuid: generateUuid() };
}

export function isSerializedSpace(serializedElement: TAnySerializedElement): serializedElement is ISerializedSpace {
    return serializedElement.type === "space";
}
