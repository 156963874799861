import { action, computed, makeObservable, observable } from "mobx";
import { createTransformer } from "mobx-utils";

import { areSamePoints } from "@viuch/geometry-lib/check-geometry";

import type { TFragmentLikeFigure } from "./types";
import type { BaseElement } from "../../elements";
import type { Figure2DController } from "../../Figure2DController";
import type { ToolbarButton, ToolbarMenu } from "../../toolbar";
import type { TToolbarIcons } from "../../toolbar/icons";
import type { IUserPointer } from "../../viewport/types";
import type { TPoint } from "@viuch/geometry-lib/types";
import type React from "react";

import { DotElement } from "../../elements/dot";
import { ElementColor } from "../../elements/ElementColor";
import { LineElement } from "../../elements/line";
import { VectorElement } from "../../elements/line/VectorElement";
import { FragmentDashedModel, FragmentModel, VectorDashedModel, VectorModel } from "../../models/fragment";
import { LabelPointModel } from "../../models/label-point";
import { PointModel } from "../../models/point";
import { ButtonStates } from "../../toolbar";
import { TooltipMenu } from "../../toolbar/tooltip";
import { createDefaultToolbarMenuBuilder, handleToolbarButtons } from "../../utils/toolbar";
import { BaseFlow } from "../BaseFlow";
import { createMapModelToElements, opacifyColor, z } from "../utils";

export class CreateFragmentFlow extends BaseFlow {
    flow:
        | {
              stage: 1;
              a?: TPoint;
              drawing: boolean;
          }
        | {
              stage: 2;
              readonly a: TPoint;
              b?: TPoint;
              drawing: boolean;
          };
    readonly figureType: TFragmentLikeFigure;
    private readonly isRepeated: boolean;

    constructor(data: Figure2DController, figureType: TFragmentLikeFigure, isRepeated = false) {
        super(data);

        this.flow = { stage: 1, drawing: false };
        this.figureType = figureType;
        this.isRepeated = isRepeated;

        makeObservable(this, {
            flow: observable,
            modelsElements: computed,
        });
    }

    attach(): void {
        this.viewport.disable();

        this.snap.addViewportGrid().addFigureModelsPoints();
    }

    dispose(): void {
        this.snap.clean();
    }

    mapModel = createTransformer(createMapModelToElements(opacifyColor));

    get modelsElements(): BaseElement[] {
        return this.figure.models.flatMap(this.mapModel);
    }

    renderElements(): BaseElement[] {
        return [...this.modelsElements, ...this.getDrawingElements()];
    }

    *getDrawingElements(): Iterable<BaseElement> {
        const { drawing } = this.flow;
        if (this.flow.stage === 1) {
            const { a } = this.flow;

            if (a) {
                yield new DotElement({
                    id: `temp_a`,
                    x: a.x,
                    y: a.y,
                    color: drawing ? ElementColor.Selected : ElementColor.Building,
                    overrideRenderOrder: z.points.priority,
                });
            }
            return;
        }

        const { a, b } = this.flow;

        yield new DotElement({
            id: `temp_a`,
            x: a.x,
            y: a.y,
            color: ElementColor.Building,
            overrideRenderOrder: z.points.priority,
        });

        if (b) {
            const { figureType } = this;

            const isVector = figureType === "vector" || figureType === "vector-dash";
            const isDashed = figureType === "fragment-dash" || figureType === "vector-dash";

            if (isVector) {
                yield new VectorElement({
                    id: `temp_ab`,
                    x1: a.x,
                    y1: a.y,
                    x2: b.x,
                    y2: b.y,
                    color: drawing ? ElementColor.Selected : ElementColor.Building,
                    overrideRenderOrder: z.fragments.priority,
                    isDashed,
                });
            } else {
                yield new DotElement({
                    id: `temp_b`,
                    x: b.x,
                    y: b.y,
                    color: drawing ? ElementColor.Selected : ElementColor.Building,
                    overrideRenderOrder: z.points.priority,
                });

                yield new LineElement({
                    id: `temp_ab`,
                    x1: a.x,
                    y1: a.y,
                    x2: b.x,
                    y2: b.y,
                    color: drawing ? ElementColor.Selected : ElementColor.Building,
                    overrideRenderOrder: z.fragments.priority,
                    isDashed,
                });
            }
        }
    }

    override handleViewportPointerEvent(e: React.PointerEvent, pointer: IUserPointer) {
        if (e.type === "pointercancel") return this.nextFlow();
        if (!e.isPrimary) return;

        const point = this.snap.snap(pointer.canvas, pointer.canvas);

        this.movePoint(e, point);
        this.startDrawing(e);
        this.endDrawing(e);
    }

    private movePoint(e: React.PointerEvent, point: TPoint) {
        if (e.type === "pointermove" || e.type === "pointerdown") {
            switch (this.flow.stage) {
                case 1:
                    this.flow.a = point;
                    break;
                case 2:
                    this.flow.b = point;
                    break;
            }
        }
    }

    private startDrawing(e: React.PointerEvent) {
        if (e.type === "pointerdown") {
            this.flow.drawing = true;
        }
    }

    private endDrawing(e: React.PointerEvent) {
        if (e.type === "pointerup") {
            this.flow.drawing = false;

            switch (this.flow.stage) {
                case 1:
                    if (this.flow.a) {
                        this.flow = {
                            stage: 2,
                            drawing: false,
                            a: this.flow.a,
                        };
                    }
                    break;
                case 2: {
                    const { a, b } = this.flow;
                    if (b) {
                        if (!areSamePoints(a, b)) {
                            this.saveRaw(a, b);
                            this.nextFlow((data) => new CreateFragmentFlow(data, this.figureType, true));
                        } else {
                            this.nextFlow();
                        }
                    }
                    break;
                }
            }
        }
    }

    private saveRaw = action((a: TPoint, b: TPoint) => {
        const { figureType } = this;
        this.figure.insertModels(function* () {
            switch (figureType) {
                case "fragment":
                    yield PointModel.create({ ...a, style: null });
                    yield PointModel.create({ ...b, style: null });
                    yield LabelPointModel.createNext(a, this.figure);
                    yield LabelPointModel.createNext(b, this.figure);

                    yield FragmentModel.create({ a, b, style: null });
                    break;
                case "fragment-dash":
                    yield PointModel.create({ ...a, style: null });
                    yield PointModel.create({ ...b, style: null });
                    yield LabelPointModel.createNext(a, this.figure);
                    yield LabelPointModel.createNext(b, this.figure);

                    yield FragmentDashedModel.create({ a, b, style: null });
                    break;
                case "vector":
                    yield PointModel.create({ ...a, style: null });

                    yield LabelPointModel.createNext(a, this.figure);
                    yield LabelPointModel.createNext(b, this.figure);

                    yield VectorModel.create({ from: a, to: b, style: null });
                    break;
                case "vector-dash":
                    yield PointModel.create({ ...a, style: null });

                    yield LabelPointModel.createNext(a, this.figure);
                    yield LabelPointModel.createNext(b, this.figure);

                    yield VectorDashedModel.create({ from: a, to: b, style: null });
                    break;
            }
        }, this);
    });

    override getToolbarMenu(): ToolbarMenu {
        const map: Record<TFragmentLikeFigure, TToolbarIcons> = {
            fragment: "fragment",
            vector: "vector",
            "fragment-dash": "fragment-dashed",
            "vector-dash": "vector-dashed",
        };
        const icon: TToolbarIcons = map[this.figureType];

        return createDefaultToolbarMenuBuilder()
            .setButtonState(ButtonStates.active, ["fragments"])
            .setButtonIcon(icon, ["fragments"])
            .build();
    }

    override handleToolbarButtonClick(menu: ToolbarMenu, button: ToolbarButton): void {
        switch (button.key) {
            case "fragments":
                return this.nextFlow();
            default:
                handleToolbarButtons(this, button);
        }
    }

    override getTooltipMenu(): TooltipMenu | null {
        let text: string;

        if (this.isRepeated) {
            text = "Продолжайте построение или нажмите на кнопку курсора, чтобы закончить построение";
        } else {
            if (this.figureType === "vector" || this.figureType === "vector-dash") {
                switch (this.flow.stage) {
                    case 1:
                        text = "Поставьте первую точку вектора";
                        break;
                    case 2:
                        text = "Поставьте вторую точку вектора";
                        break;
                }
            } else {
                switch (this.flow.stage) {
                    case 1:
                        text = "Поставьте точку начала отрезка";
                        break;
                    case 2:
                        text = "Поставьте точку конца отрезка";
                        break;
                }
            }
        }

        return new TooltipMenu(text, []);
    }
}
