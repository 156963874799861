import { Video } from "../../entities/videos";
import { BaseCRUDService } from "../base";

export class VideosService extends BaseCRUDService<Video, TVideoRequest, TVideoResponse> {
    constructor() {
        super("/videos/", mapVideo, serializeVideo);
    }
}

export type TVideoRequest = {
    id: number;
    url: string;
    name: string;
};

export type TVideoResponse = TVideoRequest;

export function mapVideo(data: TVideoRequest): Video {
    return new Video(data);
}

export function serializeVideo({ id, name, url }: Video): TVideoRequest {
    return { id, name, url };
}
