import { action, makeObservable, observable } from "mobx";

export class FrequentlyAskedQuestion {
    constructor(question = "", answer = "") {
        this.question = question;
        this.answer = answer;

        makeObservable(this);
    }

    @observable question: string;
    @observable answer: string;

    @action.bound
    setQuestion(question: string) {
        this.question = question;
    }

    @action.bound
    setAnswer(answer: string) {
        this.answer = answer;
    }
}

export class FrequentlyAskedQuestionsList {
    constructor(questions: FrequentlyAskedQuestion[], email = "") {
        this.questions = questions;
        this.email = email;

        makeObservable(this);
    }

    @observable questions: FrequentlyAskedQuestion[];
    @observable email: string;

    @action.bound
    setEmail(email: string) {
        this.email = email;
    }

    @action.bound
    addEmptyQuestion() {
        this.questions.push(new FrequentlyAskedQuestion());
    }

    @action.bound
    removeQuestionByIndex(index: number) {
        this.questions.splice(index, 1);
    }

    @action.bound
    swapQuestions(firstIndex: number, secondIndex: number) {
        [this.questions[firstIndex], this.questions[secondIndex]] = [
            this.questions[secondIndex],
            this.questions[firstIndex],
        ];
    }
}
