import { makeObservable, observable, action, computed } from "mobx";

import { compareSimple } from "@viuch/shared/utils/data";
import { validateSlug } from "@viuch/shared/utils/data/slug";

export class IndexingEntityMetadata {
    @observable title: string | null;
    @observable description: string | null;
    @observable slug: string;

    constructor(title: string | null, description: string | null, slug: string) {
        this.title = title;
        this.description = description;
        this.slug = slug;

        makeObservable(this);
    }

    @computed get slugString(): string {
        return this.slug;
    }

    @computed get titleString(): string {
        return this.title ?? "";
    }

    @computed get descriptionString(): string {
        return this.description ?? "";
    }

    @action.bound
    setTitle(title: string | null) {
        if (title?.trim() === "") {
            this.title = null;
        } else {
            this.title = title;
        }
    }

    @action.bound
    setDescription(description: string | null) {
        if (description?.trim() === "") {
            this.description = null;
        } else {
            this.description = description;
        }
    }

    @action.bound
    setSlug(slug: string) {
        this.slug = slug;
    }

    @computed
    get slugValidationError(): string | undefined {
        return validateSlug(this.slug);
    }

    equalsTo(indexing: IndexingEntityMetadata): boolean {
        switch (false) {
            case compareSimple(this.slug, indexing.slug):
            case compareSimple(this.title, indexing.title):
            case compareSimple(this.description, indexing.description):
                return false;
        }
        return true;
    }

    clone(): IndexingEntityMetadata {
        return new IndexingEntityMetadata(this.title, this.description, this.slug);
    }
}
