import type { TCellRenderProps } from "./types";
import type { BaseTableInstrumentSettingsCell } from "../settings/cells";
import type { ReactElement } from "react";

import { cellRenderer } from "./constants";

export function useCellEditorRenderer(): (
    cell: BaseTableInstrumentSettingsCell,
    props: TCellRenderProps
) => ReactElement {
    return (cell, props) => {
        return cell.accept(cellRenderer)(props);
    };
}
