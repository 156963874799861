import type { ContainerModel } from "../../container";
import type { BaseElementModel } from "../../element";
import type { ILimit } from "../ILimit";

import { FactorialsTogetherElementsVisitor } from "../utils/FactorialsTogetherElementsVisitor";

export class FactorialsBetweenBracketsLimit implements ILimit {
    private readonly maxCount: number;
    private readonly visitor: FactorialsTogetherElementsVisitor;

    public constructor(maxCount: number) {
        this.maxCount = maxCount;
        this.visitor = new FactorialsTogetherElementsVisitor();
    }

    filterInsertElements(
        elements: BaseElementModel[],
        containerToInsert: ContainerModel,
        indexToInsert: number
    ): BaseElementModel[] {
        if (indexToInsert < 3) {
            return elements;
        }

        if (elements[0].accept(this.visitor) !== "factorial") {
            return elements;
        }

        const rightBracket = containerToInsert.elements[indexToInsert - 1];
        if (rightBracket.accept(this.visitor) !== "bracket-right") {
            return elements;
        }

        let bracketsLevel = 1;
        let factorialsCount = 1;

        for (let i = indexToInsert - 2; i >= 0; i--) {
            const element = containerToInsert.elements[i];
            const elementType = element.accept(this.visitor);

            if (elementType === "bracket-right") {
                bracketsLevel++;
            }

            if (elementType === "bracket-left") {
                if (bracketsLevel === 0) {
                    return elements;
                }
                bracketsLevel--;

                if (bracketsLevel === 0) {
                    if (factorialsCount > this.maxCount) {
                        return [];
                    }
                    return elements;
                }
            }

            if (elementType === "stop") {
                return elements;
            }

            if (elementType === "factorial") {
                factorialsCount++;
            }
        }

        return [];
    }
}
