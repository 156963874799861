import type { TAnyAction } from "../types";

export type TFunctionAction = { type: "function"; filled?: boolean };

type TFunctionOptions = { filled: true };

export function createFunctionAction(options?: TFunctionOptions): TFunctionAction {
    return { type: "function", filled: options?.filled };
}

export function isFunctionAction(action: TAnyAction): action is TFunctionAction {
    return action.type === "function";
}
