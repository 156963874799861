import cn from "classnames";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

import { transformPoint } from "@viuch/geometry-lib/transformations/functions";
import { MathExpressionView } from "@viuch/math-editor";

import type { Graph2DViewportController } from "../editor/core/Graph2DViewportController";
import type { Graph2DViewSettings } from "../editor/core/Graph2DViewSettings";
import type { LabelViewItem } from "../view-models/LabelViewItem";

import styles from "./LabelView.module.scss";

type Props = {
    item: LabelViewItem;
    viewport: Graph2DViewportController;
    settings: Graph2DViewSettings;
};

export const LabelView = observer(function LabelView({ item, viewport, settings }: Props) {
    const { color, position, label } = item;

    const { x, y } = useMemo(() => {
        const { x, y } = item;
        return transformPoint({ x, y }, [...settings.coordinateSystemTransform, ...viewport.viewportTransformations]);
    }, [settings, viewport, item]);

    return (
        <foreignObject
            data-info="label"
            data-x={item.x}
            data-y={item.y}
            width={1}
            height={1}
            x={`${x}%`}
            y={`${y}%`}
        >
            <div
                className={cn(
                    styles.mathLabelContainer,
                    position === "top" && styles._up,
                    position === "bottom" && styles._down
                )}
                style={{ color }}
            >
                <MathExpressionView
                    mathExpression={label}
                    className={styles.mathLabel}
                    formulaClassName={styles.mathLabel__formula}
                />
            </div>
        </foreignObject>
    );
});
