import { assert } from "@viuch/utils/debug";

import type { BaseElementModel } from "../element";
import type { IElementVisitor } from "../element/IElementVisitor";

export type TElementPlaceholderCategory = "eq" | "low" | "high" | "char" | "break" | "left" | "right" | "degree";

export interface IElementPlaceholdersCategory {
    getCategory(): TElementPlaceholderCategory;
    shouldShowPlaceholder(left?: BaseElementModel, right?: BaseElementModel): boolean;
}

enum S {
    HIDE,
    SHOW,
    CBER,
    CBEL,
}

export class ElementPlaceholdersChecker
    implements IElementPlaceholdersCategory, IElementVisitor<TElementPlaceholderCategory>
{
    private constructor() {}

    private static instance?: IElementPlaceholdersCategory;

    static Singleton(): IElementPlaceholdersCategory {
        this.instance = this.instance ?? new ElementPlaceholdersChecker();
        return this.instance;
    }

    getCategory(element?: BaseElementModel): TElementPlaceholderCategory {
        return element?.accept(this) ?? "break";
    }

    private static matrixOrder: TElementPlaceholderCategory[] = [
        "eq",
        "low",
        "high",
        "char",
        "break",
        "left",
        "right",
        "degree",
    ];

    private static categoryMatrix = [
        //   eq     low    high    char   break    left   right  degree
        [S.SHOW, S.HIDE, S.SHOW, S.HIDE, S.CBER, S.SHOW, S.HIDE, S.SHOW], // eq
        [S.SHOW, S.SHOW, S.SHOW, S.HIDE, S.CBER, S.SHOW, S.HIDE, S.SHOW], // low
        [S.SHOW, S.SHOW, S.SHOW, S.HIDE, S.CBER, S.SHOW, S.HIDE, S.SHOW], // high
        [S.HIDE, S.HIDE, S.HIDE, S.HIDE, S.HIDE, S.HIDE, S.HIDE, S.HIDE], // char
        [S.CBEL, S.HIDE, S.CBEL, S.HIDE, S.HIDE, S.SHOW, S.HIDE, S.SHOW], // break
        [S.HIDE, S.HIDE, S.HIDE, S.HIDE, S.HIDE, S.HIDE, S.SHOW, S.HIDE], // left (_^2)
        [S.SHOW, S.SHOW, S.SHOW, S.HIDE, S.SHOW, S.SHOW, S.HIDE, S.SHOW], // right (log_)
        [S.HIDE, S.HIDE, S.HIDE, S.HIDE, S.HIDE, S.HIDE, S.SHOW, S.SHOW], // degree
    ];

    shouldShowPlaceholder(left?: BaseElementModel, right?: BaseElementModel): boolean {
        const order = ElementPlaceholdersChecker.matrixOrder;
        const leftCat = this.getCategory(left);
        const rightCat = this.getCategory(right);
        const leftIndex = order.indexOf(leftCat);
        const rightIndex = order.indexOf(rightCat);
        const category = ElementPlaceholdersChecker.categoryMatrix[leftIndex][rightIndex];

        if (category === S.HIDE) {
            return false;
        }

        if (category === S.SHOW) {
            return true;
        }

        if (right && category === S.CBEL) {
            const next = left?.getLeft();
            return next ? !next.isEquals(right) : true;
        }

        if (left && category === S.CBER) {
            const next = right?.getRight();
            return next ? !next.isEquals(left) : true;
        }

        assert(false, { left, right });
    }

    protected defaultBehaviour(): TElementPlaceholderCategory {
        return "char";
    }

    doWithEquality(): TElementPlaceholderCategory {
        return "eq";
    }

    doWithOperatorLow(): TElementPlaceholderCategory {
        return "low";
    }

    doWithOperatorHigh(): TElementPlaceholderCategory {
        return "high";
    }

    doWithLinebreak(): TElementPlaceholderCategory {
        return "break";
    }

    doWithDegree(): TElementPlaceholderCategory {
        return "degree";
    }

    doWithDerivative(): TElementPlaceholderCategory {
        return "left";
    }

    doWithAffiliation(): TElementPlaceholderCategory {
        return "high";
    }

    doWithAngle(): TElementPlaceholderCategory {
        return "right";
    }

    doWithDownIndex(): TElementPlaceholderCategory {
        return "left";
    }

    doWithFraction(): TElementPlaceholderCategory {
        return "char";
    }

    doWithSpace(): TElementPlaceholderCategory {
        return "char";
    }

    doWithBracket(): TElementPlaceholderCategory {
        return "char";
    }

    doWithChar(): TElementPlaceholderCategory {
        return "char";
    }

    doWithComplexIntegral(): TElementPlaceholderCategory {
        return "char";
    }

    doWithCoordinatesVector(): TElementPlaceholderCategory {
        return "char";
    }

    doWithDifferential(): TElementPlaceholderCategory {
        return "char";
    }

    doWithDigit(): TElementPlaceholderCategory {
        return "char";
    }

    doWithEditor(): TElementPlaceholderCategory {
        return "char";
    }

    doWithEquationsSet(): TElementPlaceholderCategory {
        return "char";
    }

    doWithFunction(): TElementPlaceholderCategory {
        return "char";
    }

    doWithGeometry(): TElementPlaceholderCategory {
        return "char";
    }

    doWithGrade(): TElementPlaceholderCategory {
        return "left";
    }

    doWithIntegral(): TElementPlaceholderCategory {
        return "char";
    }

    doWithLim(): TElementPlaceholderCategory {
        return "char";
    }

    doWithLog(): TElementPlaceholderCategory {
        return "char";
    }

    doWithModule(): TElementPlaceholderCategory {
        return "char";
    }

    doWithMultitude(): TElementPlaceholderCategory {
        return "high";
    }

    doWithNaturalLog(): TElementPlaceholderCategory {
        return "char";
    }

    doWithResult(): TElementPlaceholderCategory {
        return "high";
    }

    doWithRoot(): TElementPlaceholderCategory {
        return "char";
    }

    doWithSlash(): TElementPlaceholderCategory {
        return "eq";
    }

    doWithSymbol(): TElementPlaceholderCategory {
        return "char";
    }

    doWithTopAngle(): TElementPlaceholderCategory {
        return "char";
    }

    doWithTrigonometricOperator(): TElementPlaceholderCategory {
        return "char";
    }

    doWithVector(): TElementPlaceholderCategory {
        return "char";
    }
}
