import { computed, makeObservable, observable } from "mobx";

import { getLineAngle, getMiddleAngle, getUnitVectorFromAngle } from "@viuch/geometry-lib/angles";
import { copyAngle, copyBasis, createBasis, createFragment, createLine } from "@viuch/geometry-lib/factories";
import { intersectionLines } from "@viuch/geometry-lib/intersection";
import { addVectors } from "@viuch/geometry-lib/vectors";
import { generateId, sortAsc } from "@viuch/shared/utils/data";

import type { TModelStyle } from "../modelStyle";
import type { TAngle, TBasis, TFragment, TLine, TPoint } from "@viuch/geometry-lib/types";

import { BaseModel } from "../BaseModel";

import { getRealBasis } from "./utils";

export interface IBisectModel {
    angle: TAngle;
    segmentsCount: number;
    basis?: TBasis;
    style: TModelStyle | null;
}

export class BisectionModel extends BaseModel implements IBisectModel {
    angle: TAngle;
    basis: TBasis;
    segmentsCount: number;
    style: TModelStyle | null;

    constructor(data: IBisectModel, id: number) {
        super(id);
        this.angle = copyAngle(data.angle);
        this.basis = data.basis ? copyBasis(data.basis) : createBasis({ a: data.angle.start, b: data.angle.end });
        this.segmentsCount = data.segmentsCount;
        this.style = data.style;

        makeObservable(this, {
            midPoint: computed,
            bisectionFragment: computed,
            angle: observable,
            basis: observable,
            segmentsCount: observable,
            style: observable,
        });
    }

    get angles(): Record<"angleFirst" | "angleLast" | "angleMiddle", number> {
        const { start, vertex, end } = this.angle;
        const line1 = createLine(vertex, start);
        const line2 = createLine(vertex, end);

        const angle1 = getLineAngle(line1, true);
        const angle2 = getLineAngle(line2, true);
        let [angleFirst, angleLast] = [angle1, angle2].sort(sortAsc);

        if (angleLast - angleFirst > Math.PI) {
            [angleLast, angleFirst] = [angleFirst, angleLast];
        }
        const angleMiddle = getMiddleAngle(angleFirst, angleLast);
        return { angleLast, angleFirst, angleMiddle };
    }

    get midPoint(): TPoint | null {
        const { vertex } = this.angle;
        const { angleMiddle } = this.angles;
        const vector = getUnitVectorFromAngle(angleMiddle);

        const midLine = createLine(vertex, addVectors(vertex, vector));

        return intersectionLines(midLine, this.realBasis);
    }

    get realBasis(): TLine {
        const { basis } = this;
        return createLine(getRealBasis(basis));
    }

    get bisectionFragment(): TFragment | null {
        return this.toBisectionFragment();
    }

    accept<R>(visitor: IBisectionModelVisitor<R>): R {
        return visitor.withBisection(this);
    }

    static create(data: IBisectModel) {
        return new BisectionModel(data, generateId());
    }

    toBisectionFragment(): TFragment | null {
        return this.midPoint ? createFragment(this.angle.vertex, this.midPoint) : null;
    }
}

export interface IBisectionModelVisitor<R> {
    withBisection: (bisection: BisectionModel) => R;
}
