import cn from "classnames";
import { observer } from "mobx-react-lite";

import { normalizeNumber } from "@viuch/shared/utils/math/normalize";
import { roundToStep } from "@viuch/shared/utils/math/round";

import type { RefObject, MouseEvent, PropsWithChildren } from "react";

import { GRAPH_STEPS } from "../constants";

import styles from "./Line.module.scss";

type Props = PropsWithChildren<{
    onLineClick?(position: number): void;
    axis: string;
    className?: string;
    lineRef: RefObject<HTMLDivElement>;
}>;

export const Line = observer(function Line({ className, children, onLineClick, axis, lineRef }: Props) {
    const handleClick = (e: MouseEvent) => {
        const { width, left } = e.currentTarget.getBoundingClientRect();

        const position = roundToStep(normalizeNumber((e.clientX - left) / width, { min: 0, max: 1 }), GRAPH_STEPS);

        onLineClick?.(position);
    };

    return (
        <div className={cn(className, styles.wrapper)}>
            <div className={styles.line}>
                <div
                    className={styles.clickArea}
                    onClick={handleClick}
                />
                <div
                    ref={lineRef}
                    className={styles.dots}
                >
                    {children}
                </div>
            </div>
            <div className={styles.arrow}>
                <div className={styles.axis}>{axis}</div>
            </div>
        </div>
    );
});
