import { action, makeObservable, observable } from "mobx";

import { createSerializedContainer } from "@viuch/math-editor";

import type { IVariableContentVisitor } from "./IVariableContentVisitor";
import type { TSerializedState } from "@viuch/math-editor";

import { BaseVariableContent } from "./BaseVariableContent";

export class RandomChoiceVariableContent extends BaseVariableContent {
    @observable.shallow choices: TSerializedState[];

    constructor(choices: TSerializedState[]) {
        super();

        this.choices = choices.slice();

        makeObservable(this);
    }

    accept<R>(visitor: IVariableContentVisitor<R>): R {
        return visitor.withRandomChoice(this);
    }

    @action.bound
    addChoice() {
        this.choices.push(createSerializedContainer());
    }

    @action.bound
    removeChoiceByIndex(index: number) {
        this.choices.splice(index, 1);
    }

    @action.bound
    changeChoice(index: number, content: TSerializedState) {
        this.choices[index] = content;
    }

    clone(): RandomChoiceVariableContent {
        return new RandomChoiceVariableContent(this.choices);
    }
}
