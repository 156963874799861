import cn from "classnames";
import React from "react";

import type { TToolbarIcons } from "../icons";

import { ToolbarIcon } from "../icons";

import styles from "./ToolbarButton.module.scss";

type Props = {
    icon: TToolbarIcons | string;
    className?: string;
    active?: boolean;
    disabled?: boolean;
    label?: string;
    child?: boolean;
    onClick?: React.MouseEventHandler;
    onBlur?: VoidFunction;
};

export const ToolbarButton: React.VFC<Props> = function ToolbarButton({
    className,
    icon,
    active,
    disabled,
    label,
    child,
    onClick,
    onBlur,
}) {
    return (
        <button
            className={cn(styles.button, className, active && styles.active)}
            disabled={disabled}
            onClick={onClick}
            onBlur={() => onBlur?.()}
        >
            <span className={styles.icon}>
                <span className={styles.buttonIcon}>
                    <ToolbarIcon
                        icon={icon}
                        className={styles.buttonIconInner}
                    />
                </span>
                {child && <div className={styles.openInnerButton} />}
            </span>
            {label && (
                <span className={styles.label}>
                    <span className={styles.text}>{label}</span>
                </span>
            )}
        </button>
    );
};
