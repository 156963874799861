import type { TrigonometricOperatorModel } from "./TrigonometricOperatorModel";
import type { TElementProps } from "../../types/element";
import type { VFC } from "react";

import { ContainerElement } from "../../core/container";
import { RoundBrackets } from "../../core/dynamic-brackets";
import { useMathEditorElementRef } from "../../hooks";

import styles from "./TrigonometricOperatorElement.module.scss";

export const TrigonometricOperatorElement: VFC<TElementProps<TrigonometricOperatorModel>> = ({ elementModel }) => {
    const ref = useMathEditorElementRef(elementModel);

    return (
        <span
            ref={ref}
            className={styles.wrapper}
        >
            <span className={styles.inline}>
                {elementModel.symbolName}
                <ContainerElement
                    containerModel={elementModel.degreeContent}
                    className={styles.degree}
                    inline
                />
            </span>
            <RoundBrackets>
                <ContainerElement
                    containerModel={elementModel.content}
                    inline
                />
            </RoundBrackets>
        </span>
    );
};
