import cn from "classnames";
import { observer } from "mobx-react-lite";
import React from "react";

import { MathInput2 } from "@viuch/ui-common";
import { BaseInput } from "@viuch/ui-common/BaseInput";

import type { EvalGraphSettings } from "../../settings-entity/graphs/EvalGraphSettings.ts/EvalGraphSettings";
import type { KeyboardService } from "@viuch/math-editor";

import styles from "./Graph2DInstrumentSettingsGraphEditor.module.scss";

type Props = {
    graph: EvalGraphSettings;
    onDelete(graph: EvalGraphSettings): void;
    keyboardService: KeyboardService;
    className?: string;
};

export const Graph2DInstrumentSettingsGraphEditor = observer(function Graph2DInstrumentSettingsGraphEditor({
    onDelete,
    graph,
    keyboardService,
    className,
}: Props) {
    const { func, setFunc, color, setColor, sign, setSign } = graph;

    return (
        <div className={cn(styles.layout, className)}>
            <h5 className={styles.title}>Произвольный график</h5>
            <MathInput2
                title="Функция Y = ..."
                value={func}
                onChange={setFunc}
                keyboardService={keyboardService}
            />
            <BaseInput title="Цвет графика">
                <div className={styles.colors}>
                    <input
                        type="color"
                        value={color}
                        onChange={(e) => setColor(e.target.value)}
                    />
                    {colors.map((color, i) => (
                        <button
                            key={i}
                            onClick={() => setColor(color)}
                            style={{ "--color": color }}
                            className={styles.colorButton}
                        >
                            {" "}
                        </button>
                    ))}
                </div>
            </BaseInput>
            <BaseInput title="Знак графика">
                <div className={styles.signs}>
                    {signs.map(({ token, label }) => (
                        <button
                            key={token}
                            className={cn(styles.signsButton, sign === token && styles._active)}
                            onClick={() => setSign(token)}
                        >
                            {label}
                        </button>
                    ))}
                </div>
            </BaseInput>
            <button
                onClick={() => onDelete(graph)}
                className={styles.deleteButton}
            >
                <i className={styles.deleteButton__icon} />
                Удалить график
            </button>
        </div>
    );
});

const colors = ["#ffffff", "#21f0a5", "#f18921", "#ff603d", "#439bff"];
const signs = [
    { token: "less-equals", label: "≤" },
    { token: "less", label: "<" },
    { token: "equals", label: "=" },
    { token: "greater", label: ">" },
    { token: "greater-equals", label: "≥" },
] as const;
