type TCreateAnimationFrameOptions = {
    onFrame(currentTime: number, diffTime: number): void;
};

type TDispose = VoidFunction;

export function createAnimationFrame({ onFrame }: TCreateAnimationFrameOptions): TDispose {
    let handle = requestAnimationFrame(runFrame);

    let lastTime = window.performance.now();

    function runFrame(time: number): void {
        handle = requestAnimationFrame(runFrame);
        onFrame(time, time - lastTime);
        lastTime = time;
    }

    return () => {
        cancelAnimationFrame(handle);
    };
}
