import { makeObservable, observable, action, computed } from "mobx";

import { compareSimple } from "@viuch/shared/utils/data";

export class IndexingEntityMetadataOnly {
    @observable title: string | null;
    @observable description: string | null;

    constructor(title: string | null, description: string | null) {
        this.title = title;
        this.description = description;

        makeObservable(this);

        this.setTitle(this.title);
        this.setDescription(this.description);
    }

    @computed get titleString(): string {
        return this.title ?? "";
    }

    @computed get descriptionString(): string {
        return this.description ?? "";
    }

    @computed get titleIsEmpty(): boolean {
        return this.title === null;
    }

    @computed get descriptionIsEmpty(): boolean {
        return this.description === null;
    }

    @action.bound
    setTitle(title: string | null) {
        if (title?.trim() === "") {
            this.title = null;
        } else {
            this.title = title;
        }
    }

    @action.bound
    setDescription(description: string | null) {
        if (description?.trim() === "") {
            this.description = null;
        } else {
            this.description = description;
        }
    }

    equalsTo(indexing: IndexingEntityMetadataOnly): boolean {
        switch (false) {
            case compareSimple(this.title, indexing.title):
            case compareSimple(this.description, indexing.description):
                return false;
        }
        return true;
    }

    clone(): IndexingEntityMetadataOnly {
        return new IndexingEntityMetadataOnly(this.title, this.description);
    }
}
