import type { TAnyAction } from "../types";

export type TTopAngleAction = { type: "top-angle" };

export function createTopAngleAction(): TTopAngleAction {
    return { type: "top-angle" };
}

export function isTopAngleAction(action: TAnyAction): action is TTopAngleAction {
    return action.type === "top-angle";
}
