import { makeObservable, observable } from "mobx";

import type { IElementVisitor } from "../BaseElement";
import type { ElementColor } from "../ElementColor";
import type { TFragment, TLine } from "@viuch/geometry-lib/types";

import { BaseElement } from "../BaseElement";

export interface ILineElement {
    id: string;
    x1: number;
    y1: number;
    x2: number;
    y2: number;
    thin?: boolean;
    isDashed?: boolean;
    color?: ElementColor;
    overrideRenderOrder: number;
}

export class LineElement extends BaseElement implements ILineElement {
    x1: number;
    y1: number;
    x2: number;
    y2: number;
    thin: boolean;
    isDashed: boolean;

    constructor(data: ILineElement) {
        super(data);
        this.x1 = data.x1;
        this.y1 = data.y1;
        this.x2 = data.x2;
        this.y2 = data.y2;
        this.thin = !!data.thin;
        this.isDashed = !!data.isDashed;

        makeObservable(this, {
            x1: observable,
            y1: observable,
            x2: observable,
            y2: observable,
            isDashed: observable,
        });
    }

    static create(data: ILineElement) {
        return new LineElement(data);
    }

    accept<R>(visitor: IElementVisitor<R>): R {
        return visitor.withLine(this);
    }

    toFragment(): TFragment {
        const { x1, y1, x2, y2 } = this;
        return { a: { x: x1, y: y1 }, b: { x: x2, y: y2 } };
    }

    toLine(): TLine {
        const { x1, y1, x2, y2 } = this;
        return { x1, y1, x2, y2 };
    }
}
