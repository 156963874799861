import type { TEquationsSetVariant } from "../elements/equations-set/types";
import type { TAnyAction } from "../types";

export type TEquationsSetAction = {
    type: "equations-set";
    variant: TEquationsSetVariant;
};

export function createEquationsSetAction(variant: TEquationsSetVariant): TEquationsSetAction {
    return { type: "equations-set", variant };
}

export function isEquationsSetAction(action: TAnyAction): action is TEquationsSetAction {
    return action.type === "equations-set";
}
