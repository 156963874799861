import classNames from "classnames";
import { observer } from "mobx-react-lite";
import React from "react";

import { getLineAngle, radToDeg } from "@viuch/geometry-lib/angles";
import { useIsSafari } from "@viuch/utils/hooks";

import type { Figure2D } from "../../../entities/Figure2D";
import type { ElementColor } from "../ElementColor";

import styles from "./LineView.module.scss";

export interface ILineViewElement {
    id: string;
    x1: number;
    y1: number;
    x2: number;
    y2: number;
    thin?: boolean;
    isDashed?: boolean;
    visible: boolean;
    color: ElementColor;
    overrideRenderOrder: number;
}

type Props<T extends ILineViewElement> = {
    line: T;
    onPointerEvent(e: React.PointerEvent, line: T): void;
    isVector?: boolean;
    figure: Figure2D;
};

export const LineView = observer(function LineView<T extends ILineViewElement>({
    line,
    onPointerEvent,
    isVector,
}: Props<T>) {
    const { id, x1, y1, x2, y2, color, thin, isDashed, visible } = line;

    const handlePointerEvent = (e: React.PointerEvent) => {
        onPointerEvent(e, line);
    };

    const isSafari = useIsSafari();
    // const width = thin ? 1 : 2; // уменьшили толщину всех линий
    const width = thin ? 1 : 1;

    const f = (n: number) => +n.toFixed(5) * 100;

    const _x1 = `${f(x1)}%`;
    const _y1 = `${f(1 - y1)}%`;
    const _x2 = `${f(x2)}%`;
    const _y2 = `${f(1 - y2)}%`;

    const angleDeg = radToDeg(getLineAngle(line, true)) + 90;

    return (
        <g className={!visible ? styles.hidden : void 0}>
            {color.shadow && !isSafari && (
                <line
                    transform="translate(1 4)"
                    filter="blur(4px)"
                    x1={_x1}
                    y1={_y1}
                    x2={_x2}
                    y2={_y2}
                    stroke={color.hex}
                    strokeWidth={width}
                    className={isDashed ? styles.dashed : void 0}
                />
            )}
            <line
                x1={_x1}
                y1={_y1}
                x2={_x2}
                y2={_y2}
                stroke={color.hex}
                strokeLinecap="round"
                strokeWidth={width}
                opacity={color.opacity}
                className={isDashed ? styles.dashed : void 0}
            />
            <line
                x1={_x1}
                y1={_y1}
                x2={_x2}
                y2={_y2}
                stroke="transparent"
                strokeWidth={isSafari ? 18 : 12}
                onPointerDown={handlePointerEvent}
                onPointerMove={handlePointerEvent}
                onPointerUp={handlePointerEvent}
                onPointerCancel={handlePointerEvent}
            />
            {isVector && (
                <>
                    <rect
                        x={_x2}
                        y={_y2}
                        pathLength={4}
                        strokeWidth={width}
                        className={styles.rect}
                        stroke={color.hex}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        opacity={color.opacity}
                        style={{ "--angle": angleDeg }}
                    />
                    <rect
                        x={_x2}
                        y={_y2}
                        pathLength={4}
                        strokeWidth={width}
                        className={classNames(styles.rect, styles._second)}
                        stroke={color.hex}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        opacity={color.opacity}
                        style={{ "--angle": angleDeg }}
                    />
                </>
            )}
        </g>
    );
});
