import { AdminRolesService } from "../services/auth";
import { AuthService } from "../services/auth/AuthService";
import { BlogPostsService } from "../services/blog-posts/BlogPostsService";
import { ContentAdminsService } from "../services/content-admins/ContentAdminsService";
import { MeasureUnitsService, DimensionsService } from "../services/dimensions";
import { ExamsService } from "../services/exams";
import { ExpressionReplacementsService } from "../services/expressionReplacements";
import { ExpressionsService } from "../services/expressions";
import { FAQService } from "../services/faq/FAQService";
import { AnswersService } from "../services/feedback/AnswersService";
import { QuestionsService } from "../services/feedback/QuestionsService";
import { ReviewCommentsService } from "../services/feedback/ReviewCommentsService";
import { ReviewsService } from "../services/feedback/ReviewsService";
import { GeometrySynonymsService } from "../services/geometry-synonyms/GeometrySynonymsService";
import { GlobalStudyPlansService } from "../services/global-study-plans";
import { GlobalStudyPlanTemplatesService } from "../services/global-study-plans/GlobalStudyPlanTemplatesService";
import { GradesService } from "../services/grades";
import { SearchService } from "../services/hierarchy/search";
import { SectionsService } from "../services/hierarchy/sections";
import { SubjectsService } from "../services/hierarchy/subjects";
import { SubsectionsService } from "../services/hierarchy/subsections";
import { ThemesService } from "../services/hierarchy/themes";
import { ImageMaterialsService } from "../services/image-materials/ImageMaterialsService";
import { IndexingService } from "../services/indexing/IndexingService";
import { PaymentsService } from "../services/payments/PaymentsService";
import { PromocodesService } from "../services/payments/PromocodesService";
import { PreamblesService } from "../services/preambles/PreamblesService";
import { ProblemsService } from "../services/problems";
import { RegenerateVariantsService } from "../services/regenerate-variants/RegenerateVariantsService";
import { SolversUsageStatsService } from "../services/stats/SolversUsageStatsService";
import { StatsInfoService } from "../services/stats/StatsInfoService";
import { StepDescriptionsService } from "../services/stepDescriptions";
import { StudentSolutionsService } from "../services/students/StudentSolutionsService";
import { StudentsService } from "../services/students/StudentsService";
import { SynopsesService } from "../services/synopses/SynopsesService";
import { SynopsesDraftsService } from "../services/synopsesDrafts";
import { TaskVariantsService } from "../services/tasks";
import { ThemeKnowledgeLinksService } from "../services/theme-knowledge-links/ThemeKnowledgeLinksService";
import { TheoremsService } from "../services/theorems/TheoremsService";
import { TaskUtilsService } from "../services/utils/TaskUtilsService";
import { VideosService } from "../services/videos";

export class ApiStore {
    readonly subjects = new SubjectsService();
    readonly sections = new SectionsService();
    readonly subsections = new SubsectionsService();
    readonly themes = new ThemesService();
    readonly videos = new VideosService();
    readonly synopses = new SynopsesService();
    readonly preambles = new PreamblesService();
    readonly problems = new ProblemsService();
    readonly dimensions = new DimensionsService();
    readonly measureUnits = new MeasureUnitsService();
    readonly replacements = new ExpressionReplacementsService();
    readonly exams = new ExamsService();
    readonly search = new SearchService();
    readonly globalStudyPlans = new GlobalStudyPlansService();
    readonly grades = new GradesService();
    readonly expressions = new ExpressionsService();
    readonly globalStudyPlanTemplates = new GlobalStudyPlanTemplatesService();
    readonly students = new StudentsService();
    readonly solutions = new StudentSolutionsService();
    readonly contentAdmins = new ContentAdminsService();
    readonly statsInfo = new StatsInfoService();
    readonly blogPosts = new BlogPostsService();
    readonly imageMaterials = new ImageMaterialsService();
    readonly faq = new FAQService();
    readonly questions = new QuestionsService();
    readonly answers = new AnswersService();
    readonly reviews = new ReviewsService();
    readonly comments = new ReviewCommentsService();
    readonly regenerate = new RegenerateVariantsService();
    readonly indexing = new IndexingService();
    readonly synopsesDrafts = new SynopsesDraftsService();
    readonly adminRoles = new AdminRolesService();
    readonly auth = new AuthService();
    readonly taskVariants = new TaskVariantsService();
    readonly taskUtils = new TaskUtilsService();
    readonly theorems = new TheoremsService();
    readonly payments = new PaymentsService();
    readonly promocodes = new PromocodesService();
    readonly stepDescriptions = new StepDescriptionsService();
    readonly solversUsageStats = new SolversUsageStatsService();
    readonly geometrySynonyms = new GeometrySynonymsService();
    readonly themeKnowledgeLinks = new ThemeKnowledgeLinksService();
}
