import type { TAnyAction } from "../types";

export type TDownIndexAction = {
    type: "down-index";
};

export function createDownIndexAction(): TDownIndexAction {
    return { type: "down-index" };
}

export function isDownIndexAction(action: TAnyAction): action is TDownIndexAction {
    return action.type === "down-index";
}
