import { makeObservable, observable } from "mobx";

import { createSerializedContainer } from "@viuch/math-editor";
import { generateId } from "@viuch/shared/utils/data";

import type { TDotForm } from "./types";
import type { TSerializedState } from "@viuch/math-editor";

export interface IIntervalsMethodLineDot {
    position: number;
    form: TDotForm;
    value: TSerializedState;
}

export class IntervalsDot {
    readonly id: number;

    @observable position: number;
    @observable form: TDotForm;
    @observable.ref value: TSerializedState;

    constructor(fields: IIntervalsMethodLineDot, id?: number) {
        this.id = id ?? generateId();

        this.position = fields.position;
        this.form = fields.form;
        this.value = fields.value;

        makeObservable(this);
    }

    static createEmpty(fields: Partial<IIntervalsMethodLineDot>): IntervalsDot {
        return new IntervalsDot({
            form: "filled",
            value: createSerializedContainer(),
            position: 0,
            ...fields,
        });
    }

    cloneWithCurrentId(): IntervalsDot {
        const { form, value, position, id } = this;
        return new IntervalsDot({ form, value, position }, id);
    }
}
