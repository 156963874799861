import cn from "classnames";
import { observer } from "mobx-react-lite";
import React from "react";

import type { PropsWithChildren } from "react";

import styles from "./ModalFrame.module.scss";

type Props = PropsWithChildren<{
    className?: string;
    classes?: {
        wrapper?: string;
        frameOuter?: string;
        frameInner?: string;
    };
    everyClassName?: string;
    disableShadows?: boolean;
    isReversed?: boolean;
}>;

export const ModalFrame = observer(function ModalFrame(props: Props) {
    const { className, children, classes, everyClassName, disableShadows, isReversed } = props;
    return (
        <div
            className={cn(
                className,
                styles.wrapper,
                everyClassName,
                classes?.wrapper,
                disableShadows && styles.disableShadows
            )}
        >
            <div
                className={cn(
                    styles.frameOuter,
                    isReversed && styles.frameOuter_reversed,
                    everyClassName,
                    classes?.frameOuter
                )}
            >
                <div
                    className={cn(
                        styles.frameInner,
                        isReversed && styles.frameInner_reversed,
                        everyClassName,
                        classes?.frameInner
                    )}
                >
                    {children}
                </div>
            </div>
        </div>
    );
});
