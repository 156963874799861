import { flow, makeObservable } from "mobx";

import type { ImageResponse, TPictureImage } from "./types";
import type { IUploadImageService } from "@viuch/instrument-picture-settings/settings-editor/types";
import type { TSerializedState } from "@viuch/math-editor";
import type { AxiosResponse } from "axios";

import { agent } from "../../agent";

export class ImagesService implements IUploadImageService {
    public constructor() {
        makeObservable(this);
    }

    @flow.bound
    async *uploadImage(file: File): Promise<TPictureImage> {
        const formData = new FormData();
        formData.append("file", file, file.name);

        //

        const { data }: AxiosResponse<ImageResponse> = yield agent.post("/uploaded_files/", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });

        const url = data.file;
        const uuid = data.id;
        return { url, uuid };
    }

    async uploadImageWithWidth(file: File, width: number): Promise<TPictureImage> {
        const formData = new FormData();
        formData.append("file", file, file.name);
        formData.append("image_width", `${width}`);

        const { data }: AxiosResponse<ImageResponse> = await agent.post("/uploaded_files/", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });

        const url = data.file;
        const uuid = data.id;
        return { url, uuid };
    }

    @flow.bound
    async *mathExpressionToString(formula: TSerializedState): Promise<string> {
        try {
            const { data } = await agent.post<{ text: string }>("/task/util/formula_to_text/", { formula });
            yield;

            return data?.text || "";
        } catch (e) {
            return "";
        }
    }
}
