import type { TFormControl } from "@viuch/form-builder/types/controls";

import { createLabel } from "../controls/label";
import { exprMathControl, intervalMathControl, valueMathControl, variableMathControl } from "../controls/value";

export const minMax: TFormControl = {
    control: "Form",
    layout: "column",
    condition: {
        "./solver_type": [
            "min_value", //
            "max_value",
            "min_point",
            "max_point",
        ],
    },
    controls: [
        createLabel("Находит минимальное значение выражения", { "./solver_type": "min_value" }), //
        createLabel("Находит максимальное значение выражения", { "./solver_type": "max_value" }),
        createLabel("Находит точку глобального минимума", { "./solver_type": "min_point" }),
        createLabel("Находит точку глобального максимума", { "./solver_type": "max_point" }),
        valueMathControl,
        variableMathControl,
        intervalMathControl,
    ],
};

export const localMinMax: TFormControl = {
    control: "Form",
    layout: "column",
    condition: {
        "./solver_type": ["min_max_local", "min_max_special"],
    },
    controls: [
        createLabel("Находит точки локальных экстремумов", { "./solver_type": "min_max_local" }), //
        createLabel("Находит точки локальных экстремумов для параболы или монотонной функции от параболы", {
            "./solver_type": "min_max_special",
        }),
        exprMathControl,
        variableMathControl,
    ],
};
