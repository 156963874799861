import { action, makeObservable, observable } from "mobx";

import type { ContainerModel } from "./ContainerModel";
import type { InputService } from "../../services";
import type { BaseElementModel } from "../element";

export class ContainersStore {
    private readonly inputService: InputService;

    private readonly containers: Map<string, ContainerModel> & {
        get<T extends BaseElementModel>(id: string): ContainerModel<T>;
    };

    constructor(inputService: InputService) {
        this.inputService = inputService;
        // @ts-expect-error - из-за дополненного типа
        this.containers = observable.map();

        makeObservable(this);
    }

    getAll(): Iterable<ContainerModel> {
        return this.containers.values();
    }

    @action.bound
    register(container: ContainerModel): void {
        this.containers.set(container.uuid, container);
    }

    @action.bound
    free(container: ContainerModel): void {
        this.containers.delete(container.uuid);
    }

    getById<T extends BaseElementModel = BaseElementModel>(id: string): ContainerModel<T> {
        return this.containers.get<T>(id);
    }

    tryGetById(id: string): ContainerModel | null {
        return this.containers.get(id) ?? null;
    }
}
