import { makeObservable, observable } from "mobx";

import { BaseDrawingGenerationSettings } from "./BaseDrawingGenerationSettings";
import { DrawingGenerationViewport } from "./DrawingGenerationViewport";

export class DistanceToCenter extends BaseDrawingGenerationSettings {
    @observable drawLines: boolean;
    @observable.ref viewport: DrawingGenerationViewport;

    constructor(init: DistanceToCenter.Init) {
        super();

        this.drawLines = init.drawLines;
        this.viewport = init.viewport;

        makeObservable(this);
    }

    accept<R>(visitor: BaseDrawingGenerationSettings.Visitor<R>): R {
        return visitor.withDistanceToCenter(this);
    }

    static createEmpty() {
        return new DistanceToCenter({
            drawLines: false,
            viewport: DrawingGenerationViewport.createEmpty(),
        });
    }

    clone(): DistanceToCenter {
        return new DistanceToCenter({ drawLines: this.drawLines, viewport: this.viewport.clone() });
    }
}

export declare namespace DistanceToCenter {
    export type Init = {
        drawLines: boolean;
        viewport: DrawingGenerationViewport;
    };
}
