import { safeCheck } from "@viuch/utils/debug";

import type { Graph2DModel } from "../../../core/Graph2DModel";
import type { Graph2DPreRenderingService } from "../../core/Graph2DPreRenderingService";
import type { Graph2DViewportController } from "../../core/Graph2DViewportController";
import type { BaseGraphViewItem } from "../../rendering/BaseGraphViewItem";
import type { IButtonToolbarElement } from "../../toolbar/elements/ToolbarButton";
import type { IToolbarElement } from "../../toolbar/toolbarTypes";

import { BaseGraphFlow } from "../BaseGraphFlow";

export class ViewElementsFlow extends BaseGraphFlow {
    constructor(
        model: Graph2DModel,
        viewport: Graph2DViewportController,
        renderTransformer: Graph2DPreRenderingService
    ) {
        super(model, viewport, renderTransformer);
    }

    handleElementPointerEvent = void 0;
    handleViewportPointerEvent = void 0;
    handleKeyEvent = void 0;
    handleElementClickEvent = void 0;

    getViewItems(): readonly BaseGraphViewItem[] {
        const { renderTransformer } = this;

        const items = this.model.dataItems //
            .getAll()
            .flatMap((item) => renderTransformer.transform(item));

        return this.renderTransformer.filter(items);
    }

    override getToolbarPreset(): string | null {
        return null;
    }

    override getToolbarElementUpdates(element: IToolbarElement): IButtonToolbarElement | IToolbarElement {
        if (safeCheck<IButtonToolbarElement>(element, (el) => el.type === "button")) {
            switch (element.key) {
                case "cursor":
                    return {
                        ...element,
                        enabled: true,
                    };
                case "add-point":
                    return {
                        ...element,
                        enabled: true,
                    };
            }
        }

        return element;
    }
}
