import cn from "classnames";
import { observer } from "mobx-react-lite";
import React from "react";

import { CheckboxInput } from "@viuch/ui-common";

import type { TableViewSettings } from "../../settings/TableViewSettings";

import styles from "./TableViewSettingsEditor.module.scss";

type Props = {
    viewSettings: TableViewSettings;
    disabled: boolean;
};

export const TableViewSettingsEditor = observer(function TableViewSettingsEditor({ viewSettings, disabled }: Props) {
    const items = [
        {
            label: "Настройки таблицы",
            items: [
                { label: "Центрировать", field: "tableCenter" },
                { label: "Одинаковые столбцы", field: "equalColumns" },
            ],
        },
        {
            label: "Первая строка",
            items: [
                { label: "Подсвечивать", field: "highlightFirstRow" },
                { label: "По центру", field: "centerFirstRow" },
                { label: "Переносить текст", field: "wrapFirstRow" },
                { label: "Жирный", field: "boldFirstRow" },
            ],
        },
        {
            label: "Первый столбец",
            items: [
                { label: "Подсвечивать", field: "highlightFirstColumn" },
                { label: "По центру", field: "centerFirstColumn" },
                { label: "Переносить текст", field: "wrapFirstColumn" },
                { label: "Жирный", field: "boldFirstColumn" },
                { label: "Сжимать", field: "shrinkFirstColumn" },
            ],
        },
        {
            label: "Остальные ячейки",
            items: [
                { label: "Переносить текст", field: "wrapTable" },
                { label: "По центру", field: "centerCells" },
            ],
        },
    ] as const;

    return (
        <div className={cn(styles.root)}>
            {items.map(({ items, label }, i) => (
                <div
                    key={i}
                    className={styles.group}
                >
                    <div className={styles.group__label}>{label}</div>
                    <div className={styles.group__items}>
                        {items.map(({ label, field }, i) => (
                            <div
                                key={i}
                                className={styles.groupItem}
                            >
                                <CheckboxInput
                                    type="checkbox"
                                    checked={viewSettings[field].value}
                                    onChange={viewSettings[field].set}
                                    noSpace
                                    disabled={disabled}
                                    labelAfter={label}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
});
