export function sortById<T extends { id: number }>(left: T, right: T): number {
    return sortAsc(left.id, right.id);
}
export function sortDescById<T extends { id: number }>(left: T, right: T): number {
    return sortDesc(left.id, right.id);
}

export function sortAsc(left: number, right: number): number {
    return left - right;
}

export function createSortAscBy<T>(getField: (value: T) => number): (left: T, right: T) => number {
    return (l, r) => sortAsc(getField(l), getField(r));
}

export function sortDesc(left: number, right: number): number {
    return right - left;
}

export function sortAbc(left: string, right: string): number {
    return left.toLowerCase().localeCompare(right.toLowerCase());
}

export function createSortDescBy<T>(getField: (value: T) => number): (left: T, right: T) => number {
    return (l, r) => sortDesc(getField(l), getField(r));
}

export function createSortAbcBy<T>(getField: (value: T) => string): (left: T, right: T) => number {
    return (l, r) => sortAbc(getField(l), getField(r));
}
