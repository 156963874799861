import { equateNumbers } from "@viuch/geometry-lib/equate";
import { createPoint } from "@viuch/geometry-lib/factories";
import { solveQuadraticEquation } from "@viuch/geometry-lib/solvers";
import { addVectors, subtractVectors } from "@viuch/geometry-lib/vectors";

import type { TEllipse, TFragment, TPoint } from "@viuch/geometry-lib/types";

/**
 * Находит две точки касания к окружности прямой через указанную точку
 *
 * @throws Error Если точка не за пределами окружности
 */
export function getTangentPoints(point: TPoint, ellipse: TEllipse): [TPoint, TPoint] {
    const { x: x0, y: y0 } = subtractVectors(ellipse.center, point);
    const { rx: a, ry: b } = ellipse;

    const _a = a ** 2 * y0 ** 2 + x0 ** 2 * b ** 2;
    const _b = -2 * a ** 2 * b ** 2 * y0;
    const _c = a ** 2 * b ** 4 - x0 ** 2 * b ** 4;

    const results = solveQuadraticEquation(_a, _b, _c);

    if (results.length !== 2) {
        throw new Error("Point should be outside of ellipse");
    }

    const [y1, y2] = results;

    const getX = (y: number) => (a ** 2 * (b ** 2 - y * y0)) / (x0 * b ** 2);
    const x1 = getX(y1);
    const x2 = getX(y2);

    return [addVectors(createPoint(x1, y1), ellipse.center), addVectors(createPoint(x2, y2), ellipse.center)];
}

export function findEllipseFocuses(ellipse: TEllipse): [f1: TPoint, f2: TPoint] {
    const { rx, ry, center } = ellipse;

    if (rx > ry) {
        const c = Math.sqrt(rx ** 2 - ry ** 2);
        return [
            { x: center.x + c, y: center.y },
            { x: center.x - c, y: center.y },
        ];
    } else {
        const c = Math.sqrt(ry ** 2 - rx ** 2);
        return [
            { x: center.x, y: center.y + c },
            { x: center.x, y: center.y - c },
        ];
    }
}

/**
 * Находит касательную к эллипсу через точку, которая лежит на этом эллипсе
 */
export function getTangentLineFromPointOnEllipse(ellipse: TEllipse, pointOnEllipse: TPoint): TFragment {
    const { rx, ry, center } = ellipse;
    const { a, b } = getTangentLineFromPointOnZeroCenterEllipse(rx, ry, subtractVectors(center, pointOnEllipse));

    return {
        a: addVectors(a, center),
        b: addVectors(b, center),
    };
}

function getTangentLineFromPointOnZeroCenterEllipse(rx: number, ry: number, pointOnEllipse: TPoint): TFragment {
    const { x: px, y: py } = pointOnEllipse;

    if (equateNumbers(py, "==", 0)) {
        // вертикальная прямая
        return {
            a: pointOnEllipse,
            b: { x: pointOnEllipse.x, y: pointOnEllipse.y + 1 },
        };
    }

    const f_x = (x: number) => ((ry * ry) / py) * (1 - (x * px) / (rx * rx));

    return {
        a: pointOnEllipse,
        b: {
            x: pointOnEllipse.x + 0.1,
            y: f_x(pointOnEllipse.x + 0.1),
        },
    };
}
