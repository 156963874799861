import cn from "classnames";
import { observer } from "mobx-react-lite";
import React from "react";

import { radToDeg } from "@viuch/geometry-lib/angles";
import { range } from "@viuch/shared/utils/data";

import type { AngleElement } from "./AngleElement";

import styles from "./AngleView.module.scss";

type Props = {
    angle: AngleElement;
    onPointerEvent(e: React.PointerEvent, angle: AngleElement): void;
};

export const AngleView = observer(function AngleView({ angle, onPointerEvent }: Props) {
    const { isRight, color, x, y, angleStart, angleEnd, segments = 1, offset = 0, visible } = angle;

    const rightAngleRectRef = React.useRef<SVGRectElement>(null);

    const angleLength = angleEnd - angleStart;
    const angleMiddle = radToDeg(angleStart + angleLength / 2);
    const strokeDasharray = `${angleLength} ${Math.PI * 2 - angleLength}`;
    const strokeDashoffset = angleLength + angleStart;

    const handlePointerEvent = (e: React.PointerEvent) => {
        onPointerEvent(e, angle);
    };

    const _x = `${x * 100}%`;
    const _y = `${(1 - y) * 100}%`;

    return (
        <g
            data-test-type="angle"
            className={cn(styles.g, !visible && styles.hidden)}
        >
            <svg
                x={_x}
                y={_y}
                className={styles.svgInner}
            >
                <rect
                    ref={rightAngleRectRef}
                    x={0}
                    y={0}
                    style={{
                        "--rotate": angleMiddle,
                        "--color": color.hex,
                    }}
                    className={cn(styles.rect, !isRight && styles.hidden)}
                    pathLength={4}
                    onPointerDown={handlePointerEvent}
                    onPointerMove={handlePointerEvent}
                    onPointerUp={handlePointerEvent}
                    onPointerCancel={handlePointerEvent}
                />
            </svg>
            {!isRight &&
                range(segments).map((i) => (
                    <circle
                        key={i}
                        cx={_x}
                        cy={_y}
                        r={20 - segments * 2 + i * 4 + offset * 2}
                        stroke={color.hex}
                        pathLength={Math.PI * 2}
                        strokeDashoffset={strokeDashoffset}
                        strokeDasharray={strokeDasharray}
                        className={styles.circle}
                    />
                ))}
        </g>
    );
});
