import { Figure2DWithCompletionsSettings } from "@viuch/instrument-figure2d-settings/entities";
import { Graph1DInstrumentSettings } from "@viuch/instrument-graph1d-settings/entities";
import { createNewGraph2DSettings } from "@viuch/instrument-graph2d-settings/serialization/createNewGraph2DSettings";
import { IntervalsInstrumentStatement } from "@viuch/instrument-intervals/statement";
import { IntervalsInstrumentSettings } from "@viuch/instrument-intervals-settings";
import { MultichoiceInstrumentSettings } from "@viuch/instrument-multichoice-settings/entities";
import { PictureInstrumentSettingsImpl } from "@viuch/instrument-picture-settings";
import {
    TableTextInstrumentSettingsImpl,
    TableSelectInstrumentSettingsImpl,
} from "@viuch/instrument-table-settings/settings";
import { generateId, generateUuid } from "@viuch/shared/utils/data";

import type { Graph2DInstrumentSettings } from "@viuch/instrument-graph2d-settings/settings-entity/Graph2DInstrumentSettings";

export abstract class BaseTaskInstrumentSettings<T = unknown> {
    readonly $$instanceId = generateId();

    readonly settingsId: number;
    readonly settingsUuid: string;
    readonly instrument: T;

    constructor(settingsId: number, uuid: string, instrument: T) {
        this.settingsId = settingsId;
        this.settingsUuid = uuid;
        this.instrument = instrument;
    }

    abstract accept<R>(visitor: IInstrumentSettingsVisitor<R>): R;
}

export interface IInstrumentSettingsVisitor<R> {
    withMultichoice(model: MultichoiceTaskInstrumentSettings): R;
    withInformationTable(model: TableTextTaskInstrumentSettings): R;
    withSelectTable(model: TableSelectTaskInstrumentSettings): R;
    withGraphic1d(model: Graph1DTaskInstrumentSettings): R;
    withIntervalMethod(model: IntervalsTaskInstrumentSettings): R;
    withPicture(model: PictureTaskInstrumentSettings): R;
    withFigure(model: Figure2DWithCompletionsTaskInstrumentSettings): R;
    withGraph2d(model: Graph2DTaskInstrumentSettings): R;
}

export class MultichoiceTaskInstrumentSettings extends BaseTaskInstrumentSettings<MultichoiceInstrumentSettings> {
    accept = <R>(visitor: IInstrumentSettingsVisitor<R>): R => visitor.withMultichoice(this);

    static createEmpty() {
        return new MultichoiceTaskInstrumentSettings(
            -generateId(),
            generateUuid(),
            MultichoiceInstrumentSettings.createEmpty()
        );
    }
}

export class Graph1DTaskInstrumentSettings extends BaseTaskInstrumentSettings<Graph1DInstrumentSettings> {
    constructor(settingsId: number, uuid: string, instrument: Graph1DInstrumentSettings) {
        super(settingsId, uuid, instrument);
    }

    accept = <R>(visitor: IInstrumentSettingsVisitor<R>): R => visitor.withGraphic1d(this);

    static createEmpty() {
        return new Graph1DTaskInstrumentSettings(
            -generateId(),
            generateUuid(),
            Graph1DInstrumentSettings.CreateEmpty()
        );
    }
}

export class IntervalsTaskInstrumentSettings extends BaseTaskInstrumentSettings<IntervalsInstrumentSettings> {
    accept = <R>(visitor: IInstrumentSettingsVisitor<R>): R => visitor.withIntervalMethod(this);

    static createEmpty() {
        return new IntervalsTaskInstrumentSettings(
            -generateId(),
            generateUuid(),
            new IntervalsInstrumentSettings(IntervalsInstrumentStatement.createEmpty())
        );
    }
}

export class PictureTaskInstrumentSettings extends BaseTaskInstrumentSettings<PictureInstrumentSettingsImpl> {
    accept = <R>(visitor: IInstrumentSettingsVisitor<R>): R => visitor.withPicture(this);

    static createEmpty() {
        return new PictureTaskInstrumentSettings(
            -generateId(),
            generateUuid(),
            PictureInstrumentSettingsImpl.createEmpty()
        );
    }
}

export class Figure2DWithCompletionsTaskInstrumentSettings extends BaseTaskInstrumentSettings<Figure2DWithCompletionsSettings> {
    readonly $$type = "figure2d-with-completions";

    accept = <R>(visitor: IInstrumentSettingsVisitor<R>): R => visitor.withFigure(this);

    static createEmpty() {
        return new Figure2DWithCompletionsTaskInstrumentSettings(
            -generateId(),
            generateUuid(),
            Figure2DWithCompletionsSettings.createEmpty()
        );
    }
}

export class TableTextTaskInstrumentSettings extends BaseTaskInstrumentSettings<TableTextInstrumentSettingsImpl> {
    accept = <R>(visitor: IInstrumentSettingsVisitor<R>): R => visitor.withInformationTable(this);

    static createEmpty() {
        return new TableTextTaskInstrumentSettings(
            -generateId(),
            generateUuid(),
            TableTextInstrumentSettingsImpl.createEmpty()
        );
    }
}

export class TableSelectTaskInstrumentSettings extends BaseTaskInstrumentSettings<TableSelectInstrumentSettingsImpl> {
    accept = <R>(visitor: IInstrumentSettingsVisitor<R>): R => visitor.withSelectTable(this);

    static createEmpty() {
        return new TableSelectTaskInstrumentSettings(
            -generateId(),
            generateUuid(),
            TableSelectInstrumentSettingsImpl.createEmpty()
        );
    }
}

export class Graph2DTaskInstrumentSettings extends BaseTaskInstrumentSettings<Graph2DInstrumentSettings> {
    accept = <R>(visitor: IInstrumentSettingsVisitor<R>): R => visitor.withGraph2d(this);

    static createEmpty() {
        return new Graph2DTaskInstrumentSettings(-generateId(), generateUuid(), createNewGraph2DSettings());
    }
}
