import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";

export const ForbiddenPage = observer(function ForbiddenPage() {
    return (
        <div>
            <span>Недостаточно прав, </span>
            <Link to="/">вернуться на главную страницу</Link>
            <span>.</span>
        </div>
    );
});
