import type { TTransformerObject } from "./transformer";

export function createOffsetX(offsetX: number): TTransformerObject {
    return {
        transformFunction: (f, x) => f(x - offsetX),
        transformXInterval: ({ start, end }) => [
            {
                start: { ...start, value: start.value + offsetX },
                end: { ...end, value: end.value + offsetX },
            },
        ],
        transformXIntervalWithLimits: ({ start, end }) => [
            {
                start: { ...start, value: start.value + offsetX },
                end: { ...end, value: end.value + offsetX },
            },
        ],
        transformPoint: ({ x, y }) => [{ x: x + offsetX, y }],
        transformXValue: (x) => x + offsetX,
        transformYValue: (y) => y,
    };
}

export function createOffsetY(offsetY: number): TTransformerObject {
    return {
        transformFunction: (f, x) => f(x) + offsetY,
        transformXInterval: (interval) => [interval],
        transformXIntervalWithLimits: ({ start, end }) => [
            {
                start: { ...start, limit: start.limit + offsetY },
                end: { ...end, limit: end.limit + offsetY },
            },
        ],
        transformPoint: ({ x, y }) => [{ x, y: y + offsetY }],
        transformXValue: (x) => x,
        transformYValue: (y) => y + offsetY,
    };
}

export function createOffsetXY(offsetX: number, offsetY: number): TTransformerObject {
    return {
        transformFunction: (f, x) => f(x - offsetX) + offsetY,
        transformXInterval: ({ start, end }) => [
            {
                start: { ...start, value: start.value + offsetX },
                end: { ...end, value: end.value + offsetX },
            },
        ],
        transformXIntervalWithLimits: ({ start, end }) => [
            {
                start: { ...start, value: start.value + offsetX, limit: start.limit + offsetY },
                end: { ...end, value: end.value + offsetX, limit: end.limit + offsetY },
            },
        ],
        transformPoint: ({ x, y }) => [{ x: x + offsetX, y: y + offsetY }],
        transformXValue: (x) => x + offsetX,
        transformYValue: (y) => y + offsetY,
    };
}
