import type { TAnyAction } from "../types";

export type TSpaceAction = {
    type: "space";
};

export function createSpaceAction(): TSpaceAction {
    return { type: "space" };
}

export function isSpaceAction(action: TAnyAction): action is TSpaceAction {
    return action.type === "space";
}
