import { makeObservable, observable } from "mobx";

import { generateId } from "@viuch/shared/utils/data";

import type { Figure2D } from "../../../entities/Figure2D";
import type { IModelVisitor } from "../BaseModel";
import type { TModelStyle } from "../modelStyle";
import type { TPoint } from "@viuch/geometry-lib/types";
import type { TSerializedState } from "@viuch/math-editor";

import { getNextPointAutoLabel } from "../../utils/auto-labels";
import { BaseModel } from "../BaseModel";

export interface IPointLabel {
    x: number;
    y: number;
    label: TSerializedState;
    rotationAngle?: number;
    directionAngle?: number;
    style: TModelStyle | null;
}

export class LabelPointModel extends BaseModel {
    @observable.ref x: number;
    @observable.ref y: number;
    @observable.ref label: TSerializedState;
    @observable.ref rotationAngle: number;
    @observable.ref directionAngle: number;
    @observable.ref style: TModelStyle | null;

    constructor(data: IPointLabel, id: number) {
        super(id);
        this.x = data.x;
        this.y = data.y;
        this.label = data.label;
        this.rotationAngle = data.rotationAngle ?? 0;
        this.directionAngle = data.directionAngle ?? 0;
        this.style = data.style;

        makeObservable(this);
    }

    static create(data: IPointLabel) {
        return new LabelPointModel(data, generateId());
    }

    static createNext(point: TPoint, figure: Figure2D) {
        return new LabelPointModel(
            { label: getNextPointAutoLabel(figure), x: point.x, y: point.y, style: null },
            generateId()
        );
    }

    accept<R>(visitor: IModelVisitor<R>): R {
        return visitor.withLabelPoint(this);
    }
}
