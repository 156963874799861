import type { TRawBlogPost } from "./types";
import type { BlogFilters } from "../../entities/attention-filters/BlogPostsFilters";
import type { BlogPost } from "../../entities/blog-post/BlogPost";

import { agent } from "../../agent";
import { throwServiceError } from "../base/RequestError";

import { mapBlogPost, serializeBlogPost, serializeBlogPostsFilters } from "./utils";

export class BlogPostsService {
    async fetchAll(filters?: BlogFilters): Promise<BlogPost[]> {
        try {
            const filtersRequest = filters ? serializeBlogPostsFilters(filters) : void 0;
            const { data } = await agent.get<TRawBlogPost[]>("/blog/", {
                params: filtersRequest,
            });

            return data.map(mapBlogPost);
        } catch (e) {
            throwServiceError(e);
        }
    }

    async fetchById(blogPostId: number): Promise<BlogPost> {
        try {
            const { data } = await agent.get<TRawBlogPost>(`/blog/${blogPostId}/`);

            return mapBlogPost(data);
        } catch (e) {
            throwServiceError(e);
        }
    }

    async create(blogPost: BlogPost): Promise<BlogPost> {
        try {
            const { data } = await agent.post<TRawBlogPost>("/blog/", serializeBlogPost(blogPost));

            return mapBlogPost(data);
        } catch (e) {
            throwServiceError(e);
        }
    }

    async patch(blogPost: BlogPost): Promise<BlogPost> {
        try {
            const { data } = await agent.patch<TRawBlogPost>(`/blog/${blogPost.id}/`, serializeBlogPost(blogPost));

            return mapBlogPost(data);
        } catch (e) {
            throwServiceError(e);
        }
    }

    async changeArchivedStatus(blogPost: BlogPost, isArchived: boolean): Promise<BlogPost> {
        try {
            const { data } = await agent.patch<TRawBlogPost>(`/blog/${blogPost.id}/`, { is_archived: isArchived });

            return mapBlogPost(data);
        } catch (e) {
            throwServiceError(e);
        }
    }
}
