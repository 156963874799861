import { createContext, useContext } from "react";

import type { HighlightRange } from "../../core/highlighting/ranges/HighlightRange";
import type { PropsWithChildren } from "react";

export type TMathEditorHandlers = {
    onHighlightClick: (highlight: HighlightRange) => void;
};

export const mathEditorHandlersContext = createContext<TMathEditorHandlers>(null!);

export function useMathEditorHandlers(): TMathEditorHandlers {
    return useContext(mathEditorHandlersContext);
}

type Props = PropsWithChildren<{
    handlers: TMathEditorHandlers;
}>;

export function HandlersContextProvider({ children, handlers }: Props) {
    return <mathEditorHandlersContext.Provider value={handlers}>{children}</mathEditorHandlersContext.Provider>;
}
