import { normalizeAngle } from "@viuch/geometry-lib/angles";
import {
    areSameEllipses,
    areSameFragments,
    areSamePoints,
    areSameVectors,
    hasMatchInPointSet,
} from "@viuch/geometry-lib/check-geometry";
import { createFragment } from "@viuch/geometry-lib/factories";

import type { Figure2D } from "../../../entities/Figure2D";
import type { IModelVisitor } from "../../models/BaseModel";
import type { EllipseSelection, FragmentSelection, PointSelection, VectorSelection } from "../../selection/items";
import type { TAngle, TFragment, TPoint } from "@viuch/geometry-lib/types";

import { checkModelType, ModelTypes } from "../../services/actions/utils";
import { removeModel } from "../utils";

export function handleRemovePoint(figure: Figure2D, s: PointSelection) {
    const removeModelVisitor: IModelVisitor<void> = {
        withLabelFragment: (l) => {
            if (areSamePoints(l.a, s) || areSamePoints(l.b, s)) {
                removeModel(figure, l);
            }
        },
        withFragment: (f) => {
            if (areSamePoints(f.a, s) || areSamePoints(f.b, s)) {
                removeModel(figure, f);
            }
        },
        withParallelFragment: (fragment) => {
            switch (true) {
                case areSamePoints(fragment.a, s):
                case areSamePoints(fragment.b, s):
                case areSamePoints(fragment.base.a, s):
                case areSamePoints(fragment.base.b, s):
                    removeModel(figure, fragment);
                    break;
            }
        },
        withPoint: (p) => {
            if (areSamePoints(p, s)) {
                removeModel(figure, p);
            }
        },
        withLabelPoint: (l) => {
            if (areSamePoints(l, s)) {
                removeModel(figure, l);
            }
        },
        withLabelAngle: (l) => {
            if (areSamePoints(l.start, s) || areSamePoints(l.vertex, s) || areSamePoints(l.end, s)) {
                removeModel(figure, l);
            }
        },
        withLine: (l) => {
            if (areSamePoints(l.a, s) || areSamePoints(l.b, s)) {
                removeModel(figure, l);
            }
        },
        withConstraintLine: (l) => {
            if (areSamePoints(l.vertex, s)) {
                removeModel(figure, l);
            }
        },
        withComputedIntersectionPoints: () => {},
        withBisection: (b) => {
            if (
                areSamePoints(b.angle.start, s) ||
                areSamePoints(b.angle.vertex, s) ||
                areSamePoints(b.angle.end, s) ||
                areSamePoints(b.basis.fragment.a, s) ||
                areSamePoints(b.basis.fragment.b, s) ||
                (b.basis.offset && areSamePoints(b.basis.offset, s)) ||
                (b.midPoint && areSamePoints(b.midPoint, s))
            ) {
                removeModel(figure, b);
            }
        },
        withMedian: (m) => {
            if (
                areSamePoints(m.vertex, s) ||
                areSamePoints(m.fragment.a, s) ||
                areSamePoints(m.fragment.b, s) ||
                areSamePoints(m.midPoint, s)
            ) {
                removeModel(figure, m);
            }
        },
        withAltitude: (a) => {
            if (
                areSamePoints(a.vertex, s) ||
                areSamePoints(a.basis.fragment.a, s) ||
                areSamePoints(a.basis.fragment.b, s) ||
                (a.basis.offset && areSamePoints(a.basis.offset, s)) ||
                (a.projectionPoint && areSamePoints(a.projectionPoint, s))
            ) {
                removeModel(figure, a);
            }
        },
        withEqualSegments: (es) => {
            for (const segment of es.segments) {
                if (areSamePoints(segment.a, s) || areSamePoints(segment.b, s)) {
                    removeModel(figure, es);
                    break;
                }
            }
        },
        withEqualAngles: (ea) => {
            for (const angle of ea.angles) {
                if (areSamePoints(angle.vertex, s) || areSamePoints(angle.start, s) || areSamePoints(angle.end, s)) {
                    removeModel(figure, ea);
                    break;
                }
            }
        },
        withEllipse: (ellipse) => {
            if (areSamePoints(ellipse.center, s)) {
                removeModel(figure, ellipse);
            }
        },
        withInscribedCircle: (circle) => {
            if (circle.center && areSamePoints(circle.center, s)) {
                removeModel(figure, circle);
                return;
            }
            for (const point of circle.fragmentsPoints) {
                if (areSamePoints(point, s)) {
                    removeModel(figure, circle);
                    return;
                }
            }
        },
        withCircumscribedCircle: (circle) => {
            if (circle.center && areSamePoints(circle.center, s)) {
                removeModel(figure, circle);
                return;
            }
            for (const point of circle.points) {
                if (areSamePoints(point, s)) {
                    removeModel(figure, circle);
                    return;
                }
            }
        },
        withTangentLine: (tangent) => {
            switch (true) {
                case areSamePoints(tangent.circle.center, s):
                case areSamePoints(tangent.point, s):
                case tangent.checkIsTangent() && areSamePoints(tangent.pointOnCircle, s):
                    removeModel(figure, tangent);
            }
        },
        withMiddleLine: (middleLine) => {
            const { base1, base2, middleLineFragment } = middleLine;

            switch (true) {
                case areSamePoints(base1.a, s):
                case areSamePoints(base1.b, s):
                case areSamePoints(base2.a, s):
                case areSamePoints(base2.b, s):
                case areSamePoints(middleLineFragment.a, s):
                case areSamePoints(middleLineFragment.b, s):
                    removeModel(figure, middleLine);
            }
        },
        withTangentLineOnCircle: (tangent) => {
            switch (true) {
                case areSamePoints(tangent.touchPoint, s):
                case areSamePoints(tangent.ellipse.center, s):
                    removeModel(figure, tangent);
            }
        },
        withVector: (vector) => {
            switch (true) {
                case areSamePoints(vector.from, s):
                    removeModel(figure, vector);
            }
        },
        withVectorDashed: (vector) => {
            switch (true) {
                case areSamePoints(vector.from, s):
                    removeModel(figure, vector);
            }
        },
        withFragmentDashed: (fragment) => {
            switch (true) {
                case areSamePoints(fragment.a, s):
                case areSamePoints(fragment.b, s):
                    removeModel(figure, fragment);
            }
        },
    };

    [...figure.models].forEach((model) => model.accept(removeModelVisitor));
}

export const handleRemoveFragment = (figure: Figure2D, selection: FragmentSelection) => {
    const createFilterModelHasPointsVisitor = (p: TPoint): IModelVisitor<boolean> => ({
        withPoint: (point) => hasMatchInPointSet([point], p),
        withFragment: (fragment) => hasMatchInPointSet([fragment.a, fragment.b], p),
        withFragmentDashed: (fragment) => hasMatchInPointSet([fragment.a, fragment.b], p),
        withVector: (vector) => hasMatchInPointSet([vector.from], p),
        withVectorDashed: (vector) => hasMatchInPointSet([vector.from], p),
        withParallelFragment: (fragment) => hasMatchInPointSet([fragment.a, fragment.b], p),
        withLabelPoint: (label) => hasMatchInPointSet([label], p),
        withLabelFragment: (label) => hasMatchInPointSet([label], p),
        withLabelAngle: ({ start, vertex, end }) => hasMatchInPointSet([start, vertex, end], p),
        withLine: (line) => hasMatchInPointSet([line.a, line.b], p),
        withConstraintLine: ({ constraintA, constraintB, vertex }) =>
            hasMatchInPointSet([constraintA, vertex, constraintB], p),
        withComputedIntersectionPoints: () => false,
        withBisection: ({ angle, basis, midPoint }) => {
            const modelPoints = [basis.fragment.a, basis.fragment.b, angle.vertex, angle.start, angle.end];
            if (midPoint) modelPoints.push(midPoint);
            if (basis.offset) modelPoints.push(basis.offset);

            return hasMatchInPointSet(modelPoints, p);
        },
        withMedian: ({ vertex, fragment, midPoint }) =>
            hasMatchInPointSet([fragment.a, fragment.b, vertex, midPoint], p),
        withAltitude: ({ vertex, basis, projectionPoint }) => {
            const points = [basis.fragment.a, basis.fragment.b, vertex, projectionPoint];
            if (basis.offset) points.push(basis.offset);

            return hasMatchInPointSet(points, p);
        },
        withEqualAngles: ({ angles }) =>
            hasMatchInPointSet(
                angles.flatMap(({ vertex, start, end }) => [vertex, start, end]),
                p
            ),
        withEqualSegments: ({ segments }) =>
            hasMatchInPointSet(
                segments.flatMap(({ a, b }) => [a, b]),
                p
            ),
        withEllipse: ({ center }) => hasMatchInPointSet([center], p),
        withInscribedCircle: ({ fragmentsPoints }) => hasMatchInPointSet(fragmentsPoints, p),
        withCircumscribedCircle: ({ points }) => hasMatchInPointSet(points, p),
        withTangentLine: (tangent) =>
            hasMatchInPointSet(tangent.checkIsTangent() ? [tangent.point, tangent.pointOnCircle] : [tangent.point], p),
        withMiddleLine: ({ base1, base2, middleLineFragment }) =>
            hasMatchInPointSet([base1.a, base1.b, base2.a, base2.b, middleLineFragment.a, middleLineFragment.b], p),
        withTangentLineOnCircle: ({ ellipse, touchPoint }) => hasMatchInPointSet([ellipse.center, touchPoint], p),
    });

    const removeModelVisitor: IModelVisitor<void> = {
        withLabelFragment: (labelFragment) => {
            if (areSameFragments(labelFragment, selection)) {
                removeModel(figure, labelFragment);
            }
        },
        withFragment: (fragment) => {
            if (areSameFragments(fragment, selection)) {
                removeModel(figure, fragment);

                // заодно удалить точки отрезка

                const filterA = createFilterModelHasPointsVisitor(selection.a);
                const filterB = createFilterModelHasPointsVisitor(selection.b);
                const modelsA = figure.models.filter((model) => model.accept(filterA));
                const modelsB = figure.models.filter((model) => model.accept(filterB));

                if (modelsA.length === 1) {
                    if (checkModelType(modelsA[0], ModelTypes.point)) {
                        removeModel(figure, modelsA[0]);
                    }
                }
                if (modelsB.length === 1) {
                    if (checkModelType(modelsB[0], ModelTypes.point)) {
                        removeModel(figure, modelsB[0]);
                    }
                }
            }
        },
        withFragmentDashed(fragment) {
            if (areSameFragments(fragment, selection)) {
                removeModel(figure, fragment);

                // заодно удалить точки отрезка

                const filterA = createFilterModelHasPointsVisitor(selection.a);
                const filterB = createFilterModelHasPointsVisitor(selection.b);
                const modelsA = figure.models.filter((model) => model.accept(filterA));
                const modelsB = figure.models.filter((model) => model.accept(filterB));

                if (modelsA.length === 1) {
                    if (checkModelType(modelsA[0], ModelTypes.point)) {
                        removeModel(figure, modelsA[0]);
                    }
                }
                if (modelsB.length === 1) {
                    if (checkModelType(modelsB[0], ModelTypes.point)) {
                        removeModel(figure, modelsB[0]);
                    }
                }
            }
        },
        withVector(vector) {
            if (areSameVectors(vector.toFragment(), selection)) {
                removeModel(figure, vector);
            }
        },
        withVectorDashed(vector) {
            if (areSameVectors(vector.toFragment(), selection)) {
                removeModel(figure, vector);
            }
        },
        withParallelFragment: (fragment) => {
            if (areSameFragments(fragment, selection)) {
                removeModel(figure, fragment);
            }
        },
        withPoint: () => {},
        withLabelPoint: () => {},
        withLabelAngle: (l) => {
            const f1 = { a: l.vertex, b: l.start };
            const f2 = { a: l.vertex, b: l.end };
            if (areSameFragments(f1, selection) || areSameFragments(f2, selection)) {
                removeModel(figure, l);
            }
        },
        withLine: (line) => {
            if (areSameFragments(line, selection)) {
                removeModel(figure, line);
            }
        },
        withConstraintLine: () => {},
        withComputedIntersectionPoints: () => {},
        withBisection: (bisection) => {
            const { bisectionFragment } = bisection;
            if (bisectionFragment && areSameFragments(bisectionFragment, selection)) {
                removeModel(figure, bisection);
            }
        },
        withMedian: (median) => {
            if (areSameFragments(median.toMedianFragment(), selection)) {
                removeModel(figure, median);
            }
        },
        withAltitude: (altitude) => {
            if (areSameFragments(altitude.toAltitudeFragment(), selection)) {
                removeModel(figure, altitude);
            }
        },
        withEqualAngles: (angles) => {
            for (const angle of angles.angles) {
                const f1 = createFragment(angle.vertex, angle.start);
                const f2 = createFragment(angle.vertex, angle.end);
                if (areSameFragments(f1, selection) || areSameFragments(f2, selection)) {
                    removeModel(figure, angles);
                }
            }
        },
        withEqualSegments: (segments) => {
            for (const segment of segments.segments) {
                if (areSameFragments(segment, selection)) {
                    removeModel(figure, segments);
                    return;
                }
            }
        },
        withEllipse: () => {},
        withInscribedCircle: (circle) => {
            for (const fragment of circle.fragments) {
                if (areSameFragments(fragment, selection)) {
                    removeModel(figure, circle);
                    return;
                }
            }
        },
        withCircumscribedCircle: (circle) => {
            for (const fragment of circle.fragments) {
                if (areSameFragments(fragment, selection)) {
                    removeModel(figure, circle);
                    return;
                }
            }
        },
        withTangentLine: (tangent) => {
            if (
                tangent.checkIsTangent() &&
                areSameFragments(createFragment(tangent.point, tangent.pointOnCircle), selection)
            ) {
                removeModel(figure, tangent);
            }
        },
        withMiddleLine: (middleLine) => {
            if (areSameFragments(middleLine.middleLineFragment, selection)) {
                removeModel(figure, middleLine);
            }
        },
        withTangentLineOnCircle: () => {},
    };
    figure.models.forEach((model) => model.accept(removeModelVisitor));
};

export const getAngleFromFragment = (fragment: TFragment, vertex: TPoint): TAngle =>
    normalizeAngle({ vertex, start: fragment.a, end: fragment.b }, true);

export function handleRemoveEllipse(figure: Figure2D, s: EllipseSelection) {
    const removeModelVisitor: IModelVisitor<void> = {
        withEllipse: (ellipse) => {
            if (areSameEllipses(ellipse, s)) {
                removeModel(figure, ellipse);
            }
        },
        withCircumscribedCircle: (circle) => {
            if (!circle.isEllipse()) return;

            if (areSameEllipses(circle, s)) {
                removeModel(figure, circle);
            }
        },
        withInscribedCircle: (circle) => {
            if (!circle.isEllipse()) return;

            if (areSameEllipses(circle, s)) {
                removeModel(figure, circle);
            }
        },
        withTangentLine: (tangent) => {
            if (areSameEllipses(tangent.circle, s)) {
                removeModel(figure, tangent);
            }
        },
        withTangentLineOnCircle: (tangent) => {
            if (areSameEllipses(tangent.ellipse, s)) {
                removeModel(figure, tangent);
            }
        },
        withPoint: () => void 0,
        withFragment: () => void 0,
        withFragmentDashed: () => void 0,
        withVector: () => void 0,
        withVectorDashed: () => void 0,
        withLabelPoint: () => void 0,
        withLabelFragment: () => void 0,
        withLabelAngle: () => void 0,
        withLine: () => void 0,
        withConstraintLine: () => void 0,
        withComputedIntersectionPoints: () => void 0,
        withBisection: () => void 0,
        withMedian: () => void 0,
        withAltitude: () => void 0,
        withEqualAngles: () => void 0,
        withEqualSegments: () => void 0,
        withMiddleLine: () => void 0,
        withParallelFragment: () => void 0,
    };

    figure.models.slice().forEach((model) => model.accept(removeModelVisitor));
}

export function handleRemoveVector(figure: Figure2D, v: VectorSelection) {
    const removeVectorVisitor: IModelVisitor<void> = {
        withVector: (vector) => {
            if (areSameVectors(v.toFragment(), vector.toFragment())) {
                removeModel(figure, vector);
            }
        },
        withPoint: () => void 0,
        withFragment: () => void 0,
        withFragmentDashed: () => void 0,
        withVectorDashed: () => void 0,
        withLabelPoint: () => void 0,
        withLabelFragment: () => void 0,
        withLabelAngle: () => void 0,
        withLine: () => void 0,
        withConstraintLine: () => void 0,
        withComputedIntersectionPoints: () => void 0,
        withBisection: () => void 0,
        withMedian: () => void 0,
        withAltitude: () => void 0,
        withEqualAngles: () => void 0,
        withEqualSegments: () => void 0,
        withEllipse: () => void 0,
        withInscribedCircle: () => void 0,
        withCircumscribedCircle: () => void 0,
        withTangentLine: () => void 0,
        withMiddleLine: () => void 0,
        withTangentLineOnCircle: () => void 0,
        withParallelFragment: () => void 0,
    };

    figure.models.slice().forEach((model) => model.accept(removeVectorVisitor));
}
