import { computed, makeObservable } from "mobx";

import { getAnglePoints, getLineAngle } from "@viuch/geometry-lib/angles";
import { findFragmentsOnLine } from "@viuch/geometry-lib/check-geometry";
import { copyEllipse, copyFragment, copyPoint, createLine } from "@viuch/geometry-lib/factories";
import { tryCreateConvexPolygon, tryCreatePolygon } from "@viuch/geometry-lib/figures";
import { tryExtractMergedFragment } from "@viuch/geometry-lib/lines";
import { mergeOneLineFragments } from "@viuch/geometry-lib/transform";
import { assert } from "@viuch/utils/debug";

import type { GetSelectionTypes } from "./utils";
import type { Figure2DController } from "../../Figure2DController";
import type { ISelectionElementVisitor } from "../../selection/BaseSelection";
import type { TAngle, TEllipse, TFragment, TLine, TPoint } from "@viuch/geometry-lib/types";

import {
    checkSelectionType,
    createCheckSelectionType,
    findEllipsesVisitor,
    isPointOnEllipse,
    SelectionTypes,
} from "./utils";

export class ActionsManager {
    private data: Figure2DController;

    constructor(data: Figure2DController) {
        this.data = data;

        makeObservable(this);
    }

    private get selection() {
        return this.data.selection;
    }

    private get figure() {
        return this.data.figure;
    }

    @computed
    get selectedSinglePoint(): { point: TPoint } | null {
        if (this.selection.elements.length !== 1) return null;

        const selectedDot = this.selection.elements[0];
        if (!checkSelectionType(SelectionTypes.point, selectedDot)) return null;

        return { point: copyPoint(selectedDot) };
    }

    private getElementsOfType<T extends SelectionTypes>(type: T): GetSelectionTypes[T][] {
        return this.selection.elements.filter<GetSelectionTypes[T]>(createCheckSelectionType(type));
    }

    private getGroupByTypeElements() {
        type Return = {
            fragments: TFragment[];
            points: TPoint[];
            circles: TEllipse[];
        };

        const data: Return = { fragments: [], points: [], circles: [] };

        const addToDataVisitor: ISelectionElementVisitor<void> = {
            withPoint: (point) => data.points.push(copyPoint(point)),
            withFragment: (fragment) => data.fragments.push(copyFragment(fragment)),
            withEllipse: (ellipse) => data.circles.push(copyEllipse(ellipse)),
            withVector: (vector) => data.fragments.push(vector.toFragment()),
        };

        for (const element of this.selection.elements) {
            element.accept(addToDataVisitor);
        }

        return data;
    }

    @computed
    get selectedFragmentAndPoint() {
        const { fragments, circles, points } = this.getGroupByTypeElements();
        if (circles.length > 0) return null;

        const fragment = tryExtractMergedFragment(fragments, points);
        if (!fragment || fragments.length > 0 || points.length !== 1) return null;

        return { fragment, point: points[0] };
    }

    @computed
    get selectedSingleFragment(): { fragment: TFragment } | null {
        const fragments = this.getElementsOfType(SelectionTypes.fragment).map((fragment) => fragment.toLine());

        if (fragments.length !== this.selection.elements.length) return null;
        if (fragments.length === 0) return null;

        const { matched, notMatched } = findFragmentsOnLine(fragments, fragments[0]);
        if (notMatched.length > 0) return null;

        const mergedFragments = mergeOneLineFragments(matched);
        if (mergedFragments.length > 1) return null;

        const [fragment] = mergedFragments;
        return {
            fragment: {
                a: { x: fragment.x1, y: fragment.y1 },
                b: { x: fragment.x2, y: fragment.y2 },
            },
        };
    }

    @computed
    get selectedSingleAngle(): { angle: TAngle } | null {
        const fragments = this.selection.elements.flatMap((selection) =>
            selection.accept<TLine[]>({
                withFragment: (f) => [f.toLine()],
                withVector: (v) => [v.toLine()],
                withPoint: () => [],
                withEllipse: () => [],
            })
        );

        const { matched: firstDraftLines, notMatched: forSecond } = findFragmentsOnLine(fragments, fragments[0]);
        if (forSecond.length === 0) return null;
        const firstLines = mergeOneLineFragments(firstDraftLines);
        if (firstLines.length !== 1) return null;
        const [first] = firstLines;

        const { matched: secondDraftLines, notMatched } = findFragmentsOnLine(forSecond, forSecond[0]);
        if (notMatched.length !== 0) return null;
        const secondLines = mergeOneLineFragments(secondDraftLines);
        if (secondLines.length !== 1) return null;
        const [second] = secondLines;

        const anglePoints = getAnglePoints(first, second);
        if (!anglePoints) return null;
        const {
            vertex,
            rest: [a, b],
        } = anglePoints;

        let [c, d] = [a, b]
            .map((point) => ({
                point,
                angle: getLineAngle(createLine(vertex, point)),
            }))
            .sort((l, r) => l.angle - r.angle);

        const angleDiff = d.angle - c.angle;
        assert(angleDiff >= 0, { angleDiff, c, d });
        if (angleDiff > Math.PI) {
            [c, d] = [d, c];
        }

        return {
            angle: {
                vertex,
                start: c.point,
                end: d.point,
            },
        };
    }

    @computed
    get selectSomePoints(): { points: TPoint[] } | null {
        const { elements } = this.selection;

        const points = this.getElementsOfType(SelectionTypes.point);
        if (points.length !== elements.length) return null;

        return { points: points.map(copyPoint) };
    }

    @computed
    get selectedConvexPolygon(): { polygon: TPoint[] } | null {
        const polygon = this.getPolygonCandidatePoints();

        return polygon ? { polygon: polygon.map(copyPoint) } : null;
    }

    @computed
    get selectedFragments(): { fragments: TFragment[] } | null {
        const { points, fragments, circles } = this.getGroupByTypeElements();

        if (circles.length > 0) return null;

        const _fragments: TFragment[] = [];

        let _fragment: TFragment | null;
        while ((_fragment = tryExtractMergedFragment(fragments, points))) {
            _fragments.push(_fragment);
        }

        if (_fragments.length >= 2) {
            return { fragments: _fragments };
        }
        return null;
    }

    private getPolygonCandidatePoints(): TPoint[] | null {
        const { elements } = this.selection;

        if (elements.length < 3) return null;

        const points = this.getElementsOfType(SelectionTypes.point);
        if (points.length === elements.length) {
            return tryCreateConvexPolygon(points);
        }

        if (points.length !== 0) return null;

        const fragments = this.getElementsOfType(SelectionTypes.fragment);
        if (fragments.length === elements.length) {
            return tryCreatePolygon(fragments);
        }
        return null;
    }

    @computed
    get selectedSinglePointOnEllipse(): { point: TPoint; ellipse: TEllipse } | null {
        const point = this.selectedSinglePoint?.point;
        if (!point) return null;

        const ellipses = this.figure.models.flatMap((model) => model.accept(findEllipsesVisitor));

        for (const ellipse of ellipses) {
            if (isPointOnEllipse(ellipse, point)) {
                return { point, ellipse };
            }
        }

        return null;
    }
}
