import classNames from "classnames";
import { observer } from "mobx-react-lite";

import type { PropsWithChildren } from "react";

import styles from "./ExamsShortFrame.module.scss";

type Props = PropsWithChildren<{
    className?: string;
    everyClassName?: string;
}>;

export const ExamsShortFrame = observer(function ExamsShortFrame({ children, className, everyClassName }: Props) {
    return (
        <div className={classNames(styles.wrapper, className, everyClassName)}>
            <div className={classNames(styles.outer, everyClassName)}>
                <div className={classNames(styles.inner, everyClassName)}>{children}</div>
            </div>
        </div>
    );
});
