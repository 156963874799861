import { createTransformer } from "mobx-utils";

import type { BaseElement } from "../../elements";
import type { Figure2DController } from "../../Figure2DController";

import { BaseFlow } from "../BaseFlow";
import { createMapModelToElements } from "../utils";

export class ViewFlow extends BaseFlow {
    constructor(controller: Figure2DController) {
        super(controller);
    }

    attach(): void {}

    dispose(): void {}

    mapModel = createTransformer(createMapModelToElements());

    protected renderElements(): BaseElement[] {
        return this.figure.models.flatMap(this.mapModel);
    }

    // nextFlow(): void {}

    override handleKeyEvent() {}
}
