import { isEmptyEditorData } from "@viuch/complex-editor";

import type { SynopsisAttentionFilter } from "../../entities/attention-filters/SynopsisAttentionFilter";
import type { GetError } from "../base/RequestError";
import type { TComplexEditorUnion } from "@viuch/complex-editor";

import { agent } from "../../agent";
import { IndexingEntityMetadataOnly } from "../../entities/indexing/IndexingEntityMetadataOnly";
import { SynopsisEntityChecks } from "../../entities/indexing/SynopsisEntityChecks";
import { Synopsis } from "../../entities/synopses";
import { SynopsisMinimal } from "../../entities/synopses/SynopsisMinimal";
import { BaseCRUDService } from "../base";

export class SynopsesService extends BaseCRUDService<Synopsis, TSynopsisRequest, TSynopsisResponse> {
    constructor() {
        super("/synopses/", mapSynopsis, serializeSynopsis, validateSynopsis);
    }

    override getAll = (): never => {
        throw new Error("Not implemented");
    };

    getAllMinimal = async (): Promise<SynopsisMinimal[]> => {
        const { data } = await agent.get<TSynopsisMinimalResponse[]>("/synopses/");
        return data.map(mapSynopsisMinimal);
    };

    searchAttention = async (filters: SynopsisAttentionFilter, signal: AbortSignal): Promise<SynopsisMinimal[]> => {
        const params = SynopsesService.serializeFilters(filters);

        const { data } = await agent.get<TSynopsisSearchResponse>("/synopses/search/", { signal, params });

        return data.hits.map(mapSynopsisSearchResult);
    };
}

export type TSynopsisMinimalResponse = {
    id: number;
    name: string;
    themes: Array<{
        id: number;
        name: string;
    }>;
};

export type TSynopsisSearchResult = {
    id: number;
    name: string;
    themes?: Array<{
        id: number;
        name: string;
    }>;
};

export type TSynopsisSearchResponse = {
    hits: Array<TSynopsisSearchResult>;
};

export type TSynopsisRequest = {
    id: number;
    name: string;
    introduction: TComplexEditorUnion;
    theory: TComplexEditorUnion;
    conclusion: TComplexEditorUnion;
    facts?: TComplexEditorUnion;
    themes: number[];
    meta_title: string | null;
    meta_description: string | null;
    main_theme: number | null;
    theory_verified: boolean | undefined;
    seo_verified: boolean | undefined;
    all_verified: boolean | undefined;
};

export type TSynopsisResponse = TSynopsisRequest;

export const mapSynopsis = ({
    id,
    name,
    introduction,
    theory,
    conclusion,
    facts,
    themes,
    meta_title,
    meta_description,
    main_theme,
    theory_verified,
    seo_verified,
    all_verified,
}: TSynopsisResponse): Synopsis =>
    new Synopsis({
        id,
        name,
        introduction,
        theory,
        conclusion,
        interestingFacts: facts || null,
        themes,
        indexing: new IndexingEntityMetadataOnly(meta_title, meta_description),
        mainThemeId: main_theme,
        checks: new SynopsisEntityChecks(!!theory_verified, !!seo_verified, !!all_verified),
    });

export const serializeSynopsis = ({
    id,
    name,
    themes,
    introduction,
    theory,
    conclusion,
    interestingFacts,
    indexing,
    mainThemeId,
    checks,
}: Synopsis): TSynopsisRequest => ({
    id,
    name,
    introduction,
    theory,
    conclusion,
    facts: interestingFacts || void 0,
    themes,
    meta_title: indexing.title,
    meta_description: indexing.description,
    main_theme: mainThemeId,
    theory_verified: checks.theoryVerified,
    seo_verified: checks.SEOVerified,
    all_verified: checks.allVerified,
});

export const validateSynopsis = (synopsis: Synopsis): GetError<TSynopsisRequest> | void => {
    if (synopsis.name.trim().length === 0) {
        return { name: ["Обязательное поле"] };
    }

    if (isEmptyEditorData(synopsis.introduction)) {
        return { introduction: ["Обязательное поле"] };
    }

    if (isEmptyEditorData(synopsis.theory)) {
        return { theory: ["Обязательное поле"] };
    }

    if (isEmptyEditorData(synopsis.conclusion)) {
        return { conclusion: ["Обязательное поле"] };
    }
};

export namespace SynopsesService {
    export const serializeFilters = (filters: SynopsisAttentionFilter) => {
        const data: Record<string, string> = {};

        filters.theme_is_null && (data.theme_is_null = "true");
        filters.search.length && (data.search = filters.search);
        data.limit = `1000000`;
        filters.is_meta_title_null && (data.meta_title_is_null = "true");
        filters.is_meta_description_null && (data.meta_description_is_null = "true");

        return new URLSearchParams(data);
    };
}

export function mapSynopsisMinimal({ id, name, themes }: TSynopsisMinimalResponse): SynopsisMinimal {
    return new SynopsisMinimal(id, name, themes);
}

export function mapSynopsisSearchResult({ id, name, themes }: TSynopsisSearchResult): SynopsisMinimal {
    return new SynopsisMinimal(id, name, themes || []);
}
