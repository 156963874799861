import { useEffect, useRef } from "react";

import type { IRoundBracketController } from "../types";
import type { PropsWithChildren } from "react";

import { createDefaultRoundBracketConfig } from "../presets";
import { RoundBracket } from "../RoundBracket";

import styles from "./RoundBrackets.module.scss";

export const RoundBrackets = ({ children }: PropsWithChildren) => {
    const leftBracketRef = useRef<RoundBracket>(null);
    const rightBracketRef = useRef<RoundBracket>(null);
    const contentRef = useRef<HTMLSpanElement>(null);

    useEffect(() => {
        const content = contentRef.current!;
        const leftBracket: IRoundBracketController = leftBracketRef.current!;
        const rightBracket: IRoundBracketController = rightBracketRef.current!;

        const observer = new ResizeObserver(() => {
            const height = content.clientHeight;
            const config = createDefaultRoundBracketConfig(height);

            leftBracket.updateBracketParams(config);
            rightBracket.updateBracketParams(config);
        });
        observer.observe(content);

        return () => observer.disconnect();
    }, []);

    return (
        <span className={styles.inline}>
            <RoundBracket
                ref={leftBracketRef}
                className={styles.bracket}
            />
            <span
                className={styles.inline}
                ref={contentRef}
            >
                {children}
            </span>
            <RoundBracket
                ref={rightBracketRef}
                className={styles.bracket}
                closing
            />
        </span>
    );
};
