import { mapTaskExample } from "@viuch/feature-solutions/serializers/mapTaskExample";

import type { TBulkRemoveVariantsParams, TGenerateResponse, TPagedTaskExamples, TRawAdminTaskExample } from "./types";
import type { SolutionExampleImpl } from "@viuch/feature-solutions/entities/SolutionExampleImpl";
import type { TRawTaskExample } from "@viuch/feature-solutions/service-types/taskExample";

import { agent } from "../../agent";

export class TaskVariantsService {
    async rawGenerateExamples(
        taskId: number | string,
        quantity: number,
        tableId: string | number | null
    ): Promise<TGenerateResponse> {
        const params = { quantity, table: tableId ?? void 0 };

        const { data } = await agent.get<TGenerateResponse>(`/tasks/${taskId}/generate_examples/`, {
            params,
        });

        return data;
    }

    async loadVariantById(variantId: string | number): Promise<TRawAdminTaskExample> {
        const { data } = await agent.get<TRawAdminTaskExample>(`/tasks_variants/${variantId}/`);

        return data;
    }

    async removeVariant(variantId: number | string): Promise<void> {
        await agent.delete(`/tasks_variants/${variantId}/`);
    }

    async bulkRemoveVariants(params: TBulkRemoveVariantsParams): Promise<void> {
        await agent.delete("/tasks_variants/bulk_delete/", { params });
    }

    async rawRandomSampleExamples(count: number, themeIds: number[]): Promise<TRawTaskExample[]> {
        const params = new URLSearchParams({ count: String(count), theme_in: themeIds.join(",") });
        const { data } = await agent.get<TRawTaskExample[]>(`/tasks_variants/random_sample/`, { params });

        return data;
    }

    async loadVariantExamples(variantIds: number[]): Promise<SolutionExampleImpl[]> {
        if (variantIds.length === 0) {
            return [];
        }

        const params = { id_in: variantIds.join(",") };
        const { data } = await agent.get<TRawTaskExample[]>(`/tasks_variants/`, { params });

        return data.map(mapTaskExample);
    }

    async rawLoadSolutionExamples(variantIds: number[]): Promise<TRawTaskExample[]> {
        const allRequests = await Promise.all(
            variantIds.map((variantId) => agent.get<TRawTaskExample>(`/tasks_variants/${variantId}/`))
        );

        return allRequests.map((request) => request.data);
    }

    async rawLoadVariantExamplesByTaskId(
        taskId: number | string,
        limit: number = 10,
        offset: number = 0,
        signal?: AbortSignal
    ): Promise<TPagedTaskExamples> {
        const { data } = await agent.get<TPagedTaskExamples>(
            `/tasks_variants/?template=${taskId}&limit=${limit}&offset=${offset}`,
            {
                signal,
            }
        );

        return data;
    }
}
