import { generateUuid } from "../../utils/data";

export class LazyResult<T, P = unknown> {
    readonly promise: Promise<T>;
    private resolve!: (value: T) => void;
    private reject!: (value: unknown) => void;

    readonly uuid = generateUuid();
    readonly payload: P;

    constructor();
    constructor(p: P);
    constructor(p: P = null!) {
        this.payload = p;
        this.promise = new Promise((resolve, reject) => {
            this.resolve = resolve;
            this.reject = reject;
        });
    }

    static create<T, P>(payload: P): LazyResult<T, P>;
    static create<T>(): LazyResult<T>;

    static create<T, P>(payload?: P): LazyResult<T, P> {
        return new LazyResult<T, P>(payload!);
    }

    resolveWith = (value: T): void => {
        this.resolve(value);
    };

    rejectWith = (e: unknown) => {
        this.reject(e);
    };
}
