import type { TAnyAction } from "../types";

export type TResultAction = { type: "result" };

export function createResultAction(): TResultAction {
    return { type: "result" };
}

export function isResultAction(action: TAnyAction): action is TResultAction {
    return action.type === "result";
}
