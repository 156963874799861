import cn from "classnames";
import { observer } from "mobx-react-lite";
import React from "react";

import type { PropsWithChildren, CSSProperties } from "react";

import styles from "./TooltipFrame.module.scss";

type Props = PropsWithChildren<{
    className?: string;
    innerClassName?: string;
    childrenClassName?: string;
    everyClassName?: string;
    style?: CSSProperties;
    elementRef?: React.Ref<HTMLDivElement>;
    wing?: "top" | "bottom";
}>;

export const TooltipFrame = observer(function TooltipFrame({
    className,
    innerClassName,
    childrenClassName,
    everyClassName,
    wing = "top",
    style,
    elementRef,
    children,
}: Props) {
    return (
        <div
            className={cn(styles.wrapper, className, everyClassName, styles[`wing_${wing}`])}
            style={style}
            ref={elementRef}
        >
            <div className={cn(styles.inner, innerClassName, everyClassName)}>
                <div className={cn(styles.children, childrenClassName, everyClassName)}>{children}</div>
            </div>
        </div>
    );
});
