import type { BaseElementModel } from "../../core/element";
import type { IElementFilter } from "../../core/strategies/IElementFilter";

import { BaseDefaultElementVisitor } from "../../core/element/BaseDefaultElementVisitor";

export class DifferentialContentFilter extends BaseDefaultElementVisitor<boolean> implements IElementFilter {
    canInsert(element: BaseElementModel): boolean {
        return element.accept(this);
    }

    protected defaultBehaviour(element: BaseElementModel): boolean {
        return true;
    }

    override doWithDifferential(element: BaseElementModel): boolean {
        return false;
    }
}
