import { assert } from "@viuch/utils/debug";

import type { TInsertInsteadOfSelectedSupportsBracketsWrappingCommandOptions } from "./types";
import type { BaseElementModel } from "../core/element";
import type { TAnySerializedElement } from "../types";

import { BaseCommand } from "../core/commands";
import { BracketModel } from "../elements/bracket/BracketModel";
import { isLinebreak } from "../elements/linebreak/utils";

import { EmptyFilteredElementsError } from "./errors/EmptyFilteredElementsError";
import { SelectionRequiredError } from "./errors/SelectionRequiredError";
import { isPolynomial } from "./utils";

export class InsertInsteadOfSelectedSupportsBracketsWrappingCommand<T extends BaseElementModel> extends BaseCommand<T> {
    private readonly newSelectedElementsData: TAnySerializedElement[];

    public constructor(
        model: T,
        serializedElement: TAnySerializedElement,
        options?: TInsertInsteadOfSelectedSupportsBracketsWrappingCommandOptions
    ) {
        super(model);

        if (!this.selection.selectedElements || !this.selectionSnapshot) {
            throw new SelectionRequiredError();
        }

        const selectedElements = this.selection.selectedElements.filter((el) => !isLinebreak(el));

        if (!selectedElements.length) {
            throw new EmptyFilteredElementsError();
        }

        this.newSelectedElementsData = selectedElements.map((element) =>
            this.deserializeElement(element.serializeAsClone()).serialize()
        );

        const withBrackets =
            !options?.showBracketsOnlyIfPolynomial ||
            (options.showBracketsOnlyIfPolynomial && isPolynomial(selectedElements));

        if (withBrackets) {
            this.newSelectedElementsData.unshift(new BracketModel(this.inputService, "round", false).serialize());
            this.newSelectedElementsData.push(new BracketModel(this.inputService, "round", true).serialize());
        }

        if (options?.insertElementIn === "before") {
            this.newSelectedElementsData.unshift(serializedElement);
        } else {
            this.newSelectedElementsData.push(serializedElement);
        }
    }

    public apply(): void {
        assert(this.selectionSnapshot);

        const container = this.getSelectionContainer();
        const childrenElements = this.newSelectedElementsData.map((data) => this.deserializeElement(data));

        this.removeSelectedElements();

        container.insertElementsRange(childrenElements, this.selectionSnapshot.firstElementIndex);

        this.cursor.moveToPosition(container, this.selectionSnapshot.firstElementIndex + childrenElements.length);
    }

    public rollback(): void {
        assert(this.selectionSnapshot);

        const container = this.getSelectionContainer();

        container.removeElementsRange(this.selectionSnapshot.firstElementIndex, this.newSelectedElementsData.length);

        this.restoreRemovedSelectedElements();

        this.cursor.moveToPosition(container, this.cursorPosition);
    }
}
