import { action, computed, flow, makeObservable } from "mobx";
import { createTransformer } from "mobx-utils";

import { areEqualPointsSets } from "@viuch/geometry-lib/check-geometry";
import { createSerializedContainer, isZeroLengthMathExpr } from "@viuch/math-editor";
import { isLinebreakAction } from "@viuch/math-editor/actions/linebreak";

import type { BaseElement } from "../../elements";
import type { Figure2DController } from "../../Figure2DController";
import type { ToolbarButton, ToolbarMenu } from "../../toolbar";
import type { TFragment } from "@viuch/geometry-lib/types";
import type { InputService, TSerializedState } from "@viuch/math-editor";
import type { TAnyAction } from "@viuch/math-editor/types";
import type React from "react";

import { ElementColor } from "../../elements/ElementColor";
import { LabelFragmentElement } from "../../elements/label-text";
import { LineElement } from "../../elements/line";
import { LabelFragmentModel } from "../../models/label-fragment";
import { PointModel } from "../../models/point";
import { checkModelType, ModelTypes } from "../../services/actions/utils";
import { ButtonStates } from "../../toolbar";
import { TooltipMenu } from "../../toolbar/tooltip";
import { createDefaultToolbarMenuBuilder, handleToolbarButtons } from "../../utils/toolbar";
import { BaseFlow } from "../BaseFlow";
import { createMapModelToElements, removeModel } from "../utils";

export class LabelFragmentFlow extends BaseFlow {
    private readonly fragment: TFragment;
    private initialValue?: TSerializedState;

    constructor(data: Figure2DController, fragment: TFragment) {
        super(data);
        this.fragment = fragment;

        makeObservable(this, {
            models: computed,
            tempElements: computed,
            tempLabel: computed,
            existingLabel: computed,
            notifyAction: flow.bound,
        });
    }

    get existingLabel(): LabelFragmentModel | null {
        return (
            this.figure.models.find<LabelFragmentModel>(
                (model): model is LabelFragmentModel =>
                    checkModelType(model, ModelTypes.labelFragment) &&
                    areEqualPointsSets([model.a, this.fragment.a], [model.b, this.fragment.b])
            ) ?? null
        );
    }

    get models() {
        return this.figure.models.filter((model) => model !== this.existingLabel);
    }

    get tempLabel() {
        const {
            a: { x: x1, y: y1 },
            b: { x: x2, y: y2 },
        } = this.fragment;

        const altOrigin = this.existingLabel?.altOrigin ?? false;

        return new LabelFragmentElement({
            directionAngle: 0,
            rotationAngle: 0,
            altOrigin,
            color: ElementColor.Default,
            id: "temp__label",
            isEditable: true,
            x1,
            x2,
            y1,
            y2,
            value: this.initialValue ?? createSerializedContainer(),
            overrideRenderOrder: 0,
            notifyAction: this.notifyAction,
        });
    }

    get tempElements(): BaseElement[] {
        const {
            a: { x: x1, y: y1 },
            b: { x: x2, y: y2 },
        } = this.fragment;

        return [
            new LineElement({
                color: ElementColor.Building,
                x1,
                y1,
                x2,
                y2,
                id: "temp__line",
                overrideRenderOrder: 0,
            }),
            this.tempLabel,
        ];
    }

    attach(): void {
        this.viewport.disable();
        this.initialValue = this.existingLabel?.value;
    }

    dispose(): void {}

    override handleElementPointerEvent(e: React.PointerEvent): void {
        switch (e.type) {
            case "pointerdown":
            case "pointermove":
            case "pointerup":
            case "pointercancel":
                break;
            default:
                return;
        }

        e.preventDefault();
        e.stopPropagation();
    }

    override handleViewportPointerEvent(e: React.PointerEvent): void {
        if (e.type === "pointerdown") {
            e.preventDefault();
            this.onSave();
        }
    }

    override handleToolbarButtonClick(menu: ToolbarMenu, button: ToolbarButton): void {
        switch (button.key) {
            case "remove":
            case "clear": {
                if (this.existingLabel) {
                    removeModel(this.figure, this.existingLabel);
                }
                this.nextFlow();
                break;
            }
            default:
                handleToolbarButtons(this, button);
        }
    }

    mapElement = createTransformer(createMapModelToElements());

    protected renderElements(): BaseElement[] {
        return [...this.models.flatMap(this.mapElement), ...this.tempElements];
    }

    private onSave = action(() => {
        const value = this.tempLabel.inputService.getSerializedState();
        const altOrigin = this.tempLabel.altOrigin;
        if (this.existingLabel) {
            this.figure.removeModel(this.existingLabel);
        }
        const { a, b } = this.fragment;

        if (!isZeroLengthMathExpr(value)) {
            this.figure.insertModels(function* () {
                yield PointModel.create({ ...a, style: null });
                yield PointModel.create({ ...b, style: null });
                yield LabelFragmentModel.create({ value, a, b, rotationAngle: 0, altOrigin, style: null });
            });
        }

        this.nextFlow();
    });

    async *notifyAction(inputService: InputService, action: TAnyAction) {
        if (isLinebreakAction(action)) {
            yield;
            this.tempLabel.value = inputService.getSerializedState();
            this.onSave();
        }
    }

    override getToolbarMenu(): ToolbarMenu {
        return createDefaultToolbarMenuBuilder()
            .setButtonState(ButtonStates.active, ["labels"])
            .setButtonIcon("label-segment", ["labels"])
            .build();
    }

    override getTooltipMenu(): TooltipMenu | null {
        return new TooltipMenu("Подпишите отрезок, затем нажмите Enter или в пустую область");
    }
}
