import React, { Fragment } from "react";

import type { BaseElement } from "./BaseElement";
import type { LineElement } from "./line";
import type { VectorElement } from "./line/VectorElement";
import type { Figure2D } from "../../entities/Figure2D";
import type { EventsController } from "../events";
import type { KeyboardService } from "@viuch/math-editor";

import { AngleView } from "./angle";
import { DotView } from "./dot";
import { EllipseView } from "./ellipse";
import { LabelView } from "./label-text";
import { LineView } from "./line";
import { StrokeView } from "./stroke";

type RenderElementsArgs = {
    events: EventsController;
    handlePointerEvent: (e: React.PointerEvent, element: BaseElement) => void;
    keyboardService?: KeyboardService;
    figure: Figure2D;
};

export function useRenderElement({ handlePointerEvent, keyboardService, figure }: RenderElementsArgs) {
    const renderSvg = (element: BaseElement) => (
        <Fragment key={element.id}>
            {element.accept({
                withDot: (dot) => (
                    <DotView
                        figure={figure}
                        dot={dot}
                        onPointerEvent={handlePointerEvent}
                    />
                ),
                withLine: (line) => (
                    <LineView<LineElement>
                        line={line}
                        onPointerEvent={handlePointerEvent}
                        figure={figure}
                    />
                ),
                withAngle: (angle) => (
                    <AngleView
                        angle={angle}
                        onPointerEvent={handlePointerEvent}
                    />
                ),
                withLabelDot: () => null,
                withLabelAngle: () => null,
                withLabelFragment: () => null,
                withStroke: (stroke) => (
                    <StrokeView
                        stroke={stroke}
                        onPointerEvent={handlePointerEvent}
                    />
                ),
                withEllipse: (ellipse) => (
                    <EllipseView
                        ellipse={ellipse}
                        onPointerEvent={handlePointerEvent}
                    />
                ),
                withVector: (vector) => (
                    <LineView<VectorElement>
                        line={vector}
                        isVector
                        figure={figure}
                        onPointerEvent={handlePointerEvent}
                    />
                ),
            })}
        </Fragment>
    );

    const renderHtml = (element: BaseElement) => (
        <Fragment key={element.id}>
            {element.accept({
                withDot: () => null,
                withLine: () => null,
                withAngle: () => null,
                withLabelDot: (label) => (
                    <LabelView
                        label={label}
                        onPointerEvent={handlePointerEvent}
                        keyboardService={keyboardService}
                        bold
                    />
                ),
                withLabelAngle: (label) => (
                    <LabelView
                        label={label}
                        onPointerEvent={handlePointerEvent}
                        keyboardService={keyboardService}
                        isAngle
                    />
                ),
                withLabelFragment: (label) => (
                    <LabelView
                        label={label}
                        onPointerEvent={handlePointerEvent}
                        keyboardService={keyboardService}
                        rotate
                        allowAltOrigin
                    />
                ),
                withStroke: () => null,
                withEllipse: () => null,
                withVector: () => null,
            })}
        </Fragment>
    );

    return { renderSvg, renderHtml };
}
