import { generateUuid } from "@viuch/shared/utils/data";

import type { ISerializedModule } from "./types";

import { createSerializedContainer } from "../../utils/serialization";

export function createSerializedModule(): ISerializedModule {
    return {
        type: "module",
        uuid: generateUuid(),
        content: createSerializedContainer(),
    };
}
