import cn from "classnames";
import { observer } from "mobx-react-lite";

import type { PropsWithChildren } from "react";

import styles from "./InstrumentFrame.module.scss";

type Props = PropsWithChildren<{
    className?: string;
    bodyClassName?: string;
    contentClassName?: string;
    everyClassName?: string;
    disableShadows?: boolean;
}>;

export const InstrumentFrame = observer(function InstrumentFrame({
    children,
    className,
    bodyClassName,
    contentClassName,
    everyClassName,
    disableShadows,
}: Props) {
    return (
        <div className={cn(styles.wrapper, everyClassName, className, disableShadows && styles.disableShadows)}>
            <div className={cn(styles.frameOuter, everyClassName, bodyClassName)}>
                <div className={cn(styles.frameInner, everyClassName, contentClassName)}>{children}</div>
            </div>
        </div>
    );
});
