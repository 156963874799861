import type { Figure2DWithCompletionsTaskInstrumentSettings } from "../../../entities/task-instruments-settings/BaseTaskInstrumentSettings";
import type { TFigure2DWithCompletionsInstrumentSettings } from "@viuch/feature-instrument-settings/service-types/figure2d";

import { serializeFigureSettings } from "../figure/serializeFigureSettings";

export function serializeFigureTaskSettings(
    model: Figure2DWithCompletionsTaskInstrumentSettings
): TFigure2DWithCompletionsInstrumentSettings {
    return {
        instrument_type: "figure2d",
        figure: {
            initial: serializeFigureSettings(model.instrument.initial),
            completions: model.instrument.completions.map((completion) => ({
                hint: completion.hint,
                state: serializeFigureSettings(completion.state),
            })),
        },
        view_settings: {
            initialScale: model.instrument.initialScale,
            initialPosition: model.instrument.initialPosition,
        },
        settings_uuid: model.settingsUuid,
    };
}
