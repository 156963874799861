import { generateUuid } from "@viuch/shared/utils/data";

import type { ISerializedCoordinatesVector } from "./types";

import { createSerializedContainer } from "../../utils/serialization";

export function createSerializedCoordinatesVector(): ISerializedCoordinatesVector {
    return {
        type: "coordinates-vector",
        uuid: generateUuid(),
        firstCoordinate: createSerializedContainer(),
        secondCoordinate: createSerializedContainer(),
    };
}
