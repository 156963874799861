import { action, makeObservable, observable } from "mobx";

import { generateUuid } from "@viuch/shared/utils/data";

export class GlobalStudyPlanTemplateQuarter {
    readonly $$instanceId = generateUuid();
    @observable start: Date;
    @observable end: Date;

    constructor(startDate: Date, endDate: Date) {
        this.start = startDate;
        this.end = endDate;
        makeObservable(this);
    }

    @action.bound
    setStartDate(date: Date) {
        this.start = date;
    }

    @action.bound
    setEndDate(date: Date) {
        this.end = date;
    }
}
