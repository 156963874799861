import * as mobx from "mobx";

import type { ISerializedEquationsSet, TEquationsSetVariant } from "./types";
import type { TSerializedElementPrototype } from "../../core/element";
import type { IElementVisitor } from "../../core/element/IElementVisitor";
import type { IStrategy } from "../../core/strategies";
import type { IMultiContainerElement } from "../../core/strategies/types";
import type { InputService } from "../../services";
import type { TContainerElements, TElementDeserializerFunction } from "../../types";

import { ContainerModel } from "../../core/container";
import { BaseElementModel } from "../../core/element";

import { EquationsSetStrategy } from "./EquationsSetStrategy";

export class EquationsSetModel extends BaseElementModel<ISerializedEquationsSet> implements IMultiContainerElement {
    public readonly equations: ContainerModel[];
    public readonly variant: TEquationsSetVariant;

    public constructor(
        inputService: InputService,
        variant: TEquationsSetVariant,
        equations: TContainerElements[],
        uuid?: string
    ) {
        super(inputService, uuid);
        this.variant = variant;
        this.equations = equations.map(this.createContainer);

        mobx.makeObservable(this, {
            equations: mobx.observable,
            firstEquationContainer: mobx.computed,
            addNewContainerAt: mobx.action,
            removeContainerAt: mobx.action,
        });
    }

    public get firstEquationContainer(): ContainerModel {
        return this.equations[0];
    }

    private createContainer = (content: TContainerElements, index: number): ContainerModel =>
        new ContainerModel(content, this, ["equations", index], this.inputService, {
            showPlaceholderIfEmpty: true,
        });

    public addNewContainerAt(data: TContainerElements, containerIndex: number): ContainerModel {
        const newContainer = this.createContainer(data, containerIndex);
        this.equations.splice(containerIndex, 0, newContainer);
        for (let i = containerIndex + 1; i < this.equations.length; i++) {
            const container = this.equations[i];
            container.updatePath(["equations", i]);
        }
        return newContainer;
    }

    public removeContainerAt(containerIndex: number): void {
        this.equations.splice(containerIndex, 1);
        for (let i = containerIndex + 1; i < this.equations.length; i++) {
            const container = this.equations[i];
            container.updatePath(["equations", i]);
        }
    }

    public serialize(): ISerializedEquationsSet {
        return {
            type: "equations-set",
            variant: this.variant,
            equations: this.equations.map((container) => container.serialize()),
            uuid: this.uuid,
        };
    }

    static deserialize: TElementDeserializerFunction<ISerializedEquationsSet> = (
        { inputService },
        { uuid, variant, equations }
    ) =>
        new EquationsSetModel(
            inputService,
            variant,
            equations.map((equation) => inputService.deserializeContainer(equation)),
            uuid
        );

    protected override initBehaviour(): IStrategy {
        return new EquationsSetStrategy(this);
    }

    getContainersToMoveCursorBetween(): ContainerModel[] {
        return this.equations;
    }

    public accept<R>(visitor: IElementVisitor<R>): R {
        return visitor.doWithEquationsSet(this);
    }

    serializeAsClone(): TSerializedElementPrototype<ISerializedEquationsSet> {
        const { uuid, equations, ...clone } = this.serialize();
        return {
            ...clone,
            equations: this.equations.map((container) => container.serializeAsClone()),
        };
    }

    override getWrapperContainer(): ContainerModel | null {
        return this.equations[0];
    }
}
