import type { DegreeModel } from "./DegreeModel";
import type { ContainerModel } from "../../core/container";
import type { BaseElementModel } from "../../core/element";

import { KeepCommonFilter } from "../../core/strategies/filters/KeepCommonFilter";
import { MultiContainerElementStrategy } from "../../core/strategies/MultiContainerElementStrategy";

export class DegreeStrategy extends MultiContainerElementStrategy<DegreeModel> {
    private filter: KeepCommonFilter;

    constructor(model: DegreeModel) {
        super(model);

        // disable 3,5,7,8,9,10
        this.filter = new KeepCommonFilter();
    }

    override checkCanBeInserted(element: BaseElementModel, container: ContainerModel<DegreeModel>): boolean {
        if (container === this.model.content) {
            return this.filter.canInsert(element);
        }
        return super.checkCanBeInserted(element, container);
    }
}
