import type { TAnyAction } from "../types";

export type TRootAction = {
    type: "root";
    squared?: boolean;
    index?: number;
};

export type TRootOptions = {
    squared?: boolean;
    index?: number;
};

export function createRootAction(options?: TRootOptions): TRootAction {
    return { type: "root", squared: options?.squared, index: options?.index };
}

export function isRootAction(action: TAnyAction): action is TRootAction {
    return action.type === "root";
}
