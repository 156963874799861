import { action, makeObservable, observable } from "mobx";

import { copyFragment } from "@viuch/geometry-lib/factories";
import { generateId } from "@viuch/shared/utils/data";

import type { TModelStyle } from "../modelStyle";
import type { TFragment } from "@viuch/geometry-lib/types";

import { BaseModel } from "../BaseModel";

export interface IEqualSegmentsModel {
    segments: TFragment[];
    segmentsCount: number;
    style: TModelStyle | null;
}

export class EqualSegmentsModel extends BaseModel implements IEqualSegmentsModel {
    segments: TFragment[];
    segmentsCount: number;
    style: TModelStyle | null;

    constructor(data: IEqualSegmentsModel, id: number) {
        super(id);

        this.segments = data.segments.map(copyFragment);
        this.segmentsCount = data.segmentsCount;
        this.style = data.style;

        makeObservable(this, {
            segments: observable,
            segmentsCount: observable,
            setSegmentsCount: action,
            style: observable,
        });
    }

    accept<R>(visitor: IEqualSegmentsModelVisitor<R>): R {
        return visitor.withEqualSegments(this);
    }

    static create(data: IEqualSegmentsModel) {
        return new EqualSegmentsModel(data, generateId());
    }

    setSegmentsCount(count: number) {
        this.segmentsCount = count;
    }
}

export interface IEqualSegmentsModelVisitor<R> {
    withEqualSegments: (equalSegments: EqualSegmentsModel) => R;
}
