import type { BaseElementModel } from "../element";
import type { IElementVisitor } from "../element/IElementVisitor";
import type { IElementFilter } from "../strategies/IElementFilter";

import { BaseDefaultElementVisitor } from "../element/BaseDefaultElementVisitor";

export type TClipboardPasteFilterConfig = {
    allowMultiline: boolean;
    allowEquationsSet: boolean;
};

export class ClipboardPasteFilter
    extends BaseDefaultElementVisitor<boolean>
    implements IElementVisitor<boolean>, IElementFilter
{
    protected config: TClipboardPasteFilterConfig;

    private constructor(config: TClipboardPasteFilterConfig) {
        super();
        this.config = config;
    }

    static Create(config: TClipboardPasteFilterConfig): IElementFilter {
        return new ClipboardPasteFilter(config);
    }

    canInsert(element: BaseElementModel): boolean {
        return element.accept(this);
    }

    protected defaultBehaviour(): boolean {
        return true;
    }

    override doWithLinebreak(): boolean {
        return this.config.allowMultiline;
    }

    override doWithEquationsSet(): boolean {
        return this.config.allowEquationsSet;
    }
}
