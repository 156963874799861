import React, { useEffect, useRef } from "react";

import { ReactComponent as PlusIcon } from "@viuch/assets/icons/plus-1.svg";
import { Portal } from "@viuch/shared/features/portal-lib";
import { useToggle } from "@viuch/utils/hooks";

import type { EditorTypes } from "../../../../editors/base";

import { ComplexEditorTypesList } from "../types-list/ComplexEditorTypesList";

import styles from "./ComplexEditorPlusButtonBlock.module.scss";

type TProps = {
    onClick(type: EditorTypes): void;
};

export const ComplexEditorPlusButtonBlock: React.VFC<TProps> = ({ onClick }) => {
    const [isListVisible, toggleIsListVisible, setIsVisible] = useToggle(false);

    const handleInstrumentTypeClick = (type: EditorTypes) => {
        onClick(type);
        setIsVisible(false);
    };

    const buttonRef = useRef<HTMLButtonElement>(null);
    const portalRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const buttonElement = buttonRef.current;
        const portalElement = portalRef.current;

        if (!buttonElement || !portalElement) return;

        const coords = buttonElement.getBoundingClientRect();

        portalElement.style.top = `${coords.top + window.scrollY}px`;
        portalElement.style.left = `${coords.right}px`;
    }, [isListVisible]);

    return (
        <div className={styles.plusButtonBlock}>
            <button
                className={styles.button}
                type="button"
                onClick={() => toggleIsListVisible()}
                ref={buttonRef}
            >
                <PlusIcon />
            </button>
            {isListVisible && (
                <Portal
                    className={styles.portal}
                    elementRef={portalRef}
                >
                    <ComplexEditorTypesList
                        onItemClick={handleInstrumentTypeClick}
                        onClose={() => setIsVisible(false)}
                    />
                </Portal>
            )}
        </div>
    );
};
