import { action, makeObservable, observable } from "mobx";

import { s2m } from "@viuch/math-editor";

import type { TSerializedState } from "@viuch/math-editor";

import { Graph2DInstrumentAxisSettings } from "./Graph2DInstrumentAxisSettings";

export class Graph2DInstrumentCoordinateSystemSettings {
    @observable.ref scaleX: TSerializedState;
    @observable.ref scaleY: TSerializedState;

    @observable.ref offsetX: TSerializedState;
    @observable.ref offsetY: TSerializedState;

    @observable.ref axisX: Graph2DInstrumentAxisSettings | null;
    @observable.ref axisY: Graph2DInstrumentAxisSettings | null;

    constructor(
        scaleX: TSerializedState,
        scaleY: TSerializedState,
        offsetX: TSerializedState,
        offsetY: TSerializedState,
        axisX: Graph2DInstrumentAxisSettings | null,
        axisY: Graph2DInstrumentAxisSettings | null
    ) {
        this.scaleX = scaleX;
        this.scaleY = scaleY;
        this.offsetX = offsetX;
        this.offsetY = offsetY;
        this.axisX = axisX;
        this.axisY = axisY;

        makeObservable(this);
    }

    @action.bound
    setEnableAxisX(enable: boolean) {
        if (enable) {
            if (!this.axisX) {
                this.axisX = new Graph2DInstrumentAxisSettings(s2m`x`);
            }
        } else {
            this.axisX = null;
        }
    }
    @action.bound
    setEnableAxisY(enable: boolean) {
        if (enable) {
            if (!this.axisY) {
                this.axisY = new Graph2DInstrumentAxisSettings(s2m`y`);
            }
        } else {
            this.axisY = null;
        }
    }

    @action.bound setScaleX(value: TSerializedState) {
        this.scaleX = value;
    }

    @action.bound setScaleY(value: TSerializedState) {
        this.scaleY = value;
    }

    @action.bound setOffsetX(value: TSerializedState) {
        this.offsetX = value;
    }

    @action.bound setOffsetY(value: TSerializedState) {
        this.offsetY = value;
    }

    clone() {
        const { scaleX, scaleY, offsetX, offsetY, axisX, axisY } = this;

        return new Graph2DInstrumentCoordinateSystemSettings(
            scaleX,
            scaleY,
            offsetX,
            offsetY,
            axisX?.clone() ?? null,
            axisY?.clone() ?? null
        );
    }
}
