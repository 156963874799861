import type { EventsController } from "./events";
import type { THandlersObject } from "./FigureViewModel";
import type { FlowManager } from "./flows";
import type { HistoryManager } from "./history";
import type { SelectionController } from "./selection";
import type { DeviceSettings, SnapToGridService } from "./services";
import type { ActionsManager } from "./services/actions";
import type { MoveDotService } from "./services/MoveDotService";
import type { ModelNormalizer } from "./services/normalizer";
import type { ToolbarController } from "./toolbar";
import type { ViewportController } from "./viewport";
import type { Figure2D } from "../entities/Figure2D";

import { DefaultFlow } from "./flows/default";
import { ViewFlow } from "./flows/view/ViewFlow";
import { getInitControllerData } from "./utils/init";

export interface IFigure2DController {
    viewport: ViewportController;
    events: EventsController;
    toolbar: ToolbarController;
    flows: FlowManager;
    selection: SelectionController;
    actions: ActionsManager;
    normalizer: ModelNormalizer;
    handlers: THandlersObject;
    history: HistoryManager;
    snap: SnapToGridService;
    moveDot: MoveDotService;
    device: DeviceSettings;
}

export type LazyFactory<T, A extends unknown[]> = {
    [K in keyof T & string as `get${Capitalize<K>}`]: (...args: A) => T[K];
};

export class Figure2DController implements IFigure2DController {
    readonly figure: Figure2D;
    readonly viewport: ViewportController;
    readonly events: EventsController;
    readonly toolbar: ToolbarController;
    readonly flows: FlowManager;
    readonly selection: SelectionController;
    readonly actions: ActionsManager;
    readonly normalizer: ModelNormalizer;
    readonly handlers: THandlersObject;
    readonly history: HistoryManager;
    readonly snap: SnapToGridService;
    readonly moveDot: MoveDotService;
    readonly device: DeviceSettings;

    constructor(figure: Figure2D) {
        const data = getInitControllerData(figure, {});

        this.figure = figure;
        this.viewport = data.getViewport(this);
        this.events = data.getEvents(this);
        this.toolbar = data.getToolbar(this);
        this.selection = data.getSelection(this);
        this.actions = data.getActions(this);
        this.normalizer = data.getNormalizer(this);
        this.handlers = data.getHandlers(this);
        this.history = data.getHistory(this);
        this.snap = data.getSnap(this);
        this.moveDot = data.getMoveDot(this);
        this.device = data.getDevice(this);
        this.flows = data.getFlows(this);
    }

    initialize() {
        const initialState = new DefaultFlow(this);

        this.flows.initialize(initialState);
    }

    initializeView() {
        this.flows.initialize(new ViewFlow(this));
    }

    dispose() {
        this.viewport.dispose();
    }
}
