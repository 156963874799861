import { action, makeObservable } from "mobx";

import { copyAngle, createFragment } from "@viuch/geometry-lib/factories";

import type { BaseElement } from "../../elements";
import type { Figure2DController } from "../../Figure2DController";
import type { TAngle } from "@viuch/geometry-lib/types";

import { FragmentModel } from "../../models/fragment";
import { MedianModel } from "../../models/geometry";
import { LabelPointModel } from "../../models/label-point";
import { PointModel } from "../../models/point";
import { getNewEqualSegmentsStrokesCount } from "../../utils/strokes-count";
import { BaseFlow } from "../BaseFlow";

export class MedianFlow extends BaseFlow {
    private readonly angle: TAngle;

    constructor(data: Figure2DController, angle: TAngle) {
        super(data);
        this.angle = copyAngle(angle);

        makeObservable(this, {
            saveModel: action.bound,
        });
    }

    attach(): void {
        Promise.resolve().then(this.saveModel);
    }

    saveModel() {
        const { start, end, vertex } = this.angle;
        const segmentsCount = getNewEqualSegmentsStrokesCount(this.figure);

        const fragment = createFragment(start, end);

        this.figure.insertModels(function* () {
            const model = MedianModel.create({ vertex, fragment, segmentsCount, style: null });
            yield model;

            yield LabelPointModel.createNext(model.midPoint, this.figure);

            for (const point of [vertex, start, end]) {
                yield PointModel.create({ ...point, style: null });
                yield LabelPointModel.createNext(point, this.figure);
            }
            yield FragmentModel.create({ ...fragment, style: null });
            yield FragmentModel.create({ a: vertex, b: start, style: null });
            yield FragmentModel.create({ a: vertex, b: end, style: null });
            yield FragmentModel.create({ a: start, b: end, style: null });
        }, this);
        this.nextFlow();
    }

    dispose(): void {}

    protected renderElements(): BaseElement[] {
        return [];
    }
}
