import React, { forwardRef } from "react";

import type { PointerEventHandler } from "react";

import styles from "./SelectionPointer.module.scss";

export interface ISelectionPointerProps {
    onPointerDown?: PointerEventHandler<HTMLDivElement>;
    onPointerMove?: PointerEventHandler<HTMLDivElement>;
    onPointerUp?: PointerEventHandler<HTMLDivElement>;
    onPointerCancel?: PointerEventHandler<HTMLDivElement>;
}

export const SelectionPointer = forwardRef<HTMLDivElement, ISelectionPointerProps>(function SelectionPointer(
    { onPointerDown, onPointerMove, onPointerUp, onPointerCancel },
    ref
) {
    return (
        <div
            ref={ref}
            className={styles.selectionPointer}
            onPointerDown={onPointerDown}
            onPointerMove={onPointerMove}
            onPointerUp={onPointerUp}
            onPointerCancel={onPointerCancel}
        >
            <svg
                className={styles.selectionPointerImg}
                width="20"
                height="12"
                viewBox="0 0 20 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M0 12L9 0H11L20 12H0Z"
                    fill="currentColor"
                />
            </svg>
        </div>
    );
});
