import { action, makeObservable, observable } from "mobx";

import type { TComplexEditorUnion } from "@viuch/complex-editor";

export class Preamble {
    readonly id: number;
    @observable.ref title: string;
    @observable.ref editorContent: TComplexEditorUnion;

    constructor(id: number, title: string, editorContent: TComplexEditorUnion) {
        this.id = id;
        this.title = title;
        this.editorContent = editorContent;
        makeObservable(this);
    }

    @action.bound
    setTitle(value: string) {
        this.title = value;
    }

    @action.bound
    setEditorContent(value: TComplexEditorUnion) {
        this.editorContent = value;
    }

    clone(): Preamble {
        const { id, title, editorContent } = this;
        return new Preamble(id, title, editorContent);
    }
}
