import type { TRawAdminRole, TRawAdminRoleShort } from "./types";

import { agent } from "../../agent";
import { throwServiceError } from "../base/RequestError";

export class AdminRolesService {
    async getRoles(): Promise<TRawAdminRoleShort[]> {
        try {
            const { data } = await agent.get<TRawAdminRoleShort[]>(`/admins/roles/`);

            return data;
        } catch (e) {
            throwServiceError(e);
        }
    }

    async getRoleById(id: number): Promise<TRawAdminRole> {
        try {
            const { data } = await agent.get<TRawAdminRole>(`/admins/roles/${id}/`);

            return data;
        } catch (e) {
            throwServiceError(e);
        }
    }
}
