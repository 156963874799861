import { action, makeObservable, observable } from "mobx";

import type { ILabelElement } from "./BaseLabelElement";
import type { IElementVisitor } from "../BaseElement";

import { BaseLabelElement } from "./BaseLabelElement";

export interface ILabelDotElement extends ILabelElement {
    x: number;
    y: number;
}

export class LabelDotElement extends BaseLabelElement implements ILabelDotElement, ILabelElement {
    x: number;
    y: number;

    private constructor(data: ILabelDotElement) {
        super(data);

        this.x = data.x;
        this.y = data.y;
        this.notifyAction = data.notifyAction;

        makeObservable(this, {
            x: observable,
            y: observable,
            updateValue: action.bound,
        });
    }

    static create(data: ILabelDotElement) {
        return new LabelDotElement(data);
    }

    accept<R>(visitor: IElementVisitor<R>): R {
        return visitor.withLabelDot(this);
    }

    updateValue() {
        this.value = this.inputService.getSerializedState();
    }
}
