import { assert } from "@viuch/utils/debug";

import type { TPoint, TRect } from "../../types";

import { getRectFromDomRect, rectAddition } from "../../utils/positions";
import { isPointInsideRect } from "../../utils/validation";

export class PlaceholdersService {
    private readonly elements: Set<HTMLElement>;

    public constructor() {
        this.elements = new Set();
    }

    public registerPlaceholderElement(element: HTMLElement): void {
        assert(element);

        this.elements.add(element);
    }

    public freePlaceholderElement(element: HTMLElement): void {
        assert(element);

        const isDeleted = this.elements.delete(element);

        assert(isDeleted, element);
    }

    public checkIsPointInsidePlaceholder(point: TPoint, paddingRect?: TRect): boolean {
        const elements = Array.from(this.elements);

        for (const element of elements) {
            const domRect = element.getBoundingClientRect();
            let clientRect = getRectFromDomRect(domRect);
            if (paddingRect) {
                clientRect = rectAddition(clientRect, paddingRect);
            }

            if (isPointInsideRect(point, clientRect)) {
                return true;
            }
        }

        return false;
    }
}
