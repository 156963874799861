import cn from "classnames";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

import { Graph1DInstrumentSettingsEditor } from "@viuch/instrument-graph1d-settings/settings-editor";
import { CheckboxInput, SelectInput } from "@viuch/ui-common";

import type { Graph1DEditor } from "../../../editors/instruments/Graph1DEditor";
import type { TEditorBlockVerticalAlign } from "../../../view/editor-view/useRenderBlock";
import type { KeyboardService } from "@viuch/math-editor";
import type { TSelectInputData } from "@viuch/ui-common";

import styles from "./Graph1DInstrumentEditor.module.scss";

type Props = {
    editor: Graph1DEditor;
    keyboardService: KeyboardService;
};

export const Graph1DInstrumentEditor = observer(function Graph1DInstrumentEditor({ editor, keyboardService }: Props) {
    const { graph, setAutoHeight, autoHeight, verticalAlign, setVerticalAlign } = editor;

    const [large, setLarge] = useState(true);

    return (
        <div className={cn(styles.wrapper)}>
            <Graph1DInstrumentSettingsEditor
                graph={graph}
                keyboardService={keyboardService}
                frameClassName={cn(styles.instrument, large && styles.large)}
            />
            <div>
                <CheckboxInput
                    type="checkbox"
                    checked={autoHeight}
                    onChange={setAutoHeight}
                    labelAfter="Сжать по высоте"
                    noSpace
                />
                <CheckboxInput
                    type="checkbox"
                    checked={large}
                    onChange={setLarge}
                    labelAfter="Увеличить"
                    noSpace
                />
                <SelectInput<TEditorBlockVerticalAlign>
                    value={verticalAlign}
                    onChange={setVerticalAlign}
                    title="Вертикальное выравнивание"
                    data={verticalAlignData}
                    emptyLabel="По-умолчанию"
                />
            </div>
        </div>
    );
});

const verticalAlignData: TSelectInputData<TEditorBlockVerticalAlign> = [
    { label: "По-умолчанию", value: null },
    { label: "По верху", value: "top" },
    { label: "По центру", value: "center" },
    { label: "По низу", value: "bottom" },
];
