import { observer } from "mobx-react-lite";
import React from "react";

import { ConfirmModalWindow } from "../components/confirm-save-modal-window";
import { ModalWindow } from "../components/modal-window";
import { useRootStore } from "../integration";

export const Confirmations = observer(function Confirmations() {
    const { confirmation } = useRootStore();

    return (
        <>
            {confirmation.items.map(({ driver, uuid, text }) => (
                <ConfirmModalWindow
                    key={uuid}
                    onConfirm={driver.confirm}
                    onDiscard={driver.discard}
                >
                    {text}
                </ConfirmModalWindow>
            ))}

            {confirmation.notifications.map(({ driver, uuid, title, text }) => (
                <ModalWindow
                    key={uuid}
                    title={title ?? "Уведомление"}
                    onClose={() => driver.resolve()}
                >
                    <div>{text || "Подробности не указаны"}</div>
                </ModalWindow>
            ))}
        </>
    );
});
