import type { TIgnoredWordRequest, TIgnoredWordResponse, TUnrecognizedWord } from "./types";

import { agent } from "../../agent";
import { throwServiceError } from "../base/RequestError";

export class ExpressionsService {
    async allIgnoredWords(): Promise<TIgnoredWordResponse[]> {
        const { data } = await agent.get<TIgnoredWordResponse[]>("/expression_exclusions/");

        return data;
    }

    async addIgnoredWord(word: string): Promise<TIgnoredWordResponse> {
        try {
            const request: TIgnoredWordRequest = { word };
            const { data } = await agent.post<TIgnoredWordResponse>("/expression_exclusions/", request);

            return data;
        } catch (e) {
            throwServiceError(e);
        }
    }

    async deleteIgnoredWord(id: number): Promise<void> {
        await agent.delete(`/expression_exclusions/${id}/`);
    }

    async getUnrecognizedWords(startDate: Date, endDate: Date): Promise<TUnrecognizedWord[]> {
        try {
            const _endDate = new Date(endDate.toISOString());
            _endDate.setDate(_endDate.getDate() + 1);

            const params = {
                start: startDate.toISOString().split("T")[0],
                end: _endDate.toISOString().split("T")[0],
            };

            const { data } = await agent.get<TUnrecognizedWord[]>("/expression_unrecognized/", { params });

            return data;
        } catch (e) {
            throwServiceError(e);
        }
    }
}
