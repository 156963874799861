import type { TRawLoginRequest, TRawLoginResult, TRawUserInfo, TRawRefreshResult } from "./types";

import { agent } from "../../agent";
import { throwServiceError } from "../base/RequestError";

export class AuthService {
    async me(): Promise<TRawUserInfo> {
        try {
            const { data } = await agent.get<TRawUserInfo>("/auth/me/");

            return data;
        } catch (e) {
            throwServiceError(e);
        }
    }

    async login(request: TRawLoginRequest): Promise<TRawLoginResult> {
        try {
            const { data } = await agent.post<TRawLoginResult>("/auth/get_token/", request);

            return data;
        } catch (e) {
            throwServiceError(e);
        }
    }

    async refresh(refresh: string): Promise<TRawRefreshResult> {
        try {
            const { data } = await agent.post<TRawRefreshResult>("/auth/refresh_token/", { refresh });

            return data;
        } catch (e) {
            throwServiceError(e);
        }
    }
}
