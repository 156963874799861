import cn from "classnames";
import { observer } from "mobx-react-lite";
import React from "react";

import type { EditorTypes } from "../../editors/base";
import type { ComplexEditor } from "../../editors/complex";
import type { IComplexEditorGraph2DHost } from "../../editors/instruments/Graph2DEditor";
import type { IMathConverterService } from "../../editors/types";
import type { IUploadImageService } from "@viuch/instrument-picture-settings/settings-editor/types";
import type { KeyboardService } from "@viuch/math-editor";

import { createEditorByData } from "../../utils/data";

import { ComplexEditorActionsBlock } from "./components/actions-block";
import { ComplexEditorPlusButtonBlock } from "./components/plus-button-block";
import { useEditorRenderer } from "./useEditorRenderer";

import styles from "./ComplexEditorComponent.module.scss";

type Props = {
    complexEditor: ComplexEditor;
    uploadService: IUploadImageService;
    mathConverterService: IMathConverterService;
    keyboardService: KeyboardService;
    host: IComplexEditorGraph2DHost;
    className?: string;
};

export const ComplexEditorComponent = observer(function ComplexEditorComponent({
    complexEditor,
    className,
    keyboardService,
    uploadService,
    mathConverterService,
    host,
}: Props) {
    const handleAddClick = (index: number, type: EditorTypes) => {
        complexEditor.addEditorByIndex(index, createEditorByData({ type }));
    };

    const renderEditor = useEditorRenderer();

    return (
        <div className={cn(styles.wrapper, className)}>
            <div className={styles.editorBlock}>
                <ComplexEditorPlusButtonBlock onClick={(type) => handleAddClick(0, type)} />
            </div>
            {complexEditor.editors.map((editor, index) => (
                <div
                    key={editor.uuid}
                    className={styles.editorBlock}
                >
                    {renderEditor(editor, {
                        keyboardService,
                        uploadService,
                        mathConverterService,
                        host,
                    })}
                    <ComplexEditorActionsBlock
                        onAddClick={(type) => handleAddClick(index + 1, type)}
                        onRemoveClick={() => complexEditor.removeEditorByIndex(index)}
                    />
                </div>
            ))}
        </div>
    );
});
