import type { Graph2DModel } from "../core/Graph2DModel";
import type { Graph2DView } from "../core/Graph2DView";
import type { RefObject } from "react";

export class InstrumentGraph2DEditorStore {
    private readonly model: Graph2DModel;
    private readonly view: Graph2DView;
    private readonly rootRef: RefObject<HTMLDivElement>;

    constructor(model: Graph2DModel, view: Graph2DView, rootRef: RefObject<HTMLDivElement>) {
        this.rootRef = rootRef;
        this.model = model;
        this.view = view;
    }

    effect = () => {
        const rootElement = this.rootRef.current!;
        this.view.viewport.setRootElement(rootElement);

        const resizeObserver = new ResizeObserver((entries) => {
            for (const entry of entries) {
                if (entry.target === rootElement) {
                    const { width, height } = entry.contentRect;
                    this.view.viewport.setRootSize(width, height);
                }
            }
        });

        resizeObserver.observe(rootElement);

        return () => {
            return resizeObserver.disconnect();
        };
    };
}
