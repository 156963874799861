import type { TSpecialSymbols } from "./SpecialSymbolElement.types";

import { ReactComponent as InfinityIcon } from "./icons/infinity-sign.svg";

import styles from "./SpecialSymbolElement.module.scss";

export const specialSymbols: TSpecialSymbols = {
    pi: { char: "π" },
    delta: { className: styles.delta },
    comma: { char: "," },
    dot: { char: "." },
    colon: { char: ":" },
    semicolon: { char: ";" },
    infinity: {
        className: styles.infinity,
        renderIcon: () => <InfinityIcon className={styles.infinity__icon} />,
    },
    percent: { char: "%" },
    exhibitor: { char: "e" },
    factorial: { char: "!" },
    "empty-set": { char: "∅" },
    tilda: { char: "~" },
    dollar: { char: "$" },
    question: { char: "?" },
    arc: { char: "◡" },
    collinear: { char: "⇈" },
    "counter-collinear": { char: "⇅" },
};
