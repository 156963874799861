import cn from "classnames";
import { observer } from "mobx-react-lite";
import { useEffect, useRef } from "react";

import type { DerivativeModel } from "./DerivativeModel";
import type { TElementProps } from "../../types/element";
import type { VFC } from "react";

import { Baseline } from "../../core/element";
import { useMathEditorElementRef } from "../../hooks";

import { applyMargin } from "./DerivativeElement.utils";

import styles from "./DerivativeElement.module.scss";

export const DerivativeElement: VFC<TElementProps<DerivativeModel>> = observer(function DerivativeElement({
    elementModel,
}) {
    const ref = useMathEditorElementRef<HTMLSpanElement>(elementModel);
    const baselineRef = useRef<HTMLSpanElement>(null);

    useEffect(() => {
        const baseline = baselineRef.current!;
        const elementBefore = elementModel.domElementBefore;

        if (!elementBefore) {
            baseline.style.marginTop = "0.5em";
            return;
        }

        const observer = new ResizeObserver(() => {
            applyMargin(elementBefore, baseline);
        });

        observer.observe(elementBefore);

        return () => observer.disconnect();
    }, [elementModel.domElementBefore]);

    return (
        <span
            ref={ref}
            className={styles.inline}
        >
            <span className={styles.inline}>
                <Baseline ref={baselineRef} />
                <span className={cn(styles.inline, styles.derivative)} />
            </span>
        </span>
    );
});
