import type { TRawImageMaterial } from "./ImageMaterialsService.types";
import type { ImageMaterial } from "../../entities/image-material/ImageMaterial";

import { agent } from "../../agent";
import { throwServiceError } from "../base/RequestError";

import {
    mapImageMaterial,
    serializeImageMaterialCreate,
    serializeImageMaterialUpdate,
} from "./ImageMaterialsService.utils";

export class ImageMaterialsService {
    async getAllRaw(): Promise<TRawImageMaterial[]> {
        try {
            const { data } = await agent.get<TRawImageMaterial[]>(`/image_materials/`);

            return data;
        } catch (e) {
            throwServiceError(e);
        }
    }

    async getRawById(id: number): Promise<TRawImageMaterial> {
        try {
            const { data } = await agent.get<TRawImageMaterial>(`/image_materials/${id}/`);

            return data;
        } catch (e) {
            throwServiceError(e);
        }
    }

    async create(imageMaterial: ImageMaterial): Promise<ImageMaterial> {
        try {
            const requestData = serializeImageMaterialCreate(imageMaterial);

            const { data } = await agent.post<TRawImageMaterial>("/image_materials/", requestData, {
                headers: { "Content-Type": "multipart/form-data" },
            });

            return mapImageMaterial(data);
        } catch (e) {
            throwServiceError(e);
        }
    }

    async update(imageMaterial: ImageMaterial): Promise<ImageMaterial> {
        try {
            const { method, data: requestData } = serializeImageMaterialUpdate(imageMaterial);

            const { data } = await agent[method]<TRawImageMaterial>(
                `/image_materials/${imageMaterial.id}/`,
                requestData,
                {
                    headers: { "Content-Type": method === "put" ? "multipart/form-data" : "application/json" },
                }
            );

            return mapImageMaterial(data);
        } catch (e) {
            throwServiceError(e);
        }
    }

    async getByThemeId(themeId: number): Promise<ImageMaterial[]> {
        try {
            const { data } = await agent.get<TRawImageMaterial[]>(`/image_materials/?themes_in=${themeId}`);

            return data.map((m) => mapImageMaterial(m));
        } catch (e) {
            throwServiceError(e);
        }
    }

    async remove(imageMaterialId: number): Promise<void> {
        try {
            await agent.delete(`/image_materials/${imageMaterialId}/`);
        } catch (e) {
            throwServiceError(e);
        }
    }
}
