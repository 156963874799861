export function isArrayOfStrings(data: unknown | Array<unknown>): data is Array<string> {
    if (!Array.isArray(data)) return false;

    for (const item of data) {
        if (typeof item !== "string") return false;
    }
    return true;
}

export function isArrayOfObjects<TObject extends object = object>(
    data: unknown | Array<unknown>
): data is Array<TObject> {
    if (!Array.isArray(data)) return false;

    for (const item of data) {
        if (typeof item !== "object") return false;
    }
    return true;
}
