import { mapDate, serializeDate } from "@viuch/shared/serialization/dates";
import { mapDurationToSeconds, serializeDuration } from "@viuch/shared/serialization/durations";

import type { GlobalStudyPlansService } from "./GlobalStudyPlansService";
import type { TGlobalStudyPlanShort } from "../../entities/global-study-plan/GlobalStudyPlanShort";
import type { GetError } from "../base/RequestError";

import { GlobalStudyPlan } from "../../entities/global-study-plan/GlobalStudyPlan";
import { QuarterShort } from "../../entities/global-study-plan/GlobalStudyPlanQuarter";
import { ThemeWithDuration } from "../../entities/global-study-plan/ThemeWithDuration";
import { RequestError } from "../base/RequestError";
import { mapGradeShort } from "../grades/gradeSerializers";

export function mapGlobalStudyPlanShort(
    data: GlobalStudyPlansService.TGlobalStudyPlanShortResponse
): TGlobalStudyPlanShort {
    const { name, id, grade } = data;
    return {
        id,
        name,
        grade: {
            id: grade.id,
            gradeValue: grade.grade,
        },
    };
}

export function mapGlobalStudyPlan(data: GlobalStudyPlansService.TGlobalStudyPlanResponse): GlobalStudyPlan {
    const { name, grade, id, quarters, subject } = data;

    return new GlobalStudyPlan(
        id,
        name,
        mapGradeShort(grade),
        subject,
        quarters.map(
            ({ end, themes, start }) =>
                new QuarterShort({
                    themes: themes.map(
                        (theme) => new ThemeWithDuration(theme.id, theme.name, mapDurationToSeconds(theme.duration))
                    ),
                    startDate: mapDate(start),
                    endDate: mapDate(end),
                })
        )
    );
}

export function serializeGlobalStudyPlan(plan: GlobalStudyPlan): GlobalStudyPlansService.TGlobalStudyPlanRequest {
    const { id, name, quarters, grade, subject } = plan;

    if (!subject) {
        throw new RequestError<GetError<GlobalStudyPlansService.TGlobalStudyPlanRequest>>({
            subject: ["Обязательное поле"],
        });
    }

    return {
        id,
        name,
        grade: grade?.id || null,
        quarters: quarters.map((plan) => ({
            start: plan.startDate ? serializeDate(plan.startDate) : null,
            end: plan.endDate ? serializeDate(plan.endDate) : null,
            themes: plan.themes.map((theme) => ({
                id: theme.id,
                duration: serializeDuration(theme.durationSeconds),
            })),
        })),
        subject: subject.id,
    };
}
