import type { TFormControl } from "@viuch/form-builder/types/controls";

import { createLabel } from "../controls/label";
import { exprMathControl } from "../controls/value";

export const linearIntegerSystem: TFormControl = {
    control: "Form",
    layout: "column",
    condition: { "./solver_type": "linear_integer_system" },
    controls: [
        createLabel("Находит все целочисленные решения системы линейных уравнений и неравенств."), //
        exprMathControl,
    ],
};

export const multivariativeSystem: TFormControl = {
    control: "Form",
    layout: "column",
    condition: { "./solver_type": "multivariative_system" },
    controls: [
        createLabel("Находит решение системы уравнений нескольких переменных"), //
        exprMathControl,
    ],
};

export const multivariativeSystemReplacement: TFormControl = {
    control: "Form",
    layout: "column",
    condition: { "./solver_type": "multivariative_system_replacement" },
    controls: [
        createLabel("Находит решение системы уравнений методом подстановки"), //
        exprMathControl,
    ],
};
