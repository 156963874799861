import cn from "classnames";
import { observer } from "mobx-react-lite";

import type { CSSProperties, PropsWithChildren } from "react";

import styles from "./PaymentFrame.module.scss";

type Props = PropsWithChildren<{
    className?: string;
    style?: CSSProperties;
}>;

export const PaymentFrame = observer(function PaymentFrame({ children, className, style }: Props) {
    return (
        <div
            className={cn(styles.outer, className)}
            style={style}
        >
            <div className={styles.inner}>
                <div className={styles.innerWrapper}>{children}</div>
            </div>
        </div>
    );
});
