import type { BaseElementModel } from "../../element";
import type { IElementFilter } from "../IElementFilter";

import { BaseDefaultElementVisitor } from "../../element/BaseDefaultElementVisitor";

export class CharsOnlyFilter extends BaseDefaultElementVisitor<boolean> implements IElementFilter {
    canInsert(element: BaseElementModel): boolean {
        return element.accept(this);
    }

    protected defaultBehaviour(element: BaseElementModel): boolean {
        return false;
    }

    override doWithChar(element: BaseElementModel): boolean {
        return true;
    }

    override doWithSpace(element: BaseElementModel): boolean {
        return true;
    }
}
