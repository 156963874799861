import type { ISerializedBracket, TBracketType } from "./types";
import type { TSerializedElementPrototype } from "../../core/element";
import type { IElementVisitor } from "../../core/element/IElementVisitor";
import type { InputService } from "../../services";
import type { TElementDeserializerFunction } from "../../types";

import { BaseElementModel } from "../../core/element";

export class BracketModel extends BaseElementModel<ISerializedBracket> {
    public readonly type: TBracketType;
    public readonly closing: boolean;

    public constructor(inputService: InputService, type: TBracketType, closing: boolean, uuid?: string) {
        super(inputService, uuid);

        this.type = type;
        this.closing = closing;
    }

    public serialize(): ISerializedBracket {
        return {
            type: "bracket",
            uuid: this.uuid,
            bracket: this.type,
            closing: this.closing,
        };
    }

    static deserialize: TElementDeserializerFunction<ISerializedBracket> = (
        { inputService },
        { bracket, closing, uuid }
    ) => new BracketModel(inputService, bracket, closing, uuid);

    public accept<R>(visitor: IElementVisitor<R>): R {
        return visitor.doWithBracket(this);
    }

    serializeAsClone(): TSerializedElementPrototype<ISerializedBracket> {
        const { uuid, ...clone } = this.serialize();
        return clone;
    }
}
