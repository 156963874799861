import type { TrigonometricOperatorModel } from "./TrigonometricOperatorModel";
import type { ContainerModel } from "../../core/container";
import type { BaseElementModel } from "../../core/element";
import type { IElementFilter } from "../../core/strategies/IElementFilter";

import { MultiContainerElementStrategy } from "../../core/strategies/MultiContainerElementStrategy";

import { TrigonometricContentFilter } from "./TrigonometricContentFilter";

export class TrigonometricOperatorStrategy extends MultiContainerElementStrategy<TrigonometricOperatorModel> {
    private contentFilter: IElementFilter;

    constructor(model: TrigonometricOperatorModel) {
        super(model);

        this.contentFilter = new TrigonometricContentFilter();
    }

    override checkCanBeInserted(
        element: BaseElementModel,
        container: ContainerModel<TrigonometricOperatorModel>
    ): boolean {
        if (container === this.model.content) {
            return this.contentFilter.canInsert(element);
        }
        return super.checkCanBeInserted(element, container);
    }
}
