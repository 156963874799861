import { action, makeObservable, observable } from "mobx";

import type {
    TGraph2DFeaturesConfiguration_Add,
    TGraph2DFeaturesConfiguration_Behaviour,
    TGraph2DFeaturesConfiguration_Modifier,
    TGraph2DFeaturesConfiguration_Transformation,
} from "@viuch/instrument-graph2d-plugins/shared/features";

export class Graph2DInstrumentConfigSettings {
    @observable.ref readonly: boolean;
    @observable.ref showGraphs: boolean;
    @observable.shallow behaviourFeatures: TGraph2DFeaturesConfiguration_Behaviour[];
    @observable.shallow addFeatures: TGraph2DFeaturesConfiguration_Add[];
    @observable.shallow transformationFeatures: TGraph2DFeaturesConfiguration_Transformation[];
    @observable.shallow modifierFeatures: TGraph2DFeaturesConfiguration_Modifier[];

    constructor(
        readonly: boolean,
        showGraphs: boolean,
        behaviourFeatures: TGraph2DFeaturesConfiguration_Behaviour[],
        addFeatures: TGraph2DFeaturesConfiguration_Add[],
        transformationsFeatures: TGraph2DFeaturesConfiguration_Transformation[],
        modifierFeatures: TGraph2DFeaturesConfiguration_Modifier[]
    ) {
        this.readonly = readonly;
        this.showGraphs = showGraphs;
        this.behaviourFeatures = behaviourFeatures.slice();
        this.addFeatures = addFeatures.slice();
        this.transformationFeatures = transformationsFeatures.slice();
        this.modifierFeatures = modifierFeatures.slice();

        makeObservable(this);
    }

    clone(): Graph2DInstrumentConfigSettings {
        const { readonly, showGraphs, behaviourFeatures, addFeatures, transformationFeatures, modifierFeatures } = this;

        return new Graph2DInstrumentConfigSettings(
            readonly,
            showGraphs,
            behaviourFeatures,
            addFeatures,
            transformationFeatures,
            modifierFeatures
        );
    }

    getAddFeature(feature: TGraph2DFeaturesConfiguration_Add): boolean {
        return this.addFeatures.includes(feature);
    }

    @action.bound
    setAddFeature(feature: TGraph2DFeaturesConfiguration_Add, enabled: boolean) {
        if (enabled) {
            if (!this.addFeatures.includes(feature)) {
                this.addFeatures.push(feature);
            }
        } else {
            this.addFeatures.remove(feature);
        }
    }

    getBehaviourFeature(feature: TGraph2DFeaturesConfiguration_Behaviour): boolean {
        return this.behaviourFeatures.includes(feature);
    }

    @action.bound
    setBehaviourFeature(feature: TGraph2DFeaturesConfiguration_Behaviour, enabled: boolean) {
        if (enabled) {
            if (!this.behaviourFeatures.includes(feature)) {
                this.behaviourFeatures.push(feature);
            }
        } else {
            this.behaviourFeatures.remove(feature);
        }
    }

    getTransformationFeature(feature: TGraph2DFeaturesConfiguration_Transformation): boolean {
        return this.transformationFeatures.includes(feature);
    }

    @action.bound
    setTransformationFeature(feature: TGraph2DFeaturesConfiguration_Transformation, enabled: boolean) {
        if (enabled) {
            if (!this.transformationFeatures.includes(feature)) {
                this.transformationFeatures.push(feature);
            }
        } else {
            this.transformationFeatures.remove(feature);
        }
    }

    getModifierFeature(feature: TGraph2DFeaturesConfiguration_Modifier): boolean {
        return this.modifierFeatures.includes(feature);
    }

    @action.bound
    setModifierFeature(feature: TGraph2DFeaturesConfiguration_Modifier, enabled: boolean) {
        if (enabled) {
            if (!this.modifierFeatures.includes(feature)) {
                this.modifierFeatures.push(feature);
            }
        } else {
            this.modifierFeatures.remove(feature);
        }
    }

    @action.bound
    setReadonly(readonly: boolean) {
        this.readonly = readonly;
    }

    @action.bound
    setIsResultGraphsShown(value: boolean): void {
        this.showGraphs = value;
    }
}
