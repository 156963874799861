import { flow, makeObservable, observable } from "mobx";

import { LazyConfirmation } from "@viuch/shared/features/lazy-result";
import { generateUuid } from "@viuch/shared/utils/data";
import { createPromiseController } from "@viuch/shared/utils/runtime/promises";

export class ConfirmationStore {
    @observable.shallow items: ConfirmationStore.ConfirmationData[] = [];
    @observable.shallow notifications: ConfirmationStore.NotificationData[] = [];

    constructor() {
        makeObservable(this);
    }

    async waitConfirmation(text?: string): Promise<boolean>;

    @flow.bound
    async *waitConfirmation(text?: string) {
        const data = new ConfirmationStore.ConfirmationData(text);

        let result: boolean;
        try {
            this.items.push(data);
            result = await data.driver;
        } finally {
            yield;
            const i = this.items.indexOf(data);
            if (i !== -1) {
                this.items.splice(i, 1);
            }
        }

        return result;
    }

    async showNotification(title: string, text: string): Promise<void>;

    @flow.bound
    async *showNotification(title: string, text: string) {
        const data = new ConfirmationStore.NotificationData(title, text);

        try {
            this.notifications.push(data);
            await data.driver;
        } finally {
            yield;
            this.notifications.remove(data);
        }
    }
}

export namespace ConfirmationStore {
    export class ConfirmationData {
        readonly uuid = generateUuid();
        readonly driver = new LazyConfirmation();
        readonly text?: string;

        constructor(text?: string) {
            this.text = text;
        }
    }

    export class NotificationData {
        readonly uuid = generateUuid();
        readonly driver = createPromiseController<void>();
        readonly text?: string;
        readonly title?: string;

        constructor(title?: string, text?: string) {
            this.title = title;
            this.text = text;
        }
    }
}
