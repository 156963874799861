import { observer } from "mobx-react-lite";
import React from "react";

import { Button } from "@viuch/ui-kit/buttons";

import type { PropsWithChildren } from "react";

import { ModalWindow } from "../modal-window";

import styles from "./ConfirmSaveModalWindow.module.scss";

type Props = PropsWithChildren<{
    onConfirm: VoidFunction;
    onDiscard: VoidFunction;
    onCancel?: VoidFunction;
    title?: string;
    labelYes?: string;
    labelNo?: string;
}>;

export const ConfirmModalWindow = observer(function ConfirmSaveModalWindow(props: Props) {
    const { children, onDiscard, onConfirm, onCancel, labelNo, labelYes, title } = props;
    return (
        <ModalWindow
            title={title ?? "Требуется подтверждение"}
            onClose={onCancel}
        >
            <div className={styles.content}>{children || "Подробности не указаны"}</div>
            <div className={styles.buttons}>
                <Button
                    className={styles.button}
                    onClick={onConfirm}
                >
                    {labelYes ?? "Да"}
                </Button>
                <Button
                    className={styles.button}
                    onClick={onDiscard}
                >
                    {labelNo ?? "Нет"}
                </Button>
            </div>
        </ModalWindow>
    );
});
