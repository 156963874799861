import { mapDate, serializeDate } from "@viuch/shared/serialization/dates";

import type {
    TGlobalStudyPlanTemplate,
    TGlobalStudyPlanTemplateQuarter,
} from "./GlobalStudyPlanTemplatesService.types";

import { GlobalStudyPlanTemplate, GlobalStudyPlanTemplateQuarter } from "../../entities/global-study-plan/templates";

export function mapGlobalStudyPlanTemplate(data: TGlobalStudyPlanTemplate): GlobalStudyPlanTemplate {
    return new GlobalStudyPlanTemplate(data.id, data.name, data.quarters.map(mapGlobalStudyPlanTemplateQuarter));
}

function mapGlobalStudyPlanTemplateQuarter(data: TGlobalStudyPlanTemplateQuarter): GlobalStudyPlanTemplateQuarter {
    return new GlobalStudyPlanTemplateQuarter(mapDate(data.start), mapDate(data.end));
}

export function serializeGlobalStudyPlanTemplate(template: GlobalStudyPlanTemplate): TGlobalStudyPlanTemplate {
    const { id, name, quarters } = template;
    return {
        id,
        name,
        quarters: quarters.map(serializeGlobalStudyPlanTemplateQuarter),
    };
}

function serializeGlobalStudyPlanTemplateQuarter(
    quarter: GlobalStudyPlanTemplateQuarter
): TGlobalStudyPlanTemplateQuarter {
    const { start, end } = quarter;
    return {
        start: serializeDate(start),
        end: serializeDate(end),
    };
}
