import {
    TableSelectInstrumentSettingsImpl,
    TableTextInstrumentSettingsImpl,
} from "@viuch/instrument-table-settings/settings";
import {
    SelectTableInstrumentSettingsCell,
    TextTableInstrumentSettingsCell,
} from "@viuch/instrument-table-settings/settings/cells";
import { TableViewSettings } from "@viuch/instrument-table-settings/settings/TableViewSettings";
import { createSerializedContainer, isZeroLengthMathExpr } from "@viuch/math-editor";

import type { IEditorVisitor } from "../IEditorVisitor";
import type { BaseTableInstrumentSettingsImpl } from "@viuch/instrument-table-settings/settings";
import type {
    BaseTableInstrumentSettingsCell,
    ITableCellVisitor,
    TSelectCellContent,
} from "@viuch/instrument-table-settings/settings/cells";
import type { TSerializedState } from "@viuch/math-editor";

import { BaseEditor, EditorTypes } from "../base";

export type TCell = TSerializedState | TSelectCellContent;

export type TTableInstrumentEditorSettings = {
    cells: TCell[][];
    title?: TSerializedState;
    viewSettings?: TTableInstrumentViewSettings | null;
};

export type TTableInstrumentViewSettings = {
    tableCenter: boolean;
    equalColumns: boolean;
    shrinkFirstColumn: boolean;
    centerCells: boolean;
    centerFirstRow: boolean;
    centerFirstColumn: boolean;
    wrapTable: boolean;
    wrapFirstRow: boolean;
    wrapFirstColumn: boolean;
    highlightFirstRow: boolean;
    highlightFirstColumn: boolean;
    boldFirstRow: boolean;
    boldFirstColumn: boolean;
};

export type TType = EditorTypes.table | EditorTypes.tableSelect;

export type TTableInstrumentEditorData = {
    type: TType;
    settings?: TTableInstrumentEditorSettings;
};

const cellSerializer: ITableCellVisitor<TCell> = {
    withSelectCell: (model) => model.content,
    withTextCell: (model) => model.formula,
};

export class TableInstrumentEditor extends BaseEditor<TTableInstrumentEditorData> {
    public readonly table: BaseTableInstrumentSettingsImpl;
    public readonly type: TType;

    constructor(type: TType, settings?: TTableInstrumentEditorSettings) {
        super();
        this.type = type;
        this.table = this.deserializeTable(type, settings);
    }

    accept<R>(visitor: IEditorVisitor<R>): R {
        return visitor.visitTable(this);
    }

    checkIsEmpty(): boolean {
        return false;
    }

    async serialize(): Promise<TTableInstrumentEditorData> {
        const {
            tableCenter,
            equalColumns,
            shrinkFirstColumn,
            centerCells,
            centerFirstRow,
            centerFirstColumn,
            wrapTable,
            wrapFirstRow,
            wrapFirstColumn,
            highlightFirstRow,
            highlightFirstColumn,
            boldFirstRow,
            boldFirstColumn,
        } = this.table.viewSettings;

        return {
            type: this.type,
            settings: {
                cells: this.table.cells.map((row) => row.map((cell) => cell.accept(cellSerializer))),
                title: isZeroLengthMathExpr(this.table.title) ? void 0 : this.table.title,
                viewSettings: {
                    tableCenter: tableCenter.value,
                    equalColumns: equalColumns.value,
                    shrinkFirstColumn: shrinkFirstColumn.value,
                    centerCells: centerCells.value,
                    centerFirstRow: centerFirstRow.value,
                    centerFirstColumn: centerFirstColumn.value,
                    wrapTable: wrapTable.value,
                    wrapFirstRow: wrapFirstRow.value,
                    wrapFirstColumn: wrapFirstColumn.value,
                    highlightFirstRow: highlightFirstRow.value,
                    highlightFirstColumn: highlightFirstColumn.value,
                    boldFirstRow: boldFirstRow.value,
                    boldFirstColumn: boldFirstColumn.value,
                },
            },
        };
    }

    private deserializeTable(type: TType, data?: TTableInstrumentEditorSettings): BaseTableInstrumentSettingsImpl {
        if (!data) {
            if (type === EditorTypes.table) {
                return TableTextInstrumentSettingsImpl.createEmpty();
            }
            return TableSelectInstrumentSettingsImpl.createEmpty();
        }

        const cells = data.cells.map((row) => row.map((cell) => this.deserializeCell(cell)));

        const viewSettings = new TableViewSettings(data.viewSettings ?? {});

        if (type === EditorTypes.table) {
            return new TableTextInstrumentSettingsImpl(
                cells,
                data.title ?? createSerializedContainer(),
                false,
                viewSettings
            );
        }
        return new TableSelectInstrumentSettingsImpl(
            cells,
            data.title ?? createSerializedContainer(),
            false,
            viewSettings
        );
    }

    private deserializeCell(cell: TCell): BaseTableInstrumentSettingsCell {
        if (typeof cell === "string") {
            return new SelectTableInstrumentSettingsCell(false, cell);
        }
        return new TextTableInstrumentSettingsCell(false, cell);
    }
}
