import type { ISerializedDigit } from "./types";
import type { TSerializedElementPrototype } from "../../core/element";
import type { IElementVisitor } from "../../core/element/IElementVisitor";
import type { InputService } from "../../services";
import type { TElementDeserializerFunction } from "../../types";

import { BaseElementModel } from "../../core/element";

export class DigitModel extends BaseElementModel<ISerializedDigit> {
    public readonly digit: number;

    public constructor(inputService: InputService, digit: number, uuid?: string) {
        super(inputService, uuid);
        this.digit = +digit;
    }

    override isEquals(otherElement: BaseElementModel): boolean {
        return otherElement instanceof DigitModel && otherElement.digit === this.digit;
    }

    public serialize(): ISerializedDigit {
        return { type: "digit", uuid: this.uuid, digit: +this.digit };
    }

    public static deserialize: TElementDeserializerFunction<ISerializedDigit> = ({ inputService }, { uuid, digit }) =>
        new DigitModel(inputService, +digit, uuid);

    public accept<R>(visitor: IElementVisitor<R>): R {
        return visitor.doWithDigit(this);
    }

    serializeAsClone(): TSerializedElementPrototype<ISerializedDigit> {
        const { uuid, ...clone } = this.serialize();
        return clone;
    }
}
