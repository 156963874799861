/* eslint-disable react-hooks/rules-of-hooks */
import { observer } from "mobx-react-lite";
import { useLayoutEffect } from "react";
import { createPortal } from "react-dom";

import { useConst } from "@viuch/utils/hooks";

import type { PropsWithChildren, Ref } from "react";

import { setRefValue } from "../../utils/react";

type Props = PropsWithChildren<{
    target?: HTMLElement;
    className?: string;
    elementRef?: Ref<HTMLElement>;
}>;

export const Portal = observer(function Portal({ children, target, className, elementRef }: Props) {
    if (isServer) return null;

    const divElement = useConst(() => {
        const element = document.createElement("div");
        setRefValue(elementRef, element);
        return element;
    });

    useLayoutEffect(() => {
        const root = target || document.body;

        root.appendChild(divElement);

        return () => {
            divElement.remove();
        };
    }, [target, divElement]);

    useLayoutEffect(() => {
        divElement.className = className || "";
    }, [className, divElement]);

    return createPortal(children, divElement);
});

const isServer = typeof window === "undefined";
