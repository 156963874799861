import type { TRawFrequentlyAskedQuestion, TRawFrequentlyAskedQuestionsList } from "./FAQService.types";

import { FrequentlyAskedQuestion, FrequentlyAskedQuestionsList } from "../../entities/faq/faq";

export function mapFrequentlyAskedQuestionsList(data: TRawFrequentlyAskedQuestionsList): FrequentlyAskedQuestionsList {
    const { email, questions } = data;

    const mappedQuestions: FrequentlyAskedQuestion[] = Array.isArray(questions)
        ? questions.map(mapFrequentlyAskedQuestion)
        : [];

    return new FrequentlyAskedQuestionsList(mappedQuestions, email);
}

export function mapFrequentlyAskedQuestion(data: TRawFrequentlyAskedQuestion): FrequentlyAskedQuestion {
    const { answer, question } = data;

    return new FrequentlyAskedQuestion(question, answer);
}

export function serializeFrequentlyAskedQuestion(data: FrequentlyAskedQuestion): TRawFrequentlyAskedQuestion {
    const { answer, question } = data;

    return { answer, question };
}

export function serializeFrequentlyAskedQuestionsList(
    list: FrequentlyAskedQuestionsList
): TRawFrequentlyAskedQuestionsList {
    const { email, questions } = list;

    return {
        email,
        questions: questions.map(serializeFrequentlyAskedQuestion),
    };
}
