import { action, makeObservable, observable } from "mobx";

import { isZeroLengthMathExpr, type TSerializedState } from "@viuch/math-editor";
import { generateId } from "@viuch/shared/utils/data";

import type { FormulaMultichoiceInstrumentSettingsField } from "./choices/FormulaMultichoiceInstrumentSettingsField";
import type { ImageMultichoiceInstrumentSettingsField } from "./choices/ImageMultichoiceInstrumentSettingsField";
import type { TextMultichoiceInstrumentSettingsField } from "./choices/TextMultichoiceInstrumentSettingsField";

export abstract class BaseMultichoiceInstrumentSettingsField {
    readonly $$instanceId = generateId();
    @observable.ref checked: boolean;
    @observable.ref mistakeHint: TSerializedState | null;

    protected constructor(checked: boolean, mistakeHint: TSerializedState | null) {
        this.checked = checked;
        this.mistakeHint = mistakeHint;

        makeObservable(this);
    }

    abstract clone(): BaseMultichoiceInstrumentSettingsField;

    abstract accept<R>(visitor: IMultichoiceInstrumentSettingsFieldVisitor<R>): R;

    @action.bound
    setChecked(checked: boolean) {
        this.checked = checked;
    }

    @action.bound
    setMistakeHint(value: TSerializedState | null) {
        this.mistakeHint = value && !isZeroLengthMathExpr(value) ? value : null;
    }
}

export interface IMultichoiceInstrumentSettingsFieldVisitor<R> {
    withFormula(field: FormulaMultichoiceInstrumentSettingsField): R;
    withText(field: TextMultichoiceInstrumentSettingsField): R;
    withImage(field: ImageMultichoiceInstrumentSettingsField): R;
}
