import cn from "classnames";
import { observer } from "mobx-react-lite";
import React from "react";

import { InstrumentGrid } from "@viuch/ui-kit/instrument-grid";

import type { Graph1DInstrumentStatement, Graph1DInstrumentStatementDot } from "../statement";

import { GRAPH_STEPS } from "./constants";
import { Dot, Line } from "./elements";

import styles from "./Graph1DInstrumentStatementEditor.module.scss";

type Props = {
    graph: Graph1DInstrumentStatement;
    onEditDot?(graph: Graph1DInstrumentStatement, dot: Graph1DInstrumentStatementDot): void;
    onMoveDot?(graph: Graph1DInstrumentStatement, dot: Graph1DInstrumentStatementDot, position: number): void;
    onAddDot?(graph: Graph1DInstrumentStatement, position: number): void;
    onClear?(graph: Graph1DInstrumentStatement): void;
    className?: string;
};

export const Graph1DInstrumentStatementEditor = observer(function Graph1DInstrumentStatementEditor(props: Props) {
    const { className, graph, onEditDot, onAddDot, onMoveDot, onClear } = props;

    const containerRef = React.useRef<HTMLDivElement>(null);
    const lineRef = React.useRef<HTMLDivElement>(null);

    const handleEditDot = (dot: Graph1DInstrumentStatementDot) => {
        onEditDot?.(graph, dot);
    };

    const handleMoveDot = (dot: Graph1DInstrumentStatementDot, position: number) => {
        onMoveDot?.(graph, dot, position);
    };

    const handleAddDot = (position: number) => {
        onAddDot?.(graph, position);
    };

    const handleClear = () => {
        onClear?.(graph);
    };

    return (
        <div className={cn(className, styles.wrapper)}>
            <div className={styles.area}>
                <InstrumentGrid
                    startRef={containerRef}
                    steps={GRAPH_STEPS}
                />
                <div
                    className={styles.container}
                    ref={containerRef}
                >
                    <Line
                        onLineClick={handleAddDot}
                        lineRef={lineRef}
                        axis={graph.axis}
                    >
                        {graph.dots.map((dot) => (
                            <Dot
                                key={dot.$$instanceId}
                                dot={dot}
                                onMoveDot={handleMoveDot}
                                onEditDot={handleEditDot}
                                lineRef={lineRef}
                            />
                        ))}
                    </Line>
                </div>
                <div className={styles.toolbar}>
                    <button
                        onClick={handleClear}
                        className={styles.button}
                    >
                        <div className={styles.icon} />
                    </button>
                </div>
            </div>
        </div>
    );
});
