import cn from "classnames";
import React, { useCallback, useEffect, useRef, useState } from "react";

import type { TAnyAction } from "../../../../types";
import type { THoldRegistrationEffect, TMathKeyboardButton } from "../types";

import { KeyboardIcon, KeyboardTextIcon } from "../../../keyboard/elements";

import styles from "./HoldButton.module.scss";

type Props = {
    registerHold: THoldRegistrationEffect;
    button: TMathKeyboardButton;
    onClick?(action: TAnyAction): void;
    isShiftPressed: boolean;
};

export const HoldButton = ({
    registerHold,
    button: { icon, actionCreator, text, symbols },
    onClick,
    isShiftPressed,
}: Props) => {
    const ref = useRef<HTMLDivElement>(null);
    const [isHover, setIsHover] = useState(false);
    const caseTransformer = isShiftPressed ? "toUpperCase" : "toLowerCase";

    const handleEnter = useCallback(() => {
        setIsHover(true);
    }, []);

    const handleExit = useCallback(() => {
        setIsHover(false);
    }, []);

    const handleDrop = useCallback(() => {
        onClick?.(actionCreator({ isShiftPressed }));
    }, [actionCreator, onClick, isShiftPressed]);

    useEffect(() => {
        const element = ref.current!;
        const { x, y, width: w, height: h } = element.getBoundingClientRect();

        const disposeRegistration = registerHold({
            rect: { x, y, w, h },
            onDrop: handleDrop,
            onEnter: handleEnter,
            onExit: handleExit,
        });

        return () => {
            disposeRegistration();
        };
    }, [handleDrop, handleEnter, handleExit, registerHold]);

    return (
        <div
            className={cn(styles.wrapper, isHover && styles.hover)}
            ref={ref}
        >
            {icon && (
                <KeyboardIcon
                    iconName={icon}
                    className={cn(styles.icon, isHover && styles.hover)}
                />
            )}
            {text && (
                <KeyboardTextIcon className={cn(styles.textIcon, isHover && styles.hover)}>
                    {text[caseTransformer]()}
                </KeyboardTextIcon>
            )}
            {symbols && (
                <KeyboardTextIcon className={cn(styles.textIcon, isHover && styles.hover)}>{symbols}</KeyboardTextIcon>
            )}
        </div>
    );
};
