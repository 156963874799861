import cn from "classnames";

import type { TKeyboardTextIconProps } from "./KeyboardTextIcon.types";

import styles from "./KeyboardTextIcon.module.scss";

const KeyboardTextIcon = ({ children, className, size = "large" }: TKeyboardTextIconProps) => (
    <span className={cn(styles.icon, className)}>
        <span className={cn(styles.content, styles[size])}>{children}</span>
    </span>
);

export default KeyboardTextIcon;
