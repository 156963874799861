import type { GradesService } from "./GradesService";
import type { TGradeShort } from "../../entities/grades";

import { Grade } from "../../entities/grades";

export function mapGrade(data: GradesService.TGradeResponse): Grade {
    const { grade, id, subjects, final_exam, exams } = data;

    return new Grade(id, grade, exams, final_exam, subjects);
}

export function serializeGrade(grade: Grade): GradesService.TGradeRequest {
    const { id, examIds, subjectIds, finalExamId, gradeValue } = grade;
    return {
        id,
        final_exam: finalExamId,
        exams: examIds,
        grade: gradeValue,
        subjects: subjectIds,
    };
}

export function mapGradeShort(data: GradesService.TGradeShortResponse): TGradeShort {
    const { grade, id } = data;
    return { id, gradeValue: grade };
}
