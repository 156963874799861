import type { EqualityModel } from "../../../elements/equality/EqualityModel";
import type { LowPriorityOperatorModel } from "../../../elements/operator-low/LowPriorityOperatorModel";
import type { SpecialSymbolModel } from "../../../elements/symbol/SpecialSymbolModel";
import type { BaseElementModel } from "../../element";
import type { IElementFilter } from "../IElementFilter";

import { BaseDefaultElementVisitor } from "../../element/BaseDefaultElementVisitor";

export class KeepCommonFilter extends BaseDefaultElementVisitor<boolean> implements IElementFilter {
    canInsert(element: BaseElementModel): boolean {
        return element.accept(this);
    }

    protected defaultBehaviour(): boolean {
        return false;
    }

    override doWithChar(): boolean {
        return true;
    }

    override doWithSpace(): boolean {
        return true;
    }

    override doWithDigit(): boolean {
        return true;
    }

    override doWithOperatorLow(element: LowPriorityOperatorModel): boolean {
        switch (element.symbolName) {
            case "plus-minus":
                return false;
        }
        return true;
    }

    override doWithOperatorHigh(): boolean {
        return true;
    }

    override doWithBracket(): boolean {
        return true;
    }

    override doWithEquality(element: EqualityModel): boolean {
        switch (element.symbolName) {
            case "not-equals":
            case "equals":
                return true;
        }
        return false;
    }

    override doWithSymbol(element: SpecialSymbolModel): boolean {
        switch (element.symbolName) {
            case "semicolon":
            case "dot":
            case "comma":
            case "factorial":
            case "dollar":
            case "pi":
                return true;
        }
        return false;
    }

    override doWithDegree(): boolean {
        return true;
    }

    override doWithRoot(): boolean {
        return true;
    }

    override doWithFraction(): boolean {
        return true;
    }

    override doWithDerivative(): boolean {
        return true;
    }

    override doWithModule(): boolean {
        return true;
    }

    override doWithLog(): boolean {
        return true;
    }

    override doWithNaturalLog(): boolean {
        return true;
    }

    override doWithDownIndex(): boolean {
        return true;
    }

    override doWithLim(): boolean {
        return true;
    }

    override doWithTrigonometricOperator(): boolean {
        return true;
    }
}
