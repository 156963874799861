import type { TAnyAction } from "../types";

export type TLimAction = { type: "lim" };

export function createLimAction(): TLimAction {
    return { type: "lim" };
}

export function isLimAction(action: TAnyAction): action is TLimAction {
    return action.type === "lim";
}
