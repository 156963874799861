import { action, computed, makeObservable, observable } from "mobx";
import { createTransformer } from "mobx-utils";

import { getAngles, getMiddleAngle, normalizeAngle } from "@viuch/geometry-lib/angles";
import { areSamePoints } from "@viuch/geometry-lib/check-geometry";
import { createBasis } from "@viuch/geometry-lib/factories";
import { assert } from "@viuch/utils/debug";

import type { BaseElement } from "../../elements";
import type { Figure2DController } from "../../Figure2DController";
import type { ToolbarMenu } from "../../toolbar";
import type { TBisectionBindingData } from "../../utils/bisections";
import type { IUserPointer } from "../../viewport/types";
import type { TPoint } from "@viuch/geometry-lib/types";
import type React from "react";

import { AngleElement } from "../../elements/angle";
import { DotElement } from "../../elements/dot";
import { ElementColor } from "../../elements/ElementColor";
import { LineElement } from "../../elements/line";
import { FragmentModel } from "../../models/fragment";
import { BisectionModel } from "../../models/geometry";
import { LabelPointModel } from "../../models/label-point";
import { PointModel } from "../../models/point";
import { ButtonStates } from "../../toolbar";
import { TooltipMenu } from "../../toolbar/tooltip";
import { findBisectionPoints, getBisectionTargetData } from "../../utils/bisections";
import { getNewEqualAnglesSegmentsCount } from "../../utils/strokes-count";
import { createDefaultToolbarMenuBuilder } from "../../utils/toolbar";
import { findAllViewFragments, findVertexViewPointsOnFragments } from "../../utils/visible-fragments";
import { findAllViewPoints } from "../../utils/visible-points";
import { BaseFlow } from "../BaseFlow";
import { createMapModelToElements, opacifyColor } from "../utils";

export class InteractiveBisectionFlow extends BaseFlow {
    flow:
        | {
              stage: 1;
              vertex?: TPoint;
              drawing: boolean;
          }
        | {
              stage: 2;
              readonly availableBisections: TBisectionBindingData[];
              readonly vertex: TPoint;
              targetPoint?: TPoint;
              drawing: boolean;
          };

    constructor(data: Figure2DController) {
        super(data);

        this.flow = { stage: 1, drawing: false };

        makeObservable(this, {
            flow: observable,
            modelElements: computed,
            tempElements: computed,
            checkAndCreate: action.bound,
        });
    }

    attach(): void {
        this.snap.clean().addFigureModelsPoints();
        this.viewport.disable();
    }

    dispose(): void {
        this.snap.clean();
    }

    override handleViewportPointerEvent(e: React.PointerEvent, pointer: IUserPointer): void {
        if (e.type === "pointercancel") return this.nextFlow();
        if (!e.isPrimary) return;

        const point = this.snap.snap(pointer.canvas);

        this.updatePosition(e, point);
        this.startDrawing(e);
        this.endDrawing(e);
    }

    checkAndCreate() {
        if (this.flow.stage !== 2 || !this.flow.targetPoint) return;
        const { targetPoint, vertex } = this.flow;
        const data = this.findCurrentBisection(targetPoint);
        if (data) {
            this.internalCreate(targetPoint, vertex, data);
        }
    }

    protected internalCreate(targetPoint: TPoint, vertex: TPoint, data: TBisectionBindingData): void {
        const { angle, basis, angleOffset, basisOffset } = data;
        const segmentsCount = getNewEqualAnglesSegmentsCount(this.figure);

        this.figure.insertModels(function* () {
            const model = BisectionModel.create({
                angle,
                segmentsCount,
                basis: createBasis(basis, basisOffset, angleOffset),
                style: null,
            });
            yield model;

            model.midPoint && (yield LabelPointModel.createNext(model.midPoint, this.figure));

            yield FragmentModel.create({ b: angle.vertex, a: angle.start, style: null });
            yield FragmentModel.create({ b: angle.vertex, a: angle.end, style: null });
            yield PointModel.create({ ...angle.vertex, style: null });
            yield PointModel.create({ ...angle.start, style: null });
            yield PointModel.create({ ...angle.end, style: null });

            yield FragmentModel.create({ ...basis, style: null });
            for (const point of [basis.a, basis.b, vertex]) {
                yield PointModel.create({ ...point, style: null });
                yield LabelPointModel.createNext(point, this.figure);
            }

            basisOffset && (yield PointModel.create({ ...basisOffset, style: null }));
        }, this);
    }

    private renderModel = createTransformer(createMapModelToElements(opacifyColor));

    protected renderElements(): BaseElement[] {
        return [...this.modelElements, ...this.tempElements];
    }

    get modelElements(): BaseElement[] {
        return this.figure.models.flatMap(this.renderModel);
    }

    get tempElements(): BaseElement[] {
        const elements: BaseElement[] = [];

        const { stage, vertex } = this.flow;
        if (vertex) {
            const vertexColor = this.flow.stage === 1 ? ElementColor.Building : ElementColor.Selected;
            elements.push(
                new DotElement({
                    x: vertex.x,
                    y: vertex.y,
                    id: `temp_vertex`,
                    overrideRenderOrder: 0,
                    color: vertexColor,
                })
            );
        }

        if (stage === 2) {
            const { availableBisections, targetPoint } = this.flow;
            elements.push(
                ...availableBisections.flatMap<BaseElement>(({ intersectionPoint }, i) => [
                    new DotElement({
                        id: `temp_bisection_${i}_point`,
                        x: intersectionPoint.x,
                        y: intersectionPoint.y,
                        color: ElementColor.Building,
                        overrideRenderOrder: 0,
                    }),
                    new LineElement({
                        id: `temp_bisection_${i}_line`,
                        x1: vertex.x,
                        y1: vertex.y,
                        x2: intersectionPoint.x,
                        y2: intersectionPoint.y,
                        color: ElementColor.Util,
                        thin: true,
                        overrideRenderOrder: 0,
                    }),
                ])
            );

            if (targetPoint) {
                const currentBisectionData = this.findCurrentBisection(targetPoint);
                if (currentBisectionData) {
                    const { angle, basis, basisOffset, intersectionPoint } = currentBisectionData;
                    const { startAngle, endAngle } = getAngles(normalizeAngle(angle, true));
                    const middleAngle = getMiddleAngle(startAngle, endAngle);

                    elements.push(
                        new LineElement({
                            id: `temp_current_basis`,
                            x1: basis.a.x,
                            y1: basis.a.y,
                            x2: basis.b.x,
                            y2: basis.b.y,
                            color: ElementColor.Selected,
                            overrideRenderOrder: -1,
                        }),
                        new LineElement({
                            id: `temp_current_bisection`,
                            x1: vertex.x,
                            y1: vertex.y,
                            x2: intersectionPoint.x,
                            y2: intersectionPoint.y,
                            color: ElementColor.Selected,
                            overrideRenderOrder: -1,
                        }),
                        new DotElement({
                            id: `temp_current_bisectionTarget`,
                            x: intersectionPoint.x,
                            y: intersectionPoint.y,
                            color: ElementColor.Selected,
                            overrideRenderOrder: -1,
                        }),
                        new AngleElement({
                            id: `temp_current_angle1`,
                            x: vertex.x,
                            y: vertex.y,
                            offset: 0,
                            overrideRenderOrder: -1,
                            color: ElementColor.Selected,
                            segments: 2,
                            angleStart: startAngle,
                            angleEnd: middleAngle,
                        }),
                        new AngleElement({
                            id: `temp_current_angle2`,
                            x: vertex.x,
                            y: vertex.y,
                            offset: 1,
                            overrideRenderOrder: -1,
                            color: ElementColor.Selected,
                            segments: 2,
                            angleStart: middleAngle,
                            angleEnd: endAngle,
                        })
                    );

                    if (basisOffset) {
                        elements.push(
                            new DotElement({
                                id: `temp_current_basisOffset`,
                                x: basisOffset.x,
                                y: basisOffset.y,
                                color: ElementColor.Selected,
                                overrideRenderOrder: -1,
                            })
                        );
                    }
                }
            }
        }

        return elements;
    }

    protected findCurrentBisection(targetPoint: TPoint): TBisectionBindingData | null {
        assert(this.flow.stage === 2);
        return (
            this.flow.availableBisections.find(({ intersectionPoint }) =>
                areSamePoints(intersectionPoint, targetPoint)
            ) || null
        );
    }

    static create(data: Figure2DController) {
        return new InteractiveBisectionFlow(data);
    }

    private updatePosition(e: React.PointerEvent, point: TPoint | null) {
        if (e.type === "pointermove" || e.type === "pointerdown") {
            switch (this.flow.stage) {
                case 1:
                    this.flow.vertex = point || void 0;
                    break;
                case 2:
                    this.flow.targetPoint = point || void 0;
                    break;
            }
        }
    }

    private startDrawing(e: React.PointerEvent) {
        if (e.type === "pointerdown") {
            this.flow.drawing = true;
        }
    }

    private endDrawing(e: React.PointerEvent) {
        if (e.type === "pointerup") {
            switch (this.flow.stage) {
                case 1: {
                    const { vertex } = this.flow;
                    if (vertex) {
                        const allViewFragments = findAllViewFragments(this.figure.models);
                        const allViewPoints = findAllViewPoints(this.figure.models);

                        const availableVertexFragments = findVertexViewPointsOnFragments(
                            vertex,
                            allViewFragments,
                            allViewPoints
                        );

                        const allBisectionAngles = findBisectionPoints(vertex, availableVertexFragments);

                        const availableBisections = allBisectionAngles.flatMap(({ fragment, point }) =>
                            getBisectionTargetData(this.figure.models, vertex, point, fragment)
                        );

                        this.flow = { stage: 2, drawing: false, availableBisections, vertex };

                        this.snap.clean();
                        for (const { intersectionPoint } of availableBisections) {
                            this.snap.addPoint(intersectionPoint);
                        }
                    }
                    break;
                }
                case 2:
                    this.checkAndCreate();
                    this.nextFlow();
                    break;
            }
        }
    }

    override getToolbarMenu(): ToolbarMenu {
        return createDefaultToolbarMenuBuilder()
            .setButtonState(ButtonStates.active, ["fragments"])
            .setButtonIcon("bisection", ["fragments"])
            .build();
    }

    override getTooltipMenu(): TooltipMenu | null {
        let text: string;

        switch (this.flow.stage) {
            case 1:
                text = "Выберите вершину угла";
                break;
            case 2:
                text = "Выберите точку, на которую упадёт биссектриса";
                break;
        }

        return new TooltipMenu(text, []);
    }
}
