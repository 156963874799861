import cn from "classnames";
import { observer } from "mobx-react-lite";
import React from "react";

import type { TPictureWatermarkAlignment } from "@viuch/instrument-picture/statement";

import { alignmentToIconMap, alignments } from "./Alignments.constants";
import { areAlignmentsEquals } from "./Alignments.utils";

import styles from "./Alignments.module.scss";

type Props = {
    selectedAlignment: TPictureWatermarkAlignment;
    onChange(alignment: TPictureWatermarkAlignment): void;
};

export const Alignments = observer(function Alignments({ selectedAlignment, onChange }: Props) {
    return (
        <div className={styles.alignments}>
            {alignments.map((alignment, index) => {
                const Icon = alignmentToIconMap[alignment.horizontal];
                const isSelected = areAlignmentsEquals(alignment, selectedAlignment);

                return (
                    <div
                        className={cn(
                            styles.alignmentRadioWrapper,
                            styles[alignment.vertical],
                            styles[alignment.horizontal],
                            isSelected && styles.selected
                        )}
                        key={index}
                    >
                        <Icon />
                        <input
                            type="radio"
                            name="alignment"
                            className={styles.alignmentRadio}
                            onChange={() => onChange(alignment)}
                        />
                    </div>
                );
            })}
        </div>
    );
});
