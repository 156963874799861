import type {
    TContentAdminRequest,
    TContentAdminWithGeneratedPassword,
    TRawContentAdmin,
    TRawCreateContentAdmin,
} from "./types";

import { AdminUser } from "../../entities/content-admin/AdminUser";

export function mapAdmin(data: TRawContentAdmin): AdminUser {
    return new AdminUser({
        id: data.id,
        username: data.username,
        firstName: data.first_name,
        lastName: data.last_name,
        isActive: data.is_active,
        role: data.role,
    });
}

export function mapCreatedAdmin(data: TRawCreateContentAdmin): TContentAdminWithGeneratedPassword {
    const contentAdmin = new AdminUser({
        id: data.id,
        username: data.username,
        firstName: data.first_name,
        lastName: data.last_name,
        isActive: data.is_active,
        role: data.role,
    });

    const generatedPassword = data.password;

    return {
        contentAdmin,
        generatedPassword,
    };
}

export function serializeAdmin(admin: AdminUser): TContentAdminRequest {
    return {
        username: admin.username,
        first_name: admin.firstName,
        last_name: admin.lastName,
        is_active: admin.isActive,
        role: admin.role,
    };
}
