import {
    SelectTableInstrumentSettingsCell,
    TextTableInstrumentSettingsCell,
} from "@viuch/instrument-table-settings/settings/cells";

import type { TRawMathTableCell, TRawSelectTableCell, TRawTableCell } from "../service-types/tables";
import type {
    BaseTableInstrumentSettingsCell,
    ITableCellVisitor,
    TSelectCellContent,
} from "@viuch/instrument-table-settings/settings/cells";
import type { TSerializedState } from "@viuch/math-editor";

const cellContentMapping: Record<TSelectCellContent, TRawSelectTableCell> = {
    "+": "+",
    "-": "-",
    empty: "",
    select: null,
};

function getSelectCellContent(s: TRawSelectTableCell): TSelectCellContent {
    switch (s) {
        case "+":
        case "-":
            return s;
        case "":
            return "empty";
        case null:
            return "select";
    }
}

function serializeSelectCellContent(s: TSelectCellContent): TRawSelectTableCell {
    return cellContentMapping[s];
}

export function mapTableCell(cell: TRawTableCell, isHidden: boolean): BaseTableInstrumentSettingsCell {
    if (typeof cell === "string" || cell === null) {
        return new SelectTableInstrumentSettingsCell(isHidden, getSelectCellContent(cell));
    }

    return new TextTableInstrumentSettingsCell(isHidden, cell);
}

export const selectTableCellSerializer: ITableCellVisitor<TRawTableCell> = {
    withTextCell: (model: TextTableInstrumentSettingsCell): TSerializedState => model.formula,
    withSelectCell: (model: SelectTableInstrumentSettingsCell): TRawSelectTableCell =>
        serializeSelectCellContent(model.content),
};

export const mathTableCellSerializer: ITableCellVisitor<TRawMathTableCell> = {
    withTextCell: (model: TextTableInstrumentSettingsCell): TRawMathTableCell => model.formula,
    withSelectCell: (): never => {
        throw new Error("Select items are not supported");
    },
};
