import { copyPoint } from "@viuch/geometry-lib/factories";
import { filterEqualPoints } from "@viuch/geometry-lib/filters";

import type { BaseModel } from "../models";
import type { IModelVisitor } from "../models/BaseModel";
import type { TPoint } from "@viuch/geometry-lib/types";

export function findAllViewPoints(models: BaseModel[]): TPoint[] {
    return filterEqualPoints(models.flatMap(getAllModelPoints)).map(copyPoint);
}

export function getAllModelPoints(model: BaseModel): TPoint[] {
    return model.accept(getAllModelPointsVisitor);
}

export const getAllModelPointsVisitor: IModelVisitor<TPoint[]> = {
    withPoint: (point) => [point],
    withFragment: ({ a, b }) => [a, b],
    withParallelFragment: ({ a, b }) => [a, b],
    withLabelPoint: () => [],
    withLabelFragment: () => [],
    withLabelAngle: () => [],
    withLine: ({ a, b, virtualFragment: v }) => [a, b, v.a, v.b],
    withConstraintLine: ({ vertex, virtualFragment: { a, b }, intersectionPoint }) =>
        intersectionPoint ? [vertex, a, b, intersectionPoint] : [vertex, a, b],
    withComputedIntersectionPoints: (model) => model.points,
    withBisection: ({ midPoint }) => (midPoint ? [midPoint] : []),
    withMedian: ({ vertex, midPoint }) => [vertex, midPoint],
    withAltitude: ({ vertex, projectionPoint }) => [vertex, projectionPoint],
    withEqualAngles: () => [],
    withEqualSegments: () => [],
    withEllipse: ({ center }) => [center],
    withInscribedCircle: ({ center, intersectionPoints }) => (center ? [center, ...intersectionPoints] : []),
    withCircumscribedCircle: ({ center }) => (center ? [center] : []),
    withTangentLine: (tangent) =>
        tangent.checkIsTangent()
            ? [tangent.point, tangent.pointOnCircle, tangent.virtualTangentLine.a, tangent.virtualTangentLine.b]
            : [tangent.point],
    withMiddleLine: ({ middleLineFragment }) => [middleLineFragment.a, middleLineFragment.b],
    withTangentLineOnCircle: ({ touchPoint, virtualTangentFragment }) =>
        [virtualTangentFragment?.a, virtualTangentFragment?.b, touchPoint].filter<TPoint>(Boolean),
    withFragmentDashed: ({ a, b }) => [a, b],
    withVector: ({ from, to }) => [from, to],
    withVectorDashed: ({ from, to }) => [from, to],
};
