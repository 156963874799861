import { action, makeObservable, observable } from "mobx";

import { generateId } from "@viuch/shared/utils/data";

import type { Answer } from "./Answer";
import type { ITask, TTaskDraft } from "./types";
import type { Variable } from "./Variable";
import type { BaseTaskInstrumentSettings } from "../task-instruments-settings/BaseTaskInstrumentSettings";
import type { TSerializedState } from "@viuch/math-editor/types";

import { cloneTaskInstrumentSettings } from "../task-instruments-settings/cloneTaskInstrumentSettings";

import { PrimaryHints } from "./PrimaryHints";
import { createDefaultTaskFields } from "./utils";

export class Task implements ITask {
    readonly id: number;
    @observable answers: Answer[];
    @observable.ref formula: TSerializedState;
    @observable theme: number;
    @observable.shallow variables: Variable[];
    @observable instruments: BaseTaskInstrumentSettings[];
    @observable problemIds: number[];
    @observable primaryHints: PrimaryHints | null;
    @observable variantGenerationLimit: number;
    @observable.ref overrideTaskText: TSerializedState;
    @observable isVerified: boolean;

    readonly $$instance_id = generateId();

    constructor(data: TTaskDraft, id: number) {
        this.id = id;
        this.answers = data.answers.slice();
        this.formula = data.formula;
        this.theme = data.theme;
        this.variables = data.variables.slice();
        this.instruments = data.instruments.slice();
        this.problemIds = data.problemIds.slice();
        this.primaryHints = data.primaryHints;
        this.variantGenerationLimit = data.variantGenerationLimit;
        this.overrideTaskText = data.overrideTaskText;
        this.isVerified = data.isVerified;

        makeObservable(this);
    }

    static createEmpty(themeId: number): Task {
        return new Task(createDefaultTaskFields(themeId), generateId());
    }

    @action.bound
    addProblem(problemId: number) {
        if (!this.problemIds.includes(problemId)) {
            this.problemIds.push(problemId);
        }
    }

    @action.bound removeProblem(problemId: number) {
        const i = this.problemIds.indexOf(problemId);
        if (i !== -1) {
            this.problemIds.splice(i, 1);
        }
    }

    @action.bound
    enablePrimaryHints() {
        this.primaryHints ??= new PrimaryHints([]);
    }

    @action.bound
    removePrimaryHints() {
        this.primaryHints = null;
    }

    @action.bound
    setVariantGenerationLimit(variantGenerationLimit: number) {
        this.variantGenerationLimit = variantGenerationLimit;
    }

    @action.bound
    setTaskText(formula: TSerializedState) {
        this.overrideTaskText = formula;
    }

    @action.bound
    setIsVerified(value: boolean): void {
        this.isVerified = value;
    }

    clone(taskId?: number) {
        return new Task(
            {
                theme: this.theme,
                formula: this.formula,
                problemIds: this.problemIds,
                primaryHints: this.primaryHints?.clone() ?? null,
                variantGenerationLimit: this.variantGenerationLimit,
                answers: this.answers.map((answer) => answer.clone()),
                variables: this.variables.map((variable) => variable.clone()),
                instruments: this.instruments.map(cloneTaskInstrumentSettings),
                overrideTaskText: this.overrideTaskText,
                isVerified: false,
            },
            taskId ?? this.id
        );
    }
}
