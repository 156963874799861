import { action, autorun, makeObservable, observable } from "mobx";

import type { IAutorunOptions } from "mobx";

interface IReactionPublic {
    dispose(): void;
    trace(enterBreakPoint?: boolean): void;
}

export type TEffect = { (r: IReactionPublic): TDisposeEffect | void };
export type TDisposeEffect = VoidFunction;
export type TDisposeAutorun = VoidFunction;

export function createAutorun(effect: TEffect, opts?: IAutorunOptions): TDisposeAutorun {
    let disposeEffect: TDisposeEffect | undefined | void;

    const disposeAutorun = autorun((r) => {
        disposeEffect?.();
        disposeEffect = effect(r);
    }, opts);

    return () => {
        disposeEffect?.();
        disposeEffect = void 0;
        disposeAutorun();
    };
}

export class ObservableRef<T> {
    @observable.ref value: T;

    constructor(value: T) {
        this.value = value;

        makeObservable(this);
    }

    @action.bound
    set(value: T) {
        this.value = value;
    }
}
