import type { BaseFormNode } from "../nodes/BaseFormNode";
import type { BaseValueFormNode } from "../nodes/BaseValueFormNode";
import type { TSelectControl } from "../types/controls";

import { CustomSelectFormNode } from "../nodes/CustomSelectFormNode";
import { SelectFormNode } from "../nodes/SelectFormNode";
import { SelectGroupedFormNode } from "../nodes/SelectGroupedFormNode";
import { StaticSelectItemsProvider } from "../providers/StaticSelectItemsProvider";
import { pathFromRaw } from "../utils/paths";

export function instantiateSelectControl(data: TSelectControl, parent: BaseFormNode | null): BaseValueFormNode {
    const { path, data: content, label, initialValue } = data;

    switch (content.type) {
        case "static-items":
            return new SelectFormNode(
                initialValue,
                label,
                new StaticSelectItemsProvider(content.items),
                parent,
                pathFromRaw(path),
                []
            );
        case "static-grouped-items":
            return new SelectGroupedFormNode(initialValue, label, content.groups, parent, pathFromRaw(path), []);
        case "custom-provider":
            return new CustomSelectFormNode(initialValue, label, content.selector, parent, pathFromRaw(path), []);
    }
}
