import classNames from "classnames";
import { observer } from "mobx-react-lite";
import React, { useEffect, useRef } from "react";
import SimpleBar from "simplebar-react";

import { ReactComponent as PlusIcon } from "@viuch/assets/icons/plus-black.svg";
import { InstrumentGrid } from "@viuch/ui-kit/instrument-grid";

import type { IntervalsDot, IntervalsLine, IntervalsInstrumentStatement, IIntervalsMethodLineDot } from "../statement";
import type { KeyboardService } from "@viuch/math-editor";
import type SimpleBarCore from "simplebar-core";

import { Line } from "./components/Line";
import { STEPS } from "./constants";
import { TrashBinButton } from "./elements/TrashBinButton";
import { IntervalsMethodEditorViewModel } from "./IntervalsMethodEditor.vm";

import styles from "./IntervalsMethodEditor.module.scss";

type TProps = {
    intervals: IntervalsInstrumentStatement;
    onClearRequested?(intervals: IntervalsInstrumentStatement): Promise<boolean>;
    onAddDotRequested?(
        intervals: IntervalsInstrumentStatement,
        line: IntervalsLine,
        position: number
    ): Promise<IIntervalsMethodLineDot | null>;
    onEditDotRequested?(
        intervals: IntervalsInstrumentStatement,
        line: IntervalsLine,
        dot: IntervalsDot
    ): Promise<IIntervalsMethodLineDot | null>;
    mathKeyboard?: KeyboardService;
    readonly?: boolean;
    className?: string;
};

export const IntervalsMethodEditor = observer(function IntervalsMethodEditor({
    intervals,
    onClearRequested,
    onAddDotRequested,
    onEditDotRequested,
    readonly,
    mathKeyboard,
    className,
}: TProps) {
    const containerRef = React.useRef<HTMLDivElement>(null);

    const vm = React.useMemo(() => new IntervalsMethodEditorViewModel(intervals), [intervals]);

    useEffect(() => {
        const linesContainer = containerRef.current!;

        const observer = new ResizeObserver(() => {
            const width = linesContainer.clientWidth;
            const stepSize = width / (STEPS - 1);
            const multiplier = Math.ceil(70 / (stepSize || 1));
            const distance = stepSize * multiplier;

            linesContainer.style.setProperty("--linesGap", `${distance}px`);
        });

        observer.observe(linesContainer);

        return () => {
            observer.disconnect();
        };
    }, []);

    const handleClear = () => {
        onClearRequested?.(intervals).then((confirmClear) => {
            if (confirmClear) {
                vm.resetAll();
            }
        });
    };

    const handleCreateDot = (line: IntervalsLine, position: number) => {
        onAddDotRequested?.(intervals, line, position).then((dotFields) => {
            if (dotFields) {
                vm.addDot(line, position, dotFields);
            }
        });
    };

    const handleEditDot = (line: IntervalsLine, dot: IntervalsDot) => {
        onEditDotRequested?.(intervals, line, dot).then((dotFields) => {
            if (dotFields) {
                vm.editDot(line, dot, dotFields);
            }
        });
    };

    const simplebarRef = useRef<SimpleBarCore>(null);

    useEffect(() => {
        const simplebar = simplebarRef.current!;

        simplebar.recalculate();
    }, []);

    return (
        <div className={classNames(styles.wrapper, className)}>
            <SimpleBar
                className={styles.simplebar}
                ref={simplebarRef}
            >
                <div className={classNames(styles.instrumentContainer, readonly && styles._readonly)}>
                    <div className={styles.instrumentContent}>
                        <InstrumentGrid
                            startRef={containerRef}
                            steps={STEPS}
                        />
                        <div className={styles.linesContainerWrapper}>
                            <div
                                ref={containerRef}
                                className={styles.linesContainer}
                            >
                                {intervals.lines.map((line) => (
                                    <Line
                                        line={line}
                                        key={line.id}
                                        onCreateDot={handleCreateDot}
                                        onRemoveLine={vm.handleRemoveLine}
                                        onEditDot={handleEditDot}
                                        readonly={readonly}
                                        mathKeyboard={mathKeyboard}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </SimpleBar>

            {!readonly && (
                <>
                    <TrashBinButton onClick={handleClear} />
                    <div className={styles.leftBlock}>
                        <div className={styles.rectangle} />
                        <button
                            className={styles.addLineButton}
                            onClick={vm.addLine}
                        >
                            <PlusIcon className={styles.plusIcon} />
                        </button>
                    </div>
                </>
            )}
        </div>
    );
});
