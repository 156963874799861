import type { ISerializedChar } from "./types";
import type { TSerializedElementPrototype } from "../../core/element";
import type { IElementVisitor } from "../../core/element/IElementVisitor";
import type { InputService } from "../../services";
import type { TElementDeserializerFunction } from "../../types";

import { BaseElementModel } from "../../core/element";

export class CharModel extends BaseElementModel<ISerializedChar> {
    public readonly char: string;

    public constructor(inputService: InputService, char: string, uuid?: string) {
        super(inputService, uuid);
        this.char = char;
    }

    override isEquals(otherElement: BaseElementModel): boolean {
        return otherElement instanceof CharModel && otherElement.char === this.char;
    }

    public serialize(): ISerializedChar {
        return {
            type: "char",
            uuid: this.uuid,
            char: this.char,
        };
    }

    public static deserialize: TElementDeserializerFunction<ISerializedChar> = ({ inputService }, { uuid, char }) =>
        new CharModel(inputService, char, uuid);

    public accept<R>(visitor: IElementVisitor<R>): R {
        return visitor.doWithChar(this);
    }

    serializeAsClone(): TSerializedElementPrototype<ISerializedChar> {
        const { uuid, ...clone } = this.serialize();
        return clone;
    }
}
