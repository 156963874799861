import { assert } from "@viuch/utils/debug";

import type { Graph2DFlowsController } from "./Graph2DFlowsController";
import type { IToolbarElement } from "../toolbar/toolbarTypes";
import type { ReactNode, RefObject } from "react";

export class Graph2DToolbar {
    private readonly flows: Graph2DFlowsController;

    constructor(flows: Graph2DFlowsController) {
        this.flows = flows;
    }

    private readonly _presets = new Map<string, IToolbarElement>();

    registerPreset<TElement extends IToolbarElement>(name: string, content: TElement) {
        assert(!this._presets.has(name));

        this._presets.set(name, content);

        return this;
    }

    getPreset(name: string): IToolbarElement {
        const preset = this._presets.get(name);

        assert(preset);

        return preset;
    }

    private get currentFlow() {
        return this.flows.flow;
    }

    private readonly _renderFunctions: Map<string, TRenderFunction<IToolbarElement>> & {
        set<TElement extends IToolbarElement<TType>, TType extends string>(
            key: TElement["type"],
            value: TRenderFunction<TElement>
        ): void;
    } = new Map();

    registerElementRenderer<TElement extends IToolbarElement>(
        elementType: TElement["type"],
        renderer: TRenderFunction<TElement>
    ) {
        assert(!this._renderFunctions.has(elementType));

        this._renderFunctions.set(elementType, renderer);

        return this;
    }

    getElementRenderer(element: IToolbarElement): (props: TRenderProps) => ReactNode {
        const render = this._renderFunctions.get(element.type);

        assert(render, `render function was not registered for type = '${element.type}' `, element);

        return (props) => render(element, props);
    }

    getElementOverride(element: IToolbarElement): IToolbarElement {
        return this.currentFlow.getToolbarElementUpdates(element);
    }

    getCurrentPresetName(): string | null {
        return this.currentFlow.getToolbarPreset();
    }
}

export type TRenderFunction<TElement extends IToolbarElement<TType>, TType extends string = string> = (
    element: TElement,
    props: TRenderProps
) => ReactNode;

export type TRenderProps = {
    toolbar: Graph2DToolbar;
    rootRef: RefObject<HTMLDivElement>;
    className?: string;
};
