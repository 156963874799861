import type {
    TQuestionStudent,
    TQuestionSubject,
    TQuestionTheme,
    TRawPatchedQuestion,
    TRawPatchQuestionRequest,
    TRawQuestion,
    TRawQuestionsFiltersRequest,
    TRawQuestionStudent,
    TRawQuestionSubject,
    TRawQuestionTheme,
} from "./QuestionsService.types";
import type { Answer } from "../../entities/questions/Answer";
import type { QuestionsFilters } from "../../entities/questions/QuestionsFilters";

import { Question } from "../../entities/questions/Question";

import { mapAnswer } from "./AnswersService.utils";

export function mapQuestion(data: TRawQuestion): Question {
    const { id, text, answers, is_verified, student, theme, created_at, updated_at, name, email } = data;

    const answer: Answer | null = answers.length > 0 ? mapAnswer(answers[0]) : null;

    return new Question(
        id,
        text,
        name,
        email,
        student ? mapQuestionStudent(student) : null,
        is_verified,
        answer,
        !!theme ? mapQuestionTheme(theme) : null,
        new Date(created_at),
        new Date(updated_at)
    );
}

export function mapPatchedQuestion(data: TRawPatchedQuestion): Question {
    const { id, text, is_verified, student, theme, created_at, updated_at, name, email } = data;

    return new Question(
        id,
        text,
        name,
        email,
        student ? mapQuestionStudent(student) : null,
        is_verified,
        null,
        !!theme ? mapQuestionTheme(theme) : null,
        new Date(created_at),
        new Date(updated_at)
    );
}

export function mapQuestionStudent(data: TRawQuestionStudent): TQuestionStudent {
    const { id, username } = data;

    return { id, username };
}

export function mapQuestionTheme(data: TRawQuestionTheme): TQuestionTheme {
    const { id, name, subjects } = data;

    return {
        id,
        name,
        subjects: subjects ? subjects.map(mapQuestionSubject) : [],
    };
}

export function mapQuestionSubject(data: TRawQuestionSubject): TQuestionSubject {
    const { id, name } = data;

    return { id, name };
}

export function serializePathQuestion(data: Question): TRawPatchQuestionRequest {
    const { text } = data;

    return {
        text,
        is_verified: true,
    };
}

export function serializeQuestionsFilters(filters: QuestionsFilters): TRawQuestionsFiltersRequest {
    const data: TRawQuestionsFiltersRequest = {};

    switch (filters.questionEntity) {
        case "all":
            break;
        case "theme":
            data.theme_is_null = "false";
            data.blog_is_null = "true";
            break;
        case "blog":
            data.blog_is_null = "false";
            data.theme_is_null = "true";
            break;
        case "faq":
            data.blog_is_null = "true";
            data.theme_is_null = "true";
            break;
    }

    switch (filters.verifiedStatus) {
        case "all":
            break;
        case "only-verified":
            data.is_verified = "true";
            break;
        case "not-verified":
            data.is_verified = "false";
            break;
    }

    switch (filters.pendingStatus) {
        case "all":
            break;
        case "only-pending":
            data.is_pending = "true";
            break;
        case "not-pending":
            data.is_pending = "false";
            break;
    }

    switch (filters.userStatus) {
        case "all":
            break;
        case "only-anonymous":
            data.without_student = "true";
            break;
        case "only-free-student":
            data.without_subscription = "true";
            break;
        case "only-paid-student":
            data.with_subscription = "true";
            break;
    }

    filters.ordering && (data.ordering = filters.ordering);

    return data;
}
