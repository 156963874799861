import type { ISelectItemsProvider, TItem } from "../types/interfaces";

export class StaticSelectItemsProvider implements ISelectItemsProvider {
    private readonly items: TItem[];

    constructor(items: TItem[]) {
        this.items = items;
    }

    getItems(): TItem[] {
        return this.items;
    }
}
