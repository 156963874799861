import { observer } from "mobx-react-lite";
import React, { useRef } from "react";

type Props = {
    ax: number | string;
    ay: number | string;
    bx: number | string;
    by: number | string;
    color: string;
    width: number;
};

export const LineView = observer(function LineView({ ax, ay, bx, by, color, width }: Props) {
    const ref = useRef<SVGLineElement>(null);

    return (
        <line
            data-info="line"
            ref={ref}
            x1={ax}
            y1={ay}
            x2={bx}
            y2={by}
            stroke={color}
            strokeWidth={width}
        />
    );
});
