/* eslint-disable prettier/prettier */
import type { API, InlineToolConstructorOptions } from "@editorjs/editorjs";


export default class FontFamilyPlugin2 {
    private api: API;
    private _state: boolean;
    private button: HTMLButtonElement | null;

    private readonly tag = "SPAN";
    private readonly className = "cdx-plugin-font-family2";
    readonly availableFonts = ["Nunito", "Plateia"];
    // private colorPicker?: HTMLInputElement;
    private fontSelectControl?: {
        select: HTMLSelectElement;
        wrapper: HTMLElement;
    };

    static get isInline() {
        return true;
    }

    get state() {
        return this._state;
    }

    set state(state) {
        this._state = state;

        this.button?.classList.toggle(this.api.styles.inlineToolButtonActive, state);
    }

    constructor({ api }: InlineToolConstructorOptions) {
        this.api = api;
        this.button = null;
        this._state = false;
    }

    render() {
        this.button = document.createElement("button");
        this.button.type = "button";
        this.button.innerHTML =
            '<svg width="20" height="18"><path d="M10.458 12.04l2.919 1.686-.781 1.417-.984-.03-.974 1.687H8.674l1.49-2.583-.508-.775.802-1.401zm.546-.952l3.624-6.327a1.597 1.597 0 0 1 2.182-.59 1.632 1.632 0 0 1 .615 2.201l-3.519 6.391-2.902-1.675zm-7.73 3.467h3.465a1.123 1.123 0 1 1 0 2.247H3.273a1.123 1.123 0 1 1 0-2.247z"/></svg>';
        this.button.classList.add(this.api.styles.inlineToolButton);

        return this.button;
    }

    surround(range: Range) {
        if (this.state) {
            this.unwrap(range);
            return;
        }

        this.wrap(range);
    }

    wrap(range: Range) {
        const selectedText = range.extractContents();
        const span = document.createElement(this.tag);

        span.classList.add(this.className);
        span.appendChild(selectedText);
        range.insertNode(span);

        this.api.selection.expandToTag(span);
    }

    unwrap(range: Range) {
        const mark = this.api.selection.findParentTag(this.tag, this.className);
        const text = range.extractContents();

        mark?.remove();

        range.insertNode(text);
    }

    checkState() {
        const element = this.api.selection.findParentTag(this.tag, this.className);

        const state = !!element;
        this.state = state;

        if (state) {
            this.showActions(element);
        } else {
            this.hideActions();
        }
    }

    renderActions() {
        const wrapper = document.createElement("div");
        wrapper.style.position = "relative";

        const fonts = this.availableFonts;
        const selectText = `
            <select>
              <option data-font="" value="">Не выбрано</option>
              ${fonts
                  .map(
                      (font) => `
                <option data-font="${font}" value="${font}">${font}</option>
              `
                  )
                  .join("")}
            </select>
        `;

        wrapper.insertAdjacentHTML("beforeend", selectText);

        const select = wrapper.querySelector("select")!;
        this.fontSelectControl = { select, wrapper };
        wrapper.hidden = true;

        return wrapper;
    }

    private showActions(element: HTMLElement) {
        if (!this.fontSelectControl) return;
        const { wrapper, select } = this.fontSelectControl;

        const currentFont = this.getValue(element);
        select.querySelectorAll("option").forEach((option) => {
            option.selected = currentFont === option.dataset.font;
        });

        select.onchange = (e: Event) => {
            const newFont = select.value;
            this.setValue(element, newFont);
        };

        wrapper.hidden = false;
    }

    private hideActions() {
        // if (!this.colorPicker) return;
        // this.colorPicker.onchange = null;
        // this.colorPicker.hidden = true;

        if (!this.fontSelectControl) return;
        const { wrapper, select } = this.fontSelectControl;
        wrapper.hidden = true;
        select.onchange = null;
    }

    private getValue(element: HTMLElement): string | null {
        const font = element.dataset.font;
        return font || null;
    }

    private setValue(element: HTMLElement, font: string): void {
        element.dataset.font = font;
    }

    static get sanitize() {
        return {
            span: { "data-font": true },
        };
    }
}
