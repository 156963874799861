// TODO: перенести хук в админку
import { useMemo } from "react";
import { useLocation } from "react-router-dom";

export function useSearchParams(): Partial<Record<string, string>> {
    const location = useLocation();

    return useMemo(() => {
        const params = new URLSearchParams(location.search);
        return Object.fromEntries(params.entries());
    }, [location.search]);
}
