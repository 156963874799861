import { observer } from "mobx-react-lite";
import React from "react";

import type { IBaseEqualitySymbolElementProps } from "./BaseEqualitySymbolElement.types";
import type { FC, Ref } from "react";

import { BaseSymbolElement } from "../base/base-symbol";

type Props = IBaseEqualitySymbolElementProps & {
    lineRef: Ref<HTMLElement>;
};

export const BaseEqualitySymbolElement: FC<Props> = observer(function BaseEqualitySymbolElement({
    children,
    className,
    lineRef,
}) {
    return (
        <BaseSymbolElement
            ref={lineRef}
            className={className}
        >
            {children}
        </BaseSymbolElement>
    );
});
