import { action, makeObservable, observable } from "mobx";

import type { TSelectCellContent } from "./types";
import type { ITableCellVisitor } from "../ITableCellVisitor";

import { BaseTableInstrumentSettingsCell } from "../BaseTableInstrumentSettingsCell";

import { CELL_CONTENT } from "./constants";

export class SelectTableInstrumentSettingsCell extends BaseTableInstrumentSettingsCell {
    @observable content: TSelectCellContent;

    constructor(isHidden: boolean, content: TSelectCellContent) {
        super(isHidden);

        this.content = content;

        makeObservable(this);
    }

    static createEmpty(): SelectTableInstrumentSettingsCell {
        return new SelectTableInstrumentSettingsCell(false, "select");
    }

    clone(): SelectTableInstrumentSettingsCell {
        return new SelectTableInstrumentSettingsCell(this.isHidden, this.content);
    }

    @action.bound
    toggleContent(): void {
        const nextContentIndex = (CELL_CONTENT.indexOf(this.content) + 1) % CELL_CONTENT.length;

        this.content = CELL_CONTENT[nextContentIndex];
    }

    accept<R>(visitor: ITableCellVisitor<R>): R {
        return visitor.withSelectCell(this);
    }
}
