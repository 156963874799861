import type { TRawBlogPost, TRawBlogPostImage, TRawBlogPostRequest } from "./types";
import type { BlogFilters } from "../../entities/attention-filters/BlogPostsFilters";
import type { TPictureImage } from "../image-field";

import { BlogPost } from "../../entities/blog-post/BlogPost";
import { IndexingEntityMetadata } from "../../entities/indexing/IndexingEntityMetadata";

export function mapBlogPost(data: TRawBlogPost) {
    const { id, name, description, content, image, is_archived, created_at, meta_title, meta_description, slug } = data;

    return new BlogPost(
        id,
        name,
        description,
        content,
        mapBlogPostImage(image),
        is_archived,
        new IndexingEntityMetadata(meta_title, meta_description ?? null, slug),
        new Date(created_at)
    );
}

export function mapBlogPostImage(data: TRawBlogPostImage): TPictureImage {
    const { id, file } = data;

    return { url: file, uuid: String(id) };
}

export function serializeBlogPost(blogPost: BlogPost): TRawBlogPostRequest {
    const { name, description, content, image, indexing } = blogPost;

    return {
        name,
        description,
        content,
        image: image?.uuid || "",
        slug: indexing.slug,
        meta_title: indexing.title,
        meta_description: indexing.description,
    };
}

export const serializeBlogPostsFilters = (filters: BlogFilters) => {
    const data: Record<string, string> = {};

    filters.isArchived && (data.is_archived = "true");
    filters.is_meta_title_null && (data.is_meta_title_null = "true");
    filters.is_meta_description_null && (data.is_meta_description_null = "true");

    return new URLSearchParams(data);
};
