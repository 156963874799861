import { action, makeObservable, observable } from "mobx";
import { computedFn } from "mobx-utils";

import type { TKeyboardButtonTags } from "./types";

import { __allTag } from "./constants";

export class KeyboardButtonTags {
    public enabledTags: string[] = [];
    public disabledTags: string[] = [];

    public constructor() {
        makeObservable(this, {
            setCurrentTags: action,
            enabledTags: observable,
            disabledTags: observable,
        });
    }

    public setCurrentTags(tags: TKeyboardButtonTags): void {
        this.disabledTags = tags.disabled;
        this.enabledTags = tags.enabled;
    }

    public checkEnabled = computedFn((tags: string[]): boolean => {
        return this.includesAny("enabledTags", tags) || !this.includesAny("disabledTags", tags);
    });

    private includesAny(ownTags: "disabledTags" | "enabledTags", tags: string[]): boolean {
        if (this[ownTags].includes(__allTag)) return true;

        if (tags.length === 0) {
            return false;
        }

        for (const tag of tags) {
            if (this[ownTags].includes(tag)) {
                return true;
            }
        }

        return false;
    }
}
