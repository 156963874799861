import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { useRootStore } from "../../integration";

import styles from "./AdminHeader.module.scss";

const AdminHeader = observer(function AdminHeader() {
    const { t } = useTranslation("auth");
    const { auth } = useRootStore();

    return (
        <header className={styles.header}>
            <h1 className={styles.logo}>
                <Link to="/">Viuch</Link>
            </h1>
            <button
                onClick={auth.logout}
                className={styles.logout}
            >
                {t("logout")}
            </button>
        </header>
    );
});

export default AdminHeader;
