import type { Figure2DController } from "../Figure2DController";
import type { TPoint } from "@viuch/geometry-lib/types";

import { createMoveModelVisitor } from "./utils";

export class MoveDotService {
    private readonly data: Figure2DController;

    private get figure() {
        return this.data.figure;
    }

    constructor(data: Figure2DController) {
        this.data = data;
    }

    moveDots(pairs: Array<{ from: TPoint; to: TPoint }>): void {
        pairs.forEach(({ from }, i) => {
            this.moveDot(from, { x: -i - 1, y: -i - 1 });
        });
        pairs.forEach(({ to }, i) => {
            this.moveDot({ x: -i - 1, y: -i - 1 }, to);
        });
    }

    moveDot(from: TPoint, to: TPoint): void {
        const moveModelVisitor = createMoveModelVisitor(from, to);
        this.figure.models.forEach((model) => model.accept(moveModelVisitor));
    }
}
