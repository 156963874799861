import { reaction } from "mobx";

import { appHistory } from "../router/appHistory";
import { ImagesService } from "../services/image-field";
import { RouterStore } from "../services/RouterStore";
import { StatsTrackerService } from "../services/stats/StatsTrackerService";
import { TasksService, TaskVariantsService } from "../services/tasks";

import { ApiStore } from "./ApiStore";
import { AuthStore } from "./AuthStore";
import { ConfirmationStore } from "./ConfirmationStore";

export class RootStore {
    readonly routerStore: RouterStore;
    readonly uploadService: ImagesService;
    readonly variantsService: TaskVariantsService;
    readonly tasksService: TasksService;

    readonly api = new ApiStore();
    readonly auth = new AuthStore();
    readonly confirmation = new ConfirmationStore();
    readonly statsTracker: StatsTrackerService;

    constructor() {
        this.routerStore = new RouterStore(appHistory);
        this.uploadService = new ImagesService();
        this.variantsService = new TaskVariantsService();
        this.tasksService = new TasksService();

        this.statsTracker = new StatsTrackerService(this.auth);
    }

    effect = () => {
        const disposers = [
            reaction(
                () => this.auth.user,
                (userInfo, prevUserInfo) => {
                    if (prevUserInfo) {
                        this.statsTracker.disconnect();
                    }
                    if (userInfo) {
                        this.statsTracker.connect();
                    }
                }
            ),
        ];

        return () => {
            disposers.forEach((dispose) => dispose());
        };
    };
}
