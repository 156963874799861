import type { VectorModel } from "./VectorModel";
import type { TElementProps } from "../../types/element";
import type { VFC } from "react";

import { ContainerElement } from "../../core/container";
import { useMathEditorElementRef } from "../../hooks";

import styles from "./VectorElement.module.scss";

export const VectorElement: VFC<TElementProps<VectorModel>> = ({ elementModel }) => {
    const ref = useMathEditorElementRef(elementModel);

    return (
        <span
            ref={ref}
            className={styles.wrapper}
        >
            <ContainerElement
                containerModel={elementModel.content}
                className={styles.container}
            />
        </span>
    );
};
