import type { GlobalStudyPlan } from "../../entities/global-study-plan/GlobalStudyPlan";
import type { TGlobalStudyPlanShort } from "../../entities/global-study-plan/GlobalStudyPlanShort";
import type { GradesService } from "../grades";

import { agent } from "../../agent";
import { throwServiceError } from "../base/RequestError";

import { mapGlobalStudyPlan, mapGlobalStudyPlanShort, serializeGlobalStudyPlan } from "./globalStudyPlanSerializers";

export class GlobalStudyPlansService {
    async allShort(): Promise<TGlobalStudyPlanShort[]> {
        try {
            const { data } =
                await agent.get<GlobalStudyPlansService.TGlobalStudyPlanShortResponse[]>("/global_study_plans/");
            return data.map(mapGlobalStudyPlanShort);
        } catch (e) {
            throwServiceError(e);
        }
    }

    async byId(id: string | number): Promise<GlobalStudyPlan> {
        try {
            const { data } = await agent.get<GlobalStudyPlansService.TGlobalStudyPlanResponse>(
                `/global_study_plans/${id}/`
            );
            return mapGlobalStudyPlan(data);
        } catch (e) {
            throwServiceError(e);
        }
    }

    async create(plan: GlobalStudyPlan): Promise<GlobalStudyPlan> {
        try {
            const request = serializeGlobalStudyPlan(plan);
            const { data } = await agent.post<GlobalStudyPlansService.TGlobalStudyPlanResponse>(
                `/global_study_plans/`,
                request
            );
            return mapGlobalStudyPlan(data);
        } catch (e) {
            throwServiceError<GlobalStudyPlansService.TGlobalStudyPlanRequestError>(e);
        }
    }

    async update(plan: GlobalStudyPlan): Promise<GlobalStudyPlan> {
        try {
            const request = serializeGlobalStudyPlan(plan);
            const { data } = await agent.patch<GlobalStudyPlansService.TGlobalStudyPlanResponse>(
                `/global_study_plans/${plan.id}/`,
                request
            );
            return mapGlobalStudyPlan(data);
        } catch (e) {
            throwServiceError<GlobalStudyPlansService.TGlobalStudyPlanRequestError>(e);
        }
    }

    async delete(planId: number): Promise<void> {
        try {
            await agent.delete(`/global_study_plans/${planId}`);
        } catch (e) {
            throwServiceError(e);
        }
    }

    async clonePlan(planId: string): Promise<number> {
        const { data } = await agent.post<GlobalStudyPlansService.TGlobalStudyPlanResponse>(
            `/global_study_plans/${planId}/copy/`
        );

        return data.id;
    }
}

export namespace GlobalStudyPlansService {
    export type TGlobalStudyPlanRequest = {
        id: number;
        name: string;
        grade: number | null;
        quarters: {
            start: string | null;
            end: string | null;
            themes: TThemeDescription[];
        }[];
        subject: number;
    };

    export type TGlobalStudyPlanResponse = {
        id: number;
        name: string;
        grade: GradesService.TGradeShortResponse;
        quarters: {
            start: string;
            end: string;
            themes: {
                id: number;
                name: string;
                duration: string;
            }[];
        }[];
        subject: {
            id: number;
            slug: string;
            name: string;
        } | null;
    };

    export type TGlobalStudyPlanShortResponse = {
        id: number;
        name: string;
        grade: {
            id: number;
            grade: number;
        };
    };

    export type TThemeDescription = {
        id: number;
        duration: string;
    };

    export type TGlobalStudyPlanRequestError = {
        name: string;
        start: string;
        end: string;
        themes: string;
        grade: string;
        subject: string;
        quarters: {
            start: string | null;
            end: string | null;
            themes: TThemeDescription[];
        }[];
        theme_order: {
            before: string;
            after: string;
            msg: string;
        }[];
        non_field_errors?: string[];
    };
}
