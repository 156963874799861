import { MathKeyboardProvider } from "../features/floating-math-keyboard";
import { ModalsProvider } from "../integration/modals-provider";
import Router from "../router";

import { Confirmations } from "./Confirmations";

const App = () => {
    return (
        <ModalsProvider>
            <MathKeyboardProvider>
                <Router />
                <Confirmations />
            </MathKeyboardProvider>
        </ModalsProvider>
    );
};

export default App;
