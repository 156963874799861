import type { FractionModel } from "./FractionModel";
import type { TElementProps } from "../../types/element";
import type { VFC } from "react";

import { ContainerElement } from "../../core/container";
import { BaseFractionElement } from "../base/base-fraction";

export const FractionElement: VFC<TElementProps<FractionModel>> = ({ elementModel }) => (
    <BaseFractionElement
        elementModel={elementModel}
        denominator={
            <ContainerElement
                containerModel={elementModel.denominator}
                inline
            />
        }
        numerator={
            <ContainerElement
                containerModel={elementModel.numerator}
                inline
            />
        }
    />
);
