import { generateUuid } from "@viuch/shared/utils/data";

import type { ISerializedIntegral } from "./types";
import type { TAnySerializedElement } from "../../types";

import { createSerializedContainer } from "../../utils/serialization";

export function createSerializedIntegral(arg: TAnySerializedElement[]): ISerializedIntegral {
    return {
        type: "integral",
        uuid: generateUuid(),
        argContent: createSerializedContainer(arg),
        integralContent: createSerializedContainer(),
    };
}
