import type { IFormNodeVisitor } from "./BaseFormNode";
import type { ICondition } from "../conditions/ICondition";
import type { TVirtualTreeNode } from "../tree/types";

import { BaseFormNode } from "./BaseFormNode";

export class FormNode extends BaseFormNode {
    readonly controls: BaseFormNode[];
    readonly condition: ICondition;
    readonly layout: TFormLayout;

    constructor(layout: TFormLayout, condition: ICondition, parent: BaseFormNode | null, path: string[]) {
        super(parent, path);

        this.condition = condition;
        this.controls = [];
        this.layout = layout;
    }

    addControl(control: BaseFormNode) {
        this.controls.push(control);
    }

    getChildren() {
        return this.controls;
    }

    override canPresentInTree(currentNode: TVirtualTreeNode): boolean {
        return this.condition.getResult(this, currentNode);
    }

    override accept = <R>(visitor: IFormNodeVisitor<R>): R => visitor.withForm(this);
}

export type TFormLayout = "column" | "row";
