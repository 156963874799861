import { action, makeObservable, observable } from "mobx";

export class AdminUser {
    @observable id: number;

    @observable username: string;
    @observable firstName: string;
    @observable lastName: string;
    @observable role: string;
    @observable isActive: boolean;

    constructor(init: AdminUser.Init) {
        this.id = init.id;

        this.username = init.username;
        this.firstName = init.firstName;
        this.lastName = init.lastName;

        this.role = init.role;
        this.isActive = init.isActive;

        makeObservable(this);
    }

    @action.bound
    setUsername(username: string) {
        this.username = username;
    }

    @action.bound
    setFirstName(firstName: string) {
        this.firstName = firstName;
    }

    @action.bound
    setLastName(lastName: string) {
        this.lastName = lastName;
    }

    @action.bound
    setIsActive(isActive: boolean) {
        this.isActive = isActive;
    }

    @action.bound
    setRole(role: string) {
        this.role = role;
    }
}

export namespace AdminUser {
    export type Init = {
        id: number;

        username: string;
        firstName: string;
        lastName: string;

        role: string;
        isActive: boolean;
    };
}
