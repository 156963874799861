import type { TRawPatchedQuestion, TRawQuestion } from "./QuestionsService.types";
import type { Question } from "../../entities/questions/Question";
import type { QuestionsFilters } from "../../entities/questions/QuestionsFilters";

import { agent } from "../../agent";
import { throwServiceError } from "../base/RequestError";

import {
    mapQuestion,
    mapPatchedQuestion,
    serializePathQuestion,
    serializeQuestionsFilters as serializeQuestionsFiltersRequest,
} from "./QuestionsService.utils";

export class QuestionsService {
    async fetchAll(filters?: QuestionsFilters): Promise<Question[]> {
        try {
            const filtersData = filters ? serializeQuestionsFiltersRequest(filters) : void 0;

            const { data } = await agent.get<TRawQuestion[]>("/feedback/questions", {
                params: filtersData,
            });

            return data.map(mapQuestion);
        } catch (e) {
            throwServiceError(e);
        }
    }

    // /** @deprecated use 'denyQuestion' instead */
    // async removeById(questionId: number): Promise<void> {
    //     try {
    //         await agent.delete(`/feedback/questions/${questionId}/`);
    //     } catch (e) {
    //         throwServiceError(e);
    //     }
    // }

    async denyQuestion(questionId: number, reason: string): Promise<void> {
        try {
            const requestData = { reason };

            await agent.post(`/feedback/questions/${questionId}/deny/`, requestData);
        } catch (e) {
            throwServiceError(e);
        }
    }

    async patchQuestion(question: Question): Promise<Question> {
        try {
            const { data } = await agent.patch<TRawPatchedQuestion>(
                `/feedback/questions/${question.id}/`,
                serializePathQuestion(question)
            );

            return mapPatchedQuestion(data);
        } catch (e) {
            throwServiceError(e);
        }
    }
}
