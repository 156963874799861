/* eslint-disable @typescript-eslint/no-explicit-any */
import type { InferErrorResponse } from "../../../types/service";
import type { TTaskResponse } from "../types";

export type TErrorArray<T> = Partial<Record<string | number, T>>;

export function getErrorString(field: any): string | undefined {
    if (field) {
        return String(field);
    }
}

export function getErrorArray<T>(data: any, transformValue: (value: any) => T): undefined | Record<string, T> {
    if (!data) return;
    return Object.fromEntries(
        Object.entries(data).flatMap(([k, v]) => {
            if (!v) return [];
            if (Object.keys(v as any).length === 0) return [];
            return [[k, transformValue(v)]];
        })
    );
}

/** @see Task */
export class TaskError extends Error {
    raw: InferErrorResponse<TTaskResponse>;
    formula?: string;
    variables?: TErrorArray<TaskVariableError>;
    answers?: TErrorArray<TaskVariableError>;
    variantGenerationLimit?: string;

    constructor(message: string, error: any) {
        super(message);
        this.raw = error;
        if (typeof error === "object") {
            this.formula = getErrorString(error.formula);
            this.answers = getErrorArray(error.answers, (data) => new TaskVariableError(data));
            this.variables = getErrorArray(error.variables, (data) => new TaskVariableError(data));
            this.variantGenerationLimit = getErrorString(error.variant_generation_limit);
        }
    }
}

export class TaskVariableError {
    generation?: string;
    name?: string;
    content?: string;
    description?: string;

    constructor(data: any) {
        this.generation = getErrorString(data.generation);
        this.name = getErrorString(data.name);
        this.content = getErrorString(data.content);
        this.description = getErrorString(data.description);
    }
}
