export function* filterEqualObjects<T>(elements: Iterable<T>, getHashCode: (object: T) => string): Iterable<T> {
    const set = new Set<string>();

    for (const element of elements) {
        const hash = getHashCode(element);
        if (!set.has(hash)) {
            set.add(hash);
            yield element;
        }
    }
}

export function isOdd(value: number): boolean {
    return !isEven(value);
}

export function isEven(value: number): boolean {
    return value % 2 === 0;
}

export function flipCoin(): boolean;
export function flipCoin(trueChance: number): boolean;

export function flipCoin(trueChance = 0.5): boolean {
    return Math.random() > 1 - trueChance;
}
