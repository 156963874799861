import { action, makeObservable, observable } from "mobx";

import { Graph1DInstrumentStatementDot } from "@viuch/instrument-graph1d/statement";
import { createSerializedState } from "@viuch/math-editor/utils/serialization";

import type { TLazyEditDotResultPayload, TLazyNewDotResultPayload } from "./Graph1DInstrumentSettingsEditor.types";
import type { Graph1DInstrumentStatement, IGraph1DInstrumentStatementDot } from "@viuch/instrument-graph1d/statement";

export class Graph1DInstrumentSettingsEditorViewModel {
    readonly graph: Graph1DInstrumentStatement;

    @observable.ref whileEditDot?: TLazyEditDotResultPayload;
    @observable.ref whileAddDot?: TLazyNewDotResultPayload;

    constructor(graph: Graph1DInstrumentStatement) {
        this.graph = graph;

        makeObservable(this);
    }

    @action.bound
    handleEditDot(graph: Graph1DInstrumentStatement, dot: Graph1DInstrumentStatementDot) {
        this.whileEditDot = { graph, dot: dot.clone(), sourceDot: dot };
    }

    @action.bound
    handleEditDotSave() {
        if (!this.whileEditDot) return;

        const { dot, sourceDot } = this.whileEditDot;

        const index = this.graph.dots.indexOf(sourceDot);

        if (index !== -1) {
            this.graph.dots[index] = dot;
        }
        this.whileEditDot = void 0;
    }

    @action.bound
    removeDot() {
        if (!this.whileEditDot) return;
        const { sourceDot } = this.whileEditDot;

        this.graph.removeDot(sourceDot);
        this.whileEditDot = void 0;
    }

    @action.bound
    handleEditDotClose() {
        this.whileEditDot = void 0;
    }

    @action.bound
    handleAddDot(graph: Graph1DInstrumentStatement, position: number) {
        if (graph.hasDotOnPosition(position)) return;

        const newDot = new Graph1DInstrumentStatementDot({
            name: createSerializedState(),
            value: createSerializedState(),
            form: "line",
            isProtected: true,
            position,
            isNameVisible: true,
            isValueVisible: true,
        });

        this.whileAddDot = { graph, newDot };
    }

    @action.bound
    handleAddDotSave(dot: Graph1DInstrumentStatementDot) {
        if (!this.whileAddDot) return;

        this.graph.dots.push(dot);
        this.whileAddDot = void 0;
    }

    @action.bound
    handleAddDotCancel() {
        this.whileAddDot = void 0;
    }

    @action.bound
    moveDot(graph: Graph1DInstrumentStatement, dot: IGraph1DInstrumentStatementDot, position: number) {
        if (!this.hasDotOnPosition(position)) {
            dot.position = position;
        }
    }

    @action.bound
    clear() {
        this.graph.dots.slice().forEach((dot) => this.graph.removeDot(dot));
    }

    private hasDotOnPosition(position: number) {
        return this.graph.dots.some((dot) => dot.position === position);
    }
}
