import type { TAnyAction } from "../types";

export type TDegreeAction = {
    type: "degree";
    power?: number;
};

export function createDegreeAction(power?: number): TDegreeAction {
    return { type: "degree", power };
}

export function isDegreeAction(action: TAnyAction): action is TDegreeAction {
    return action.type === "degree";
}
