import { generateUuid } from "@viuch/shared/utils/data";

import type { ISerializedDerivative } from "./types";

export function createSerializedDerivative(): ISerializedDerivative {
    return {
        type: "derivative",
        uuid: generateUuid(),
    };
}
