import React from "react";

import type { PlaceholdersService } from "../../core/placeholders";

import { usePlaceholderRegistrationRef } from "../../core/placeholders";

import styles from "./Placeholder.module.scss";

type Props = {
    placeholders: PlaceholdersService;
};

export const Placeholder: React.VFC<Props> = ({ placeholders }) => {
    const ref = usePlaceholderRegistrationRef<HTMLDivElement>(placeholders);

    return (
        <span
            className={styles.placeholder}
            ref={ref}
        >
            <span className={styles.inner} />
        </span>
    );
};
