import type { TAnyAction } from "../types";

export type THistoryActionTypes = "undo" | "redo";

export type THistoryAction = { type: "history"; action: THistoryActionTypes };

export function createHistoryAction(action: THistoryActionTypes): THistoryAction {
    return { type: "history", action };
}

export function isHistoryAction(action: TAnyAction): action is THistoryAction {
    return action.type === "history";
}
