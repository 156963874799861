export function pathFromRaw(path: string): string[] {
    return filterPath(path.split("/"));
}

function filterPath(pathSegments: string[]): string[] {
    const segments: string[] = [];

    for (const segment of pathSegments) {
        switch (segment) {
            case "":
            case ".":
                // skip
                break;
            case "..":
                // remove last segment
                const lastElement = segments.pop();
                if (typeof lastElement === "undefined") {
                    segments.push(segment);
                }
                break;
            default:
                // pass segment
                segments.push(segment);
        }
    }

    return segments;
}
