import { makeObservable, observable } from "mobx";

import type { ToolbarButton } from "./ToolbarButton";

export class ToolbarMenu {
    readonly key: string;
    buttons: ToolbarButton[];

    constructor(key: string, buttons: ToolbarButton[]) {
        this.key = key;
        this.buttons = buttons.slice();

        makeObservable(this, {
            buttons: observable,
        });
    }
}
