import React from "react";

import type { IInputServiceOptions } from "../services/input/InputService.types";

import { InputService } from "../services";

export function useInputService(getOptions: () => IInputServiceOptions) {
    const [inputService] = React.useState(() => new InputService(getOptions()));
    return inputService;
}
