import cn from "classnames";
import { reaction, runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { useMemo, useRef, useEffect, useState } from "react";

import { CustomScrollbar } from "@viuch/custom-scrollbar";
import { useDebounce, useDisableSafariTouchSelectionFix, useLongPress, usePropsRef } from "@viuch/utils/hooks";

import type { TMathEditorHandlers } from "./mathEditorHandlersContext";
import type { IMathEditorInputProps } from "./MathEditorInput.types";
import type { HighlightRange } from "../../core/highlighting/ranges/HighlightRange";
import type { TDirectionTrace, TPoint } from "../../types";
import type { KeyboardEvent, CSSProperties, PointerEventHandler, MouseEvent, PointerEvent } from "react";

import { ContainerElement } from "../../core/container";
import { useMathEditorElementRef } from "../../hooks";
import { getRectFromDomRect, pointAddition, pointSubtraction } from "../../utils/positions";
import { traceWhenPointOutsideRect } from "../../utils/validation";

import { SelectionCursor, SelectionPointer } from "./elements";
import { HandlersContextProvider } from "./mathEditorHandlersContext";

import styles from "./MathEditorInput.module.scss";

const MathEditorInput = observer(function MathEditorInput(props: IMathEditorInputProps) {
    const {
        className,
        inputModel,
        onContextMenu,
        contentClassName,
        inputWrapperClassName,
        scrollbarContentClassName,
        withoutScrollbar,
        inputContentStyles,
        onBlur,
        keyboardService,
        onClick,
        readonly,
        baselineRef,
        disabled,
    } = props;

    const propsRef = usePropsRef(props);
    const { inputService } = inputModel;

    useEffect(() => {
        if (keyboardService) {
            inputService.setKeyboard(keyboardService);
        }
    }, [inputService, keyboardService]);

    useEffect(() => {
        const dispose = inputService.highlightingService.effect();
        return () => {
            dispose();
        };
    }, [inputService]);

    const { selectionController: selection, placeholders } = inputService;
    const mathEditorRef = useMathEditorElementRef<HTMLDivElement>(inputModel);
    const wrapperRef = useRef<HTMLDivElement>(null);

    const debounce = useDebounce();

    function handleKeyDown(e: KeyboardEvent<HTMLDivElement>): void {
        const { key, code, ctrlKey, shiftKey, altKey, metaKey } = e;

        if (/^Arrow|Space/i.test(code)) {
            e.preventDefault();
        }
        inputService.dispatchKeyboardEvent({
            key,
            code,
            ctrlKey,
            altKey,
            shiftKey,
            cmdKey: metaKey,
        });
    }

    const { disableSelectAbility, returnSelectAbility } = useDisableSafariTouchSelectionFix();
    const { handleDown, handleUp, handleCancel } = useLongPress<[TPoint, TPoint]>(500, (isLong, [point, offset]) => {
        if (!isLong) {
            selection.clearSelection();
            return;
        }

        const clientPoint = pointAddition(point, offset);

        const isOverPlaceholder = placeholders.checkIsPointInsidePlaceholder(clientPoint);

        selection.initContainerRects(offset);
        const raycast = selection.raycast(point);
        const raycastElementIndex = raycast?.index;

        const isOverElement = Boolean(raycast?.isOverElement);
        const container = raycast?.container;
        const element = container?.tryGetElementByIndex(raycastElementIndex);

        onContextMenu?.({
            pointerType: "touch",
            clientPoint,
            offset,
            relativePoint: point,
            inputService,
            isOverPlaceholder,
            isOverElement,
            element,
            container,
            raycastElementIndex,
        });
    });

    const onBlurRef = useRef(onBlur);

    useEffect(() => {
        onBlurRef.current = onBlur;
    });

    const handleFocus = () => {
        inputModel.handleFocus();
    };

    const handleBlur = () => {
        onBlurRef.current?.(inputService);
        inputModel.onValueBlur();
    };

    function handleDownOnCanvas(e: PointerEvent): void {
        const clientPoint: TPoint = { x: e.clientX, y: e.clientY };
        const offset = inputModel.getOffsetPoint();
        const point = pointSubtraction(clientPoint, offset);

        if (e.pointerType === "mouse") {
            const LEFT_MOUSE_BUTTON = 0;
            const RIGHT_MOUSE_BUTTON = 2;
            if (e.button === LEFT_MOUSE_BUTTON) {
                selection.notifyMouseDown(point, offset);
                return;
            }

            if (e.button === RIGHT_MOUSE_BUTTON) {
                const isOverPlaceholder = placeholders.checkIsPointInsidePlaceholder(clientPoint);

                selection.initContainerRects(offset);
                const raycast = selection.raycast(point);
                const raycastElementIndex = raycast?.index;

                const isOverElement = Boolean(raycast?.isOverElement);
                const container = raycast?.container;
                const element = container?.tryGetElementByIndex(raycastElementIndex);

                onContextMenu?.({
                    pointerType: "mouse",
                    clientPoint,
                    offset,
                    relativePoint: point,
                    inputService,
                    isOverPlaceholder,
                    isOverElement,
                    container,
                    element,
                    raycastElementIndex,
                });
            }
        } else if (e.isPrimary) {
            disableSelectAbility();
            handleDown([point, offset]);
        }
    }

    function handleMoveOnCanvas(e: PointerEvent): void {
        if (!selection.isSelecting) {
            return;
        }

        e.currentTarget.setPointerCapture(e.pointerId);

        const containerRect = inputModel.getContainerRect();
        const clientPoint: TPoint = { x: e.clientX, y: e.clientY };
        const offset = inputModel.getOffsetPoint(containerRect);
        const point = inputModel.getRelativePoint(clientPoint, offset);

        if (e.pointerType === "mouse") {
            const callback = () => {
                selection.notifyMouseMove(point);
            };

            debounce(callback, 0, true);

            scrollDirectionsRef.current = traceWhenPointOutsideRect(clientPoint, containerRect);
        }
    }

    const scrollDirectionsRef = useRef<TDirectionTrace | void>();

    useEffect(() => {
        const container = mathEditorRef.current!;
        let next = true;

        function performScroll(): void {
            const directions = scrollDirectionsRef.current;
            if (directions) {
                if (directions.includes("T")) container.scrollTop -= 1;
                if (directions.includes("R")) container.scrollLeft += 1;
                if (directions.includes("B")) container.scrollTop += 1;
                if (directions.includes("L")) container.scrollLeft -= 1;
            }
            next && requestAnimationFrame(performScroll);
        }

        performScroll();

        return () => {
            next = false;
        };
    }, [mathEditorRef]);

    function handleUpOnCanvas(e: PointerEvent): void {
        const clientPoint: TPoint = { x: e.clientX, y: e.clientY };
        const offset = inputModel.getOffsetPoint();
        const point = inputModel.getRelativePoint(clientPoint, offset);

        if (e.pointerType === "mouse") {
            selection.notifyMouseUp(point);
        } else if (e.isPrimary) {
            returnSelectAbility();
            handleUp();
        }
    }

    function handleContextMenu(e: MouseEvent) {
        e.preventDefault();
    }

    function handlePointerCancel(e: PointerEvent) {
        if (e.isPrimary) {
            returnSelectAbility();
            handleCancel();
        }
    }

    const selectPointerFirstRef = useRef<HTMLDivElement | null>(null);
    const selectPointerSecondRef = useRef<HTMLDivElement | null>(null);

    const [firstCursorElement, setFirstCursorElement] = useState<HTMLDivElement | null>(null);
    const [secondCursorElement, setSecondCursorElement] = useState<HTMLDivElement | null>(null);

    const firstPointerVisibleRef = useRef(false);
    const secondPointerVisibleRef = useRef(false);

    useEffect(() => {
        const intersectionElement = wrapperRef.current!;

        if (!firstCursorElement || !secondCursorElement) {
            return;
        }

        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    const visible = entry.intersectionRatio > 0;
                    if (entry.target === firstCursorElement) {
                        firstPointerVisibleRef.current = visible;
                    }
                    if (entry.target === secondCursorElement) {
                        secondPointerVisibleRef.current = visible;
                    }
                });
            },
            {
                root: intersectionElement,
                threshold: 0,
            }
        );

        observer.observe(firstCursorElement);
        observer.observe(secondCursorElement);

        return () => {
            observer.disconnect();
        };
    }, [firstCursorElement, secondCursorElement]);

    useEffect(() => {
        function handleWithElements(cursor: HTMLDivElement, pointer: HTMLDivElement, visible: boolean) {
            const cursorRect = getRectFromDomRect(cursor.getBoundingClientRect());
            const { x, y, w, h } = cursorRect;
            pointer.style.setProperty("top", `${y + h}px`);
            pointer.style.setProperty("left", `${x + w / 2}px`);
            if (visible) {
                pointer.style.removeProperty("display");
            } else {
                pointer.style.setProperty("display", "none");
            }
        }

        let next = true;

        function handle(): void {
            const cursor1 = firstCursorElement;
            const cursor2 = secondCursorElement;
            const pointer1 = selectPointerFirstRef.current;
            const pointer2 = selectPointerSecondRef.current;

            if (cursor1 && cursor2 && pointer1 && pointer2) {
                handleWithElements(cursor1, pointer1, firstPointerVisibleRef.current);
                handleWithElements(cursor2, pointer2, secondPointerVisibleRef.current);
            }

            next && requestAnimationFrame(handle);
        }

        handle();

        return () => {
            next = false;
        };
    }, [firstCursorElement, secondCursorElement]);

    useEffect(() => {
        const { elements, containers } = inputService;

        const dispose = reaction(
            () => [elements.getAll(), containers.getAll()],
            () => {
                propsRef.current.onChange?.(inputService);
            }
        );

        return () => {
            dispose();
        };
    }, [inputService, propsRef]);

    const debouncePointer = useDebounce();

    function getMoveHandlerOnPointer(what: 1 | 2): PointerEventHandler {
        return (e) => {
            if (!e.isPrimary) {
                return;
            }
            debouncePointer(
                () => {
                    const clientPoint: TPoint = {
                        x: e.clientX,
                        y: e.clientY,
                    };
                    const point = inputModel.getRelativePoint(pointAddition(clientPoint, { x: 0, y: -20 }));
                    selection.notifyPointerMove(what, point);
                },
                4,
                false
            );
        };
    }

    function handlerCancelOnPointer(): void {
        selection.clearSelection();
    }

    const firstSelectedStyle: CSSProperties | undefined = selection.firstSelectedElementRect
        ? {
              left:
                  selection.firstSelectedElementRect.x + (selection.reverse ? selection.firstSelectedElementRect.w : 0),
              top: selection.firstSelectedElementRect.y,
              height: selection.firstSelectedElementRect.h,
          }
        : undefined;

    const lastSelectedStyle: CSSProperties | undefined = selection.lastSelectedElementRect
        ? {
              left: selection.lastSelectedElementRect.x + (selection.reverse ? 0 : selection.lastSelectedElementRect.w),
              top: selection.lastSelectedElementRect.y,
              height: selection.lastSelectedElementRect.h,
          }
        : undefined;

    function handleClick(e: MouseEvent) {
        runInAction(() => {
            onClick?.();

            if (!selection.isSelectedSomething) {
                const clientPoint: TPoint = { x: e.clientX, y: e.clientY };
                const offset = inputModel.getOffsetPoint();
                const point = inputModel.getRelativePoint(clientPoint, offset);
                selection.setCursorAt(point, offset, true);
            }
        });
    }

    const handlers: TMathEditorHandlers = useMemo(
        () => ({
            onHighlightClick: (highlight: HighlightRange) => {
                propsRef.current.onHighlightClick?.(highlight);
            },
        }),
        [propsRef]
    );

    const containerWithSelectionCursorsLayout = (
        <HandlersContextProvider handlers={handlers}>
            <ContainerElement
                containerModel={inputModel.rootContainer}
                baselineRef={baselineRef}
            />
            {selection.isSelectingTouch && (
                <div className={styles.selectionContainer}>
                    {firstSelectedStyle && (
                        <SelectionCursor
                            ref={setFirstCursorElement}
                            style={firstSelectedStyle}
                        />
                    )}
                    {lastSelectedStyle && (
                        <SelectionCursor
                            ref={setSecondCursorElement}
                            style={lastSelectedStyle}
                        />
                    )}
                </div>
            )}
        </HandlersContextProvider>
    );

    useEffect(() => {
        if (inputModel.isFocused) {
            mathEditorRef.current?.focus();
        }
    }, [inputModel.isFocused, mathEditorRef]);

    const shouldBeDisabled = !!disabled || !!readonly;
    useEffect(() => {
        inputService.setDisabled(shouldBeDisabled);
    }, [shouldBeDisabled, inputService]);

    useEffect(() => {
        // Фикс проблемы с неожиданным скроллбаром в редакторе
        // Воспроизводится: в Firefox Desktop, в Safari Desktop:
        // загрузить страницу (Desktop-версия) и изменять размер окна.
        //
        // Проблема: ширина элементов кастомного скроллбара может быть близка к нулю
        // и отличаться менее чем на 1 пиксель. Это происходит случайным образом.
        // В других браузерах длина элементов всегда равна (при отсутствии проблемы с переполнением).
        //
        // Решение: распознавать указанное выше состояние через MutationObserver.
        // Если такое состояние было поймано, скрывать скроллбар.
        // Обратного действия не предусмотрено, т.к. проблем не было замечено.

        const root = wrapperRef.current!;

        const target = root.querySelector(`[data-scrollbar-element-tag="scroller"]`);
        const trackX = root.querySelector<HTMLDivElement>(`[data-scrollbar-element-tag="track-x"]`);
        const trackY = root.querySelector<HTMLDivElement>(`[data-scrollbar-element-tag="track-y"]`);
        const elementTableCellLike = target?.children.item(0);

        if (!target || !elementTableCellLike || !trackX || !trackY) return;

        const mutationObserver = new MutationObserver(() => {
            const contentWidth = elementTableCellLike.getBoundingClientRect().width;
            const wrapperWidth = target.getBoundingClientRect().width;
            const diffX = contentWidth - wrapperWidth;

            if (diffX > -1 && diffX < 1.5) {
                trackX.style.setProperty("display", "none");
            }

            const contentHeight = elementTableCellLike.getBoundingClientRect().height;
            const wrapperHeight = target.getBoundingClientRect().height;
            const diffY = contentHeight - wrapperHeight;

            if (diffY > -1 && diffY < 1.5) {
                trackY.style.setProperty("display", "none");
            }
        });

        mutationObserver.observe(target, { attributes: true, attributeFilter: ["style"] });

        return () => {
            mutationObserver.disconnect();
        };
    }, []);

    return (
        <div
            role="presentation"
            className={cn(styles.inputWrapper, inputWrapperClassName, readonly && styles.readonly)}
        >
            <div
                className={cn(className, styles.mathField)}
                ref={wrapperRef}
            >
                {withoutScrollbar ? (
                    <div
                        ref={mathEditorRef}
                        className={cn(styles.content, contentClassName)}
                        tabIndex={0}
                        onKeyDown={handleKeyDown}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        onPointerDown={handleDownOnCanvas}
                        onPointerMove={handleMoveOnCanvas}
                        onPointerUp={handleUpOnCanvas}
                        onPointerCancel={handlePointerCancel}
                        onContextMenu={handleContextMenu}
                        onClick={handleClick}
                        style={inputContentStyles}
                    >
                        {containerWithSelectionCursorsLayout}
                    </div>
                ) : (
                    <CustomScrollbar
                        scrollbarContentClassName={scrollbarContentClassName}
                        ref={mathEditorRef}
                        scrollerProps={{
                            onKeyDown: handleKeyDown,
                            onFocus: handleFocus,
                            onBlur: handleBlur,
                            onPointerDown: handleDownOnCanvas,
                            onPointerMove: handleMoveOnCanvas,
                            onPointerUp: handleUpOnCanvas,
                            onPointerCancel: handlePointerCancel,
                            onContextMenu: handleContextMenu,
                            onClick: handleClick,
                            tabIndex: readonly ? undefined : 0,
                        }}
                        isFocused={inputModel.isFocused}
                    >
                        <div className={cn(styles.content, contentClassName)}>
                            {containerWithSelectionCursorsLayout}
                        </div>
                    </CustomScrollbar>
                )}
            </div>
            {selection.isSelectingTouch && selection.isSelectedSomething && (
                <>
                    <SelectionPointer
                        ref={selectPointerFirstRef}
                        onPointerDown={getMoveHandlerOnPointer(1)}
                        onPointerMove={getMoveHandlerOnPointer(1)}
                        onPointerUp={getMoveHandlerOnPointer(1)}
                        onPointerCancel={handlerCancelOnPointer}
                    />
                    <SelectionPointer
                        ref={selectPointerSecondRef}
                        onPointerDown={getMoveHandlerOnPointer(2)}
                        onPointerMove={getMoveHandlerOnPointer(2)}
                        onPointerUp={getMoveHandlerOnPointer(2)}
                        onPointerCancel={handlerCancelOnPointer}
                    />
                </>
            )}
        </div>
    );
});
export default MathEditorInput;
