import type { ISerializedComplexIntegral } from "./types";
import type { TSerializedElementPrototype } from "../../core/element";
import type { IElementVisitor } from "../../core/element/IElementVisitor";
import type { IStrategy } from "../../core/strategies";
import type { IMultiContainerElement } from "../../core/strategies/types";
import type { InputService } from "../../services";
import type { TContainerElements, TElementDeserializerFunction } from "../../types";

import { ContainerModel } from "../../core/container";
import { BaseElementModel } from "../../core/element";

import { ComplexIntegralStrategy } from "./ComplexIntegralStrategy";

export class ComplexIntegralModel
    extends BaseElementModel<ISerializedComplexIntegral>
    implements IMultiContainerElement
{
    public readonly integralContent: ContainerModel;
    public readonly argContent: ContainerModel;
    public readonly topContent: ContainerModel;
    public readonly bottomContent: ContainerModel;

    constructor(
        inputService: InputService,
        integralElements: TContainerElements,
        argElements: TContainerElements,
        topElements: TContainerElements,
        bottomElements: TContainerElements,
        uuid?: string
    ) {
        super(inputService, uuid);

        const containerOptions = { showPlaceholderIfEmpty: true };

        this.integralContent = new ContainerModel(
            integralElements,
            this,
            ["integralContent"],
            inputService,
            containerOptions
        );

        this.argContent = new ContainerModel(argElements, this, ["argContent"], inputService, containerOptions);

        this.topContent = new ContainerModel(topElements, this, ["topContent"], inputService, containerOptions);

        this.bottomContent = new ContainerModel(
            bottomElements,
            this,
            ["bottomContent"],
            inputService,
            containerOptions
        );
    }

    protected override initBehaviour(): IStrategy {
        return new ComplexIntegralStrategy(this);
    }

    public serialize(): ISerializedComplexIntegral {
        return {
            type: "complex-integral",
            argContent: this.argContent.serialize(),
            integralContent: this.integralContent.serialize(),
            topContent: this.topContent.serialize(),
            bottomContent: this.bottomContent.serialize(),
            uuid: this.uuid,
        };
    }

    public static deserialize: TElementDeserializerFunction<ISerializedComplexIntegral> = (
        { inputService },
        { argContent, integralContent, topContent, bottomContent, uuid }
    ) =>
        new ComplexIntegralModel(
            inputService,
            inputService.deserializeContainer(integralContent),
            inputService.deserializeContainer(argContent),
            inputService.deserializeContainer(topContent),
            inputService.deserializeContainer(bottomContent),
            uuid
        );

    getContainersToMoveCursorBetween(): ContainerModel[] {
        return [this.integralContent, this.bottomContent, this.topContent, this.argContent];
    }

    public accept<R>(visitor: IElementVisitor<R>): R {
        return visitor.doWithComplexIntegral(this);
    }

    public serializeAsClone(): TSerializedElementPrototype<ISerializedComplexIntegral> {
        const { uuid, ...clone } = this.serialize();
        return {
            ...clone,
            integralContent: this.integralContent.serializeAsClone(),
            topContent: this.topContent.serializeAsClone(),
            bottomContent: this.bottomContent.serializeAsClone(),
            argContent: this.argContent.serializeAsClone(),
        };
    }

    override getWrapperContainer(): ContainerModel | null {
        return this.integralContent;
    }
}
