import { action, makeObservable, observable } from "mobx";

import { createSerializedState } from "@viuch/math-editor/utils/serialization";
import { generateId } from "@viuch/shared/utils/data";

import type { IntervalsDot } from "./IntervalsDot";
import type { TIntervalSign } from "./types";
import type { TSerializedState } from "@viuch/math-editor";

export class IntervalsLine {
    public readonly id: number;

    @observable.shallow dots: IntervalsDot[];

    @observable.shallow intervalsSings: TIntervalSign[];

    @observable.ref label: TSerializedState;

    constructor(
        dots: IntervalsDot[],
        intervalsSings: TIntervalSign[],
        label: TSerializedState | undefined,
        id?: number
    ) {
        this.id = id ?? generateId();

        this.dots = dots;
        this.intervalsSings = intervalsSings;
        this.label = label ?? createSerializedState();

        this.sortDots();

        if (this.intervalsSings.length - this.dots.length !== 1) {
            throw new Error("difference between intervals length and dots length must equals to 1");
        }

        makeObservable(this);
    }

    @action.bound
    setLabel(label: TSerializedState) {
        this.label = label;
    }

    static createEmpty(): IntervalsLine {
        return new IntervalsLine([], ["zero"], createSerializedState());
    }

    @action.bound
    removeDot(dot: IntervalsDot): void {
        const removedDotIndex = this.dots.findIndex(({ id }) => dot.id === id);
        const leftIntervalSign = this.intervalsSings[removedDotIndex];
        const rightIntervalSign = this.intervalsSings[removedDotIndex + 1];

        const newIntervalSign = leftIntervalSign === rightIntervalSign ? leftIntervalSign : "zero";

        this.dots = this.dots.filter(({ id }) => dot.id !== id);
        this.sortDots();

        this.intervalsSings.splice(removedDotIndex, 2, newIntervalSign);
    }

    @action.bound
    createDot(dot: IntervalsDot) {
        if (this.hasDotOn(dot.position)) {
            return;
        }

        this.dots.push(dot);
        this.sortDots();

        const addedDotIndex = this.dots.indexOf(dot);
        const oldIntervalSign = this.intervalsSings[addedDotIndex];

        this.intervalsSings[addedDotIndex] = oldIntervalSign;
        this.intervalsSings.splice(addedDotIndex + 1, 0, oldIntervalSign);
    }

    private hasDotOn(position: number): boolean {
        return this.dots.some((dot) => dot.position === position);
    }

    private sortDots(): void {
        this.dots.sort((prev, next) => prev.position - next.position);
    }

    cloneWithCurrentId(): IntervalsLine {
        return new IntervalsLine(
            this.dots.map((dot) => dot.cloneWithCurrentId()),
            this.intervalsSings.slice(),
            this.label,
            this.id
        );
    }
}
