import { observer } from "mobx-react-lite";
import React from "react";

import { useIsSafari } from "@viuch/utils/hooks";

import type { DotElement } from "./DotElement";
import type { Figure2D } from "../../../entities/Figure2D";

type Props = {
    figure: Figure2D;
    dot: DotElement;
    onPointerEvent(e: React.PointerEvent, dot: DotElement): void;
};

export const DotView = observer(function DotView({ dot, onPointerEvent }: Props) {
    const { x, y, color } = dot;

    const handlePointerEvent = (e: React.PointerEvent) => {
        onPointerEvent(e, dot);
    };

    const isSafari = useIsSafari();

    return (
        <g>
            {color.shadow && !isSafari && (
                <circle
                    transform="translate(-1 2)"
                    filter="blur(4px)"
                    cx={`${x * 100}%`}
                    cy={`${(1 - y) * 100}%`}
                    r={4}
                    fill={color.hex}
                />
            )}
            <circle
                cx={`${x * 100}%`}
                cy={`${(1 - y) * 100}%`}
                r={4}
                fill={color.hex}
                opacity={color.opacity}
            />
            <circle
                cx={`${x * 100}%`}
                cy={`${(1 - y) * 100}%`}
                r={isSafari ? 18 : 12}
                fill="transparent"
                onPointerDown={handlePointerEvent}
                onPointerMove={handlePointerEvent}
                onPointerUp={handlePointerEvent}
                onPointerCancel={handlePointerEvent}
            />
        </g>
    );
});
