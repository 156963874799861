import * as mobx from "mobx";

import type { ISerializedDerivative } from "./types";
import type { TSerializedElementPrototype } from "../../core/element";
import type { IElementVisitor } from "../../core/element/IElementVisitor";
import type { InputService } from "../../services";
import type { TElementDeserializerFunction } from "../../types";

import { BaseElementModel } from "../../core/element";

export class DerivativeModel extends BaseElementModel<ISerializedDerivative> {
    public constructor(inputService: InputService, uuid?: string) {
        super(inputService, uuid);

        mobx.makeObservable(this, {
            domElementBefore: mobx.computed,
        });
    }

    public get domElementBefore(): HTMLElement | null {
        const thisIndex = this.parentContainer.getElementIndex(this);

        for (let i = thisIndex - 1; i >= 0; i--) {
            const prevModel = this.parentContainer.getElementByIndex(i);
            return prevModel?.domElement || null;
        }

        return null;
    }

    public serialize(): ISerializedDerivative {
        return { type: "derivative", uuid: this.uuid };
    }

    public static deserialize: TElementDeserializerFunction<ISerializedDerivative> = ({ inputService }, { uuid }) =>
        new DerivativeModel(inputService, uuid);

    public accept<R>(visitor: IElementVisitor<R>): R {
        return visitor.doWithDerivative(this);
    }

    serializeAsClone(): TSerializedElementPrototype<ISerializedDerivative> {
        const { uuid, ...clone } = this.serialize();
        return clone;
    }
}
