import * as mobx from "mobx";

import type { TSerializedState } from "../../../types";
import type { EquationsSetModel } from "../EquationsSetModel";

import { BaseCommand } from "../../../core/commands";
import { createSerializedContainer } from "../../../utils/serialization";

export class AddNewContainerCommand extends BaseCommand<EquationsSetModel> {
    private readonly newContainerIndex: number;
    private readonly newSerializedContainer: TSerializedState;

    public constructor(model: EquationsSetModel, newContainerIndex: number) {
        super(model);
        this.newContainerIndex = newContainerIndex;
        this.newSerializedContainer = createSerializedContainer([]);

        mobx.makeObservable(this, {
            apply: mobx.action,
            rollback: mobx.action,
        });
    }

    apply(): void {
        const element = this.getCursorParentElement();
        const containerData = this.inputService.deserializeContainer(this.newSerializedContainer);
        const newContainer = element.addNewContainerAt(containerData, this.newContainerIndex);
        this.cursor.moveToStart(newContainer);
    }

    rollback(): void {
        const element = this.getCursorParentElement();
        element.removeContainerAt(this.newContainerIndex);
        this.restoreCursor();
    }
}
