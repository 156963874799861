import { detect } from "detect-browser";

export const platformDependentFractionOffset: number = (() => {
    const browserInfo = detect();

    if (!browserInfo) {
        return 0;
    }

    const { os, name } = browserInfo;

    switch (os) {
        case "Mac OS":
        case "iOS":
            switch (name) {
                case "safari":
                    return 1;
                case "firefox":
                    return 1.5;
                case "chrome":
                case "edge-chromium":
                    return 0.5;
                case "opera":
                    return -0.5;
            }
            break;

        case "Windows 10":
            switch (name) {
                case "firefox":
                    return 0.5;
                case "chrome":
                case "edge-chromium":
                    return 0.5;
            }
            break;
    }

    return 0;
})();
