import { generateUuid } from "@viuch/shared/utils/data";

import type { ISerializedSpecialSymbol, TSymbolActionChar } from "./types";
import type { TAnySerializedElement } from "../../types";

export function createSerializedSymbol(char: TSymbolActionChar): ISerializedSpecialSymbol {
    return { type: "symbol", uuid: generateUuid(), symbol: char };
}

export function isSerializedSymbol(element: TAnySerializedElement): element is ISerializedSpecialSymbol {
    return element.type === "symbol";
}
