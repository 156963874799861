import type { TRawPatchedThemeReview, TRawThemeReview } from "./ReviewsService.types";
import type { ReviewsFilters } from "../../entities/reviews/ReviewsFilters";
import type { ThemeReview } from "../../entities/reviews/ThemeReview";

import { agent } from "../../agent";
import { throwServiceError } from "../base/RequestError";

import {
    mapPatchedThemeReview,
    mapThemeReview,
    serializePatchThemeReview,
    serializeReviewFilters,
} from "./ReviewsService.utils";

export class ReviewsService {
    async fetchAll(filters?: ReviewsFilters): Promise<ThemeReview[]> {
        try {
            const filtersData = filters ? serializeReviewFilters(filters) : void 0;

            const { data } = await agent.get<TRawThemeReview[]>("/feedback/reviews/", {
                params: filtersData,
            });

            return data.map(mapThemeReview);
        } catch (e) {
            throwServiceError(e);
        }
    }

    async fetchById(reviewId: number): Promise<ThemeReview> {
        try {
            const { data } = await agent.get<TRawThemeReview>(`/feedback/reviews/${reviewId}/`);

            return mapThemeReview(data);
        } catch (e) {
            throwServiceError(e);
        }
    }

    // /** @deprecated use 'denyById' instead */
    // async removeById(reviewId: number): Promise<void> {
    //     try {
    //         await agent.delete(`/feedback/reviews/${reviewId}/`);
    //     } catch (e) {
    //         throwServiceError(e);
    //     }
    // }

    async denyReview(reviewId: number, reason: string): Promise<void> {
        try {
            const requestData = { reason };

            await agent.post(`/feedback/reviews/${reviewId}/deny/`, requestData);
        } catch (e) {
            throwServiceError(e);
        }
    }

    async patch(review: ThemeReview): Promise<ThemeReview> {
        try {
            const { data } = await agent.patch<TRawPatchedThemeReview>(
                `/feedback/reviews/${review.id}/`,
                serializePatchThemeReview(review)
            );

            return mapPatchedThemeReview(review, data);
        } catch (e) {
            throwServiceError(e);
        }
    }
}
