import React from "react";
import { createRoot } from "react-dom/client";
import retargetEvents from "react-shadow-dom-retarget-events";

import { createSerializedContainer, isSerializedState } from "@viuch/math-editor";
import { assert } from "@viuch/utils/debug";

import type { IMathDataProviderComponent } from "./types";
import type { InputService, TSerializedState } from "@viuch/math-editor";

import { MathEditorPluginComponent } from "./MathEditorPluginComponent";

import styles from "./MathEditorPluginWebComponent.module.scss";

export class MathEditorPluginWebComponent extends HTMLElement {
    constructor() {
        super();
        this.attachShadow({ mode: "open", delegatesFocus: true });
    }

    static get observedAttributes() {
        return ["math-expression"];
    }

    mountPoint?: HTMLSpanElement;

    private findKeyboardServiceProviderElement(): IMathDataProviderComponent | null {
        let currentParent: HTMLElement | null = this;
        while ((currentParent = currentParent.parentElement)) {
            if ("keyboardService" in currentParent) {
                // Не получается отловить тип currentParent
                return currentParent as any;
            }
        }
        return null;
    }

    private formulaToText?: (expression: TSerializedState) => Promise<string>;

    connectedCallback() {
        const { shadowRoot } = this;
        assert(shadowRoot);

        const keyboardServiceProviderElement = this.findKeyboardServiceProviderElement();
        const keyboardService = keyboardServiceProviderElement?.keyboardService;
        this.formulaToText = keyboardServiceProviderElement?.serializeMathExpression;

        this.mountPoint = document.createElement("span");
        this.mountPoint.classList.add(styles.wrapper);

        shadowRoot.appendChild(this.mountPoint);

        const observer = new MutationObserver(() => {
            this.cloneAndUpdateStylesFromDocumentHead(shadowRoot);
        });

        observer.observe(document.head, {
            childList: true,
            attributes: true,
            characterData: true,
            subtree: true,
        });

        this.cloneAndUpdateStylesFromDocumentHead(shadowRoot);
        const initialState = this.getInitialState(this.getAttribute("math-expression"));

        createRoot(this.mountPoint).render(
            <MathEditorPluginComponent
                initialState={initialState}
                onStateChanged={this.handleStateChanged}
                keyboardService={keyboardService}
            />
        );

        retargetEvents(shadowRoot);
    }

    private getInitialState(content: string | null): TSerializedState {
        try {
            const state = content ? JSON.parse(content) : createSerializedContainer();
            if (isSerializedState(state)) {
                return state;
            }
            return createSerializedContainer([]);
        } catch (e) {
            return createSerializedContainer([]);
        }
    }

    private handleStateChanged = (inputService: InputService): void => {
        const formula = inputService.getSerializedState();
        const expression = JSON.stringify(formula);
        this.setAttribute("math-expression", expression);

        this.formulaToText?.(formula).then((value) => {
            this.innerText = value;
        });
    };

    private cloneAndUpdateStylesFromDocumentHead(shadowRoot: ShadowRoot): void {
        for (const element of shadowRoot.children) {
            if (element instanceof HTMLElement) {
                if (element.dataset.cloned) {
                    element.remove();
                }
            }
        }
        for (const element of document.head.children) {
            // const element = document.head.children[i];
            if (element instanceof HTMLStyleElement || element instanceof HTMLLinkElement) {
                const node = element.cloneNode(true);
                assert(node instanceof HTMLElement);
                node.dataset.cloned = "cloned";
                shadowRoot.appendChild(node);
            }
        }
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "math-editor-web-component": MathEditorPluginWebComponent;
    }

    namespace JSX {
        interface IntrinsicElements {
            "math-editor-web-component": React.DetailedHTMLProps<
                React.HTMLAttributes<MathEditorPluginWebComponent>,
                MathEditorPluginWebComponent
            >;
        }
    }
}
