import { action, makeObservable } from "mobx";

import { getLineAngle, getMiddleAngle, normalizeAngle } from "@viuch/geometry-lib/angles";
import { areSameFragments, areSamePoints } from "@viuch/geometry-lib/check-geometry";
import { getPointHash } from "@viuch/geometry-lib/hashing";
import { intersectionFigures } from "@viuch/geometry-lib/intersection";
import { isZeroLengthMathExpr } from "@viuch/math-editor";
import { createSortDescBy, sortAsc } from "@viuch/shared/utils/data";
import { createPairs } from "@viuch/shared/utils/data/createPairs";
import { filterEqualObjects } from "@viuch/shared/utils/data/filter";
import { assert } from "@viuch/utils/debug";

import type { Figure2DController } from "../../Figure2DController";
import type { BaseModel } from "../../models";
import type { TFragment, TPoint } from "@viuch/geometry-lib/types";

import { ComputedIntersectionPoints } from "../../models/computed";
import { EqualSegmentsModel } from "../../models/constraints";
import { MedianModel } from "../../models/geometry";
import { getAllModelPoints } from "../../utils/visible-points";
import { checkModelType, ModelTypes } from "../actions/utils";

import {
    getEqualFragmentsVisitor,
    getFragmentsWithAngles,
    equalPositionHashVisitor,
    intersectionFragmentsAndLinesVisitor,
    getAllFragmentAngleValuesAtPoint,
} from "./utils";

export class ModelNormalizer {
    private readonly data: Figure2DController;

    constructor(data: Figure2DController) {
        this.data = data;

        makeObservable(this);
    }

    @action.bound
    normalizeModel() {
        this.restoreComputedModels();
        this.dropInvalidElements();
        this.mergeEqualElements();
        this.computeIntersectionPoints();
        this.normalizeAngles();
        this.updateModels();
        this.dropUnboundPointLabels();
        this.mergeEqualSegments();
    }

    get figure() {
        return this.data.figure;
    }

    private mergeEqualElements() {
        const hashes = new Set<string>();

        this.figure.models.slice().forEach((model) => {
            const hash = model.accept(equalPositionHashVisitor);
            if (hashes.has(hash)) {
                this.dropModel(model);
            } else {
                hashes.add(hash);
            }
        });
    }

    private dropInvalidElements() {
        [...this.figure.models].forEach((model) => {
            if (checkModelType(model, ModelTypes.fragment)) {
                if (areSamePoints(model.a, model.b)) {
                    this.dropModel(model);
                }
            }
            if (checkModelType(model, ModelTypes.labelFragment)) {
                if (areSamePoints(model.a, model.b)) {
                    this.dropModel(model);
                }
            }
            if (checkModelType(model, ModelTypes.labelPoint)) {
                if (isZeroLengthMathExpr(model.label)) {
                    this.dropModel(model);
                }
            }
        });
    }

    private dropModel(model: BaseModel) {
        const i = this.figure.models.indexOf(model);
        assert(i !== -1);
        this.figure.models.splice(i, 1);
    }

    private normalizeAngles() {
        const allFragments = getFragmentsWithAngles(this.figure.models);

        this.figure.models.forEach((model) => {
            if (checkModelType(model, ModelTypes.labelFragment)) {
                const {
                    a: { x: x1, y: y1 },
                    b: { x: x2, y: y2 },
                } = model;

                const angle = getLineAngle({ x1, y1, x2, y2 }, true);
                const is = normalizeAngle(angle + Math.PI / 2) > Math.PI;

                const rotationAngle = is //
                    ? angle + Math.PI
                    : angle;

                model.rotationAngle = rotationAngle;
            }

            if (checkModelType(model, ModelTypes.labelPoint)) {
                const angles = this.figure.models
                    .map((m) => getAllFragmentAngleValuesAtPoint(model, m))
                    .flat()
                    .sort(sortAsc);

                const pairs = createPairs(angles);

                if (pairs.length > 0) {
                    pairs[pairs.length - 1].next += Math.PI * 2;

                    const pair = pairs
                        .map((p) => ({ ...p, angleDiff: p.next - p.current }))
                        .sort(createSortDescBy((f) => f.angleDiff))
                        .at(0);

                    if (pair) {
                        model.directionAngle = Math.PI + getMiddleAngle(pair.current, pair.next);
                    }
                }
            }
        });
    }

    private computeIntersectionPoints() {
        const model = this.figure.models.find((model): model is ComputedIntersectionPoints =>
            checkModelType(model, ModelTypes.computedIntersectionPoints)
        );
        if (!model) return;

        const allPoints: TPoint[] = [];

        this.figure.models.forEach((modelA): void => {
            const fragmentsOrLinesA = modelA.accept(intersectionFragmentsAndLinesVisitor);
            if (fragmentsOrLinesA.length === 0) return;

            this.figure.models.forEach((modelB): void => {
                if (modelA === modelB) return;

                const fragmentsOrLinesB = modelB.accept(intersectionFragmentsAndLinesVisitor);
                if (fragmentsOrLinesB.length === 0) return;

                fragmentsOrLinesA.forEach((fol1) =>
                    fragmentsOrLinesB.forEach((fol2) => {
                        allPoints.push(...intersectionFigures(fol1, fol2));
                    })
                );
            });
        });

        model.points = Array.from(filterEqualObjects(allPoints, getPointHash));
    }

    private restoreComputedModels() {
        const hasComputedIntersectionPointsModel = this.figure.models.some((model) =>
            checkModelType(model, ModelTypes.computedIntersectionPoints)
        );

        if (!hasComputedIntersectionPointsModel) {
            this.figure.addModel(ComputedIntersectionPoints.create());
        }
    }

    private updateModels() {
        for (const model of this.figure.models) {
            model.update();
        }
    }

    /**
     * Удаляет подписи с несуществующих точек.
     *
     * Подписи на виртуальных точках остаются. Новые подписи НЕ создаются.
     */
    private dropUnboundPointLabels() {
        const allPoints = this.figure.models.flatMap(getAllModelPoints);

        for (const model of [...this.figure.models]) {
            if (checkModelType(model, ModelTypes.labelPoint)) {
                if (!allPoints.some((point) => areSamePoints(point, model))) {
                    this.figure.removeModel(model);
                }
            }
        }
    }

    private mergeEqualSegments() {
        type TModel = { segmentsCount: number; setSegmentsCount(count: number): void } & BaseModel;
        type TItem = { model: TModel; fragments: TFragment[] };

        const items: TItem[] = [];

        for (const model of this.figure.models) {
            if (model instanceof EqualSegmentsModel || model instanceof MedianModel) {
                items.push({
                    model,
                    fragments: model.accept(getEqualFragmentsVisitor),
                });
            }
        }

        type TCacheItem = { models: TModel[]; fragments: TFragment[] };
        const cache = new Map<number, TCacheItem>();

        function hasEqualFragments(left: TFragment[], right: TFragment[]): boolean {
            for (const a of left) {
                for (const b of right) {
                    if (areSameFragments(a, b)) return true;
                }
            }
            return false;
        }

        for (const { model, fragments } of items) {
            const newCacheItem: TCacheItem = {
                models: [model],
                fragments: [...fragments],
            };
            const keysExtracted: number[] = [model.segmentsCount];

            for (const [key, data] of [...cache.entries()]) {
                if (hasEqualFragments(fragments, data.fragments)) {
                    newCacheItem.models.push(...data.models);
                    newCacheItem.fragments.push(...data.fragments);
                    keysExtracted.push(key);
                    cache.delete(key);
                }
            }

            const targetKey = Math.min(...keysExtracted);
            cache.set(targetKey, newCacheItem);
            for (const model of newCacheItem.models) {
                model.setSegmentsCount(targetKey);
            }
        }
    }
}
