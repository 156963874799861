import { computed, makeObservable, observable } from "mobx";
import { createTransformer } from "mobx-utils";

import { areSameFragments, areSamePoints } from "@viuch/geometry-lib/check-geometry";
import { filterSameFragments } from "@viuch/geometry-lib/filters";

import type { BaseElement } from "../../elements";
import type { Figure2DController } from "../../Figure2DController";
import type { BaseModel } from "../../models";
import type { ToolbarMenu } from "../../toolbar";
import type { IUserPointer } from "../../viewport/types";
import type { TFragment, TPoint } from "@viuch/geometry-lib/types";
import type React from "react";

import { DotElement } from "../../elements/dot";
import { ElementColor } from "../../elements/ElementColor";
import { LineElement } from "../../elements/line";
import { StrokeElement } from "../../elements/stroke";
import { EqualSegmentsModel } from "../../models/constraints";
import { LabelPointModel } from "../../models/label-point";
import { PointModel } from "../../models/point";
import { checkModelType, ModelTypes } from "../../services/actions/utils";
import { ButtonStates } from "../../toolbar";
import { TooltipMenu } from "../../toolbar/tooltip";
import { getNewEqualSegmentsStrokesCount } from "../../utils/strokes-count";
import { createDefaultToolbarMenuBuilder } from "../../utils/toolbar";
import { BaseFlow } from "../BaseFlow";
import { createMapModelToElements, opacifyColor } from "../utils";

type TEqualFragmentsFlowStages = { stage: 1; a?: TPoint } | { stage: 2; a: TPoint; b?: TPoint };

export class EqualSegmentsFlow extends BaseFlow {
    flow: {
        segments: TFragment[];
        current: TEqualFragmentsFlowStages;
    };

    constructor(data: Figure2DController) {
        super(data);

        this.flow = {
            segments: [],
            current: { stage: 1 },
        };

        makeObservable(this, {
            flow: observable,
            readySegments: computed,
            tempCurrentSegmentElements: computed,
        });
    }

    static create(data: Figure2DController) {
        return new EqualSegmentsFlow(data);
    }

    attach(): void {
        this.snap.addFigureModelsPoints();
        this.viewport.disable();
    }

    dispose(): void {
        this.snap.clean();
    }

    override handleViewportPointerEvent(e: React.PointerEvent, pointer: IUserPointer): void {
        if (!e.isPrimary) {
            return;
        }
        if (e.type === "pointercancel") {
            return this.nextFlow();
        }

        const snapPoint = this.snap.snap(pointer.canvas);
        if (snapPoint) {
            this.updateCurrentPointPosition(e, snapPoint);
            this.updateStages(e, snapPoint);
        } else {
            this.handleNotPoint(e);
        }
    }

    private updateCurrentPointPosition(e: React.PointerEvent, point: TPoint) {
        if (e.type === "pointerdown" || e.type === "pointermove") {
            switch (this.flow.current.stage) {
                case 1:
                    this.flow.current.a = point;
                    break;
                case 2:
                    this.flow.current.b = point;
                    break;
            }
        }
    }

    private updateStages(e: React.PointerEvent, point: TPoint) {
        if (e.type !== "pointerup") return;

        switch (this.flow.current.stage) {
            case 1:
                this.flow.current = { stage: 2, a: point };
                break;
            case 2: {
                const { a, b = point } = this.flow.current;
                this.flow.segments.push({ a, b });
                this.flow.current = { stage: 1 };
                break;
            }
        }
    }

    private handleNotPoint(e: React.PointerEvent) {
        if (e.type !== "pointerdown") return;

        const { segments } = this.flow;
        if (segments.length < 2) {
            return this.nextFlow();
        }

        const segmentsToMerge: EqualSegmentsModel[] & BaseModel[] = this.findInvolvedEqualSegmentsModels(segments);
        const segmentsFromOtherGroups = segmentsToMerge.flatMap(({ segments }) => segments);
        const segmentsCount = getNewEqualSegmentsStrokesCount(this.figure);

        this.figure.filterModels((model) => !segmentsToMerge.includes(model));
        this.figure.insertModels(function* () {
            yield EqualSegmentsModel.create({
                segments: filterSameFragments([...segments, ...segmentsFromOtherGroups]),
                segmentsCount,
                style: null,
            });

            for (const { a, b } of segments) {
                yield PointModel.create({ ...a, style: null });
                yield PointModel.create({ ...b, style: null });
            }

            for (const { a, b } of segments) {
                yield LabelPointModel.createNext(a, this.figure);
                yield LabelPointModel.createNext(b, this.figure);
            }
        }, this);

        this.nextFlow();
    }

    private findInvolvedEqualSegmentsModels(segments: TFragment[]): EqualSegmentsModel[] {
        return this.figure.models.filter((model): model is EqualSegmentsModel => {
            if (!checkModelType(model, ModelTypes.equalSegments)) return false;

            for (const segment1 of segments) {
                for (const segment2 of model.segments) {
                    if (areSameFragments(segment1, segment2)) {
                        return true;
                    }
                }
            }
            return false;
        });
    }

    private render = createTransformer(createMapModelToElements(opacifyColor));

    protected renderElements(): BaseElement[] {
        return [...this.figure.models.flatMap(this.render), ...this.readySegments, ...this.tempCurrentSegmentElements];
    }

    get readySegments(): BaseElement[] {
        const color = ElementColor.Building;
        const overrideRenderOrder = 0;

        return this.flow.segments.flatMap(({ a, b }, i) => [
            new DotElement({
                id: `temp__${i}_a`,
                x: a.x,
                y: a.y,
                color,
                overrideRenderOrder,
            }),
            new DotElement({
                id: `temp__${i}_b`,
                x: b.x,
                y: b.y,
                color,
                overrideRenderOrder,
            }),
            new LineElement({
                id: `temp__${i}_line`,
                x1: a.x,
                y1: a.y,
                x2: b.x,
                y2: b.y,
                color,
                overrideRenderOrder,
            }),
            new StrokeElement({
                id: `temp__${i}_stroke`,
                a,
                b,
                segments: 2,
                color,
                overrideRenderOrder,
            }),
        ]);
    }

    get tempCurrentSegmentElements(): BaseElement[] {
        const { current } = this.flow;
        const color = ElementColor.Building;
        const currentColor = ElementColor.Selected;
        const overrideRenderOrder = 0;

        if (current.stage === 1) {
            if (!current.a) return [];

            return [
                new DotElement({
                    id: "temp__a",
                    x: current.a.x,
                    y: current.a.y,
                    color: currentColor,
                    overrideRenderOrder,
                }),
            ];
        }
        if (current.stage === 2) {
            if (!current.b || areSamePoints(current.a, current.b)) {
                return [
                    new DotElement({
                        id: "temp__a",
                        x: current.a.x,
                        y: current.a.y,
                        color,
                        overrideRenderOrder,
                    }),
                ];
            }
            const { a, b } = current;
            return [
                new DotElement({
                    id: "temp__a",
                    x: a.x,
                    y: a.y,
                    color,
                    overrideRenderOrder,
                }),
                new DotElement({
                    id: "temp__b",
                    x: b.x,
                    y: b.y,
                    color: currentColor,
                    overrideRenderOrder,
                }),
                new LineElement({
                    id: "temp__ab",
                    overrideRenderOrder,
                    x1: a.x,
                    y1: a.y,
                    x2: b.x,
                    y2: b.y,
                    color: currentColor,
                }),
                new StrokeElement({
                    id: "temp__stroke",
                    a,
                    b,
                    segments: 2,
                    color,
                    overrideRenderOrder,
                }),
            ];
        }
        return [];
    }

    override getToolbarMenu(): ToolbarMenu {
        return createDefaultToolbarMenuBuilder()
            .setButtonState(ButtonStates.active, ["labels"])
            .setButtonIcon("equal-segments", ["labels"])
            .build();
    }

    override getTooltipMenu(): TooltipMenu | null {
        let text: string;
        const {
            flow: {
                segments: { length },
                current: { stage },
            },
        } = this;

        switch (stage) {
            case 1:
                switch (length) {
                    case 0:
                        text = "Выберите один конец первого отрезка";
                        break;
                    case 1:
                        text = "Выберите один конец второго отрезка";
                        break;
                    default:
                        text = "Выберите один конец отрезка, или нажмите в пустую область, чтобы закончить действие";
                        break;
                }
                break;
            case 2:
                switch (length) {
                    case 0:
                        text = "Выберите другой конец первого отрезка";
                        break;
                    case 1:
                        text = "Выберите другой конец второго отрезка";
                        break;
                    default:
                        text = "Выберите другой конец отрезка, или нажмите в пустую область, чтобы закончить действие";
                        break;
                }
                break;
        }

        return new TooltipMenu(text);
    }
}
