import {
    copyAngle,
    copyBasis,
    copyEllipse,
    copyFragment,
    copyPoint,
    createFragment,
    createLine,
    lineToFragment,
} from "@viuch/geometry-lib/factories";

import type { TSnapshotModel } from "./types";
import type { BaseModel } from "../models";
import type { IModelVisitor } from "../models/BaseModel";

import { CircumscribedCircleModel, EllipseModel, InscribedCircleModel } from "../models/circle";
import { EqualAnglesModel, EqualSegmentsModel } from "../models/constraints";
import {
    ConstraintLineModel,
    FragmentDashedModel,
    FragmentModel,
    LineModel,
    ParallelFragmentModel,
    VectorDashedModel,
    VectorModel,
} from "../models/fragment";
import { AltitudeModel, BisectionModel, MedianModel } from "../models/geometry";
import { MiddleLineModel } from "../models/geometry/MiddleLineModel";
import { LabelAngleModel } from "../models/label-angle";
import { LabelFragmentModel } from "../models/label-fragment";
import { LabelPointModel } from "../models/label-point";
import { PointModel } from "../models/point";
import { TangentLineToCircleModel, TangentLineOnCircleModel } from "../models/tangent";
import { getModelType, ModelTypes } from "../services/actions/utils";

export function createModelSnapshot(model: BaseModel): TSnapshotModel | null {
    return model.accept(serializeModelVisitor);
}

const serializeModelVisitor: IModelVisitor<TSnapshotModel | null> = {
    withPoint: (point) => ({
        type: getModelType(point),
        point: copyPoint(point),
        style: point.style,
    }),
    withFragment: (fragment) => ({
        type: getModelType(fragment),
        fragment: copyFragment(fragment),
        style: fragment.style,
    }),
    withParallelFragment: (fragment) => ({
        type: getModelType(fragment),
        base: copyFragment(fragment.base),
        fragment: copyFragment(fragment),
    }),
    withLabelPoint: (label) => ({
        type: getModelType(label),
        point: copyPoint(label),
        label: label.label,
        style: label.style,
    }),
    withLabelFragment: (label) => ({
        type: getModelType(label),
        fragment: copyFragment(label),
        label: label.value,
        altOrigin: label.altOrigin,
        style: label.style,
    }),
    withLabelAngle: (label) => ({
        type: getModelType(label),
        angle: copyAngle(label),
        label: label.value,
        preferRightAngle: label.preferRightAngle,
        style: label.style,
    }),
    withLine: (line) => ({
        type: getModelType(line),
        line: createLine(line),
        style: line.style,
    }),
    withConstraintLine: (line) => ({
        type: getModelType(line),
        vertex: copyPoint(line.vertex),
        baseFragment: createFragment(line.constraintA, line.constraintB),
        angle: line.offsetAngle,
    }),
    withComputedIntersectionPoints: () => null,
    withBisection: (bisection) => ({
        type: getModelType(bisection),
        angle: copyAngle(bisection.angle),
        basis: copyBasis(bisection.basis),
        segmentsCount: bisection.segmentsCount,
        style: bisection.style,
    }),
    withMedian: (median) => ({
        type: getModelType(median),
        vertex: copyPoint(median.vertex),
        fragment: copyFragment(median.fragment),
        segmentsCount: median.segmentsCount,
        style: median.style,
    }),
    withAltitude: (altitude) => ({
        type: getModelType(altitude),
        vertex: copyPoint(altitude.vertex),
        basis: copyBasis(altitude.basis),
        style: altitude.style,
    }),
    withEqualAngles: (equalAngles) => ({
        type: getModelType(equalAngles),
        angles: equalAngles.angles.map(copyAngle),
        segmentsCount: equalAngles.segmentsCount,
        style: equalAngles.style,
    }),
    withEqualSegments: (equalSegments) => ({
        type: getModelType(equalSegments),
        segments: equalSegments.segments.map(copyFragment),
        segmentsCount: equalSegments.segmentsCount,
        style: equalSegments.style,
    }),
    withEllipse: (ellipse) => ({
        type: getModelType(ellipse),
        center: copyPoint(ellipse.center),
        rx: ellipse.rx,
        ry: ellipse.ry,
        style: ellipse.style,
    }),
    withInscribedCircle: (circle) => ({
        type: getModelType(circle),
        shape: circle.fragmentsPoints.map(copyPoint),
        style: circle.style,
    }),
    withCircumscribedCircle: (circle) => ({
        type: getModelType(circle),
        shape: circle.points.map(copyPoint),
        style: circle.style,
    }),
    withTangentLine: (tangent) => ({
        type: getModelType(tangent),
        point: copyPoint(tangent.point),
        circle: copyEllipse(tangent.circle),
        forwardDirection: tangent.forwardDirection,
    }),
    withMiddleLine: (middleLine) => ({
        type: getModelType(middleLine),
        base1: copyFragment(middleLine.base1),
        base2: copyFragment(middleLine.base2),
    }),
    withTangentLineOnCircle: (tangent) => ({
        type: getModelType(tangent),
        touchPoint: copyPoint(tangent.touchPoint),
        ellipse: copyEllipse(tangent.ellipse),
    }),
    withVector: (vector) => ({
        type: getModelType(vector),
        vector: vector.toVector(),
        style: vector.style,
    }),
    withVectorDashed: (vector) => ({
        type: getModelType(vector),
        vector: vector.toVector(),
        style: vector.style,
    }),
    withFragmentDashed: (fragment) => ({
        type: getModelType(fragment),
        fragment: fragment.toFragment(),
        style: fragment.style,
    }),
};

export function mapSnapshotToModel(snapshot: TSnapshotModel): BaseModel {
    switch (snapshot.type) {
        case ModelTypes.point:
            return PointModel.create({ ...snapshot.point, style: snapshot.style });
        case ModelTypes.fragment:
            return FragmentModel.create({ ...snapshot.fragment, style: snapshot.style });
        case ModelTypes.fragmentDashed:
            return FragmentDashedModel.create({ ...snapshot.fragment, style: snapshot.style });
        case ModelTypes.vector:
            return VectorModel.create({ ...snapshot.vector, style: snapshot.style });
        case ModelTypes.vectorDashed:
            return VectorDashedModel.create({ ...snapshot.vector, style: snapshot.style });
        case ModelTypes.parallelFragment: {
            const {
                base,
                fragment: { a, b },
            } = snapshot;
            return ParallelFragmentModel.create({ a, b, base });
        }
        case ModelTypes.labelPoint: {
            const {
                point: { x, y },
                label,
                style,
            } = snapshot;
            return LabelPointModel.create({ x, y, label, style });
        }
        case ModelTypes.labelFragment: {
            const {
                fragment: { a, b },
                label: value,
                altOrigin,
                style,
            } = snapshot;
            return LabelFragmentModel.create({ a, b, value, altOrigin, style });
        }
        case ModelTypes.labelAngle: {
            const {
                angle: { start, vertex, end },
                label: value,
                preferRightAngle,
                style,
            } = snapshot;
            return LabelAngleModel.create({ start, vertex, end, value, preferRightAngle, style });
        }
        case ModelTypes.line:
            return LineModel.create({ ...lineToFragment(snapshot.line), style: snapshot.style });
        case ModelTypes.constraintLine: {
            const {
                vertex,
                baseFragment: { a: constraintA, b: constraintB },
                angle: offsetAngle,
            } = snapshot;
            return ConstraintLineModel.create({
                offsetAngle,
                vertex,
                constraintA,
                constraintB,
            });
        }
        case ModelTypes.bisection:
            return BisectionModel.create(snapshot);
        case ModelTypes.median:
            return MedianModel.create(snapshot);
        case ModelTypes.altitude:
            return AltitudeModel.create(snapshot);
        case ModelTypes.equalSegments:
            return EqualSegmentsModel.create(snapshot);
        case ModelTypes.equalAngles:
            return EqualAnglesModel.create(snapshot);
        case ModelTypes.ellipse:
            return EllipseModel.create(snapshot);
        case ModelTypes.inscribedCircle:
            return InscribedCircleModel.create({ fragmentsPoints: snapshot.shape, style: snapshot.style });
        case ModelTypes.circumscribedCircle:
            return CircumscribedCircleModel.create({ points: snapshot.shape, style: snapshot.style });
        case ModelTypes.tangentToCircle:
            return TangentLineToCircleModel.create(snapshot);
        case ModelTypes.middleLine:
            return MiddleLineModel.create(snapshot);
        case ModelTypes.tangentOnCircle:
            return TangentLineOnCircleModel.create(snapshot);
    }
}
