import { action, computed, makeObservable, observable } from "mobx";

import type { TAccess } from "./AccessProvider";
import type { TRawUserInfo } from "../services/auth/types";

import { removeAccessToken, removeRefreshToken } from "../services/auth/utils";

import { createAccess } from "./AccessProvider";

export class AuthStore {
    @observable isAuthorized = false;
    @observable user?: TRawUserInfo;

    constructor() {
        makeObservable(this);
    }

    @action.bound
    authorize(userInfo: TRawUserInfo) {
        this.isAuthorized = true;
        this.user = userInfo;
    }

    @action.bound
    logout() {
        removeAccessToken();
        removeRefreshToken();

        window.location.href = "/login";
    }

    @computed
    get accessProvider(): TAccess {
        return createAccess(this);
    }
}
