import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

import { computeDurationSeconds, expandDuration } from "@viuch/shared/serialization/durations";
import { formatTime, parseTime } from "@viuch/shared/serialization/times";
import { renderDaysRuWord } from "@viuch/shared/utils/localization";

import type { ChangeEvent } from "react";

import { BaseInput } from "./BaseInput";
import { NumberInput } from "./NumberInput";

import styles from "./DurationInput.module.scss";

type Props = {
    value: number;
    onChange(value: number): void;
    className?: string;
    title?: string;
    error?: string;
    inline?: boolean;
    disabled?: boolean;
    onlyTime?: boolean;
};

export const DurationInput = observer(function DurationInput({
    value,
    onChange,
    className,
    inline,
    disabled,
    title,
    error,
    onlyTime,
}: Props) {
    const { days, hours, minutes, seconds } = useMemo(() => expandDuration(value), [value]);

    const setDays = (newDays: number) => {
        onChange(computeDurationSeconds(newDays, hours, minutes, seconds));
    };

    const setTime = (e: ChangeEvent<HTMLInputElement>) => {
        const { hours, minutes, seconds } = parseTime(e.target.value);
        const _days = onlyTime ? 0 : days;

        onChange(computeDurationSeconds(_days, hours, minutes, seconds));
    };

    return (
        <BaseInput
            title={title}
            className={className}
            error={error}
            inline={inline}
        >
            <div className={styles.wrapper}>
                {!onlyTime && (
                    <>
                        <NumberInput
                            value={days}
                            onChange={setDays}
                            className={styles.daysInput}
                            disabled={disabled}
                        />
                        <span className={styles.text}>{renderDaysRuWord(days)} и</span>
                    </>
                )}
                <input
                    type="time"
                    value={formatTime("hh:mm:ss", hours, minutes, seconds)}
                    onChange={setTime}
                    disabled={disabled}
                />
            </div>
        </BaseInput>
    );
});
