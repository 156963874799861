import { generateId } from "@viuch/shared/utils/data";

import type { Figure2DSettings } from "./Figure2DSettings";
import type { TSerializedState } from "@viuch/math-editor";

type TFigure2DSettingsCompletion = {
    state: Figure2DSettings;
    hint: TSerializedState;
};

export class Figure2DSettingsCompletion implements TFigure2DSettingsCompletion {
    readonly $$instanceId = generateId();

    state: Figure2DSettings;
    hint: TSerializedState;

    constructor(data: TFigure2DSettingsCompletion) {
        this.hint = data.hint;
        this.state = data.state.clone();
    }

    clone() {
        return new Figure2DSettingsCompletion({
            hint: this.hint,
            state: this.state.clone(),
        });
    }
}
