import { useEffect, useRef } from "react";

import type { TopAngleModel } from "./TopAngleModel";
import type { TElementProps } from "../../types/element";
import type { VFC } from "react";

import { ContainerElement } from "../../core/container";
import { useMathEditorElementRef } from "../../hooks";

import * as utils from "./TopAngleElement.utils";

import styles from "./TopAngleElement.module.scss";

export const TopAngleElement: VFC<TElementProps<TopAngleModel>> = ({ elementModel }) => {
    const wrapperRef = useMathEditorElementRef<HTMLSpanElement>(elementModel);
    const leftLineRef = useRef<SVGPathElement>(null);
    const rightLineRef = useRef<SVGPathElement>(null);
    const svgRef = useRef<SVGSVGElement>(null);

    useEffect(() => {
        const leftLine = leftLineRef.current!;
        const rightLine = rightLineRef.current!;
        const wrapper = wrapperRef.current!;
        const svg = svgRef.current!;

        const observer = new ResizeObserver(() => {
            utils.resizeLines(leftLine, rightLine, wrapper.clientWidth, svg.clientHeight);
        });

        observer.observe(wrapper);

        return () => {
            observer.disconnect();
        };
    }, [wrapperRef]);

    return (
        <span
            ref={wrapperRef}
            className={styles.wrapper}
        >
            <svg
                width="1em"
                height="0.5em"
                ref={svgRef}
                className={styles.icon}
            >
                <path
                    stroke="currentColor"
                    strokeWidth="0.1em"
                    ref={leftLineRef}
                />
                <path
                    stroke="currentColor"
                    strokeWidth="0.1em"
                    ref={rightLineRef}
                />
            </svg>

            <ContainerElement
                containerModel={elementModel.content}
                inline
            />
        </span>
    );
};
