import type { BaseElementModel } from "./BaseElementModel";
import type { IElementVisitor } from "./IElementVisitor";

export abstract class BaseDefaultElementVisitor<R> implements IElementVisitor<R> {
    protected abstract defaultBehaviour(element: BaseElementModel): R;

    doWithAffiliation(element: BaseElementModel): R {
        return this.defaultBehaviour(element);
    }

    doWithAngle(element: BaseElementModel): R {
        return this.defaultBehaviour(element);
    }

    doWithBracket(element: BaseElementModel): R {
        return this.defaultBehaviour(element);
    }

    doWithChar(element: BaseElementModel): R {
        return this.defaultBehaviour(element);
    }

    doWithComplexIntegral(element: BaseElementModel): R {
        return this.defaultBehaviour(element);
    }

    doWithCoordinatesVector(element: BaseElementModel): R {
        return this.defaultBehaviour(element);
    }

    doWithDegree(element: BaseElementModel): R {
        return this.defaultBehaviour(element);
    }

    doWithDerivative(element: BaseElementModel): R {
        return this.defaultBehaviour(element);
    }

    doWithDifferential(element: BaseElementModel): R {
        return this.defaultBehaviour(element);
    }

    doWithDigit(element: BaseElementModel): R {
        return this.defaultBehaviour(element);
    }

    doWithDownIndex(element: BaseElementModel): R {
        return this.defaultBehaviour(element);
    }

    doWithEditor(element: BaseElementModel): R {
        return this.defaultBehaviour(element);
    }

    doWithEquality(element: BaseElementModel): R {
        return this.defaultBehaviour(element);
    }

    doWithEquationsSet(element: BaseElementModel): R {
        return this.defaultBehaviour(element);
    }

    doWithFraction(element: BaseElementModel): R {
        return this.defaultBehaviour(element);
    }

    doWithFunction(element: BaseElementModel): R {
        return this.defaultBehaviour(element);
    }

    doWithGeometry(element: BaseElementModel): R {
        return this.defaultBehaviour(element);
    }

    doWithGrade(element: BaseElementModel): R {
        return this.defaultBehaviour(element);
    }

    doWithIntegral(element: BaseElementModel): R {
        return this.defaultBehaviour(element);
    }

    doWithLim(element: BaseElementModel): R {
        return this.defaultBehaviour(element);
    }

    doWithLinebreak(element: BaseElementModel): R {
        return this.defaultBehaviour(element);
    }

    doWithLog(element: BaseElementModel): R {
        return this.defaultBehaviour(element);
    }

    doWithModule(element: BaseElementModel): R {
        return this.defaultBehaviour(element);
    }

    doWithMultitude(element: BaseElementModel): R {
        return this.defaultBehaviour(element);
    }

    doWithNaturalLog(element: BaseElementModel): R {
        return this.defaultBehaviour(element);
    }

    doWithOperatorHigh(element: BaseElementModel): R {
        return this.defaultBehaviour(element);
    }

    doWithOperatorLow(element: BaseElementModel): R {
        return this.defaultBehaviour(element);
    }

    doWithResult(element: BaseElementModel): R {
        return this.defaultBehaviour(element);
    }

    doWithRoot(element: BaseElementModel): R {
        return this.defaultBehaviour(element);
    }

    doWithSlash(element: BaseElementModel): R {
        return this.defaultBehaviour(element);
    }

    doWithSpace(element: BaseElementModel): R {
        return this.defaultBehaviour(element);
    }

    doWithSymbol(element: BaseElementModel): R {
        return this.defaultBehaviour(element);
    }

    doWithTopAngle(element: BaseElementModel): R {
        return this.defaultBehaviour(element);
    }

    doWithTrigonometricOperator(element: BaseElementModel): R {
        return this.defaultBehaviour(element);
    }

    doWithVector(element: BaseElementModel): R {
        return this.defaultBehaviour(element);
    }
}
