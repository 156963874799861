import cn from "classnames";
import { observer } from "mobx-react-lite";
import React from "react";

import type { FC } from "react";

import styles from "./ErrorInputLabel.module.scss";

type Props = {
    error?: string;
    className?: string;
};

export const ErrorInputLabel: FC<Props> = observer(function ErrorInputLabel({ className, error }) {
    return <>{error && <span className={cn(className, styles.error)}>{String(error)}</span>}</>;
});
