import { observer } from "mobx-react-lite";
import React from "react";

import { SelectInput } from "@viuch/ui-common";
import { BaseInput } from "@viuch/ui-common/BaseInput";

import type { FC } from "react";

import styles from "./LayerSelect.module.scss";

type Props = {
    value: string;
    layers: { value: string; label: string }[];
    completionsCount: number;
    onAdd(after: string): void;
    onDelete(value: string): void;
    onChange(value: string): void;
    deleteDisabled?: boolean;
};

export const LayerSelect: FC<Props> = observer(function CompletionSelect({
    layers,
    value,
    completionsCount,
    deleteDisabled,
    onAdd,
    onDelete,
    onChange,
}) {
    return (
        <div className={styles.container}>
            <BaseInput title={`Текущее достроение (всего достроений: ${completionsCount})`}>
                <div className={styles.row}>
                    <SelectInput
                        value={value}
                        data={layers}
                        onChange={onChange}
                        className={styles.field}
                    />

                    <button
                        className={styles.deleteButton}
                        disabled={deleteDisabled}
                        onClick={() => onDelete(value)}
                    >
                        <span
                            className={styles.deleteButton__icon}
                            title="Удалить текущее достроение"
                        />
                    </button>
                </div>
            </BaseInput>

            <button onClick={() => onAdd(value)}>+ Добавить достроение (после текущего)</button>
        </div>
    );
});
