import { copyPoint } from "@viuch/geometry-lib/factories";

import type { ISelectionElementVisitor } from "../BaseSelection";
import type { TEllipse, TPoint } from "@viuch/geometry-lib/types";

import { BaseSelection } from "../BaseSelection";

export class EllipseSelection extends BaseSelection implements TEllipse {
    readonly center: Readonly<TPoint>;
    readonly rx: number;
    readonly ry: number;

    constructor(center: TPoint, rx: number, ry: number) {
        super();
        this.center = copyPoint(center);
        this.rx = rx;
        this.ry = ry;
    }

    accept<R>(visitor: ISelectionElementVisitor<R>): R {
        return visitor.withEllipse(this);
    }
}
