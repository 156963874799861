import type { ISerializedNaturalLog } from "./types";
import type { TSerializedElementPrototype } from "../../core/element";
import type { IElementVisitor } from "../../core/element/IElementVisitor";
import type { IStrategy } from "../../core/strategies";
import type { IMultiContainerElement } from "../../core/strategies/types";
import type { InputService } from "../../services";
import type { TContainerElements, TElementDeserializerFunction } from "../../types";

import { ContainerModel } from "../../core/container";
import { BaseElementModel } from "../../core/element";
import { MultiContainerElementStrategy } from "../../core/strategies/MultiContainerElementStrategy";

export class NaturalLogModel extends BaseElementModel<ISerializedNaturalLog> implements IMultiContainerElement {
    public readonly content: ContainerModel;
    public readonly degree: ContainerModel;

    public constructor(
        inputService: InputService,
        content: TContainerElements,
        degree: TContainerElements,
        uuid?: string
    ) {
        super(inputService, uuid);

        this.content = new ContainerModel(content, this, ["content"], inputService, {
            showPlaceholderIfEmpty: true,
        });
        this.degree = new ContainerModel(degree, this, ["degree"], inputService);
    }

    public serialize(): ISerializedNaturalLog {
        return {
            type: "natural-log",
            uuid: this.uuid,
            content: this.content.serialize(),
            degree: this.degree.serialize(),
        };
    }

    public static deserialize: TElementDeserializerFunction<ISerializedNaturalLog> = (
        { inputService },
        { uuid, content, degree }
    ) =>
        new NaturalLogModel(
            inputService,
            inputService.deserializeContainer(content),
            inputService.deserializeContainer(degree),
            uuid
        );

    public getContainersToMoveCursorBetween(): ContainerModel[] {
        return [this.degree, this.content];
    }

    public accept<R>(visitor: IElementVisitor<R>): R {
        return visitor.doWithNaturalLog(this);
    }

    serializeAsClone(): TSerializedElementPrototype<ISerializedNaturalLog> {
        const { uuid, ...clone } = this.serialize();
        return {
            ...clone,
            content: this.content.serializeAsClone(),
            degree: this.degree.serializeAsClone(),
        };
    }

    overridePrimaryContainer(): ContainerModel {
        return this.content;
    }

    override getWrapperContainer(): ContainerModel | null {
        return this.content;
    }

    protected override initBehaviour(): IStrategy {
        return new MultiContainerElementStrategy(this);
    }
}
