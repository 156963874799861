import type { TEqualitySymbols } from "./EqualityElement.types";

import styles from "./EqualityElement.module.scss";

export const equalitySymbols: TEqualitySymbols = {
    equals: { char: "=" },
    "not-equals": { char: "=", className: styles.notEquals },
    greater: { char: ">" },
    less: { char: "<" },
    "greater-equals": { char: ">", className: styles.withEquals },
    "less-equals": { char: "<", className: styles.withEquals },
    "approx-equals": { char: "≈", className: styles.approxEquals },
};
