export function compareNumbers(left: number, right: number): number {
    return left - right;
}

export function compareSimple<T>(left: T, right: T): boolean {
    return Object.is(left, right);
}

export function compareArrays<T>(left: T[], right: T[], comparer: (left: T, right: T) => boolean = compareSimple) {
    if (left.length !== right.length) return false;

    for (let i = 0; i < left.length; i++) {
        if (!comparer(left[i], right[i])) {
            return false;
        }
    }
    return true;
}

export function areEqual<T>(...values: T[]): boolean {
    return new Set(values).size === 1;
}
