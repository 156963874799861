import { action, makeObservable, observable } from "mobx";

export class ThemeWithDuration {
    readonly id: number;
    readonly name: string;
    @observable durationSeconds: number;

    constructor(themeId: number, themeName: string, durationSeconds: number) {
        this.id = themeId;
        this.name = themeName;
        this.durationSeconds = durationSeconds;
        makeObservable(this);
    }

    @action.bound
    setDuration(durationSeconds: number) {
        this.durationSeconds = durationSeconds;
    }
}
