import { EditorTypes } from "../../../../editors/base";

type TEditor = {
    type: EditorTypes;
    label: string;
};

export const EDITORS: TEditor[] = [
    {
        type: EditorTypes.math,
        label: "Математический редактор",
    },
    {
        type: EditorTypes.format,
        label: "Редактор с форматированием",
    },
    {
        type: EditorTypes.picture,
        label: "Инструмент Рисунок",
    },
    {
        type: EditorTypes.intervals,
        label: "Инструмент Интервалы",
    },
    {
        type: EditorTypes.table,
        label: "Инструмент Таблица",
    },
    {
        type: EditorTypes.tableSelect,
        label: "Инструмент Таблица c выбором",
    },
    {
        type: EditorTypes.graph1d,
        label: "Инструмент График 1Д",
    },
    {
        type: EditorTypes.figure2d,
        label: "Инструмент Чертёж 2Д",
    },
    {
        type: EditorTypes.graph2d,
        label: "Инструмент График 2Д",
    },
];
