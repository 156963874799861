import type { TAnyAction } from "../types";

export type TCoordinatesVectorAction = { type: "coordinates-vector" };

export function createCoordinatesVectorAction(): TCoordinatesVectorAction {
    return { type: "coordinates-vector" };
}

export function isCoordinatesVectorAction(action: TAnyAction): action is TCoordinatesVectorAction {
    return action.type === "coordinates-vector";
}
