import { generateUuid } from "@viuch/shared/utils/data";

import type { GraphFunctionInterval } from "./GraphFunctionInterval";
import type { Graph2DModel } from "../../core/Graph2DModel";

export abstract class BaseGraphItem {
    readonly $uuid: string = generateUuid();

    beforeAdd?(model: Graph2DModel): void;
    onRemoved?(model: Graph2DModel): void;

    abstract clone(): BaseGraphItem;
}

export interface IIntervalsCollection {
    intervalsTree: GraphFunctionInterval[];
    removeInterval(interval: GraphFunctionInterval): void;
    addInterval(interval: GraphFunctionInterval): void;
}
