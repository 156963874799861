import React from "react";

import type { PlaceholdersService } from "./PlaceholdersService";

export function usePlaceholderRegistrationRef<T extends HTMLElement>(
    placeholders: PlaceholdersService
): React.MutableRefObject<T | null> {
    const ref = React.useRef<T | null>(null);

    React.useEffect(() => {
        const element = ref.current!;

        placeholders.registerPlaceholderElement(element);

        return () => {
            placeholders.freePlaceholderElement(element);
        };
    }, [placeholders]);

    return ref;
}
