import { action, computed, makeObservable, observable } from "mobx";
import { createTransformer } from "mobx-utils";

import { getAngle } from "@viuch/geometry-lib/angles";
import { copyAngle, copyEllipse, createLine } from "@viuch/geometry-lib/factories";

import type { BaseElement } from "../../elements";
import type { Figure2DController } from "../../Figure2DController";
import type { ToolbarButton, ToolbarMenu } from "../../toolbar";
import type { IUserPointer } from "../../viewport/types";
import type { TAngle } from "@viuch/geometry-lib/types";
import type React from "react";

import { DotElement } from "../../elements/dot";
import { ElementColor } from "../../elements/ElementColor";
import { EllipseElement } from "../../elements/ellipse";
import { LineElement } from "../../elements/line";
import { VectorElement } from "../../elements/line/VectorElement";
import { ButtonStates } from "../../toolbar";
import { createDefaultToolbarMenuBuilder, handleToolbarButtons } from "../../utils/toolbar";
import { BaseFlow } from "../BaseFlow";
import { CircumscribedCircleFlow, InscribedCircleFlow } from "../circles";
import { EqualSegmentsSelectedFlow } from "../constraints";
import { MiddleFragmentInteractiveFlow } from "../create-fragment";
import { CreateLabelAngleFlow } from "../create-label-angle";
import { CreateLabelDotFlow } from "../create-label-dot";
import { LabelFragmentFlow } from "../create-label-fragment";
import { ConstraintLineFlow } from "../create-line";
import { TriangleFlow } from "../derived";
import { BisectionFlow, MedianFlow } from "../geometry";
import { AltitudeFlow } from "../geometry/AltitudeFlow";
import { TangentEllipsePointFlow } from "../tangent/TangentEllipsePointFlow";
import { createMapModelToElements, z } from "../utils";

import {
    getAngleFromFragment,
    handleRemoveEllipse,
    handleRemoveFragment,
    handleRemovePoint,
    handleRemoveVector,
} from "./utils";

export class SelectElementsFlow extends BaseFlow {
    clearSelectionOnUp = false;

    static create(data: Figure2DController) {
        return new SelectElementsFlow(data);
    }

    constructor(data: Figure2DController) {
        super(data);

        makeObservable(this, {
            tempElements: computed,
            clearSelectionOnUp: observable,
            handleRemove: action.bound,
        });
    }

    get tempElements(): BaseElement[] {
        const color = ElementColor.Selected;
        return this.selection.elements.flatMap((element) =>
            element.accept<BaseElement[]>({
                withPoint: (point) => [
                    new DotElement({
                        x: point.x,
                        y: point.y,
                        color,
                        id: `selected__${point.$$instance_id}`,
                        overrideRenderOrder: z.points.priority,
                    }),
                ],
                withFragment: (fragment) => [
                    new LineElement({
                        x1: fragment.a.x,
                        y1: fragment.a.y,
                        x2: fragment.b.x,
                        y2: fragment.b.y,
                        color,
                        id: `selected__${fragment.$$instance_id}__line`,
                        overrideRenderOrder: z.fragments.priority,
                    }),
                ],
                withEllipse: (ellipse) => [
                    new EllipseElement({
                        id: `selected__${ellipse.$$instance_id}__ellipse`,
                        center: ellipse.center,
                        rx: ellipse.rx,
                        ry: ellipse.ry,
                        color,
                        overrideRenderOrder: z.circles.priority,
                    }),
                ],
                withVector: (vector) => [
                    new VectorElement({
                        id: `selected__${vector.$$instance_id}__vector`,
                        x1: vector.from.x,
                        y1: vector.from.y,
                        x2: vector.to.x,
                        y2: vector.to.y,
                        color,
                        overrideRenderOrder: z.fragments.priority,
                    }),
                ],
            })
        );
    }

    attach(): void {
        this.viewport.enable();
    }

    dispose(): void {
        this.selection.clear();
    }

    mapModel = createTransformer(createMapModelToElements());

    handleRemove() {
        this.selection.elements.forEach((elem) =>
            elem.accept({
                withFragment: (fragment) => handleRemoveFragment(this.figure, fragment),
                withPoint: (point) => handleRemovePoint(this.figure, point),
                withEllipse: (ellipse) => handleRemoveEllipse(this.figure, ellipse),
                withVector: (vector) => handleRemoveVector(this.figure, vector),
            })
        );
    }

    override handleElementPointerEvent(e: React.PointerEvent, element: BaseElement, pointer: IUserPointer): void {
        if (!e.isPrimary) return;

        switch (e.type) {
            case "pointerdown": {
                this.onDown(e, element, pointer);
                break;
            }
        }
    }

    override handleViewportPointerEvent(e: React.PointerEvent, pointer: IUserPointer): void {
        if (!e.isPrimary) return;

        switch (e.type) {
            case "pointerdown": {
                this.clearSelectionOnUp = true;
                break;
            }
            case "pointermove": {
                this.clearSelectionOnUp = false;
                break;
            }
            case "pointerup": {
                if (this.clearSelectionOnUp) {
                    this.nextFlow();
                }
                break;
            }
            case "pointercancel": {
                this.clearSelectionOnUp = false;
                break;
            }
        }
    }

    override getToolbarMenu(): ToolbarMenu {
        const b = createDefaultToolbarMenuBuilder().setButtonState(ButtonStates.active, ["cursor"]);

        if (this.actions.selectedSinglePoint) {
            b.setButtonState(ButtonStates.default, ["labels"]);
            b.setButtonState(ButtonStates.default, ["labels", "label-dot"]);
        }

        if (this.actions.selectedSingleFragment) {
            b.setButtonState(ButtonStates.default, ["labels"]);
            b.setButtonState(ButtonStates.default, ["labels", "label-segment"]);

            b.setButtonState(ButtonStates.default, ["fragments"]);
            b.setButtonState(ButtonStates.default, ["fragments", "perpendicular"]);
            b.setButtonState(ButtonStates.default, ["fragments", "parallel"]);
        }

        if ((this.actions.selectSomePoints?.points.length || 0) <= 3) {
            b.setButtonState(ButtonStates.default, ["triangle"]);
        }

        if (this.actions.selectedFragmentAndPoint) {
            b.setButtonState(ButtonStates.default, ["fragments"]);
            b.setButtonState(ButtonStates.default, ["fragments", "perpendicular"]);
            b.setButtonState(ButtonStates.default, ["fragments", "parallel"]);
            b.setButtonState(ButtonStates.default, ["fragments", "bisection"]);
            b.setButtonState(ButtonStates.default, ["fragments", "altitude"]);
            b.setButtonState(ButtonStates.default, ["fragments", "median"]);
            b.setButtonState(ButtonStates.default, ["fragments", "middle-line"]);
        }

        if (this.actions.selectedSingleAngle) {
            b.setButtonState(ButtonStates.default, ["labels"]);
            b.setButtonState(ButtonStates.default, ["labels", "label-angle"]);
            b.setButtonState(ButtonStates.default, ["fragments"]);
            b.setButtonState(ButtonStates.default, ["fragments", "bisection"]);
            b.setButtonState(ButtonStates.default, ["fragments", "median"]);
            b.setButtonState(ButtonStates.default, ["fragments", "altitude"]);
        }

        if (this.actions.selectSomePoints?.points.length === 3) {
            b.setButtonState(ButtonStates.default, ["labels"]);
            b.setButtonState(ButtonStates.default, ["labels", "label-angle"]);
        }

        if (this.actions.selectedConvexPolygon) {
            b.setButtonState(ButtonStates.default, ["circles"]);
            b.setButtonState(ButtonStates.default, ["circles", "circle-inscribed"]);
            b.setButtonState(ButtonStates.default, ["circles", "circle-circumscribed"]);
        }

        if (this.actions.selectedSinglePointOnEllipse) {
            b.setButtonState(ButtonStates.default, ["fragments"]);
            b.setButtonState(ButtonStates.default, ["fragments", "tangent-point"]);
        }

        return b.build();
    }

    override handleToolbarButtonClick(menu: ToolbarMenu, button: ToolbarButton): void {
        switch (button.key) {
            case "cursor": {
                this.nextFlow();
                break;
            }
            case "label-dot": {
                const { selectedSinglePoint } = this.actions;
                if (selectedSinglePoint) {
                    this.nextFlow((data) => new CreateLabelDotFlow(data, selectedSinglePoint.point));
                }
                break;
            }
            case "label-segment": {
                const { selectedSingleFragment } = this.actions;
                if (selectedSingleFragment) {
                    this.nextFlow((data) => new LabelFragmentFlow(data, selectedSingleFragment.fragment));
                }
                break;
            }
            case "remove":
            case "clear": {
                this.handleRemove();
                this.nextFlow();
                break;
            }
            case "perpendicular":
            case "parallel": {
                const { selectedFragmentAndPoint, selectedSingleFragment } = this.actions;
                const angle = button.key === "parallel" ? 0 : Math.PI / 2;
                if (selectedFragmentAndPoint) {
                    const { fragment, point } = selectedFragmentAndPoint;
                    this.nextFlow((data) => new ConstraintLineFlow(data, angle, fragment, point));
                }
                if (selectedSingleFragment) {
                    const { fragment } = selectedSingleFragment;

                    this.nextFlow((data) => new ConstraintLineFlow(data, angle, fragment));
                }
                break;
            }
            case "label-angle": {
                const { selectedSingleAngle, selectSomePoints } = this.actions;

                const angle: TAngle | null = (() => {
                    if (selectSomePoints && selectSomePoints.points.length === 3) {
                        const [start, vertex, end] = selectSomePoints.points;
                        return { start, vertex, end };
                    }
                    if (selectedSingleAngle) {
                        return selectedSingleAngle.angle;
                    }
                    return null;
                })();

                if (angle) {
                    const a = copyAngle(angle);
                    if (getAngle(a) > Math.PI) {
                        [a.start, a.end] = [a.end, a.start];
                    }

                    this.nextFlow((data) => new CreateLabelAngleFlow(data, a, true));
                }
                break;
            }
            case "median":
            case "bisection":
            case "altitude": {
                const { selectedFragmentAndPoint, selectedSingleAngle } = this.actions;
                const angle =
                    selectedSingleAngle?.angle ||
                    (selectedFragmentAndPoint
                        ? getAngleFromFragment(selectedFragmentAndPoint.fragment, selectedFragmentAndPoint.point)
                        : null);

                if (!angle) break;

                switch (button.key) {
                    case "bisection":
                        return this.nextFlow((data) => BisectionFlow.create(data, angle));
                    case "median":
                        return this.nextFlow((data) => new MedianFlow(data, angle));
                    case "altitude": {
                        const vertex = angle.vertex;
                        const line = createLine(angle.start, angle.end);
                        return this.nextFlow((data) => new AltitudeFlow(data, vertex, line));
                    }
                }
                break;
            }
            case "middle-line": {
                const { selectedFragmentAndPoint } = this.actions;
                if (selectedFragmentAndPoint) {
                    const {
                        point,
                        fragment: { a, b },
                    } = selectedFragmentAndPoint;

                    const initialData = {
                        a,
                        b,
                        c: point,
                        d: point,
                    };
                    return this.nextFlow((data) => new MiddleFragmentInteractiveFlow(data, initialData));
                }
                break;
            }
            case "triangle": {
                const { selectSomePoints } = this.actions;
                if (selectSomePoints) {
                    return this.nextFlow((data) => new TriangleFlow(data, selectSomePoints.points));
                }
                break;
            }
            case "circle-inscribed":
            case "circle-circumscribed": {
                const { selectedConvexPolygon } = this.actions;
                if (selectedConvexPolygon) {
                    return this.nextFlow((data) =>
                        button.key === "circle-inscribed"
                            ? new InscribedCircleFlow(data, selectedConvexPolygon.polygon)
                            : new CircumscribedCircleFlow(data, selectedConvexPolygon.polygon)
                    );
                }
                break;
            }
            case "equal-segments": {
                const { selectedFragments } = this.actions;
                if (selectedFragments) {
                    this.nextFlow((data) => new EqualSegmentsSelectedFlow(data, selectedFragments.fragments));
                }
                break;
            }
            case "tangent-point": {
                const { selectedSinglePointOnEllipse } = this.actions;
                if (selectedSinglePointOnEllipse) {
                    const { ellipse, point } = selectedSinglePointOnEllipse;
                    this.nextFlow((data) => new TangentEllipsePointFlow(data, { ellipse, point }));
                }
                break;
            }
            default:
                handleToolbarButtons(this, button);
        }
    }

    protected renderElements(): BaseElement[] {
        return [...this.figure.models.flatMap(this.mapModel), ...this.tempElements];
    }

    private onDown(e: React.PointerEvent, element: BaseElement, pointer: IUserPointer) {
        e.stopPropagation();

        element.accept<void>({
            withDot: (dot) => this.selection.toggleDot({ x: dot.x, y: dot.y }),
            withLine: (line) => this.selection.handleLineClick(line, pointer.canvas),
            withVector: (vector) => this.selection.toggleVector(vector.toFragment()),
            withAngle: () => {},
            withLabelDot: () => {},
            withLabelAngle: () => {},
            withLabelFragment: () => {},
            withStroke: () => {},
            withEllipse: (ellipse) => this.selection.toggleEllipse(copyEllipse(ellipse)),
        });

        if (this.selection.isEmpty()) {
            this.nextFlow();
        }
    }

    override handleKeyEvent(e: React.KeyboardEvent) {
        const { key, ctrlKey, altKey, shiftKey } = e;
        switch (e.key.toLowerCase()) {
            case "delete":
            case "backspace":
                this.handleRemove();
                this.nextFlow();
                break;
        }

        if (key === "Escape" && !ctrlKey && !altKey && !shiftKey) {
            this.nextFlow();
            return;
        }
    }
}
