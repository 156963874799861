import type { TFormControl } from "@viuch/form-builder/types/controls";

import { createLabel } from "../controls/label";
import { variableMathControl, equationMathControl } from "../controls/value";

export const rangeOfExpr: TFormControl = {
    control: "Form",
    layout: "column",
    condition: {
        "./solver_type": ["range_of_expr"],
    },
    controls: [
        createLabel("В качестве ответа выступает область определения выражения"), //
        equationMathControl,
        variableMathControl,
    ],
};
