import type { TRawThemeGenerationProgress, TThemeGenerationProgress, TThemeMinimal } from "./themes";
import type { TTaskGenerationProgress } from "../tasks";

import { mapTaskGenerationProgress } from "../tasks";

export function mapThemeMinimal(data: TThemeMinimal): TThemeMinimal {
    const { id, name } = data;

    return { id, name };
}

export function mapThemeGenerationProgress(data: TRawThemeGenerationProgress): TThemeGenerationProgress {
    const { is_in_progress, tasks } = data;

    const tasksMap: Map<number, TTaskGenerationProgress> = new Map();

    let estimate = 0;
    let generated = 0;

    tasks.forEach((task) => {
        estimate += task.estimate;
        generated += task.generated;
        tasksMap.set(task.id, mapTaskGenerationProgress(task));
    });

    return {
        isInProgress: is_in_progress,
        estimate,
        generated,
        tasks: tasksMap,
    };
}
