import { action, makeObservable, observable } from "mobx";

import type { TTaskAnyInstrumentUpdateData } from "@viuch/feature-instrument-settings/service-types/instrumentUpdate";
import type { TTaskInstrumentInit } from "@viuch/feature-instrument-settings/service-types/taskInstruments";
import type { TSerializedState } from "@viuch/math-editor";
import type { ISolutionExample, ISolutionExampleInnerStep, ISolutionExampleStep } from "@viuch/solution-example";
import type { TSolutionExampleStepHelp } from "@viuch/solution-example/model/ISolutionExample";

export class SolutionExampleImpl<
    TInnerStep extends ISolutionExampleInnerStep = ISolutionExampleInnerStep,
    TStep extends ISolutionExampleStep<TInnerStep> = ISolutionExampleStep<TInnerStep>,
> implements ISolutionExample
{
    @observable.ref title: TSerializedState | null;
    @observable.ref expression: TSerializedState | null;
    @observable.shallow steps: TStep[];
    @observable.shallow instruments: TTaskInstrumentInit[];

    constructor(
        title: TSerializedState | null,
        expression: TSerializedState | null,
        steps: TStep[],
        instruments: TTaskInstrumentInit[]
    ) {
        this.title = title;
        this.expression = expression;
        this.steps = steps.slice();
        this.instruments = instruments.slice();

        makeObservable(this);
    }

    @action.bound
    addStep(step: TStep) {
        this.steps.push(step);
    }
}

export class SolutionExampleStepImpl<TInnerStep extends ISolutionExampleInnerStep = ISolutionExampleInnerStep>
    implements ISolutionExampleStep<TInnerStep>
{
    @observable.ref title: TSerializedState | null;
    @observable.ref inputExpression: TSerializedState | null;
    @observable.ref outputExpression: TSerializedState | null;
    @observable.shallow innerSteps: TInnerStep[];
    @observable.shallow stepInstruments: TTaskInstrumentInit[];
    @observable.shallow stepUpdateInstruments: TTaskAnyInstrumentUpdateData[];
    @observable.ref isActual: boolean;
    readonly help: TSolutionExampleStepHelp | null;

    constructor(
        title: TSerializedState | null,
        inputExpression: TSerializedState | null,
        outputExpression: TSerializedState | null,
        innerSteps: TInnerStep[],
        stepInstruments: TTaskInstrumentInit[],
        stepUpdateInstruments: TTaskAnyInstrumentUpdateData[],
        help: TSolutionExampleStepHelp | null,
        isActual: boolean
    ) {
        this.title = title;
        this.inputExpression = inputExpression;
        this.outputExpression = outputExpression;
        this.innerSteps = innerSteps.slice();
        this.stepInstruments = stepInstruments.slice();
        this.stepUpdateInstruments = stepUpdateInstruments.slice();
        this.isActual = isActual;
        this.help = help;

        makeObservable(this);
    }

    @action.bound
    setTitle(title: TSerializedState) {
        this.title = title;
    }

    @action.bound
    setInputExpr(inputExpression: TSerializedState | null) {
        this.inputExpression = inputExpression;
    }

    @action.bound
    setOutputExpr(outputExpression: TSerializedState | null) {
        this.outputExpression = outputExpression;
    }

    @action.bound
    addInnerStep(innerStep: TInnerStep) {
        this.innerSteps.push(innerStep);
    }

    @action.bound
    setIsActual(state: boolean) {
        this.isActual = state;
    }
}

export class SolutionExampleInnerStepImpl implements ISolutionExampleInnerStep {
    @observable.ref inputExpression: TSerializedState | null;
    @observable.shallow descriptionSteps: TSerializedState[];
    @observable.ref outputExpression: TSerializedState | null;
    @observable.shallow innerStepInstruments: TTaskInstrumentInit[];
    @observable.shallow innerStepUpdateInstruments: TTaskAnyInstrumentUpdateData[];
    readonly help: TSolutionExampleStepHelp | null;

    constructor(
        inputExpression: TSerializedState | null,
        descriptionSteps: TSerializedState[],
        outputExpression: TSerializedState | null,
        innerStepInstruments: TTaskInstrumentInit[],
        innerStepUpdateInstruments: TTaskAnyInstrumentUpdateData[],
        help: TSolutionExampleStepHelp | null
    ) {
        this.inputExpression = inputExpression;
        this.descriptionSteps = descriptionSteps.slice();
        this.outputExpression = outputExpression;
        this.innerStepInstruments = innerStepInstruments.slice();
        this.innerStepUpdateInstruments = innerStepUpdateInstruments.slice();
        this.help = help;

        makeObservable(this);
    }
}
