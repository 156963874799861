import { action, makeObservable, observable } from "mobx";

import type { Figure2DController } from "../Figure2DController";

import { HistorySnapshot } from "./HistorySnapshot";

export class HistoryManager {
    private readonly data: Figure2DController;
    private readonly initialSnapshot: HistorySnapshot;
    private undoSnapshots: HistorySnapshot[];

    @observable.shallow
    snapshots: HistorySnapshot[];

    constructor(data: Figure2DController) {
        this.data = data;
        this.snapshots = [];
        this.undoSnapshots = [];

        this.initialSnapshot = HistorySnapshot.createFromFigure(this.figure);

        makeObservable(this);
    }

    private get figure() {
        return this.data.figure;
    }

    // @action.bound
    // initialize() {}

    /**
     * Создаёт снимок из активного состояния и добавляет в конец истории.
     */
    @action.bound
    commit() {
        const snapshot = HistorySnapshot.createFromFigure(this.figure);
        this.pushInternal(snapshot);
    }

    @action.bound
    private pushInternal(snapshot: HistorySnapshot): void {
        if (HistorySnapshot.areEqual(this.getLastSnapshot(), snapshot)) return;

        if (this.undoSnapshots.length) {
            this.snapshots.push(...this.undoSnapshots.reverse(), this.getLastSnapshot());
            this.undoSnapshots = [];
        }
        this.snapshots.push(snapshot);
    }

    /**
     * Применяет последний снимок
     */
    @action.bound
    applyLast(): void {
        this.getLastSnapshot().apply(this.figure);
    }

    @action.bound
    undo(): void {
        const currentSnapshot = this.snapshots.pop();
        if (currentSnapshot) {
            this.undoSnapshots.push(currentSnapshot);
        }
        this.applyLast();
    }

    @action.bound
    redo() {
        const snapshot = this.undoSnapshots.pop();
        if (snapshot) {
            this.snapshots.push(snapshot);
        }
        this.applyLast();
    }

    getLastSnapshot(): HistorySnapshot {
        return this.snapshots.at(-1) || this.initialSnapshot;
    }

    /**
     * Добавляет первоначальный снимок в историю и применяет его.
     */
    @action.bound
    reset(): void {
        this.push(this.initialSnapshot);
    }

    /**
     * Добавляет пустой снимок и применяет его.
     */
    @action.bound
    clear(): void {
        this.push(HistorySnapshot.createEmpty());
    }

    /**
     * Добавляет сторонний снимок и применяет его.
     */
    @action.bound
    push(snapshot: HistorySnapshot): void {
        this.pushInternal(snapshot);
        this.applyLast();
    }

    /**
     * Добавляет сторонний снимок, затем накладывает сторонний снимок на текущий, и применяет его.
     *
     * - В историю сохраняются два снимка.
     * - При слиянии элементы стороннего снимка имеют приоритет.
     */
    @action.bound
    pushCorrections(snapshot: HistorySnapshot): void {
        const lastSnapshot = this.getLastSnapshot();
        this.push(snapshot);

        lastSnapshot.append(this.data.figure);
        this.data.normalizer.normalizeModel();
        this.commit();
    }
}
