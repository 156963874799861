import type { ISerializedSpace } from "./types";
import type { TSerializedElementPrototype } from "../../core/element";
import type { IElementVisitor } from "../../core/element/IElementVisitor";
import type { InputService } from "../../services";
import type { TElementDeserializerFunction } from "../../types";

import { BaseElementModel } from "../../core/element";

export class SpaceModel extends BaseElementModel<ISerializedSpace> {
    public constructor(inputService: InputService, uuid?: string) {
        super(inputService, uuid);
    }

    public serialize(): ISerializedSpace {
        return { type: "space", uuid: this.uuid };
    }

    public static deserialize: TElementDeserializerFunction<ISerializedSpace> = ({ inputService }, { uuid }) =>
        new SpaceModel(inputService, uuid);

    public accept<R>(visitor: IElementVisitor<R>): R {
        return visitor.doWithSpace(this);
    }

    serializeAsClone(): TSerializedElementPrototype<ISerializedSpace> {
        const { uuid, ...clone } = this.serialize();
        return clone;
    }
}
