import cn from "classnames";

import type { TKeyboardIconProps } from "./KeyboardIcon.types";
import type { VFC } from "react";

import { keyboardIconsMap } from "../../../../constants";

import styles from "./KeyboardIcon.module.scss";

const KeyboardIcon: VFC<TKeyboardIconProps> = ({ className, iconName }) => {
    const Icon = keyboardIconsMap[iconName];

    if (Icon) {
        return <Icon className={cn(styles.icon, className)} />;
    }

    return null;
};

export default KeyboardIcon;
