import type { ISerializedTrigonometricOperator, TTrigonometricOperator } from "./types";
import type { TSerializedElementPrototype } from "../../core/element";
import type { IElementVisitor } from "../../core/element/IElementVisitor";
import type { IStrategy } from "../../core/strategies";
import type { IMultiContainerElement } from "../../core/strategies/types";
import type { InputService } from "../../services";
import type { TContainerElements, TElementDeserializerFunction } from "../../types";

import { ContainerModel } from "../../core/container";
import { BaseElementModel } from "../../core/element";

import { TrigonometricOperatorStrategy } from "./TrigonometricOperatorStrategy";

export class TrigonometricOperatorModel
    extends BaseElementModel<ISerializedTrigonometricOperator>
    implements IMultiContainerElement
{
    public readonly symbolName: TTrigonometricOperator;
    public readonly content: ContainerModel;
    public readonly degreeContent: ContainerModel;

    public constructor(
        inputService: InputService,
        symbolName: TTrigonometricOperator,
        elements: TContainerElements,
        degreesElements: TContainerElements,
        uuid?: string
    ) {
        super(inputService, uuid);

        this.symbolName = symbolName;
        this.content = new ContainerModel(elements, this, ["content"], inputService, {
            showPlaceholderIfEmpty: true,
        });
        this.degreeContent = new ContainerModel(degreesElements, this, ["degreeContent"], inputService);
    }

    public serialize(): ISerializedTrigonometricOperator {
        return {
            type: "trigonometric-operator",
            symbolName: this.symbolName,
            content: this.content.serialize(),
            degreeContent: this.degreeContent.serialize(),
            uuid: this.uuid,
        };
    }

    public static deserialize: TElementDeserializerFunction<ISerializedTrigonometricOperator> = (
        { inputService },
        { symbolName, content, uuid, degreeContent }
    ) =>
        new TrigonometricOperatorModel(
            inputService,
            symbolName,
            inputService.deserializeContainer(content),
            inputService.deserializeContainer(degreeContent),
            uuid
        );

    getContainersToMoveCursorBetween(): ContainerModel[] {
        return [this.degreeContent, this.content];
    }

    overridePrimaryContainer(): ContainerModel {
        return this.content;
    }

    protected override initBehaviour(): IStrategy {
        return new TrigonometricOperatorStrategy(this);
    }

    public accept<R>(visitor: IElementVisitor<R>): R {
        return visitor.doWithTrigonometricOperator(this);
    }

    serializeAsClone(): TSerializedElementPrototype<ISerializedTrigonometricOperator> {
        const { uuid, ...clone } = this.serialize();
        return {
            ...clone,
            content: this.content.serializeAsClone(),
            degreeContent: this.degreeContent.serializeAsClone(),
        };
    }

    override getWrapperContainer(): ContainerModel | null {
        return this.content;
    }
}
