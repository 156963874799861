import type { TCursorMoveDirection, TRemoveMethod } from "./IStrategy";
import type { BaseElementModel } from "../element";

import { BackspaceCommand } from "../../commands/BackspaceCommand";
import { DeleteCommand } from "../../commands/DeleteCommand";
import { RemoveSelectedCommand } from "../../commands/RemoveSelectedCommand";

import { BaseStrategy } from "./BaseStrategy";

export class ContainerLessElementStrategy<M extends BaseElementModel> extends BaseStrategy<M> {
    public constructor(model: M) {
        super(model);
    }

    handleAction(): void {
        throw new Error("This element cannot be used for action handling");
    }

    checkCanBeInserted(): boolean {
        throw new Error("This element cannot contains elements");
    }

    handleCursorMoveOver(direction: TCursorMoveDirection): void {
        if (direction === "ltr") {
            this.cursor.moveRight();
        }
        if (direction === "rtl") {
            this.cursor.moveLeft();
        }
    }

    handleRemoveThis(method: TRemoveMethod): void {
        if (method === "backspace") {
            return this.commands.perform(
                this.selection.isSelectedSomething
                    ? new RemoveSelectedCommand(this.model)
                    : new BackspaceCommand(this.model)
            );
        }
        if (method === "delete") {
            return this.commands.perform(
                this.selection.isSelectedSomething
                    ? new RemoveSelectedCommand(this.model)
                    : new DeleteCommand(this.model)
            );
        }
    }

    handleInserted(): void {
        this.cursor.moveRight();
    }
}
