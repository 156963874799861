import type { GetError } from "../base/RequestError";
import type { TSerializedState } from "@viuch/math-editor";

import { ExpressionReplacement } from "../../entities/expression-replacements";
import { BaseCRUDService } from "../base";

export class ExpressionReplacementsService extends BaseCRUDService<
    ExpressionReplacement,
    TExpressionReplacementRequest,
    TExpressionReplacementResponse
> {
    constructor() {
        super("/expression_replacements/", mapExpressionReplacement, serializeExpressionReplacement);
    }
}

export type TExpressionReplacementRequest = {
    id: number;
    input_expr: TSerializedState[];
    output_expr: TSerializedState;
};

export type TExpressionReplacementResponse = TExpressionReplacementRequest;

export type TExpressionReplacementError = GetError<TExpressionReplacementRequest>;

export const mapExpressionReplacement = ({
    id,
    input_expr: inputExpressions,
    output_expr: outputExpression,
}: TExpressionReplacementResponse) => new ExpressionReplacement({ id, inputExpressions, outputExpression });

export const serializeExpressionReplacement = ({
    id,
    inputExpressions: input_expr,
    outputExpression: output_expr,
}: ExpressionReplacement): TExpressionReplacementRequest => ({ id, input_expr, output_expr });
