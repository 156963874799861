import type { TFormControl } from "@viuch/form-builder/types/controls";

import { createLabel } from "../controls/label";
import { valueMathControl } from "../controls/value";

export const divideWithRemainder: TFormControl = {
    control: "Form",
    layout: "column",
    condition: [{ "./solver_type": "divide_with_remainder" }],
    controls: [
        createLabel("Деление с остатком"),
        valueMathControl,
        {
            path: "./target",
            control: "Select",
            label: "Действие",
            initialValue: "remainder",
            data: {
                type: "static-items",
                items: [
                    { label: "Остаток от деления", value: "remainder" },
                    { label: "Частное", value: "quotient" },
                ],
            },
        },
    ],
};
