import type { TGeometryChar } from "../elements/geometry/types";
import type { TAnyAction } from "../types";

export type TGeometryAction = {
    type: "geometry";
    char: TGeometryChar;
};

export function createGeometryCharAction(char: TGeometryChar): TGeometryAction {
    return { type: "geometry", char };
}

export function isGeometryAction(action: TAnyAction): action is TGeometryAction {
    return action.type === "geometry";
}
