import { action, makeObservable, observable } from "mobx";

import type { GlobalStudyPlanTemplateQuarter } from "./GlobalStudyPlanTemplateQuarter";

export class GlobalStudyPlanTemplate {
    readonly id: number;
    @observable name: string;
    @observable quarters: GlobalStudyPlanTemplateQuarter[];

    constructor(id: number, name: string, quarters: GlobalStudyPlanTemplateQuarter[]) {
        this.id = id;
        this.name = name;
        this.quarters = quarters.slice();

        makeObservable(this);
    }

    @action.bound
    setName(name: string) {
        this.name = name;
    }

    @action.bound
    addQuarter(quarter: GlobalStudyPlanTemplateQuarter) {
        this.quarters.push(quarter);
    }

    @action.bound
    removeQuarter(quarter: GlobalStudyPlanTemplateQuarter) {
        this.quarters.remove(quarter);
    }
}
