import { action, makeObservable, observable } from "mobx";

import { createSerializedContainer } from "@viuch/math-editor";

import type { BaseFormNode, IFormNodeVisitor } from "./BaseFormNode";
import type { TSerializedState } from "@viuch/math-editor";

import { BaseValueFormNode } from "./BaseValueFormNode";

export class MathArrayFormNode extends BaseValueFormNode {
    @observable.shallow values: TSerializedState[];

    constructor(label: string, parent: BaseFormNode | null, path: string[], extensions: string[]) {
        super(label, parent, path, extensions);

        this.values = [createSerializedContainer()];

        makeObservable(this);
    }

    @action.bound
    addValue() {
        this.values.push(createSerializedContainer());
    }

    @action.bound
    removeValueByIndex(index: number) {
        this.values.splice(index, 1);
    }

    @action.bound
    changeValueAtIndex(index: number, content: TSerializedState) {
        this.values[index] = content;
    }

    accept<R>(visitor: IFormNodeVisitor<R>): R {
        return visitor.withMathArray(this);
    }

    getValue(): unknown {
        return this.values;
    }

    trySetValue(value: unknown): boolean {
        if (Array.isArray(value)) {
            this.values = value;
            return true;
        }

        return false;
    }

    valueEqualsTo(value: unknown): boolean {
        return false;
    }
}
