import type { IAttentionEntity } from "../attention-filters/types";

export class SynopsisMinimal implements IAttentionEntity {
    readonly id: number;
    readonly name: string;
    readonly themes: Array<{ id: number; name: string }>;

    constructor(id: number, name: string, themes: Array<{ id: number; name: string }>) {
        this.id = id;
        this.name = name;
        this.themes = themes.slice();
    }
}
