import type { TAnyAction } from "../types";

export type TLogAction = { type: "log"; index?: number };

export function createLogAction(index?: number): TLogAction {
    return { type: "log", index };
}

export function isLogAction(action: TAnyAction): action is TLogAction {
    return action.type === "log";
}
