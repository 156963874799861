import { mapAlignmentX, mapAlignmentY } from "@viuch/feature-instrument-settings/serializers/mapPicture";

import type { PictureTaskInstrumentSettings } from "../../../entities/task-instruments-settings/BaseTaskInstrumentSettings";
import type { TPictureInstrumentSettings } from "@viuch/feature-instrument-settings/service-types/picture";

export function serializePictureTaskSettings(model: PictureTaskInstrumentSettings): TPictureInstrumentSettings {
    return {
        instrument_type: "picture",
        picture: model.instrument.image
            ? {
                  id: model.instrument.image.uuid,
                  url: model.instrument.image.url,
              }
            : null!, // TODO: клиентская валидация
        points: model.instrument.watermarks.map((watermark) => ({
            align_x: mapAlignmentX[watermark.alignment.horizontal],
            align_y: mapAlignmentY[watermark.alignment.vertical],
            x: watermark.position.left,
            y: watermark.position.top,
            value: watermark.templateValue,
            color: watermark.color,
        })),
        caption: model.instrument.title,
        alt_text: model.instrument.altText,
        settings_uuid: model.settingsUuid,
    };
}
