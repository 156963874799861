import { action, makeObservable } from "mobx";

import type { IIntervalsMethodLineDot, IntervalsInstrumentStatement, IntervalsLine } from "../statement";

import { IntervalsDot } from "../statement";

export class IntervalsMethodEditorViewModel {
    readonly intervals: IntervalsInstrumentStatement;

    constructor(intervals: IntervalsInstrumentStatement) {
        this.intervals = intervals;

        makeObservable(this);
    }

    @action.bound
    resetAll() {
        this.intervals.clear();
    }

    @action.bound
    handleRemoveLine(line: IntervalsLine) {
        this.intervals.removeLine(line);
    }

    @action.bound
    addDot(line: IntervalsLine, position: number, { form, value }: IIntervalsMethodLineDot) {
        line.createDot(new IntervalsDot({ form, value, position }));
    }

    @action.bound
    addLine() {
        this.intervals.addLine();
    }

    @action.bound
    editDot(line: IntervalsLine, dot: IntervalsDot, { value, form }: IIntervalsMethodLineDot) {
        dot.value = value;
        dot.form = form;
    }
}
