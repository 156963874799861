import { generateId } from "@viuch/shared/utils/data";

import type { CheckboxFormNode } from "./CheckboxFormNode";
import type { CustomSelectFormNode } from "./CustomSelectFormNode";
import type { FormNode } from "./FormNode";
import type { LabelFormNode } from "./LabelFormNode";
import type { MathArrayFormNode } from "./MathArrayFormNode";
import type { MathFormNode } from "./MathFormNode";
import type { NumberFormNode } from "./NumberFormNode";
import type { SelectFormNode } from "./SelectFormNode";
import type { SelectGroupedFormNode } from "./SelectGroupedFormNode";
import type { TextFormNode } from "./TextFormNode";
import type { TVirtualTreeNode } from "../tree/types";

export abstract class BaseFormNode {
    readonly $$instanceId = generateId();

    readonly pathSegments: readonly string[];
    readonly parent: BaseFormNode | null;

    protected constructor(parent: BaseFormNode | null, pathSegments: readonly string[]) {
        this.parent = parent;
        this.pathSegments = pathSegments;
    }

    abstract getChildren(): readonly BaseFormNode[];

    abstract canPresentInTree(currentNode: TVirtualTreeNode): boolean;

    abstract accept<R>(visitor: IFormNodeVisitor<R>): R;
}

export interface IFormNodeVisitor<R> {
    withForm: (form: FormNode) => R;
    withText: (text: TextFormNode) => R;
    withLabel: (label: LabelFormNode) => R;
    withSelect: (select: SelectFormNode) => R;
    withSelectGrouped: (select: SelectGroupedFormNode) => R;
    withMath: (math: MathFormNode) => R;
    withMathArray: (mathArray: MathArrayFormNode) => R;
    withCheckbox: (checkbox: CheckboxFormNode) => R;
    withNumber: (number: NumberFormNode) => R;
    withSelectCustom: (selectCustom: CustomSelectFormNode) => R;
}
