import cn from "classnames";
import { observer } from "mobx-react-lite";
import React from "react";

import type { SelectTableInstrumentSettingsCell } from "../../settings/cells";

import styles from "./TableInstrumentSettingsSelectCellEditor.module.scss";

type Props = {
    cell: SelectTableInstrumentSettingsCell;
    className?: string;
};

export const TableInstrumentSettingsSelectCellEditor: React.VFC<Props> = observer(
    function TableInstrumentSettingsSelectCellEditor(props) {
        const { className, cell } = props;

        const cellClassName = ((): string => {
            switch (cell.content) {
                case "+":
                    return styles.cellPlus;
                case "-":
                    return styles.cellMinus;
                case "empty":
                    return styles.cellEmpty;
                case "select":
                    return styles.cellNull;
            }
        })();

        return (
            <div
                className={cn(className, styles.wrapper)}
                onClick={cell.toggleContent}
            >
                <div className={cellClassName} />
            </div>
        );
    }
);
