import { isZeroLengthMathExpr } from "@viuch/math-editor";

import type { TSolutionStep, TSolutionStepWithInnerSteps } from "../service-types/solutionSteps";
import type { TSerializedState } from "@viuch/math-editor";

import { SolutionExampleInnerStepImpl, SolutionExampleStepImpl } from "../entities/SolutionExampleImpl";

export function mapSolutionExampleStepData(data: TSolutionStepWithInnerSteps): SolutionExampleStepImpl {
    const { description, input_expr, output_expr, steps, instrument, instrument_update, theme } = data;
    const title: TSerializedState = description;
    const inputExpr: TSerializedState | null = input_expr;
    const outputExpr: TSerializedState | null = output_expr;
    const innerSteps: SolutionExampleInnerStepImpl[] = steps.map(mapInnerStepData);
    const instruments = instrument ? [instrument] : [];
    const instrumentUpdates = instrument_update ? [instrument_update] : [];

    const helpData = theme
        ? {
              url: `/theme/${theme.slug}/`,
              buttonText: "Посмотреть конспект",
          }
        : null;

    return new SolutionExampleStepImpl(
        title,
        inputExpr,
        outputExpr,
        innerSteps,
        instruments,
        instrumentUpdates,
        helpData,
        true
    );
}

function mapInnerStepData(data: TSolutionStep): SolutionExampleInnerStepImpl {
    const input: TSerializedState | null = data.input_expr;

    const descriptions: TSerializedState[] = isZeroLengthMathExpr(data.description) ? [] : [data.description];

    const output: TSerializedState | null = data.output_expr;

    const instruments = data.instrument ? [data.instrument] : [];
    const instrumentUpdates = data.instrument_update ? [data.instrument_update] : [];

    const helpData = data.theme
        ? {
              url: `/theme/${data.theme.slug}/`,
              buttonText: "Посмотреть конспект",
          }
        : null;

    return new SolutionExampleInnerStepImpl(input, descriptions, output, instruments, instrumentUpdates, helpData);
}
