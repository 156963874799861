export class Student {
    readonly id: number;
    readonly firstName: string;
    readonly email: string | null;
    readonly phone: string | null;
    readonly subscription_status: TStudentSubscriptionStatus;

    constructor(
        id: number,
        firstName: string,
        email: string | null,
        phone: string | null,
        subscription_status: TStudentSubscriptionStatus
    ) {
        this.id = id;
        this.firstName = firstName;
        this.email = email;
        this.phone = phone;
        this.subscription_status = subscription_status;
    }
}

export type TStudentSubscriptionStatus = "free" | "free_expired" | "expired" | "active";
