import { GraphDataItems } from "../editor/model/GraphDataItems";

export class Graph2DModel {
    readonly publicKey: string;
    readonly dataItems: GraphDataItems;

    constructor(publicKey: string) {
        this.publicKey = publicKey;
        this.dataItems = new GraphDataItems(this);
    }
}
