import React, { memo, useEffect } from "react";

import type { PropsWithChildren } from "react";

import { RootStore } from "../store";

export function createRootStore(): RootStore {
    return new RootStore();
}

export const RootStoreContext = React.createContext<RootStore>(null!);

export function useRootStore(): RootStore {
    const store = React.useContext(RootStoreContext);

    return store;
}

export const RootStoreProvider = memo(({ children, store }: PropsWithChildren<{ store: RootStore }>) => {
    const { effect } = store;

    useEffect(effect, [effect]);

    return <RootStoreContext.Provider value={store}>{children}</RootStoreContext.Provider>;
});
