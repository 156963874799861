import { makeObservable, observable } from "mobx";

import type { ILabelElement } from "./BaseLabelElement";
import type { IElementVisitor } from "../BaseElement";

import { BaseLabelElement } from "./BaseLabelElement";

export interface ILabelAngleElement extends ILabelElement {
    x: number;
    y: number;
}

export class LabelAngleElement extends BaseLabelElement {
    x: number;
    y: number;

    constructor(data: ILabelAngleElement) {
        super(data);
        this.x = data.x;
        this.y = data.y;
        this.notifyAction = data.notifyAction;

        makeObservable(this, {
            x: observable,
            y: observable,
        });
    }

    accept<R>(visitor: IElementVisitor<R>): R {
        return visitor.withLabelAngle(this);
    }

    static create(data: ILabelAngleElement) {
        return new LabelAngleElement(data);
    }
}
