import type { EquationsSetModel } from "./EquationsSetModel";
import type { TEquationsSetAction } from "../../actions/equations-set";
import type { TLinebreakAction } from "../../actions/linebreak";
import type { IElementFilter } from "../../core/strategies/IElementFilter";

import { InsertIntoCursorCommand } from "../../commands/InsertIntoCursorCommand";
import { RemoveCurrentCommand } from "../../commands/RemoveCurrentCommand";
import { ClipboardPasteFilter } from "../../core/clipboard";
import { MultiContainerElementStrategy } from "../../core/strategies/MultiContainerElementStrategy";

import { AddNewContainerCommand } from "./commands/AddNewContainerCommand";
import { RemoveContainerCommand } from "./commands/RemoveContainerCommand";
import { WrapSelectedIntoEquationsSetCommand } from "./commands/WrapSelectedIntoEquationsSetCommand";
import { createSerializedEquationsSet } from "./utils";

export class EquationsSetStrategy extends MultiContainerElementStrategy<EquationsSetModel> {
    private readonly filter: IElementFilter;

    constructor(model: EquationsSetModel) {
        super(model);

        this.filter = ClipboardPasteFilter.Create({
            allowMultiline: false,
            allowEquationsSet: true,
        });
    }

    protected override handleLinebreakAction(action: TLinebreakAction) {
        const { direction } = action;
        const cursor = this.cursor;
        const containers = this.model.getContainersToMoveCursorBetween();
        const length = containers.length;

        const index = containers.indexOf(cursor.container);
        const skipEmptyCheck = this.model.equations.length < 5;

        if (direction === "down") {
            if (index === length - 1) {
                if (cursor.container.checkIsEmpty()) {
                    if (skipEmptyCheck) {
                        this.commands.perform(new AddNewContainerCommand(this.model, length));
                    } else {
                        super.handleLinebreakAction(action);
                    }
                } else {
                    (skipEmptyCheck || !cursor.container.checkIsEmpty()) &&
                        this.commands.perform(new AddNewContainerCommand(this.model, length));
                }
            } else {
                const nextContainer = containers[index + 1];
                if (nextContainer.checkIsEmpty()) {
                    cursor.moveToStart(nextContainer);
                } else {
                    (skipEmptyCheck || !cursor.container.checkIsEmpty()) &&
                        this.commands.perform(new AddNewContainerCommand(this.model, index + 1));
                }
            }
        }
    }

    protected override handleRemoveOutFirst(): void {
        const containers = this.model.getContainersToMoveCursorBetween();
        const container = this.cursor.container;
        const index = containers.indexOf(this.cursor.container);

        if (container.checkIsEmpty()) {
            if (containers.length === 1) {
                this.commands.perform(new RemoveCurrentCommand(this.model));
            } else if (index === 0) {
                this.cursor.moveToEnd(containers[containers.length - 1]);
            } else {
                this.commands.perform(new RemoveContainerCommand(this.model, index));
            }
        } else {
            this.cursor.moveToEnd(container);
        }
    }

    protected override handleRemoveOutLast(): void {}

    protected override handleEquationsSetAction({ variant }: TEquationsSetAction): void {
        const serializedEquationsSet = createSerializedEquationsSet(variant);

        this.commands.perform(
            this.selection.isSelectedSomething
                ? new WrapSelectedIntoEquationsSetCommand(this.model, serializedEquationsSet)
                : new InsertIntoCursorCommand(this.model, serializedEquationsSet)
        );
    }
}
