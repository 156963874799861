import type { TAnyAction } from "../types";

export type TIntegralAction = { type: "integral"; arg?: string };

export function createIntegralAction(arg?: string): TIntegralAction {
    return { type: "integral", arg };
}

export function isIntegralAction(action: TAnyAction): action is TIntegralAction {
    return action.type === "integral";
}
