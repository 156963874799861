import { computed, makeObservable, observable } from "mobx";
import { createTransformer } from "mobx-utils";

import { areSamePoints, hasMatchInPointSet } from "@viuch/geometry-lib/check-geometry";
import { getLineFromFragment } from "@viuch/geometry-lib/solvers";

import type { BaseElement } from "../../elements";
import type { Figure2DController } from "../../Figure2DController";
import type { IModelVisitor } from "../../models/BaseModel";
import type { IUserPointer } from "../../viewport/types";
import type { TEllipse, TFragment, TPoint } from "@viuch/geometry-lib/types";
import type React from "react";

import { DotElement } from "../../elements/dot";
import { ElementColor } from "../../elements/ElementColor";
import { LineElement } from "../../elements/line";
import { MoveDotService } from "../../services/MoveDotService";
import { BaseFlow } from "../BaseFlow";
import { SelectElementsFlow } from "../select-elements";
import { createMapModelToElements, opacifyColor, z } from "../utils";

export class MoveDotFlow extends BaseFlow {
    private readonly initialPoint: TPoint;
    private readonly moveDotService: MoveDotService;
    currentPoint: TPoint;
    wasMoved: boolean;
    extraSnap: {
        fragments: TFragment[];
        points: TPoint[];
        ellipses: TEllipse[];
    };

    constructor(data: Figure2DController, point: TPoint) {
        super(data);

        this.moveDotService = new MoveDotService(data);
        this.wasMoved = false;
        this.extraSnap = { ellipses: [], fragments: [], points: [] };

        const { x, y } = point;
        this.initialPoint = { x, y };
        this.currentPoint = { x, y };

        makeObservable(this, {
            currentPoint: observable,
            wasMoved: observable,
            tempElements: computed,
        });
    }

    attach(): void {
        this.viewport.disable();
        this.snap.addViewportGrid().addFigureModelsPoints();

        const extraSnapVisitor: IModelVisitor<void> = {
            withParallelFragment: ({ a, b }) => {
                if (!areSamePoints(a, b) && hasMatchInPointSet([a, b], this.initialPoint)) {
                    const longFragment = getLineFromFragment(a, b);

                    this.extraSnap.fragments.push(longFragment);
                    this.snap.addFragment(longFragment);
                }
            },
            withPoint: () => void 0,
            withFragment: () => void 0,
            withFragmentDashed: () => void 0,
            withVector: () => void 0,
            withVectorDashed: () => void 0,
            withLabelPoint: () => void 0,
            withLabelFragment: () => void 0,
            withLabelAngle: () => void 0,
            withLine: () => void 0,
            withConstraintLine: () => void 0,
            withComputedIntersectionPoints: () => void 0,
            withBisection: () => void 0,
            withMedian: () => void 0,
            withAltitude: () => void 0,
            withEqualAngles: () => void 0,
            withEqualSegments: () => void 0,
            withEllipse: () => void 0,
            withInscribedCircle: () => void 0,
            withCircumscribedCircle: () => void 0,
            withTangentLine: () => void 0,
            withMiddleLine: () => void 0,
            withTangentLineOnCircle: () => void 0,
        };

        for (const model of this.figure.models) {
            model.accept(extraSnapVisitor);
        }
    }

    dispose(): void {
        this.snap.clean();
    }

    mapModels = createTransformer(createMapModelToElements(opacifyColor));

    protected renderElements(): BaseElement[] {
        return [...this.renderExtraElements(), ...this.figure.models.flatMap(this.mapModels), ...this.tempElements];
    }

    *renderExtraElements(): Iterable<BaseElement> {
        let i = 0;
        const color = ElementColor.Util;

        for (const { x, y } of this.extraSnap.points) {
            yield new DotElement({
                id: `extraSnap_${i++}`,
                color,
                overrideRenderOrder: z.points.virtual,
                x,
                y,
            });
        }

        for (const { a, b } of this.extraSnap.fragments) {
            yield new LineElement({
                id: `extraSnap_${i++}`,
                color,
                overrideRenderOrder: z.fragments.virtual,
                thin: true,
                x1: a.x,
                y1: a.y,
                x2: b.x,
                y2: b.y,
            });
        }
    }

    get tempElements(): BaseElement[] {
        const { x, y } = this.currentPoint;
        const color = this.wasMoved ? ElementColor.Building : ElementColor.Selected;
        return [
            DotElement.create({
                x,
                y,
                color,
                id: "temp",
                overrideRenderOrder: 0,
            }),
        ];
    }

    override handleViewportPointerEvent(e: React.PointerEvent, pointer: IUserPointer): void {
        switch (e.type) {
            case "pointermove":
                return this.onMove(pointer);
            case "pointerup":
                return this.onUp();
            case "pointercancel":
                return this.onCancel();
        }
    }

    private onUp() {
        if (this.wasMoved) {
            if (areSamePoints(this.initialPoint, this.currentPoint)) {
                return this.nextFlow();
            }
            this.moveDotService.moveDot(this.initialPoint, this.currentPoint);
            this.nextFlow();
        } else {
            this.selection.toggleDot(this.initialPoint);
            this.nextFlow((data) => new SelectElementsFlow(data));
        }
    }

    private onMove(pointer: IUserPointer) {
        const point = this.snap.snap(pointer.canvas, pointer.canvas);

        if (!areSamePoints(point, this.currentPoint)) {
            this.wasMoved = true;
            this.currentPoint = point;
        }
    }

    private onCancel() {
        this.nextFlow();
    }
}
