import type { Figure2DController } from "./Figure2DController";

export type THandlersObject = {
    onResetAsync?(): Promise<boolean>;
    returnFocus?(): void;
};

export class FigureViewModel {
    readonly controller: Figure2DController;

    constructor(controller: Figure2DController) {
        this.controller = controller;
    }

    static initialize(controller: Figure2DController) {
        const instance = new FigureViewModel(controller);
        instance.initialize();
        return instance;
    }

    initialize() {
        this.controller.initialize();
    }

    dispose() {
        this.controller.dispose();
    }
}
