import React from "react";

import addPoint from "@viuch/assets/icons/graph2d/add-point.svg";
import cursorIcon from "@viuch/assets/icons/graph2d/cursor.svg";
import flipIcon from "@viuch/assets/icons/graph2d/flip.svg";
import plusIcon from "@viuch/assets/icons/graph2d/plus.svg";
import trashBin from "@viuch/assets/icons/trash-bin.svg";
import { buildFunc } from "@viuch/geometry-lib/transformations/functions";
import { createSerializedContainer } from "@viuch/math-editor";
import { createSerializedBracket } from "@viuch/math-editor/elements/bracket/utils";
import { createSerializedSymbol } from "@viuch/math-editor/elements/symbol/utils";
import { safeCheckNever } from "@viuch/utils/debug";

import type { IIntervalsCollection } from "./model/BaseGraphItem";
import type { GraphFunctionInterval } from "./model/GraphFunctionInterval";
import type { BaseGraphViewItem } from "./rendering/BaseGraphViewItem";
import type { IRootToolbarElement } from "./toolbar/elements/RootToolbarElement";
import type { IButtonToolbarElement } from "./toolbar/elements/ToolbarButton";
import type { IMenuToolbarElement } from "./toolbar/elements/ToolbarMenu";
import type { IButtonMenuToolbarElement } from "./toolbar/elements/ToolbarMenuButton";
import type { IToolbarElement } from "./toolbar/toolbarTypes";
import type { Graph2DView } from "../core/Graph2DView";

import { AxisGraphItem } from "../models/AxisGraphItem";
import { FormulaEvalFunctionGraphItem } from "../models/FormulaEvalFunctionGraphItem";
import { GenericViewGraphItem } from "../models/GenericViewGraphItem";
import { HighlightEvalFunctionGraphItem } from "../models/HighlightEvalFunctionGraphItem";
import { PointGraphItem } from "../models/PointGraphItem";
import { AxisGraphViewItem } from "../view-models/AxisGraphViewItem";
import { FunctionIntervalViewItem } from "../view-models/FunctionIntervalViewItem";
import { LabelViewItem } from "../view-models/LabelViewItem";
import { PointViewItem } from "../view-models/PointViewItem";
import { AxisGraphView } from "../views/axes/AxisGraphView";
import { FunctionIntervalView } from "../views/FunctionIntervalView";
import { LabelView } from "../views/LabelView";
import { PointView } from "../views/PointView";

import { EditorFlow } from "./flows/view/EditorFlow";
import { RootToolbarElement } from "./toolbar/elements/RootToolbarElement";
import { ToolbarButton } from "./toolbar/elements/ToolbarButton";
import { ToolbarMenu } from "./toolbar/elements/ToolbarMenu";
import { ToolbarMenuButton } from "./toolbar/elements/ToolbarMenuButton";

export function configureGraph2DKernel(view: Graph2DView): void {
    const { renderer, preRenderer, settings, toolbar, viewport, history, events, flows } = view;

    flows.registerFlowExtension((flow) => {
        if (flow instanceof EditorFlow) {
            flow.addToolbarButtonHandler<IButtonToolbarElement>("cursor", (button) => {
                const somethingSelected = flow.selectedElements.length > 0;

                return {
                    ...button,
                    enabled: somethingSelected,
                    active: somethingSelected,
                    onClick: () => {
                        flow.selectedElements.clear();
                    },
                };
            });
            flow.addToolbarButtonHandler<IButtonToolbarElement>("delete", (button) => {
                // TODO: поменять способ обнаружения
                const hasSelectedElementsToDelete = flow.selectedElements.length > 0;

                return {
                    ...button,
                    enabled: hasSelectedElementsToDelete,
                    onClick: () => {
                        let removedSomething = false;

                        for (const selectedElement of [...flow.selectedElements]) {
                            switch (selectedElement.type) {
                                case "point": {
                                    const { x, y } = selectedElement;

                                    flow.removeSelectedElement(selectedElement);

                                    const allModels = [...view.model.dataItems.getAll()];
                                    for (const model of allModels) {
                                        if (model instanceof FormulaEvalFunctionGraphItem) {
                                            const affectedIntervals = model.funcIntervals.filter(({ interval }) => {
                                                const { start, end } = interval;

                                                return (
                                                    (start.value === x && start.limit === y) ||
                                                    (end.value === x && end.limit === y)
                                                );
                                            });

                                            // у сущностей должно быть одинаковое дерево преобразований
                                            // TODO: надо сгруппировать интервалы по их деревьям преобразований.

                                            const map = affectedIntervals.reduce(
                                                (acc, { interval, path }) => {
                                                    const parent: IIntervalsCollection =
                                                        path.at(-1)?.transformation ?? model;

                                                    let collection = acc.get(parent);
                                                    if (!collection) {
                                                        collection = [];
                                                        acc.set(parent, collection);
                                                    }

                                                    collection.push(interval);
                                                    return acc;
                                                },
                                                new Map<IIntervalsCollection, GraphFunctionInterval[]>([[model, []]])
                                            );

                                            for (const [parent, intervals] of map) {
                                                // теоретически {intervals} можно объединить, если это не противоречит функции.
                                                // так, нельзя объединять выколотые точки на корневой функции
                                            }
                                        }

                                        if (model instanceof PointGraphItem) {
                                            if (model.x === x && model.y === y) {
                                                view.model.dataItems.remove(model);
                                                removedSomething = true;
                                            }
                                        }
                                    }

                                    break;
                                }
                                case "graph-interval": {
                                    const { model, interval } = selectedElement;

                                    model.removeIntervalFromTree(interval);
                                    flow.removeSelectedElement(selectedElement);

                                    if (model.funcIntervals.length === 0) {
                                        view.model.dataItems.remove(model);
                                    }

                                    removedSomething = true;

                                    break;
                                }
                                default: {
                                    safeCheckNever(selectedElement);
                                }
                            }
                        }

                        flow.clearSelectedElements();
                        if (removedSomething) {
                            history.commit();
                        }
                    },
                };
            });
        }
    });

    preRenderer //
        .registerTransformer(FormulaEvalFunctionGraphItem, (model) => {
            const { sourceFunc, funcIntervals } = model;

            return funcIntervals.flatMap(({ interval, path }) => {
                const transformers = path.map((p) => p.transformation.transformer);
                const { color, startLabels, endLabels, sign } = interval;
                const transformedFunc = buildFunc(sourceFunc, ...transformers.map((t) => t.transformFunction));

                const puncture = sign === ">" || sign === "<";

                const fill = (
                    {
                        "<=": "down",
                        "<": "down",
                        "=": null,
                        ">": "up",
                        ">=": "up",
                    } as const
                )[sign];

                const views: BaseGraphViewItem[] = [
                    new FunctionIntervalViewItem(model, transformedFunc, interval, color, 2, puncture, fill),
                ];

                const { start, end } = interval;

                for (const { point, labels } of [
                    { point: start, labels: startLabels },
                    { point: end, labels: endLabels },
                ]) {
                    const { value: x, limit: y, include } = point;
                    if (Number.isFinite(x) && Number.isFinite(y)) {
                        views.push(new PointViewItem(model, x, y, 8, color, include));

                        const { x: labelX, y: labelY, label } = labels;

                        if (labelX && labelY) {
                            const label = createSerializedContainer([
                                createSerializedBracket("round", false),
                                ...labelX.elements,
                                createSerializedSymbol("semicolon"),
                                ...labelY.elements,
                                createSerializedBracket("round", true),
                            ]);
                            views.push(new LabelViewItem(x, y, label, "top", color, model));
                        }

                        if (label) {
                            views.push(new LabelViewItem(x, y, label, "bottom", color, model));
                        }
                    }
                }

                return views;
            });
        })
        .registerTransformer(HighlightEvalFunctionGraphItem, (model) => {
            const { sourceFunc, funcIntervals, color } = model;

            const useBlur = true;

            return funcIntervals.flatMap(({ interval, path }) => {
                const transformers = path.map((p) => p.transformation.transformer);
                const { startLabels, endLabels, sign } = interval;
                const transformedFunc = buildFunc(sourceFunc, ...transformers.map((t) => t.transformFunction));

                const puncture = sign === ">" || sign === "<";

                const fill = (
                    {
                        "<=": "down",
                        "<": "down",
                        "=": null,
                        ">": "up",
                        ">=": "up",
                    } as const
                )[sign];

                const views: BaseGraphViewItem[] = [
                    new FunctionIntervalViewItem(model, transformedFunc, interval, color, 2, puncture, fill, useBlur),
                ];

                const { start, end } = interval;

                for (const { point, labels } of [
                    { point: start, labels: startLabels },
                    { point: end, labels: endLabels },
                ]) {
                    const { value: x, limit: y, include } = point;
                    if (Number.isFinite(x) && Number.isFinite(y)) {
                        views.push(new PointViewItem(model, x, y, 8, color, include, useBlur));

                        const { x: labelX, y: labelY, label } = labels;

                        if (labelX && labelY) {
                            const label = createSerializedContainer([
                                createSerializedBracket("round", false),
                                ...labelX.elements,
                                createSerializedSymbol("semicolon"),
                                ...labelY.elements,
                                createSerializedBracket("round", true),
                            ]);
                            views.push(new LabelViewItem(x, y, label, "top", color, model));
                        }

                        if (label) {
                            views.push(new LabelViewItem(x, y, label, "bottom", color, model));
                        }
                    }
                }

                return views;
            });
        })
        .registerTransformer(PointGraphItem, (model) => {
            const { x, y, color, included, labelX, labelY, label } = model;

            const views: BaseGraphViewItem[] = [new PointViewItem(model, x, y, 8, color, included)];

            if (Number.isFinite(x) && Number.isFinite(y)) {
                views.push(new PointViewItem(model, x, y, 8, color, included));

                if (labelX && labelY) {
                    const label = createSerializedContainer([
                        createSerializedBracket("round", false),
                        ...labelX.elements,
                        createSerializedSymbol("semicolon"),
                        ...labelY.elements,
                        createSerializedBracket("round", true),
                    ]);
                    views.push(new LabelViewItem(x, y, label, "top", color, model));
                }

                if (label) {
                    views.push(new LabelViewItem(x, y, label, "bottom", color, model));
                }
            }

            return views;
        })
        .registerTransformer(GenericViewGraphItem, ({ viewItems }) => {
            return viewItems;
        })
        .registerTransformer(AxisGraphItem, (item) => {
            return item.getAxesView(view);
        });

    preRenderer.registerFilter(function* (items): Iterable<BaseGraphViewItem> {
        const map = new Map<`${"top" | "bottom"}_${number}_${number}`, LabelViewItem[]>();

        for (const item of items) {
            if (item instanceof LabelViewItem) {
                const key = `${item.position}_${item.x}_${item.y}` as const;

                let collection = map.get(key);
                if (!collection) {
                    collection = [];
                    map.set(key, collection);
                }

                collection.push(item);
            } else {
                yield item;
            }
        }

        for (const [, labels] of map) {
            if (labels.length === 0) continue;

            if (labels.length === 1) yield labels[0];

            if (labels.length > 1) {
                yield labels[0];
            }
        }
    });

    renderer //
        .registerRenderer(FunctionIntervalViewItem, (item) => (
            <FunctionIntervalView
                item={item}
                viewport={viewport}
                settings={settings}
                events={events}
            />
        ))
        .registerRenderer(PointViewItem, (item) => (
            <PointView
                item={item}
                viewport={viewport}
                settings={settings}
                events={events}
            />
        ))
        .registerRenderer(LabelViewItem, (item) => (
            <LabelView
                item={item}
                viewport={viewport}
                settings={settings}
            />
        ))
        .registerRenderer(AxisGraphViewItem, (axis) => (
            <AxisGraphView
                axis={axis}
                viewport={viewport}
                settings={settings}
                color="var(--blue)"
            />
        ));

    toolbar //
        .registerPreset<IRootToolbarElement>("default", {
            type: "root",
            key: "root",
            topElements: ((): IButtonToolbarElement[] => [
                {
                    type: "button",
                    iconSrc: trashBin,
                    key: "delete",
                },
            ])(),
            elements: (() => {
                const items: IToolbarElement[] = [];

                items.push<IButtonToolbarElement>({
                    type: "button",
                    key: "cursor",
                    iconSrc: cursorIcon,
                });

                items.push<IButtonMenuToolbarElement>({
                    type: "button-menu",
                    key: "add",
                    enabled: true,
                    icon: plusIcon,
                    content: ((): IMenuToolbarElement => ({
                        type: "menu",
                        buttons: ((): IButtonToolbarElement[] => [
                            {
                                type: "button",
                                key: "add-point",
                                iconSrc: addPoint,
                                label: "Точка",
                            },
                        ])(),
                    }))(),
                });

                items.push<IButtonMenuToolbarElement>({
                    type: "button-menu",
                    icon: flipIcon,
                    key: "transform",
                    content: ((): IMenuToolbarElement => ({
                        type: "menu",
                        buttons: ((): IButtonToolbarElement[] => [])(),
                    }))(),
                });

                return items;
            })(),
        });

    toolbar //
        .registerElementRenderer<IRootToolbarElement>("root", (element, { toolbar, className, rootRef }) => (
            <RootToolbarElement
                toolbar={toolbar}
                elements={element.elements}
                topElements={element.topElements}
                rootRef={rootRef}
                className={className}
            />
        ))
        .registerElementRenderer<IButtonToolbarElement>("button", (element, { className }) => (
            <ToolbarButton
                element={element}
                className={className}
            />
        ))
        .registerElementRenderer<IButtonMenuToolbarElement>(
            "button-menu",
            (element, { toolbar, className, rootRef }) => (
                <ToolbarMenuButton
                    element={element}
                    className={className}
                    rootRef={rootRef}
                    toolbar={toolbar}
                />
            )
        )
        .registerElementRenderer<IMenuToolbarElement>("menu", (element, { toolbar, className, rootRef }) => (
            <ToolbarMenu
                element={element}
                toolbar={toolbar}
                rootRef={rootRef}
                className={className}
            />
        ));
}
