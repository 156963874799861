import { generateId } from "@viuch/shared/utils/data";

import type { EllipseSelection, FragmentSelection, PointSelection, VectorSelection } from "./items";

export abstract class BaseSelection {
    readonly $$instance_id = generateId();

    protected constructor() {}

    abstract accept<R>(visitor: ISelectionElementVisitor<R>): R;
}

export interface ISelectionElementVisitor<R> {
    withFragment: (fragment: FragmentSelection) => R;
    withPoint: (point: PointSelection) => R;
    withEllipse: (ellipse: EllipseSelection) => R;
    withVector: (vector: VectorSelection) => R;
}
