import cytoscape from "cytoscape";
import cytoscapeDagre from "cytoscape-dagre";
import cytoscapeEdgehandles from "cytoscape-edgehandles";
import { configure } from "mobx";

import { configureEditorWebComponents } from "@viuch/complex-editor/editors/format/plugins/MathEditorWebComponentPlugin/utils";

configure({
    useProxies: "always",
    enforceActions: "always",
    computedRequiresReaction: true,
    // observableRequiresReaction: true,
    // reactionRequiresObservable: true,
    disableErrorBoundaries: false,
});

cytoscape.use(cytoscapeDagre);
cytoscape.use(cytoscapeEdgehandles);

configureEditorWebComponents();
