import React from "react";

import type { LinebreakModel } from "./LinebreakModel";
import type { TElementProps } from "../../types/element";

import { useMathEditorElementRef } from "../../hooks";

import styles from "./LinebreakElement.module.scss";

export const LinebreakElement: React.VFC<TElementProps<LinebreakModel>> = ({ elementModel }) => {
    const ref = useMathEditorElementRef(elementModel);

    return (
        <>
            <span className={styles.caret} />
            <div className={styles.newline} />
            <span
                className={styles.caret}
                ref={ref}
            />
        </>
    );
};
