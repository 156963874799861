import { action, makeObservable, observable } from "mobx";

export class StepDescriptionTable {
    readonly id: number;
    @observable name: string;

    constructor(id: number, name: string) {
        this.id = id;
        this.name = name;

        makeObservable(this);
    }

    @action.bound
    setName(name: string) {
        this.name = name;
    }
}
