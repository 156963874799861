import type { TAngle, TFragment, TLineCharacteristic, TPoint } from "./types";

const FRAC_DIGITS = 5;

export function getCoordinateHash(n: number): string {
    return n.toFixed(FRAC_DIGITS);
}

export function getPointHash(point: TPoint) {
    return `${getCoordinateHash(point.x)}__${getCoordinateHash(point.y)}`;
}

export function getFragmentHashes(fragment: TFragment): [string, string] {
    const a = getPointHash(fragment.a);
    const b = getPointHash(fragment.b);

    return [`${a}__${b}`, `${b}__${a}`];
}

export function getAngleHash(angle: TAngle): string {
    const vertex = getPointHash(angle.vertex);
    const start = getPointHash(angle.start);
    const end = getPointHash(angle.end);

    return `${vertex}__${start}__${end}`;
}

export function getLineCharacteristicHash({ angle, normalVectorSquaredLength }: TLineCharacteristic): string {
    return `${getCoordinateHash(angle)}__${getCoordinateHash(normalVectorSquaredLength)}`;
}
