import React from "react";

import { useConst } from "./useConst";

const HOLD_DEFAULT_DELAY = 1000;
const HOLD_DEFAULT_DISTANCE = 15;

type TUseHoldParams = {
    holdDelay?: number;
    holdDistance?: number;
    onClick(downEvent: React.PointerEvent, upEvent: React.PointerEvent): void;
    onHold(downEvent: React.PointerEvent): void;
    onMove(downEvent: React.PointerEvent, moveEvent: React.PointerEvent): void;
};

type TUseHold = {
    onPointerDown(e: React.PointerEvent): void;
    onPointerUp(e: React.PointerEvent): void;
    onPointerMove(e: React.PointerEvent): void;
    onPointerCancel(e: React.PointerEvent): void;
};

export function useHold(params: TUseHoldParams): TUseHold {
    const { holdDelay = HOLD_DEFAULT_DELAY, holdDistance = HOLD_DEFAULT_DISTANCE, onMove, onHold, onClick } = params;
    const downEventRef = React.useRef<{
        downEvent: React.PointerEvent;
        timer: number;
        state: "wait" | "moving" | "held";
    }>();

    return useConst(() => ({
        onPointerDown(downEvent: React.PointerEvent) {
            if (!downEvent.isPrimary) return;
            downEvent.currentTarget.setPointerCapture(downEvent.pointerId);
            const timer = window.setTimeout(() => {
                const data = downEventRef.current;
                if (!data) return;

                downEventRef.current = void 0;
                data.state = "held";
                onHold(downEvent);
            }, holdDelay);
            downEventRef.current = { downEvent, timer, state: "wait" };
        },
        onPointerMove(moveEvent: React.PointerEvent) {
            if (!moveEvent.isPrimary) return;
            const data = downEventRef.current;
            if (!data) return;

            switch (data.state) {
                case "wait": {
                    const offsetX = moveEvent.clientX - data.downEvent.clientX;
                    const offsetY = moveEvent.clientY - data.downEvent.clientY;
                    const distance = Math.sqrt(Math.pow(offsetX, 2) + Math.pow(offsetY, 2));
                    if (distance > holdDistance) {
                        window.clearTimeout(data.timer);
                        data.state = "moving";
                        onMove(data.downEvent, moveEvent);
                    }
                    break;
                }
                case "moving":
                    onMove(data.downEvent, moveEvent);
                    break;
            }
        },
        onPointerUp(upEvent: React.PointerEvent) {
            if (!upEvent.isPrimary) return;
            const data = downEventRef.current;
            if (!data) return;

            if (data.state === "wait") {
                onClick(data.downEvent, upEvent);
            }

            window.clearTimeout(data.timer);
            downEventRef.current = void 0;
        },
        onPointerCancel(e: React.PointerEvent) {
            if (!e.isPrimary) return;
            const data = downEventRef.current;
            if (!data) return;

            window.clearTimeout(data.timer);
            downEventRef.current = void 0;
        },
    }));
}
