import cn from "classnames";

import type { TAlphabetBlockProps } from "./AlphabetBlock.types";
import type { VFC } from "react";

import { createShiftAction } from "../../../../../actions/shift";
import { KeyboardIcon } from "../../../../keyboard/elements";
import { DesktopKeyboardButton } from "../../elements";
import { BaseKeyboardBlock } from "../BaseKeyboardBlock";

import { alphabetsMap } from "./AlphabetBlock.constants";

import styles from "./AlphabetBlock.module.scss";

const AlphabetBlock: VFC<TAlphabetBlockProps> = ({
    onClick,
    onShiftDoubleClick,
    selectedTab,
    isShiftPressed,
    isCapsPressed,
    keyboardService,
}) => {
    const { topChars, bottomChars } = alphabetsMap[selectedTab];

    return (
        <div className={styles.block}>
            <BaseKeyboardBlock
                onClick={onClick}
                columns={8}
                rows={3}
                mathChars={topChars}
                blockClassName={styles[`${selectedTab}Top`]}
                isShiftPressed={isShiftPressed}
                keyboardService={keyboardService}
            />

            <div
                className={cn(styles.bottomBlock, {
                    [styles.ruBottomBlock]: selectedTab === "ru",
                })}
            >
                <DesktopKeyboardButton
                    action={createShiftAction()}
                    onClick={onClick}
                    onShiftDoubleClick={onShiftDoubleClick}
                    className={cn({
                        [styles.pressedShift]: isShiftPressed,
                    })}
                    keyboardService={keyboardService}
                    tags={["alphabet"]}
                >
                    <KeyboardIcon iconName={isCapsPressed ? "caps" : "shift"} />
                </DesktopKeyboardButton>

                <BaseKeyboardBlock
                    onClick={onClick}
                    mathChars={bottomChars}
                    blockClassName={styles[`${selectedTab}Bottom`]}
                    isShiftPressed={isShiftPressed}
                    keyboardService={keyboardService}
                />
            </div>
        </div>
    );
};

export default AlphabetBlock;
