import { action, makeObservable, observable } from "mobx";

import type { IBaseEditor } from "../base";
import type { TComplexEditorDataUnion, TComplexEditorUnion } from "../types";

import { createEditorByData } from "../../utils/data";
import { FormatEditor } from "../format";

export type TComplexEditorOptions = {};

export class ComplexEditor {
    readonly options?: TComplexEditorOptions;
    @observable editors: IBaseEditor[];

    private constructor(editors: IBaseEditor[], options?: TComplexEditorOptions) {
        this.editors = editors;
        this.options = options;

        makeObservable(this);
    }

    static createEmpty(options?: TComplexEditorOptions): ComplexEditor {
        return new ComplexEditor([new FormatEditor()], options);
    }

    static createFromData({ data }: TComplexEditorUnion, options?: TComplexEditorOptions): ComplexEditor {
        try {
            const editors = ComplexEditor.createEditorsFromData(data);
            return new ComplexEditor(editors, options);
        } catch (e) {
            console.error("Editor init failed", e);
            return ComplexEditor.createEmpty();
        }
    }

    @action.bound
    addEditorByIndex(index: number, editor: IBaseEditor): void {
        if (index >= 0 && index <= this.editors.length) {
            this.editors.splice(index, 0, editor);
        }
    }

    @action.bound
    removeEditorByIndex(index: number): void {
        if (index >= 0 && index < this.editors.length) {
            this.editors.splice(index, 1);
        }
    }

    @action.bound
    setData({ data }: TComplexEditorUnion): void {
        this.editors = ComplexEditor.createEditorsFromData(data);
    }

    async serialize(): Promise<TComplexEditorUnion> {
        const data = await Promise.all(this.editors.map((editor) => editor.serialize()));

        return { data };
    }

    checkIsEmpty(): boolean {
        for (const editor of this.editors) {
            if (!editor.checkIsEmpty()) {
                return false;
            }
        }

        return true;
    }

    static createEditorsFromData(data: TComplexEditorDataUnion[]): IBaseEditor[] {
        return data.map((serializedData) => createEditorByData(serializedData));
    }
}
