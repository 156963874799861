import { observer } from "mobx-react-lite";

import type { DifferentialModel } from "./DifferentialModel";
import type { TElementProps } from "../../types/element";
import type { VFC } from "react";

import { ContainerElement } from "../../core/container";
import { RoundBrackets } from "../../core/dynamic-brackets";
import { BaseFractionElement } from "../base/base-fraction";

import styles from "./DifferentialElement.module.scss";

export const DifferentialElement: VFC<TElementProps<DifferentialModel>> = observer(function DifferentialElement({
    elementModel,
}) {
    return (
        <span className={styles.inline}>
            <BaseFractionElement
                elementModel={elementModel}
                numerator="d"
                denominator={
                    <>
                        <span className={styles.denominatorChar}>d</span>
                        <ContainerElement
                            containerModel={elementModel.denominator}
                            inline
                        />
                    </>
                }
            />
            <RoundBrackets>
                <ContainerElement
                    containerModel={elementModel.content}
                    inline
                />
            </RoundBrackets>
        </span>
    );
});
