import type { TEqualityActionChar } from "../elements/equality/types";
import type { TAnyAction } from "../types";

export type TEqualityAction = {
    type: "equals";
    char: TEqualityActionChar;
};

export function createEqualityAction(char: TEqualityActionChar): TEqualityAction {
    return { type: "equals", char };
}

export function isEqualityAction(action: TAnyAction): action is TEqualityAction {
    return action.type === "equals";
}
