import { action, computed, makeObservable, observable } from "mobx";

import type { BaseMultichoiceInstrumentSettingsField } from "./BaseMultichoiceInstrumentSettingsField";
import type { TMultichoiceFieldTypes, TMultichoiceInterfaces } from "./types";

import { FormulaMultichoiceInstrumentSettingsField } from "./choices/FormulaMultichoiceInstrumentSettingsField";
import { ImageMultichoiceInstrumentSettingsField } from "./choices/ImageMultichoiceInstrumentSettingsField";
import { TextMultichoiceInstrumentSettingsField } from "./choices/TextMultichoiceInstrumentSettingsField";

export class MultichoiceInstrumentSettings {
    @observable.shallow fields: BaseMultichoiceInstrumentSettingsField[];
    @observable.ref inputType: TMultichoiceInterfaces;
    @observable.ref visibleAnswersCount: number;

    constructor(
        fields: BaseMultichoiceInstrumentSettingsField[],
        inputType: TMultichoiceInterfaces,
        visibleAnswersCount: number
    ) {
        this.fields = fields.slice();
        this.inputType = inputType;
        this.visibleAnswersCount = visibleAnswersCount;

        makeObservable(this);
    }

    clone(): MultichoiceInstrumentSettings {
        return new MultichoiceInstrumentSettings(
            this.fields.map((field) => field.clone()),
            this.inputType,
            this.visibleAnswersCount
        );
    }

    @computed get minVisibleAnswers() {
        return Math.max(1, this.fields.filter((f) => f.checked).length);
    }

    @computed get maxVisibleAnswers() {
        return Math.max(1, this.fields.length);
    }

    @action.bound
    setVisibleAnswersCount(count: number) {
        const { minVisibleAnswers, maxVisibleAnswers } = this;

        this.visibleAnswersCount = Math.min(maxVisibleAnswers, Math.max(minVisibleAnswers, count));
    }

    @action.bound
    addField(type: TMultichoiceFieldTypes) {
        const shouldIncrement = this.visibleAnswersCount === this.fields.length;

        switch (type) {
            case "text":
                this.fields.push(TextMultichoiceInstrumentSettingsField.createEmpty());
                break;
            case "formula":
                this.fields.push(FormulaMultichoiceInstrumentSettingsField.createEmpty());
                break;
            case "image":
                this.fields.push(ImageMultichoiceInstrumentSettingsField.createEmpty());
                break;
        }

        if (shouldIncrement) {
            this.setVisibleAnswersCount(this.visibleAnswersCount + 1);
        }
    }

    @action.bound
    changeOrder(field: BaseMultichoiceInstrumentSettingsField, direction: "up" | "down") {
        const i = this.fields.indexOf(field);

        if (i === -1 || (direction === "up" ? i === 0 : i === this.fields.length - 1)) return;

        const iNext = direction == "up" ? i - 1 : i + 1;

        [this.fields[i], this.fields[iNext]] = [this.fields[iNext], this.fields[i]];
    }

    @action.bound
    setInputType(inputType: TMultichoiceInterfaces) {
        this.inputType = inputType;

        for (const field of this.fields) {
            field.setChecked(false);
        }
    }

    @action.bound
    removeField(field: BaseMultichoiceInstrumentSettingsField) {
        this.fields.remove(field);

        this.setVisibleAnswersCount(this.visibleAnswersCount);
    }

    @action.bound
    setFieldChecked(field: BaseMultichoiceInstrumentSettingsField, checked: boolean) {
        if (this.inputType === "radio") {
            for (const field of this.fields) {
                field.setChecked(false);
            }
        }

        field.setChecked(checked);
        this.setVisibleAnswersCount(this.visibleAnswersCount);
    }

    static createEmpty() {
        return new MultichoiceInstrumentSettings([], "checkbox", 1);
    }
}
