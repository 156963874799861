import { action, makeObservable, observable } from "mobx";

import { compareArrays, compareSimple } from "@viuch/shared/utils/data";
import { sortAsc } from "@viuch/shared/utils/data/sort";

export class ExamTask implements ExamTask.Init {
    readonly id: number;
    @observable name: string;
    @observable score: number;
    @observable problemIds: number[];
    @observable themeIds: number[];

    constructor(init: ExamTask.Init) {
        this.id = init.id;
        this.score = init.score;
        this.problemIds = init.problemIds.slice();
        this.themeIds = init.themeIds.slice();
        this.name = init.name;

        makeObservable(this);
    }

    @action.bound
    setScore(score: number) {
        this.score = score;
    }

    @action.bound
    removeTheme(themeId: number) {
        const i = this.themeIds.indexOf(themeId);
        if (i !== -1) {
            this.themeIds.splice(i, 1);
        }
    }

    @action.bound
    addTheme(themeId: number) {
        if (!this.themeIds.includes(themeId)) {
            this.themeIds.push(themeId);
            this.themeIds.sort(sortAsc);
        }
    }

    @action.bound
    addProblem(id: number) {
        if (!this.problemIds.includes(id)) {
            this.problemIds.push(id);
            this.problemIds.sort(sortAsc);
        }
    }

    @action.bound
    removeProblem(id: number) {
        const i = this.problemIds.indexOf(id);
        if (i !== -1) {
            this.problemIds.splice(i, 1);
        }
    }

    @action.bound
    setName(name: string) {
        this.name = name;
    }

    equalsTo(other: ExamTask.Init): boolean {
        switch (false) {
            case compareSimple(this.score, other.score):
            case compareArrays(this.themeIds, other.themeIds):
            case compareArrays(this.problemIds, other.problemIds):
            case compareSimple(this.name, other.name):
                return false;
        }

        return true;
    }

    clone() {
        return new ExamTask(this);
    }
}

export namespace ExamTask {
    export type Init = {
        id: number;
        score: number;
        problemIds: number[];
        themeIds: number[];
        name: string;
    };
}
