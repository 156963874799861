import { makeObservable, observable } from "mobx";

import { copyPoint } from "@viuch/geometry-lib/factories";
import { middlePoint } from "@viuch/geometry-lib/vectors";

import type { IElementVisitor } from "../BaseElement";
import type { ElementColor } from "../ElementColor";
import type { TPoint } from "@viuch/geometry-lib/types";

import { BaseElement } from "../BaseElement";

export interface IStrokeElement {
    id: string;
    a: TPoint;
    b: TPoint;
    segments: number;
    color?: ElementColor;
    overrideRenderOrder: number;
}

export class StrokeElement extends BaseElement implements IStrokeElement {
    a: TPoint;
    b: TPoint;
    segments: number;

    constructor(data: IStrokeElement) {
        super(data);

        this.a = copyPoint(data.a);
        this.b = copyPoint(data.b);
        this.segments = data.segments;

        makeObservable(this, {
            a: observable,
            b: observable,
        });
    }

    get middle(): TPoint {
        return middlePoint(this.a, this.b);
    }

    accept<R>(visitor: IElementVisitor<R>): R {
        return visitor.withStroke(this);
    }
}
