import { generateId } from "@viuch/shared/utils/data";

import type { IDescriptionTemplateVisitor, IBaseTemplate } from "./BaseTemplate";

import { BaseTemplate } from "./BaseTemplate";

export type IStepTemplate = IBaseTemplate;

export class StepTemplate extends BaseTemplate implements IStepTemplate {
    constructor(data: IStepTemplate) {
        super(data);
    }

    static create(data: IStepTemplate) {
        return new StepTemplate(data);
    }

    static createEmpty() {
        return this.create({
            id: generateId(),
            name: "",
            templates: new Map(),
        });
    }

    accept<R>(visitor: IDescriptionTemplateVisitor<R>): R {
        return visitor.withStep(this);
    }
}
