import { useCallback, useState } from "react";

export function useToggle(init: () => boolean): [boolean, () => void, (state: boolean) => void];
export function useToggle(init: boolean): [boolean, () => void, (state: boolean) => void];
export function useToggle(init: (() => boolean) | boolean): [boolean, () => void, (state: boolean) => void] {
    const [state, setState] = useState<boolean>(init);

    const toggle = useCallback(() => {
        setState((s) => !s);
    }, []);

    const set = useCallback((value: boolean) => {
        setState(value);
    }, []);

    return [state, toggle, set];
}
