import cn from "classnames";
import { observer } from "mobx-react-lite";
import { Fragment, useCallback, useRef } from "react";

import { createSortAscBy } from "@viuch/shared/utils/data";

import type { GraphElementsRendererService } from "./GraphElementsRendererService";
import type { Graph2DFlowsController } from "../core/Graph2DFlowsController";
import type { Graph2DPreRenderingService } from "../core/Graph2DPreRenderingService";
import type { TPoint } from "@viuch/geometry-lib/types";
import type { PointerEvent, Touch } from "react";

import styles from "./GraphElements.module.scss";

type Props = {
    flows: Graph2DFlowsController;
    renderer: GraphElementsRendererService;
    preRenderer: Graph2DPreRenderingService;
};

export const GraphElements = observer(function GraphElements({ flows, renderer, preRenderer }: Props) {
    const containerRef = useRef<HTMLDivElement>(null);

    const { flow } = flows;

    const getPoint = useCallback((e: PointerEvent | Touch | WheelEvent): TPoint => {
        const { x, y } = { x: e.clientX, y: e.clientY };

        const element = containerRef.current!;
        const { width, height, left, top } = element.getBoundingClientRect();

        return {
            x: (x - left) / width,
            y: 1 - (y - top) / height,
        };
    }, []);

    const items = [...flow.getViewItems()].sort(createSortAscBy((item) => item.zIndex));

    return (
        <div
            className={cn(styles.root)}
            ref={containerRef}
        >
            <svg
                width="100%"
                height="100%"
                className={styles.rootSvg}
            >
                {items.map((viewItem, i) => {
                    const content = renderer.render(viewItem);

                    return <Fragment key={i}>{content}</Fragment>;
                })}
            </svg>
        </div>
    );
});
