import { observer } from "mobx-react-lite";
import React from "react";

import type { IntervalsDot, TIntervalSign } from "../../statement";

import { IntervalContainer } from "./IntervalContainer";

type Props = {
    sign: TIntervalSign;
    leftDot: IntervalsDot | null;
    rightDot: IntervalsDot | null;
    onSignChange: (rightDot: IntervalsDot | null, newSign: TIntervalSign) => void;
    readonly?: boolean;
};

export const LineInterval = observer(function LineInterval(props: Props) {
    const { leftDot, rightDot, sign, onSignChange, readonly } = props;

    const offsetLeft = leftDot?.position ?? 0;
    const offsetRight = rightDot?.position ?? 1;
    const offsetWidth = offsetRight - offsetLeft;

    const handleSignClick = (newSign: TIntervalSign) => {
        onSignChange(rightDot, newSign);
    };

    return (
        <IntervalContainer
            first={!leftDot}
            last={!rightDot}
            sign={sign}
            style={{
                width: `${offsetWidth * 100}%`,
                left: `${offsetLeft * 100}%`,
            }}
            key={rightDot?.id ?? "last"}
            onSignClick={handleSignClick}
            readonly={readonly}
        />
    );
});
