import type { TPoint } from "@viuch/geometry-lib/types";
import type { TSerializedState } from "@viuch/math-editor";

export type TFigure2DWithCompletionsInstrumentSettings = {
    instrument_type: "figure2d";
    settings_uuid: string;
    figure: {
        initial: TSerializedFigure2DSettings;
        completions: TSerializedFigure2DCompletion[];
    };
    view_settings: TFigure2DWithCompletionsInstrumentSettings_ViewSettings | undefined;
};

export type TFigure2DWithCompletionsInstrumentSettings_ViewSettings = {
    initialScale: number | undefined;
    initialPosition: TPoint | undefined;
};

export type TSerializedFigure2DCompletion = {
    hint: TSerializedState;
    state: TSerializedFigure2DSettings;
};

export type TSerializedFigure2D = {
    points: TSerializedFigure2DSettings.Point[];
    segment_likes: TSerializedFigure2DSettings.SegmentLike[];
    bisections: TSerializedFigure2DSettings.Bisection[];
    medians: TSerializedFigure2DSettings.Median[];
    heights: TSerializedFigure2DSettings.Height[];
    ellipses: TSerializedFigure2DSettings.Ellipse[];
    inscribed_circles: TSerializedFigure2DSettings.InscribedCircle[];
    circumcircles: TSerializedFigure2DSettings.Circumcircle[];
    segment_labels: TSerializedFigure2DSettings.SegmentLabel[];
    angle_labels: TSerializedFigure2DSettings.AngleLabel[];
    angle_equalities: TSerializedFigure2DSettings.AnglesEquality[];
    segment_equalities: TSerializedFigure2DSettings.SegmentsEquality[];
    tangent_lines: TSerializedFigure2DSettings.TangentLine[];
    middle_lines: TSerializedFigure2DSettings.MiddleLine[];

    __highlights?: TFigure2DHighlights;
};

export type TSerializedFigure2DSettings = TSerializedFigure2D & {
    polygons: TSerializedFigure2DSettings.Polygon[];
    system: TSerializedFigure2DSettings.TMathExpr[];
};

export const enum SegmentLikeKinds {
    segment = "segment",
    line = "line",
    parallel = "parallel",
    orthogonal = "orthogonal",
    parallelFragment = "parallel_segment",
    vector = "vector",
}

export declare namespace TSerializedFigure2DSettings {
    type TMathExpr = TSerializedState;

    type Point = {
        x: number;
        y: number;
        id: string;
        label: TMathExpr;
    };

    type BaseSegment = {
        a: Point["id"];
        b: Point["id"];
    };

    type SegmentLike = Segment | Line | ParallelLine | OrthogonalLine | ParallelSegment | Vector;

    type Segment = BaseSegment & { kind: SegmentLikeKinds.segment; stroke_style: SegmentStroke };
    type Line = BaseSegment & { kind: SegmentLikeKinds.line };
    type Vector = BaseSegment & { kind: SegmentLikeKinds.vector; stroke_style: SegmentStroke };

    type OrthogonalLine = {
        kind: SegmentLikeKinds.orthogonal;
        base: BaseSegment;
        point: Point["id"];
    };

    type ParallelLine = {
        kind: SegmentLikeKinds.parallel;
        base: BaseSegment;
        point: Point["id"];
    };

    type ParallelSegment = {
        kind: SegmentLikeKinds.parallelFragment;
        base: BaseSegment;
        a: Point["id"];
        b: Point["id"];
    };

    type Bisection = {
        angle: Angle;
        on_segment: SegmentLike;
        point: Point["id"];
    };

    type Angle = {
        left: Point["id"];
        center: Point["id"];
        right: Point["id"];
    };

    type Median = {
        from_point: Point["id"];
        on_segment: BaseSegment;
        point: Point["id"];
    };

    type Height = {
        from_point: Point["id"];
        on_segment: SegmentLike;
        point: Point["id"];
    };

    type SegmentLabel = {
        segment: BaseSegment;
        label: TMathExpr;
        alt_origin?: boolean;
    };

    type AngleLabel = {
        angle: Angle;
        label: TMathExpr;
    };

    type PolygonKind = "rectangle" | "square" | "parallelogram" | "rhombus";
    type SegmentStroke = "solid" | "dash";

    type Polygon = {
        points: TMathExpr;
        area: TMathExpr | null;
        perimeter: TMathExpr | null;
        kind: PolygonKind | null;
    };

    type Ellipse = {
        id: string;
        center: BasePoint;
        x_radius: number;
        y_radius: number;
    };

    type BasePoint = {
        x: number;
        y: number;
        id: Point["id"];
    };

    type Circumcircle = {
        id: string;
        polygon: Array<Point["id"]>;
        center: Point["id"];
    };

    type InscribedCircle = {
        id: string;
        polygon: Array<Point["id"]>;
        touch_points: Array<Point["id"]>;
        center: Point["id"];
    };

    type System = TSerializedState;

    type AnglesEquality = Angle[];

    type SegmentsEquality = BaseSegment[];

    type TangentLine = {
        outside_point?: Point["id"];
        circle: Ellipse["id"];
        touch_point: BasePoint;
    };

    type VirtualSegment = {
        a: BasePoint;
        b: BasePoint;
    };

    type MiddleLine = {
        base_a: BaseSegment;
        base_b: BaseSegment;
        new_segment: VirtualSegment;
    };
}

export type TFigure2DHighlightTag = "attention";

export type TFigure2DHighlights = {
    points: {
        highlight_tag: TFigure2DHighlightTag;
        point: string;
    }[];
    segments: {
        highlight_tag: TFigure2DHighlightTag;
        a: string;
        b: string;
        is_line: boolean;
    }[];
    ellipses: {
        highlight_tag: TFigure2DHighlightTag;
        center: {
            x: number;
            y: number;
            id: string;
        };
        x_radius: number;
        y_radius: number;
        id: string;
    }[];
    inscribed_circles: {
        highlight_tag: TFigure2DHighlightTag;
        polygon: string[];
        touch_points: string[];
        center: string;
        id: string;
    }[];
    circumcircles: {
        highlight_tag: TFigure2DHighlightTag;
        polygon: string[];
        center: string;
        id: string;
    }[];
    angles: {
        highlight_tag: TFigure2DHighlightTag;
        left: string;
        center: string;
        right: string;
    }[];
};

export type TRawFigure2DUpdate = {
    instrument_type: "figure2d";
    settings_uuid: string;
    figure: TSerializedFigure2D | null;
    highlights: TFigure2DHighlights | null;
};
