import { areSameFragments, areSamePoints } from "@viuch/geometry-lib/check-geometry";

import type { TSerializedFigure2D, TSerializedFigure2DSettings } from "../service-types/figure2d";
import type { TBasis } from "@viuch/geometry-lib/types";
import type { Figure2D } from "@viuch/instrument-figure2d";
import type { IModelVisitor } from "@viuch/instrument-figure2d/statement-editor/models/BaseModel";

import { SegmentLikeKinds } from "../service-types/figure2d";
import { hashEllipse, hashFragment, hashPoint, hashVirtualFragment } from "../utils/figure2d";

export function serializeFigure(figure: Figure2D): TSerializedFigure2D {
    const points: TSerializedFigure2DSettings.Point[] = [];
    const segment_likes: TSerializedFigure2DSettings.SegmentLike[] = [];
    const bisections: TSerializedFigure2DSettings.Bisection[] = [];
    const medians: TSerializedFigure2DSettings.Median[] = [];
    const heights: TSerializedFigure2DSettings.Height[] = [];
    const ellipses: TSerializedFigure2DSettings.Ellipse[] = [];
    const inscribed_circles: TSerializedFigure2DSettings.InscribedCircle[] = [];
    const circumcircles: TSerializedFigure2DSettings.Circumcircle[] = [];
    const segment_labels: TSerializedFigure2DSettings.SegmentLabel[] = [];
    const angle_labels: TSerializedFigure2DSettings.AngleLabel[] = [];
    const angle_equalities: TSerializedFigure2DSettings.AnglesEquality[] = [];
    const segment_equalities: TSerializedFigure2DSettings.SegmentsEquality[] = [];
    const tangent_lines: TSerializedFigure2DSettings.TangentLine[] = [];
    const middle_lines: TSerializedFigure2DSettings.MiddleLine[] = [];

    function findSegmentLike(_basis: TBasis): TSerializedFigure2DSettings.SegmentLike | void {
        const { offset, angle } = _basis;
        const tryCreateSegmentLikeVisitor: IModelVisitor<TSerializedFigure2DSettings.SegmentLike | void> = {
            withFragment: (fragment) => {
                if (angle || offset) return;
                if (!areSameFragments(fragment, _basis.fragment)) return;
                return {
                    kind: SegmentLikeKinds.segment,
                    a: hashPoint(fragment.a),
                    b: hashPoint(fragment.b),
                    stroke_style: "solid",
                };
            },
            withFragmentDashed: (fragment) => {
                if (angle || offset) return;
                if (!areSameFragments(fragment.toFragment(), _basis.fragment)) return;

                return {
                    kind: SegmentLikeKinds.segment,
                    a: hashPoint(fragment.a),
                    b: hashPoint(fragment.b),
                    stroke_style: "dash",
                };
            },
            withVector: (vector) => {
                if (angle || offset) return;
                if (!areSameFragments(vector.toFragment(), _basis.fragment)) return;

                return {
                    kind: SegmentLikeKinds.vector,
                    a: hashPoint(vector.from),
                    b: hashPoint(vector.to),
                    stroke_style: "solid",
                };
            },
            withVectorDashed: (vector) => {
                if (angle || offset) return;
                if (!areSameFragments(vector.toFragment(), _basis.fragment)) return;

                return {
                    kind: SegmentLikeKinds.vector,
                    a: hashPoint(vector.from),
                    b: hashPoint(vector.to),
                    stroke_style: "dash",
                };
            },
            withParallelFragment: (fragment) => {
                // TODO сериализация для бэка
                if (angle || offset) return;
                if (!areSameFragments(fragment, _basis.fragment)) return;
                return {
                    kind: SegmentLikeKinds.segment,
                    a: hashPoint(fragment.a),
                    b: hashPoint(fragment.b),
                    stroke_style: "solid",
                };
            },
            withLine: (line) => {
                if (angle || offset) return;
                if (!areSameFragments(line, _basis.fragment)) return;

                return {
                    kind: SegmentLikeKinds.line,
                    a: hashPoint(line.a),
                    b: hashPoint(line.b),
                };
            },
            withConstraintLine: (line) => {
                if (!offset) return;
                if (!areSamePoints(offset, line.vertex)) return;
                if (!areSamePoints(_basis.fragment.a, line.constraintA)) return;
                if (!areSamePoints(_basis.fragment.b, line.constraintB)) return;

                return {
                    kind: line.offsetAngle ? SegmentLikeKinds.orthogonal : SegmentLikeKinds.parallel,
                    point: hashPoint(line.vertex),
                    base: { a: hashPoint(line.constraintA), b: hashPoint(line.constraintB) },
                };
            },
            withPoint: () => void 0,
            withLabelPoint: () => void 0,
            withLabelFragment: () => void 0,
            withLabelAngle: () => void 0,
            withComputedIntersectionPoints: () => void 0,
            withBisection: () => void 0,
            withMedian: () => void 0,
            withAltitude: () => void 0,
            withEqualAngles: () => void 0,
            withEqualSegments: () => void 0,
            withEllipse: () => void 0,
            withInscribedCircle: () => void 0,
            withCircumscribedCircle: () => void 0,
            withTangentLine: () => void 0,
            withMiddleLine: () => void 0,
            withTangentLineOnCircle: () => void 0,
        };

        for (const model of figure.models) {
            const segmentLike = model.accept(tryCreateSegmentLikeVisitor);
            if (segmentLike) return segmentLike;
        }
    }

    const serializeModelVisitor: IModelVisitor<void> = {
        withPoint: () => {},
        withComputedIntersectionPoints: () => {},
        withLabelPoint: (point) => {
            const { x, y, label } = point;
            points.push({ x, y, id: hashPoint(point), label });
        },
        withFragment: (fragment) => {
            const { a, b } = fragment;
            segment_likes.push({
                kind: SegmentLikeKinds.segment,
                a: hashPoint(a),
                b: hashPoint(b),
                stroke_style: "solid",
            });
        },
        withFragmentDashed: (fragment) => {
            const { a, b } = fragment;
            segment_likes.push({
                kind: SegmentLikeKinds.segment,
                a: hashPoint(a),
                b: hashPoint(b),
                stroke_style: "dash",
            });
        },
        withVector: (vector) => {
            const { from, to } = vector;
            segment_likes.push({
                kind: SegmentLikeKinds.vector,
                a: hashPoint(from),
                b: hashPoint(to),
                stroke_style: "solid",
            });
        },
        withVectorDashed: (vector) => {
            const { from, to } = vector;
            segment_likes.push({
                kind: SegmentLikeKinds.vector,
                a: hashPoint(from),
                b: hashPoint(to),
                stroke_style: "dash",
            });
        },
        withLine: (line) => {
            const { a, b } = line;
            segment_likes.push({
                kind: SegmentLikeKinds.line,
                a: hashPoint(a),
                b: hashPoint(b),
            });
        },
        withConstraintLine: ({ constraintA, constraintB, vertex, offsetAngle }) => {
            const point = hashPoint(vertex);
            const base = { a: hashPoint(constraintA), b: hashPoint(constraintB) };

            if (offsetAngle === 0) {
                segment_likes.push({ kind: SegmentLikeKinds.parallel, base, point });
            }
            if (offsetAngle === Math.PI / 2) {
                segment_likes.push({ kind: SegmentLikeKinds.orthogonal, base, point });
            }
        },
        withBisection: ({ angle, midPoint, basis }) => {
            if (!midPoint) return;
            const on_segment = findSegmentLike(basis);
            if (!on_segment) return;

            bisections.push({
                on_segment,
                angle: {
                    center: hashPoint(angle.vertex),
                    left: hashPoint(angle.start),
                    right: hashPoint(angle.end),
                },
                point: hashPoint(midPoint),
            });
        },
        withMedian: ({ vertex, fragment, midPoint }) => {
            medians.push({
                from_point: hashPoint(vertex),
                on_segment: {
                    a: hashPoint(fragment.a),
                    b: hashPoint(fragment.b),
                },
                point: hashPoint(midPoint),
            });
        },
        withAltitude: ({ vertex, basis, projectionPoint }) => {
            const on_segment = findSegmentLike(basis);
            if (!on_segment) return;
            heights.push({
                from_point: hashPoint(vertex),
                on_segment,
                point: hashPoint(projectionPoint),
            });
        },
        withEllipse: (ellipse) => {
            ellipses.push({
                id: hashEllipse(ellipse),
                center: {
                    x: ellipse.center.x,
                    y: ellipse.center.y,
                    id: hashPoint(ellipse.center),
                },
                x_radius: ellipse.rx,
                y_radius: ellipse.ry,
            });
        },
        withCircumscribedCircle: (circle) => {
            if (circle.isEllipse()) {
                circumcircles.push({
                    id: hashEllipse(circle),
                    center: hashPoint(circle.center),
                    polygon: circle.points.map(hashPoint),
                });
            }
        },
        withInscribedCircle: (circle) => {
            if (circle.isEllipse()) {
                inscribed_circles.push({
                    id: hashEllipse(circle),
                    center: hashPoint(circle.center),
                    polygon: circle.fragmentsPoints.map(hashPoint),
                    touch_points: circle.intersectionPoints.map(hashPoint),
                });
            }
        },
        withLabelFragment: ({ value, a, b, altOrigin }) => {
            segment_labels.push({
                segment: { a: hashPoint(a), b: hashPoint(b) },
                label: value,
                alt_origin: altOrigin,
            });
        },
        withLabelAngle: ({ start, vertex, end, value }) => {
            angle_labels.push({
                angle: {
                    left: hashPoint(start),
                    center: hashPoint(vertex),
                    right: hashPoint(end),
                },
                label: value,
            });
        },
        withEqualSegments: ({ segments }) => {
            segment_equalities.push(
                segments.map(({ a, b }) => ({
                    a: hashPoint(a),
                    b: hashPoint(b),
                }))
            );
        },
        withEqualAngles: ({ angles }) => {
            angle_equalities.push(
                angles.map(({ start, vertex, end }) => ({
                    left: hashPoint(start),
                    center: hashPoint(vertex),
                    right: hashPoint(end),
                }))
            );
        },
        withTangentLine: (tangent) => {
            if (tangent.checkIsTangent()) {
                tangent_lines.push({
                    circle: hashEllipse(tangent.circle),
                    outside_point: hashPoint(tangent.point),
                    touch_point: {
                        id: hashPoint(tangent.pointOnCircle),
                        x: tangent.pointOnCircle.x,
                        y: tangent.pointOnCircle.y,
                    },
                });
            }
        },
        withMiddleLine: ({ base1, base2, middleLineFragment }) => {
            middle_lines.push({
                base_a: hashFragment(base1),
                base_b: hashFragment(base2),
                new_segment: hashVirtualFragment(middleLineFragment),
            });
        },
        withTangentLineOnCircle: (tangent) => {
            if (tangent.hasTangent()) {
                tangent_lines.push({
                    circle: hashEllipse(tangent.ellipse),
                    touch_point: {
                        id: hashPoint(tangent.touchPoint),
                        x: tangent.touchPoint.x,
                        y: tangent.touchPoint.y,
                    },
                });
            }
        },
        withParallelFragment: (fragment) => {
            // TODO сериализация для бэка
        },
    };

    figure.models.forEach((model) => model.accept(serializeModelVisitor));

    return {
        points,
        segment_likes,
        bisections,
        medians,
        heights,
        ellipses,
        inscribed_circles,
        circumcircles,
        segment_labels,
        angle_labels,
        angle_equalities,
        segment_equalities,
        tangent_lines,
        middle_lines,
    };
}
