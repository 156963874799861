import type { BaseTaskInstrumentSettings } from "../../entities/task-instruments-settings/BaseTaskInstrumentSettings";
import type { TTaskInstrumentSettingsResponse } from "@viuch/feature-instrument-settings/service-types/taskInstruments";

import { serializeInstrumentSettings } from "./serializeInstrumentSettings";

export function serializeTaskInstrumentSettings(
    instrument: BaseTaskInstrumentSettings
): TTaskInstrumentSettingsResponse {
    return {
        id: instrument.settingsId,
        settings: serializeInstrumentSettings(instrument),
    };
}
