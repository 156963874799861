import { computed, makeObservable, observable } from "mobx";

import type { ILabelElement } from "./BaseLabelElement";
import type { IElementVisitor } from "../BaseElement";
import type { TPoint } from "@viuch/geometry-lib/types";
import type { InputService } from "@viuch/math-editor";
import type { TAnyAction } from "@viuch/math-editor/types";

import { BaseLabelElement } from "./BaseLabelElement";

export interface ILabelFragmentElement extends ILabelElement {
    x1: number;
    y1: number;
    x2: number;
    y2: number;
    notifyAction?: (inputService: InputService, action: TAnyAction) => void;
}

export class LabelFragmentElement extends BaseLabelElement {
    a: TPoint;
    b: TPoint;

    constructor(data: ILabelFragmentElement) {
        super(data);

        const { x1, y1, x2, y2 } = data;
        this.a = { x: x1, y: y1 };
        this.b = { x: x2, y: y2 };
        this.notifyAction = data.notifyAction;

        makeObservable(this, {
            x: computed,
            y: computed,
            a: observable,
            b: observable,
        });
    }

    static create(data: ILabelFragmentElement) {
        return new LabelFragmentElement(data);
    }

    accept<R>(visitor: IElementVisitor<R>): R {
        return visitor.withLabelFragment(this);
    }

    get x(): number {
        return (this.a.x + this.b.x) / 2;
    }

    get y(): number {
        return (this.a.y + this.b.y) / 2;
    }
}
