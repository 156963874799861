import { action, makeObservable, observable } from "mobx";

import type { BaseFormNode, IFormNodeVisitor } from "./BaseFormNode";

import { BaseValueFormNode } from "./BaseValueFormNode";

export class NumberFormNode extends BaseValueFormNode {
    @observable value = 0;

    readonly min?: number;
    readonly max?: number;
    readonly step?: number;
    readonly digits?: number;

    @action.bound
    setValue(value: number) {
        this.value = this._applyConstraints(value);
    }

    private _applyConstraints(value: number): number {
        let v = value;
        if (typeof this.min === "number") {
            v = Math.max(this.min, v);
        }
        if (typeof this.max === "number") {
            v = Math.min(this.max, v);
        }
        if (typeof this.digits === "number") {
            v = +v.toFixed(this.digits);
        }

        return v;
    }

    constructor(
        label: string,
        min: number | undefined,
        max: number | undefined,
        step: number | undefined,
        digits: number | undefined,
        parent: BaseFormNode | null,
        pathSegments: readonly string[],
        extensions: string[]
    ) {
        super(label, parent, pathSegments, extensions);

        this.min = min;
        this.max = max;
        this.step = step;
        this.digits = digits;

        makeObservable(this);
    }

    accept<R>(visitor: IFormNodeVisitor<R>): R {
        return visitor.withNumber(this);
    }

    getValue(): unknown {
        return this.value;
    }

    trySetValue(value: unknown): boolean {
        if (typeof value === "number") {
            this.value = this._applyConstraints(value);
            return true;
        }
        return false;
    }

    valueEqualsTo(value: unknown): boolean {
        return this.value === value;
    }
}
