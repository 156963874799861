import type { TFormControl } from "@viuch/form-builder/types/controls";

import { createLabel } from "../controls/label";
import { variableMathControl } from "../controls/value";

export const derivative: TFormControl = {
    control: "Form",
    layout: "column",
    condition: { "./solver_type": ["derivative"] },
    controls: [
        createLabel("Вычисление производной функции"), //
        {
            control: "Math",
            path: "./value",
            label: "Значение функции",
        },
        variableMathControl,
    ],
};
