import { assert } from "@viuch/utils/debug";

export function normalizeNumber(n: number, { min, max }: { min: number; max: number }) {
    assert(!Number.isNaN(n));
    assert(!Number.isNaN(min));
    assert(!Number.isNaN(max));
    assert(max >= min);

    return Math.max(min, Math.min(n, max));
}
