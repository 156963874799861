import React from "react";

import type { RootModel } from "./RootModel";
import type { TElementProps } from "../../types/element";

import { ContainerElement } from "../../core/container";
import { Baseline } from "../../core/element";
import { useMathEditorElementRef } from "../../hooks";

import styles from "./RootElement.module.scss";

export const RootElement: React.VFC<TElementProps<RootModel>> = ({ elementModel }) => {
    const ref = useMathEditorElementRef(elementModel);
    const baselineRef = React.useRef<HTMLSpanElement>(null);
    const containerRef = React.useRef<HTMLSpanElement>(null);
    const indicatorRef = React.useRef<HTMLSpanElement>(null);
    const contentRef = React.useRef<HTMLSpanElement>(null);

    React.useEffect(() => {
        const sqrtWrapperEl = containerRef.current!;
        const baselineEl = baselineRef.current!;
        const contentEl = contentRef.current!;
        const indicatorEl = indicatorRef.current;

        const observer = new ResizeObserver(() => {
            const baselineHalf = baselineEl.scrollHeight / 2;
            const heightHalf = contentEl.scrollHeight / 2;

            baselineEl.style.marginBottom = `${heightHalf - baselineHalf}px`;

            if (indicatorEl) {
                const percent = 0.7 * sqrtWrapperEl.scrollHeight;
                indicatorEl.style.marginBottom = `${percent}px`;
            }
        });

        observer.observe(contentEl);

        return () => {
            observer.disconnect();
        };
    });

    return (
        <span
            ref={ref}
            className={styles.wrapper}
        >
            <span className={styles.sqrt}>
                <Baseline ref={baselineRef} />
                <span className={styles.body}>
                    {!elementModel.isSquared && (
                        <span
                            ref={indicatorRef}
                            className={styles.index}
                        >
                            <ContainerElement containerModel={elementModel.indicator} />
                        </span>
                    )}
                    <span
                        ref={containerRef}
                        className={styles.container}
                    >
                        <svg>
                            <line
                                x1="0.1em"
                                y1="30%"
                                x2="0.65em"
                                y2="30%"
                            />
                            <line
                                x1="0.6em"
                                y1="30%"
                                x2="0.6em"
                                y2="92%"
                            />
                            <line
                                x1="0.6em"
                                y1="92%"
                                x2="2em"
                                y2="-100%"
                            />
                            <line
                                x1="1.26em"
                                y1="0"
                                x2="100%"
                                y2="0"
                            />
                        </svg>
                        <span
                            ref={contentRef}
                            className={styles.content}
                        >
                            <ContainerElement containerModel={elementModel.content} />
                        </span>
                    </span>
                </span>
            </span>
        </span>
    );
};
