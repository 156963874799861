import type { GetError } from "../base/RequestError";
import type { TTaskVariableRequest, TTaskVariableResponse } from "../tasks";
import type { TTaskInstrumentSettingsResponse } from "@viuch/feature-instrument-settings/service-types/taskInstruments";
import type { TSerializedState } from "@viuch/math-editor";

import { agent } from "../../agent";
import { Problem } from "../../entities/problems";
import { mapPrimaryHints, serializePrimaryHints } from "../../entities/tasks/utils";
import { mapTaskInstrumentSettings } from "../../serializers/instrumentSettings/mapTaskInstrumentSettings";
import { serializeTaskInstrumentSettings } from "../../serializers/instrumentSettings/serializeTaskInstrumentSettings";
import { BaseCRUDService } from "../base";
import { mapVariable } from "../tasks/utils/mapVariable";
import { serializeVariable } from "../tasks/utils/serializeVariable";

export class ProblemsService extends BaseCRUDService<Problem, TProblemRequest, TProblemResponse> {
    constructor() {
        super("/problems/", mapProblem, serializeProblem);
    }

    async getByTaskId(taskId: number): Promise<Problem[]> {
        return (await this.getAll()).filter(({ tasks }) => tasks.includes(taskId));
    }

    async rawSearchByFormula(
        formula: TSerializedState,
        exact: boolean,
        signal?: AbortSignal
    ): Promise<TProblemResponse[]> {
        const requestData = { formula, exact };
        const { data } = await agent.post<TProblemResponse[]>("/problems/search/", requestData, { signal });

        return data;
    }

    async searchByFormula(formula: TSerializedState, exact: boolean, signal?: AbortSignal): Promise<Problem[]> {
        const data = await this.rawSearchByFormula(formula, exact, signal);
        return data.map(mapProblem);
    }
}

export type TProblemRequest = {
    id: number;
    formula: TSerializedState;
    description: TSerializedState;
    instrument_settings: TTaskInstrumentSettingsResponse[];
    primary_hints: TSerializedState[] | null;
    variables: TTaskVariableRequest[];
    answer_replacements: {};
};

export type TProblemError = GetError<TProblemRequest>;

export type TProblemResponse = {
    id: number;
    formula: TSerializedState;
    description: TSerializedState;
    instrument_settings: TTaskInstrumentSettingsResponse[];
    primary_hints: TSerializedState[] | null;
    variables: TTaskVariableResponse[];
    answer_replacements: {};
    tasks: readonly number[];
    themes: readonly number[];
};

export function mapProblem(data: TProblemResponse): Problem {
    const {
        id,
        tasks,
        themes,
        formula,
        description,
        instrument_settings,
        primary_hints,
        variables,
        answer_replacements,
    } = data;

    return new Problem(
        id,
        formula,
        description,
        primary_hints ? mapPrimaryHints(primary_hints) : null,
        variables.map(mapVariable),
        instrument_settings.map(mapTaskInstrumentSettings),
        tasks,
        themes
    );
}

export function serializeProblem(problem: Problem): TProblemRequest {
    const { id, formula, description, variables, instruments, primaryHints } = problem;

    return {
        id,
        formula,
        description,
        instrument_settings: instruments.map(serializeTaskInstrumentSettings),
        answer_replacements: {},
        primary_hints: primaryHints ? serializePrimaryHints(primaryHints) : null,
        variables: variables.map(serializeVariable),
    };
}
