import axios from "axios";

import { API_URL } from "../constants";
import { getAccessToken, hasAccessToken, removeAccessToken } from "../services/auth/utils";

const agent = axios.create({
    baseURL: API_URL,
    headers: {
        "Content-Type": "application/json",
    },
});

agent.interceptors.request.use(
    (config) => {
        const newConfig = { ...config };

        if (newConfig.headers && hasAccessToken()) {
            newConfig.headers.Authorization = `Bearer ${getAccessToken()}`;
        }

        if (newConfig.url?.length && !newConfig.url.includes("?") && newConfig.url[newConfig.url.length - 1] !== "/") {
            newConfig.url += "/";
        }

        return newConfig;
    },
    (error) => Promise.reject(error)
);

agent.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error?.response?.status === 401) {
            removeAccessToken();
        }

        return Promise.reject(error);
    }
);

export default agent;
