import cn from "classnames";
import React, { type ReactNode, useCallback, useState } from "react";
import { flushSync } from "react-dom";

import { MathEditorInput } from "@viuch/math-editor";

import type { InputService, KeyboardService, TContextMenuEvent } from "@viuch/math-editor";

import { BaseInput } from "./BaseInput";

import styles from "./MathInput.module.scss";

type Props = {
    inputService: InputService;
    keyboardService?: KeyboardService;
    title?: string;
    onBlur?(inputService: InputService): void;
    onChanged?(inputService: InputService): void;
    className?: string;
    error?: string;
    inline?: boolean;
    innerClassName?: string;
    disabled?: boolean;
    buttons?: ReactNode;
};

export const MathInput = (props: Props) => {
    const {
        inputService,
        className,
        title,
        onBlur,
        keyboardService,
        error,
        inline,
        onChanged,
        innerClassName,
        disabled,
        buttons,
    } = props;

    const copyToClipboard = () => {
        inputService.clipboard.copy(inputService.model.rootContainer.elements);
    };

    const [contextMenuData, setContextMenuData] = useState<void | TContextMenuEvent>();

    const handleContextMenu = useCallback((e: TContextMenuEvent) => {
        flushSync(() => {
            setContextMenuData(e);
        });

        // Handle context menu
    }, []);

    return (
        <BaseInput
            title={title}
            className={className}
            error={error}
            inline={inline}
        >
            <div className={cn(styles.mathInputWrapper, innerClassName)}>
                <MathEditorInput
                    inputModel={inputService.model}
                    keyboardService={keyboardService}
                    className={styles.mathInput}
                    onBlur={onBlur}
                    onChange={onChanged}
                    disabled={disabled}
                    readonly={disabled}
                    onContextMenu={handleContextMenu}
                />
                <div className={styles.buttons}>
                    <button
                        title="Скопировать формулу"
                        className={styles.copyButton}
                        onClick={copyToClipboard}
                        disabled={disabled}
                    />
                    {buttons}
                </div>
            </div>
        </BaseInput>
    );
};
