import { action, makeObservable, observable } from "mobx";

import { deepClone } from "@viuch/shared/utils/data/copy";

import type { Answer } from "./Answer";
import type { TQuestionStudent, TQuestionTheme } from "../../services/feedback/QuestionsService.types";

export class Question {
    constructor(
        id: Question["id"],
        text: Question["text"],
        username: Question["username"],
        email: Question["email"],
        student: Question["student"],
        isVerified: Question["isVerified"],
        answer: Question["answer"],
        theme: Question["theme"],
        createdAt: Question["createdAt"],
        updatedAt: Question["updatedAt"]
    ) {
        this.id = id;
        this.text = text;
        this.username = username;
        this.email = email;
        this.student = student;
        this.isVerified = isVerified;
        this.answer = answer;
        this.theme = theme;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;

        makeObservable(this);
    }

    readonly id: number;
    @observable text: string;
    readonly student: TQuestionStudent | null;
    readonly isVerified: boolean;
    @observable answer: Answer | null = null;
    readonly theme: TQuestionTheme | null;
    readonly createdAt: Date;
    readonly updatedAt: Date;
    readonly username: string;
    readonly email: string;

    @action.bound
    setText(text: string) {
        this.text = text;
    }

    clone() {
        return new Question(
            this.id,
            this.text,
            this.username,
            this.email,
            deepClone(this.student),
            this.isVerified,
            this.answer ? this.answer.clone() : null,
            deepClone(this.theme),
            this.createdAt,
            this.updatedAt
        );
    }
}
