import { action, makeObservable, observable } from "mobx";

import { createSerializedContainer } from "@viuch/math-editor";
import { generateId } from "@viuch/shared/utils/data";

import type { TSerializedState } from "@viuch/math-editor";

export class Dimension implements Dimension.Init {
    readonly id;
    @observable name;
    @observable sign;
    @observable.ref formula;
    @observable.shallow measureUnitIds;

    constructor(init: Dimension.Init) {
        this.id = init.id;
        this.name = init.name;
        this.sign = init.sign;
        this.formula = init.formula;
        this.measureUnitIds = init.measureUnitIds.slice();

        makeObservable(this);
    }

    @action.bound
    setName(name: string) {
        this.name = name;
    }

    @action.bound
    setSign(sign: string) {
        this.sign = sign;
    }

    @action.bound
    setFormula(formula: TSerializedState) {
        this.formula = formula;
    }

    @action.bound
    toggleFormula() {
        if (!this.formula) {
            this.formula = createSerializedContainer();
        } else {
            this.formula = null;
        }
    }
}

export namespace Dimension {
    export type Init = {
        id: number;
        name: string;
        sign: string;
        formula: TSerializedState | null;
        measureUnitIds: number[];
    };

    export const createDefaultInit = (override?: Partial<Init>): Init => ({
        id: generateId(),
        sign: "",
        name: "",
        measureUnitIds: [],
        formula: createSerializedContainer(),
        ...override,
    });
}
