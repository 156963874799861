import type { GetError } from "../base/RequestError";

import { MeasureUnit } from "../../entities/dimensions/MeasureUnit";
import { BaseCRUDService } from "../base";

export class MeasureUnitsService extends BaseCRUDService<MeasureUnit, TMeasureUnitRequest, TMeasureUnitResponse> {
    constructor() {
        super("/measure_units", mapMeasureUnit, serializeMeasureUnit);
    }
}

export type TMeasureUnitRequest = {
    id: number;
    dimension: number;
    full_name: string;
    math_designation: string;
    coefficient: string;
};

export type TMeasureUnitResponse = TMeasureUnitRequest;
export type TMeasureUnitError = GetError<TMeasureUnitRequest>;

export const mapMeasureUnit = ({
    id,
    coefficient,
    dimension: dimensionId,
    full_name: name,
    math_designation: sign,
}: TMeasureUnitResponse) => new MeasureUnit({ id, name, sign, k: coefficient, dimensionId });

export const serializeMeasureUnit = ({
    id,
    dimensionId: dimension,
    k: coefficient,
    name: full_name,
    sign: math_designation,
}: MeasureUnit): TMeasureUnitRequest => ({ id, full_name, coefficient, math_designation, dimension });
