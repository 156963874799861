import { assert } from "@viuch/utils/debug";

const lerp = (x: number, y: number, a: number) => x * (1 - a) + y * a;

const clamp = (a: number, min = 0, max = 1) => Math.min(max, Math.max(min, a));

const invLerp = (x: number, y: number, a: number) => clamp((a - x) / (y - x));

export const range = (x1: number, y1: number, x2: number, y2: number, a: number) => lerp(x2, y2, invLerp(x1, y1, a));

export function rangeArray(from: number[], to: number[], currentFrom: number): number {
    assert(from.length === to.length);
    assert(from.length >= 2);

    const length = Math.min(from.length, to.length);

    if (currentFrom < from[0]) {
        return to[0];
    }

    for (let i = 0; i < length - 1; i++) {
        const a_start = from[i];
        const a_end = from[i + 1];

        const b_start = to[i];
        const b_end = to[i + 1];

        if (a_start <= currentFrom && currentFrom <= a_end) {
            return range(a_start, a_end, b_start, b_end, currentFrom);
        }
    }

    // if (currentFrom > from[length]) {
    return to[length - 1];
    // }
}
