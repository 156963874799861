import { filterEqualObjects } from "@viuch/shared/utils/data/filter";
import { assert } from "@viuch/utils/debug";

import type { TFragment, TLine, TPoint } from "./types";

import { getLineAngle, normalizeAngle } from "./angles";
import { compareFloats, equateNumbers, isFloatInRange } from "./equate";
import { createLine, lineToFragment } from "./factories";
import { filterEqualPoints } from "./filters";
import { getCoordinateHash } from "./hashing";
import { subtractVectors, vectorLength } from "./vectors";

export function solveQuadraticEquation(a: number, b: number, c: number): number[] {
    const D = b ** 2 - 4 * a * c;

    if (equateNumbers(D, "<", 0)) {
        return [];
    }

    const sqrtD = Math.sqrt(D);

    const x1 = (-b + sqrtD) / (2 * a);
    const x2 = (-b - sqrtD) / (2 * a);

    return [...filterEqualObjects([x1, x2], getCoordinateHash)];
}

export function normalizeVector({ x, y }: TPoint): TPoint {
    const length = vectorLength(x, y);
    if (!(length > 0)) throw new Error("Length shouldn't be zero");

    return { x: x / length, y: y / length };
}

export function rotatePoints(
    points: TPoint[],
    angle: number,
    zero: TPoint = { x: 0, y: 0 },
    reverse?: boolean
): TPoint[] {
    const k = reverse ? -1 : 1;

    const sin = Math.sin(angle);
    const cos = Math.cos(angle);

    return points.map((point) => {
        const x = point.x - zero.x;
        const y = point.y - zero.y;
        return {
            x: x * cos - k * y * sin + zero.x,
            y: k * x * sin + y * cos + zero.y,
        };
    });
}

export function getVectorAngle(vector: TPoint, clockwise = false): number {
    const { x, y } = vector;

    const sign = clockwise ? 1 : -1;
    return normalizeAngle(sign * Math.atan2(y, x));
}

export function getFragmentAngle(fragment: TFragment, clockwise = false): number {
    const { a, b } = fragment;
    return getLineAngle(createLine(a, b), clockwise);
}

export function getLineFromFragment(start: TPoint, end: TPoint) {
    const { x: x0, y: y0 } = start;
    const { x: x1, y: y1 } = end;

    const diff_x = x1 - x0;
    const diff_y = y1 - y0;

    const fx_y = (y: number) => (y * diff_x - y1 * diff_x + x1 * diff_y) / diff_y;

    const fy_x = (x: number) => (x * diff_y - x1 * diff_y + y1 * diff_x) / diff_x;

    const x_y0 = fx_y(0);
    const y_x0 = fy_x(0);
    const x_y1 = fx_y(1);
    const y_x1 = fy_x(1);

    const inBounds = (a: number) => Number.isFinite(a) && !Number.isNaN(a) && isFloatInRange(0, a, 1);

    const allIntersectionPoints = [
        inBounds(x_y0) && { x: x_y0, y: 0 },
        inBounds(x_y1) && { x: x_y1, y: 1 },
        inBounds(y_x0) && { x: 0, y: y_x0 },
        inBounds(y_x1) && { x: 1, y: y_x1 },
    ];

    const [a, b] = filterEqualPoints(allIntersectionPoints.filter<TPoint>(Boolean));

    assert(a);
    assert(b);

    return { a, b };
}

export function reflectPoint(vertex: TPoint, source: TPoint): TPoint {
    const vector = subtractVectors(vertex, source);
    return subtractVectors(vector, vertex);
}

export function rotatePoint(point: TPoint, angle: number, zero: TPoint = { x: 0, y: 0 }, reverse?: boolean): TPoint {
    return rotatePoints([point], angle, zero, reverse)[0];
}

export function canonicalLineParams(line: TLine): { a: number; b: number } {
    const _line = lineToFragment(line);

    const { x: rx, y: ry } = subtractVectors(_line.a, _line.b);
    const ax = line.x1;
    const ay = line.y1;

    const a = ry / rx;
    const b = (rx * ay - ry * ax) / rx;

    return { a, b };
}

export function getClosestPoints(_points: TPoint[], pointer: TPoint): [TPoint, TPoint] {
    const points = filterEqualPoints(_points);
    assert(points.length >= 2);

    if (points.length === 2) {
        return [points[0], points[1]];
    }

    const angle = getLineAngle(createLine(points[0], points[1]), false);
    const rotatedPoints = rotatePoints(points, angle);
    const pointerValue = rotatePoint(pointer, angle).x;

    const pointsData = points
        .map((point, i) => ({ point, value: rotatedPoints[i].x }))
        .sort((a, b) => compareFloats(a.value, b.value));

    let closestIndex = 0;
    let closestDistance = Number.MAX_SAFE_INTEGER;
    for (let i = 0; i < pointsData.length; i++) {
        const distance = pointerValue - pointsData[i].value;
        if (Math.abs(closestDistance) > Math.abs(distance)) {
            closestDistance = distance;
            closestIndex = i;
        }
    }

    if (closestIndex === 0) {
        return [pointsData[0].point, pointsData[1].point];
    }
    if (closestIndex === pointsData.length - 1) {
        return [pointsData[pointsData.length - 1].point, pointsData[pointsData.length - 2].point];
    }

    if (Math.sign(closestDistance) >= 0) {
        return [pointsData[closestIndex].point, pointsData[closestIndex + 1].point];
    }
    return [pointsData[closestIndex].point, pointsData[closestIndex - 1].point];
}
