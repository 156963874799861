import { computed, makeObservable } from "mobx";

import { InputService } from "@viuch/math-editor";

import type { PictureInstrumentSettingsWatermark } from "../../settings/PictureInstrumentSettingsWatermark";

export class PictureWatermarkDotViewModel {
    private readonly watermark: PictureInstrumentSettingsWatermark;

    @computed
    get valueInput(): InputService {
        const initialState = this.watermark.templateValue;
        return new InputService({
            initialState,
            isReadOnly: true,
        });
    }

    constructor(watermark: PictureInstrumentSettingsWatermark) {
        this.watermark = watermark;

        makeObservable(this);
    }
}
