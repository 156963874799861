import type { TMathKeyboardButton } from "../types";

import { createLinebreakAction } from "../../../../actions/linebreak";
import { __miscTag } from "../../../../core/keyboard-button-tags/constants";

export const enterButton: TMathKeyboardButton = {
    actionCreator: () => createLinebreakAction("down"),
    icon: "top-enter",
    tags: [__miscTag],
};
