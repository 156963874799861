import { computed, makeObservable, observable } from "mobx";
import { createTransformer } from "mobx-utils";

import { areSamePoints } from "@viuch/geometry-lib/check-geometry";
import { createLine } from "@viuch/geometry-lib/factories";
import { addVectors, subtractVectors } from "@viuch/geometry-lib/vectors";

import type { BaseElement } from "../../elements";
import type { Figure2DController } from "../../Figure2DController";
import type { IUserPointer } from "../../viewport/types";
import type { TFragment, TPoint } from "@viuch/geometry-lib/types";
import type React from "react";

import { DotElement } from "../../elements/dot";
import { ElementColor } from "../../elements/ElementColor";
import { LineElement } from "../../elements/line";
import { BaseFlow } from "../BaseFlow";
import { SelectElementsFlow } from "../select-elements";
import { createMapModelToElements, opacifyColor } from "../utils";

export class MoveFragmentFlow extends BaseFlow {
    private readonly fragment: TFragment;
    private readonly selected: TFragment;
    private readonly initialPoint: TPoint;

    drawing: {
        newPoint: TPoint;
        actuallyMoved: boolean;
    };

    constructor(data: Figure2DController, fragment: TFragment, selected: TFragment, initialPoint: TPoint) {
        super(data);
        this.initialPoint = initialPoint;
        this.fragment = { ...fragment };
        this.selected = { ...selected };

        makeObservable(this, {
            drawing: observable.deep,
            tempElements: computed,
        });

        this.drawing = {
            newPoint: initialPoint,
            actuallyMoved: false,
        };
    }

    attach(): void {
        this.viewport.disable();
        this.snap.addViewportGrid().addFigureModelsPoints();
    }

    dispose(): void {
        this.snap.clean();
    }

    override handleViewportPointerEvent(e: React.PointerEvent, pointer: IUserPointer): void {
        if (!e.isPrimary) return;
        if (e.type === "pointercancel") {
            return this.nextFlow();
        }

        switch (e.type) {
            case "pointermove":
                return this.onMove(pointer);
            case "pointerup":
                return this.onUp();
        }
    }

    private onMove(pointer: IUserPointer) {
        const initialOffsetVector = subtractVectors(this.fragment.a, this.initialPoint);

        const pointerChangedVector = subtractVectors(this.initialPoint, pointer.canvas);

        const _newA = addVectors(this.fragment.a, pointerChangedVector);
        const newA: TPoint = this.snap.snap(_newA, _newA);

        const newPoint = addVectors(newA, initialOffsetVector);
        if (!areSamePoints(this.drawing.newPoint, newPoint)) {
            this.drawing = { newPoint, actuallyMoved: true };
        }
    }

    private get moveDotService() {
        return this.controller.moveDot;
    }

    private onUp() {
        const { a, b } = this.getNewLine();
        if (this.drawing.actuallyMoved) {
            this.moveDotService.moveDots([
                { from: this.fragment.a, to: a },
                { from: this.fragment.b, to: b },
            ]);
            this.nextFlow();
        } else {
            this.selection.handleLineClick(createLine(this.selected.a, this.selected.b), this.initialPoint);
            this.nextFlow(SelectElementsFlow.create);
        }
    }

    private mapModel = createTransformer(createMapModelToElements(opacifyColor));

    protected renderElements(): BaseElement[] {
        return [...this.figure.models.flatMap(this.mapModel), ...this.tempElements];
    }

    private getNewLine(): TFragment {
        const newUserPoint = this.drawing.newPoint;

        const a = subtractVectors(subtractVectors(this.fragment.a, this.initialPoint), newUserPoint);

        const b = subtractVectors(subtractVectors(this.fragment.b, this.initialPoint), newUserPoint);

        return { a, b };
    }

    get tempElements(): BaseElement[] {
        const { actuallyMoved } = this.drawing;
        const { a, b } = this.getNewLine();

        const lineColor = actuallyMoved ? ElementColor.Building : ElementColor.Default;
        const dotColor = actuallyMoved ? ElementColor.Building : ElementColor.Util;

        return [
            DotElement.create({
                id: "temp__a",
                x: a.x,
                y: a.y,
                overrideRenderOrder: 0,
                color: dotColor,
            }),
            DotElement.create({
                id: "temp__b",
                x: b.x,
                y: b.y,
                overrideRenderOrder: 0,
                color: dotColor,
            }),
            LineElement.create({
                id: "temp__line",
                x1: a.x,
                y1: a.y,
                x2: b.x,
                y2: b.y,
                overrideRenderOrder: 0,
                color: lineColor,
            }),
        ];
    }
}
