import { deepClone } from "@viuch/shared/utils/data/copy";

import type {
    TRawPatchThemeReview,
    TRawPatchedThemeReview,
    TRawReviewFiltersRequest,
    TRawThemeReview,
} from "./ReviewsService.types";
import type { ReviewsFilters } from "../../entities/reviews/ReviewsFilters";

import { ThemeReview } from "../../entities/reviews/ThemeReview";

import { mapReviewComment } from "./ReviewCommentsService.utils";

export function mapThemeReview(data: TRawThemeReview): ThemeReview {
    const { id, theme, updated_at, created_at, is_verified, student, text, comments } = data;

    return new ThemeReview(
        id,
        mapThemeReviewTheme(theme),
        new Date(updated_at),
        new Date(created_at),
        is_verified,
        mapThemeReviewStudent(student),
        text,
        mapThemeReviewComments(comments)
    );
}

export function mapThemeReviewTheme(data: TRawThemeReview["theme"]): ThemeReview["theme"] {
    const { id, name, subjects } = data;

    return {
        id,
        name,
        subjects: subjects.map((s) => ({ id: s.id, name: s.name })),
    };
}

export function mapThemeReviewStudent(data: TRawThemeReview["student"]): ThemeReview["student"] {
    const { id, username } = data;

    return { id, username };
}

export function mapThemeReviewComments(data: TRawThemeReview["comments"]): ThemeReview["comments"] {
    return data.map(mapReviewComment);
}

export function serializePatchThemeReview(data: ThemeReview): TRawPatchThemeReview {
    const { isVerified, text } = data;

    return {
        is_verified: isVerified,
        text,
    };
}

// TODO: на бэке неполные данные (theme, student, comments)
export function mapPatchedThemeReview(sourceThemeReview: ThemeReview, data: TRawPatchedThemeReview): ThemeReview {
    const { id, is_verified, text, created_at, updated_at } = data;
    const { theme, student, comments } = sourceThemeReview;

    return new ThemeReview(
        id,
        deepClone(theme),
        new Date(updated_at),
        new Date(created_at),
        is_verified,
        deepClone(student),
        text,
        comments.map((comment) => comment.clone())
    );
}

export function serializeReviewFilters(filters: ReviewsFilters): TRawReviewFiltersRequest {
    const serializedFilters: Partial<Record<string, string | boolean>> = {
        is_verified: filters.isVerified,
        ordering: filters.ordering,
        has_paid_subscription: filters.hasPaidSubscription,
    };

    if (filters.showAllUsers) {
        delete serializedFilters.has_paid_subscription;
    }

    return serializedFilters;
}
