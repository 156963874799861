import { generateUuid } from "@viuch/shared/utils/data";

import type { ISerializedRoot } from "./types";
import type { TAnySerializedElement } from "../../types";

import { createSerializedContainer } from "../../utils/serialization";

export function createSerializedRoot(squared: boolean, index: TAnySerializedElement[]): ISerializedRoot {
    return {
        type: "root",
        uuid: generateUuid(),
        isSquared: squared,
        content: createSerializedContainer(),
        indicator: createSerializedContainer(index),
    };
}
