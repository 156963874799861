import React from "react";

type TAction<T> = (arg: T) => void;

type TUseLongPress<T> = {
    handleDown: TAction<T>;
    handleUp: VoidFunction;
    handleCancel: VoidFunction;
};

type TLongPressCallback<T> = (isLong: boolean, arg: T) => void;

export function useLongPress<T>(delayMs: number, callback: TLongPressCallback<T>): TUseLongPress<T> {
    const timerRef = React.useRef<number>();
    const argRef = React.useRef<T>();

    return React.useMemo(() => {
        function handleDown(arg: T) {
            argRef.current = arg;
            timerRef.current = window.setTimeout(() => {
                cancel();
                callback(true, arg);
            }, delayMs);
        }

        function handleUp() {
            if (timerRef.current === undefined) {
                return;
            }
            cancel();
            callback(false, argRef.current!);
        }

        function cancel() {
            window.clearTimeout(timerRef.current);
            timerRef.current = undefined;
        }

        function handleCancel() {
            cancel();
        }

        return {
            handleDown,
            handleUp,
            handleCancel,
        };
    }, [callback, delayMs]);
}
