import type { TEllipse, TFragment, TLine, TPoint } from "./types";

export function isCoordinate(value: unknown): value is number {
    return typeof value === "number";
}

export function isPointType(value: unknown): value is TPoint {
    if (!value || typeof value !== "object") return false;

    const { x, y } = value as Partial<TPoint>;
    return isCoordinate(x) && isCoordinate(y);
}

export function isLineType(value: unknown): value is TLine {
    if (!value || typeof value !== "object") return false;

    const { x1, x2, y1, y2 } = value as Partial<TLine>;
    return isCoordinate(x1) && isCoordinate(y1) && isCoordinate(x2) && isCoordinate(y2);
}

export function isFragmentType(value: unknown): value is TFragment {
    if (!value || typeof value !== "object") return false;

    const { a, b } = value as Partial<TFragment>;
    return isPointType(a) && isPointType(b);
}

export function isEllipseType(value: unknown): value is TEllipse {
    if (!value) return false;
    if (typeof value !== "object") return false;

    const { center, rx, ry } = value as Partial<TEllipse>;
    return isPointType(center) && isCoordinate(rx) && isCoordinate(ry);
}
