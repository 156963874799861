import type { TAlphabetsMap } from "./AlphabetBlock.types";

import { createSpaceAction } from "../../../../../actions/space";
import { generateCharsArray } from "../../../../../utils/data";
import { charActionCreator } from "../../../utils";

export const alphabetsMap: TAlphabetsMap = {
    en: {
        topChars: generateCharsArray("a", "x").map((char) => ({
            char,
            actionCreator: charActionCreator(char),
            tags: ["alphabet", "alphabet-en"],
        })),
        bottomChars: [
            {
                char: "y",
                actionCreator: charActionCreator("y"),
                tags: ["alphabet", "alphabet-en"],
            },
            {
                actionCreator: () => createSpaceAction(),
                tags: ["alphabet", "alphabet-en", "space"],
            },
            {
                char: "z",
                actionCreator: charActionCreator("z"),
                tags: ["alphabet", "alphabet-en"],
            },
        ],
    },
    ru: {
        topChars: [
            ...generateCharsArray("а", "е"),
            "ё",
            ...generateCharsArray("ж", "и"),
            ...generateCharsArray("к", "э"),
        ].map((char) => ({
            char,
            actionCreator: charActionCreator(char),
            tags: ["alphabet", "alphabet-ru"],
        })),
        bottomChars: [
            {
                actionCreator: () => createSpaceAction(),
                tags: ["alphabet", "alphabet-ru", "space"],
            },
            {
                char: "ю",
                actionCreator: charActionCreator("ю"),
                tags: ["alphabet", "alphabet-ru"],
            },
            {
                char: "я",
                actionCreator: charActionCreator("я"),
                tags: ["alphabet", "alphabet-ru"],
            },
        ],
    },
    greek: {
        topChars: generateCharsArray("α", "ψ").map((char) => ({
            char,
            actionCreator: charActionCreator(char),
            tags: ["alphabet", "alphabet-greek"],
        })),
        bottomChars: [
            {
                actionCreator: () => createSpaceAction(),
                tags: ["alphabet", "alphabet-greek", "space"],
            },
            {
                char: "ω",
                actionCreator: charActionCreator("ω"),
                tags: ["alphabet", "alphabet-greek"],
            },
        ],
    },
};
