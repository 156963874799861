import type { ISerializedAffiliation, TAffiliationChar } from "./types";
import type { TSerializedElementPrototype } from "../../core/element";
import type { IElementVisitor } from "../../core/element/IElementVisitor";
import type { InputService } from "../../services";
import type { TElementDeserializerFunction } from "../../types";

import { BaseElementModel } from "../../core/element";

export class AffiliationModel extends BaseElementModel<ISerializedAffiliation> {
    public readonly symbolName: TAffiliationChar;

    public constructor(inputService: InputService, symbolName: TAffiliationChar, uuid?: string) {
        super(inputService, uuid);

        this.symbolName = symbolName;
    }

    override isEquals(otherElement: BaseElementModel): boolean {
        return otherElement instanceof AffiliationModel && otherElement.symbolName === this.symbolName;
    }

    public serialize(): ISerializedAffiliation {
        return { type: "affiliation", uuid: this.uuid, char: this.symbolName };
    }

    public serializeAsClone(): TSerializedElementPrototype<ISerializedAffiliation> {
        const { uuid, ...clone } = this.serialize();
        return clone;
    }

    public static deserialize: TElementDeserializerFunction<ISerializedAffiliation> = (
        { inputService },
        { uuid, char }
    ) => new AffiliationModel(inputService, char, uuid);

    public accept<R>(visitor: IElementVisitor<R>): R {
        return visitor.doWithAffiliation(this);
    }
}
