import { InputService } from "@viuch/math-editor";

import type { IEditorVisitor } from "../IEditorVisitor";
import type { IMathConverterService } from "../types";
import type { EditorModel, KeyboardService, TSerializedState } from "@viuch/math-editor";

import { BaseEditor, EditorTypes } from "../base";

export type TMathEditorData = {
    type: EditorTypes.math;
    state?: TSerializedState;
    textRepresentation?: string;
};

export class MathEditor extends BaseEditor<TMathEditorData> {
    private readonly inputService: InputService;
    public readonly keyboardService?: KeyboardService;
    private mathConverterService?: IMathConverterService;
    private textRepresentation?: string;

    public constructor(state?: TSerializedState, textRepresentation?: string) {
        super();
        this.textRepresentation = textRepresentation;

        this.inputService = new InputService({
            multiline: true,
        });

        if (state) {
            this.inputService.setSerializedState(state);
        }
    }

    public get inputModel(): EditorModel {
        return this.inputService.model;
    }

    public serialize(): Promise<TMathEditorData> {
        return Promise.resolve({
            type: EditorTypes.math,
            state: this.inputService.getSerializedState(),
            textRepresentation: this.textRepresentation,
        });
    }

    public accept<R>(visitor: IEditorVisitor<R>): R {
        return visitor.visitMathEditor(this);
    }

    public checkIsEmpty(): boolean {
        return this.inputService.getElementsCount() < 1;
    }

    setMathConverterService(mathConverterService: IMathConverterService) {
        this.mathConverterService = mathConverterService;
    }

    setTextRepresentation(text: string) {
        this.textRepresentation = text;
    }
}
