import { makeObservable, observable } from "mobx";

import type { BaseDrawingGenerationSettings } from "./generated-drawing/BaseDrawingGenerationSettings";
import type { IVariableContentVisitor } from "./IVariableContentVisitor";

import { BaseVariableContent } from "./BaseVariableContent";
import { DistanceToLine } from "./generated-drawing/DistanceToLine";

export class DrawingSettingsVariableContent extends BaseVariableContent {
    @observable
    settings: BaseDrawingGenerationSettings;

    constructor(init: DrawingSettingsVariableContent.InitObject) {
        super();

        this.settings = init.settings;

        makeObservable(this);
    }

    accept<R>(visitor: IVariableContentVisitor<R>): R {
        return visitor.doWithDrawingSettings(this);
    }

    static createEmpty() {
        return new DrawingSettingsVariableContent({ settings: DistanceToLine.createEmpty() });
    }

    clone(): DrawingSettingsVariableContent {
        return new DrawingSettingsVariableContent({ settings: this.settings.clone() });
    }
}

declare namespace DrawingSettingsVariableContent {
    type InitObject = {
        settings: BaseDrawingGenerationSettings;
    };
}
