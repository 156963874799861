import cn from "classnames";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";

import { ZoomButtons } from "@viuch/ui-kit/components/zoom-buttons/ZoomButtons";
import { useConst } from "@viuch/utils/hooks";

import type { Figure2D } from "../entities/Figure2D";

import { Figure2DController } from "./Figure2DController";
import { FigureViewport, RenderElements } from "./viewport/components";

import styles from "./Figure2DView.module.scss";

type Props = {
    figure: Figure2D;
    isStatic?: boolean;
    className?: string;
};

export const Figure2DView = observer(function Figure2DView({ className, isStatic, figure }: Props) {
    const controller = useConst(() => {
        const controller = new Figure2DController(figure);
        controller.initializeView();
        return controller;
    });

    const { viewport, events, device, flows } = controller;

    useEffect(() => {
        if (isStatic) {
            viewport.disable();
        }
    }, [isStatic, viewport]);

    return (
        <div className={cn(className, styles.wrapper)}>
            <FigureViewport
                figure={figure}
                viewport={viewport}
                events={events}
                device={device}
                isStatic={isStatic}
            >
                <RenderElements
                    events={events}
                    figure={figure}
                    flows={flows}
                />
            </FigureViewport>
            {!isStatic && (
                <ZoomButtons
                    onPlus={() => viewport.offsetScale(0.2)}
                    onMinus={() => viewport.offsetScale(-0.2)}
                />
            )}
        </div>
    );
});
