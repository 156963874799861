import { action, makeObservable, observable } from "mobx";

import {
    mapGraph2DSettings,
    serializeGraph2DSettings,
} from "@viuch/feature-instrument-settings/serializers/mapGraph2D";
import { createNewGraph2DSettings } from "@viuch/instrument-graph2d-settings/serialization/createNewGraph2DSettings";
import { generateUuid } from "@viuch/shared/utils/data";
import { assert } from "@viuch/utils/debug";

import type { IEditorVisitor } from "../IEditorVisitor";
import type { TRawGraph2DInstrumentEditorInit } from "@viuch/feature-instrument-editor/service-types/graph2d";
import type { TRawGraph2DInstrumentSettings } from "@viuch/feature-instrument-settings/service-types/graph2d";
import type { IGraph2DSettingsHost } from "@viuch/instrument-graph2d-settings/interfaces/IGraph2DSettingsHost";
import type { Graph2DInstrumentSettings } from "@viuch/instrument-graph2d-settings/settings-entity/Graph2DInstrumentSettings";

import { BaseEditor, EditorTypes } from "../base";

export type TGraph2DInstrumentEditorData = {
    type: EditorTypes.graph2d;
    init?: TRawGraph2DInstrumentEditorInit;
    settings?: TRawGraph2DInstrumentSettings;
    isStatic?: boolean | undefined;
};

export interface IComplexEditorGraph2DHost extends IGraph2DSettingsHost {
    serializeGraph2DSettingsToViewAsync(
        instrumentSettings: Graph2DInstrumentSettings
    ): Promise<TRawGraph2DInstrumentEditorInit>;
}

export class Graph2DEditor extends BaseEditor<TGraph2DInstrumentEditorData> {
    private host?: IComplexEditorGraph2DHost;

    readonly instrumentSettings: Graph2DInstrumentSettings;
    private graphInitSettings?: TRawGraph2DInstrumentEditorInit;

    constructor(data: TGraph2DInstrumentEditorData) {
        super();

        const { settings, init, isStatic } = data;

        this.instrumentSettings = settings ? mapGraph2DSettings(settings) : createNewGraph2DSettings();
        this.graphInitSettings = init;
        this.interactiveView = !isStatic;

        makeObservable(this);
    }

    setHost = (host: IComplexEditorGraph2DHost) => {
        this.host = host;
    };

    accept<R>(visitor: IEditorVisitor<R>): R {
        return visitor.visitGraph2D(this);
    }

    checkIsEmpty(): boolean {
        return false;
    }

    @observable.ref interactiveView: boolean;
    @action setInteractiveView = (v: boolean) => void (this.interactiveView = v);

    async serialize(): Promise<TGraph2DInstrumentEditorData> {
        assert(this.host);

        const init = await this.host.serializeGraph2DSettingsToViewAsync(this.instrumentSettings);
        const settings = serializeGraph2DSettings(this.instrumentSettings, generateUuid());
        const isStatic = !this.interactiveView;

        this.graphInitSettings = init;

        return {
            type: EditorTypes.graph2d,
            init,
            settings,
            isStatic,
        };
    }
}
