import type { ReviewCommentsFilters } from "./ReviewCommentsFilters";
import type { TRawPatchedReviewComment, TRawReviewComment } from "./ReviewCommentsService.types";
import type { ReviewComment } from "../../entities/reviews/ReviewComment";

import { agent } from "../../agent";
import { throwServiceError } from "../base/RequestError";

import {
    mapPatchedReviewComment,
    mapReviewComment,
    serializeCreateReviewComment,
    serializePatchReviewComment,
} from "./ReviewCommentsService.utils";

export class ReviewCommentsService {
    async fetchAll(filters?: ReviewCommentsFilters): Promise<ReviewComment[]> {
        try {
            const { data } = await agent.get<TRawReviewComment[]>(`/feedback/comments/`, {
                params: filters?.serialized,
            });

            return data.map(mapReviewComment);
        } catch (e) {
            throwServiceError(e);
        }
    }

    async createComment(comment: ReviewComment) {
        try {
            const { data } = await agent.post<TRawReviewComment>(
                `/feedback/comments/`,
                serializeCreateReviewComment(comment)
            );

            return mapReviewComment(data);
        } catch (e) {
            throwServiceError(e);
        }
    }

    async patchComment(comment: ReviewComment) {
        try {
            const { data } = await agent.patch<TRawPatchedReviewComment>(
                `/feedback/comments/${comment.id}`,
                serializePatchReviewComment(comment)
            );

            return mapPatchedReviewComment(comment, data);
        } catch (e) {
            throwServiceError(e);
        }
    }

    // /** @deprecated use 'denyComment' instead */
    // async removeCommentById(commentId: number) {
    //     try {
    //         await agent.delete(`/feedback/comments/${commentId}/`);
    //     } catch (e) {
    //         throwServiceError(e);
    //     }
    // }

    async denyComment(commentId: number, reason: string) {
        try {
            const requestData = { reason };

            await agent.post(`/feedback/comments/${commentId}/deny/`, requestData);
        } catch (e) {
            throwServiceError(e);
        }
    }
}
