import React from "react";

import type { EqualityModel } from "./EqualityModel";
import type { TElementProps } from "../../types/element";

import { useMathEditorElementRef } from "../../hooks";

import { BaseEqualitySymbolElement } from "./BaseEqualitySymbolElement";
import { equalitySymbols } from "./EqualityElement.constants";

export const EqualityElement: React.VFC<TElementProps<EqualityModel>> = ({ elementModel }) => {
    const { char, className } = equalitySymbols[elementModel.symbolName]!;
    const ref = useMathEditorElementRef(elementModel);

    return (
        <BaseEqualitySymbolElement
            elementModel={elementModel}
            className={className}
            lineRef={ref}
        >
            {char}
        </BaseEqualitySymbolElement>
    );
};
