import type { Figure2DController } from "../../Figure2DController";
import type { ToolbarMenu } from "../../toolbar";

import { ElementColor } from "../../elements/ElementColor";
import { EllipseElement } from "../../elements/ellipse";
import { CircumscribedCircleModel } from "../../models/circle";
import { LabelPointModel } from "../../models/label-point";
import { PointModel } from "../../models/point";
import { ButtonStates } from "../../toolbar";
import { TooltipMenu } from "../../toolbar/tooltip";
import { createDefaultToolbarMenuBuilder } from "../../utils/toolbar";
import { z } from "../utils";

import { InscribedCircleFlow } from "./InscribedCircleFlow";

export class CircumscribedCircleFlow extends InscribedCircleFlow {
    protected override finishCreatingInternal() {
        if (this.flow.points.length >= 3) {
            this.figure.insertModels(function* () {
                yield CircumscribedCircleModel.create({ points: this.flow.points, style: null });

                for (const point of this.flow.points) {
                    yield PointModel.create({ x: point.x, y: point.y, style: null });
                    yield LabelPointModel.createNext(point, this.figure);
                }
            }, this);
        }
    }

    static override create(data: Figure2DController) {
        return new CircumscribedCircleFlow(data);
    }

    override getToolbarMenu(): ToolbarMenu {
        return createDefaultToolbarMenuBuilder()
            .setButtonState(ButtonStates.active, ["circles"])
            .setButtonIcon("circle-circumscribed", ["circles"])
            .build();
    }

    override getTooltipMenu(): TooltipMenu | null {
        let text: string;
        if (this.flow.points.length >= 3) {
            text = "Выберите все вершины фигуры. Чтобы описать окружность, замкните контур";
        } else {
            text = "Выберите все вершины фигуры";
        }

        return new TooltipMenu(text);
    }

    protected override get tempCircle() {
        const points = [...this.flow.points];
        if (points.length >= 3) {
            const exampleModel = new CircumscribedCircleModel({ points, style: null }, -1);

            const { center, radius } = exampleModel;
            if (center !== null && radius !== null) {
                return new EllipseElement({
                    id: `temp_inscribed_circle`,
                    center,
                    rx: radius,
                    ry: radius,
                    color: ElementColor.Computed,
                    overrideRenderOrder: z.circles.priority,
                    thin: false,
                });
            }
        }
    }
}
