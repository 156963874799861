import type { IntegralModel } from "./IntegralModel";
import type { ContainerModel } from "../../core/container";
import type { BaseElementModel } from "../../core/element";

import { CharsOnlyFilter } from "../../core/strategies/filters/CharsOnlyFilter";
import { MultiContainerElementStrategy } from "../../core/strategies/MultiContainerElementStrategy";

export class IntegralStrategy extends MultiContainerElementStrategy<IntegralModel> {
    private readonly argFilter: CharsOnlyFilter;

    constructor(model: IntegralModel) {
        super(model);

        this.argFilter = new CharsOnlyFilter();
    }

    override checkCanBeInserted(element: BaseElementModel, container: ContainerModel<IntegralModel>): boolean {
        if (container === this.model.argContent) {
            return this.argFilter.canInsert(element);
        }
        return super.checkCanBeInserted(element, container);
    }
}
