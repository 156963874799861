import { createSortAscBy } from "@viuch/shared/utils/data";

import type { Grade, TGradeShort } from "../../entities/grades";

import { agent } from "../../agent";
import { throwServiceError } from "../base/RequestError";

import { mapGrade, serializeGrade } from "./gradeSerializers";

export class GradesService {
    async all(): Promise<Grade[]> {
        try {
            const { data } = await agent.get<GradesService.TGradeResponse[]>(`/grades/`);
            return data.map(mapGrade).sort(createSortAscBy((g) => g.gradeValue));
        } catch (e) {
            throwServiceError(e);
        }
    }

    async byId(gradeId: string | number): Promise<Grade> {
        try {
            const { data } = await agent.get<GradesService.TGradeResponse>(`/grades/${gradeId}/`);
            return mapGrade(data);
        } catch (e) {
            throwServiceError(e);
        }
    }

    async create(grade: Grade): Promise<Grade> {
        try {
            const request = serializeGrade(grade);
            const { data } = await agent.post<GradesService.TGradeResponse>("/grades/", request);
            return mapGrade(data);
        } catch (e) {
            throwServiceError(e);
        }
    }

    async update(grade: Grade): Promise<Grade> {
        try {
            const request = serializeGrade(grade);
            const { data } = await agent.patch<GradesService.TGradeResponse>(`/grades/${grade.id}/`, request);
            return mapGrade(data);
        } catch (e) {
            throwServiceError<GradesService.TGradeRequestError>(e);
        }
    }

    async delete(gradeId: number): Promise<void> {
        try {
            await agent.delete(`/grades/${gradeId}/`);
        } catch (e) {
            throwServiceError(e);
        }
    }

    async allShort(): Promise<TGradeShort[]> {
        return (await this.all()).map((grade) => ({
            id: grade.id,
            gradeValue: grade.gradeValue,
        }));
    }
}

export namespace GradesService {
    export type TGradeRequest = {
        id: number;
        exams: number[];
        grade: number;
        final_exam: number | null;
        subjects: number[];
    };

    export type TGradeRequestError = {
        id: number;
        exams: string[];
        grade: number;
        final_exam: string[];
        subjects: number[];
    };

    export type TGradeResponse = {
        id: number;
        exams: number[];
        grade: number;
        final_exam: number;
        subjects: number[];
    };

    export type TGradeShortResponse = {
        id: number;
        grade: number;
    };
}
