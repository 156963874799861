export function compareFloats(a: number, b: number) {
    if (Math.abs(a - b) < 1e-8) return 0;

    const _a = +a.toFixed(7);
    const _b = +b.toFixed(7);
    const diff = _a - _b;
    return Math.sign(diff);
}

export function equateNumbers(left: number, op: "<" | "<=" | "==" | ">=" | ">", right: number): boolean {
    const r = compareFloats(left, right);
    switch (op) {
        case "<":
            return r < 0;
        case "<=":
            return r <= 0;
        case "==":
            return r === 0;
        case ">=":
            return r >= 0;
        case ">":
            return r > 0;
    }
}

export function isFloatInRange(left: number, target: number, right: number): boolean {
    return compareFloats(target, left) >= 0 && compareFloats(target, right) <= 0;
}
