import { action, makeObservable, observable } from "mobx";

export class Grade {
    @observable id: number;
    @observable gradeValue: number;
    @observable.shallow examIds: number[];
    @observable finalExamId: number | null;
    @observable.shallow subjectIds: number[];

    constructor(id: number, gradeValue: number, examIds: number[], finalExamId: number | null, subjectIds: number[]) {
        this.id = id;
        this.gradeValue = gradeValue;
        this.examIds = examIds.slice();
        this.finalExamId = finalExamId;
        this.subjectIds = subjectIds.slice();

        makeObservable(this);
    }

    @action.bound
    setGradeValue(gradeValue: number) {
        this.gradeValue = gradeValue;
    }

    @action.bound
    addExamId(examId: number) {
        if (this.examIds.includes(examId)) return;

        this.examIds.push(examId);
    }

    @action.bound
    removeExamId(examId: number) {
        this.examIds.remove(examId);
    }

    @action.bound
    setFinalExamId(examId: number | null) {
        this.finalExamId = examId;
    }

    @action.bound
    addSubjectId(subjectId: number) {
        if (this.subjectIds.includes(subjectId)) return;

        this.subjectIds.push(subjectId);
    }

    @action.bound
    setSubjectIds(subjectIds: number[]) {
        this.subjectIds = [...new Set(subjectIds)];
    }

    @action.bound
    removeSubjectId(subjectId: number) {
        this.subjectIds.remove(subjectId);
    }
}
