import { observer } from "mobx-react-lite";
import React from "react";

import type { EquationsSetModel } from "./EquationsSetModel";
import type { ICurlyBracketController } from "../../core/dynamic-brackets";
import type { TElementProps } from "../../types/element";

import { ContainerElement } from "../../core/container";
import { CurlyBracket, defaultCurlyBracketConfig, defaultSquareBracketConfig } from "../../core/dynamic-brackets";
import { useMathEditorElementRef } from "../../hooks";

import styles from "./EquationsSetElement.module.scss";

export const EquationsSetElement: React.VFC<TElementProps<EquationsSetModel>> = observer(function EquationsSetElement({
    elementModel,
}) {
    const ref = useMathEditorElementRef<HTMLDivElement>(elementModel);

    const contentRef = React.useRef<HTMLDivElement>(null);
    const leftBracketRef = React.useRef<CurlyBracket>(null);

    React.useEffect(() => {
        const element = contentRef.current!;
        const bracket: ICurlyBracketController = leftBracketRef.current!;

        const isSquare = elementModel.variant === "or";

        function updateBracket(height: number): void {
            const config = isSquare ? defaultSquareBracketConfig(height) : defaultCurlyBracketConfig(height);
            bracket.updateBracketParams(config);
        }

        const observer = new ResizeObserver(([entry]) => {
            const { height } = entry.contentRect;
            updateBracket(height);
        });

        updateBracket(element.getBoundingClientRect().height);

        observer.observe(element);

        return () => {
            observer.disconnect();
        };
    }, [elementModel.variant, ref]);

    const bracketType = elementModel.variant === "and" ? "curly" : "square";

    return (
        <div
            className={styles.wrapper}
            ref={ref}
        >
            <CurlyBracket
                ref={leftBracketRef}
                className={styles.bracket}
                bracketType={bracketType}
            />
            <div
                className={styles.content}
                ref={contentRef}
            >
                <div className={styles.contentInner}>
                    {elementModel.equations.map((container) => (
                        <ContainerElement
                            key={container.uuid}
                            containerModel={container}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
});
