import { generateUuid } from "@viuch/shared/utils/data";

import type { Graph2DModel } from "./Graph2DModel";
import type { Graph2DViewSettings } from "../editor/core/Graph2DViewSettings";

import { configureGraph2DKernel } from "../editor/configureGraph2D";
import { Graph2DEvents } from "../editor/core/Graph2DEvents";
import { Graph2DFlowsController } from "../editor/core/Graph2DFlowsController";
import { Graph2DHistory } from "../editor/core/Graph2DHistory";
import { Graph2DPreRenderingService } from "../editor/core/Graph2DPreRenderingService";
import { Graph2DToolbar } from "../editor/core/Graph2DToolbar";
import { Graph2DViewportController } from "../editor/core/Graph2DViewportController";
import { GraphElementsRendererService } from "../editor/rendering/GraphElementsRendererService";

export class Graph2DView {
    readonly $uuid = generateUuid();
    readonly isReadonly: boolean;
    readonly model: Graph2DModel;
    readonly settings: Graph2DViewSettings;

    readonly preRenderer: Graph2DPreRenderingService;
    readonly renderer: GraphElementsRendererService;

    readonly viewport: Graph2DViewportController;
    readonly events: Graph2DEvents;
    readonly flows: Graph2DFlowsController;
    readonly toolbar: Graph2DToolbar;
    readonly history: Graph2DHistory;

    constructor(model: Graph2DModel, settings: Graph2DViewSettings, isReadonly: boolean) {
        this.isReadonly = isReadonly;
        this.model = model;
        this.settings = settings;

        this.preRenderer = new Graph2DPreRenderingService();
        this.renderer = new GraphElementsRendererService();

        this.viewport = new Graph2DViewportController(settings);
        this.history = new Graph2DHistory(model, this.viewport);
        this.flows = new Graph2DFlowsController(model, settings, this.viewport, this.preRenderer, isReadonly);
        this.events = new Graph2DEvents(this.flows, this.viewport);
        this.toolbar = new Graph2DToolbar(this.flows);

        configureGraph2DKernel(this);
    }
}
