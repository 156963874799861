import { action, computed, makeObservable, observable } from "mobx";

import { buildFunc } from "@viuch/geometry-lib/transformations/functions";
import { base64ToBytes } from "@viuch/shared/utils/crypto/base64ToBytes";
import { textToBytes } from "@viuch/shared/utils/crypto/textToBytes";
import { verifyRsaSignature } from "@viuch/shared/utils/crypto/verifyRsaSignature";
import { assert } from "@viuch/utils/debug";

import type { Graph2DModel } from "../core/Graph2DModel";
import type { IIntervalsCollection } from "../editor/model/BaseGraphItem";
import type { GraphFunctionInterval } from "../editor/model/GraphFunctionInterval";
import type { BaseTransformation } from "../editor/transformations/BaseTransformation";
import type { TFunc } from "@viuch/geometry-lib/transformations/functions";
import type { TSerializedState } from "@viuch/math-editor";

import { BaseGraphItem } from "../editor/model/BaseGraphItem";

export class FormulaEvalFunctionGraphItem extends BaseGraphItem implements IIntervalsCollection {
    readonly sourceFunction: TSignedEvalFunc;
    readonly sourceFormula: TSerializedState;
    readonly color: string;
    readonly sourceFunc: TFunc;

    @observable.shallow intervalsTree: GraphFunctionInterval[];

    @computed get funcIntervals(): {
        interval: GraphFunctionInterval;
        func: TFunc;
        path: { interval: GraphFunctionInterval; transformation: BaseTransformation }[];
    }[] {
        const { intervalsTree, sourceFunc } = this;

        const paths: {
            interval: GraphFunctionInterval;
            func: TFunc;
            path: { interval: GraphFunctionInterval; transformation: BaseTransformation }[];
        }[] = [];

        function traverse(
            parents: { interval: GraphFunctionInterval; transformation: BaseTransformation }[],
            interval: GraphFunctionInterval
        ): void {
            const { transformation } = interval;

            if (!transformation) {
                const func = buildFunc(
                    sourceFunc,
                    ...parents.map((p) => p.transformation.transformer.transformFunction)
                );

                paths.push({ interval, func, path: parents });
            } else {
                const newTransformers = parents.concat({ transformation, interval });

                for (const innerInterval of transformation.intervalsTree) {
                    traverse(newTransformers, innerInterval);
                }
            }
        }

        for (const interval of intervalsTree) {
            traverse([], interval);
        }

        return paths;
    }

    constructor(
        func: TSignedEvalFunc,
        formula: TSerializedState,
        intervalsTree: GraphFunctionInterval[],
        color: string
    ) {
        super();

        this.sourceFunction = func;
        this.sourceFormula = formula;
        this.color = color;
        this.intervalsTree = intervalsTree;

        const functionSource = new Function("x", func.js);
        this.sourceFunc = (x) => functionSource.call(null, x);

        makeObservable(this);
    }

    override clone(): FormulaEvalFunctionGraphItem {
        const { sourceFunction, sourceFormula, intervalsTree, color } = this;
        return new FormulaEvalFunctionGraphItem(
            sourceFunction,
            sourceFormula,
            intervalsTree.map((i) => i.clone()),
            color
        );
    }

    override beforeAdd(model: Graph2DModel): void {
        const { publicKey } = model;
        const { js, signature } = this.sourceFunction;

        const isValid = verifyRsaSignature(textToBytes(js), publicKey, base64ToBytes(signature));

        assert(isValid);
    }

    @action.bound
    removeIntervalFromTree(interval: GraphFunctionInterval) {
        removeIn(this);

        function removeIn(container: IIntervalsCollection): boolean {
            for (const item of container.intervalsTree) {
                if (item === interval) {
                    container.removeInterval(item);
                    return true;
                } else if (item.transformation) {
                    const removed = removeIn(item.transformation);
                    if (removed) return true;
                }
            }
            return false;
        }
    }

    @action.bound
    removeInterval(interval: GraphFunctionInterval) {
        this.intervalsTree.remove(interval);
    }

    @action.bound
    addInterval(interval: GraphFunctionInterval) {
        this.intervalsTree.push(interval);
    }

    @action.bound
    setIntervalsTree(intervalsTree: GraphFunctionInterval[]) {
        this.intervalsTree = intervalsTree;
    }
}

export type TSignedEvalFunc = {
    js: string;
    signature: string;
};
