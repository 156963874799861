import { generateUuid } from "@viuch/shared/utils/data";

import type { ISerializedDifferential } from "./types";
import type { TAnySerializedElement } from "../../types";

import { createSerializedContainer } from "../../utils/serialization";

export function createSerializedDifferential(denominator: TAnySerializedElement[]): ISerializedDifferential {
    return {
        type: "differential",
        uuid: generateUuid(),
        denominator: createSerializedContainer(denominator),
        content: createSerializedContainer(),
    };
}
