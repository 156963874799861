import type { ContainerModel } from "../core/container";
import type { BaseElementModel } from "../core/element";
import type { IMultiContainerElement } from "../core/strategies/types";

import { isChar } from "../elements/char/utils";
import { isDigit } from "../elements/digit/utils";

export function computeNestingDown(container?: ContainerModel): number {
    let parent = container?.parentElement;
    let count = 0;

    while (parent) {
        count++;
        parent = parent.parentContainer?.parentElement;
    }

    return count;
}

export function isMultiContainerElement(element: BaseElementModel): element is IMultiContainerElement {
    return typeof (element as unknown as Record<string, unknown>)?.getContainersToMoveCursorBetween === "function";
}

export function computeNestingUp(elements: BaseElementModel[], startLevel: number): number {
    function computeContainerRecursive(elements: BaseElementModel[], level: number): number {
        const nextLevel = level + 1;
        return Math.max(
            nextLevel,
            ...elements.map<number>((element) => {
                if (!isMultiContainerElement(element)) {
                    return nextLevel;
                }
                return Math.max(
                    nextLevel,
                    ...element
                        .getContainersToMoveCursorBetween()
                        .map<number>((container) => computeContainerRecursive(container.elements, nextLevel))
                );
            })
        );
    }

    return computeContainerRecursive(elements, startLevel - 2);
}

export function isPolynomial(elements: BaseElementModel[]): boolean {
    const elementChecker = isDigit(elements[0]) ? isDigit : isChar(elements[0]) ? isChar : null;

    if (!elementChecker?.(elements[1])) {
        return true;
    }

    for (let i = 2; i < elements.length; i++) {
        if (!elementChecker(elements[i])) {
            return true;
        }
    }

    return false;
}
