import { action, makeObservable, observable } from "mobx";

import { createSerializedContainer } from "@viuch/math-editor";

import type { BaseTaskInstrumentSettings } from "../task-instruments-settings/BaseTaskInstrumentSettings";
import type { TSerializedState } from "@viuch/math-editor";

import { cloneTaskInstrumentSettings } from "../task-instruments-settings/cloneTaskInstrumentSettings";
import { VariableGenerationTypes } from "../tasks";
import { PrimaryHints } from "../tasks/PrimaryHints";
import { Variable } from "../tasks/Variable";
import { NumberVariableContent } from "../tasks/variables";

export class Problem {
    readonly id: number;
    @observable.ref formula: TSerializedState;
    @observable.ref description: TSerializedState;
    @observable.ref primaryHints: PrimaryHints | null;
    @observable.shallow variables: Variable[];
    @observable.shallow instruments: BaseTaskInstrumentSettings[];
    readonly tasks: readonly number[];
    readonly themes: readonly number[];

    constructor(
        id: number,
        formula: TSerializedState,
        description: TSerializedState,
        primaryHints: PrimaryHints | null,
        variables: Variable[],
        instruments: BaseTaskInstrumentSettings[],
        tasks: readonly number[],
        themes: readonly number[]
    ) {
        this.id = id;
        this.formula = formula;
        this.description = description;
        this.primaryHints = primaryHints;
        this.variables = variables.slice();
        this.instruments = instruments.slice();
        this.tasks = tasks.slice();
        this.themes = themes.slice();

        makeObservable(this);
    }

    @action.bound
    setFormula(formula: TSerializedState) {
        this.formula = formula;
    }

    @action.bound
    setDescription(description: TSerializedState) {
        this.description = description;
    }

    @action.bound
    addVariable() {
        this.variables.push(
            new Variable(
                "",
                VariableGenerationTypes.number,
                new NumberVariableContent({
                    formula: createSerializedContainer(),
                    quantity_fractional_digits: 0,
                    multiple_of: [],
                    conditions: [],
                })
            )
        );
    }

    @action.bound
    removeVariable(variable: Variable) {
        this.variables.remove(variable);
    }

    @action.bound
    cloneVariable(variable: Variable) {
        this.variables.push(variable.clone());
    }

    @action.bound
    enablePrimaryHints() {
        this.primaryHints ??= new PrimaryHints([]);
    }

    @action.bound
    removePrimaryHints() {
        this.primaryHints = null;
    }

    @action.bound
    removeInstrument(instrument: BaseTaskInstrumentSettings) {
        this.instruments.remove(instrument);
    }

    @action.bound
    addInstrument(instrument: BaseTaskInstrumentSettings) {
        this.instruments.push(instrument);
    }

    @action.bound
    setInstrumentByIndex(index: number, instrument: BaseTaskInstrumentSettings) {
        this.instruments[index] = instrument;
    }

    createNewCopy(id: number, taskIds: number[], themeIds: number[]) {
        const { formula, description, instruments, variables, primaryHints } = this;
        return new Problem(
            id,
            formula,
            description,
            primaryHints?.clone() ?? null,
            variables.map((v) => v.clone()),
            instruments.map(cloneTaskInstrumentSettings),
            taskIds,
            themeIds
        );
    }
}
