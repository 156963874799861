import { action, computed, makeObservable } from "mobx";
import { createTransformer } from "mobx-utils";

import { areSamePoints } from "@viuch/geometry-lib/check-geometry";
import { copyPoint } from "@viuch/geometry-lib/factories";
import { createSerializedContainer, isZeroLengthMathExpr } from "@viuch/math-editor";
import { isLinebreakAction } from "@viuch/math-editor/actions/linebreak";

import type { BaseElement } from "../../elements";
import type { Figure2DController } from "../../Figure2DController";
import type { BaseModel } from "../../models";
import type { ToolbarButton, ToolbarMenu } from "../../toolbar";
import type { TPoint } from "@viuch/geometry-lib/types";
import type { InputService, TSerializedState } from "@viuch/math-editor";
import type { TAnyAction } from "@viuch/math-editor/types";
import type React from "react";

import { checkElementTypes, ElementTypes } from "../../elements/BaseElement";
import { LabelDotElement } from "../../elements/label-text";
import { LabelPointModel } from "../../models/label-point";
import { PointModel } from "../../models/point";
import { checkModelType, ModelTypes } from "../../services/actions/utils";
import { ButtonStates } from "../../toolbar";
import { TooltipMenu } from "../../toolbar/tooltip";
import { createDefaultToolbarMenuBuilder, handleToolbarButtons } from "../../utils/toolbar";
import { BaseFlow } from "../BaseFlow";
import { createMapModelToElements, removeModel } from "../utils";

export class CreateLabelDotFlow extends BaseFlow {
    private readonly point: TPoint;
    private initialLabel?: TSerializedState;

    constructor(data: Figure2DController, point: TPoint) {
        super(data);
        this.point = copyPoint(point);

        makeObservable(this, {
            tempElement: computed,
            models: computed,
        });
    }

    attach(): void {
        if (this.existingLabel) {
            this.initialLabel = this.existingLabel.label;
        }
        this.viewport.disable();
    }

    dispose(): void {}

    get existingLabel(): LabelPointModel | null {
        return (
            this.figure.models.find<LabelPointModel>(
                (model): model is LabelPointModel =>
                    checkModelType(model, ModelTypes.labelPoint) && areSamePoints(model, this.point)
            ) ?? null
        );
    }

    override handleElementPointerEvent(e: React.PointerEvent, element: BaseElement): void {
        switch (e.type) {
            case "pointerdown":
            case "pointermove":
            case "pointerup":
            case "pointercancel":
                break;
            default:
                return;
        }
        e.stopPropagation();

        if (e.type === "pointerdown") {
            if (checkElementTypes(element, ElementTypes.labelDot) || checkElementTypes(element, ElementTypes.dot)) {
                e.preventDefault(); // отменить blur
                this.onSave();
                this.nextFlow((data) => new CreateLabelDotFlow(data, element));
            }
        }
    }

    override handleToolbarButtonClick(menu: ToolbarMenu, button: ToolbarButton): void {
        switch (button.key) {
            case "remove":
            case "clear": {
                if (this.existingLabel) {
                    removeModel(this.figure, this.existingLabel);
                }
                this.nextFlow();
                break;
            }
            case "labels":
                return this.nextFlow();
            default:
                handleToolbarButtons(this, button);
        }
    }

    override handleViewportPointerEvent(e: React.PointerEvent): void {
        switch (e.type) {
            case "pointerdown":
                this.onSave();
                this.nextFlow();
                break;
        }
    }

    onSave = action(() => {
        if (this.existingLabel) {
            this.figure.removeModel(this.existingLabel);
        }

        const { x, y } = this.point;
        this.tempElement.updateValue();
        const label = this.tempElement.value;

        if (!isZeroLengthMathExpr(label)) {
            this.figure.insertModels(function* () {
                yield LabelPointModel.create({ x, y, label, style: null });
                yield PointModel.create({ x, y, style: null });
            });
        }
    });

    mapModels = createTransformer(createMapModelToElements());

    get models(): BaseModel[] {
        return this.figure.models.filter((model) => {
            if (checkModelType(model, ModelTypes.labelPoint)) {
                if (areSamePoints(model, this.point)) {
                    return false;
                }
            }
            return true;
        });
    }

    protected renderElements(): BaseElement[] {
        return [...this.models.flatMap(this.mapModels), this.tempElement];
    }

    get tempElement(): LabelDotElement {
        const { x, y } = this.point;

        return LabelDotElement.create({
            x,
            y,
            value: this.initialLabel ?? createSerializedContainer(),
            id: `temp__label__${this.id}`,
            isEditable: true,
            directionAngle: 0,
            rotationAngle: 0,
            notifyAction: this.notifyAction,
            overrideRenderOrder: 0,
        });
    }

    notifyAction = action((inputService: InputService, action: TAnyAction) => {
        if (isLinebreakAction(action)) {
            this.tempElement.value = inputService.getSerializedState();
            this.onSave();
            this.nextFlow();
        }
    });

    override getToolbarMenu(): ToolbarMenu {
        return createDefaultToolbarMenuBuilder()
            .setButtonIcon("label-dot", ["labels"])
            .setButtonState(ButtonStates.active, ["labels"])
            .build();
    }

    override getTooltipMenu(): TooltipMenu | null {
        return new TooltipMenu("Напишите название точки, затем нажмите Enter или в пустую область");
    }
}
