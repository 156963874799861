import { makeObservable, observable } from "mobx";

import { createSerializedContainer } from "@viuch/math-editor";

import type { IVariableContentVisitor } from "./IVariableContentVisitor";
import type { TSerializedState } from "@viuch/math-editor/types";

import { BaseVariableContent } from "./BaseVariableContent";

export interface IShuffleVariableContent {
    formula: TSerializedState;
    quantity_fractional_digits: number;
}

export class ShuffleVariableContent extends BaseVariableContent implements IShuffleVariableContent {
    public formula: TSerializedState;
    public quantity_fractional_digits: number;

    constructor(data: IShuffleVariableContent) {
        super();

        this.formula = data.formula;
        this.quantity_fractional_digits = data.quantity_fractional_digits;

        makeObservable(this, {
            formula: observable,
            quantity_fractional_digits: observable,
        });
    }

    accept<R>(visitor: IVariableContentVisitor<R>): R {
        return visitor.doWithShuffledNumber(this);
    }

    static createEmpty(): ShuffleVariableContent {
        return new ShuffleVariableContent({
            formula: createSerializedContainer(),
            quantity_fractional_digits: 0,
        });
    }

    clone(): ShuffleVariableContent {
        return new ShuffleVariableContent(this);
    }
}
