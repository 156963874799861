import { makeObservable, observable, when, flow } from "mobx";

import { createPromiseController } from "@viuch/shared/utils/runtime/promises";

import type { TRawAnyStatsTrackerAction, TRawAnyStatsTrackerEvent } from "./StatsTrackerService.types";
import type { AuthStore } from "../../store/AuthStore";
import type { TPromiseController } from "@viuch/shared/utils/runtime/promises";

import { WebsocketClient } from "../../agent/websocket";
import { getAccessToken } from "../auth/utils";

export class StatsTrackerService {
    @observable private shouldBeConnected = false;

    private client?: WebsocketClient<TRawAnyStatsTrackerAction, TRawAnyStatsTrackerEvent>;
    private userStore: AuthStore;

    constructor(userStore: AuthStore) {
        this.userStore = userStore;

        makeObservable(this);
    }

    @flow.bound
    async *connect() {
        const authToken = getAccessToken();
        if (!authToken) throw new Error("User was not authorized");

        this.client = new WebsocketClient("/");
        await this.client.connect(this.onData, this.onError, this.onClose);
        await this.authorize(authToken);
        yield;

        this.shouldBeConnected = true;
    }

    async startTrackingEditTheme(themeId: number) {
        await this.sendMessage({
            action_kind: "theme_update_start",
            theme_id: themeId,
        });
    }

    async endTrackingTheme() {
        await this.sendMessage({ action_kind: "theme_update_finish" });
    }

    async startTrackingNewTheme() {
        await this.sendMessage({ action_kind: "theme_create_start" });
    }

    async endTrackingNewTheme(newThemeId: number | null) {
        await this.sendMessage({
            action_kind: "theme_create_finish",
            theme_id: newThemeId,
        });
    }

    async cancelTrackingTheme() {
        await this.sendMessage({ action_kind: "theme_cancel" });
    }

    async startTrackingNewTask() {
        await this.sendMessage({ action_kind: "task_create_start" });
    }

    async endTrackingNewTask(newTaskId: number) {
        await this.sendMessage({ action_kind: "task_create_finish", task_id: newTaskId });
    }

    async startTrackingEditTask(taskId: number) {
        await this.sendMessage({ action_kind: "task_update_start", task_id: taskId });
    }

    async endTrackingEditTask(taskId: number) {
        await this.sendMessage({ action_kind: "task_update_finish", task_id: taskId });
    }

    @flow.bound
    private async *sendMessage(message: TRawAnyStatsTrackerAction) {
        if (!this.shouldBeConnected) {
            await when(() => this.shouldBeConnected);
            yield;
        }

        this.client?.sendMessage(message);
    }

    disconnect() {
        this.shouldBeConnected = false;
        this.client?.disconnect();
    }

    private authPromise?: TPromiseController<void>;

    private async authorize(authToken: string): Promise<void> {
        if (this.authPromise) throw new Error("Authorization (tracking) still in progress");

        try {
            this.authPromise = createPromiseController<void>();

            this.client?.sendMessage({
                auth_token: authToken,
            });

            await this.authPromise;
        } finally {
            this.authPromise = void 0;
        }
    }

    private onData = (e: TRawAnyStatsTrackerEvent) => {
        switch (e.event) {
            case "auth_success":
                this.authPromise?.resolve();
                break;
            case "error": {
                switch (e.error_kind) {
                    case "auth":
                        this.authPromise?.reject(new Error("Authorization failed"));
                        break;
                }
                console.error("Error tracking event", e);
                break;
            }
            case "action_created":
            case "action_finished":
                break;
            default:
                console.error("Unknown tracking event", e);
        }
    };

    private onError = () => {
        console.error("websocket tracking error");
    };

    private onClose = () => {
        if (this.shouldBeConnected) {
            // TODO: можно попытаться переподключиться
            this.connect();
        }
    };
}
