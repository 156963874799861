import cn from "classnames";
import { observer } from "mobx-react-lite";
import React, { useEffect, useRef, useState } from "react";

import { MathInput2, TextInput } from "@viuch/ui-common";
import { InstrumentFrame } from "@viuch/ui-kit/frames";
import { LoadingSpinner } from "@viuch/ui-kit/spinner";
import { useConst } from "@viuch/utils/hooks";

import type { IUploadImageService } from "./types";
import type { PictureInstrumentSettingsImpl } from "../settings/PictureInstrumentSettingsImpl";
import type { KeyboardService } from "@viuch/math-editor";

import { PictureInstrumentSettingsEditorVm } from "./PictureInstrumentSettingsEditor.vm";
import { convertCoordinatesToFloatPosition } from "./utils";
import { PictureWatermarkDot } from "./watermark-dot";
import { PictureWatermarkListItem } from "./watermark-list-item";

import styles from "./PictureInstrumentSettingsEditor.module.scss";

type Props = {
    picture: PictureInstrumentSettingsImpl;
    uploadImageService: IUploadImageService;
    keyboardService: KeyboardService;
    className?: string;
    frameClassName?: string;
};

export const PictureInstrumentSettingsEditor = observer(function PictureInstrumentSettingsEditor({
    picture,
    className,
    uploadImageService,
    keyboardService,
    frameClassName,
}: Props) {
    const vm = useConst(() => new PictureInstrumentSettingsEditorVm(picture, uploadImageService));

    const watermarksBlockRef = useRef<HTMLDivElement>(null);
    const imageRef = useRef<HTMLImageElement>(null);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            vm.downloadImage(file);
        }
    };

    const handleImageClick = (e: React.MouseEvent<HTMLDivElement>) => {
        const position = convertCoordinatesToFloatPosition(e.clientX, e.clientY, watermarksBlockRef);
        vm.createWatermark(position);
    };

    useEffect(() => {
        const image = imageRef.current!;
        const watermarksBlock = watermarksBlockRef.current!;

        const observer = new ResizeObserver(() => {
            watermarksBlock.style.setProperty("width", `${image.clientWidth}px`);
            watermarksBlock.style.setProperty("height", `${image.clientHeight}px`);
        });
        observer.observe(image);

        return () => {
            observer.disconnect();
        };
    }, []);

    const [recommendedWidthExceeded, setRecommendedWidthExceeded] = useState(false);
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const element = ref.current!;

        function handle() {
            const width = element.getBoundingClientRect().width;
            setRecommendedWidthExceeded(width > 360);
        }

        const observer = new ResizeObserver(handle);
        observer.observe(element);

        return () => {
            observer.disconnect();
        };
    }, []);

    return (
        <div className={cn(className, styles.wrapper)}>
            <div className={styles.body}>
                <div className={cn(styles.frameWrapper, frameClassName)}>
                    <InstrumentFrame
                        contentClassName={styles.frameContent}
                        className={styles.frame}
                    >
                        <div className={styles.imageWrapper}>
                            <img
                                src={picture.image?.url ?? ""}
                                alt=""
                                className={styles.image}
                                ref={imageRef}
                            />
                            <div
                                className={styles.watermarksBlock}
                                onClick={handleImageClick}
                                ref={watermarksBlockRef}
                            >
                                {picture.watermarks.map((watermark) => (
                                    <PictureWatermarkDot
                                        watermark={watermark}
                                        watermarksBlockRef={watermarksBlockRef}
                                        key={watermark.$$instanceId}
                                    />
                                ))}
                            </div>
                        </div>
                    </InstrumentFrame>
                </div>

                <div className={styles.content}>
                    <input
                        type="file"
                        accept="image/*"
                        onChange={handleInputChange}
                    />
                    <div ref={ref}>
                        <MathInput2
                            value={picture.title}
                            onChange={picture.setTitle}
                            title="Подпись"
                            keyboardService={keyboardService}
                            multiline
                            error={recommendedWidthExceeded ? `Превышена рекомендуемая ширина` : void 0}
                        />
                    </div>
                    <TextInput
                        value={picture.altText}
                        onChange={picture.setAltText}
                        title="Alt текст"
                    />
                    <ul className={styles.watermarksList}>
                        {picture.watermarks.map((watermark) => (
                            <PictureWatermarkListItem
                                watermark={watermark}
                                keyboard={keyboardService}
                                onRemove={vm.removeWatermark}
                                key={watermark.$$instanceId}
                            />
                        ))}
                    </ul>
                </div>
            </div>
            {vm.isLoading && <LoadingSpinner />}
        </div>
    );
});
