import type { ISerializedSpecialSymbol, TSymbolActionChar } from "./types";
import type { TSerializedElementPrototype } from "../../core/element";
import type { IElementVisitor } from "../../core/element/IElementVisitor";
import type { InputService } from "../../services";
import type { TElementDeserializerFunction } from "../../types";

import { BaseElementModel } from "../../core/element";

export class SpecialSymbolModel extends BaseElementModel<ISerializedSpecialSymbol> {
    public readonly symbolName: TSymbolActionChar;

    public constructor(inputService: InputService, symbolName: TSymbolActionChar, uuid?: string) {
        super(inputService, uuid);
        this.symbolName = symbolName;
    }

    public override isEquals(otherElement: BaseElementModel): boolean {
        return otherElement instanceof SpecialSymbolModel && this.symbolName === otherElement.symbolName;
    }

    public serialize(): ISerializedSpecialSymbol {
        return { type: "symbol", uuid: this.uuid, symbol: this.symbolName };
    }

    public static deserialize: TElementDeserializerFunction<ISerializedSpecialSymbol> = (
        { inputService },
        { uuid, symbol }
    ) => new SpecialSymbolModel(inputService, symbol, uuid);

    public accept<R>(visitor: IElementVisitor<R>): R {
        return visitor.doWithSymbol(this);
    }

    serializeAsClone(): TSerializedElementPrototype<ISerializedSpecialSymbol> {
        const { uuid, ...clone } = this.serialize();
        return clone;
    }
}
