import cn from "classnames";
import { observer } from "mobx-react-lite";
import React from "react";

import type { Graph2DToolbar } from "../../core/Graph2DToolbar";
import type { IToolbarElement } from "../toolbarTypes";
import type { RefObject } from "react";

import { AnyToolbarElement } from "../AnyToolbarElement";

import styles from "./ToolbarMenu.module.scss";

type Props = {
    element: IMenuToolbarElement;
    toolbar: Graph2DToolbar;
    className?: string;
    rootRef: RefObject<HTMLDivElement>;
};

export const ToolbarMenu = observer(function ToolbarMenu({ element, toolbar, className, rootRef }: Props) {
    const { buttons } = element;

    return (
        <div className={cn(styles.container, className)}>
            {buttons.map((element, i) => (
                <AnyToolbarElement
                    key={element.key ?? i}
                    toolbar={toolbar}
                    element={element}
                    rootRef={rootRef}
                    className={styles.item}
                />
            ))}
        </div>
    );
});

export interface IMenuToolbarElement extends IToolbarElement {
    type: "menu";
    buttons: IToolbarElement[];
}
