import { useCallback, useEffect, useRef } from "react";

import { isDefined } from "@viuch/shared/utils/data/isDefined";

export const useTimeout = () => {
    const timeoutId = useRef<number>();

    const clearTimeout = useCallback(() => {
        if (timeoutId.current) {
            window.clearTimeout(timeoutId.current);
            timeoutId.current = undefined;
        }
    }, []);

    const initTimeout = useCallback(
        (cb: VoidFunction, ms: number) => {
            if (isDefined(timeoutId.current)) {
                window.clearTimeout(timeoutId.current);
            }

            timeoutId.current = window.setTimeout(() => {
                cb();
                clearTimeout();
            }, ms);
        },
        [clearTimeout]
    );

    useEffect(() => {
        return () => clearTimeout();
    }, [clearTimeout]);

    return { initTimeout, clearTimeout };
};
