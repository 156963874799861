export function renderDaysRuWord(count: number) {
    count = Math.abs(count);
    if (count <= 1) {
        return "день";
    }
    if (count <= 4) {
        return "дня";
    }
    return "дней";
}
