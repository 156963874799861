import cn from "classnames";
import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo, useRef } from "react";

import { ZoomButtons } from "@viuch/ui-kit/components/zoom-buttons/ZoomButtons";

import type { Graph2DModel } from "../core/Graph2DModel";
import type { Graph2DView } from "../core/Graph2DView";
import type { TZoomButtonsExtraButton } from "@viuch/ui-kit/components/zoom-buttons/ZoomButtons";

import { InstrumentGraph2DEditorStore } from "./InstrumentGraph2DEditor.store";
import { GraphElements } from "./rendering/GraphElements";
import { GraphToolbar } from "./toolbar/GraphToolbar";
import { GraphViewport } from "./viewport/GraphViewport";

import styles from "./InstrumentGraph2DEditor.module.scss";

type Props = {
    model: Graph2DModel;
    view: Graph2DView;
    extraButtons?: TZoomButtonsExtraButton[];
    className?: string;
    isStatic?: boolean;
};

export const InstrumentGraph2DEditor = observer(function InstrumentGraph2DEditor({
    model,
    view,
    isStatic,
    extraButtons,
    className,
}: Props) {
    const ref = useRef<HTMLDivElement>(null);

    const { effect } = useMemo(() => new InstrumentGraph2DEditorStore(model, view, ref), [model, view]);

    useEffect(effect, [effect]);

    const { viewport, events, renderer, flows, preRenderer, settings, toolbar } = view;

    return (
        <div
            className={cn(styles.root, className)}
            ref={ref}
        >
            <GraphViewport
                viewport={viewport}
                settings={settings}
                events={events}
                isStatic={isStatic ?? false}
            >
                <GraphElements
                    renderer={renderer}
                    preRenderer={preRenderer}
                    flows={flows}
                />
            </GraphViewport>
            {!isStatic && (
                <ZoomButtons
                    onPlus={() => viewport.multiplyScale(1.2)}
                    onMinus={() => viewport.multiplyScale(0.8)}
                    extra={extraButtons}
                />
            )}
            <GraphToolbar toolbar={toolbar} />
        </div>
    );
});
