import type { Graph2DModel } from "../../core/Graph2DModel";
import type { Graph2DPreRenderingService } from "../core/Graph2DPreRenderingService";
import type { Graph2DViewportController } from "../core/Graph2DViewportController";
import type { BaseGraphViewItem } from "../rendering/BaseGraphViewItem";
import type { IToolbarElement } from "../toolbar/toolbarTypes";
import type { TPoint } from "@viuch/geometry-lib/types";
import type { MouseEvent, KeyboardEvent, PointerEvent } from "react";

export abstract class BaseGraphFlow {
    protected readonly viewport: Graph2DViewportController;
    protected readonly model: Graph2DModel;
    protected readonly renderTransformer: Graph2DPreRenderingService;

    constructor(
        model: Graph2DModel,
        viewport: Graph2DViewportController,
        renderTransformer: Graph2DPreRenderingService
    ) {
        this.model = model;
        this.viewport = viewport;
        this.renderTransformer = renderTransformer;
    }

    abstract handleViewportPointerEvent?(e: PointerEvent, canvasPoint: TPoint): void;

    abstract handleElementPointerEvent?(e: PointerEvent, canvasPoint: TPoint, element: BaseGraphViewItem): void;

    abstract handleElementClickEvent?(e: MouseEvent, element: BaseGraphViewItem): void;

    abstract handleKeyEvent?(e: KeyboardEvent): void;

    abstract getViewItems(): readonly BaseGraphViewItem[];

    abstract getToolbarPreset(): string | null;

    abstract getToolbarElementUpdates(element: IToolbarElement): IToolbarElement;
}
