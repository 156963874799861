import type { TSymbolActionChar } from "../elements/symbol/types";
import type { TAnyAction } from "../types";

export type TSymbolAction = {
    type: "special-symbol";
    char: TSymbolActionChar;
};

export function createSymbolAction(char: TSymbolActionChar): TSymbolAction {
    return { type: "special-symbol", char };
}

export function isSymbolAction(action: TAnyAction): action is TSymbolAction {
    return action.type === "special-symbol";
}
