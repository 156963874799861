import type { TAngle, TLine, TPoint } from "./types";

import { areSamePoints } from "./check-geometry";
import { copyAngle, copyPoint, createLine, lineToFragment } from "./factories";

export function swapAngle(angle: TAngle): TAngle {
    const { vertex, start, end } = angle;
    return {
        vertex: copyPoint(vertex),
        start: copyPoint(end),
        end: copyPoint(start),
    };
}

export function normalizeAngle(angleRad: number, lessThan180?: boolean): number;
export function normalizeAngle(angle: TAngle, lessThan180?: boolean): TAngle;
export function normalizeAngle(angle: number | TAngle, lessThan180?: boolean): number | TAngle {
    if (typeof angle === "number") {
        return (angle + Math.PI * 4) % (Math.PI * (lessThan180 ? 1 : 2));
    }

    return lessThan180 && getAngle(angle) > Math.PI ? swapAngle(angle) : copyAngle(angle);
}

export function radToDeg(rad: number): number {
    const deg = (rad / Math.PI) * 180;
    return (deg + 360) % 360;
}

export function degToRad(deg: number): number {
    const rad = (deg / 180) * Math.PI;
    return (rad + Math.PI * 2) % (Math.PI * 2);
}

export function getUnitVectorFromAngle(angle: number): TPoint {
    return {
        x: Math.cos(angle),
        y: Math.sin(angle),
    };
}

export function getAngle(angle: TAngle): number {
    const { startAngle, endAngle } = getAngles(angle);

    return normalizeAngle(endAngle - startAngle);
}

export function getLineAngle({ x1, y1, x2, y2 }: TLine, clockwise = false): number {
    const x = x2 - x1;
    const y = y2 - y1;

    const sign = clockwise ? 1 : -1;
    return normalizeAngle(sign * Math.atan2(y, x));
}

export function getAngles(angle: TAngle): Record<"startAngle" | "endAngle", number> {
    const { start, vertex, end } = angle;

    const startAngle = getLineAngle(createLine(vertex, start), true);
    const endAngle = getLineAngle(createLine(vertex, end), true);

    return { startAngle, endAngle };
}

export function getMiddleAngle(leftAngle: number, rightAngle: number): number {
    const inc = leftAngle > rightAngle ? Math.PI * 2 : 0;
    return normalizeAngle((leftAngle + rightAngle + inc) / 2);
}

/**
 * Если линии имеют общую точку, возвращает оставшиеся точки, необходимые для формирования угла из этих линий.
 */
export function getAnglePoints(line1: TLine, line2: TLine): { vertex: TPoint; rest: [TPoint, TPoint] } | null {
    const { a: a1, b: b1 } = lineToFragment(line1);
    const { a: a2, b: b2 } = lineToFragment(line2);

    if (areSamePoints(a1, a2)) {
        return { vertex: a1, rest: [b1, b2] };
    }
    if (areSamePoints(a1, b2)) {
        return { vertex: a1, rest: [b1, a2] };
    }
    if (areSamePoints(b1, a2)) {
        return { vertex: b1, rest: [a1, b2] };
    }
    if (areSamePoints(b1, b2)) {
        return { vertex: b1, rest: [a1, a2] };
    }
    return null;
}
