import cn from "classnames";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";

import { Graph2DInstrumentSettingsEditor } from "@viuch/instrument-graph2d-settings/settings-editor/Graph2DInstrumentSettingsEditor";
import { CheckboxInput } from "@viuch/ui-common";

import type { Graph2DEditor, IComplexEditorGraph2DHost } from "../../../editors/instruments/Graph2DEditor";
import type { KeyboardService } from "@viuch/math-editor";

import styles from "./Graph2DInstrumentEditor.module.scss";

type Props = {
    editor: Graph2DEditor;
    keyboardService: KeyboardService;
    host: IComplexEditorGraph2DHost;
};

export const Graph2DInstrumentEditor = observer(function Graph2DInstrumentEditor({
    editor,
    keyboardService,
    host,
}: Props) {
    const { setHost, instrumentSettings, interactiveView, setInteractiveView } = editor;

    useEffect(() => {
        setHost(host);
    }, [host, setHost]);

    return (
        <div className={cn(styles.root)}>
            <Graph2DInstrumentSettingsEditor
                graphInstrumentSettings={instrumentSettings}
                keyboardService={keyboardService}
                host={host}
            >
                <CheckboxInput
                    noSpace
                    labelAfter="Зум и перемещение"
                    type="checkbox"
                    checked={interactiveView}
                    onChange={setInteractiveView}
                />
            </Graph2DInstrumentSettingsEditor>
        </div>
    );
});
