import type { BaseFormNode } from "../nodes/BaseFormNode";
import type { TCheckboxControl } from "../types/controls";

import { CheckboxFormNode } from "../nodes/CheckboxFormNode";
import { pathFromRaw } from "../utils/paths";

export function instantiateCheckboxControl(data: TCheckboxControl, parent: BaseFormNode | null): CheckboxFormNode {
    const { label, path, virtual } = data;

    return new CheckboxFormNode(label, !!virtual, parent, pathFromRaw(path), []);
}
