import type { TMathChar } from "./blocks/BaseKeyboardBlock/BaseKeyboardBlock.types";

import { createAffiliationAction } from "../../../actions/affiliation";
import { createBracketAction } from "../../../actions/bracket";
import { createComplexIntegralAction } from "../../../actions/complex-integral";
import { createCoordinatesVectorAction } from "../../../actions/coordinates-vector";
import { createDegreeAction } from "../../../actions/degree";
import { createDerivativeAction } from "../../../actions/derivative";
import { createDifferentialAction } from "../../../actions/differential";
import { createDigitAction } from "../../../actions/digit";
import { createDownIndexAction } from "../../../actions/down-index";
import { createEqualityAction } from "../../../actions/equality";
import { createEquationsSetAction } from "../../../actions/equations-set";
import { createFractionAction } from "../../../actions/fraction";
import { createFunctionAction } from "../../../actions/function";
import { createGeometryCharAction } from "../../../actions/geometry";
import { createGradeAction } from "../../../actions/grade";
import { createIntegralAction } from "../../../actions/integral";
import { createLimAction } from "../../../actions/lim";
import { createLogAction } from "../../../actions/log";
import { createModuleAction } from "../../../actions/module";
import { createMultitudeAction } from "../../../actions/multitude";
import { createNaturalLogAction } from "../../../actions/natural-log";
import { createHighPriorityAction } from "../../../actions/operator-high";
import { createLowPriorityOperatorAction } from "../../../actions/operator-low";
import { createResultAction } from "../../../actions/result";
import { createRootAction } from "../../../actions/root";
import { createSlashAction } from "../../../actions/slash";
import { createSymbolAction } from "../../../actions/symbol";
import { createTopAngleAction } from "../../../actions/top-angle";
import { createTrigonometricOperatorAction } from "../../../actions/trigonometric-operator";
import { createVectorAction } from "../../../actions/vector";
import { __miscTag } from "../../../core/keyboard-button-tags/constants";
import { createAngleAction } from "../../../elements/angle/utils";

export const notImplementedAction = { type: "" };

export const digitsMathChars: TMathChar[] = [
    {
        actionCreator: () => createDigitAction(7),
        char: "7",
        tags: ["basic", "numbers"],
    },
    {
        actionCreator: () => createDigitAction(8),
        char: "8",
        tags: ["basic", "numbers"],
    },
    {
        actionCreator: () => createDigitAction(9),
        char: "9",
        tags: ["basic", "numbers"],
    },
    {
        actionCreator: () => createHighPriorityAction("divide"),
        icon: "divide",
        tags: ["basic", "operations", "divide"],
    },
    {
        actionCreator: () => createDigitAction(4),
        char: "4",
        tags: ["basic", "numbers"],
    },
    {
        actionCreator: () => createDigitAction(5),
        char: "5",
        tags: ["basic", "numbers"],
    },
    {
        actionCreator: () => createDigitAction(6),
        char: "6",
        tags: ["basic", "numbers"],
    },
    {
        actionCreator: () => createHighPriorityAction("multiply"),
        icon: "multiply",
        tags: ["basic", "operations", "multiply"],
    },
    {
        actionCreator: () => createDigitAction(1),
        char: "1",
        tags: ["basic", "numbers"],
    },
    {
        actionCreator: () => createDigitAction(2),
        char: "2",
        tags: ["basic", "numbers"],
    },
    {
        actionCreator: () => createDigitAction(3),
        char: "3",
        tags: ["basic", "numbers"],
    },
    {
        actionCreator: () => createLowPriorityOperatorAction("minus"),
        char: "-",
        tags: ["basic", "operations", "subtraction"],
    },
    {
        actionCreator: () => createDigitAction(0),
        char: "0",
        tags: ["basic", "numbers"],
    },
    {
        actionCreator: () => createSymbolAction("comma"),
        char: ",",
        tags: ["basic", "symbols", "comma"],
    },
    {
        actionCreator: () => createEqualityAction("equals"),
        char: "=",
        tags: ["basic", "equals"],
    },
    {
        actionCreator: () => createLowPriorityOperatorAction("plus"),
        char: "+",
        tags: ["basic", "operations", "addition"],
    },
];

export const columnCalculationsMathChars: TMathChar[] = [
    {
        actionCreator: () => notImplementedAction,
        icon: "long-division",
        tags: ["long-division", "column-calculations"],
    },
    {
        actionCreator: () => notImplementedAction,
        icon: "column-addition",
        tags: ["column-addition", "column-calculations"],
    },
    {
        actionCreator: () => notImplementedAction,
        icon: "column-subtraction",
        tags: ["column-subtraction", "column-calculations"],
    },
    {
        actionCreator: () => notImplementedAction,
        icon: "column-multiplication",
        tags: ["column-multiplication", "column-calculations"],
    },
];

export const bracketsMathChars: TMathChar[] = [
    {
        actionCreator: () => createBracketAction("round"),
        char: "(",
        tags: ["brackets"],
    },
    {
        actionCreator: () => createBracketAction("round", { closing: true }),
        char: ")",
        tags: ["brackets"],
    },
    {
        actionCreator: () => createBracketAction("square"),
        char: "[",
        tags: ["brackets"],
    },
    {
        actionCreator: () => createBracketAction("square", { closing: true }),
        char: "]",
        tags: ["brackets"],
    },
    {
        actionCreator: () => createBracketAction("curly"),
        char: "{",
        tags: ["brackets"],
    },
    {
        actionCreator: () => createBracketAction("curly", { closing: true }),
        char: "}",
        tags: ["brackets"],
    },
    {
        actionCreator: () => createCoordinatesVectorAction(),
        icon: "vector-coordinates",
        tags: [__miscTag],
    },
    {
        actionCreator: () => createSymbolAction("semicolon"),
        char: ";",
        tags: ["symbols", "semicolon"],
    },
];

export const equalsMathChars: TMathChar[] = [
    {
        actionCreator: () => createEqualityAction("less"),
        char: "<",
        tags: ["compare"],
    },
    {
        actionCreator: () => createEqualityAction("greater"),
        char: ">",
        tags: ["compare"],
    },
    {
        actionCreator: () => createEqualityAction("less-equals"),
        char: "≤",
        tags: ["compare"],
    },
    {
        actionCreator: () => createEqualityAction("greater-equals"),
        char: "≥",
        tags: ["compare"],
    },
];

export const fractionsMathChars: TMathChar[] = [
    {
        actionCreator: () => createDegreeAction(2),
        icon: "degree2",
        tags: ["power"],
    },
    {
        actionCreator: () => createDegreeAction(),
        icon: "degree",
        tags: ["power"],
    },
    {
        actionCreator: () => createRootAction({ squared: true }),
        icon: "sqrt",
        tags: ["power"],
    },
    {
        actionCreator: () => createRootAction(),
        icon: "sqrt-index",
        tags: ["power"],
    },
    {
        actionCreator: () => createFractionAction(),
        icon: "frac",
        tags: [__miscTag],
    },
    {
        actionCreator: () => createDerivativeAction(),
        icon: "derivative",
        tags: ["derivative"],
    },
    {
        actionCreator: () => createModuleAction(),
        icon: "module",
        tags: [__miscTag],
    },
    {
        actionCreator: () => createSymbolAction("factorial"),
        icon: "factorial",
        tags: [__miscTag],
    },
];

export const differentialsMathChars: TMathChar[] = [
    {
        actionCreator: () => createDifferentialAction("x"),
        icon: "differential-x",
        tags: ["derivative"],
    },
    {
        actionCreator: () => createIntegralAction("x"),
        icon: "integral-x",
        tags: ["integrals"],
    },
    {
        actionCreator: () => createNaturalLogAction(),
        icon: "ln",
        tags: ["logarithms"],
    },
    {
        actionCreator: () => createDifferentialAction(),
        icon: "differential",
        tags: ["derivative"],
    },
    {
        actionCreator: () => createIntegralAction(),
        icon: "integral",
        tags: ["integrals"],
    },
    {
        actionCreator: () => createLogAction(),
        icon: "log",
        tags: ["logarithms"],
    },
    {
        actionCreator: () => createLimAction(),
        icon: "lim",
        tags: ["limits"],
    },
    {
        actionCreator: () => createComplexIntegralAction(),
        icon: "complex-integral",
        tags: ["integrals"],
    },
    {
        actionCreator: () => createLogAction(2),
        icon: "log2",
        tags: ["logarithms"],
    },
    {
        actionCreator: () => createFunctionAction(),
        icon: "func",
        tags: ["functions"],
    },
    {
        actionCreator: () => createFunctionAction({ filled: true }),
        icon: "funcX",
        tags: ["functions"],
    },
    {
        actionCreator: () => createLogAction(10),
        icon: "log10",
        tags: ["logarithms"],
    },
];

export const sinsMathChars: TMathChar[] = [
    {
        actionCreator: () => createTrigonometricOperatorAction("sin"),
        icon: "sin",
        tags: ["trigonometric", "sin"],
    },
    {
        actionCreator: () => createTrigonometricOperatorAction("arcsin"),
        icon: "arcsin",
        tags: ["trigonometric", "arcsin"],
    },
    {
        actionCreator: () => createTrigonometricOperatorAction("cos"),
        icon: "cos",
        tags: ["trigonometric", "cos"],
    },
    {
        actionCreator: () => createTrigonometricOperatorAction("arccos"),
        icon: "arccos",
        tags: ["trigonometric", "arccos"],
    },
    {
        actionCreator: () => createTrigonometricOperatorAction("tg"),
        icon: "tg",
        tags: ["trigonometric", "tg"],
    },
    {
        actionCreator: () => createTrigonometricOperatorAction("arctg"),
        icon: "arctg",
        tags: ["trigonometric", "arctg"],
    },
    {
        actionCreator: () => createTrigonometricOperatorAction("ctg"),
        icon: "ctg",
        tags: ["trigonometric", "ctg"],
    },
    {
        actionCreator: () => createTrigonometricOperatorAction("arcctg"),
        icon: "arcctg",
        tags: ["trigonometric", "arcctg"],
    },
];

export const geometryMathChars: TMathChar[] = [
    {
        actionCreator: () => createVectorAction(),
        icon: "vector",
        tags: ["geometry"],
    },
    {
        actionCreator: () => createGeometryCharAction("parallel"),
        icon: "parallel",
        tags: ["geometry"],
    },
    {
        actionCreator: () => createMultitudeAction("crossing"),
        icon: "intersection",
        tags: ["sets"],
    },
    //
    {
        actionCreator: () => createSymbolAction("delta"),
        icon: "delta",
        tags: [__miscTag],
    },
    {
        actionCreator: () => createGeometryCharAction("perpendicular"),
        icon: "perpendicular",
        tags: ["geometry"],
    },
    {
        actionCreator: () => createMultitudeAction("union"),
        icon: "union-set",
        tags: ["sets"],
    },
    //
    {
        actionCreator: () => createSymbolAction("arc"),
        tags: ["geometry"],
        icon: "semicircle",
    },
    {
        actionCreator: () => createSymbolAction("collinear"),
        tags: ["geometry"],
        icon: "collinear",
    },
    {
        actionCreator: () => createSymbolAction("counter-collinear"),
        tags: ["geometry"],
        icon: "counter-collinear",
    },
    //
    {
        actionCreator: () => createTopAngleAction(),
        icon: "top-angle",
        tags: ["geometry"],
    },
    {
        actionCreator: () => createAngleAction(),
        icon: "angle",
        tags: ["geometry"],
    },
    {
        actionCreator: () => createGradeAction(),
        icon: "num-degree",
        tags: ["geometry"],
    },
];

export const geometrySymbols: TMathChar[] = [
    {
        actionCreator: () => createGeometryCharAction("square"),
        icon: "square",
        tags: ["geometry"],
    },
    {
        actionCreator: () => createGeometryCharAction("trapeze"),
        icon: "trapeze",
        tags: ["geometry"],
    },
    {
        actionCreator: () => createGeometryCharAction("rect"),
        icon: "rect",
        tags: ["geometry"],
    },
    {
        actionCreator: () => createGeometryCharAction("circle"),
        icon: "circle",
        tags: ["geometry"],
    },
    {
        actionCreator: () => createGeometryCharAction("parallelogram"),
        icon: "parallelogram",
        tags: ["geometry"],
    },
    {
        actionCreator: () => createGeometryCharAction("rhomb"),
        icon: "rhomb",
        tags: ["geometry"],
    },
    {
        actionCreator: () => createGeometryCharAction("triangle"),
        icon: "triangle",
        tags: ["geometry"],
    },
];

export const specialSymbolsMathChars: TMathChar[] = [
    {
        actionCreator: () => createAffiliationAction("affiliation"),
        icon: "belong",
        tags: [__miscTag],
    },
    {
        actionCreator: () => createDownIndexAction(),
        icon: "num-index",
        tags: [__miscTag],
    },
    {
        actionCreator: () => createEquationsSetAction("and"),
        icon: "input-curly-bracket",
        tags: [__miscTag],
    },
    {
        actionCreator: () => createSymbolAction("pi"),
        icon: "pi",
        tags: [__miscTag],
    },
    //
    {
        actionCreator: () => createAffiliationAction("not-affiliation"),
        icon: "notBelong",
        tags: [__miscTag],
    },
    {
        actionCreator: () => createSlashAction(),
        icon: "divide-nums",
        tags: [__miscTag],
    },
    {
        actionCreator: () => createEquationsSetAction("or"),
        icon: "input-round-bracket",
        tags: [__miscTag],
    },
    {
        actionCreator: () => createSymbolAction("exhibitor"),
        icon: "exponent",
        tags: [__miscTag],
    },
    //
    {
        actionCreator: () => createSymbolAction("tilda"),
        char: "~",
        tags: [__miscTag],
    },
    {
        actionCreator: () => createLowPriorityOperatorAction("plus-minus"),
        char: "±",
        tags: [__miscTag],
    },
    {
        actionCreator: () => createResultAction(),
        icon: "result",
        tags: [__miscTag],
    },
    {
        actionCreator: () => createSymbolAction("infinity"),
        icon: "infinity",
        tags: [__miscTag],
    },
    //
    {
        actionCreator: () => createEqualityAction("approx-equals"),
        char: "≈",
        tags: ["compare"],
    },
    {
        actionCreator: () => createEqualityAction("not-equals"),
        icon: "not-equals",
        tags: ["compare"],
    },
    {
        actionCreator: () => createSymbolAction("empty-set"),
        icon: "empty",
        tags: [__miscTag],
    },
    {
        actionCreator: () => createSymbolAction("percent"),
        icon: "percent",
        tags: [__miscTag],
    },
];
