import { autorun } from "mobx";
import { observer } from "mobx-react-lite";
import React from "react";

import { doNothing } from "@viuch/utils/debug";

import type { BracketModel } from "./BracketModel";
import type { TElementProps } from "../../types/element";

import {
    createDefaultRoundBracketConfig,
    CurlyBracket,
    defaultCurlyBracketConfig,
    defaultSquareBracketConfig,
    RoundBracket,
} from "../../core/dynamic-brackets";
import { Baseline } from "../../core/element";
import { useMathEditorElementRef } from "../../hooks";

import { applySafariFix } from "./BracketElement.constants";

import styles from "./BracketElement.module.scss";

export const BracketElement: React.VFC<TElementProps<BracketModel>> = observer(function BracketElement({
    elementModel,
}) {
    const ref = useMathEditorElementRef<HTMLDivElement>(elementModel);
    const baselineRef = React.useRef<HTMLSpanElement>(null);
    const roundBracketRef = React.useRef<RoundBracket>(null);
    const curlyBracketRef = React.useRef<CurlyBracket>(null);

    const isClosing = elementModel.closing;

    React.useEffect(() => {
        const baselineElement = baselineRef.current!;

        function update(height?: number) {
            if (elementModel.type === "round") {
                const bracket = roundBracketRef.current;
                if (!bracket) {
                    return;
                }

                height
                    ? bracket.updateBracketParams(createDefaultRoundBracketConfig(height))
                    : bracket.useFontBracket();
            } else {
                const bracket = curlyBracketRef.current;
                if (!bracket) {
                    return;
                }

                height
                    ? bracket.updateBracketParams(
                          elementModel.type === "square"
                              ? defaultSquareBracketConfig(height)
                              : defaultCurlyBracketConfig(height)
                      )
                    : bracket.useFontBracket();
            }
        }

        function autorunEffect() {
            const pairs = elementModel.parentContainer.pairBrackets.modelsBetweenBrackets;

            const baselineRect = baselineElement.getBoundingClientRect();
            const baselineHeight = baselineRect.height;
            const baselineTop = applySafariFix ? Number(baselineRect.y.toFixed(0)) : baselineRect.y;
            const baselineBottom = baselineTop + baselineHeight;

            const bracketType = elementModel.closing ? "closeBracket" : "openBracket";

            const pair = pairs.find((pair) => pair[bracketType] === elementModel);

            if (!pair) {
                update(undefined);
                return;
            }

            doNothing(pair.elementsBetween.map((element) => element.size));

            const domRects = pair.elementsBetween
                .map((model) => {
                    return model.getRect()!;
                })
                .filter(Boolean);

            const heights: number[] = domRects.map(({ y, h }) => {
                const top = y;
                const bottom = y + h;

                const topOffset = Math.abs(baselineTop - top);
                const bottomOffset = Math.abs(bottom - baselineBottom);

                return baselineHeight + Math.max(topOffset, bottomOffset) * 2;
            });

            if (!heights.length) {
                update(undefined);
                return;
            }

            const maxHeight = Math.max(...heights);

            update(maxHeight);
        }

        const disposeAutorun = autorun(autorunEffect, { delay: 50 });
        return () => {
            disposeAutorun();
        };
    }, [elementModel]);

    return (
        <div
            ref={ref}
            className={styles.wrapper}
        >
            <Baseline ref={baselineRef} />
            {elementModel.type === "round" ? (
                <RoundBracket
                    ref={roundBracketRef}
                    closing={isClosing}
                />
            ) : (
                <CurlyBracket
                    ref={curlyBracketRef}
                    closing={isClosing}
                    bracketType={elementModel.type}
                />
            )}
        </div>
    );
});
