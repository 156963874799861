import type { ILimit } from "./ILimit";
import type { ContainerModel } from "../container";
import type { BaseElementModel } from "../element";

import { LinebreakFinder } from "../cursor/LinebreakFinder";

export abstract class BaseMaxNestingLimit implements ILimit {
    private readonly maxNesting: number;

    public constructor(maxNesting: number) {
        this.maxNesting = maxNesting;
    }

    protected finder = LinebreakFinder.Create();

    filterInsertElements(
        elements: BaseElementModel[],
        containerToInsert: ContainerModel,
        indexToInsert: number
    ): BaseElementModel[] {
        let sourceMatched = false;
        for (const element of elements) {
            if (this.elementMatch(element)) {
                sourceMatched = true;
                break;
            }
        }
        if (!sourceMatched) {
            return elements;
        }

        let currentParentElement = containerToInsert.parentElement;

        let matches = 1;
        while (!this.finder.isEditor(currentParentElement)) {
            if (this.elementMatch(currentParentElement)) {
                matches++;

                if (matches > this.maxNesting) {
                    return [];
                }
            }
            currentParentElement = currentParentElement.parentContainer.parentElement;
        }

        return elements;
    }

    protected abstract elementMatch(element: BaseElementModel): boolean;
}
