import type { TFormControl } from "@viuch/form-builder/types/controls";

import { createLabel } from "../controls/label";

export const commonDenominator: TFormControl = {
    control: "Form",
    layout: "column",
    condition: {
        "./solver_type": ["common_denominator"],
    },
    controls: [
        createLabel("Приводит значения к общему знаменателю"), //
        {
            control: "MathArray",
            label: "Значения",
            path: "./values",
        },
    ],
};
