import type { TFormControl } from "@viuch/form-builder/types/controls";

import { createLabel } from "../controls/label";

export const rewriteWithConditions: TFormControl = {
    control: "Form",
    layout: "column",
    condition: {
        "./solver_type": ["rewrite_with_conditions"],
    },
    controls: [
        createLabel("Находит значения выражения если известны значения"),
        {
            path: "./expr",
            control: "Math",
            label: "Выражение",
        },
        {
            path: "./conditions",
            control: "MathArray",
            label: "Условия (В формате x*y = 3)",
        },
    ],
};
