import { action, computed, makeObservable, observable } from "mobx";

import type { Graph2DViewportController } from "./Graph2DViewportController";
import type { Graph2DModel } from "../../core/Graph2DModel";
import type { TGraph2DSnapshot } from "../../types/history-snapshots";

export class Graph2DHistory {
    private readonly model: Graph2DModel;
    private readonly viewport: Graph2DViewportController;

    @observable.shallow private currentStack: TGraph2DSnapshot[];
    @observable.shallow private nextStack: TGraph2DSnapshot[];

    constructor(model: Graph2DModel, viewport: Graph2DViewportController) {
        this.viewport = viewport;
        this.model = model;
        this.currentStack = [];
        this.nextStack = [];

        makeObservable(this);
    }

    @computed get canUndo(): boolean {
        return this.currentStack.length >= 2;
    }

    @computed get canRedo(): boolean {
        return this.nextStack.length > 0;
    }

    @action.bound
    commit() {
        const snapshot = this.make();
        this.push(snapshot);
    }

    @action.bound
    undo() {
        if (!this.canUndo) return;

        const current = this.currentStack.pop()!;
        const prev = this.currentStack.at(-1)!;

        this.nextStack.push(current);
        this.apply(prev);
    }

    @action.bound
    redo() {
        if (!this.canRedo) return;

        const next = this.nextStack.pop()!;

        this.currentStack.push(next);
        this.apply(next);
    }

    @action.bound
    init() {
        this.currentStack = [];
        this.nextStack = [];

        this.commit();
    }

    private make(): TGraph2DSnapshot {
        const { model, viewport } = this;

        return {
            graphsStore: model.dataItems.clone(),
            viewport: {
                scale: viewport.scale,
                x: viewport.position.x,
                y: viewport.position.y,
            },
        };
    }

    private push(snapshot: TGraph2DSnapshot) {
        this.nextStack = [];
        this.currentStack.push(snapshot);
    }

    private apply(snapshot: TGraph2DSnapshot): void {
        const storedItems = snapshot.graphsStore.clone().getAll();

        this.model.dataItems.clear();
        this.model.dataItems.addSome(storedItems);
    }
}
