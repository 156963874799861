import type { TRawStepDescription, TRawStepDescriptionTable } from "./types";
import type { BaseTemplate } from "../../entities/solver-step-templates";
import type { IDescriptionTemplateVisitor } from "../../entities/solver-step-templates/BaseTemplate";
import type { TSerializedState } from "@viuch/math-editor";

import { SolverTemplate, StepTemplate } from "../../entities/solver-step-templates";
import { TemplatesList } from "../../entities/solver-step-templates/BaseTemplate";
import { StepDescriptionTable } from "../../entities/solver-step-templates/StepDescriptionTable";

import { SolverStepTypes } from "./types";

export const mapSolverStepTemplate = (data: TRawStepDescription): BaseTemplate => {
    switch (data.step_type) {
        case SolverStepTypes.solver:
            return SolverTemplate.create({
                id: data.id,
                name: data.name,
                templates: mapSolverTemplates(data.template),
                isShallow: Boolean(data.is_shallow),
            });
        case SolverStepTypes.step:
            return StepTemplate.create({
                id: data.id,
                name: data.name,
                templates: mapSolverTemplates(data.template),
            });
    }
};

export const mapSolverTemplates = (data: Record<string, TSerializedState[]>): Map<string, TemplatesList> => {
    return new Map(Object.entries(data).map(([key, templates]) => [key, new TemplatesList(templates)]));
};

function serializeSolverTemplates(templates: Map<string, TemplatesList>): Record<string, TSerializedState[]> {
    return Object.fromEntries([...templates.entries()].map(([key, data]) => [key, data.templates]));
}

const serializeDescriptionTemplateVisitor: IDescriptionTemplateVisitor<TRawStepDescription> = {
    withStep: (template) => ({
        id: template.id,
        step_type: SolverStepTypes.step,
        name: template.name,
        template: serializeSolverTemplates(template.templates),
    }),
    withSolver: (template) => ({
        id: template.id,
        step_type: SolverStepTypes.solver,
        name: template.name,
        template: serializeSolverTemplates(template.templates),
        is_shallow: template.isShallow,
    }),
};

export const serializeStepDescription = (template: BaseTemplate): TRawStepDescription =>
    template.accept(serializeDescriptionTemplateVisitor);

export function mapStepDescriptionTable(data: TRawStepDescriptionTable): StepDescriptionTable {
    const { id, name } = data;

    return new StepDescriptionTable(id, name);
}

export function serializeStepDescriptionTable(table: StepDescriptionTable): TRawStepDescriptionTable {
    const { id, name } = table;

    return { id, name };
}
