import type { TAnyAction } from "../types";

export type TComplexIntegralAction = { type: "complex-integral" };

export function createComplexIntegralAction(): TComplexIntegralAction {
    return { type: "complex-integral" };
}

export function isComplexIntegralAction(action: TAnyAction): action is TComplexIntegralAction {
    return action.type === "complex-integral";
}
