import type { BaseTaskInstrumentSettings, IInstrumentSettingsVisitor } from "./BaseTaskInstrumentSettings";

import {
    Figure2DWithCompletionsTaskInstrumentSettings,
    Graph1DTaskInstrumentSettings,
    Graph2DTaskInstrumentSettings,
    IntervalsTaskInstrumentSettings,
    MultichoiceTaskInstrumentSettings,
    PictureTaskInstrumentSettings,
    TableSelectTaskInstrumentSettings,
    TableTextTaskInstrumentSettings,
} from "./BaseTaskInstrumentSettings";

export function cloneTaskInstrumentSettings(settings: BaseTaskInstrumentSettings): BaseTaskInstrumentSettings {
    return settings.accept<BaseTaskInstrumentSettings>(cloneTaskInstrumentSettingsVisitor);
}

const cloneTaskInstrumentSettingsVisitor: IInstrumentSettingsVisitor<BaseTaskInstrumentSettings> = {
    withMultichoice: ({ instrument, settingsId, settingsUuid }) => {
        return new MultichoiceTaskInstrumentSettings(settingsId, settingsUuid, instrument.clone());
    },
    withFigure({ instrument, settingsId, settingsUuid }) {
        return new Figure2DWithCompletionsTaskInstrumentSettings(settingsId, settingsUuid, instrument.clone());
    },
    withGraphic1d({ instrument, settingsId, settingsUuid }) {
        return new Graph1DTaskInstrumentSettings(settingsId, settingsUuid, instrument.clone());
    },
    withInformationTable({ instrument, settingsId, settingsUuid }) {
        return new TableTextTaskInstrumentSettings(settingsId, settingsUuid, instrument.clone());
    },
    withIntervalMethod({ instrument, settingsId, settingsUuid }) {
        return new IntervalsTaskInstrumentSettings(settingsId, settingsUuid, instrument.clone());
    },
    withPicture({ instrument, settingsId, settingsUuid }) {
        return new PictureTaskInstrumentSettings(settingsId, settingsUuid, instrument.clone());
    },
    withSelectTable({ instrument, settingsId, settingsUuid }) {
        return new TableSelectTaskInstrumentSettings(settingsId, settingsUuid, instrument.clone());
    },
    withGraph2d({ instrument, settingsId, settingsUuid }) {
        return new Graph2DTaskInstrumentSettings(settingsId, settingsUuid, instrument.clone());
    },
};
