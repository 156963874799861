import type { TFormControl } from "@viuch/form-builder/types/controls";

import { createLabel } from "../controls/label";
import { valueMathControl } from "../controls/value";

export const indefiniteIntegral: TFormControl = {
    control: "Form",
    layout: "column",
    condition: {
        "./solver_type": ["indefinite_integral"],
    },
    controls: [
        createLabel("Берет неопределенный интеграла"), //
        valueMathControl,
    ],
};

export const definitiveIntegral: TFormControl = {
    control: "Form",
    layout: "column",
    condition: { "./solver_type": "definitive_integral" },
    controls: [
        createLabel("Находит значение определенного интеграла"), //
        valueMathControl,
    ],
};
