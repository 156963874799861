import { makeObservable, observable } from "mobx";

import { copyAngle } from "@viuch/geometry-lib/factories";
import { generateId } from "@viuch/shared/utils/data";

import type { TModelStyle } from "../modelStyle";
import type { TAngle } from "@viuch/geometry-lib/types";

import { BaseModel } from "../BaseModel";

export interface IAngleEqualityModel {
    angles: TAngle[];
    segmentsCount: number;
    style: TModelStyle | null;
}

export class EqualAnglesModel extends BaseModel implements IAngleEqualityModel {
    angles: TAngle[];
    segmentsCount: number;
    style: TModelStyle | null;

    constructor(data: IAngleEqualityModel, id: number) {
        super(id);

        this.angles = data.angles.map(copyAngle);
        this.segmentsCount = data.segmentsCount;
        this.style = data.style;

        makeObservable(this, {
            angles: observable,
            style: observable,
        });
    }

    static create(data: IAngleEqualityModel) {
        return new EqualAnglesModel(data, generateId());
    }

    accept<R>(visitor: IEqualAnglesModelVisitor<R>): R {
        return visitor.withEqualAngles(this);
    }
}

export interface IEqualAnglesModelVisitor<R> {
    withEqualAngles: (equalAngles: EqualAnglesModel) => R;
}
