import React from "react";

import type { DigitModel } from "./DigitModel";
import type { TElementProps } from "../../types/element";

import { useMathEditorElementRef } from "../../hooks";

import styles from "./DigitElement.module.scss";

export const DigitElement: React.VFC<TElementProps<DigitModel>> = ({ elementModel }) => {
    const ref = useMathEditorElementRef(elementModel);

    return (
        <span
            className={styles.digit}
            ref={ref}
        >
            {elementModel.digit}
        </span>
    );
};
