export class CommandInitError extends Error {
    public readonly isCritical: boolean;

    public constructor(isCritical: boolean, message?: string) {
        super(message);
        this.isCritical = isCritical;
    }

    public static Is(error: unknown): error is CommandInitError {
        return error instanceof CommandInitError;
    }
}
