import { createFragment } from "@viuch/geometry-lib/factories";
import { rotatePoint } from "@viuch/geometry-lib/solvers";
import { addVectors, subtractVectors } from "@viuch/geometry-lib/vectors";

import type { TBasis, TFragment } from "@viuch/geometry-lib/types";

export function getRealBasis({ angle, offset, fragment }: TBasis): TFragment {
    const _offset = offset || fragment.a;
    const _angle = angle || 0;

    const a = _offset;
    const b = addVectors(fragment.b, subtractVectors(fragment.a, _offset));
    return createFragment(a, rotatePoint(b, _angle, a, false));
}
