import { defaultTableViewSettings } from "@viuch/instrument-table/data/viewSettings";
import { ObservableRef } from "@viuch/shared/utils/mobx";

import type { TTableViewSettings } from "@viuch/instrument-table/entities/types";

export class TableViewSettings {
    readonly tableCenter: ObservableRef<boolean>;
    readonly equalColumns: ObservableRef<boolean>;
    readonly shrinkFirstColumn: ObservableRef<boolean>;
    readonly centerCells: ObservableRef<boolean>;
    readonly centerFirstRow: ObservableRef<boolean>;
    readonly centerFirstColumn: ObservableRef<boolean>;
    readonly wrapTable: ObservableRef<boolean>;
    readonly wrapFirstRow: ObservableRef<boolean>;
    readonly wrapFirstColumn: ObservableRef<boolean>;
    readonly highlightFirstRow: ObservableRef<boolean>;
    readonly highlightFirstColumn: ObservableRef<boolean>;
    readonly boldFirstRow: ObservableRef<boolean>;
    readonly boldFirstColumn: ObservableRef<boolean>;

    constructor(settings: Partial<TTableViewSettings>) {
        const s: { [Key in keyof TTableViewSettings]: unknown } = {
            ...defaultTableViewSettings,
            ...settings,
        };

        this.tableCenter = new ObservableRef(!!s.tableCenter);
        this.equalColumns = new ObservableRef(!!s.equalColumns);
        this.shrinkFirstColumn = new ObservableRef(!!s.shrinkFirstColumn);
        this.centerCells = new ObservableRef(!!s.centerCells);
        this.centerFirstRow = new ObservableRef(!!s.centerFirstRow);
        this.centerFirstColumn = new ObservableRef(!!s.centerFirstColumn);
        this.wrapTable = new ObservableRef(!!s.wrapTable);
        this.wrapFirstRow = new ObservableRef(!!s.wrapFirstRow);
        this.wrapFirstColumn = new ObservableRef(!!s.wrapFirstColumn);
        this.highlightFirstRow = new ObservableRef(!!s.highlightFirstRow);
        this.highlightFirstColumn = new ObservableRef(!!s.highlightFirstColumn);
        this.boldFirstRow = new ObservableRef(!!s.boldFirstRow);
        this.boldFirstColumn = new ObservableRef(!!s.boldFirstColumn);
    }

    static createEmpty(): TableViewSettings {
        return new TableViewSettings(defaultTableViewSettings);
    }

    serialize(): TTableViewSettings {
        return {
            tableCenter: this.tableCenter.value,
            equalColumns: this.equalColumns.value,
            shrinkFirstColumn: this.shrinkFirstColumn.value,
            centerCells: this.centerCells.value,
            centerFirstRow: this.centerFirstRow.value,
            centerFirstColumn: this.centerFirstColumn.value,
            wrapTable: this.wrapTable.value,
            wrapFirstRow: this.wrapFirstRow.value,
            wrapFirstColumn: this.wrapFirstColumn.value,
            highlightFirstRow: this.highlightFirstRow.value,
            highlightFirstColumn: this.highlightFirstColumn.value,
            boldFirstRow: this.boldFirstRow.value,
            boldFirstColumn: this.boldFirstColumn.value,
        };
    }

    clone(): TableViewSettings {
        return new TableViewSettings(this.serialize());
    }
}
