import type { BaseElementModel } from "../core/element";
import type { TAnySerializedElement } from "../types";

import { BaseCommand } from "../core/commands";

export class BackspaceCommand<T extends BaseElementModel = BaseElementModel> extends BaseCommand<T> {
    private readonly removedSerializedElement: TAnySerializedElement;

    public constructor(element: T) {
        super(element);

        this.removedSerializedElement = this.cursor.container.getElementByIndex(this.cursorPosition - 1).serialize();
    }

    apply(): void {
        const container = this.getCursorContainer();
        container.removeElementByIndex(this.cursorPosition - 1);
        this.cursor.moveToPosition(container, this.cursorPosition - 1);
    }

    rollback(): void {
        const container = this.getCursorContainer();
        const element = this.inputService.deserializeElement(this.removedSerializedElement);
        container.insertElement(element, this.cursorPosition - 1);
        this.restoreCursor();
    }
}
