import { observer } from "mobx-react-lite";

import type { GradeModel } from "./GradeModel";
import type { TElementProps } from "../../types/element";
import type { VFC } from "react";

import { useMathEditorElementRef } from "../../hooks";

import styles from "./GradeElement.module.scss";

export const GradeElement: VFC<TElementProps<GradeModel>> = observer(function GradeElement({ elementModel }) {
    const ref = useMathEditorElementRef<HTMLSpanElement>(elementModel);

    return (
        <span
            ref={ref}
            className={styles.inlineWrapper}
        >
            <span className={styles.grade} />
        </span>
    );
});
