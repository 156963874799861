import cn from "classnames";
import { useEffect, useRef } from "react";

import type { ComplexIntegralModel } from "./ComplexIntegralModel";
import type { TElementProps } from "../../types/element";
import type { FC } from "react";

import { ContainerElement } from "../../core/container";
import { RoundBrackets } from "../../core/dynamic-brackets";
import { useMathEditorElementRef } from "../../hooks";

import * as utils from "./utils";

import styles from "./ComplexIntegralElement.module.scss";

export const ComplexIntegralElement: FC<TElementProps<ComplexIntegralModel>> = ({ elementModel }) => {
    const wrapperRef = useMathEditorElementRef<HTMLSpanElement>(elementModel);
    const topContentRef = useRef<HTMLSpanElement>(null);
    const bottomContentRef = useRef<HTMLSpanElement>(null);
    const integralContentRef = useRef<HTMLSpanElement>(null);
    const argContentRef = useRef<HTMLSpanElement>(null);

    useEffect(() => {
        const wrapper = wrapperRef.current;
        const topContent = topContentRef.current;
        const bottomContent = bottomContentRef.current;
        const integralContent = integralContentRef.current;
        const argContent = argContentRef.current;

        if (!wrapper || !topContent || !bottomContent || !integralContent || !argContent) {
            return;
        }

        const integralWrapperSizeObserver = new ResizeObserver(() => {
            const { marginTop, marginBottom } = utils.computeContentMargin(
                topContent,
                bottomContent,
                integralContent,
                argContent,
                wrapper
            );

            wrapper.style.marginTop = `${marginTop}px`;
            wrapper.style.marginBottom = `${marginBottom}px`;
        });

        integralWrapperSizeObserver.observe(wrapper);

        return () => integralWrapperSizeObserver.disconnect();
    }, [wrapperRef]);

    return (
        <span
            ref={wrapperRef}
            className={cn(styles.inline, styles.wrapper)}
        >
            <span className={styles.integralWrapper}>
                <span
                    className={cn(styles.smallContent, styles.topContent)}
                    ref={topContentRef}
                >
                    <ContainerElement
                        containerModel={elementModel.topContent}
                        inline
                    />
                </span>

                <span className={cn(styles.inline, styles.integralChar)}>∫</span>

                <span
                    className={cn(styles.smallContent, styles.bottomContent)}
                    ref={bottomContentRef}
                >
                    <ContainerElement
                        containerModel={elementModel.bottomContent}
                        inline
                    />
                </span>
            </span>

            <span
                className={cn(styles.inline, styles.contentsWrapper)}
                ref={integralContentRef}
            >
                <ContainerElement
                    containerModel={elementModel.integralContent}
                    inline
                />
            </span>

            <span
                className={cn(styles.inline, styles.argContent)}
                ref={argContentRef}
            >
                <span className={cn(styles.inline, styles.char)}>d</span>
                <RoundBrackets>
                    <ContainerElement
                        containerModel={elementModel.argContent}
                        inline
                    />
                </RoundBrackets>
            </span>
        </span>
    );
};
