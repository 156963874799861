import type { GetError } from "./RequestError";

import { agent } from "../../agent";

import { RequestError, throwServiceError } from "./RequestError";
import { stripPath } from "./utils";

export abstract class BaseCRUDService<TEntity extends { id: number }, TRequest, TResponse = TRequest>
    implements ICRUDService<TEntity>
{
    protected readonly baseUrl: string;
    protected readonly mapEntity: (data: TResponse) => TEntity;
    protected readonly serializeEntity: (entity: TEntity) => TRequest;
    protected readonly clientValidate?: (entity: TEntity) => GetError<TRequest> | void;

    protected constructor(
        baseUrl: string,
        mapEntity: Bivariant<(data: TResponse) => TEntity>,
        serializeEntity: (entity: TEntity) => TRequest,
        clientValidate?: (entity: TEntity) => GetError<TRequest> | void
    ) {
        this.baseUrl = stripPath(baseUrl);
        this.mapEntity = mapEntity;
        this.serializeEntity = serializeEntity;
        this.clientValidate = clientValidate;
    }

    getAllRaw = async (): Promise<TResponse[]> => {
        const { data } = await agent.get<TResponse[]>(`/${this.baseUrl}/`);
        return data;
    };

    getAll = async (): Promise<TEntity[]> => {
        return (await this.getAllRaw()).map((entity) => this.mapEntity(entity));
    };

    getByIdRaw = async (id: number): Promise<TResponse> => {
        const { data } = await agent.get<TResponse>(`/${this.baseUrl}/${id}/`);
        return data;
    };

    getById = async (id: number): Promise<TEntity> => {
        return this.mapEntity(await this.getByIdRaw(id));
    };

    createNew = async (entity: TEntity): Promise<TEntity> => {
        const clientError = this.clientValidate?.(entity);
        if (clientError) {
            throw new RequestError<GetError<TRequest>>(clientError);
        }

        try {
            const requestData = this.serializeEntity(entity);
            const { data } = await agent.post<TResponse>(`/${this.baseUrl}/`, requestData);
            return this.mapEntity(data);
        } catch (e) {
            throwServiceError<TResponse>(e);
        }
    };

    createNewRaw = async (requestData: TRequest): Promise<TResponse> => {
        try {
            const { data } = await agent.post<TResponse>(`/${this.baseUrl}/`, requestData);
            return data;
        } catch (e) {
            throwServiceError<TResponse>(e);
        }
    };

    update = async (entity: TEntity): Promise<TEntity> => {
        const clientError = this.clientValidate?.(entity);
        if (clientError) {
            throw new RequestError<GetError<TRequest>>(clientError);
        }

        try {
            const requestData = this.serializeEntity(entity);
            const { data } = await agent.patch<TResponse>(`/${this.baseUrl}/${entity.id}/`, requestData);
            return this.mapEntity(data);
        } catch (e) {
            throwServiceError<TResponse>(e);
        }
    };

    updateRaw = async (requestData: TRequest, id: number): Promise<TResponse> => {
        try {
            const { data } = await agent.patch<TResponse>(`/${this.baseUrl}/${id}/`, requestData);
            return data;
        } catch (e) {
            throwServiceError<TResponse>(e);
        }
    };

    deleteById = async (id: number): Promise<void> => {
        await agent.delete(`/${this.baseUrl}/${id}/`);
    };
}

export interface ICRUDService<TEntity> {
    getAll: () => Promise<TEntity[]>;
    getById: (id: number) => Promise<TEntity>;
    createNew: (entity: TEntity) => Promise<TEntity>;
    update: (entity: TEntity) => Promise<TEntity>;
    deleteById: (id: number) => Promise<void>;
}
