import type { MutableRefObject, RefCallback } from "react";

type ReactRef<T> = MutableRefObject<T | null> | RefCallback<T> | null | undefined;

export function mergeRefs<T>(...refs: (ReactRef<T> | undefined | null)[]): RefCallback<T> {
    return (object) => {
        for (const ref of refs) {
            if (ref) {
                setRefValue(ref, object);
            }
        }
    };
}

export function setRefValue<T>(ref: ReactRef<T>, value: T): void {
    if (ref) {
        if (typeof ref === "function") {
            ref(value);
        } else {
            ref.current = value;
        }
    }
}
