import { areSamePoints, isPointOnFragment } from "@viuch/geometry-lib/check-geometry";
import { compareFloats, equateNumbers } from "@viuch/geometry-lib/equate";
import { copyFragment } from "@viuch/geometry-lib/factories";
import { getLineCharacteristicHash } from "@viuch/geometry-lib/hashing";
import { getLineCharacteristic } from "@viuch/geometry-lib/lines";
import { rotatePoint } from "@viuch/geometry-lib/solvers";

import type { BaseModel } from "../models";
import type { IModelVisitor } from "../models/BaseModel";
import type { TFragment, TLineCharacteristic, TFragmentWithPoints, TPoint } from "@viuch/geometry-lib/types";

type TFragmentWithData = TFragment & {
    lineCharacteristic: TLineCharacteristic;
    f1: TFragment<number>;
};

export function findAllViewFragments(models: BaseModel[]): TFragment[] {
    const fragments = models
        .flatMap((model) => model.accept(getModelVisibleFragmentsVisitor))
        .map(addLineCharacteristic);

    const fragmentsOnLines = Object.values(
        fragments.reduce((map: Record<string, TFragmentWithData[]>, fragment) => {
            const hash = getLineCharacteristicHash(fragment.lineCharacteristic);
            if (!map[hash]) {
                map[hash] = [fragment];
            } else {
                map[hash].push(fragment);
            }
            return map;
        }, {})
    );

    fragmentsOnLines.forEach((fragments) =>
        fragments.sort((left, right) => compareFloats(left.f1.a, right.f1.a) || compareFloats(left.f1.b, right.f1.b))
    );

    return fragmentsOnLines
        .flatMap<TFragmentWithData>((fragments) =>
            fragments.reduce<TFragmentWithData[]>((all, current, i) => {
                if (i === 0) return [current];
                const last = all.at(-1)!;

                if (equateNumbers(current.f1.a, "<=", last.f1.b)) {
                    if (equateNumbers(current.f1.b, ">", last.f1.b)) {
                        last.f1.b = current.f1.b;
                        last.b = current.b;
                    }
                } else {
                    all.push(current);
                }

                return all;
            }, [])
        )
        .map<TFragment>(copyFragment);
}

export const getModelVisibleFragmentsVisitor: IModelVisitor<TFragment[]> = {
    withFragment: (fragment) => [copyFragment(fragment)],
    withParallelFragment: (fragment) => [copyFragment(fragment)],
    withLine: ({ virtualFragment }) => [copyFragment(virtualFragment)],
    withConstraintLine: ({ virtualFragment }) => [copyFragment(virtualFragment)],
    withComputedIntersectionPoints: () => [],
    withBisection: ({ bisectionFragment }) => (bisectionFragment ? [copyFragment(bisectionFragment)] : []),
    withMedian: ({ medianFragment }) => [copyFragment(medianFragment)],
    withAltitude: ({ altitudeFragment }) => [copyFragment(altitudeFragment)],
    withTangentLine: ({ virtualTangentLine }) => (virtualTangentLine ? [copyFragment(virtualTangentLine)] : []),
    withMiddleLine: ({ middleLineFragment }) => [copyFragment(middleLineFragment)],
    withTangentLineOnCircle: ({ virtualTangentFragment }) =>
        virtualTangentFragment ? [copyFragment(virtualTangentFragment)] : [],
    withFragmentDashed: (fragment) => [fragment.toFragment()],
    withVector: (vector) => [vector.toFragment()],
    withVectorDashed: (vector) => [vector.toFragment()],
    withPoint: () => [],
    withLabelPoint: () => [],
    withLabelFragment: () => [],
    withLabelAngle: () => [],
    withEqualAngles: () => [],
    withEqualSegments: () => [],
    withEllipse: () => [],
    withInscribedCircle: () => [],
    withCircumscribedCircle: () => [],
};

function addLineCharacteristic({ a, b }: TFragment): TFragmentWithData {
    const lineCharacteristic = getLineCharacteristic(a, b);
    const f1: TFragment<number> = {
        a: rotatePoint(a, lineCharacteristic.angle, void 0, true).x,
        b: rotatePoint(b, lineCharacteristic.angle, void 0, true).x,
    };
    if (equateNumbers(f1.a, ">", f1.b)) {
        [f1.a, f1.b] = [f1.b, f1.a];
        [a, b] = [b, a];
    }
    return { a, b, lineCharacteristic, f1 };
}

export function findVertexViewPointsOnFragments(
    vertex: TPoint,
    allViewFragments: TFragment[],
    allViewPoints: TPoint[]
): TFragmentWithPoints[] {
    const fragmentsThroughPoint = allViewFragments.filter(isPointOnFragment.getPointFilter(vertex));

    return fragmentsThroughPoint.map((fragment) => ({
        fragment,
        points: allViewPoints.filter((point) => !areSamePoints(point, vertex) && isPointOnFragment(fragment, point)),
    }));
}
