import { action, makeObservable, observable } from "mobx";

import { createSerializedContainer } from "@viuch/math-editor";
import { isMathExpression } from "@viuch/math-editor/utils/validation";

import type { BaseFormNode, IFormNodeVisitor } from "./BaseFormNode";
import type { TSerializedState } from "@viuch/math-editor";

import { BaseValueFormNode } from "./BaseValueFormNode";

export class MathFormNode extends BaseValueFormNode {
    @observable.ref formula: TSerializedState;
    readonly multiline: boolean;

    @action.bound
    setFormula(formula: TSerializedState) {
        this.formula = formula;
    }

    constructor(label: string, multiline: boolean, parent: BaseFormNode | null, path: string[], extensions: string[]) {
        super(label, parent, path, extensions);

        this.multiline = multiline;
        this.formula = createSerializedContainer();

        makeObservable(this);
    }

    accept<R>(visitor: IFormNodeVisitor<R>): R {
        return visitor.withMath(this);
    }

    getValue(): unknown {
        return this.formula;
    }

    trySetValue(value: unknown): boolean {
        if (isMathExpression(value)) {
            this.formula = value;
            return true;
        }
        return false;
    }

    valueEqualsTo(value: unknown): boolean {
        return false;
    }
}
