import { action, makeObservable, observable } from "mobx";

import { generateId } from "@viuch/shared/utils/data";
import { deepClone } from "@viuch/shared/utils/data/copy";

export class ReviewComment {
    constructor(
        id: ReviewComment["id"],
        reviewId: ReviewComment["reviewId"],
        parentId: ReviewComment["parentId"],
        updatedAt: ReviewComment["updatedAt"],
        createdAt: ReviewComment["createdAt"],
        isVerified: ReviewComment["isVerified"] = true,
        user: ReviewComment["user"],
        text = "",
        childrens: ReviewComment[] = []
    ) {
        this.id = id;
        this.reviewId = reviewId;
        this.parentId = parentId;
        this.updatedAt = updatedAt;
        this.createdAt = createdAt;
        this.isVerified = isVerified;
        this.user = user;

        this.text = text;
        this.childrens = childrens;

        makeObservable(this);
    }

    readonly id: number;
    readonly reviewId: number;
    readonly parentId: number | null;
    readonly updatedAt: Date;
    readonly createdAt: Date;
    readonly user: {
        id: number;
        username: string;
    };

    @observable text: string;
    @observable childrens: ReviewComment[];
    @observable isVerified: boolean;

    @action.bound
    public setText(text: string) {
        this.text = text;
    }

    @action.bound
    public removeChildren(comment: ReviewComment) {
        this.childrens.remove(comment);
    }

    static createEmpty(
        reviewId: ReviewComment["reviewId"],
        parentId: ReviewComment["parentId"],
        user: ReviewComment["user"]
    ) {
        return new ReviewComment(
            generateId(),
            reviewId,
            parentId,
            new Date(),
            new Date(),
            true,
            user,
            undefined,
            undefined
        );
    }

    clone(): ReviewComment {
        return new ReviewComment(
            this.id,
            this.reviewId,
            this.parentId,
            this.updatedAt,
            this.createdAt,
            this.isVerified,
            deepClone(this.user),
            this.text,
            this.childrens.map((comment) => comment.clone())
        );
    }
}
