import { makeObservable, observable } from "mobx";
import { createTransformer } from "mobx-utils";

import { areSamePoints, findPerpendicularPoint } from "@viuch/geometry-lib/check-geometry";
import { computeSquaredDistance } from "@viuch/math-editor/utils/positions";

import type { BaseElement } from "../../elements";
import type { Figure2DController } from "../../Figure2DController";
import type { ToolbarButton, ToolbarMenu } from "../../toolbar";
import type { IUserPointer } from "../../viewport/types";
import type { TLine, TPoint } from "@viuch/geometry-lib/types";
import type React from "react";

import { DotElement } from "../../elements/dot";
import { ElementColor } from "../../elements/ElementColor";
import { LineElement } from "../../elements/line";
import { FragmentModel } from "../../models/fragment";
import { LabelPointModel } from "../../models/label-point";
import { PointModel } from "../../models/point";
import { ButtonStates } from "../../toolbar";
import { TooltipMenu } from "../../toolbar/tooltip";
import { createDefaultToolbarMenuBuilder, handleToolbarButtons } from "../../utils/toolbar";
import { BaseFlow } from "../BaseFlow";
import { createMapModelToElements, opacifyColor } from "../utils";

export class CreateSquareFlow extends BaseFlow {
    flow:
        | {
              stage: 1;
              a?: TPoint;
              drawing: boolean;
          }
        | {
              stage: 2;
              readonly a: TPoint;
              c?: TPoint;
              drawing: boolean;
          };

    constructor(data: Figure2DController) {
        super(data);

        this.flow = { stage: 1, drawing: false };

        makeObservable(this, {
            flow: observable,
        });
    }

    get rect(): Record<"a" | "b" | "c" | "d", TPoint> | null {
        if (this.flow.stage === 1 || !this.flow.c) return null;

        const { a, c } = this.flow;
        return {
            a,
            b: { x: a.x, y: c.y },
            c,
            d: { x: c.x, y: a.y },
        };
    }

    static create(data: Figure2DController) {
        return new CreateSquareFlow(data);
    }

    attach(): void {
        this.viewport.disable();
        this.snap.addViewportGrid().addFigureModelsPoints();
    }

    dispose(): void {
        this.snap.clean();
    }

    mapModels = createTransformer(createMapModelToElements(opacifyColor));

    protected renderElements(): BaseElement[] {
        return [...this.figure.models.flatMap(this.mapModels), ...this.getTempElements()];
    }

    *getTempElements(): Iterable<BaseElement> {
        const { stage, a, drawing } = this.flow;
        const { scale } = this.viewport;

        const shallowColor = ElementColor.Util;

        if (a) {
            yield new DotElement({
                id: "temp_a",
                x: a.x,
                y: a.y,
                color: stage === 1 && drawing ? ElementColor.Selected : ElementColor.Building,
                overrideRenderOrder: 0,
            });
        }

        if (stage === 1 || !this.flow.c) {
            // нарисовать демо-квадрат

            if (a) {
                const c = { x: a.x + 0.1 / scale, y: a.y + 0.1 / scale };
                const b = { x: a.x, y: c.y };
                const d = { x: c.x, y: a.y };
                yield* [
                    [a, b],
                    [b, c],
                    [c, d],
                    [d, a],
                ].map(([first, second], i) => {
                    return new LineElement({
                        id: `placeholder_line_${i}`,
                        x1: first.x,
                        y1: first.y,
                        x2: second.x,
                        y2: second.y,
                        color: shallowColor,
                        thin: true,
                        overrideRenderOrder: 0,
                    });
                });
            }

            return;
        }

        const { c } = this.flow;

        const [b, d] = [
            { x: a.x, y: c.y },
            { x: c.x, y: a.y },
        ];

        const lineColor = stage === 2 ? ElementColor.Building : ElementColor.Util;

        yield new DotElement({
            x: b.x,
            y: b.y,
            color: ElementColor.Building,
            id: "temp_b",
            overrideRenderOrder: 0,
        });
        yield new DotElement({
            ...c,
            x: c.x,
            y: c.y,
            color: stage === 2 && drawing ? ElementColor.Selected : ElementColor.Building,
            id: "temp_c",
            overrideRenderOrder: 0,
        });
        yield new DotElement({
            ...d,
            x: d.x,
            y: d.y,
            color: ElementColor.Building,
            id: "temp_d",
            overrideRenderOrder: 0,
        });

        yield new LineElement({
            x1: a.x,
            y1: a.y,
            x2: b.x,
            y2: b.y,
            color: lineColor,
            id: "temp_ab",
            overrideRenderOrder: 1,
        });
        yield new LineElement({
            x1: b.x,
            y1: b.y,
            x2: c.x,
            y2: c.y,
            color: lineColor,
            id: "temp_bc",
            overrideRenderOrder: 1,
        });
        yield new LineElement({
            x1: c.x,
            y1: c.y,
            x2: d.x,
            y2: d.y,
            color: lineColor,
            id: "temp_cd",
            overrideRenderOrder: 1,
        });
        yield new LineElement({
            x1: d.x,
            y1: d.y,
            x2: a.x,
            y2: a.y,
            color: lineColor,
            id: "temp_da",
            overrideRenderOrder: 1,
        });
    }

    override handleViewportPointerEvent(e: React.PointerEvent, pointer: IUserPointer): void {
        if (e.type === "pointercancel") return this.nextFlow();
        if (!e.isPrimary) return;

        const point = this.snap.snap(pointer.canvas) || void 0;

        this.movePointer(e, point);
        this.startDrawing(e, point);
        this.endDrawing(e);
    }

    private movePointer(e: React.PointerEvent, point?: TPoint) {
        if (e.type === "pointerdown" || e.type === "pointermove") {
            switch (this.flow.stage) {
                case 1:
                    this.flow.a = point;
                    break;
                case 2:
                    point && (point = this.snap.snap(point, undefined));

                    if (point) {
                        const { a } = this.flow;
                        const line1: TLine = { x1: a.x, y1: a.y, x2: a.x + 1, y2: a.y + 1 };
                        const line2: TLine = { x1: a.x, y1: a.y, x2: a.x + 1, y2: a.y - 1 };

                        const p1 = findPerpendicularPoint(line1, point);
                        const p2 = findPerpendicularPoint(line2, point);

                        const snappedPoint =
                            computeSquaredDistance(p1, point) < computeSquaredDistance(p2, point) ? p1 : p2;

                        if (snappedPoint) {
                            this.flow.c = snappedPoint;
                        }
                    }

                    break;
            }
        }
    }

    private startDrawing(e: React.PointerEvent, point?: TPoint) {
        if (e.type === "pointerdown") {
            switch (this.flow.stage) {
                case 1:
                case 2:
                    this.flow.drawing = true;
                    break;
            }
        }
    }

    private endDrawing(e: React.PointerEvent) {
        if (e.type === "pointerup") {
            switch (this.flow.stage) {
                case 1:
                    this.flow.drawing = false;

                    if (this.flow.a) {
                        this.flow = {
                            stage: 2,
                            a: this.flow.a,
                            drawing: false,
                        };
                    }
                    break;
                case 2:
                    this.flow.drawing = false;
                    if (this.flow.c) {
                        const { a, c } = this.flow;
                        if (!areSamePoints(a, c)) {
                            this.rawSave(a, c);
                        }
                    }
                    this.nextFlow();
                    break;
            }
        }
    }

    private rawSave(_a: TPoint, _c: TPoint) {
        const [_b, _d] = [
            { x: _a.x, y: _c.y },
            { x: _c.x, y: _a.y },
        ];

        this.figure.insertModels(function* () {
            for (const [a, b] of [
                [_a, _b],
                [_b, _c],
                [_c, _d],
                [_d, _a],
            ]) {
                yield FragmentModel.create({ a, b, style: null });
            }

            for (const point of [_a, _b, _c, _d]) {
                yield PointModel.create({ ...point, style: null });
                yield LabelPointModel.createNext(point, this.figure);
            }
        }, this);
    }

    override getToolbarMenu(): ToolbarMenu {
        return createDefaultToolbarMenuBuilder()
            .setButtonIcon("square", ["squares"])
            .setButtonState(ButtonStates.active, ["squares"])
            .build();
    }

    override handleToolbarButtonClick(menu: ToolbarMenu, button: ToolbarButton): void {
        switch (button.key) {
            case "rect":
                this.nextFlow();
                break;
            default:
                handleToolbarButtons(this, button);
        }
    }

    override getTooltipMenu(): TooltipMenu | null {
        let text: string;

        switch (this.flow.stage) {
            case 1:
                text = "Выберите первую вершину квадрата";
                break;
            case 2:
                text = "Выберите вершину квадрата";
                break;
        }

        return new TooltipMenu(text);
    }
}
