import cn from "classnames";
import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo, useRef } from "react";

import { ZoomButtons } from "@viuch/ui-kit/components/zoom-buttons/ZoomButtons";
import { useClickOutside } from "@viuch/utils/hooks";

import type { Figure2DController } from "./Figure2DController";
import type { KeyboardService } from "@viuch/math-editor";
import type { TZoomButtonsExtraButton } from "@viuch/ui-kit/components/zoom-buttons/ZoomButtons";

import { FigureViewModel } from "./FigureViewModel";
import { ActionsBar, Toolbar } from "./toolbar/components";
import { Tooltip } from "./toolbar/tooltip";
import { FigureViewport, RenderElements } from "./viewport/components";

import styles from "./Figure2DEditor.module.scss";

type Props = {
    controller: Figure2DController;
    className?: string;
    keyboardService: KeyboardService;
    onResetAsync?(): Promise<boolean>;
    extraButtons?: TZoomButtonsExtraButton[];
};

export const Figure2DEditor = observer(function Figure2DEditor({
    className,
    controller,
    keyboardService,
    extraButtons,
    onResetAsync,
}: Props) {
    const returnFocusRef = useRef<HTMLDivElement>(null);

    const returnFocus = () => {
        returnFocusRef.current?.focus();
    };

    useEffect(() => {
        controller.handlers.onResetAsync = onResetAsync ?? (async () => true);
        controller.handlers.returnFocus = returnFocus;
    });

    const vm = useMemo(() => FigureViewModel.initialize(controller), [controller]);

    useEffect(() => {
        return () => {
            vm.dispose();
        };
    }, [vm]);

    const { figure, toolbar, flows, viewport, events, device, handlers } = controller;

    const ref = useClickOutside<HTMLDivElement>(() => {
        toolbar.closeNestedMenu();
    });

    return (
        <div
            className={cn(className, styles.wrapper)}
            ref={ref}
        >
            <FigureViewport
                figure={figure}
                viewport={viewport}
                events={events}
                device={device}
                focusRef={returnFocusRef}
            >
                <RenderElements
                    events={events}
                    figure={figure}
                    flows={flows}
                    keyboardService={keyboardService}
                />
            </FigureViewport>
            <Toolbar
                toolbar={toolbar}
                handlers={handlers}
            />
            <ActionsBar toolbar={toolbar} />
            <ZoomButtons
                onPlus={() => viewport.offsetScale(0.2)}
                onMinus={() => viewport.offsetScale(-0.2)}
                extra={extraButtons}
            />
            <Tooltip toolbar={toolbar} />
        </div>
    );
});
