import { action, makeObservable } from "mobx";

import type { Graph2DFlowsController } from "./Graph2DFlowsController";
import type { Graph2DViewportController } from "./Graph2DViewportController";
import type { BaseGraphViewItem } from "../rendering/BaseGraphViewItem";
import type { TPoint } from "@viuch/geometry-lib/types";
import type { PointerEvent, KeyboardEvent, MouseEvent } from "react";

export class Graph2DEvents implements IGraphViewportEventsController, IElementViewportEvent {
    private readonly flows: Graph2DFlowsController;
    private readonly viewport: Graph2DViewportController;

    constructor(flows: Graph2DFlowsController, viewport: Graph2DViewportController) {
        this.viewport = viewport;
        this.flows = flows;

        makeObservable(this);
    }

    @action.bound
    dispatchViewportPointerEvent(e: PointerEvent): void {
        this.flows.flow.handleViewportPointerEvent?.(e, this.getCanvasPointFromPointerEvent(e));
    }

    @action.bound
    dispatchKeyEvent(e: KeyboardEvent) {
        this.flows.flow.handleKeyEvent?.(e);
    }

    @action.bound
    dispatchElementPointerEvent(e: PointerEvent, element: BaseGraphViewItem): void {
        this.flows.flow.handleElementPointerEvent?.(e, this.getCanvasPointFromPointerEvent(e), element);
    }

    @action.bound
    dispatchElementClick(e: MouseEvent, element: BaseGraphViewItem): void {
        this.flows.flow.handleElementClickEvent?.(e, element);
    }

    private getCanvasPointFromPointerEvent(e: PointerEvent): TPoint {
        const { x, y } = { x: e.clientX, y: e.clientY };

        const { width, height, left, top } = this.viewport.rootElement!.getBoundingClientRect();

        return {
            x: (x - left) / width,
            y: 1 - (y - top) / height,
        };
    }
}

export interface IGraphViewportEventsController {
    dispatchViewportPointerEvent(e: PointerEvent): void;
    dispatchKeyEvent(e: KeyboardEvent): void;
}

export interface IElementViewportEvent {
    dispatchElementPointerEvent(e: PointerEvent, element: BaseGraphViewItem): void;
}
