import type { BaseElementModel } from "../../core/element";
import type { IElementFilter } from "../../core/strategies/IElementFilter";
import type { BracketModel } from "../bracket/BracketModel";
import type { EqualityModel } from "../equality/EqualityModel";
import type { LowPriorityOperatorModel } from "../operator-low/LowPriorityOperatorModel";
import type { SpecialSymbolModel } from "../symbol/SpecialSymbolModel";

import { BaseDefaultElementVisitor } from "../../core/element/BaseDefaultElementVisitor";

export class TrigonometricContentFilter extends BaseDefaultElementVisitor<boolean> implements IElementFilter {
    protected defaultBehaviour(): boolean {
        return false;
    }

    canInsert(element: BaseElementModel): boolean {
        return element.accept(this);
    }

    override doWithChar(): boolean {
        return true;
    }

    override doWithSpace(): boolean {
        return true;
    }

    override doWithDigit(): boolean {
        return true;
    }

    override doWithOperatorLow(element: LowPriorityOperatorModel): boolean {
        switch (element.symbolName) {
            case "plus":
            case "minus":
                return true;
        }
        return false;
    }

    override doWithOperatorHigh(): boolean {
        return true;
    }

    override doWithEquality(element: EqualityModel): boolean {
        switch (element.symbolName) {
            case "equals":
                return true;
        }
        return false;
    }

    override doWithAngle(element: BaseElementModel): boolean {
        return true;
    }

    override doWithSymbol(element: SpecialSymbolModel): boolean {
        switch (element.symbolName) {
            case "comma":
            case "dot":
            case "pi":
            case "factorial":
                return true;
        }
        return false;
    }

    override doWithGrade(): boolean {
        return true;
    }

    override doWithDownIndex(): boolean {
        return true;
    }

    override doWithBracket(element: BracketModel): boolean {
        return element.type === "round";
    }

    override doWithDegree(): boolean {
        return true;
    }

    override doWithRoot(): boolean {
        return true;
    }

    override doWithFraction(): boolean {
        return true;
    }
    override doWithDerivative(): boolean {
        return true;
    }
    override doWithModule(): boolean {
        return true;
    }
}
