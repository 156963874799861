import cn from "classnames";
import React, { useEffect, useRef, useState } from "react";

import type { FormatEditor } from "../../editors/format";
import type { IMathDataProviderComponent } from "../../editors/format/plugins/MathEditorWebComponentPlugin/types";
import type { IMathConverterService } from "../../editors/types";
import type { IUploadImageService } from "@viuch/instrument-picture-settings/settings-editor/types";
import type { KeyboardService } from "@viuch/math-editor";

import styles from "./FormatEditorComponent.module.scss";

type TProps = {
    editor: FormatEditor;
    uploadService: IUploadImageService;
    mathConverterService: IMathConverterService;
    keyboardService: KeyboardService;
    wrapperClassName?: string;
    editorClassName?: string;
};

export const FormatEditorComponent: React.VFC<TProps> = ({
    editor,
    uploadService,
    mathConverterService,
    keyboardService,
    wrapperClassName,
    editorClassName,
}) => {
    const editorContainerRef = useRef<HTMLDivElement & IMathDataProviderComponent>(null);
    const [isEditorMounted, setIdEditorMounted] = useState(false);

    useEffect(() => {
        const editorContainer = editorContainerRef.current!;
        editorContainer.keyboardService = keyboardService;
        editorContainer.serializeMathExpression = mathConverterService.mathExpressionToString;

        editor.createEditor(mathConverterService, uploadService, keyboardService, () => {
            const wrapper = editorContainer.querySelector(".codex-editor");
            const editor = wrapper?.querySelector(".codex-editor__redactor");

            editor?.removeAttribute("style");

            setIdEditorMounted(true);

            if (wrapperClassName) {
                wrapper?.classList.add(wrapperClassName);
            }
            if (editorClassName) {
                editor?.classList.add(editorClassName);
            }

            const colorHexRegexp = /^#[0-9a-f]{6}$/;

            const elements = wrapper?.querySelectorAll("[data-color]") ?? [];

            elements.forEach((element) => {
                if (element instanceof HTMLElement) {
                    const color = element.dataset.color?.toLowerCase();

                    if (color && colorHexRegexp.test(color)) {
                        element.style.color = color;
                    }
                }
            });
        });

        return () => {
            editor.destroy();
            setIdEditorMounted(false);
        };
    }, [editor, wrapperClassName, editorClassName, uploadService, keyboardService, mathConverterService]);

    return (
        <div
            id={editor.uuid}
            ref={editorContainerRef}
            className={cn(styles.wrapper, !isEditorMounted ? styles.container : undefined)}
        />
    );
};
