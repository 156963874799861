import cn from "classnames";
import { forwardRef } from "react";

import type { IBaseSymbolElementProps } from "./BaseSymbolElement.types";

import styles from "./BaseSymbolElement.module.scss";

export const BaseSymbolElement = forwardRef<HTMLElement, IBaseSymbolElementProps>(function BaseSymbolElement(
    { className, children },
    ref
) {
    return (
        <span
            ref={ref}
            className={cn(styles.inputSymbol, className)}
        >
            {children}
        </span>
    );
});
