import React from "react";

import { useClickOutside } from "@viuch/utils/hooks";

import type { EditorTypes } from "../../../../editors/base";

import { EDITORS } from "./ComplexEditorTypesList.constants";

import styles from "./ComplexEditorTypesList.module.scss";

type TProps = {
    onItemClick(type: EditorTypes): void;
    onClose(): void;
};

export const ComplexEditorTypesList: React.VFC<TProps> = ({ onClose, onItemClick }) => {
    const listRef = useClickOutside<HTMLUListElement>(onClose);

    return (
        <ul
            className={styles.list}
            ref={listRef}
        >
            {EDITORS.map(({ type, label }) => (
                <li
                    key={type}
                    className={styles.listItem}
                    onClick={() => onItemClick(type)}
                >
                    {label}
                </li>
            ))}
        </ul>
    );
};
