export class ElementColor {
    readonly hex: string;
    readonly shadow: boolean;
    readonly opacity: number;

    protected constructor(hex: string, shadow: boolean, opacity: number) {
        this.hex = hex;
        this.shadow = shadow;
        this.opacity = opacity;
    }

    static create(hex: string, shadow = false, opacity = 1): ElementColor {
        const hash = this.getHashCode(hex, shadow ?? false, opacity ?? 1);

        if (!this._cachedColors[hash]) {
            this._cachedColors[hash] = new ElementColor(hex, shadow ?? false, opacity ?? 1);
        }

        return this._cachedColors[hash]!;
    }

    withShadow() {
        return ElementColor.create(this.hex, true, this.opacity);
    }

    withOpacity(opacity: number) {
        return ElementColor.create(this.hex, this.shadow, opacity);
    }

    withColor(hex: string) {
        return ElementColor.create(hex, this.shadow, this.opacity);
    }

    static get Default() {
        return ElementColor.create("#FFFFFF");
    }

    static get Selected() {
        return ElementColor.create("#BBF021", true, 1);
    }

    static get Computed() {
        return ElementColor.create("#E0821C", true, 1);
    }

    static get Building() {
        return ElementColor.create("#439BFF", true);
    }

    static get Red(): ElementColor {
        return ElementColor.create("#e02f2f", false, 1);
    }

    static get Util() {
        return ElementColor.create("#ACD7FF", false, 0.5);
    }

    static get Transparent() {
        return ElementColor.create("#FFFFFF", false, 0);
    }

    private static _cachedColors: Partial<Record<string, ElementColor>> = {};

    private static getHashCode(hex: string, shadow: boolean, opacity: number) {
        return `${hex}__${shadow}__${opacity}`;
    }
}
