import type { GetError } from "../base/RequestError";

import { Subject } from "../../entities/hierarchy";
import { IndexingEntityMetadata } from "../../entities/indexing/IndexingEntityMetadata";
import { BaseCRUDService } from "../base";

export class SubjectsService extends BaseCRUDService<Subject, TSubjectRequest, TSubjectResponse> {
    constructor() {
        super("/subjects_new/", mapSubject, serializeSubject);
    }
}

export type TSubjectRequest = {
    id: number;
    slug: string;
    name: string;
    sections: number[];
    meta_title: string | null;
    meta_description: string | null;
};

export type TSubjectResponse = TSubjectRequest & {
    subsection_count: number;
    theme_count: number;
    video_count: number;
    synopsis_count: number;
    task_count: number;
    problem_count: number;
    unverified_task_count: number;
};

export type TSubjectError = GetError<TSubjectRequest>;

export const mapSubject = (data: TSubjectResponse): Subject => {
    const {
        id,
        slug,
        name,
        sections,
        meta_title,
        meta_description,
        subsection_count,
        theme_count,
        video_count,
        synopsis_count,
        task_count,
        problem_count,
        unverified_task_count,
    } = data;

    return new Subject({
        id,
        name,
        sections,
        subsection_count,
        theme_count,
        video_count,
        synopsis_count,
        task_count,
        problem_count,
        unverified_task_count,
        indexing: new IndexingEntityMetadata(meta_title, meta_description, slug),
    });
};

export const serializeSubject = ({ id, name, sections, indexing }: Subject): TSubjectRequest => ({
    id,
    name,
    sections,
    slug: indexing.slug,
    meta_title: indexing.title,
    meta_description: indexing.description,
});
