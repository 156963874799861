import { makeObservable, observable } from "mobx";

import { s2m } from "@viuch/math-editor";

import type { IVariableContentVisitor } from "./IVariableContentVisitor";
import type { TSerializedState } from "@viuch/math-editor/types";

import { BaseVariableContent } from "./BaseVariableContent";

export interface IRandomVariableContent {
    left_range: TSerializedState;
    right_range: TSerializedState;
    quantity_fractional_digits: number;
    step_by: TSerializedState;
    shift_start: boolean;
    conditions: TSerializedState[];
}

export class RandomVariableContent extends BaseVariableContent implements IRandomVariableContent {
    left_range: TSerializedState;
    right_range: TSerializedState;
    quantity_fractional_digits: number;
    step_by: TSerializedState;
    shift_start: boolean;
    conditions: TSerializedState[];

    constructor(data: IRandomVariableContent) {
        super();

        this.left_range = data.left_range;
        this.right_range = data.right_range;
        this.quantity_fractional_digits = data.quantity_fractional_digits;
        this.step_by = data.step_by;
        this.shift_start = data.shift_start;
        this.conditions = data.conditions.slice();

        makeObservable(this, {
            left_range: observable,
            right_range: observable,
            quantity_fractional_digits: observable,
            step_by: observable,
            shift_start: observable,
            conditions: observable,
        });
    }

    accept<R>(visitor: IVariableContentVisitor<R>): R {
        return visitor.doWithRandom(this);
    }

    static createEmpty() {
        return new RandomVariableContent({
            left_range: s2m`1`,
            right_range: s2m`9`,
            quantity_fractional_digits: 0,
            step_by: s2m`1`,
            shift_start: false,
            conditions: [],
        });
    }

    clone(): RandomVariableContent {
        return new RandomVariableContent(this);
    }
}
