import { makeObservable, observable } from "mobx";
import { createTransformer } from "mobx-utils";

import type { BaseElement } from "../../elements";
import type { Figure2DController } from "../../Figure2DController";
import type { ToolbarButton, ToolbarMenu } from "../../toolbar";
import type { IUserPointer } from "../../viewport/types";
import type { TPoint } from "@viuch/geometry-lib/types";
import type React from "react";

import { DotElement } from "../../elements/dot";
import { ElementColor } from "../../elements/ElementColor";
import { LineElement } from "../../elements/line";
import { ButtonStates } from "../../toolbar";
import { TooltipMenu } from "../../toolbar/tooltip";
import { createDefaultToolbarMenuBuilder, handleToolbarButtons } from "../../utils/toolbar";
import { BaseFlow } from "../BaseFlow";
import { createMapModelToElements, opacifyColor, z } from "../utils";

import { LabelFragmentFlow } from "./LabelFragmentFlow";

export class LabelFragmentInteractiveFlow extends BaseFlow {
    flow:
        | {
              stage: 1;
              a?: TPoint;
              drawing: boolean;
          }
        | {
              stage: 2;
              readonly a: TPoint;
              b?: TPoint;
              drawing: boolean;
          };
    constructor(data: Figure2DController) {
        super(data);

        this.flow = { stage: 1, drawing: false };

        makeObservable(this, {
            flow: observable.deep,
        });
    }

    attach(): void {
        this.snap.addFigureModelsPoints();
    }

    dispose(): void {
        this.snap.clean();
    }

    override handleViewportPointerEvent(e: React.PointerEvent, pointer: IUserPointer): void {
        if (e.type === "pointercancel") return this.nextFlow();
        if (!e.isPrimary) return;

        const point = this.snap.snap(pointer.canvas, void 0);

        this.handlePointerMove(e, point);
        this.startDrawing(e);
        this.endDrawing(e);
    }

    private handlePointerMove(e: React.PointerEvent, point?: TPoint) {
        if (e.type === "pointermove" || e.type === "pointerdown") {
            switch (this.flow.stage) {
                case 1:
                    this.flow.a = point;
                    break;
                case 2:
                    this.flow.b = point;
                    break;
            }
        }
    }

    private startDrawing(e: React.PointerEvent) {
        if (e.type === "pointerdown") {
            this.flow.drawing = true;
        }
    }

    private endDrawing(e: React.PointerEvent) {
        if (e.type === "pointerup") {
            this.flow.drawing = false;

            switch (this.flow.stage) {
                case 1:
                    if (this.flow.a) {
                        this.flow = {
                            stage: 2,
                            a: this.flow.a,
                            drawing: false,
                        };
                    }
                    break;
                case 2:
                    if (this.flow.b) {
                        const { a, b } = this.flow;
                        this.nextFlow((data) => new LabelFragmentFlow(data, { a, b }));
                    }
                    break;
            }
        }
    }

    private renderModels = createTransformer(createMapModelToElements(opacifyColor));

    protected renderElements(): BaseElement[] {
        return [...this.figure.models.flatMap(this.renderModels), ...this.tempElements];
    }

    get tempElements(): BaseElement[] {
        const { stage, a, drawing } = this.flow;
        if (!a) return [];

        const color = drawing ? ElementColor.Selected : ElementColor.Building;

        if (stage === 1 || !this.flow.b) {
            return [new DotElement({ id: `temp_a`, x: a.x, y: a.y, color, overrideRenderOrder: z.points.priority })];
        }
        if (stage === 2) {
            const { b } = this.flow;
            return [
                new DotElement({
                    id: `temp_a`,
                    x: a.x,
                    y: a.y,
                    color: ElementColor.Building,
                    overrideRenderOrder: z.points.priority,
                }),
                new DotElement({
                    id: `temp_b`,
                    x: b.x,
                    y: b.y,
                    color,
                    overrideRenderOrder: z.points.priority,
                }),
                new LineElement({
                    id: `temp_fragment`,
                    x1: a.x,
                    y1: a.y,
                    x2: b.x,
                    y2: b.y,
                    color: ElementColor.Building,
                    overrideRenderOrder: z.fragments.priority,
                }),
            ];
        }
        return [];
    }

    override handleToolbarButtonClick(menu: ToolbarMenu, button: ToolbarButton): void {
        switch (button.key) {
            case "labels":
                this.nextFlow();
                break;
            default:
                handleToolbarButtons(this, button);
        }
    }

    static create(data: Figure2DController) {
        return new LabelFragmentInteractiveFlow(data);
    }

    override getToolbarMenu(): ToolbarMenu {
        return createDefaultToolbarMenuBuilder()
            .setButtonIcon("label-segment", ["labels"])
            .setButtonState(ButtonStates.active, ["labels"])
            .build();
    }

    override getTooltipMenu(): TooltipMenu | null {
        let text: string;

        switch (this.flow.stage) {
            case 1:
                text = "Выберите один конец отрезка";
                break;
            case 2:
                text = "Выберите другой конец отрезка";
                break;
        }

        return new TooltipMenu(text);
    }
}
