import { filterEqualObjects } from "@viuch/shared/utils/data/filter";

import type { TAngle, TFragment, TPoint } from "./types";

import { getAngleHash, getFragmentHashes, getPointHash } from "./hashing";

export function filterSameFragments(fragments: TFragment[]): TFragment[] {
    const set = new Set<string>();
    const result: TFragment[] = [];

    for (const fragment of fragments) {
        const [hash1, hash2] = getFragmentHashes(fragment);
        if (!set.has(hash1)) {
            result.push(fragment);
            set.add(hash1).add(hash2);
        }
    }

    return result;
}

export function filterEqualAngles(angles: TAngle[]): TAngle[] {
    const set = new Set<string>();
    const result = <TAngle[]>[];

    for (const angle of angles) {
        const hash = getAngleHash(angle);
        if (!set.has(hash)) {
            result.push(angle);
            set.add(hash);
        }
    }

    return result;
}

export function filterEqualPoints(points: TPoint[]): TPoint[] {
    return Array.from(filterEqualObjects<TPoint>(points, getPointHash));
}
