import cn from "classnames";

import type { PropsWithChildren, PointerEvent, TouchEvent } from "react";

import styles from "./Button.module.scss";

export type TButtonProps = PropsWithChildren<{
    alternate?: boolean;
    className?: string;
    onClick?: VoidFunction;
}>;

export const Button = ({ children, alternate, className, onClick }: TButtonProps) => {
    function handleDown(e: PointerEvent<HTMLDivElement>): void {
        e.preventDefault();
        e.currentTarget.releasePointerCapture(e.pointerId);
    }

    function handleUp(e: PointerEvent<HTMLDivElement>): void {
        e.preventDefault();
        onClick?.();
    }

    function handleTouchStart(e: TouchEvent<HTMLDivElement>) {
        e.preventDefault();
    }

    function handleTouchEnd(e: TouchEvent<HTMLDivElement>) {
        e.preventDefault();
    }

    return (
        <div
            className={cn(styles.button, alternate && styles.alternate, className)}
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
            onPointerDown={handleDown}
            onPointerUp={handleUp}
        >
            {children}
        </div>
    );
};
