import { computed, makeObservable, observable } from "mobx";
import { createTransformer } from "mobx-utils";

import { areSamePoints } from "@viuch/geometry-lib/check-geometry";
import { compareFloats } from "@viuch/geometry-lib/equate";
import { subtractVectors } from "@viuch/geometry-lib/vectors";

import type { BaseElement } from "../../elements";
import type { Figure2DController } from "../../Figure2DController";
import type { ToolbarMenu } from "../../toolbar";
import type { IUserPointer } from "../../viewport/types";
import type { TPoint } from "@viuch/geometry-lib/types";
import type React from "react";

import { DotElement } from "../../elements/dot";
import { ElementColor } from "../../elements/ElementColor";
import { EllipseElement } from "../../elements/ellipse";
import { LineElement } from "../../elements/line";
import { EllipseModel } from "../../models/circle";
import { LabelPointModel } from "../../models/label-point";
import { PointModel } from "../../models/point";
import { ButtonStates } from "../../toolbar";
import { TooltipMenu } from "../../toolbar/tooltip";
import { createDefaultToolbarMenuBuilder } from "../../utils/toolbar";
import { BaseFlow } from "../BaseFlow";
import { createMapModelToElements, opacifyColor } from "../utils";

export class EllipseFlow extends BaseFlow {
    flow:
        | {
              stage: 1;
              drawing: boolean;
              center?: TPoint;
          }
        | {
              stage: 2;
              drawing: boolean;
              center: TPoint;
              radiusPoint?: TPoint;
          };

    constructor(data: Figure2DController) {
        super(data);

        this.flow = { stage: 1, drawing: false };

        makeObservable(this, {
            flow: observable,
            tempElements: computed,
        });
    }

    attach(): void {
        this.snap.addViewportGrid().addFigureModelsPoints();
        this.viewport.disable();
    }

    dispose(): void {
        this.snap.clean();
    }

    override handleViewportPointerEvent(e: React.PointerEvent, pointer: IUserPointer): void {
        if (e.type === "pointercancel") return;
        if (!e.isPrimary) return;

        const point = this.snap.snap(pointer.canvas, pointer.canvas);

        this.movePoint(e, point);
        this.startDrawing(e);
        this.endDrawing(e, point);
    }

    private movePoint(e: React.PointerEvent, point: TPoint) {
        if (e.type === "pointermove" || e.type === "pointerdown") {
            switch (this.flow.stage) {
                case 1:
                    this.flow.center = point;
                    break;
                case 2:
                    this.flow.radiusPoint = point;
                    break;
            }
        }
    }

    private startDrawing(e: React.PointerEvent) {
        if (e.type === "pointerdown") {
            this.flow.drawing = true;
        }
    }

    private endDrawing(e: React.PointerEvent, point: TPoint) {
        if (e.type === "pointerup") {
            switch (this.flow.stage) {
                case 1: {
                    this.flow = {
                        stage: 2,
                        center: this.flow.center || point,
                        drawing: false,
                    };
                    break;
                }
                case 2: {
                    const { center, radiusPoint = point } = this.flow;
                    const radiusVector = subtractVectors(center, radiusPoint);
                    const rx = Math.abs(radiusVector.x);
                    const ry = Math.abs(radiusVector.y);
                    if (compareFloats(rx, 0) === 0 || compareFloats(ry, 0) === 0) {
                        this.nextFlow();
                        return;
                    }

                    this.figure.insertModels(function* () {
                        yield EllipseModel.create({ center, rx, ry, style: null });
                        yield PointModel.create({ x: center.x, y: center.y, style: null });
                        yield LabelPointModel.createNext(center, this.figure);
                    }, this);

                    this.nextFlow();
                }
            }
        }
    }

    private render = createTransformer(createMapModelToElements(opacifyColor));

    protected renderElements(): BaseElement[] {
        return [...this.figure.models.flatMap(this.render), ...this.tempElements];
    }

    get tempElements(): BaseElement[] {
        const { stage, center, drawing } = this.flow;
        const { scale } = this.viewport;

        const color = drawing ? ElementColor.Selected : ElementColor.Building;
        const overrideRenderOrder = 0;

        if (stage === 1) {
            if (!center) return [];
            return [
                new DotElement({
                    id: "temp_center",
                    x: center.x,
                    y: center.y,
                    color,
                    overrideRenderOrder,
                }),
                new EllipseElement({
                    id: "temp_ellipse",
                    center,
                    rx: 0.2 / scale,
                    ry: 0.1 / scale,
                    overrideRenderOrder,
                    color: ElementColor.Util,
                }),
            ];
        }

        if (stage === 2) {
            const { radiusPoint } = this.flow;

            if (!radiusPoint || areSamePoints(center, radiusPoint)) {
                return [
                    new DotElement({
                        id: "temp_center",
                        x: center.x,
                        y: center.y,
                        color: ElementColor.Building,
                        overrideRenderOrder,
                    }),
                    new EllipseElement({
                        id: "temp_ellipse",
                        center,
                        rx: 0.2 / scale,
                        ry: 0.1 / scale,
                        overrideRenderOrder,
                        color: ElementColor.Util,
                    }),
                ];
            }

            const radiusVector = subtractVectors(center, radiusPoint);
            const rx = Math.abs(radiusVector.x);
            const ry = Math.abs(radiusVector.y);

            return [
                new DotElement({
                    id: "temp_center",
                    x: center.x,
                    y: center.y,
                    color: ElementColor.Building,
                    overrideRenderOrder,
                }),
                new LineElement({
                    id: "temp_lineX",
                    x1: radiusPoint.x,
                    y1: radiusPoint.y,
                    x2: center.x - radiusVector.x,
                    y2: radiusPoint.y,
                    color: ElementColor.Util,
                    overrideRenderOrder: 1,
                    thin: true,
                }),
                new LineElement({
                    id: "temp_lineY",
                    x1: radiusPoint.x,
                    y1: radiusPoint.y,
                    x2: radiusPoint.x,
                    y2: center.y - radiusVector.y,
                    color: ElementColor.Util,
                    overrideRenderOrder: 1,
                    thin: true,
                }),
                new DotElement({
                    id: "temp_radiusPoint",
                    x: radiusPoint.x,
                    y: radiusPoint.y,
                    color,
                    overrideRenderOrder,
                }),
                new EllipseElement({
                    id: "temp_ellipse",
                    center,
                    rx,
                    ry,
                    overrideRenderOrder,
                    color,
                }),
            ];
        }

        return [];
    }

    static create(data: Figure2DController) {
        return new EllipseFlow(data);
    }

    override getToolbarMenu(): ToolbarMenu {
        return createDefaultToolbarMenuBuilder()
            .setButtonState(ButtonStates.active, ["circles"])
            .setButtonIcon("ellipse", ["circles"])
            .build();
    }

    override getTooltipMenu(): TooltipMenu | null {
        let text: string;

        switch (this.flow.stage) {
            case 1:
                text = "Выберите центр эллипса";
                break;
            case 2:
                text = "Выберите размер эллипса";
                break;
        }

        return new TooltipMenu(text);
    }
}
