// eslint-disable-next-line @typescript-eslint/no-var-requires
const CHAR_MAP = require("./slugCharMap.json");

export function generateSlug(text: string, spaceChar = "-"): string {
    text = text.toLocaleLowerCase();

    const slug: string[] = [];

    for (const char of text) {
        if (char in CHAR_MAP) {
            slug.push(CHAR_MAP[char]);
        } else if (slug[slug.length - 1] === spaceChar) {
            continue;
        } else {
            slug.push(spaceChar);
        }
    }

    if (slug[0] === spaceChar) slug.splice(0, 1);
    if (slug[slug.length - 1] === spaceChar) slug.splice(slug.length - 1, 1);

    return slug.join("");
}

export function validateSlug(slug: string | null): string | undefined {
    if (!slug) return "Поле обязательно для заполнения";

    if (/^-/g.test(slug)) {
        return "Не должно начинаться с '-'";
    }

    if (/-$/g.test(slug)) {
        return "Не должно заканчиваться на '-'";
    }

    if (/-{2,}/g.test(slug)) {
        return "2 или более '-' подряд";
    }

    if (slug !== slug.toLocaleLowerCase()) {
        return "Буквы должны быть в нижнем регистре";
    }

    if (/[^a-z-\d]+/g.test(slug)) {
        return "Допускаются только буквы (en,ru), цифры (0-9) и дефис (-)";
    }

    if (!/^[a-z0-9]+([-][a-z0-9]+)*$/g.test(slug)) {
        return "Неверный формат";
    }

    return undefined;
}
