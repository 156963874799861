import { action, makeObservable, observable } from "mobx";

import {
    areSameEllipses,
    areSamePoints,
    areSameVectors,
    isPointInBounds,
    isPointOnLine,
} from "@viuch/geometry-lib/check-geometry";
import { getPointHash } from "@viuch/geometry-lib/hashing";
import { getClosestPoints } from "@viuch/geometry-lib/solvers";
import { filterEqualObjects } from "@viuch/shared/utils/data/filter";
import { assert } from "@viuch/utils/debug";

import type { BaseSelection } from "./BaseSelection";
import type { Figure2DController } from "../Figure2DController";
import type { TFragment, TEllipse, TLine, TPoint } from "@viuch/geometry-lib/types";

import { getAllModelPointsVisitor } from "../utils/visible-points";

import { EllipseSelection, FragmentSelection, PointSelection, VectorSelection } from "./items";

export class SelectionController {
    private readonly data: Figure2DController;
    elements: BaseSelection[];

    constructor(data: Figure2DController) {
        this.data = data;
        this.elements = [];

        makeObservable(this, {
            elements: observable.shallow,
            clear: action.bound,
            handleLineClick: action.bound,
        });
    }

    toggleDot(point: TPoint) {
        const pointIndex = this.findDotIndex(point);

        if (pointIndex === -1) {
            this.addDot(point);
        } else {
            this.removeDot(point, pointIndex);
        }
    }

    private addDot(point: TPoint) {
        this.elements.push(new PointSelection(point.x, point.y));
    }

    private removeDot(point: TPoint, knownIndex?: number) {
        const index = knownIndex ?? this.findDotIndex(point);

        if (index !== -1) {
            this.elements.splice(index, 1);
        }
    }

    private findDotIndex(point: TPoint): number {
        return this.elements.findIndex((element) =>
            element.accept<boolean>({
                withPoint: (element) => areSamePoints(element, point),
                withFragment: () => false,
                withEllipse: () => false,
                withVector: () => false,
            })
        );
    }

    toggleLine(a: TPoint, b: TPoint) {
        const lineIndex = this.findLineIndex(a, b);

        if (lineIndex === -1) {
            this.addLine(a, b);
        } else {
            this.removeLine(a, b, lineIndex);
        }
    }

    private addLine(a: TPoint, b: TPoint) {
        this.elements.push(new FragmentSelection(a, b));
    }

    private removeLine(a: TPoint, b: TPoint, lineIndex?: number) {
        const index = lineIndex !== void 0 ? lineIndex : this.findLineIndex(a, b);
        this.elements.splice(index, 1);
    }

    private findLineIndex(a: TPoint, b: TPoint) {
        return this.elements.findIndex((element) =>
            element.accept<boolean>({
                withFragment: (fragment) =>
                    areSamePoints(fragment.a, a)
                        ? areSamePoints(fragment.b, b)
                        : areSamePoints(fragment.a, b)
                        ? areSamePoints(fragment.b, a)
                        : false,
                withPoint: () => false,
                withEllipse: () => false,
                withVector: () => false,
            })
        );
    }

    clear() {
        this.elements = [];
    }

    isEmpty() {
        return !this.elements.length;
    }

    private get figure() {
        return this.data.figure;
    }

    handleLineClick(line: TLine, pointer: TPoint) {
        const points = Array.from(filterEqualObjects(this.findAllLinePoints(line), getPointHash));

        const [p1, p2] = getClosestPoints(points, pointer);

        assert(!areSamePoints(p1, p2), { pointer, points, line, p1, p2 });

        this.toggleLine(p1, p2);
    }

    private *findAllLinePoints(line: TLine): Iterable<TPoint> {
        for (const model of this.figure.models) {
            const modelPoints = model.accept<TPoint[]>(getAllModelPointsVisitor);

            yield* modelPoints.filter((point) => isPointOnLine(line, point) && isPointInBounds(line, point));
        }
    }

    toggleEllipse(ellipse: TEllipse) {
        const i = this.findEllipseIndex(ellipse);

        if (i === -1) {
            this.addEllipse(ellipse);
        } else {
            this.removeEllipse(ellipse, i);
        }
    }

    private addEllipse({ center, rx, ry }: TEllipse): void {
        this.elements.push(new EllipseSelection(center, rx, ry));
    }

    private findEllipseIndex(ellipse: TEllipse): number | -1 {
        return this.elements.findIndex((element) =>
            element.accept<boolean>({
                withPoint: () => false,
                withFragment: () => false,
                withEllipse: (ellipseSelection) => areSameEllipses(ellipseSelection, ellipse),
                withVector: () => false,
            })
        );
    }

    private removeEllipse(ellipse: TEllipse, knownIndex: number) {
        const index = knownIndex ?? this.findEllipseIndex(ellipse);

        if (index !== -1) {
            this.elements.splice(index, 1);
        }
    }

    toggleVector(vector: TFragment): void {
        const vectorIndex = this.findVectorIndex(vector);

        if (vectorIndex !== -1) {
            this.elements.push(new VectorSelection(vector.a, vector.b));
        } else {
            this.elements.splice(vectorIndex, 1);
        }
    }

    private findVectorIndex(vector: TFragment): number | -1 {
        return this.elements.findIndex((element) =>
            element.accept<boolean>({
                withVector: (element) => areSameVectors(element.toFragment(), vector),
                withPoint: () => false,
                withFragment: () => false,
                withEllipse: () => false,
            })
        );
    }
}
