import type { TCursorMoveDirection, TRemoveMethod } from "./IStrategy";
import type { IAffiliationAction } from "../../actions/affiliation";
import type { TBracketAction } from "../../actions/bracket";
import type { TCharAction } from "../../actions/char";
import type { TClipboardAction } from "../../actions/clipboard";
import type { TCursorAction } from "../../actions/cursor";
import type { TDegreeAction } from "../../actions/degree";
import type { TDifferentialAction } from "../../actions/differential";
import type { TDigitAction } from "../../actions/digit";
import type { TEqualityAction } from "../../actions/equality";
import type { TEquationsSetAction } from "../../actions/equations-set";
import type { TFunctionAction } from "../../actions/function";
import type { TGeometryAction } from "../../actions/geometry";
import type { THistoryAction } from "../../actions/history";
import type { TIntegralAction } from "../../actions/integral";
import type { TLinebreakAction } from "../../actions/linebreak";
import type { TLogAction } from "../../actions/log";
import type { TMultitudeAction } from "../../actions/multitude";
import type { THighPriorityOperatorAction } from "../../actions/operator-high";
import type { TLowPriorityOperatorAction } from "../../actions/operator-low";
import type { TRemoveAction } from "../../actions/remove";
import type { TRootAction } from "../../actions/root";
import type { TSymbolAction } from "../../actions/symbol";
import type { TTrigonometricOperatorAction } from "../../actions/trigonometric-operator";
import type { TInsertInsteadOfSelectedSupportsBracketsWrappingCommandOptions } from "../../commands/types";
import type { TAnyAction, TAnySerializedElement } from "../../types";
import type { ContainerModel } from "../container";
import type { BaseElementModel } from "../element";

import { isAffiliationAction } from "../../actions/affiliation";
import { isAngleAction } from "../../actions/angle";
import { isBracketAction } from "../../actions/bracket";
import { isCharAction } from "../../actions/char";
import { isClipboardAction } from "../../actions/clipboard";
import { isComplexIntegralAction } from "../../actions/complex-integral";
import { isCoordinatesVectorAction } from "../../actions/coordinates-vector";
import { isCursorMoveAction } from "../../actions/cursor";
import { isDegreeAction } from "../../actions/degree";
import { isDerivativeAction } from "../../actions/derivative";
import { isDifferentialAction } from "../../actions/differential";
import { isDigitAction } from "../../actions/digit";
import { isDownIndexAction } from "../../actions/down-index";
import { isEqualityAction } from "../../actions/equality";
import { isEquationsSetAction } from "../../actions/equations-set";
import { isFractionAction } from "../../actions/fraction";
import { isFunctionAction } from "../../actions/function";
import { isGeometryAction } from "../../actions/geometry";
import { isGradeAction } from "../../actions/grade";
import { isHistoryAction } from "../../actions/history";
import { isIntegralAction } from "../../actions/integral";
import { isLimAction } from "../../actions/lim";
import { isLinebreakAction } from "../../actions/linebreak";
import { isLogAction } from "../../actions/log";
import { isModuleAction } from "../../actions/module";
import { isMultitudeAction } from "../../actions/multitude";
import { isNaturalLogAction } from "../../actions/natural-log";
import { isHighPriorityAction } from "../../actions/operator-high";
import { isLowPriorityAction } from "../../actions/operator-low";
import { isRemoveAction } from "../../actions/remove";
import { isResultAction } from "../../actions/result";
import { isRootAction } from "../../actions/root";
import { isSlashAction } from "../../actions/slash";
import { isSpaceAction } from "../../actions/space";
import { isSymbolAction } from "../../actions/symbol";
import { isTopAngleAction } from "../../actions/top-angle";
import { isTrigonometricOperatorAction } from "../../actions/trigonometric-operator";
import { isVectorAction } from "../../actions/vector";
import { BackspaceCommand } from "../../commands/BackspaceCommand";
import { ClipboardPasteCommand } from "../../commands/ClipboardPasteCommand";
import { DeleteCommand } from "../../commands/DeleteCommand";
import { CommandInitError } from "../../commands/errors/CommandInitError";
import { InsertInsteadOfSelectedCommand } from "../../commands/InsertInsteadOfSelectedCommand";
import { InsertInsteadOfSelectedSupportsBracketsWrappingCommand } from "../../commands/InsertInsteadOfSelectedSupportsBracketsWrappingCommand";
import { InsertIntoCursorCommand } from "../../commands/InsertIntoCursorCommand";
import { RemoveSelectedCommand } from "../../commands/RemoveSelectedCommand";
import { createSerializedAffiliation } from "../../elements/affiliation/utils";
import { createSerializedAngle } from "../../elements/angle/utils";
import { createSerializedBracket } from "../../elements/bracket/utils";
import { createSerializedChar } from "../../elements/char/utils";
import { createSerializedComplexIntegral } from "../../elements/complex-integral/utils";
import { createSerializedCoordinatesVector } from "../../elements/coordinates-vector/utils";
import { createSerializedDegree } from "../../elements/degree/utils";
import { createSerializedDerivative } from "../../elements/derivative/utils";
import { createSerializedDifferential } from "../../elements/differential/utils";
import { createSerializedDigit } from "../../elements/digit/utils";
import { createSerializedDownIndex } from "../../elements/down-index/utils";
import { createSerializedEquality } from "../../elements/equality/utils";
import { WrapSelectedIntoEquationsSetCommand } from "../../elements/equations-set/commands/WrapSelectedIntoEquationsSetCommand";
import { createSerializedEquationsSet } from "../../elements/equations-set/utils";
import { createSerializedFraction } from "../../elements/fraction/utils";
import { createSerializedFunction } from "../../elements/function/utils";
import { createSerializedGeometry } from "../../elements/geometry/utils";
import { createSerializedGrade } from "../../elements/grade/utils";
import { createSerializedIntegral } from "../../elements/integral/utils";
import { createSerializedLim } from "../../elements/lim/utils";
import { createSerializedLinebreak } from "../../elements/linebreak/utils";
import { createSerializedLog } from "../../elements/log/utils";
import { createSerializedModule } from "../../elements/module/utils";
import { createSerializedMultitude } from "../../elements/multitude/utils";
import { createSerializedNaturalLog } from "../../elements/natural-log/utils";
import { createSerializedOperatorHigh } from "../../elements/operator-high/utils";
import { createSerializedOperatorLow } from "../../elements/operator-low/utils";
import { createSerializedResult } from "../../elements/result/utils";
import { createSerializedRoot } from "../../elements/root/utils";
import { createSerializedSlash } from "../../elements/slash/utils";
import { createSerializedSpace } from "../../elements/space/utils";
import { createSerializedSymbol } from "../../elements/symbol/utils";
import { createSerializedTopAngle } from "../../elements/top-angle/utils";
import { createSerializedTrigonometricOperator } from "../../elements/trigonometric-operator/utils";
import { createSerializedVector } from "../../elements/vector/utils";

import { BaseStrategy } from "./BaseStrategy";

export abstract class DefaultStrategy<M extends BaseElementModel> extends BaseStrategy<M> {
    protected constructor(model: M) {
        super(model);
    }

    handleCursorMoveOver(direction: TCursorMoveDirection): void {
        if (direction === "ltr") {
            this.cursor.moveRight();
        }
        if (direction === "rtl") {
            this.cursor.moveLeft();
        }
    }

    handleRemoveThis(method: TRemoveMethod): void {
        if (method === "backspace") {
            this.commands.perform(
                this.selection.isSelectedSomething
                    ? new RemoveSelectedCommand(this.model)
                    : new BackspaceCommand(this.model)
            );
        }
        if (method === "delete") {
            this.commands.perform(
                this.selection.isSelectedSomething
                    ? new RemoveSelectedCommand(this.model)
                    : new DeleteCommand(this.model)
            );
        }
    }

    handleInserted(): void {
        this.cursor.moveRight();
    }

    handleAction(action: TAnyAction): void {
        try {
            if (isCharAction(action)) {
                return this.handleCharAction(action);
            }
            if (isDigitAction(action)) {
                return this.handleDigitAction(action);
            }
            if (isHistoryAction(action)) {
                return this.handleHistoryAction(action);
            }
            if (isRemoveAction(action)) {
                return this.handleRemoveAction(action);
            }
            if (isCursorMoveAction(action)) {
                return this.handleCursorMoveAction(action);
            }
            if (isAffiliationAction(action)) {
                return this.handleAffiliationAction(action);
            }
            if (isBracketAction(action)) {
                return this.handleBracketAction(action);
            }
            if (isComplexIntegralAction(action)) {
                return this.handleComplexIntegralAction();
            }
            if (isCoordinatesVectorAction(action)) {
                return this.handleCoordinatesVectorAction();
            }
            if (isDegreeAction(action)) {
                return this.handleDegreeAction(action);
            }
            if (isDerivativeAction(action)) {
                return this.handleDerivativeAction();
            }
            if (isDifferentialAction(action)) {
                return this.handleDifferentialAction(action);
            }
            if (isDownIndexAction(action)) {
                return this.handleDownIndexAction();
            }
            if (isEqualityAction(action)) {
                return this.handleEqualityAction(action);
            }
            if (isEquationsSetAction(action)) {
                return this.handleEquationsSetAction(action);
            }
            if (isFractionAction(action)) {
                return this.handleFractionAction();
            }
            if (isFunctionAction(action)) {
                return this.handleFunctionAction(action);
            }
            if (isGeometryAction(action)) {
                return this.handleGeometryAction(action);
            }
            if (isGradeAction(action)) {
                return this.handleGradeAction();
            }
            if (isIntegralAction(action)) {
                return this.handleIntegralAction(action);
            }
            if (isLimAction(action)) {
                return this.handleLimAction();
            }
            if (isLinebreakAction(action)) {
                return this.handleLinebreakAction(action);
            }
            if (isLogAction(action)) {
                return this.handleLogAction(action);
            }
            if (isModuleAction(action)) {
                return this.handleModuleAction();
            }
            if (isMultitudeAction(action)) {
                return this.handleMultitudeAction(action);
            }
            if (isNaturalLogAction(action)) {
                return this.handleNaturalLogAction();
            }
            if (isHighPriorityAction(action)) {
                return this.handleOperatorHighAction(action);
            }
            if (isLowPriorityAction(action)) {
                return this.handleOperatorLowAction(action);
            }
            if (isResultAction(action)) {
                return this.handleResultAction();
            }
            if (isRootAction(action)) {
                return this.handleRootAction(action);
            }
            if (isSlashAction(action)) {
                return this.handleSlashAction();
            }
            if (isSpaceAction(action)) {
                return this.handleSpaceAction();
            }
            if (isSymbolAction(action)) {
                return this.handleSymbolAction(action);
            }
            if (isAngleAction(action)) {
                return this.handleSymbolAngleAction();
            }
            if (isTopAngleAction(action)) {
                return this.handleTopAngleAction();
            }
            if (isTrigonometricOperatorAction(action)) {
                return this.handleTrigonometricOperatorAction(action);
            }
            if (isVectorAction(action)) {
                return this.handleVectorAction();
            }
            if (isClipboardAction(action)) {
                return this.handleClipboardAction(action);
            }
        } catch (e: unknown) {
            if (CommandInitError.Is(e)) {
                if (!e.isCritical) {
                    return;
                }
            }
            throw e;
        }
    }

    protected handleCharAction({ char }: TCharAction): void {
        this.performInsertCommand(createSerializedChar(char));
    }

    protected handleDigitAction({ digit }: TDigitAction): void {
        this.performInsertCommand(createSerializedDigit(digit));
    }

    protected handleAffiliationAction({ char }: IAffiliationAction): void {
        this.performInsertCommand(createSerializedAffiliation(char));
    }

    protected handleBracketAction({ bracket, closing }: TBracketAction): void {
        this.performInsertCommand(createSerializedBracket(bracket, Boolean(closing)));
    }

    protected handleComplexIntegralAction(): void {
        this.performInsertCommand(createSerializedComplexIntegral());
    }

    protected handleCoordinatesVectorAction(): void {
        this.performInsertCommand(createSerializedCoordinatesVector());
    }

    protected handleDegreeAction({ power }: TDegreeAction) {
        const content = this.serializeString(String(power ?? ""));
        this.performInsertElementSupportsBracketsWrapping(createSerializedDegree(content), {
            showBracketsOnlyIfPolynomial: true,
        });
    }

    protected handleDerivativeAction(): void {
        const serializedElement = createSerializedDerivative();
        this.performInsertElementSupportsBracketsWrapping(serializedElement);
    }

    protected handleDifferentialAction({ content }: TDifferentialAction): void {
        const elements = this.serializeString(content ?? "");
        this.performInsertCommand(createSerializedDifferential(elements));
    }

    protected handleDownIndexAction(): void {
        this.performInsertElementSupportsBracketsWrapping(createSerializedDownIndex(), {
            showBracketsOnlyIfPolynomial: true,
        });
    }

    protected handleEqualityAction({ char }: TEqualityAction): void {
        this.performInsertCommand(createSerializedEquality(char));
    }

    protected handleEquationsSetAction({ variant }: TEquationsSetAction): void {
        const serializedEquationsSet = createSerializedEquationsSet(variant);

        this.commands.perform(
            this.selection.isSelectedSomething
                ? new WrapSelectedIntoEquationsSetCommand(this.model, serializedEquationsSet)
                : new InsertIntoCursorCommand(this.model, serializedEquationsSet)
        );
    }

    protected handleFractionAction(): void {
        this.performInsertCommand(createSerializedFraction());
    }

    protected handleFunctionAction({ filled }: TFunctionAction): void {
        this.performInsertCommand(
            createSerializedFunction(this.serializeString(filled ? "f" : ""), this.serializeString(filled ? "x" : ""))
        );
    }

    protected handleGeometryAction({ char }: TGeometryAction): void {
        this.performInsertCommand(createSerializedGeometry(char));
    }

    protected handleGradeAction(): void {
        this.performInsertElementSupportsBracketsWrapping(createSerializedGrade(), {
            showBracketsOnlyIfPolynomial: true,
        });
    }

    protected handleIntegralAction({ arg }: TIntegralAction): void {
        this.performInsertCommand(createSerializedIntegral(this.serializeString(arg ?? "")));
    }

    protected handleLimAction(): void {
        this.performInsertCommand(createSerializedLim());
    }

    protected handleLinebreakAction({ direction }: TLinebreakAction): void {
        this.performInsertCommand(createSerializedLinebreak());
    }

    protected handleLogAction({ index }: TLogAction): void {
        const content = this.serializeString(String(index ?? ""));
        this.performInsertCommand(createSerializedLog(content));
    }

    protected handleModuleAction(): void {
        this.performInsertCommand(createSerializedModule());
    }

    protected handleMultitudeAction({ char }: TMultitudeAction): void {
        this.performInsertCommand(createSerializedMultitude(char));
    }

    protected handleNaturalLogAction(): void {
        this.performInsertCommand(createSerializedNaturalLog());
    }

    protected handleOperatorHighAction({ sign }: THighPriorityOperatorAction): void {
        this.performInsertCommand(createSerializedOperatorHigh(sign));
    }

    protected handleOperatorLowAction({ sign }: TLowPriorityOperatorAction): void {
        this.performInsertCommand(createSerializedOperatorLow(sign));
    }

    protected handleResultAction(): void {
        this.performInsertCommand(createSerializedResult());
    }

    protected handleRootAction({ squared, index }: TRootAction): void {
        const content = this.serializeString(String(index ?? ""));
        this.performInsertCommand(createSerializedRoot(Boolean(squared), content));
    }

    protected handleSlashAction(): void {
        this.performInsertCommand(createSerializedSlash());
    }

    protected handleSpaceAction(): void {
        this.performInsertCommand(createSerializedSpace());
    }

    protected handleSymbolAction({ char }: TSymbolAction): void {
        const serializedElement = createSerializedSymbol(char);

        switch (char) {
            case "factorial": {
                this.performInsertElementSupportsBracketsWrapping(serializedElement);
                break;
            }

            case "delta": {
                this.performInsertElementSupportsBracketsWrapping(serializedElement, { insertElementIn: "before" });
                break;
            }

            default: {
                this.performInsertCommand(serializedElement);
            }
        }
    }

    protected handleSymbolAngleAction(): void {
        const serializedElement = createSerializedAngle();

        this.performInsertElementSupportsBracketsWrapping(serializedElement, {
            insertElementIn: "before",
        });
    }

    protected handleTopAngleAction(): void {
        this.performInsertCommand(createSerializedTopAngle());
    }

    protected handleTrigonometricOperatorAction({ operator }: TTrigonometricOperatorAction): void {
        this.performInsertCommand(createSerializedTrigonometricOperator(operator));
    }

    protected handleVectorAction(): void {
        this.performInsertCommand(createSerializedVector());
    }

    protected performInsertCommand<T extends TAnySerializedElement>(element: T): void {
        const command = this.selection.isSelectedSomething
            ? new InsertInsteadOfSelectedCommand(this.model, element)
            : new InsertIntoCursorCommand(this.model, element);

        this.commands.perform(command);
    }

    protected performInsertElementSupportsBracketsWrapping<T extends TAnySerializedElement>(
        element: T,
        options?: TInsertInsteadOfSelectedSupportsBracketsWrappingCommandOptions
    ): void {
        const command = this.selection.isSelectedSomething
            ? new InsertInsteadOfSelectedSupportsBracketsWrappingCommand(this.model, element, options)
            : new InsertIntoCursorCommand(this.model, element);

        this.commands.perform(command);
    }

    protected serializeString(str: string): TAnySerializedElement[] {
        return str.split("").map((char) => {
            if (/^\d$/.test(char)) {
                return createSerializedDigit(Number(char));
            }
            return createSerializedChar(char);
        });
    }

    protected handleHistoryAction({ action }: THistoryAction): void {
        if (action === "undo") {
            this.commands.undo();
            return;
        }

        if (action === "redo") {
            this.commands.redo();
        }
    }

    protected handleRemoveOutFirst(): void {}

    protected handleRemoveOutLast(): void {}

    protected handleRemoveAction({ direction }: TRemoveAction): void {
        if (direction === "backward") {
            if (this.selection.isSelectedSomething) {
                this.commands.perform(new RemoveSelectedCommand(this.model));
            } else if (this.cursor.isInStart) {
                this.handleRemoveOutFirst();
            } else {
                const element = this.cursor.getElementBefore();
                if (element) {
                    element.behaviour.handleRemoveThis("backspace");
                }
            }
            return;
        }

        if (direction === "forward") {
            if (this.selection.isSelectedSomething) {
                this.commands.perform(new RemoveSelectedCommand(this.model));
            } else if (this.cursor.isInEnd) {
                this.handleRemoveOutLast();
            } else {
                const element = this.cursor.getElementAfter();
                if (element) {
                    element.behaviour.handleRemoveThis("delete");
                }
            }
        }
    }

    protected handleCursorOutLeft(): void {}

    protected handleCursorOutRight(): void {}

    protected handleCursorMoveAction({ direction }: TCursorAction) {
        if (this.selection.isSelectedSomething) {
            const isLeft = direction === "left" || direction === "up";
            const selectedElement = isLeft ? this.selection.firstSelectedElement : this.selection.lastSelectedElement;

            if (selectedElement) {
                isLeft ? this.cursor.setBeforeElement(selectedElement) : this.cursor.setAfterElement(selectedElement);
            }
            this.selection.clearSelection();
            return;
        }

        if (direction === "left") {
            if (this.cursor.isInStart) {
                this.handleCursorOutLeft();
            } else {
                const element = this.cursor.getElementBefore();
                if (element) {
                    element.behaviour.handleCursorMoveOver("rtl");
                }
            }
            return;
        }

        if (direction === "right") {
            if (this.cursor.isInEnd) {
                this.handleCursorOutRight();
            } else {
                const element = this.cursor.getElementAfter();
                if (element) {
                    element.behaviour.handleCursorMoveOver("ltr");
                }
            }
            return;
        }

        if (direction === "up") {
            this.cursor.moveUp();
        }

        if (direction === "down") {
            this.cursor.moveDown();
        }
    }

    protected handleClipboardAction({ action }: TClipboardAction): void {
        if (action === "paste") {
            const command = new ClipboardPasteCommand(this.model);
            this.commands.perform(command);
        }
        if (this.selection.isSelectedSomething) {
            if (action === "copy") {
                this.inputService.clipboard.copySelected();
                return;
            }

            if (action === "cut") {
                this.inputService.clipboard.copySelected();
                const command = new RemoveSelectedCommand(this.model);
                this.commands.perform(command);
            }
        }
    }

    abstract override checkCanBeInserted(element: BaseElementModel, container: ContainerModel<M>): boolean;
}
