import type { ISerializedFraction } from "./types";
import type { TSerializedElementPrototype } from "../../core/element";
import type { IElementVisitor } from "../../core/element/IElementVisitor";
import type { IStrategy } from "../../core/strategies";
import type { IMultiContainerElement } from "../../core/strategies/types";
import type { InputService } from "../../services";
import type { TContainerElements, TElementDeserializerFunction } from "../../types";

import { ContainerModel } from "../../core/container";
import { BaseElementModel } from "../../core/element";
import { MultiContainerElementStrategy } from "../../core/strategies/MultiContainerElementStrategy";

export class FractionModel extends BaseElementModel<ISerializedFraction> implements IMultiContainerElement {
    public numerator: ContainerModel;
    public denominator: ContainerModel;

    public constructor(
        inputService: InputService,
        numerator: TContainerElements,
        denominator: TContainerElements,
        uuid?: string
    ) {
        super(inputService, uuid);

        this.numerator = new ContainerModel(numerator, this, ["numerator"], this.inputService, {
            showPlaceholderIfEmpty: true,
        });
        this.denominator = new ContainerModel(denominator, this, ["denominator"], this.inputService, {
            showPlaceholderIfEmpty: true,
        });
    }

    protected override initBehaviour(): IStrategy {
        return new MultiContainerElementStrategy(this);
    }

    public serialize(): ISerializedFraction {
        return {
            type: "fraction",
            uuid: this.uuid,
            numerator: this.numerator.serialize(),
            denominator: this.denominator.serialize(),
        };
    }

    public static deserialize: TElementDeserializerFunction<ISerializedFraction> = (
        { inputService },
        { uuid, numerator, denominator }
    ) =>
        new FractionModel(
            inputService,
            inputService.deserializeContainer(numerator),
            inputService.deserializeContainer(denominator),
            uuid
        );

    getContainersToMoveCursorBetween(): ContainerModel[] {
        return [this.numerator, this.denominator];
    }

    public accept<R>(visitor: IElementVisitor<R>): R {
        return visitor.doWithFraction(this);
    }

    public serializeAsClone(): TSerializedElementPrototype<ISerializedFraction> {
        const { uuid, ...clone } = this.serialize();
        return {
            ...clone,
            numerator: this.numerator.serializeAsClone(),
            denominator: this.denominator.serializeAsClone(),
        };
    }

    override getWrapperContainer(): ContainerModel | null {
        return this.numerator;
    }
}
