import { computed, makeObservable, observable } from "mobx";
import { createTransformer } from "mobx-utils";

import { areSamePoints, isPointOnFragment } from "@viuch/geometry-lib/check-geometry";
import { filterSameFragments } from "@viuch/geometry-lib/filters";
import { middlePoint } from "@viuch/geometry-lib/vectors";

import type { TAvailableMedian } from "./utils";
import type { BaseElement } from "../../elements";
import type { Figure2DController } from "../../Figure2DController";
import type { ToolbarMenu } from "../../toolbar";
import type { IUserPointer } from "../../viewport/types";
import type { TPoint } from "@viuch/geometry-lib/types";
import type React from "react";

import { DotElement } from "../../elements/dot";
import { ElementColor } from "../../elements/ElementColor";
import { LineElement } from "../../elements/line";
import { FragmentModel } from "../../models/fragment";
import { MedianModel } from "../../models/geometry";
import { LabelPointModel } from "../../models/label-point";
import { PointModel } from "../../models/point";
import { ButtonStates } from "../../toolbar";
import { TooltipMenu } from "../../toolbar/tooltip";
import { getNewEqualSegmentsStrokesCount } from "../../utils/strokes-count";
import { createDefaultToolbarMenuBuilder } from "../../utils/toolbar";
import { findAllViewFragments } from "../../utils/visible-fragments";
import { findAllViewPoints } from "../../utils/visible-points";
import { BaseFlow } from "../BaseFlow";
import { createMapModelToElements, opacifyColor } from "../utils";

import { arrangeDotsToFragments, mixPointsToMakeFragments } from "./utils";

export class InteractiveMedianFlow extends BaseFlow {
    flow:
        | {
              stage: 1;
              vertex?: TPoint;
              drawing: boolean;
          }
        | {
              stage: 2;
              vertex: TPoint;
              readonly availableMedians: TAvailableMedian[];
              target?: TPoint;
              drawing: boolean;
          };

    constructor(data: Figure2DController) {
        super(data);

        this.flow = { stage: 1, drawing: false };

        makeObservable(this, {
            flow: observable,
            modelElements: computed,
        });
    }

    attach(): void {
        this.snap.clean().addFigureModelsPoints();
        this.viewport.disable();
    }

    dispose(): void {
        this.snap.clean();
    }

    override handleViewportPointerEvent(e: React.PointerEvent, pointer: IUserPointer): void {
        if (e.type === "pointercancel") return this.nextFlow();
        if (!e.isPrimary) return;
        const point = this.snap.snap(pointer.canvas);

        this.movePoint(e, point);
        this.startDrawing(e);
        this.endDrawing(e);
    }

    private findMedian(target: TPoint, availableMedians: TAvailableMedian[]): TAvailableMedian | null {
        return availableMedians.find(({ medianPoint }) => areSamePoints(target, medianPoint)) || null;
    }

    private checkAndSave() {
        if (this.flow.stage !== 2 || !this.flow.target) return;
        const { target, availableMedians } = this.flow;

        const median = this.findMedian(target, availableMedians);
        if (median) {
            this.saveMedian(median);
        }
    }

    private saveMedian({ base, vertex }: TAvailableMedian) {
        const segmentsCount = getNewEqualSegmentsStrokesCount(this.figure);
        const { a, b } = base;

        this.figure.insertModels(function* () {
            const model = MedianModel.create({
                vertex,
                fragment: base,
                segmentsCount,
                style: null,
            });
            yield model;

            yield LabelPointModel.createNext(model.midPoint, this.figure);

            for (const point of [vertex, a, b]) {
                yield PointModel.create({ ...point, style: null });
                yield LabelPointModel.createNext(point, this.figure);
            }
            yield FragmentModel.create({ ...base, style: null });
        }, this);
    }

    protected renderElements(): BaseElement[] {
        return [...this.modelElements, ...this.getTempElements()];
    }

    private renderModel = createTransformer(createMapModelToElements(opacifyColor));

    get modelElements(): BaseElement[] {
        return this.figure.models.flatMap(this.renderModel);
    }

    getTempElements(): BaseElement[] {
        const elements: BaseElement[] = [];

        if (this.flow.vertex) {
            const { vertex, stage } = this.flow;
            elements.push(
                new DotElement({
                    id: `temp_vertex`,
                    x: vertex.x,
                    y: vertex.y,
                    color: stage === 1 ? ElementColor.Building : ElementColor.Selected,
                    overrideRenderOrder: 0,
                })
            );

            if (stage === 2) {
                const { availableMedians } = this.flow;

                const { target } = this.flow;

                const overrideRenderOrder = 0;
                elements.push(
                    ...availableMedians.flatMap<BaseElement>(({ medianPoint }, i) => [
                        new DotElement({
                            id: `temp_${i}_medianPoint`,
                            x: medianPoint.x,
                            y: medianPoint.y,
                            overrideRenderOrder,
                            color: target ? ElementColor.Building.withOpacity(0.3) : ElementColor.Building,
                        }),
                    ])
                );

                const median = target ? this.findMedian(target, availableMedians) : null;
                if (median) {
                    const {
                        vertex,
                        base: { a: start, b: end },
                        medianPoint,
                    } = median;

                    const overrideRenderOrder = -1;
                    const color = ElementColor.Selected;

                    elements.push(
                        new LineElement({
                            id: `temp_current_angleLine_basis`,
                            x1: start.x,
                            y1: start.y,
                            x2: end.x,
                            y2: end.y,
                            color,
                            overrideRenderOrder,
                        }),
                        new LineElement({
                            id: `temp_current_median`,
                            x1: vertex.x,
                            y1: vertex.y,
                            x2: medianPoint.x,
                            y2: medianPoint.y,
                            color,
                            overrideRenderOrder,
                        }),
                        new DotElement({
                            id: `temp_current_medianPoint`,
                            x: medianPoint.x,
                            y: medianPoint.y,
                            color,
                            overrideRenderOrder,
                        }),
                        new DotElement({
                            id: `temp_current_a`,
                            x: start.x,
                            y: start.y,
                            color,
                            overrideRenderOrder,
                        }),
                        new DotElement({
                            id: `temp_current_b`,
                            x: end.x,
                            y: end.y,
                            color,
                            overrideRenderOrder,
                        })
                    );
                }
            }
        }

        return elements;
    }

    static create(data: Figure2DController) {
        return new InteractiveMedianFlow(data);
    }

    private movePoint(e: React.PointerEvent, point: TPoint | null) {
        if (e.type === "pointermove" || e.type === "pointerdown") {
            switch (this.flow.stage) {
                case 1:
                    this.flow.vertex = point || void 0;
                    break;
                case 2:
                    this.flow.target = point || void 0;
                    break;
            }
        }
    }

    private startDrawing(e: React.PointerEvent) {
        if (e.type === "pointerdown") {
            this.flow.drawing = true;
        }
    }

    private endDrawing(e: React.PointerEvent) {
        if (e.type === "pointerup") {
            this.flow.drawing = false;

            switch (this.flow.stage) {
                case 1: {
                    const vertex = this.flow.vertex;
                    if (vertex) {
                        const allViewFragments = findAllViewFragments(this.figure.models);
                        const allViewPoints = findAllViewPoints(this.figure.models);

                        const filterFragments = isPointOnFragment.getPointFilter(vertex, false);

                        const pointsOnFragments = arrangeDotsToFragments(
                            allViewFragments.filter(filterFragments),
                            allViewPoints
                        );

                        const medians = filterSameFragments(
                            pointsOnFragments.flatMap(({ points }) => mixPointsToMakeFragments(points))
                        ).map<TAvailableMedian>((median) => ({
                            base: median,
                            vertex,
                            medianPoint: middlePoint(median),
                        }));

                        this.flow = {
                            stage: 2,
                            vertex,
                            availableMedians: medians,
                            drawing: false,
                        };

                        this.snap.clean();
                        for (const { medianPoint } of medians) {
                            this.snap.addPoint(medianPoint);
                        }
                    }
                    break;
                }
                case 2: {
                    this.checkAndSave();
                    this.nextFlow();
                    break;
                }
            }
        }
    }

    override getToolbarMenu(): ToolbarMenu {
        return createDefaultToolbarMenuBuilder()
            .setButtonState(ButtonStates.active, ["fragments"])
            .setButtonIcon("median", ["fragments"])
            .build();
    }

    override getTooltipMenu(): TooltipMenu | null {
        let text: string;

        switch (this.flow.stage) {
            case 1:
                text = "Выберите вершину угла";
                break;
            case 2:
                text = "Выберите точку, на которую упадёт медиана";
                break;
        }

        return new TooltipMenu(text, []);
    }
}
