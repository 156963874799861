import { getClassOf } from "@viuch/shared/utils/runtime/classOf";
import { assert } from "@viuch/utils/debug";

import type { BaseGraphItem } from "../model/BaseGraphItem";
import type { BaseGraphViewItem } from "../rendering/BaseGraphViewItem";

export class Graph2DPreRenderingService {
    private readonly map: Map<object, TModelToRenderTransformer> & {
        set<T extends BaseGraphItem>(classType: ClassOf<T>, renderFunction: TModelToRenderTransformer<T>): void;
    };

    private readonly filtersList: TFilterCallback[];

    constructor() {
        this.map = new Map();
        this.filtersList = [];
    }

    registerTransformer<T extends BaseGraphItem>(type: ClassOf<T>, transformFunc: TModelToRenderTransformer<T>): this {
        assert(!this.map.has(type));

        this.map.set<T>(type, transformFunc);
        return this;
    }

    registerFilter(filterCallback: TFilterCallback) {
        this.filtersList.push(filterCallback);
    }

    transform(item: BaseGraphItem): BaseGraphViewItem[] {
        const classType = getClassOf(item);
        assert(classType);

        const renderFunction = this.map.get(classType);
        assert(renderFunction);

        return [...renderFunction(item)];
    }

    filter(items: BaseGraphViewItem[]): BaseGraphViewItem[] {
        for (const filter of this.filtersList) {
            items = [...filter(items)];
        }

        return items;
    }
}

export type TModelToRenderTransformer<in T extends BaseGraphItem = BaseGraphItem> = (
    model: T
) => Iterable<BaseGraphViewItem>;

export type TFilterCallback = (items: BaseGraphViewItem[]) => Iterable<BaseGraphViewItem>;
