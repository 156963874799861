import { generateUuid } from "@viuch/shared/utils/data";

import type { ISerializedLog } from "./types";
import type { TAnySerializedElement } from "../../types";

import { createSerializedContainer } from "../../utils/serialization";

export function createSerializedLog(
    index: TAnySerializedElement[],
    content: TAnySerializedElement[] = []
): ISerializedLog {
    return {
        type: "log",
        uuid: generateUuid(),
        base: createSerializedContainer(index),
        degreeContent: createSerializedContainer(),
        content: createSerializedContainer(content),
    };
}
