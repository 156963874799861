import { generateUuid } from "@viuch/shared/utils/data";

import type { ISerializedTopAngle } from "./types";

import { createSerializedContainer } from "../../utils/serialization";

export function createSerializedTopAngle(): ISerializedTopAngle {
    return {
        type: "top-angle",
        uuid: generateUuid(),
        content: createSerializedContainer(),
    };
}
