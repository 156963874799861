import { agent } from "../../agent";

export class RegenerateVariantsService {
    // Перегенерировать решения во всех вариантах задания
    async regenerateVariantSolutions(): Promise<void> {
        await agent.post("/themes_new/regenerate_variant_solutions/");
    }

    // Пересоздать варианты заданий во всех проверенных темах
    async dropAndRegenerateAllVariants(): Promise<void> {
        await agent.post("/themes_new/drop_and_regenerate_all_variants/");
    }

    async dropAndRegenerateVariantsInTheme(themeId: number): Promise<void> {
        await agent.post(`/themes_new/${themeId}/drop_and_regenerate/`);
    }
}
