import cn from "classnames";
import { observer } from "mobx-react-lite";

import type { PropsWithChildren } from "react";

import styles from "./Button.module.scss";

type Props = PropsWithChildren<{
    onClick?: VoidFunction;
    link?: boolean;
    href?: string;
    disabled?: boolean;
    className?: string;
    block?: boolean;
    title?: string;
    secondary?: boolean;
    classes?: {
        inner?: string;
        frame?: string;
    };
}>;

export const Button = observer(function Button({
    className,
    children,
    onClick,
    disabled,
    block,
    title,
    secondary,
    classes,
    href,
    link,
}: Props) {
    const _label = title || (typeof children === "string" ? String(children) : void 0);

    const Component = link ? "a" : "button";

    return (
        <Component
            className={cn(
                styles.button,
                block && styles.button__block,
                secondary && styles.button__secondary,
                className
            )}
            disabled={disabled}
            onClick={onClick}
            href={href}
            title={_label}
        >
            <div className={cn(styles.frame, classes?.frame)} />
            <div className={cn(styles.inner, classes?.inner)}>{children}</div>
        </Component>
    );
});
