import { generateUuid } from "@viuch/shared/utils/data";

import type { ISerializedLinebreak } from "./types";
import type { BaseElementModel } from "../../core/element";
import type { TAnySerializedElement } from "../../types";

import { getClassFromInstance } from "../../utils/data";

import { LinebreakModel } from "./LinebreakModel";

export function createSerializedLinebreak(): ISerializedLinebreak {
    return { type: "linebreak", uuid: generateUuid() };
}

export function isLinebreak(element: BaseElementModel): element is LinebreakModel {
    return getClassFromInstance(element) === LinebreakModel;
}

export function isSerializedLinebreak(element: TAnySerializedElement): element is ISerializedLinebreak {
    return element.type === "linebreak";
}
