import type { TAnyAction } from "../types";

export type TVectorAction = { type: "vector" };

export function createVectorAction(): TVectorAction {
    return { type: "vector" };
}

export function isVectorAction(action: TAnyAction): action is TVectorAction {
    return action.type === "vector";
}
