import { observer } from "mobx-react-lite";
import React, { useEffect, useLayoutEffect, useRef } from "react";

import { LineView } from "./LineView";

type Props = {
    x: string;
    y: string;
    color: string;
    width: number;
    length: number;
    direction: "right" | "up" | "top";
};

export const ArrowHeadView = observer(function ArrowHeadView({ x, y, color, direction, width, length }: Props) {
    const { ax, bx, ay, by } = (() => {
        switch (direction) {
            case "right":
                return { ax: -1, bx: -1, ay: -1, by: 1 };
            case "up":
            case "top":
                return { ax: -1, bx: 1, ay: -1, by: -1 };
        }
    })();

    const ref = useRef<SVGSVGElement>(null);

    useEffect(() => {
        requestAnimationFrame(() => {
            ref.current?.setAttribute("width", `1`);
        });
    }, []);

    useLayoutEffect(() => {
        requestAnimationFrame(() => {
            ref.current?.setAttribute("width", `1`);
        });
    });

    return (
        <svg
            data-info="arrow-head"
            width={1}
            height={1}
            x={x}
            y={y}
            ref={ref}
        >
            <LineView
                ax={0}
                ay={0}
                bx={ax * length}
                by={ay * -length}
                width={width}
                color={color}
            />
            <LineView
                ax={0}
                ay={0}
                bx={bx * length}
                by={by * -length}
                width={width}
                color={color}
            />
        </svg>
    );
});
