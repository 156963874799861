import { action, makeObservable, observable } from "mobx";

import { generateId } from "@viuch/shared/utils/data";

import type { ThemeWithDuration } from "./ThemeWithDuration";

import { arrayMove } from "../../utils/array";

export class QuarterShort implements QuarterShort.Init {
    readonly $$instanceId = generateId();
    @observable startDate;
    @observable endDate;
    @observable themes: ThemeWithDuration[];

    constructor(init: QuarterShort.Init) {
        this.startDate = init.startDate;
        this.endDate = init.endDate;
        this.themes = init.themes.slice();

        makeObservable(this);
    }

    @action.bound
    setStartDate(startDate: Date) {
        this.startDate = startDate;
    }

    @action.bound
    setEndDate(endDate: Date) {
        this.endDate = endDate;
    }

    @action.bound
    addTheme(theme: ThemeWithDuration) {
        this.themes.push(theme);
    }

    @action.bound
    removeThemeId(themeId: number) {
        const i = this.themes.findIndex((theme) => theme.id === themeId);
        if (i !== -1) {
            this.themes.splice(i, 1);
        }
    }

    @action.bound
    changeThemeOrder(oldIndex: number, targetIndex: number): void {
        if (targetIndex < 0 || targetIndex >= this.themes.length) {
            return;
        }

        this.themes = arrayMove(this.themes, oldIndex, targetIndex);
    }

    clone() {
        return new QuarterShort(this);
    }
}

export namespace QuarterShort {
    export type Init = {
        startDate: Date | null;
        endDate: Date | null;
        themes: ThemeWithDuration[];
    };
}
