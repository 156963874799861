import { action, makeObservable, observable } from "mobx";

import { generateUuid } from "@viuch/shared/utils/data";

import type { AngleElement } from "./angle";
import type { DotElement } from "./dot";
import type { EllipseElement } from "./ellipse";
import type { LabelAngleElement, LabelDotElement, LabelFragmentElement } from "./label-text";
import type { LineElement } from "./line";
import type { VectorElement } from "./line/VectorElement";
import type { StrokeElement } from "./stroke";

import { ElementColor } from "./ElementColor";

export type TElementData = {
    id: string;
    color?: ElementColor;
    overrideRenderOrder: number;
};

export abstract class BaseElement implements TElementData {
    readonly id: string;
    color: ElementColor;
    overrideRenderOrder: number;
    visible: boolean;

    protected constructor(data: TElementData) {
        this.id = data.id ?? generateUuid();
        this.color = data.color ?? ElementColor.Default;
        this.overrideRenderOrder = data.overrideRenderOrder;
        this.visible = true;

        makeObservable(this, {
            color: observable,
            visible: observable.ref,
            hide: action,
            show: action,
        });
    }

    abstract accept<R>(visitor: IElementVisitor<R>): R;

    hide() {
        this.visible = false;
    }

    show() {
        this.visible = true;
    }
}

export interface IElementVisitor<R> {
    withDot: (dot: DotElement) => R;
    withLine: (line: LineElement) => R;
    withAngle: (angle: AngleElement) => R;
    withLabelDot: (label: LabelDotElement) => R;
    withLabelAngle: (label: LabelAngleElement) => R;
    withLabelFragment: (label: LabelFragmentElement) => R;
    withStroke: (stroke: StrokeElement) => R;
    withEllipse: (ellipse: EllipseElement) => R;
    withVector: (vector: VectorElement) => R;
}

export enum ElementTypes {
    dot,
    line,
    angle,
    labelDot,
    labelAngle,
    labelFragment,
    stroke,
    ellipse,
    vector,
}

type TElementTypes = {
    [ElementTypes.dot]: DotElement;
    [ElementTypes.line]: LineElement;
    [ElementTypes.angle]: AngleElement;
    [ElementTypes.labelDot]: LabelDotElement;
    [ElementTypes.labelAngle]: LabelAngleElement;
    [ElementTypes.labelFragment]: LabelFragmentElement;
    [ElementTypes.stroke]: StrokeElement;
    [ElementTypes.ellipse]: EllipseElement;
    [ElementTypes.vector]: VectorElement;
};

export const getElementTypeVisitor: IElementVisitor<ElementTypes> = {
    withDot: () => ElementTypes.dot,
    withLine: () => ElementTypes.line,
    withAngle: () => ElementTypes.angle,
    withLabelDot: () => ElementTypes.labelDot,
    withLabelAngle: () => ElementTypes.labelAngle,
    withLabelFragment: () => ElementTypes.labelFragment,
    withStroke: () => ElementTypes.stroke,
    withEllipse: () => ElementTypes.ellipse,
    withVector: () => ElementTypes.vector,
};

export function checkElementTypes<T extends ElementTypes>(element: BaseElement, type: T): element is TElementTypes[T] {
    return element.accept(getElementTypeVisitor) === type;
}
