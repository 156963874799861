import cn from "classnames";
import { useTranslation } from "react-i18next";

import type { LoginFormProps } from "./LoginForm.types";
import type { FC } from "react";

import styles from "./LoginForm.module.scss";

const LoginForm: FC<LoginFormProps> = (props) => {
    const { handleSubmit, register, serverErrors } = props;
    const { t } = useTranslation("auth");

    const usernameError = serverErrors?.username;
    const passwordError = serverErrors?.password;

    return (
        <form
            className={styles.container}
            onSubmit={handleSubmit}
        >
            <label className={cn(styles.label, Boolean(usernameError) && styles.hasError)}>
                {t("username")}
                <input {...register("username")} />
                {Boolean(usernameError) && <p className={styles.error}>{usernameError}</p>}
            </label>

            <label className={cn(styles.label, Boolean(passwordError) && styles.hasError)}>
                {t("password")}
                <input
                    type="password"
                    {...register("password")}
                />
                {Boolean(passwordError) && <p className={styles.error}>{passwordError}</p>}
            </label>

            <button
                className={styles.button}
                type="submit"
            >
                {t("login")}
            </button>
        </form>
    );
};

export default LoginForm;
