import type {
    TAnswerAdmin,
    TRawAnswer,
    TRawAnswerAdmin,
    TRawCreateAnswerRequest,
    TRawPatchAnswerRequest,
} from "./AnswersService.types";

import { Answer } from "../../entities/questions/Answer";

export function mapAnswer(data: TRawAnswer): Answer {
    const { id, question, text, admin, created_at, updated_at } = data;

    return new Answer(id, text, question, mapAnswerAdmin(admin), new Date(created_at), new Date(updated_at));
}

export function mapAnswerAdmin(data: TRawAnswerAdmin): TAnswerAdmin {
    const { id, username } = data;

    return {
        id,
        username,
    };
}

export function serializeCreateAnswer(data: Answer): TRawCreateAnswerRequest {
    const { text, questionId, admin } = data;

    return {
        text,
        question: questionId,
        admin: {
            id: admin.id,
            username: admin.username,
        },
    };
}

export function serializePatchAnswer(data: Answer): TRawPatchAnswerRequest {
    const { text } = data;

    return {
        text,
    };
}
