import cn from "classnames";
import { observer } from "mobx-react-lite";
import { useRef } from "react";

import type { PropsWithChildren, MouseEvent, PointerEvent } from "react";

import styles from "./ModalBackdrop.module.scss";

type Props = PropsWithChildren<{
    onClick?(): void;
    className?: string;
    areaClassName?: string;
    zIndex?: number;
    position?: "fixed" | "absolute";
}>;

export const ModalBackdrop = observer(function ModalBackdrop(props: Props) {
    const { className, children, zIndex, position, onClick, areaClassName } = props;

    const refOuter = useRef<HTMLDivElement>(null);
    const refInner = useRef<HTMLDivElement>(null);

    const handleClick = (e: MouseEvent) => {
        switch (e.target) {
            case refOuter.current:
            case refInner.current:
        }
    };

    const clickingRef = useRef<boolean>(false);

    const handlePointerDown = (e: PointerEvent) => {
        switch (e.target) {
            case refOuter.current:
            case refInner.current:
                clickingRef.current = true;
                break;
            default:
                clickingRef.current = false;
        }
    };

    const handlePointerUp = (e: React.PointerEvent) => {
        switch (e.target) {
            case refOuter.current:
            case refInner.current:
                if (clickingRef.current) {
                    clickingRef.current = false;
                    onClick?.();
                }
        }
    };

    return (
        <div
            className={cn(styles.backdrop, className)}
            style={{ zIndex, position }}
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            ref={refOuter}
        >
            <div
                ref={refInner}
                className={cn(styles.area, areaClassName)}
            >
                {children}
            </div>
        </div>
    );
});
