import cn from "classnames";

import type { TTabsBlockProps } from "./TabsBlock.types";

import { KeyboardTextIcon } from "../../../../keyboard/elements";
import { notImplementedAction } from "../../DesktopKeyboard.constants";
import { DesktopKeyboardButton } from "../../elements";

import { tabButtons } from "./TabsBlock.constants";

import styles from "./TabsBlock.module.scss";

const TabsBlock = ({ selectedTab, isShiftPressed, keyboardService, onClick }: TTabsBlockProps) => (
    <div className={styles.tabs}>
        {tabButtons.map(({ text, tab, className, tags }) => {
            const isSelectedTab = tab === selectedTab;
            const caseTransformer = isShiftPressed && isSelectedTab ? "toUpperCase" : "toLowerCase";

            return (
                <DesktopKeyboardButton
                    action={notImplementedAction}
                    className={cn(className, {
                        [styles.selected]: isSelectedTab,
                    })}
                    onClick={() => onClick(tab)}
                    key={tab}
                    tags={tags}
                    keyboardService={keyboardService}
                >
                    <KeyboardTextIcon>{text[caseTransformer]()}</KeyboardTextIcon>
                </DesktopKeyboardButton>
            );
        })}
    </div>
);

export default TabsBlock;
