import { FormulaMultichoiceInstrumentSettingsField } from "@viuch/instrument-multichoice-settings/entities/choices/FormulaMultichoiceInstrumentSettingsField";
import { ImageMultichoiceInstrumentSettingsField } from "@viuch/instrument-multichoice-settings/entities/choices/ImageMultichoiceInstrumentSettingsField";
import { TextMultichoiceInstrumentSettingsField } from "@viuch/instrument-multichoice-settings/entities/choices/TextMultichoiceInstrumentSettingsField";

import type { TAnyMultichoiceItem } from "../service-types/multichoice";
import type { BaseMultichoiceInstrumentSettingsField } from "@viuch/instrument-multichoice-settings/entities/BaseMultichoiceInstrumentSettingsField";

export function mapMultichoiceField(data: TAnyMultichoiceItem): BaseMultichoiceInstrumentSettingsField {
    const type = data.item_type;
    const isCorrect = data.is_correct ?? false;
    const mistakeHint = data.mistake_hint ?? null;

    switch (type) {
        case "text":
            return new TextMultichoiceInstrumentSettingsField(data.text, isCorrect, mistakeHint);
        case "formula":
            return new FormulaMultichoiceInstrumentSettingsField(data.formula, isCorrect, mistakeHint);
        case "picture":
            return new ImageMultichoiceInstrumentSettingsField(
                { url: data.url, uuid: data.id },
                data.description,
                isCorrect,
                mistakeHint
            );
    }
}
