import type { TFormControl } from "@viuch/form-builder/types/controls";

import { createLabel } from "../controls/label";

export const proveIdentity: TFormControl = {
    control: "Form",
    layout: "column",
    condition: {
        "./solver_type": ["prove-identity"],
    },
    controls: [
        createLabel("Доказывает тождество путем упрощения выражения и приведения подобных членов"),
        {
            path: "./expr",
            control: "Math",
            label: "Выражение",
        },
    ],
};

export const substituteAndProveIdentity: TFormControl = {
    control: "Form",
    layout: "column",
    condition: {
        "./solver_type": ["substitute_prove_identity"],
    },
    controls: [
        createLabel("Доказывает тождество с заменой путем упрощения выражения и приведения подобных членов"),
        {
            path: "./expr",
            control: "Math",
            label: "Выражение",
        },
        {
            path: "./substitutions",
            control: "MathArray",
            label: "Замены",
        },
    ],
};

export const proveInequality: TFormControl = {
    control: "Form",
    layout: "column",
    condition: {
        "./solver_type": ["prove_inequality"],
    },
    controls: [
        createLabel("Доказывает тождественное неравенство"),
        {
            path: "./expr",
            control: "Math",
            label: "Неравенство",
        },
        {
            path: "./variable",
            control: "Math",
            label: "Переменная",
        },
    ],
};
