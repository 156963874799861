import { action, computed, makeObservable, observable } from "mobx";

import { generateUuid } from "@viuch/shared/utils/data";

import type { IElementVisitor } from "./IElementVisitor";
import type { TSerializedElementPrototype } from "./types";
import type { InputService } from "../../services";
import type { TAnyAction, TAnySerializedElement, TRect, TSize } from "../../types";
import type { ContainerModel } from "../container";
import type { CursorState } from "../cursor";
import type { IStrategy } from "../strategies";

import { getClassFromInstance } from "../../utils/data";
import { getRectFromDomRect } from "../../utils/positions";
import { ContainerLessElementStrategy } from "../strategies/ContainerLessElementStrategy";

export abstract class BaseElementModel<T extends TAnySerializedElement = TAnySerializedElement> {
    public readonly inputService: InputService;
    public readonly behaviour: IStrategy;
    public parentContainer!: ContainerModel;

    public readonly uuid: string;
    public domElement?: HTMLElement;
    public size?: TSize;
    private disposeCallbacks: VoidFunction[];

    protected constructor(inputService: InputService, uuid?: string) {
        this.uuid = uuid ?? generateUuid();
        this.inputService = inputService;
        this.disposeCallbacks = [];
        this.behaviour = this.initBehaviour();

        makeObservable(this, {
            domElement: observable,
            setDomElement: action,
            parentContainer: observable,
            size: observable,
            notifySizeChanged: action,
            index: computed,
        });

        this.inputService.elements.register(this);
        this.registerDisposeCallback(() => {
            this.inputService.elements.free(this);
        });
    }

    public get index(): number {
        const container = this.parentContainer as ContainerModel | undefined;
        return container?.elements.indexOf(this) ?? -1;
    }

    protected initBehaviour(): IStrategy {
        return new ContainerLessElementStrategy(this);
    }

    public handle(action: TAnyAction): void {
        this.behaviour.handleAction(action);
    }

    public getRenderType(): string {
        return "inline-block";
    }

    public registerDisposeCallback(dispose: VoidFunction): void {
        this.disposeCallbacks.unshift(dispose);
    }

    public dispose() {
        this.disposeCallbacks.forEach((dispose) => dispose());
    }

    public setDomElement(element: HTMLElement): void {
        this.domElement = element;
    }

    public getRect(): TRect | null {
        if (!this.domElement) {
            return null;
        }

        return getRectFromDomRect(this.domElement.getBoundingClientRect());
    }

    protected get cursor(): CursorState {
        return this.inputService.cursorState;
    }

    public isEquals(otherElement: BaseElementModel): boolean {
        return getClassFromInstance(otherElement) === getClassFromInstance(this);
    }

    public abstract serialize(): T;

    public abstract serializeAsClone(): TSerializedElementPrototype<T>;

    public notifySizeChanged(size: TSize | undefined) {
        this.size = size;
    }

    public computeIndex(): number {
        return this.parentContainer.getElementIndex(this);
    }

    public removeFromContainer(): void {
        this.parentContainer.removeElement(this);
    }

    public abstract accept<R>(visitor: IElementVisitor<R>): R;

    getRight(): BaseElementModel | null {
        if (this.index === this.parentContainer.elements.length - 1) {
            return null;
        }
        return this.parentContainer.getElementByIndex(this.index + 1);
    }

    getLeft() {
        if (this.index === 0) {
            return null;
        }
        return this.parentContainer.getElementByIndex(this.index - 1);
    }

    getWrapperContainer(): ContainerModel | null {
        return null;
    }
}
