import type {
    TRawCreateReviewComment,
    TRawPatchReviewComment,
    TRawPatchedReviewComment,
    TRawReviewComment,
} from "./ReviewCommentsService.types";

import { ReviewComment } from "../../entities/reviews/ReviewComment";

export function mapReviewComment(data: TRawReviewComment): ReviewComment {
    const { id, review, parent, updated_at, created_at, user, text, children, is_verified } = data;

    return new ReviewComment(
        id,
        review,
        parent,
        new Date(updated_at),
        new Date(created_at),
        is_verified,
        mapReviewCommentUser(user),
        text,
        children ? mapReviewCommentChidlrens(children) : []
    );
}

export function mapReviewCommentUser(data: TRawReviewComment["user"]): ReviewComment["user"] {
    const { id, username } = data;

    return { id, username };
}

export function mapReviewCommentChidlrens(data: TRawReviewComment[]): ReviewComment[] {
    return data.map(mapReviewComment);
}

export function serializeCreateReviewComment(data: ReviewComment): TRawCreateReviewComment {
    const { parentId, reviewId, text } = data;

    return {
        parent: parentId,
        review: reviewId,
        text,
    };
}

export function serializePatchReviewComment(data: ReviewComment): TRawPatchReviewComment {
    const { parentId, reviewId, text, isVerified } = data;

    return {
        parent: parentId,
        review: reviewId,
        is_verified: isVerified,
        text,
    };
}

// TODO: на бэке неполные данные (theme, student, comments)
export function mapPatchedReviewComment(sourceComment: ReviewComment, data: TRawPatchedReviewComment): ReviewComment {
    const { id, review, parent, updated_at, created_at, is_verified, user, text } = data;
    const { childrens } = sourceComment;

    return new ReviewComment(
        id,
        review,
        parent,
        new Date(updated_at),
        new Date(created_at),
        is_verified,
        mapReviewCommentUser(user),
        text,
        childrens.map((comment) => comment.clone())
    );
}
