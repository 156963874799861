import { action, computed, makeObservable, untracked } from "mobx";

import { InputService } from "@viuch/math-editor";

import type { TGraph1DDotForms, Graph1DInstrumentStatementDot } from "@viuch/instrument-graph1d/statement";

export class EditDotModalViewModel {
    readonly dot: Graph1DInstrumentStatementDot;

    constructor(dot: Graph1DInstrumentStatementDot) {
        this.dot = dot;

        makeObservable(this);
    }

    @computed
    get nameInput(): InputService {
        const initialState = untracked(() => this.dot.name);
        return new InputService({ initialState });
    }

    @computed
    get valueInput(): InputService {
        const initialState = untracked(() => this.dot.value);
        return new InputService({ initialState });
    }

    @action.bound
    setName(inputService: InputService) {
        this.dot.name = inputService.getSerializedState();
    }

    @action.bound
    setValue(inputService: InputService) {
        this.dot.value = inputService.getSerializedState();
    }

    @action.bound
    setForm(form: TGraph1DDotForms) {
        this.dot.form = form;
    }
}
