import { action, makeObservable } from "mobx";

import type { BaseElement } from "../elements";
import type { Figure2DController } from "../Figure2DController";
import type { ToolbarMenu, ToolbarButton } from "../toolbar";
import type { IUserPointer } from "../viewport/types";
import type React from "react";

export class EventsController {
    private readonly data: Figure2DController;

    constructor(data: Figure2DController) {
        this.data = data;

        makeObservable(this);
    }

    @action.bound
    dispatchToolbarButtonClick(menu: ToolbarMenu, button: ToolbarButton) {
        this.data.flows.currentFlow.handleToolbarButtonClick(menu, button);
    }

    @action.bound
    dispatchViewportPointerEvent(e: React.PointerEvent, pointer: IUserPointer) {
        if (e.type === "pointerdown") {
            this.data.toolbar.closeNestedMenu();
        }
        this.data.flows.currentFlow.handleViewportPointerEvent(e, pointer);
    }

    @action.bound
    dispatchElementPointerEvent(e: React.PointerEvent, element: BaseElement, pointer: IUserPointer) {
        this.data.flows.currentFlow.handleElementPointerEvent(e, element, pointer);
    }

    @action.bound
    dispatchKeyEvent(e: React.KeyboardEvent) {
        this.data.flows.currentFlow.handleKeyEvent(e);
    }
}
