import type { MultichoiceTaskInstrumentSettings } from "../../../entities/task-instruments-settings/BaseTaskInstrumentSettings";
import type {
    TAnyMultichoiceItem,
    TMultichoiceInstrumentSettings,
} from "@viuch/feature-instrument-settings/service-types/multichoice";
import type { IMultichoiceInstrumentSettingsFieldVisitor } from "@viuch/instrument-multichoice-settings/entities/BaseMultichoiceInstrumentSettingsField";

export function serializeMultichoiceTaskSettings(
    model: MultichoiceTaskInstrumentSettings
): TMultichoiceInstrumentSettings {
    return {
        instrument_type: "multichoice",
        number_of_items: model.instrument.visibleAnswersCount,
        interface: model.instrument.inputType,
        items: model.instrument.fields.map((field) => field.accept(multichoiceFieldSerializer)),
        settings_uuid: model.settingsUuid,
    };
}

const multichoiceFieldSerializer: IMultichoiceInstrumentSettingsFieldVisitor<TAnyMultichoiceItem> = {
    withImage: (field) => ({
        item_type: "picture",
        id: field.image?.uuid ?? null!,
        url: field.image?.url ?? null!,
        description: field.text,
        is_correct: field.checked,
        mistake_hint: field.mistakeHint,
    }),
    withFormula: (field) => ({
        item_type: "formula",
        formula: field.formula,
        is_correct: field.checked,
        mistake_hint: field.mistakeHint,
    }),
    withText: (field) => ({
        item_type: "text",
        text: field.text,
        is_correct: field.checked,
        mistake_hint: field.mistakeHint,
    }),
};
