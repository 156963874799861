import { createManagedPromise } from "@viuch/shared/utils/runtime/promises";

const WS_BASE_URL = process.env.REACT_APP_WS_API;

export type THandleDataFunction<T> = (data: T) => void;

export class WebsocketClient<TAction, TEvent> {
    protected wsConnection?: WebSocket;
    private url: string;

    constructor(url: string) {
        this.url = url;
    }

    async connect(onData: THandleDataFunction<TEvent>, onError: VoidFunction, onClose: VoidFunction): Promise<void> {
        if (this.wsConnection) {
            throw new Error(`Already connected`);
        }

        const wsConnection = new WebSocket(`${WS_BASE_URL}${this.url}`);

        let connected = false;
        const { promise, resolve, reject } = createManagedPromise<void>();

        wsConnection.addEventListener("open", () => {
            resolve();
            connected = true;
        });

        wsConnection.addEventListener("message", (e: MessageEvent) => {
            this.handleMessage(e, onData, onError, onClose);
        });

        wsConnection.addEventListener("close", (e: CloseEvent) => {
            console.error(e);
            onClose();
        });

        wsConnection.addEventListener("error", (e) => {
            if (connected) {
                this.handleError(e, onData, onError, onClose);
            } else {
                console.error(e);
                reject(new Error("Websocket is not connected"));
            }
        });

        this.wsConnection = wsConnection;
        return promise;
    }

    sendMessage(message: TAction): void {
        if (!this.wsConnection) {
            throw new Error("Websocket is not connected");
        }

        this.wsConnection.send(this.serializeAction(message));
    }

    disconnect(): void {
        this.wsConnection?.close();
    }

    protected serializeAction(message: TAction): string | ArrayBufferLike | Blob | ArrayBufferView {
        return JSON.stringify(message);
    }

    protected handleMessage(
        e: MessageEvent,
        onData: THandleDataFunction<TEvent>,
        onError: VoidFunction,
        onClose: VoidFunction
    ): void;

    protected handleMessage(e: MessageEvent, onData: THandleDataFunction<TEvent>): void {
        onData(JSON.parse(e.data));
    }

    protected handleError(
        e: Event,
        onData: THandleDataFunction<TEvent>,
        onError: VoidFunction,
        onClose: VoidFunction
    ): void;

    protected handleError(e: Event, onData: THandleDataFunction<TEvent>, onError: VoidFunction): void {
        console.error(e);
        onError();
    }
}
