import { isEmptyEditorData } from "@viuch/complex-editor";

import type { TRawPreamble, TRawPreambleRequest } from "./PreamblesService.types";
import type { GetError } from "../base/RequestError";

import { Preamble } from "../../entities/preambles/Preamble";

export function mapPreambleResponse(data: TRawPreamble): Preamble {
    const { id, name, preamble } = data;
    return new Preamble(id, name, preamble);
}

export function serializePreambleRequest(preamble: Preamble): TRawPreambleRequest {
    const { id, title, editorContent } = preamble;
    return { id, name: title, preamble: editorContent };
}
export function validatePreambleRequest(preamble: Preamble): GetError<TRawPreambleRequest> {
    const errors = new Map<
        keyof TRawPreambleRequest,
        (GetError<TRawPreambleRequest> & {})[keyof TRawPreambleRequest]
    >();

    if (isEmptyEditorData(preamble.editorContent)) {
        errors.set("preamble", ["Не может быть пустым"]);
    }

    if (preamble.title.trim().length === 0) {
        errors.set("name", ["Заголовок не может быть пустым"]);
    }

    if (errors.size === 0) return;

    return Object.fromEntries<typeof errors extends Map<any, infer V> ? V : never>(errors.entries());
}
