import { generateUuid } from "@viuch/shared/utils/data";

import type { ISerializedEquationsSet, TEquationsSetVariant } from "./types";

import { createSerializedContainer } from "../../utils/serialization";

export function createSerializedEquationsSet(variant: TEquationsSetVariant): ISerializedEquationsSet {
    return {
        type: "equations-set",
        variant,
        uuid: generateUuid(),
        equations: [createSerializedContainer(), createSerializedContainer()],
    };
}
