import type { IToolbarButtonParams } from "../ToolbarButton";

import { ToolbarButton } from "../ToolbarButton";
import { ToolbarMenu } from "../ToolbarMenu";

export function* createFragments(): Generator<IToolbarButtonParams> {
    yield { icon: "dot", title: "Точка" };
    yield { icon: "fragment", title: "Отрезок" };
    yield { icon: "fragment-dashed", title: "Отрезок (пунктир)", key: "fragment-dash" };
    yield { icon: "vector", title: "Вектор", key: "vector" };
    yield { icon: "vector-dashed", title: "Вектор (пунктир)", key: "vector-dash" };
    yield { icon: "line", title: "Прямая" };
    yield { icon: "parallel", title: "Параллельная прямая" };
    yield { icon: "perpendicular", title: "Перпендикулярная прямая" };
    yield { icon: "tangent", title: "Касательная" };
    yield { icon: "tangent-throw-point", title: "Касательная через точку", key: "tangent-point" };
    yield { icon: "bisection", title: "Биссектриса", key: "bisection" };
    yield { icon: "median", title: "Медиана", key: "median" };
    yield { icon: "height", title: "Высота", key: "altitude" };
    yield { icon: "middle-line", title: "Средняя линия", key: "middle-line" };
    yield { icon: "parallel-fragment", title: "Параллельный отрезок", key: "parallel-fragment" };
}

export function* createLabels(): Generator<IToolbarButtonParams> {
    yield { icon: "label-dot", title: "Подписать точку", key: "label-dot" };
    yield { icon: "label-angle", title: "Подписать угол" };
    yield { icon: "equal-segments", title: "Равные отрезки", key: "equal-segments" };
    yield { icon: "equal-angles", title: "Равные углы", key: "equal-angles" };
    yield { icon: "right-angle", title: "Прямой угол", key: "right-angle" };
    yield { icon: "label-segment", title: "Подписать отрезок" };
}

export function* createSquares(): Generator<IToolbarButtonParams> {
    yield { icon: "square", title: "Квадрат" };
    yield { icon: "rect", title: "Прямоугольник" };
    yield { icon: "trapeze", title: "Трапеция" };
    yield { icon: "parallelogram", title: "Параллелограмм" };
    yield { icon: "rhombus", title: "Ромб" };
}

export function* createCircles(): Generator<IToolbarButtonParams> {
    yield { icon: "circle", key: "circle", title: "Окружность" };
    yield { icon: "ellipse", key: "ellipse", title: "Эллипс" };
    yield { icon: "circle-inscribed", title: "Вписанная окружность" };
    yield { icon: "circle-circumscribed", title: "Описанная окружность" };
}

export function* createMainMenuButtons(): Generator<IToolbarButtonParams> {
    yield { icon: "cursor", key: "cursor" };
    yield {
        icon: "fragment",
        key: "fragments",
        nestedMenu: instantiateMenu("fragments", createFragments()),
    };
    yield {
        icon: "triangle",
        key: "triangle",
    };
    yield {
        icon: "square",
        key: "squares",
        nestedMenu: instantiateMenu("squares", createSquares()),
    };
    yield {
        icon: "circle",
        key: "circles",
        nestedMenu: instantiateMenu("circles", createCircles()),
    };
    yield {
        icon: "label-angle",
        key: "labels",
        nestedMenu: instantiateMenu("labels", createLabels()),
    };
}

export function instantiateButton(buttonParams: IToolbarButtonParams): ToolbarButton {
    return new ToolbarButton(buttonParams);
}

export function instantiateMenu(key: string, buttons: Iterable<IToolbarButtonParams>): ToolbarMenu {
    return new ToolbarMenu(key, Array.from(buttons).map(instantiateButton));
}
