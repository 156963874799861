import cn from "classnames";
import { observer } from "mobx-react-lite";
import React from "react";

import { MathEditorInput } from "@viuch/math-editor";
import { normalizeNumber } from "@viuch/shared/utils/math/normalize";
import { roundToStep } from "@viuch/shared/utils/math/round";
import { assert } from "@viuch/utils/debug";
import { useHold } from "@viuch/utils/hooks";

import type { Graph1DInstrumentStatementDot } from "../../statement";

import { GRAPH_STEPS } from "../constants";
import { isLeftMouseEvent, isRightMouseEvent, isTouchPointerEvent } from "../utils";

import { DotViewModel } from "./Dot.vm";

import styles from "./Dot.module.scss";

type Props = {
    dot: Graph1DInstrumentStatementDot;
    onMoveDot?(dot: Graph1DInstrumentStatementDot, newPosition: number): void;
    onEditDot?(dot: Graph1DInstrumentStatementDot): void;
    lineRef: React.RefObject<HTMLDivElement>;
    className?: string;
};

export const Dot = observer(function Dot({ className, dot, onEditDot, onMoveDot, lineRef }: Props) {
    const vm = React.useMemo(() => new DotViewModel(dot), [dot]);

    const dotVariant = {
        line: styles.line,
        circle: styles.circle,
    }[dot.form];

    const dotStyle = {
        "--dotOffset": `${dot.position * 100}%`,
    };

    const getPosition = (e: React.PointerEvent): number => {
        const line = lineRef.current;
        assert(line, "Line should be present");
        const { left, width } = line.getBoundingClientRect();
        const position = (e.clientX - left) / width;
        return roundToStep(normalizeNumber(position, { min: 0, max: 1 }), GRAPH_STEPS);
    };

    const { onPointerCancel, onPointerDown, onPointerMove, onPointerUp } = useHold({
        holdDelay: 1000,
        holdDistance: 15,
        onClick(downEvent: React.PointerEvent, upEvent) {
            if (isRightMouseEvent(downEvent)) {
                upEvent.preventDefault();

                setTimeout(() => {
                    onEditDot?.(dot);
                });
            }
        },
        onHold(downEvent: React.PointerEvent) {
            if (isTouchPointerEvent(downEvent) || isLeftMouseEvent(downEvent)) {
                onEditDot?.(dot);
            }
        },
        onMove(downEvent: React.PointerEvent, moveEvent: React.PointerEvent) {
            onMoveDot?.(dot, getPosition(moveEvent));
        },
    });

    const handleContextMenu = (e: React.MouseEvent) => {
        e.preventDefault();
    };

    return (
        <div
            style={dotStyle}
            className={cn(className, styles.wrapper)}
            tabIndex={0}
        >
            <div className={styles.name}>
                <MathEditorInput
                    inputModel={vm.nameInput.model}
                    className={styles.mathInput}
                />
            </div>
            <div
                className={cn(styles.dot, dotVariant)}
                onContextMenu={handleContextMenu}
            >
                <div
                    className={styles.dotClickable}
                    onPointerCancel={onPointerCancel}
                    onPointerDown={onPointerDown}
                    onPointerMove={onPointerMove}
                    onPointerUp={onPointerUp}
                    onContextMenu={handleContextMenu}
                    onContextMenuCapture={handleContextMenu}
                />
            </div>
            <div className={styles.value}>
                <MathEditorInput
                    inputModel={vm.valueInput.model}
                    className={styles.mathInput}
                />
            </div>
        </div>
    );
});
