import cn from "classnames";
import { observer } from "mobx-react-lite";

import type { NaturalLogModel } from "./NaturalLogModel";
import type { TElementProps } from "../../types/element";
import type { VFC } from "react";

import { ContainerElement } from "../../core/container";
import { RoundBrackets } from "../../core/dynamic-brackets";
import { useMathEditorElementRef } from "../../hooks";

import styles from "./NaturalLogElement.module.scss";

export const NaturalLogElement: VFC<TElementProps<NaturalLogModel>> = observer(function NaturalLogElement({
    elementModel,
}) {
    const ref = useMathEditorElementRef(elementModel);

    return (
        <span
            ref={ref}
            className={cn(styles.inline, styles.wrapper)}
        >
            <span className={styles.inline}>
                <span className={styles.inline}>ln</span>
                <ContainerElement
                    containerModel={elementModel.degree}
                    inline
                    className={styles.degree}
                />
            </span>
            <RoundBrackets>
                <ContainerElement
                    containerModel={elementModel.content}
                    inline
                />
            </RoundBrackets>
        </span>
    );
});
