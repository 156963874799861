import cn from "classnames";
import { observer } from "mobx-react-lite";
import React from "react";

import { MathInput2, RadioInput } from "@viuch/ui-common";

import type { BaseTableInstrumentSettingsImpl } from "../settings";
import type { KeyboardService } from "@viuch/math-editor";
import type { TRadioInputData } from "@viuch/ui-common";

import { InstrumentTable } from "./elements";
import { TableViewSettingsEditor } from "./elements/TableViewSettingsEditor";

import styles from "./TableInstrumentSettingsEditor.module.scss";

type Props = {
    table: BaseTableInstrumentSettingsImpl;
    keyboardService: KeyboardService;
    className?: string;
};

const canEditData: TRadioInputData<"0" | "1"> = [
    { label: "Разрешено", value: "1" },
    { label: "Запрещено", value: "0" },
];

const viewPlacementData: TRadioInputData<"inFrame" | "inSolution"> = [
    { label: "В поле 'Решение'", value: "inSolution" },
    { label: "В рамке c инструментами", value: "inFrame" },
];

export const TableInstrumentSettingsEditor = observer(function TableInstrumentSettingsEditor(props: Props) {
    const { className, keyboardService, table } = props;

    return (
        <div className={cn(className, styles.wrapper)}>
            <h3>Таблица</h3>
            <MathInput2
                value={table.title}
                onChange={table.setTitle}
                className={styles.title}
                title="Заголовок над таблицей"
                keyboardService={keyboardService}
                multiline
            />
            <div className={styles.tableContainer}>
                <InstrumentTable
                    keyboardService={keyboardService}
                    columnsCount={table.cells[0].length}
                    rowsCount={table.cells.length}
                    onAddColumn={table.addCol}
                    onAddRow={table.addRow}
                    onRemoveColumn={table.removeColumn}
                    onRemoveRow={table.removeRow}
                    table={table}
                />
            </div>
            <TableViewSettingsEditor
                viewSettings={table.viewSettings}
                disabled={false}
            />
            <RadioInput
                title="Отображать инструмент:"
                data={viewPlacementData}
                value={table.renderInFrame ? "inFrame" : "inSolution"}
                onChange={(value) => table.setRenderInFrame(value === "inFrame")}
            />
        </div>
    );
});
