import type { ISerializedLim } from "./types";
import type { TSerializedElementPrototype } from "../../core/element";
import type { IElementVisitor } from "../../core/element/IElementVisitor";
import type { IStrategy } from "../../core/strategies";
import type { IMultiContainerElement } from "../../core/strategies/types";
import type { InputService } from "../../services";
import type { TContainerElements, TElementDeserializerFunction } from "../../types";

import { ContainerModel } from "../../core/container";
import { BaseElementModel } from "../../core/element";

import { LimStrategy } from "./LimStrategy";

export class LimModel extends BaseElementModel<ISerializedLim> implements IMultiContainerElement {
    public readonly contentFrom: ContainerModel;
    public readonly contentTo: ContainerModel;
    public readonly content: ContainerModel;

    public constructor(
        inputService: InputService,
        contentFrom: TContainerElements,
        contentTo: TContainerElements,
        content: TContainerElements,
        uuid?: string
    ) {
        super(inputService, uuid);

        this.contentFrom = new ContainerModel(contentFrom, this, ["contentFrom"], inputService, {
            showPlaceholderIfEmpty: true,
        });
        this.contentTo = new ContainerModel(contentTo, this, ["contentTo"], inputService, {
            showPlaceholderIfEmpty: true,
        });
        this.content = new ContainerModel(content, this, ["content"], inputService, {
            showPlaceholderIfEmpty: true,
        });
    }

    public serialize(): ISerializedLim {
        return {
            type: "lim",
            uuid: this.uuid,
            contentFrom: this.contentFrom.serialize(),
            contentTo: this.contentTo.serialize(),
            content: this.content.serialize(),
        };
    }

    public static deserialize: TElementDeserializerFunction<ISerializedLim> = (
        { inputService },
        { contentFrom, contentTo, content, uuid }
    ) =>
        new LimModel(
            inputService,
            inputService.deserializeContainer(contentFrom),
            inputService.deserializeContainer(contentTo),
            inputService.deserializeContainer(content),
            uuid
        );

    getContainersToMoveCursorBetween(): ContainerModel[] {
        return [this.contentFrom, this.contentTo, this.content];
    }

    protected override initBehaviour(): IStrategy {
        return new LimStrategy(this);
    }

    public accept<R>(visitor: IElementVisitor<R>): R {
        return visitor.doWithLim(this);
    }

    public serializeAsClone(): TSerializedElementPrototype<ISerializedLim> {
        const { uuid, ...clone } = this.serialize();
        return {
            ...clone,
            contentFrom: this.contentFrom.serializeAsClone(),
            contentTo: this.contentTo.serializeAsClone(),
            content: this.content.serializeAsClone(),
        };
    }
    override getWrapperContainer(): ContainerModel {
        return this.content;
    }
}
