/**
 * Class for working with UI:
 *  - rendering base structure
 *  - show/hide preview
 *  - apply tune view
 */
export default class Ui {
  /**
   * @param {object} ui - image tool Ui module
   * @param {object} ui.api - Editor.js API
   * @param {ImageConfig} ui.config - user config
   * @param {Function} ui.onSelectFile - callback for clicks on Select file button
   * @param {boolean} ui.readOnly - read-only mode flag
   */
  constructor({ api, config, onSelectFile, readOnly }) {
    this.api = api;
    this.config = config;
    this.onSelectFile = onSelectFile;
    this.readOnly = readOnly;
    this.nodes = {
      wrapper: make('div', [this.CSS.baseClass, this.CSS.wrapper]),
      imageContainer: make('div', [this.CSS.imageContainer]),
      fileButton: this.createFileButton(),
      imageEl: undefined,
      imagePreloader: make('div', this.CSS.imagePreloader),
      caption: make('div', [this.CSS.input, this.CSS.caption], { contentEditable: !this.readOnly }),
      title: make("div", [this.CSS.input, this.CSS.title], { contentEditable: !this.readOnly }),
      settings: make("div", [this.CSS.settingsContainer]),
      altWrapper: make("div", [this.CSS.wrapperItem]),
      alt: make("input", [this.CSS.input, this.CSS.alt], { type: "text" }),
      desktopSizeWrapper: make("div", [this.CSS.settingsItem]),
      mobileSizeWrapper: make("div", [this.CSS.settingsItem]),
      verticalAlignWrapper: make("div", [this.CSS.settingsItem]),
      desktopSize: make("input", [this.CSS.input], { type: "number", min: 20, max: 100, initial: 100, step: 1 }),
      mobileSize: make("input", [this.CSS.input], { type: "number", min: 20, max: 100, initial: 100, step: 1 }),
      verticalAlign: make("select", [this.CSS.input], {}, `
        <option value="">По-умолчанию</option>
        <option value="top">Сверху</option>
        <option value="center">Посередине</option>
        <option value="bottom">Снизу</option>
      `),
    };

    /**
     * Create base structure
     *  <wrapper>
     *    <title />
     *    <image-container>
     *      <image-preloader />
     *    </image-container>
     *    <caption />
     *    <select-file-button />
     *    <alt-text />
     *    <settings>
     *      <desktop-size />
     *      <mobile-size />
     *      <vertical-align />
     *    </settings
     *  </wrapper>
     */
    this.nodes.title.dataset.placeholder = this.config.titlePlaceholder;
    this.nodes.caption.dataset.placeholder = this.config.captionPlaceholder;
    this.nodes.alt.dataset.placeholder = this.config.altPlaceholder;

    this.nodes.imageContainer.appendChild(this.nodes.imagePreloader);
    this.nodes.wrapper.appendChild(this.nodes.title);
    this.nodes.wrapper.appendChild(this.nodes.imageContainer);
    this.nodes.wrapper.appendChild(this.nodes.caption);
    this.nodes.wrapper.appendChild(this.nodes.fileButton);
    this.nodes.wrapper.appendChild(this.nodes.settings);
    {
      this.nodes.settings.appendChild(this.nodes.desktopSizeWrapper);
      {
        this.nodes.desktopSizeWrapper.appendChild(make("span", [], {}, this.config.desktopSizePlaceholder));
        this.nodes.desktopSizeWrapper.appendChild(this.nodes.desktopSize);
      }
      this.nodes.settings.appendChild(this.nodes.mobileSizeWrapper);
      {
        this.nodes.mobileSizeWrapper.appendChild(make("span", [], {}, this.config.mobileSizePlaceholder));
        this.nodes.mobileSizeWrapper.appendChild(this.nodes.mobileSize);
      }
      this.nodes.settings.appendChild(this.nodes.verticalAlignWrapper);
      {
        this.nodes.verticalAlignWrapper.appendChild(make("div", [], {}, this.config.verticalAlignPlaceholder));
        this.nodes.verticalAlignWrapper.appendChild(this.nodes.verticalAlign);
      }
    }
    this.nodes.wrapper.appendChild(this.nodes.altWrapper);
    {
      this.nodes.altWrapper.appendChild(make("span", [], {}, this.config.altPlaceholder));
      this.nodes.altWrapper.appendChild(this.nodes.alt);
    }
  }

  /**
   * CSS classes
   *
   * @returns {object}
   */
  get CSS() {
    return {
      baseClass: this.api.styles.block,
      loading: this.api.styles.loader,
      input: this.api.styles.input,
      button: this.api.styles.button,

      /**
       * Tool's classes
       */
      wrapper: 'image-tool',
      imageContainer: 'image-tool__image',
      imagePreloader: 'image-tool__image-preloader',
      imageEl: 'image-tool__image-picture',
      title: 'image-tool__title',
      caption: 'image-tool__caption',
      alt: 'image-tool__alt',
      settingsContainer: 'image-tool__settings',
      settingsItem: 'image-tool__settingsItem',
      wrapperItem: 'image-tool__item',
    };
  };

  /**
   * Ui statuses:
   * - empty
   * - uploading
   * - filled
   *
   * @returns {{EMPTY: string, UPLOADING: string, FILLED: string}}
   */
  static get status() {
    return {
      EMPTY: 'empty',
      UPLOADING: 'loading',
      FILLED: 'filled',
    };
  }

  /**
   * Renders tool UI
   *
   * @param {ImageToolData} toolData - saved tool data
   * @returns {Element}
   */
  render(toolData) {
    if (!toolData.file || Object.keys(toolData.file).length === 0) {
      this.toggleStatus(Ui.status.EMPTY);
    } else {
      this.toggleStatus(Ui.status.UPLOADING);
    }

    return this.nodes.wrapper;
  }

  /**
   * Creates upload-file button
   *
   * @returns {Element}
   */
  createFileButton() {
    const button = make('div', [this.CSS.button]);

    button.innerHTML = this.config.buttonContent || `${this.api.i18n.t('Select an Image')}`;

    button.addEventListener('click', () => {
      this.onSelectFile();
    });

    return button;
  }

  /**
   * Shows uploading preloader
   *
   * @param {string} src - preview source
   * @returns {void}
   */
  showPreloader(src) {
    this.nodes.imagePreloader.style.backgroundImage = `url(${src})`;

    this.toggleStatus(Ui.status.UPLOADING);
  }

  /**
   * Hide uploading preloader
   *
   * @returns {void}
   */
  hidePreloader() {
    this.nodes.imagePreloader.style.backgroundImage = '';
    this.toggleStatus(Ui.status.EMPTY);
  }

  /**
   * Shows an image
   *
   * @param {string} url - image source
   * @returns {void}
   */
  fillImage(url) {
    /**
     * Check for a source extension to compose element correctly: video tag for mp4, img — for others
     */
    const tag = 'IMG';

    const attributes = {
      src: url,
    };

    /**
     * We use eventName variable because IMG and VIDEO tags have different event to be called on source load
     * - IMG: load
     * - VIDEO: loadeddata
     *
     * @type {string}
     */
    const eventName = 'load';

    /**
     * Update attributes and eventName if source is a mp4 video
     */
    // if (tag === 'VIDEO') {
    //   /**
    //    * Add attributes for playing muted mp4 as a gif
    //    *
    //    * @type {boolean}
    //    */
    //   attributes.autoplay = true;
    //   attributes.loop = true;
    //   attributes.muted = true;
    //   attributes.playsinline = true;

    //   /**
    //    * Change event to be listened
    //    *
    //    * @type {string}
    //    */
    //   eventName = 'loadeddata';
    // }

    /**
     * Compose tag with defined attributes
     *
     * @type {Element}
     */
    this.nodes.imageEl = make(tag, this.CSS.imageEl, attributes);

    /**
     * Add load event listener
     */
    this.nodes.imageEl.addEventListener(eventName, () => {
      this.toggleStatus(Ui.status.FILLED);

      /**
       * Preloader does not exists on first rendering with presaved data
       */
      if (this.nodes.imagePreloader) {
        this.nodes.imagePreloader.style.backgroundImage = '';
      }
    });

    this.nodes.imageContainer.appendChild(this.nodes.imageEl);
  }

  /**
   * Shows caption input
   *
   * @param {string} text - caption text
   * @returns {void}
   */
  fillCaption(text) {
    if (this.nodes.caption) {
      this.nodes.caption.innerHTML = text;
    }
  }

  /**
   * Shows title input
   *
   * @param {string} text - caption text
   * @returns {void}
   */
  fillTitle(text) {
    if (this.nodes.title) {
      this.nodes.title.innerHTML = text;
    }
  }

  /**
   * Shows title input
   *
   * @param {string} text - caption text
   * @returns {void}
   */
  fillAlt(text) {
    if (this.nodes.alt) {
      this.nodes.alt.value = text;
    }
  }

  /**
   * Update desktop size value
   *
   * @param {number} value - caption text
   * @returns {void}
   */
  fillDesktopSize(value) {
    if (this.nodes.desktopSize) {
      this.nodes.desktopSize.valueAsNumber = value;
    }
  }

  /**
   * Update mobile size value
   *
   * @param {number} value - caption text
   * @returns {void}
   */
  fillMobileSize(value) {
    if (this.nodes.mobileSize) {
      this.nodes.mobileSize.valueAsNumber = value;
    }
  }

  /**
   * @param { string | null | undefined } value
   */
  fillVerticalAlign(value) {
    switch (value) {
      case "top":
      case "center":
      case "bottom":
        if (this.nodes.verticalAlign) {
          this.nodes.verticalAlign.value = value;
        }
        break;
      default:
        this.nodes.verticalAlign.value = "";
    }
  }

  /**
   * Changes UI status
   *
   * @param {string} status - see {@link Ui.status} constants
   * @returns {void}
   */
  toggleStatus(status) {
    for (const statusType in Ui.status) {
      if (Object.prototype.hasOwnProperty.call(Ui.status, statusType)) {
        this.nodes.wrapper.classList.toggle(`${this.CSS.wrapper}--${Ui.status[statusType]}`, status === Ui.status[statusType]);
      }
    }
  }

  /**
   * Apply visual representation of activated tune
   *
   * @param {string} tuneName - one of available tunes {@link Tunes.tunes}
   * @param {boolean} status - true for enable, false for disable
   * @returns {void}
   */
  applyTune(tuneName, status) {
    this.nodes.wrapper.classList.toggle(`${this.CSS.wrapper}--${tuneName}`, status);
  }
}

/**
 * Helper for making Elements with attributes
 *
 * @param  {string} tagName           - new Element tag name
 * @param  {Array|string} classNames  - list or name of CSS class
 * @param  {object} attributes        - any attributes
 * @returns {Element}
 */
export const make = function make(tagName, classNames = null, attributes = {}, innerHTML = "") {
  const el = document.createElement(tagName);

  if (Array.isArray(classNames)) {
    el.classList.add(...classNames);
  } else if (classNames) {
    el.classList.add(classNames);
  }

  for (const attrName in attributes) {
    el[attrName] = attributes[attrName];
  }

  if (innerHTML) {
    el.innerHTML = innerHTML;
  }

  return el;
};
