import React from "react";

import { ReactComponent as MinusIcon } from "@viuch/assets/icons/minus-red.svg";

import type { EditorTypes } from "../../../../editors/base";

import { ComplexEditorPlusButtonBlock } from "../plus-button-block";

import styles from "./ComplexEditorActionsBlock.module.scss";

type TProps = {
    onAddClick(type: EditorTypes): void;
    onRemoveClick(): void;
};

export const ComplexEditorActionsBlock: React.VFC<TProps> = ({ onAddClick, onRemoveClick }) => (
    <div className={styles.actions}>
        <button
            className={styles.button}
            type="button"
            onClick={onRemoveClick}
        >
            <MinusIcon />
        </button>
        <ComplexEditorPlusButtonBlock onClick={onAddClick} />
    </div>
);
