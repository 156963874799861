import { generateUuid } from "@viuch/shared/utils/data";

import type { ISerializedDownIndex } from "./types";
import type { TAnySerializedElement } from "../../types";

import { createSerializedContainer } from "../../utils/serialization";

export function createSerializedDownIndex(content?: TAnySerializedElement[]): ISerializedDownIndex {
    return {
        type: "down-index",
        uuid: generateUuid(),
        content: createSerializedContainer(content),
    };
}

export function isSerializedDownIndex(value: TAnySerializedElement): value is ISerializedDownIndex {
    return value.type === "down-index";
}
