import { mapSolutionExampleStepData } from "@viuch/feature-solutions/serializers/mapSolutionExampleStepData";
import { HintsViewController } from "@viuch/task-solution-view/HintsViewController";

import type { TSolutionSteps } from "@viuch/feature-solutions/service-types/solutionSteps";
import type { TSerializedState } from "@viuch/math-editor";

export class StudentSolutionAttempt {
    readonly number: number;
    readonly createdAt: Date;
    readonly solutionId: number;
    readonly isScored: boolean;
    readonly isHint: boolean;
    readonly totalTimeSeconds: number;
    readonly solution: TSerializedState;
    readonly answers: TSerializedState[];
    readonly analysis: StudentSolutionAttemptAnalysis;
    readonly themeAdvancements: StudentSolutionAttemptThemeAdvancement[];
    readonly hintsController: HintsViewController;

    constructor(
        number: number,
        createdAt: Date,
        solutionId: number,
        isScored: boolean,
        isHint: boolean,
        totalTimeSeconds: number,
        solution: TSerializedState,
        answers: TSerializedState[],
        analysis: StudentSolutionAttemptAnalysis,
        themeAdvancements: StudentSolutionAttemptThemeAdvancement[]
    ) {
        this.number = number;
        this.createdAt = createdAt;
        this.solutionId = solutionId;
        this.isScored = isScored;
        this.isHint = isHint;
        this.totalTimeSeconds = totalTimeSeconds;
        this.solution = solution;
        this.answers = answers.slice();
        this.analysis = analysis;
        this.themeAdvancements = themeAdvancements;
        this.hintsController = new HintsViewController({
            steps: (this.analysis.hint ?? []).map(mapSolutionExampleStepData),
        });
    }
}

export class StudentSolutionAttemptAnalysis {
    readonly themeId: number;
    readonly solution: StudentSolutionAttemptGrade[];
    readonly answers: StudentSolutionAttemptGrade[];
    readonly hint: TSolutionSteps | null;
    readonly timeSpent: number;
    readonly improvement: Improvement | null;

    constructor(
        themeId: number,
        solution: StudentSolutionAttemptGrade[],
        answers: StudentSolutionAttemptGrade[],
        hint: TSolutionSteps | null,
        timeSpent: number,
        improvement: Improvement | null
    ) {
        this.themeId = themeId;
        this.solution = solution;
        this.answers = answers;
        this.hint = hint;
        this.timeSpent = timeSpent;
        this.improvement = improvement;
    }
}

export class StudentSolutionAttemptThemeAdvancement {
    readonly oldKnowledgeLevel: number;
    readonly newKnowledgeLevel: number;
    readonly totalTimeSpent: number;
    readonly createdAt: Date;
    readonly themeAdvancement: ThemeAdvancement;

    constructor(
        oldKnowledgeLevel: number,
        newKnowledgeLevel: number,
        totalTimeSpent: number,
        createdAt: Date,
        themeAdvancement: ThemeAdvancement
    ) {
        this.oldKnowledgeLevel = oldKnowledgeLevel;
        this.newKnowledgeLevel = newKnowledgeLevel;
        this.totalTimeSpent = totalTimeSpent;
        this.createdAt = createdAt;
        this.themeAdvancement = themeAdvancement;
    }
}

export class ThemeAdvancement {
    readonly themeId: number;
    readonly importanceScore: number;
    readonly knowledgeLevel: number;
    readonly averageTime: number;
    readonly state: TThemeAdvancementState;

    constructor(
        themeId: number,
        importanceScore: number,
        knowledgeLevel: number,
        averageTime: number,
        state: TThemeAdvancementState
    ) {
        this.themeId = themeId;
        this.importanceScore = importanceScore;
        this.knowledgeLevel = knowledgeLevel;
        this.averageTime = averageTime;
        this.state = state;
    }
}

export type TThemeAdvancementState = "available" | "unavailable" | "completed";

export class StudentSolutionAttemptGrade {
    readonly isRight: boolean;
    readonly expression: TSerializedState;
    readonly source: unknown;
    readonly themeId: number | null;
    readonly themeName: string | null;

    constructor(
        isValid: boolean,
        expression: TSerializedState,
        source: unknown,
        themeId: number | null,
        themeName: string | null
    ) {
        this.isRight = isValid;
        this.expression = expression;
        this.source = source;
        this.themeId = themeId;
        this.themeName = themeName;
    }
}

export class Improvement {
    readonly oldScore: number;
    readonly newScore: number;
    readonly maxScore: number;

    constructor(oldScore: number, newScore: number, maxScore: number) {
        this.oldScore = oldScore;
        this.newScore = newScore;
        this.maxScore = maxScore;
    }
}
