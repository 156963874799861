import type { BaseFormNode } from "../nodes/BaseFormNode";
import type { TMathArrayControl } from "../types/controls";

import { MathArrayFormNode } from "../nodes/MathArrayFormNode";
import { pathFromRaw } from "../utils/paths";

export function instantiateMathArrayControl(data: TMathArrayControl, parent: BaseFormNode | null) {
    const { path, label, extensions } = data;

    return new MathArrayFormNode(label, parent, pathFromRaw(path), extensions ?? []);
}
