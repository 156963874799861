import { mapIntervals } from "@viuch/feature-instrument-settings/serializers/instruments/intervals";
import { createFigureModels, mapFigurePolygon } from "@viuch/feature-instrument-settings/serializers/mapFigure";
import { mapGraph2DSettings } from "@viuch/feature-instrument-settings/serializers/mapGraph2D";
import { mapMultichoiceField } from "@viuch/feature-instrument-settings/serializers/mapMultichoice";
import { mapAlignmentX, mapAlignmentY } from "@viuch/feature-instrument-settings/serializers/mapPicture";
import { mapTableCell } from "@viuch/feature-instrument-settings/serializers/serializeTable";
import { Figure2D, Figure2DViewConfig } from "@viuch/instrument-figure2d";
import { Figure2DSettings, Figure2DWithCompletionsSettings } from "@viuch/instrument-figure2d-settings/entities";
import { Figure2DSettingsCompletion } from "@viuch/instrument-figure2d-settings/entities/Figure2DSettingsCompletion";
import { Graph1DInstrumentStatement, Graph1DInstrumentStatementDot } from "@viuch/instrument-graph1d/statement";
import { Graph1DInstrumentStatementStrokesInterval } from "@viuch/instrument-graph1d/statement/Graph1DInstrumentStatementInterval";
import { Graph1DInstrumentSettings } from "@viuch/instrument-graph1d-settings/entities";
import { MultichoiceInstrumentSettings } from "@viuch/instrument-multichoice-settings/entities";
import { PictureInstrumentSettingsImpl, PictureInstrumentSettingsWatermark } from "@viuch/instrument-picture-settings";
import {
    TableSelectInstrumentSettingsImpl,
    TableTextInstrumentSettingsImpl,
} from "@viuch/instrument-table-settings/settings";
import { TableViewSettings } from "@viuch/instrument-table-settings/settings/TableViewSettings";
import { createSerializedContainer, tryConvertSimpleToString } from "@viuch/math-editor";
import { revertObject } from "@viuch/shared/utils/data";

import type { BaseTaskInstrumentSettings } from "../../entities/task-instruments-settings/BaseTaskInstrumentSettings";
import type { TTaskInstrumentSettingsResponse } from "@viuch/feature-instrument-settings/service-types/taskInstruments";

import {
    Figure2DWithCompletionsTaskInstrumentSettings,
    Graph1DTaskInstrumentSettings,
    Graph2DTaskInstrumentSettings,
    IntervalsTaskInstrumentSettings,
    MultichoiceTaskInstrumentSettings,
    PictureTaskInstrumentSettings,
    TableSelectTaskInstrumentSettings,
    TableTextTaskInstrumentSettings,
} from "../../entities/task-instruments-settings/BaseTaskInstrumentSettings";

export function mapTaskInstrumentSettings(data: TTaskInstrumentSettingsResponse): BaseTaskInstrumentSettings {
    const { id, settings } = data;
    const { instrument_type, settings_uuid } = settings;

    switch (instrument_type) {
        case "interval": {
            return new IntervalsTaskInstrumentSettings(id, settings_uuid, mapIntervals(settings));
        }
        case "graph1d": {
            const graph = new Graph1DInstrumentStatement(
                tryConvertSimpleToString(settings.axis_name) ?? "",
                settings.points.map(({ name, value, position, is_point, is_name_visible, is_value_visible }) => {
                    return new Graph1DInstrumentStatementDot({
                        name,
                        value,
                        position,
                        form: is_point ? "circle" : "line",
                        isProtected: !is_point,
                        isNameVisible: is_name_visible ?? true,
                        isValueVisible: is_value_visible ?? true,
                    });
                }),
                settings.coverage?.map(
                    (d) => new Graph1DInstrumentStatementStrokesInterval(d.color, d.left, d.right, d.side, d.direction)
                ) ?? []
            );

            return new Graph1DTaskInstrumentSettings(id, settings_uuid, new Graph1DInstrumentSettings(graph));
        }

        case "table": {
            const { title, render_in_frame, cells, hidden_cells = [], view_settings } = settings;

            const getIsHidden = (row: number, col: number): boolean =>
                hidden_cells.some((c) => c.row === row && c.col === col);

            const table = new TableTextInstrumentSettingsImpl(
                cells.map((row, i) => row.map((cell, j) => mapTableCell(cell, getIsHidden(i, j)))),
                title ?? createSerializedContainer(),
                render_in_frame ?? false,
                new TableViewSettings(view_settings ?? {})
            );
            return new TableTextTaskInstrumentSettings(id, settings_uuid, table);
        }

        case "table-select": {
            const { title, cells, hidden_cells = [], render_in_frame, view_settings } = settings;

            const getIsHidden = (row: number, col: number): boolean =>
                hidden_cells.some((c) => c.row === row && c.col === col);

            const table = new TableSelectInstrumentSettingsImpl(
                cells.map((row, i) => row.map((cell, j) => mapTableCell(cell, getIsHidden(i, j)))),
                title ?? createSerializedContainer(),
                render_in_frame ?? false,
                new TableViewSettings(view_settings ?? {})
            );

            return new TableSelectTaskInstrumentSettings(id, settings_uuid, table);
        }
        case "multichoice":
            const { interface: _interface, number_of_items, items } = settings;

            const multichoice = new MultichoiceInstrumentSettings(
                items.map((field) => mapMultichoiceField(field)),
                _interface,
                number_of_items
            );

            return new MultichoiceTaskInstrumentSettings(id, settings_uuid, multichoice);
        case "picture":
            const picture = new PictureInstrumentSettingsImpl(
                settings.points.map(
                    (point) =>
                        new PictureInstrumentSettingsWatermark({
                            alignment: {
                                vertical: revertObject(mapAlignmentY)[point.align_y],
                                horizontal: revertObject(mapAlignmentX)[point.align_x],
                            },
                            color: point.color,
                            position: { left: point.x, top: point.y },
                            templateValue: point.value,
                        })
                ),
                settings.picture ? { url: settings.picture.url, uuid: settings.picture.id } : null,
                settings.caption,
                settings.alt_text
            );
            return new PictureTaskInstrumentSettings(id, settings_uuid, picture);
        case "figure2d": {
            const {
                settings_uuid,
                figure: { completions, initial },
                view_settings,
            } = settings;

            const figure = new Figure2DWithCompletionsSettings({
                initial: new Figure2DSettings({
                    figureEditorInstance: new Figure2D({
                        settings: Figure2DViewConfig.createDefault({
                            initialScale: settings.view_settings?.initialScale ?? 1,
                            initialOffsetX: settings.view_settings?.initialPosition?.x ?? 0.5,
                            initialOffsetY: settings.view_settings?.initialPosition?.y ?? 0.5,
                        }),
                        models: [...createFigureModels(initial)],
                    }),
                    polygons: initial.polygons.map(mapFigurePolygon),
                    systems: initial.system,
                }),
                completions: completions.map(
                    (completion) =>
                        new Figure2DSettingsCompletion({
                            state: new Figure2DSettings({
                                figureEditorInstance: new Figure2D({
                                    settings: Figure2DViewConfig.createDefault(),
                                    models: [...createFigureModels(completion.state)],
                                }),
                                polygons: completion.state.polygons.map(mapFigurePolygon),
                                systems: completion.state.system,
                            }),
                            hint: completion.hint,
                        })
                ),
                viewSettings: {
                    initialPosition: view_settings?.initialPosition ?? { x: 0.5, y: 0.5 },
                    initialScale: view_settings?.initialScale ?? 1,
                },
            });

            return new Figure2DWithCompletionsTaskInstrumentSettings(id, settings_uuid, figure);
        }
        case "graph2d": {
            const { settings_uuid } = settings;

            const graphSettings = mapGraph2DSettings(settings);

            return new Graph2DTaskInstrumentSettings(id, settings_uuid, graphSettings);
        }
    }
}
