import { mapTimeToSeconds } from "@viuch/shared/serialization/times";

import type {
    TContentAdminStatsItem,
    TRawContentAdminStatsItem,
    TContentAdminStatsByThemesItem,
    TRawContentAdminStatsByThemesItem,
    TRawContentAdminStatsByThemesRecord,
} from "./StatsInfoService.types";

import { AdminStatsItemKindEnum } from "./StatsInfoService.types";

export function mapContentAdminStatsItemKind(data: TRawContentAdminStatsItem): AdminStatsItemKindEnum {
    if (!data.theme) return AdminStatsItemKindEnum.cancelled;

    switch (data.kind) {
        case "create":
            return AdminStatsItemKindEnum.created;
        case "update":
            return AdminStatsItemKindEnum.updated;
        default:
            return AdminStatsItemKindEnum.unknown;
    }
}

export function formatContentAdminStatsKind(kind: AdminStatsItemKindEnum) {
    switch (kind) {
        case AdminStatsItemKindEnum.updated:
            return "Обновлено";
        case AdminStatsItemKindEnum.created:
            return "Создано";
        case AdminStatsItemKindEnum.cancelled:
            return "Не создано";
        case AdminStatsItemKindEnum.viewed:
            return "Просмотрено";
        default:
            return "Неизвестно";
    }
}

export function mapContentAdminStatsItem(data: TRawContentAdminStatsItem): TContentAdminStatsItem {
    const { id, created_at, finished_at, theme } = data;

    return {
        id,
        kind: mapContentAdminStatsItemKind(data),
        createdAt: created_at ? new Date(created_at) : null,
        finishedAt: finished_at ? new Date(finished_at) : null,
        theme: theme
            ? {
                  id: theme.id,
                  name: theme.name,
              }
            : null,
    };
}

function mapContentAdminStatsByThemesItemKind(
    record: TRawContentAdminStatsByThemesRecord,
    withTheme: boolean
): AdminStatsItemKindEnum {
    if (!record.is_completed && withTheme) {
        return AdminStatsItemKindEnum.viewed;
    } else if (!record.is_completed) {
        return AdminStatsItemKindEnum.cancelled;
    }

    switch (record.kind) {
        case "create":
            return AdminStatsItemKindEnum.created;
        case "update":
            return AdminStatsItemKindEnum.updated;
        default:
            return AdminStatsItemKindEnum.unknown;
    }
}

export function mapContentAdminStatsByThemes(data: TRawContentAdminStatsByThemesItem): TContentAdminStatsByThemesItem {
    const { theme, records, tasks, total_time } = data;

    return {
        theme: !!theme ? { id: theme.id, name: theme.name } : null,
        totalTime: mapTimeToSeconds(data.total_time),
        records: records.map((record) => ({
            id: record.id,
            createdAt: record.created_at ? new Date(record.created_at) : null,
            finishedAt: record.finished_at ? new Date(record.finished_at) : null,
            isCompleted: record.is_completed,
            kind: mapContentAdminStatsByThemesItemKind(record, !!theme),
        })),
        tasks,
    };
}
