import { action, makeObservable, observable } from "mobx";

import type { QuarterShort } from "./GlobalStudyPlanQuarter";
import type { ThemeWithDuration } from "./ThemeWithDuration";
import type { TGradeShort } from "../grades";
import type { TSubjectShort } from "../hierarchy/Subject";

export class GlobalStudyPlan {
    @observable id: number;
    @observable name: string;
    @observable grade: TGradeShort | null;
    @observable.ref subject: TSubjectShort | null;
    @observable quarters: QuarterShort[];

    constructor(
        id: number,
        name: string,
        grade: TGradeShort | null,
        subject: TSubjectShort | null,
        quarters: QuarterShort[]
    ) {
        this.id = id;
        this.name = name;
        this.grade = grade;
        this.subject = subject;
        this.quarters = quarters.slice();

        this.getTheme = this.getTheme.bind(this);

        makeObservable(this);
    }

    clone(): GlobalStudyPlan {
        const { id, name, grade, quarters, subject } = this;
        return new GlobalStudyPlan(
            id,
            name,
            grade,
            subject,
            quarters.map((q) => q.clone())
        );
    }

    getTheme(id: number): ThemeWithDuration | null {
        for (const quarter of this.quarters) {
            for (const theme of quarter.themes) {
                if (theme.id === id) {
                    return theme;
                }
            }
        }

        return null;
    }

    @action.bound
    setName(name: string) {
        this.name = name;
    }

    @action.bound
    setGrade(grade: TGradeShort) {
        this.grade = grade;
    }

    @action.bound
    setSubject(subject: TSubjectShort) {
        this.subject = subject;
    }

    @action.bound
    addQuarter(quarter: QuarterShort) {
        this.quarters.push(quarter);
    }

    @action.bound
    removeQuarter(quarter: QuarterShort) {
        this.quarters.remove(quarter);
    }
}
