import axios, { CanceledError } from "axios";
import { makeObservable, observable } from "mobx";

import type { TComplexEditorUnion } from "@viuch/complex-editor";
import type { TSerializedContainer } from "@viuch/math-editor/types";

import { isArrayOfStrings } from "../../utils";

export abstract class ServiceError extends Error {
    protected constructor() {
        super();
    }
}

export class ServerError extends ServiceError {
    payload: unknown;

    constructor(payload: unknown) {
        super();

        this.payload = payload;
    }
}

export class RequestError<E = unknown> extends ServiceError {
    readonly status: number | undefined;

    @observable readonly data?: E;

    constructor(data: E | undefined, status?: number) {
        super();
        this.data = data;
        this.status = status;

        makeObservable(this);
    }
}

export function isBadRequestError<T = any>(e: unknown): e is RequestError<T> {
    return e instanceof RequestError;
}

/**
 * @throws {RequestError}
 * @throws {ServerError}
 */
export function throwServiceError<T>(e: unknown): never {
    if (e instanceof RequestError) {
        throw e;
    }
    if (axios.isAxiosError<GetError<T>>(e)) {
        if (e.response) {
            throw new RequestError<GetError<T>>(e.response.data, e.response.status);
        }
    }
    if (e instanceof CanceledError) {
        throw e;
    }
    throw new ServerError(e);
}

export type GetError<T> = undefined | GetErrorValue<T> | GetErrorArray<T> | GetErrorObject<T>;

type ErrorValue = [string];
type ValueTypes = string | number | TSerializedContainer | TComplexEditorUnion;
type GetErrorInternal<T> = T | (T & ErrorValue);

type GetErrorValue<T> = T extends ValueTypes ? ErrorValue : never;
type GetErrorArray<T> = T extends Array<any> ? GetErrorInternal<{ [K: number]: GetError<T[number]> }> : never;
type GetErrorObject<T> = T extends Record<string, any>
    ? GetErrorInternal<{ [Key in keyof T]?: GetError<T[Key]> }>
    : never;

export function showErrorText(error?: GetError<any>): string | undefined {
    if (isArrayOfStrings(error)) {
        return error.join();
    }
}
