import { getAngles, normalizeAngle } from "@viuch/geometry-lib/angles";
import { areSamePoints, findPerpendicularPoint, isPointOnFragment } from "@viuch/geometry-lib/check-geometry";
import { createAngle, createBasis, fragmentToLine } from "@viuch/geometry-lib/factories";

import type { BaseModel } from "../../models";
import type { IModelVisitor } from "../../models/BaseModel";
import type { TBasis, TFragment, TFragmentWithPoints, TPoint } from "@viuch/geometry-lib/types";

import { getRealBasis } from "../../models/geometry/utils";

export type TAvailableMedian = { vertex: TPoint; medianPoint: TPoint; base: TFragment };

export type TAvailableAltitude = {
    vertex: TPoint;
    altitudePoint: TPoint;
    basis: TBasis;
};

export function getAvailableAltitudes(vertex: TPoint, models: BaseModel[]): TAvailableAltitude[] {
    const getAltitudesVisitor = createGetAltitudesVisitor(vertex);
    return models.flatMap<TAvailableAltitude>((model) => model.accept(getAltitudesVisitor));
}

function createGetAltitudesVisitor(vertex: TPoint): IModelVisitor<TAvailableAltitude[]> {
    return {
        withFragment: (fragment) => {
            const altitudePoint = findPerpendicularPoint(fragment, vertex);
            if (areSamePoints(altitudePoint, vertex)) return [];
            return [{ vertex, altitudePoint, basis: { fragment } }];
        },
        withFragmentDashed: (fragment) => {
            const altitudePoint = findPerpendicularPoint(fragment, vertex);
            if (areSamePoints(altitudePoint, vertex)) return [];
            return [{ vertex, altitudePoint, basis: { fragment } }];
        },
        withVector: (vector) => {
            const fragment = vector.toFragment();
            const altitudePoint = findPerpendicularPoint(fragment, vertex);
            if (areSamePoints(altitudePoint, vertex)) return [];
            return [{ vertex, altitudePoint, basis: { fragment } }];
        },
        withVectorDashed: (vector) => {
            const fragment = vector.toFragment();
            const altitudePoint = findPerpendicularPoint(fragment, vertex);
            if (areSamePoints(altitudePoint, vertex)) return [];
            return [{ vertex, altitudePoint, basis: { fragment } }];
        },
        withParallelFragment: (fragment) => {
            const altitudePoint = findPerpendicularPoint(fragment, vertex);
            if (areSamePoints(altitudePoint, vertex)) return [];
            return [{ vertex, altitudePoint, basis: { fragment } }];
        },
        withLine: (line) => {
            const altitudePoint = findPerpendicularPoint(line, vertex);

            if (areSamePoints(altitudePoint, vertex)) return [];
            return areSamePoints(altitudePoint, vertex) ? [] : [{ vertex, altitudePoint, basis: { fragment: line } }];
        },
        withConstraintLine: (line) => {
            const basis = createBasis({ a: line.constraintA, b: line.constraintB }, line.vertex, line.offsetAngle);
            const realBasis = getRealBasis(basis);
            const altitudePoint = findPerpendicularPoint(realBasis, vertex);

            if (areSamePoints(altitudePoint, vertex)) return [];
            return [{ vertex, altitudePoint, basis }];
        },
        withBisection: (bisection) => {
            const { bisectionFragment } = bisection;
            if (!bisectionFragment) return [];

            const bisectionLine = fragmentToLine(bisectionFragment);
            const altitudePoint = findPerpendicularPoint(bisectionLine, vertex);

            if (areSamePoints(altitudePoint, vertex)) return [];
            return [{ vertex, basis: { fragment: bisectionFragment }, altitudePoint }];
        },
        withMedian: (median) => {
            const { medianFragment } = median;

            const medianLine = fragmentToLine(medianFragment);
            const altitudePoint = findPerpendicularPoint(medianLine, vertex);

            if (areSamePoints(altitudePoint, vertex)) return [];
            return [{ vertex, basis: { fragment: medianFragment }, altitudePoint }];
        },
        withAltitude: (altitude) => {
            const { altitudeFragment } = altitude;
            const altitudeLine = fragmentToLine(altitudeFragment);
            const altitudePoint = findPerpendicularPoint(altitudeLine, vertex);

            if (areSamePoints(altitudePoint, vertex)) return [];
            return [{ vertex, basis: { fragment: altitudeFragment }, altitudePoint }];
        },
        withTangentLine: (tangent) => {
            if (!tangent.checkIsTangent()) return [];
            const altitudePoint = findPerpendicularPoint(tangent.virtualTangentLine, vertex);
            if (areSamePoints(altitudePoint, vertex)) return [];
            return [{ vertex, altitudePoint, basis: { fragment: tangent.virtualTangentLine } }];
        },
        withMiddleLine: ({ middleLineFragment }) => {
            const altitudePoint = findPerpendicularPoint(middleLineFragment, vertex);
            if (areSamePoints(altitudePoint, vertex)) return [];
            return [{ vertex, altitudePoint, basis: { fragment: middleLineFragment } }];
        },
        withTangentLineOnCircle: (tangent) => {
            if (!tangent.hasTangent()) return [];
            const altitudePoint = findPerpendicularPoint(tangent.virtualTangentFragment, vertex);
            if (areSamePoints(altitudePoint, vertex)) return [];
            return [{ vertex, altitudePoint, basis: { fragment: tangent.virtualTangentFragment } }];
        },
        withPoint: () => [],
        withLabelPoint: () => [],
        withLabelFragment: () => [],
        withLabelAngle: () => [],
        withComputedIntersectionPoints: () => [],
        withEqualAngles: () => [],
        withEqualSegments: () => [],
        withEllipse: () => [],
        withInscribedCircle: () => [],
        withCircumscribedCircle: () => [],
    };
}

export function getAltitudeAngles({ basis, vertex, altitudePoint }: TAvailableAltitude) {
    const angleEnd = basis.offset || basis.fragment.a;
    return getAngles(normalizeAngle(createAngle(vertex, altitudePoint, angleEnd), true));
}

/**
 * Расставляет указанные точки на отрезках
 */
export function arrangeDotsToFragments(fragments: TFragment[], points: TPoint[]): TFragmentWithPoints[] {
    return fragments.map<TFragmentWithPoints>((fragment) => ({
        fragment,
        points: points.filter(isPointOnFragment.getFragmentFilter(fragment)),
    }));
}

/**
 * Составляет все возможные отрезки из указанных точек
 */
export function mixPointsToMakeFragments(points: TPoint[]): TFragment[] {
    if (points.length < 2) return [];

    const fragments: TFragment[] = [];

    for (let i = 0; i < points.length - 1; i++) {
        for (let j = i + 1; j < points.length; j++) {
            const a = points[i];
            const b = points[j];
            fragments.push({ a, b });
        }
    }

    return fragments;
}
