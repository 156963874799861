import type {
    TRawStudentSolution,
    TRawStudentSolutionAttempt,
    TRawStudentSolutionResponse,
    TRawTheme,
    TRawVerificationResult,
} from "./types";
import type { StudentSolution } from "../../entities/solutions/StudentSolution";
import type { StudentSolutionAttempt } from "../../entities/solutions/StudentSolutionAttempt";

import { agent } from "../../agent";
import { throwServiceError } from "../base/RequestError";

import { mapStudentSolution, mapStudentSolutionAttempt } from "./utils";

export class StudentSolutionsService {
    async solutionsByTheme(
        studentId: string | number,
        themeId: number | null,
        onlyUnrecognized?: boolean,
        onlyCompleted?: boolean,
        hasNoTheme?: boolean,
        abortSignal?: AbortSignal
    ): Promise<StudentSolution[]> {
        try {
            const { data } = await agent.get<TRawStudentSolution[]>(`/solutions/`, {
                params: {
                    student_in: studentId,
                    theme_in: themeId,
                    has_unrecognized_lines: onlyUnrecognized ? "true" : void 0,
                    is_accounted_for_theme_score: onlyCompleted,
                    has_no_theme: hasNoTheme,
                },
                signal: abortSignal,
            });

            return data.map(mapStudentSolution);
        } catch (e) {
            throwServiceError(e);
        }
    }

    async themesWithSolutions(
        studentId: string | number,
        onlyUnrecognized?: boolean,
        onlyCompleted?: boolean,
        signal?: AbortSignal
    ): Promise<TRawTheme[]> {
        try {
            const { data } = await agent.get<TRawTheme[]>("/solutions/themes/", {
                params: {
                    student_in: studentId,
                    has_unrecognized_lines: onlyUnrecognized ? "true" : void 0,
                    is_accounted_for_theme_score: onlyCompleted,
                },
                signal,
            });

            return data;
        } catch (e) {
            throwServiceError(e);
        }
    }

    async solutionById(solutionId: string | number): Promise<StudentSolution> {
        try {
            const { data } = await agent.get<TRawStudentSolution>(`/solutions/${solutionId}/`);

            return mapStudentSolution(data);
        } catch (e) {
            throwServiceError(e);
        }
    }

    async adminSolutionsInVariantRaw(variantId: number): Promise<TRawStudentSolutionResponse[]> {
        try {
            const params = {
                has_student: "false",
                variant_in: variantId,
            };
            const { data } = await agent.get<TRawStudentSolutionResponse[]>(`/solutions/`, { params });

            return data;
        } catch (e) {
            throwServiceError(e);
        }
    }

    async createAdminSolutionRaw(variantId: number): Promise<TRawStudentSolutionResponse> {
        try {
            const request = { variant: variantId };
            const { data } = await agent.post<TRawStudentSolutionResponse>(`/solutions/`, request);

            return data;
        } catch (e) {
            throwServiceError(e);
        }
    }

    async updateAdminSolutionRaw(
        solutionId: number,
        solution: TRawStudentSolution
    ): Promise<TRawStudentSolutionResponse> {
        try {
            const { data } = await agent.patch<TRawStudentSolutionResponse>(`/solutions/${solutionId}/`, solution);

            return data;
        } catch (e) {
            throwServiceError(e);
        }
    }

    async verifySolution(solutionId: number): Promise<TRawVerificationResult> {
        try {
            const { data } = await agent.get<TRawVerificationResult>(`/solutions/${solutionId}/verify/`);

            return data;
        } catch (e) {
            throwServiceError(e);
        }
    }

    async deleteSolutionById(solutionId: number): Promise<void> {
        try {
            await agent.delete(`/solutions/${solutionId}/`);
        } catch (e) {
            throwServiceError(e);
        }
    }

    async solutionAttempts(solutionId: string | number): Promise<StudentSolutionAttempt[]> {
        try {
            const { data } = await agent.get<TRawStudentSolutionAttempt[]>(`/solution_attempts/`, {
                params: { solution_in: solutionId },
            });

            return data.map(mapStudentSolutionAttempt);
        } catch (e) {
            throwServiceError(e);
        }
    }
}
