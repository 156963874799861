import React from "react";

import type { CharModel } from "./CharModel";
import type { TElementProps } from "../../types/element";

import { useMathEditorElementRef } from "../../hooks";

import styles from "./CharElement.module.scss";

export const CharElement: React.VFC<TElementProps<CharModel>> = ({ elementModel }) => {
    const ref = useMathEditorElementRef(elementModel);

    return (
        <span
            className={styles.char}
            ref={ref}
        >
            {elementModel.char}
        </span>
    );
};
