import { makeObservable, observable } from "mobx";
import { createTransformer } from "mobx-utils";

import { assert } from "@viuch/utils/debug";

import type { BaseElement } from "../../elements";
import type { Figure2DController } from "../../Figure2DController";
import type { ToolbarMenu } from "../../toolbar";
import type { IUserPointer } from "../../viewport/types";
import type { TPoint } from "@viuch/geometry-lib/types";
import type React from "react";

import { DotElement } from "../../elements/dot";
import { ElementColor } from "../../elements/ElementColor";
import { LineElement } from "../../elements/line";
import { FragmentModel } from "../../models/fragment";
import { LabelPointModel } from "../../models/label-point";
import { PointModel } from "../../models/point";
import { ButtonStates } from "../../toolbar";
import { TooltipMenu } from "../../toolbar/tooltip";
import { createDefaultToolbarMenuBuilder } from "../../utils/toolbar";
import { BaseFlow } from "../BaseFlow";
import { createMapModelToElements, opacifyColor } from "../utils";

export class TrapezeFlow extends BaseFlow {
    flow:
        | { drawing: boolean } & (
              | { stage: 1; a?: TPoint }
              | ({ stage: 2; b?: TPoint } & Record<"a", TPoint>)
              | ({ stage: 3; c?: TPoint } & Record<"a" | "b", TPoint>)
              | ({ stage: 4; d?: TPoint } & Record<"a" | "b" | "c", TPoint>)
          );

    constructor(data: Figure2DController) {
        super(data);

        this.flow = { stage: 1, drawing: false };

        makeObservable(this, {
            flow: observable,
        });
    }

    attach() {
        this.snap.addViewportGrid().addFigureModelsPoints();
        this.viewport.disable();
    }

    dispose() {
        this.snap.clean();
    }

    static create(data: Figure2DController) {
        return new TrapezeFlow(data);
    }

    get tempElements(): BaseElement[] {
        const { stage, drawing } = this.flow;

        const overrideRenderOrder = 0;
        const color = ElementColor.Building;
        const currentColor = drawing ? ElementColor.Selected : ElementColor.Building;
        const utilColor = ElementColor.Util;

        const { scale } = this.viewport;

        if (stage === 1 || !this.flow.b) {
            const { a } = this.flow;
            if (!a) return [];

            const b = { x: a.x + 0.1 / scale, y: a.y };
            const offset = (b.x - a.x) / 5;
            const height = 0.06 / scale;
            const c = { x: a.x + offset, y: a.y + height };
            const d = { x: b.x - offset, y: b.y + height };

            return [
                new DotElement({
                    id: `temp_a`,
                    x: a.x,
                    y: a.y,
                    color: currentColor,
                    overrideRenderOrder,
                }),
                ...[
                    [a, b],
                    [c, d],
                    [a, c],
                    [b, d],
                ].map(
                    ([a, b], i) =>
                        new LineElement({
                            id: `temp_line${i}`,
                            x1: a.x,
                            y1: a.y,
                            x2: b.x,
                            y2: b.y,
                            color: utilColor,
                            overrideRenderOrder,
                            thin: true,
                        })
                ),
            ];
        }

        if (stage === 2 || !this.flow.c) {
            const { a, b } = this.flow;

            const offset = (b.x - a.x) * 0.2;
            const height = 0.06 / scale;
            const c = { x: a.x + offset, y: a.y + height };
            const d = { x: b.x - offset, y: b.y + height };

            return [
                new DotElement({
                    id: `temp_a`,
                    x: a.x,
                    y: a.y,
                    color,
                    overrideRenderOrder,
                }),
                new DotElement({
                    id: `temp_b`,
                    x: b.x,
                    y: b.y,
                    color: currentColor,
                    overrideRenderOrder,
                }),
                new LineElement({
                    id: `temp_ab`,
                    x1: a.x,
                    y1: a.y,
                    x2: b.x,
                    y2: b.y,
                    color: currentColor,
                    overrideRenderOrder,
                }),
                ...[
                    [c, d],
                    [a, c],
                    [b, d],
                ].map(
                    ([a, b], i) =>
                        new LineElement({
                            id: `temp_line${i}`,
                            x1: a.x,
                            y1: a.y,
                            x2: b.x,
                            y2: b.y,
                            color: utilColor,
                            overrideRenderOrder,
                            thin: true,
                        })
                ),
            ];
        }

        if (stage === 3 || !this.flow.d) {
            const { c } = this.flow;
            let { a, b } = this.flow;

            const offset = c.x - a.x;
            const d = { x: b.x - offset, y: c.y };
            if (d.x - c.x < 0) {
                [a, b] = [b, a];
            }

            return [
                new DotElement({
                    id: `temp_a`,
                    x: a.x,
                    y: a.y,
                    color,
                    overrideRenderOrder,
                }),
                new DotElement({
                    id: `temp_b`,
                    x: b.x,
                    y: b.y,
                    color,
                    overrideRenderOrder,
                }),
                new LineElement({
                    id: `temp_ab`,
                    x1: a.x,
                    y1: a.y,
                    x2: b.x,
                    y2: b.y,
                    color,
                    overrideRenderOrder,
                }),
                new DotElement({
                    id: `temp_c`,
                    x: c.x,
                    y: c.y,
                    color: currentColor,
                    overrideRenderOrder,
                }),
                new LineElement({
                    id: `temp_ac`,
                    x1: a.x,
                    y1: a.y,
                    x2: c.x,
                    y2: c.y,
                    color: currentColor,
                    overrideRenderOrder,
                }),
                new DotElement({
                    id: `temp_d`,
                    x: d.x,
                    y: d.y,
                    color: utilColor,
                    overrideRenderOrder,
                }),
                new LineElement({
                    id: `temp_cd`,
                    x1: c.x,
                    y1: c.y,
                    x2: d.x,
                    y2: d.y,
                    color: utilColor,
                    overrideRenderOrder,
                    thin: true,
                }),
                new LineElement({
                    id: `temp_bd`,
                    x1: b.x,
                    y1: b.y,
                    x2: d.x,
                    y2: d.y,
                    color: utilColor,
                    overrideRenderOrder,
                    thin: true,
                }),
            ];
        }

        if (this.flow.d) {
            const { a, b } = this.flow;
            let { c, d } = this.flow;
            assert(a);

            const rightOffset = c.x - a.x;
            const dRight = { x: b.x - rightOffset, y: c.y };

            if (d.x - c.x < 0) {
                [c, d] = [d, c];
            }

            return [
                new DotElement({
                    id: `temp_a`,
                    x: a.x,
                    y: a.y,
                    color,
                    overrideRenderOrder,
                }),
                new DotElement({
                    id: `temp_b`,
                    x: b.x,
                    y: b.y,
                    color,
                    overrideRenderOrder,
                }),
                new DotElement({
                    id: `temp_c`,
                    x: c.x,
                    y: c.y,
                    color,
                    overrideRenderOrder,
                }),
                new LineElement({
                    id: `temp_ab`,
                    x1: a.x,
                    y1: a.y,
                    x2: b.x,
                    y2: b.y,
                    color,
                    overrideRenderOrder,
                }),
                new LineElement({
                    id: `temp_ac`,
                    x1: a.x,
                    y1: a.y,
                    x2: c.x,
                    y2: c.y,
                    color,
                    overrideRenderOrder,
                }),
                new DotElement({
                    id: `temp_d_right`,
                    x: dRight.x,
                    y: dRight.y,
                    color: utilColor,
                    overrideRenderOrder: 1,
                }),
                new LineElement({
                    id: `temp_cd_right`,
                    x1: c.x,
                    y1: c.y,
                    x2: dRight.x,
                    y2: dRight.y,
                    color: utilColor,
                    overrideRenderOrder: 1,
                    thin: true,
                }),
                new DotElement({
                    id: `temp_d`,
                    x: d.x,
                    y: d.y,
                    color: currentColor,
                    overrideRenderOrder,
                }),
                new LineElement({
                    id: `temp_bd`,
                    x1: b.x,
                    y1: b.y,
                    x2: d.x,
                    y2: d.y,
                    color: currentColor,
                    overrideRenderOrder,
                }),
                new LineElement({
                    id: `temp_cd`,
                    x1: c.x,
                    y1: c.y,
                    x2: d.x,
                    y2: d.y,
                    color: currentColor,
                    overrideRenderOrder,
                }),
            ];
        }

        return [];
    }

    private render = createTransformer(createMapModelToElements(opacifyColor));

    protected renderElements(): BaseElement[] {
        return [...this.figure.models.flatMap(this.render), ...this.tempElements];
    }

    override handleViewportPointerEvent(e: React.PointerEvent, pointer: IUserPointer): void {
        if (e.type === "pointercancel") return this.nextFlow();
        if (!e.isPrimary) return;

        const point = this.snap.snap(pointer.canvas, pointer.canvas);

        this.moveCurrentPoint(e, point);
        this.startDrawing(e);
        this.endDrawing(e);
    }

    private moveCurrentPoint(e: React.PointerEvent, point: TPoint) {
        if (e.type === "pointermove" || e.type === "pointerdown") {
            switch (this.flow.stage) {
                case 1:
                    this.flow.a = point;
                    break;
                case 2:
                    this.flow.b = { x: point.x, y: this.flow.a.y };
                    break;
                case 3:
                    this.flow.c = point;
                    break;
                case 4:
                    this.flow.d = { x: point.x, y: this.flow.c.y };
                    break;
            }
        }
    }

    private startDrawing(e: React.PointerEvent) {
        if (e.type === "pointerdown") {
            this.flow.drawing = true;
        }
    }

    private endDrawing(e: React.PointerEvent) {
        if (e.type === "pointerup") {
            this.flow.drawing = false;
            switch (this.flow.stage) {
                case 1: {
                    const { a } = this.flow;
                    assert(a);
                    this.flow = { stage: 2, a, drawing: false };
                    break;
                }
                case 2: {
                    let { a, b } = this.flow;
                    assert(b);
                    if (b.x - a.x < 0) {
                        [a, b] = [b, a];
                    }
                    this.flow = { stage: 3, a, b, drawing: false };
                    break;
                }
                case 3: {
                    const { a, b, c } = this.flow;
                    assert(c);
                    this.flow = { stage: 4, a, b, c, drawing: false };
                    break;
                }
                case 4: {
                    const { c, d } = this.flow;
                    assert(d);
                    if (d.x - c.x < 0) {
                        [this.flow.c, this.flow.d] = [d, c];
                    }
                    this.checkAndSaveModel();
                    break;
                }
            }
        }
    }

    private checkAndSaveModel() {
        if (this.flow.stage !== 4 || !this.flow.d) return;
        let { a, b, c, d } = this.flow;
        if (b.x - a.x < 0) [a, b] = [b, a];
        if (d.x - c.x < 0) [c, d] = [d, c];

        this.figure.insertModels(function* () {
            yield FragmentModel.create({ a, b, style: null });
            yield FragmentModel.create({ a: c, b: d, style: null });
            yield FragmentModel.create({ a, b: c, style: null });
            yield FragmentModel.create({ a: b, b: d, style: null });

            for (const point of [a, b, c, d]) {
                yield PointModel.create({ ...point, style: null });
                yield LabelPointModel.createNext(point, this.figure);
            }
        }, this);

        this.nextFlow();
    }

    override getToolbarMenu(): ToolbarMenu {
        return createDefaultToolbarMenuBuilder()
            .setButtonIcon("trapeze", ["squares"])
            .setButtonState(ButtonStates.active, ["squares"])
            .build();
    }

    override getTooltipMenu(): TooltipMenu | null {
        let text: string;
        switch (this.flow.stage) {
            case 1:
                text = "Выберите первую точку одного основания трапеции";
                break;
            case 2:
                text = "Выберите вторую точку одного основания трапеции";
                break;
            case 3:
                text = "Выберите первую точку другого основания трапеции";
                break;
            case 4:
                text = "Выберите вторую точку другого основания трапеции";
                break;
        }

        return new TooltipMenu(text);
    }
}
