import type { TAnyAction } from "../types";

export type TDigitAction = {
    type: "digit";
    digit: number;
};

export function createDigitAction(digit: number): TDigitAction {
    return { type: "digit", digit };
}

export function isDigitAction(action: TAnyAction): action is TDigitAction {
    return action.type === "digit";
}
