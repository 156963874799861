import type {
    TRawSubjectMeta,
    TPagedResult,
    TRawSectionMeta,
    TRawSubsectionMeta,
    TRawThemeMeta,
    TRawSynopsisMeta,
    TRawExamMeta,
    TRawBlogMeta,
} from "./types";

import { agent } from "../../agent";
import { throwServiceError } from "../base/RequestError";

export class IndexingService {
    private _getPageMetaListPaged = async <T>(path: string, pageIndex: number, resultsOnPage: number) => {
        try {
            const params = { limit: resultsOnPage, offset: pageIndex * resultsOnPage };

            const { data } = await agent.get<TPagedResult<T>>(`/${path}/meta_list/`, { params });

            return data;
        } catch (e) {
            throwServiceError(e);
        }
    };

    private _createGetPageMetaListPaged =
        <T>(path: string) =>
        (pageIndex: number, resultsOnPage: number) =>
            this._getPageMetaListPaged<T>(path, pageIndex, resultsOnPage);

    getSubjectsMetaPaged = this._createGetPageMetaListPaged<TRawSubjectMeta>("subjects_new");
    getSectionsMetaPaged = this._createGetPageMetaListPaged<TRawSectionMeta>("sections_new");
    getSubsectionsMetaPaged = this._createGetPageMetaListPaged<TRawSubsectionMeta>("subsections");
    getThemesMetaPaged = this._createGetPageMetaListPaged<TRawThemeMeta>("themes_new");
    getSynopsesMetaPaged = this._createGetPageMetaListPaged<TRawSynopsisMeta>("synopses");
    getExamsMetaPaged = this._createGetPageMetaListPaged<TRawExamMeta>("exams");
    getBlogsMetaPaged = this._createGetPageMetaListPaged<TRawBlogMeta>("blog");

    private _createUpdateMetaTags =
        <T extends string | null = string>(path: string): TUpdateFn<T> =>
        async (id, meta_title, meta_description) => {
            try {
                const data = { meta_title, meta_description };

                await agent.patch(`/${path}/${id}/`, data);
            } catch (e) {
                throwServiceError(e);
            }
        };

    updateSubjectMetaTags = this._createUpdateMetaTags("subjects_new");
    updateSectionMetaTags = this._createUpdateMetaTags("sections_new");
    updateSubsectionMetaTags = this._createUpdateMetaTags("subsections");
    updateThemeMetaTags = this._createUpdateMetaTags<string | null>("themes_new");
    updateSynopsisMetaTags = this._createUpdateMetaTags("synopses");
    updateExamMetaTags = this._createUpdateMetaTags("exams");
    updateBlogMetaTags = this._createUpdateMetaTags("blog");
}

type TUpdateFn<T extends string | null> = (entityId: number, metaTitle: T, metaDescription: T) => Promise<void>;
