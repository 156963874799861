import type { ICurlyBracketConfig } from "../types";

export function defaultSquareBracketConfig(height: number): ICurlyBracketConfig {
    if (height <= 32) {
        return {
            totalHeight: height,
            totalWidth: 4.5 + height * 0.04,
            strokeWidth: 1.5,
            middleHeight: 5,
            middleWidth: 0,
            offsetTop: 1,
            offsetBottom: 1,
            offsetLeft: 1.5,
            asideWidth: 2 + height * 0.03,
            radiusX: 0.5,
            radiusY: 0.5,
        };
    }

    if (height <= 50) {
        return {
            totalHeight: height,
            totalWidth: 7.5,
            strokeWidth: 1.5,
            middleHeight: 10,
            middleWidth: 0,
            offsetTop: 3,
            offsetBottom: 2,
            offsetLeft: 2,
            asideWidth: 2,
            radiusX: 2,
            radiusY: 2,
        };
    }

    return {
        totalHeight: height,
        totalWidth: 11,
        strokeWidth: 2.5,
        middleHeight: 10,
        middleWidth: 0,
        offsetTop: 3,
        offsetBottom: 2,
        offsetLeft: 3,
        asideWidth: 2,
        radiusX: 4,
        radiusY: 4,
    };
}
