import type { BracketModel } from "../../../elements/bracket/BracketModel";
import type { SpecialSymbolModel } from "../../../elements/symbol/SpecialSymbolModel";
import type { BaseElementModel } from "../../element";

import { BaseDefaultElementVisitor } from "../../element/BaseDefaultElementVisitor";

type TElementType = "stop" | "symbol" | "factorial" | "bracket-left" | "bracket-right";

export class FactorialsTogetherElementsVisitor extends BaseDefaultElementVisitor<TElementType> {
    protected defaultBehaviour(element: BaseElementModel): TElementType {
        return "symbol";
    }

    override doWithSymbol(element: SpecialSymbolModel): TElementType {
        return element.symbolName === "factorial" ? "factorial" : super.doWithSymbol(element);
    }

    override doWithBracket(element: BracketModel): TElementType {
        return element.closing ? "bracket-right" : "bracket-left";
    }

    override doWithLinebreak(): TElementType {
        return "stop";
    }

    override doWithEquationsSet(): TElementType {
        return "stop";
    }

    override doWithResult(): TElementType {
        return "stop";
    }
}
