import type { TFormControl } from "@viuch/form-builder/types/controls";

import { createLabel } from "../controls/label";

export const gcd: TFormControl = {
    control: "Form",
    layout: "column",
    condition: {
        "./solver_type": ["gcd", "lcm"],
    },
    controls: [
        createLabel("Наибольший общий делитель", { "./solver_type": "gcd" }), //
        createLabel("Наименьшее общее кратное", { "./solver_type": "lcm" }),
        {
            control: "Math",
            label: "Значения",
            path: "./values",
        },
    ],
};
