import { computed, makeObservable, observable } from "mobx";
import { createTransformer } from "mobx-utils";

import { areSamePoints } from "@viuch/geometry-lib/check-geometry";
import { distancePoints } from "@viuch/geometry-lib/vectors";

import type { BaseElement } from "../../elements";
import type { Figure2DController } from "../../Figure2DController";
import type { ToolbarMenu } from "../../toolbar";
import type { IUserPointer } from "../../viewport/types";
import type { TPoint } from "@viuch/geometry-lib/types";
import type React from "react";

import { DotElement } from "../../elements/dot";
import { ElementColor } from "../../elements/ElementColor";
import { EllipseElement } from "../../elements/ellipse";
import { EllipseModel } from "../../models/circle";
import { LabelPointModel } from "../../models/label-point";
import { PointModel } from "../../models/point";
import { ButtonStates } from "../../toolbar";
import { TooltipMenu } from "../../toolbar/tooltip";
import { createDefaultToolbarMenuBuilder } from "../../utils/toolbar";
import { BaseFlow } from "../BaseFlow";
import { createMapModelToElements, opacifyColor } from "../utils";

export class CircleFlow extends BaseFlow {
    flow:
        | {
              stage: 1;
              drawing: boolean;
              center?: TPoint;
          }
        | {
              stage: 2;
              drawing: boolean;
              center: TPoint;
              radiusPoint?: TPoint;
          };

    constructor(data: Figure2DController) {
        super(data);

        this.flow = { stage: 1, drawing: false };

        makeObservable(this, {
            flow: observable,
            tempElements: computed,
        });
    }

    static create(data: Figure2DController) {
        return new CircleFlow(data);
    }

    attach(): void {
        this.snap.clean().addViewportGrid().addFigureModelsPoints();
        this.viewport.disable();
    }

    dispose(): void {
        this.snap.clean();
    }

    override handleViewportPointerEvent(e: React.PointerEvent, pointer: IUserPointer): void {
        if (e.type === "pointercancel") return this.nextFlow();
        if (!e.isPrimary) return;

        const point = this.snap.snap(pointer.canvas, pointer.canvas);

        this.moveCurrentPoint(e, point);
        this.startDrawing(e);
        this.endDrawing(e);
    }

    private render = createTransformer(createMapModelToElements(opacifyColor));

    protected renderElements(): BaseElement[] {
        return [...this.figure.models.flatMap(this.render), ...this.tempElements];
    }

    get tempElements(): BaseElement[] {
        const { stage, drawing, center } = this.flow;
        const { scale } = this.viewport;

        if (!center) return [];

        const color = ElementColor.Building;
        const currentColor = drawing ? ElementColor.Selected : ElementColor.Building;
        const utilColor = ElementColor.Util;
        const overrideRenderOrder = 0;
        const exampleRadius = 0.1 / scale;

        if (stage === 1 || !this.flow.radiusPoint) {
            return [
                new DotElement({
                    id: `temp_center`,
                    x: center.x,
                    y: center.y,
                    overrideRenderOrder,
                    color: currentColor,
                }),
                new EllipseElement({
                    id: `temp_circle_example`,
                    rx: exampleRadius,
                    ry: exampleRadius,
                    color: utilColor,
                    thin: true,
                    overrideRenderOrder,
                    center,
                }),
            ];
        }

        const { radiusPoint } = this.flow;
        const radius = distancePoints(center, radiusPoint);

        return [
            new DotElement({
                id: `temp_center`,
                x: center.x,
                y: center.y,
                overrideRenderOrder,
                color,
            }),
            new EllipseElement({
                id: `temp_circle`,
                center,
                rx: radius,
                ry: radius,
                overrideRenderOrder,
                color,
            }),
            new DotElement({
                id: `temp_radiusPoint`,
                x: radiusPoint.x,
                y: radiusPoint.y,
                overrideRenderOrder,
                color: currentColor,
            }),
        ];
    }

    private moveCurrentPoint(e: React.PointerEvent, point: TPoint) {
        if (e.type === "pointermove" || e.type === "pointerdown") {
            switch (this.flow.stage) {
                case 1: {
                    this.flow.center = point;
                    break;
                }
                case 2: {
                    this.flow.radiusPoint = areSamePoints(this.flow.center, point) ? void 0 : point;
                    break;
                }
            }
        }
    }

    private startDrawing(e: React.PointerEvent) {
        if (e.type === "pointerdown") {
            this.flow.drawing = true;
        }
    }

    private endDrawing(e: React.PointerEvent) {
        if (e.type === "pointerup") {
            this.flow.drawing = false;

            const { stage, center } = this.flow;
            switch (stage) {
                case 1: {
                    if (center) {
                        this.flow = {
                            stage: 2,
                            center,
                            drawing: false,
                        };
                    }
                    break;
                }
                case 2: {
                    const { radiusPoint } = this.flow;
                    if (radiusPoint) {
                        this.checkAndSave();
                    }
                    break;
                }
            }
        }
    }

    private checkAndSave() {
        if (this.flow.drawing || this.flow.stage !== 2 || !this.flow.radiusPoint) return;

        const { center, radiusPoint } = this.flow;
        const radius = distancePoints(center, radiusPoint);

        this.figure.insertModels(function* () {
            yield EllipseModel.create({ center, rx: radius, ry: radius, style: null });
            yield PointModel.create({ x: center.x, y: center.y, style: null });
            yield PointModel.create({ x: radiusPoint.x, y: radiusPoint.y, style: null });
            yield LabelPointModel.createNext(center, this.figure);
            yield LabelPointModel.createNext(radiusPoint, this.figure);
        }, this);

        this.nextFlow();
    }

    override getToolbarMenu(): ToolbarMenu {
        return createDefaultToolbarMenuBuilder()
            .setButtonState(ButtonStates.active, ["circles"])
            .setButtonIcon("circle", ["circles"])
            .build();
    }

    override getTooltipMenu(): TooltipMenu | null {
        let text: string;

        switch (this.flow.stage) {
            case 1:
                text = "Выберите центр окружности";
                break;
            case 2:
                text = "Выберите точку окружности";
                break;
        }

        return new TooltipMenu(text);
    }
}
