import React from "react";

import type { AffiliationModel } from "./AffiliationModel";
import type { TElementProps } from "../../types/element";

import { useMathEditorElementRef } from "../../hooks";
import { BaseSymbolElement } from "../base/base-symbol";

export const AffiliationElement: React.VFC<TElementProps<AffiliationModel>> = ({ elementModel }) => {
    const ref = useMathEditorElementRef(elementModel);
    const isNot = elementModel.symbolName === "not-affiliation";

    return <BaseSymbolElement ref={ref}>{isNot ? "∉" : "∈"}</BaseSymbolElement>;
};
