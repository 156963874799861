import { action, makeObservable, observable } from "mobx";

import { createSerializedContainer } from "@viuch/math-editor";
import { generateId } from "@viuch/shared/utils/data";
import { deepClone } from "@viuch/shared/utils/data/copy";

import type { TSerializedState } from "@viuch/math-editor";

export class ExpressionReplacement implements ExpressionReplacement.Init {
    @observable id;
    @observable inputExpressions;
    @observable outputExpression;

    constructor(init: ExpressionReplacement.Init = ExpressionReplacement.createDefaultInit()) {
        this.id = init.id;
        this.inputExpressions = deepClone(init.inputExpressions);
        this.outputExpression = deepClone(init.outputExpression);

        makeObservable(this);
    }

    @action.bound
    setInputExpression(i: number, value: TSerializedState) {
        this.inputExpressions[i] = value;
    }

    @action.bound
    setOutputExpression(value: TSerializedState) {
        this.outputExpression = value;
    }

    @action.bound
    removeInputExpression(i: number) {
        this.inputExpressions.splice(i, 1);
    }

    @action.bound
    addInputExpression() {
        this.inputExpressions.push(createSerializedContainer());
    }
}

export namespace ExpressionReplacement {
    export type Init = {
        id: number;
        inputExpressions: TSerializedState[];
        outputExpression: TSerializedState;
    };

    export const createDefaultInit = (override?: Partial<Init>): Init => ({
        id: generateId(),
        inputExpressions: [createSerializedContainer()],
        outputExpression: createSerializedContainer(),
        ...override,
    });
}
