import cn from "classnames";
import { observer } from "mobx-react-lite";
import React, { useEffect, useRef, useState } from "react";

import { InputService } from "@viuch/math-editor";
import { MathInput } from "@viuch/ui-common";
import { useConst } from "@viuch/utils/hooks";

import type { KeyboardService, TSerializedState } from "@viuch/math-editor";

import styles from "./MathEditorPluginComponent.module.scss";

type Props = {
    onStateChanged(inputService: InputService): void;
    initialState: TSerializedState;
    keyboardService?: KeyboardService;
    className?: string;
};

export const MathEditorPluginComponent: React.VFC<Props> = observer(function MathEditorPluginConstants({
    className,
    keyboardService,
    onStateChanged,
    initialState,
}) {
    const inputService = useConst(() => new InputService({ multiline: true, initialState }));

    const [recommendedWidthExceeded, setRecommendedWidthExceeded] = useState(false);
    const ref = useRef<HTMLSpanElement>(null);

    useEffect(() => {
        const element = ref.current!;

        function handle() {
            const width = element.getBoundingClientRect().width;
            setRecommendedWidthExceeded(width > 360);
        }

        const observer = new ResizeObserver(handle);
        observer.observe(element);

        return () => {
            observer.disconnect();
        };
    }, []);

    return (
        <span
            className={cn(className, styles.wrapper)}
            onKeyDown={(e) => {
                e.stopPropagation();
            }}
            title={recommendedWidthExceeded ? "Превышена рекомендуемая ширина!" : void 0}
            ref={ref}
        >
            <MathInput
                className={styles.input}
                inputService={inputService}
                keyboardService={keyboardService}
                onBlur={onStateChanged}
                innerClassName={cn(recommendedWidthExceeded && styles.redFrame)}
            />
        </span>
    );
});
