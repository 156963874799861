import { observer } from "mobx-react-lite";
import React from "react";

import { useIsSafari } from "@viuch/utils/hooks";

import type { EllipseElement } from "./EllipseElement";

type Props = {
    ellipse: EllipseElement;
    onPointerEvent(e: React.PointerEvent, stroke: EllipseElement): void;
};

export const EllipseView = observer(function EllipseView({ ellipse, onPointerEvent }: Props) {
    const { center, rx, ry, color, thin } = ellipse;
    const { x, y } = center;

    const handlePointerEvent = (e: React.PointerEvent) => onPointerEvent(e, ellipse);

    const isSafari = useIsSafari();

    return (
        <g data-type="ellipse">
            <ellipse
                cx={`${x * 100}%`}
                cy={`${(1 - y) * 100}%`}
                rx={`${rx * 100}%`}
                ry={`${ry * 100}%`}
                stroke={color.hex}
                fill="none"
                strokeWidth={thin ? 1 : 2}
                opacity={color.opacity}
            />
            <ellipse
                cx={`${x * 100}%`}
                cy={`${(1 - y) * 100}%`}
                rx={`${rx * 100}%`}
                ry={`${ry * 100}%`}
                stroke="transparent"
                fill="none"
                strokeWidth={isSafari ? 16 : 12}
                onPointerDown={handlePointerEvent}
                onPointerMove={handlePointerEvent}
                onPointerUp={handlePointerEvent}
                onPointerCancel={handlePointerEvent}
            />
        </g>
    );
});
