import cn from "classnames";
import { observer } from "mobx-react-lite";
import React from "react";

import { Graph1DInstrumentStatementEditor } from "@viuch/instrument-graph1d/statement-editor";
import { TextInput } from "@viuch/ui-common";
import { InstrumentFrame } from "@viuch/ui-kit/frames";

import type { Graph1DInstrumentStatement } from "@viuch/instrument-graph1d/statement";
import type { KeyboardService } from "@viuch/math-editor";

import { EditDotModal } from "./elements/EditDotModal";
import { Graph1DInstrumentSettingsEditorViewModel } from "./Graph1DInstrumentSettingsEditor.vm";

import styles from "./Graph1DInstrumentSettingsEditor.module.scss";

type Props = {
    graph: Graph1DInstrumentStatement;
    keyboardService: KeyboardService;
    className?: string;
    frameClassName?: string;
};

export const Graph1DInstrumentSettingsEditor = observer(function Graph1DInstrumentSettingsEditor(props: Props) {
    const { className, graph, keyboardService, frameClassName } = props;

    const vm = React.useMemo(() => new Graph1DInstrumentSettingsEditorViewModel(graph), [graph]);

    return (
        <div className={cn(className, styles.wrapper)}>
            <InstrumentFrame className={cn(styles.frame, frameClassName)}>
                <Graph1DInstrumentStatementEditor
                    graph={graph}
                    onEditDot={vm.handleEditDot}
                    onAddDot={vm.handleAddDot}
                    onMoveDot={vm.moveDot}
                    onClear={vm.clear}
                />
            </InstrumentFrame>
            <div className={styles.settings}>
                <TextInput
                    title="Название оси"
                    value={graph.axis}
                    onChange={graph.setAxis}
                />
            </div>
            {vm.whileEditDot && (
                <EditDotModal
                    title="Изменить точку"
                    keyboardService={keyboardService}
                    dot={vm.whileEditDot.dot}
                    onSave={vm.handleEditDotSave}
                    onRemove={vm.removeDot}
                    onCancel={vm.handleEditDotClose}
                />
            )}
            {vm.whileAddDot && (
                <EditDotModal
                    title="Добавить точку"
                    keyboardService={keyboardService}
                    dot={vm.whileAddDot.newDot}
                    onSave={vm.handleAddDotSave}
                    onCancel={vm.handleAddDotCancel}
                />
            )}
        </div>
    );
});
