import { observer } from "mobx-react-lite";
import { Redirect, Route } from "react-router-dom";

import type { TAccess } from "../store/AccessProvider";
import type { RouteProps } from "react-router-dom";

import { useRootStore } from "../integration";

type Props = RouteProps & {
    hasAccess(p: TAccess): boolean;
};

export const RoleRoute = observer(function RoleRoute(props: Props) {
    const { hasAccess, ...rest } = props;

    const { auth } = useRootStore();

    if (!hasAccess(auth.accessProvider)) {
        if (!auth.isAuthorized) {
            return <Redirect to="/login" />;
        }
        return <Redirect to="/" />;
    }

    return <Route {...rest} />;
});
