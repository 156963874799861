import type { TFormControl } from "@viuch/form-builder/types/controls";

import { createLabel } from "../controls/label";

export const factorIntegers: TFormControl = {
    control: "Form",
    layout: "column",
    condition: {
        "./solver_type": ["factor_integer"],
    },
    controls: [
        createLabel("Раскладывает целое число на множители"),
        {
            control: "Select",
            label: "Тип ответа",
            initialValue: "prime",
            path: "./answer_type",
            data: {
                type: "static-items",
                items: [
                    {
                        value: "prime",
                        label: "Простые числа",
                    },
                    {
                        value: "all_prime",
                        label: "Представляет в виде произведения простых множителей",
                    },
                    {
                        value: "all",
                        label: "Все множители",
                    },
                ],
            },
        },
        {
            control: "Math",
            label: "Значение",
            path: "./value",
        },
    ],
};
