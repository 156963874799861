import jsonpath from "jsonpath";

import type { InputService } from "../../../services";
import type { THighlightLocation, THighlightStyle } from "../../../types";

import { ContainerModel } from "../../container";

import { CrossOutHighlightRange } from "./CrossedOutHighlightRange";
import { HighlightRange } from "./HighlightRange";

export class HighlightRangesFactory {
    private readonly inputService: InputService;

    constructor(inputService: InputService) {
        this.inputService = inputService;
    }

    private getHighlightStyleFromPayload(payload: Record<string, unknown>): THighlightStyle {
        switch (payload.tag) {
            case "attention":
                return "attention";
            case "crossout":
                return "crossout";
            default:
                return "default";
        }
    }

    createHighlightFromData(data: THighlightLocation): HighlightRange | null {
        const { source, start, end, ...payload } = data;
        const length = end - start;

        const style = this.getHighlightStyleFromPayload(payload);

        const container = this.findContainerByJsonpath(source);

        if (!container) {
            console.error("Invalid jsonpath", data);
            return null;
        }

        const elementUuids = this.findElementUuidsByRange(container, start, length);

        if (!elementUuids) return null;

        if (style === "crossout") {
            return new CrossOutHighlightRange(payload, style, container.uuid, elementUuids, this.inputService);
        } else {
            return new HighlightRange(payload, style, container.uuid, elementUuids, this.inputService);
        }
    }

    private findContainerByJsonpath(source: string): ContainerModel | null {
        try {
            const result: unknown[] = jsonpath.query(this.inputService.model.rootContainer, source, 1);

            if (result.length === 1) {
                const [container] = result;
                if (container instanceof ContainerModel) {
                    return container;
                }
            }
            return null;
        } catch (e) {
            console.error("Jsonpath was wrong", source, e);
            return null;
        }
    }

    private findElementUuidsByRange(container: ContainerModel, start: number, length: number): string[] | null {
        if (length <= 0) return null;

        const end = start + length;
        const elements = container.elements.slice(start, end);

        if (elements.length !== length) return null;

        return elements.map((element) => element.uuid);
    }

    createDataFromHighlight(highlight: HighlightRange): THighlightLocation | null {
        const { containerUuid, elementUuids, payload } = highlight;
        const [firstElementUuid] = elementUuids;

        const container = this.inputService.containers.tryGetById(containerUuid);
        if (!container) return null;

        const firstElement = this.inputService.elements.tryGetById(firstElementUuid);
        if (!firstElement) return null;

        const start = firstElement.index;
        const end = start + elementUuids.length;
        if (start === -1) return null;

        const source = this.createContainerJsonpath(container);
        if (!source) return null;

        return {
            ...payload,
            source,
            start,
            end,
        };
    }

    private createContainerJsonpath(container: ContainerModel): string | null {
        const segments: (string | number)[] = [...container.parentPath];

        let currentElement = container.parentElement;
        while (currentElement !== this.inputService.model) {
            segments.unshift("elements", currentElement.index);
            segments.unshift(...currentElement.parentContainer.parentPath);

            currentElement = currentElement.parentContainer.parentElement;
        }

        return jsonpath.stringify(segments);
    }
}
