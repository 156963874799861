import type { ISerializedLinebreak } from "./types";
import type { TSerializedElementPrototype } from "../../core/element";
import type { IElementVisitor } from "../../core/element/IElementVisitor";
import type { InputService } from "../../services";
import type { ISerializedElement, TElementDeserializerFunction } from "../../types";

import { BaseElementModel } from "../../core/element";

export class LinebreakModel extends BaseElementModel<ISerializedLinebreak> {
    public constructor(inputService: InputService, uuid?: string) {
        super(inputService, uuid);
    }

    public serialize(): ISerializedElement<"linebreak"> {
        return { type: "linebreak", uuid: this.uuid };
    }

    public static deserialize: TElementDeserializerFunction<ISerializedElement<"linebreak">> = (
        { inputService },
        { uuid }
    ) => new LinebreakModel(inputService, uuid);

    override getRenderType(): string {
        return "contents";
    }

    public accept<R>(visitor: IElementVisitor<R>): R {
        return visitor.doWithLinebreak(this);
    }

    serializeAsClone(): TSerializedElementPrototype<ISerializedLinebreak> {
        const { uuid, ...clone } = this.serialize();
        return clone;
    }
}
