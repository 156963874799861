import { useRef, useState } from "react";

const useConst__useStateImpl = <T>(serviceInitializer: () => T): T => {
    const [service] = useState<T>(serviceInitializer);
    return service;
};

const useConst__useRefImpl = <T>(serviceInitializer: () => T): T => {
    const serviceRef = useRef<T>();

    if (typeof serviceRef.current === "undefined") {
        serviceRef.current = serviceInitializer();
    }

    return serviceRef.current;
};

export const useConst: { <T>(serviceInitializer: () => T): T } = useConst__useRefImpl;
