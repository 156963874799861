import type { IVariableContentVisitor } from "../../../entities/tasks/variables";
import type {
    TGeneratedDrawingVariableContent,
    TIntervalVariableContent,
    TNumberVariableContent,
    TNumericSequenceVariableContent,
    TRandomChoiceVariableContent,
    TRandomVariableContent,
    TRandomWithDimensionVariableContent,
    TSolverVariableContent,
    TTaskVariableContent,
    TTextMappingVariableContent,
    TTextVariableContent,
} from "../types";

import { serializeDrawingSettings } from "./generatedDrawing";
import { serializeSolverRequest } from "./serializeSolverRequest";

export const variableContentSerializer: IVariableContentVisitor<TTaskVariableContent> = {
    doWithNumber: (content): TNumberVariableContent => ({
        quantity_fractional_digits: content.quantity_fractional_digits,
        formula: content.formula,
        multiple_of: content.multiple_of,
        conditions: content.conditions,
    }),
    doWithShuffledNumber: (content): TNumberVariableContent => ({
        quantity_fractional_digits: content.quantity_fractional_digits,
        formula: content.formula,
    }),
    doWithRandom: (content): TRandomVariableContent => ({
        left_range: content.left_range,
        right_range: content.right_range,
        quantity_fractional_digits: content.quantity_fractional_digits,
        step_by: content.step_by,
        shift_start: content.shift_start,
        conditions: content.conditions,
    }),
    doWithRandomWithDimension: (content): TRandomWithDimensionVariableContent => ({
        left_range: content.leftRange,
        right_range: content.rightRange,
        quantity_fractional_digits: content.quantityFractionalDigits,
        dimension: content.sourceDimension,
        target: content.targetDimension,
    }),
    doWithInterval: (content): TIntervalVariableContent => ({
        left_range: content.left_range,
        right_range: content.right_range,
    }),
    doWithSolver: (content): TSolverVariableContent => ({
        request: serializeSolverRequest(content.request),
    }),
    doWithDrawingSettings: (content): TGeneratedDrawingVariableContent => ({
        drawing_settings: serializeDrawingSettings(content.settings),
    }),
    withText: (content): TTextVariableContent => ({
        formula: content.formula,
    }),
    withRandomChoice: (content): TRandomChoiceVariableContent => ({ choices: content.choices }),
    withNumericSequence: (content): TNumericSequenceVariableContent => ({
        formula: content.formula,
        count: content.count,
        index_symbol: content.index_symbol,
        separator: content.separator,
    }),
    doWithTextMapping: (content): TTextMappingVariableContent => ({
        key: content.key,
        mapping: content.mapping,
    }),
    withUnknown: (content) => content.data,
};
