import { assert } from "@viuch/utils/debug";

import type { BaseElementModel } from "../core/element";

import { BaseCommand } from "../core/commands";

import { SelectionRequiredError } from "./errors/SelectionRequiredError";

export class RemoveSelectedCommand<T extends BaseElementModel = BaseElementModel> extends BaseCommand<T> {
    public constructor(element: T) {
        super(element);

        if (!this.selectionSnapshot) {
            throw new SelectionRequiredError();
        }
    }

    apply(): void {
        assert(this.selectionSnapshot);

        this.removeSelectedElements();
        this.cursor.moveToPosition(this.getSelectionContainer(), this.selectionSnapshot.firstElementIndex);
    }

    rollback(): void {
        this.restoreRemovedSelectedElements();
    }
}
