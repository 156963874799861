import type { TRawPromocode, TRawPromocodeCreate } from "./promocodes.types";

import { agent } from "../../agent";
import { throwServiceError } from "../base/RequestError";

export class PromocodesService {
    async getPromocodes(onlyManual: boolean): Promise<TRawPromocode[]> {
        try {
            const { data } = await agent.get<TRawPromocode[]>(`/payments/promocodes/`, {
                params: {
                    is_manual: onlyManual,
                },
            });

            return data;
        } catch (e) {
            throwServiceError(e);
        }
    }

    async createPromocodes(request: TRawPromocodeCreate): Promise<TRawPromocode[]> {
        try {
            const { data } = await agent.post<TRawPromocode[]>(`/payments/promocodes/`, request);

            return data;
        } catch (e) {
            throwServiceError(e);
        }
    }
}
