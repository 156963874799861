import { generateUuid } from "@viuch/shared/utils/data";

import type { ISerializedLowPriorityOperator, TLowPriorityOperatorsActionSign } from "./types";
import type { TAnySerializedElement } from "../../types";

export function createSerializedOperatorLow(sign: TLowPriorityOperatorsActionSign): ISerializedLowPriorityOperator {
    return { type: "operator-low", uuid: generateUuid(), symbol: sign };
}

export function isSerializedLowPriority(
    serializedElement: TAnySerializedElement
): serializedElement is ISerializedLowPriorityOperator {
    return serializedElement.type === "operator-low";
}
