import type { TBracketType } from "../elements/bracket/types";
import type { TAnyAction } from "../types";

export type TBracketAction = {
    type: "bracket";
    bracket: TBracketType;
    closing?: boolean;
};

export function createBracketAction(bracket: TBracketType, options?: { closing: boolean }): TBracketAction {
    return { type: "bracket", bracket, closing: options?.closing };
}

export function isBracketAction(action: TAnyAction): action is TBracketAction {
    return action.type === "bracket";
}
