import React from "react";

import { TableInstrumentSettingsEditor } from "@viuch/instrument-table-settings/settings-editor";

import type { IBaseEditor } from "../../editors/base";
import type { IEditorVisitor } from "../../editors/IEditorVisitor";
import type { IComplexEditorGraph2DHost } from "../../editors/instruments/Graph2DEditor";
import type { IMathConverterService } from "../../editors/types";
import type { IUploadImageService } from "@viuch/instrument-picture-settings/settings-editor/types";
import type { KeyboardService } from "@viuch/math-editor";

import { FormatEditorComponent } from "../format-editor";
import { MathEditorComponent } from "../math-editor";

import { Figure2DInstrumentEditor } from "./instruments/Figure2DInstrumentEditor";
import { Graph1DInstrumentEditor } from "./instruments/Graph1DInstrumentEditor";
import { Graph2DInstrumentEditor } from "./instruments/Graph2DInstrumentEditor";
import { IntervalsInstrumentEditor } from "./instruments/IntervalsInstrumentEditor";
import { PictureInstrumentEditor } from "./instruments/PictureInstrumentEditor";

export type TEditorProps = {
    uploadService: IUploadImageService;
    host: IComplexEditorGraph2DHost;
    mathConverterService: IMathConverterService;
    keyboardService: KeyboardService;
};

export const anyEditorRenderer: IEditorVisitor<(props: TEditorProps) => React.ReactElement> = {
    visitFormatEditor:
        (editor) =>
        ({ uploadService, keyboardService, mathConverterService }) =>
            (
                <FormatEditorComponent
                    key={editor.uuid}
                    editor={editor}
                    uploadService={uploadService}
                    mathConverterService={mathConverterService}
                    keyboardService={keyboardService}
                />
            ),
    visitMathEditor:
        (editor) =>
        ({ keyboardService, uploadService, mathConverterService }) =>
            (
                <MathEditorComponent
                    editor={editor}
                    keyboardService={keyboardService}
                    mathConverterService={mathConverterService}
                />
            ),
    visitPicture:
        (editor) =>
        ({ keyboardService, uploadService }) =>
            (
                <PictureInstrumentEditor
                    picture={editor.picture}
                    keyboardService={keyboardService}
                    uploadService={uploadService}
                />
            ),
    visitIntervalsInstrument:
        (editor) =>
        ({ keyboardService }) =>
            (
                <IntervalsInstrumentEditor
                    keyboardService={keyboardService}
                    editor={editor}
                />
            ),
    visitTable:
        (editor) =>
        ({ keyboardService }) =>
            (
                <TableInstrumentSettingsEditor
                    table={editor.table}
                    keyboardService={keyboardService}
                />
            ),
    visitGraph1D:
        (editor) =>
        ({ keyboardService }) =>
            (
                <Graph1DInstrumentEditor
                    editor={editor}
                    keyboardService={keyboardService}
                />
            ),
    visitFigure2D:
        (editor) =>
        ({ keyboardService }) =>
            (
                <Figure2DInstrumentEditor
                    editor={editor}
                    keyboardService={keyboardService}
                />
            ),
    visitGraph2D:
        (editor) =>
        ({ keyboardService, uploadService, host }) =>
            (
                <Graph2DInstrumentEditor
                    editor={editor}
                    host={host}
                    keyboardService={keyboardService}
                />
            ),
};

export function useEditorRenderer(): (editor: IBaseEditor, props: TEditorProps) => React.ReactElement {
    return (editor, props) => editor.accept(anyEditorRenderer)(props);
}
