import { serializeDate } from "@viuch/shared/serialization/dates";

import type { TRawPaymentsTariffSettings, TRawPaymentsStatsByDevices, TRawTariffsPrices } from "./payments.types";

import { agent } from "../../agent";
import { throwServiceError } from "../base/RequestError";

export class PaymentsService {
    async loadTariffsSettings(): Promise<TRawPaymentsTariffSettings> {
        try {
            const { data } = await agent.get<TRawPaymentsTariffSettings>(`/payments/configuration/`);

            return data;
        } catch (e) {
            throwServiceError(e);
        }
    }

    async saveTariffsSettings(request: TRawPaymentsTariffSettings) {
        try {
            const { data } = await agent.patch<TRawPaymentsTariffSettings>(`/payments/configuration/`, request);

            return data;
        } catch (e) {
            throwServiceError(e);
        }
    }

    async paymentsStats(start: Date, end: Date): Promise<TRawPaymentsStatsByDevices> {
        try {
            const { data } = await agent.get<TRawPaymentsStatsByDevices>(`/statistics/payments/general/`, {
                params: {
                    start: serializeDate(start),
                    end: serializeDate(end),
                },
            });

            return data;
        } catch (e) {
            throwServiceError(e);
        }
    }

    async getPrices(
        type: "gift" | "subscription" | undefined,
        usersCount = 1,
        hasSubscription: boolean,
        signal?: AbortSignal
    ): Promise<TRawTariffsPrices> {
        try {
            const { data } = await agent.get<TRawTariffsPrices>("/payments/pricing/", {
                params: {
                    type,
                    user_count: usersCount,
                    has_subscription: hasSubscription,
                },
                signal,
            });

            return data;
        } catch (e) {
            throwServiceError(e);
        }
    }
}
