import type { TPictureAlignX, TPictureAlignY } from "../service-types/picture";
import type { TPictureWatermarkAlignment } from "@viuch/instrument-picture/statement";

export const mapAlignmentX: Record<TPictureWatermarkAlignment["horizontal"], TPictureAlignX> = {
    middle: "center",
    right: "right",
    left: "left",
};

export const mapAlignmentY: Record<TPictureWatermarkAlignment["vertical"], TPictureAlignY> = {
    middle: "center",
    bottom: "bottom",
    top: "top",
};
