import React, { forwardRef } from "react";

import styles from "./SelectionCursor.module.scss";

export interface ISelectionCursorProps {
    style?: React.CSSProperties;
}

export const SelectionCursor = forwardRef<HTMLDivElement, ISelectionCursorProps>(function SelectionCursor(
    { style },
    ref
) {
    return (
        <div
            ref={ref}
            className={styles.selectionCursor}
            style={style}
        >
            <div className={styles.selectionCursorInner} />
        </div>
    );
});
