import type { GetError } from "../base/RequestError";
import type { TSerializedState } from "@viuch/math-editor";

import { Dimension } from "../../entities/dimensions";
import { BaseCRUDService } from "../base";

export class DimensionsService extends BaseCRUDService<Dimension, TDimensionRequest, TDimensionResponse> {
    constructor() {
        super("/dimensions/", mapDimension, serializeDimension);
    }
}

export type TDimensionRequest = {
    id: number;
    name: string;
    math_designation: string;
    formula: TSerializedState | null;
    measure_units: number[];
};

export type TDimensionResponse = TDimensionRequest;

export type TDimensionError = GetError<TDimensionRequest>;
type TDimensionFormulaError = {
    formula: {
        message: string;
        suggestion: TSerializedState;
    };
};

export const isFormulaSuggestionError = (e: any): e is TDimensionFormulaError => {
    return typeof e?.formula?.message === "string";
};

export const mapDimension = ({
    id,
    name,
    formula,
    measure_units: measureUnitIds,
    math_designation: sign,
}: TDimensionResponse) => new Dimension({ id, formula, measureUnitIds, name, sign });

export const serializeDimension = ({
    id,
    name,
    formula,
    sign: math_designation,
    measureUnitIds: measure_units,
}: Dimension): TDimensionRequest => ({ id, formula, math_designation, name, measure_units });
