import { createOffsetXY } from "@viuch/geometry-lib/transformations/offset";
import { createScaleXY } from "@viuch/geometry-lib/transformations/scale";

import type { TTransformerObject } from "@viuch/geometry-lib/transformations/transformer";

export class Graph2DViewSettings {
    readonly coordinateSystemTransform: TTransformerObject[];

    constructor(
        readonly primaryGridSteps: number = 8,
        readonly secondaryGridSteps: number = 40,
        readonly secondaryGridScaleFactor: number = 2,
        readonly secondaryGridScaleSteps: number[] = [],
        readonly maxScale: number = 8,
        readonly initialScale = 1,
        readonly initialOffsetX = 0.5,
        readonly initialOffsetY = 0.5,
        readonly scaleViewportX = 1,
        readonly scaleViewportY = 1,
        readonly offsetViewportX = 0,
        readonly offsetViewportY = 0
    ) {
        this.coordinateSystemTransform = [
            createOffsetXY(-offsetViewportX, -offsetViewportY),
            createScaleXY(1 / primaryGridSteps, 1 / primaryGridSteps),
            createScaleXY(1 / scaleViewportX, 1 / scaleViewportY),
        ];
    }
}
