import cn from "classnames";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

import type { IToolbarElement } from "../toolbarTypes";

import { ToolbarButtonIcon } from "./ToolbarButtonIcon";

import styles from "./ToolbarButton.module.scss";

type Props = {
    element: IButtonToolbarElement;
    className?: string;
};

export const ToolbarButton = observer(function ToolbarButton({ element, className }: Props) {
    const { iconSrc: iconSrc, tooltip, label, enabled, active, iconColor, bgColor, onClick } = element;

    const [tooltipVisible, setTooltipVisible] = useState(false);

    return (
        <div
            className={cn(styles.wrapper, className, !enabled && styles._disabled, active && styles._active)}
            onClick={() => enabled && onClick?.()}
            style={{
                "--icon-color": iconColor,
                "--bg-color": bgColor,
            }}
        >
            <div
                className={styles.root}
                onPointerEnter={() => setTooltipVisible(true)}
                onPointerLeave={() => setTooltipVisible(false)}
            >
                <ToolbarButtonIcon
                    iconSrc={iconSrc}
                    className={styles.icon}
                />
            </div>
            {tooltip && tooltipVisible && <div className={styles.tooltip}>{tooltip}</div>}
            {label && <div className={styles.label}>{label}</div>}
        </div>
    );
});

export interface IButtonToolbarElement extends IToolbarElement {
    type: "button";
    key: string;
    iconSrc: string;
    onClick?(): void;
    enabled?: boolean;
    active?: boolean;
    tooltip?: string;
    label?: string;
    iconColor?: string;
    bgColor?: string;
}
