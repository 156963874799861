import { action, makeObservable, observable } from "mobx";

import type { BaseFlow } from "./BaseFlow";
import type { Figure2DController } from "../Figure2DController";

export class FlowManager {
    private readonly data: Figure2DController;

    @observable.ref
    currentFlow!: BaseFlow;

    constructor(data: Figure2DController) {
        this.data = data;

        makeObservable(this);
    }

    @action.bound
    initialize(initialState: BaseFlow) {
        this.currentFlow = initialState;
        this.normalizer.normalizeModel();

        this.currentFlow.attach();
    }

    get normalizer() {
        return this.data.normalizer;
    }

    @action.bound
    next(state: BaseFlow): void {
        this.currentFlow.dispose();
        this.data.toolbar.closeNestedMenu();

        this.normalizer.normalizeModel();

        this.data.history.commit();

        this.currentFlow = state;
        this.currentFlow.attach();
    }
}
