import { copyPoint } from "@viuch/geometry-lib/factories";

import type { ISelectionElementVisitor } from "../BaseSelection";
import type { TLine, TPoint } from "@viuch/geometry-lib/types";

import { BaseSelection } from "../BaseSelection";

export class FragmentSelection extends BaseSelection {
    readonly a: Readonly<TPoint>;
    readonly b: Readonly<TPoint>;

    constructor(a: TPoint, b: TPoint) {
        super();

        this.a = copyPoint(a);
        this.b = copyPoint(b);
    }

    accept<R>(visitor: ISelectionElementVisitor<R>): R {
        return visitor.withFragment(this);
    }

    toLine(): TLine {
        const { a, b } = this;
        return { x1: a.x, y1: a.y, x2: b.x, y2: b.y };
    }
}
