import "react-zoom-pan-pinch";

export function applyPictureLibFixes() {
    /**
     * Исправление проблемы с импортом стилей
     * из библиотеки react-zoom-pan-pinch (версия 2.1.3)
     *
     * Проблема в том, что стили вставляются в рантайме, в конец <head>, и имеют высокий приоритет.
     * Это поведение невозможно переопределить на текущий момент.
     *
     * В проекте используется переопределение некоторых значений из этих стилей,
     * но "наши" стили не применяются, так как имеют приоритет ниже.
     *
     * Фикс состоит в следующем:
     * 1. Импортируется библиотека
     * 2. Библиотека добавляет стили в конец <head> путём вставки одного элемента <style> с константной строкой
     * 3. Мы находим этот элемент по паттерну совпадения части этой строки
     * 4. Найденный элемент в самое начало <head>
     *
     * Теперь CSS из библиотеки имеет низкий приоритет, и может быть переопределен.
     */

    if (typeof window !== "undefined") {
        const startsWith = ".transform-component-module_wrapper";
        const head = document.head;

        for (const element of document.head.children) {
            if (element instanceof HTMLStyleElement) {
                if (element.innerText.startsWith(startsWith)) {
                    const style = head.removeChild(element);

                    head.insertBefore(style, head.firstChild);
                    break;
                }
            }
        }
    }
}
