import { equateNumbers } from "@viuch/geometry-lib/equate";
import { copyFragment, createFragment } from "@viuch/geometry-lib/factories";
import { filterSameFragments } from "@viuch/geometry-lib/filters";
import { middlePoint } from "@viuch/geometry-lib/vectors";

import type { Figure2D } from "../../../entities/Figure2D";
import type { IModelVisitor } from "../../models/BaseModel";
import type { TFragment, TPoint } from "@viuch/geometry-lib/types";

const getParallelFragmentsCandidatesVisitor: IModelVisitor<TFragment[]> = {
    withFragment: (fragment) => [copyFragment(fragment)],
    withFragmentDashed: (fragment) => [copyFragment(fragment)],
    withVector: (vector) => [vector.toFragment()],
    withVectorDashed: (vector) => [vector.toFragment()],
    withParallelFragment: (fragment) => [copyFragment(fragment)],
    withTangentLine: (tangent) =>
        tangent.checkIsTangent() ? [createFragment(tangent.pointOnCircle, tangent.point)] : [],
    withMiddleLine: ({ middleLineFragment }) => [copyFragment(middleLineFragment)],
    withMedian: ({ medianFragment }) => [copyFragment(medianFragment)],
    withBisection: ({ bisectionFragment }) => (bisectionFragment ? [copyFragment(bisectionFragment)] : []),
    withAltitude: ({ altitudeFragment }) => [copyFragment(altitudeFragment)],
    withLine: (line) => [copyFragment(line)],
    withEqualSegments: (equalSegments) => equalSegments.segments.map(copyFragment),
    withLabelFragment: (label) => [copyFragment(label)],
    withPoint: () => [],
    withLabelPoint: () => [],
    withLabelAngle: () => [],
    withConstraintLine: () => [],
    withComputedIntersectionPoints: () => [],
    withEqualAngles: () => [],
    withEllipse: () => [],
    withInscribedCircle: () => [],
    withCircumscribedCircle: () => [],
    withTangentLineOnCircle: () => [],
};

export function collectCandidateFragments(figure: Figure2D) {
    const fragments = figure.models.flatMap<TFragment>((model) =>
        model.accept<TFragment[]>(getParallelFragmentsCandidatesVisitor)
    );

    return filterSameFragments(fragments).map((fragment) => ({ fragment, middlePoint: middlePoint(fragment) }));
}

const FRAC_DIGITS = 5;

export function hashCoordinate(coordinate: number) {
    if (equateNumbers(coordinate, "==", 0)) {
        return (0).toFixed(FRAC_DIGITS);
    }
    return coordinate.toFixed(FRAC_DIGITS);
}

export function hashPoint(point: TPoint) {
    return `${hashCoordinate(point.x)}_${hashCoordinate(point.y)}`;
}
