// export function instantiateMathControl(data: TMathControl, parent: BaseFormNode | null): MathFormNode {}
// export function instantiateNumberControl(data: TNumberControl, parent: BaseFormNode | null): NumberFormNode {}
import type { BaseFormNode } from "../nodes/BaseFormNode";
import type { TLabelControl } from "../types/controls";

import { createCondition } from "../conditions/createCondition";
import { LabelFormNode } from "../nodes/LabelFormNode";

export function instantiateLabelControl(data: TLabelControl, parent: BaseFormNode | null): LabelFormNode {
    const { label, type, condition } = data;
    return new LabelFormNode(label, type, createCondition(condition), parent, []);
}
