import { action, makeObservable, observable } from "mobx";

import type { BaseFormNode, IFormNodeVisitor } from "./BaseFormNode";

import { BaseValueFormNode } from "./BaseValueFormNode";

export class CheckboxFormNode extends BaseValueFormNode {
    private readonly virtual: boolean;

    constructor(label: string, virtual: boolean, parent: BaseFormNode | null, path: string[], extensions: string[]) {
        super(label, parent, path, extensions);

        this.virtual = virtual;

        makeObservable(this);
    }

    @observable checked = false;
    @action.bound
    setChecked(checked: boolean) {
        this.checked = checked;
    }

    accept<R>(visitor: IFormNodeVisitor<R>): R {
        return visitor.withCheckbox(this);
    }

    getValue(): unknown {
        return this.checked;
    }

    trySetValue(value: unknown): boolean {
        if (typeof value === "boolean") {
            this.checked = value;
            return true;
        }
        if (typeof value === "object") {
            this.checked = !!value;
            return true;
        }

        if (this.virtual && value !== null && value !== undefined) {
            this.checked = true;
            return true;
        }
        return false;
    }

    valueEqualsTo(value: unknown): boolean {
        return value === this.checked;
    }

    override isVirtual(): boolean {
        return this.virtual;
    }
}
