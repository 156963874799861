import { createSerializedContainer, tryCreateSerializedFromString } from "@viuch/math-editor";

import type { Graph1DTaskInstrumentSettings } from "../../../entities/task-instruments-settings/BaseTaskInstrumentSettings";
import type { TGraphic1DInstrumentSettings } from "@viuch/feature-instrument-settings/service-types/graph1d";

export function serializeGraph1dTaskSettings(model: Graph1DTaskInstrumentSettings): TGraphic1DInstrumentSettings {
    const { dots, axis } = model.instrument.graph1d;

    return {
        instrument_type: "graph1d",
        axis_name: tryCreateSerializedFromString(axis) ?? createSerializedContainer(),
        points: dots.map(({ value, name, position, form, isProtected, isValueVisible, isNameVisible }) => ({
            position,
            name,
            value,
            is_point: form === "circle",
            is_name_visible: isNameVisible,
            is_value_visible: isValueVisible,
        })),
        coverage: [], // TODO: не поддерживается в интерфейсе администратора
        settings_uuid: model.settingsUuid,
    };
}
