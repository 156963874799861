import { action, computed, makeObservable, observable } from "mobx";

import type { TooltipMenu } from "./tooltip";
import type { Figure2DController } from "../Figure2DController";

import { ToolbarButton } from "./ToolbarButton";
import { ToolbarMenu } from "./ToolbarMenu";

export class ToolbarController {
    readonly data: Figure2DController;
    nestedMenu?: ToolbarMenu;
    actionsMenu: ToolbarMenu;

    constructor(data: Figure2DController) {
        this.data = data;

        this.actionsMenu = new ToolbarMenu("actions", [
            new ToolbarButton({ key: "undo", icon: "history-undo" }),
            new ToolbarButton({ key: "redo", icon: "history-redo" }),
            new ToolbarButton({ icon: "remove" }),
            new ToolbarButton({ icon: "clear" }),
        ]);

        makeObservable(this, {
            menu: computed,
            tooltip: computed,
            nestedMenu: observable.ref,
            actionsMenu: observable.ref,
            dispatchButtonClick: action.bound,
        });
    }

    get menu(): ToolbarMenu {
        return this.data.flows.currentFlow.getToolbarMenu();
    }

    get tooltip(): TooltipMenu | null {
        return this.data.flows.currentFlow.getTooltipMenu();
    }

    dispatchButtonClick(menu: ToolbarMenu, button: ToolbarButton) {
        const prevNestedMenu = this.nestedMenu;
        this.nestedMenu = void 0;

        if (button.nestedMenu && !button.disableNestedMenuHandler) {
            if (prevNestedMenu !== button.nestedMenu) {
                this.nestedMenu = button.nestedMenu;
            }
        }

        this.data.events.dispatchToolbarButtonClick(menu, button);
    }

    closeNestedMenu = action(() => {
        this.nestedMenu = void 0;
    });
}
