import { makeObservable, observable } from "mobx";

import type { ToolbarButton } from "../ToolbarButton";

import { ToolbarMenu } from "../ToolbarMenu";

export class TooltipMenu extends ToolbarMenu {
    tooltip?: string;

    constructor(tooltip?: string, buttons: ToolbarButton[] = [], key = "tooltip") {
        super(key, buttons);
        this.tooltip = tooltip;

        makeObservable(this, {
            tooltip: observable,
        });
    }
}
