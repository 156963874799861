import type { PropsWithChildren } from "react";

import AdminHeader from "../../components/AdminHeader";

import styles from "./AdminLayout.module.scss";

const AdminLayout = (props: PropsWithChildren) => {
    const { children } = props;

    return (
        <div className={styles.container}>
            <AdminHeader />
            <main className={styles.main}>{children}</main>
        </div>
    );
};

export default AdminLayout;
