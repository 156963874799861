import classNames from "classnames";
import { observer } from "mobx-react-lite";

import styles from "./ProgressBar.module.scss";

type Props = {
    title?: string;
    showTitle?: boolean;
    percents: number;
    className?: string;
};

export const ProgressBar = observer(function ProgressBar({ percents, title, showTitle = false, className }: Props) {
    return (
        <div className={classNames(styles.wrapper, className)}>
            {showTitle && (
                <div className={styles.title}>
                    {title ?? "Прогресс"}: {percents}%
                </div>
            )}
            <div className={styles.bar}>
                <div
                    className={styles.barFill}
                    style={{ width: `${percents}%` }}
                />
            </div>
        </div>
    );
});
