import { useEffect, useRef } from "react";

export function usePropsRef<P>(props: P): Ref<P> {
    const propsRef = useRef(props);

    useEffect(() => {
        propsRef.current = props;
    });

    return propsRef;
}

export type Ref<T> = { readonly current: T };
