import { observer } from "mobx-react-lite";
import React from "react";

import { generateId } from "@viuch/shared/utils/data";
import { useConst } from "@viuch/utils/hooks";

import { BaseInput } from "./BaseInput";

type Props<T extends string> = {
    value: T | null;
    onChange: (value: T) => void;
    data: TRadioInputData<T>;
    title?: string;
    className?: string;
    error?: string;
};

type GenericRadioChangeEvent<T extends string> = React.ChangeEvent<HTMLInputElement> & {
    target: { value: T };
};

export type TRadioInputData<T> = Array<{ value: T; label: string }>;

export const RadioInput = observer(function RadioInput<T extends string>({
    value,
    data,
    onChange,
    className,
    title,
    error,
}: Props<T>): React.ReactElement {
    const idName = useConst(() => String(generateId()));

    const handleChange = (e: GenericRadioChangeEvent<T>) => {
        onChange(e.target.value);
    };

    return (
        <BaseInput
            title={title}
            className={className}
            error={error}
        >
            {data.map(({ value: key, label }) => (
                <label key={key}>
                    <input
                        type="radio"
                        name={idName}
                        value={key}
                        checked={value === key}
                        onChange={handleChange}
                    />
                    <span>{label}</span>
                </label>
            ))}
        </BaseInput>
    );
});
