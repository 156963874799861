import { generateUuid } from "@viuch/shared/utils/data";

import type { ISerializedLim } from "./types";

import { createSerializedContainer } from "../../utils/serialization";

export function createSerializedLim(): ISerializedLim {
    return {
        type: "lim",
        uuid: generateUuid(),
        contentFrom: createSerializedContainer(),
        contentTo: createSerializedContainer(),
        content: createSerializedContainer(),
    };
}
