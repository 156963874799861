import { action, makeObservable, observable } from "mobx";

import { generateId } from "@viuch/shared/utils/data";

import type { IDescriptionTemplateVisitor, IBaseTemplate } from "./BaseTemplate";

import { BaseTemplate } from "./BaseTemplate";

export interface ISolverTemplate extends IBaseTemplate {
    isShallow: boolean;
}

export class SolverTemplate extends BaseTemplate implements ISolverTemplate {
    @observable isShallow: boolean;

    constructor(data: ISolverTemplate) {
        super(data);

        this.isShallow = data.isShallow;

        makeObservable(this);
    }

    static create(data: ISolverTemplate) {
        return new SolverTemplate(data);
    }

    static createEmpty() {
        return this.create({
            id: generateId(),
            isShallow: false,
            name: "",
            templates: new Map(),
        });
    }

    accept<R>(visitor: IDescriptionTemplateVisitor<R>): R {
        return visitor.withSolver(this);
    }

    @action.bound
    setIsShallow(isShallow: boolean) {
        this.isShallow = isShallow;
    }
}
