export const colors = [
    //
    "#bbf021",
    "#fff",
    "#acd7ff",
    "#2e86e0",
    "#324c68",
    "#ff603d",
    "#21f0a5",
];
