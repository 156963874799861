import cn from "classnames";
import React from "react";

import { ReactComponent as MinusIcon } from "@viuch/assets/icons/minus-red.svg";
import { ReactComponent as PlusIcon } from "@viuch/assets/icons/plus-black.svg";

import type { TIntervalSign } from "../../statement";

import { Interval } from "./Interval";
import * as constants from "./IntervalContainer.constants";

import styles from "./IntervalContainer.module.scss";

type Props = {
    sign: TIntervalSign;
    style?: React.CSSProperties;
    first?: boolean;
    last?: boolean;
    onSignClick(sing: TIntervalSign): void;
    readonly?: boolean;
};

export const IntervalContainer = ({ sign, style, first, last, onSignClick, readonly }: Props) => {
    return (
        <div
            className={styles.intervalWrapper}
            style={style}
        >
            <div className={styles.intervalBody}>
                {!readonly && (
                    <button
                        className={cn(styles.intervalPlusButton, constants.signToPositiveClassNameMap[sign])}
                        onClick={() => !readonly && onSignClick("positive")}
                        disabled={readonly}
                    >
                        <PlusIcon className={styles.plusIcon} />
                    </button>
                )}
                <Interval
                    sign={sign}
                    first={first}
                    last={last}
                />
                {!readonly && (
                    <button
                        className={cn(styles.intervalMinusButton, constants.signToNegativeClassNameMap[sign])}
                        disabled={readonly}
                    >
                        <MinusIcon
                            className={styles.minusIcon}
                            onClick={() => !readonly && onSignClick("negative")}
                        />
                    </button>
                )}
            </div>
        </div>
    );
};
