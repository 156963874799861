import React from "react";

import type { TCellRenderProps } from "./types";
import type { ITableCellVisitor } from "../settings/cells";

import { TableInstrumentSettingsSelectCellEditor } from "./select-cell";
import { TableInstrumentSettingsTextCellEditor } from "./text-cell";

export const cellRenderer: ITableCellVisitor<(props: TCellRenderProps) => React.ReactElement> = {
    withTextCell:
        (model) =>
        ({ keyboardService }) =>
            (
                <TableInstrumentSettingsTextCellEditor
                    textCell={model}
                    keyboardService={keyboardService}
                />
            ),
    withSelectCell: (model) => () => <TableInstrumentSettingsSelectCellEditor cell={model} />,
};
