import React from "react";

import type { TGeometrySymbols } from "./GeometryElement.types";

import { ReactComponent as CircleIcon } from "./icons/circle.svg";
import { ReactComponent as ParallelogramIcon } from "./icons/parallelogram.svg";
import { ReactComponent as RectIcon } from "./icons/rect.svg";
import { ReactComponent as RhombIcon } from "./icons/rhomb.svg";
import { ReactComponent as SquareIcon } from "./icons/square.svg";
import { ReactComponent as TrapezeIcon } from "./icons/trapeze.svg";
import { ReactComponent as TriangleIcon } from "./icons/triangle.svg";
import { ReactComponent as PerpendicularSymbolIcon } from "./perpendicular-symbol-icon.svg";

import styles from "./GeometryElement.module.scss";

export const geometrySymbols: TGeometrySymbols = {
    parallel: { renderChar: () => "||", className: styles.parallel },
    perpendicular: {
        renderChar: () => <PerpendicularSymbolIcon />,
        className: styles.perpendicular,
    },

    square: {
        renderChar: () => <SquareIcon className={styles.icon} />,
    },
    trapeze: {
        renderChar: () => <TrapezeIcon className={styles.icon} />,
    },
    rect: {
        renderChar: () => <RectIcon className={styles.icon} />,
    },
    circle: {
        renderChar: () => <CircleIcon className={styles.icon} />,
    },
    parallelogram: {
        renderChar: () => <ParallelogramIcon className={styles.icon} />,
    },
    rhomb: {
        renderChar: () => <RhombIcon className={styles.icon} />,
    },
    triangle: {
        renderChar: () => <TriangleIcon className={styles.icon} />,
    },
};
