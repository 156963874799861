import cn from "classnames";
import * as mobx from "mobx";
import { observer } from "mobx-react-lite";
import React from "react";

import { doNothing } from "@viuch/utils/debug";

import type { CursorState } from "../../core/cursor";

import { scheduleTask } from "../../utils/runtime";

import { computeNewScrollAxis, scrollMargin } from "./CursorElement.utils";

import styles from "./CursorElement.module.scss";

export interface ICursorElementProps {
    cursorState: CursorState;
}

export const CursorElement: React.VFC<ICursorElementProps> = observer(function CursorElement({ cursorState }) {
    const ref = React.useRef<HTMLDivElement | null>(null);

    const disabled = cursorState.inputService.isDisabled;

    React.useEffect(() => {
        const dispose = mobx.autorun(() => {
            doNothing(cursorState.container, cursorState.index);

            scheduleTask(() => {
                const inputService = cursorState.inputService;
                const input = inputService.model.domElement;
                const cursor = ref.current!;

                if (!cursor || !input) {
                    return;
                }

                const inputRect = input.getBoundingClientRect();
                const marginBottom = -Number.parseInt(input.style.marginBottom);
                const marginRight = -Number.parseInt(input.style.marginRight);
                const cursorRect = cursor.getBoundingClientRect();

                input.scrollTop = computeNewScrollAxis(
                    input.scrollTop,
                    cursorRect.y,
                    inputRect.y,
                    cursorRect.height,
                    inputRect.height - (Number.isNaN(marginBottom) ? 0 : marginBottom),
                    scrollMargin
                );

                input.scrollLeft = computeNewScrollAxis(
                    input.scrollLeft,
                    cursorRect.x,
                    inputRect.x,
                    cursorRect.width,
                    inputRect.width - (Number.isNaN(marginRight) ? 0 : marginRight),
                    scrollMargin
                );
            });
        });
        return () => {
            dispose();
        };
    }, [cursorState]);

    React.useEffect(() => {
        cursorState.setDomElement(ref.current!);
    }, [cursorState]);

    return (
        <div
            ref={ref}
            className={cn(styles.cursor, (!cursorState.isShown || disabled) && styles.hidden)}
        />
    );
});
