import type { TMathCharsRow } from "./types";

import { charActionCreator } from "../utils";

export function makeMathCharsRowFromAlphabet(chars: string, tags: string[]): TMathCharsRow {
    return Array.from(chars).map((char) => ({
        text: char,
        actionCreator: charActionCreator(char),
        tags,
    }));
}
