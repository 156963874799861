import cn from "classnames";
import React from "react";

import type { IRoundBracketProps, IRoundBracketState } from "./RoundBracket.types";
import type { IRoundBracketConfig, IRoundBracketController } from "./types";

import styles from "./RoundBracket.module.scss";

export class RoundBracket
    extends React.Component<IRoundBracketProps, IRoundBracketState>
    implements IRoundBracketController
{
    constructor(props: IRoundBracketProps) {
        super(props);
        this.state = { fontBracket: true };
    }

    private svgRef = React.createRef<SVGSVGElement>();
    private bracketRef = React.createRef<SVGPathElement>();

    public updateBracketParams({
        width,
        height,
        x,
        xControl,
        offsetBottom,
        offsetTop,
        strokeWidth,
    }: IRoundBracketConfig): void {
        const svg = this.svgRef.current;
        const bracket = this.bracketRef.current!;

        if (!svg) {
            return;
        }

        const { closing } = this.props;

        const xr = closing ? width - x : x;
        const xc = xr - xControl * (closing ? -1 : 1);
        const yt = offsetTop;
        const yb = height - offsetBottom;
        const ym = yt + (yb - yt) / 2;

        svg.setAttribute("width", `${String(width)}px`);
        svg.setAttribute("height", `${String(height)}px`);
        svg.setAttribute("viewBox", `0 0 ${width} ${height}`);

        bracket.setAttribute("stroke-width", String(strokeWidth));
        bracket.setAttribute("d", `M ${xr} ${yt} Q ${xc} ${ym} ${xr} ${yb}`);
        this.setState({ fontBracket: false });
    }

    public useFontBracket(): void {
        this.setState({ fontBracket: true });
    }

    override render(): React.ReactElement | null {
        const { className, closing } = this.props;
        const { fontBracket } = this.state;
        return (
            <span className={cn(className, styles.bracket)}>
                {fontBracket && (closing ? ")" : "(")}
                <svg
                    ref={this.svgRef}
                    className={cn(styles.bracketSvg, fontBracket && styles.hidden)}
                >
                    <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        fill="transparent"
                        ref={this.bracketRef}
                    />
                </svg>
            </span>
        );
    }
}
