import type { TFormControl } from "@viuch/form-builder/types/controls";

import { createLabel } from "../controls/label";
import { valueMathControl, answerTargetFormatMathControl } from "../controls/value";

// Array of expression inputs for `simplify_and_substitute` simplification solver.
const substitutionsControl: TFormControl = {
    control: "Form",
    controls: [
        {
            control: "MathArray",
            path: "./substitutions",
            label: "Подстановки (В виде x=3)",
        },
    ],
    layout: "column",
    condition: { "./solver_type": "simplify_and_substitute" },
};

const isFactorRadical: TFormControl = {
    control: "Form",
    controls: [
        {
            control: "Checkbox",
            path: "./factor_radical",
            label: "Выносить корни за множитель",
        },
    ],
    layout: "column",
    condition: { "./solver_type": "factor" },
};

const isEvaluationWithPrecision: TFormControl = {
    control: "Form",
    controls: [
        {
            control: "Number",
            path: "./precision",
            label: "С точностью до знаков после запятой",
        },
    ],
    layout: "column",
    condition: { "./solver_type": "evaluate_precision" },
};

const withAnswerTarget: TFormControl = {
    control: "Form",
    controls: [answerTargetFormatMathControl],
    layout: "column",
    condition: { "./solver_type": ["evaluate", "smart_evaluate"] },
};

export const simplify: TFormControl = {
    control: "Form",
    layout: "column",
    condition: {
        "./solver_type": [
            "simplify",
            "simplify_log",
            "simplify_exponential",
            "simplify_rational",
            "simplify_radical",
            "simplify_trigonometry",
            "simplify_and_substitute",
            "expand",
            "factor",
            "add_common_terms",
            "mul_common_terms",
            "rationalize",
            "to_polynomial",
            "simplify_root",
            "reverse_simplify_root",
            "numeric_nested_root",
            "polynomial_nested_root",
            "beautify",
            "evaluate",
            "smart_evaluate",
            "evaluate_interval",
            "polynom_divide",
            "evaluate_precision",
        ],
    },
    controls: [
        createLabel("Упрощает выражение, используя большинство доступных методов", { "./solver_type": "simplify" }),
        createLabel("Упрощает выражение используя свойства логарифмов", { "./solver_type": "simplify_log" }),
        createLabel("Упрощает выражение используя свойства степеней", { "./solver_type": "simplify_exponential" }),
        createLabel("Упрощает рациональную дробь, приводя общие множители", { "./solver_type": "simplify_rational" }),
        createLabel("Упрощает выражение в радикалах", { "./solver_type": "simplify_radical" }),
        createLabel("Упрощает выражение используя тригонометрические тождества", {
            "./solver_type": "simplify_trigonometry",
        }),
        createLabel("Раскрывает скобки в произведении сумм", { "./solver_type": "expand" }),
        createLabel(
            "Раскладывает выражение на множители. В случае, если выражение не получается разложить, исходное выражение возвращается.",
            { "./solver_type": "factor" }
        ),
        createLabel("Приведение общих слагаемых", { "./solver_type": "add_common_terms" }),
        createLabel("Приведение общих множителей", { "./solver_type": "mul_common_terms" }),
        createLabel("Преобразует выражение в рациональную дробь, приводя выражение к общим множителям", {
            "./solver_type": "rationalize",
        }),
        createLabel("", { "./solver_type": "to_polynomial" }),
        createLabel("Упрощает выражение вида корень из числа", { "./solver_type": "simplify_root" }),
        createLabel("Вносит множители под корень", { "./solver_type": "reverse_simplify_root" }),
        createLabel("Упрощает выражение с вложенным числовым корнем путем выделения полного квадрата", {
            "./solver_type": "numeric_nested_root",
        }),
        createLabel("Упрощает выражение с вложенным полиномиальным корнем путем выделения полного квадрата", {
            "./solver_type": "polynomial_nested_root",
        }),
        createLabel("Убирает из выражения лишние умножения на 1 и сложения с 0", { "./solver_type": "beautify" }),
        createLabel("Вычисление численного выражения", { "./solver_type": "evaluate" }),
        createLabel("Упрощает выражения с последующей подстановкой значений и вычислением", {
            "./solver_type": "simplify_and_substitute",
        }),
        createLabel("Вычисляет пересечения и объединения интервалов", {
            "./solver_type": "evaluate_interval",
        }),
        createLabel("Делит один многочлен на другой с остатком", {
            "./solver_type": "polynom_divide",
        }),
        valueMathControl,
        substitutionsControl,
        isFactorRadical,
        isEvaluationWithPrecision,
        withAnswerTarget,
    ],
};
