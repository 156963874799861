import type { TFragment, TPoint } from "./types";

export function subtractVectors(p1: TPoint, p2: TPoint): TPoint {
    return {
        x: p2.x - p1.x,
        y: p2.y - p1.y,
    };
}

export function addVectors(p1: TPoint, p2: TPoint): TPoint {
    return {
        x: p1.x + p2.x,
        y: p1.y + p2.y,
    };
}

export function multiplyVector(vector: TPoint, multiplier: number): TPoint {
    return { x: vector.x * multiplier, y: vector.y * multiplier };
}

export function middlePoint(p1: TPoint, p2: TPoint): TPoint;
export function middlePoint(fragment: TFragment): TPoint;
export function middlePoint(p1: TPoint | TFragment, p2?: TPoint): TPoint {
    const [_p1, _p2] = "a" in p1 ? [p1.a, p1.b] : [p1, p2!];
    return { x: (_p1.x + _p2.x) / 2, y: (_p1.y + _p2.y) / 2 };
}

export function vectorLength(vector: TPoint): number;
export function vectorLength(x: number, y: number): number;
export function vectorLength(x: number | TPoint, y?: number): number {
    const [_x, _y] = typeof x === "object" ? [x.x, x.y] : [x, y!];
    return Math.sqrt(squaredVectorLength(_x, _y));
}

export function squaredVectorLength(x: number, y: number): number;
export function squaredVectorLength(vector: TPoint): number;
export function squaredVectorLength(_x: number | TPoint, _y?: number): number {
    const [x, y] = typeof _x === "object" ? [_x.x, _x.y] : [_x, _y!];
    return x * x + y * y;
}

export function distancePoints(a: TPoint, b: TPoint): number {
    return vectorLength(a.x - b.x, a.y - b.y);
}
