import { computed, makeObservable } from "mobx";

import { InputService } from "@viuch/math-editor";

import type { Graph1DInstrumentStatementDot } from "../../statement";

export class DotViewModel {
    private readonly dot: Graph1DInstrumentStatementDot;

    constructor(dot: Graph1DInstrumentStatementDot) {
        this.dot = dot;
        makeObservable(this, {
            nameInput: computed,
            valueInput: computed,
        });
    }

    get nameInput(): InputService {
        const initialState = this.dot.name;
        return new InputService({
            isReadOnly: true,
            disableRuntimePlaceholders: true,
            initialState,
        });
    }

    get valueInput(): InputService {
        const initialState = this.dot.value;
        return new InputService({
            isReadOnly: true,
            disableRuntimePlaceholders: true,
            initialState,
        });
    }
}
