import * as mobx from "mobx";

import type { ISerializedDegree } from "./types";
import type { TSerializedElementPrototype } from "../../core/element";
import type { IElementVisitor } from "../../core/element/IElementVisitor";
import type { IStrategy } from "../../core/strategies";
import type { IMultiContainerElement } from "../../core/strategies/types";
import type { InputService } from "../../services";
import type { TContainerElements, TElementDeserializerFunction } from "../../types";

import { ContainerModel } from "../../core/container";
import { BaseElementModel } from "../../core/element";

import { DegreePrevElementAllowFinder } from "./DegreePrevElementAllowFinder";
import { DegreeStrategy } from "./DegreeStrategy";

export class DegreeModel extends BaseElementModel<ISerializedDegree> implements IMultiContainerElement {
    public readonly content: ContainerModel;
    private readonly finder = new DegreePrevElementAllowFinder();

    public constructor(inputService: InputService, content: TContainerElements, uuid?: string) {
        super(inputService, uuid);

        this.content = new ContainerModel(content, this, ["content"], inputService, {
            showPlaceholderIfEmpty: true,
        });

        mobx.makeObservable(this, {
            domElementBeforeDegree: mobx.computed,
        });
    }

    protected override initBehaviour(): IStrategy {
        return new DegreeStrategy(this);
    }

    public get domElementBeforeDegree(): HTMLElement | null {
        const prevIndex = this.index - 1;
        if (prevIndex < 0) {
            return null;
        }
        const prevElement = this.parentContainer.getElementByIndex(prevIndex);

        if (this.finder.canElementBePrev(prevElement)) {
            return prevElement?.domElement ?? null;
        }
        return null;
    }

    public serialize(): ISerializedDegree {
        return {
            type: "degree",
            content: this.content.serialize(),
            uuid: this.uuid,
        };
    }

    public static deserialize: TElementDeserializerFunction<ISerializedDegree> = (
        { inputService },
        { uuid, content }
    ) => new DegreeModel(inputService, inputService.deserializeContainer(content), uuid);

    getContainersToMoveCursorBetween(): ContainerModel[] {
        return [this.content];
    }

    public accept<R>(visitor: IElementVisitor<R>): R {
        return visitor.doWithDegree(this);
    }

    serializeAsClone(): TSerializedElementPrototype<ISerializedDegree> {
        const { uuid, ...clone } = this.serialize();
        return {
            ...clone,
            content: this.content.serializeAsClone(),
        };
    }

    override getWrapperContainer(): ContainerModel | null {
        return this.content;
    }
}
