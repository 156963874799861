import React from "react";

import type { SpecialSymbolModel } from "./SpecialSymbolModel";
import type { TElementProps } from "../../types/element";

import { useMathEditorElementRef } from "../../hooks";
import { BaseSymbolElement } from "../base/base-symbol";

import { specialSymbols } from "./SpecialSymbolElement.constants";

export const SpecialSymbolElement: React.VFC<TElementProps<SpecialSymbolModel>> = ({ elementModel }) => {
    const { char, className, renderIcon } = specialSymbols[elementModel.symbolName] ?? {
        char: "_",
    };
    const ref = useMathEditorElementRef(elementModel);

    return (
        <BaseSymbolElement
            ref={ref}
            className={className}
        >
            {char}
            {renderIcon?.()}
        </BaseSymbolElement>
    );
};
