import { action, computed, makeObservable, observable } from "mobx";

import type { BaseFormNode, IFormNodeVisitor } from "./BaseFormNode";
import type { ISelectItemsProvider } from "../types/interfaces";

import { BaseValueFormNode } from "./BaseValueFormNode";

export class SelectFormNode extends BaseValueFormNode {
    @observable currentValue: string | number | null;

    private itemsProvider: ISelectItemsProvider;

    constructor(
        initialValue: string | number | null,
        label: string,
        itemsProvider: ISelectItemsProvider,
        parent: BaseFormNode | null,
        path: string[],
        extensions: string[]
    ) {
        super(label, parent, path, extensions);

        this.itemsProvider = itemsProvider;
        this.currentValue = initialValue;

        makeObservable(this);
    }

    @computed
    get items(): TSelectFormNodeItem[] {
        return this.itemsProvider.getItems();
    }

    @action.bound
    setValue(value: string | number | null) {
        this.currentValue = value;
    }

    @computed get currentItem(): TSelectFormNodeItem | null {
        const currentValue = this.currentValue;
        return this.items.find((item) => item.value === currentValue) ?? null;
    }

    accept<R>(visitor: IFormNodeVisitor<R>): R {
        return visitor.withSelect(this);
    }

    getValue(): unknown {
        return this.currentValue;
    }

    trySetValue(value: unknown): boolean {
        const item = this.items.find((item) => item.value === value);
        if (item) {
            this.currentValue = item.value;
            return true;
        }
        return false;
    }

    valueEqualsTo(value: unknown): boolean {
        return this.currentValue === value;
    }
}

export type TSelectFormNodeItem = {
    label: string;
    value: string | number | null;
};
