import { observer } from "mobx-react-lite";
import React from "react";

import { Portal } from "@viuch/shared/features/portal-lib";
import { CheckboxInput, MathInput, SelectInput } from "@viuch/ui-common";
import { ModalFrame } from "@viuch/ui-kit/frames";
import { ModalBackdrop, ModalLayout } from "@viuch/ui-kit/surfaces";

import type { TGraph1DDotForms, Graph1DInstrumentStatementDot } from "@viuch/instrument-graph1d/statement";
import type { KeyboardService } from "@viuch/math-editor";
import type { TSelectInputData } from "@viuch/ui-common";

import { EditDotModalViewModel } from "./EditDotModal.vm";

import styles from "./EditDotModal.module.scss";

type Props = {
    dot: Graph1DInstrumentStatementDot;
    title: string;
    onSave(dot: Graph1DInstrumentStatementDot): void;
    onRemove?(dot: Graph1DInstrumentStatementDot): void;
    onCancel(dot: Graph1DInstrumentStatementDot): void;
    keyboardService: KeyboardService;
};

const dotFormInputData: TSelectInputData<TGraph1DDotForms> = [
    { label: "Черта", value: "line" },
    { label: "Круг", value: "circle" },
];

export const EditDotModal: React.VFC<Props> = observer(function EditDotModal(props) {
    const { title, dot, onSave, onRemove, onCancel, keyboardService } = props;

    const handleClose = () => {
        onCancel(dot);
    };

    const handleSave = () => {
        onSave(dot);
    };

    const handleRemove = () => {
        onRemove?.(dot);
    };

    const vm = React.useMemo(() => new EditDotModalViewModel(dot), [dot]);

    return (
        <Portal>
            <ModalBackdrop
                onClick={handleClose}
                className={styles.backdrop}
            >
                <ModalFrame className={styles.frame}>
                    <ModalLayout
                        title={title}
                        onClose={handleClose}
                    >
                        <div className={styles.content}>
                            <MathInput
                                inputService={vm.nameInput}
                                keyboardService={keyboardService}
                                onBlur={vm.setName}
                                title="Название точки"
                            />
                            <CheckboxInput
                                type="checkbox"
                                checked={dot.isNameVisible}
                                onChange={dot.setIsNameVisible}
                                labelAfter="Показывать название точки"
                            />
                            <MathInput
                                inputService={vm.valueInput}
                                keyboardService={keyboardService}
                                onBlur={vm.setValue}
                                title="Формула точки"
                            />
                            <CheckboxInput
                                type="checkbox"
                                checked={dot.isValueVisible}
                                onChange={dot.setIsValueVisible}
                                labelAfter="Показывать значение точки"
                            />
                            <SelectInput
                                data={dotFormInputData}
                                value={vm.dot.form}
                                onChange={vm.setForm}
                                title="Форма точки"
                            />
                            <button onClick={handleSave}>Сохранить</button>
                            {onRemove && <button onClick={handleRemove}>Удалить</button>}
                        </div>
                    </ModalLayout>
                </ModalFrame>
            </ModalBackdrop>
        </Portal>
    );
});
