import type { IntervalsInstrumentStatement } from "@viuch/instrument-intervals/statement";

export class IntervalsInstrumentSettings {
    readonly intervals: IntervalsInstrumentStatement;

    constructor(intervals: IntervalsInstrumentStatement) {
        this.intervals = intervals;
    }

    clone() {
        return new IntervalsInstrumentSettings(this.intervals.clone());
    }
}
