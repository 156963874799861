import { action, computed, makeObservable, observable } from "mobx";
import { createTransformer } from "mobx-utils";

import { areSamePoints } from "@viuch/geometry-lib/check-geometry";

import type { BaseElement } from "../../elements";
import type { Figure2DController } from "../../Figure2DController";
import type { ToolbarButton, ToolbarMenu } from "../../toolbar";
import type { IUserPointer } from "../../viewport/types";
import type { TPoint } from "@viuch/geometry-lib/types";
import type React from "react";

import { DotElement } from "../../elements/dot";
import { ElementColor } from "../../elements/ElementColor";
import { LabelPointModel } from "../../models/label-point";
import { PointModel } from "../../models/point";
import { checkModelType, ModelTypes } from "../../services/actions/utils";
import { ButtonStates } from "../../toolbar";
import { TooltipMenu } from "../../toolbar/tooltip";
import { createDefaultToolbarMenuBuilder, handleToolbarButtons } from "../../utils/toolbar";
import { BaseFlow } from "../BaseFlow";
import { createMapModelToElements, opacifyColor } from "../utils";

export class CreateDotFlow extends BaseFlow {
    flow: {
        point?: TPoint;
        drawing: boolean;
    };

    constructor(data: Figure2DController) {
        super(data);
        this.flow = { drawing: false };

        makeObservable(this, {
            flow: observable,
            modelsElements: computed,
        });
    }

    static create(data: Figure2DController) {
        return new CreateDotFlow(data);
    }

    attach(): void {
        this.viewport.disable();
        this.snap.addViewportGrid().addFigureModelsPoints();
    }

    dispose(): void {
        this.snap.clean();
    }

    mapModel = createTransformer(createMapModelToElements(opacifyColor));

    *getTempPoint(): Iterable<BaseElement> {
        if (!this.flow.point) return;

        const { x, y } = this.flow.point;

        yield DotElement.create({
            id: "temp__dot",
            x,
            y,
            color: ElementColor.Building,
            overrideRenderOrder: 0,
        });
    }

    get modelsElements(): BaseElement[] {
        return this.figure.models.flatMap(this.mapModel);
    }

    renderElements(): BaseElement[] {
        return [...this.modelsElements, ...this.getTempPoint()];
    }

    override handleViewportPointerEvent = action((e: React.PointerEvent, pointer: IUserPointer) => {
        if (e.type === "pointercancel") return this.nextFlow();
        if (!e.isPrimary) return;

        const point = this.snap.snap(pointer.canvas, pointer.canvas);

        this.movePoint(e, point);
        this.startDrawing(e);
        this.endDrawing(e);
    });

    private movePoint(e: React.PointerEvent, point: TPoint) {
        if (e.type === "pointerdown" || e.type === "pointermove") {
            this.flow.point = point;
        }
    }

    private startDrawing(e: React.PointerEvent) {
        if (e.type === "pointerdown") {
            this.flow.drawing = true;
        }
    }

    private hasPointOnPosition(point: TPoint): boolean {
        return this.figure.models.some(
            (model) => checkModelType(model, ModelTypes.point) && areSamePoints(model, point)
        );
    }

    private endDrawing(e: React.PointerEvent) {
        if (e.type === "pointerup") {
            this.flow.drawing = false;
            const { point } = this.flow;
            if (point && !this.hasPointOnPosition(point)) {
                this.figure.insertModels(function* () {
                    yield PointModel.create({ ...point, style: null });
                    yield LabelPointModel.createNext(point, this.figure);
                }, this);

                this.nextFlow(CreateDotFlow.create);
            } else {
                this.nextFlow();
            }
        }
    }

    override getToolbarMenu(): ToolbarMenu {
        return createDefaultToolbarMenuBuilder()
            .setButtonIcon("dot", ["fragments"])
            .setButtonState(ButtonStates.active, ["fragments"])
            .build();
    }

    override handleToolbarButtonClick(menu: ToolbarMenu, button: ToolbarButton): void {
        switch (button.key) {
            case "fragments":
                return this.nextFlow();

            default:
                handleToolbarButtons(this, button);
        }
    }

    override getTooltipMenu(): TooltipMenu | null {
        return new TooltipMenu("Поставьте точку", []);
    }
}
