import { generateUuid } from "@viuch/shared/utils/data";

import type { ISerializedFraction } from "./types";
import type { TAnySerializedElement } from "../../types";

import { createSerializedContainer } from "../../utils/serialization";

export function createSerializedFraction(
    numerator: TAnySerializedElement[] = [],
    denominator: TAnySerializedElement[] = []
): ISerializedFraction {
    return {
        type: "fraction",
        uuid: generateUuid(),
        numerator: createSerializedContainer(numerator),
        denominator: createSerializedContainer(denominator),
    };
}
