import cn from "classnames";
import { observer } from "mobx-react-lite";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";

import { Portal } from "@viuch/shared/features/portal-lib";

import type { Graph2DToolbar } from "../../core/Graph2DToolbar";
import type { IToolbarElement } from "../toolbarTypes";
import type { RefObject } from "react";

import { AnyToolbarElement } from "../AnyToolbarElement";

import { ToolbarButtonIcon } from "./ToolbarButtonIcon";

import styles from "./ToolbarMenuButton.module.scss";

type Props = {
    element: IButtonMenuToolbarElement;
    toolbar: Graph2DToolbar;
    rootRef: RefObject<HTMLDivElement>;
    className?: string;
};

export const ToolbarMenuButton = observer(function ToolbarMenuButton({ element, toolbar, rootRef, className }: Props) {
    const { icon, content, enabled } = element;

    const [isOpen, setIsOpen] = useState(false);

    const buttonRef = useRef<HTMLDivElement>(null);
    const portalRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!enabled) {
            setIsOpen(false);
        }
    }, [enabled]);

    useLayoutEffect(() => {
        if (!isOpen) return;

        const update = () => {
            const buttonEl = buttonRef.current;
            const portalEl = portalRef.current;

            if (!buttonEl || !portalEl) return;

            portalEl.style.setProperty("--offset-top", `0px`);
            portalEl.style.setProperty("--offset-left", `0px`);

            const { top: buttonTop, right: buttonRight } = buttonEl.getBoundingClientRect();
            const { top: portalTop, left: portalLeft } = portalEl.getBoundingClientRect();

            const portalOffsetTop = buttonTop - portalTop;
            const portalOffsetLeft = buttonRight - portalLeft;

            portalEl.style.setProperty("--offset-top", `${portalOffsetTop}px`);
            portalEl.style.setProperty("--offset-left", `${portalOffsetLeft}px`);
        };

        update();
    }, [isOpen]);

    useEffect(() => {
        const innerElementRefs: RefObject<HTMLElement>[] = [buttonRef];

        document.addEventListener("click", (e) => {
            const clickTarget = e.target;
            if (!(clickTarget instanceof Node)) return;

            const preventClose = innerElementRefs.some(({ current: element }) => {
                return element?.contains(clickTarget);
            });

            if (!preventClose) {
                setIsOpen(false);
            }
        });
    }, []);

    return (
        <div className={cn(styles.root, className, !enabled && styles._disabled)}>
            <div
                className={styles.button}
                onClick={() => enabled && setIsOpen((v) => !v)}
                ref={buttonRef}
            >
                <ToolbarButtonIcon iconSrc={icon} />
                <i className={styles.chunk} />
            </div>

            {isOpen && (
                <Portal
                    elementRef={portalRef}
                    className={styles.portal}
                >
                    <AnyToolbarElement
                        toolbar={toolbar}
                        element={content}
                        className={styles.nestedMenu}
                        rootRef={rootRef}
                    />
                </Portal>
            )}
        </div>
    );
});

export interface IButtonMenuToolbarElement extends IToolbarElement {
    type: "button-menu";
    icon: string;
    enabled?: boolean;
    content: IToolbarElement;
}
