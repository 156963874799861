import { action, makeObservable, observable } from "mobx";

import type { IMultichoiceInstrumentSettingsFieldVisitor } from "../BaseMultichoiceInstrumentSettingsField";
import type { TSerializedState } from "@viuch/math-editor";

import { BaseMultichoiceInstrumentSettingsField } from "../BaseMultichoiceInstrumentSettingsField";

export class TextMultichoiceInstrumentSettingsField extends BaseMultichoiceInstrumentSettingsField {
    @observable text: string;

    constructor(text: string, checked: boolean, mistakeHint: TSerializedState | null) {
        super(checked, mistakeHint);

        this.text = text;

        makeObservable(this);
    }

    static createEmpty(): TextMultichoiceInstrumentSettingsField {
        return new TextMultichoiceInstrumentSettingsField("", false, null);
    }

    @action.bound
    setText(text: string): void {
        this.text = text;
    }

    clone() {
        return new TextMultichoiceInstrumentSettingsField(this.text, this.checked, this.mistakeHint);
    }

    accept<R>(visitor: IMultichoiceInstrumentSettingsFieldVisitor<R>): R {
        return visitor.withText(this);
    }
}
