import classNames from "classnames";
import cn from "classnames";
import { observer } from "mobx-react-lite";
import { useEffect, useMemo, useRef } from "react";

import { useConst, useIsSafari } from "@viuch/utils/hooks";

import type { TSerializedState } from "../../types";

import { InputService } from "../../services";
import { splitToTextFragments } from "../../utils/analysis";
import { MathEditorInput } from "../editor";

import styles from "./MathExpressionView.module.scss";

type Props = {
    mathExpression: TSerializedState;
    className?: string;
    formulaClassName?: string;
};

export const MathExpressionView = observer(function MathExpressionView({
    mathExpression,
    className,
    formulaClassName,
}: Props) {
    const fragments = useMemo(() => splitToTextFragments(mathExpression), [mathExpression]);

    const ref = useRef<HTMLDivElement & { mathExpr?: TSerializedState }>(null);

    useEffect(() => {
        const el = ref.current!;

        el.mathExpr = mathExpression;
    }, [mathExpression]);

    return (
        <div
            className={classNames(styles.wrapper, className)}
            ref={ref}
        >
            {fragments.map((fragment, i) => {
                if (fragment.type === "text") {
                    return (
                        <div
                            className={styles.textBlock}
                            key={i}
                        >
                            <div className={styles.textBlock__inner}>{fragment.text}</div>
                        </div>
                    );
                }

                if (fragment.type === "linebreak") {
                    return <br key={i} />;
                }

                if (fragment.type === "formula") {
                    return (
                        <MathExpressionFormula
                            formula={fragment.formula}
                            key={i}
                            className={formulaClassName}
                        />
                    );
                }
            })}
        </div>
    );
});

type FormulaProps = {
    formula: TSerializedState;
    className?: string;
};

export const MathExpressionFormula = observer(function MathExpressionFormula({ formula, className }: FormulaProps) {
    const inputService = useConst(() => {
        return new InputService({
            initialState: formula,
            isReadOnly: true,
            multiline: true,
            disableRuntimePlaceholders: true,
            disablePlaceholders: true,
        });
    });

    useEffect(() => {
        inputService.setSerializedState(formula);
    }, [formula, inputService]);

    const formulaBaselineRef = useRef<HTMLElement>(null);
    const outerBaselineRef = useRef<HTMLElement>(null);
    const innerBaselineRef = useRef<HTMLElement>(null);

    const isSafari = useIsSafari();

    useEffect(() => {
        if (!inputService) {
            return;
        }

        function reflow() {
            const outerElement = outerBaselineRef.current;
            const innerElement = innerBaselineRef.current;
            const formulaElement = formulaBaselineRef.current;

            if (!innerElement || !outerElement || !formulaElement) return;

            innerElement.style.setProperty("--offset", `0`);

            const formulaY = formulaElement.getBoundingClientRect().bottom;
            const innerY = innerElement.getBoundingClientRect().bottom;
            const outerY = outerElement.getBoundingClientRect().bottom;

            const diff = outerY - formulaY + (isSafari ? -2 : 0);

            innerElement.style.setProperty("--offset", `${diff}`);
        }

        [20, 30, 40, 50, 60, 70, 80, 90, 100, 500].map((ms) => {
            return setTimeout(() => {
                reflow();
            }, ms);
        });
    }, [formula, inputService, isSafari]);

    return (
        <div className={cn(styles.root, className)}>
            <div className={styles.mathTagWrapper}>
                <span
                    dangerouslySetInnerHTML={{ __html: "<!--noindex-->" }}
                    style={{ display: "contents" }}
                />
                <span
                    className={styles.baseline}
                    ref={outerBaselineRef}
                >
                    <span
                        className={styles.baseline__inner}
                        ref={innerBaselineRef}
                    >
                        0
                    </span>
                </span>
                <div className={styles.mathFieldOuter}>
                    <MathEditorInput
                        inputModel={inputService.model}
                        readonly
                        withoutScrollbar
                        className={styles.mathField}
                        inputWrapperClassName={styles.mathFieldWrapper}
                        contentClassName={styles.mathFieldContent}
                        baselineRef={formulaBaselineRef}
                    />
                </div>
                <span
                    dangerouslySetInnerHTML={{ __html: "<!--/noindex-->" }}
                    style={{ display: "contents" }}
                />
            </div>
        </div>
    );
});
