import { assert } from "@viuch/utils/debug";

import type { TFragment, TPoint } from "./types";

import { getAngle } from "./angles";
import { areSameFragments, areSamePoints } from "./check-geometry";
import { compareFloats, equateNumbers } from "./equate";
import { createAngle } from "./factories";
import { distancePoints } from "./vectors";

/**
 * Пытается создать выпуклый многоугольник, используя все заданные точки.
 */
export function tryCreateConvexPolygon(points: TPoint[]): TPoint[] | null {
    assert(points.length >= 3);

    const stack = [points[0]];

    while (true) {
        const a: TPoint = stack.at(-2) || points[1];
        const b: TPoint = stack.at(-1)!;

        let minDistance = Number.MAX_SAFE_INTEGER;
        let maxAngle = -1;
        let nextPoint: TPoint | undefined = a;

        for (const point of points) {
            if (point === a || point === b) {
                continue;
            }

            const angle = createAngle(a, b, point);
            const angleValue = getAngle(angle);

            if (equateNumbers(angleValue, ">", Math.PI)) {
                continue;
            }

            if (equateNumbers(angleValue, ">=", maxAngle)) {
                const distance = distancePoints(b, point);
                if (equateNumbers(distance, "<=", minDistance)) {
                    minDistance = distance;
                    maxAngle = angleValue;
                    nextPoint = point;
                }
            }
        }

        const indexInStack = stack.indexOf(nextPoint);

        if (indexInStack === -1) {
            stack.push(nextPoint);
        } else {
            break;
        }
    }

    if (stack.length === points.length) {
        return stack;
    }
    return null;
}

/**
 * Пытается создать выпуклый многоугольник, используя заданные отрезки.
 */
export function tryCreatePolygon(fragments_: TFragment[]): TPoint[] | null {
    assert(fragments_.length >= 3);

    const [first_, ...fragments] = [...fragments_];

    const stack: TPoint[] = [first_.a, first_.b];
    let globalDirection = 0;

    while (stack.length !== fragments_.length) {
        const a = stack.at(-2)!;
        const b = stack.at(-1)!;
        let c: TPoint | null = null;

        for (let i = 0; i < fragments.length; i++) {
            const fragment = fragments[i];

            if (areSamePoints(fragment.a, b)) {
                c = fragment.b;
                fragments.splice(i, 1);
                break;
            }
            if (areSamePoints(fragment.b, b)) {
                c = fragment.a;
                fragments.splice(i, 1);
                break;
            }
        }

        if (!c) {
            return null;
        }
        stack.push(c);

        const direction = compareFloats(getAngle({ start: a, vertex: b, end: c }), Math.PI);
        if (direction !== 0) {
            if (globalDirection === 0) {
                globalDirection = direction;
            } else if (globalDirection !== direction) {
                return null;
            }
        }
    }

    assert(fragments.length === 1);

    if (areSameFragments(fragments[0], { a: stack[0], b: stack.at(-1)! })) {
        return stack;
    }

    return null;
}
