import type { TControlsSchema } from "@viuch/form-builder/types/controls";

import { rendererConfigFields, rendererConfigFormControl } from "./rendererConfigFormControl";
import { selectSolverTypeControl } from "./selectSolverTypeControl";
import { commonDenominator } from "./solvers/commonDenominator";
import { compareNumbers } from "./solvers/compare";
import { derivative } from "./solvers/derivative";
import { compareDims, equationDims, evaluateDims } from "./solvers/dimensions";
import { divideWithRemainder } from "./solvers/divide";
import { equation, fillMissingNumbers } from "./solvers/equation";
import { estimateSigns } from "./solvers/estimteSigns";
import { factorIntegers } from "./solvers/factorIntegers";
import { findPrimes } from "./solvers/findPrimes";
import { fractionConversion } from "./solvers/fractionConversion";
import { gcd } from "./solvers/gcd";
import { geometry, figureCompletion, geometryProve } from "./solvers/geometry";
import { hideOperators } from "./solvers/hide-operators";
import { inequality } from "./solvers/inequality";
import { indefiniteIntegral, definitiveIntegral } from "./solvers/integrals";
import { limit } from "./solvers/limit";
import { minMax, localMinMax } from "./solvers/minMax";
import { linearIntegerSystem, multivariativeSystem, multivariativeSystemReplacement } from "./solvers/multivariative";
import { numberToWords } from "./solvers/numberToWords";
import { parametricPerRootCount, parametricPerSolver, parametricRootCount } from "./solvers/parametric";
import { proveIdentity, proveInequality, substituteAndProveIdentity } from "./solvers/proveIdentity";
import { rangeOfExpr } from "./solvers/rangeOfExpr";
import { rewriteWithConditions } from "./solvers/rewriteWithCondition";
import { simplify } from "./solvers/simplify";
import { trigonometryWithConditions } from "./solvers/trigonometryWithConditions";
import { univariativeSystem } from "./solvers/univariativeSystem";

export const solverRequestFormSchema: TControlsSchema = {
    rootControl: {
        path: "/",
        control: "Form",
        layout: "column",
        controls: [
            selectSolverTypeControl,

            // Решатели
            simplify,
            fractionConversion,
            univariativeSystem,
            derivative,
            indefiniteIntegral,
            compareNumbers,
            compareDims,
            equationDims,
            evaluateDims,
            divideWithRemainder,
            equation,
            gcd,
            geometry,
            geometryProve,
            inequality,
            definitiveIntegral,
            limit,
            minMax,
            localMinMax,
            numberToWords,
            hideOperators,
            rangeOfExpr,
            figureCompletion,
            rewriteWithConditions,
            trigonometryWithConditions,
            findPrimes,
            proveIdentity,
            proveInequality,
            fillMissingNumbers,
            linearIntegerSystem,
            multivariativeSystem,
            multivariativeSystemReplacement,
            substituteAndProveIdentity,
            commonDenominator,
            estimateSigns,
            factorIntegers,
            parametricPerSolver,
            parametricPerRootCount,
            parametricRootCount,

            // Настройки отображения
            rendererConfigFormControl,
        ],
    },
};

export const rendererConfigFormSchema: TControlsSchema = {
    rootControl: {
        path: "/",
        control: "Form",
        layout: "column",
        controls: rendererConfigFields,
    },
};
