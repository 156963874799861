import cn from "classnames";
import { Link } from "react-router-dom";

import type { FC, ReactElement } from "react";

import { useRootStore } from "../../integration";

import { getMenuBlocks } from "./AdminMain.utils";

import styles from "./AdminMain.module.scss";

const AdminMain: FC = () => {
    const rootStore = useRootStore();
    const menuBlocks = getMenuBlocks();
    const accessProvider = rootStore.auth.accessProvider;

    return (
        <div className={styles.wrapper}>
            {menuBlocks.map((block) => {
                const items = block.items.reduce((items: ReactElement[], { label, path, external, hasAccess }) => {
                    if (hasAccess(accessProvider)) {
                        items.push(
                            <li key={path + label}>
                                {external ? (
                                    <a
                                        href={path}
                                        target="_blank"
                                    >
                                        {label}
                                    </a>
                                ) : (
                                    <Link to={path}>{label}</Link>
                                )}
                            </li>
                        );
                    }

                    return items;
                }, []);

                if (items.length === 0) return null;

                return (
                    <div
                        className={cn(styles.block, block.style === "transparent" && styles.transparent)}
                        key={block.title}
                    >
                        <h2>{block.title}</h2>
                        <ul>{items}</ul>
                    </div>
                );
            })}
        </div>
    );
};

export default AdminMain;
