import type { TFormControl } from "@viuch/form-builder/types/controls";

import { createLabel } from "../controls/label";
import { exprMathControl } from "../controls/value";

export const hideOperators: TFormControl = {
    control: "Form",
    layout: "column",
    condition: { "./solver_type": "hide_operators" },
    controls: [
        createLabel(""), //
        exprMathControl,
    ],
};
