import cn from "classnames";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { useEffect, useRef } from "react";

import type { DownIndexModel } from "./DownIndexModel";
import type { TElementProps } from "../../types/element";
import type { VFC } from "react";

import { ContainerElement } from "../../core/container";
import { Baseline } from "../../core/element";
import { useMathEditorElementRef } from "../../hooks";

import { applyMargin } from "./DownIndexElement.utils";

import styles from "./DownIndex.module.scss";

export const DownIndexElement: VFC<TElementProps<DownIndexModel>> = observer(function DownIndexElement({
    elementModel,
}) {
    const ref = useMathEditorElementRef(elementModel);
    const baselineRef = useRef<HTMLSpanElement>(null);
    const contentRef = useRef<HTMLSpanElement>(null);

    useEffect(() => {
        let observer: ResizeObserver | null = null;
        const baselineElement = baselineRef.current!;
        const contentElement = contentRef.current!;

        runInAction(() => {
            const elementBefore = elementModel.domElementBeforeDigit;

            if (!elementBefore) {
                return;
            }

            observer = new ResizeObserver(() => {
                applyMargin(elementBefore, baselineElement, contentElement);
            });

            observer.observe(elementBefore);
            applyMargin(elementBefore, baselineElement, contentElement);
        });

        return () => {
            observer?.disconnect();
        };
    }, [elementModel.domElementBeforeDigit]);

    return (
        <span
            ref={ref}
            className={styles.wrapper}
        >
            <span className={styles.inlineWrapper}>
                <Baseline ref={baselineRef} />
                <span
                    ref={contentRef}
                    className={cn(styles.inlineWrapper, styles.content)}
                >
                    <ContainerElement
                        containerModel={elementModel.content}
                        className={cn(styles.inlineWrapper, styles.container)}
                    />
                </span>
            </span>
        </span>
    );
});
