import { makeObservable, observable } from "mobx";

import { BaseDrawingGenerationSettings } from "./BaseDrawingGenerationSettings";
import { DrawingGenerationViewport } from "./DrawingGenerationViewport";

export class DistanceToLine extends BaseDrawingGenerationSettings {
    @observable drawLines: boolean;
    @observable.ref viewport: DrawingGenerationViewport;

    constructor(init: DistanceToLine.InitObject) {
        super();

        this.drawLines = init.drawLines;
        this.viewport = init.viewport;

        makeObservable(this);
    }

    static createEmpty() {
        return new DistanceToLine({
            drawLines: false,
            viewport: DrawingGenerationViewport.createEmpty(),
        });
    }

    accept<R>(visitor: BaseDrawingGenerationSettings.Visitor<R>): R {
        return visitor.withDistanceToLine(this);
    }

    clone(): DistanceToLine {
        return new DistanceToLine({ drawLines: this.drawLines, viewport: this.viewport.clone() });
    }
}

export declare namespace DistanceToLine {
    export type InitObject = {
        drawLines: boolean;
        viewport: DrawingGenerationViewport;
    };
}
