import cn from "classnames";
import { observer } from "mobx-react-lite";
import React from "react";

import { repeat } from "@viuch/shared/utils/data";

import type { BaseTableInstrumentSettingsImpl } from "../../settings";
import type { KeyboardService } from "@viuch/math-editor";

import { useCellEditorRenderer } from "../hooks";

import styles from "./InstrumentTable.module.scss";

type Props = {
    columnsCount: number;
    rowsCount: number;
    onRemoveColumn: (i: number) => void;
    onRemoveRow: (i: number) => void;
    table: BaseTableInstrumentSettingsImpl;
    keyboardService: KeyboardService;
    onAddColumn: () => void;
    onAddRow: () => void;
};

export const InstrumentTable: React.VFC<Props> = observer(function InstrumentTable(props) {
    const { table, columnsCount, onRemoveColumn, onRemoveRow, keyboardService, onAddColumn, onAddRow, rowsCount } =
        props;
    const renderCellEditor = useCellEditorRenderer();

    return (
        <table className={styles.table}>
            <tbody>
                <tr>
                    <td className={styles.hidden} />
                    {repeat(columnsCount).map((i) => (
                        <td
                            className={styles.addRow}
                            key={i}
                            onClick={() => onRemoveColumn(i)}
                        >
                            -
                        </td>
                    ))}
                    <td className={styles.hidden} />
                </tr>
                {table.cells.map((row, i) => (
                    <tr key={i}>
                        <td
                            className={styles.addRow}
                            onClick={() => onRemoveRow(i)}
                        >
                            -
                        </td>
                        {row.map((cell) => (
                            <td key={cell.$$instanceId}>
                                {renderCellEditor(cell, {
                                    keyboardService,
                                })}
                                <div>
                                    <span
                                        className={cn(styles.switchLabel, cell.isHidden && styles.switchLabel_hidden)}
                                    >
                                        {cell.isHidden ? "Скрыта" : "Отображается"}
                                    </span>{" "}
                                    <button
                                        className={styles.switchButton}
                                        onClick={() => cell.setHidden(!cell.isHidden)}
                                    >
                                        {cell.isHidden ? "Показать" : "Скрыть"}
                                    </button>
                                </div>
                            </td>
                        ))}
                        {i === 0 && (
                            <td
                                className={styles.addRow}
                                rowSpan={rowsCount}
                                onClick={() => onAddColumn()}
                            >
                                +
                            </td>
                        )}
                    </tr>
                ))}
                <tr>
                    <td className={styles.hidden} />
                    <td
                        className={styles.addRow}
                        colSpan={columnsCount}
                        onClick={() => onAddRow()}
                    >
                        +
                    </td>
                    <td className={styles.hidden} />
                </tr>
            </tbody>
        </table>
    );
});
