import { createAllFeatures } from "@viuch/instrument-graph2d-plugins/shared/features";
import { Graph2DInstrumentAxisSettings } from "@viuch/instrument-graph2d-settings/settings-entity/Graph2DInstrumentAxisSettings";
import { Graph2DInstrumentConfigSettings } from "@viuch/instrument-graph2d-settings/settings-entity/Graph2DInstrumentConfigSettings";
import { Graph2DInstrumentCoordinateSystemSettings } from "@viuch/instrument-graph2d-settings/settings-entity/Graph2DInstrumentCoordinateSystemSettings";
import { Graph2DInstrumentGridSettings } from "@viuch/instrument-graph2d-settings/settings-entity/Graph2DInstrumentGridSettings";
import { Graph2DInstrumentSettings } from "@viuch/instrument-graph2d-settings/settings-entity/Graph2DInstrumentSettings";
import { Graph2DInstrumentStateSettings } from "@viuch/instrument-graph2d-settings/settings-entity/Graph2DInstrumentStateSettings";
import { Graph2DInstrumentViewportSettings } from "@viuch/instrument-graph2d-settings/settings-entity/Graph2DInstrumentViewportSettings";
import { EvalGraphSettings } from "@viuch/instrument-graph2d-settings/settings-entity/graphs/EvalGraphSettings.ts/EvalGraphSettings";
import { PointGraphSettings } from "@viuch/instrument-graph2d-settings/settings-entity/graphs/EvalGraphSettings.ts/PointGraphSettings";
import { assertNever } from "@viuch/utils/debug";

import type {
    TRawGraph2DInstrumentSettings,
    TRawGraph2DInstrumentSettings_AnyGraph,
    TRawGraph2DInstrumentSettings_EvalFunctionGraph,
    TRawGraph2DInstrumentSettings_PointGraph,
} from "../service-types/graph2d";
import type { IGraph2DInstrumentSettingsGraphVisitor } from "@viuch/instrument-graph2d-settings/settings-entity/graphs/EvalGraphSettings.ts/BaseGraph2DInstrumentSettingsGraph";

function mapGraphSettings(graph: TRawGraph2DInstrumentSettings_AnyGraph): EvalGraphSettings | PointGraphSettings {
    switch (graph.type) {
        case "eval-function": {
            const { color, funcFormula, sign } = graph;

            return new EvalGraphSettings(color, funcFormula, sign);
        }
        case "point": {
            const { x, y, is_included, label, color, x_label, y_label } = graph;

            return new PointGraphSettings(x, y, is_included, color, label, x_label, y_label);
        }
    }

    assertNever(graph);
}

export function mapGraph2DSettings(settings: TRawGraph2DInstrumentSettings): Graph2DInstrumentSettings {
    const { coordinateSystem, grid, viewport, state, axes, version, config } = settings;

    const { add, behaviour, transformations, modifiers } = config?.features ?? createAllFeatures();

    return new Graph2DInstrumentSettings(
        new Graph2DInstrumentViewportSettings(
            viewport.scale.max,
            viewport.scale.initial,
            viewport.initialPosition.x,
            viewport.initialPosition.y
        ),
        new Graph2DInstrumentGridSettings(
            grid.primary.size,
            grid.secondary.size,
            grid.secondary.splitOnZoomFactor,
            grid.secondary.scaleSteps ?? []
        ),
        new Graph2DInstrumentCoordinateSystemSettings(
            coordinateSystem.gridStepSize.x,
            coordinateSystem.gridStepSize.y,
            coordinateSystem.viewportCenter.x,
            coordinateSystem.viewportCenter.y,
            axes.x ? new Graph2DInstrumentAxisSettings(axes.x.name) : null,
            axes.y ? new Graph2DInstrumentAxisSettings(axes.y.name) : null
        ),
        new Graph2DInstrumentStateSettings(state.graphs.map(mapGraphSettings)),
        new Graph2DInstrumentConfigSettings(
            config?.readonly ?? false,
            state.show_graphs ?? false,
            behaviour,
            add,
            transformations,
            modifiers
        ),
        state.completions.map((graphs) => new Graph2DInstrumentStateSettings(graphs.map(mapGraphSettings)))
    );
}

export function serializeGraph2DSettings(
    settings: Graph2DInstrumentSettings,
    uuid: string
): TRawGraph2DInstrumentSettings {
    const { coordinateSystem, grid, viewport, state, config, completions } = settings;

    return {
        version: 1,
        instrument_type: "graph2d",
        settings_uuid: uuid,
        viewport: {
            scale: {
                initial: viewport.zoomInitial,
                max: viewport.zoomMax,
            },
            initialPosition: {
                x: viewport.initialPositionX,
                y: viewport.initialPositionY,
            },
        },
        grid: {
            primary: {
                size: grid.primaryGridSize,
            },
            secondary: {
                size: grid.secondaryGridSize,
                splitOnZoomFactor: grid.secondaryGridSplitOnZoomFactor,
                scaleSteps: grid.secondaryGridSteps,
            },
        },
        coordinateSystem: {
            gridStepSize: {
                x: coordinateSystem.scaleX,
                y: coordinateSystem.scaleY,
            },
            viewportCenter: {
                x: coordinateSystem.offsetX,
                y: coordinateSystem.offsetY,
            },
        },
        axes: {
            x: coordinateSystem.axisX ? { name: coordinateSystem.axisX.axisName } : null,
            y: coordinateSystem.axisY ? { name: coordinateSystem.axisY.axisName } : null,
        },
        state: {
            graphs: state.graphs.map((graph) => graph.accept(serializeAnyGraphVisitor)),
            completions: completions.map((completion) =>
                completion.graphs.map((graph) => graph.accept(serializeAnyGraphVisitor))
            ),
            show_graphs: config.showGraphs,
        },
        config: {
            readonly: config.readonly,
            features: {
                add: config.addFeatures,
                modifiers: config.modifierFeatures,
                transformations: config.transformationFeatures,
                behaviour: config.behaviourFeatures,
            },
        },
    };
}

const serializeAnyGraphVisitor: IGraph2DInstrumentSettingsGraphVisitor<TRawGraph2DInstrumentSettings_AnyGraph> = {
    withPoint: ({
        x,
        y,
        isIncluded: is_included,
        label,
        color,
        xLabel: x_label,
        yLabel: y_label,
    }): TRawGraph2DInstrumentSettings_PointGraph => ({
        type: "point",
        x,
        y,
        is_included,
        label,
        x_label,
        y_label,
        color,
    }),
    withEvalGraph: ({ color, func, sign }: EvalGraphSettings): TRawGraph2DInstrumentSettings_EvalFunctionGraph => ({
        type: "eval-function",
        funcFormula: func,
        color,
        sign,
    }),
};
