import React, { useEffect } from "react";

import { MathEditorInput } from "@viuch/math-editor";

import type { MathEditor } from "../../editors/math";
import type { IMathConverterService } from "../../editors/types";
import type { KeyboardService, InputService } from "@viuch/math-editor";

import styles from "./MathEditorComponent.module.scss";

type TProps = {
    editor: MathEditor;
    keyboardService: KeyboardService;
    mathConverterService: IMathConverterService;
};

export const MathEditorComponent: React.VFC<TProps> = ({ editor, keyboardService, mathConverterService }) => {
    useEffect(() => {
        editor.setMathConverterService(mathConverterService);
    }, [editor, mathConverterService]);

    async function handleBlur(inputService: InputService) {
        const text = await mathConverterService.mathExpressionToString(inputService.getSerializedState());
        editor.setTextRepresentation(text);
    }

    return (
        <MathEditorInput
            inputModel={editor.inputModel}
            keyboardService={keyboardService}
            inputWrapperClassName={styles.inputWrapper}
            className={styles.input}
            onBlur={handleBlur}
        />
    );
};
