import cn from "classnames";
import { observer } from "mobx-react-lite";
import React, { Fragment, type PropsWithChildren, type ReactNode, useCallback, useEffect, useMemo } from "react";

import { InstrumentGraph2DEditor } from "@viuch/instrument-graph2d/editor/InstrumentGraph2DEditor";
import { CheckboxInput, MathArrayInput, MathInput2 } from "@viuch/ui-common";
import { InstrumentFrame } from "@viuch/ui-kit/frames";
import { LoadingSpinner } from "@viuch/ui-kit/spinner";
import { useConst } from "@viuch/utils/hooks";

import type { IGraph2DSettingsHost } from "../interfaces/IGraph2DSettingsHost";
import type { Graph2DInstrumentSettings } from "../settings-entity/Graph2DInstrumentSettings";
import type {
    BaseGraph2DInstrumentSettingsGraph,
    IGraph2DInstrumentSettingsGraphVisitor,
} from "../settings-entity/graphs/EvalGraphSettings.ts/BaseGraph2DInstrumentSettingsGraph";
import type { KeyboardService } from "@viuch/math-editor";

import { Graph2DInstrumentSettingsGraphEditor } from "./components/Graph2DInstrumentSettingsGraphEditor";
import { Graph2DInstrumentSettingsPointEditor } from "./components/Graph2DInstrumentSettingsPointEditor";
import { LayerSelect } from "./components/LayerSelect/LayerSelect";
import { Graph2DInstrumentSettingsEditorStore } from "./Graph2DInstrumentSettingsEditor.store";

import styles from "./Graph2DInstrumentSettingsEditor.module.scss";

type Props = PropsWithChildren<{
    host: IGraph2DSettingsHost;
    graphInstrumentSettings: Graph2DInstrumentSettings;
    keyboardService: KeyboardService;
    className?: string;
}>;

export const Graph2DInstrumentSettingsEditor = observer(function Graph2DInstrumentSettingsEditor({
    graphInstrumentSettings,
    host,
    keyboardService,
    className,
    children,
}: Props) {
    const { model, view, settings, buildGraph, loading, error } = useConst(
        () => new Graph2DInstrumentSettingsEditorStore(graphInstrumentSettings, host)
    );

    const {
        viewport,
        state,
        grid,
        coordinateSystem,
        config,
        selectedLayerId,
        selectedLayer,
        completions,
        selectCompletion,
        addCompletion,
        deleteCompletion,
    } = settings;

    const { axisX, axisY } = coordinateSystem;

    useEffect(() => {
        buildGraph();
    }, [buildGraph, completions, selectedLayerId]);

    const layers = useMemo(() => {
        const mainLayer = { value: state.$uuid, label: "Основной график" };
        const completionLayers = completions.map((c, index) => ({
            value: c.$uuid,
            label: `Достроение #${index + 1}`,
        }));

        return [mainLayer, ...completionLayers];
    }, [completions, state]);

    const isMainLayerSelected = selectedLayerId === state.$uuid;

    const onResultGraphsVisibilityChange = useCallback(
        (value: boolean) => config.setIsResultGraphsShown(!value),
        [config]
    );

    return (
        <div className={cn(styles.root, className)}>
            <div className={styles.column}>
                <div className={styles.title}>Графики</div>

                <LayerSelect
                    value={selectedLayerId}
                    layers={layers}
                    completionsCount={completions.length}
                    onChange={selectCompletion}
                    onAdd={addCompletion}
                    onDelete={deleteCompletion}
                    deleteDisabled={isMainLayerSelected}
                />

                {selectedLayer?.graphs.map((graphSettings) => (
                    <Fragment key={graphSettings.$uuid}>
                        {graphSettings.accept(renderGraphEditorVisitor, keyboardService, selectedLayer?.deleteGraph)}
                    </Fragment>
                ))}

                {!!selectedLayer && (
                    <>
                        <button onClick={selectedLayer.addNewGraph}>+ Новый график</button>
                        <button onClick={selectedLayer.addNewPoint}>+ Добавить точку</button>
                    </>
                )}
            </div>
            <div className={styles.column}>
                <div className={styles.title}>Область просмотра</div>

                <div className={styles.section}>
                    <div className={styles.name}>Зум</div>
                    <div className={styles.row}>
                        <MathInput2
                            title="Максимальный (от 1)"
                            value={viewport.zoomMax}
                            onChange={viewport.setZoomMax}
                            keyboardService={keyboardService}
                        />
                        <MathInput2
                            title="Начальный (от 1 до MAX)"
                            value={viewport.zoomInitial}
                            onChange={viewport.setZoomInitial}
                            keyboardService={keyboardService}
                        />
                    </div>
                </div>
                <div className={styles.section}>
                    <div className={styles.name}>Центр вьюпорта</div>
                    <div className={styles.row}>
                        <MathInput2
                            title="По оси X (центр = 0.5)"
                            value={viewport.initialPositionX}
                            onChange={viewport.setInitialPositionX}
                            keyboardService={keyboardService}
                        />
                        <MathInput2
                            title="По оси Y (центр = 0.5)"
                            value={viewport.initialPositionY}
                            onChange={viewport.setInitialPositionY}
                            keyboardService={keyboardService}
                        />
                    </div>
                </div>

                <div className={styles.title}>Координатные оси</div>
                <div className={styles.section}>
                    <div className={styles.name}>Координатная ось X</div>
                    <CheckboxInput
                        noSpace
                        labelAfter="Отображать"
                        type="checkbox"
                        checked={!!axisX}
                        onChange={coordinateSystem.setEnableAxisX}
                    />
                    <div className={styles.row}>
                        {axisX && (
                            <>
                                <MathInput2
                                    title="Название оси X"
                                    value={axisX.axisName}
                                    onChange={axisX.setAxisName}
                                    keyboardService={keyboardService}
                                />
                            </>
                        )}
                    </div>
                </div>
                <div className={styles.section}>
                    <div className={styles.name}>Координатная ось Y</div>
                    <CheckboxInput
                        noSpace
                        labelAfter="Отображать"
                        type="checkbox"
                        checked={!!axisY}
                        onChange={coordinateSystem.setEnableAxisY}
                    />
                    {axisY && (
                        <div className={styles.row}>
                            <MathInput2
                                title="Название оси Y"
                                value={axisY.axisName}
                                onChange={axisY.setAxisName}
                                keyboardService={keyboardService}
                            />
                        </div>
                    )}
                </div>
            </div>
            <div className={styles.column}>
                <div className={styles.title}>Система координат</div>

                <div className={styles.section}>
                    <div className={styles.name}>Основная сетка</div>

                    <div className={styles.row}>
                        <MathInput2
                            title="Кол-во шагов первичной сетки"
                            value={grid.primaryGridSize}
                            onChange={grid.setPrimaryGridSize}
                        />
                    </div>
                    <div className={styles.row}>
                        <MathInput2
                            title="Ширина (X) одной клетки"
                            value={coordinateSystem.scaleX}
                            onChange={coordinateSystem.setScaleX}
                            keyboardService={keyboardService}
                        />
                        <MathInput2
                            title="Высота (Y) одной клетки"
                            value={coordinateSystem.scaleY}
                            onChange={coordinateSystem.setScaleY}
                            keyboardService={keyboardService}
                        />
                    </div>
                    <div className={styles.row}>
                        <MathInput2
                            title="Сдвиг по X"
                            value={coordinateSystem.offsetX}
                            onChange={coordinateSystem.setOffsetX}
                            keyboardService={keyboardService}
                        />
                        <MathInput2
                            title="Сдвиг по Y"
                            value={coordinateSystem.offsetY}
                            onChange={coordinateSystem.setOffsetY}
                            keyboardService={keyboardService}
                        />
                    </div>
                </div>

                <div className={styles.section}>
                    <div className={styles.name}>Вторичная сетка</div>

                    <MathInput2
                        title="Кол-во шагов вторичной сетки (при масштабе = 1)"
                        value={grid.secondaryGridSize}
                        onChange={grid.setSecondaryGridSize}
                        keyboardService={keyboardService}
                    />
                    <MathInput2
                        title="Дробление сетки (экспонента)"
                        value={grid.secondaryGridSplitOnZoomFactor}
                        onChange={grid.setSecondaryGridSplitOnZoomFactor}
                        keyboardService={keyboardService}
                    />
                    <MathArrayInput
                        title="Дробление сетки (множители)"
                        values={grid.secondaryGridSteps}
                        onChange={grid.setSecondaryGridStep}
                        onAddValue={grid.addSecondaryGridStep}
                        onRemoveValue={grid.removeSecondaryGridStep}
                        keyboardService={keyboardService}
                    />
                </div>
            </div>
            <div className={styles.column}>
                <div className={styles.title}>Конфигурация инструмента</div>

                {children && (
                    <div className={styles.section}>
                        <div className={styles.name}>Дополнительно</div>
                        {children}
                    </div>
                )}

                <div className={styles.section}>
                    <div className={styles.name}>Поведение инструмента</div>
                    <CheckboxInput
                        noSpace
                        labelAfter="Отключить проверку"
                        type="checkbox"
                        checked={config.readonly}
                        onChange={config.setReadonly}
                    />
                    <CheckboxInput
                        noSpace
                        labelAfter="Отмена/Повтор"
                        type="checkbox"
                        checked={config.getBehaviourFeature("history")}
                        onChange={(v) => config.setBehaviourFeature("history", v)}
                    />
                    <CheckboxInput
                        noSpace
                        labelAfter="Скрывать правильное решение"
                        type="checkbox"
                        checked={!config.showGraphs}
                        onChange={onResultGraphsVisibilityChange}
                    />
                </div>

                <div className={styles.section}>
                    <div className={styles.name}>Создание графиков</div>
                    <CheckboxInput
                        noSpace
                        labelAfter="Точки (сами по себе)"
                        type="checkbox"
                        checked={config.getAddFeature("point")}
                        onChange={(v) => config.setAddFeature("point", v)}
                    />
                    <CheckboxInput
                        noSpace
                        labelAfter="Точки по одной координате"
                        type="checkbox"
                        checked={config.getAddFeature("pointOnInterval")}
                        onChange={(v) => config.setAddFeature("pointOnInterval", v)}
                    />
                    <CheckboxInput
                        noSpace
                        labelAfter="Прямая"
                        type="checkbox"
                        checked={config.getAddFeature("line")}
                        onChange={(v) => config.setAddFeature("line", v)}
                    />
                    <CheckboxInput
                        noSpace
                        labelAfter="Парабола"
                        type="checkbox"
                        checked={config.getAddFeature("parabola")}
                        onChange={(v) => config.setAddFeature("parabola", v)}
                    />
                    <CheckboxInput
                        noSpace
                        labelAfter="Парабола (кубическая)"
                        type="checkbox"
                        checked={config.getAddFeature("cubicParabola")}
                        onChange={(v) => config.setAddFeature("cubicParabola", v)}
                    />
                    <CheckboxInput
                        noSpace
                        labelAfter="Гипербола"
                        type="checkbox"
                        checked={config.getAddFeature("hyperbola")}
                        onChange={(v) => config.setAddFeature("hyperbola", v)}
                    />
                    <CheckboxInput
                        noSpace
                        labelAfter="Корень квадратный"
                        type="checkbox"
                        checked={config.getAddFeature("sqrt")}
                        onChange={(v) => config.setAddFeature("sqrt", v)}
                    />
                    <CheckboxInput
                        noSpace
                        labelAfter="Корень кубический"
                        type="checkbox"
                        checked={config.getAddFeature("curt")}
                        onChange={(v) => config.setAddFeature("curt", v)}
                    />
                    <CheckboxInput
                        noSpace
                        labelAfter="Произвольный график"
                        type="checkbox"
                        checked={config.getAddFeature("custom")}
                        onChange={(v) => config.setAddFeature("custom", v)}
                    />
                </div>

                <div className={styles.section}>
                    <div className={styles.name}>Модификаторы</div>
                    <CheckboxInput
                        noSpace
                        labelAfter="Раскрашивание графиков"
                        type="checkbox"
                        checked={config.getModifierFeature("color")}
                        onChange={(v) => config.setModifierFeature("color", v)}
                    />
                    <CheckboxInput
                        noSpace
                        labelAfter="Подписи к точкам"
                        type="checkbox"
                        checked={config.getModifierFeature("pointLabel")}
                        onChange={(v) => config.setModifierFeature("pointLabel", v)}
                    />
                    <CheckboxInput
                        noSpace
                        labelAfter="Графики неравенств"
                        type="checkbox"
                        checked={config.getModifierFeature("inequality")}
                        onChange={(v) => config.setModifierFeature("inequality", v)}
                    />
                </div>

                <div className={styles.section}>
                    <div className={styles.name}>Преобразования</div>
                    <CheckboxInput
                        noSpace
                        labelAfter="Перемещение графиков"
                        type="checkbox"
                        checked={config.getTransformationFeature("move")}
                        onChange={(v) => config.setTransformationFeature("move", v)}
                    />
                    <CheckboxInput
                        noSpace
                        labelAfter="Сжатие и растяжение"
                        type="checkbox"
                        checked={config.getTransformationFeature("scale")}
                        onChange={(v) => config.setTransformationFeature("scale", v)}
                    />
                    <CheckboxInput
                        noSpace
                        labelAfter="Модуль |x|"
                        type="checkbox"
                        checked={config.getTransformationFeature("absX")}
                        onChange={(v) => config.setTransformationFeature("absX", v)}
                    />
                </div>
            </div>
            <div className={styles.column}>
                <div className={styles.title}>Предпросмотр</div>
                <button onClick={buildGraph}>Собрать график</button>
                {error && <i className={styles.errorText}>Не удалось построить график, попробуйте ещё раз</i>}
                <InstrumentFrame className={styles.frame}>
                    <InstrumentGraph2DEditor
                        key={view.$uuid}
                        model={model}
                        view={view}
                        className={styles.instrument}
                    />
                </InstrumentFrame>
            </div>
            {loading && (
                <LoadingSpinner
                    absolute
                    center
                />
            )}
        </div>
    );
});

const renderGraphEditorVisitor: IGraph2DInstrumentSettingsGraphVisitor<
    ReactNode,
    [keyboard: KeyboardService, onDelete: (graph: BaseGraph2DInstrumentSettingsGraph) => void]
> = {
    withEvalGraph: (graph, keyboard, onDelete) => (
        <Graph2DInstrumentSettingsGraphEditor
            key={graph.$uuid}
            graph={graph}
            onDelete={onDelete}
            keyboardService={keyboard}
        />
    ),
    withPoint: (point, keyboard, onDelete) => (
        <Graph2DInstrumentSettingsPointEditor
            graph={point}
            keyboardService={keyboard}
            onDelete={onDelete}
        />
    ),
};
