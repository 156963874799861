import { action, makeObservable, observable } from "mobx";

import { generateUuid } from "@viuch/shared/utils/data";

import type { EvalGraphSettings } from "./EvalGraphSettings";
import type { PointGraphSettings } from "./PointGraphSettings";

export abstract class BaseGraph2DInstrumentSettingsGraph {
    readonly $uuid = generateUuid();
    @observable.ref color: string;

    protected constructor(color: string) {
        this.color = color;

        makeObservable(this);
    }

    @action.bound
    setColor(color: string) {
        this.color = color;
    }

    abstract clone(): BaseGraph2DInstrumentSettingsGraph;

    abstract accept<T, A extends any[]>(visitor: IGraph2DInstrumentSettingsGraphVisitor<T, A>, ...args: A): T;
}

export interface IGraph2DInstrumentSettingsGraphVisitor<T, A extends any[] = []> {
    withEvalGraph(graph: EvalGraphSettings, ...args: A): T;
    withPoint(point: PointGraphSettings, ...args: A): T;
}
