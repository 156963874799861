import type { AxisGraphItem } from "../models/AxisGraphItem";
import type { TSerializedState } from "@viuch/math-editor";

import { BaseGraphViewItem } from "../editor/rendering/BaseGraphViewItem";
import { zIndexValues } from "../editor/rendering/utils";

export class AxisGraphViewItem extends BaseGraphViewItem {
    readonly type: "x" | "y";
    readonly axisName: TSerializedState;
    readonly points: TAxisPointView[];
    readonly omitZero: boolean;

    constructor(
        model: AxisGraphItem,
        type: "x" | "y",
        axisName: TSerializedState,
        points: TAxisPointView[],
        omitZero: boolean
    ) {
        super(model, zIndexValues.util.default);

        this.type = type;
        this.axisName = axisName;
        this.points = points;
        this.omitZero = omitZero;
    }
}

type TAxisPointView = {
    position: number;
    boundPosition: number;
    label: TSerializedState;
};
