import * as mobx from "mobx";

import { assert } from "@viuch/utils/debug";

import type {
    TContainerRectWithElements,
    TElementData,
    TElementDataWithRect,
    TElementWithRect,
    TRaycastResult,
    TSelectMode,
} from "./SelectionController.types";
import type { TSerializedElementWithIndex, TSelectionSnapshot } from "./types";
import type { InputService } from "../../services";
import type { TPoint, TRect } from "../../types";
import type { ContainerModel, ContainersStore } from "../container";
import type { ILinebreakFinder } from "../cursor/LinebreakFinder";
import type { BaseElementModel } from "../element";
import type { ElementsStore } from "../element/ElementsStore";

import { InsertIntoCursorCommand } from "../../commands/InsertIntoCursorCommand";
import { LinebreakModel } from "../../elements/linebreak/LinebreakModel";
import { createSerializedLinebreak } from "../../elements/linebreak/utils";
import { compare } from "../../utils/data";
import { computeRectCenter, computeSquaredDistance, pointSubtraction, rectAddition } from "../../utils/positions";
import { scheduleTask } from "../../utils/runtime";
import { computeRectArea, isPointInsideRect } from "../../utils/validation";
import { LinebreakFinder } from "../cursor/LinebreakFinder";

import { getClientRects, computeClosestElement, computeCommonElement } from "./SelectionController.utils";

export class SelectionController {
    public readonly inputService: InputService;
    private readonly finder: ILinebreakFinder;
    private readonly elementsStore: ElementsStore;
    private readonly containersStore: ContainersStore;
    private readonly containers: Set<ContainerModel>;
    private containerRects?: Map<ContainerModel, TContainerRectWithElements>;
    public isSelecting: boolean;
    public reverse: boolean;
    public mode: TSelectMode;

    private startSelectionData?: TElementDataWithRect;
    private endSelectionData?: TElementDataWithRect;

    constructor(inputService: InputService) {
        this.inputService = inputService;
        this.finder = LinebreakFinder.Create();
        this.elementsStore = inputService.elements;
        this.containersStore = inputService.containers;
        this.containers = new Set();
        this.isSelecting = false;
        this.reverse = false;
        this.mode = "mouse";

        mobx.makeAutoObservable(this, {
            isElementSelected: false,
            isContainerSelection: false,
            isSelectedSomething: false,
        });
    }

    get isSelectingTouch(): boolean {
        return this.isSelecting && this.mode === "touch";
    }

    get commonContainer(): ContainerModel | undefined {
        if (!this.startSelectionData || !this.endSelectionData) {
            return undefined;
        }

        const leftTree: ContainerModel[] = [];
        let leftCurrentContainer = this.startSelectionData.element.parentContainer;

        while (leftCurrentContainer) {
            leftTree.unshift(leftCurrentContainer);
            leftCurrentContainer = leftCurrentContainer.parentElement.parentContainer;
        }

        const rightTree: ContainerModel[] = [];
        let rightCurrentContainer = this.endSelectionData.element.parentContainer;

        while (rightCurrentContainer) {
            rightTree.unshift(rightCurrentContainer);
            rightCurrentContainer = rightCurrentContainer.parentElement.parentContainer;
        }

        const length = Math.min(leftTree.length, rightTree.length);
        if (length < 2) {
            return leftTree[0];
        }
        for (let i = 1; i < length; i++) {
            if (leftTree[i] !== rightTree[i]) {
                return leftTree[i - 1];
            }
        }
        return leftTree[length - 1];
    }

    get startCommonElementData(): TElementData | undefined {
        if (!this.commonContainer || !this.startSelectionData) {
            return undefined;
        }
        return computeCommonElement(this.commonContainer, this.startSelectionData);
    }

    get endCommonElementData(): TElementData | undefined {
        if (!this.commonContainer || !this.endSelectionData) {
            return undefined;
        }
        return computeCommonElement(this.commonContainer, this.endSelectionData);
    }

    get selectedElements(): BaseElementModel[] | undefined {
        const commonContainer = this.commonContainer;
        if (!commonContainer) {
            return undefined;
        }

        let startCommonElementData = this.startCommonElementData;
        let endCommonElementData = this.endCommonElementData;

        if (!startCommonElementData || !endCommonElementData) {
            return undefined;
        }

        let startIndex = startCommonElementData.element.computeIndex();
        let endIndex = endCommonElementData.element.computeIndex();

        mobx.runInAction(() => {
            this.reverse = startIndex >= endIndex;
        });

        if (this.reverse) {
            [startCommonElementData, endCommonElementData] = [endCommonElementData, startCommonElementData];
            [startIndex, endIndex] = [endIndex, startIndex];
        }
        if (startCommonElementData.isRight) {
            startIndex++;
        }
        if (endCommonElementData.isLeft) {
            endIndex--;
        }

        if (startIndex > endIndex) {
            return undefined;
        }

        const selectedElements = commonContainer.elements.slice(startIndex, endIndex + 1);

        if (
            selectedElements.length >= 2 &&
            this.finder.isLinebreak(selectedElements[0]) &&
            !this.finder.isLinebreak(selectedElements[1])
        ) {
            return selectedElements.slice(1);
        }

        return selectedElements;
    }

    get isSelectedSomething(): boolean {
        return Boolean(this.selectedElements?.length);
    }

    get firstSelectedElement(): BaseElementModel | null {
        if (!this.selectedElements?.length) {
            return null;
        }
        return this.selectedElements[0];
    }

    get lastSelectedElement(): BaseElementModel | null {
        if (!this.selectedElements?.length) {
            return null;
        }
        return this.selectedElements[this.selectedElements.length - 1];
    }

    get firstSelectedElementRect(): TRect | null {
        if (!this.startSelectionData) {
            return null;
        }
        return this.startSelectionData.rect;
    }

    get lastSelectedElementRect(): TRect | null {
        if (!this.endSelectionData) {
            return null;
        }
        return this.endSelectionData.rect;
    }

    isElementSelected(element: BaseElementModel): boolean {
        return this.selectedElements?.includes(element) ?? false;
    }

    isElementOrParentSelected(element: BaseElementModel): boolean {
        const commonContainer = this.commonContainer;

        let currentElement = element;

        while (commonContainer !== currentElement.parentContainer) {
            if (this.finder.isEditor(currentElement)) {
                return false;
            }
            currentElement = currentElement.parentContainer.parentElement;
        }

        assert(currentElement.parentContainer === commonContainer);
        return this.isElementSelected(currentElement);
    }

    isContainerSelection(container: ContainerModel): boolean {
        return container === this.commonContainer;
    }

    clearSelection(): void {
        this.isSelecting = false;

        this.containerRects = undefined;
        this.startSelectionData = undefined;
        this.endSelectionData = undefined;
    }

    initContainerRects(offset: TPoint): void {
        this.containerRects = new Map<ContainerModel, TContainerRectWithElements>();
        this.containers.forEach((container) => {
            const screenRect = container.getRect();
            if (!screenRect) {
                return;
            }

            const containerRect = pointSubtraction(screenRect, offset);
            const elementsWithRects = getClientRects(container, offset);

            this.containerRects?.set(container, {
                containerRect,
                elementsWithRects,
            });
        });
    }

    notifyMouseDown(point: TPoint, offset: TPoint): void {
        this.clearSelection();

        this.isSelecting = true;
        this.mode = "mouse";
        this.initContainerRects(offset);

        this.startSelectionData = this.getElementDataUnderPoint(point);
    }

    notifyMouseMove(point: TPoint): void {
        if (!this.isSelecting) {
            return;
        }
        this.endSelectionData = this.getElementDataUnderPoint(point);
    }

    notifyMouseUp(point: TPoint) {
        if (!this.isSelecting) {
            return;
        }

        this.endSelectionData = this.getElementDataUnderPoint(point);

        if (!this.endSelectionData) {
            this.startSelectionData = undefined;
        }
        this.isSelecting = false;
        this.containerRects = undefined;
    }

    private getContainerDataUnderPoint(point: TPoint): [ContainerModel, TContainerRectWithElements] | undefined {
        if (!this.containerRects) {
            return;
        }

        const entries = Array.from(this.containerRects.entries()).sort(([, left], [, right]) =>
            compare(computeRectArea(left.containerRect), computeRectArea(right.containerRect))
        );
        const paddingRect: TRect = { x: -5, y: -5, w: 10, h: 10 };

        const entry = entries.find(([, data]) => {
            return isPointInsideRect(point, rectAddition(data.containerRect, paddingRect));
        });

        if (entry) {
            return entry;
        }

        const rootContainer = this.inputService.model.rootContainer;
        const rectWithElements = this.containerRects.get(rootContainer);

        if (rectWithElements) {
            return [rootContainer, rectWithElements];
        }
    }

    private getElementDataUnderPoint(point: TPoint): TElementDataWithRect | undefined {
        const entry = this.getContainerDataUnderPoint(point);
        if (entry) {
            return computeClosestElement(point, entry[1].elementsWithRects);
        }
    }

    public raycast(point: TPoint): TRaycastResult | undefined {
        const entry = this.getContainerDataUnderPoint(point);
        if (!entry) {
            return;
        }
        const [container, elementsData] = entry;

        if (elementsData.elementsWithRects.length === 0) {
            return { container, index: 0, isOverElement: false, isUnderBottomLine: false };
        }

        const lines = elementsData.elementsWithRects.reduce<TElementWithRect[][]>(
            (lines, elementWithRect) => {
                const { element } = elementWithRect;
                if (this.finder.isLinebreak(element)) {
                    const newLine: TElementWithRect[] = [elementWithRect];
                    lines.push(newLine);
                } else {
                    lines[lines.length - 1].push(elementWithRect);
                }
                return lines;
            },
            [[]]
        );

        const lineBounds = lines.map((line) => {
            const bounds = line.reduce(
                ({ top, bottom }, { rect }) => ({
                    top: top ? Math.min(rect.y, top) : rect.y,
                    bottom: bottom ? Math.max(rect.y + rect.h, bottom) : rect.y + rect.h,
                }),
                { top: 0, bottom: 10 }
            );

            return { elementsWithRects: line, bounds };
        });

        let isUnderBottomLine = false;

        const lastLine = lineBounds.at(-1);
        if (lastLine) {
            if (point.y > lastLine.bounds.bottom) {
                isUnderBottomLine = true;
            }
        }

        let minDistance = 1e9;
        let minDistanceLineIndex = 0;

        const { y } = point;
        const linesWithDistances = lineBounds.map((line, i) => {
            const { top, bottom } = line.bounds;
            const isInside = top <= y && y <= bottom;

            const distance = isInside ? 0 : top > y ? top - y : y - bottom;

            if (minDistance > distance) {
                minDistance = distance;
                minDistanceLineIndex = i;
            }

            return { ...line, distance };
        });

        const closestLine = linesWithDistances[minDistanceLineIndex];

        if (closestLine.elementsWithRects.length === 0 && minDistanceLineIndex === 0) {
            return {
                isOverElement: false,
                isUnderBottomLine,
                index: 0,
                container,
            };
        }

        let minSquaredDistance = 1e9;
        let closestElementData: TElementWithRect | null = null;
        let closestElementIsRight = false;

        for (const elementData of closestLine.elementsWithRects) {
            const { rect, element } = elementData;
            const rectCenter = computeRectCenter(rect);
            const isRight = point.x > rectCenter.x;
            if (isPointInsideRect(point, rect)) {
                const index = element.computeIndex() + (isRight ? 1 : 0);
                return { container, index, isOverElement: true, isUnderBottomLine };
            }
            const distance = computeSquaredDistance(point, rectCenter);
            if (minSquaredDistance > distance) {
                minSquaredDistance = distance;
                closestElementData = elementData;
                closestElementIsRight = isRight;
            }
        }
        if (!closestElementData) {
            return;
        }
        const { element } = closestElementData;
        const index = element.computeIndex() + (closestElementIsRight ? 1 : 0);
        return { container, index, isOverElement: false, isUnderBottomLine };
    }

    registerContainer(containerModel: ContainerModel) {
        this.containers.add(containerModel);
    }

    freeContainer(containerModel: ContainerModel) {
        this.containers.delete(containerModel);
    }

    notifyTouchSelectionInit(point: TPoint, offset: TPoint): void {
        this.clearSelection();

        this.isSelecting = true;
        this.mode = "touch";
        this.initContainerRects(offset);

        const data = this.getElementDataUnderPoint(point);

        if (!data) {
            this.clearSelection();
            return;
        }

        this.startSelectionData = { ...data, isLeft: false, isRight: false };
        this.endSelectionData = { ...data, isLeft: false, isRight: false };
    }

    notifyPointerMove(what: 1 | 2, point: TPoint): void {
        if (!this.startSelectionData || !this.endSelectionData) {
            return;
        }
        const found = this.getElementDataUnderPoint(point);

        if (!found) {
            return;
        }

        if (what === 1) {
            this.startSelectionData = {
                ...found,
                isLeft: false,
                isRight: false,
            };
            this.endSelectionData = {
                ...this.endSelectionData,
                isLeft: false,
                isRight: false,
            };
        }

        if (what === 2) {
            this.endSelectionData = { ...found, isLeft: false, isRight: false };
            this.startSelectionData = {
                ...this.startSelectionData,
                isLeft: false,
                isRight: false,
            };
        }
    }

    setCursorAt(point: TPoint, offset: TPoint, allowNewLineAction: boolean): void {
        this.initContainerRects(offset);
        const data = this.raycast(point);

        const checkIsEmptyLastLine = (): boolean => {
            const lastElement = this.inputService.model.rootContainer.elements.at(-1);
            if (!lastElement) return false;

            return lastElement instanceof LinebreakModel;
        };

        if (data) {
            if (
                allowNewLineAction &&
                data.isUnderBottomLine &&
                this.inputService.isMultiline &&
                !checkIsEmptyLastLine()
            ) {
                this.inputService.cursorState.reset(null);

                const linebreak = createSerializedLinebreak();
                const insertLinebreak = new InsertIntoCursorCommand(this.inputService.model, linebreak);

                this.inputService.commands.perform(insertLinebreak);
            } else {
                this.inputService.cursorState.moveToPosition(data.container, data.index);
            }
        }
    }

    serializeSelectedElements(): TSerializedElementWithIndex[] {
        return (this.selectedElements ?? []).map((element) => ({
            serializedElement: element.serialize(),
            index: element.computeIndex(),
        }));
    }

    createSnapshot(): TSelectionSnapshot | null {
        const startData = this.startSelectionData;
        const endData = this.endSelectionData;

        if (
            !startData ||
            !endData ||
            !this.commonContainer ||
            !this.firstSelectedElement ||
            !this.lastSelectedElement
        ) {
            return null;
        }
        return {
            containerUuid: this.commonContainer.uuid,
            start: {
                elementUuid: startData.element.uuid,
                isLeft: startData.isLeft,
                isRight: startData.isRight,
            },
            end: {
                elementUuid: endData.element.uuid,
                isLeft: endData.isLeft,
                isRight: endData.isRight,
            },
            firstElementIndex: this.firstSelectedElement.computeIndex(),
            lastElementIndex: this.lastSelectedElement.computeIndex(),
        };
    }

    restoreSnapshot(snapshot: TSelectionSnapshot): void {
        const startElement = this.elementsStore.getById(snapshot.start.elementUuid);
        const endElement = this.elementsStore.getById(snapshot.end.elementUuid);

        scheduleTask(() => {
            mobx.runInAction(() => {
                const offset: TPoint = this.inputService.getDomElementOffset();
                this.initContainerRects(offset);
                const startElementData = this.containerRects
                    ?.get(startElement.parentContainer)
                    ?.elementsWithRects.find(({ element }) => element === startElement);

                const endElementData = this.containerRects
                    ?.get(endElement.parentContainer)
                    ?.elementsWithRects.find(({ element }) => element === endElement);

                if (startElementData && endElementData) {
                    this.startSelectionData = {
                        element: startElement,
                        isRight: snapshot.start.isRight,
                        isLeft: snapshot.start.isLeft,
                        rect: startElementData.rect,
                    };
                    this.endSelectionData = {
                        element: endElement,
                        isRight: snapshot.end.isRight,
                        isLeft: snapshot.end.isLeft,
                        rect: endElementData.rect,
                    };
                }
            });
        });
    }
}
