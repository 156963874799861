import type { TAnyAction } from "../types";

export type TDerivativeAction = { type: "derivative" };

export function createDerivativeAction(): TDerivativeAction {
    return { type: "derivative" };
}

export function isDerivativeAction(action: TAnyAction): action is TDerivativeAction {
    return action.type === "derivative";
}
