import { generateUuid } from "@viuch/shared/utils/data";

import type { ISerializedComplexIntegral } from "./types";

import { createSerializedContainer } from "../../utils";

export function createSerializedComplexIntegral(): ISerializedComplexIntegral {
    return {
        type: "complex-integral",
        uuid: generateUuid(),
        argContent: createSerializedContainer(),
        bottomContent: createSerializedContainer(),
        topContent: createSerializedContainer(),
        integralContent: createSerializedContainer(),
    };
}

const MIN_MARGIN_TOP_EM = 0.4;
const MIN_MARGIN_BOTTOM_EM = 0.4;

export function computeContentMargin(
    topContent: HTMLSpanElement,
    bottomContent: HTMLSpanElement,
    contentWrapper: HTMLSpanElement,
    argContent: HTMLSpanElement,
    wrapper: HTMLSpanElement
) {
    const oneEm = Math.round(Number(window.getComputedStyle(wrapper).fontSize.replace("px", "")));

    const topContentRect = topContent.getBoundingClientRect();
    const bottomContentRect = bottomContent.getBoundingClientRect();
    const contentRect = contentWrapper.getBoundingClientRect();
    const argContentRect = argContent.getBoundingClientRect();
    const wrapperRect = wrapper.getBoundingClientRect();

    const topBound = Math.min(topContentRect.top, contentRect.top, argContentRect.top);
    const bottomBound = Math.max(
        topContentRect.top + topContentRect.height,
        bottomContentRect.top + bottomContentRect.height,
        contentRect.top + contentRect.height,
        argContentRect.top + argContentRect.height
    );

    const minMarginTopPx = MIN_MARGIN_TOP_EM * oneEm;
    const minMarginBottomPx = MIN_MARGIN_BOTTOM_EM * oneEm;

    const marginTop = Math.floor((wrapperRect.top - topBound + minMarginTopPx) * 100) / 100;
    const marginBottom =
        Math.floor((bottomBound - (wrapperRect.top + wrapperRect.height) + minMarginBottomPx) * 100) / 100;

    return { marginTop, marginBottom };
}
