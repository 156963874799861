import { action, makeObservable, observable } from "mobx";

import { createSerializedContainer, s2m } from "@viuch/math-editor";
import { generateUuid } from "@viuch/shared/utils/data";

import type { BaseGraph2DInstrumentSettingsGraph } from "./graphs/EvalGraphSettings.ts/BaseGraph2DInstrumentSettingsGraph";

import { EvalGraphSettings } from "./graphs/EvalGraphSettings.ts/EvalGraphSettings";
import { PointGraphSettings } from "./graphs/EvalGraphSettings.ts/PointGraphSettings";

export class Graph2DInstrumentStateSettings {
    readonly $uuid = generateUuid();

    @observable.shallow graphs: BaseGraph2DInstrumentSettingsGraph[];

    constructor(graphs: BaseGraph2DInstrumentSettingsGraph[]) {
        this.graphs = graphs.slice();

        makeObservable(this);
    }

    @action.bound
    addNewGraph() {
        this.graphs.push(new EvalGraphSettings("#ffffff", s2m``, "equals"));
    }

    @action.bound
    addNewPoint() {
        this.graphs.push(
            new PointGraphSettings(
                createSerializedContainer(),
                createSerializedContainer(),
                true,
                "#ffffff",
                null,
                null,
                null
            )
        );
    }

    @action.bound
    deleteGraph(graph: BaseGraph2DInstrumentSettingsGraph) {
        this.graphs.remove(graph);
    }

    clone() {
        return new Graph2DInstrumentStateSettings(this.graphs.map((g) => g.clone()));
    }
}
