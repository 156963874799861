import { generateUuid } from "@viuch/shared/utils/data";

import type { ISerializedNaturalLog } from "./types";

import { createSerializedContainer } from "../../utils/serialization";

export function createSerializedNaturalLog(): ISerializedNaturalLog {
    return {
        type: "natural-log",
        uuid: generateUuid(),
        content: createSerializedContainer(),
        degree: createSerializedContainer(),
    };
}
