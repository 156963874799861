import { makeObservable, observable } from "mobx";

import type { IVariableContentVisitor } from "./IVariableContentVisitor";

import { SolverRequest } from "../SolverRequest";

import { BaseVariableContent } from "./BaseVariableContent";

export interface ISolverVariableContent {
    request: SolverRequest;
}

export class SolverVariableContent extends BaseVariableContent implements ISolverVariableContent {
    request: SolverRequest;

    constructor(data: ISolverVariableContent) {
        super();
        this.request = data.request;

        makeObservable(this, {
            request: observable.ref,
        });
    }

    accept<R>(visitor: IVariableContentVisitor<R>): R {
        return visitor.doWithSolver(this);
    }

    static createEmpty(): SolverVariableContent {
        return new SolverVariableContent({
            request: SolverRequest.createEmpty(),
        });
    }

    clone(): SolverVariableContent {
        return new SolverVariableContent({ request: this.request.clone() });
    }
}
